import { MONTH_OPTIONS, DEFAULT_DATE_FORMAT, DEFAULT_DAY_FORMAT } from '@remote-com/norma';

/**
 * @deprecated Use these from Norma directly.
 *
 * This is here to maintain backwards compatibility.
 */
export {
  // MONTH_OPTIONS is previously exported using camel case,
  // instead of all caps like a constant.
  MONTH_OPTIONS as monthOptions,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DAY_FORMAT,
};

export const EMPLOY_START_DATE = 2019;

export function getMonthLabel(slug) {
  return MONTH_OPTIONS.find(({ value }) => value === slug)?.label;
}

export const MISSING_DUE_DATE_COLOR = 'grey.300';
export const DEFAULT_DUE_DATE_COLOR = 'lynch';
export const WARNING_DUE_DATE_COLOR = 'salmon';
export const ERROR_DUE_DATE_COLOR = 'redPink';
