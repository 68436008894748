// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineUsers = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M8 2.75a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5ZM3.25 6a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Zm11-4c0-.41.34-.75.75-.75a4.75 4.75 0 1 1 0 9.5.75.75 0 0 1 0-1.5 3.25 3.25 0 0 0 0-6.5.75.75 0 0 1-.75-.75Zm-14 16.8a5.55 5.55 0 0 1 5.55-5.55h4.4a5.55 5.55 0 0 1 5.55 5.55 3.95 3.95 0 0 1-3.95 3.95H4.2A3.95 3.95 0 0 1 .25 18.8Zm5.55-4.05a4.05 4.05 0 0 0-4.05 4.05c0 1.35 1.1 2.45 2.45 2.45h7.6c1.35 0 2.45-1.1 2.45-2.45a4.05 4.05 0 0 0-4.05-4.05H5.8ZM16.25 14c0-.41.34-.75.75-.75h1.2a5.55 5.55 0 0 1 5.55 5.55 3.95 3.95 0 0 1-3.95 3.95H17a.75.75 0 0 1 0-1.5h2.8c1.35 0 2.45-1.1 2.45-2.45a4.05 4.05 0 0 0-4.05-4.05H17a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineUsers);
export { ForwardRef as IconV2OutlineUsers };