import type { GetEndpoints, GetResponse, UseGetOptions } from '@remote-com/data-layer';
import { useGet } from '@remote-com/data-layer';
import { useEffect, useState } from 'react';
import type { ValueOf } from 'type-fest';

import usePrevious from '@/src/hooks/usePrevious';

import { useDeepMemo } from './useDeepMemo';

// These are states extracted from Oban
export const BACKGROUND_JOB_STATES = {
  SCHEDULED: 'scheduled',
  AVAILABLE: 'available',
  EXECUTING: 'executing',
  RETRYABLE: 'retryable',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  DISCARDED: 'discarded',
};

function isJobStateDone(state?: ValueOf<typeof BACKGROUND_JOB_STATES>) {
  return [
    BACKGROUND_JOB_STATES.COMPLETED,
    BACKGROUND_JOB_STATES.CANCELLED,
    BACKGROUND_JOB_STATES.DISCARDED,
  ].includes(state || '');
}

type Options<TEndpoint extends keyof GetEndpoints> = {
  reactQueryOptions?: UseGetOptions<TEndpoint, unknown, GetResponse<TEndpoint>>;
  onBackgroundJobCompleted?: () => void;
};

/**
 * Given a background job tag, check if there's ongoing job.
 *
 * Currently we only return `inProgress` plus the query error and loading state.
 *
 * We also return a `refetchQuery` function to immediatly request the state of the background job.
 * Can be useful after triggering any endpoint that starts a background job.
 */
export function useBackgroundJobStatus(
  tag: string,
  {
    reactQueryOptions = {},
    onBackgroundJobCompleted,
  }: Options<'/api/v1/rivendell/background-jobs/[tag]'> = {}
) {
  const [refetchInterval, setRefetchInterval] = useState(5000);
  const query = useGet('/api/v1/rivendell/background-jobs/[tag]', {
    params: { pathParams: { tag } },
    options: {
      // Polling each 5 seconds always
      refetchInterval,
      // DO NOT CACHE THIS QUERY NEVER SO WE ALWAYS GET THE REAL BG JOB STATUS
      cacheTime: 0,
      // Do not retry as well
      retry: false,
      // Refetch on windows focus, like opening up the tab after alt-tabbing
      refetchOnWindowFocus: true,
      // Default options can always be overriden
      ...reactQueryOptions,
    },
  });
  const bgJobState = query.data?.data?.state;
  const previousBgJobState = usePrevious(bgJobState);
  /**
     The query is considered enabled if the options lack the property `enabled`
     By default `reactQueryOptions.enabled` is considered true! But we also want
     to support `undefined` and `false` if they are passed manually through the options
   */
  const isQueryDisabled =
    Object.hasOwn(reactQueryOptions || {}, 'enabled') && !reactQueryOptions.enabled;

  useEffect(() => {
    // Only run the callback if it goes from not-completed to completed
    if (
      bgJobState === BACKGROUND_JOB_STATES.COMPLETED &&
      previousBgJobState !== BACKGROUND_JOB_STATES.COMPLETED &&
      onBackgroundJobCompleted
    ) {
      onBackgroundJobCompleted();
    }
  }, [bgJobState, onBackgroundJobCompleted, previousBgJobState]);

  // If there's an error or the job is done, stop the polling
  useEffect(() => {
    if (query.error || isJobStateDone(bgJobState)) setRefetchInterval(0);
  }, [bgJobState, query.error]);

  return {
    isQueryLoading: query.isLoading,
    isQueryError: query.isError,
    queryError: query.error,
    refetchQuery: () => {
      setRefetchInterval(5000);
      query.refetch();
    },
    inProgress: !isQueryDisabled && !query.error && !isJobStateDone(bgJobState) && !query.isLoading,
    jobState: bgJobState,
    query,
  };
}

// A job with multiple tags is done if it only has one key and it's completed
function isMultiJobStateCompleted(set: Set<ValueOf<typeof BACKGROUND_JOB_STATES>> = new Set()) {
  return set.size === 1 && set.has(BACKGROUND_JOB_STATES.COMPLETED);
}

// A job with multiple tags is not done while some of them are available, executing, retryable, schedulued
function isMultiJobStateDone(set?: Set<ValueOf<typeof BACKGROUND_JOB_STATES>>) {
  // If there's no set, it means the query didn't even fire, so return false
  if (!set) return false;

  return (
    !set.has(BACKGROUND_JOB_STATES.AVAILABLE) &&
    !set.has(BACKGROUND_JOB_STATES.EXECUTING) &&
    !set.has(BACKGROUND_JOB_STATES.RETRYABLE) &&
    !set.has(BACKGROUND_JOB_STATES.SCHEDULED)
  );
}

/**
 *
 * Same as the above hook but calls the endpoint that returns aggregated results.
 *
 * Multiple workers can have the same tag and this endpoints returns an array of unique results.
 */
export function useMultiBackgroundJobStatus(
  tag: string,
  {
    reactQueryOptions = {},
    onBackgroundJobCompleted,
  }: Options<'/api/v1/rivendell/background-jobs-status/[tag]'> = {}
) {
  const [refetchInterval, setRefetchInterval] = useState(5000);
  const query = useGet('/api/v1/rivendell/background-jobs-status/[tag]', {
    params: { pathParams: { tag } },
    options: {
      // Polling each 5 seconds always
      refetchInterval,
      // DO NOT CACHE THIS QUERY NEVER SO WE ALWAYS GET THE REAL BG JOB STATUS
      cacheTime: 0,
      // Do not retry as well
      retry: false,
      // Refetch on windows focus, like opening up the tab after alt-tabbing
      refetchOnWindowFocus: true,
      // Default options can always be overriden
      ...reactQueryOptions,
    },
  });
  // Make sure we only initialize a `set` if the dependencies change
  const bgJobStatuses = useDeepMemo(
    () => (query.data?.data?.status ? new Set(query.data?.data?.status) : undefined),
    [query.data?.data?.status]
  );
  const previousBgJobStatuses = usePrevious(bgJobStatuses);
  /**
     The query is considered enabled if the options lack the property `enabled`
     By default `reactQueryOptions.enabled` is considered true! But we also want
     to support `undefined` and `false` if they are passed manually through the options
   */
  const isQueryDisabled =
    Object.hasOwn(reactQueryOptions || {}, 'enabled') && !reactQueryOptions.enabled;

  useEffect(() => {
    // Only run the callback if it goes from not-completed to completed
    if (
      isMultiJobStateCompleted(bgJobStatuses) &&
      !isMultiJobStateCompleted(previousBgJobStatuses) &&
      onBackgroundJobCompleted
    ) {
      onBackgroundJobCompleted();
    }
  }, [bgJobStatuses, onBackgroundJobCompleted, previousBgJobStatuses]);

  // If there's an error or the job is done, stop the polling
  useEffect(() => {
    if (query.error || isMultiJobStateDone(bgJobStatuses)) setRefetchInterval(0);
  }, [bgJobStatuses, query.error]);

  return {
    isQueryLoading: query.isLoading,
    isQueryError: query.isError,
    queryError: query.error,
    refetchQuery: () => {
      setRefetchInterval(5000);
      query.refetch();
    },
    inProgress:
      !isQueryDisabled && !query.error && !isMultiJobStateDone(bgJobStatuses) && !query.isLoading,
    jobsStatuses: bgJobStatuses,
    query,
  };
}
