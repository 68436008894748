import type { FeedbackMessageVariant } from '@remote-com/norma';
import { Text, HTMLRendered, FeedbackMessage } from '@remote-com/norma';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { InputSpacer as InputSpacerV2 } from '@/src/components/Ui/Form/InputSpacer';

type Props = {
  name: string;
  forcedValue: string | number | boolean;
  title: string;
  description?: string;
  severity?: FeedbackMessageVariant;
  isHiddenField: boolean;
};
export function FieldWithForcedValueAsSentence({
  name,
  forcedValue,
  title,
  description,
  severity,
  isHiddenField,
}: Props) {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    // As this field doesn't show an actual input,
    // we must set the value automatically on mount
    // otherwise the user will get stuck with a wrong value in the form state.
    setFieldValue(name, forcedValue);
  }, [setFieldValue, name, forcedValue]);

  // Only manages Formik's field value without visually displaying anything
  if (isHiddenField) {
    return null;
  }

  return severity ? (
    <InputSpacerV2 type="text" hidden={false}>
      <FeedbackMessage variant={severity} title={title} mb={0}>
        <HTMLRendered Tag={Text}>{description}</HTMLRendered>
      </FeedbackMessage>
    </InputSpacerV2>
  ) : (
    <InputSpacerV2 type="forcedValue" hidden={false}>
      <HTMLRendered Tag={Text}>{title}</HTMLRendered>
      {description && (
        <HTMLRendered Tag={Text} variant="xs" color="grey.600">
          {description}
        </HTMLRendered>
      )}
    </InputSpacerV2>
  );
}
