import pick from 'lodash/pick';
import getConfig from 'next/config';

import {
  requestTypes,
  requestTypesLabels,
  requestTypePerUser,
} from '@/src/domains/employment/requestsHub/shared/constants';
import { generateSelectOptions } from '@/src/helpers/forms';

const {
  publicRuntimeConfig: { ZENDESK_URL },
} = getConfig();

/**
 * ! DO NOT RENAME THE TABS VALUES
 * They're used by Tiger to redirect users to the specific tab
 * If they need renaming, a Tiger MR will be necessary
 */

export const EMPLOYER_REQUESTS_TABS = {
  EMPLOYER: {
    value: 'your-requests',
    label: 'Your requests',
    params: { requestType: requestTypePerUser.employer },
    getTotal: (totals) => totals?.submittedByCompanyAdmin,
    emptyStateDescription: 'When you submit document or change requests, they appear here.',
  },
  EMPLOYEE: {
    value: 'employee-requests',
    label: 'Employee requests',
    params: {
      // Employers can only view 'resignation' type requests from their EEs
      requestType: [requestTypes.RESIGNATION],
      omitIfNullExternalId: true,
    },
    getTotal: (totals) => totals?.submittedByEmployment,
    emptyStateDescription: 'When your team submits document or change requests, they appear here.',
  },
};

export const ADMIN_REQUESTS_TABS = {
  EMPLOYER: {
    value: 'customer-requests',
    label: 'Customer requests',
    params: { requestType: requestTypePerUser.employer },
    getTotal: (totals) => totals?.submittedByCompanyAdmin,
  },
  EMPLOYEE: {
    value: 'employee-requests',
    label: 'Employee requests',
    params: { requestType: requestTypePerUser.employee },
    getTotal: (totals) => totals?.submittedByEmployment,
  },
};

export const requestCategories = {
  OFFBOARDING: 'OFFBOARDING',
  EMPLOYMENT: 'EMPLOYMENT',
  TIMEOFF: 'TIMEOFF',
  MOBILITY: 'MOBILITY',
};

export const requestCategoriesLabels = {
  [requestCategories.OFFBOARDING]: 'Offboarding',
  [requestCategories.EMPLOYMENT]: 'Employment',
  [requestCategories.TIMEOFF]: 'Time Off',
  [requestCategories.MOBILITY]: 'Mobility',
};

export const requestCategoryPerType = {
  [requestTypes.AMENDMENT]: requestCategories.EMPLOYMENT,
  [requestTypes.TERMINATION]: requestCategories.OFFBOARDING,
  [requestTypes.RENEWAL]: requestCategories.EMPLOYMENT,
  [requestTypes.EXTENSION]: requestCategories.EMPLOYMENT,
  [requestTypes.RESIGNATION]: requestCategories.OFFBOARDING,
  [requestTypes.VERIFICATION_LETTER]: requestCategories.EMPLOYMENT,
  [requestTypes.PROBATION_EXTENSION]: requestCategories.EMPLOYMENT,
  [requestTypes.PROBATION_PASSED_LETTER]: requestCategories.EMPLOYMENT,
  [requestTypes.PUBLIC_HOLIDAY]: requestCategories.TIMEOFF,
  [requestTypes.ANNUAL_LEAVE]: requestCategories.TIMEOFF,
  [requestTypes.SPECIAL_LEAVE]: requestCategories.TIMEOFF,
  [requestTypes.FAMILY_LEAVE]: requestCategories.TIMEOFF,
  [requestTypes.SICK_LEAVE]: requestCategories.TIMEOFF,
  [requestTypes.TIMESHEETS]: requestCategories.TIMEOFF,
  [requestTypes.TRAVEL]: requestCategories.MOBILITY,
  [requestTypes.REMOTE_WORK]: requestCategories.MOBILITY,
  [requestTypes.MOBILITY_FOLLOW_UP]: requestCategories.MOBILITY,
};

export const getRequestCategoryPerTypeLabel = (requestType) => {
  return requestCategoriesLabels[requestCategoryPerType[requestType]];
};

export const requestColorPerCategory = {
  [requestCategories.OFFBOARDING]: 'fuchsia',
  [requestCategories.EMPLOYMENT]: 'radiance',
  [requestCategories.TIMEOFF]: 'turquoise',
  [requestCategories.MOBILITY]: 'purple',
};

export const requestStatus = {
  SUBMITTED: 'submitted',
  IN_REVIEW: 'in_review',
  DONE: 'done',
  CANCELED: 'canceled',
};

export const requestStatusLabel = {
  [requestStatus.SUBMITTED]: 'Submitted',
  [requestStatus.IN_REVIEW]: 'Reviewing',
  [requestStatus.DONE]: 'Done',
  [requestStatus.CANCELED]: 'Canceled',
};

export const requestStatusBadge = {
  [requestStatus.SUBMITTED]: 'pending',
  [requestStatus.IN_REVIEW]: 'info',
  [requestStatus.DONE]: 'active',
  [requestStatus.CANCELED]: 'inactive',
};

export const requestConfidentialityTypes = {
  CONFIDENTIAL: 'confidential',
  NON_CONFIDENTIAL: 'non_confidential',
};

export const requestConfidentialityLabels = {
  [requestConfidentialityTypes.CONFIDENTIAL]: 'Confidential',
  [requestConfidentialityTypes.NON_CONFIDENTIAL]: 'Non-confidential',
};

const requestExternalTypes = {
  ZENDESK: 'zendesk',
};

export const requestExternalTypesURL = {
  [requestExternalTypes.ZENDESK]: (externalId) => `${ZENDESK_URL}/hc/requests/${externalId}`,
};

export const requestStatusOptions = generateSelectOptions(requestStatusLabel, true);

export const employerRequestTypeOptions = generateSelectOptions(
  pick(requestTypesLabels, requestTypePerUser.employer),
  true
);
export const employeeRequestTypeOptions = generateSelectOptions(
  pick(requestTypesLabels, requestTypePerUser.employee),
  true
);

export const nonZendeskRequestTypeOptions = generateSelectOptions(
  pick(requestTypesLabels, [requestTypes.REMOTE_WORK]),
  true
);

export const requestsTableColumnAccessorExclusionSet = new Set([
  'employment.contract.country.code',
  'employment.contract.country.name',
  'employment.contract.country.slug',
  'employment.contract.slug',
  'employment.company.name',
  'employment.company.slug',
  'employment.user.slug',
  'employment.user.name',
  'employment.user.profilePicture',
  'employment.slug',
  'externalType',
  'file',
  'submittedByEmployment',
  'submittedByEmployment.slug',
  'submittedByEmployment.user.name',
  'submittedByEmployment.user.slug',
  'submittedByEmployment.user.profilePicture',
  'submittedByTeamMember',
  'submittedByTeamMember.slug',
  'submittedByTeamMember.user.name',
  'submittedByTeamMember.user.slug',
  'submittedByTeamMember.user.profilePicture',
  'submittedByCompanyAdmin',
  'submittedByCompanyAdmin.slug',
  'submittedByCompanyAdmin.user.name',
  'submittedByCompanyAdmin.user.slug',
  'submittedByCompanyAdmin.user.profilePicture',
]);

export const pillAttributes = {
  OFFBOARDING: {
    pill: requestColorPerCategory[requestCategories.OFFBOARDING],
    pillText: requestCategoriesLabels[requestCategories.OFFBOARDING],
  },
  EMPLOYMENT: {
    pill: requestColorPerCategory[requestCategories.EMPLOYMENT],
    pillText: requestCategoriesLabels[requestCategories.EMPLOYMENT],
  },
  TIMEOFF: {
    pill: requestColorPerCategory[requestCategories.TIMEOFF],
    pillText: requestCategoriesLabels[requestCategories.TIMEOFF],
  },
  MOBILITY: {
    pill: requestColorPerCategory[requestCategories.MOBILITY],
    pillText: requestCategoriesLabels[requestCategories.MOBILITY],
  },
};
