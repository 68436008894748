import React, { memo, useRef, useLayoutEffect } from 'react';
import type { ChangeEvent, ClipboardEvent, KeyboardEvent, ReactNode } from 'react';
import styled from 'styled-components';

import usePrevious from '../../hooks/usePrevious';
import { FormGroup, Input, useFormGroupContext } from '../form-group';
import type { FormGroupProps } from '../form-group';

const StyledFieldInput = styled(Input).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})`
  padding: ${({ theme: { space } }) => `${space[1]}px 0`};
  width: 12px;
  height: 100%;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 24px;
    height: 36px;
  }
`;

function InputElement() {
  const inputProps = useFormGroupContext();
  return <StyledFieldInput {...inputProps} />;
}

type SingleInputOTPComponentProps = {
  autoComplete?: string;
  autoFocus?: boolean;
  focus?: boolean;
  errorText?: ReactNode;
  label?: string;
  onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
  type?: string;
  value?: string;
} & Omit<FormGroupProps, ' placeholder' | 'errorText' | 'children'>;

const SingleInputOTPComponent = ({
  autoFocus,
  focus,
  errorText,
  label,
  name,
  value,
  ...props
}: SingleInputOTPComponentProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <FormGroup
      label=""
      name={name}
      ref={inputRef}
      value={value}
      displayErrorMessage={false}
      errorText={errorText}
      {...props}
      hideLabel
    >
      <InputElement />
    </FormGroup>
  );
};

SingleInputOTPComponent.defaultProps = {
  value: '',
};

export const SingleInput = memo(SingleInputOTPComponent);
