import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import type { StackProps } from '../../layout';
import { Box, Stack } from '../../layout';

import { DEFAULT_SIZE, tones, marginDensity } from './constants';
import { getListSize } from './helpers';

export type ListDiscSize = 'sm' | 'md' | 'lg';
type ListDiscTone = 'primary' | 'secondary';
type ListDiscGap = 'sm' | 'md' | 'lg' | 'none';

const dotSizeInEm: Record<ListDiscSize, number> = {
  sm: 0.4,
  md: 0.6, // md size is bigger to align the dot with 1st line of text
  lg: 0.5,
};

export const ListStyle = css<{
  $discTone: ListDiscTone;
  $size: ListDiscSize;
}>`
  display: flex;
  flex-direction: column;
  ${getListSize};
  padding: 0;
  margin: ${({ theme }) => theme.space[4]}px 0;
  width: 100%;
  position: relative;
  list-style-type: none;
  /* This offsets the absolute positioning for the ListDisc bullet so we don't have spacing issues */
  margin-left: 14px;

  > li {
    &::before {
      content: '';
      flex-direction: row;
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      border-radius: ${({ theme }) => theme.borderRadius.full};
      /* NOTE: Why are CSS variables used? Know at https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/8436 */
      background-color: var(
        --ListDisc-bullet-bg-color,
        ${({ theme, $discTone }) => tones(theme.colors)[$discTone]}
      );
      margin-top: var(--ListDisc-bullet-mt, ${({ $size = DEFAULT_SIZE }) => dotSizeInEm[$size]}em);
      position: absolute;
      left: -14px;
    }

    > span {
      width: 85%;

      ul {
        margin-bottom: 0;
        /* The parent list has a horizontal margin of 12px, the space for nested lists on the design is 40px, removing
        12px from 40px gives 28px
        */
        padding-left: 28px;
        margin-left: 0px;

        li {
          &::before {
            background-color: ${({ theme }) => theme.colors.blank};
            border: 1px solid
              var(
                --ListDisc-bullet-bg-color,
                ${({ theme, $discTone }) => {
                  return tones(theme.colors)[$discTone];
                }}
              );
            left: 14px;
          }
        }
      }
    }
  }
`;

const ListDiscStyle = styled(Stack)`
  ${ListStyle}
`;

export interface ListDiscProps extends StackProps {
  /** Controls the size of each list item */
  size?: ListDiscSize;
  /** Controls the space between each list item */
  gap?: ListDiscGap;
  /** The color of each bullet */
  discTone?: ListDiscTone;
  /** The color of the text */
  color?: 'grey.500' | 'grey.600';
  children: ReactNode;
}

function validateListDiscProps({ size, gap }: { size: ListDiscSize; gap: ListDiscGap }) {
  if (size === 'sm' && ['md', 'lg'].includes(gap)) {
    return new Error(`Incompatible combination: gap: ${gap} does not work with size: sm`);
  }
}

export const ListDisc = ({
  gap = 'sm',
  discTone = 'primary',
  size = DEFAULT_SIZE,
  ...props
}: ListDiscProps) => {
  validateListDiscProps({ size, gap });
  return (
    <ListDiscStyle
      forwardedAs="ul"
      p={0}
      m={5}
      role="list"
      gap={gap === 'none' ? undefined : marginDensity[gap]}
      $discTone={discTone}
      $size={size}
      {...props}
    />
  );
};

const Nest = ({ children }: { children: ReactNode }) => {
  return <Box as="span">{children}</Box>;
};

ListDisc.Nest = Nest;

ListDisc.displayName = 'ListDisc';
Nest.displayName = 'ListDisc.Nest';
