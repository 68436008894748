// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneCoffee = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M7.85 1.25c-1.96 0-2.94 0-3.69.38a3.5 3.5 0 0 0-1.53 1.53c-.38.75-.38 1.73-.38 3.69v3.9c0 2.8 0 4.2.54 5.27a5 5 0 0 0 2.19 2.18c1.07.55 2.47.55 5.27.55h1.5c2.8 0 4.2 0 5.27-.55a5 5 0 0 0 2.18-2.18c.55-1.07.55-2.47.55-5.27v-3.9c0-1.96 0-2.94-.38-3.69a3.5 3.5 0 0 0-1.53-1.53c-.75-.38-1.73-.38-3.69-.38h-6.3Z" /><path fillRule="evenodd" clipRule="evenodd" d="M18.75 6c0-.41.34-.75.75-.75h.5A2.75 2.75 0 0 1 22.75 8v3A2.75 2.75 0 0 1 20 13.75h-.5a.75.75 0 0 1 0-1.5h.5c.69 0 1.25-.56 1.25-1.25V8c0-.69-.56-1.25-1.25-1.25h-.5a.75.75 0 0 1-.75-.75ZM1.25 22c0-.41.34-.75.75-.75h18a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneCoffee);
export { ForwardRef as IconV2DuotoneCoffee };