import type { Event } from '@remote-com/analytics';
import type { ButtonProps } from '@remote-com/norma';

import { BookitScript, useBookit } from '@/src/domains/marketing/bookit';
import { NoWrapButton } from '@/src/domains/userPrompts/widgets/common.styled';

import type { InstanceConfig } from './types';

export type BookACallButtonProps = {
  url: string;
  clickEvent?: Event;
  instanceConfig: InstanceConfig;
  children: React.ReactNode;
};

export const BookACallButton = ({
  url,
  clickEvent,
  instanceConfig,
  children,
  ...props
}: BookACallButtonProps & ButtonProps) => {
  const { bookDemo, onLoad: initializeBookit } = useBookit(instanceConfig);

  const handleClick = () => {
    bookDemo(url, clickEvent);
  };

  return (
    <>
      <NoWrapButton type="button" onClick={handleClick} {...props}>
        {children}
      </NoWrapButton>

      <BookitScript onLoad={initializeBookit} />
    </>
  );
};
