import { object as yupObject } from 'yup';

import { isLunchWithinWorkingHours } from '@/src/components/Ui/Form/WorkWeekScheduleTableField/utils/isLunchWithinWorkingHours';

function buildTimeValidationSchema(name) {
  return yupObject().test({
    name: `${name}-required`,
    message: 'Invalid',
    test(val) {
      return val.parsed !== null;
    },
  });
}

export function getValidationSchema(displayLunchTime) {
  if (!displayLunchTime) {
    return yupObject().shape({
      startTime: buildTimeValidationSchema('start-time'),
      endTime: buildTimeValidationSchema('end-time'),
    });
  }

  return yupObject()
    .shape({
      startTime: buildTimeValidationSchema('start-time'),
      endTime: buildTimeValidationSchema('end-time'),
      lunchStartTime: buildTimeValidationSchema('lunch-start-time'),
      lunchEndTime: buildTimeValidationSchema('lunch-end-time'),
    })
    .test({
      name: 'lunch-hours-outside-working-hours',
      message: 'Lunch hours are outside of working hours',
      test(values) {
        const { startTime, endTime, lunchStartTime, lunchEndTime } = values;

        if (
          !startTime?.parsed ||
          !endTime?.parsed ||
          !lunchStartTime?.parsed ||
          !lunchEndTime?.parsed
        ) {
          return true;
        }

        return isLunchWithinWorkingHours(
          startTime.parsed,
          endTime.parsed,
          lunchStartTime.parsed,
          lunchEndTime.parsed
        );
      },
    });
}
