import { createContext, useContext } from 'react';

import { useSaveFilter } from '@/src/components/Table/hooks/useSaveFilter';

const SaveFilterContext = createContext();

export const useSaveFilterContext = () => useContext(SaveFilterContext);

export const SaveFilterContextProvider = ({
  enabled,
  tableId,
  trackOnSaveFilter,
  setLoadedSavedFilter,
  showFilterManager,
  setShowFilterManager,
  children,
}) => {
  const [savedFilters, addFilter, removeFilter, updateFilter] = useSaveFilter(tableId);

  return (
    <SaveFilterContext.Provider
      value={{
        enabled,
        savedFilters,
        addFilter,
        removeFilter,
        updateFilter,
        trackOnSaveFilter,
        setLoadedSavedFilter,
        tableId,
        showFilterManager,
        setShowFilterManager,
      }}
    >
      {children}
    </SaveFilterContext.Provider>
  );
};
