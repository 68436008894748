export const KEN_WISE_BANK_CODES = [
  {
    value: '01',
    label: 'Kenya Commercial Bank',
  },
  {
    value: '03',
    label: 'ABSA Bank Kenya PLC',
  },
  {
    value: '05',
    label: 'Bank of India',
  },
  {
    value: '06',
    label: 'Bank of Baroda Kenya',
  },
  {
    value: '07',
    label: 'NCBA Bank Kenya PLC',
  },
  {
    value: '08',
    label: 'Habib Bank',
  },
  {
    value: '10',
    label: 'Prime Bank',
  },
  {
    value: '11',
    label: 'The Cooperative Bank of Kenya',
  },
  {
    value: '12',
    label: 'National Bank of Kenya',
  },
  {
    value: '14',
    label: 'M-Oriental Bank',
  },
  {
    value: '16',
    label: 'Citibank',
  },
  {
    value: '17',
    label: 'Habib Bank A.G. Zurich',
  },
  {
    value: '18',
    label: 'Middle East Bank Kenya',
  },
  {
    value: '19',
    label: 'Bank of Africa Kenya',
  },
  {
    value: '23',
    label: 'Consolidated Bank of Kenya',
  },
  {
    value: '25',
    label: 'Credit Bank',
  },
  {
    value: '26',
    label: 'Transnational Bank',
  },
  {
    value: '30',
    label: 'SBM Bank (Kenya) Limited',
  },
  {
    value: '31',
    label: 'Stanbic Bank Kenya',
  },
  {
    value: '35',
    label: 'African Banking Corporation Ltd',
  },
  {
    value: '39',
    label: 'Imperial Bank Limited',
  },
  {
    value: '42',
    label: 'Giro Commercial Bank',
  },
  {
    value: '43',
    label: 'Ecobank Kenya',
  },
  {
    value: '49',
    label: 'Spire Bank',
  },
  {
    value: '50',
    label: 'Paramount Universal Bank',
  },
  {
    value: '51',
    label: 'Jamii Bora Bank',
  },
  {
    value: '53',
    label: 'Guaranty Trust Bank Kenya',
  },
  {
    value: '54',
    label: 'Victoria Commercial Bank',
  },
  {
    value: '55',
    label: 'Guardian Bank',
  },
  {
    value: '57',
    label: 'I & M Bank Limited',
  },
  {
    value: '59',
    label: 'Development Bank of Kenya',
  },
  {
    value: '61',
    label: 'Housing Finance Bank of Kenya',
  },
  {
    value: '63',
    label: 'Diamond Trust Bank Kenya',
  },
  {
    value: '65',
    label: 'Mayfair Bank Limited',
  },
  {
    value: '66',
    label: 'Sidian Bank',
  },
  {
    value: '68',
    label: 'Equity Bank',
  },
  {
    value: '70',
    label: 'Family Bank',
  },
  {
    value: '72',
    label: 'Gulf African Bank',
  },
  {
    value: '74',
    label: 'First Community Bank',
  },
  {
    value: '76',
    label: 'UBA Kenya Bank',
  },
  {
    value: '78',
    label: 'Kenya Women Microfinance Bank (KWFT)',
  },
  {
    value: 'BARCKENX',
    label: 'BARCLAYS BANK OF KENYA, LTD.',
  },
  {
    value: 'BARCKENXXXX',
    label: 'BARCLAYS BANK OF KENYA, LTD.',
  },
  {
    value: 'CIFIKENA',
    label: 'CITY FINANCE BANK',
  },
  {
    value: 'CITIKENA',
    label: 'CITIBANK N.A. NAIROBI',
  },
  {
    value: 'ECOCKENA',
    label: 'ECOBANK KENYA LTD',
  },
  {
    value: 'SCBLKENXXXX',
    label: 'STANDARD CHARTERED BANK KENYA LIMITED',
  },
];
