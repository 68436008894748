import { IconAlertTriangle } from '@remote-com/norma/icons/IconAlertTriangle';
import { IconTimesheet } from '@remote-com/norma/icons/IconTimesheet';
import styled, { css } from 'styled-components';

const iconStyling = css`
  position: absolute;
  top: 0;
  right: 4px;
  bottom: 0;
  width: 13px;
  height: 100%;
`;

export const StyledIconAlertTriangle = styled(IconAlertTriangle)`
  ${iconStyling}
`;

export const StyledIconTimesheet = styled(IconTimesheet)`
  ${iconStyling}
`;

export const getTooltipStyle = (unavailableIsError) => ({
  wrapper: css`
    color: ${({ theme }) =>
      unavailableIsError ? theme.colors.redPink : theme.colors.attention.foreground};
  `,
});

export const LabelContainer = styled.span`
  display: inline-flex;
  border-radius: 4px;
  user-select: none;
  ${({ theme }) => theme.typography.xsSemiBold};
`;

export const LabelTextWrapper = styled.span`
  /* Maintain whitespace, so that it's possible to render user-formatted bullet-points and line-breaks.
   *
   * Note that this style was originally part of LabelContainer, but moved because ProseMirror's own
   * styles override the white-space property on contenteditable=false elements. */
  white-space: pre-wrap;
`;

export const SmartFieldNodeViewWrapperStyled = styled.span`
  padding: 0;
  /**
   * Use 'inline-flex' when displayed to maintain vertical alignment with
   * the surrounding text and manually-entered fields.
   */
  display: ${({ isDisplayed }) => (isDisplayed ? 'none' : 'inline-flex')};

  ${({ isEditable }) =>
    isEditable &&
    css`
      &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 1px
          ${({ theme, isUnavailable }) =>
            isUnavailable ? theme.colors.yellow[900] : theme.colors.purple[700]};
      }
    `}
`;
