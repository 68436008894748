import { transparentize } from 'polished';
import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';

import { Text } from '../../core/text/Text';
import type { TextProps } from '../../core/text/Text';
import { FieldError } from '../../core/text/Text.styled';
import { Box } from '../../foundations/layout/Box';
import { Stack } from '../../foundations/layout/Stack';

import type { FIELDSET_VARIANTS } from './constants';

export const StyledArrayFieldset = styled.fieldset`
  width: calc(100% + 64px + 4px);
  right: calc(-1 * (((100% - 64px - 4px) - 100%) / 2));
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.grey[300]};
  margin: 0;
  padding: 40px 32px 32px;
  position: relative;
  margin-top: 44px;
`;

type StyledFieldsetLegendTextProps = {
  hasError?: boolean;
  variant?: TextProps['variant'];
  color?: TextProps['color'];
  $hidden?: boolean;
};

export const StyledFieldsetLegendText = styled(Text).attrs<StyledFieldsetLegendTextProps>(
  ({ hasError, variant = 'baseMedium', color = 'inputGroupLabel' }) => {
    return {
      forwardedAs: 'legend',
      variant,
      color: hasError ? 'error' : color,
    };
  }
)<StyledFieldsetLegendTextProps>`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
`;

export const FieldsetStyled = styled(Box).attrs({
  as: 'fieldset',
  pl: 9,
  pr: 9,
  pb: 8,
  pt: 8,
  bg: 'blank',
})`
  border: none;
  border-radius: 16px;
  margin: 0;

  & + & {
    margin-top: ${({ theme }) => `${theme.space[8]}px`};
  }

  /* We must _not_ use return here, as it breaks ts-styled-plugin
  https://github.com/styled-components/vscode-styled-components/issues/371 */
  @media screen and (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: ${({ theme }) => `${theme.space[7]}px ${theme.space[6]}px`};
  }
`;

const descriptionSpacing = (theme: DefaultTheme) =>
  css`
    margin-top: -${theme.space[6]}px;

    + * {
      margin-top: -${theme.space[4]}px;
    }
  `;

export const StyledFieldsetDescription = styled(Text).attrs({
  variant: 'sm',
  color: 'inputGroupDescription',
  mb: 7,
  px: 5,
})`
  ${({ theme }) => descriptionSpacing(theme)}
`;

export const StyledFieldsetLegendBox = styled(Box).attrs({
  as: 'legend',
  mx: 3,

  my: 0,
})`
  padding: 0 8px;

  + {
    /*
      NOTE: There's some magic happening with the positioning of the native
      legend element within a fieldset. These magic numbers fix the extra
      white space that is being created at the top.
    */

    ${StyledFieldsetDescription} {
      ${({ theme }) => descriptionSpacing(theme)}
    }

    * {
      margin-top: -13px;
    }

    ${FieldError} {
      margin-bottom: ${({ theme }) => `${theme.space[7]}px`};
      margin-top: ${({ theme }) => `-${theme.space[6]}px`};
    }
  }
`;

export const StyledFieldsetStack = styled(Stack)``;

type WrapperProps = {
  hasError?: boolean;
  variant?: (typeof FIELDSET_VARIANTS)[keyof typeof FIELDSET_VARIANTS];
};

export const StyledFieldsetWrapper = styled.fieldset<WrapperProps>`
  width: calc(100% + 64px + 4px);
  right: calc(-1 * (((100% - 64px - 4px) - 100%) / 2));
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.grey[300]};
  margin: 0;
  position: relative;
  padding: 32px 32px 40px 32px;

  ${({ hasError, theme }) =>
    hasError
      ? css`
          border-color: ${theme.colors.error};
        `
      : null}

  & &,
  ${StyledArrayFieldset} & {
    width: initial;
    right: initial;
  }

  ${({ variant }) =>
    variant === 'card' &&
    css`
      background: ${({ theme }) => transparentize(0.55, theme.colors.grey[100])};
      border: 0;
      padding: 0;

      ${StyledFieldsetLegendBox} {
        background-color: ${({ theme }) => theme.colors.grey[100]};
        border-radius: 15px 15px 0px 0px;
        margin: 0 0 0;
        padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[6]}px`};
        text-transform: uppercase;
        width: 100%;

        ${StyledFieldsetLegendText} {
          ${({ theme }) => theme.typography.xsMedium};
          text-transform: uppercase;
        }
      }

      ${StyledFieldsetDescription} {
        padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[6]}px 0`};
        margin: ${({ theme }) => `0 0 ${theme.space[3]}px 0`};
      }

      > ${StyledFieldsetStack} {
        padding: ${({ theme }) => `${theme.space[6]}px ${theme.space[6]}px ${theme.space[7]}px`};
      }
    `}
`;
