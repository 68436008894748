import React, { useMemo } from 'react';

import { isIosDevice } from '../../utils/helpers';
import { FormGroup, AsideBadge, useFormGroupContext } from '../form-group';
import type { FormGroupProps, FormGroupLabelPlacement } from '../form-group';

import { CurrencyInput } from './InputCurrency.styled';

export interface InputElementProps {
  label: FormGroupProps['label'];
  /* Affects how numbers will be displayed and what inputs are acceptable */
  locale: string;
  /* Callback for when the value changes */
  handleOnValueChange: (value?: string) => void;
  /* Input mode for the currency */
  inputMode: 'text' | 'decimal';
  /* Current inputValue */
  value?: string | number;
}

const InputElement = ({ locale, handleOnValueChange, inputMode, value }: InputElementProps) => {
  const inputProps = useFormGroupContext();
  const {
    name,
    label,
    'data-testid': dataTestid,
    labelPlacement, // NOTE: Destructure labelPlacement but don't pass it down to avoid React warnings about unknown props on DOM elements
    setHasInputFocus, // NOTE: Destructure setHasInputFocus but don't pass it down to avoid React warnings about unknown props on DOM elements
    placeholder,
    ...restProps
  } = inputProps;

  return (
    <>
      <CurrencyInput
        name={name}
        {...restProps}
        data-testid={dataTestid}
        intlConfig={{ locale }}
        placeholder={placeholder}
        onValueChange={handleOnValueChange}
        // NOTE: iOS selects the decimal separator for the `decimal` keyboard based on system language.
        // Since we use `en-US` locale this can prevent users from being able to enter floating-point numbers.
        inputMode={inputMode}
        // NOTE: This component uses `Intl.NumberFormat` under the hood which causes
        // rounding errors for very, very large numbers in the UI.
        maxLength={15}
        lang={locale}
        decimalSeparator="."
        groupSeparator=","
      />
      <input
        data-e2e="currency" // For E2E to get the input value
        data-testid={dataTestid ? `${dataTestid}-value` : undefined}
        readOnly
        type="hidden"
        // NOTE: The input value from `react-currency-input-field` will be formatted
        // but in most (all) cases we only care about the unformatted value. 💁
        name={`${name}-unformatted-value`}
        value={value}
      />
    </>
  );
};

export interface InputCurrencyProps extends Omit<FormGroupProps, 'onChange' | 'children'> {
  /* Currency that will be rendered as an aside. */
  currency?: React.ReactNode;
  /* Affects how numbers will be displayed and what inputs are acceptable */
  locale?: string;
  /* Similar to `onChange` but exposes unformatted value */
  onValueChange: (value: string) => void;
  /* Current inputValue */
  value?: string | number;
  /* Placement of the label, relatively to the input outline */
  labelPlacement?: FormGroupLabelPlacement;
}

export const InputCurrency = ({
  currency,
  locale = 'en-US',
  label,
  onValueChange,
  value,
  ...props
}: InputCurrencyProps) => {
  const inputMode = useMemo(() => (isIosDevice() ? 'text' : 'decimal'), []);

  const handleOnValueChange = (unformattedValue?: string) => {
    onValueChange(unformattedValue ?? '');
  };

  const formGroupProps: Omit<FormGroupProps, 'children'> = {
    label,
    value,
    ...props,
  };

  return (
    <FormGroup
      {...formGroupProps}
      aside={currency ? (asideProps) => <AsideBadge {...asideProps}>{currency}</AsideBadge> : null}
    >
      <InputElement
        label={label}
        locale={locale}
        handleOnValueChange={handleOnValueChange}
        inputMode={inputMode}
        value={value}
      />
    </FormGroup>
  );
};
