export const ZMB_WISE_BANK_CODES = [
  {
    value: '210000',
    label: 'AB BANK ZAMBIA LIMITED',
  },
  {
    value: '020000',
    label: 'ABSA Bank Zambia',
  },
  {
    value: '350000',
    label: 'ACCSOUTH SUDAN BANK',
  },
  {
    value: '200000',
    label: 'BANC ABC',
  },
  {
    value: '190000',
    label: 'BANK OF CHINA',
  },
  {
    value: '000000',
    label: 'BANK OF ZAMBIA LUSAKA',
  },
  {
    value: '130000',
    label: 'CAVMONT BANK',
  },
  {
    value: '030000',
    label: 'CITIBANK ZAMBIA',
  },
  {
    value: '360000',
    label: 'ECOBANK',
  },
  {
    value: '110000',
    label: 'FINANCEBANK',
  },
  {
    value: '340000',
    label: 'FIRST ALLIANCE BANK',
  },
  {
    value: '280000',
    label: 'FIRST CAPITAL BANK',
  },
  {
    value: '260000',
    label: 'FIRST NATIONAL BANK',
  },
  {
    value: '090000',
    label: 'INDO ZAMBIA BANK',
  },
  {
    value: '150000',
    label: 'INTERMARKET BANKING CORPORATION',
  },
  {
    value: '170000',
    label: 'INVESTRUST',
  },
  {
    value: '040000',
    label: 'STANBIC BANK',
  },
  {
    value: '060000',
    label: 'STANDARD CHARTERED BANK Zambia',
  },
  {
    value: '370000',
    label: 'UNITED BANK OF AFRICA',
  },
  {
    value: '180000',
    label: 'UNITED BANK OF AFRICA',
  },
  {
    value: '010000',
    label: 'ZAMBIA NATIONAL COMMERCIAL BANK',
  },
];
