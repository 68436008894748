import { useRouter } from 'next/router';
import { useEffect } from 'react';

type HookProps = {
  shouldSave: boolean;
  cancelPendingOperations: () => void;
  saveChanges: () => void;
};

/**
 * This hook handles the scenario where the user attempts to navigate away from the page.
 * Its purpose is to ensure that any unsaved changes in the editor are saved before the navigation
 */
export function useSaveBeforeNavigation({
  saveChanges,
  cancelPendingOperations,
  shouldSave,
}: HookProps) {
  const router = useRouter();

  useEffect(() => {
    function onRouteChangeStart() {
      /**
       * Cancel any pending auto-saves to prevent race conditions
       */
      cancelPendingOperations();
      /**
       * Save any pending change
       */
      saveChanges();
    }

    if (shouldSave) {
      router.events.on('routeChangeStart', onRouteChangeStart);
    }

    return () => {
      if (shouldSave) {
        router.events.off('routeChangeStart', onRouteChangeStart);
      }
    };
  }, [cancelPendingOperations, router.events, saveChanges, shouldSave]);
}
