// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneLayers = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} fillRule="evenodd" clipRule="evenodd" d="M1.33 12.66a.75.75 0 0 1 1-.33l5.71 2.85c1.62.8 2.3 1.14 3 1.27.63.12 1.29.12 1.93 0 .7-.13 1.37-.46 2.99-1.27l5.7-2.85a.75.75 0 1 1 .68 1.34l-5.71 2.85-.11.06c-1.47.73-2.35 1.17-3.28 1.35-.82.15-1.66.15-2.48 0-.93-.18-1.8-.62-3.28-1.35l-.1-.06-5.72-2.85a.75.75 0 0 1-.33-1Zm0 5a.75.75 0 0 1 1-.33l5.71 2.85c1.62.8 2.3 1.14 3 1.27.63.12 1.29.12 1.93 0 .7-.13 1.37-.46 2.99-1.27l5.7-2.85a.75.75 0 1 1 .68 1.34l-5.71 2.85-.11.06c-1.47.73-2.35 1.17-3.28 1.35-.82.15-1.66.15-2.48 0-.93-.18-1.8-.62-3.28-1.35l-.1-.06-5.72-2.85a.75.75 0 0 1-.33-1Z" /><path d="M9.14 1.93c1.05-.52 1.57-.79 2.12-.89a4 4 0 0 1 1.48 0c.55.1 1.07.37 2.12.9l4.42 2.2c1.84.92 2.76 1.38 3.05 2a2 2 0 0 1 0 1.72c-.3.62-1.21 1.08-3.05 2l-4.42 2.2c-1.05.53-1.57.8-2.12.9a4 4 0 0 1-1.48 0c-.55-.1-1.07-.37-2.12-.9l-4.42-2.2c-1.84-.92-2.76-1.38-3.05-2a2 2 0 0 1 0-1.72c.3-.62 1.21-1.08 3.05-2l4.42-2.2Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneLayers);
export { ForwardRef as IconV2DuotoneLayers };