import { Box } from '@remote-com/norma';
import { IconV2DuotoneCheckSquare } from '@remote-com/norma/icons/IconV2DuotoneCheckSquare';

import { CardStatusMessage } from '@/src/components/Cards';

export const TodosOverlayEmptyState = () => {
  return (
    <Box px={10} py={9}>
      <CardStatusMessage
        icon={IconV2DuotoneCheckSquare}
        title="You’re all caught up"
        description="When an important task needs your attention, it appears here."
      />
    </Box>
  );
};
