/**
 * Generate filter table from date ranger picker component
 *
 * @param {Array} value
 * @param {string} value.start - Start of range
 * @param {string} value.end - End of range
 */

export const dateRangeFilterLabel = (input) => {
  if (!input || !Array.isArray(input)) return undefined;

  const [start, end] = input;

  if (start === end) return start;
  if (!end) return start;

  return `${start} → ${end}`;
};

const popoverFilterWithValue = ([, value]) => {
  if (Array.isArray(value)) {
    const isMultiSelect = value[0] && Object.prototype.hasOwnProperty.call(value[0], 'value');

    return isMultiSelect ? value.some((v) => !!v.value) : value.some((v) => !!v);
  }

  // Boolean and number are valid filter values, albeit require the non-default filterType. See:
  // - https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/7546
  // - https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/2206
  // - https://github.com/TanStack/react-table/discussions/2257#discussioncomment-34090
  // - https://github.com/TanStack/react-table/blob/bc5e8b879eba47af2a1043586017eed595ed5bd2/src/filterTypes.js#L102
  if (typeof value === 'boolean') return true;
  if (typeof value === 'number') return true;

  return !!value;
};

/**
 * Transform popover filters to table filters by mapping them into an `id` and a
 * `value`. Additionally, we make sure to filter out filters with nullish values
 * in order to avoid requests to the API to filter an attribute by `null`.
 * Input:  { status: [{ value: 'active', value: null }], inserted_at: [null, null] }
 * Output: [{ id: 'status', value: ['active'] }]
 * @param {Object} popoverFilters popover filters
 * @returns {Array} popover filters shaped for usage in a table component
 */
export const popoverFiltersToTableFilters = (popoverFilters) =>
  Object.entries(popoverFilters)
    .filter(popoverFilterWithValue)
    .map(([id, value]) => {
      // multi select
      if (Array.isArray(value) && value[0]?.value) {
        return {
          id,
          value: value.filter((v) => !!v.value).map((v) => v.value),
        };
      }

      return { id, value };
    });
