import { makeApiService } from '@/src/services/ApiClient';
import { VERSIONS } from '@/src/services/defaults';

export const fetchEmployerRequest = makeApiService({
  path: '/employer/employment-requests/[slug]',
});

export const fetchEmployerRequests = makeApiService({
  path: '/employer/employment-requests',
});

export const addEmploymentRequestCC = makeApiService({
  method: 'post',
  path: '/employer/employment-requests/[slug]/cc',
});

export const validateResignationEmploymentRequest = makeApiService({
  method: 'put',
  path: '/employer/resignation-employment-requests/[slug]/validate',
});

export const fetchEmployeeRequests = makeApiService({
  path: '/employee/employment-requests',
});

export const fetchAdminRequests = makeApiService({
  path: '/employment-requests',
  version: VERSIONS.ADMIN_V1,
});
