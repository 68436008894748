import { InputDatePicker } from '@remote-com/norma';
import type { ComponentProps, ReactElement } from 'react';

import ProvisionalStartDateInfoColumn from './ProvisionalStartDate';

type NormaProps = ComponentProps<typeof InputDatePicker>;

type Props = NormaProps & {
  /**
   * Yes, for historical reason, this component actually expects a "props.props" prop.
   * Specifically, it is used inside DynamicForm in a way that the properties are passed
   * via "props" (its parent), "props.props" (its parent props), and "props.field" (form).
   *
   * @deprecated
   */
  props: {
    canSelectWeekends?: boolean;
    isStartDate?: boolean;
  };
};

/**
 * This is a temporarily patched component to support Employ's provisional start date.
 * It was defined while we move the Date Picker to Norma.
 * In the future, we should remove the patch and support this in a more generic way,
 * right in the Norma component.
 *
 * @deprecated
 */
export const DatePicker = (outerProps: Props): ReactElement => {
  const { props: innerProps, ...outerRest } = outerProps;
  const { canSelectWeekends, isStartDate, ...innerRest } = innerProps;

  const startDate = isStartDate ? (
    <ProvisionalStartDateInfoColumn canSelectWeekends={canSelectWeekends} />
  ) : undefined;

  return (
    <InputDatePicker
      {...outerRest}
      props={{
        dangerouslyStartDateChildren: startDate,
        ...innerRest,
      }}
    />
  );
};
