import { IconV2DuotoneBuilding } from '@remote-com/norma/icons/IconV2DuotoneBuilding';
import Image from 'next/image';

import { getFlagSrc } from '@/src/domains/countries/helpers';

type ProfileIconProps = {
  className?: string;
  countryName?: string;
};

export const ProfileIcon = ({ countryName, ...rest }: ProfileIconProps) => {
  const countryFlagSrc = getFlagSrc(countryName);

  if (!countryFlagSrc) {
    return <IconV2DuotoneBuilding height={20} width={20} {...rest} />;
  }

  return <Image alt="" src={countryFlagSrc} height={20} width={20} {...rest} />;
};
