import type { ButtonIconVariant, ButtonProps } from '@remote-com/norma';
import { IconArrowLeft } from '@remote-com/norma/icons/IconArrowLeft';
import type { RequireAtLeastOne } from 'type-fest';

import { GoBackButton } from '@/src/components/Button/GoBackButton';
import { useGoBack } from '@/src/components/Ui/BackButtonAsIcon/useGoBack';

import type { Href } from '../Layout/PageHeader';

interface BaseBackButtonAsIconProps extends ButtonProps {
  isLinkBack?: boolean;
  ariaLabel: string;
  href?: Href | string; // preferred for progressive enhancement
  customGoBack?: () => void;
  variant?: ButtonIconVariant;
}

type BackButtonAsIconProps = RequireAtLeastOne<BaseBackButtonAsIconProps, 'href' | 'customGoBack'>;

/*
 Label always hidden
 For visible label version, use GoBackButton
*/
export const BackButtonAsIcon = ({
  isLinkBack,
  variant = 'ghost',
  ariaLabel, // for screen readers only
  href,
  customGoBack,
  ...props
}: BackButtonAsIconProps) => {
  const { goBack } = useGoBack();
  const goBackFn = () => goBack(href);

  return (
    <GoBackButton
      data-testid="go-back-link"
      role="link" // always changes route
      Icon={IconArrowLeft}
      hideLabel
      label={ariaLabel} // label always hidden due to hideLabel, but used in GoBackButton for aria-label
      variant={variant}
      // if url is directly opened in a new tab or via an email
      // then use the back arrow to go to the specified page, otherwise use router history
      onClick={isLinkBack ? customGoBack || goBackFn : undefined}
      href={!isLinkBack ? href : ''}
      {...props}
    />
  );
};
