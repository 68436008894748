import { Box, Tooltip } from '@remote-com/norma';
import { IconV2OutlineHelpCircle } from '@remote-com/norma/icons/IconV2OutlineHelpCircle';

export default function PayElementDescriptionTooltip({ description }: { description?: string }) {
  if (!description) return null;

  return (
    <Tooltip label={description}>
      <Box color="grey.500" width="18px" height="18px" tabIndex={0}>
        <IconV2OutlineHelpCircle width="18px" />
      </Box>
    </Tooltip>
  );
}
