import { format } from 'date-fns';
import type { ComponentProps } from 'react';
import { useState, useRef, useEffect, forwardRef } from 'react';
import type { Except } from 'type-fest';

import { convertLocalTimeStringToDateObj } from '../../foundations/date';

import { DatePickerRegularContainer } from './calendarContainers';
import ReactDatePickerWrapper from './ReactDatePickerWrapper';

type InternalProps = ComponentProps<typeof ReactDatePickerWrapper>;
type Props = Except<InternalProps, 'cancelCallback' | 'saveCallback' | 'onChange'> & {
  onChange?: (date: string) => void;
  dateFormat: string;
};

export const DatePickerRegular = forwardRef<HTMLInputElement, Props>(function DatePickerRegular(
  { onChange, selected, ...props },
  ref
) {
  const initialValue = useRef(convertLocalTimeStringToDateObj(selected ?? null));
  const [selectedValue, setSelectedValue] = useState(initialValue.current);

  useEffect(() => {
    setSelectedValue(convertLocalTimeStringToDateObj(selected ?? null));
  }, [selected]);

  const handleChange = (date: Date | null) => {
    const dateValue = date === null ? '' : date;
    const formattedDate = dateValue && format(dateValue, props.dateFormat);

    setSelectedValue(date);
    if (onChange) onChange(formattedDate);
  };

  function calendarOpenCallback() {
    initialValue.current = convertLocalTimeStringToDateObj(selected ?? null);
  }

  function cancelCallback() {
    handleChange(initialValue.current);
  }

  function saveCallback() {
    if (!selectedValue) {
      handleChange(initialValue.current);
    }
  }

  return (
    <ReactDatePickerWrapper
      selected={selectedValue}
      onChange={handleChange}
      calendarContainer={DatePickerRegularContainer}
      cancelCallback={cancelCallback}
      saveCallback={saveCallback}
      calendarOpenCallback={calendarOpenCallback}
      ref={ref}
      {...props}
    />
  );
});
