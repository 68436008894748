import { homepageEvents, trackEvent } from '@remote-com/analytics';
import { Text, Box } from '@remote-com/norma';
import { useEffect } from 'react';
import { openModal } from 'react-url-modal';

import { WidgetCard } from '@/src/components/WidgetCard';
import { ADD_NEW_HIRE_ROUTE } from '@/src/constants/routes';
import { useIsTalentCompany } from '@/src/domains/companies/hooks/useIsTalentCompany';
import { TAKE_QUICK_EMPLOYER_TOUR_PROMPT } from '@/src/domains/userPrompts/constants';
import { useUserPrompts } from '@/src/domains/userPrompts/context';
import { NoWrapButton } from '@/src/domains/userPrompts/widgets/common.styled';

export function useShouldShowDemoWidget() {
  const isTalentCompany = useIsTalentCompany();
  const { promptsMap } = useUserPrompts();

  const productTourPrompt = promptsMap[TAKE_QUICK_EMPLOYER_TOUR_PROMPT];

  return productTourPrompt?.status === 'active' && !isTalentCompany;
}

export const PRODUCT_DEMO_WIDGET_STORAGE_KEY = 'product-demo-widget-dismissed';

export function ProductDemoWidget({ onDismiss }) {
  const show = useShouldShowDemoWidget();

  const openDemo = () => {
    openModal({ name: 'product-tour-modal' });
    trackEvent(homepageEvents.contentCardClicked('product-tour'));
  };

  useEffect(() => {
    if (show) {
      trackEvent(homepageEvents.contentCardLoaded('take-a-quick-tour'));
    }
  }, [show]);

  if (!show) return null;
  return (
    <WidgetCard title="Take a quick tour" onDismiss={onDismiss}>
      <Text variant="sm" color="grey.600">
        Learn how to make the most of Remote's platform and manage your global team with ease.
      </Text>
      <Box
        mt="24px"
        width="100%"
        maxWidth="355px"
        display="flex"
        justifyContent="space-around"
        marginX="auto"
      >
        <NoWrapButton type="button" size="sm" onClick={openDemo}>
          Take the tour
        </NoWrapButton>
        <NoWrapButton
          type="button"
          size="sm"
          variant="outline"
          href={ADD_NEW_HIRE_ROUTE}
          trackNavigation={['dashboard', 'add-new-hire-widget']}
        >
          Add new hire
        </NoWrapButton>
      </Box>
    </WidgetCard>
  );
}
