import { HeadingIcon, ListDisc, ListNumbers, Text } from '@remote-com/norma';
import { IconV2DuotoneAlertCircle } from '@remote-com/norma/icons/IconV2DuotoneAlertCircle';
import type { HTMLReactParserOptions } from 'html-react-parser';
import parse, { Element, attributesToProps, domToReact } from 'html-react-parser';

import { ButtonInline } from '@/src/components/Button';

export function transformHtmlToComponents(
  htmlContent: string,
  htmlReactParserOptions?: HTMLReactParserOptions
) {
  return parse(htmlContent, {
    replace(domNode) {
      // This type assertion is required
      // See https://github.com/remarkablemark/html-react-parser#replace-with-typescript
      if (domNode instanceof Element) {
        const { attribs, name: domName } = domNode;
        const children = domNode.children as Element[];

        if (children && domName === 'h1' && attribs['data-component'] === 'HeadingIcon') {
          const props = attributesToProps(attribs);
          return (
            <Text color="grey.900" mb={5}>
              <HeadingIcon Icon={IconV2DuotoneAlertCircle} text={domToReact(children)} {...props} />
            </Text>
          );
        }

        if (children && domName === 'a') {
          const props = attributesToProps(attribs);
          return <ButtonInline {...props}>{domToReact(children)}</ButtonInline>;
        }

        if (children && domName === 'ul') {
          const props = attributesToProps(attribs);
          return (
            <ListDisc gap="sm" {...props}>
              {domToReact(children)}
            </ListDisc>
          );
        }

        if (children && domName === 'ol') {
          const props = attributesToProps(attribs);
          return <ListNumbers {...props}>{domToReact(children)}</ListNumbers>;
        }
      }

      return null;
    },
    ...htmlReactParserOptions,
  });
}
