import { Box, Stack, Text } from '@remote-com/norma';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';

import {
  WIDGET_CARD_DEFAULT_HORIZONTAL_PADDING,
  WIDGET_CARD_DEFAULT_VERTICAL_PADDING,
} from './constants';
import type { WidgetCardProps } from './WidgetCard';

export const StyledWidgetCardHeader = styled(Stack).attrs({
  borderColor: 'grey.200',
  direction: 'row',
  gap: 4,
  px: WIDGET_CARD_DEFAULT_HORIZONTAL_PADDING,
})`
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  justify-content: space-between;
`;

export const StyledWidgetDismissButton = styled(Button).attrs({ variant: 'raw' })`
  border-radius: 9999px;
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  color: ${({ theme }) => theme.colors.grey[600]};
  display: grid;
  height: 24px;
  place-items: center;
  width: 24px;
`;

type StyledWidgetCardBodyProps = {
  $hasPadding: WidgetCardProps['hasBodyPadding'];
};

export const StyledWidgetCardBody = styled(Box).attrs(
  ({ $hasPadding }: StyledWidgetCardBodyProps) => ({
    px: $hasPadding ? WIDGET_CARD_DEFAULT_HORIZONTAL_PADDING : 0,
    py: $hasPadding ? WIDGET_CARD_DEFAULT_VERTICAL_PADDING : 0,
  })
)<StyledWidgetCardBodyProps>``;

export const StyledWidgetCardTitle = styled(Text).attrs({
  forwardedAs: 'h2',
  variant: 'lgMedium',
})`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]}px;
`;
