import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';

export const PAYSLIP_STATUSES = {
  VALID: 'valid',
  APPROVED: 'approved',
  REVIEWED: 'reviewed',
  DELETED: 'deleted',
  CREATED: 'created',
};

export const PAYSLIP_LABELS = {
  [PAYSLIP_STATUSES.VALID]: 'Valid',
  [PAYSLIP_STATUSES.DELETED]: 'Deleted',
  [PAYSLIP_STATUSES.APPROVED]: 'Approved',
  [PAYSLIP_STATUSES.REVIEWED]: 'Reviewed',
  [PAYSLIP_STATUSES.CREATED]: 'Created',
};

export const PAYSLIP_BADGES = /** @type {const} */ ({
  [PAYSLIP_STATUSES.VALID]: 'active',
  [PAYSLIP_STATUSES.DELETED]: 'error',
  [PAYSLIP_STATUSES.CREATED]: 'pending',
  [PAYSLIP_STATUSES.REVIEWED]: 'info',
  [PAYSLIP_STATUSES.APPROVED]: 'active',
});

export const PAYSLIP_STATUS_OPTIONS = Object.values(PAYSLIP_STATUSES).map((value) => ({
  value,
  label: PAYSLIP_LABELS[value],
  badge: PAYSLIP_BADGES[value],
}));

export const ACTIVE_PAYSLIP_STATUS_OPTIONS = PAYSLIP_STATUS_OPTIONS.filter(
  (option) => option.value !== PAYSLIP_STATUSES.DELETED
);

// Legacy? Why? Because these fields should be now present on a payroll output. Payslips only store the netPay.
// But we still need to render these in some places for safekeeping sake! (Old payslips are still here!)
export const legacyPayslipValuePropertyIds = [
  'gross_salary',
  'total_incentives',
  'total_expenses',
  'total_benefits',
  'employee_deductions',
  'employer_taxes',
  'net_salary',
  'total_cost',
];

export const payslipValuePropertyIds = ['net_salary'];

const allPayslipProps = ['user', 'country_slug', 'status', 'issued_at', ...payslipValuePropertyIds];

const makePayslipValuePropertyFromId = (id) => {
  let description;
  let label = capitalize(id.replace(/_/g, ' '));
  const accessor = camelCase(id);

  switch (id) {
    case 'net_salary':
      label = 'Net pay';
      break;
    case 'country_slug':
      label = 'Country';
      break;
    case 'employer_taxes':
      label = 'Employer contributions';
      break;
    case 'total_cost':
      description = 'Total cost = Net salary + Employer contributions + Employee deductions';
      break;
    case 'gross_salary':
      description = 'Gross salary = Net pay + Employee deductions';
      break;
  }

  return {
    label,
    id,
    accessor,
    description,
  };
};

// Legacy? Why? Because these fields should be now present on a payroll output. Payslips only store the netPay.
// But we still need to render these in some places for safekeeping sake! (Old payslips are still here!)
export const legacyPayslipValueProperties = legacyPayslipValuePropertyIds.map(
  makePayslipValuePropertyFromId
);
export const payslipValueProperties = payslipValuePropertyIds.map(makePayslipValuePropertyFromId);
export const payslipProperties = allPayslipProps.map(makePayslipValuePropertyFromId);

export const PAYSLIP_ACTION = {
  BATCH: 'batch',
  SELECTED: 'selected',
};

export const PAYSLIP_BULK_ERRORS = {
  approve: {
    approved: "Can't approve already approved payslips",
    deleted: "Can't approve already deleted payslips",
  },
  edit: {
    deleted: "Can't edit already deleted payslips",
  },
  delete: { deleted: "Can't delete already deleted payslips" },
};
