import { Text, Box, Stack } from '@remote-com/norma';
import { IconCoffee } from '@remote-com/norma/icons/IconCoffee';
import { IconFlag } from '@remote-com/norma/icons/IconFlag';
import { useTheme } from 'styled-components';

import { ButtonInline } from '@/src/components/Button';

import { ProvisionalStartDateWrapper, HeaderWrapper, HeaderText } from './components.styled';

const ProvisionalStartDateInfoColumn = ({ canSelectWeekends }) => {
  const { colors } = useTheme();
  return (
    <ProvisionalStartDateWrapper>
      <HeaderWrapper>
        <HeaderText>Good to know</HeaderText>
      </HeaderWrapper>
      <Box px={6} py={5}>
        <Box display="flex" mb={6}>
          <Box
            width="32px"
            height="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            backgroundColor="brand.100"
            style={{
              // eslint-disable-next-line no-inline-styles/no-inline-styles
              aspectRatio: '1 / 1',
            }}
          >
            <IconCoffee
              width="15px"
              style={{
                // eslint-disable-next-line no-inline-styles/no-inline-styles
                aspectRatio: '1 / 1',
              }}
              fill={colors.blueLight[500]}
            />
          </Box>

          <Box ml={4}>
            <Text variant="smMedium" color="grey.900" pt={2} pb={3} as="h4">
              Weekend days
            </Text>
            <Text variant="xs" color="grey.500" as="div">
              <Stack gap={4}>
                {canSelectWeekends ? (
                  <>
                    <Text as="p">
                      You can select a weekend day as the start date but we do not recommend it.
                    </Text>
                    <Text as="p">
                      You'll need to make additional statutory payments if the start date is a
                      non-typical working day.
                    </Text>
                  </>
                ) : (
                  <Text color="grey.500">
                    You cannot select a weekend day as an employee's start date.
                  </Text>
                )}
              </Stack>
            </Text>
          </Box>
        </Box>
        <Box display="flex">
          <Box
            width="32px"
            height="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            backgroundColor="orange.200"
            style={{
              // eslint-disable-next-line no-inline-styles/no-inline-styles
              aspectRatio: '1 / 1',
            }}
          >
            <IconFlag height="15px" fill={colors.orange[400]} />
          </Box>

          <Box ml={4}>
            <Text variant="smMedium" color="grey.900" pt={2} pb={3} as="h4">
              Public holidays
            </Text>
            <Text variant="xs" color="grey.500" as="div">
              <Stack gap={4}>
                <Text as="p">You cannot select a public holiday as an employee's start date.</Text>
                <Text as="p">
                  Please contact{' '}
                  <ButtonInline href="mailto:help@remote.com" rel="noopener noreferrer">
                    help@remote.com
                  </ButtonInline>{' '}
                  for more advice.
                </Text>
              </Stack>
            </Text>
          </Box>
        </Box>
      </Box>
    </ProvisionalStartDateWrapper>
  );
};

export default ProvisionalStartDateInfoColumn;
