// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineCheckCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12 2.75a9.25 9.25 0 1 0 0 18.5 9.25 9.25 0 0 0 0-18.5ZM1.25 12a10.75 10.75 0 1 1 21.5 0 10.75 10.75 0 0 1-21.5 0Zm15.28-3.53c.3.3.3.77 0 1.06l-5 5c-.3.3-.77.3-1.06 0l-2-2a.75.75 0 1 1 1.06-1.06L11 12.94l4.47-4.47c.3-.3.77-.3 1.06 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineCheckCircle);
export { ForwardRef as IconV2OutlineCheckCircle };