import type { MouseEvent } from 'react';
import styled from 'styled-components';

import { Text } from '../../../core/text';
import { Box } from '../../../layout/index';
import { InputCheckbox } from '../../input-checkbox';

const OptionValue = styled(Text).attrs({ variant: 'sm' })`
  color: ${({ theme }) => theme.colors.grey[600]};
  text-align: right;
`;

type MultiSelectVisibleOptionProps = {
  isSelected: boolean;
  data: Record<string, unknown>;
  showValue?: boolean;
};

// A no-op function to pass for onChange to remove the console
// error for having a selected value with no onChange handler.
// An onChange handler is not needed for the checkbox because it
// is handled by the wrapping react-select component
const noop = () => null;

/**
 * A select option that will show the label and value and a checkbox
 * next to the item when it is selected in the format:
 * {[x] label                                     value ✓ }
 */
export function MultiSelectVisibleOption({
  isSelected,
  data,
  showValue = true,
}: MultiSelectVisibleOptionProps) {
  const { value, label } = data;
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      py={4}
      mr={4}
    >
      <Box display="flex" flexDirection="row">
        <InputCheckbox
          label={null}
          checked={isSelected}
          onChange={noop}
          handleCheckboxClick={(evt: MouseEvent<HTMLElement>) => evt.preventDefault()}
        />
        <Text variant="sm">{label}</Text>
      </Box>
      {showValue && <OptionValue>{value}</OptionValue>}
    </Box>
  );
}
