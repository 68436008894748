import { accountsSwitchingEvents, trackEvent } from '@remote-com/analytics';
import { usePost } from '@remote-com/data-layer';
import {
  Box,
  FeedbackMessage,
  FEEDBACK_MESSAGE_ERROR,
  Stack,
  Text,
  toast,
} from '@remote-com/norma';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { object } from 'yup';

import { ButtonInline, ButtonInline as ButtonInlineNorma } from '@/src/components/Button';
import { Modal } from '@/src/components/Modal';
import { InputPassword, InputField, CheckboxField } from '@/src/components/Ui/Form';
import { useUser } from '@/src/components/UserProvider/context';
import { getTOTPErrorMessage, AUTH_ERRORS } from '@/src/domains/account/helpers';
import { useUserLinkedAccounts } from '@/src/domains/account/hooks/useUserLinkedAccounts';
import ResetPasswordModal from '@/src/domains/account/ResetPasswordModal';
import { Verify2FACodeModal } from '@/src/domains/account/Verify2FACodeModal';
import { SSO_ERROR_MESSAGES } from '@/src/domains/registration/auth/constants';
import { isTwoFactorAuthenticationError } from '@/src/domains/registration/auth/helpers';
import { useReCAPTCHA } from '@/src/domains/registration/auth/ReCAPTCHA';
import { getUserTimezone } from '@/src/helpers/date';
import { emailSchema, requiredPasswordSchema } from '@/src/helpers/validationSchema';
import type { $TSFixMe } from '@/types';

const Divider = styled.hr`
  border-top: 0.5px solid ${({ theme }) => theme.colors.grey[300]};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.grey[300]};
  width: 64px;
`;

type ProfileAddModalProps = {
  visible: boolean;
  onSuccessAuth: () => void;
  onClose: () => void;
  isAccountSwitching: boolean;
  authenticationMethod?: 'password' | 'sso';
  email?: string;
};

const validationSchemaPassword = object().shape({
  email: emailSchema.required(),
  password: requiredPasswordSchema.required(),
});
const validationSchemaSSO = object().shape({
  email: emailSchema.required(),
});

export function ProfileAddModal({
  visible,
  onSuccessAuth,
  email = '',
  onClose,
  authenticationMethod = 'password',
  isAccountSwitching,
}: ProfileAddModalProps) {
  const [verify2FACodeModalVisibility, setVerify2FACodeModalVisibility] = useState(false);
  const [userAuthMethod, setUserAuthMethod] = useState(authenticationMethod); // password or sso
  const [savedPassword, setSavedPassword] = useState('');
  const [savedEmail, setSavedEmail] = useState(email);
  const [savedRememberMe, setSavedRememberMe] = useState(false);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingState, setIsLoadingState] = useState(false);

  const { accountIsLinked } = useUserLinkedAccounts();
  const { user } = useUser();

  // IF user is trying to add existing account that is already in the list or current account that they are logged in, we prevent that.
  const userIsTryingToAddExistingAccount =
    !isAccountSwitching && Boolean(accountIsLinked(savedEmail) || user?.email === savedEmail);

  const { executeRecaptcha } = useReCAPTCHA();
  const router = useRouter();

  const { mutate, isLoading } = usePost('/api/v1/session');

  const { mutate: signInSSOMutation, isLoading: isLoadingSSOMutation } = usePost(
    '/api/v1/sso/redirect',
    {
      onSuccess: ({ data }) => {
        // redirect to the URL that has been returned to complete SSO auth flow
        if (data?.url) {
          router.push(data.url);
        }
      },
      onError: (err: $TSFixMe) => {
        const { status, data: respData } = err?.response || {};
        const ssoErrorMessage =
          respData?.message || err.message || SSO_ERROR_MESSAGES.GENERIC_SSO_ERROR;
        const resultFeedback =
          status === 404 ? SSO_ERROR_MESSAGES.SSO_EMAIL_NOT_FOUND : ssoErrorMessage;
        setErrorMessage(resultFeedback);
        setIsLoadingState(false);
      },
    }
  );

  async function onHandleSubmit(data: {
    totp?: number;
    password?: string;
    email?: string;
    rememberMe?: boolean;
  }) {
    trackEvent(
      isAccountSwitching
        ? accountsSwitchingEvents.SWITCH_ACCOUNT_CTA_CLICKED
        : accountsSwitchingEvents.ADD_ACCOUNT_CTA_CLICKED
    );

    if (data?.rememberMe) {
      trackEvent(
        isAccountSwitching
          ? accountsSwitchingEvents.ACCOUNT_LINK_REMEMBER_ME('switch account pw modal')
          : accountsSwitchingEvents.ACCOUNT_LINK_REMEMBER_ME('add account pw modal')
      );
    }

    setErrorMessage('');
    setIsLoadingState(true);

    if (userAuthMethod === 'sso') {
      const timezone = getUserTimezone();
      signInSSOMutation({
        bodyParams: {
          email: savedEmail,
          timezone,
          linking_accounts: true,
        },
      });

      return;
    }

    const recaptchaToken = await executeRecaptcha({ action: 'submit', twofactor: undefined });
    mutate(
      {
        bodyParams: {
          user: {
            email: savedEmail,
            password: savedPassword,
            ...(data?.totp && { totp: data?.totp }),
          },
          recaptchaToken,
          linking_accounts: true,
          rememberMe: savedRememberMe,
        },
      },
      {
        onSuccess: () => {
          trackEvent(
            isAccountSwitching
              ? accountsSwitchingEvents.SWITCH_ACCOUNT_FORM_SUBMITTED('success')
              : accountsSwitchingEvents.ADD_ACCOUNT_FORM_SUBMITTED('success')
          );
          onSuccessAuth();
        },
        onError: (error: $TSFixMe) => {
          setIsLoadingState(false);
          const hasTwoFactorAuthentication = isTwoFactorAuthenticationError(error);
          // handle 2FA error by showing the 2FA modal
          if (hasTwoFactorAuthentication) {
            setVerify2FACodeModalVisibility(true);
          } else {
            // handle account locked error
            const errorData = error.response?.data;
            if (errorData?.code === AUTH_ERRORS.LOCKED_ACCOUNT) {
              setErrorMessage(getTOTPErrorMessage(errorData?.code));
              return;
            }
            trackEvent(
              isAccountSwitching
                ? accountsSwitchingEvents.SWITCH_ACCOUNT_FORM_SUBMITTED('error')
                : accountsSwitchingEvents.ADD_ACCOUNT_FORM_SUBMITTED('error')
            );
            // generic error
            setErrorMessage(
              errorData?.message ||
                'Something went wrong adding account. Please try again or contact support for help.'
            );
          }
        },
      }
    );
  }

  if (verify2FACodeModalVisibility) {
    return (
      <Verify2FACodeModal
        handleSubmit={({ totp }: { totp: number }) => onHandleSubmit({ totp })}
        customTitle="Two-factor authentication (2FA) required"
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        isLoading={isLoading}
        onClose={() => {
          setVerify2FACodeModalVisibility(false);
          toast.error('Account addition has been cancelled');
          onClose();
        }}
      />
    );
  }

  if (forgotPasswordVisible) {
    return (
      <ResetPasswordModal
        email={savedEmail}
        onClose={() => {
          setForgotPasswordVisible(false);
        }}
      />
    );
  }

  const ModalDescription = () => {
    const Description = ({ children }: { children: React.ReactNode }) => {
      return (
        <Text mb={6} variant="sm">
          {children}
        </Text>
      );
    };

    if (userAuthMethod === 'password' && isAccountSwitching) {
      return (
        <Description>
          You’re logged out of <strong>{savedEmail}</strong>. To switch to this account, log in
          again.
        </Description>
      );
    }

    if (userAuthMethod === 'password' && !isAccountSwitching) {
      return (
        <Description>
          When you add an account, you can switch between them faster. To get started, enter the
          credentials of the account you want to add.
        </Description>
      );
    }

    if (userAuthMethod === 'sso' && isAccountSwitching) {
      return (
        <Description>
          You’re logged out of <strong>{savedEmail}</strong>. To switch to this account,
          authenticate using single sign-on (SSO) again.
        </Description>
      );
    }
    if (userAuthMethod === 'sso' && !isAccountSwitching) {
      return (
        <Description>
          When you add accounts, you can switch between them faster. Enter your company email to
          authenticate using single sign-on (SSO).
        </Description>
      );
    }
    return null;
  };

  return (
    <Modal
      visible={visible}
      headerTitle={isAccountSwitching ? 'Switch account' : 'Add account'}
      formName="auth-email-password"
      saveButtonText={isAccountSwitching ? 'Switch account' : 'Add account'}
      confirmLoading={isLoading || isLoadingSSOMutation || isLoadingState}
      disableSubmitBtn={userIsTryingToAddExistingAccount}
      onDismiss={() => {
        setErrorMessage('');
        onClose();
      }}
    >
      <ModalDescription />
      {/* User is using SSO as authentication method to add linked account */}
      {userAuthMethod === 'sso' && (
        <Formik
          initialValues={{
            email,
          }}
          validationSchema={validationSchemaSSO}
          onSubmit={onHandleSubmit}
        >
          {({ handleSubmit, touched, errors, handleChange }) => {
            return (
              <Stack gap={3}>
                <form onSubmit={handleSubmit} noValidate id="auth-email-password">
                  <Box mb={5}>
                    <InputField
                      id="email"
                      name="email"
                      type="email"
                      label="Company email"
                      autoComplete="username"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event);
                        setSavedEmail(event.target.value);
                      }}
                      errorText={touched.email && errors.email ? errors.email : ''}
                      required
                    />
                  </Box>
                </form>
                {!isAccountSwitching && (
                  <Text variant="sm" textAlign="center">
                    Not using SSO?{' '}
                    <ButtonInlineNorma
                      onClick={() => {
                        setUserAuthMethod('password');
                        setErrorMessage('');
                      }}
                      tone="primary"
                    >
                      Go back
                    </ButtonInlineNorma>
                  </Text>
                )}
                {errorMessage && (
                  <Box mt={6}>
                    <FeedbackMessage variant={FEEDBACK_MESSAGE_ERROR}>
                      {errorMessage}
                    </FeedbackMessage>
                  </Box>
                )}
                {userIsTryingToAddExistingAccount && (
                  <Box mt={6}>
                    <FeedbackMessage variant={FEEDBACK_MESSAGE_ERROR}>
                      This account has already been added.
                    </FeedbackMessage>
                  </Box>
                )}
              </Stack>
            );
          }}
        </Formik>
      )}

      {/* User is using email/password as authentication method to add linked account */}
      {userAuthMethod === 'password' && (
        <Formik
          initialValues={{
            email,
            password: '',
          }}
          validationSchema={validationSchemaPassword}
          onSubmit={onHandleSubmit}
        >
          {({ handleSubmit, touched, errors, values, handleChange }) => {
            return (
              <Stack>
                {errorMessage && (
                  <FeedbackMessage mb={6} variant={FEEDBACK_MESSAGE_ERROR}>
                    <Box dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  </FeedbackMessage>
                )}
                {userIsTryingToAddExistingAccount && (
                  <FeedbackMessage mb={6} variant={FEEDBACK_MESSAGE_ERROR}>
                    This account has already been added.
                  </FeedbackMessage>
                )}
                <form onSubmit={handleSubmit} noValidate id="auth-email-password">
                  {!isAccountSwitching && (
                    <Box mb={6}>
                      <InputField
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        autoComplete="username"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          handleChange(event);
                          setSavedEmail(event.target.value);
                        }}
                        errorText={touched.email && errors.email ? errors.email : ''}
                        required
                      />
                    </Box>
                  )}
                  <Box mb={6}>
                    <InputPassword
                      id="password"
                      name="password"
                      label="Password"
                      value={values.password}
                      autoComplete="password"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event);
                        setSavedPassword(event.target.value);
                      }}
                      errorText={touched.password && errors.password ? errors.password : ''}
                      required
                    />
                  </Box>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <CheckboxField
                      type="checkbox"
                      name="rememberMe"
                      label="Remember me"
                      size="sm"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event);
                        setSavedRememberMe(event.target.checked);
                      }}
                    />
                    <Text variant="smMedium" textAlign="right">
                      <ButtonInline
                        onClick={() => {
                          setForgotPasswordVisible(true);
                        }}
                      >
                        Forgot password?
                      </ButtonInline>
                    </Text>
                  </Stack>
                </form>
                {!isAccountSwitching && (
                  <Stack mt={6} alignItems="center">
                    <Box width={140} display="flex" alignItems="center" justifyContent="center">
                      <Divider />
                      <Text variant="xsSemiBold" textTransform="uppercase" color="grey.500" mx={4}>
                        or
                      </Text>
                      <Divider />
                    </Box>
                    <Text variant="smMedium" textAlign="center" mt={5}>
                      <ButtonInline
                        variant="default"
                        onClick={() => {
                          setUserAuthMethod('sso');
                          setErrorMessage('');
                        }}
                      >
                        Authenticate with SSO
                      </ButtonInline>
                    </Text>
                  </Stack>
                )}
              </Stack>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
}
