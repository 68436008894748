import type { Currency } from '@remote-com/talent/api/boba/shared.types';
import { parse } from 'date-fns';
import words from 'lodash/words';

import type { API } from '@/src/api/config/api.types';
import type {
  BillingDocumentTotal,
  TaxationItem,
} from '@/src/api/config/employ/billingDocuments.types';
import type {
  CorrectionReason,
  InvoiceReportFile,
  PrimaryCorrectionReason,
} from '@/src/api/config/employ/invoiceReport.types';
import type { ExMoneyValue } from '@/src/api/config/employ/shared.types';
import {
  reversalTypes,
  employeeInvoiceTypes,
  correctionReasonLabels,
  correctionReasons,
} from '@/src/domains/invoices/constants';
import type { PaymentStatus } from '@/src/domains/payments/constants';
import { paymentStatus } from '@/src/domains/payments/constants';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { isEmployee, userCan } from '@/src/domains/registration/auth/helpers';
import { userCacheKeys } from '@/src/domains/userCache/constants';
import { getFromUserCache } from '@/src/domains/userCache/helpers';
import {
  convertToCents,
  friendlyMoney,
  friendlyMoneyWithoutCurrency,
} from '@/src/helpers/currency';
import { compareDates, formatShortDayShortMonthYear } from '@/src/helpers/date';

export const shouldShowInvoices = (user: API.UserAccountResponse['data']) => {
  if (isEmployee(user)) {
    return getFromUserCache(user, userCacheKeys.INVOICES_COUNT) > 0;
  }
  return userCan('read', Resources.invoices, user);
};

export const getPaymentButtonsText = ({
  status,
  isAdminUser,
  isInvoicePayable,
}: {
  status: PaymentStatus;
  isAdminUser: boolean;
  isInvoicePayable: boolean;
}) => {
  const PAYMENT_COMPLETED_COPY = 'View completed payment';
  const VIEW_PAYMENT_COPY = 'View outstanding payment';
  const PAY_INVOICE_COPY = 'Pay invoice';
  const isPaymentCompleted = status === paymentStatus.COMPLETED;

  if (isAdminUser) {
    return isPaymentCompleted ? PAYMENT_COMPLETED_COPY : VIEW_PAYMENT_COPY;
  }

  if (isInvoicePayable) return PAY_INVOICE_COPY;
  if (isPaymentCompleted) return PAYMENT_COMPLETED_COPY;

  return VIEW_PAYMENT_COPY;
};

export const getRefundButtonsText = (refundStatus?: PaymentStatus) => {
  const buttonsText = {
    [paymentStatus.CREDITED as PaymentStatus]: 'View transactions',
    [paymentStatus.REFUNDED as PaymentStatus]: 'View transactions',
    [paymentStatus.CLOSED as PaymentStatus]: 'View transactions',
  };

  const defaultText = 'View outstanding credit';

  if (refundStatus) {
    return buttonsText[refundStatus] ?? defaultText;
  }

  return defaultText;
};

export const getExportFileName = ({
  invoicePeriod,
  companyName,
  isCreditNote,
  documentNumber,
}: {
  invoicePeriod: string;
  companyName: string;
  isCreditNote: boolean;
  documentNumber?: string;
}) => {
  const docName = isCreditNote ? 'Credit-Note' : 'Invoice';
  return `${invoicePeriod}-${docName}-${documentNumber || 'Draft'}-${words(companyName).join('-')}`;
};

export const getInvoiceSubtitleText = ({
  isContractorPaymentsInvoice,
  isContractorSubscriptionBill,
  isOnboardingReserveInvoice,
}: {
  isContractorPaymentsInvoice: boolean;
  isContractorSubscriptionBill: boolean;
  isOnboardingReserveInvoice: boolean;
}) => {
  if (isContractorPaymentsInvoice) {
    return 'Contractor services';
  }
  if (isContractorSubscriptionBill) {
    return 'Fees';
  }
  if (isOnboardingReserveInvoice) {
    return 'Reserve invoice';
  }
  return 'Payroll services';
};

export const getInvoiceReversalType = ({
  isReconciliationCredit,
  isRemotePaidServiceInvoice,
}: {
  isReconciliationCredit: boolean;
  isRemotePaidServiceInvoice: boolean;
}) => {
  if (isRemotePaidServiceInvoice) {
    return reversalTypes.PARTIAL_CREDIT_NOTE;
  }

  if (isReconciliationCredit) {
    return reversalTypes.REMOTE_INVOICE;
  }

  return reversalTypes.CREDIT_NOTE;
};

export const getInvoiceTotalCopy = (totalAmount: number) => {
  return totalAmount > 0 ? 'Invoice total' : 'Total amount';
};

export const checkShowSplitBenefits = (invoicePeriod: string) => {
  // Old column: 'Benefits'.
  // New split columns: 'Payslip benefits' and 'Other benefits'.
  // Starting from February 2022 we show the new columns.
  return new Date(invoicePeriod) >= new Date('2022-02');
};

export const invoiceReportsTableDateRangeFilterLabel = (
  dateRange: string,
  separator: string = 'to'
) => {
  const [minDate, hasSeparator, maxDate] = dateRange.split(' ').filter(Boolean);

  return [
    minDate && formatShortDayShortMonthYear(minDate),
    maxDate && hasSeparator && separator,
    maxDate && formatShortDayShortMonthYear(maxDate),
  ]
    .filter(Boolean)
    .join(' ');
};

export const parseInvoicePeriod = (invoicePeriod: string) =>
  parse(invoicePeriod, 'yyyy-MM', new Date());

export const getInvoiceFileSlug = (
  invoiceFiles: InvoiceReportFile[],
  invoiceType = employeeInvoiceTypes.REMOTE_INVOICE
) => invoiceFiles.find((file) => file.type === invoiceType)?.slug;

const mapReasonsToOptions = (reasonsList: CorrectionReason[]) => {
  return reasonsList.map((value) => ({
    value,
    label: correctionReasonLabels[value],
  }));
};

export const getPrimaryCorrectionReasonOptions = () => {
  const primaryReasonsList = Object.keys(correctionReasons);
  return mapReasonsToOptions(primaryReasonsList as PrimaryCorrectionReason[]);
};

export const getSecondaryCorrectionReasonOptions = (primaryReason: PrimaryCorrectionReason) => {
  const secondaryReasonsList = primaryReason ? correctionReasons[primaryReason] : [];
  return secondaryReasonsList.length > 0 ? mapReasonsToOptions(secondaryReasonsList) : [];
};

type AggregatedTaxationItem = {
  amount: {
    amount: number;
    currency: Currency;
  };
  name: string;
  taxRate: string;
  estimateAmount?: {
    amount: number;
    currency: Currency;
  };
};

/**
 * Aggregates taxation items by name from invoice totals for a BillingDocument.
 * Equivalent of the legacy invoiceReportTaxes that was sent.
 * Used for displaying the list of taxes at the bottom of the invoice.
 *
 * This function takes an array of invoice totals, collects all the taxation items,
 * groups them by their name, and sums their amounts. It returns an array of aggregated
 * taxation items with their total amounts. If the invoice totals are for a reconciliation,
 * it also sums the estimate amounts.
 *
 * @param {BillingDocumentTotal[]} invoiceTotals - An array of invoice totals, each containing a list of taxation items.
 * @returns {AggregatedTaxationItem[]} - An array of aggregated taxation items with summed amounts.
 */
export const getAggregatedTaxationItems = (
  invoiceTotals: BillingDocumentTotal[]
): AggregatedTaxationItem[] => {
  if (!invoiceTotals) {
    return [];
  }

  const taxMap: { [key: string]: AggregatedTaxationItem } = {};
  let hasTaxationItems = false;

  const addToTaxMap = (taxationItem: TaxationItem, isEstimate: boolean = false) => {
    if (!taxMap[taxationItem.name]) {
      taxMap[taxationItem.name] = {
        ...taxationItem,
        amount: {
          amount: 0,
          currency: taxationItem.amount.currency,
        },
        estimateAmount: { amount: 0, currency: taxationItem.amount.currency },
      };
    }

    const amountToAdd = convertToCents(taxationItem.amount.amount) ?? 0;

    if (isEstimate) {
      const currentEstimateAmount = taxMap[taxationItem.name].estimateAmount!.amount;
      taxMap[taxationItem.name].estimateAmount!.amount = currentEstimateAmount + amountToAdd;
    } else {
      const currentAmount = taxMap[taxationItem.name].amount.amount;
      taxMap[taxationItem.name].amount.amount = currentAmount + amountToAdd;
    }
  };

  invoiceTotals.forEach((invoiceLineItem) => {
    if (invoiceLineItem.taxationItems.length > 0) {
      hasTaxationItems = true;

      invoiceLineItem.taxationItems.forEach((taxationItem) => addToTaxMap(taxationItem));

      invoiceLineItem.estimateTaxationItems?.forEach((estimateTaxationItem) =>
        addToTaxMap(estimateTaxationItem, true)
      );
    }
  });

  if (!hasTaxationItems) {
    return [];
  }

  return Object.values(taxMap);
};

export const sumInvoiceTotals = (
  invoiceTotals: BillingDocumentTotal[],
  isEstimate: boolean = false
): number => {
  return invoiceTotals.reduce((invoiceTotalsSum, totalRow) => {
    const amount = isEstimate ? totalRow.estimateAmount.amount : totalRow.amount.amount;
    const amountInCents = convertToCents(amount) ?? 0;
    return invoiceTotalsSum + amountInCents;
  }, 0);
};

export const sumInvoiceTaxationItems = (
  invoiceTotals: BillingDocumentTotal[],
  isEstimate: boolean = false
) => {
  return invoiceTotals.reduce((allTaxationItemsSum, totalRow) => {
    const taxationItems = isEstimate ? totalRow.estimateTaxationItems : totalRow.taxationItems;
    if (!taxationItems) {
      return 0;
    }
    const currentTaxationItemsSum = taxationItems.reduce((taxationItemSum, taxationItem) => {
      const currentTaxationItemAmountInCents = convertToCents(taxationItem.amount.amount) ?? 0;
      return taxationItemSum + currentTaxationItemAmountInCents;
    }, 0);
    return allTaxationItemsSum + currentTaxationItemsSum;
  }, 0);
};

export const sumInvoiceItemTaxationItems = (taxationItems: TaxationItem[]) => {
  return taxationItems.reduce((taxationItemSum, taxationItem) => {
    const currentTaxationItemAmountInCents = convertToCents(taxationItem.amount.amount) ?? 0;
    return taxationItemSum + currentTaxationItemAmountInCents;
  }, 0);
};

export function isExMoneyValue(value?: ExMoneyValue | unknown): value is ExMoneyValue {
  if (value) {
    return typeof value === 'object' && 'currency' in value && 'amount' in value;
  }
  return false;
}

/**
 * Used to format an amount to a plain string
 * @param amount string | number | ExMoneyValue
 * @param fallbackCurrency | Currency
 * @returns string
 */
export function formatAmountWithCurrency(
  amount: string | number | ExMoneyValue,
  fallbackCurrency: Currency
) {
  return isExMoneyValue(amount)
    ? friendlyMoney(amount.amount, { code: amount.currency })
    : friendlyMoney(amount, fallbackCurrency);
}

/**
 * Used to format an amount to MoneyCell params
 * @param amount string | number | ExMoneyValue
 * @param fallbackCurrency | Currency
 * @returns string
 */
export function formatAmountToMoneyCellParams(
  amount: string | number | ExMoneyValue,
  fallbackCurrency: Currency
) {
  return isExMoneyValue(amount)
    ? { amount: amount.amount, currency: { code: amount.currency } }
    : { amount, currency: fallbackCurrency };
}

/**
 * Used to format amount without currency when exporting invoice breakdown table to CSV.
 * @param amount string | number | ExMoneyValue
 * @param fallbackCurrency | Currency
 * @returns string
 */
export function formatAmountWithoutCurrency(
  amount: string | number | ExMoneyValue,
  fallbackCurrency: Currency
) {
  return isExMoneyValue(amount)
    ? friendlyMoneyWithoutCurrency(amount.amount, {
        impliedCurrencyCode: amount.currency,
        placeholder: '',
      })
    : friendlyMoneyWithoutCurrency(amount, {
        impliedCurrencyCode: fallbackCurrency.code,
        placeholder: '',
      });
}

/**
 * Used determine if a given period is before 2022-08-01 (zuora earliest accounting period)
 * @param invoicePeriod string - Date as yyyy-mm
 * @returns boolean
 */
export function isBeforeEarliestZuoraAccountingPeriod(invoicePeriod: string) {
  const zuoraEarliestAccountingPeriod = '2022-08-01';
  const invoicePeriodDate = `${invoicePeriod}-01`;

  return compareDates(invoicePeriodDate, zuoraEarliestAccountingPeriod) === -1;
}
