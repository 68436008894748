import isPlainObject from 'lodash/isPlainObject';
import type { HTMLAttributes, MouseEventHandler, Ref } from 'react';
import React, { Fragment, forwardRef, isValidElement, useEffect, useRef, useState } from 'react';

import { IconV2OutlineEditUnderline } from '../../icons/build/IconV2OutlineEditUnderline';
import { IconV2OutlineTrash } from '../../icons/build/IconV2OutlineTrash';
import { Box, Stack } from '../../layout';
import { EMPTY_INDICATOR } from '../../utils/helpers';
import type { ComprehensiveElementType } from '../../utils/types';
import { ButtonIcon, StandaloneLink } from '../button';
import { CopyToClipboard } from '../copy-to-clipboard';
import type { FeedbackMessageVariant } from '../feedback-message';
import type { HeadingIconColor } from '../heading-icon';
import { HeadingIcon as HeadingIconComponent, DEFAULT_COLOR } from '../heading-icon';
import type { PillTone } from '../pill';
import { Pill } from '../pill';
import { Text } from '../text';
import { Tooltip } from '../tooltip';

import {
  InfoBlockActions,
  InfoBlockListNestedItemActions,
  InfoBlockHeading,
  InfoBlockList,
  InfoBlockListItem,
  InfoBlockListItemTitle,
  InfoBlockListItemValue,
  InfoBlockListItemValueEmptyOptional,
  InfoBlockListNestedItem,
  InfoBlockListNestedItemTitle,
  InfoBlockMaskSecretStyled,
  PillStyled,
  SkeletonLine,
  StyledFeedbackMessage,
  StyledIconChevronDown,
  TextContentWithEllipsis,
  InfoBlockListNestedItemTitleWrapper,
  InfoBlockListNestedItemTopValue,
} from './InfoBlock.styled';

type NonElementActionType = {
  Icon: React.ElementType;
  label: string;
  onClick?: MouseEventHandler<HTMLElement>;
  href?: string;
  tone?: 'secondary' | 'primary' | 'destructive';
  disabled?: boolean;
  'aria-label'?: string;
};

type ActionType = NonElementActionType | React.ReactElement;

export type { ActionType as InfoBlockAction };

export type ListItem = {
  title?: ComprehensiveElementType;
  topLevelValue?: ComprehensiveElementType;
  topLevelActions?: ActionType[];
  value?:
    | string
    | ListItem[]
    | string[]
    | React.ReactElement
    | React.ElementType
    | number
    | boolean;
  copyValue?: string[] | number[] | string | number;
  isOptional?: boolean;
  maskSecret?: number;
  /**
   * DISCLAIMER: Currently only applies to masked secrets.
   */
  onValueClick?: () => void;
  name?: string;
  actions?: ActionType[];
  onEdit?: MouseEventHandler<HTMLElement>;
  editLabel?: string;
  onDelete?: MouseEventHandler<HTMLElement>;
  deleteLabel?: string;
  tag?: string;
  pillTone?: PillTone;
  isDeprecated?: boolean;
};

// The "Props" is unnecessary but we already have "InfoBlockListItem" exported
// as a styled component.
export type { ListItem as InfoBlockPropsListItem };

// Extending the type with Html div attributes to allow TS to infer valid html props such as role
export type InfoBlockProps = HTMLAttributes<HTMLDivElement> & {
  /** InfoBlock's heading's text. If undefined, the heading won't be displayed. */
  headingText?: ComprehensiveElementType;
  /**
   * InfoBlock's heading's SVG icon. Use only icons imported from "@remote-com/norma/icons"
   */
  HeadingIcon?: React.ElementType;
  /**
   * InfoBlock's heading's icon color. Its background will be automatically colored with
   * the color's 15% tint.
   */
  headingIconColor?: HeadingIconColor;
  /** When true, it renders the loading state below the heading */
  isLoading?: boolean;
  /**
   * List of definition items. Each item has the following set of properties:
   *
   * - `title`: item's title, displayed on the left (required).
   * - `topLevelValue`: item's top level value, only applicable to items that contain a nested list, displayed on the right. Useful if you want to render both a nested list and a value.
   * - `value`: item's value, displayed on the right. Can be:
   *    - string, number (rendered as is)
   *    - element (e.g. `<MyCustomElement />`)
   *    - element type (e.g. `MyCustomElement`);
   *    - Array of objects similar to `list` itself. (it's recursive)
   *    - empty value: Renders "Missing details"
   * - `isOptional`: if `true`, empty values render empty indicator "–".
   * - `maskSecret`: if > 0, will render the value as a mask with only the last
   *   `maskSecret` characters visible, and a button to unmask the value.
   * - `actions`: displays custom actions on the right side of the item value,
   *   for more information see the `actions` prop at the `InfoBlock` level.
   * - `onEdit`: if defined, displays an "Edit" action on the right side of the item value
   * - `onDelete`: if defined, displays a "Delete" action on the right side of the item value
   * - `copyValue`: if defined, displays `<CopyToClipboard>` component on the right side of the item value
   */
  list?: ListItem[];
  /**
   * Determines the size variant. When `size="sm"` the font and padding values are
   * smaller which results in higher information density.
   * */
  size?: 'sm' | 'md';
  /**
   * If defined, displays an "Edit" action on the right side of the header, and
   * will be called when it's clicked.
   */
  onEdit?: MouseEventHandler<HTMLElement>;
  /**
   * If defined, displays a "Delete" action on the right side of the header, and
   * will be called when it's clicked.
   */
  onDelete?: MouseEventHandler<HTMLElement>;
  /**
   * Displays custom actions on the right side of the header, rendered before
   * the pre-defined "Edit" and "Delete" actions. Each action is either a
   * React element or a standard action with the following set of properties:
   *
   * - `Icon`: action's SVG icon. Use only icons imported from "@remote-com/norma/icons".
   * - `label`: text displayed after the icon.
   * - `onClick`: called when the action is clicked.
   * - `href`: alternatively if the action is a link
   */
  actions?: ActionType[];
  /**
   * Function to call when clicking a "missing details" element
   * If this function is not set, will fallback to the 'onEdit' fn
   */
  onMissingDetailsClick?: MouseEventHandler<HTMLElement>;
  /**
   * The title of the message that should be display in the feedback message below the header
   */
  messageTitle?: string;
  /**
   * The message content that should be display in the feedback message below the header. Message
   * will be rendered only when this prop value is passed
   */
  messageBody?: React.ReactElement | string;
  /**
   * Type of feedback message. Should be either INFO, WARNING, ERROR
   */
  messageType?: FeedbackMessageVariant;
  /**
   * Specifies whether the nested list(s) should be open by default.
   */
  isNestedListOpenByDefault?: boolean;
};

type InfoBlockActionButtonProps = {
  isSingle: boolean;
  Icon: React.ElementType;
  onClick?: MouseEventHandler<HTMLElement>;
  label: string;
  title?: string;
  tone: 'secondary' | 'primary' | 'destructive';
  ariaLabel?: string;
};

function isNilOrEmptyString(value?: unknown) {
  return value === undefined || value === null || value === '';
}

function InfoBlockActionButton({
  isSingle,
  Icon,
  onClick,
  label,
  title,
  tone,
  ariaLabel,
  ...props
}: InfoBlockActionButtonProps) {
  const isDefaultLabel = label === 'Edit' || label === 'Delete';
  const ariaLabelText = ariaLabel || (isDefaultLabel ? `${label} ${title}` : label);

  if (isSingle) {
    return (
      <Box>
        <StandaloneLink
          tone={tone || 'secondary'}
          IconBefore={Icon}
          onClick={onClick}
          aria-label={ariaLabelText}
          {...props}
        >
          {label}
        </StandaloneLink>
      </Box>
    );
  }

  return (
    <ButtonIcon
      Icon={Icon}
      tone={tone || 'secondary'}
      onClick={onClick}
      size="xs"
      variant="ghost"
      aria-label={ariaLabelText}
      label={label}
      {...props}
    />
  );
}

export function renderActions(
  {
    actions,
    onEdit,
    editLabel,
    onDelete,
    deleteLabel,
    title,
  }: Pick<ListItem, 'actions' | 'onEdit' | 'editLabel' | 'deleteLabel' | 'onDelete' | 'title'>,
  isHeader = false
) {
  const titleText =
    typeof title === 'object' && !Array.isArray(title) ? title.props?.children : title;
  const hasMultipleListItemActions =
    ((!!actions && actions?.length > 1) ||
      (actions?.length === 1 && (!!onEdit || !!onDelete)) ||
      (!!onEdit && !!onDelete)) &&
    !isHeader;
  return (
    <InfoBlockActions $isHeader={isHeader}>
      {actions?.map((action, index) => {
        if (isValidElement(action)) {
          return <Box key={index}>{action}</Box>;
        }

        const nonElementAction = action as NonElementActionType;
        const ariaLabelText = isHeader
          ? `${nonElementAction.label} ${titleText}`
          : `${nonElementAction.label} ${titleText}`;

        return (
          <InfoBlockActionButton
            key={`${nonElementAction.label}-${index}`}
            isSingle={!hasMultipleListItemActions}
            onClick={nonElementAction.onClick}
            title={titleText}
            tone={isHeader ? 'primary' : 'secondary'}
            {...nonElementAction}
            ariaLabel={ariaLabelText}
          />
        );
      })}

      {!!onEdit && (
        <InfoBlockActionButton
          isSingle={!hasMultipleListItemActions}
          Icon={IconV2OutlineEditUnderline}
          onClick={onEdit}
          label={editLabel ?? 'Edit'}
          title={titleText}
          tone={isHeader ? 'primary' : 'secondary'}
          key={`edit-${titleText}`}
        />
      )}
      {!!onDelete && (
        <InfoBlockActionButton
          isSingle={!hasMultipleListItemActions}
          Icon={IconV2OutlineTrash}
          onClick={onDelete}
          label={deleteLabel ?? 'Delete'}
          title={titleText}
          tone="destructive"
          key={`delete-${titleText}`}
        />
      )}
    </InfoBlockActions>
  );
}

function RenderValue({
  maskSecret,
  onValueClick,
  value,
  isOptional,
  copyValue,
  onEdit,
  onMissingDetailsClick,
  pillTone = 'error',
  isDeprecated,
}: ListItem & {
  onMissingDetailsClick: InfoBlockProps['onMissingDetailsClick'];
}) {
  const ref = useRef<HTMLElement>(null);
  const [isEllipsisActive, setEllipsisActive] = useState(false);

  useEffect(() => {
    function checkForEllipsis(element?: HTMLElement | null) {
      if (!element) return false;

      return element?.offsetWidth < element?.scrollWidth;
    }

    const isActive = checkForEllipsis(ref?.current);

    if (isActive) {
      setEllipsisActive(true);
    }
  }, []);

  const copyButton = !isNilOrEmptyString(copyValue) ? <CopyToClipboard value={copyValue} /> : null;

  if (isNilOrEmptyString(value)) {
    return isOptional ? (
      <InfoBlockListItemValueEmptyOptional>{EMPTY_INDICATOR}</InfoBlockListItemValueEmptyOptional>
    ) : (
      <PillStyled tone={pillTone} onClick={onMissingDetailsClick || onEdit}>
        Missing details
      </PillStyled>
    );
  }

  if (maskSecret) {
    return (
      <>
        <InfoBlockMaskSecretStyled revealN={maskSecret} onClick={onValueClick}>
          {value as string}
        </InfoBlockMaskSecretStyled>
        {copyButton}
      </>
    );
  }

  if (['string', 'number'].includes(typeof value)) {
    const Component = isEllipsisActive ? Tooltip : Fragment;
    return (
      <Component {...(isEllipsisActive ? { label: value } : {})}>
        {isDeprecated && (
          <Pill tone="neutralLight" mr={2}>
            Deprecated
          </Pill>
        )}
        <TextContentWithEllipsis ref={ref}>{value}</TextContentWithEllipsis>
        {copyButton}
      </Component>
    );
  }

  if (typeof value === 'function') {
    const Value = value as any;
    return (
      <>
        <Value />
        {copyButton}
      </>
    );
  }

  if (isValidElement(value)) {
    return (
      <>
        {isDeprecated && (
          <Pill tone="neutralLight" mr={2}>
            Deprecated
          </Pill>
        )}
        {value}
        {copyButton}
      </>
    );
  }

  if (Array.isArray(value)) {
    return (
      <>
        <span>{value.join(', ')}</span>
        {copyButton}
      </>
    );
  }

  if (typeof value === 'boolean') {
    return <Pill>{String(value)}</Pill>;
  }

  return <i>Error displaying value</i>;
}

function renderItem({
  item,
  onEdit,
  key,
  onMissingDetailsClick,
  id,
  isNestedListOpenByDefault,
  indentationLevel = 0,
}: {
  item: ListItem;
  onEdit: InfoBlockProps['onEdit'];
  key: string;
  onMissingDetailsClick: InfoBlockProps['onMissingDetailsClick'];
  id: string | number;
  isNestedListOpenByDefault: boolean;
  indentationLevel?: number;
}) {
  const hasDisplayActions = !!item.onEdit || !!item.onDelete || !!item.actions;

  if (
    Array.isArray(item.value) &&
    (item.value as unknown[]).every((subItem) => isPlainObject(subItem))
  ) {
    return (
      <InfoBlockListNestedItem key={key} open={isNestedListOpenByDefault}>
        <InfoBlockListNestedItemTitle>
          <InfoBlockListNestedItemTitleWrapper $indentationLevel={indentationLevel}>
            {item.title}
            <StyledIconChevronDown />
          </InfoBlockListNestedItemTitleWrapper>
          {item.topLevelValue && (
            <InfoBlockListNestedItemTopValue>{item.topLevelValue}</InfoBlockListNestedItemTopValue>
          )}
          {item.topLevelActions && (
            <InfoBlockListNestedItemActions $isHeader>
              {renderActions({
                actions: item.topLevelActions,
              })}
            </InfoBlockListNestedItemActions>
          )}
        </InfoBlockListNestedItemTitle>
        {(item.value as ListItem[]).map((nestedItem, index) =>
          renderItem({
            item: nestedItem,
            onEdit,
            key: `${key}-${index}`,
            id: `${id}-${index}`,
            onMissingDetailsClick,
            isNestedListOpenByDefault,
            indentationLevel: indentationLevel + 1,
          })
        )}
      </InfoBlockListNestedItem>
    );
  }

  return (
    <InfoBlockListItem data-list-item={item.tag} key={key} $indentationLevel={indentationLevel}>
      <InfoBlockListItemTitle>{item.title} </InfoBlockListItemTitle>
      <InfoBlockListItemValue>
        <RenderValue onEdit={onEdit} onMissingDetailsClick={onMissingDetailsClick} {...item} />
      </InfoBlockListItemValue>
      {hasDisplayActions &&
        renderActions({
          actions: item.actions,
          onEdit: item.onEdit,
          editLabel: item.editLabel,
          onDelete: item.onDelete,
          deleteLabel: item.deleteLabel,
          title: item.title,
        })}
    </InfoBlockListItem>
  );
}

export const InfoBlock = forwardRef(
  (
    {
      HeadingIcon,
      headingText,
      headingIconColor = DEFAULT_COLOR,
      list,
      size = 'md',
      onEdit,
      onDelete,
      actions,
      onMissingDetailsClick,
      isLoading,
      messageTitle,
      messageBody,
      messageType,
      isNestedListOpenByDefault = true,
      ...props
    }: InfoBlockProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const hasDisplayHeading = !!headingText || !!HeadingIcon;
    const hasDisplayHeadingWithIcon = !!headingText && !!HeadingIcon;
    const hasDisplayActions =
      !!onEdit || !!onDelete || (Array.isArray(actions) && !!actions?.length);
    const hasDisplayHeadingBlock = hasDisplayHeading || hasDisplayActions;

    return (
      <div {...props} ref={ref}>
        {hasDisplayHeadingBlock && (
          <InfoBlockHeading $justifyContent={hasDisplayHeading ? 'space-between' : 'flex-end'}>
            {hasDisplayHeading && HeadingIcon && (
              <HeadingIconComponent
                Icon={HeadingIcon}
                text={headingText}
                color={headingIconColor}
              />
            )}
            {hasDisplayHeading && !HeadingIcon && (
              <Text as="h2" variant="lgMedium">
                {headingText}
              </Text>
            )}
            {hasDisplayActions &&
              renderActions({ actions, onEdit, onDelete, title: headingText }, true)}
          </InfoBlockHeading>
        )}
        {isLoading && (
          <Stack
            ml={hasDisplayHeadingWithIcon ? 9 : 0}
            mt={7}
            gap={size === 'md' ? 7 : 5}
            data-testid="info-block-loading-skeleton"
          >
            {list?.length ? (
              // Match the number of lines for the loading state
              list.map((i, index) => <SkeletonLine key={`${i.title}-${index}`} />)
            ) : (
              <>
                <SkeletonLine />
                <SkeletonLine />
                <SkeletonLine />
              </>
            )}
          </Stack>
        )}
        {messageBody && (
          <StyledFeedbackMessage
            variant={messageType}
            title={messageTitle}
            $hasLeftMargin={hasDisplayHeadingWithIcon}
            $hasTopMargin={hasDisplayHeading}
          >
            {messageBody}
          </StyledFeedbackMessage>
        )}
        {!!list?.length && !isLoading && (
          <InfoBlockList
            $size={size}
            $hasLeftMargin={hasDisplayHeadingWithIcon}
            $hasTopMargin={hasDisplayHeading}
          >
            {list.map((item, index) =>
              renderItem({
                item,
                onEdit,
                key: `${item.title}-${index}`,
                // Since this table does not get added onto, we can safely use index here as an id
                id: index,
                onMissingDetailsClick,
                isNestedListOpenByDefault,
              })
            )}
          </InfoBlockList>
        )}
      </div>
    );
  }
);
