import { Root, Trigger } from '@radix-ui/react-dropdown-menu';
import type { ElementType, ReactNode } from 'react';
import { forwardRef, useRef } from 'react';
import type { CSSObject, FlattenSimpleInterpolation } from 'styled-components';

import { IconCheck } from '../../icons/build/IconCheck';
import { IconCopy } from '../../icons/build/IconCopy';
import { DropdownMenuList } from '../dropdown-menu';
import { Text } from '../text';
import { Tooltip } from '../tooltip';

import { CopyToClipboardButton, IconSvg, StyledMenuItem } from './CopyToClipboard.styled';
import { useCopyToClipboard } from './useCopyToClipboard';

export type CopyToClipboardProps = {
  /** Color of the copy/success icons. Default: var(--colors-primary) */
  color?: string;
  /** Value to be copied to the clipboard. Falls back to children if passed a string. */
  value?: string | string[] | number | number[];
  $styles?: FlattenSimpleInterpolation | CSSObject;
  onClick?: () => void;
  copyLabel?: string;
  copiedLabel?: string;
  /** The icon to be displayed in the initial 'copy' state. */
  icon?: ElementType;
  /** The icon to be displayed in the 'success' state after copying. */
  iconSuccess?: ElementType;
  iconPosition?: 'left' | 'right';
  /** Text of the CopyToClipboard button. */
  children?: ReactNode;
};

const CopyMenu = forwardRef(
  (
    {
      value,
      title,
      iconPosition,
      onSelect,
      Icon,
      children,
      ...rest
    }: {
      value: number[] | string[];
      title?: string;
      iconPosition: CopyToClipboardProps['iconPosition'];
      onSelect: (
        event?:
          | {
              preventDefault: any;
            }
          | undefined,
        val?: string
      ) => void;
      Icon: ReactNode;
      children: ReactNode;
    },
    ref
  ) => (
    <Root modal={false}>
      {value.length > 0 ? (
        <Trigger asChild>
          <CopyToClipboardButton
            ref={ref}
            data-dd-action-name="Copy to clipboard"
            title={title}
            $iconPosition={iconPosition}
            {...rest}
          >
            {children}
            {Icon}
          </CopyToClipboardButton>
        </Trigger>
      ) : (
        children
      )}

      <DropdownMenuList side="bottom" align="end">
        {value.map((val) => (
          <StyledMenuItem key={String(val)} onSelect={() => onSelect(undefined, String(val))}>
            <Text as="span" variant="sm" color="bayoux">
              Copy as{' '}
            </Text>
            <Text as="span" variant="sm">
              {val}
            </Text>
          </StyledMenuItem>
        ))}
      </DropdownMenuList>
    </Root>
  )
);

export function CopyToClipboard({
  value,
  children,
  color = 'var(--colors-primary)',
  copyLabel = 'Copy to clipboard',
  copiedLabel = 'Copied!',
  icon = IconCopy,
  iconSuccess = IconCheck,
  iconPosition = 'right',
  onClick,
  ...rest
}: CopyToClipboardProps) {
  const copiedValue = value?.toString() || (typeof children === 'string' ? children : '');
  const targetRef = useRef<HTMLButtonElement>(null);

  const { copied, onCopyClick } = useCopyToClipboard({
    value: copiedValue,
    onClick,
    targetRef,
  });

  const title = copied ? copiedLabel : copyLabel;

  const Icon = <IconSvg as={copied ? iconSuccess : icon} fill={color} />;

  return (
    <Tooltip label={title} type="caption">
      {Array.isArray(value) ? (
        <CopyMenu
          title={title}
          value={value}
          Icon={Icon}
          iconPosition={iconPosition}
          {...rest}
          ref={targetRef}
          onSelect={onCopyClick}
        >
          {children}
        </CopyMenu>
      ) : (
        <CopyToClipboardButton
          title={title}
          type="button"
          data-dd-action-name="Copy to clipboard"
          $iconPosition={iconPosition}
          {...rest}
          ref={targetRef}
          onClick={onCopyClick}
        >
          {children}
          {Icon}
        </CopyToClipboardButton>
      )}
    </Tooltip>
  );
}
