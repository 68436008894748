import { useFormikContext } from 'formik';

interface FormValues {
  [key: string]: unknown;
}

export function useFieldsetFieldErrors(name: keyof FormValues): string | undefined {
  const { errors } = useFormikContext<FormValues>();
  const error = errors[name];
  return error;
}
