import { transformPermissions } from '@/src/domains/account/helpers';
import { DOCUMENT_TYPES } from '@/src/domains/documents/constants';
// TODO: refactor using data-layer
// eslint-disable-next-line
import { makeApiService } from '@/src/services/ApiClient/ApiClient';
import { makeGet, makePut } from '@/src/services/ApiClient/functions/makeRequest';
import { VERSIONS } from '@/src/services/defaults';
import { authenticationMethod } from '@/src/views/dashboard/Company/constants';

export const fetchUserAccount = makeApiService({
  path: '/account',
});

const updateUserAccount = makeApiService({
  method: 'patch',
  path: '/account',
});

export async function getUser() {
  return fetchUserAccount().then(transformPermissions);
}

export function transformLoginsResponse(response) {
  return {
    loginWithPassword: response.data.logins.find(
      (profile) => profile.authenticationMethod === authenticationMethod.PASSWORD
    ),
    loginWithSSO: response.data.logins.find(
      (profile) => profile.authenticationMethod === authenticationMethod.SSO
    ),
  };
}

export async function getTwoFactorReminderInfo() {
  const response = await makePut('/api/v1/account/two-factor/optional-enrollment-reminder');
  return response;
}

export async function getLogins() {
  const response = await makeGet('/api/v1/account/logins');

  return transformLoginsResponse(response);
}

export function updateUser(params) {
  const { profilePicture, ...paramsWithoutProfilePicture } = params;
  // If `profilePicture` is empty or undefined, it means that the user would like
  // to keep the existing one - do not send this parameter along.
  const bodyParams = profilePicture ? params : paramsWithoutProfilePicture;

  return updateUserAccount({ bodyParams }).then(transformPermissions);
}

export const validatePersonalEmail = makeApiService({
  method: 'post',
  path: '/account/email/validation',
});

export const signIn = makeApiService({
  method: 'post',
  path: '/session',
  asFormData: false,
  skipCustomAuthInterceptor: true,
});

export const getUserProfiles = makeApiService({
  method: 'get',
  path: '/user-profiles',
  version: VERSIONS.API_V2,
  asFormData: false,
});

export const selectUserProfile = makeApiService({
  method: 'post',
  path: '/session/user-profiles',
  asFormData: false,
});

export const validateSignInSSO = makeApiService({
  method: 'post',
  path: '/sso/callback',
  asFormData: false,
  skipCustomAuthInterceptor: true,
  timeout: 10000, // testing a longer timeout for SSO
});

export const signInSSO = makeApiService({
  method: 'post',
  path: '/sso/redirect',
});

export const deleteSession = makeApiService({
  method: 'delete',
  path: '/session',
});

export const signUp = makeApiService({
  method: 'post',
  path: '/registration',
  asFormData: false,
});

export const recoverPassword = makeApiService({
  method: 'post',
  path: '/reset-password',
  asFormData: false,
});

export const resetPassword = makeApiService({
  method: 'patch',
  path: '/reset-password',
  asFormData: false,
});

export const setPassword = makeApiService({
  method: 'put',
  path: '/registration/password',
  asFormData: false,
});

export const getHomeAddressDetails = makeApiService({
  method: 'get',
  path: '/account/home-address',
});

export const updateHomeAddress = makeApiService({
  method: 'post',
  path: '/account/home-address',
  asFormData: false,
});

export const getEmployeePersonalDetails = makeApiService({
  path: '/account/personal-details',
});

export const updatePersonalDetails = makeApiService({
  method: 'post',
  path: '/account/personal-details',
  asFormData: false,
});

export const getRecentInquiryForPersona = makeApiService({
  method: 'get',
  path: '/identity-verification/recent-inquiry-with-token',
});

export const getValidInquiryForPersona = makeApiService({
  method: 'get',
  path: '/identity-verification/inquiry-with-token/[inquiryId]',
});

export const updateContractorBusinessInfo = makeApiService({
  method: 'post',
  path: '/account/business-info',
  asFormData: false,
});

export const updateEmergencyContact = makeApiService({
  method: 'post',
  path: '/account/emergency-contact',
  asFormData: false,
});

export const updateAdministrativeDetails = makeApiService({
  method: 'post',
  path: `/account/contracts/[contractSlug]/administrative-details`,
  asFormData: false,
});

const uploadPersonalFilesService = makeApiService({
  method: 'post',
  path: '/account/upload-personal-files',
});

/**
 * Upload Additional documents, used on onboarding
 * @param {Object} fields
 */
export async function uploadPersonalFiles(fileObjects) {
  if (!fileObjects) {
    return uploadPersonalFilesService();
  }

  const files = fileObjects?.map(({ file, subType, type, description }) => ({
    file,
    subType,
    description: description || null,
    type: type || DOCUMENT_TYPES.ID,
  }));

  return uploadPersonalFilesService({ bodyParams: { files } });
}
