import { Text, Stack, Box } from '@remote-com/norma';
import { IconV2OutlineTrash } from '@remote-com/norma/icons/IconV2OutlineTrash';
import { Formik } from 'formik';
import { useState } from 'react';
import { object } from 'yup';

import { Button } from '@/src/components/Button';
import { ErrorSummary } from '@/src/components/Form/ErrorSummary';
import { InputField } from '@/src/components/Ui/Form';
import { FieldsetField } from '@/src/components/Ui/Form/formikIntegration/FieldsetField/FieldsetField';
import { SelectField } from '@/src/components/Ui/Form/formikIntegration/SelectField';
import { InputController } from '@/src/components/Ui/Form/Input';
import {
  CONDITIONAL_NODE_TYPE_LABELS,
  CONDITIONAL_OPERATORS,
} from '@/src/domains/contracts/ContractsEditor/constants';
import { operatorOptions } from '@/src/domains/contracts/ContractsEditor/nodes/Conditionals/helpers';
import { comparisonValueTypes } from '@/src/domains/contractTemplates/contractTemplates/modals/ConditionalModal/constants';
import {
  getComparisonValueType,
  getComparisonConstantOrSmartField,
  hasComparisonValue,
  hasComparisonSmartField,
  hasMultipleComparisonValues,
} from '@/src/domains/contractTemplates/contractTemplates/modals/ConditionalModal/helpers';
import {
  OptionSmartField,
  GroupLabel,
  getDisplayableSmartFields,
} from '@/src/domains/contractTemplates/contractTemplates/modals/SmartFieldModal/helpers';
import { baseString, genericRequiredLabel } from '@/src/helpers/validationSchema';

const validationSchema = object().shape({
  smartField: baseString.required(genericRequiredLabel),
  operator: baseString.required(genericRequiredLabel),
  comparisonValueType: baseString.when('operator', {
    is: (operator) => hasComparisonValue(operator),
    then: baseString.required(genericRequiredLabel),
    otherwise: baseString,
  }),
  comparisonConstant: baseString.when(['operator', 'comparisonValueType'], {
    is: (operator, comparisonValueType) =>
      hasComparisonValue(operator) && comparisonValueType === comparisonValueTypes.CONSTANT,
    then: baseString.required(genericRequiredLabel),
    otherwise: baseString,
  }),
  comparisonSmartField: baseString.when(['operator', 'comparisonValueType'], {
    is: (operator, comparisonValueType) =>
      hasComparisonSmartField(operator) && comparisonValueType === comparisonValueTypes.SMARTFIELD,
    then: baseString.required(genericRequiredLabel),
    otherwise: baseString,
  }),
});

export const ConditionalModal = ({
  currentValues = {},
  handleModalSubmit,
  editorStorage,
  conditionalType,
  formName,
  handleRemoveConditional,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const initialValues = {
    smartField: currentValues.fields?.[0] ?? '',
    operator: currentValues.operator ?? CONDITIONAL_OPERATORS.EQUAL,
    comparisonValueType:
      getComparisonValueType(currentValues.fields?.[1]) || comparisonValueTypes.CONSTANT,
    comparisonConstant: '',
    comparisonSmartField: '',
    ...getComparisonConstantOrSmartField(currentValues.fields?.[1]),
  };

  const smartFieldOptions = getDisplayableSmartFields(editorStorage);

  const modalConditionalType = currentValues?.conditionalType
    ? CONDITIONAL_NODE_TYPE_LABELS[currentValues.conditionalType]
    : CONDITIONAL_NODE_TYPE_LABELS[conditionalType];

  const onSubmit = (values) => {
    setErrorMessage('');

    try {
      handleModalSubmit(values);
    } catch (_err) {
      setErrorMessage(
        'Something went wrong, please try again. If this problem persists, please get in touch with support.'
      );
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <form id={formName} data-testid={formName} onSubmit={handleSubmit}>
          <Text variant="sm">{`You are editing a ${modalConditionalType.toLowerCase()}`}</Text>
          <Box mt={7}>
            <InputController>
              <SelectField
                name="smartField"
                label="Smartfield"
                description="Smart field that best describes the starting point of the operation"
                options={smartFieldOptions}
                transformValue={(option) => option.value}
                components={{ Option: OptionSmartField }}
                formatGroupLabel={GroupLabel}
              />
            </InputController>
          </Box>
          <Box mx={7} mt={7} mb={4}>
            <FieldsetField
              name="conditionalParameters"
              label="Depending on"
              description="Edit the information that complements the field above"
            >
              <InputController>
                <SelectField
                  name="operator"
                  label="Operator"
                  options={operatorOptions}
                  transformValue={(option) => option.value}
                  onChange={(option) =>
                    [CONDITIONAL_OPERATORS.IN, CONDITIONAL_OPERATORS.NOT_IN].includes(option.value)
                      ? setFieldValue('comparisonValueType', comparisonValueTypes.CONSTANT)
                      : null
                  }
                />
              </InputController>
              <Stack direction="row" gap={3}>
                {hasComparisonValue(values.operator) &&
                  !hasMultipleComparisonValues(values.operator) && (
                    <Box mt={6} width="240px">
                      <InputController>
                        <SelectField
                          name="comparisonValueType"
                          data-testid="comparison-value-type"
                          label="Type"
                          direction="row"
                          options={[
                            { value: comparisonValueTypes.CONSTANT, label: 'Value' },
                            { value: comparisonValueTypes.SMARTFIELD, label: 'Smartfield' },
                          ]}
                          transformValue={(option) => option.value}
                        />
                      </InputController>
                    </Box>
                  )}
                {values.comparisonValueType === comparisonValueTypes.SMARTFIELD &&
                  hasComparisonValue(values.operator) &&
                  !hasMultipleComparisonValues(values.operator) && (
                    <Box mt={6} width="100%">
                      <InputController>
                        <SelectField
                          name="comparisonSmartField"
                          label="Smartfield"
                          options={smartFieldOptions}
                          transformValue={(option) => option.value}
                          components={{ Option: OptionSmartField }}
                          formatGroupLabel={GroupLabel}
                        />
                      </InputController>
                    </Box>
                  )}
                {values.comparisonValueType === comparisonValueTypes.CONSTANT &&
                  hasComparisonValue(values.operator) && (
                    <Box mt={6} width="100%">
                      <InputController>
                        <InputField
                          name="comparisonConstant"
                          label="Value"
                          placeholder="Value1|Value2"
                          description={
                            hasMultipleComparisonValues(values.operator)
                              ? 'Separate values with a vertical bar, for example: "Value1|Value2"'
                              : ''
                          }
                        />
                      </InputController>
                    </Box>
                  )}
              </Stack>
            </FieldsetField>
          </Box>
          {handleRemoveConditional && (
            <Button
              mt={3}
              variant="ghost"
              tone="destructive"
              IconBefore={IconV2OutlineTrash}
              onClick={handleRemoveConditional}
            >
              Remove conditional
            </Button>
          )}{' '}
          <ErrorSummary errorMessage={errorMessage} />
        </form>
      )}
    </Formik>
  );
};
