// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineBriefcase = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M7.3 5.25c-.6 0-1.13.02-1.59.06-.71.06-1.32.18-1.87.46a4.75 4.75 0 0 0-2.07 2.07c-.28.55-.4 1.16-.46 1.87-.06.7-.06 1.56-.06 2.66v3.26c0 1.1 0 1.96.06 2.66.06.71.18 1.32.46 1.87.45.89 1.18 1.62 2.07 2.07.55.28 1.16.4 1.87.46.7.06 1.56.06 2.66.06h7.26c1.1 0 1.96 0 2.66-.06a4.88 4.88 0 0 0 1.87-.46 4.75 4.75 0 0 0 2.07-2.07c.28-.55.4-1.16.46-1.87.06-.7.06-1.56.06-2.66v-3.26c0-1.1 0-1.96-.06-2.66a4.88 4.88 0 0 0-.46-1.87 4.75 4.75 0 0 0-2.07-2.07 4.88 4.88 0 0 0-1.87-.46 22.2 22.2 0 0 0-1.6-.06 4.75 4.75 0 0 0-9.38 0Zm1.54 0h6.32a3.25 3.25 0 0 0-6.32 0Zm-3 1.55c-.63.05-1.01.15-1.32.3-.6.32-1.1.81-1.42 1.42-.15.3-.25.7-.3 1.31l-.03.47.03.01.03.01a15.33 15.33 0 0 0 1.47.56c.77.26 1.78.56 2.95.82V11a.75.75 0 0 1 1.5 0v.98c1.03.17 2.13.27 3.25.27s2.22-.1 3.25-.27V11a.75.75 0 0 1 1.5 0v.7a27.91 27.91 0 0 0 4.43-1.38h.01l.04.07v-.09a.75.75 0 0 1 .57 1.39l-.3-.69.3.69-.03.01a8.07 8.07 0 0 1-.52.21c0-.6 0-1.1-.02-1.52M2.75 11.91V15.6c0 1.13 0 1.94.05 2.57.05.62.15 1 .3 1.3.32.62.81 1.11 1.42 1.43.3.15.7.25 1.31.3.63.05 1.44.05 2.57.05h7.2c1.13 0 1.94 0 2.57-.05.62-.05 1-.15 1.3-.3.62-.32 1.11-.81 1.43-1.42.15-.3.25-.7.3-1.31.05-.63.05-1.44.05-2.57V11.91l-1.07.4c-.88.29-2.06.64-3.43.92V14a.75.75 0 0 1-1.5 0v-.5a22.3 22.3 0 0 1-6.5 0v.5a.75.75 0 0 1-1.5 0v-.77a29.36 29.36 0 0 1-4.5-1.32Zm18.48-1.6h-.03.03Zm0 0-.03-.48c-.05-.62-.15-1-.3-1.3-.32-.62-.81-1.11-1.42-1.43-.3-.15-.7-.25-1.31-.3-.63-.05-1.44-.05-2.57-.05H8.4c-1.13 0-1.94 0-2.57.05" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineBriefcase);
export { ForwardRef as IconV2OutlineBriefcase };