import PropTypes from 'prop-types';

import { ButtonInline } from '@/src/components/Button';
import UserCan from '@/src/components/UserCan';

export function RestrictedLink({ permissions, href, children, ...linkProps }) {
  return (
    <UserCan permissions={permissions}>
      {({ hasAccess }) =>
        hasAccess ? (
          <ButtonInline href={href} {...linkProps}>
            {children}
          </ButtonInline>
        ) : (
          children
        )
      }
    </UserCan>
  );
}

RestrictedLink.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.array).isRequired,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string.isRequired, query: PropTypes.shape({}) }),
  ]).isRequired,
  children: PropTypes.node.isRequired,
};
