import { Stack, Box } from '@remote-com/norma';
import styled, { css } from 'styled-components';

import { CheckboxState as InputCheckboxState, CHECKBOX_STATE_SIZE } from '@/src/components/Ui/Form';

export const Wrapper = styled(Stack).attrs({
  direction: 'row',
  gap: 4,
  // Align the checkbox and its body along the text baseline: this allows
  // to render both single- and multi-line `InputCheckbox` with input box
  // right across the body's first line.
  alignItems: 'baseline',
})``;

export const Input = styled(Box)`
  flex-shrink: 0;
`;

export const Body = Box;

export const CheckboxState = InputCheckboxState;

/**
 * Exposes the Checkbox State dimension as a CSS variable.
 */
export const checkboxStateSize = css`
  --checkboxStateSize: ${() => CHECKBOX_STATE_SIZE}px;
`;
