import { Tooltip, Pill } from '@remote-com/norma';
import styled from 'styled-components';

import {
  type ContractorSubscriptionPlanIdentifier,
  contractorSubscriptionPlanIdentifiers,
  contractorSubscriptionPlanLabels,
  contractorSubscriptionPlanPillProps,
  contractorSubscriptionPlanTooltipLabel,
} from '../constants';

const IndicatorPill = styled(Pill)`
  text-wrap: nowrap;
`;

export function ContractorProductIndicator({
  tooltipLabel = '',
  type = contractorSubscriptionPlanIdentifiers.STANDARD,
  isDisabled = false,
}: {
  tooltipLabel?: string;
  type: ContractorSubscriptionPlanIdentifier;
  isDisabled?: boolean;
}) {
  return (
    <Tooltip label={tooltipLabel || contractorSubscriptionPlanTooltipLabel[type]}>
      <IndicatorPill
        data-testid="contractor-product-indicator"
        tone={isDisabled ? 'neutralLight' : contractorSubscriptionPlanPillProps[type]?.tone}
      >
        {contractorSubscriptionPlanLabels[type]}
      </IndicatorPill>
    </Tooltip>
  );
}
