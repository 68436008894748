import 'focus-visible';
import '@remote-com/norma/styles.css'; // fonts imports

import { initRudderStack, setupAnalyticsConfig, setupDatadogRum } from '@remote-com/analytics';
import { ApiServiceProvider } from '@remote-com/data-layer';
import cls from 'cls-hooked';
import Cookies from 'js-cookie';
import App from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';
import { createPortal } from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import { setupDataLayer } from '@/src/api/config';
import { AppErrorBoundary } from '@/src/components/AppErrorBoundary';
import { BrowserContextProvider } from '@/src/components/BrowserContext';
import { Favicon } from '@/src/components/Favicon/Favicon';
import { setForcedSSRFlagsInDevMode } from '@/src/components/FeatureFlag';
import { LazyURLModal } from '@/src/components/Modal/LazyURLModal';
import UserProvider from '@/src/components/UserProvider';
import { queryClient } from '@/src/config/queryClient';
import { GlobalStyles, themeV2 as theme } from '@/src/config/styles';
import { DASHBOARD_ROUTE } from '@/src/constants/routes';
import AnalyticsWrapper from '@/src/domains/analytics/AnalyticsWrapper';
import { BroadcastMessageHandler } from '@/src/domains/broadcast/BroadcastMessageHandler';
import { generateNonce } from '@/src/domains/contentSecurityPolicy/helpers';
import {
  applyForcedFlagsForDevMode,
  createFeatureFlagConfig,
} from '@/src/domains/feature-flag/config';
import { EmployFeatureFlagProvider } from '@/src/domains/feature-flag/host/host';
import { UploadConfig } from '@/src/domains/files/UploadConfig';
import { findTeamByRoute } from '@/src/domains/governance/utils';
import { ImpersonatedBanner } from '@/src/domains/impersonation/ImpersonatedBanner';
import { PowerSearchContextProvider } from '@/src/domains/powerSearch/PowerSearchContext';
import { ProductUsageAndAccessContextProvider } from '@/src/domains/productUsageAndAccess';
import { AccessTokenProvider, SCOPE } from '@/src/domains/registration/auth/AccessTokenProvider';
import { CleanOAPFromUrl } from '@/src/domains/registration/auth/CleanOAPFromUrl';
import { permissionsSignatureCookieName } from '@/src/domains/registration/auth/constants';
import {
  isAdmin,
  isCandidateMissingEmailConfirmation,
  isEmployerMissingEmailConfirmation,
} from '@/src/domains/registration/auth/helpers';
// DO NOT IMPORT setupAPIMocksForDev FROM '@/src/domains/remoteControlPanel/tools/api-mocks' as it serves as a barrel file.
import { setupAPIMocksForDev } from '@/src/domains/remoteControlPanel/tools/api-mocks/utils';
import { ReturnPathQueryHandler } from '@/src/domains/returnPath/ReturnPathQueryHandler';
import { WebSocketProvider } from '@/src/domains/websockets/WebSocketProvider';
import { isPublicEnvironment, staticAssetUrl } from '@/src/helpers/general';
import DefaultLayout from '@/src/layouts/Default';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { makeApiService } from '@/src/services/ApiClient';

const Toaster = dynamic(() => import('@remote-com/norma').then((c) => c.Toaster), {
  ssr: false,
});

const ConfirmEmailModal = dynamic(
  () =>
    import('@/src/domains/registration/onboarding/company/two-part-signup/ConfirmEmailModal').then(
      (c) => c.ConfirmEmailModal
    ),
  {
    ssr: false,
  }
);

const RemoteControlPanel = dynamic(
  () =>
    import('@/src/domains/remoteControlPanel/RemoteControlPanel').then((c) => c.RemoteControlPanel),
  {
    ssr: false,
  }
);

const {
  publicRuntimeConfig: {
    ENVIRONMENT,
    DATADOG_RUM_ENABLED,
    DATADOG_SESSION_REPLAY_ENABLED,
    DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    API_BASE_URL_CLIENT,
    TALENT_API_BASE_URL,
    RUDDERSTACK_WRITE_KEY,
    RUDDERSTACK_DATAPLANE_URL,
    RUDDERSTACK_ENABLED,
  },
} = getConfig();

const isServer = typeof window === 'undefined';

if (isServer) {
  cls.createNamespace('context');
}

setupAPIMocksForDev();
setupDataLayer();
setupAnalyticsConfig({
  DATADOG_RUM_ENABLED,
});

// eslint-disable-next-line no-underscore-dangle
const _getInitialProps = async ({ Component, ctx }) => {
  // Generate cryptographic `nonce` attribute that will be used to mark all application
  // `script` and `style` tags, see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/nonce
  ctx.nonce = generateNonce();

  // Get layout props
  let layoutProps = {};
  const { Layout, Parent } = Component;
  if (Layout && Layout.getInitialProps) {
    layoutProps = await Layout.getInitialProps(ctx);
  }

  const isInternalEnvironment = !isPublicEnvironment();

  // this exposes the query to the layout and child component
  layoutProps.query = ctx.query;

  // spreads additional layout props defined at the page level
  layoutProps = { ...layoutProps, ...Component.layoutProps };

  // Get component props
  let pageProps = {};

  // writableEnded prevents a follow up request when a redirect happened on Layout.getInitialProps
  const isBrowser = typeof window !== 'undefined';
  const { statusCode } = ctx?.res || {};

  if (Component.getInitialProps && (isBrowser || statusCode === 200)) {
    pageProps = await Component.getInitialProps(ctx, layoutProps);
  }

  // Feature flag config must be available before rendering the page.
  // The config here is the same for all users, so this should be fast.
  let featureFlagConfig = {};
  const { config, error } = await createFeatureFlagConfig();
  featureFlagConfig = config;

  // If on dev mode and cookies are available, force feature flag SSR values based on them
  if (isInternalEnvironment && ctx?.req?.cookies) {
    featureFlagConfig = applyForcedFlagsForDevMode(featureFlagConfig, ctx.req.cookies, ENVIRONMENT);
    // Doing the same, but for the legacy system
    setForcedSSRFlagsInDevMode(ctx.req.cookies);
  }

  return {
    featureFlags: {
      config: featureFlagConfig,
      error,
    },
    Layout,
    Parent,
    layoutProps,
    pageProps: { ...pageProps, nonce: ctx.nonce },
  };
};

class Employ extends App {
  static async getInitialProps({ Component, ctx }) {
    if (isServer) {
      const contextNamespace = cls.getNamespace('context');

      return contextNamespace.runAndReturn(async () => {
        contextNamespace.set('cookie', ctx.req.headers.cookie);
        const props = await _getInitialProps({ Component, ctx });
        return props;
      });
    }

    const props = await _getInitialProps({ Component, ctx });
    return props;
  }

  componentDidMount() {
    const { layoutProps } = this.props;
    const permissionsSignature = layoutProps.user?.permissionsSignature;

    // This cookies is used to store a signature of the user permissions sent by Tiger.
    // This is used for deciding on escalating or suppressing permissions mismatch errors.
    // See https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/30170 for details
    if (permissionsSignature) {
      Cookies.set(permissionsSignatureCookieName, permissionsSignature, {
        sameSite: 'lax',
        expires: 30,
      });
    }

    initRudderStack({ RUDDERSTACK_WRITE_KEY, RUDDERSTACK_DATAPLANE_URL, RUDDERSTACK_ENABLED });

    if (DATADOG_RUM_ENABLED) {
      setupDatadogRum({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        service: 'dragon',
        env: ENVIRONMENT,
        version: process.env.RELEASE,
        allowedTracingUrls: [
          { match: API_BASE_URL_CLIENT, propagatorTypes: ['datadog', 'tracecontext'] },
          { match: TALENT_API_BASE_URL, propagatorTypes: ['tracecontext'] },
        ],
        startSessionReplayRecording: DATADOG_SESSION_REPLAY_ENABLED && layoutProps.user,
        beforeSend: (event, context) => {
          const errorCode = context?.error?.code;
          if (errorCode) {
            event.context.errorCode = errorCode;
          }
          // If no ownership is provided, we try to find it by the current route.
          if (event.type === 'error' && !event.context.ownership) {
            const routeOwner = findTeamByRoute({ route: Router.pathname, query: Router.query });
            event.context.ownership = routeOwner;
          }
        },
      });
    }
  }

  handleGlobalForceUpdate = () => {
    this.forceUpdate();
  };

  render() {
    const { featureFlags, Layout, Parent, layoutProps, Component, pageProps, router } = this.props;

    // Component.Layout is accessed when in browser
    // Layout is provided when in SSR
    const ComponentLayout = Component.Layout || Layout || DefaultLayout;
    const ParentComponent = Component.Parent || Parent || DefaultLayout;

    const user = layoutProps.user || pageProps.user;

    // For two-part signup we want to show the dashboard but block user from any action
    // by displaying the email confirmation modal
    const shouldDisplayEmailConfirmationModal =
      (isEmployerMissingEmailConfirmation(user) ||
        // Candidate users should also be shown this modal, without it, a user can bypass our account confirmation email
        isCandidateMissingEmailConfirmation(user)) &&
      router.pathname.includes(DASHBOARD_ROUTE);

    return (
      <>
        <Head>
          <title key="page-title">Remote</title>
          <link rel="manifest" href="/manifest.json" />
        </Head>
        <Favicon />
        <Script
          nonce={pageProps.nonce}
          id="release-version"
          dangerouslySetInnerHTML={{
            __html: `window.__RELEASE_VERSION = "${process.env.RELEASE}"`,
          }}
        />
        {/* The following snippet is required to prevent
          https://remote-com.slack.com/archives/C013NGS9G7K/p1639761780068700 */}
        <Script
          nonce={pageProps.nonce}
          id="prevent-submit"
          src={staticAssetUrl(`/scripts/prevent-submit.js`)}
        />

        <ApiServiceProvider apiService={makeApiService}>
          <EmployFeatureFlagProvider
            config={featureFlags.config}
            account={user}
            error={featureFlags.error}
          >
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <BroadcastMessageHandler />
              <ReturnPathQueryHandler />
              <CleanOAPFromUrl />

              <AppErrorBoundary>
                <BrowserContextProvider>
                  <QueryClientProvider client={queryClient}>
                    <WebSocketProvider user={user}>
                      <UserProvider user={user}>
                        <ProductUsageAndAccessContextProvider>
                          <PowerSearchContextProvider>
                            <AnalyticsWrapper />
                            <NextNProgress
                              color={theme.colors.cyan[700]}
                              // Do not trigger loading animation on `shallow: true` route
                              // changes.
                              showOnShallow={false}
                            />
                            {!isServer && createPortal(<Toaster />, document.body)}
                            <UploadConfig user={layoutProps.user} />
                            <AccessTokenProvider
                              scope={isAdmin(user) ? SCOPE.TALENT_ADMIN : SCOPE.TALENT}
                            >
                              <ComponentLayout {...layoutProps}>
                                <ParentComponent>
                                  <ImpersonatedBanner />
                                  <Component {...pageProps} />
                                  <LazyURLModal />
                                  {!isPublicEnvironment() && (
                                    <RemoteControlPanel
                                      onGlobalForceUpdate={this.handleGlobalForceUpdate}
                                    />
                                  )}
                                </ParentComponent>
                              </ComponentLayout>
                              {shouldDisplayEmailConfirmationModal ? (
                                <ConfirmEmailModal isVisible />
                              ) : null}
                            </AccessTokenProvider>
                            <ReactQueryDevtools initialIsOpen={false} />
                          </PowerSearchContextProvider>
                        </ProductUsageAndAccessContextProvider>
                      </UserProvider>
                    </WebSocketProvider>
                  </QueryClientProvider>
                </BrowserContextProvider>
              </AppErrorBoundary>
            </ThemeProvider>
          </EmployFeatureFlagProvider>
        </ApiServiceProvider>
      </>
    );
  }
}

export default Employ;
