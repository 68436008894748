import { Box } from '@remote-com/norma';
import styled from 'styled-components';

import { friendlyLabel } from '@/src/helpers/general';

export const isTextAlignedRight = (align: string | undefined) => align === 'right';

export const AlignRight = styled(Box)`
  text-align: right;
`;

type Props = {
  value: string | number | null | undefined;
  column?: {
    align: string;
  };
};

export const TextCell = ({ value, ...rest }: Props) => {
  const { column: { align } = {} } = rest;
  const stringValue = typeof value === 'number' ? value.toString() : value;
  const label = friendlyLabel(stringValue);

  if (isTextAlignedRight(align)) {
    return <AlignRight>{label}</AlignRight>;
  }
  return <>{label}</>;
};
