import { employerBillingEvents, trackEvent } from '@remote-com/analytics';
import { FeedbackMessage } from '@remote-com/norma';
import { parseJSON, addMinutes } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import isFunction from 'lodash/isFunction';

import { ButtonInline } from '@/src/components/Button';
import { RestrictedLink } from '@/src/components/RestrictedLink';
import { ADMIN_BILLING_ROUTE } from '@/src/constants/routes';
import { billingDocumentTypes, billingTabs } from '@/src/domains/billing/constants';
import { isBillingDocumentDashboard } from '@/src/domains/billing/shared/hooks/useBillingDashboardWidget';
import { invoiceDisplayTypesLabel, invoiceDisplayTypes } from '@/src/domains/invoices/constants';
import { getInvoiceReportDocumentTypeLabel } from '@/src/domains/invoices/shared/helpers';
import { legalEntityStatus } from '@/src/domains/kyb/constants';
import { paymentStatus } from '@/src/domains/payments/constants';
import {
  isContractorReceiptPaymentType,
  isPayrollRunPaymentType,
} from '@/src/domains/payments/shared/helpers';
import { getPaymentStatusBadgeProps } from '@/src/domains/payments/status';
import { USER_TYPE } from '@/src/domains/registration/auth/constants';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { convertToCents } from '@/src/helpers/currency';
import { dateFormatterPattern } from '@/src/helpers/validationSchema';

export function readInvoiceContent(file) {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsText(file);
  });
}

export async function getInvoiceFileMetadata(file) {
  const fileContent = await readInvoiceContent(file);

  // Finds the metadata header within the file content
  /*
    <<
    /Type /Metadata
    /Subtype /XML
    /Length 00000
    >>
    stream
    */
  const findMetadataRegExp =
    /<<\s*\/Type\s*\/Metadata\s*\/Subtype\s*\/XML\s*\/Length\s*([\d]{1,5})\s>>\sstream\s/im;
  const metadataLocation = fileContent.match(findMetadataRegExp);

  // File does not have XML Metadata
  if (metadataLocation === null) {
    return null;
  }

  const metadataHeaderLength = metadataLocation[0].length;
  const metadataStart = metadataLocation.index + metadataHeaderLength;
  const metadataLength = parseInt(metadataLocation[1], 10);
  const metadataString = fileContent.substr(metadataStart, metadataLength);

  const parser = new DOMParser();
  const xmlContent = parser.parseFromString(metadataString, 'application/xml');

  // In sync with exported fields from google sheets
  const metadataFields = [
    'number',
    'currency',
    'amount',
    'due',
    'to',
    'from',
    'description',
    'issue',
    'json',
  ];
  const prefix = 'invoice';

  // Extracts the content from the parsed DOM elements
  const metadataEntries = metadataFields.map((field) => {
    const elementTag = `${prefix}:${field}`;
    const elements = xmlContent.getElementsByTagName(elementTag);
    const firstElement = elements.item(0);
    let value;
    if (firstElement) {
      value = firstElement.innerHTML;
    }

    // All the content also comes as a single JSON field
    // so we get the data as raw as possible and we convert it ourselves, i.e. dates
    if (field === 'json') {
      value = JSON.parse(value);
    }
    return [field, value];
  });

  return Object.fromEntries(metadataEntries);
}

export function formatInvoiceDate(isoDate, timezoneOffsetInMinutes) {
  let parsedDate = parseJSON(isoDate);
  parsedDate = utcToZonedTime(parsedDate, 'Z');
  if (timezoneOffsetInMinutes) {
    parsedDate = addMinutes(parsedDate, timezoneOffsetInMinutes);
  }
  return format(parsedDate, dateFormatterPattern, { timeZone: 'Z' });
}

export async function setInvoiceFormData({
  file,
  invoiceMetadata,
  handleCompanyChange,
  getEntityOptionByName,
  findCompanyByName,
  values,
  setValues,
  setTouched,
  setFieldError,
}) {
  const {
    amount = '',
    description = '',
    due: dueDate,
    dueTimezoneOffset,
    from: legalEntityLabel,
    to: companyLabel,
  } = invoiceMetadata.json;

  const errors = {};

  const newValues = {
    ...values,
    file,
    amount,
    description,
    status: paymentStatus.CREATED,
  };

  if (dueDate) {
    try {
      newValues.dueDate = formatInvoiceDate(dueDate, dueTimezoneOffset);
    } catch {
      errors.dueDate = `Invalid date "${dueDate}" provided`;
    }
  }

  const legalEntity = await getEntityOptionByName(legalEntityLabel);
  if (legalEntity) {
    newValues.remoteEntitySlug = legalEntity.value;
  } else if (legalEntityLabel) {
    errors.remoteEntitySlug = `"${legalEntityLabel}" not found`;
  }

  const company = await findCompanyByName(companyLabel);
  if (company) {
    newValues.companySlug = company.slug;
  } else if (companyLabel) {
    errors.companySlug = `"${companyLabel}" not found`;
  }

  setValues(newValues);

  // Set touched fields so errors are displayed when filling metadata
  setTouched(
    Object.entries(newValues).reduce((output, [field]) => {
      output[field] = true;
      return output;
    }, {})
  );

  // Ensure our custom error messages remain after formik validates the data on setValues and setTouched
  setTimeout(() => {
    Object.entries(errors).forEach(([field, error]) => setFieldError(field, error));
  }, 0);

  // Simulate companySlug onChange
  if (values.companySlug !== newValues.companySlug) {
    handleCompanyChange(company);
  }
}

export const getOutstandingPaymentInvoice = (outstandingPayment) => {
  const { type, remoteInvoices, transactionReceipts } = outstandingPayment;
  const isRemoteInvoice = isPayrollRunPaymentType(type);
  const invoices = isRemoteInvoice ? remoteInvoices : transactionReceipts;
  return invoices?.[0];
};

export const getLegalEntityLabel = (legalEntity) =>
  `${legalEntity?.name} (${legalEntity?.address?.country?.name})`;

export const getLegalEntityLabelWithArchivedStatus = (legalEntity) =>
  `${legalEntity?.name} (${legalEntity?.address?.country?.name})${
    legalEntity?.status === legalEntityStatus.ARCHIVED ? ' (Archived)' : ''
  }`;

export function getStatusBadgeByAmount(status, amount, userRole) {
  const role = userRole || USER_TYPE.ADMIN;
  const cellStatus =
    amount < 0 && status === paymentStatus.CREATED ? paymentStatus.CREDIT_DUE : status;
  return getPaymentStatusBadgeProps(cellStatus, role);
}

export const getInvoiceDisplayTypeLabel = ({ type, invoiceReport, isAdminUser }) => {
  if (isContractorReceiptPaymentType(type)) {
    return invoiceDisplayTypesLabel[invoiceDisplayTypes.CONTRACTOR_INVOICE];
  }

  return getInvoiceReportDocumentTypeLabel(invoiceReport, isAdminUser);
};

const trackClickEventForContactUs = ({ status }) => {
  trackEvent(
    employerBillingEvents.CLICK_BILLING_FEEDBACK_MESSAGE({
      paymentStatus: status,
      actionType: 'contact-us',
    })
  );
};

const renderContactUsButton = (status) => (
  <ButtonInline
    asTag="a"
    href="https://support.remote.com/hc/en-us/requests/new"
    target="_blank"
    rel="noopener noreferrer"
    onClick={(event) => {
      event.stopPropagation();
      trackClickEventForContactUs({ status });
    }}
  >
    contact us
  </ButtonInline>
);

const paymentFeedbackMeta = {
  // Not an actual status - used for overdue messages
  [`OVERDUE`]: {
    title: (
      invoiceCount,
      { isDashboard, documentTypeLabel, documentNumber },
      isDescriptiveTitle
    ) => {
      if (invoiceCount > 1) {
        return `Payment overdue for ${invoiceCount} invoices`;
      }
      if (isDescriptiveTitle) {
        return isDashboard
          ? `Payment overdue for ${documentTypeLabel} #${documentNumber}`
          : `Payment overdue for this invoice`;
      }
      return 'Payment overdue';
    },
    message:
      'Please make your payment as soon as you can. Overdue payments can result in interruptions to your access to Remote services.',
    ctaText: (invoiceCount) => (invoiceCount > 1 ? 'View invoices' : 'Pay invoice'),
  },
  [paymentStatus.SCHEDULED]: {
    title: (invoiceCount, additionalMeta) =>
      invoiceCount > 1
        ? `${invoiceCount} invoices will be auto-paid ${
            additionalMeta?.scheduledDate ? `on ${additionalMeta.scheduledDate}` : ''
          }`
        : `${additionalMeta?.invoiceDueLabel} will be auto-paid ${
            additionalMeta?.scheduledDate ? `on ${additionalMeta.scheduledDate}` : ''
          }`,
    message: 'No action is needed on your end, we just wanted to let you know.',
    ctaText: '',
  },
  [paymentStatus.PAY_IN_FAILED]: {
    title: (
      invoiceCount,
      { isDashboard, documentTypeLabel, documentNumber },
      isDescriptiveTitle
    ) => {
      if (invoiceCount > 1) {
        return `Payment failed for ${invoiceCount} invoices`;
      }
      if (isDescriptiveTitle) {
        return isDashboard
          ? `Payment failed for ${documentTypeLabel} #${documentNumber}`
          : `Payment failed for this invoice`;
      }
      return 'Payment failed';
    },
    message: (invoiceCount, additionalMeta) =>
      invoiceCount > 1 ? (
        <>
          We couldn't process your payment on {invoiceCount} invoices
          {additionalMeta.paymentMethodName ? (
            <>
              {' '}
              with <b>{additionalMeta.paymentMethodName}</b>
            </>
          ) : (
            ''
          )}
          . Please try again, or {renderContactUsButton(paymentStatus.PAY_IN_FAILED)} if you
          continue to have problems.
        </>
      ) : (
        <>
          We couldn't process your payment
          {additionalMeta.paymentMethodName ? (
            <>
              {' '}
              with <b>{additionalMeta.paymentMethodName}</b>
            </>
          ) : (
            ''
          )}
          . Please try again, or {renderContactUsButton(paymentStatus.PAY_IN_FAILED)} if you
          continue to have problems.
        </>
      ),
    ctaText: (invoiceCount) => (invoiceCount > 1 ? 'View invoices' : 'Retry payment'),
  },
  [paymentStatus.PARTIAL_FAILURE]: {
    title: (
      invoiceCount,
      { isDashboard, documentTypeLabel, documentNumber },
      isDescriptiveTitle
    ) => {
      if (invoiceCount > 1) {
        return `Payment failed for ${invoiceCount} invoices`;
      }
      if (isDescriptiveTitle) {
        return isDashboard
          ? `Payment failed for ${documentTypeLabel} #${documentNumber}`
          : `Payment failed for this invoice`;
      }
      return 'Payment failed';
    },
    message: (invoiceCount) =>
      invoiceCount > 1 ? (
        <>Please {renderContactUsButton(paymentStatus.PARTIAL_FAILURE)} as soon as possible.</>
      ) : (
        <>
          Your payment was split into multiple transactions due to its size, and one of these
          transactions failed. Please {renderContactUsButton(paymentStatus.PARTIAL_FAILURE)} if you
          continue to have problems.
        </>
      ),
    ctaText: (invoiceCount) => (invoiceCount > 1 ? 'View invoices' : 'Pay invoice'),
  },
  [paymentStatus.BLOCKED]: {
    title: (
      invoiceCount,
      { isDashboard, documentTypeLabel, documentNumber },
      isDescriptiveTitle
    ) => {
      if (invoiceCount > 1) {
        return `Payment failed for ${invoiceCount} invoices`;
      }
      if (isDescriptiveTitle) {
        return isDashboard
          ? `Payment failed for ${documentTypeLabel} #${documentNumber}`
          : `Payment failed for this invoice`;
      }
      return 'Payment failed';
    },
    message: <>Please {renderContactUsButton(paymentStatus.BLOCKED)} immediately.</>,
    ctaText: 'View invoices',
  },
  [paymentStatus.SHORT_PAID_IN]: {
    title: (
      invoiceCount,
      { isDashboard, documentTypeLabel, documentNumber },
      isDescriptiveTitle
    ) => {
      if (invoiceCount > 1) {
        return `Partial payment due for ${invoiceCount} invoices`;
      }
      if (isDescriptiveTitle) {
        return isDashboard
          ? `Partial payment due for ${documentTypeLabel} #${documentNumber}`
          : `Partial payment due for this invoice`;
      }
      return 'Payment failed';
    },
    message: <>Please {renderContactUsButton(paymentStatus.SHORT_PAID_IN)} as soon as possible.</>,
    ctaText: 'View invoices',
  },
  [paymentStatus.DISPUTED]: {
    title: (
      invoiceCount,
      { isDashboard, documentTypeLabel, documentNumber },
      isDescriptiveTitle
    ) => {
      if (invoiceCount > 1) {
        return `Payment failed for ${invoiceCount} invoices`;
      }
      if (isDescriptiveTitle) {
        return isDashboard
          ? `Payment failed for ${documentTypeLabel} #${documentNumber}`
          : `Payment failed for this invoice`;
      }
      return 'Payment failed';
    },
    message: (invoiceCount) =>
      invoiceCount > 1 ? (
        <>
          The payment method(s) used to pay these invoices are no longer valid. Please{' '}
          {renderContactUsButton(paymentStatus.DISPUTED)} as soon as possible.
        </>
      ) : (
        '' // STRIPE_DISPUTE_REASONS_ERROR_MESSAGES
      ),
    ctaText: (invoiceCount) => (invoiceCount > 1 ? 'View invoices' : 'Pay invoice'),
  },
};

/**
 * Retrieves the payment feedback metadata based on the status.
 * This function dynamically resolves the title, message, and CTA text associated with a particular payment status.
 * The content can be either static or dynamically generated based on the invoice count and additional metadata provided.
 *
 * @param {string} status Payment status identifier
 * @param {Object} [additionalMeta={}] Additional metadata for dynamic content.
 * @param {boolean} isDescriptiveTitle InvoicePaymentFeedbackMessage uses longer variations of the title compared to the BillingIssuesPill
 * @param {number} [invoiceCount=1] Number of invoices, used for generating pluralized messages.
 * @returns {{ title: string, message: string | JSX.Element, ctaText: string }}
 */
export const getPaymentFeedbackMeta = (
  status,
  additionalMeta = {},
  isDescriptiveTitle = false,
  invoiceCount = 1
) => {
  const title = isFunction(paymentFeedbackMeta[status].title)
    ? paymentFeedbackMeta[status].title(invoiceCount, additionalMeta, isDescriptiveTitle)
    : paymentFeedbackMeta[status].title;
  const message = isFunction(paymentFeedbackMeta[status].message)
    ? paymentFeedbackMeta[status].message(invoiceCount, additionalMeta)
    : paymentFeedbackMeta[status].message;
  const ctaText = isFunction(paymentFeedbackMeta[status].ctaText)
    ? paymentFeedbackMeta[status].ctaText(invoiceCount)
    : paymentFeedbackMeta[status].ctaText;

  return {
    title,
    message,
    ctaText,
  };
};

/**
 * Returns fields for the billing dashboard widget based on the BillingDocument API and the legacy Billing dashboard API
 * @param {BillingDocumentWidget | BillingWidget | undefined} widgetData Data for a specific currency
 * @returns {*}
 */
export const getBillingDashboardWidgetFields = (widgetData) => {
  if (isBillingDocumentDashboard(widgetData)) {
    return {
      overduePaymentsCount: widgetData?.overduePaymentsCount,
      dueInvoices: widgetData?.dueBillingDocuments,
      dueInvoicesCount: widgetData?.dueBillingDocuments?.length || 0,
      appliedCredit: convertToCents(widgetData?.appliedCredit?.amount),
      unappliedCredit: convertToCents(widgetData?.unappliedCredit?.amount),
      amount: convertToCents(widgetData?.amount?.amount),
      amountDue: convertToCents(widgetData?.amountDue?.amount),
    };
  }

  return {
    overduePaymentsCount: widgetData?.overdueInvoices,
    dueInvoices: widgetData?.dueInvoices,
    dueInvoicesCount: widgetData?.dueInvoices?.length || 0,
    appliedCredit: widgetData?.appliedCredit,
    unappliedCredit: widgetData?.unappliedCredit,
    amount: widgetData?.totalValue,
    amountDue: widgetData?.finalValue,
  };
};

export const getZuoraMigrationFeedbackMessageForAdmins = (companySlug) => {
  return (
    <FeedbackMessage variant="info">
      This new Billing tab replicates the Customer view and includes Zuora-generated invoices. It
      replaces{' '}
      <RestrictedLink
        permissions={[['navigate', Resources.invoices]]}
        href={{
          pathname: ADMIN_BILLING_ROUTE,
          query: {
            selectedTab: billingTabs.CUSTOMER_INVOICES,
            'sortBy[0][id]': 'finalizedAt',
            'sortBy[0][desc]': 'true',
            ...(companySlug && {
              'filters[0][id]': 'companySlug',
              'filters[0][value]': companySlug,
            }),
          },
        }}
      >
        Customer invoices (old)
      </RestrictedLink>{' '}
      and{' '}
      <ButtonInline
        href={{
          pathname: ADMIN_BILLING_ROUTE,
          query: {
            selectedTab: billingTabs.BILLING_LEGACY,
            'sortBy[0][id]': 'inserted_at',
            'sortBy[0][desc]': 'true',
            ...(companySlug && {
              'filters[0][id]': 'company',
              'filters[0][value]': companySlug,
            }),
          },
        }}
      >
        Billing (old)
      </ButtonInline>
      , where you can still find{' '}
      <ButtonInline
        href={{
          pathname: ADMIN_BILLING_ROUTE,
          query: {
            selectedTab: billingTabs.BILLING_LEGACY,
            'sortBy[0][id]': 'inserted_at',
            'sortBy[0][desc]': 'true',
            'filters[0][id]': 'type',
            'filters[0][value]': billingDocumentTypes.CONTRACTOR_TRANSACTION_RECEIPT,
            ...(companySlug && {
              'filters[1][id]': 'company',
              'filters[1][value]': companySlug,
            }),
          },
        }}
      >
        transaction receipts
      </ButtonInline>
      . Please leave any questions or feedback in this{' '}
      <ButtonInline
        href="https://www.notion.so/remotecom/New-Admin-billing-section-00889cc231e340ba8b22bcb45c075e9b?pvs=4#f58f32bcff7c46c8b4aab3a8ed5ed18a"
        target="_blank"
        rel="noopener noreferrer"
      >
        Notion doc
      </ButtonInline>
      .
    </FeedbackMessage>
  );
};

export const getZuoraMigrationFeedbackMessageForLegacyAdminTabs = (
  { isCustomerInvoicesTab } = { isCustomerInvoicesTab: false }
) => {
  return (
    <FeedbackMessage variant="info">
      This tab has been replaced by{' '}
      <ButtonInline
        href={{
          pathname: ADMIN_BILLING_ROUTE,
          query: {
            selectedTab: billingTabs.BILLING,
          },
        }}
      >
        Billing
      </ButtonInline>
      , where you can find both invoice and payment-related information.
      {isCustomerInvoicesTab
        ? ' Please note that this tab doesn’t include Zuora-generated invoices.'
        : ''}
    </FeedbackMessage>
  );
};
