export const TEAM_PAGE_TABS = {
  ACTIVE: 'active',
  EMPLOYEES: 'employees',
  CONTRACTORS: 'contractors',
  ARCHIVED: 'inactive',
  ORG_CHART: 'org-chart',
  WORLD_MAP: 'world-map',
};

export const CONTRACTOR_AGREEMENT_ASSIGNED_FILTER_OPTIONS = [
  {
    value: 'true',
    label: 'With contract assigned',
  },
  {
    value: 'false',
    label: 'No contract assigned',
  },
];

export const CONTRACTOR_SERVICE_DATE = {
  SERVICE_START_DATE: 'serviceStartDate',
  SERVICE_END_DATE: 'serviceEndDate',
} as const;
