import type { Ref } from 'react';
import { forwardRef } from 'react';

import { CardDrawerFooter } from '@/src/components/CardDrawer';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

type NotificationsListFooterProps = {
  hasNextPage: boolean;
  totalCount: number;
  currentCount: number;
  singularWord?: string;
  pluralWord?: string;
};

export const NotificationsListFooter = forwardRef(
  (
    {
      hasNextPage,
      totalCount,
      currentCount,
      singularWord = 'notification',
      pluralWord = 'notifications',
    }: NotificationsListFooterProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <CardDrawerFooter ref={ref}>
        {!hasNextPage
          ? getSingularPluralUnit(
              totalCount,
              `Showing 1 ${singularWord}`,
              `Showing all ${currentCount} ${pluralWord}`,
              true,
              false
            )
          : `Showing ${currentCount} of ${totalCount} ${pluralWord}`}
      </CardDrawerFooter>
    );
  }
);
