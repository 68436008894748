import type { ReactNode } from 'react';

import { IconV2OutlinePlus } from '../../icons/build/IconV2OutlinePlus';

import { AddFieldIcon, ButtonCardWrapper, AddFieldCard } from './AddField.styled';

export type AddFieldProps = {
  onClick: () => void;
  children: ReactNode;
  width?: string;
  disabled?: boolean;
};

export const AddField = ({
  onClick,
  children,
  width = '24px',
  disabled = false,
  ...props
}: AddFieldProps) => (
  <ButtonCardWrapper {...props}>
    <AddFieldCard type="button" onClick={onClick} {...props} disabled={disabled}>
      <AddFieldIcon Icon={IconV2OutlinePlus} width={width} />
      {children}
    </AddFieldCard>
  </ButtonCardWrapper>
);
