// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineEye = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M4.92 7.58C3.39 9.16 2.75 10.98 2.75 12s.64 2.84 2.17 4.42A9.52 9.52 0 0 0 12 19.25c3.28 0 5.6-1.28 7.08-2.83 1.53-1.58 2.17-3.4 2.17-4.42s-.64-2.84-2.17-4.42A9.52 9.52 0 0 0 12 4.75a9.52 9.52 0 0 0-7.08 2.83ZM3.83 6.54A11.02 11.02 0 0 1 12 3.25c3.72 0 6.4 1.47 8.17 3.3 1.72 1.79 2.58 3.97 2.58 5.45s-.86 3.66-2.58 5.46A11.02 11.02 0 0 1 12 20.75c-3.72 0-6.4-1.47-8.17-3.3C2.11 15.67 1.25 13.5 1.25 12c0-1.48.86-3.66 2.58-5.46ZM12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM8.25 12a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineEye);
export { ForwardRef as IconV2OutlineEye };