import type { PillProps } from '@remote-com/norma';

/**
 * Determines the color for a pill component based on the difference between the current date and the due date.
 */
export function getPillUrgencyTone(daysDifference: number): PillProps['tone'] {
  switch (true) {
    case daysDifference <= 0:
      return 'error';
    case daysDifference < 10:
      return 'warning';
    default:
      return 'neutralLight';
  }
}
