import { Text, Stack, IconArrowLeft } from '@remote-com/norma';
import { Formik } from 'formik';
import { useState } from 'react';
import { object } from 'yup';

import { Button } from '@/src/components/Button';
import { GoBackButton } from '@/src/components/Button/GoBackButton';
import { Modal } from '@/src/components/Modal';
import { InputOTP, InputField } from '@/src/components/Ui/Form';
import { ContactRemoteSupportButton } from '@/src/domains/registration/shared/ContactRemoteSupportLink';
import { totpBackupValidationSchema, totpValidationSchema } from '@/src/helpers/validationSchema';

export function Verify2FACodeModal({
  onClose,
  customTitle = '',
  handleSubmit: handleSubmission,
  errorMessage,
  setErrorMessage,
  isLoading,
}) {
  const [backupCodeView, setBackupCodeView] = useState(false);

  const initialValues = { totp: '' };

  const totpSchema = object().shape({
    totp: totpValidationSchema,
  });

  const backupValidationSchema = object().shape({
    totp: totpBackupValidationSchema,
  });

  function handleCancel() {
    onClose();
  }

  const ModalActionButtons = () => {
    return (
      <Stack gap={4} alignItems="center" mt={8}>
        <Button
          isLoading={isLoading}
          variant="solid"
          tone="primary"
          type="submit"
          data-testid="modal-save-button"
        >
          Continue
        </Button>
        <Button variant="ghost" tone="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Stack>
    );
  };

  return (
    <Modal
      visible
      headerTitle=""
      saveButtonText="Turn off"
      cancelLabel="Cancel"
      onCancel={() => {
        setErrorMessage('');
        setBackupCodeView(true);
        onClose();
      }}
      formName="validate-2fa-code"
      showFooter={false}
    >
      <div>
        <Text textAlign="center" variant="2xlMedium" mb={4}>
          {customTitle || 'Two-factor authentication (2FA) is required for this action'}
        </Text>
        <Text p={2} mb={7} textAlign="center">
          {!backupCodeView
            ? 'Enter the 6-digit code from your authenticator app.'
            : 'Enter one of the backup codes generated during Two-Factor Authentication setup.'}
        </Text>
        {!backupCodeView ? (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmission}
            validationSchema={totpSchema}
            enableReinitialize
          >
            {({ errors, handleSubmit }) => {
              return (
                <form id="validate-2fa-code" onSubmit={handleSubmit} noValidate>
                  <InputOTP
                    name="totp"
                    errorText={errors.totp ? errors.totp : errorMessage}
                    required
                  />
                  <Text mb={6} variant="smMedium" textAlign="center">
                    <Text variant="sm">
                      Need help? <ContactRemoteSupportButton is2FASetupCompleted />
                    </Text>
                    <ModalActionButtons />
                  </Text>
                </form>
              );
            }}
          </Formik>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmission}
            validationSchema={backupValidationSchema}
            enableReinitialize
          >
            {({ errors, handleSubmit }) => {
              return (
                <form id="validate-2fa-code" onSubmit={handleSubmit} noValidate>
                  <Stack justifyContent="center" gap={6}>
                    <Stack>
                      <InputField
                        name="totp"
                        label="Backup code"
                        required
                        autoComplete="disabled"
                        errorText={errors.totp ? errors.totp : errorMessage}
                      />
                    </Stack>
                    <Text variant="sm" textAlign="center">
                      <GoBackButton
                        onClick={() => {
                          setErrorMessage('');
                          setBackupCodeView(false);
                        }}
                        Icon={IconArrowLeft}
                        size="sm"
                        variant="ghost"
                        label="Back to authentication code"
                      />
                      <Text variant="sm">
                        Need help? <ContactRemoteSupportButton is2FASetupCompleted />
                      </Text>
                      <ModalActionButtons />
                    </Text>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    </Modal>
  );
}

export default Verify2FACodeModal;
