import { Pill } from '@remote-com/norma';
import { differenceInCalendarDays } from 'date-fns';

import { formatDaysDifference } from '@/src/domains/todos/helpers';

import { getPillUrgencyTone } from './helpers';

type DueDatePillProps = {
  /**
   * The due date.
   */
  date: Date;
};

export function DueDatePill({ date }: DueDatePillProps) {
  const today = new Date();
  const daysDifference = differenceInCalendarDays(date, today);

  return (
    <Pill tone={getPillUrgencyTone(daysDifference)} type="subtle">
      <time dateTime={date.toISOString()}>{formatDaysDifference(daysDifference)}</time>
    </Pill>
  );
}
