import type { GetResponse, UseGetOptions } from '@remote-com/data-layer';
import { useGet } from '@remote-com/data-layer';
import { useCallback, useMemo } from 'react';

import type { BillingWidget } from '@/src/api/config/employ/billingDashboardApi.types';
import type {
  BillingDocument,
  BillingDocumentWidget,
} from '@/src/api/config/employ/billingDocuments.types';
import type { InvoiceReport, RemoteInvoice } from '@/src/api/config/employ/invoiceReport.types';
// eslint-disable-next-line import/no-cycle
import { getBillingDashboardWidgetFields } from '@/src/domains/billing/helpers';
import { invoiceStaleTime } from '@/src/domains/invoices/constants';
import { useIsZuoraMigratedCompany } from '@/src/domains/invoices/shared/hooks';
import type { PaymentStatus } from '@/src/domains/payments/constants';
import { PAYMENT_WITH_ERROR_STATUSES } from '@/src/domains/payments/status';
import type { OutstandingPayment } from '@/src/domains/payments/types';
import useCompanyData from '@/src/domains/team/hooks/useCompanyData';

type DashboardWidgetData =
  | Record<string, BillingDocumentWidget>
  | Record<string, BillingWidget>
  | undefined;

/**
 * Type-guard for BillingDocumentWidget - 'dueBillingDocuments' is a field unique to BillingDocumentWidget
 */
export function isBillingDocumentDashboard(
  widgetData: BillingDocumentWidget | BillingWidget | undefined
): widgetData is BillingDocumentWidget {
  return widgetData !== undefined && 'dueBillingDocuments' in widgetData;
}

type GetBillingDashboardEndpoint =
  | '/api/v1/employer/billing/dashboard'
  | '/api/v1/billing-platform/employer/billing-documents/dashboard';

type GetBillingDashboardWidgetConfig = {
  options?: UseGetOptions<
    GetBillingDashboardEndpoint,
    unknown,
    GetResponse<GetBillingDashboardEndpoint>
  >;
};

export const useBillingDashboardWidget = (config?: GetBillingDashboardWidgetConfig) => {
  const { options } = config || {};
  const enabled = options?.enabled ?? true;
  const { isFetching: isFetchingCompanyData, data: companyData } = useCompanyData({ enabled });

  const {
    data: companyMigrationStatus,
    isLoading: isLoadingIsZuoraMigratedCompany,
    isIdle,
  } = useIsZuoraMigratedCompany(companyData?.slug);

  const { data: legacyDashboardWidgetData, isFetching: isFetchingLegacyData } = useGet(
    '/api/v1/employer/billing/dashboard',
    {
      options: {
        enabled: enabled && !isIdle && !companyMigrationStatus?.isZuoraMigrated,
        select: (data) => data?.data?.issuesByCurrency,
        staleTime: invoiceStaleTime,
      },
      ...options,
    }
  );

  const { data: billingDocumentDashboardWidgetData, isFetching: isFetchingBillingDocumentData } =
    useGet('/api/v1/billing-platform/employer/billing-documents/dashboard', {
      options: {
        enabled: enabled && !isIdle && companyMigrationStatus?.isZuoraMigrated,
        select: (data) => data?.data?.totals,
        staleTime: invoiceStaleTime,
      },
      ...options,
    });

  const dashboardWidgetsData = companyMigrationStatus?.isZuoraMigrated
    ? billingDocumentDashboardWidgetData
    : legacyDashboardWidgetData;

  const companyMainCurrency: string | null | undefined = useMemo(
    () => companyData?.bankAccount?.desiredCurrency?.code,
    [companyData]
  );

  const getWidgetDataByCurrency = useCallback(
    (currencyCode: string) => {
      return dashboardWidgetsData?.[currencyCode.toLowerCase()];
    },
    [dashboardWidgetsData]
  );

  const widgetsCurrencies = useMemo(
    () => Object.keys(dashboardWidgetsData || {}).map((currencyCode) => currencyCode.toUpperCase()),
    [dashboardWidgetsData]
  );

  // sorting currencies to display in UI
  // [main currency, ...remaining currencies sorted A-Z ]
  // and removing currency data when widget currency has no due invoices
  const sortedWidgetCurrencies = useMemo(() => {
    const sortedCurrencies = [
      ...(companyMainCurrency ? [companyMainCurrency] : []),
      ...widgetsCurrencies.filter((currency) => currency !== companyMainCurrency).sort(),
    ];

    return sortedCurrencies;
  }, [companyMainCurrency, widgetsCurrencies]);

  const currenciesWithDueInvoices = useMemo(() => {
    return sortedWidgetCurrencies.filter((currency) => {
      const widgetData = getWidgetDataByCurrency(currency);
      const { dueInvoicesCount } = getBillingDashboardWidgetFields(widgetData);

      return dueInvoicesCount > 0;
    });
  }, [sortedWidgetCurrencies, getWidgetDataByCurrency]);

  const unappliedCredits = useMemo(
    () =>
      sortedWidgetCurrencies
        ? sortedWidgetCurrencies.map((currencyCode) => {
            const widgetData = getWidgetDataByCurrency(currencyCode);
            const { unappliedCredit } = getBillingDashboardWidgetFields(widgetData);
            return {
              currencyCode,
              amount: unappliedCredit ?? 0,
            };
          })
        : [],
    [sortedWidgetCurrencies, getWidgetDataByCurrency]
  );

  const hasNoDueInvoices = useMemo(
    () =>
      Object.values(dashboardWidgetsData || {}).every((widgetData) => {
        const { dueInvoicesCount } = getBillingDashboardWidgetFields(widgetData);
        return dueInvoicesCount === 0;
      }),
    [dashboardWidgetsData]
  );

  const dueInvoicesTotalCount = useMemo(
    () =>
      widgetsCurrencies.reduce((total, currency) => {
        const widgetData = getWidgetDataByCurrency(currency);
        const { dueInvoicesCount } = getBillingDashboardWidgetFields(widgetData);
        return total + dueInvoicesCount;
      }, 0),
    [widgetsCurrencies, getWidgetDataByCurrency]
  );

  const getIsCurrencyCodeWithError = (currencyCode: string) => {
    const currencyDashboardData = getWidgetDataByCurrency(currencyCode);
    const { overduePaymentsCount, dueInvoices } =
      getBillingDashboardWidgetFields(currencyDashboardData);

    if (overduePaymentsCount) {
      return true;
    }

    let hasPaymentWithError = false;

    if (isBillingDocumentDashboard(currencyDashboardData)) {
      hasPaymentWithError = dueInvoices?.some((document: BillingDocument) =>
        document.outstandingPayments?.some((payment: OutstandingPayment) =>
          PAYMENT_WITH_ERROR_STATUSES.includes(payment.status)
        )
      );
    } else {
      hasPaymentWithError = dueInvoices?.some((payment: OutstandingPayment) =>
        PAYMENT_WITH_ERROR_STATUSES.includes(payment.status)
      );
    }

    return hasPaymentWithError;
  };

  const getIssuesGroupedByOutstandingPaymentStatus = useCallback(() => {
    const initialState = {} as Record<PaymentStatus, InvoiceReport[] | BillingDocument[]>;

    const getIssuesByPaymentStatusForBillingDocuments = (dashboardData: DashboardWidgetData) => {
      return Object.values(dashboardData || {})
        .flatMap((widgetData) => widgetData.dueBillingDocuments)
        .reduce((acc, billingDocument) => {
          billingDocument.outstandingPayments.forEach((outstandingPayment: OutstandingPayment) => {
            const { status } = outstandingPayment;

            if (!acc[status]) {
              acc[status] = [];
            }

            if (!acc[status].includes(billingDocument)) {
              acc[status].push(billingDocument);
            }
          });

          return acc;
        }, initialState);
    };

    const getIssuesByPaymentStatusForInvoiceReports = (dashboardData: DashboardWidgetData) => {
      const toInvoiceReport = (outstandingPayment: OutstandingPayment): InvoiceReport => {
        const { remoteInvoices, ...rest } = outstandingPayment;

        const { invoiceReport, ...remoteInvoice } = remoteInvoices?.[0] ?? ({} as RemoteInvoice);

        return {
          ...(invoiceReport as InvoiceReport),
          remoteInvoice: { ...(remoteInvoice as RemoteInvoice), outstandingPayment: rest },
        };
      };

      return Object.values(dashboardData || {})
        .map((widgetData) => widgetData.dueInvoices)
        .flat()
        .reduce((acc, outstandingPayment) => {
          if (outstandingPayment.status in acc) {
            return {
              ...acc,
              [outstandingPayment.status]: [
                ...acc[outstandingPayment.status],
                toInvoiceReport(outstandingPayment),
              ],
            };
          }

          return {
            ...acc,
            [outstandingPayment.status]: [toInvoiceReport(outstandingPayment)],
          };
        }, initialState);
    };

    if (!dashboardWidgetsData) return null;

    if (companyMigrationStatus?.isZuoraMigrated) {
      return getIssuesByPaymentStatusForBillingDocuments(dashboardWidgetsData);
    }
    return getIssuesByPaymentStatusForInvoiceReports(dashboardWidgetsData);
  }, [dashboardWidgetsData, companyMigrationStatus?.isZuoraMigrated]);

  const getListofPaymentStatuses = useCallback(
    (billingDocumentsOrOutstandingPayments) => {
      if (companyMigrationStatus?.isZuoraMigrated) {
        return billingDocumentsOrOutstandingPayments
          .map((data: BillingDocument) =>
            data.outstandingPayments.map((payment: OutstandingPayment) => payment.status)
          )
          .flat();
      }
      return (
        billingDocumentsOrOutstandingPayments.map((data: OutstandingPayment) => data.status) || []
      );
    },
    [companyMigrationStatus?.isZuoraMigrated]
  );

  return {
    isFetching:
      isFetchingCompanyData ||
      isLoadingIsZuoraMigratedCompany ||
      isFetchingLegacyData ||
      isFetchingBillingDocumentData,
    dashboardWidgetsData,
    currenciesWithDueInvoices,
    unappliedCredits,
    dueInvoicesTotalCount,
    getIsCurrencyCodeWithError,
    widgetsCurrencies,
    hasNoDueInvoices,
    getWidgetDataByCurrency,
    getIssuesGroupedByOutstandingPaymentStatus,
    getListofPaymentStatuses,
  };
};
