// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineRocket = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M16.56 1.25H17.23c.81 0 1.47 0 2 .04.55.05 1.03.14 1.47.37.7.36 1.28.93 1.64 1.64.23.44.32.92.37 1.47.04.53.04 1.19.04 2V7.45c0 .88 0 1.45-.13 2-.12.49-.31.95-.57 1.38-.3.48-.7.88-1.33 1.5l-.06.07-1.35 1.35.25 1.24.03.12c.22 1.12.36 1.83.3 2.54a4.75 4.75 0 0 1-.54 1.77 8.87 8.87 0 0 1-1.66 1.95l-.08.08-1.08 1.08a.75.75 0 0 1-1.14-.1l-2.1-2.93-.18.1a4.75 4.75 0 0 1-4.22 0c-.7-.35-1.33-.97-2.3-1.96-.05-.03-.08-.07-.12-.11l-.11-.11c-.99-.98-1.61-1.61-1.96-2.31a4.75 4.75 0 0 1 .1-4.4L1.56 8.6a.75.75 0 0 1-.09-1.14l1.08-1.08.08-.08c.8-.8 1.32-1.32 1.95-1.66.55-.3 1.15-.47 1.77-.54.7-.06 1.42.08 2.54.3l.12.03 1.24.25 1.34-1.35.07-.06a8.7 8.7 0 0 1 1.51-1.33c.43-.26.9-.45 1.38-.57.54-.13 1.12-.13 2-.13ZM11 6.05a.78.78 0 0 0 .04-.04l1.6-1.6a8 8 0 0 1 1.3-1.18c.3-.18.62-.31.95-.39a8 8 0 0 1 1.75-.09h.55c.85 0 1.45 0 1.9.04.46.04.72.1.92.2.42.22.77.57.98.99.1.2.17.46.21.91.04.46.04 1.06.04 1.91v.55c0 1.01 0 1.4-.1 1.75-.07.33-.2.65-.38.94a8 8 0 0 1-1.18 1.3l-5.12 5.13c-1.13 1.13-1.58 1.56-2.03 1.79-.9.45-1.97.45-2.88 0-.38-.19-.74-.51-1.5-1.26l1.47-1.47a.75.75 0 0 0-1.06-1.06L7 15.94a6.52 6.52 0 0 1-1.26-1.5 3.25 3.25 0 0 1 0-2.88c.23-.45.66-.9 1.79-2.03l3.48-3.48Zm-2.03-.09-.27-.05a8.23 8.23 0 0 0-2.21-.3c-.43.04-.84.16-1.22.37-.4.21-.75.55-1.67 1.47l-.45.45 2.27 1.63c.26-.29.57-.6.93-.95l.1-.11 2.52-2.51Zm5.5 12.61 1.62 2.27.45-.45a8.23 8.23 0 0 0 1.48-1.67c.2-.38.32-.8.36-1.22.05-.45-.04-.94-.3-2.21l-.05-.27-2.51 2.51-.11.11c-.36.36-.66.67-.95.93ZM15 7.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM12.25 9a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineRocket);
export { ForwardRef as IconV2OutlineRocket };