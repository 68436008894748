import { generateSelectOptions } from '@/src/helpers/forms';

export const CONTRACT_TEMPLATE_STATUS = /** @type {const} */ ({
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
});

export const CONTRACT_TEMPLATE_STATUS_OPTIONS = generateSelectOptions({
  [CONTRACT_TEMPLATE_STATUS.DRAFT]: 'Draft',
  [CONTRACT_TEMPLATE_STATUS.PUBLISHED]: 'Live',
  [CONTRACT_TEMPLATE_STATUS.ARCHIVED]: 'Archived',
});

export const CONTRACT_TEMPLATE_STATUS_BADGES = {
  [CONTRACT_TEMPLATE_STATUS.DRAFT]: {
    label: 'Draft',
    type: 'inactive',
  },
  [CONTRACT_TEMPLATE_STATUS.PUBLISHED]: {
    label: 'Live',
    type: 'active',
  },
  [CONTRACT_TEMPLATE_STATUS.ARCHIVED]: {
    label: 'Archived',
    type: 'inactive',
  },
};
