/**
 * @description
 * This enum defines all possible feature flags that can be toggled in dragon.
 *
 * **Note**: All feature flags are turned off by default. This means that in order to activate any of them, that should be done
 * explicitly via .env file or via k8s config.
 * @enum {String}
 */
const featureFlags = {
  CSP_ENABLED: 'CSP_ENABLED',

  ZENDESK_WEB_WIDGET_ENABLED: 'ZENDESK_WEB_WIDGET_ENABLED',
  BEAMER_WEB_WIDGET_ENABLED: 'BEAMER_WEB_WIDGET_ENABLED',
  SPRIG_ENABLED: 'SPRIG_ENABLED',

  ADMIN_INVOICE_CAN_FINALIZE_NEGATIVE: 'ADMIN_INVOICE_CAN_FINALIZE_NEGATIVE',

  /** Enables the 'Pay elements' tab in the employee profile view for employers */
  EMPLOYEE_PROFILE_PAY_ELEMENTS: 'EMPLOYEE_PROFILE_PAY_ELEMENTS',

  /** Enables editing pay elements from the 'Pay elements' tab in the employee profile view */
  EMPLOYEE_PROFILE_PAY_ELEMENTS_EDIT: 'EMPLOYEE_PROFILE_PAY_ELEMENTS_EDIT',

  /** Enables editing payroll calendars */
  PAYROLL_CALENDARS_EDIT: 'PAYROLL_CALENDARS_EDIT',

  /** Enables employers to see the payroll's receipt for payroll runs processed by Gusto in the US */
  GUSTO_PAYROLL_EMPLOYERS_RECEIPT: 'GUSTO_PAYROLL_EMPLOYERS_RECEIPT',

  /** Enables the Navattic quick tour */
  NAVATTIC_QUICK_TOUR_ENABLED: 'NAVATTIC_QUICK_TOUR_ENABLED',

  /** Enables the App version check and warns the user if a mismatch exists {@link https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/847} */
  APP_VERSION_CHECK_ENABLED: 'APP_VERSION_CHECK_ENABLED',

  /** Enables the contract details editor for standardising employment agreement data: {@link https://gitlab.com/groups/remote-com/-/epics/575} */
  CONTRACT_DETAILS_EDITOR: 'CONTRACT_DETAILS_EDITOR',

  Q4_PAY_ELEMENT_ROLLOUT_ENABLED: 'Q4_PAY_ELEMENT_ROLLOUT_ENABLED',

  MOBILITY_GENERAL_REQUESTS: 'MOBILITY_GENERAL_REQUESTS',
  MOBILITY_INFORMATION_REQUEST_ENABLED: 'MOBILITY_INFORMATION_REQUEST_ENABLED',
  MOBILITY_REMOTE_WORK_REQUEST_ENABLED: 'MOBILITY_REMOTE_WORK_REQUEST_ENABLED',

  /** Enables the Incentives page for employees */
  EMPLOYEE_INCENTIVES: 'EMPLOYEE_INCENTIVES',

  /** Enables expenses for Direct employees */
  DIRECT_EMPLOYEES_EXPENSES_ENABLED: 'DIRECT_EMPLOYEES_EXPENSES_ENABLED',

  /** FormBuilder direct schema editor to enable direct json schema editing, developers only. #dev-team-contracts-platform */
  FORM_BUILDER_SCHEMA_EDITOR: 'FORM_BUILDER_SCHEMA_EDITOR',

  /** Enables `rivendell/task-assignments` usage {@link https://linear.app/remote/issue/MBLY-54/[mobility]-multiple-assignee-relationship} */
  TASK_ASSIGNMENTS_ENABLED: 'TASK_ASSIGNMENTS_ENABLED',

  /** Enables integration with NEO payroll */
  NEO_PAYROLL_INTEGRATION_ENABLED: 'NEO_PAYROLL_INTEGRATION_ENABLED',

  // Handles primary and additional billing contact feature
  PRIMARY_BILLING_CONTACT_ENABLED: 'PRIMARY_BILLING_CONTACT_ENABLED',

  /** Enables the employer to create individual carryover for employees */
  EMPLOYER_INDIVIDUAL_CARRYOVER_ENABLED: 'EMPLOYER_INDIVIDUAL_CARRYOVER_ENABLED',

  /** Enables unapplied credits widgets on customer's billing dashboard  */
  BILLING_DASHBOARD_WIDGETS_UNAPPLIED_CREDITS_ENABLED:
    'BILLING_DASHBOARD_WIDGETS_UNAPPLIED_CREDITS_ENABLED',

  /** Enables the UI to edit profile email for company admins with multiple profiles */
  ONE_ACCOUNT_PROFILE_EMAIL_EDIT: 'ONE_ACCOUNT_PROFILE_EMAIL_EDIT',

  /** Enables features related to One Account P3 milestone {@link https://linear.app/remote/project/one-account-one-login-per-person-for-all-company-managementemployments-fbaed557954c/issues} */
  ONE_ACCOUNT_P3: 'ONE_ACCOUNT_P3',

  /* Enables the direct employee account creation -- bulk add reporting lines. {@link https://linear.app/remote/issue/WFP-570/invite-page-for-admins-without-employee-accounts} */
  ORG_CHART_DIRECT_EMPLOYEE_ACCOUNT_CREATION_ENABLED:
    'ORG_CHART_DIRECT_EMPLOYEE_ACCOUNT_CREATION_ENABLED',

  /** Enables the org-chart manager assignment in bulk overlay that can be opened from the org chart page. {@link https://linear.app/remote/issue/WFP-433/org-chart-assign-managers-to-employees-without-managers} */
  ORG_CHART_MANAGER_ASSIGNMENT_BULK_OVERLAY_ENABLED:
    'ORG_CHART_MANAGER_ASSIGNMENT_BULK_OVERLAY_ENABLED',

  /** Enables the manager assignment on bulk onboarding feature */
  MANAGER_ASSIGNMENT_ON_BULK_ONBOARDING: 'MANAGER_ASSIGNMENT_ON_BULK_ONBOARDING',

  /** Enables the severance reserves UI features such as the countries table */
  SEVERANCE_ACCRUALS_ENABLED: 'SEVERANCE_ACCRUALS_ENABLED',

  SMARTY_ADDRESS_ENABLED: 'SMARTY_ADDRESS_ENABLED',

  /** Enables performance management features */
  CAREER_DEVELOPMENT_ENABLED: 'CAREER_DEVELOPMENT_ENABLED',

  /** Enables the display of feedback within the career journal */
  CAREER_JOURNAL_FEEDBACK_ENABLED: 'CAREER_JOURNAL_FEEDBACK_ENABLED',

  /** Secondary output pay element flag that changes the data source on the pay details screens to output pay elements */
  PAY_DETAILS_WITH_OUTPUT_PAY_ELEMENTS_ENABLED: 'PAY_DETAILS_WITH_OUTPUT_PAY_ELEMENTS_ENABLED',

  /** Enables template payroll features */
  TEMPLATE_PAYROLL_ENABLED: 'TEMPLATE_PAYROLL_ENABLED',

  /** Enables the world map for employers */
  WORLD_MAP_ENABLED: 'WORLD_MAP_ENABLED',

  /** Allows Employer admins to install the Remote Slack App integration. See https://www.notion.so/remotecom/Team-Members-can-link-their-Slack-Remote-accounts-298060801e56414aaca43a8c3b2633ea */
  INTEGRATIONS_SLACK_INSTALL_ENABLED: 'INTEGRATIONS_SLACK_INSTALL_ENABLED',

  /** Allows individuals to connect their Remote account to the Remote Slack App. See https://www.notion.so/remotecom/Team-Members-can-link-their-Slack-Remote-accounts-298060801e56414aaca43a8c3b2633ea */
  INTEGRATIONS_SLACK_CONNECTION_ENABLED: 'INTEGRATIONS_SLACK_CONNECTION_ENABLED',

  /** Enables restrictions on employers and allows admins to modify */
  TALENT_SUBSCRIPTION_RESTRICTIONS_ENABLED: 'TALENT_SUBSCRIPTION_RESTRICTIONS_ENABLED',

  /** Enables Nium automated refund flows, from setting up beneficiary to refund overview.
   * https://linear.app/remote/project/nium-automated-refunds-da2c2069918a/EP
   */
  AUTOMATED_NIUM_REFUNDS_ENABLED: 'AUTOMATED_NIUM_REFUNDS_ENABLED',

  /** Enables Employer EE bulk upload functionality */
  EMPLOYER_EE_BULK_UPLOAD_ENABLED: 'EMPLOYER_EE_BULK_UPLOAD_ENABLED',

  /** Enables the reporting library sidebar menu item */
  REPORTING_LIBRARY_ENABLED: 'REPORTING_LIBRARY_ENABLED',

  /** Enables all Workflows related UI */
  WORKFLOWS_ENABLED: 'WORKFLOWS_ENABLED',

  EMPLOYEE_TIMELINE_ENABLED: 'EMPLOYEE_TIMELINE_ENABLED',

  /** Enables syncing new benefits for LEPES with gusto */
  GUSTO_SYNC_LEPES_WITH_COMPANY_BENEFITS: 'GUSTO_SYNC_LEPES_WITH_COMPANY_BENEFITS',

  /** Enables syncing new benefits for Employee pay elements with gusto */
  GUSTO_SYNC_EPES_WITH_EMPLOYEE_BENEFITS_FE: 'GUSTO_SYNC_EPES_WITH_EMPLOYEE_BENEFITS_FE',

  /** Enables employment eligibility/RTW checks for direct employees */
  HRIS_EMPLOYMENT_ELIGIBILITY_ENABLED: 'HRIS_EMPLOYMENT_ELIGIBILITY_ENABLED',

  /** Enables the Remote AI Chat Debugger in Remote Control Panel */
  TEMPORARY_AI_DEBUGGER_ENABLED: 'TEMPORARY_AI_DEBUGGER_ENABLED',

  /** Enables admins to convert direct employees to GP */
  ADMIN_LINK_CONVERT_DIRECT_TO_GP_ENABLED: 'ADMIN_LINK_CONVERT_DIRECT_TO_GP_ENABLED',

  /** Enables sign in pages to have some feature highlights */
  ENHANCED_SIGN_IN_PAGES_ENABLED: 'ENHANCED_SIGN_IN_PAGES_ENABLED',

  /** Enables SafetyWing related UI */
  SAFETY_WING_ENABLED: 'SAFETY_WING_ENABLED',

  /**
   * Enables Remote Equity page access
   * https://linear.app/remote/project/self-serve-sign-up-via-sso-for-easop-292af9446449/overview
   */
  REMOTE_EQUITY_ENABLED: 'REMOTE_EQUITY_ENABLED',

  /** Enables Statutory payments UI */
  STATUTORY_PAYMENTS_ENABLED: 'STATUTORY_PAYMENTS_ENABLED',

  /**
   * Enables the new pay elements bulk upload feature for employers
   */
  EMPLOYER_PAY_ELEMENTS_BULK_UPLOAD_ENABLED: 'EMPLOYER_PAY_ELEMENTS_BULK_UPLOAD_ENABLED',

  /**
   * Temporarily allow Admins to create service costs with invoice period in the past
   * https://linear.app/remote/issue/BIL-1656/allow-creating-supplemental-costs-with-past-invoice-periods-under
   */
  ADMIN_SERVICE_COSTS_ALLOW_PAST_PERIODS: 'ADMIN_SERVICE_COSTS_ALLOW_PAST_PERIODS',

  /**
   * Enables access to the revamped customer signup flow
   */
  CUSTOMER_SIGNUP_REVAMP: 'CUSTOMER_SIGNUP_REVAMP',
};

const featureFlagNames = Object.values(featureFlags);

module.exports = {
  featureFlagNames,
  featureFlags,
};
