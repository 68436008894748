import { useQuery, useQueryClient } from 'react-query';

import { useUserCan } from '@/src/components/UserCan';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { useCustomMutation } from '@/src/hooks/useCustomMutation';
import {
  fetchAdminComplianceSettings,
  updateAdminComplianceSettings,
  fetchCompanyDetails,
  updateCompanyDetails,
} from '@/src/services/Admin';
import { fetchEmployerComplianceSettings } from '@/src/services/Employer';

const getEmployerComplianceKey = () => ['employer-fetch-compliance-settings'];
export const getAdminComplianceKey = (slug) => ['admin-fetch-compliance-settings', slug];
export const getAdminCompanyKey = (slug) => ['admin-fetch-company-details', slug];

export const useEmployerComplianceSettings = (enabled) => {
  const query = useQuery(getEmployerComplianceKey(), () => fetchEmployerComplianceSettings(), {
    select: (data) => data.data,
    enabled,
  });
  return query;
};

export const useAdminComplianceSettings = (slug) => {
  const { userCan: userCanFn } = useUserCan();
  const query = useQuery(
    getAdminComplianceKey(slug),
    () => fetchAdminComplianceSettings({ pathParams: { slug } }),
    {
      enabled: userCanFn('read', Resources.compliance_settings) && Boolean(slug),
      select: (data) => data.data,
    }
  );
  return query;
};

export const useAdminComplianceSettingsMutation = (slug) => {
  const queryClient = useQueryClient();

  const mutation = useCustomMutation(
    async (values) => {
      const settings = await updateAdminComplianceSettings({
        pathParams: { slug },
        bodyParams: { ...values },
      });
      return settings;
    },
    {
      onSuccess: (next) => queryClient.setQueryData(getAdminComplianceKey(slug), next),
    }
  );

  return mutation;
};

export const useAdminCompanyDetails = (companySlug, config = {}) => {
  const query = useQuery(
    getAdminCompanyKey(companySlug),
    () => fetchCompanyDetails({ pathParams: { companySlug } }),
    {
      ...config,
      select: (res) => res.data,
    }
  );

  return query;
};

export const useAdminCompanyDetailsMutation = (companySlug) => {
  const queryClient = useQueryClient();

  const mutation = useCustomMutation(
    async (values) => {
      const bodyParams = { company: { ...values } };
      const details = await updateCompanyDetails({ pathParams: { companySlug }, bodyParams });
      return details;
    },
    {
      onSuccess: () => {
        // Unlike ComplianceSettings, CompanyDetails update doesn't return the updated data, so we
        // can only invalidate the queries without updating
        queryClient.invalidateQueries(getAdminCompanyKey(companySlug));
      },
    }
  );
  return mutation;
};
