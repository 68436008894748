import capitalize from 'lodash/capitalize';
import flow from 'lodash/flow';

import { DATE_SMARTFIELD_SUFFIXES } from '@/src/domains/contracts/constants';
import { BILINGUAL_EDITOR_TYPE } from '@/src/domains/contracts/shared/constants';

import {
  CONTRACT_NODE_TYPES,
  CONDITIONAL_CONSTANT_IND,
  CONDITIONAL_VALUE_SEPARATOR,
  CONTRACT_MARK_TYPES,
} from './constants';

export const hasMultiValues = (value) => value.includes(CONDITIONAL_VALUE_SEPARATOR);
export const hasConstantValues = (value) => value.includes(CONDITIONAL_CONSTANT_IND);

// Formats conditional field names so that they are easier to read
// i.e. Value1|Value2 => Value 1 | Value 2
export const getFriendlyFieldName = (fieldName) => {
  // is constant value and should not be changed
  if (fieldName.includes('(')) {
    const [, constant] = fieldName.match(/\(([^)]+)\)/);
    const values = constant.split('|').join(' | ');
    return values;
  }

  const values = fieldName
    .split('|')
    .map((field) => capitalize(field.split('_').join(' ')).toUpperCase())
    .join(' | ');

  return values;
};

// Parses smart fields inside conditional nodes
export const buildSmartFieldList = (nodeFields) => {
  return nodeFields
    .filter((field) => !hasConstantValues(field))
    .reduce((smartFields, field) => {
      // i.e. (Value1|Value2)
      if (hasMultiValues(field)) {
        const splitFields = field
          .split(CONDITIONAL_VALUE_SEPARATOR)
          .map((fieldName) => fieldName.trim());
        return [...smartFields, ...splitFields];
      }

      return [...smartFields, field];
    }, []);
};

// Recursively parses document content to find any smart fields within
// the document and returns a unique list of smart fiels included
// in the document
export const getSmartFields = (content = [], fields = []) => {
  const fieldsArray = content.reduce((acc, node) => {
    const hasNestedContent = node.content?.length;

    if (node.type === CONTRACT_NODE_TYPES.SMART_FIELD) {
      return [...acc, node.attrs.field];
    }

    if (node.type === CONTRACT_NODE_TYPES.CONDITIONAL_CONTENT) {
      return [...acc, node.attrs.conditional];
    }

    if (node.type === CONTRACT_NODE_TYPES.INLINE_CONDITIONAL) {
      let { fields: nodeFields } = node.attrs;

      if (typeof nodeFields === 'string') {
        nodeFields = nodeFields.split(CONDITIONAL_VALUE_SEPARATOR);
      }

      const condSmartFields = buildSmartFieldList(nodeFields);
      return hasNestedContent
        ? [...acc, ...condSmartFields, ...getSmartFields(node.content, acc)]
        : [...acc, ...condSmartFields];
    }

    if (
      node.type === CONTRACT_NODE_TYPES.BLOCK_CONDITIONAL ||
      node.type === CONTRACT_NODE_TYPES.LIST_ITEM_CONDITIONAL
    ) {
      const condSmartFields = buildSmartFieldList(node.attrs.fields);

      return hasNestedContent
        ? [...acc, ...condSmartFields, ...getSmartFields(node.content, acc)]
        : [...acc, ...condSmartFields];
    }

    if (hasNestedContent) {
      return [...acc, ...getSmartFields(node.content, acc)];
    }

    return acc;
  }, fields);

  return [...new Set(fieldsArray)];
};

export const parseCommentIdInClass = (classValues) => {
  const commentPrefix = 'comment-id-';
  const splitValues = classValues.split(' ');

  const commentValue = splitValues.find((str) => str.includes(commentPrefix));
  const [, commentID] = commentValue.split(commentPrefix);

  return commentID;
};

// This function takes a Prose Mirror selections's from & to, and returns
// a string. Blocks are separated by a [newline] tag so that it can be split for
// rendering
// This implementation is a modification of ProseMirror's textBetween
// https://github.com/ProseMirror/prosemirror-model/blob/23221300428f1b485cb17d86bb1bd72570096647/src/fragment.js#L46
export const selectionBetween = (doc, from, to) => {
  let text = '';
  let separated = true;

  try {
    doc.nodesBetween(
      from,
      to,
      (node, pos) => {
        if (node.isText) {
          text += node.text.slice(Math.max(from, pos) - pos, to - pos);
          separated = false;
        }

        if (node.type.name === CONTRACT_NODE_TYPES.SMART_FIELD) {
          text += node.attrs.label;
          separated = false;
        }

        if (node.type.name === CONTRACT_NODE_TYPES.CONDITIONAL_CONTENT) {
          text += node.attrs.content;
          separated = false;
        }

        if (!separated && node.isBlock) {
          text += node.textContent === '' ? '\n' : '\n\n';
          separated = true;
        }
      },
      0
    );

    return text;
  } catch (err) {
    // in the event of an error, we just proceed and
    // ignore for now. This primarily occurs when there are
    // document state updates between transactions
    return text;
  }
};

export const isEmptyDoc = (doc) => {
  const noTextContent = doc.textContent.length === 0;
  const hasChild = doc?.firstChild;
  const missingChildContent = hasChild.content.size === 0;

  return missingChildContent && noTextContent;
};

function removeAmendedPrefix(smartFieldName) {
  return smartFieldName.replace(/^AMENDED_/, '').replace(/^IS_AMENDED_/, '');
}

function removeDateSuffix(smartFieldName) {
  return Object.keys(DATE_SMARTFIELD_SUFFIXES).reduce((acc, suffix) => {
    return acc.replace(`_${suffix}`, '');
  }, smartFieldName);
}

export const isSmartFieldAvailable = (fieldName, smartFieldDefinitions) => {
  return smartFieldDefinitions.some((smartField) => {
    let smartFieldToCompare = fieldName.toUpperCase();
    const normalizedSmartFieldKey = smartField.key.toUpperCase();

    if (smartField.canPrefixAmended) {
      // an example:
      // if normalizedSmartFieldKey === 'ADDITIONAL_HOLIDAY_DAYS_ADDITIONAL_HOLIDAY_DAYS' and smartField.canPrefixAmended and smartFieldToCompare == 'AMENDED_ADDITIONAL_HOLIDAY_DAYS_ADDITIONAL_HOLIDAY_DAYS'
      // we consider it valid because we allow dynamic smartfields with AMENDED and IS_AMENDED prefixes
      smartFieldToCompare = removeAmendedPrefix(smartFieldToCompare);
    }

    if (smartField.canFormatDate) {
      // an example:
      // if normalizedSmartFieldKey === 'CONTRACT_END_DATE' and smartField.canFormatDate and smartFieldToCompare == 'CONTRACT_END_DATE_DD_MM_YYYY'
      // we consider it valid because we allow dynamic smartfields with date suffixes
      smartFieldToCompare = removeDateSuffix(smartFieldToCompare);
    }

    if (normalizedSmartFieldKey === smartFieldToCompare) {
      return true;
    }

    return false;
  });
};

export const isSmartFieldPlaceholder = (
  fieldName,
  smartFieldDefinitions,
  placeholderSmartFields
) => {
  const smartFieldToCompare = fieldName.toUpperCase();

  const isValidSmartField = isSmartFieldAvailable(fieldName, smartFieldDefinitions);

  if (isValidSmartField) {
    return false;
  }

  return placeholderSmartFields.some((placeholder) => placeholder.key === smartFieldToCompare);
};

// TODO: Determine these explicitly from editor_state https://linear.app/remote/issue/EA-639/create-placeholder-smartfields-explicitly
export const getPlaceholderSmartFields = (fields, smartFieldDefinitions) =>
  fields?.reduce((acc, field) => {
    if (!isSmartFieldAvailable(field, smartFieldDefinitions)) {
      return [...acc, { key: field, isPlaceholder: true }];
    }
    return acc;
  }, []);

export const toggleSmartFieldAvailability = (pipeMap) => {
  if (pipeMap.inputNode?.type !== CONTRACT_NODE_TYPES.SMART_FIELD) {
    return pipeMap;
  }

  const { inputNode, smartFieldDefinitions } = pipeMap;

  const smartFieldIsAvailable = isSmartFieldAvailable(inputNode.attrs.field, smartFieldDefinitions);

  const outputNode = {
    ...inputNode,
    attrs: {
      ...inputNode.attrs,
      isUnavailable: !smartFieldIsAvailable,
    },
  };

  return { ...pipeMap, outputNode };
};

export const toggleConditionalSmartFieldAvailability = (pipeMap) => {
  const conditionalNodeTypes = [
    CONTRACT_NODE_TYPES.BLOCK_CONDITIONAL,
    CONTRACT_NODE_TYPES.INLINE_CONDITIONAL,
    CONTRACT_NODE_TYPES.LIST_ITEM_CONDITIONAL,
  ];

  if (!conditionalNodeTypes.includes(pipeMap.inputNode?.type)) {
    return pipeMap;
  }

  const { inputNode, smartFieldDefinitions, placeholderSmartFields } = pipeMap;

  const isValidSmartField =
    inputNode.attrs.fields.length > 0 &&
    isSmartFieldAvailable(inputNode.attrs.fields[0], smartFieldDefinitions);

  const smartFieldIsPlaceholder =
    inputNode.attrs.fields.length > 0
      ? isSmartFieldPlaceholder(
          inputNode.attrs.fields[0],
          smartFieldDefinitions,
          placeholderSmartFields
        )
      : false;

  const outputNode = {
    ...inputNode,
    attrs: {
      ...inputNode.attrs,
      isSmartFieldAvailable: isValidSmartField,
      isSmartFieldPlaceholder: smartFieldIsPlaceholder,
    },
  };

  return { ...pipeMap, outputNode };
};

// Recursively parses child conditional nodes and sets isParentDisabled
// to true if it is nested inside a block or list item conditional where
// the conditional result is false
const setDisabledConditionalChildNodeContent = (node) => {
  if (!node.content || !node.content.length) {
    return node.content;
  }

  const updatedContent = [];

  // eslint-disable-next-line
  for (const childNode of node.content) {
    const newNode =
      childNode.type === CONTRACT_NODE_TYPES.BLOCK_CONDITIONAL ||
      childNode.type === CONTRACT_NODE_TYPES.LIST_ITEM_CONDITIONAL
        ? {
            ...childNode,
            attrs: {
              ...childNode.attrs,
              isParentDisabled: true,
            },
          }
        : childNode;

    const content =
      newNode.content && newNode.content.length > 0
        ? setDisabledConditionalChildNodeContent(newNode)
        : newNode.content;

    updatedContent.push({
      ...newNode,
      content,
    });
  }

  return updatedContent;
};

const isNodeConditionalAndFalse = (node) => {
  const hasChildNodes = node.content && node.content.length > 0;

  const isHierarchicalConditional =
    node.type === CONTRACT_NODE_TYPES.BLOCK_CONDITIONAL ||
    node.type === CONTRACT_NODE_TYPES.LIST_ITEM_CONDITIONAL;

  const isConditionallyFalse =
    !node.attrs.isSmartFieldAvailable ||
    node.attrs.isSmartFieldPlaceholder ||
    !node.attrs.conditionalResult;

  return hasChildNodes && isHierarchicalConditional && isConditionallyFalse;
};

export const toggleDocumentConditionalStyling = (pipeMap) => {
  const conditionalNodeTypes = [
    CONTRACT_NODE_TYPES.BLOCK_CONDITIONAL,
    CONTRACT_NODE_TYPES.INLINE_CONDITIONAL,
    CONTRACT_NODE_TYPES.LIST_ITEM_CONDITIONAL,
  ];

  if (!conditionalNodeTypes.includes(pipeMap.inputNode?.type)) {
    return pipeMap;
  }

  const { inputNode } = pipeMap;

  if (isNodeConditionalAndFalse(inputNode)) {
    const outputNode = {
      ...inputNode,
      content: setDisabledConditionalChildNodeContent(inputNode),
    };

    return { ...pipeMap, outputNode };
  }

  const outputNode = {
    ...inputNode,
    attrs: {
      ...inputNode.attrs,
    },
  };

  return { ...pipeMap, outputNode };
};

// Recursively updates ProseMirror nodes
// It will call the callback function for every node. If the callback
// returns undefined, the function will use the original node values, but still
// recursively process any child nodes. The call back is passed the current
// node as an argument
export const cloneDocumentNodes = (parentNode, callback) => {
  const isRootNode = parentNode.type === 'doc';
  const updatedNode = callback(parentNode);

  const useExistingNode = updatedNode === undefined || isRootNode;
  const nodeHasContent = useExistingNode
    ? parentNode?.content?.length
    : updatedNode?.content && updatedNode?.content.length;

  if (!nodeHasContent) {
    return useExistingNode ? parentNode : updatedNode;
  }

  const contentNode = useExistingNode ? parentNode : updatedNode;
  const updatedContent = contentNode.content.map((node) => cloneDocumentNodes(node, callback));

  return { ...contentNode, content: updatedContent };
};

export const processTemplateJSON = (content, smartFieldDefinitions, placeholderSmartFields) => {
  const initialMap = {
    doc: content,
    smartFieldDefinitions,
    placeholderSmartFields,
    outputNode: undefined,
  };

  const pipeJSON = flow(toggleSmartFieldAvailability, toggleConditionalSmartFieldAvailability);

  return cloneDocumentNodes(content, (node) => {
    const jsonResult = pipeJSON({
      inputNode: node,
      ...initialMap,
    });

    return jsonResult.outputNode;
  });
};

export const processDocumentJSON = (content) => {
  const initialMap = {
    doc: content,
    outputNode: undefined,
  };

  const pipeJSON = flow(toggleDocumentConditionalStyling);

  return cloneDocumentNodes(content, (node) => {
    const jsonResult = pipeJSON({
      inputNode: node,
      ...initialMap,
    });

    return jsonResult.outputNode;
  });
};

export const getFriendlyName = (name) => capitalize(name?.split('_').join(' ').toLowerCase());

export const getSmartFieldsForSidebar = ({ input, fields }) => {
  return fields.reduce((acc, field) => {
    if (input[field]) {
      return [...acc, { name: getFriendlyFieldName(field), value: input[field] }];
    }
    return [...acc, { name: getFriendlyFieldName(field), value: null }];
  }, []);
};

export const getUnavailableSmartFields = ({ input, fields }) =>
  fields?.reduce((acc, field) => {
    if (Object.keys(input).includes(field)) {
      return acc;
    }
    return [...acc, field];
  }, []) ?? [];

export const getCommentSlugFromMark = (mark) => mark.attrs.class?.replace('comment-id-', '');
export const forEachDocumentComment = (doc, fn) => {
  doc.descendants((node, pos) => {
    node.marks.forEach((mark) => {
      if (mark.type.name === CONTRACT_MARK_TYPES.COMMENT_TEXT) {
        fn(mark, node, pos);
      }
    });
  });
};

export const getDocumentCommentPositions = (doc) => {
  const markPositions = {};

  if (!doc) {
    return markPositions;
  }

  forEachDocumentComment(doc, (mark, node, pos) => {
    const commentSlug = getCommentSlugFromMark(mark);
    if (commentSlug) {
      markPositions[commentSlug] = {
        from: pos,
        to: pos + node.nodeSize,
      };
    }
  });
  return markPositions;
};

/**
 * Determines if a comment is valid for the current editor based on the language.
 *
 * @param {Object} editor - The current editor instance.
 * @param {Object} comment - The comment object to validate.
 * @returns {boolean} True if the comment is valid for this editor, false otherwise.
 *
 * This function checks if a comment should be displayed in the current editor
 * based on the editor's language type and the comment's language metadata.
 * If the comment doesn't have a language set, it's assumed to be for the primary editor.
 */
export function isCommentValidForThisEditor(editor, comment) {
  const { languageEditorType } = editor.storage;
  const commentLanguage = comment.metadata?.language;

  if (!commentLanguage) {
    return languageEditorType === BILINGUAL_EDITOR_TYPE.PRIMARY;
  }

  return commentLanguage === languageEditorType;
}
