import { Title } from '@radix-ui/react-dialog';
import { Box, Text } from '@remote-com/norma';
import { forwardRef, type PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledCardDrawerTitleWrapper = styled(Box)`
  align-items: baseline;
  display: inline-flex;
  gap: ${({ theme }) => theme.space[2]}px;
`;

const StyledCardDrawerTitle = styled(Text)`
  ${({ theme }) => theme.typography.lgMedium};
`;

const StyledCardDrawerTitleCount = styled(Text)`
  ${({ theme }) => theme.typography.smMedium};
  color: ${({ theme }) => theme.colors.grey[500]};
`;

type StyledCardDrawerTitleProps = PropsWithChildren<{
  /**
   * The number of items in the drawer.
   * Will be displayed if larger than 0.
   */
  count?: number;
}>;

export const CardDrawerTitle = forwardRef<HTMLDivElement, StyledCardDrawerTitleProps>(
  ({ children, count, ...props }, ref) => {
    return (
      <StyledCardDrawerTitleWrapper ref={ref} {...props}>
        <Title asChild>
          <StyledCardDrawerTitle>{children}</StyledCardDrawerTitle>
        </Title>
        {!!count && <StyledCardDrawerTitleCount>({count})</StyledCardDrawerTitleCount>}
      </StyledCardDrawerTitleWrapper>
    );
  }
);
