import type { ComponentProps } from 'react';
import { forwardRef } from 'react';
import type { Except } from 'type-fest';

import { DEFAULT_DATE_FORMAT } from '../../foundations/date';

import { DatePickerRange } from './DatePickerRange';
import { DatePickerRegular } from './DatePickerRegular';

type DateProps = Date | number | string;

type Props =
  | ({
      selectsRange: true;
      selected?: [DateProps, DateProps] | null;
    } & ComponentProps<typeof DatePickerRange>)
  | ({
      selectsRange?: false | undefined;
      selected?: DateProps | null;
    } & ComponentProps<typeof DatePickerRegular>);

type OptionalProps = 'dateFormat' | 'selected' | 'maxDate' | 'minDate';

type RawProps = Except<Props, OptionalProps> & Partial<Pick<Props, OptionalProps>>;

export const DatePicker = forwardRef<HTMLInputElement, RawProps>(function DatePicker(
  rawProps,
  ref
) {
  const requiredProps = {
    ...rawProps,
    // Heads up: we can't have "...rawProps" over-write the default props,
    // because rawProps actually have them defined (even though with "undefined"):
    // rawProps { isClearable: undefined }
    // Therefore, we need to manually set the default values explicitly here:
    dateFormat: rawProps.dateFormat ?? DEFAULT_DATE_FORMAT,
    maxDate: rawProps.maxDate ?? new Date(9999, 11, 31, 0, 0, 0),
    minDate: rawProps.minDate ?? new Date(1900, 0, 1, 0, 0, 0),
    selected: rawProps.selected ?? null,
    showMonthYearPicker: rawProps.showMonthYearPicker ?? false,
    isClearable: rawProps.isClearable ?? true,
    // It's likely "withIcon" is removed at some point.
    // thus there is no typing here.
    // We keep it during the migration of Date Picker,
    // but let's remove it soon.
    withIcon: (rawProps as any).withIcon ?? true,
  };
  const { selectsRange, ...props } = requiredProps;

  if (selectsRange) {
    const initialSelected = Array.isArray(props.selected) ? props.selected : [null, null];
    // any: Our typing for Range is poor at the moment, should revisit
    return <DatePickerRange ref={ref} {...(props as any)} selected={initialSelected} />;
  }

  return <DatePickerRegular ref={ref} {...props} />;
});

DatePicker.defaultProps = {};
