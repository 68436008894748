import initials from 'initials';
import { forwardRef, memo } from 'react';
import type { HTMLProps } from 'react';

import type { ColorPropsWithCustomPalette } from '../../utils/types';

import { Img, InitialsImage } from './Avatar.styled';
import { getRandomColorForInitialsImage, getFormattedName } from './helpers';

export const avatarSizes = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as const;
export type AvatarSize = (typeof avatarSizes)[number];

export type AvatarTone = {
  background: ColorPropsWithCustomPalette['backgroundColor'];
  text: ColorPropsWithCustomPalette['color'];
};

export type AvatarProps = {
  /**
   * URL of the image file for the avatar.
   * */
  src?: HTMLProps<HTMLImageElement>['src'] | null;
  /**
   * Alternative text description of the avatar image. Should not be used if the
   * user name is displayed next to the avatar.
   */
  alt?: HTMLProps<HTMLImageElement>['alt'];
  /**
   * @deprecated - use fallback prop instead.
   * */
  name?: string;
  /**
   * Controls the size of the avatar.
   * */
  size?: AvatarSize;
  /**
   * Customizes the color scheme of the avatar.
   * */
  tone?: AvatarTone;
  /**
   * Fallback text to show when "src" is not defined. See also: "hasInitials".
   * */
  fallback?: string;

  /**
   * Show only initials of the fallback text.
   * Note that this is `true` by default.
   */
  hasInitials?: boolean;
};

// Use 2 exports for Storybook purposes
// https://github.com/storybookjs/storybook/issues/9586
// This component receives a ref for cases where parent needs access to the DOM, e.g. Avatar is wrapped by <Tooltip>
export const AvatarOriginal = forwardRef<HTMLImageElement, AvatarProps>(
  ({ alt = '', tone, name, size = 'md', src, fallback, hasInitials = true, ...props }, ref) => {
    if (name) {
      // eslint-disable-next-line no-console
      console.warn(`Avatar: name prop is deprecated. Use fallback prop instead.`);
    }

    if (fallback && !hasInitials && !name && !tone) {
      throw new Error('Avatar: `tone` prop is required when using `fallback` prop.');
    }

    if (name && fallback) {
      throw new Error(
        'Avatar: `name` and `fallback` props are mutually exclusive. Use one or the other.'
      );
    }

    if (!name && !fallback && !src) {
      return null;
    }

    const content = fallback ?? name;

    return src ? (
      <Img src={src} $size={size} alt={alt} ref={ref} {...props} />
    ) : (
      <InitialsImage
        ref={ref}
        $size={size}
        $tone={tone || (hasInitials && content && getRandomColorForInitialsImage(content))}
        {...props}
      >
        {hasInitials && content && initials(getFormattedName(content)).slice(0, 2)}
        {!hasInitials && content}
      </InitialsImage>
    );
  }
);

AvatarOriginal.displayName = 'Initials';

export const Avatar = memo(AvatarOriginal);
