type SvgUseProps = {
  href: string;
  width: number;
  height: number;
};

export const SvgUse = ({ href, width, height }: SvgUseProps) => (
  <svg width={width} height={height}>
    <use href={href} />
  </svg>
);
