// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconEdit2 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M19 3.17a1.83 1.83 0 0 0-1.3.54L4.4 17l-.97 3.56 3.55-.96L20.3 6.29A1.83 1.83 0 0 0 19 3.17Zm-1.47-1.7a3.83 3.83 0 0 1 4.18 6.24L8.2 21.2a1 1 0 0 1-.45.25l-5.5 1.5a1 1 0 0 1-1.22-1.22l1.5-5.5a1 1 0 0 1 .25-.45L16.3 2.3c.36-.35.78-.63 1.24-.83Z" /></svg>;
const ForwardRef = forwardRef(IconEdit2);
export { ForwardRef as IconEdit2 };