// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlinePromotion = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M17.26 2.9c-.46.26-1.04.66-1.87 1.24l-4.64 3.25v7.22l4.65 3.25c.82.58 1.4.98 1.86 1.24.46.27.68.31.8.3.36 0 .7-.19.93-.48.07-.1.16-.3.21-.83.05-.52.05-1.23.05-2.24v-9.7c0-1.01 0-1.72-.05-2.24-.05-.53-.14-.74-.2-.83-.23-.3-.58-.47-.95-.49-.11 0-.33.04-.8.3ZM9.25 14.25v-6.5H6a3.25 3.25 0 1 0 0 6.5h3.25Zm-1 1.5h-1.5v3.75a.75.75 0 0 0 1.5 0v-3.75Zm-3-.06v3.81a2.25 2.25 0 1 0 4.5 0v-3.75h.01l4.8 3.36c.8.55 1.43 1 1.95 1.3.52.3 1.05.52 1.61.5a2.75 2.75 0 0 0 2.06-1.08c.34-.44.46-1 .52-1.6.05-.6.05-1.38.05-2.34v-1.21a3.75 3.75 0 0 0 0-7.35V6.1c0-.96 0-1.74-.05-2.34a3.02 3.02 0 0 0-.52-1.6 2.75 2.75 0 0 0-2.06-1.08c-.56-.02-1.09.2-1.61.5s-1.16.75-1.94 1.3l-4.8 3.36H6a4.75 4.75 0 0 0-.75 9.44Zm15.5-6.81v4.24a2.25 2.25 0 0 0 0-4.24Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlinePromotion);
export { ForwardRef as IconV2OutlinePromotion };