import { Stack, sharedTransition } from '@remote-com/norma';
import styled from 'styled-components';

export const TodoOverlayListItemWrapper = styled(Stack).attrs({
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: 4,
  cursor: 'pointer',
})`
  ${sharedTransition('background-color')}

  padding: var(--card-drawer-space-y, ${({ theme }) => theme.space[5]}px)
    var(--card-drawer-space-x, ${({ theme }) => theme.space[6]}px);
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[50]};
  }
`;
