import debounce from 'lodash/debounce';
import { useEffect, useRef, useLayoutEffect, useMemo } from 'react';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useDebounce<T extends (...args: any) => ReturnType<T>>(
  callback: T,
  delay?: number
) {
  const callbackRef = useRef(callback);

  useIsomorphicLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const debouncedHandler = useMemo(
    () => debounce((...args) => callbackRef.current(...args), delay),
    [delay]
  );

  // Make sure debounced handler is canceled when component is unmounted
  useEffect(() => () => debouncedHandler.cancel(), [debouncedHandler]);

  return debouncedHandler;
}
