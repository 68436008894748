import { Text } from '@remote-com/norma';
import { Field } from 'formik';

import { ButtonInline } from '@/src/components/Button';
import { StyledFormFieldError } from '@/src/components/Ui/Form/AckField/AckField.styled';
import { CheckboxField as InputCheckbox } from '@/src/components/Ui/Form';
import { REMOTE_TERMS_OF_SERVICE } from '@/src/constants/routes';

import { TERMS_OF_SERVICE_FIELD_NAME } from './constants';

export function AcceptTermsOfServiceLabel({ labelPrefix }) {
  const labelText = 'I accept the';
  const preLabel = labelPrefix ? `${labelPrefix}, ${labelText}` : labelText;

  return (
    <Text color="grey.600">
      {preLabel}{' '}
      <ButtonInline
        href={REMOTE_TERMS_OF_SERVICE}
        target="_blank"
        rel="noopener noreferrer"
        trackNavigation={['sign-up', 'terms-of-service']}
      >
        Terms of Service
      </ButtonInline>{' '}
      and I'm authorized to accept for my company
    </Text>
  );
}

export function AcceptTermsInput({
  setTermsAcceptedAt,
  labelPrefix,
  name = TERMS_OF_SERVICE_FIELD_NAME,
}) {
  return (
    <Field name={name}>
      {({ form: { setFieldValue } }) => (
        <>
          <InputCheckbox
            type="checkbox"
            aria-label="Agree to the Terms of Service"
            label={<AcceptTermsOfServiceLabel labelPrefix={labelPrefix} />}
            name={TERMS_OF_SERVICE_FIELD_NAME}
            data-testid="tosAcceptDate"
            onChange={(event) => {
              if (event.target.checked) {
                setTermsAcceptedAt(new Date().toISOString());
              }
              setFieldValue(TERMS_OF_SERVICE_FIELD_NAME, event.target.checked);
            }}
          />

          <StyledFormFieldError name={TERMS_OF_SERVICE_FIELD_NAME} />
        </>
      )}
    </Field>
  );
}
