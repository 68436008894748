/* eslint-disable no-restricted-imports */
import type { ButtonIconProps, ButtonProps, DropdownMenuItemProps } from '@remote-com/norma';
import {
  Button,
  ButtonIcon,
  DropdownMenu,
  DropdownMenuList,
  sharedTransition,
} from '@remote-com/norma';
import { IconV2OutlineChevronDown } from '@remote-com/norma/icons/IconV2OutlineChevronDown';
import { IconV2OutlineMoreHorizontal } from '@remote-com/norma/icons/IconV2OutlineMoreHorizontal';
import type { ButtonHTMLAttributes, ElementRef, PropsWithChildren, Ref } from 'react';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { getDDSessionReplayAttribute } from '@/src/helpers/datadog';
import { withNextLink } from '@/src/helpers/withNextLink';

const MenuItem = withNextLink(
  forwardRef((props: DropdownMenuItemProps, ref: Ref<any>) => {
    const { children, ...rest } = props;
    const ddActionAttribute = getDDSessionReplayAttribute(props, {
      showChildContents: true,
    });

    return (
      <DropdownMenu.Item {...rest} {...ddActionAttribute} ref={ref}>
        {children}
      </DropdownMenu.Item>
    );
  })
);

/**
 * @deprecated Use MenuItem instead.
 */
const MenuLink = MenuItem;

export { DropdownMenu, DropdownMenuList, MenuItem, MenuLink };

/* -------------------------------------------------------------------------------------------------
 * Button helpers
 * -----------------------------------------------------------------------------------------------*/

const dropdownMenuButtonHelpersMessages = {
  triggerLabel: 'Show menu',
} as const;

const StyledDropdownMenuButton = styled(Button)<{ $hasCustomIcon: boolean }>`
  ${({ $hasCustomIcon }) =>
    !$hasCustomIcon &&
    css`
      > svg:last-of-type {
        ${sharedTransition('rotate')}
      }

      &[data-state='open'] > svg:last-of-type {
        rotate: 0.5turn;
      }
    `}
`;

export type DropdownMenuButtonProps = PropsWithChildren<{}> &
  ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Renders a button for use with dropdown menus with a default label and animated icon.
 * Animation will be disabled when using a custom icon.
 *
 * **Accessibility notes**
 * - There is no need to change the label depending on open/close state.
 */
export const DropdownMenuButton = forwardRef<ElementRef<'button'>, DropdownMenuButtonProps>(
  (
    {
      children = dropdownMenuButtonHelpersMessages.triggerLabel,
      IconAfter,
      ...props
    }: DropdownMenuButtonProps,
    ref
  ) => {
    return (
      <StyledDropdownMenuButton
        {...props}
        IconAfter={IconAfter || IconV2OutlineChevronDown}
        ref={ref}
        $hasCustomIcon={!!IconAfter}
      >
        {children}
      </StyledDropdownMenuButton>
    );
  }
);

type DropdownMenuButtonIconProps = {} & Partial<ButtonIconProps> &
  ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Renders an icon button for use with dropdown menus with default icon, label, tone, and variant.
 *
 * **Accessibility notes**
 * - There is no need to change the label depending on open/close state.
 */
export const DropdownMenuButtonIcon = forwardRef<ElementRef<'button'>, DropdownMenuButtonIconProps>(
  (
    {
      Icon = IconV2OutlineMoreHorizontal,
      label = dropdownMenuButtonHelpersMessages.triggerLabel,
      size = 'sm',
      variant = 'outline',
      ...props
    },
    ref
  ) => {
    return (
      <ButtonIcon
        {...props}
        Icon={Icon}
        label={label}
        size={size}
        tone="secondary"
        variant={variant}
        ref={ref}
      />
    );
  }
);
