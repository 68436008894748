import { Text } from '@remote-com/norma';

import { ModalActionable } from '@/src/components/Modal/ModalActionable';

const ConfirmDeleteModal = ({ filterName, onDelete, onCancel }) => {
  return (
    <ModalActionable
      open
      tone="destructive"
      title="Delete saved filter?"
      description={
        <Text variant="base" color="bayoux">
          Are you sure you want to delete the <Text variant="baseMedium">{filterName}</Text> saved
          filter?
        </Text>
      }
      confirmLabel="Delete"
      onConfirm={onDelete}
      onCancel={onCancel}
    />
  );
};

export default ConfirmDeleteModal;
