import styled, { css } from 'styled-components';

import { Box } from '../../foundations/layout/Box';
import { Stack } from '../../foundations/layout/Stack';
import { ButtonInline } from '../button';
import { Text } from '../text/Text';
import { Tooltip } from '../tooltip/Tooltip';
import type { TooltipProps } from '../tooltip/Tooltip';

type WarningDetail = {
  title: string;
  description: string;
  action?: {
    onClick: () => void;
    label: string;
  };
};

const StyledBox = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 2,
  p: 0,
  m: 0,
})`
  :not(:first-child) {
    padding-top: ${({ theme }) => theme.space[4]}px;
  }
  :not(:last-child) {
    padding-bottom: ${({ theme }) => theme.space[4]}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
  }
`;

const WarningItem = ({ detail }: { detail: WarningDetail }) => {
  return (
    <StyledBox>
      <Text color="red.700" variant="2xsSemiBold" textTransform="uppercase">
        {detail.title}
      </Text>
      <Text color="grey.900" variant="xs">
        {detail.description}
      </Text>
      {detail.action && (
        <ButtonInline onClick={detail.action.onClick}>{detail.action.label}</ButtonInline>
      )}
    </StyledBox>
  );
};

const WarningList = ({ warnings }: { warnings: Array<WarningDetail> }) => {
  return (
    <Stack justifyContent="flex-start" px={4} py={3} width="100%">
      {warnings.map((warning) => (
        <WarningItem key={warning.title} detail={warning} />
      ))}
    </Stack>
  );
};

export type WarningTooltipProps = Omit<TooltipProps, 'label' | 'content'> & {
  content: Array<WarningDetail> | WarningDetail;
};

const wrapperStyle = css`
  width: 324px;
  max-height: 380px;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
`;

export const WarningTooltip = ({ content, ...rest }: WarningTooltipProps) => {
  const contentAsArray = Array.isArray(content) ? content : [content];
  const labelComponent = <WarningList warnings={contentAsArray} />;

  return (
    <Tooltip
      {...rest}
      label={labelComponent}
      type="default"
      maxWidth="324px"
      styles={{
        wrapper: wrapperStyle,
      }}
    />
  );
};
