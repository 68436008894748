import * as Popover from '@radix-ui/react-popover';
import {
  aiFormAssistantEvents,
  pathnameToLocationOfAction,
  trackEvent,
} from '@remote-com/analytics';
import { FeedbackMessage, Stack, Text, useFormGroupContext } from '@remote-com/norma';
import { IconTimes } from '@remote-com/norma/icons/IconTimes';
import { IconV2SolidStars } from '@remote-com/norma/icons/IconV2SolidStars';
import { useFormikContext } from 'formik';
import kebabCase from 'lodash/kebabCase';
import { useRouter } from 'next/router';
import type { MouseEvent, FocusEvent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { Button, ButtonIcon, ButtonInline } from '@/src/components/Button';
import AIFormAssistantInput from '@/src/components/Ui/Form/AIFormAssistant/AIFormAssistantInput';
import AIFormAssistantResult from '@/src/components/Ui/Form/AIFormAssistant/AIFormAssistantResult';
import type { AIFormAssistantConfig } from '@/src/domains/aiPoweredForms';
import { useAIFormAssistantStreamMutation } from '@/src/domains/aiPoweredForms';

import { AIFormAssistantWrapper } from './AIFormAssistant.styled';

type Props = AIFormAssistantConfig & {
  /**
   * An event handler that is called when the AI assistant button loses focus.
   */
  onBlur?: (event: FocusEvent<HTMLAnchorElement>) => void;
};

const AIFormAssistant = ({
  formTitle = '',
  formContext = '',
  generateOnOpen = false,
  onBlur,
}: Props): JSX.Element => {
  const { colors } = useTheme();
  const { value: fieldValue, label, id = '_', onChange } = useFormGroupContext();
  const { values } = useFormikContext();
  const { pathname } = useRouter();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [displayInput, setDisplayInput] = useState(true);
  const [aiResult, setAIResult] = useState('');

  const {
    mutate: triggerPrompt,
    isLoading,
    error,
    variables,
  } = useAIFormAssistantStreamMutation(setAIResult, {
    onSuccess: () => {
      setDisplayInput(false);
    },
  });

  const location = useMemo(() => pathnameToLocationOfAction(pathname), [pathname]);
  const formFieldName = useMemo(() => kebabCase(id), [id]);

  const handleWrapperClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  // spreading values to remove the value of the current field
  const formValues = useMemo(() => {
    const { [id]: _, ...rest } = (values as { [key: string]: unknown }) || { [id]: '' };
    return rest;
  }, [values, id]);

  const handleSubmit = useCallback(
    (userInput: string) => {
      setAIResult('');
      triggerPrompt({
        formTitle,
        formContext,
        formValues: JSON.stringify(formValues),
        formField: id || '',
        prompt: `Generate a complete and detailed content for the user to input into the ${
          typeof label === 'string' ? label : id
        } form field.`,
        userInput,
      });
    },
    [formTitle, formContext, formValues, id, label, triggerPrompt]
  );

  const handleOpen = useCallback(() => {
    setDisplayInput(!generateOnOpen);
    setIsExpanded(true);

    // Generate content automatically on open
    if (generateOnOpen && !isLoading) {
      handleSubmit(fieldValue || '');
    }

    trackEvent(aiFormAssistantEvents.AI_FORM_ASSISTANT_OPENED(location, formTitle, formFieldName));
  }, [location, formTitle, formFieldName, generateOnOpen, isLoading, fieldValue, handleSubmit]);

  const handleClose = useCallback(() => {
    setIsExpanded(false);
    setAIResult('');
    // Focus back on the textarea input when closing the AI assistants
    wrapperRef.current?.parentNode?.querySelector('textarea')?.focus();
  }, []);

  // ### Result Actions ###
  const handleCancel = useCallback(() => {
    handleClose();
    trackEvent(
      aiFormAssistantEvents.AI_FORM_ASSISTANT_CANCELLED(location, formTitle, formFieldName)
    );
  }, [handleClose, location, formTitle, formFieldName]);

  const handleFocusInput = () => {
    const textarea = wrapperRef.current?.querySelector('textarea');
    if (textarea) {
      textarea.focus();
      textarea.selectionStart = textarea.value.length;
      textarea.selectionEnd = textarea.value.length;
    }
  };

  const handleTryAgain = useCallback(() => {
    setDisplayInput(true);
    setAIResult('');
    // focus on the input when trying again
    setTimeout(() => {
      handleFocusInput();
    }, 200);

    trackEvent(
      aiFormAssistantEvents.AI_FORM_ASSISTANT_TRY_AGAIN(location, formTitle, formFieldName)
    );
  }, [location, formTitle, formFieldName]);

  const handleUseResult = useCallback(() => {
    if (!onChange) return;
    onChange({ target: { value: aiResult, id } });
    // making sure the result isn't reset before onChange uses it
    setTimeout(() => {
      handleClose();
    }, 100);

    trackEvent(
      aiFormAssistantEvents.AI_FORM_ASSISTANT_USE_THIS(location, formTitle, formFieldName)
    );
  }, [onChange, aiResult, id, handleClose, location, formTitle, formFieldName]);

  const handleRetry = useCallback(() => {
    if (!variables) return;
    // retry the mutation with the same values
    triggerPrompt(variables);
  }, [variables, triggerPrompt]);

  // ### Popover Handlers ###
  const handleOpenChange = (open: boolean) => {
    return open ? handleOpen() : handleClose();
  };

  const handleOpenAutoFocus = (evt: Event) => {
    evt.preventDefault();
    handleFocusInput();
  };

  const displayResult = (isLoading || !!aiResult) && !error;

  return (
    <Popover.Root open={isExpanded} onOpenChange={handleOpenChange}>
      <AIFormAssistantWrapper
        ref={wrapperRef}
        $isExpanded={isExpanded}
        onClick={handleWrapperClick}
      >
        {!isExpanded && (
          <Popover.Trigger asChild>
            <Button size="xs" variant="ghost" IconBefore={IconV2SolidStars} onBlur={onBlur}>
              Write with AI
            </Button>
          </Popover.Trigger>
        )}
        <Popover.Content onOpenAutoFocus={handleOpenAutoFocus}>
          <Stack direction="row" alignItems="center" gap={3} mb={3}>
            <IconV2SolidStars width={20} color={colors.grey[900]} />
            <Text variant="smMedium" color="grey.900" mr="auto">
              Generate {typeof label !== 'string' ? 'with AI' : label.toLowerCase()}
            </Text>
            <Popover.Close asChild>
              <ButtonIcon
                Icon={IconTimes}
                label="Close"
                variant="ghost"
                size="xs"
                tone="secondary"
              />
            </Popover.Close>
          </Stack>
          <AIFormAssistantInput
            isVisible={displayInput}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
          {displayResult && (
            <AIFormAssistantResult
              aiResult={aiResult}
              isLoading={isLoading}
              onCancel={handleCancel}
              onTryAgain={handleTryAgain}
              onUseResult={handleUseResult}
            />
          )}
          <Text variant="2xs" color="grey.500" textAlign="center" mt={4}>
            Remote AI can make mistakes. Double check its responses.
          </Text>
          {!!error && (
            <FeedbackMessage title="AI request failed" variant="error" mb={0} mt={4}>
              Something went wrong. Please{' '}
              <ButtonInline tone="primary" onClick={handleRetry}>
                try again
              </ButtonInline>
              .
            </FeedbackMessage>
          )}
        </Popover.Content>
      </AIFormAssistantWrapper>
    </Popover.Root>
  );
};

export default AIFormAssistant;
