import { inputStyles } from '@remote-com/norma';
import { CardNumberElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';

/**
 * Temporarily wrapping the CardNumberElement in a styled component to avoid circular dependency issue:
 * will be addressed in https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/34531
 */
export const CardNumberElementStyled = styled((props) => <CardNumberElement {...props} />)`
  ${inputStyles}
`;

export const CreditCardVendorLogo = styled.svg`
  aspect-ratio: 36/24;
  border-radius: 4px;
  height: 24px;
  outline-offset: -1px;
  outline: 1px solid #d8dadc;
`;
