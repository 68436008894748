import { FormGroup, InputSelectAsideIcon } from '@remote-com/norma';
import { forwardRef, useState, useRef } from 'react';

import { isValueFilled } from '@/src/components/Ui/Form/inputs/inputSelectAsyncCreatable/utils';
import useClickOutside from '@/src/hooks/useClickOutside';
import { useProvidedRefOrCreate } from '@/src/hooks/useProvidedRefOrCreate';

import { AsyncInputAndCreateElement } from './AsyncInputAndCreateElement';

export const InputSelectAsyncCreatable = forwardRef(
  (
    {
      components,
      options,
      onFocus,
      onBlur,
      onMenuOpen,
      onMenuClose,
      styles,
      isClearable,
      onCreateOption,
      hideSelectedOptions,
      closeMenuOnSelect,
      showChevron = true,
      showPlaceholder = false,
      ...props
    },
    ref
  ) => {
    const inputRef = useProvidedRefOrCreate(ref);
    const asideRef = useRef();
    const containerRef = useRef();
    const menuRef = useRef(null);
    const [hasUserInput, setHasUserInput] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const preventDuplicatedEventAfterBlur = useRef(false);
    useClickOutside({
      refEl: containerRef,
      listen: isMenuOpen,
      onClick: () => isMenuOpen && setIsMenuOpen(false),
      whitelistEl: (target) => menuRef?.current?.contains(target),
    });

    const handleOnClick = () => {
      if (!isMenuOpen && !props?.readOnly && !props?.disabled) {
        setIsMenuOpen(true);
      }
    };

    const handleOnFocus = () => {
      onFocus?.();
    };

    const handleOnBlur = (event) => {
      preventDuplicatedEventAfterBlur.current = true;

      if (event.relatedTarget !== asideRef.current) {
        setIsMenuOpen(false);
      }

      onBlur?.(event);
    };

    const handleOnMenuOpen = () => {
      setIsMenuOpen(true);
      onMenuOpen?.();
    };

    const handleOnMenuClose = () => {
      if (!preventDuplicatedEventAfterBlur.current) {
        setIsMenuOpen(false);
      }

      preventDuplicatedEventAfterBlur.current = false;

      onMenuClose?.();
    };

    const handleOnInputChange = (value) => {
      setHasUserInput(!!value.length);
    };

    const identifiers = {
      id: props.id || `${props.name}-selector`,
      inputId: `${props.name}-selector-input`,
      instanceId: `${props.name}-selector-instance`,
    };

    const hasValue = isValueFilled(props.value, props.defaultValue, hasUserInput);

    return (
      <FormGroup
        {...props}
        id={identifiers.inputId}
        containerRef={containerRef}
        hasFocus={isMenuOpen}
        hasValue={hasValue}
        onClick={handleOnClick}
        aside={
          !showChevron
            ? () => null
            : ({ $hasFocus, $hasError, $isReadOnly }) => {
                return (
                  <InputSelectAsideIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();

                      if ($isReadOnly) {
                        return;
                      }

                      setIsMenuOpen(!$hasFocus);
                      if (!$hasFocus) {
                        inputRef.current?.focus();
                      }
                    }}
                    tabIndex="-1"
                    $hasFocus={isMenuOpen}
                    $hasError={$hasError}
                    $isReadOnly={$isReadOnly}
                    ref={asideRef}
                    $size={props.size}
                  />
                );
              }
        }
        ref={inputRef}
      >
        <AsyncInputAndCreateElement
          isClearable={isClearable}
          components={components}
          styles={styles}
          identifiers={identifiers}
          isMenuOpen={isMenuOpen}
          handleOnFocus={handleOnFocus}
          handleOnBlur={handleOnBlur}
          handleOnInputChange={handleOnInputChange}
          handleOnMenuClose={handleOnMenuClose}
          handleOnMenuOpen={handleOnMenuOpen}
          options={options}
          menuRef={menuRef}
          onCreateOption={onCreateOption}
          hideSelectedOptions={hideSelectedOptions}
          closeMenuOnSelect={closeMenuOnSelect}
          forcePlaceholder={showPlaceholder}
          {...props}
        />
      </FormGroup>
    );
  }
);
