import { mergeAttributes, Node, ReactNodeViewRenderer } from '@tiptap/react';

import { SignatureBlockComponent } from './SignatureBlockComponent';

export const SignatureBlockField = Node.create({
  name: 'signatureBlockField',
  group: 'block',
  content: 'block*',

  addNodeView() {
    return ReactNodeViewRenderer(SignatureBlockComponent);
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (element) => element.getAttribute('data-type') === this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ 'data-type': this.name }, HTMLAttributes), 0];
  },
});
