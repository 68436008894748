import { Tooltip } from '@remote-com/norma';
import { IconV2OutlineAlertTriangle } from '@remote-com/norma/icons/IconV2OutlineAlertTriangle';
import { useTheme } from 'styled-components';

export const AccountLockedIndicator = ({ accountLocked = false }) => {
  const { colors } = useTheme();
  if (!accountLocked) return null;
  return (
    <Tooltip label="Account is locked">
      <IconV2OutlineAlertTriangle
        fill={colors.yellow[600]}
        width="15px"
        data-testid="account-locked-indicator"
      />
    </Tooltip>
  );
};
