import type { FormGroupProps } from '../../form-group';

import { Wrapper, Count } from './InputTextareaCounter.styled';

type InputTextareaCounterProps = {
  maxLength?: number;
  value?: FormGroupProps['value'];
};

export function InputTextareaCounter({ maxLength, value }: InputTextareaCounterProps) {
  const valueMaxLength = maxLength || '∞';
  const valueLength = typeof value === 'string' ? value.length : 0;

  const countLabel = `${valueLength} of ${valueMaxLength} characters used`;

  return (
    <Wrapper>
      <Count aria-label={countLabel}>
        {valueLength}/{valueMaxLength}
      </Count>
    </Wrapper>
  );
}
