import type { ReactNode } from 'react';
import type { DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import type { StackProps } from '../../layout';
import { Stack } from '../../layout';

import { DEFAULT_SIZE } from './constants';

type ListNumbersShape = 'round' | 'simple';
type ListNumbersSize = 'md' | 'lg';

type TypographyListNumbers = {
  [shape in ListNumbersShape]: {
    [size in ListNumbersSize]: {
      text: FlattenSimpleInterpolation;
      number: FlattenSimpleInterpolation;
    };
  };
};

const sizes = (typography: DefaultTheme['typography']): TypographyListNumbers => {
  return {
    simple: {
      md: {
        number: typography.smSemiBold,
        text: typography.sm,
      },
      lg: {
        number: typography.baseSemiBold,
        text: typography.base,
      },
    },
    round: {
      md: {
        number: css``,
        text: css``,
      },
      lg: {
        number: typography.smSemiBold,
        text: typography.lgMedium,
      },
    },
  };
};

export const marginDensity: Record<ListNumbersSize, number> = {
  md: 5,
  lg: 6,
};

const ListNumbersStyled = styled(Stack)<{ $shape: ListNumbersShape; $size: ListNumbersSize }>`
  list-style: none;
  counter-reset: item;

  > li {
    display: flex;
    align-items: center;
    ${(props) => sizes(props.theme.typography)[props.$shape][props.$size].text}

    &::before {
      ${(props) => sizes(props.theme.typography)[props.$shape][props.$size].number}
      ${(props) =>
        props.$shape === 'round'
          ? `
            background: ${props.theme.colors.brand[100]}; 
            width: 28px; 
            height: 28px; 
            border-radius: ${props.theme.borderRadius.full};
            content: counter(item);
            `
          : `
            content: counter(item) '.';
          `}
      counter-increment: item;
      display: flex;
      flex-shrink: 0;
      color: ${({ theme }) => theme.colors.primary};
      margin-right: 16px;
      justify-content: center;
      align-items: center;
    }

    &.nested {
      align-items: flex-start;
      flex-wrap: wrap;

      span {
        display: inline-block;
        width: 85%;
      }

      a {
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 500;
        text-decoration: none;
      }

      ul {
        margin-left: 28px;
        margin-right: 27px;
        width: 85%;

        li {
          span {
            width: 100%;
          }
        }
      }
    }
  }
`;

export interface ListNumbersProps extends StackProps {
  /** Controls the size of each list item */
  size?: ListNumbersSize;
  /** Controls the shape of each list item */
  shape?: ListNumbersShape;
  /** Controls the space between each list item */
  gap?: 'md' | 'lg';
  /** Color of the text of each list item */
  color?: 'grey.500' | 'grey.600';
  children: ReactNode;
}

export const ListNumbers = ({
  gap = 'md',
  shape = 'simple',
  size = DEFAULT_SIZE,
  ...props
}: ListNumbersProps) => (
  <ListNumbersStyled
    forwardedAs="ol"
    p={0}
    m={5}
    role="list"
    gap={marginDensity[gap] || marginDensity.md}
    $size={size}
    $shape={shape}
    {...props}
  />
);
