import type { ReactElement, ReactNode } from 'react';
import { useMemo } from 'react';
import type { FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import { ButtonInline } from '../../../core/button';
import { SROnly } from '../../../core/text';
import { Box } from '../../../layout';
import type { InputFileObject } from '../helpers';
import { InputFileStyled } from '../styled';

import { InputFileDropRequirements } from './requirements';

const {
  Description,
  DropArea,
  DropAreaImage,
  DropAreaSubtitle,
  DropAreaTitle,
  DropAreaTitleGroup,
  HiddenInput,
  Label,
} = InputFileStyled;

// TODO: add max num files allowed

type Props = {
  description: ReactNode;
  fileObjects: InputFileObject[];
  hasErrors: boolean;
  hideDescription: boolean;
  hideLabel: boolean;
  isUploading: boolean;
  label?: string;
  maxSize: number;
  name?: string;
  onAddFiles: (files: File[], rejections: FileRejection[]) => void;
  singleFile: boolean;
  /**
   * The "single file" prop does not actually prevent the user from
   * _selecting_ more files.
   * This is not intuitive but somewhat expected,
   * because it was built with the assumption that the "uploader" outside
   * will handle that logic.
   *
   * However, that assumption does not work in form builders,
   * where it's not possible to dynamically adapt to different value formats,
   * thus forcing us to have the same logic for single and multiple files.
   * Therefore, we introduce this prop to simply disable the input in this case,
   * as a safe workaround.
   *
   * This is intentionally marked as deprecated to discourage direct usages
   * outside of form builders.
   * To learn more, see its usage at the parent "uploader" file.
   *
   * @deprecated
   */
  disableInputOnSingleFileUploaded?: boolean;
  size: 'small' | 'large';
  supportedFileTypes: string[];
};

export function InputFileDropArea(props: Props): ReactElement {
  const {
    supportedFileTypes,
    description,
    fileObjects,
    hasErrors,
    isUploading,
    size: initialSize,
    label,
    maxSize,
    singleFile,
    onAddFiles,
    name,
    hideLabel,
    hideDescription,
    disableInputOnSingleFileUploaded,
  } = props;

  const size = fileObjects.length ? 'small' : initialSize;

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: supportedFileTypes,
    maxSize,
    multiple: !singleFile,
    maxFiles: singleFile ? 1 : undefined,
    // Prevents click triggering on the label and potential file dialog opening twice.
    noClick: true,
    noKeyboard: true,
    onDrop: onAddFiles,
    // This does NOT prevent the user from opening the file dialog
    // and selecting files.
    // It only discards the files afterwards,
    // which causes a confusing user experience.
    // We need to manually disable the "input" as well.
    // See "HiddenInput" for more.
    disabled: disableInputOnSingleFileUploaded && fileObjects.length > 0,
  });

  const isStatusDone =
    !isDragActive &&
    fileObjects?.some((fileObject) => fileObject?.file instanceof File) && // to account for existing files in case of and edit form
    /**
     * @todo fileObjects is an array, so accessing "errors" from it is likely wrong.
     * However, we keep source code as is during Norma migration.
     */
    !(fileObjects as any).errors?.length;

  const dragMessage = singleFile ? 'Drag your file here or' : 'Drag your file(s) here or';
  const isFieldInvalid = hasErrors;
  const dropTitle = useMemo(() => {
    if (!isStatusDone) return dragMessage;
    if (hasErrors) return 'Document added with errors';
    return 'Document added successfully.';
  }, [isStatusDone, hasErrors, dragMessage]);
  const addMoreMessage = singleFile ? '' : 'Add more file(s)';

  return (
    <>
      {!hideLabel ? <Label>{label}</Label> : <SROnly>{label}</SROnly>}
      {!hideDescription && <Description>{description}</Description>}
      <DropArea
        {...getRootProps()}
        $hasErrors={isFieldInvalid}
        isDragging={isDragActive}
        $size={size}
        data-testid="dropzone-root"
      >
        <DropAreaImage
          hasErrors={isFieldInvalid}
          isDragging={isDragActive}
          isUploading={isUploading}
          isStatusDone={isStatusDone}
        />
        <DropAreaTitleGroup gap={2} $size={size}>
          <Box>
            <DropAreaTitle>{dropTitle}</DropAreaTitle>
            <DropAreaSubtitle>
              <ButtonInline as="span">
                {!isStatusDone ? 'click to upload' : addMoreMessage}
              </ButtonInline>
            </DropAreaSubtitle>
          </Box>
          <InputFileDropRequirements supportedTypes={supportedFileTypes} maxSize={maxSize} />
        </DropAreaTitleGroup>
        <HiddenInput
          aria-label={label}
          name={name}
          data-testid="file-input"
          // Disable the file input for "legacy" behaviour.
          // To learn more, see:
          // - "disabled" at "useDropzone" for more
          // - Comment on "disable input on single file uploaded" interface
          disabled={disableInputOnSingleFileUploaded && fileObjects.length > 0}
          {...getInputProps({
            onClick: (event) => {
              // Reset the dropzone input value so that the same file(s) can be cleared and then re-uploaded without having to close it first
              /**
               * @todo This is wrong on 2 levels.
               *
               * First, "event" is actually typed correctly,
               * but that only defines the type of "currentTarget", not "target".
               * "target" is always expected to be Element.
               *
               * Second, "value" is a string in typing, not accepting null.
               * This is true even if we use "currentTarget",
               * which is of type HTMLInputElement.
               */
              (event.target as any).value = null;
              return event;
            },
          })}
          // Override to react-dropzone:
          // originally display none hides completely the input, affecting accessibility
          // and also Testing Library assertions. Use opacity: 0 instead (done in styled components)
          // eslint-disable-next-line no-inline-styles/no-inline-styles
          style={{ display: 'block' }}
          // Restore tabindex to make it keyboard friendly
          /**
           * @todo tabIndex expects a number, so this is likely wrong.
           * However, we keep it as is during the migration.
           */
          tabIndex={'0' as any}
          type="file"
        />
      </DropArea>
    </>
  );
}
