import { GOOGLE_DRIVE_HOST } from './constants';

export async function getAllFilesFromDriveFolder(driveFolderId) {
  let shouldContinue = true;
  let nextPageToken;
  let results = [];

  while (shouldContinue) {
    // eslint-disable-next-line no-await-in-loop
    const response = await window.gapi.client.drive.files.list({
      q: `'${driveFolderId}' in parents and trashed = false`,
      pageToken: nextPageToken,
      includeItemsFromAllDrives: true,
      corpora: 'allDrives',
      supportsAllDrives: true,
    });

    // Stop the loop if a response is empty (means we lost auth)
    if (!response?.result) {
      shouldContinue = false;
    } else {
      nextPageToken = response.result.nextPageToken;
      results = results.concat(response.result.files);

      // Keep looping as long as we have a nextPageToken
      shouldContinue = Boolean(response.result.nextPageToken);
    }
  }

  return results;
}

/**
 * From a list of files with different extensions, we need to filter and return only those that are
 * PDF files.
 * @param {array} fileList list of files to filter
 * @returns list of files that are only PDF.
 */
export function filterPdfFiles(fileList) {
  if (fileList == null || fileList.length === 0) return fileList;
  return fileList.filter((file) => file?.mimeType === 'application/pdf');
}

/**
 * Get Google Drive ID from the provided URL.
 * @param {string} urlContent - URL provided by the user.
 * @returns the Google Drive ID.
 */
export function getGoogleDriveId(urlContent) {
  const url = new URL(urlContent);

  if (url.host !== GOOGLE_DRIVE_HOST) return undefined;

  const pathTokens = url.pathname.split('/');
  const urlEnd = pathTokens[pathTokens.length - 1];

  return urlEnd;
}

export function generatePdfFile(fileName, fileContent) {
  const fileOptions = {
    type: 'application/pdf',
    lastModified: new Date().getTime(),
  };
  const bytes = new Uint8Array(fileContent.length);
  for (let i = 0; i < fileContent.length; i++) bytes[i] = fileContent.charCodeAt(i);
  const blob = new Blob([new Uint8Array(bytes).buffer], { type: 'application/pdf' });
  return new File([blob], fileName, fileOptions);
}
