import { useCallback, useMemo, useState } from 'react';

import { onlyConfigurableColumns } from '@/src/components/Table/hooks/useColumnsState';
import type { ColumnState } from '@/src/components/Table/hooks/useColumnsState/types';

/* -------------------------------------------------------------------------------------------------
 * Shared types
 * -----------------------------------------------------------------------------------------------*/

type ColumnsState = ColumnState[];

type ColumnId = string;

type ColumnIndex = number;

type ColumnsOrder = ColumnId[];

/* -------------------------------------------------------------------------------------------------
 * Helpers
 * -----------------------------------------------------------------------------------------------*/

const cleanOrder = (order: ColumnsOrder, defaultOrder: ColumnsOrder) => {
  return order.filter((columnId) => defaultOrder.includes(columnId));
};

const isDefaultOrder = (order: ColumnsOrder, defaultOrder: ColumnsOrder) => {
  return order.every((columnId, index) => columnId === defaultOrder[index]);
};

const getOrderFromColumnsState = (columnsState: ColumnsState): ColumnsOrder => {
  return columnsState.map(([id]) => id);
};

/* -------------------------------------------------------------------------------------------------
 * Hook
 * -----------------------------------------------------------------------------------------------*/

export type UseColumnsStateOrderProps = {
  /** The initial, unordered columns state. */
  initialColumnsState: ColumnsState;
  /** Initial order that should be applied (e.g. from a saved table). */
  initialOrder?: ColumnsOrder;
  /** Callback function that is called when the column order changes. */
  onChangeColumnOrder?: () => void;
};

/**
 * This hook provides a flexible way to manage the order of columns in table components.
 * It allows for dynamic ordering of columns based on user interaction while offering
 * an initial order setup (e.g. for saved tables) and a callback to listen for order changes.
 */
export const useColumnsStateOrder = ({
  initialColumnsState,
  initialOrder = [],
  onChangeColumnOrder,
}: UseColumnsStateOrderProps) => {
  const defaultColumnsState = useMemo(() => {
    const configurableColumnsSpace: ColumnsState = [];
    const nonConfigurableColumnsSpace: ColumnsState = [];

    initialColumnsState.forEach((columnState) => {
      if (onlyConfigurableColumns(columnState)) {
        configurableColumnsSpace.push(columnState);
      } else {
        nonConfigurableColumnsSpace.push(columnState);
      }
    });

    return [...configurableColumnsSpace, ...nonConfigurableColumnsSpace];
  }, [initialColumnsState]);

  const defaultOrder = useMemo(
    () => getOrderFromColumnsState(defaultColumnsState),
    [defaultColumnsState]
  );

  const cleanInitialOrder = useMemo(
    () => cleanOrder(initialOrder, defaultOrder),
    [defaultOrder, initialOrder]
  );

  const [order, setOrder] = useState<ColumnsOrder>(cleanInitialOrder);

  const orderedColumnsState = useMemo(() => {
    if (!order.length) {
      return defaultColumnsState;
    }

    /** Columns state with customized order. */
    const configuredColumnsState = order.reduce<ColumnsState>((columnsState, columnId) => {
      const columnState = defaultColumnsState.find(([id]) => id === columnId);

      if (!columnState) {
        return columnsState;
      }

      return [...columnsState, columnState];
    }, []);

    /** Additional columns state without customized order. */
    const additionalColumnsState = defaultColumnsState.filter(([id]) => !order.includes(id));

    return [...configuredColumnsState, ...additionalColumnsState];
  }, [defaultColumnsState, order]);

  return {
    /**
     * The column order.
     */
    order,
    /**
     * Dispatch function for setting the order.
     */
    setOrder,
    /**
     * The ordered columns state.
     */
    orderedColumnsState,
    /**
     * Change the order of columns.
     */
    changeColumnsOrder: useCallback(
      ({ id, index }: { id: ColumnId; index: ColumnIndex }) => {
        const nextOrder = [...getOrderFromColumnsState(orderedColumnsState)];

        const columnToMoveIndex = nextOrder.indexOf(id);

        /** Exit early if column does not exist or is not changing position. */
        if (columnToMoveIndex === -1 || columnToMoveIndex === index) {
          return;
        }

        const columnId = nextOrder.splice(columnToMoveIndex, 1)[0];
        nextOrder.splice(index, 0, columnId);

        if (isDefaultOrder(nextOrder, defaultOrder)) {
          setOrder([]);
        } else {
          setOrder(nextOrder);
        }

        onChangeColumnOrder?.();
      },
      [defaultOrder, onChangeColumnOrder, orderedColumnsState]
    ),
    /**
     * Change the order of columns to its default state.
     */
    resetColumnsOrder: () => {
      setOrder([]);
    },
  };
};
