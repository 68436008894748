import styled from 'styled-components';

import { Box } from '../../layout';
import { StandaloneLink } from '../button';

export const Wrapper = styled.span`
  align-items: center;
  justify-content: space-between;
  display: inline-flex;
`;

export const Value = styled(Box).attrs({
  as: 'span',
})`
  ${({ theme }) => theme.typography.mono}
`;

export const ButtonStyled = styled(StandaloneLink)`
  &:last-child {
    margin-left: 8px;
  }
`;
