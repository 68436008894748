import { InfoBlock, Stack, HTMLRendered } from '@remote-com/norma';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';

import { ButtonCallout } from '@/src/components/ButtonCallout';
import AckField from '@/src/components/Ui/Form/AckField';
import { FieldGroupLabel, InputLabel } from '@/src/components/Ui/Form/Input';
import RemoteLogoLoader from '@/src/components/Ui/Loader/RemoteLoader';
import {
  benefitPlanCostTypes as costTypes,
  groupEmployerOptedOut,
} from '@/src/domains/benefits/constants';
import { friendlyBenefitPlanCost, groupHasSelection } from '@/src/domains/benefits/helpers';
import { workScheduleTypes } from '@/src/domains/employment/employer/contractDetails/constants';
import { getPreviewPageUrl } from '@/src/domains/files/helpers';

import {
  Description as StyledDescription,
  FinePrint,
  DetailsCard,
} from './BenefitsSelection.styled';
import { LockedBenefits } from './LockedBenefits';
import { MultipleGroups } from './MultipleGroups';

export const hasAvailableBenefits = (benefitGroups) =>
  (benefitGroups?.benefitGroups || benefitGroups || []).some(
    ({ benefitTiers, benefitSubgroups }) =>
      benefitTiers?.length > 0 ||
      benefitSubgroups?.some((subGroup) => subGroup.benefitTiers?.length > 0)
  );

function getPlanDescription({ description, name, planCostType, costDescription }) {
  switch (planCostType) {
    case costTypes.OTHER:
      return costDescription ? `${name}<br />${costDescription}` : name;
    default:
      return description ? `${name}<br />${description}` : name;
  }
}

export function hasPartTimeException(workSchedule, workHoursPerWeek, threshold) {
  const lessThanThreshold = parseInt(workHoursPerWeek) < threshold;
  const isPartTime = workSchedule === workScheduleTypes.PART_TIME;

  return !!(threshold && isPartTime && lessThanThreshold);
}

export function getOptionsFromGroup(group, hasPartTimeRule) {
  if (!group) {
    return [];
  }

  const tiers = group.benefitSubgroups?.length
    ? group.benefitSubgroups.flatMap((subgroup) => subgroup.benefitTiers)
    : group.benefitTiers;

  const options = (tiers || []).map((tier) => ({
    value: tier.value,
    label: tier.displayCost ? `${tier.name} - Price: ${tier.displayCost}` : tier.name,
    description: tier.description
      ? tier.description
      : tier.benefitPlans.map(getPlanDescription).join('<br />'),
  }));

  return hasPartTimeRule || group?.employerCanOptOut
    ? [...options, { value: 'no', label: "I don't want to offer benefits to this employee." }]
    : options;
}

const Description = ({ countryDescription, showDescriptionSuffix }) =>
  countryDescription ? (
    <StyledDescription>
      {showDescriptionSuffix ? (
        <>
          <HTMLRendered Tag="span">{countryDescription}</HTMLRendered>
          <br />
          <br />
          Please select one of the available benefits packages for your employee:
        </>
      ) : (
        <HTMLRendered Tag="span">{countryDescription}</HTMLRendered>
      )}
    </StyledDescription>
  ) : null;

const NoBenefits = ({ name, label, countryName }) => (
  <>
    <StyledDescription>
      We do not currently offer supplemental benefits in {countryName}. All mandated benefits are
      offered and categorized under our mandatory social programs.
    </StyledDescription>

    <AckField
      name={name}
      label={label}
      hideLabel
      description="I acknowledge this statement."
      checkboxValue="Acknowledged that there are no supplemental benefits"
    />
  </>
);

function OnlyOneOption({ group, label, name, tier }) {
  const plans = tier.benefitPlans.map((plan) => ({
    title: plan.name,
    value: friendlyBenefitPlanCost({
      ...plan,
      showCostDescription: true,
    }),
  }));

  return (
    <Stack gap="3">
      {tier.description ? (
        <StyledDescription>
          <strong>{tier.name}</strong>: <HTMLRendered Tag="span">{tier.description}</HTMLRendered>
        </StyledDescription>
      ) : (
        <DetailsCard>
          <InfoBlock size="sm" list={plans} />
        </DetailsCard>
      )}
      <AckField
        name={name}
        label={label}
        hideLabel
        description="I acknowledge that the benefits shown above will be included in this employee's profile."
        checkboxValue={{ [snakeCase(group.name)]: tier.value }}
      />
    </Stack>
  );
}

const isGroupLocked = (group) =>
  group?.selectedBenefitTier === groupEmployerOptedOut || groupHasSelection(group);

/**
 * BenefitsSelection
 *
 * @deprecated Sunsetting for project Octopus, use apps/employ/src/domains/benefits/shared/BenefitSelectionField instead
 * @export
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').BenefitsField, 'type'> & {id?: string}} props
 * @return {JSX.Element}
 */
export function BenefitsSelection({
  countryName,
  name,
  label,
  benefitGroups,
  countryBenefitDetails = { file: null, finePrint: null },
  formValues = {},
  isLoading,
  setValue,
}) {
  const {
    description,
    file,
    finePrint,
    countryWithBenefits,
    maximumWorkHoursPerWeekWithoutBenefits,
    url,
  } = countryBenefitDetails;
  const hasBenefits = countryWithBenefits && hasAvailableBenefits(benefitGroups);
  const locked = hasBenefits && isGroupLocked(benefitGroups[0]);
  const hasPartTimeRule = hasPartTimeException(
    formValues.workSchedule,
    formValues.workHoursPerWeek,
    maximumWorkHoursPerWeekWithoutBenefits
  );
  const hasMultipleGroups = benefitGroups?.length > 1;
  const options = hasBenefits ? getOptionsFromGroup(benefitGroups[0], hasPartTimeRule) : [];
  const hasMultipleOptions = options?.length > 1;

  if (isLoading) {
    return <RemoteLogoLoader />;
  }

  if (!hasBenefits) {
    return (
      <InputLabel as="div">
        <FieldGroupLabel>Benefits</FieldGroupLabel>
        <NoBenefits
          name={name}
          label={label}
          countryName={countryName}
          value={formValues.benefits}
        />
      </InputLabel>
    );
  }

  function handleBenefitsChange(value) {
    setValue('benefits', value);
  }

  function renderOptions() {
    if (locked) {
      return (
        <LockedBenefits
          groups={benefitGroups}
          countryName={countryName}
          onChange={handleBenefitsChange}
          benefitsValue={formValues.benefits}
        />
      );
    }

    if (hasMultipleGroups || hasMultipleOptions) {
      return (
        <Stack mt={4}>
          <MultipleGroups
            onChange={handleBenefitsChange}
            forceShowOptOut={hasPartTimeRule}
            groups={benefitGroups}
            values={formValues.benefits}
          />
        </Stack>
      );
    }

    return (
      <OnlyOneOption
        group={benefitGroups[0]}
        label={label}
        name={name}
        tier={benefitGroups[0].benefitTiers[0]}
      />
    );
  }

  return (
    <InputLabel as="div">
      <FieldGroupLabel pl={5} pb="2">
        Benefits
      </FieldGroupLabel>

      <Description countryDescription={description} showDescriptionSuffix={hasMultipleOptions} />

      {(file || url) && (
        <ButtonCallout
          data-testid="check-benefits"
          description={`What Remote can offer your employees in ${countryName}.`}
          href={url || getPreviewPageUrl({ fileSlug: file.slug })}
          rel="noopener noreferrer"
          target="_blank"
          title={`Check the benefits in ${countryName}`}
          mt={4}
        />
      )}

      {renderOptions()}

      {finePrint && (
        <FinePrint mt={8}>
          <HTMLRendered Tag="span">{finePrint}</HTMLRendered>
        </FinePrint>
      )}
    </InputLabel>
  );
}

BenefitsSelection.propTypes = {
  countryBenefitDetails: PropTypes.oneOfType([
    PropTypes.shape({
      description: PropTypes.string,
      file: PropTypes.shape({
        slug: PropTypes.string,
      }),
      finePrint: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  benefitGroups: PropTypes.array.isRequired,
  countryName: PropTypes.string.isRequired,
  formValues: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
};
