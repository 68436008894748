import { Box, SROnly, Tooltip } from '@remote-com/norma';
import { useField } from 'formik';

import { supportedTypes } from '@/src/components/Form/DynamicForm/constants';
import { DatePickerField } from '@/src/components/Ui/Form/DatePickerField';
import { InputController } from '@/src/components/Ui/Form/Input';

export default function DateFieldCell({
  fieldName,
  row: { index },
  isOptional = false,
}: {
  fieldName: string;
  row: { index: number };
  isOptional?: boolean;
}) {
  const fieldId = `${fieldName}-${index}`;

  // This should match the value of the `aria-errormessage` attribute as defined in FormGroup's getFormGroupIds helper function
  const errorMessageId = `${fieldName}-errormessage`;

  const [field, { error, touched }, helpers] = useField(fieldName);

  const onChange = (date: string) => {
    helpers.setValue(date);
  };

  const label = isOptional ? '(Optional) Date' : 'Date';
  const shouldDisplayError = !!error && touched;

  return (
    <Tooltip
      label={shouldDisplayError && error}
      interactive={false}
      position="bottom"
      animation={false}
    >
      <Box minWidth="180px" width="100%" key={fieldId}>
        <SROnly id={errorMessageId} aria-live="assertive">
          {shouldDisplayError && error}
        </SROnly>
        <InputController type={supportedTypes.DATE_RANGE}>
          <DatePickerField
            name={field.name}
            label={label}
            onChange={onChange}
            portalId="root-portal"
            displayErrorMessage={false}
            data-testid={fieldId}
            // We don't want the date-picker and the tooltip that contains the form validation to overlap.
            // To avoid that, we render the date-picker at the left of the input,
            // and the tooltip at the bottom.
            popperPlacement="left"
          />
        </InputController>
      </Box>
    </Tooltip>
  );
}
