import type { Url } from 'url';

import type { ElementType, ReactNode } from 'react';
import { useState } from 'react';

import { IconHeart } from '../../icons/build/IconHeart';
import { IconV2OutlineExternalLink } from '../../icons/build/IconV2OutlineExternalLink';
import { Stack } from '../../layout';
import { BoxedIcon } from '../boxed-icon';
import type { ButtonProps } from '../button/Button';
import { SROnly } from '../text';

import {
  ButtonCallout as ButtonCalloutStyled,
  IconContainer,
  Title,
  Message,
  LogoContainer,
} from './ButtonCallout.styled';

export type Href = Partial<Pick<Url, 'pathname' | 'query'>>;

export type ButtonCalloutProps = Omit<ButtonProps, 'tone'> & {
  description?: ReactNode;
  href?: Href | string;
  mode?: 'icon' | 'logo';
  Icon?: ElementType;
  Logo?: ReactNode;
  onClick?: (event: MouseEvent) => void;
  rel?: string;
  target?: string;
  title: ReactNode;
  tone?: 'brand' | 'orange' | 'green' | 'cyan' | 'pink' | 'yellow' | 'grey';
  Pill?: ReactNode;
};

export function ButtonCallout({
  title,
  description,
  mode = 'icon',
  Icon = IconHeart,
  Logo,
  href,
  rel,
  target,
  tone = 'brand',
  Pill,
  ...props
}: ButtonCalloutProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isExternal = target === '_blank';

  return (
    <ButtonCalloutStyled
      $tone={tone}
      href={href}
      target={target}
      {...props}
      flexDirection="row"
      justifyContent="space-between"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Stack flexDirection="row" flex={1}>
        {mode === 'icon' && (
          <IconContainer>
            <BoxedIcon
              Icon={Icon}
              size="md"
              appearance={isHovered || isFocused ? 'subtle' : 'default'}
              tone={tone}
            />
          </IconContainer>
        )}
        {mode === 'logo' && Logo && <LogoContainer>{Logo}</LogoContainer>}
        <div>
          <Title>{title}</Title>
          <Message>{description}</Message>
        </div>
      </Stack>
      {Pill && <Stack justifyContent="center">{Pill}</Stack>}
      {isExternal && (
        <Stack justifyContent="center" ml={2}>
          <SROnly>(opens in a new tab)</SROnly>
          <IconV2OutlineExternalLink fill="grey" aria-hidden />
        </Stack>
      )}
    </ButtonCalloutStyled>
  );
}
