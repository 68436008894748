import { supportedTypes } from '@/src/components/Form/DynamicForm/constants';
import { generateSelectOptions } from '@/src/helpers/forms';

export const CUSTOM_FIELD_ACCESSOR_PREFIX = 'customFields.';
export const CUSTOM_FIELD_ID_PREFIX = `data-${CUSTOM_FIELD_ACCESSOR_PREFIX}`;

export const CUSTOM_FIELD_TYPES = {
  string: 'string',
  text: 'text',
  date: 'date',
  integer: 'integer',
  boolean: 'boolean',
  singleSelect: 'single_select',
  decimal: 'decimal',
  link: 'link',
  percentage: 'percentage',
  currency: 'currency',
};

export const CUSTOM_FIELD_TYPE_LABELS = {
  [CUSTOM_FIELD_TYPES.string]: 'Text',
  [CUSTOM_FIELD_TYPES.text]: 'Multiline text',
  [CUSTOM_FIELD_TYPES.date]: 'Date',
  [CUSTOM_FIELD_TYPES.integer]: 'Number',
  [CUSTOM_FIELD_TYPES.boolean]: 'Yes/No',
  [CUSTOM_FIELD_TYPES.singleSelect]: 'Single select',
  [CUSTOM_FIELD_TYPES.decimal]: 'Decimal',
  [CUSTOM_FIELD_TYPES.link]: 'Link',
  [CUSTOM_FIELD_TYPES.percentage]: 'Percentage',
  [CUSTOM_FIELD_TYPES.currency]: 'Currency',
};

export const CUSTOM_FIELD_VALUE_ACCESSOR = {
  [CUSTOM_FIELD_TYPES.string]: 'textValue',
  [CUSTOM_FIELD_TYPES.text]: 'textValue',
  [CUSTOM_FIELD_TYPES.date]: 'dateValue',
  [CUSTOM_FIELD_TYPES.integer]: 'integerValue',
  [CUSTOM_FIELD_TYPES.boolean]: 'booleanValue',
  [CUSTOM_FIELD_TYPES.decimal]: 'decimalValue',
  [CUSTOM_FIELD_TYPES.link]: 'textValue',
  [CUSTOM_FIELD_TYPES.percentage]: 'decimalValue',
  [CUSTOM_FIELD_TYPES.currency]: 'decimalValue',
  [CUSTOM_FIELD_TYPES.singleSelect]: 'jsonValue',
};

export const CUSTOM_FIELD_TYPE_MAPPING = {
  [CUSTOM_FIELD_TYPES.string]: supportedTypes.TEXT,
  [CUSTOM_FIELD_TYPES.text]: supportedTypes.TEXTAREA,
  [CUSTOM_FIELD_TYPES.date]: supportedTypes.DATE,
  [CUSTOM_FIELD_TYPES.integer]: supportedTypes.NUMBER,
  [CUSTOM_FIELD_TYPES.boolean]: supportedTypes.RADIO,
  [CUSTOM_FIELD_TYPES.singleSelect]: supportedTypes.SELECT,
};

export const customFieldTypeOptions = generateSelectOptions(CUSTOM_FIELD_TYPE_LABELS);

export const fetchCustomFieldDefinitionsQuery = 'fetchCustomFieldDefinitions';
export const fetchCustomFieldDataQuery = 'fetchCustomFieldData';
