import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';
import type { UseInfiniteQueryResult } from 'react-query';

import type { API } from '@/src/api/config/api.types';
import type { TodoUpdate } from '@/src/api/config/employ/shared.types';

import { todosDefaultParams, todosSortOptions, todosTabs } from './constants';
import type { UseGetTodosProps } from './hooks';
import { useGetTodos } from './hooks';

type TodosContext = {
  /**
   * The list of *Todos* available to the user, according to the current `queryParams`
   */
  todos: TodoUpdate[];
  /**
   * The total number of Todos available to the user, grouped by type
   */
  totalGroupedTodos?: number;
  /**
   * The total number of new Todos available to the user, grouped by type
   */
  totalGroupedNewTodos?: number;
  /**
   * The total number of overdue Todos available to the user, grouped by type
   */
  totalGroupedOverdueTodos?: number;
  /**
   * Whether there was an error fetching the *Todos* list.
   *
   * Only accurate when `useErrorBoundary: false` is passed through the `getDataOptions` prop.
   */
  isError: boolean;
  /**
   * Whether the list of *Todos* is currently loading
   */
  isLoading: boolean;
  /**
   * Whether the next page of *Todos* is being fetched with `fetchNextPage`
   */
  isFetchingNextPage: boolean;
  /**
   * Whether there is a next page to be fetched
   */
  hasNextPage: boolean;
  /**
   * The current status of the *Todos* list query
   */
  status: 'idle' | 'error' | 'loading' | 'success';
  /**
   * `SetStateAction` used to control the query parameters  used for the API requests, including filters, sorting and pagination
   */
  setQueryParams: Dispatch<SetStateAction<Partial<API.Common.UpdatesTodosRequest['queryParams']>>>;
  /**
   * Function that allows the next page of results to be fetched
   */
  fetchNextPage: () => Promise<UseInfiniteQueryResult>;
  /**
   * Function that allows the data to be refetched
   */
  refetch: () => void;
};

const TodosContext = createContext({} as TodosContext);

type TodosContextProvider = PropsWithChildren<{
  /**
   * An optional query key to be passed to the `useGetTodos` hook used to fetch the Todos
   */
  queryKey?: string;
  /**
   * Options to be passed to the `useGetTodos` hook used to fetch the Todos
   */
  getDataOptions?: UseGetTodosProps['options'];
  /**
   * Options to be passed to the `useGetTodos` hook used to fetch the counts of Todos
   */
  getCountsOptions?: UseGetTodosProps['options'];
}>;

const INITIAL_QUERY_PARAMS = {
  ...todosTabs[0].queryParams,
  ...todosSortOptions[0].queryParams,
  pageSize: 20,
};

const TodosContextProvider = ({
  queryKey,
  getDataOptions = {},
  getCountsOptions = {},
  children,
}: TodosContextProvider) => {
  const [queryParams, setQueryParams] =
    useState<Partial<API.Common.UpdatesTodosRequest['queryParams']>>(INITIAL_QUERY_PARAMS);

  const {
    data: todosData,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage = false,
    status,
    fetchNextPage,
    refetch,
  } = useGetTodos({
    params: { queryParams, queryKeyOptions: { queryKey } },
    options: { useErrorBoundary: true, ...getDataOptions },
  });
  const { data: countData } = useGetTodos({
    params: todosDefaultParams,
    options: { useErrorBoundary: true, ...getCountsOptions },
  });

  const { totalGroupedTodos, totalGroupedNewTodos, totalGroupedOverdueTodos } = countData || {};
  const { items: todos = [] } = todosData || {};

  return (
    <TodosContext.Provider
      value={{
        todos,
        totalGroupedTodos,
        totalGroupedNewTodos,
        totalGroupedOverdueTodos,
        isError,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        status,
        setQueryParams,
        fetchNextPage,
        refetch,
      }}
    >
      {children}
    </TodosContext.Provider>
  );
};

const useTodosContext = () => {
  return useContext(TodosContext);
};

export { TodosContextProvider, useTodosContext };
