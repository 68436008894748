import { Tooltip } from '@remote-com/norma';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

import {
  FileButton,
  FileContainer,
  FileIcon,
  FileInput,
  FileLabel,
  FileLabelTitle,
  FileRemove,
} from './FileField.styled';

const FileUpload = ({
  label,
  files,
  multiple,
  onFilesSelected,
  onFilesRemoved,
  'data-testid': dataTestid,
  ...rest
}) => {
  const [filesList, setFilesList] = useState(files);
  const hasFiles = filesList.length > 0;

  const removeFilesLabel = getSingularPluralUnit(
    filesList.length,
    'Remove selected file',
    'Remove selected files',
    true,
    false
  );

  function handleReset() {
    onFilesRemoved(filesList);
    setFilesList([]);
  }

  function handleClick(event) {
    if (hasFiles) {
      event.preventDefault();
      event.target.value = null;
      handleReset();
    }
  }

  const handleFileChange = (event) => {
    const {
      target: { files: selectedFiles },
    } = event;
    setFilesList([...selectedFiles]);
    onFilesSelected([...selectedFiles]);
  };

  return (
    <FileContainer $hasFiles={hasFiles}>
      {/* TODO: Connect to proper label with aria-labelledby
      eg "Upload file" isn't relevant. the correct is "IBAN Proof" */}
      <Tooltip label={removeFilesLabel} disabled={!hasFiles}>
        <FileInput
          as="input"
          type="file"
          multiple={multiple}
          data-testid={`${dataTestid}-input`}
          onChange={(event) => {
            event.persist();
            handleFileChange(event.nativeEvent);
          }}
          onClick={handleClick}
          {...rest}
        />
      </Tooltip>
      {hasFiles ? (
        <FileRemove aria-label={removeFilesLabel} />
      ) : (
        <FileButton>
          <FileIcon />
          <FileLabel>
            <FileLabelTitle>{label}</FileLabelTitle>
          </FileLabel>
        </FileButton>
      )}
    </FileContainer>
  );
};

FileUpload.defaultProps = {
  'data-testid': 'fileUpload',
  files: [],
  label: 'Upload here',
  multiple: false,
};

FileUpload.propTypes = {
  'data-testid': PropTypes.string,
  files: PropTypes.array,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onFilesRemoved: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
};

export default FileUpload;
