import { accountsSwitchingEvents, trackEvent } from '@remote-com/analytics';
import type { UsePostOptions } from '@remote-com/data-layer';
import { usePost } from '@remote-com/data-layer';
import { toast } from '@remote-com/norma';
import type { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { useUserContext } from '@/src/components/UserProvider';
import { ADMIN_DASHBOARD_ROUTE, DASHBOARD_ROUTE } from '@/src/constants/routes';
import {
  PROFILE_SWITCH_ERRORS,
  PROFILE_SWITCH_ERROR_TOAST_MESSAGE,
} from '@/src/domains/account/constants';
import type { SessionUserProfileErrorResponse } from '@/src/domains/account/types';
import { isAdmin } from '@/src/domains/registration/auth/helpers';
import { ProfileAuthModal } from '@/src/domains/registration/auth/ProfileAuthModal';
import { useModalContext } from '@/src/hooks/useModalContext';

/**
 * A hook to switch between user profiles, handling the API requests and authentication.
 * It returns a function to initiate the switch and a modal component for additional authentication when required.
 */
export const useSwitchProfile = (
  options: Omit<
    UsePostOptions<'/api/v1/session/user-profiles', AxiosError<SessionUserProfileErrorResponse>>,
    'onSuccess' | 'onError'
  > = {}
) => {
  const { push } = useRouter();
  const { user } = useUserContext();
  const { showModal, hideModal } = useModalContext();

  const { mutate: triggerProfileSwitch } = usePost('/api/v1/session/user-profiles', {
    onSuccess: () => {
      const destination = isAdmin(user) ? ADMIN_DASHBOARD_ROUTE : DASHBOARD_ROUTE;
      window.location.replace(destination);
    },
    ...options,
  });

  const handleSwitchProfile = useCallback(
    (profileIndex: number) => {
      trackEvent(accountsSwitchingEvents.PROFILE_SWITCH_CLICKED);
      triggerProfileSwitch(
        { bodyParams: { profileIndex } },
        {
          onError: (exception) => {
            const errorMessage = exception?.response?.data?.message;
            if (errorMessage && typeof errorMessage === 'object') {
              const { reason, email, redirectUrl } = errorMessage;

              if (reason === PROFILE_SWITCH_ERRORS.PASSWORD_REQUIRED && email) {
                // Show the modal to authenticate the user
                showModal({
                  component: ProfileAuthModal,
                  modalProps: {
                    visible: true,
                    onSuccessAuth: () => {
                      handleSwitchProfile(profileIndex);
                    },
                    onClose: () => {
                      hideModal();
                    },
                    email,
                  },
                });
              } else if (reason === PROFILE_SWITCH_ERRORS.SSO_REQUIRED && redirectUrl) {
                push(redirectUrl);
              }
            } else {
              toast.error(PROFILE_SWITCH_ERROR_TOAST_MESSAGE);
            }
          },
        }
      );
    },
    [triggerProfileSwitch, hideModal, showModal, push]
  );

  return { handleSwitchProfile };
};
