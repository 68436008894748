/* eslint-disable import/no-extraneous-dependencies */
import { Stack, Text } from '@remote-com/norma';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styled, { useTheme } from 'styled-components';

import type { ReportsApi } from '@/src/api/config/employ/reports.types';
import { Pagination } from '@/src/components/Table/TableFooter/TableFooter';
import RemoteLogoLoader from '@/src/components/Ui/Loader/RemoteLoader';
import { WidgetCard } from '@/src/components/WidgetCard';
import ChartContainer from '@/src/domains/reports/components/ReportWidgets/ChartContainer/ChartContainer';
import ChartLegend from '@/src/domains/reports/components/ReportWidgets/ChartLegend/ChartLegend';
import { tooltipStyles } from '@/src/domains/reports/components/ReportWidgets/Common';

import {
  legendItems,
  createDataSets,
  calculateBarThickness,
  getDepartmentsCountCopy,
} from './helpers';

import 'chart.js/auto';

const WidgetFooter = styled(Stack).attrs({
  direction: 'row',
  alignContent: 'center',
  justifyContent: 'space-between',
})`
  flex-wrap: wrap;
  height: 48px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[200]};
  margin-top: ${({ theme }) => theme.space[6]}px;
  margin-left: ${({ theme }) => -theme.space[6]}px;
  margin-right: ${({ theme }) => -theme.space[6]}px;
  margin-bottom: ${({ theme }) => -theme.space[4]}px;
  padding-right: ${({ theme }) => theme.space[6]}px;
  padding-left: ${({ theme }) => theme.space[6]}px;
`;

type SpanOfControlWidgetProps = {
  data: ReportsApi.SpanOfControlData;
  isLoading: boolean;
};

export default function SpanOfControlWidget({ data, isLoading }: SpanOfControlWidgetProps) {
  const { colors } = useTheme();
  const [currentPage, setCurrentPage] = useState(0);

  if (!data || !data.managerGroups.length || !data.departments.length) {
    return null;
  }

  const { departments, dataSets, totalPages } = createDataSets(data, currentPage);

  if (isLoading)
    return (
      <WidgetCard title="">
        <RemoteLogoLoader $space="xl" />
      </WidgetCard>
    );

  const chartData = {
    labels: departments,
    datasets: dataSets,
  };

  const options = {
    indexAxis: 'y',
    barThickness: calculateBarThickness(departments),
    plugins: {
      datalabels: {
        color: 'white',
        anchor: 'center',
        align: 'center',
        font: {
          size: 14,
        },
        display(context: {
          dataset: { data: { [x: string]: number } };
          dataIndex: string | number;
        }) {
          // Do not display labels if the value is 0
          return context.dataset.data[context.dataIndex] !== 0;
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        ...tooltipStyles(colors),
        callbacks: {
          label(tooltipItem: any) {
            const label = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw;
            return `managers with ${label} reports: ${value}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
        },
        ticks: {
          stepSize: 2,
          color: colors.grey[600],
          font: {
            size: 12,
            weight: 500,
            family: 'Inter',
          },
        },
        grid: {
          display: true,
        },
      },
      y: {
        stacked: true,
        title: {
          display: false,
        },
        ticks: {
          color: colors.grey[600],
          font: {
            size: 12,
            weight: 500,
            family: 'Inter',
          },
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Stack>
      <WidgetCard title="Span of control">
        <ChartLegend items={legendItems} />
        <ChartContainer yLabel="department" xLabel="manager count">
          <Bar
            height={380}
            width="100%"
            data={chartData}
            // @ts-expect-error -> typescript does not like this in the options: indexAxis: 'y', no idea why, it is a valid option
            options={options}
            plugins={[ChartDataLabels]}
          />
        </ChartContainer>
        {totalPages > 1 && (
          <WidgetFooter>
            <Text color="grey.600" variant="sm">
              {getDepartmentsCountCopy({
                pageIndex: currentPage,
                totalRecords: data?.departments.length,
              })}
            </Text>
            <Pagination
              pageIndex={currentPage}
              onPageChange={(page) => {
                setCurrentPage(page);
              }}
              totalPages={totalPages}
              disabled={isLoading}
            />
          </WidgetFooter>
        )}
      </WidgetCard>
    </Stack>
  );
}
