// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneCalendarPerson = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M10.85 1.25c-3.36 0-5.04 0-6.32.65A6 6 0 0 0 1.9 4.53c-.65 1.28-.65 2.96-.65 6.32v2.3c0 3.36 0 5.04.65 6.32a6 6 0 0 0 2.63 2.63c1.28.65 2.96.65 6.32.65h2.3c3.36 0 5.04 0 6.32-.65a6 6 0 0 0 2.63-2.63c.65-1.28.65-2.96.65-6.32v-2.3c0-3.36 0-5.04-.65-6.32a6 6 0 0 0-2.63-2.63c-1.28-.65-2.96-.65-6.32-.65h-2.3Z" /><path fillRule="evenodd" clipRule="evenodd" d="M8.75 1a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0V1Zm8 0a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0V1ZM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-1.67 2A3.33 3.33 0 0 0 7 16.33c0 .92.75 1.67 1.67 1.67h6.66c.92 0 1.67-.75 1.67-1.67C17 14.5 15.5 13 13.67 13h-3.34Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneCalendarPerson);
export { ForwardRef as IconV2DuotoneCalendarPerson };