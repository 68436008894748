import { Box } from '@remote-com/norma';
import styled from 'styled-components';

type DotProps = {
  $isActive: boolean;
  $size: 'sm' | 'md';
};

export const Dot = styled(Box)<DotProps>`
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  right: ${({ $size }) => ($size === 'sm' ? '-1px' : '0')};
  top: ${({ $size }) => ($size === 'sm' ? '-1px' : '0')};
  background: ${({ theme }) => theme.colors.red[600]};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.background.base};
  display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
`;
