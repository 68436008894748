import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';

import { inputStyles } from '../form-group';

export const StyledInputText = styled.input`
  ${inputStyles}
`;

export const StyledReactInputMask = styled(ReactInputMask)`
  ${inputStyles}
`;
