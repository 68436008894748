import { Text } from '@remote-com/norma';
import type { PropsWithChildren } from 'react';
import { css } from 'styled-components';

import { TooltipModal } from '@/src/components/Ui/TooltipModal';
import { useProfilesMenuTooltip } from '@/src/domains/navigation/profiles-menu/hooks/useProfilesMenuTooltip';

const tooltipStyle = {
  label: css`
    text-align: left;
  `,
  wrapper: css`
    padding: ${({ theme }) => theme.space[4]}px;
    border: 1px solid ${({ theme }) => theme.colors.grey[200]};
    box-shadow: 0px 0px 14px rgba(13, 38, 58, 0.12);
    border-radius: ${({ theme }) => theme.borderRadius.md};
  `,
};

export const ProfilesMenuTooltip = ({
  children,
  isAccountsMenu,
}: PropsWithChildren<{ isAccountsMenu: boolean }>) => {
  const { accountsTooltipVisible, profilesTooltipVisible, closeTooltip } = useProfilesMenuTooltip();

  return (
    <TooltipModal
      styles={{
        tooltip: tooltipStyle,
      }}
      label={
        <>
          <Text variant="smMedium" mb={3}>
            {isAccountsMenu ? 'Find your accounts here' : 'Your new profile is here ✨'}
          </Text>
          <Text as="p" variant="xs" color="grey.600">
            {isAccountsMenu
              ? 'You can switch between them using this account menu'
              : 'Use the switcher to change which profile you’re using.'}
          </Text>
        </>
      }
      position="left"
      visible={isAccountsMenu ? accountsTooltipVisible : profilesTooltipVisible}
      onClose={closeTooltip}
      useOverlayPortal
      useHighlightBox={false}
      useFocusLock={false}
    >
      {children}
    </TooltipModal>
  );
};
