import { badgeTypesMapper } from '@remote-com/norma';

import { validateJSONSchemaValue } from '@/src/domains/bulkUpload/admin/helpers/getRequiredData';
import { parseNumberInput } from '@/src/domains/bulkUpload/admin/helpers/processValues';
import { employmentType } from '@/src/domains/employment/constants';
import {
  allowedContractType,
  allowedContractTypeLabels,
  legalEntityPayrollStatus,
  legalEntityPayrollStatusLabels,
  legalEntityStatus,
  legalEntityStatusLabels,
  legalEntityOnboardingTaskStatus,
} from '@/src/domains/legalEntities/constants';
import { arrayToCSV, downloadCSV } from '@/src/helpers/csv';

export const DEFAULT_CONTRACTORS_ENTITY = 'Remote Technology Services, Inc.';

export function getLegalEntityLabel(legalEntity) {
  return legalEntity ? `${legalEntity?.name} (${legalEntity?.address?.country?.name})` : null;
}

export function getLegalEntityOptions(legalEntities = []) {
  return legalEntities.map((legalEntity) => ({
    value: legalEntity.slug,
    label: getLegalEntityLabel(legalEntity),
    isInternal: legalEntity.isInternal,
  }));
}

export function getLegalEntityAllowedContractTypeOptions() {
  return Object.values(allowedContractType).map((value) => {
    return {
      label: allowedContractTypeLabels[value],
      value,
    };
  });
}

export function getLegalEntityPayrollStatusLabel(status) {
  return legalEntityPayrollStatusLabels[status];
}

export function getLegalEntityPayrollStatusOptions({ hideVerifyStatus = true } = {}) {
  return Object.values(legalEntityPayrollStatus)
    .filter((value) => {
      if (hideVerifyStatus) {
        return value !== legalEntityPayrollStatus.VERIFYING;
      }

      return value;
    })
    .map((value) => ({
      label: getLegalEntityPayrollStatusLabel(value),
      value,
    }));
}

export function setLegalEntityPayrollStatusBadge(status) {
  switch (status) {
    case legalEntityPayrollStatus.ACTIVE:
      return {
        label: legalEntityPayrollStatusLabels[status],
        type: badgeTypesMapper.ACTIVE,
      };
    case legalEntityPayrollStatus.VERIFYING:
      return {
        label: legalEntityPayrollStatusLabels[status],
        type: badgeTypesMapper.PENDING,
      };
    default:
      return {
        label: legalEntityPayrollStatusLabels[status],
        type: badgeTypesMapper.SCHEDULED,
      };
  }
}

export function setLegalEntityStatusBadge(status) {
  switch (status) {
    case legalEntityStatus.ACTIVE:
      return {
        label: legalEntityStatusLabels[status],
        type: badgeTypesMapper.ACTIVE,
      };
    case legalEntityStatus.ARCHIVED:
      return {
        label: legalEntityStatusLabels[status],
        type: badgeTypesMapper.ARCHIVED,
      };
    default:
      return {
        label: legalEntityStatusLabels[status],
        type: badgeTypesMapper.SCHEDULED,
      };
  }
}

export function getLegalEntityStatusOptions() {
  return Object.values(legalEntityStatus).map((value) => ({
    label: legalEntityStatusLabels[value],
    value,
  }));
}

export function getTooltipLabel(value) {
  switch (value) {
    case legalEntityPayrollStatus.TESTING:
      return 'This entity is in Implementation and communications to the end user are disabled';
    case legalEntityPayrollStatus.VERIFYING:
      return 'This intermediate step between testing and active is used for manual or automated checks to the entity setup. Currently, this status will only be used for entities located in the USA';
    default:
      return 'This entity is processing payroll and changes will impact live end users';
  }
}

export function pillColor(status) {
  switch (status) {
    case legalEntityPayrollStatus.ACTIVE:
      return 'success';
    case legalEntityPayrollStatus.VERIFYING:
      return 'warning';
    case legalEntityPayrollStatus.TESTING:
      return 'purple';
    default:
      return 'neutralLight';
  }
}

/**
 * Checks if a provided legal entity matches:
 *  - (employees) the country of employment
 *  - (contractors) the default USA legal entity
 *
 * @export
 * @param {Object} legalEntity - remoteEntityPropType shape
 * @param {Object} country - countryPropType shape
 * @param {String} contractType
 * @return {Boolean}
 */
export function isEntityValidForContract(legalEntity, country, contractType) {
  if (!legalEntity) return false;

  if (contractType === employmentType.CONTRACTOR) {
    return legalEntity.name === DEFAULT_CONTRACTORS_ENTITY;
  }

  return country.code === legalEntity.address.country.code;
}

/**
 * Generated and downloads a JSON Schema context aware CSV.
 */
export function downloadJSONSchemaCSV(currentProcessor) {
  const { rules } = currentProcessor;

  const labels = Object.keys(rules).reduce((acc, curr) => {
    if (rules[curr].noCSVDownload) return acc;

    return {
      ...acc,
      [curr]: [rules[curr].label],
    };
  }, []);

  const descriptions = Object.keys(rules).reduce((acc, curr) => {
    if (rules[curr].noCSVDownload) return acc;

    const { description } = rules[curr];
    // Some descriptions may have html in them. We need to remove it.
    const doc = new DOMParser().parseFromString(description, 'text/html');
    const parsedDescription = doc.body.textContent || '';

    return {
      ...acc,
      [curr]: description
        ? [parsedDescription.replace(/"/g, '').replace(/,/g, '').replace(/\. /g, '.\n')]
        : [''],
    };
  }, []);

  const values = Object.keys(rules).reduce((acc, curr) => {
    if (rules[curr].noCSVDownload) return acc;

    const options = rules[curr].input?.options;
    const value = options
      ? options
          .map((option, index) => {
            const isFirst = index === 0;
            let optionInfo = isFirst ? 'Please insert either the Label or Value:\n\n' : '';
            let optionLabel = option.label ? `Label: ${option.label}\n` : '';
            let optionValue = option.value ? `Value: ${option.value}\n` : '';
            let optionDescription = option.description
              ? `Description: ${option.description}\n`
              : '';

            if (rules[curr].input.type === 'countries') {
              optionInfo = isFirst
                ? 'Please insert a comma seperated list with one or more of:\n\n'
                : '';
              optionLabel = '';
              optionValue = option.value;
              optionDescription = '';
            }

            return [optionInfo, optionLabel, optionValue, optionDescription].join('');
          })
          .join(`${rules[curr].input.type === 'countries' ? ', ' : '\n'}`)
      : // Trigger JSON Schema Validation Error to populate value requirements.
        validateJSONSchemaValue({
          value: Array(1000).fill('x').join(''),
          schema: rules[curr].schema,
        });

    return {
      ...acc,
      [curr]: [value],
    };
  }, []);

  const blank = Object.keys(rules).reduce((acc, curr) => {
    if (rules[curr].noCSVDownload) return acc;

    return {
      ...acc,
      [curr]: [],
    };
  }, []);

  downloadCSV(
    arrayToCSV([labels, descriptions, values, blank]),
    `${currentProcessor.label}-blank.csv`
  );
}

const transformProcessorRuleInputType = (field) => {
  switch (field.inputType) {
    case 'radio':
      return 'select';
    case 'countries':
      // This ensures that when the jsonType isn't an array we use the CountrySelect component rather than CountriesSelect
      return field.jsonType === 'string' ? 'country' : field.inputType;
    default:
      return field.inputType;
  }
};

export const transformProcessorRuleValue = (field) => (values) => {
  const value = values[field.name];

  switch (field.inputType) {
    case 'money': {
      if (typeof value === 'string') {
        return parseNumberInput(value);
      }

      return value;
    }
    case 'number': {
      // When the value `0` gets uploaded to the upload review table `0` needs to be a string in order for the CSV to validate correct.
      // However when the data gets processed for upload, the string gets parsed to an integer. This overrides that behaviour so that a string is returned.
      // It will then later be converted back to a number before being sent to the BE so BE validation doesn't break. This is ugly.
      if (value === '0') {
        return '0';
      }

      if (value) {
        return parseInt(value, 10);
      }

      return value;
    }
    case 'countries': {
      // Returns a string instead of array for when we use the CountrySelect component rather than CountriesSelect
      if (field.jsonType === 'string') {
        return value;
      }

      if (typeof value === 'string') {
        if (field.multiple) {
          const countriesArray = value?.split(', ').map((country) => country.trim()) || null;
          return countriesArray;
        }

        return [value];
      }

      return value;
    }
    default:
      if (field.options) {
        for (let i = 0; i < field.options.length; i++) {
          const option = field.options[i];

          if (option.value === value) {
            return option.value;
          }
          if (option.label === value) {
            return option.value;
          }
        }
      }

      return value;
  }
};
export function generateProcessorRules(formFields, formSchema) {
  if (formFields.length === 0) return { rules: {} };

  const constructRules = (fields) => {
    return fields.reduce((acc, field) => {
      if (field.deprecated) {
        return {
          ...acc,
        };
      }

      if (field.fields) {
        if (typeof field.fields === 'function') {
          return {
            ...acc,
            ...constructRules(field.fields()),
          };
        }

        return {
          ...acc,
          ...constructRules(field.fields),
        };
      }

      const isRequired = field.required || formSchema.required.includes(field.name);

      return {
        ...acc,
        [field.name]: {
          field,
          label: `${isRequired ? '* ' : ''}${field.label}`,
          description: field.description,
          required: isRequired,
          mappingFields: [field.name, field.label],
          transform: transformProcessorRuleValue(field),
          input: {
            hidden: !isRequired,
            type: transformProcessorRuleInputType(field),
            ...(field.options ? { options: field.options } : null),
            ...(field.maxDate ? { maxDate: field.maxDate } : null),
            ...(field.maxLength ? { maxLength: field.maxLength } : null),
            ...(field.multiple ? { multiple: field.multiple } : null),
            // This is field is specifically used for the CountrySelect component (more details in TableCell).
            ...(field.jsonType === 'string' &&
              field.inputType === 'countries' && { useOptionsFromSchema: true }),
          },
          schema: field.schema,
        },
      };
    }, {});
  };

  const rules = constructRules(formFields);

  return { rules };
}

export const legalEntityOnboardingTaskCompleted = (onboardingTasks, taskName) => {
  return onboardingTasks?.some(
    (task) => task.name === taskName && task.status === legalEntityOnboardingTaskStatus.COMPLETED
  );
};
