import { Text } from '@remote-com/norma';
import { IconChevronRight } from '@remote-com/norma/icons/IconChevronRight';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';

export const ButtonStyled = styled((props) => <Button variant="raw" {...props} />)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.blank};
  transition: 0.3s ease;
  transition-property: 'background-color';
  border-color: ${({ theme }) => theme.colors.grey[200]};
  margin-bottom: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[50]};
  }
`;

export const Title = styled(Text).attrs(({ theme }) => ({
  variant: 'baseMedium',
  color: theme.colors.primary[900],
}))`
  display: block;
`;

export const Description = styled(Text).attrs(({ theme }) => ({
  variant: 'sm',
  color: theme.colors.grey[600],
}))``;

export const Icon = styled(IconChevronRight)`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: auto;
  flex-shrink: 0;
`;
