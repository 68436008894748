import { Root, Portal, Content, Item, Trigger } from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';
import { Children } from 'react';
import styled, { css } from 'styled-components';

import { IconV2OutlineChevronDown } from '../../icons/build/IconV2OutlineChevronDown';
import { sharedTransition } from '../animations';
import { focusVisible } from '../common.styled';

import { StyledTab } from './Tab.styled';

type TabMoreProps = {
  children: ReactNode;
};

const StyledIconChevronDown = styled(IconV2OutlineChevronDown)`
  ${sharedTransition('transform')}

  height: 16px;
  margin-left: ${({ theme }) => theme.space[1]}px;
  vertical-align: middle;
  width: 16px;
`;

const StyledContent = styled(Content)`
  box-shadow: ${({ theme }) => theme.shadows.small};
  border-radius: ${({ theme }) => theme.borderRadius['2xs']};
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space[3]}px;
  border: 1px solid ${({ theme }) => theme.colors.grey[200]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
  min-width: 196px;

  ${StyledTab} {
    ${({ theme }) =>
      css`
        ${theme.typography.smMedium}
        background: ${theme.colors.blank};
        padding: ${theme.space[4]}px ${theme.space[5]}px;
        color: ${theme.colors.grey[600]};
      `}
    display: flex;
    transition: none;
    width: 100%;
  }
  ${StyledTab}:hover {
    ${({ theme }) =>
      css`
        background: ${theme.colors.grey[50]};
        color: ${theme.colors.grey[900]};
      `}

    border-bottom-color: transparent;
  }
`;

const StyledItem = styled(Item)`
  ${focusVisible(
    css`
      a {
        background: ${({ theme }) => theme.colors.grey[50]};
        color: ${({ theme }) => theme.colors.grey[900]};
      }
      border-bottom-color: transparent;
    `
  )}
`;

const MoreButton = styled(StyledTab)`
  ${({ theme }) =>
    css`
      ${theme.typography.smMedium}
      padding-bottom: ${theme.space[4]}px;
      color: ${theme.colors.grey[600]};
    `}

  &[data-state='open'] ${StyledIconChevronDown} {
    transform: rotate(0.5turn);
  }
`;

export const TabMore = ({ children }: TabMoreProps) => {
  const handleItemSelect = (event: Event) => {
    if (event.target instanceof HTMLElement) {
      const anchor = event.target.querySelector('a');
      anchor?.click();
    }
  };
  return (
    <Root modal={false}>
      <Trigger asChild>
        <MoreButton as="button" data-testid="tabs-responsive-more">
          More
          <StyledIconChevronDown />
        </MoreButton>
      </Trigger>
      {/* The Portal has global CSS z-index at global.js */}
      <Portal>
        <StyledContent align="end">
          {Children.map(children, (child) => (
            <StyledItem onSelect={handleItemSelect}>{child}</StyledItem>
          ))}
        </StyledContent>
      </Portal>
    </Root>
  );
};
