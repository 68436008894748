import { object, boolean } from 'yup';

import { composeFieldCheckbox } from '@/src/components/Form/DynamicForm/composeFields';

import { AcceptTermsOfServiceLabel } from './AcceptTermsInput';
import { TERMS_OF_SERVICE_FIELD_NAME } from './constants';

export const getAcceptTermsOfServiceInputValidator = (isFieldRequired = true) => {
  return isFieldRequired
    ? boolean().oneOf([true], 'Please read and accept the Terms of Service to continue')
    : boolean();
};

export const getAcceptTermsOfServiceValidationSchema = (isFieldRequired = true) =>
  object().shape({
    [TERMS_OF_SERVICE_FIELD_NAME]: getAcceptTermsOfServiceInputValidator(isFieldRequired),
  });

// The terms of service checkbox was checked too long ago,
// and we must uncheck the checkbox to allow the user to recheck it.
export const validateTermsOfServiceException = ({
  errors,
  resetInput,
  name = TERMS_OF_SERVICE_FIELD_NAME,
}) => {
  if (Object.keys(errors).includes(name)) {
    resetInput(name, false, false);
  }
};

export const composeFieldAcceptTermsOfService = ({ setTermsAcceptedAt, required = true }) => {
  return composeFieldCheckbox({
    name: TERMS_OF_SERVICE_FIELD_NAME,
    label: <AcceptTermsOfServiceLabel />,
    required,
    checkboxValue: true,
    schema: getAcceptTermsOfServiceInputValidator(),
    onClick: (event) => {
      if (event?.target?.checked) {
        setTermsAcceptedAt(new Date().toISOString());
      }
    },
  });
};
