import { Box, Stack, Text } from '@remote-com/norma';
import { IconV2OutlineArrowUpRight } from '@remote-com/norma/icons/IconV2OutlineArrowUpRight';
import type { ElementType, MouseEventHandler, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import { StandaloneLink } from '@/src/components/Button';

export const Container = styled(Stack).attrs({
  alignItems: 'center',
  direction: 'row',
  height: '100%',
  width: '100%',
  pt: [8, null, 3, 6],
  pl: [0, 0, null, 0],
  pr: [0, 0, null, 0],
  gap: [8, null, null, 10],
  maxWidth: '822px',
  my: 0,
  ml: [0, null, null, null, 10],
})``;

export const CTAWrapper = styled(Stack).attrs({
  justifyContent: 'center',
  flexShrink: 1,
  maxWidth: [null, '300px'],
  mb: [0, null, null, 8],
})`
  word-break: normal;
`;

export const ImageWrapper = styled(Box).attrs({
  flex: 1,
  position: 'relative',
  height: 'auto',
  width: '100%',
})`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpointValues.medium}px) {
    display: flex;
    max-width: 250px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpointValues.large}px) {
    min-width: 250px;
    max-width: 450px;
  }
`;

type BrandShape =
  | 'line'
  | 'petal'
  | 'quarter'
  | 'semicircle'
  | 'window'
  | 'petalsDiagonal'
  | 'none';

type BrandShapeImageProps = {
  brandShape: BrandShape;
};

const BrandShapeImage = styled(Box).attrs({
  height: '100%',
  width: '100%',
  position: 'absolute',
})<BrandShapeImageProps>`
  background-image: ${({ brandShape }) => `url('/images/illustrations/shapes/${brandShape}.svg')`};
  background-repeat: no-repeat;
  max-width: 450px;
  top: 0;
  left: 0;
  z-index: 1;
`;

const StyledIllustration = styled(Box).attrs({
  height: 'auto',
  width: '100%',
})`
  z-index: 2;
  max-width: 250px;

  @media screen and (min-width: ${({ theme }) => theme.breakpointValues.large}px) {
    max-width: 450px;
  }
`;

const StyledIconArrowUpRight = styled(IconV2OutlineArrowUpRight)`
  fill: ${({ theme }) => theme.colors.brand[600]};
  width: 16px;
`;

type SecondaryLinkProps = {
  href?: string;
  label?: string;
  onClick?: MouseEventHandler<HTMLElement>;
};

export type EmptyStateProps = {
  /**
   * An array of React nodes or a single React node representing the actions to be displayed below the description.
   */
  actions?: ReactNode;
  /**
   * The name of the brand shape image to be displayed in the background.
   */
  brandShape?: BrandShape;
  /**
   * The description text to be displayed below the title.
   */
  description: ReactNode;
  /**
   * The custom SVG component to be used as the illustration.
   */
  illustration: ElementType;
  /**
   * An object containing the properties for the secondary link, including `href` (the link URL), `label` (the link label), and `onClick` (a function to handle click event).
   */
  secondaryLinkProps?: SecondaryLinkProps;
  /**
   * The title text to be displayed at the top of the component.
   */
  title: string;
};

function SecondaryAction({ href, label = 'Learn more', onClick }: SecondaryLinkProps) {
  /** If `href` is provided, renders an anchor tag. Otherwise, renders a button */
  const hrefProps = href ? { href, target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <StandaloneLink
      {...hrefProps}
      onClick={onClick}
      tone="secondary"
      IconAfter={StyledIconArrowUpRight}
    >
      {label}
    </StandaloneLink>
  );
}

/**
 * EmptyState component is used to display a visual representation of an empty state or a no data state.
 * It consists of a container with a title, description, optional secondary link, actions, and an SVG illustration.
 */
export function EmptyState({
  actions,
  brandShape = 'semicircle',
  description,
  illustration: Illustration,
  secondaryLinkProps,
  title,
}: EmptyStateProps) {
  return (
    <Container>
      <CTAWrapper>
        <Text variant="xlMedium" mb={2}>
          {title}
        </Text>
        <Text variant="sm" color="grey.600">
          {description}
        </Text>
        {secondaryLinkProps && (
          <Box mt={4}>
            <SecondaryAction {...secondaryLinkProps} />
          </Box>
        )}
        {actions && <Box mt={7}>{actions}</Box>}
      </CTAWrapper>
      <ImageWrapper>
        {brandShape !== 'none' && <BrandShapeImage brandShape={brandShape} />}
        <StyledIllustration>
          <Illustration />
        </StyledIllustration>
      </ImageWrapper>
    </Container>
  );
}
