import {
  focusRingOffsetBorder,
  srOnlyCSS,
  Card,
  Box,
  Stack,
  sharedTransition,
  Text,
} from '@remote-com/norma';
import styled, { css } from 'styled-components';

import { CheckboxState } from '@/src/components/Ui/Form';
import type { ComponentProps } from '@/types';

export const Label = styled(Box).attrs({ as: 'label' })<{
  $disabled?: boolean;
}>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  display: flex;
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
  ${srOnlyCSS}
`;

export const StyledCheckboxState = styled(CheckboxState)`
  position: absolute;
  right: ${({ theme }) => theme.space[6]}px;
  top: ${({ theme }) => theme.space[6]}px;
  z-index: 1;
  pointer-events: none;

  // Override interaction state styles

  input:hover:not([readonly]) ~ && {
    border-color: ${({ theme }) => theme.colors.grey[500]};
  }
  input:focus:not([readonly]) ~ && {
    border-color: ${({ theme }) => theme.colors.grey[500]};
    box-shadow: none;
  }
  input:checked:not([readonly]) ~ && {
    background-color: ${({ theme }) => theme.colors.brand[600]};
    border-color: ${({ theme }) => theme.colors.brand[600]};
  }
  input:checked:hover:not([readonly]) ~ && {
    background-color: ${({ theme }) => theme.colors.brand[600]};
    border-color: ${({ theme }) => theme.colors.brand[600]};
  }
  input:active:not([readonly]) ~ && {
    border-color: ${({ theme }) => theme.colors.brand[600]};
    box-shadow: none;
  }
`;

export const ContentWrapper = styled(Card)<
  ComponentProps<typeof Card> & {
    $checked?: boolean;
    $hasError?: boolean;
    $disabled?: boolean;
  }
>`
  width: 100%;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.blank};
  ${sharedTransition('border-color, background-color')}

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.brand[600]};
    background-color: ${({ theme }) => theme.colors.brand[50]};
  }

  ${({ $checked, theme }) =>
    $checked &&
    css`
      border: 1px solid ${theme.colors.brand[600]};

      &:hover {
        border: 1px solid ${theme.colors.brand[600]};
        background-color: ${theme.colors.brand[50]};
      }
    `};

  ${({ $hasError, theme }) =>
    $hasError &&
    css`
      border: 1px solid ${theme.colors.negative.foreground};
      background-color: ${theme.colors.negative.background};

      &:hover {
        border: 1px solid ${theme.colors.negative.foreground};
        background-color: ${theme.colors.negative.background};
      }
    `};

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      cursor: unset;
      background-color: ${theme.colors.grey[50]};
      border: 1px dashed ${theme.colors.grey[500]};

      &:hover {
        border: 1px dashed ${theme.colors.grey[500]};
        background-color: ${theme.colors.grey[50]};
      }
    `}

  input.focus-visible ~ & {
    ${({ theme }) => focusRingOffsetBorder(theme.colors.brand[600], theme.colors.background.base)};
  }
`;

export const Fieldset = styled(Stack).attrs({
  border: 0,
  p: 0,
  m: 0,
  forwardedAs: 'fieldset',
})``;

export const CheckboxCardGrid = styled(Box)`
  display: grid;
  gap: ${({ theme }) => theme.space[6]}px;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-auto-rows: 1fr;
`;

export const Legend = styled(Text).attrs({
  forwardedAs: 'legend',
})``;
