import { useGet } from '@remote-com/data-layer';
import lowerCase from 'lodash/lowerCase';

import type { BillingDocument } from '@/src/api/config/employ/billingDocuments.types';
import type { InvoiceReport } from '@/src/api/config/employ/invoiceReport.types';
import { useUser } from '@/src/components/UserProvider/context';
import { employeeInvoiceDisplayTypeLabels } from '@/src/domains/invoices/constants';
import {
  getInvoiceReportDocumentType,
  getInvoiceReportFields,
  getInvoiceReportFlags,
} from '@/src/domains/invoices/shared/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { userCan } from '@/src/domains/registration/auth/helpers';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

export const useRiskReserveInvoiceExtras = (document: BillingDocument | InvoiceReport) => {
  const { userIsAdmin } = useUser();

  const {
    isZuoraInvoice,
    isOnboardingReserveInvoice,
    isContractorProtectOnboardingReserveInvoice,
  } = getInvoiceReportFlags(document);
  const { employeeName } = getInvoiceReportFields(document);
  const documentType = getInvoiceReportDocumentType(document, userIsAdmin);
  const documentTypeLabel = employeeInvoiceDisplayTypeLabels[documentType];

  const isZuoraRiskReserve = isZuoraInvoice && isOnboardingReserveInvoice;

  const path = userIsAdmin
    ? '/api/v1/rivendell/billing-documents/[slug]/risk-reserve'
    : '/api/v1/billing-platform/employer/billing-documents/[slug]/risk-reserve';

  const userCanReadComplianceProfiles = userCan('read', Resources.compliance_profiles);

  const { data: riskReserveExtras } = useGet(path, {
    params: { pathParams: { slug: document?.slug } },
    options: {
      enabled: Boolean(document?.slug) && isZuoraRiskReserve && userCanReadComplianceProfiles,
      select: (data) => data?.data,
    },
  });

  const reservedMonths = riskReserveExtras?.reservedMonths ?? 0;
  const reservedMonthsText = getSingularPluralUnit(reservedMonths, 'month', 'months', false, true);

  const description = `This is the ${lowerCase(documentTypeLabel)} for ${employeeName}. ${
    isContractorProtectOnboardingReserveInvoice
      ? `It amounts to ${reservedMonthsText} of their compensation.`
      : `It amounts to ${reservedMonthsText} of their base salary plus applicable employer contributions.`
  }`;

  return {
    riskReserveDescription: isZuoraRiskReserve && reservedMonths ? description : null,
  };
};
