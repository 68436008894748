import type { TabsProps } from '@remote-com/norma';
import { useRouter } from 'next/router';
import type { ComponentType } from 'react';
import React from 'react';

export function withNextRouter<TValue extends string | number>(
  Component: ComponentType<TabsProps<TValue>>
) {
  return function WrappedComponent(props: TabsProps<TValue>) {
    const router = useRouter();
    const selectedTab = router.query.selectedTab as TValue;
    return <Component {...props} selectedTab={selectedTab} />;
  };
}
