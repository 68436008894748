import { trackEvent, formEvents, pathnameToLocationOfAction } from '@remote-com/analytics';
import flatten from 'flat';
import { useFormikContext } from 'formik';
import kebabCase from 'lodash/kebabCase';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';

import { filterTouchedInlineErrors } from './utils';

const triggerEvent = ({ formName, eventType, elementData, resultFeedback, locationOfAction }) => {
  trackEvent(formEvents.WARNING, {
    elementName: formName,
    locationOfAction,
    eventType,
    elementData,
    resultFeedback,
  });
};

/**
 * This hook handles the logic for reporting errors.
 * - Errors should be triggered only one time per appearance, otherwise we will report an error
 * for every character the user writes while correcting their phone number.
 * - The same error should be reported more than one time as long as it re-appears after it was corrected,
 * otherwise it counts as a duplicated error.
 */
const useTriggerEventForChangedValues = ({ formName, errors, eventType }) => {
  const prevTriggeredErrors = useRef(new Set());
  const router = useRouter();
  const { pathname = '' } = router || {}; // in Storybook router doesn't exist

  useEffect(() => {
    if (!errors) {
      prevTriggeredErrors.current.clear();
      return;
    }

    const currentKeys = new Set();

    Object.keys(errors).forEach((key) => {
      const errorKey = `${key}.${errors[key]}`;
      currentKeys.add(errorKey);

      if (!prevTriggeredErrors.current.has(errorKey)) {
        const resultFeedback = flatten.unflatten({ [key]: errors[key] });

        triggerEvent({
          formName,
          locationOfAction: pathnameToLocationOfAction(pathname),
          eventType,
          elementData: key,
          resultFeedback,
        });
        prevTriggeredErrors.current.add(errorKey);
      }
    });

    /**
     * This code takes care of cleaning stale errors.
     * A stale error is an error message that was corrected by the user,
     * by removing stale errors we are able to trigger events for errors that appear again.
     */
    prevTriggeredErrors.current.forEach((prevTriggeredError) => {
      if (!currentKeys.has(prevTriggeredError)) {
        prevTriggeredErrors.current.delete(prevTriggeredError);
      }
    });
  }, [formName, errors, eventType, pathname]);
};

export const TrackForm = ({ formName, systemErrors }) => {
  const { touched, errors } = useFormikContext();

  const formattedFormName = kebabCase(formName);

  /**
   * We flatten the errors so we can support nested errors, for example:
   * personalInfo.address.number
   */
  const flattenTouchedElements = flatten(touched);
  const flattenErrors = flatten(errors);
  const inlineErrors = filterTouchedInlineErrors(flattenTouchedElements, flattenErrors);
  const flattenSystemErrors = systemErrors && flatten(systemErrors);

  useTriggerEventForChangedValues({
    formName: formattedFormName,
    errors: inlineErrors,
    eventType: 'inline-notification',
  });

  useTriggerEventForChangedValues({
    formName: formattedFormName,
    errors: flattenSystemErrors,
    eventType: 'system-notification',
  });

  return null;
};

TrackForm.propTypes = {
  formName: PropTypes.string.isRequired,
  systemErrors: PropTypes.object,
};
