import { Avatar, Box, Pill, Stack, menuItemStyles } from '@remote-com/norma';
import { IconV2OutlineCheck } from '@remote-com/norma/icons/IconV2OutlineCheck';
import type { ElementType } from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import type { UserLinkedAccount } from '@/src/api/config/api.types';
import type { UserProfile } from '@/src/api/config/employ/userProfiles.types';
import { TruncatedText } from '@/src/components/TruncatedText';
import {
  getProfileEmploymentType,
  getProfileItemCopy,
} from '@/src/domains/navigation/profiles-menu/helpers';
import { ProfileIcon } from '@/src/domains/navigation/profiles-menu/ProfileIcon';

const StyledWrapper = styled(Stack)<{ $checked: boolean; $isAccountsMenu: boolean }>`
  ${menuItemStyles}

  --profiles-menu-item-padding: ${({ theme }) => theme.space[2]}px;
  --profiles-menu-account-item-padding: ${({ theme }) => theme.space[5]}px;
  --profiles-menu-item-inner-radius: max(
    0px,
    calc(var(--norma-menu-item-radius) - var(--profiles-menu-item-padding))
  );

  align-items: center;
  box-sizing: border-box;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[3]}px;
  padding: var(--profiles-menu-item-padding);
  ${({ $isAccountsMenu, theme }) =>
    $isAccountsMenu &&
    `padding: ${theme.space[3]}px ${theme.space[4]}px ${theme.space[3]}px ${theme.space[4]}px;`}
  ${({ $checked, theme }) => $checked && `background-color: ${theme.colors.brand[50]};`}
    ${({ $isAccountsMenu, theme }) => $isAccountsMenu && `gap: ${theme.space[4]}px;`}
`;

const StyledProfileIconWrapper = styled(Box)<{ $checked: boolean }>`
  align-self: flex-start;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  border-radius: var(--profiles-menu-item-inner-radius);
  display: grid;
  flex-shrink: 0;
  height: 40px;
  place-items: center;
  width: 40px;

  *:hover > &,
  *[data-highlighted]:not([data-highlighted='false']) > &,
  *[data-selected]:not([data-selected='false']) > & {
    background-color: ${({ theme }) => theme.colors.blank};
  }

  ${({ $checked, theme }) =>
    $checked &&
    css`
      background-color: ${theme.colors.blank};
    `}
`;

const StyledBody = styled(Stack)`
  flex-grow: 1;
  width: 0;
`;

const StyledProfileName = styled(TruncatedText)`
  ${({ theme }) => theme.typography.smMedium}
  color: ${({ theme }) => theme.colors.grey[900]};
`;

const StyledMetaText = styled(TruncatedText)`
  ${({ theme }) => theme.typography.xs}

  color: ${({ theme }) => theme.colors.grey[600]};
`;

const StyledIndicator = styled(IconV2OutlineCheck)`
  color: ${({ theme }) => theme.colors.brand[600]};
  height: 20px;
  margin-inline-end: var(--profiles-menu-item-padding);
  width: 20px;
`;

export type ProfilesMenuItemProps = {
  as?: ElementType;
  checked?: boolean;
  profile?: UserProfile;
  account?: UserLinkedAccount;
  isAccountsMenu: boolean;
};
const ProfileItem = ({ profile, checked }: { profile: UserProfile; checked: boolean }) => {
  const profileItemCopy = profile && getProfileItemCopy(profile);
  const employmentTypeCopy = profile && getProfileEmploymentType(profile.employmentType);
  return (
    <>
      <StyledProfileIconWrapper $checked={checked}>
        <ProfileIcon countryName={profile.countryName} />
      </StyledProfileIconWrapper>
      <StyledBody>
        <Stack direction="row" gap={3} alignItems="center">
          <StyledProfileName>{profileItemCopy && profileItemCopy.title}</StyledProfileName>
          {!profile.active && <Pill tone="neutralLight">Inactive</Pill>}
        </Stack>
        <StyledMetaText>{profileItemCopy && profileItemCopy.subtitle}</StyledMetaText>
        {employmentTypeCopy && <StyledMetaText>{employmentTypeCopy}</StyledMetaText>}
      </StyledBody>
      {checked && <StyledIndicator data-testid="profile-item-checked-icon" />}
    </>
  );
};

const AccountItem = ({ account, checked }: { account: UserLinkedAccount; checked: boolean }) => {
  return (
    <>
      <Box>
        <Avatar size="sm" fallback={account.email} src={account?.profilePicture} />
      </Box>
      <StyledBody>
        <Stack direction="row" gap={3} alignItems="center">
          <StyledProfileName>{account.email}</StyledProfileName>
        </Stack>
      </StyledBody>
      {checked && <StyledIndicator data-testid="profile-item-checked-icon" />}
    </>
  );
};

export const ProfilesMenuItem = forwardRef<HTMLDivElement, ProfilesMenuItemProps>(
  ({ as, checked = false, profile, account, isAccountsMenu, ...rest }, ref) => {
    return (
      <StyledWrapper
        as={as}
        ref={ref}
        $checked={checked}
        $isAccountsMenu={isAccountsMenu}
        aria-checked={checked}
        {...rest}
        // override role="menuitem"
        role="menuitemradio"
      >
        {!isAccountsMenu && profile && <ProfileItem profile={profile} checked={checked} />}
        {isAccountsMenu && account && <AccountItem account={account} checked={checked} />}
      </StyledWrapper>
    );
  }
);
