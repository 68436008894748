// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineZap = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M13.05 2.77a.75.75 0 0 0-.67-.23s-.04 0-.14.07c-.1.08-.24.2-.42.4-.38.39-.84.97-1.5 1.8L5.7 10.57c-.51.63-.86 1.07-1.09 1.41-.23.36-.25.49-.25.52 0 .23.1.44.28.59.03.02.14.08.56.12.41.04.97.04 1.78.04h.64c.4 0 .74 0 1.02.02a2.25 2.25 0 0 1 1.86 1.2c.14.3.2.59.22.88.02.28.02.63.02 1.02v1.79c0 1.06 0 1.81.05 2.35.02.27.05.45.09.57.03.11.05.15.06.15.17.19.42.27.67.23-.01 0 .03 0 .14-.07.1-.08.24-.2.42-.4.38-.39.84-.97 1.5-1.8l4.61-5.76c.51-.64.86-1.07 1.09-1.41.23-.36.25-.49.25-.52 0-.23-.1-.44-.28-.59-.03-.02-.14-.08-.56-.12-.41-.04-.97-.04-1.78-.04h-.64c-.4 0-.74 0-1.02-.02a2.3 2.3 0 0 1-.87-.23 2.25 2.25 0 0 1-.98-.98 2.3 2.3 0 0 1-.23-.87c-.02-.28-.02-.63-.02-1.02V5.84c0-1.06 0-1.81-.05-2.35a3.1 3.1 0 0 0-.09-.57.47.47 0 0 0-.06-.15Zm-.9-1.72c.75-.11 1.5.15 2.01.7.38.43.49 1.04.54 1.61.05.61.05 1.43.05 2.44v1.8c0 .43 0 .71.02.92.01.2.04.28.06.32.07.14.19.26.33.33.04.02.11.05.32.06.2.02.49.02.92.02h.64c.77 0 1.4 0 1.88.04.48.05.98.14 1.36.45.54.43.85 1.08.85 1.76 0 .5-.23.94-.5 1.34-.27.4-.66.9-1.14 1.5l-4.65 5.82a32.4 32.4 0 0 1-1.57 1.87c-.4.42-.86.83-1.42.92-.75.11-1.5-.15-2.01-.7-.38-.43-.49-1.04-.54-1.61-.05-.61-.05-1.43-.05-2.44v-1.8c0-.43 0-.71-.02-.92a.85.85 0 0 0-.06-.32.75.75 0 0 0-.33-.33.86.86 0 0 0-.32-.06c-.2-.02-.49-.02-.92-.02h-.64c-.77 0-1.4 0-1.88-.04a2.48 2.48 0 0 1-1.36-.45 2.25 2.25 0 0 1-.85-1.76c0-.5.23-.94.5-1.34.27-.4.66-.9 1.14-1.5l4.65-5.82a32.4 32.4 0 0 1 1.57-1.87c.4-.42.86-.83 1.42-.92Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineZap);
export { ForwardRef as IconV2OutlineZap };