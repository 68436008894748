import { Field } from 'formik';

/**
 * HiddenField
 *
 * Renders an hidden field
 *
 * @export
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').HiddenField, 'type'>} props
 * @return {JSX.Element}
 */
export const HiddenField = ({ name }) => (
  <Field name={name}>{({ field }) => <input type="hidden" {...field} />}</Field>
);
