import { Pill, Stack, Text } from '@remote-com/norma';

import PayElementDescriptionTooltip from './PayElementDescriptionTooltip';

export type Props = {
  name?: string;
  archivedAt?: string;
  description?: string;
  withArchivedPill?: boolean;
};

export default function PayElementName({
  name,
  archivedAt,
  description,
  withArchivedPill = true,
}: Props) {
  return (
    <Stack direction="row" gap={3} alignItems="center">
      <Text>{name}</Text>

      <PayElementDescriptionTooltip description={description} />

      {archivedAt && withArchivedPill ? <Pill tone="neutralLight">Archived</Pill> : null}
    </Stack>
  );
}
