import { Box, themeV2 } from '@remote-com/norma';
import styled, { css } from 'styled-components';

import { PAYROLL_CYCLE_EVENTS } from '@/src/domains/payroll/admin/PayrollCalendars/constants';

export const LoadingContainer = styled(Box)`
  max-width: 375px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

function createCalendarEventStyle(color, version) {
  const bgColor = themeV2.colors[color][version];
  const foreColor = version < 700 ? themeV2.colors.grey[800] : themeV2.colors.grey[50]; // to have constrast with the bgColor

  return css`
    color: ${foreColor};
    background: ${bgColor};

    &:hover {
      color: ${foreColor};
      background: ${bgColor};
      border: 1px solid ${foreColor};
    }

    @media print {
      background: ${bgColor};
      border: 1px solid ${bgColor};
    }
  `.join(''); // return style as string
}

const DEFAULT_VERSION = 300;

export const palette = [
  'red',
  'orange',
  'yellow',
  'green',
  'greenLight',
  'purple',
  'cyan',
  'fuchsia',
  'brand',
  'pink',
];

const PAYROLL_EVENT_COLOR_PALETTE = palette.map((color) => ({
  name: color,
  style: createCalendarEventStyle(color, DEFAULT_VERSION),
}));

const holidayEventStyle = css`
  background: ${themeV2.colors.yellow[100]};

  @media print {
    color: ${themeV2.colors.primary};
    border: 1px solid ${themeV2.colors.primary};
  }
`;

const holidayEventColor = {
  holidays: { legend: 'Bank Holiday', color: { name: 'primary', style: holidayEventStyle } },
};

export const PAYROLL_EVENT_COLORS = PAYROLL_CYCLE_EVENTS.reduce(
  (mapped, event, idx) => ({
    ...mapped,
    [event.id]: { legend: event.label, color: { ...PAYROLL_EVENT_COLOR_PALETTE[idx] } },
  }),
  { ...holidayEventColor }
);
