import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

export const FORM_DIRTY_CUSTOM_EVENT_NAME = 'form-dirty';

/**
 * Dispatches a custom event when a form becomes dirty. This event is meant to be consumed via the `useIsFormDirty` hook, which listens to it and
 * converts it into a boolean state.
 * The purpose of these 2 hooks is to allow preventing closing a modal with a form which has data entered in it. By using these hooks, the Modal can display a confirmation dialog before closing
 * and discarding all the data entered so far.
 *
 * - This is a workaround for components outside of the formik context to know if a form became dirty
 * - It still relies on the formik context, but uses a custom event to announce this change
 */
export const useFormDirtyEvent = () => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(FORM_DIRTY_CUSTOM_EVENT_NAME, { detail: { isDirty: dirty } })
    );
  }, [dirty]);
};

/**
 * Returns a boolean state indicating if a form became 'dirty'. This state is driven by an event triggered by the `useFormDirtyEvent` hook, which should be placed in a common place for all Forms.
 * The purpose of these 2 hooks is to allow preventing closing a modal with a form which has data entered in it. By using these hooks, the Modal can display a confirmation dialog before closing 
 * and discarding all the data entered so far.
 *
 * - This is a workaround for components outside of the formik context to know if a certain form is dirty

 * Please note: because this hook just listens for events on ANY form, if we have two forms on the same page, we might be considering one of them as dirty
 * and that might not be true. More details in [this discussion](https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/22219#note_1707263249)
 */
export const useIsFormDirty = ({ active }: { active: boolean }) => {
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    const handleFormDirtyEvent = (event: Event) => {
      const value = (event as CustomEvent).detail?.isDirty;
      setIsDirty(value);
    };

    if (active) {
      window.addEventListener(FORM_DIRTY_CUSTOM_EVENT_NAME, handleFormDirtyEvent);
    }

    return () => {
      window.removeEventListener(FORM_DIRTY_CUSTOM_EVENT_NAME, handleFormDirtyEvent);
    };
  }, [active]);

  return isDirty;
};
