import { Tooltip, DropdownMenu, Stack } from '@remote-com/norma';
import { IconV2OutlineCheck } from '@remote-com/norma/icons/IconV2OutlineCheck';
import { IconV2OutlineEye } from '@remote-com/norma/icons/IconV2OutlineEye';
import { IconV2OutlineSettings } from '@remote-com/norma/icons/IconV2OutlineSettings';
import truncate from 'lodash/truncate';
import { useState, useMemo, useEffect, memo } from 'react';

import { DropdownMenuButton, MenuItem } from '@/src/components/DropdownMenu';
import { ManageTableViewsDrawer } from '@/src/components/Table/Components/TableViewsDrawer/ManageTableViewsDrawer';
import type { useTableViews } from '@/src/components/Table/hooks/useTableViews';
import { DEFAULT_VIEW } from '@/src/components/Table/hooks/useTableViews';

type TableViewMenuProps = {
  tableViews: ReturnType<typeof useTableViews>;
};

const TRUNCATE_LENGTH = 25;

export const TableViewMenu = memo(
  ({ tableViews }: TableViewMenuProps) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { views = [], activeView, applyView, isTableViewsEnabledExperimental } = tableViews;

    useEffect(() => {
      if (!views.length && isDrawerOpen) {
        setIsDrawerOpen(false);
      }
    }, [views, isDrawerOpen]);

    const truncatedViewName = useMemo(() => {
      if (!activeView) return '';
      return truncate(activeView.name, { length: TRUNCATE_LENGTH });
    }, [activeView]);

    if (
      !isTableViewsEnabledExperimental ||
      views.length === 0 ||
      !activeView ||
      !Array.isArray(views)
    )
      return null;

    return (
      <>
        <DropdownMenu
          data-testid="table-views-dropdown"
          trigger={
            <DropdownMenuButton
              IconBefore={IconV2OutlineEye}
              data-testid="table-views-menu"
              variant="outline"
              tone="secondary"
              size="sm"
            >
              {activeView.name.length > TRUNCATE_LENGTH ? (
                <Tooltip label={activeView.name} position="top">
                  {truncatedViewName}
                </Tooltip>
              ) : (
                activeView.name
              )}
            </DropdownMenuButton>
          }
        >
          {[...views, DEFAULT_VIEW].map((view) => (
            <MenuItem
              key={view.slug}
              onSelect={() => applyView(view)}
              data-testid={`table-view-${view.slug}`}
            >
              <Stack direction="row" justifyContent="space-between" flex="1">
                {view.name}
                {view.slug === activeView.slug ? <IconV2OutlineCheck width="20px" /> : null}
              </Stack>
            </MenuItem>
          ))}

          <MenuItem
            hasDivider
            Icon={IconV2OutlineSettings}
            data-testid="table-views-manage"
            onSelect={() => setIsDrawerOpen(true)}
          >
            Manage table views
          </MenuItem>
        </DropdownMenu>
        <ManageTableViewsDrawer
          isVisible={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          applyView={applyView}
          views={views}
          onDelete={() => applyView(DEFAULT_VIEW)}
        />
      </>
    );
  },
  (
    {
      tableViews: {
        views: prevViews,
        activeView: prevActiveView,
        isTableViewsEnabledExperimental: prevIsEnabled,
      },
    },
    {
      tableViews: {
        views: nextViews,
        activeView: nextActiveView,
        isTableViewsEnabledExperimental: nextIsEnabled,
      },
    }
  ) => {
    return (
      prevViews === nextViews &&
      prevActiveView === nextActiveView &&
      prevIsEnabled === nextIsEnabled
    );
  }
);
