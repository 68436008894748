import { HTMLRendered } from '@remote-com/norma';
import { useField } from 'formik';
import { useEffect, type ChangeEventHandler } from 'react';

import type { CheckBoxGroupField } from '@/src/components/Form/DynamicForm/types';
import { CheckboxField } from '@/src/components/Ui/Form';
import { FieldGroupDescription, FieldGroupLabel } from '@/src/components/Ui/Form/Input';
import { Fieldset } from '@/src/components/Ui/Form/Radio/Radio.styled';
import { RadioGroup } from '@/src/components/Ui/Form/Radio/RadioGroup';
import { FormFieldError } from '@/src/domains/registration/onboarding/shared/styles/Form';

type CheckboxGroupFieldProps = Omit<CheckBoxGroupField, 'type'>;

export const CheckboxGroupField = ({
  description,
  label,
  name,
  options,
  direction = 'column',
}: CheckboxGroupFieldProps) => {
  const labelId = `${name}-label`;

  const [{ onChange }, { error, touched }, { setTouched, setValue }] = useField(name);

  useEffect(() => {
    const defaultValue = options.filter((option) => !!option.default).map((option) => option.value);

    if (defaultValue.length) {
      setValue(defaultValue);
    }
  }, [options, setValue]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!touched) {
      setTouched(true);
    }
    onChange(event);
  };

  return (
    <Fieldset>
      <FieldGroupLabel id={labelId} hasError={!!error && touched}>
        {label}
      </FieldGroupLabel>
      {description && <HTMLRendered Tag={FieldGroupDescription}>{description}</HTMLRendered>}
      {/* @ts-expect-error RadioGroup has not been converted to TypeScript yet */}
      <RadioGroup $direction={direction} role="group" aria-labelledby={labelId}>
        {options.map(({ value, label: optionLabel, description: checkboxDescription }) => (
          <CheckboxField
            key={value}
            value={value}
            label={optionLabel}
            description={checkboxDescription}
            name={name}
            onChange={handleChange}
          />
        ))}
      </RadioGroup>
      {error && <FormFieldError name={name} />}
    </Fieldset>
  );
};
