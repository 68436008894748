import { useEffect, useRef, useState } from 'react';

import { MIN_COLUMN_SIZE } from '../Table.styled';

const getColumnsWidth = (columns, activeColumn, event) =>
  columns.map((col, i) => {
    const currentCol = col.ref.current;
    if (i === activeColumn) {
      const width = event.clientX - currentCol.getBoundingClientRect().left;

      if (width >= MIN_COLUMN_SIZE) {
        return `${width}px`;
      }
    }

    return `${currentCol.offsetWidth}px`;
  });

const createHeaders = (headers) => {
  return headers.map((item) => ({
    column: item,
    ref: useRef(),
  }));
};

export const useResizableColumns = ({ baseColumns, tableRef }) => {
  const columns = createHeaders(baseColumns);
  const [activeColumn, setActiveColumn] = useState(null);
  const tableStyles = tableRef?.current?.style;
  useEffect(() => {
    const mouseMove = (event) => {
      const gridColumns = getColumnsWidth(columns, activeColumn, event);

      if (tableStyles) {
        tableStyles.gridTemplateColumns = `${gridColumns.join(' ')}`;
        tableStyles.userSelect = 'none';
      }
    };

    const removeListeners = () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', removeListeners);
    };

    if (activeColumn !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', () => {
        setActiveColumn(null);
        removeListeners();
        if (tableStyles) {
          tableStyles?.removeProperty('user-select');
        }
      });
    }

    return () => {
      removeListeners();
    };
  }, [activeColumn, columns, tableRef, tableStyles]);

  useEffect(() => {
    if (columns.length && tableStyles) {
      tableStyles?.removeProperty('grid-template-columns');
    }
    // if we change the number of columns in the screen we need to delete all the changes to the styles we made
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns.length]);

  return { activeColumn, setActiveColumn, columns };
};
