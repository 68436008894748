import { formatInTimeZone } from 'date-fns-tz';

import { EMPTY_DATE } from './constants';
import {
  convertLocalTimeStringToDateObj,
  convertUtcStringToDateObj,
  getUserTimezone,
} from './utils';

const monthYearFormats = ['yyyy-MM', 'MMMM yyyy', 'MMMM y', 'LLLL yyyy', 'MMMM, yyyy', 'MMM yyyy'];

function isValidMonthNumber(monthNumber: number) {
  return monthNumber >= 1 && monthNumber <= 12;
}

/**
 * @typedef {Object} makeFormatFnOptions
 * @property {Boolean} isSourceInUtc - whether the given date is in UTC timezone, defaults to true
 * @property {Boolean} formatInLocalTime - whether to format the date in local time, defaults to false, i.e. formats in UTC by default
 * @property {string} timezone - which timezone to use for formatting, defaults to "UTC"
 * @property {string} fallback - custom fallback to be used as empty state
 */
/**
 * Given a date format string, returns a formatting function that given a date or date-time string
 * returns a string following the specified format.
 *
 * Example use cases:
 * - Format a UTC date string from the BE, to the user's local time: makeFormatFn(''yyyy-MM-dd')(dateStr, { formatInLocalTime: true })
 * - Format a local time date string from a date-picker, to UTC: makeFormatFn(''yyyy-MM-dd')(dateStr, { isSourceInUtc: false })
 * - Format a local time date string from a date-picker, and maintain the user's local-time: makeFormatFn(''yyyy-MM-dd')(dateStr, { isSourceInUtc: false, formatInLocalTime: true })
 *
 * @param {string} dateFormat format the formatting function will use. Example yyyy-MM-dd.
 * @param {makeFormatFnOptions} options
 * @returns {(date: Date) => string}
 */
export function makeDateFormatFn(dateFormat: string) {
  return (
    datetime: string | number | Date | null | undefined,
    {
      isSourceInUtc = true,
      formatInLocalTime = false,
      timezone = 'UTC',
      fallback = EMPTY_DATE,
    } = {}
  ): string => {
    // yyyy-mm format is only allowed for yyyy-MM output,
    // otherwise we will have to assume date
    if (typeof datetime === 'string' && /^\d{4}-\d{1,2}$/.test(datetime)) {
      if (monthYearFormats.includes(dateFormat)) {
        const [year, month] = datetime.split('-');
        if (!year || !month || !isValidMonthNumber(parseInt(month, 10))) {
          return fallback;
        }
      } else {
        return fallback;
      }
    }

    /**
     * TODO: Refactor this whole file to make sure all utilities are aware of isomorphic dates
     * aka, a string or a number or a Date object, which is what date-fns always accepts.
     *
     * This would change a lot of implementations details and right now we just want to have working
     * types for now.
     */
    const date = isSourceInUtc
      ? convertUtcStringToDateObj(datetime as string)
      : convertLocalTimeStringToDateObj(datetime as string);

    if (!date) {
      return fallback;
    }

    const targetTimeZone = formatInLocalTime ? getUserTimezone() : timezone;
    return formatInTimeZone(date, targetTimeZone, dateFormat);
  };
}

export const formatFullMonth = makeDateFormatFn('MMMM');
export const formatFullMonthDay = makeDateFormatFn('MMMM d');
export const formatFullMonthDayOrdinal = makeDateFormatFn('MMMM do');
