// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneHeart = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M1.25 9A6.83 6.83 0 0 1 8 2.25c1.38 0 2.86.4 4 1.56A5.34 5.34 0 0 1 16 2.25c3.36.05 6.74 2.79 6.74 6.75 0 6.02-5.77 10.35-10.75 12.84C7.02 19.34 1.25 15.02 1.25 9Z" /><path d="M12 21.84C7.02 19.34 1.25 15.02 1.25 9c4.75-4.75 18.5 5.27 21.5 0 0 6.02-5.77 10.35-10.75 12.84Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneHeart);
export { ForwardRef as IconV2DuotoneHeart };