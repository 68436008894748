import { Box } from '@remote-com/norma';
import { NodeViewWrapper } from '@tiptap/react';
import styled from 'styled-components';

const TRIGGER_SIZE = '40px';

export const ResizeTrigger = styled(Box)`
  display: none;

  position: absolute;
  top: 50%;

  transform: translateY(-50%);
  width: 6px;
  height: ${TRIGGER_SIZE};

  background-color: ${({ theme }) => theme.colors.grey[700]};
  border: 1px solid ${({ theme }) => theme.colors.grey[200]};
  border-radius: 3px;

  cursor: ew-resize;
`;
export const StyledNodeViewWrapper = styled(NodeViewWrapper)`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 0;

  position: relative;

  &:hover ${ResizeTrigger} {
    display: block;
  }
`;
export const ResizeTriggerRight = styled(ResizeTrigger)`
  right: 8px;
`;
export const ResizeTriggerLeft = styled(ResizeTrigger)`
  left: 8px;
`;
export const Image = styled.img``;
