import type { ContainerProps } from 'react-select';

import { Box } from '../../../layout';

export function SelectContainer({ children, innerProps }: ContainerProps) {
  return (
    // @ts-expect-error - Types of property 'ref' are incompatible.
    <Box width="100%" {...innerProps}>
      {children}
    </Box>
  );
}
