import { Text, Box, Stack } from '@remote-com/norma';
import { IconCheck } from '@remote-com/norma/icons/IconCheck';
import { IconDownloadBase } from '@remote-com/norma/icons/IconDownloadBase';
import { IconPaperclip } from '@remote-com/norma/icons/IconPaperclip';
import styled from 'styled-components';

import { ButtonInline } from '@/src/components/Button';
import { FieldGroupDescription, FieldGroupLabel } from '@/src/components/Ui/Form/Input';

export const PaperClipIcon = styled(IconPaperclip)``;

export const DownloadBaseIcon = styled(IconDownloadBase)``;

export const StyledFileUploadField = styled(Box).attrs({ px: 6, py: 7 })`
  border: none;
  border-radius: 10px;
  background: var(--colors-blank);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
`;

export const FieldTitle = styled(FieldGroupLabel)`
  display: flex;
  align-items: center;
`;

export const FileSelectedCheck = styled(IconCheck)`
  height: 16px;
  fill: var(--colors-darkTurquoise);
  margin-left: 10px;
  flex-shrink: 0;
`;

export const FileDescription = FieldGroupDescription;

export const FileName = styled(Text).attrs({
  variant: 'base',
  color: 'inputPlaceholder',
})`
  font-size: 0.875rem;
`;

export const FileLinkWrapper = styled(Stack).attrs({ direction: 'row', gap: 2, mt: 4 })`
  ${PaperClipIcon} {
    stroke: currentColor;
  }
`;

export const ButtonInlineStyled = styled(ButtonInline)`
  display: inline-flex;
  gap: 2px;
  margin-left: 2px;
`;

export const FileLink = styled.a`
  color: var(--colors-irisBlue);
  text-decoration: none;

  ${PaperClipIcon} {
    fill: var(--colors-irisBlue);
  }

  ${FileName} {
    color: var(--colors-irisBlue);
  }
`;
