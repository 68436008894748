// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineShieldCheck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12.54 2.17a3.25 3.25 0 0 0-1.08 0c-.38.07-.76.23-1.75.67L6.16 4.42a6.25 6.25 0 0 0-1.72.9c-.25.25-.44.54-.56.87-.12.34-.13.74-.13 1.93v5.45c0 1.3 0 1.8.14 2.23.12.41.33.8.6 1.12.29.36.7.64 1.78 1.36l3.48 2.32c1.01.68 1.37.9 1.73.98.34.09.7.09 1.04 0 .36-.08.72-.3 1.73-.98l3.48-2.32c1.08-.72 1.5-1 1.78-1.36.27-.33.48-.7.6-1.12.13-.43.14-.93.14-2.23V8.12c0-1.19-.01-1.6-.13-1.93-.12-.33-.31-.62-.56-.87a6.25 6.25 0 0 0-1.72-.9L14.3 2.84c-.99-.44-1.37-.6-1.75-.67ZM11.21.7a4.75 4.75 0 0 1 1.58 0c.6.1 1.15.35 2.02.74l.1.04 3.54 1.58.13.05c.91.4 1.54.69 2.01 1.14.42.4.74.9.94 1.44.22.62.22 1.3.22 2.3v5.72c0 1.13 0 1.86-.2 2.54-.19.6-.48 1.15-.88 1.64a8.87 8.87 0 0 1-2.1 1.64l-3.5 2.33-.1.08c-.86.56-1.44.95-2.1 1.11-.57.14-1.17.14-1.74 0-.66-.16-1.24-.55-2.1-1.11l-.1-.08-3.5-2.33-.1-.06a8.87 8.87 0 0 1-2-1.58c-.4-.49-.7-1.04-.88-1.64-.2-.68-.2-1.4-.2-2.54V7.98c0-1 0-1.68.22-2.3.2-.55.52-1.04.94-1.44.47-.45 1.1-.73 2.01-1.14l.13-.05L9.1 1.47l.09-.04c.87-.39 1.43-.64 2.02-.74Zm4.82 8.28c.3.3.3.77 0 1.06l-4.5 4.5c-.3.3-.77.3-1.06 0l-2-2a.75.75 0 1 1 1.06-1.06L11 12.94l3.97-3.97c.3-.3.77-.3 1.06 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineShieldCheck);
export { ForwardRef as IconV2OutlineShieldCheck };