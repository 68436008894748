import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import type { Editor } from '@tiptap/react';
import { Node, mergeAttributes, PasteRule, ReactNodeViewRenderer } from '@tiptap/react';
import type { MouseEvent } from 'react';

import { CONTRACT_NODE_TYPES } from '@/src/domains/contracts/ContractsEditor/constants';
import { findFieldsById } from '@/src/domains/contracts/ContractsEditor/nodes/ManuallyEnteredField/helpers';
import { ManuallyEnteredFieldComponent } from '@/src/domains/contracts/ContractsEditor/nodes/ManuallyEnteredField/ManuallyEnteredFieldComponent';
import type { ManuallyEnteredFieldAttrs } from '@/src/domains/contracts/contractTemplate/ManuallyEnteredFields/types';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    manuallyEnteredField: {
      addManuallyEnteredField: (fieldAttrs: ManuallyEnteredFieldAttrs) => ReturnType;
      removeManuallyEnteredField: (idToRemove: string) => ReturnType;
      updateManuallyEnteredField: (
        fieldId: string,
        newAttrs: ManuallyEnteredFieldAttrs
      ) => ReturnType;
      scrollToManuallyEnteredField: (fieldId: string) => ReturnType;
      toggleManuallyEnteredFieldFocus: (fieldId: string, isFocused: boolean) => ReturnType;
    };
  }
  interface EditorEvents {
    // Event key should be the same as `MANUALLY_ENTERED_FIELD_CLICK_EVENT_NAME` below.
    manuallyEnteredFieldClick: {
      editor: Editor;
      event: MouseEvent<HTMLElement>;
      node: {
        attrs: ManuallyEnteredFieldAttrs;
      };
    };
  }
}

const PASTE_REGEX = /\[.+?\]/g;
const FIELD_FOCUSED_DECORATION = 'manuallyEnteredFieldFocused';

export const MANUALLY_ENTERED_FIELD_CLICK_EVENT_NAME = 'manuallyEnteredFieldClick';

function isElement(value?: any): value is Element {
  return value?.nodeType === 1; // Node.ELEMENT_NODE
}

type FieldOptions = {
  hasEditOnClick: boolean;
  hasPasteRule: boolean;
  hasStyling: boolean;
  hasValueRendered: boolean;
};

export const ManuallyEnteredField = Node.create<FieldOptions>({
  name: CONTRACT_NODE_TYPES.MANUALLY_ENTERED_FIELD,
  group: 'inline',
  inline: true,
  selectable: false,
  /**
   * Marking as editable via the `content` property, so that it
   * inherits default text behaviour, like CMD+Arrow to move the cursor.
   * This change is linked to the contentEditable=false set in the Component
   * More context: https://linear.app/remote/issue/COD-1291/contracts-editor-bug-with-cmdarrow-to-move-to-beginning-of-text
   */
  content: 'text*',
  atom: true,

  addOptions() {
    return {
      ...this.parent?.(),
      hasEditOnClick: false,
      hasPasteRule: false,
      hasStyling: true,
      hasValueRendered: false,
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        renderHTML: ({ id }) => ({
          'data-id': id,
        }),
        parseHTML: (element) => element.getAttribute('data-id'),
      },
      label: {
        default: '',
        renderHTML: ({ label }) => ({
          'data-label': label,
        }),
        parseHTML: (element) => element.getAttribute('data-label'),
      },
      value: {
        default: '',
        renderHTML: ({ value }) => ({
          'data-value': value,
        }),
        parseHTML: (element) => element.getAttribute('data-value'),
      },
      description: {
        default: '',
        renderHTML: ({ description }) => ({
          'data-description': description,
        }),
        parseHTML: (element) => element.getAttribute('data-description'),
      },
    };
  },

  addPasteRules() {
    if (this.options.hasPasteRule) {
      return [
        new PasteRule({
          find: PASTE_REGEX,
          handler: ({ state, range, match }) => {
            const { tr } = state;
            const fieldLabel = match[0].replace(/\[|\]/g, '').trim();

            tr.replaceWith(
              range.from,
              range.to,
              this.type.create({
                id: crypto.randomUUID(),
                label: fieldLabel,
              })
            );
          },
        }),
      ];
    }

    return [];
  },

  addCommands() {
    return {
      addManuallyEnteredField:
        (fieldAttrs) =>
        ({ commands }) =>
          commands.insertContent({
            type: this.name,
            attrs: fieldAttrs,
          }),
      removeManuallyEnteredField:
        (idToRemove) =>
        ({ tr }) => {
          const removeList = findFieldsById(tr.doc, idToRemove);
          removeList.reduceRight(
            (transform, { node, pos }) => transform.delete(pos, pos + node.nodeSize),
            tr
          );
          // NOTE: Here and in the commands below, the return value of the command call
          // should be boolean to indicate whether the command can run, see more at
          // https://tiptap.dev/api/commands#dry-run-for-commands
          return true;
        },
      updateManuallyEnteredField:
        (fieldId, newAttrs) =>
        ({ tr }) => {
          const updateList = findFieldsById(tr.doc, fieldId);
          updateList.reduceRight(
            (transform, { node, pos }) =>
              transform.replaceWith(pos, pos + node.nodeSize, this.type.create(newAttrs)),
            tr
          );
          return true;
        },
      scrollToManuallyEnteredField:
        (fieldId) =>
        ({ tr, view }) => {
          const [targetField] = findFieldsById(tr.doc, fieldId);

          if (targetField) {
            const { node } = view.domAtPos(targetField.pos);

            if (isElement(node)) {
              node.scrollIntoView({
                // Scroll the editor so that the focused field node appears
                // in the center of the viewport -- using the default `start` does
                // not work that well, because the floating toolbar sometimes covers
                // the node.
                block: 'center',
                behavior: 'smooth',
              });

              // Return value is used to decide whether the target field was
              // found and scrolled to in the primary editor before attempting
              // to scroll the secondary one.
              return true;
            }
          }

          return false;
        },
      toggleManuallyEnteredFieldFocus:
        (fieldId, isFocused) =>
        ({ tr }) => {
          const targetFields = findFieldsById(tr.doc, fieldId);

          tr.setMeta(
            FIELD_FOCUSED_DECORATION,
            targetFields.map(({ node, pos }) => ({ from: pos, to: pos + node.nodeSize, isFocused }))
          );

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      // Plugin to imperatively set decorations through a command, adapted from
      // https://discuss.prosemirror.net/t/apply-decorations-directly-through-an-editor-method/2377/7
      new Plugin({
        key: new PluginKey('manuallyEnteredFieldHighlight'),
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(tr, value) {
            const decorationMeta = tr.getMeta(FIELD_FOCUSED_DECORATION);

            if (Array.isArray(decorationMeta)) {
              return DecorationSet.create(
                tr.doc,
                decorationMeta.map(({ from, to, isFocused }) =>
                  Decoration.node(from, to, { isFocused })
                )
              );
            }

            return value;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ManuallyEnteredFieldComponent);
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['span', mergeAttributes({ 'data-type': this.name }, HTMLAttributes), node.attrs.label];
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderText({ node }) {
    return `[${node.attrs.label}]`;
  },
});
