import styled, { css } from 'styled-components';

import { Stack } from '../../layout';

type MediaProps = {
  /**
   * Spacing between MediaObject and MediaBody.
   */
  $spacing?: string;
  /**
   * Center MediaObject(on smaller viewports, when MediaBody is wrapped)
   */
  $center?: boolean;
  /**
   * @deprecated See $center.
   */
  center?: boolean;
};

export const Media = styled(Stack)<MediaProps>`
  --mediaObjectSpacing: ${(props) => props.$spacing || '20px'};
  align-items: ${(props) => (props.center || props.$center ? 'center' : 'flex-start')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: row;
  }
`;

export const MediaBody = styled(Stack)`
  flex-grow: 1;
`;

type MediaObjectProps = {
  /**
   * Don't shrink MediaObject on if there is not enough width
   */
  $preserveSize?: boolean;
};

export const MediaObject = styled(Stack)<MediaObjectProps>`
  ${({ $preserveSize }) =>
    $preserveSize &&
    css`
      flex-shrink: 0;
    `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    & + ${MediaBody}, ${MediaBody} + & {
      margin-left: var(--mediaObjectSpacing);
    }
  }

  @media screen and (max-width: ${({ theme }) => parseInt(theme.breakpoints[1]) - 1}px) {
    & + ${MediaBody}, ${MediaBody} + & {
      margin-top: var(--mediaObjectSpacing);
    }
  }

  /** 
  DO NOT override global img selector because it might break other places.
  e.g. <Avatar /> with a vertical image. Prefer using a scoped approach. */
  /* img, svg { } */
`;
