import { useSaveFilterContext } from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilterContext';
import { SelectField } from '@/src/components/Ui/Form/formikIntegration/SelectField';

const MANAGE_FILTERS_LABEL = 'Manage saved filters';
const filterManagerOption = { label: MANAGE_FILTERS_LABEL, value: {} };

const FilterLoader = ({ onChange, ...rest }) => {
  const { savedFilters, setShowFilterManager } = useSaveFilterContext();
  const parsedSavedFilters = savedFilters?.map((filter) => ({
    label: filter.label,
    value: filter.filters,
  }));

  const groupedOptions = [
    { options: [...parsedSavedFilters] },
    { label: 'Filter Manager', options: [filterManagerOption] },
  ];

  const handleOnChange = (value) => {
    if (value?.label === MANAGE_FILTERS_LABEL) {
      setShowFilterManager(true);
    } else {
      onChange(value);
    }
  };

  return (
    <SelectField name="applyFilter" options={groupedOptions} onChange={handleOnChange} {...rest} />
  );
};

export default FilterLoader;
