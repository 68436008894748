import { FeedbackMessage, FEEDBACK_MESSAGE_ERROR, Box } from '@remote-com/norma';
import { useFormikContext } from 'formik';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef, useMemo } from 'react';

import { ButtonInline } from '@/src/components/Button';
import { useFormDirtyEvent } from '@/src/components/Form/hooks';
import { captureException } from '@/src/helpers/captureException';
import { escapeIndexingAndNestingChars, getErrorFieldsNames } from '@/src/helpers/forms';

function getFieldsetLegendText(fieldsetElement) {
  if (fieldsetElement.attributes['aria-label']) {
    return fieldsetElement.attributes['aria-label'].textContent;
  }

  if (fieldsetElement.attributes['aria-labelledby']) {
    return document.getElementById(fieldsetElement.attributes['aria-labelledby'].textContent)
      ?.textContent;
  }

  return fieldsetElement.querySelector('legend')?.textContent;
}

/**
 * Inspects an element to determine its text label.
 * It looks on the aria-label attribute, labels property
 * and defaults to the name attribute of the element
 *
 * @param {HTMLElement} inputElement
 * @returns {String} input text label
 */
function getInputLabel(inputElement) {
  // accessing via attributes and not .ariaLabel since it's not supported on all browsers

  if (inputElement.attributes['aria-label']) {
    return inputElement.attributes['aria-label'].textContent;
  }

  if (inputElement.attributes['aria-labelledby']) {
    return document.getElementById(inputElement.attributes['aria-labelledby'].textContent)
      ?.textContent;
  }

  if (inputElement.type === 'fieldset') {
    return getFieldsetLegendText(inputElement);
  }

  if (inputElement.type === 'radio') {
    return getFieldsetLegendText(inputElement.closest('fieldset'));
  }

  const labelElement =
    inputElement.labels?.[inputElement.labels.length - 1] || inputElement.closest('label');

  return labelElement?.firstChild.textContent || inputElement.name;
}

/**
 * Queries DOM for an input/textarea/fieldset (that's not hidden) with the provided name attribute
 * @param {String} name - input name
 * @returns {DOMNode}
 */
function findInputElementByNameAttribute(name) {
  const nameAttrDefault = `[name='${name}']:not([type='hidden'])`;
  const nameAttrJSF = `[name='${snakeCase(name)}']:not([type='hidden'])`;

  // for regular composeField* forms
  const query = `input${nameAttrDefault}, textarea${nameAttrDefault}, fieldset${nameAttrDefault}`;
  // for json-schema-form
  const snakeCaseQuery = `input${nameAttrJSF}, textarea${nameAttrJSF}, fieldset${nameAttrJSF}`;

  return document.querySelector(query) || document.querySelector(snakeCaseQuery);
}

/**
 * Queries DOM for an react-select selector element
 * @param {String} name - input name
 * @returns {DOMNode}
 */
function findReactSelectSelectorElement(name) {
  // for regular composeField* forms
  const query = `#${escapeIndexingAndNestingChars(name)}-selector-input`;
  // for json-schema-form
  const snakeCaseQuery = `#${escapeIndexingAndNestingChars(snakeCase(name))}-selector-input`;

  try {
    // TODO solve this properly at https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/15213
    return document.querySelector(query) || document.querySelector(snakeCaseQuery);
  } catch (ex) {
    captureException(ex);
    return null;
  }
}

/**
 * Queries the DOM for the input that corresponds to the provided field name.
 *
 * @param {String} name - field name
 * @returns {HTMLElement}
 */
function getInputElement(name) {
  let inputElement = findInputElementByNameAttribute(name);

  // react-select has its input as hidden, so we need to try and search for its selector input
  if (!inputElement) {
    inputElement = findReactSelectSelectorElement(name);
  }

  // Last resource: Look for this as an ID (eg it catches supportedTypes.WORK_SCHEDULE)
  if (!inputElement) {
    inputElement = document.getElementById(name) || document.getElementById(snakeCase(name));
  }

  return inputElement;
}

/**
 * Sorts HTML elements based on their position in the DOM tree.
 *
 * @param {HTMLElement} element1
 * @param {HTMLElement} element2
 * @returns {Number} sorting result
 */
function sortElements(element1, element2) {
  const DOCUMENT_POSITION_PRECEDING = 2;
  const DOCUMENT_POSITION_FOLLOWING = 4;

  const comparisonResult = element1.compareDocumentPosition(element2);

  if (comparisonResult === DOCUMENT_POSITION_PRECEDING) {
    return 1;
  }

  if (comparisonResult === DOCUMENT_POSITION_FOLLOWING) {
    return -1;
  }

  return 0;
}

export const FieldErrorLink = ({ element, children }) => {
  const handleClick = () => {
    if (!element) {
      return;
    }

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });

    let focusableElement = element;
    if (element.type === 'fieldset') {
      focusableElement = element.querySelector('input') || element.querySelector('button');
    }
    focusableElement.focus({ preventScroll: true });
  };

  return <ButtonInline onClick={handleClick}>{children}</ButtonInline>;
};

FieldErrorLink.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
};

const INITIAL_STATE = {
  errorFields: [],
  errorLabels: [],
  errorUnmapped: [],
};
const FieldIssues = ({ errors, formName }) => {
  const errorKeys = useMemo(() => getErrorFieldsNames(errors), [errors]);

  const [{ errorFields, errorLabels, errorUnmapped }, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (errorKeys.length === 0) {
      setState(INITIAL_STATE);
    } else {
      // Based on the input names, query the DOM to fetch each node (so we can focus and scroll to it).
      // Since formik errors are ordered alphabetically we need to re-order them based on their position on the DOM tree
      const fieldsWithErrors = errorKeys.map(getInputElement).filter(Boolean).sort(sortElements);
      const unmappedWithErrors = errorKeys.filter((error) => !getInputElement(error));

      // We just need to present the name of the 2 topmost fields so we slice the array and fetch their labels afterwards
      const labels = fieldsWithErrors.slice(0, 2).filter(Boolean).map(getInputLabel);

      setState({
        errorFields: fieldsWithErrors,
        errorLabels: labels,
        errorUnmapped: unmappedWithErrors,
      });
    }
  }, [errorKeys]);

  const numberOfErrorFields = errorFields.length;
  const numberOfUnmappedErrors = errorUnmapped.length;

  if (numberOfErrorFields === 0 && numberOfUnmappedErrors === 0) {
    return null;
  }

  if (numberOfErrorFields === 0 && numberOfUnmappedErrors > 0) {
    const errorMessages = errorUnmapped.map((errorKey) => `"${errorKey} ${errors[errorKey]}"`);

    captureException(
      new Error(`Form "${formName}" unexpected error: ${JSON.stringify(errorUnmapped)}`)
    );

    return (
      <FeedbackMessage
        variant={FEEDBACK_MESSAGE_ERROR}
        title="Invalid data"
        data-testid="error-summary"
      >
        Error found: {errorMessages.join(', ')}. If needed, please contact{' '}
        <ButtonInline href="mailto:help@remote.com" target="_blank" rel="noopener noreferrer">
          help@remote.com
        </ButtonInline>
      </FeedbackMessage>
    );
  }

  const firstError = <FieldErrorLink element={errorFields[0]}>{errorLabels[0]}</FieldErrorLink>;
  const secondError =
    numberOfErrorFields > 1 ? (
      <FieldErrorLink element={errorFields[1]}>{errorLabels[1]}</FieldErrorLink>
    ) : null;

  const issueMessages = {
    1: <>The field {firstError} is invalid.</>,
    2: (
      <>
        The fields {firstError} and {secondError} are invalid.
      </>
    ),
    3: (
      <>
        The fields {firstError}, {secondError} and 1 other are invalid.
      </>
    ),
    moreThan3Errors: (
      <>
        The fields {firstError}, {secondError} and {errorFields.length - 2} others are invalid.
      </>
    ),
  };
  const fieldIssues = issueMessages[numberOfErrorFields] || issueMessages.moreThan3Errors;

  return (
    <FeedbackMessage
      variant={FEEDBACK_MESSAGE_ERROR}
      title="Invalid data"
      data-testid="error-summary"
      mb="0"
    >
      Please check the form. {fieldIssues}
    </FeedbackMessage>
  );
};

FieldIssues.propTypes = {
  errors: PropTypes.object.isRequired,
};

const ErrorSummary = ({ errorMessage, formName, title = 'Error', ...restProps }) => {
  // Although ErrorSummary is not related to signaling a "dirty" form, this component is used widely in most forms
  // So this is the simplest and most efficient solution to get the form context and send the event.
  // This event prevents closing a modal by mistake while the user is filling the form.
  // More details in this discussion: https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/22219/diffs#note_1707263247.
  useFormDirtyEvent();

  const { submitCount, errors } = useFormikContext();
  const auxRef = useRef({
    submitCount,
    errorMessage,
  });
  const [shouldRender, setShouldRender] = useState(false);
  const hasSubmitted = submitCount > 0;
  const hasFieldErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    // When fixing an already submitted form that had an API error,
    // we want to skip rendering so we don't show an 'outdated' top-level errorMessage.
    // So, ErrorSummary should render if:
    // - there are validation errors
    // - errorMessage changed from last render
    // - the user tried to re-submit the form.
    if (
      (hasSubmitted && hasFieldErrors) ||
      submitCount !== auxRef.current.submitCount ||
      errorMessage !== auxRef.current.errorMessage
    ) {
      auxRef.current.submitCount = submitCount;
      auxRef.current.errorMessage = errorMessage;
      setShouldRender(true);
    } else {
      setShouldRender(false);
    }
  }, [submitCount, auxRef, hasFieldErrors, hasSubmitted, errorMessage]);

  if (!shouldRender) {
    return null;
  }
  // In case there is no validation issue but there's still a top-level error
  if (!hasFieldErrors && errorMessage) {
    return (
      <Box my={7} {...restProps}>
        <FeedbackMessage variant={FEEDBACK_MESSAGE_ERROR} title={title} data-testid="error-summary">
          {errorMessage}
        </FeedbackMessage>
      </Box>
    );
  }

  return Object.keys(errors).length > 0 ? (
    <Box my={7} {...restProps}>
      <FieldIssues errors={errors} formName={formName} />
    </Box>
  ) : null;
};

ErrorSummary.propTypes = {
  /** Used to custom the title of the error in case we need to */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** A Top-level custom error message. It can be a generic server error, a global form error message, etc.
   * Note: field error messages are already taken care of by the component. */
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Used to improve tracking of unexpected errors */
  formName: PropTypes.string,
};

export { ErrorSummary };
