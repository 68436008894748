import type { ReactNode } from 'react';
import React from 'react';

import { BlockErrorBoundary } from '@/src/components/BlockErrorBoundary';

type TabPanelProps = {
  index: number | string;
  value: number | string;
  children: ReactNode;
};

const TabPanel = ({ value, index, children }: TabPanelProps) =>
  value === index ? <BlockErrorBoundary>{children}</BlockErrorBoundary> : null;

export default TabPanel;
