import isFunction from 'lodash/isFunction';
import type {
  FocusEventHandler,
  ReactNode,
  Ref,
  MutableRefObject,
  MouseEventHandler,
  ClipboardEventHandler,
  ChangeEvent,
} from 'react';
import React, { forwardRef, useState } from 'react';

import { useProvidedRefOrCreate } from '../../hooks/useProvidedRefOrCreate';
import { Stack } from '../../layout';

import { FormGroupProvider } from './context/context';
import {
  Container,
  Body,
  LabelInputWrapper,
  Label,
  LabelText,
  Aside,
  Meta,
  ErrorMessage,
  MetaSeparator,
  Description,
  Details,
  MetaContainer,
} from './FormGroup.styled';
import { useGetInputSizeAndLabelPlacement } from './hooks/useGetInputSizeAndLabelPlacement';

export type FormGroupSize = 'sm' | 'md';
export type FormGroupLabelPlacement = 'inside' | 'outside';

export type FormGroupProps = {
  /**
   * `name` attribute for the underlying `<input>` element
   */
  name: string;
  /**
   * Text read by a screen reader when visiting this control
   */
  label: ReactNode;
  /**
   * Placement of the label, relatively to the input outline
   */
  labelPlacement?: FormGroupLabelPlacement;
  /**
   * Text that is used alongside the control label for additional help
   */
  description?: ReactNode | string;
  /**
   * `<input>` value
   */
  value?: string | number | unknown[] | Record<string, unknown>;
  /**
   * Text displayed when the control is in an error state
   */
  errorText?: ReactNode;
  /**
   * Indicates wether the error message should be rendered (if there's any)
   * @default true
   */
  displayErrorMessage?: boolean;
  /**
   * Aside area next to input to place icons, and other components.
   */
  aside?:
    | ((props: {
        $isReadOnly?: boolean;
        $hasError?: boolean;
        $hasFocus?: boolean;
        $hasValue?: boolean;
      }) => JSX.Element)
    | null;
  /**
   * The input component
   */
  children: ReactNode;
  /**
   * Hide the UI label from screen readers.
   * Should only be used for absolute edge cases (e.g. Stripe Elements loads input elements in iframe and adds labels via `aria-label`).
   */
  dangerouslyHideA11yLabel?: boolean;
  /**
   * Specifies whether the control is read only
   * @default false
   */
  readOnly?: boolean;
  /**
   * Additional details that will be displayed separately from the description
   */
  extra?: ReactNode | string;
  /**
   * Character counter value for the input
   */
  counter?: ReactNode;
  /**
   * Force styling for focus state.
   * Use with caution.
   */
  hasFocus?: boolean;
  /**
   * Force styling for filled state.
   * Use with caution.
   */
  hasValue?: boolean;
  /**
   * Boolean prop to determine if a component using an instance of FormGroup is an InputSelect. Needed to apply specific styles for a11y purposes.
   * @private This prop is internal and should not be used outside the context of the InputSelect component.
   * Gitlab comment: https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/28491#note_1989453271
   * @default false
   */
  $internalIsInputSelect?: boolean;
  /**
   * Specify the unique identifier for the input. Will use `name` if undefined
   * @default name
   */
  id?: string;
  /**
   * onClick handler
   */
  onClick?: MouseEventHandler;
  /**
   * onBlur handler
   */
  onBlur?: FocusEventHandler;
  /**
   * Callback function. Executed whenever the `<input>` value is updated
   */
  onChange?: (event: ChangeEvent, ...args: unknown[]) => void;
  /**
   * Callback function. Executed whenever the `<input>` is focused
   */
  onFocus?: FocusEventHandler;
  /**
   * Callback function. Executed whenever the user pastes a value into the input
   */
  onPaste?: ClipboardEventHandler;
  /**
   * Placeholder attribute for the `<input>`
   * @deprecated: Use `label` prop on the input component instead since placeholder is only a fallback.
   */
  placeholder?: string;
  /**
   * Specifies the size of the input ['sm', 'md']
   * @default 'md'
   */
  size?: FormGroupSize;
  /**
   * Specifies the width of the input (useful for custom UIs such as tables or inline inputs)
   */
  maxWidth?: string;
  /**
   * For very specific use-cases, a visual label is not needed and can be turned off with the `hideLabel` prop
   * @default false
   */
  hideLabel?: boolean;
  containerRef?: Ref<HTMLDivElement | null> | MutableRefObject<HTMLDivElement | null>;
};

export function getFormGroupIds(identifier: string) {
  const idInput = identifier;
  const idDescription = `${idInput}-description`;
  const idDetails = `${idInput}-details`;
  const idErrorMessage = `${idInput}-errormessage`;
  const idLabel = `${idInput}-label`;
  return { idDescription, idDetails, idErrorMessage, idLabel, idInput };
}

/**
 * This component acts as the basis for most of our input fields to provide consistent
 * styling and accessibility throughout our application.
 */
export const FormGroup = forwardRef(function FormGroup(
  {
    aside,
    children,
    dangerouslyHideA11yLabel,
    description,
    readOnly = false,
    errorText,
    extra,
    counter,
    hasFocus: hasFocusControlled,
    hasValue: hasValueControlled,
    id,
    $internalIsInputSelect = false,
    label,
    labelPlacement: labelPlacementProvided = 'inside',
    placeholder,
    name,
    onBlur,
    onChange,
    onFocus,
    onPaste,
    onClick,
    value,
    displayErrorMessage = true,
    hideLabel = false,
    containerRef,
    size: sizeProvided = 'md',
    maxWidth,
    ...props
  }: FormGroupProps,
  ref
) {
  const { labelPlacement, size } = useGetInputSizeAndLabelPlacement(
    sizeProvided,
    labelPlacementProvided
  );

  const inputRef = useProvidedRefOrCreate(ref);
  const labelWithFallback = label || placeholder || name;
  const placeholderWithFallback =
    placeholder || (typeof label === 'string' ? label : undefined) || name;

  const hasError = !!errorText;

  let hasValueFilled = false;
  if (typeof value === 'string') {
    hasValueFilled = !!value.length;
  } else if (Array.isArray(value)) {
    // Empty arrays and arrays with only falsy values should not count as filled
    hasValueFilled = !!value.length && !value.every((valueItem) => !valueItem);
  } else if (typeof value === 'number') {
    // handles value equals to typeof number and 0 or negative numbers
    hasValueFilled = !!value.toString();
  } else {
    hasValueFilled = !!value;
  }

  const hasValue = hasValueFilled || hasValueControlled;
  const isReadOnly = readOnly;

  const [hasInputFocus, setHasInputFocus] = useState(false);

  const handleOnChange: FormGroupProps['onChange'] = (event, meta) => {
    if (!readOnly) {
      onChange?.(event, meta);
    }
  };

  const handleOnFocus: FocusEventHandler = (event) => {
    if (!readOnly) {
      setHasInputFocus(true);
      onFocus?.(event);
    }
  };

  const handleOnBlur: FocusEventHandler = (event) => {
    if (!readOnly) {
      setHasInputFocus(false);
      onBlur?.(event);
    }
  };

  const handleOnPaste: ClipboardEventHandler = (event) => {
    if (!readOnly) {
      onPaste?.(event);
    }
  };

  const handleOnClick: MouseEventHandler = (event) => {
    // we only trigger the focus event when the menu is closed, otherwise it messes up the unit tests
    // @ts-expect-error react-select specific props and handling. Optional chaining prevents errors for other inputs
    if (!inputRef.current?.props?.menuIsOpen) {
      // @ts-expect-error
      inputRef.current?.focus();
    }

    if (isFunction(onClick)) {
      onClick(event);
    }
  };

  const { idDescription, idDetails, idErrorMessage, idLabel, idInput } = getFormGroupIds(
    id || name
  );

  const inputProps = {
    'aria-describedby': description ? idDescription : undefined,
    'aria-details': extra ? idDetails : undefined,
    'aria-errormessage': errorText ? idErrorMessage : undefined,
    'aria-invalid': !!errorText || undefined,
    'aria-labelledby': idLabel,
    ...(isReadOnly && { 'aria-readonly': true, readOnly: true }),
    id: idInput,
    label: labelWithFallback,
    labelPlacement,
    name,
    onBlur: handleOnBlur,
    onChange: handleOnChange,
    onFocus: handleOnFocus,
    onPaste: handleOnPaste,
    placeholder: hideLabel ? '' : placeholderWithFallback,
    setHasInputFocus,
    ref: inputRef,
    value,
    size,
    ...props,
  };

  const hasFocus = hasInputFocus || !!hasFocusControlled;

  const asideProps = {
    $hasError: hasError,
    $hasFocus: hasFocus,
    $hasValue: hasValue,
    $isReadOnly: isReadOnly,
  };

  const hasMeta = !!description || !!extra || (hasError && displayErrorMessage) || !!counter;

  const labelElement = !hideLabel && labelWithFallback && (
    <Label
      htmlFor={!dangerouslyHideA11yLabel ? idInput : undefined}
      aria-hidden={dangerouslyHideA11yLabel}
      id={idLabel}
      $size={size}
      $placement={labelPlacement}
      $isReadOnly={isReadOnly}
    >
      <LabelText
        $hasValue={hasValue}
        $hasFocus={hasFocus}
        $hasError={hasError}
        $size={size}
        $placement={labelPlacement}
      >
        {labelWithFallback}
      </LabelText>
    </Label>
  );

  return (
    <Stack>
      {labelPlacement === 'outside' ? (
        <LabelInputWrapper $size={size}>{labelElement}</LabelInputWrapper>
      ) : null}
      <Container ref={containerRef} maxWidth={maxWidth}>
        <Body
          $hasError={hasError}
          $hasFocus={hasFocus}
          $internalIsInputSelect={$internalIsInputSelect}
          $isReadOnly={isReadOnly}
          onClick={handleOnClick}
          $size={size}
        >
          <LabelInputWrapper $size={size}>
            {labelPlacement === 'inside' ? labelElement : null}
            <FormGroupProvider {...inputProps}>{children}</FormGroupProvider>
          </LabelInputWrapper>
          {isFunction(aside) && <Aside $size={size}>{aside(asideProps)}</Aside>}
        </Body>
        {hasMeta && (
          <MetaContainer>
            <Meta>
              {displayErrorMessage && hasError && (
                <ErrorMessage aria-live="assertive" id={idErrorMessage} data-testid="error-message">
                  {errorText}
                </ErrorMessage>
              )}
              {description && (
                <>
                  {hasError && <MetaSeparator>-</MetaSeparator>}
                  <Description id={idDescription}>{description}</Description>
                </>
              )}
              {extra && <Details id={idDetails}>{extra}</Details>}
            </Meta>
            {counter && <Meta>{counter}</Meta>}
          </MetaContainer>
        )}
      </Container>
    </Stack>
  );
});
