import { useFormGroupContext, InputSelectComponents as selectComponents } from '@remote-com/norma';
import isFunction from 'lodash/isFunction';
import { useState, useMemo } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { defaultTransformCreatedOption } from '@/src/components/Ui/Form/inputs/inputSelectAsyncCreatable/utils';

const getCustomOption =
  (OptionComponent) =>
  ({ innerProps, ...restProps }) => {
    return isFunction(OptionComponent) ? (
      <selectComponents.Option {...restProps} innerProps={innerProps}>
        <OptionComponent {...restProps} />
      </selectComponents.Option>
    ) : (
      <selectComponents.Option {...restProps} innerProps={innerProps} />
    );
  };

export function AsyncInputAndCreateElement({
  components,
  styles,
  identifiers,
  isClearable,
  isMenuOpen,
  handleOnFocus,
  handleOnBlur: passedOnBlur,
  handleOnInputChange: passedOnInputChange,
  handleOnMenuClose,
  handleOnMenuOpen,
  menuRef,
  options = [],
  onCreateOption,
  forcePlaceholder,
  ...props
}) {
  const {
    id,
    ref,
    handleOnBlur: formGroupOnBlur,
    onInputChange: formGroupOnInputChange,
    multiple,
    transformCreatedOption = defaultTransformCreatedOption,
    ...inputProps
  } = useFormGroupContext();
  const { Option: CustomOptionComponent, ...customComponents } = components || {};
  const [customOption, setCustomOption] = useState(undefined);

  const privateOnBlur = (event) => {
    formGroupOnBlur?.(event);
    passedOnBlur?.(event);
  };

  // `onInputChange` can be passed both as a direct property to this component and via
  // the form group's context. We need to call both of them.
  const privateOnInputChange = (newValue, actionMeta) => {
    formGroupOnInputChange?.(newValue, actionMeta);
    passedOnInputChange?.(newValue, actionMeta);
  };

  const CustomOption = useMemo(
    () => getCustomOption(CustomOptionComponent),
    [CustomOptionComponent]
  );

  function createOption(inputOption) {
    setCustomOption(inputOption);

    if (multiple) {
      const { value = [] } = inputProps;
      inputProps.onChange?.([...value, inputOption]);
    } else {
      inputProps.onChange?.(inputOption);
    }
    onCreateOption?.([...options, inputOption]);
  }

  async function handleCreateOption(value) {
    createOption({ value: transformCreatedOption(value), label: value });
  }

  const showPlaceholder =
    forcePlaceholder || (inputProps.size === 'sm' && inputProps.labelPlacement === 'outside');

  return (
    <AsyncCreatableSelect
      components={{
        ...selectComponents,
        Option: CustomOption,
        ...customComponents,
      }}
      showPlaceholder={showPlaceholder}
      isClearable={isClearable}
      isDisabled={inputProps.readOnly}
      {...identifiers}
      menuIsOpen={isMenuOpen}
      onMenuClose={handleOnMenuClose}
      onMenuOpen={handleOnMenuOpen}
      defaultOptions={[...options, customOption].filter(Boolean)}
      ref={ref}
      aria-label={inputProps.label}
      {...props}
      {...inputProps}
      onFocus={handleOnFocus}
      onBlur={privateOnBlur}
      onInputChange={privateOnInputChange}
      tabSelectsValue
      menuRef={menuRef}
      isMulti={multiple}
      onCreateOption={handleCreateOption}
      styles={styles}
    />
  );
}
