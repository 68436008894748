import styled, { css } from 'styled-components';

import { Button } from '../button';
import { focusRingBorderCSS, focusVisible } from '../common.styled';
import { DropdownMenu } from '../dropdown-menu';

import type { CopyToClipboardProps } from './CopyToClipboard';

export const IconSvg = styled.svg`
  fill: ${(props) => props.fill};
  width: 20px;
  margin-left: ${({ theme }) => theme.space[3]}px;
  flex-shrink: 0;
  padding: 6px;
`;

export const CopyToClipboardButton = styled(Button).attrs((props) => ({
  ...props,
  variant: props.variant || 'raw',
}))<{
  $styles: CopyToClipboardProps['$styles'];
  $iconPosition: CopyToClipboardProps['iconPosition'];
}>`
  align-items: center;
  border-radius: 50px;
  border: 2px solid transparent;
  cursor: pointer;
  color: inherit;
  display: inline-flex;
  font-weight: inherit;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 5px;
  text-align: left;
  text-decoration: none;
  transition: 0.3s all;
  ${({ $styles }) => $styles}

  ${({ theme, $iconPosition }) => {
    switch ($iconPosition) {
      case 'left':
        return css`
          flex-direction: row-reverse;

          ${IconSvg} {
            margin-left: 0;
            margin-right: ${theme.space[3]}px;
          }
        `;
      default:
        return '';
    }
  }}
  ${focusVisible(css`
    /* Disables Button focus styles, focus styles set on svg element */
    border-color: transparent;
    box-shadow: none;

    ${IconSvg} {
      border-radius: ${({ theme }) => theme.borderRadius.full};
      overflow: visible; /* force the icon to be visible outside */
      ${({ theme }) =>
        css`
          ${focusRingBorderCSS(theme.colors.brand[300])};
          outline: 2px solid ${theme.colors.brand[300]};
        `}
    }
  `)};
`;

export const StyledMenuItem = styled(DropdownMenu.Item)`
  display: block;
`;
