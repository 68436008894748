import styled, { css } from 'styled-components';

import { Box } from '../../foundations/layout/Box';
import { Stack } from '../../foundations/layout/Stack';
import { IconCalendar } from '../../icons/build/IconCalendar';
import { LegacyInputStyle } from '../legacy-input-style';

export const DatePickerStyledWrapper = styled(Box)<{ $isExpanded?: boolean }>`
  ${({ $isExpanded, theme }) => css`
    --right-border-radius: ${$isExpanded ? '0' : theme.space[4]}px;

    @media screen and (max-width: ${theme.breakpoints.medium}) {
      --right-border-radius: ${theme.space[4]}px;
    }
  `}

  position: relative;

  .react-datepicker-popper {
    /*
    NOTE: This creates a new stacking context for the popper container to prevent
    other elements from being displayed on top of the calendar UI.
    */
    isolation: isolate;
    z-index: 1;
  }

  .react-datepicker__close-icon {
    appearance: none;
    background: ${({ theme }) => theme.colors.grey[200]};
    border: 0;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus-visible {
      border-color: ${({ theme }) => theme.colors.grey[500]};
      box-shadow: rgba(105, 119, 134, 0.5) 0px 0px 0px 3px;
      outline: ${({ theme }) => theme.colors.grey[500]} solid 2px;
      outline-offset: -1px;
    }

    &:before {
      content: '';
      display: block;
      /*
        NOTE: react-datepicker doesn't support overriding the clear button
        This is the same svg used by react-select for clearable fields
      */
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="${({
        theme,
      }) =>
        encodeURIComponent(
          theme.colors.grey[800]
        )}" d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>');
      background-size: 12px 12px;
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }
  }
`;

export const DatePickerStyledInput = styled.input`
  ${LegacyInputStyle}
  padding-left: 35px;
`;

export const DatePickerStyledIcon = styled(IconCalendar)`
  ${({ theme }) => css`
    fill: ${theme.colors.bayoux};
    pointer-events: none;
    position: absolute;
    left: 2px;
    top: 0px;
    width: 20px;
    transition: all 0.2s;

    ${DatePickerStyledWrapper}:focus-within & {
      fill: ${theme.colors.brand};
    }
  `}
`;

type DatePickerFooterProps = {
  $hasChildren?: boolean;
};

export const DatePickerFooter = styled(Stack).attrs({
  as: 'footer',
})<DatePickerFooterProps>`
  ${({ $hasChildren, theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${theme.space[5]}px;

    margin: ${$hasChildren ? `${theme.space[6]}px` : '0'} 0 0 0;
    border-top: 1px solid ${theme.colors.grey[200]};
    border-radius: 0 0 var(--right-border-radius) ${theme.borderRadius.md};
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);

    height: var(--date-picker-footer-height);
    background-color: var(--colors-blank);
  `}
`;

export const DatePickerDayContents = styled.span`
  position: relative;
  display: block;
  z-index: var(--date-picker-z-index-date);
`;
