import capitalize from 'lodash/capitalize';

import {
  isSmartFieldAvailable,
  isSmartFieldPlaceholder,
} from '@/src/domains/contracts/ContractsEditor/helpers';

import {
  INLINE_CON_REGEX,
  INLINE_CON_CONTENT_REGEX,
  CON_END_REGEX,
  CON_CONDITION_REGEX,
  LIST_ITEM_NODE_TYPES,
  CONDITIONAL_NODE_TYPES,
  CONDITIONAL_OPERATORS,
  CON_LIST_VALUE_TYPES,
  ROMAN_NUMERALS,
} from '../../constants';

// https://prosemirror.net/docs/ref/version/0.18.0.html#model.ParseRule.priority
// Can be used to change the order in which the parse rules in a schema are tried.
// Those with higher priority come first. Rules without a priority are counted as having priority 50.
export const PARSE_HTML_PRIORITY_HIGHEST = 100;

// converts a number from a list to its lowercase roman
// numeral equivalent. This is used when determining
// the position of a list item and its accompanying label
// (i.e. 2.b.iv.)
// This function works right to left for a given number, and
// does not work with numbers over 4 digits long
export const convertNumberToRomanNumeral = (number) => {
  if (!number || Number.isNaN(number)) {
    return '';
  }

  const digits = String(number).split('');
  let result = '';
  // This index selects the last digit of the number
  // and works right to left for a given number
  let idx = 3;

  while (idx--) {
    const numeral = idx * 10;
    const pos = parseInt(digits.pop(), 10);

    if (ROMAN_NUMERALS[pos + numeral]) {
      result = `${ROMAN_NUMERALS[pos + numeral]}${result}`;
    }
  }

  return result.toLowerCase();
};

// converts a number from a list to its lowercase alpha
// char equivalent. This is used when determining
// the position of a list item and its accompanying label
// (i.e. 2.b.)
const convertNumberToAlpha = (number) => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  return alphabet[number];
};

// When there is a smartfield inside a node, ProseMirror replaces the node in the
// textContent property with this charCode. This function removes that from the
// textContent so that we can compare a node's content with the matched input
export const replaceSmartFieldChar = (str) => str.replaceAll(String.fromCharCode(65532), '');

// Recursively searches up the ProseMirror document tree to find
// the first instance of a nodeType until it reaches the root of the
// document. For example: findParentNodeOfType(currentNode, ['listItem'])
// will find the first listItem parent and return the resolved
// reference for that node. If no node of the specified type is found
// before reaching the root of the document,then null is returned
export const findParentNodeOfType = (resolvedNode, nodeTypes) => {
  let currentNode = resolvedNode;
  let parentNode = null;

  while (!parentNode && currentNode.parent?.type.name !== 'doc') {
    const prevNode = resolvedNode.doc.resolve(currentNode.before());

    if (nodeTypes.includes(currentNode.parent?.type.name)) {
      parentNode = prevNode;
    } else {
      currentNode = prevNode;
    }
  }

  return parentNode;
};

export const determineConditionalType = (matchedResolvedPos, match) => {
  // Using a depth of 1 gets the first parent node that's at
  // the root of the doc
  const rootParentNode = matchedResolvedPos.node(1);
  const isInList = findParentNodeOfType(matchedResolvedPos, LIST_ITEM_NODE_TYPES);

  // If the matched node is nested in another node that's not the root
  // we need to determine if it's a nested list item or just an inline node
  if (rootParentNode && isInList) {
    // When a list item begins with the conditional character, it's considered a list item
    // Not beginning with this mark means that the content is surrounded by text or other content
    // and should be considered an inline node
    return match.input[0] === '«'
      ? CONDITIONAL_NODE_TYPES.LIST_ITEM
      : CONDITIONAL_NODE_TYPES.INLINE;
  }

  if (match.input.search(INLINE_CON_REGEX) >= 0) {
    return CONDITIONAL_NODE_TYPES.INLINE;
  }

  return CONDITIONAL_NODE_TYPES.BLOCK;
};

export const nodeContainsMatchText = (node, match) => {
  return (
    node?.type.name !== 'doc' &&
    node?.textContent?.length &&
    node.textContent.includes(replaceSmartFieldChar(match.input))
  );
};

// This function will find the resolved position of the
// node included in the matched input for the conditional.
// Because we are replacing transactions with our own elements,
// the shape of the document can shift between transactions, and
// the range is not always accurate
export const findConditionalNode = (state, range, match) => {
  const { doc } = state;
  let matchedResolvedPos = doc.resolve(range.from);
  let matchedNode = doc.nodeAt(matchedResolvedPos.pos);
  const parentNode = matchedResolvedPos.parent;

  const matchedNodeHasContent = matchedNode?.textContent?.length > 0;
  const matchedNodeIsValid =
    nodeContainsMatchText(matchedNode, match) || nodeContainsMatchText(parentNode, match);

  if (matchedNodeHasContent && matchedNodeIsValid) {
    return { matchedResolvedPos, matchedNode };
  }

  let resolvedNodes = false;
  doc.descendants((node, pos) => {
    if (node.isText && nodeContainsMatchText(node, match) && !resolvedNodes) {
      matchedResolvedPos = doc.resolve(pos);
      matchedNode = node;
      resolvedNodes = true;
    } else if (
      node.type.name === 'paragraph' &&
      nodeContainsMatchText(node, match) &&
      !resolvedNodes
    ) {
      // When there are multiple list item nodes that are being pasted in, ProseMirror
      // can lose track of the positions of some of these nodes because the document changes
      // between each transaction. When this happens, we have to be a bit more specific
      // about the ndoe that we're looking for

      // The +1 here will get the first child after the paragraph
      matchedResolvedPos = doc.resolve(pos + 1);
      matchedNode = doc.nodeAt(pos + 1);
      resolvedNodes = true;
    }
  });

  return { matchedResolvedPos, matchedNode };
};

// Searches up the tree of nodes to determine the number of nested
// lists. This is then used when assigning labels for the conditional
// list items
export const findNumberOfLists = (resolvedNode) => {
  let currentNode = resolvedNode;
  let numberOfLists = 0;

  while (currentNode.parent?.type.name !== 'doc') {
    const prevNode = resolvedNode.doc.resolve(currentNode.before());

    if (LIST_ITEM_NODE_TYPES.includes(currentNode.parent?.type.name)) {
      numberOfLists++;
    }

    currentNode = prevNode;
  }

  return numberOfLists;
};

// Uses the current node and ancestor node to determine a list item's position
// in a list relative to its siblings. This is so that we can show what the missing
// list item value would be in the event that the conditional result is false
// When determining the value, we always follow the pattern number => alpha =>
// roman => recurse
export const determineListItemNodePos = (ancestorNode, currentNode, match) => {
  const numberOfLists = findNumberOfLists(currentNode);
  // If the ancestor is an ordered list, we only offset by 1 when determining the
  // value of the list item
  const ancestorListModifier = ancestorNode?.type.name === 'orderedList' ? 1 : 2;

  if (numberOfLists > 1) {
    const listPath = [];
    let remainingLists = numberOfLists;

    // Subtract 2 to account for parent paragraph and list item nodes
    for (let currentDepth = currentNode.depth - 2; currentDepth > 0; currentDepth -= 2) {
      const parentNode = currentNode.node(currentDepth);
      const posInList = parentNode.content.content.findIndex((node) =>
        nodeContainsMatchText(node, match)
      );

      if (parentNode.type.name === 'orderedList') {
        const type =
          remainingLists > 4
            ? CON_LIST_VALUE_TYPES[
                remainingLists - CON_LIST_VALUE_TYPES.length - ancestorListModifier
              ]
            : CON_LIST_VALUE_TYPES[remainingLists - ancestorListModifier];

        switch (type) {
          case 'alpha':
            listPath.push(convertNumberToAlpha(posInList));
            break;
          case 'roman':
            listPath.push(convertNumberToRomanNumeral(posInList + 1));
            break;
          case 'number':
          default:
            listPath.push(posInList + 1);
            break;
        }

        remainingLists--;
      }
    }

    return listPath.reduce((marker, value) => `${value}.${marker}`, '');
  }

  const parentNode = currentNode.node(currentNode.depth - 2);
  const posInList = parentNode.content.content.findIndex((node) =>
    nodeContainsMatchText(node, match)
  );

  return `${posInList + 1}.`;
};

export const isBlockEndConNode = (node) => {
  const hasEndCondition = node.textContent.search(CON_END_REGEX) >= 0;
  const isNotInline = node.textContent.search(INLINE_CON_REGEX) < 0;

  return hasEndCondition && isNotInline;
};

export const getFriendlyOperator = (operator) => {
  if (!operator) {
    return '';
  }

  switch (operator) {
    case CONDITIONAL_OPERATORS.EQUAL:
      return `equals`;
    case CONDITIONAL_OPERATORS.NOT_EQUAL:
      return 'does not equal';
    case CONDITIONAL_OPERATORS.EXISTS:
      return 'exists';
    case CONDITIONAL_OPERATORS.NOT_EXISTS:
      return 'does not exist';
    case CONDITIONAL_OPERATORS.GREATER_THAN:
      return 'greater than';
    case CONDITIONAL_OPERATORS.LESS_THAN:
      return 'less than';
    case CONDITIONAL_OPERATORS.BEFORE:
      return 'before';
    case CONDITIONAL_OPERATORS.AFTER:
      return 'after';
    case CONDITIONAL_OPERATORS.IN:
      return 'one of';
    case CONDITIONAL_OPERATORS.NOT_IN:
      return 'not one of';
    default:
      return operator.toLowerCase();
  }
};

export const operatorOptions = Object.values(CONDITIONAL_OPERATORS).map((operator) => {
  const friendlyOperator = getFriendlyOperator(operator);
  const label = capitalize(friendlyOperator);

  return {
    value: operator,
    label,
  };
});

export const findOperatorOption = (operator) =>
  operatorOptions.find((option) => option.value === operator);

export const getNumberValue = (fieldValue) => {
  return parseFloat(fieldValue?.replace(/[^\d.-]/g, ''));
};

// Finds nodes between the start and end of a range that
// have text content or are other node types (i.e. nested smart fields or
// marked text)
const findNestedContentBetween = (state, range) => {
  const { doc } = state;
  const includedNodeTypes = ['paragraph', 'blockConditionalNode', 'listItemConditionalNode'];

  const content = [];

  let hasFoundStartNode = false;
  let hasFoundEndNode = false;

  doc.nodesBetween(range.from, range.to, (node) => {
    const hasStartText = node.textContent?.match(CON_CONDITION_REGEX);
    const hasEndText = node.textContent?.match(CON_END_REGEX);

    const remainingRegex = /»(.*)/;
    const predicateRegex = /(.*?)«END_CON»/;

    if (node.isText && hasStartText && !hasFoundStartNode) {
      const [, textContent] = node.textContent.match(remainingRegex);
      content.push(state.schema.text(textContent));
      hasFoundStartNode = true;
    }

    if (node.isText && hasEndText && !hasFoundEndNode) {
      const [, textContent] = node.textContent.match(predicateRegex);

      if (textContent.length) {
        content.push(state.schema.text(textContent));
      }

      hasFoundEndNode = true;
    }

    // Captures nodes of other types like smart fields or additional text with marks
    if (!includedNodeTypes.includes(node.type.name) && !hasStartText && !hasEndText) {
      content.push(node);
    }
  });

  return content;
};

// Finds nodes within a parent node's descendants that
// have text content or are other node types (i.e. nested smart fields or
// marked text)
const findNestedContentInDescendants = (state, parentNode) => {
  const includedNodeTypes = ['paragraph', 'blockConditionalNode', 'listItemConditionalNode'];

  const content = [];

  let hasFoundStartNode = false;
  let hasFoundEndNode = false;

  parentNode.descendants((node) => {
    const hasStartText = node.textContent?.match(CON_CONDITION_REGEX);
    const hasEndText = node.textContent?.match(CON_END_REGEX);

    const remainingRegex = /»(.*)/;
    const predicateRegex = /(.*?)«END_CON»/;

    if (node.isText && hasStartText && !hasFoundStartNode) {
      const [, textContent] = node.textContent.match(remainingRegex);

      if (textContent.length) {
        content.push(state.schema.text(textContent));
      }

      hasFoundStartNode = true;
    }

    if (node.isText && hasEndText && !hasFoundEndNode) {
      const [, textContent] = node.textContent.match(predicateRegex);

      if (textContent.length) {
        content.push(state.schema.text(textContent));
      }

      hasFoundEndNode = true;
    }

    // Captures nodes of other types like smart fields or additional text with marks
    if (!includedNodeTypes.includes(node.type.name) && !hasStartText && !hasEndText) {
      content.push(node);
    }
  });

  return content;
};

const buildConAttributes = (conditional, conditionalType, editor) => {
  const fields = conditional
    .split('__')
    .filter((cond) => !Object.keys(CONDITIONAL_OPERATORS).includes(cond));

  const [operator] = conditional
    .split('__')
    .filter((cond) => Object.keys(CONDITIONAL_OPERATORS).includes(cond));

  const isValidSmartField =
    editor && fields.length > 0
      ? isSmartFieldAvailable(fields[0], editor.storage.smartFieldDefinitions)
      : false;

  const smartFieldIsPlaceholder =
    editor && fields.length > 0
      ? isSmartFieldPlaceholder(
          fields[0],
          editor.storage.smartFieldDefinitions,
          editor.storage.placeholderSmartFields
        )
      : false;

  return {
    conditional,
    fields,
    operator,
    conditionalType,
    isSmartFieldAvailable: isValidSmartField,
    isSmartFieldPlaceholder: smartFieldIsPlaceholder,
  };
};

export const inlineConHandler =
  (editor) =>
  ({ state, range, match }) => {
    const { tr } = state;
    const { matchedResolvedPos, matchedNode } = findConditionalNode(state, range, match);
    const matchedText = match[0];

    const conditionalType = determineConditionalType(matchedResolvedPos, match);

    if (conditionalType !== CONDITIONAL_NODE_TYPES.INLINE) {
      // Returning true tells ProseMirror to continue processing other nodes and
      // regex matches. Returning a falsy value here will stop processing additional
      // nodes and cause odd side effects
      return true;
    }

    const startText = matchedNode?.textContent.match(CON_CONDITION_REGEX);
    const endText = matchedNode?.textContent.match(CON_END_REGEX);

    const [, conditional] = startText;
    const attributes = buildConAttributes(conditional, conditionalType, editor);

    // if the matched node contains both the start and end conditional
    // text inside the content, then there are no nested content nodes and
    // we can just use the content from the matched node to create the conditional
    if (startText && endText) {
      const [, textContent] = matchedText.match(INLINE_CON_CONTENT_REGEX);
      const content = [state.schema.text(textContent)];

      return tr.replaceWith(
        range.from,
        range.to,
        state.schema.nodes.inlineConditionalNode.create(attributes, content)
      );
    }

    const content = findNestedContentBetween(state, range);

    return tr.replaceWith(
      range.from,
      range.to,
      state.schema.nodes.inlineConditionalNode.create(attributes, content)
    );
  };

const findListItemNodeContent = (state, parentListNode, listPosition, match, editor) => {
  // A list of nodes where we want to ignore recursive parsing of their children - this prevents
  // duplication of node processing
  const nonRecursiveNodes = [
    'orderedList',
    'unorderedList',
    'bulletList',
    'table',
    'listItem',
    'listItemConditionalNode',
  ];
  const matchedText = match[0];
  const content = [];

  parentListNode.descendants((node) => {
    const trimmedInput = replaceSmartFieldChar(match.input);
    const nodeHasNestedContent =
      node.firstChild?.childCount > 1 && node.textContent.includes(trimmedInput);
    const nodeIncludesInputText = node.textContent.includes(match.input);

    const [, conditional] = matchedText.match(CON_CONDITION_REGEX);
    const attributes = {
      ...buildConAttributes(conditional, CONDITIONAL_NODE_TYPES.LIST_ITEM, editor),
      listPosition,
    };

    if (nodeHasNestedContent) {
      const nestedContent = findNestedContentInDescendants(state, node.firstChild);
      const conditionalNode = state.schema.nodes.listItemConditionalNode.create(attributes, [
        state.schema.nodes.paragraph.create({}, nestedContent),
        ...node.content.content.slice(1),
      ]);

      content.push(conditionalNode);
    }

    if (!nodeHasNestedContent && nodeIncludesInputText) {
      const [, textContent] = node.textContent.match(/»(.*?)«END_CON»/);
      const conditionalNode = state.schema.nodes.listItemConditionalNode.create(attributes, [
        state.schema.nodes.paragraph.create({}, state.schema.text(textContent)),
        ...node.content.content.slice(1),
      ]);

      content.push(conditionalNode);
    }

    if (!nodeHasNestedContent && !nodeIncludesInputText) {
      content.push(node);
    }

    if (nonRecursiveNodes.includes(node.type.name)) {
      // Note - returning false from this function tells ProseMirror
      // to skip recursing through child nodes of the current node
      return false;
    }

    return true;
  });

  return content;
};

export const listItemConHandler =
  (editor) =>
  ({ state, range, match }) => {
    const { doc, tr } = state;
    const { matchedResolvedPos } = findConditionalNode(state, range, match);
    const conditionalType = determineConditionalType(matchedResolvedPos, match);

    if (conditionalType !== CONDITIONAL_NODE_TYPES.LIST_ITEM) {
      // Returning true tells ProseMirror to continue processing other nodes and
      // regex matches. Returning a falsy value here will stop processing additional
      // nodes and cause odd side effects
      return true;
    }

    // A depth of > 3 accounts for doc (0), rootList (1), listItem(2)
    // and paragraph (3). Anything greater than this means the matched input
    // nested in another list
    const resolvedParent = findParentNodeOfType(matchedResolvedPos, LIST_ITEM_NODE_TYPES);
    const parentListNode = doc.nodeAt(resolvedParent.pos);
    const resolvedParentAncestor = findParentNodeOfType(resolvedParent, LIST_ITEM_NODE_TYPES);
    const parentAncestorNode = doc.nodeAt(resolvedParentAncestor?.pos || 0);
    const listPosition = determineListItemNodePos(parentAncestorNode, matchedResolvedPos, match);

    const content = findListItemNodeContent(state, parentListNode, listPosition, match, editor);

    return tr.replaceWith(
      resolvedParent.pos,
      resolvedParent.pos + parentListNode.nodeSize,
      state.schema.nodes.orderedList.create({ ...parentListNode.attrs }, content)
    );
  };

export const blockConHandler =
  (editor) =>
  ({ state, range, match }) => {
    const { doc, tr } = state;
    const { matchedResolvedPos } = findConditionalNode(state, range, match);

    const conditionalType = determineConditionalType(matchedResolvedPos, match);

    if (conditionalType !== CONDITIONAL_NODE_TYPES.BLOCK) {
      // Returning true tells ProseMirror to continue processing other nodes and
      // regex matches. Returning a falsy value here will stop processing additional
      // nodes and cause odd side effects
      return true;
    }

    const matchedText = match[0];
    const nonRecursiveNodes = [
      'orderedList',
      'unorderedList',
      'bulletList',
      'table',
      'inlineConditionalNode',
    ];

    const [, conditional] = matchedText.match(CON_CONDITION_REGEX);
    const attributes = buildConAttributes(conditional, CONDITIONAL_NODE_TYPES.BLOCK, editor);

    // +2 here accounts for node start and end tokens
    const nextNodePos = range.to + 2;
    const content = [];
    let endPos = 0;

    // Note - returning false from this function tells ProseMirror
    // to skip recursing through child nodes of the current node
    // Search through all nodes in the document between the start position
    // and the end of the document to find the block end ndoe
    doc.nodesBetween(nextNodePos, doc?.content?.size, (node, start) => {
      const hasFoundEndNode = endPos > 0;
      if (hasFoundEndNode || !node.firstChild) {
        return false;
      }

      if (isBlockEndConNode(node) && endPos === 0) {
        endPos = start + node.content?.size || 0;
        return null;
      }

      if (node.type.name !== 'inlineConditionalNode') {
        // Specifically exclude any processing of inline nodes since those
        // are handled by the inline node extension
        content.push(node);
      }

      if (nonRecursiveNodes.includes(node.type.name)) {
        return false;
      }

      return true;
    });

    return tr.replaceWith(
      range.from,
      endPos + 2,
      state.schema.nodes.blockConditionalNode.create(attributes, content)
    );
  };

export const getOperatorOptions = Object.values(CONDITIONAL_OPERATORS).map((operator) => ({
  value: operator,
  label: getFriendlyOperator(operator),
}));

export const findParentListItem = (selection) => {
  const { $from } = selection;
  const ancestorIdx = $from.index() - 1;
  const ancestorNode = $from.node(ancestorIdx);
  const ancestorPos = $from.posAtIndex(ancestorIdx, $from.depth);

  if (!ancestorNode || !ancestorPos) {
    return null;
  }

  const endPosition = ancestorNode.nodeSize >= 2 ? ancestorNode.nodeSize - 2 : 0;

  return { node: ancestorNode, from: ancestorPos, to: ancestorPos + endPosition };
};
