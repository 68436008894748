import { toast } from '@remote-com/norma';
import type { ReactNodeLike } from 'prop-types';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';
import { composeFieldText } from '@/src/components/Form/DynamicForm/composeFields';
import { DynamicFormModal } from '@/src/components/Modal/DynamicFormModal';
import {
  buildTableView,
  getTableViewErrorInfo,
} from '@/src/components/Table/helpers/tableViewHelpers';
import type { ColumnsConfigurationVisibility } from '@/src/components/Table/hooks/useColumnsState/types';
import { useCreateTableView } from '@/src/components/Table/hooks/useCreateTableView';
import { useUpdateTableView } from '@/src/components/Table/hooks/useUpdateTableView';
import type { SortBy } from '@/src/components/Table/types';
import { baseString } from '@/src/helpers/validationSchema';

type ModalFormikProps = {
  name: string;
};

type SetTableViewNameModalProps = {
  hideModal: () => void;
  setListenToClickOutside: (listen: boolean) => void;
  applyView: (view: TableViewsApi.TableView) => void;
  messages: {
    title: string;
  };
  persistenceKey: string;
  filters: TableViewsApi.TransformedFilter[];
  order: string[];
  visibility: ColumnsConfigurationVisibility;
  sortBy: SortBy[];
  contentBeforeForm?: ReactNodeLike;
  slug?: string;
  name?: string;
};

export const SetTableViewNameModal = ({
  hideModal,
  setListenToClickOutside,
  applyView,
  persistenceKey,
  filters,
  order,
  visibility,
  sortBy,
  messages,
  contentBeforeForm,
  slug,
  name,
}: SetTableViewNameModalProps) => {
  const { createTableView, isLoading: isCreatingTableView } = useCreateTableView();
  const { updateTableView, isLoading: isUpdatingTableView } = useUpdateTableView();

  function closeModal() {
    setListenToClickOutside(true);
    hideModal();
  }

  async function handleSubmit(
    values: ModalFormikProps,
    { setFieldError }: { setFieldError: (field: string, message: string) => void }
  ) {
    const tableView = buildTableView({
      name: values.name,
      columnsConfiguration: { name: values.name, customizations: { order, visibility } },
      persistenceKey,
      filters,
      sortBy,
    });

    const onSuccess = (view?: TableViewsApi.TableView) => {
      closeModal();
      if (view) {
        applyView(view);
      }
      toast.success('Table view saved.');
    };

    const onError = (exception: unknown) => {
      setFieldError('name', getTableViewErrorInfo(exception));
      toast.error('Failed to save table view.');
    };

    if (slug) {
      return updateTableView({ tableView, tableViewSlug: slug, onSuccess, onError });
    }

    return createTableView({ tableView, onSuccess, onError });
  }

  const fields = [
    composeFieldText({
      name: 'name',
      label: 'Name of the table view',
      required: false,
      ...(name && { value: name }),
      schema: baseString.required('Please enter a name for this table view'),
    }),
  ];

  return (
    <DynamicFormModal
      headerTitle={messages.title}
      onSubmit={handleSubmit}
      onCancel={closeModal}
      formName="table-views-rename"
      fields={fields}
      TODO_MIGRATE_handleOnSubmit="DONE"
      showErrorSummary={false}
      contentBeforeForm={contentBeforeForm}
      confirmLoading={isCreatingTableView || isUpdatingTableView}
    />
  );
};
