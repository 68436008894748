// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineGift = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M8.5 2.75a1.75 1.75 0 1 0 0 3.5H11l-.67-2.2a1.83 1.83 0 0 0-1.75-1.3H8.5Zm2.75 5v2.5H4a1.25 1.25 0 1 1 0-2.5h7.25Zm-5.49-1.5a3.25 3.25 0 0 1 2.74-5h.08c1.47 0 2.77.96 3.19 2.37l.23.77.23-.77a3.33 3.33 0 0 1 3.19-2.37h.08a3.25 3.25 0 0 1 2.74 5H20a2.75 2.75 0 0 1 .75 5.4v5.58c0 .82 0 1.47-.04 2a3.84 3.84 0 0 1-.37 1.47c-.36.7-.93 1.28-1.64 1.64-.44.23-.92.32-1.47.37-.53.04-1.18.04-2 .04H8.77c-.81 0-1.47 0-2-.04a3.84 3.84 0 0 1-1.47-.37 3.75 3.75 0 0 1-1.64-1.64 3.85 3.85 0 0 1-.37-1.47c-.04-.53-.04-1.19-.04-2v-5.58A2.75 2.75 0 0 1 4 6.25h1.76Zm-1.01 5.5v5.45c0 .85 0 1.45.04 1.9.04.46.1.72.2.92.22.42.57.77.99.98.2.1.46.17.91.21.46.04 1.06.04 1.91.04h2.45v-9.5h-6.5Zm8 0v9.5h2.45c.85 0 1.45 0 1.9-.04.46-.04.72-.1.92-.2.42-.22.77-.57.98-.99.1-.2.17-.46.21-.91.04-.46.04-1.06.04-1.91v-5.45h-6.5Zm7.25-1.5a1.25 1.25 0 1 0 0-2.5h-7.25v2.5H20Zm-4.5-4a1.75 1.75 0 1 0 0-3.5h-.08c-.8 0-1.52.53-1.75 1.3L13 6.25h2.49Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineGift);
export { ForwardRef as IconV2OutlineGift };