import type { ReactNode } from 'react';
import { Children } from 'react';
import type { CSSProperties } from 'styled-components';

export const areChildrenText = (children: ReactNode): boolean => {
  let allText = true;

  Children.forEach(children, (child) => {
    if (typeof child !== 'string' && typeof child !== 'number') {
      allText = false;
    }
  });

  return allText;
};

export type MenuProps = {
  /** Optionally used for testing purposes */
  dataTestid?: string;
  /** Can be used to alter the styling of the menu list */
  menuListStyle?: CSSProperties;
};
