import mergeRefs from 'merge-refs';
import { transparentize } from 'polished';
import type { MenuProps } from 'react-select';
import styled from 'styled-components';

import { Box } from '../../../layout';
import type { $TSFixMe } from '../../../types';

const StyledMenu = styled(Box).attrs(({ $isInsidePortal }: { $isInsidePortal: boolean }) => ({
  mt: 2,
  mx: $isInsidePortal && -5,
}))<{ $isInsidePortal: boolean } & $TSFixMe>`
  --field-select-menu-border-radius: 11px;
  --field-select-menu-item-height: 44px;

  background: ${({ theme }) => theme.colors.blank};
  border-radius: var(--field-select-menu-border-radius);
  border: 1px solid ${({ theme }) => theme.colors.grey[400]};
  box-shadow: 0 5px 15px 0 ${({ theme }) => transparentize(0.9, theme.colors.grey[900])};
  left: 0;
  overflow: hidden;
  background: white;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
`;

export function Menu({
  children,
  innerProps,
  innerRef,
  selectProps: {
    menuPortalTarget,
    // @ts-expect-error menuRef is passed in as non-standard prop to react-select in packages/norma/src/forms/input-select/InputElement.tsx
    menuRef,
  },
}: MenuProps) {
  const mergedMenuRef = mergeRefs(innerRef, menuRef);

  return (
    <StyledMenu {...innerProps} ref={mergedMenuRef} $isInsidePortal={!!menuPortalTarget}>
      {children}
    </StyledMenu>
  );
}
