import { MINUTES_IN_HOUR } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/constants';
import { simplifyTimeAmounts } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/simplifyTimeAmounts';
/**
 * Convert time or duration to hours
 * @param {{hours?: number, minutes?: number}} time time
 * @returns {number} the time or duration as hours, rounded to 1 decimal
 */

export function getAsHours(time) {
  const simplifiedTime = simplifyTimeAmounts(time);
  const hours = simplifiedTime.hours ?? 0;
  const minutes = simplifiedTime.minutes ?? 0;

  const fractionHours = minutes / MINUTES_IN_HOUR;

  return Math.round((hours + fractionHours) * 10) / 10;
}
