import { addYears, endOfMonth } from 'date-fns';

import { formatMonthDayYear } from '@/src/helpers/date';

export const getFormattedEndOfJanuaryNextYear = (today = new Date()) => {
  const nextYear = addYears(today, 1).getFullYear();
  const endOfJanuaryNextYear = endOfMonth(new Date(nextYear, 0));

  return formatMonthDayYear(endOfJanuaryNextYear, {
    isSourceInUtc: false,
    formatInLocalTime: true,
  });
};

export const ellipsizeFileName = (fileName: string, maxLength: number): string => {
  const fileNameLength = fileName.length;

  if (fileNameLength <= maxLength) {
    return fileName;
  }

  const extensionStart = fileName.lastIndexOf('.');
  const extensionLength = fileNameLength - extensionStart;

  return `${fileName.substring(0, maxLength - extensionLength)}…${fileName.substring(
    // Plus one so that we do not show the dot right after the ellipsis:
    // not `….pdf`, but `…pdf`.
    extensionStart + 1
  )}`;
};
