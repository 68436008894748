import { Node, ReactNodeViewRenderer, PasteRule, mergeAttributes } from '@tiptap/react';

import { SignatureComponent } from '@/src/domains/contracts/ContractsEditor/nodes/SignatureField/SignatureComponent';

const COMPANY_SIGNATURE_TEXT_FORM = 'COMPANY_SIGNATURE';
const HIRE_SIGNATURE_TEXT_FORM = 'SIGNATURE';
const pasteRegex = /«(COMPANY_SIGNATURE|SIGNATURE)»/g;

export const SignatureField = Node.create({
  name: 'signatureField',
  group: 'block',
  // these three settings are important for the text in the signature
  // field to not be independently selectable or draggable
  selectable: false,
  draggable: false,
  isolating: true,

  addAttributes() {
    return {
      name: {
        default: null,
        rendered: false,
      },
      signatoryName: {
        default: null,
        rendered: false,
      },
      email: {
        default: null,
        rendered: false,
      },
      hasBeenSignedByRecipient: {
        default: false,
        rendered: true,
      },
      // Render and parse `isCompanySignature` when copying and pasting the signature
      // field as HTML, this will allow users to reliably transfer this block from
      // one editor window to another.
      isCompanySignature: {
        default: false,
        renderHTML: ({ isCompanySignature }) => ({
          'data-is-company-signature': isCompanySignature,
        }),
        parseHTML: (element) => element.getAttribute('data-is-company-signature') === 'true',
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(SignatureComponent);
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: pasteRegex,
        handler: ({ state, range, match }) => {
          const { tr } = state;
          const firstCapturedGroup = match[1];

          tr.replaceWith(
            range.from,
            range.to,
            this.type.create({
              isCompanySignature: firstCapturedGroup === COMPANY_SIGNATURE_TEXT_FORM,
            })
          );
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ 'data-type': this.name }, HTMLAttributes)];
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderText({ node }) {
    return `«${
      node.attrs?.isCompanySignature ? COMPANY_SIGNATURE_TEXT_FORM : HIRE_SIGNATURE_TEXT_FORM
    }»`;
  },
});
