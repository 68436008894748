import { Card, HeadingIcon, HTMLRendered, Stack, Text, InfoBlock } from '@remote-com/norma';
import { IconDollarSign } from '@remote-com/norma/icons/IconDollarSign';
import { GiftIllustration } from '@remote-com/norma/illustrations/GiftIllustration';
import isEqual from 'lodash/isEqual';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import styled from 'styled-components';

import { groupEmployerOptedOut } from '@/src/domains/benefits/constants';
import { GroupIcons } from '@/src/domains/benefits/employer/BenefitsSelection/GroupIcons';
import { groupHasSelection, friendlyBenefitPlanCost } from '@/src/domains/benefits/helpers';
import { TierCost } from '@/src/domains/benefits/shared/TierCost';

const TierCard = styled(Card)`
  box-shadow: none;
  padding: 16px;
`;

const Description = styled(Text)`
  display: inline;
`;

function changeOptedOutToNo(benefitTier) {
  return !benefitTier || benefitTier === 'opted_out' || !benefitTier.value
    ? 'no'
    : benefitTier.value;
}

const getCostDisclaimer = (displayCost) =>
  (displayCost ?? '').endsWith('+ variable costs')
    ? 'Some plans in this benefit package include premium costs based on the employee salary. These costs are not included in the listed monthly estimate.'
    : null;

export function LockedBenefits({ countryName, onChange, groups, benefitsValue }) {
  const hasMultipleGroups = groups?.length > 1;

  useEffect(() => {
    const result = groups.reduce((acc, group) => {
      // the json-schemas expect 'no' instead of 'opt_out'
      acc[snakeCase(group.name)] = changeOptedOutToNo(group.selectedBenefitTier);

      return acc;
    }, {});

    if (result && !isEqual(benefitsValue, result)) {
      onChange?.(result);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, hasMultipleGroups, benefitsValue]);

  return (
    <Card>
      <HeadingIcon
        Icon={IconDollarSign}
        color="darkTurquoise"
        text={
          <Text as="span" variant="baseMedium">
            Package
          </Text>
        }
      />
      <Text variant="sm" mt={3} mb={6}>
        You’re only able to set up one package configuration per country. All of your employees
        based in <strong>{countryName}</strong> are eligible for the same benefit package below.
      </Text>
      <Stack display="flex" gap={5}>
        {hasMultipleGroups &&
          groups.map((group) => (
            <TierCard key={group.slug} data-testid="tier-card">
              <Stack gap={3} direction="row" flexWrap="wrap">
                <Text variant="smMedium">
                  {!group?.selectedBenefitTier ||
                  group?.selectedBenefitTier === groupEmployerOptedOut
                    ? group.name
                    : group.selectedBenefitTier.name}
                </Text>
                {groupHasSelection(group) && (
                  <>
                    <GroupIcons group={group} />
                    {!group?.selectedBenefitTier ||
                      (group?.selectedBenefitTier !== groupEmployerOptedOut &&
                        group.selectedBenefitTier?.displayCost && (
                          <TierCost
                            cost={group.selectedBenefitTier?.displayCost}
                            disclaimer={getCostDisclaimer(group.selectedBenefitTier?.displayCost)}
                          />
                        ))}
                  </>
                )}
              </Stack>
              <Text variant="xs" mt="8px">
                {!group?.selectedBenefitTier ||
                group?.selectedBenefitTier === groupEmployerOptedOut ? (
                  <Text>No benefits offered.</Text>
                ) : (
                  <>
                    {group.selectedBenefitTier.benefitPlans[0]?.benefitPlan?.carrier?.name && (
                      <Text as="strong">{`${group.selectedBenefitTier.benefitPlans[0].benefitPlan.carrier.name}: `}</Text>
                    )}
                    {group.selectedBenefitTier.description && (
                      <HTMLRendered Tag={Description}>
                        {group.selectedBenefitTier.description}
                      </HTMLRendered>
                    )}
                  </>
                )}
              </Text>
            </TierCard>
          ))}

        {!hasMultipleGroups && groups[0]?.selectedBenefitTier !== groupEmployerOptedOut && (
          <TierCard data-testid="tier-card">
            <Text as="strong">{groups[0].selectedBenefitTier.name}</Text>
            <Stack my={3} gap={3} direction="row">
              <GroupIcons group={groups[0]} />
              {groups[0].selectedBenefitTier !== groupEmployerOptedOut &&
                groups[0].selectedBenefitTier?.displayCost && (
                  <TierCost
                    cost={groups[0].selectedBenefitTier?.displayCost}
                    disclaimer={getCostDisclaimer(groups[0].selectedBenefitTier?.displayCost)}
                  />
                )}
            </Stack>
            {groups[0].selectedBenefitTier.description ? (
              <HTMLRendered Tag={Description}>
                {groups[0].selectedBenefitTier.description}
              </HTMLRendered>
            ) : (
              <InfoBlock
                size="sm"
                list={groups[0].selectedBenefitTier.benefitPlans.map((plan) => ({
                  title: plan.name,
                  value: friendlyBenefitPlanCost(plan),
                }))}
              />
            )}
          </TierCard>
        )}

        {!hasMultipleGroups && groups[0]?.selectedBenefitTier === groupEmployerOptedOut && (
          <Stack alignItems="center">
            <GiftIllustration data-testid="empty-illustration" height="128px" />
            <Text variant="smMedium" mt={4}>
              No benefits will be offered to this or upcoming employees in {countryName}
            </Text>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}

LockedBenefits.propTypes = {
  countryName: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
};
