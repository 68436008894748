import Image from '@tiptap/extension-image';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ResizableImageComponent } from './ResizableImageComponent';

const DEFAULT_WIDTH = '100%';
const DEFAULT_HEIGHT = 'auto';

export const ResizableImage = Image.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      editable: true,
    };
  },
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: DEFAULT_WIDTH,
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
      },
      height: {
        default: DEFAULT_HEIGHT,
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          };
        },
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(ResizableImageComponent);
  },
});
