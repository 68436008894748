import type { SelectOption } from '@remote-com/norma';
import type { FormikHelpers, FormikValues } from 'formik';
import omit from 'lodash/omit';
import { useState } from 'react';

import { yesNoValues, supportedTypes } from '@/src/components/Form/DynamicForm/constants';
import { DynamicFormField } from '@/src/components/Form/DynamicForm/DynamicFormField';
import {
  fieldTypesTransformations,
  transformSelectOption,
  transformRadioOption,
} from '@/src/components/Form/DynamicForm/helpers';
import type {
  SupportedFieldTypes,
  TextAreaField,
  DateRangeField,
  DateField,
  CurrenciesField,
  AckCheckField,
  WorkWeekScheduleField,
  MoneyField,
  TelephoneField,
  RadioField as RadioFieldType,
  HiddenField as HiddenFieldType,
  SelectField as SelectFieldType,
  FileField,
  SignatureField,
  CheckBoxField,
  CheckBoxGroupField,
  ExtraField,
  GroupArrayField,
  CountriesField,
  RadioCardField as RadioCardFieldType,
  BenefitsField,
  FormField,
  OnChangeCountryRenderField,
  OnChangeSelectRenderField,
  SelectFieldJSF,
  FieldsetField as FieldsetFieldType,
  NumberField,
  TextField,
  EmailField,
  WorkScheduleField,
} from '@/src/components/Form/DynamicForm/types';
import { FieldGroupArray } from '@/src/components/Form/FieldGroupArray';
import {
  InputField,
  RadioCardField,
  RadioCardGroupField,
  InputCurrencyField,
  CheckboxGroupField,
} from '@/src/components/Ui/Form';
import AckField from '@/src/components/Ui/Form/AckField';
import { DatePickerField } from '@/src/components/Ui/Form/DatePickerField';
import { DateRangePickerField } from '@/src/components/Ui/Form/DateRangePickerField';
import { FieldsetField } from '@/src/components/Ui/Form/formikIntegration/FieldsetField/FieldsetField';
import { FileUploaderFieldCompat } from '@/src/components/Ui/Form/formikIntegration/FileUploaderField/FileUploaderFieldCompat';
import { PhoneNumberField } from '@/src/components/Ui/Form/formikIntegration/PhoneNumberField';
import { getCountryDialListFromOptions } from '@/src/components/Ui/Form/formikIntegration/PhoneNumberField/helpers';
import { SelectField } from '@/src/components/Ui/Form/formikIntegration/SelectField/SelectField';
import { HiddenField } from '@/src/components/Ui/Form/HiddenField';
import InputSignature from '@/src/components/Ui/Form/InputSignature';
import { RadioField } from '@/src/components/Ui/Form/Radio';
import { TextareaField } from '@/src/components/Ui/Form/TextareaField';
import { WorkWeekScheduleTableField } from '@/src/components/Ui/Form/WorkWeekScheduleTableField';
import { CountrySelectField } from '@/src/components/Ui/Select/CountrySelect';
import { CurrencyFields } from '@/src/components/Ui/Select/CurrencyFields';
import type { AIFormAssistantConfig } from '@/src/domains/aiPoweredForms';
import { BenefitsSelection } from '@/src/domains/benefits/employer/BenefitsSelection';
import { countryDialCodes } from '@/src/domains/countries/constants';
import { error as captureError } from '@/src/helpers/general';

type RenderField = FormField & {
  /**
   * If used, will render the component as if it was an ExtraField.
   */
  Component?: React.ReactNode;
};

type RenderFieldWithoutType = Omit<RenderField, 'type'>;

export type FormikSetFieldValue<FormValues = FormikValues> =
  FormikHelpers<FormValues>['setFieldValue'];
export type FormikSetFieldValues<FormValues = FormikValues> =
  FormikHelpers<FormValues>['setValues'];

export type RenderFieldFunction = (
  field: RenderField,
  setValue: FormikSetFieldValue,
  formValues: FormikValues,
  props?: RenderFieldProps
) => JSX.Element;

function fieldSanityCheckerTransformValue(
  fieldType: SupportedFieldTypes,
  fieldName: string,
  transformValue?: (option: any) => any
) {
  // To bypass this error, you have two solutions:
  // - Ideal: Refactor the field to use the common transformValue.
  //    read composeField* fn docs to know what's the transformation pattern of that field.
  // - Workaround: Rename the attr "transformValue" to "dangerousTransformValue".
  //    This will help us to identify these fields in the future
  //    if we need to migrate them to JSON Schema forms.
  if (transformValue) {
    captureError(
      new Error(
        `DynamicForm warning: ${fieldType} - The field "${fieldName}" has a transformValue that must not be used. Check sourcecode for further guidance.`
      )
    );
  }
}

const KEYS_TO_OMIT = [
  'schema',
  'dataTestid',
  'value',
  'visibilityCondition',
  'maskSecret',
  'nameKey',
  'required',
  'calculateDynamicProperties',
  'currency',
  'deprecated',
  'description',
  /** Keys from json-schema-form */
  'errorMessage',
  'inputType',
  'jsonType',
  'Component',
  'computedAttributes',
  'calculateConditionalProperties',
  'calculateCustomValidationProperties',
  'percentage',
  'presentation',
  'statement',
  'isVisible',
  'scopedJsonSchema',
  /** End of keys from json-schema-form */
] as const;

type KeysToOmit = (typeof KEYS_TO_OMIT)[number];

type RenderFieldAttributes<TField extends RenderFieldWithoutType> = Omit<TField, KeysToOmit> & {
  'data-testid'?: string;
  description?: string | React.ReactElement;
};

// Remove some of the unneeded attributes from the default field,
// so only the properties of `field` that are element attributes are passed as such
function getAttributesFromField<TField extends RenderFieldWithoutType>(
  field: TField
): RenderFieldAttributes<TField> {
  const attributes = omit(field, KEYS_TO_OMIT) as Omit<TField, KeysToOmit>;
  const displayLabel = field.displayLabel || !!field.readOnly;

  return {
    ...attributes,
    ...(displayLabel !== undefined ? { displayLabel } : {}),
    ...(field.required ? { 'aria-required': true } : {}),
    'data-testid': field.dataTestid ?? field.name,
    description: field.deprecated
      ? `${field.description || ''} ${
          typeof field.deprecated === 'object' ? field.deprecated.description || '' : ''
        }`
      : field.description,
  };
}

function renderWorkWeekScheduleTable(field: Omit<WorkWeekScheduleField, 'type'>) {
  const attributes = getAttributesFromField(field);
  return <WorkWeekScheduleTableField {...attributes} />;
}

// We render the WorkSchedule as a generic input field here, and overwrite it
// on the schema's modify level
function renderWorkScheduleInput(field: Omit<WorkScheduleField, 'type'>) {
  const attributes = getAttributesFromField(field);

  return <InputField {...attributes} type="text" id={field.name} />;
}

function guessRadioDirection(options: RadioFieldType['options']) {
  // This is meant for JSON Schemas that are UI agnostic.
  // I tried with CSS to force "row" in fields with 2 options, but that's
  // not bulletproof against fields with long labels or description.
  // It's an hardcoded exception to the common Yes/No field.
  if (
    typeof options !== 'function' &&
    options.length === 2 &&
    options[0].value === yesNoValues.YES
  ) {
    return 'row';
  }
  return undefined;
}

function renderDefaultInput(field: TextField | EmailField) {
  const attributes = getAttributesFromField(field);

  return <InputField {...attributes} id={field.name} />;
}

function renderNumber(field: Omit<NumberField, 'type'>) {
  const attributes = getAttributesFromField(field);

  return <InputField {...attributes} type="number" percentage={field.percentage} id={field.name} />;
}

function renderTextarea(
  field: Omit<TextAreaField, 'type'>,
  enableAIAssistant?: boolean,
  aiAssistantConfig?: AIFormAssistantConfig
) {
  const attributes = getAttributesFromField(field);
  return (
    <TextareaField
      {...attributes}
      id={field.name}
      enableAIAssistant={enableAIAssistant}
      aiAssistantConfig={aiAssistantConfig}
    />
  );
}

function renderHidden(field: Omit<HiddenFieldType, 'type'>) {
  const attributes = getAttributesFromField(field);
  return <HiddenField {...attributes} />;
}

function renderDatePicker(field: Omit<DateField, 'type'>) {
  const attributes = getAttributesFromField(field);
  return <DatePickerField {...attributes} id={field.name} />;
}

function renderDateRangePicker(field: Omit<DateRangeField, 'type'>) {
  const attributes = getAttributesFromField(field);
  return <DateRangePickerField {...attributes} id={field.name} />;
}

function renderCurrenciesSelect(field: Omit<CurrenciesField, 'type'>) {
  const attributes = getAttributesFromField(field);
  return <CurrencyFields {...attributes} id={field.name} />;
}

function renderAckCheck(field: Omit<AckCheckField, 'type'> | Omit<CheckBoxField, 'type'>) {
  const { displayLabel, ...attributes } = getAttributesFromField(field);
  return <AckField {...attributes} id={field.name} />;
}

function renderCheckbox(field: Omit<CheckBoxField, 'type'> | Omit<CheckBoxGroupField, 'type'>) {
  if ('options' in field) {
    const { displayLabel, ...attributes } = getAttributesFromField(field);

    return <CheckboxGroupField {...attributes} />;
  }

  const { displayLabel, ...attributes } = getAttributesFromField(field);

  return <AckField {...attributes} id={field.name} />;
}

function renderRadioCard(field: Omit<RadioCardFieldType, 'type'>) {
  const attributes = getAttributesFromField(field);
  return (
    <RadioCardGroupField {...attributes} name={field.name}>
      {field.options.map((item, index) => (
        <RadioCardField
          key={index}
          description={item.description}
          name={field.name}
          size="lg"
          {...item}
        />
      ))}
    </RadioCardGroupField>
  );
}

export function renderGroupArray(
  field: Omit<GroupArrayField, 'type'>,
  setValue: FormikSetFieldValue,
  render: RenderFieldFunction
) {
  return <FieldGroupArray group={field} setValue={setValue} renderFunction={render} />;
}

function renderMoney(field: Omit<MoneyField, 'type'>) {
  const attributes = getAttributesFromField(field);
  return (
    <InputCurrencyField
      {...attributes}
      currency={field.currency}
      id={field.name}
      label={field.label}
    />
  );
}

function renderPhoneNumber(field: Omit<TelephoneField, 'type'>) {
  const attributes = {
    ...getAttributesFromField(field),
    options: field.options ? getCountryDialListFromOptions(field.options) : countryDialCodes,
  };

  return <PhoneNumberField {...attributes} id={field.name} />;
}

function renderBenefitsSelection(
  field: Omit<BenefitsField, 'type'>,
  formValues: FormikValues,
  setValue: FormikSetFieldValue
) {
  const attributes = getAttributesFromField(field);
  return (
    <BenefitsSelection
      {...attributes}
      id={field.name}
      countryBenefitDetails={field.countryBenefitDetails}
      countryName={field.countryName}
      formValues={formValues}
      isLoading={field.isLoading}
      setValue={setValue}
    />
  );
}

export function SelectCreatableForJSF({
  input: { options, ...field },
}: {
  input: Omit<SelectFieldJSF, 'type'>;
}) {
  // We need to locally track the options to allow for user-created options to be properly synced in the internal logic of SelectField
  // If we did not, user-created options would not reach to SelectField, leading to this bug: https://linear.app/remote/issue/WE-409/fixdynamicform-composefieldselect-with-multiple-and-allowcreate-does
  const [localOptions, setOptions] = useState(options as SelectOption[]);
  const onCreateOption = (allOptions: SelectOption[]) => setOptions(allOptions);
  const { dangerousTransformValue, transformValue, onChange, ...attributes } =
    getAttributesFromField({
      ...field,
      options: localOptions,
    });

  return (
    <SelectField
      {...attributes}
      transformValue={fieldTypesTransformations[supportedTypes.SELECT].transformValue}
      options={localOptions}
      allowCreate
      isControlled
      onCreateOption={onCreateOption}
      transformCreatedOption={(value) => value} // we don't want to transform the value of the created option
    />
  );
}

function Select({
  input: { options, ...field },
  setValue,
  formValues,
}: {
  setValue: FormikSetFieldValue;
  formValues: FormikValues;
  input: Omit<SelectFieldType, 'type'>;
}) {
  const getInitialOptionsValue = (optionsInputProp: SelectFieldType['options']) => {
    if (optionsInputProp === undefined) {
      return [];
    }
    if (typeof optionsInputProp === 'function') {
      return optionsInputProp({ formValues });
    }

    return optionsInputProp.map(transformSelectOption) as SelectOption[]; // mapping from JSON Schema signature to react-select signature
  };

  const initialOptions = getInitialOptionsValue(options);

  // We need to locally track the options to allow for user-created options to be properly passed to SelectField
  // If we did not, user-created options would not reach SelectField, leading to this bug: https://linear.app/remote/issue/WE-409/fixdynamicform-composefieldselect-with-multiple-and-allowcreate-does
  const [localOptions, setOptions] = useState<SelectOption[]>([]);
  const onCreateOption = (allOptions: SelectOption[]) => {
    // We define onCreateOption to keep track of the user added options, but we should allow for a custom onCreateOption to be passed as well,
    // and ensure that we call it correctly
    if (field.onCreateOption !== undefined) {
      field.onCreateOption(allOptions);
    }
    setOptions(allOptions);
  };

  const { dangerousTransformValue, transformValue, onChange, ...attributes } =
    getAttributesFromField({
      ...field,
      options: localOptions.length === 0 ? initialOptions : localOptions,
    });

  const handleChange: OnChangeSelectRenderField | undefined = onChange
    ? (selectedOption, meta, previousValue) => {
        // selectedOption can be an array for multiple selects, this spread signature is not ideal
        // it makes it hard to handle all the selected options in the onChange callback
        onChange({ ...selectedOption, meta, previousValue, setValue, formValues });
      }
    : undefined;

  fieldSanityCheckerTransformValue(supportedTypes.SELECT, field.name, transformValue);

  return (
    <SelectField
      {...attributes}
      transformValue={
        transformValue ||
        dangerousTransformValue ||
        fieldTypesTransformations[supportedTypes.SELECT].transformValue
      }
      id={field.name}
      isControlled
      onChange={handleChange}
      onCreateOption={onCreateOption}
    />
  );
}

function renderCountriesSelect(
  { options, ...field }: Omit<CountriesField, 'type'>,
  { setValue, formValues }: { setValue: FormikSetFieldValue; formValues: FormikValues }
) {
  const dynamicOptions = options?.map(transformSelectOption) as CountriesField['countries']; // mapping from JSON Schema signature to react-select signature
  const { onChange, dangerousTransformValue, transformValue, ...attributes } =
    getAttributesFromField({
      ...field,
      options: dynamicOptions,
    });

  const handleChange: OnChangeCountryRenderField | undefined = onChange
    ? (selectedOption, meta, previousValue) => {
        onChange({ selectedOption, meta, previousValue, setValue, formValues });
      }
    : undefined;

  fieldSanityCheckerTransformValue(supportedTypes.COUNTRIES, field.name, transformValue);

  return (
    <CountrySelectField
      {...attributes}
      transformValue={
        transformValue ||
        dangerousTransformValue ||
        fieldTypesTransformations[supportedTypes.COUNTRIES].transformValue
      }
      id={field.name}
      isControlled
      data-testid={field.dataTestid}
      onChange={handleChange}
    />
  );
}

// mostly used to render components other than form fields and sometimes
// form fields as well - check composeMoneyInputWithConversion
function renderExtra(
  field: Omit<ExtraField, 'type'>,
  { setValue, formValues }: { setValue: FormikSetFieldValue; formValues: FormikValues }
) {
  const { Component, formatDisplay, ...rest } = field;
  return <Component {...rest} values={formValues} setValue={setValue} />;
}

function renderRadio(
  field: Omit<RadioFieldType, 'type'> | Omit<RadioCardFieldType, 'type'>,
  {
    formValues,
    setValue,
    renderField: render,
    props,
  }: {
    formValues: FormikValues;
    setValue: FormikSetFieldValue;
    renderField: RenderFieldFunction;
    props?: RenderFieldProps;
  }
) {
  const { options, onValueChanged, ...attributes } = getAttributesFromField(field);
  const dynamicOptions =
    typeof options === 'function' ? options({ formValues }) : options.map(transformRadioOption);
  const direction =
    typeof field.direction !== 'undefined' ? field.direction : guessRadioDirection(field.options);
  if ('variant' in field && (field.variant === 'card' || field.variant === 'card-expandable')) {
    return (
      <RadioCardGroupField {...attributes} direction={direction} name={field.name}>
        {field?.options?.map((item, index) => (
          <RadioCardField
            key={index}
            description={item.description}
            name={field.name}
            size={field.size}
            variant={field.variant}
            onValueChanged={onValueChanged}
            {...item}
          />
        ))}
      </RadioCardGroupField>
    );
  }

  const dynamicFormProps = { formValues, setValue, renderField: render, fieldProps: props };

  return (
    <RadioField
      {...attributes}
      options={dynamicOptions}
      direction={direction}
      id={field.name}
      jsonType={field?.jsonType}
      dynamicFormProps={dynamicFormProps}
    />
  );
}

export function renderFieldset(
  field: Omit<FieldsetFieldType<FormField>, 'type'>,
  setValue: FormikSetFieldValue,
  {
    formValues,
    renderField: render,
    props,
  }: {
    formValues: FormikValues;
    renderField: RenderFieldFunction;
    props?: RenderFieldProps;
  }
) {
  const nestedFieldElements: JSX.Element[] = field?.fields?.map((nestedField) => {
    /*  Must inherit top level name from all fieldsets above that have a name property, ensures a correct name chain is constructed
        If the field is nested under the following fieldset names:
        fieldSet1 -> undefined -> undefined -> fieldSet3 -> undefined -> fieldName
        its name should be: 'fieldSet1.fieldSet3.fieldName'
     */
    const name = [field.name, nestedField.name].filter(Boolean).join('.');

    const fieldKey =
      nestedField.name || (typeof nestedField.label === 'string' ? nestedField.label : null);

    if (fieldKey === null) {
      captureError(
        'Nested fieldset field has no render key. Make sure the field either has a name or a string label.'
      );
    }

    return (
      <DynamicFormField
        key={fieldKey}
        input={{
          ...nestedField,
          name,
        }}
        values={formValues}
        renderField={render}
        setValue={setValue}
        {...props}
      />
    );
  });

  if (field?.visualGroupingDisabled) {
    return <>{nestedFieldElements}</>;
  }

  return (
    <FieldsetField
      label={field?.label}
      description={field?.description}
      extra={field?.extra}
      name={field?.name}
      variant={field?.variant}
    >
      {nestedFieldElements}
    </FieldsetField>
  );
}

function renderFile(
  field: Omit<FileField, 'type'>,
  onFilesRemoved: RenderFieldProps['onFilesRemoved']
) {
  const {
    fileDownload, // from json-schemas presentation.
    ...attributes
  } = getAttributesFromField(field);
  const isRequired = attributes['aria-required'];

  return (
    <FileUploaderFieldCompat
      url={fileDownload}
      {...attributes}
      skippable={!isRequired}
      key={field.name}
      name={field.name}
      id={field.name}
      onFilesRemoved={onFilesRemoved}
    />
  );
}

function renderSignature(field: Omit<SignatureField, 'type'>) {
  const attributes = getAttributesFromField(field);
  return <InputSignature {...attributes} />;
}

function omitType<TField extends RenderField>({ type, ...input }: TField) {
  return input;
}

export type RenderFieldProps = {
  onFilesRemoved?: () => void;
  enableAIAssistant?: boolean;
  aiAssistantConfig?: AIFormAssistantConfig;
};

export function renderField(
  input: RenderField,
  setValue: FormikSetFieldValue,
  formValues: FormikValues,
  props?: RenderFieldProps
) {
  // json-schema-form with overridden JSON Schemas (customProperties)
  if (input.Component) {
    return renderExtra(omitType(input) as ExtraField, { setValue, formValues });
  }

  switch (input.type) {
    case supportedTypes.SELECT: {
      const isCreatableFromJSF = 'creatableOn' in input;
      return isCreatableFromJSF ? (
        <SelectCreatableForJSF input={omitType(input)} />
      ) : (
        <Select input={omitType(input)} setValue={setValue} formValues={formValues} />
      );
    }
    case supportedTypes.COUNTRIES:
      return renderCountriesSelect(omitType(input), { setValue, formValues });
    case supportedTypes.FILE:
      return renderFile(omitType(input), props?.onFilesRemoved);
    case supportedTypes.RADIO:
      return renderRadio(omitType(input), { setValue, formValues, renderField, props });
    case supportedTypes.RADIO_CARD:
      return renderRadioCard(omitType(input));
    case supportedTypes.EXTRA:
      return renderExtra(omitType(input), { setValue, formValues });
    case supportedTypes.GROUP_ARRAY:
      return renderGroupArray(omitType(input), setValue, renderField);
    case supportedTypes.MONEY:
      return renderMoney(omitType(input));
    case supportedTypes.TEXTAREA:
      return renderTextarea(omitType(input), props?.enableAIAssistant, props?.aiAssistantConfig);
    case supportedTypes.HIDDEN:
      return renderHidden(omitType(input));
    case supportedTypes.DATE:
      return renderDatePicker(omitType(input));
    case supportedTypes.DATE_RANGE:
      return renderDateRangePicker(omitType(input));
    case supportedTypes.ACK_CHECK:
      return renderAckCheck(omitType(input));
    case supportedTypes.CHECKBOX:
      // @ts-expect-error `omitType` is producing a type that clashes with the union type in `renderCheckbox`.
      return renderCheckbox(omitType(input));
    case supportedTypes.FIELDSET:
      return renderFieldset(omitType(input), setValue, {
        renderField,
        props,
        formValues,
      });
    case supportedTypes.WORK_SCHEDULE:
      return renderWorkScheduleInput(omitType(input));
    case supportedTypes.WORK_WEEK_SCHEDULE:
      return renderWorkWeekScheduleTable(omitType(input));
    case supportedTypes.CURRENCIES:
      return renderCurrenciesSelect(omitType(input));
    case supportedTypes.BENEFITS:
      return renderBenefitsSelection(omitType(input), formValues, setValue);
    case supportedTypes.TEL:
      return renderPhoneNumber(omitType(input));
    case supportedTypes.SIGNATURE:
      return renderSignature(omitType(input));
    case supportedTypes.NUMBER:
      return renderNumber(omitType(input));
    default:
      return renderDefaultInput(input);
  }
}
