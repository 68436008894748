import type { GetResponse } from '@remote-com/data-layer';
import { Box, Tooltip } from '@remote-com/norma';
import { IconV2OutlineActivity } from '@remote-com/norma/icons/IconV2OutlineActivity';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';
import { useUserContext } from '@/src/components/UserProvider';
import { PersonCellWithIdentityWarning } from '@/src/domains/mobility/common';
import type { ContractorSubscriptionPlanIdentifier } from '@/src/domains/paidContractorProduct/constants';
import { isCurrentBillingPeriod } from '@/src/domains/paidContractorProduct/employer/helpers';
import { ContractorProductIndicator } from '@/src/domains/paidContractorProduct/shared/ContractorProductIndicator';
import { BillableActivityTooltipContent } from '@/src/domains/paidContractorProduct/shared/LastBillableActivityColumn';
import {
  isProductContractorProtect,
  isProductContractorPlus,
  isProductContractorStandard,
} from '@/src/domains/pricing/helpers';
import { isEmployer } from '@/src/domains/registration/auth/helpers';

import { ContractorPlusProductIndicator } from '../shared/ContractorPlusProductIndicator';

type EmployeesResponse = GetResponse<'/api/v1/employer/dashboard/employees'>;

const IconWrapper = styled(Box).attrs({ display: 'flex', flexShrink: 0 })``;

type CellProps = {
  row: {
    original: EmployeesResponse['data']['employments'][number];
  };
  value?: EmployeesResponse['data']['employments'][number];
  extra?: ReactNode;
  wrapNameInLink?: boolean;
};

export function PersonCellWithContractorProduct({ value, row, extra, wrapNameInLink }: CellProps) {
  const { user } = useUserContext();
  const performedAt = row.original.latestBillableContractorActivity?.performedAt;
  const performedInCurrentMonth = isCurrentBillingPeriod(performedAt);
  const isStandardProduct = isProductContractorStandard(row.original.product);
  const isPlusProduct = isProductContractorPlus(row.original.product);
  const isProtectProduct = row.original.product && isProductContractorProtect(row.original.product);

  return (
    <PersonCellWithIdentityWarning
      value={value}
      row={row}
      extra={
        <>
          {extra}
          {isProtectProduct && (
            <ContractorProductIndicator
              tooltipLabel={`Contractor hired by Remote's entity`}
              type={row.original.product?.identifier as ContractorSubscriptionPlanIdentifier}
            />
          )}
          {isPlusProduct && <ContractorPlusProductIndicator />}
          {isStandardProduct && performedInCurrentMonth && (
            <Tooltip
              label={
                <BillableActivityTooltipContent
                  contractorName={row.original.name}
                  performedInCurrentMonth={performedInCurrentMonth}
                  userIsEmployer={isEmployer(user)}
                />
              }
            >
              <Button variant="raw" aria-label="Billable activity">
                <IconWrapper color="green.600">
                  <IconV2OutlineActivity width={16} />
                </IconWrapper>
              </Button>
            </Tooltip>
          )}
        </>
      }
      wrapNameInLink={wrapNameInLink}
    />
  );
}
