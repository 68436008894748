import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import snakeCase from 'lodash/snakeCase';

import { parseQueryFromUrl, standardQueryDecoder } from '@/src/helpers/queryParser';

export const disableSortAndFilters = {
  disableSortBy: true,
  disableFilters: true,
};

export const viewOnlyColumn = {
  disableSortBy: true,
  disableFilters: true,
  disableExport: true,
};

export const queryFromState = (state) => {
  const result = {};
  const sortBy = state.sortBy?.[0];

  if (sortBy) {
    result.sortBy = sortBy.id;
    result.order = sortBy.desc ? 'desc' : 'asc';
  }

  const { globalFilter } = state;

  if (globalFilter) {
    result.searchQuery = globalFilter;
  }

  if (state.filters?.length) {
    const filters = state.filters.reduce((memo, { id, value }) => {
      memo[id] = value;
      return memo;
    }, {});

    result.filters = filters;
  }

  return result;
};

/**
 * stateToAPIQuery takes react-table state(see Table component for details), and
 * converts filters, sortBy, pagination and globalFilter into appropriate
 * query parameters, following our backend API conventions.
 * @param {object} tableState
 *
 * @param {object} options
 *
 * Which queryKey to use to send search filter from the table. Defaults to `globalFilter`
 * @param {string} options.globalFilterQueryKey
 *
 * filterConfig is an object that allows transformation of how
 * filters are sent to the backend.
 *
 * It takes a map of filter key to the configuration, example
 *
 * In this example, a filter for column `status`, would be sent
 * to the backend as using `statuses` query parameter, and it's value will always be
 * an array:
 *
 * status: {
 *   key: 'statuses',
 *   type: 'array', // or scalar, auto, ignore
 * }
 *
 * In this example, a filter for column `status`, won't be sent to the backend.
 *
 * status: {
 *   type: 'ignore'
 * }
 *
 * @param {function} options.filterConfig
 * @returns
 */
export const stateToAPIQuery = (tableState, options = {}) => {
  const globalFilterQueryKey = options.globalFilterQueryKey || 'globalFilter';
  const filterConfig = options.filterConfig || {};

  const query = {};
  const sortBy = tableState.sortBy?.[tableState.sortBy.length - 1];

  if (sortBy) {
    query.sortBy = snakeCase(sortBy.id);
    query.order = sortBy.desc ? 'desc' : 'asc';
  }

  if (tableState.globalFilter || isNumber(tableState.globalFilter)) {
    query[globalFilterQueryKey] = tableState.globalFilter;
  }

  if (!isNil(tableState.pageIndex)) {
    query.page = tableState.pageIndex + 1;
  }

  if (tableState.pageSize) {
    query.pageSize = tableState.pageSize;
  }

  const filterGroups = groupBy(tableState.filters || [], ({ id }) => id);

  Object.entries(filterGroups).forEach(([key, group]) => {
    const values = group.map(({ value }) => value);
    const newKey = filterConfig[key]?.key || key;

    switch (filterConfig[key]?.type) {
      case 'array':
        query[newKey] = values;
        break;
      case 'scalar':
        [query[newKey]] = values;
        break;
      case 'ignore':
        break;
      case 'auto':
      default:
        query[newKey] = values.length === 1 ? values[0] : values;
    }
  });

  return query;
};

export const APIResponseToState = ({ data: results = {}, isLoading }) => {
  const {
    data = [],
    totalPages,
    currentPage,
    page,
    // Total item count can be returned from backend's `Paginator` service,
    // see `total_count` option.
    totalCount,
    total,
    ...rest
  } = results;
  // Mapping API response to react-table props
  // totalPages -> pageCount, currentPage -> pageIndex, page -> pageIndex
  // first page on API has index 1, we have to map it to 0 for react-table
  const pageValue = currentPage ?? page;
  const pageIndex = pageValue ? Math.max(pageValue - 1, 0) : 0;
  const pageCount = totalPages || 0;
  // The key for totals can be `total_count` or `total`
  // See: `/api/v1/rivendell/contract-templates` vs `/api/v1/rivendell/employees`
  const resultsTotal = totalCount ?? total;

  return {
    data,
    isEmptyTable: data.length === 0 && !isLoading,
    pageIndex,
    pageCount,
    totalCount: resultsTotal,
    isLoading,
    metadata: rest,
  };
};

/**
 * Returns only visible headers
 *
 * @template ReactTableHeaders
 * @param {ReactTableHeaders[]} headers
 * @returns {ReactTableHeaders[]}
 */
export const filterHiddenHeaders = (headers) => headers.filter(({ hiddenColumn }) => !hiddenColumn);

export const filterHiddenCells = (cells) => cells.filter(({ column }) => !column.hiddenColumn);

/**
 * Returns only visible columns
 *
 * @template ReactTableColumns
 * @param {ReactTableColumns[]} columns
 * @returns {ReactTableColumns[]}
 */
export const filterHiddenColumns = (columns) => columns.filter((column) => !column.hiddenColumn);

export function getPayrollRunExportData(payrollRun) {
  if (!payrollRun) {
    return '-';
  }

  if (!payrollRun.periodStart || !payrollRun.periodEnd) {
    return payrollRun.name;
  }

  return `${payrollRun.name} (${payrollRun.periodStart} - ${payrollRun.periodEnd})`;
}

export function decodeQueryParam(param) {
  if (isString(param)) {
    try {
      return decodeURIComponent(param.replace(/\+/g, ' '));
    } catch {
      return param;
    }
  }

  return param;
}

export const supportedTableParams = ['filters', 'globalFilter', 'pageIndex', 'pageSize', 'sortBy'];

export const urlToTableState = (url) => {
  const queryFromUrl = parseQueryFromUrl(url, {
    decoder: standardQueryDecoder,
    arrayLimit: 50, // allow to have multi value filters with more than 20 values (default)
  });
  const tableState = pick(queryFromUrl, supportedTableParams);

  return {
    ...tableState,
    ...(tableState.filters
      ? // Let's filter out these entries without { value } prop specified
        { filters: tableState.filters.filter((filter) => filter && 'value' in filter) }
      : {}),
  };
};
