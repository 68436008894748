import React, { forwardRef } from 'react';
import type { ChangeEvent } from 'react';

import { InputText } from '../input-text';

type NumberInputProps = {
  name?: string;
  placeholder?: string;
  label?: string;
  value?: string;
  error?: boolean;
  touched?: boolean;
  displayLabel?: boolean;
  id?: string;
  displayErrorMessage?: boolean;
  errorText?: string;
  onBlur?: () => void;
  onChange?: (event: ChangeEvent<Element>, ...args: unknown[]) => void | undefined;
};

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ name, placeholder, label, value, error, touched, displayLabel, ...props }, ref) => (
    <InputText
      name={name || ''}
      type="tel"
      inputMode="decimal"
      pattern="^[0-9.]*$"
      label={label || placeholder}
      value={value}
      ref={ref}
      {...props}
    />
  )
);
