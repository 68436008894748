import capitalize from 'lodash/capitalize';

import { CUSTOM_FIELD_ACCESSOR_PREFIX } from '@/src/domains/customFields/constants';

/* -------------------------------------------------------------------------------------------------
 * Filters
 * -----------------------------------------------------------------------------------------------*/

/**
 * Filter only columns needed for exporting
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyColumnsNeededForExporting = ([, state]) =>
  state === 'visible' || state === 'only-export';

/** We include hidden columns as they are needed for filters */

/**
 * Filter only columns needed for table rendering. We include hidden columns as they are needed for filters
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyColumnsNeededForTableRendering = ([, state]) =>
  state === 'visible' || state === 'only-view' || state === 'hidden';

/**
 * Filter only columns that can be configured (toggle visibility, change order, ...). Columns that are defined as "hidden" are not meant to be displayed on the table, and as such, should not be configurable. Columns that are defined as "export" can be displayed in the table, but shouldn't be a part of original columns. Always filters out the actions/view columns.
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyConfigurableColumns = ([, state, , sticky]) =>
  !sticky && state !== 'hidden' && state !== 'only-export';

/**
 * Filter only columns that can be exported. "view" column state allows displaying the column, but corresponds to a disabledExport state. Columns that are defined as "hidden" are not meant to be displayed on the table, and as such, should not be exportable either. Always filters out the actions/view columns.
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyExportableColumns = ([id, state]) =>
  id !== 'actions' && id !== 'view' && state !== 'hidden' && state !== 'only-view';

/**
 * Filter only columns that can be exported or are visible.
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyExportableVisibleColumns = ([, state]) =>
  state === 'visible' || state === 'only-export';

/**
 * Filter only columns whose id DOES NOT start with "data-".
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyNonDataColumns = ([id]) => typeof id === 'string' && !id.startsWith('data-');

/**
 * Filter only columns whose id starts with "data-".
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyDataColumns = ([id]) => typeof id === 'string' && id.startsWith('data-');

/**
 * Filter only visible columns.
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyVisibleColumns = ([, state]) => state === 'visible' || state === 'only-view';

/**
 * Filter only invisible columns.
 *
 * @param {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} columnState
 */
export const onlyInvisibleColumns = ([, state]) => state === 'invisible';

/* -------------------------------------------------------------------------------------------------
 * Others
 * -----------------------------------------------------------------------------------------------*/

const GLOBAL_PATH_EXCLUSION_LIST = [
  'employment.contract.contractDetails.details',
  'employment.contract',
];

/**
 * Recursively traverses an object to generate an array of object paths/accessors.
 * {
 *   a: {
 *      b: [1,2],
 *      с: 'hello'
 *   },
 *   d: null,
 *   e: 1
 * } → ['a.b' 'a.c', "e"]
 * @param {*} obj
 * @returns
 */

export function getAccessors(obj, path) {
  const props = [];

  Object.keys(obj).forEach((key) => {
    // If the value is an array, immediately return so we only store the path leading to it
    if (obj.length) {
      props.push(`${path}`);
    } else if (obj[key] instanceof Object) {
      props.push(...getAccessors(obj[key], path ? `${path}.${key}` : `${key}`));
    } else {
      props.push(path ? `${path}.${key}` : `${key}`);
    }
  });

  return props;
}

export function getAllUniqueAccessors(data) {
  if (!data.length) {
    return [];
  }

  const allAccessors = data.flatMap((obj) => {
    if (!obj || !Object.keys(obj).length) {
      return [];
    }

    return getAccessors(obj);
  });

  const uniqueAccessors = [...new Set(allAccessors)];

  return uniqueAccessors;
}

export function getDataAccessors({
  data = [],
  originalColumnsAccessors = [],
  columnAccessorExclusionSet = new Set(),
  specialAdditionalColumnsConfig = {},
  columnAccessorInclusionSet = null,
}) {
  // Ensure only one of columnAccessorExclusionSet or columnAccessorInclusionSet is provided
  if (columnAccessorExclusionSet.size > 0 && columnAccessorInclusionSet) {
    throw new Error('Cannot send both columnAccessorExclusionSet and columnAccessorInclusionSet');
  }

  if (!data || !data.length) {
    return [];
  }

  const uniqueAccessors = getAllUniqueAccessors(data);

  const uniqueAccessorsFilter = (accessor) => {
    // Filters out unnecessary accessors
    const exclusionSetHasAccessor = columnAccessorExclusionSet.has(accessor);

    // Filters out accessors that are already in the table column definition
    const columnAccessorsHasAccessor = originalColumnsAccessors.includes(accessor);

    // Filters out accessors that are handled separately
    const specialAdditionalColumnsConfigHasAccessor = Object.keys(
      specialAdditionalColumnsConfig
    ).includes(accessor);

    // If columnAccessorInclusionSet is provided, only include accessors in this set
    const isAllowed = !columnAccessorInclusionSet || columnAccessorInclusionSet.has(accessor);

    return (
      isAllowed &&
      !exclusionSetHasAccessor &&
      !columnAccessorsHasAccessor &&
      !specialAdditionalColumnsConfigHasAccessor
    );
  };

  return uniqueAccessors.filter(uniqueAccessorsFilter);
}

export function sortLeftStickyColumnsLeft(c1, c2) {
  if (c1.sticky === 'left' && c2.sticky !== 'left') {
    return -1;
  }
  if (c1.sticky !== 'left' && c2.sticky === 'left') {
    return 1;
  }
  return 0;
}

export function sortRightStickyColumnsRight(c1, c2) {
  if (c1.sticky === 'right' && c2.sticky !== 'right') {
    return 1;
  }
  if (c1.sticky !== 'right' && c2.sticky === 'right') {
    return -1;
  }
  return 0;
}

export function disableVisibilityProperties(column) {
  const { disableInView, disableExport, ...result } = column;
  return result;
}

/** We use this function to check a columnLabels object (which has an identical shape to the requested data) for an accessor. This allows us to use a more friendly columnLabels object and reduce some duplication - the alternative would be to use an object where each key contains the full accessor path, which reduces readability significantly as the object grows. */
const getLabelFromAccessor = ({ accessor, columnLabels }) => {
  const valueFromAccessor = accessor.split('.').reduce((acc, cur) => acc?.[cur], columnLabels);

  return typeof valueFromAccessor === 'object' ? null : valueFromAccessor;
};

const getAccessorWithoutExcludedPath = (accessor) => {
  if (!accessor) {
    return '';
  }

  const exclusionPath = GLOBAL_PATH_EXCLUSION_LIST.find((path) => accessor.startsWith(path));

  // We need to add ".", otherwise there will be a space at the beginning of the string
  return accessor.replace(`${exclusionPath}.`, '');
};

/** We use this function when an accessor doesn't have a manually defined label. This is a best attempt at getting a friendly label, which won't work in all cases (e.g., when the accessor has acronyms: "employment.employeePspId" will be "Employment employee psp id") */
export const friendlyLabel = (accessor) => {
  const accessorWithoutExcludedPath = getAccessorWithoutExcludedPath(accessor);

  return capitalize(
    accessorWithoutExcludedPath
      .replace(/\./g, ' ')
      .split(/(?=[A-Z])/)
      .join(' ')
  );
};

const friendlyCustomFieldLabel = (accessor) => {
  const customFieldKey = accessor.split(CUSTOM_FIELD_ACCESSOR_PREFIX)[1];
  const friendlyCustomFieldKey = friendlyLabel(customFieldKey);

  return `[Custom Field]: ${friendlyCustomFieldKey}`;
};

export const getFriendlyColumnName = ({ accessor, columnLabels }) => {
  if (accessor.includes('customFields')) {
    return friendlyCustomFieldLabel(accessor);
  }

  if (!columnLabels) {
    return friendlyLabel(accessor);
  }

  const labelFromAccessor = getLabelFromAccessor({ accessor, columnLabels });

  if (labelFromAccessor) {
    return labelFromAccessor;
  }

  return friendlyLabel(accessor);
};

/** Some column Headers are components/HTML instead of strings. Column definition can have a "toggleName" property (the accessible Header label) which we attempt to parse first. In order for the feature to work without configuration, we need fallbacks. First, we attempt to parse the identifier (note that in some cases this won't match the Header name). Some column definitions do not have ids nor string accessors. Ultimately, we return [Missing name] which is a temporary solution to ensure the app doesn't crash, until we update all tables. */
export const getColumnNameWithFallback = ({ toggleName, Header, identifier }) => {
  if (toggleName) {
    return toggleName;
  }

  if (typeof Header === 'string') {
    return Header;
  }

  if (typeof identifier === 'string') {
    return friendlyLabel(identifier);
  }

  return '[Missing name]';
};
