import { FeedbackMessage, HTMLRendered, Box, AddField } from '@remote-com/norma';
import { IconMinusCircle } from '@remote-com/norma/icons/IconMinusCircle';
import { FieldArray, Field, useFormikContext, getIn } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';

import { yesNoLabels, yesNoValues } from '@/src/components/Form/DynamicForm/constants';
import { getInitialDefaultValue } from '@/src/components/Form/DynamicForm/helpers';
import {
  FieldGroupDescription,
  FieldGroupLabel,
  InputController,
} from '@/src/components/Ui/Form/Input';
import { Radio } from '@/src/components/Ui/Form/Radio';
import { Fieldset } from '@/src/components/Ui/Form/Radio/Radio.styled';
import { RadioGroup } from '@/src/components/Ui/Form/Radio/RadioGroup';
import { FormFieldError } from '@/src/domains/registration/onboarding/shared/styles/Form';

import {
  ParentFieldset,
  CircleIconButton,
  CircleWrapperSingle,
  StyledInputController,
  BoxWithMargin,
} from './FieldGroupArray.styled';
import FieldGroupChildrenFieldset from './FieldGroupChildrenFieldset';

export const BLOCKER_ERROR_MESSAGE =
  'Please add a field or acknowledge that this does not apply to your profile.';

// TODO: Improve performance (too many re-renders) typing is slow with too many items and inputs
/**
 * FieldGroupArray
 *
 * @export
 * @param {Object} props
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').GroupArrayField, 'type'>} props.group
 * @param {import('@/src/components/Form/DynamicForm/renderField').FormikSetFieldValue} props.setValue
 * @param {import('@/src/components/Form/DynamicForm/renderField').RenderFieldFunction} props.renderFunction
 * @return {JSX.Element}
 */
export function FieldGroupArray({ group, setValue, renderFunction }) {
  const { values: formValues, errors, touched } = useFormikContext();

  const hasGroupError = getIn(errors, group.name) && getIn(touched, group.name);

  const addFieldText = group.addFieldText || 'Add field';
  const isRequired = group.required;
  const fields = group.fields();
  const numberOfFields = fields.length;
  const fieldValues = formValues[group.name];
  const fieldIsUndefined = fieldValues === undefined;
  const fieldIsEmpty = !fieldIsUndefined && isEmpty(fieldValues);
  const fieldIsEmptyOrUndefined = fieldIsEmpty || fieldIsUndefined;

  const getAckCheckInitialValue = () => {
    if (fieldIsEmpty) {
      return yesNoValues.NO;
    }
    if (fieldIsUndefined) {
      return undefined;
    }
    return yesNoValues.YES;
  };

  const [ackCheckValue, setAckCheck] = useState(getAckCheckInitialValue());
  const ackCheck = ackCheckValue === yesNoValues.NO;
  const hideAckCheck = isRequired || group.bypassAcknowledgeCheck;

  useEffect(() => {
    if (ackCheck) {
      formValues[group.name] = [];
    }
  }, [ackCheck, formValues, group]);

  // fieldsToAddRef will only be populated once on the initial render.
  // Before #8190 the fieldsToAdd used to be updated on each render to support the use-case of them
  // changing during the lifetime of the component. However, we do not seem to use this anywhere
  // in the code-base and are therefore simplifying it to only set the value once.
  const fieldsToAddRef = useRef(
    fields.reduce(
      (acc, currentField) => ({
        ...acc,
        [currentField.name]: getInitialDefaultValue(formValues, currentField) || '',
      }),
      {}
    )
  );

  useEffect(() => {
    if (
      ackCheckValue === yesNoValues.YES &&
      (fieldValues === undefined || fieldValues.length < 1) &&
      // Disable automatic adding if the user is not in control of the "ack"
      // radio, as it would prevent the user from having an "empty" case when
      // the field is optional. See:
      // - https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/6240
      // - https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/21987
      !hideAckCheck
    ) {
      setValue(group.name, [fieldsToAddRef.current]);
    }
  }, [ackCheckValue, fieldValues, group.name, setValue, hideAckCheck]);

  const isInvalid = !ackCheck && fieldIsEmptyOrUndefined && !group.bypassAcknowledgeCheck;
  const areValuesRemovable = hideAckCheck || fieldValues?.length > 1;

  const validationForBlocker = () => (isInvalid ? BLOCKER_ERROR_MESSAGE : undefined);

  const renderFields = () => {
    // Should not hide fields if the user is not in control of the "ack" radio
    // in the first place. See:
    // - https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/12608
    // - https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/21987
    if (ackCheck && !hideAckCheck) {
      return null;
    }

    return (
      <Field name={group.name} validate={validationForBlocker}>
        {() => {
          if (!hideAckCheck && ackCheckValue === undefined) {
            return null;
          }

          if (numberOfFields !== 1) {
            return (
              <FieldArray name={group.name}>
                {({ remove, push }) => (
                  <>
                    {fieldValues?.length > 0 &&
                      fieldValues.map((value, index) => (
                        <FieldGroupChildrenFieldset
                          key={`${group.label}-${index}`}
                          label={`${group.label} ${index + 1}`}
                          index={index}
                          name={`${group.label}-${index + 1}`}
                          onRemoveChild={areValuesRemovable ? () => remove(index) : undefined}
                        >
                          {group.fields(index).map((nestedField) => {
                            const nestedFieldName = `${group.name}[${index}].${nestedField.name}`;
                            const field = {
                              ...nestedField,
                              ...(nestedField.calculateDynamicProperties?.(value) || {}),
                              name: nestedFieldName,
                            };
                            return (
                              <InputController key={nestedFieldName} data-testid={nestedFieldName}>
                                {renderFunction(field, setValue, nestedFieldName)}
                              </InputController>
                            );
                          })}
                        </FieldGroupChildrenFieldset>
                      ))}

                    <AddField
                      mt="6"
                      onClick={() => push(fieldsToAddRef.current)}
                      {...(!fieldIsEmptyOrUndefined && { mr: -7, ml: -7 })}
                    >
                      {addFieldText}
                    </AddField>
                  </>
                )}
              </FieldArray>
            );
          }
          return (
            <FieldArray name={group.name}>
              {({ remove, push }) => (
                <>
                  {fieldValues?.length > 0 &&
                    fieldValues.map((value, index) => {
                      const fieldName = `${group.name}[${index}].${fields[0].name}`;
                      const field = group.fields(index)[0];
                      return (
                        <CircleWrapperSingle key={index}>
                          <StyledInputController data-testid={fieldName}>
                            {renderFunction(
                              {
                                ...field,
                                ...(field.calculateDynamicProperties?.(value) || {}),
                                name: fieldName,
                              },
                              setValue,
                              fieldName
                            )}
                          </StyledInputController>

                          {areValuesRemovable && (
                            <CircleIconButton
                              onClick={() => remove(index)}
                              Icon={IconMinusCircle}
                              index={index}
                            />
                          )}
                        </CircleWrapperSingle>
                      );
                    })}
                  <AddField mt="6" onClick={() => push(fieldsToAddRef.current)}>
                    {addFieldText}
                  </AddField>
                </>
              )}
            </FieldArray>
          );
        }}
      </Field>
    );
  };

  return (
    <Box mx={5}>
      <ParentFieldset name={group.name}>
        <FieldGroupLabel hasError={isInvalid && hasGroupError}>{group.label}</FieldGroupLabel>
        {group.description && (
          <HTMLRendered Tag={FieldGroupDescription}>{group.description}</HTMLRendered>
        )}
        {!hideAckCheck && (
          <Fieldset aria-label={group.description}>
            <RadioGroup $direction="row">
              {Object.values(yesNoValues).map((value) => (
                <Radio
                  id={`${group.name}-blocker-${value}`}
                  name={`${group.name}-blocker`}
                  key={value}
                  label={yesNoLabels[value]}
                  value={value}
                  unsafeForceCheckedAttr
                  checked={ackCheckValue === value}
                  onChange={() => setAckCheck(value)}
                  aria-describedby={`${group.name}-errorMessage`}
                />
              ))}
            </RadioGroup>
          </Fieldset>
        )}
        {isInvalid && (
          <FormFieldError
            Wrapper={BoxWithMargin}
            name={group.name}
            id={`${group.name}-errorMessage`}
          />
        )}
        {/* This statement only displays for field groups that are of type === 'group-array'. The statement displays right after the radio group instead of displaying it below the fieldset group, example BRA Administrative details form (Benefits dependents field group) */}
        {group.statement && (
          <FeedbackMessage title={group.statement.title} variant={group.statement.severity} mt={6}>
            {/* This block allows using Drawer within the Feedback component */}
            {typeof group.statement.description === 'function' ? (
              group.statement.description()
            ) : (
              // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={{ __html: group.statement.description }} />
            )}

            {/* eslint-disable-next-line react/no-danger */}
            {/* <span dangerouslySetInnerHTML={{ __html: group.statement.description }} /> */}
          </FeedbackMessage>
        )}

        {renderFields()}
      </ParentFieldset>
    </Box>
  );
}

FieldGroupArray.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    fields: PropTypes.func,
    bypassAcknowledgeCheck: PropTypes.bool,
  }),
  setValue: PropTypes.func.isRequired,
  renderFunction: PropTypes.func.isRequired,
};
