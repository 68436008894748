// TODO: Update to data layer v2
/* eslint-disable remote/prefer-using-the-data-layer */
import { useGetForTable, usePut, useGet } from '@remote-com/data-layer';
import { toast } from '@remote-com/norma';
import { useMutation, useQueries } from 'react-query';

import { useUserCan } from '@/src/components/UserCan';
import { generateAndDownloadDocument } from '@/src/domains/files/helpers';
import { fetchFile } from '@/src/domains/files/services';
import { employerFetchPayrollRun } from '@/src/domains/payroll/services';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { PAYROLL_AS_A_PRODUCT_PAGE_SIZE } from '@/src/views/dashboard/payroll/EmployerPayrollLanding/constants';

import { FETCH_EMPLOYER_PAYROLL_RUN_KEY } from './queries';

export const useEmployerPayrollRun = (slug, options = {}) =>
  useGet('/api/v1/employer/payroll-runs/[slug]', {
    params: {
      pathParams: { slug },
    },
    options: {
      ...options,
      select: ({ data }) => data,
    },
  });

export const useEmployerPayrollRunsBySlug = (slugs = []) => {
  const queries = slugs.map((slug) => {
    return {
      queryKey: [FETCH_EMPLOYER_PAYROLL_RUN_KEY, slug],
      queryFn: () => employerFetchPayrollRun({ pathParams: { slug } }),
      select: ({ data }) => data,
    };
  });

  // TODO: Refactor by removing useQueries usage. data-layer doesn't support useQueries
  // https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/12318#note_1179956871 further reference
  return useQueries(queries);
};

export function useEmployerPayrollOutputs({
  payrollRunSlug,
  defaultQueryParams,
  queryParamsTransformer,
  tableOptions,
  options,
} = {}) {
  let transformedQueryParams = defaultQueryParams;

  if (payrollRunSlug) transformedQueryParams.payrollRunSlugs = [payrollRunSlug];

  if (queryParamsTransformer) {
    transformedQueryParams = queryParamsTransformer(transformedQueryParams);
  }

  return useGetForTable('/api/v1/employer/payroll-outputs', {
    params: { queryParams: transformedQueryParams },
    tableOptions: {
      dataProperty: 'payrollOutputs',
      ...tableOptions,
    },
    options,
  });
}

export function useEmployerPayrollOutputsTotals({
  payrollRunSlug,
  defaultQueryParams,
  options,
} = {}) {
  const queryParams = {
    ...defaultQueryParams,
    withTotals: true,
  };

  if (payrollRunSlug) queryParams.payrollRunSlugs = [payrollRunSlug];

  return useGet('/api/v1/employer/payroll-outputs', {
    params: { queryParams },
    options: {
      select: (response) => response.data.totals.overallTotals,
      ...options,
    },
  });
}

export const useEmployerApprovePayrollRun = ({ onSuccess, modalContext, errorMessage }) => {
  const { hideModal, modalProps, setModalProps } = modalContext;
  return usePut('/api/v1/employer/payroll-runs/[slug]', {
    onSuccess: (response, variables) => {
      hideModal();
      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    onMutate: () => {
      setModalProps({ ...modalProps, isLoading: true });
    },
    onError: (error) => {
      // Payroll runs validation error are not the same shape as standard errors they return
      // an array for preparing and array for processing errors which is displayed in payroll runs admin side
      // in this case all we need is the status
      const statusError = error?.response?.data?.errors?.status;
      setModalProps({
        ...modalProps,
        isLoading: false,
        errorMessage: statusError ?? errorMessage,
      });
    },
  });
};

export const useEmployerPayrollRunFiles = (payrollRunSlug, options = {}) => {
  return useGetForTable('/api/v1/employer/payroll-runs/[payrollRunSlug]/payroll-run-files', {
    params: { pathParams: { payrollRunSlug } },
    options,
    tableOptions: {
      dataProperty: 'payrollRunFiles',
      withQS: false,
    },
  });
};

export const usePreviewPayslipFile = (originalSlug, queryOptions = {}) =>
  useGet('/api/v1/employer/payroll-outputs/[slug]/payslip-files', {
    params: {
      pathParams: {
        slug: originalSlug,
      },
    },
    options: {
      enabled: !!originalSlug,
      select: ({ data: queryData }) => queryData.slug,
      ...queryOptions,
    },
  });

export function useDownloadGrossToNetReport({ reactQueryCallbacks = {} } = {}) {
  const { onSuccess, ...rest } = reactQueryCallbacks;
  return useMutation(
    ({ fileSlug }) =>
      fetchFile({
        pathParams: { slug: fileSlug },
      }),
    {
      onSuccess: (data, variables) => {
        generateAndDownloadDocument(data.data);
        if (typeof onSuccess === 'function') onSuccess(data, variables);
      },
      onError: () => {
        toast.error('Failed to download report');
      },
      ...rest,
    }
  );
}

function parseContractsQueryParams({ expectedPayoutDate, ...queryParams }) {
  const parsedQueryParams = queryParams;
  if (expectedPayoutDate) {
    [parsedQueryParams.expectedPayoutFrom, parsedQueryParams.expectedPayoutTo] = expectedPayoutDate;
  }

  return parsedQueryParams;
}

export const usePayrollExpectedPaymentsTable = ({ queryParams, tableOptions }) => {
  const { userCan } = useUserCan();

  return useGetForTable('/api/v1/employer/payroll/expected-payments', {
    params: {
      queryParams,
    },
    tableOptions: {
      globalFilterQueryKey: 'employeeName',
      dataProperty: 'expectedPayments',
      initialState: {
        pageSize: PAYROLL_AS_A_PRODUCT_PAGE_SIZE,
      },
      transformParams: (untransformedParams) => ({
        ...untransformedParams,
        queryParams: parseContractsQueryParams(untransformedParams.queryParams),
      }),
      ...tableOptions,
    },
    options: {
      enabled: userCan('read', Resources.employer.payroll),
    },
  });
};
