import { useGet } from '@remote-com/data-layer';
import type { UseGetOptions } from '@remote-com/data-layer';

type Path = '/api/v1/employer/company/location';

export default function useCompanyLocationDetailsData(
  options?: UseGetOptions<Path, unknown, unknown>
) {
  return useGet('/api/v1/employer/company/location', {
    options: {
      ...options,
      select: ({ data }) => data,
    },
  });
}
