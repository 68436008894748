import { Stack } from '@remote-com/norma';
import { IconAlertCircle } from '@remote-com/norma/icons/IconAlertCircle';
import { useField } from 'formik';
import styled, { useTheme } from 'styled-components';

import { FormFieldError } from '@/src/domains/registration/onboarding/shared/styles/Form';

const FormFieldErrorV2Message = styled(FormFieldError)`
  margin-top: ${({ theme }) => theme.space[2]}px;
`;

const StyledFormFieldErrorV2 = (props) => {
  const theme = useTheme();
  const [, { error, touched }] = useField(props.name);
  const showError = error && touched;
  return showError ? (
    <Stack direction="row" gap={3} pl={7} mt={3}>
      <IconAlertCircle
        width="14px"
        fill={theme.colors.negative.foreground}
        aria-label="Has errors"
      />
      <FormFieldErrorV2Message {...props} />
    </Stack>
  ) : null;
};

export const StyledFormFieldError = (props) => <StyledFormFieldErrorV2 {...props} />;
