import { homepageEvents, trackEvent } from '@remote-com/analytics';
import { Text, Box } from '@remote-com/norma';
import { useState } from 'react';

import { Button } from '@/src/components/Button';
import { WidgetCard } from '@/src/components/WidgetCard';
import { CalendlyModal } from '@/src/domains/calendly/CalendlyModal';

export const ScheduleCallPilot = () => {
  const [isModalVisible, setModalVisibility] = useState(false);

  function handleOpenScheduleACall() {
    setModalVisibility(true);
    trackEvent(homepageEvents.contentCardClicked('schedule-a-call-with-expert'));
  }

  return (
    <WidgetCard title="Got questions? Book a call!" hasBodyPadding>
      <Text color="grey.500">
        Our HR Country Experts offer free, 30-minute sessions, so you can get guidance on everything
        from employee separation, performance management, and leave types to taxes, salaries,
        payslip reconciliation, and mobility.
      </Text>
      <Box mt={7} display="flex" justifyContent="center">
        <Button onClick={handleOpenScheduleACall}>Book Now</Button>
      </Box>
      <CalendlyModal
        isVisible={isModalVisible}
        onClose={() => {
          setModalVisibility(false);
        }}
      />
    </WidgetCard>
  );
};
