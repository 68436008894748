import type { BoxProps } from '@remote-com/norma';
import { Box } from '@remote-com/norma';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { DEFAULT_SEPARATOR_COLOR } from '@/src/components/Separator/constants';

type SeparatorOrientation = 'horizontal' | 'vertical';

const getSeparatorOrientationStyles = (orientation: SeparatorOrientation) => {
  switch (orientation) {
    case 'horizontal':
      return css`
        height: var(--separator-width);
        min-width: 100%;
      `;
    case 'vertical':
      return css`
        min-height: 100%;
        width: var(--separator-width);
      `;
  }
};

const StyledSeparator = styled(Box)<{ $orientation: SeparatorOrientation }>`
  --separator-color: ${DEFAULT_SEPARATOR_COLOR};
  --separator-width: 1px;

  background-color: var(--separator-color);

  ${({ $orientation }) => getSeparatorOrientationStyles($orientation)}
`;

interface SeparatorProps extends Omit<HTMLAttributes<HTMLDivElement>, 'color'>, BoxProps {
  orientation?: SeparatorOrientation;
}

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>(
  ({ orientation = 'horizontal', role = 'separator', ...props }, ref) => {
    return <StyledSeparator $orientation={orientation} ref={ref} role={role} {...props} />;
  }
);
