import { Stack } from '../../layout';
import { Media, MediaBody, MediaObject } from '../media';

import { Component } from './Skeleton.styled';

type SkeletonProps = {
  /**
   * Add custom classnames (e.g. for use with styled components)
   */
  className?: string;
  /**
   * Height of the skeleton.
   * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
   */
  height?: number;
  /**
   * The type of content that will be rendered.
   */
  variant?: 'text' | 'rect' | 'circle';
  /**
   * Width of the skeleton.
   * Useful when the skeleton is inside an inline element with no width of its own.
   */
  width?: '100%' | number | `${number}%`;
};

export function Skeleton({ variant = 'text', ...props }: SkeletonProps) {
  return <Component $variant={variant} data-testid="skeleton-loader" {...props} />;
}

export function ListSkeleton() {
  return (
    <Stack gap={3}>
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
    </Stack>
  );
}

type MediaSkeletonProps = {
  hasDescription?: boolean;
  width?: SkeletonProps['width'];
  height?: SkeletonProps['height'];
};

export function MediaSkeleton({
  hasDescription = true,
  width = 30,
  height = 30,
}: MediaSkeletonProps) {
  return (
    <Media>
      <MediaObject>
        <Skeleton variant="circle" width={width} height={height} />
      </MediaObject>
      <MediaBody>
        <Skeleton variant="text" width={width} />
        {hasDescription && <Skeleton variant="text" />}
      </MediaBody>
    </Media>
  );
}
