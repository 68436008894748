import { useIsInsideTableFilter } from '../context/FilterInputSizeContext';
import { useIsInsideTableBody } from '../context/TableBodyInputSizeContext';

type SizeProp = 'sm' | 'md';
type LabelPlacementProp = 'inside' | 'outside';

/**
 * Converts v1 (deprecated) sizes to v2 valid sizes
 * @param {String} sizeProp - size prop
 * @returns a valid size for InputSelect ('md' or 'sm')
 */
const compatibilitySize = (sizeProp: SizeProp): SizeProp => {
  if (sizeProp === 'sm') {
    return 'sm';
  }

  // any other size returns 'md'
  return 'md';
};

/**
 * Aux hook that determines the correct 'size' and 'labelPlacement' values
 * based on the provided props AND if the form is inside a filter popover (inputs
 * should be small and have the label placed outside the container for those cases)
 * @param {'sm'|'md'} sizeProp
 * @param {'inside'|'outside'} labelPlacementProp
 * @returns {Object}
 */
export function useGetInputSizeAndLabelPlacement(
  sizeProp: SizeProp,
  labelPlacementProp: LabelPlacementProp
) {
  const isInputInsideTableFilter = useIsInsideTableFilter();
  const isInputInsideTableBody = useIsInsideTableBody();
  let size: SizeProp;
  let labelPlacement: LabelPlacementProp;

  if (isInputInsideTableFilter) {
    size = 'sm';
    labelPlacement = 'outside';
  } else if (isInputInsideTableBody) {
    size = 'sm';
    labelPlacement = 'inside';
  } else {
    size = compatibilitySize(sizeProp);
    // If the size is 'sm', keep the provided label placement; otherwise, default to 'inside'
    labelPlacement = size === 'sm' ? labelPlacementProp : 'inside';
  }

  return { labelPlacement, size };
}
