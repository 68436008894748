import styled from 'styled-components';

import { CardDrawer, CardDrawerBody } from '@/src/components/CardDrawer';
import { NotificationsListFooter } from '@/src/domains/inAppNotifications/NotificationsListFooter';
import { useTodosOverlayContext } from '@/src/domains/todos/TodosOverlayProvider';
import { useTodosContext } from '@/src/domains/todos/TodosProvider';

import { TodosOverlayEmptyState } from './TodosOverlayEmptyState';
import { TodosOverlayError } from './TodosOverlayError';
import { TodosOverlayHeader } from './TodosOverlayHeader';
import { TodosOverlayList } from './TodosOverlayList';
import { TodosOverlayLoader } from './TodosOverlayLoader';

const StyledCardDrawerBody = styled(CardDrawerBody)`
  padding: 0;
`;

type TodosOverlayProps = {
  onClose: () => void;
};

export const TodosOverlay = ({ onClose }: TodosOverlayProps) => {
  const { isOverlayOpen } = useTodosOverlayContext();
  const { todos, totalGroupedTodos = 0, hasNextPage, status } = useTodosContext();

  const hasTodos = todos.length > 0;

  return (
    <CardDrawer open={isOverlayOpen} onClose={onClose}>
      <TodosOverlayHeader />
      <StyledCardDrawerBody>
        {status === 'success' && hasTodos && <TodosOverlayList />}
        {status === 'success' && !hasTodos && <TodosOverlayEmptyState />}
        {status === 'loading' && <TodosOverlayLoader />}
        {status === 'error' && <TodosOverlayError />}
      </StyledCardDrawerBody>
      {status === 'success' && hasTodos && (
        <NotificationsListFooter
          hasNextPage={hasNextPage}
          totalCount={totalGroupedTodos}
          currentCount={todos.length}
          singularWord="to-do"
          pluralWord="to-dos"
        />
      )}
    </CardDrawer>
  );
};
