// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneBuilding = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M3 5c0-1.1.9-2 2-2h6a2 2 0 0 1 2 2v15.25H3V5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M6 6.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5H6ZM5.25 11c0-.41.34-.75.75-.75h4a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75ZM13 11h6a2 2 0 0 1 2 2v7.25h1a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1 0-1.5h11V11Zm-7 3.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5H6Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneBuilding);
export { ForwardRef as IconV2DuotoneBuilding };