/* eslint-disable import/no-extraneous-dependencies */
import {
  Close,
  Content,
  Overlay,
  Root,
  Portal,
  Trigger as RadixTrigger,
} from '@radix-ui/react-dialog';
import { AnimatePresence } from 'framer-motion';
import type { PropsWithChildren, ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { RequireAtLeastOne } from 'type-fest';

import { IconClosePanel } from '../../icons/build/IconClosePanel';

import type { DrawerContentProps } from './Drawer.styled';
import { DrawerCloseButton, DrawerOverlay, DrawerContent, DrawerBackground } from './Drawer.styled';

export type DrawerProps = RequireAtLeastOne<
  PropsWithChildren<{
    /**
     * Callback function to be invoked when the Drawer is closed.
     */
    onClose?: () => void;
    /**
     * Whether the Drawer is visible or not
     */
    isVisible?: boolean;
    /**
     * Whether close button is visible or not
     */
    disableClose?: boolean;
    /**
     * The component that will trigger the drawer when pressed.
     * Read more about it at Drawer.Trigger.stories.mdx.
     */
    Trigger:
      | 'TODO_MIGRATE_TO_COMPONENT_DONT_COPY'
      | ReactElement
      /* When we need a Drawer in a DropdownMenu, we cannot use a Trigger because the menu gets closed (unmounted) and the drawer is closed too. */
      | 'CANNOT_USE_BECAUSE_ITS_A_DROPDOWNMENU';
    /**
     * An optional identifier for the overlay element that covers the inactive
     * part of the screen when the dialog is open
     */
    overlayId?: string;
    /**
     * If the dialog has no visible title and aria-labelledby can't be used,
     * we need to use aria-label to provide context for users with screen readers.
     * */
    'aria-label'?: string;
    /**
     * The preferred way to use this component in an accessible way
     * is passing an id to a heading inside the dialog,
     * e.g. an `<h3>` or the built-in `<DrawerTitle>` component.
     * Then using aria-labelledby with the id of the heading as value.
     * */
    'aria-labelledby'?: string;
  }> &
    Omit<DrawerContentProps, '$hasCloseButton'>,
  'aria-labelledby' | 'aria-label'
>;

export function useDrawer(initialState = false): [boolean, () => void] {
  const [isDrawerVisible, setDrawerVisibility] = useState(initialState);

  // Return the same reference to avoid unexpected errors like the one in https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/12988
  const handleDrawerVisibility = useCallback(() => {
    setDrawerVisibility((prevState) => !prevState);
  }, []);

  return [isDrawerVisible, handleDrawerVisibility];
}

const drawerBackgroundAnimationProps = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { type: 'tween', duration: 0.5, ease: 'circOut' },
  },
  exit: {
    opacity: 0,
    transition: { type: 'tween', duration: 0.25, ease: 'circOut' },
  },
};

const drawerContentAnimationProps = {
  initial: { x: '100%' },
  animate: {
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      delay: 0.15,
      ease: [0.25, 1, 0.5, 1],
    },
    transitionEnd: {
      x: 0,
    },
  },
  exit: { x: '100%', transition: { type: 'tween', duration: 0.15, ease: 'easeIn' } },
};

const drawerCloseButtonAnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.35, duration: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.1 } },
  transition: {
    type: 'tween',

    ease: [0.25, 1, 0.5, 1],
  },
};

export function Drawer({
  children,
  onClose,
  isVisible,
  disableClose,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  $layout,
  $maxWidth = '540px',
  $zIndex = 999999,
  Trigger,
  overlayId,
  ...rest
}: DrawerProps) {
  const onDismiss = disableClose ? () => {} : onClose;
  const isControlled = typeof isVisible !== 'undefined';
  const hasRadixTrigger = !!Trigger && typeof Trigger !== 'string';

  /*
    NOTE: When using `react-url-modal`, the component will be removed when `onDismiss()`` is executed.
    This prevents the exit animation from playing. To counter this, we have to handle state internally
    and execute `onDismiss()` only after the animation has completed.
  */
  const [isReallyVisible, setIsReallyVisible] = useState(isVisible);
  const startDismissal = disableClose
    ? () => {}
    : () => {
        // Note: we only do this when controlled because `onOpenChange` handles the changes when uncontrolled.
        if (isControlled) {
          setIsReallyVisible(false);
        }
      };

  const onOpenChange = useCallback(
    (nextOpen) => {
      if (!isControlled) {
        // Note: We still need to manage the state for the presence animation when this Drawer is being used as uncontrolled.
        setIsReallyVisible(nextOpen);
      }
    },
    [isControlled]
  );

  useEffect(() => {
    if (isVisible) {
      setIsReallyVisible(isVisible);
    }
  }, [isVisible]);

  /*
    NOTE: Reach UI's Dialog component will set overflow on the body element to `hidden`
    which causes layout shift for users that have scrollbars visible at all times.
    This effect will overwrite the default overflow setting while still preventing
    users from scrolling when a drawer is visible.
  */
  useEffect(() => {
    document.body.style.setProperty('overflow', 'auto', 'important');

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Root open={isControlled ? isVisible : undefined} modal onOpenChange={onOpenChange}>
      {hasRadixTrigger && <RadixTrigger asChild>{Trigger}</RadixTrigger>}
      <Portal forceMount={isControlled ? undefined : true}>
        <AnimatePresence onExitComplete={onDismiss}>
          {isReallyVisible && (
            <Overlay asChild id={overlayId}>
              <DrawerOverlay data-dd-action-name="Close drawer" $zIndex={$zIndex}>
                <DrawerBackground {...drawerBackgroundAnimationProps} />
                <Content
                  asChild
                  onEscapeKeyDown={startDismissal}
                  onPointerDownOutside={startDismissal}
                >
                  <DrawerContent
                    $layout={$layout}
                    $maxWidth={$maxWidth}
                    $hasCloseButton={!disableClose}
                    aria-label={ariaLabel}
                    aria-labelledby={ariaLabelledBy}
                    {...drawerContentAnimationProps}
                    {...rest}
                  >
                    {!disableClose && (
                      <Close asChild>
                        <DrawerCloseButton
                          aria-label="Close drawer"
                          Icon={IconClosePanel}
                          onClick={isControlled ? startDismissal : undefined}
                          tone="secondary"
                          variant="ghost"
                          {...drawerCloseButtonAnimationProps}
                        />
                      </Close>
                    )}
                    {children}
                  </DrawerContent>
                </Content>
              </DrawerOverlay>
            </Overlay>
          )}
        </AnimatePresence>
      </Portal>
    </Root>
  );
}
