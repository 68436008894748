// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneReceipt = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M12.09 1.8c-.74-.63-1.12-.95-1.53-1.07a2 2 0 0 0-1.12 0c-.41.12-.79.44-1.53 1.07-.56.48-.84.72-1.16.85a2 2 0 0 1-.88.13c-.34-.03-.68-.18-1.35-.48l-2.15-.95c-.37-.17-.56-.25-.7-.23a.5.5 0 0 0-.33.21c-.09.13-.09.34-.09.75v12.67c0 2.8 0 4.2.54 5.27a5 5 0 0 0 2.19 2.18c1.07.55 2.47.55 5.27.55h9.5V2.08c0-.41 0-.62-.09-.75a.5.5 0 0 0-.32-.2c-.15-.03-.34.05-.71.22l-2.15.95c-.67.3-1 .45-1.35.48a2 2 0 0 1-.88-.13c-.32-.13-.6-.37-1.16-.85Z" /><path fillRule="evenodd" clipRule="evenodd" d="M6.5 8.25a.75.75 0 0 0 0 1.5H11a.75.75 0 0 0 0-1.5H6.5Zm.5 3.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm3.5.5a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3ZM7 15.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm3.5.5a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Zm10.03 6.4c-.39.1-.85.1-1.78.1v-11.5h.8c1.12 0 1.68 0 2.1.22.38.19.69.5.88.87.22.43.22.99.22 2.11v4.3c0 .93 0 1.4-.1 1.78a3 3 0 0 1-2.12 2.12Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneReceipt);
export { ForwardRef as IconV2DuotoneReceipt };