import { Avatar, type AvatarProps } from '@remote-com/norma';

import { ItemAvatar } from '@/src/domains/inAppNotifications/InAppNotifications.styled';

type NotificationItemAvatarProps = Pick<AvatarProps, 'name' | 'src'>;

export const NotificationItemAvatar = ({ name, src }: NotificationItemAvatarProps) => (
  <ItemAvatar>
    <Avatar src={src} name={name} size="md" />
  </ItemAvatar>
);
