import { Tooltip, Box } from '@remote-com/norma';
import { IconAlertTriangle } from '@remote-com/norma/icons/IconAlertTriangle';
import styled from 'styled-components';

import { PersonCell } from '@/src/components/Table';
import { EMPLOYMENT_VERIFICATION } from '@/src/domains/mobility/employmentVerification/constants';

const IconWrapper = styled(Box).attrs({ display: 'flex', flexShrink: 0, color: 'orange.400' })``;

export function PersonCellWithIdentityWarning({ value, row, extra, wrapNameInLink }) {
  const identifyWasDeclined =
    row?.original?.contract?.contractEligibility?.identityVerificationStatus ===
    EMPLOYMENT_VERIFICATION.DECLINED;

  return (
    <PersonCell
      value={value}
      wrapNameInLink={wrapNameInLink}
      extra={
        <>
          {extra}
          {identifyWasDeclined && (
            <Tooltip
              label={`You declined ${value.name}’s identity. We’re reviewing the case and will be in touch.`}
            >
              <IconWrapper>
                <IconAlertTriangle width="16px" />
              </IconWrapper>
            </Tooltip>
          )}
        </>
      }
    />
  );
}
