// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneFilesText = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M8.45 1.25c-2.52 0-3.78 0-4.74.49a4.5 4.5 0 0 0-1.97 1.97c-.49.96-.49 2.22-.49 4.74v7.1c0 2.52 0 3.78.49 4.74a4.5 4.5 0 0 0 1.97 1.97c.96.49 2.22.49 4.74.49h1.1c2.52 0 3.78 0 4.74-.5a4.5 4.5 0 0 0 1.97-1.96c.49-.96.49-2.22.49-4.74v-7.1c0-2.52 0-3.78-.5-4.74a4.5 4.5 0 0 0-1.96-1.97c-.96-.49-2.22-.49-4.74-.49h-1.1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M19 5.25c.41 0 .75.34.75.75v12a.75.75 0 0 1-1.5 0V6c0-.41.34-.75.75-.75ZM5.25 7c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H6A.75.75 0 0 1 5.25 7ZM22 7.25c.41 0 .75.34.75.75v8a.75.75 0 0 1-1.5 0V8c0-.41.34-.75.75-.75ZM5.25 11c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Zm0 4c0-.41.34-.75.75-.75h2a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneFilesText);
export { ForwardRef as IconV2DuotoneFilesText };