// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconShieldCheck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M11.65 1.06a1 1 0 0 1 .7 0l8 3A1 1 0 0 1 21 5v7c0 3.45-2.28 6.2-4.34 8a22.68 22.68 0 0 1-4.18 2.88h-.02v.01h-.01L12 22l-.45.9v-.01l-.03-.01a8.28 8.28 0 0 1-.35-.19l-.95-.56c-.78-.49-1.83-1.2-2.88-2.13C5.28 18.2 3 15.45 3 12V5a1 1 0 0 1 .65-.94l8-3ZM12 22l-.45.9a1 1 0 0 0 .9 0L12 22Zm0-1.14a19.86 19.86 0 0 0 3.34-2.36C17.28 16.8 19 14.55 19 12V5.7l-7-2.63-7 2.62V12c0 2.55 1.72 4.8 3.66 6.5A20.7 20.7 0 0 0 12 20.86Z" /><path fillRule="evenodd" clipRule="evenodd" d="m16.69 10.16-5.5 5.07a.99.99 0 0 1-.69.27 1.02 1.02 0 0 1-.7-.27l-2.48-2.3a.95.95 0 0 1-.32-.7.92.92 0 0 1 .31-.69.99.99 0 0 1 .7-.27 1.02 1.02 0 0 1 .68.27l1.81 1.67 4.8-4.44a.99.99 0 0 1 .7-.27 1.02 1.02 0 0 1 .92.58.92.92 0 0 1-.23 1.08Z" /></svg>;
const ForwardRef = forwardRef(IconShieldCheck);
export { ForwardRef as IconShieldCheck };