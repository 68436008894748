import { Text } from '@remote-com/norma';
import uniq from 'lodash/uniq';
import type { ReactElement } from 'react';

import {
  FILE_HUMAN_READABLE_FORMATS as HUMAN_READABLE_FORMATS,
  FILE_MAX_SIZE_BYTES as MAX_FILE_SIZE_BYTES,
  convertFileBytesToMb as convertBytesToMb,
} from '../../../foundations';

/**
 * Don't re-export this or use it anywhere else.
 * It's duplicated here to assist the migration of Input File to Norma only.
 *
 * @todo Use Intl format list
 */
function joinArray(arr: string[], options: { lastDelimiter: string }): string {
  const { lastDelimiter } = options;

  if (arr.length > 1) {
    const [lastType] = arr.splice(arr.length - 1);
    return `${arr.join(', ')} ${lastDelimiter} ${lastType}`;
  }

  return arr[0];
}

type Props = {
  maxSize: number;
  supportedTypes: string[];
};

export function InputFileDropRequirements(props: Props): ReactElement {
  const supportedTypes = props.supportedTypes ?? [];
  const maxSize = props.maxSize ?? MAX_FILE_SIZE_BYTES;

  const readableSupportedTypes = uniq(
    supportedTypes.map((type) => HUMAN_READABLE_FORMATS[type] || type)
  );

  return (
    <Text variant="xs" color="grey.500">
      Maximum file size of {convertBytesToMb(maxSize)} MB.{' '}
      {!!readableSupportedTypes.length &&
        `Supports: ${joinArray(readableSupportedTypes, { lastDelimiter: 'and' })}`}
    </Text>
  );
}
