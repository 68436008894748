import { Box, ContentSwitcher, Stack, Text } from '@remote-com/norma';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';

import { RadioCardGroupField } from '@/src/components/Ui/Form';
import { getBenefitPlanTypeIcon } from '@/src/domains/benefits/constants';
import { TierCost } from '@/src/domains/benefits/shared/TierCost';

import { TierCard, TierCardDescription } from './MultipleGroups.styled';

function getIllustration(group, optOut = false) {
  const tiers = group.benefitSubgroups?.[0]?.benefitTiers || group.benefitTiers;
  const type = tiers[0]?.benefitPlans[0]?.type;

  const { backgroundColor, color, Icon } = getBenefitPlanTypeIcon(type, optOut);

  return (
    <Box
      color={color}
      backgroundColor={backgroundColor}
      height={optOut ? '32px' : '100%'}
      width="32px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="5px"
    >
      <Icon width="20px" />
    </Box>
  );
}

const GroupHeader = ({ group, index, onToggleChange, toggles, showGroupName }) => (
  <Box display="flex" mt={4} mb={4} alignItems="center">
    {showGroupName && (
      <Text variant="smMedium" textTransform="uppercase" as="h2" color="bayoux">
        {`${index + 1}. ${group.name}`}
      </Text>
    )}
    {group.benefitSubgroups.length > 0 && (
      <ContentSwitcher
        data-testid={`filter-${group.internalName}`}
        value={toggles[group.internalName]}
        ml="auto"
        onChange={(val) => {
          onToggleChange(group, val);
        }}
      >
        {group.benefitSubgroups.map(({ name, internalName }) => (
          <ContentSwitcher.Button
            key={internalName}
            value={internalName}
            data-testid={`toggle-${group.internalName}-${internalName}`}
          >
            {name}
          </ContentSwitcher.Button>
        ))}
      </ContentSwitcher>
    )}
  </Box>
);

function GroupOptions({ tiers, showOptOutOption, parentGroup, localValues, optOutLabel }) {
  if (tiers.length === 0) {
    return null;
  }

  return (
    <>
      {tiers.map((tier) => (
        <TierCard
          key={tier.slug}
          name={parentGroup.internalName}
          id={tier.value}
          label={
            <Stack direction="row" mt={tier.displayCost ? 3 : 0} verticalAlign="middle" gap="3">
              <Text color="grey.900">
                {(tier.name || '').replace(' - Employee Only', '').replace(' - Family', '')}
              </Text>
              {tier.displayCost && <TierCost cost={tier.displayCost} />}
            </Stack>
          }
          data-testid={`tier-${tier.slug}`}
          description={
            <TierCardDescription
              detailsUrl={tier.url}
              description={tier.description}
              providerName={tier.benefitPlans[0]?.carrier?.name}
            />
          }
          value={tier.value}
          Illustration={() => getIllustration(parentGroup)}
          checked={localValues[parentGroup.internalName] === tier.value}
        />
      ))}
      {showOptOutOption && (
        <TierCard
          id={`${parentGroup.internalName}_no`}
          data-testid={`${parentGroup.internalName}_no`}
          label={optOutLabel}
          value="no"
          name={parentGroup.internalName}
          Illustration={() => getIllustration(parentGroup, true)}
          checked={['no', 'opted_out'].includes(localValues[parentGroup.internalName])}
        />
      )}
    </>
  );
}

function changeOptedOutToNo(benefitTier) {
  return !benefitTier || benefitTier === 'opted_out' || !benefitTier.value
    ? 'no'
    : benefitTier.value;
}

function getSubGroupTiers(group) {
  return group.benefitSubgroups.reduce((tiers, subgroup) => {
    const subGroupsTiers = subgroup.benefitTiers.reduce(
      (subGroupTiers, tier) => [...subGroupTiers, { ...tier, subgroupName: subgroup.internalName }],
      []
    );
    return [...tiers, ...subGroupsTiers];
  }, []);
}

export function getSubgroupTogglesInitialValues(groups = [], values = {}) {
  const subgroupToggle = {};

  groups?.forEach((group) => {
    if (group.benefitSubgroups.length > 0) {
      const fallback = group.benefitSubgroups[0].internalName;
      const preselectedValue = values === '' || values === null ? null : values[group.internalName];

      // if there was a pre-selected value on the toggle
      if (preselectedValue) {
        const allSubGroupTiers = getSubGroupTiers(group);
        const preselectedTier = allSubGroupTiers.find(
          (tier) => changeOptedOutToNo(tier) === preselectedValue
        );

        if (preselectedTier) {
          subgroupToggle[group.internalName] = preselectedTier.subgroupName;
        } else {
          subgroupToggle[group.internalName] = fallback;
        }
      } else {
        subgroupToggle[group.internalName] = fallback;
      }
    }
  });

  return subgroupToggle;
}

function addInternalNameToGroups(groups) {
  return groups.map((group) => ({
    ...group,
    internalName: snakeCase(group.name),
    benefitSubgroups: (group.benefitSubgroups || []).map((subgroup) => ({
      ...subgroup,
      internalName: snakeCase(subgroup.name),
    })),
  }));
}

function addInternalNameToValues(values) {
  if (values) {
    return Object.entries(values).reduce((newValues, [key, value]) => {
      newValues[snakeCase(key)] = value;

      return newValues;
    }, {});
  }
  return values;
}

/**
 * @deprecated - deprecation in progress (in favour of Octopus)
 */
export function MultipleGroups({ onChange, groups, forceShowOptOut, values }) {
  const groupsWithInternalName = useMemo(() => addInternalNameToGroups(groups), [groups]);
  const valuesWithInternalName = useMemo(() => addInternalNameToValues(values), [values]);

  // FAMOUS LAST WORDS:
  // `values` prop is passed with the same form every time, since we hide whole benefits section while things're loading
  // that way we only need to pass initial values once, and we can skip updating them in useEffect()
  const [localValues, setLocalValues] = useState(valuesWithInternalName || {});
  const subgroupTogglesInitialValues = useMemo(
    () => getSubgroupTogglesInitialValues(groupsWithInternalName, valuesWithInternalName),
    [groupsWithInternalName, valuesWithInternalName]
  );
  const [subgroupToggles, setSubgroupToggles] = useState(subgroupTogglesInitialValues);

  function handleSelectionChange({ name, value }) {
    const newLocalValues = { ...localValues, [name]: value };
    setLocalValues(newLocalValues);

    const groupKeys = groupsWithInternalName.map((group) => group.internalName);
    const localValuesKeys = Object.keys(newLocalValues);
    const areAllGroupsSelected = groupKeys.every((key) => localValuesKeys.includes(key));

    // we need to skip keys that are coming from inactive/old groups
    // see: https://linear.app/remote/issue/BNFT-1168/contract-details-benefit-selection-for-default-group-is-still-sent
    const groupKeysSet = new Set(groupKeys);
    const cleanValues = Object.entries(newLocalValues).reduce(
      (acc, [key, val]) => ({
        ...acc,
        ...(groupKeysSet.has(key) ? { [key]: val } : {}),
      }),
      {}
    );

    onChange(areAllGroupsSelected ? cleanValues : null);
  }

  function handleToggleChange(group, value) {
    setSubgroupToggles((state) => ({
      ...state,
      [group.internalName]: snakeCase(value),
    }));

    // Erase the selection for that group
    handleSelectionChange({ name: group.internalName, value: null });
  }

  const optOutLabel =
    groupsWithInternalName?.length === 1
      ? "I don't want to offer benefits to this employee."
      : "I don't want to offer this benefit.";

  return (
    <Stack gap={7}>
      {groupsWithInternalName.map((group, index) => (
        <Stack key={`${group}-${index}`}>
          <GroupHeader
            group={group}
            index={index}
            toggles={subgroupToggles}
            onToggleChange={handleToggleChange}
            showGroupName={groupsWithInternalName.length > 1}
          />
          <RadioCardGroupField
            name={group.internalName || 'default_group'}
            onChange={(evt) => handleSelectionChange(evt.target)}
            data-testid={`tiers-group-${group.internalName || 'default_group'}`}
          >
            {group.benefitSubgroups.length === 0 ? (
              <GroupOptions
                name={group.internalName}
                showOptOutOption={group.employerCanOptOut || forceShowOptOut}
                localValues={localValues}
                parentGroup={group}
                tiers={group.benefitTiers}
                key={group.slug}
                optOutLabel={optOutLabel}
              />
            ) : (
              group.benefitSubgroups
                .filter((s) => s.internalName === subgroupToggles[group.internalName])
                .map((subgroup) => (
                  <GroupOptions
                    name={group.internalName}
                    // take from the parent group, since BE does it also
                    showOptOutOption={group.employerCanOptOut || forceShowOptOut}
                    localValues={localValues}
                    parentGroup={group}
                    tiers={subgroup.benefitTiers}
                    key={subgroup.slug}
                    optOutLabel={optOutLabel}
                  />
                ))
            )}
          </RadioCardGroupField>
        </Stack>
      ))}
    </Stack>
  );
}

MultipleGroups.propTypes = {
  groups: PropTypes.array.isRequired,
  forceShowOptOut: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
