import { Box } from '@remote-com/norma';
import Image from 'next/image';
import styled from 'styled-components';

export const StyledImage = styled(Image)`
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpointValues.medium}px) {
    display: none;
  }
`;

export const ContentWrapper = styled(Box)`
  width: 65%;
  > * {
    margin-top: ${({ theme }) => theme.space[6]}px;
  }
`;
