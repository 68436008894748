import pick from 'lodash/pick';
import type { HTMLAttributes, ReactNode } from 'react';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { BaseTextCell } from '@/src/components/Table/Cells';
import TableCell from '@/src/components/Table/Components/TableCell';
import { filterHiddenColumns, filterHiddenHeaders } from '@/src/components/Table/helpers';
import { CHECKBOX_COLUMN_ID } from '@/src/components/Table/helpers/injectCheckbox';

const StyledTableFootDataCell = styled(TableCell)<{ $colSpan: number }>`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[200]};
  grid-column: ${({ $colSpan }) => `span ${$colSpan}`};
  background-color: ${({ theme }) => theme.colors.blank};
`;

type ReactTableRenderedFooter = ReactNode | string | number;

type ReactTableColumn = {
  id: string;
  Footer?: ReactNode;
  sticky?: string;
};

type ReactTableFooterGroup = {
  getFooterGroupProps: () => {
    key: string;
  };
  headers: {
    getFooterProps: () => {
      colSpan: number;
      key: string;
    };
    getHeaderProps: () => {
      'data-sticky-td'?: boolean;
      'data-sticky-left-td'?: boolean;
      'data-sticky-right-td'?: boolean;
      [key: string]: unknown;
    };
    id: string;
    render: (key: string) => ReactTableRenderedFooter;
  }[];
};

type TableFootDataCell = {
  colSpan: number;
  isTitleColumn: boolean;
  key: string;
  sticky?: string;
  value: ReactTableRenderedFooter | null;
};

type TableFootGroup = {
  columns: TableFootDataCell[];
  key: string;
};

export function getTableFootGroups({
  reactTableColumns,
  reactTableFooterGroups,
}: {
  reactTableColumns: ReactTableColumn[];
  reactTableFooterGroups: ReactTableFooterGroup[];
}): TableFootGroup[] {
  const titleColumnIndex =
    reactTableColumns.findIndex((column) => column.id === CHECKBOX_COLUMN_ID) + 1;

  const filteredColumns = filterHiddenColumns(reactTableColumns);

  return reactTableFooterGroups.map((group) => ({
    ...group.getFooterGroupProps(),
    columns: filterHiddenHeaders(group.headers).map((column, columnIndex) => {
      const isTitleColumn = columnIndex === titleColumnIndex;
      const hasFooterDefinition = !!filteredColumns[columnIndex].Footer;

      const pickedHeaderProps = pick(column.getHeaderProps(), [
        'data-sticky-td',
        'data-sticky-left-td',
        'data-sticky-right-td',
      ]);

      return {
        ...column.getFooterProps(),
        ...pickedHeaderProps,
        isTitleColumn,
        value: hasFooterDefinition ? column.render('Footer') : null,
      };
    }),
  }));
}

type TableFootElement = HTMLTableSectionElement;

type TableFootProps = {
  groups: TableFootGroup[];
  footerTotalsLabel?: string | ReactNode;
};

export const TableFoot = forwardRef<
  TableFootElement,
  TableFootProps & HTMLAttributes<TableFootElement>
>(({ groups, footerTotalsLabel = 'Total', ...tfootProps }, ref) => {
  return (
    <tfoot {...tfootProps} data-testid="table-foot" ref={ref}>
      {groups.map((group, groupIndex) => {
        const isFirstGroup = groupIndex === 0;

        return (
          <tr key={group.key} data-testid="table-foot-group">
            {group.columns.map(({ colSpan, key, isTitleColumn, value, ...columnProps }) => (
              <StyledTableFootDataCell
                {...columnProps}
                $colSpan={colSpan}
                data-testid="table-foot-column"
                key={key}
              >
                {isFirstGroup && isTitleColumn && !value ? (
                  <BaseTextCell data-testid="table-foot-total-label">
                    {footerTotalsLabel}
                  </BaseTextCell>
                ) : (
                  value
                )}
              </StyledTableFootDataCell>
            ))}
          </tr>
        );
      })}
    </tfoot>
  );
});
