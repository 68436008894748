import type { BadgeType, PillTone } from '@remote-com/norma';
import pick from 'lodash/pick';
import getConfig from 'next/config';
import type { Except, ValueOf } from 'type-fest';

import { generateSelectOptions } from '@/src/helpers/forms';

const { publicRuntimeConfig } = getConfig();

export const offboardingStatus = {
  REQUESTED: 'requested',
  IN_REVIEW: 'in_review',
  DELETED: 'deleted',
  COMPLETED: 'completed',
  PENDING_PAYMENT: 'pending_payment',
} as const;

export type OffboardingStatus = ValueOf<typeof offboardingStatus>;

export const nonDeletedOffboardingStatuses = Object.values(offboardingStatus).filter(
  (status) => status !== offboardingStatus.DELETED
);

export const handedToPayrollOffboardingStatuses = [
  offboardingStatus.PENDING_PAYMENT,
  offboardingStatus.COMPLETED,
];

export const offboardingStatusLabels: { [key in OffboardingStatus]: string } = {
  [offboardingStatus.REQUESTED]: 'Requested',
  [offboardingStatus.IN_REVIEW]: 'In review',
  [offboardingStatus.PENDING_PAYMENT]: 'Pending payment',
  [offboardingStatus.COMPLETED]: 'Completed',
  [offboardingStatus.DELETED]: 'Canceled',
};

export const offboardingStatusDescription = {
  [offboardingStatus.REQUESTED]:
    'We’ve received the offboarding request. We’ll review the request soon.',
  [offboardingStatus.IN_REVIEW]: 'We’re reviewing and processing the offboarding request now.',
  [offboardingStatus.PENDING_PAYMENT]:
    'Our payroll team is processing the last payment for this employee.',
  [offboardingStatus.COMPLETED]:
    'We’ve sent the employee their last payment and their offboarding is now complete.',
  [offboardingStatus.DELETED]: 'The offboarding has been canceled.',
};

export const offboardingStatusBadge: { [key in OffboardingStatus]: string } = {
  [offboardingStatus.REQUESTED]: 'pending',
  [offboardingStatus.IN_REVIEW]: 'info',
  [offboardingStatus.DELETED]: 'inactive',
  [offboardingStatus.COMPLETED]: 'active',
  [offboardingStatus.PENDING_PAYMENT]: 'scheduled',
} as const;

export const offboardingStatusOptions = generateSelectOptions(offboardingStatusLabels) as {
  value: OffboardingStatus;
  label: string;
}[];

export const offboardingType = {
  TERMINATION: 'termination',
  RESIGNATION: 'resignation',
  RELOCATION: 'relocation',
  CANCELLATION: 'cancellation',
  TRANSFER: 'transfer',
  CONVERSION: 'conversion',
} as const;

export type OffboardingType = ValueOf<typeof offboardingType>;

export const offboardingTypeLabels: { [key in OffboardingType]: string } = {
  [offboardingType.TERMINATION]: 'Termination',
  [offboardingType.RESIGNATION]: 'Resignation',
  [offboardingType.RELOCATION]: 'Relocation',
  [offboardingType.CANCELLATION]: 'Cancelation',
  [offboardingType.TRANSFER]: 'Transfer',
  [offboardingType.CONVERSION]: 'Conversion',
} as const;

export const offboardingTypeOptions = generateSelectOptions(offboardingTypeLabels);

export const modalDirectOffboardingTypeOptions = generateSelectOptions(
  pick(offboardingTypeLabels, [offboardingType.RESIGNATION, offboardingType.TERMINATION])
);

export const offboardingTypeTone: { [key in OffboardingType]: string } = {
  [offboardingType.TERMINATION]: 'fuchsia',
  [offboardingType.RESIGNATION]: 'radiance',
  [offboardingType.RELOCATION]: 'turquoise',
  [offboardingType.CANCELLATION]: 'neutralDark',
  [offboardingType.TRANSFER]: 'purple',
  [offboardingType.CONVERSION]: 'irisBlue',
} as const;

export const subType = {
  RESIGNATION: 'resignation',
  TERMINATION: 'termination',
  TUPE: 'tupe',
} as const;

export type OffboardingSubType = ValueOf<typeof subType>;

export const subTypeLabels: { [key in OffboardingSubType]: string } = {
  [subType.RESIGNATION]: 'Resignation',
  [subType.TERMINATION]: 'Termination',
  [subType.TUPE]: 'Transfer of Undertakings (Protection of Employment)',
} as const;

export const resignationReasons = {
  RESIGNATION_REASON_PERSONAL_REASONS: 'resignation_reason_personal_reasons',
  RESIGNATION_REASON_MUTUAL_AGREEMENT: 'resignation_reason_mutual_agreement',
  RESIGNATION_REASON_OTHER_JOB_OPPORTUNITY: 'resignation_reason_other_job_opportunity',
  RESIGNATION_REASON_REMUNERATION_AND_BENEFITS: 'resignation_reason_remuneration_and_benefits',
  RESIGNATION_REASON_LEADERSHIP: 'resignation_reason_leadership',
  RESIGNATION_REASON_LACK_OF_RECOGNITION: 'resignation_reason_lack_of_recognition',
  RESIGNATION_REASON_RETIREMENT: 'resignation_reason_retirement',
  RESIGNATION_REASON_COMPANY_CULTURE_OR_VALUES: 'resignation_reason_company_culture_or_values',
  RESIGNATION_REASON_POSITION_DOES_NOT_MEET_EXPECTATIONS:
    'resignation_reason_position_does_not_meet_expectations',
  RESIGNATION_REASON_INFRASTRUCTURE_CHALLENGES: 'resignation_reason_infrastructure_challenges',
  RESIGNATION_REASON_RELATIONSHIP_WITH_COWORKERS: 'resignation_reason_relationship_with_coworkers',
  RESIGNATION_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES:
    'resignation_reason_incapacity_to_perform_inherent_duties',
  RESIGNATION_REASON_DISSATISFACTION_WITH_REMOTE_SERVICE:
    'resignation_reason_dissatisfaction_with_remote_service',
  RESIGNATION_REASON_RELOCATION_LEAVING_REMOTE: 'resignation_reason_relocation_leaving_remote',
  RESIGNATION_REASON_OTHER: 'resignation_reason_other',
} as const;

export const terminationReasons = {
  TERMINATION_BY_CUSTOMER_REASON_GROSS_MISCONDUCT:
    'termination_by_customer_reason_gross_misconduct',
  TERMINATION_BY_CUSTOMER_REASON_PERFORMANCE: 'termination_by_customer_reason_performance',
  TERMINATION_BY_CUSTOMER_REASON_WORKFORCE_REDUCTION:
    'termination_by_customer_reason_workforce_reduction',
  TERMINATION_BY_CUSTOMER_REASON_VALUES: 'termination_by_customer_reason_values',
  TERMINATION_BY_CUSTOMER_REASON_COMPLIANCE_ISSUE:
    'termination_by_customer_reason_compliance_issue',
  TERMINATION_BY_CUSTOMER_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES:
    'termination_by_customer_reason_incapacity_to_perform_inherent_duties',
  TERMINATION_BY_CUSTOMER_REASON_MUTUAL_AGREEMENT:
    'termination_by_customer_reason_mutual_agreement',
  TERMINATION_BY_CUSTOMER_REASON_JOB_ABANDONMENT: 'termination_by_customer_reason_job_abandonment',
  TERMINATION_BY_CUSTOMER_REASON_DISSATISFACTION_WITH_REMOTE_SERVICE:
    'termination_by_customer_reason_dissatisfaction_with_remote_service',
  // This option is only stored for direct offboardings
  // For non-direct-offboardings it is not tracked in the BE, it's only used for then specifying the fixed-term reason
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT:
    'termination_by_customer_reason_end_of_fixed_term_contract',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_REDUNDANCY:
    'termination_by_customer_reason_end_of_fixed_term_contract_redundancy',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_LOCAL_REGULATIONS_MAX_TERM_REACHED:
    'termination_by_customer_reason_end_of_fixed_term_contract_local_regulations_max_term_reached',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_OPERATIONAL_REASONS:
    'termination_by_customer_reason_end_of_fixed_term_contract_operational_reasons',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_MISCONDUCT:
    'termination_by_customer_reason_end_of_fixed_term_contract_misconduct',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_PERFORMANCE:
    'termination_by_customer_reason_end_of_fixed_term_contract_performance',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_VALUES:
    'termination_by_customer_reason_end_of_fixed_term_contract_values',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_COMPLIANCE_ISSUE:
    'termination_by_customer_reason_end_of_fixed_term_contract_compliance_issue',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_INCAPACITY_TO_PERFORM_INHERENT_DUTIES:
    'termination_by_customer_reason_end_of_fixed_term_contract_incapacity_to_perform_inherent_duties',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_OTHER:
    'termination_by_customer_reason_end_of_fixed_term_contract_other',
  TERMINATION_BY_CUSTOMER_REASON_OTHER: 'termination_by_customer_reason_other',
  TERMINATION_BY_REMOTE_REASON_NO_VALID_RTW_NO_VISA_VISA_REVOKED_OR_NOT_RENEWED:
    'termination_by_remote_reason_no_valid_rtw_no_visa_visa_revoked_or_not_renewed',
  TERMINATION_BY_REMOTE_REASON_FORCED_OFFBOARDING_UNPAID_INVOICES:
    'termination_by_remote_reason_forced_offboarding_unpaid_invoices',
  TERMINATION_BY_REMOTE_REASON_FORCED_OFFBOARDING_FRAUD_FAKE_ID_FROM_CUSTOMER_OR_EE:
    'termination_by_remote_reason_forced_offboarding_fraud_fake_id_from_customer_or_ee',
  TERMINATION_BY_REMOTE_REASON_DISCLOSABLE_OFFENCE:
    'termination_by_remote_reason_disclosable_offence',
  TERMINATION_BY_REMOTE_REASON_EE_DEATH: 'termination_by_remote_reason_ee_death',
  TERMINATION_BY_REMOTE_REASON_RETIREMENT: 'termination_by_remote_reason_retirement',
  TERMINATION_BY_REMOTE_REASON_OTHER: 'termination_by_remote_reason_other',
} as const;

const commonOtherReasons = {
  TERMINATION_BY_REMOTE_REASON_OTHER: terminationReasons.TERMINATION_BY_REMOTE_REASON_OTHER,
  RESIGNATION_REASON_OTHER: resignationReasons.RESIGNATION_REASON_OTHER,
} as const;

export const relocationReasons = {
  RESIGNATION_REASON_RELOCATION_FROM_ENTITY_TO_ENTITY_BY_EMPLOYEE:
    'resignation_reason_relocation_from_entity_to_entity_by_employee',
  RESIGNATION_REASON_RELOCATION_FROM_ENTITY_TO_ENTITY_BY_CUSTOMER:
    'resignation_reason_relocation_from_entity_to_entity_by_customer',
  ...commonOtherReasons,
} as const;

export const transferReasons = {
  RESIGNATION_REASON_TRANSFER_FROM_REMOTE_TO_CUSTOMER:
    'resignation_reason_transfer_from_remote_to_customer',
  RESIGNATION_REASON_TRANSFER_FROM_REMOTE_TO_OTHER_EOR:
    'resignation_reason_transfer_from_remote_to_other_eor',
  RESIGNATION_REASON_TRANSFER_AND_RELOCATION_NEW_CUSTOMER_AND_NEW_COUNTRY:
    'resignation_reason_transfer_and_relocation_new_customer_and_new_country',
  RESIGNATION_REASON_TRANSFER_BETWEEN_REMOTE_CUSTOMER:
    'resignation_reason_transfer_between_remote_customer',
  ...commonOtherReasons,
} as const;

export const cancelationReasons = {
  RESIGNATION_REASON_CANCELLATION_BEFORE_START_DATE:
    'resignation_reason_cancellation_before_start_date',
  TERMINATION_BY_CUSTOMER_REASON_CANCELLATION_BEFORE_START_DATE:
    'termination_by_customer_reason_cancellation_before_start_date',
  ...commonOtherReasons,
} as const;

export const conversionReasons = {
  TERMINATION_BY_CUSTOMER_REASON_CONVERSION_TO_CONTRACTOR:
    'termination_by_customer_reason_conversion_to_contractor',
  RESIGNATION_REASON_CONVERSION_TO_CONTRACTOR: 'resignation_reason_conversion_to_contractor',
  RESIGNATION_REASON_CONVERSION_TO_GLOBAL_PAYROLL:
    'resignation_reason_conversion_to_global_payroll',
  ...commonOtherReasons,
} as const;

type OffboardingReasonTypeKeys = typeof resignationReasons &
  typeof terminationReasons &
  typeof relocationReasons &
  typeof cancelationReasons &
  typeof transferReasons &
  typeof conversionReasons;

export type OffboardingReasons = ValueOf<OffboardingReasonTypeKeys>;

// 'termination_by_customer_reason_end_of_fixed_term_contract' is only stored for direct offboardings
// For non-direct-offboardings it is not tracked in the BE, it's only used for then specifying the fixed-term reason
export type NonDirectOffboardingReasons = ValueOf<
  Except<OffboardingReasonTypeKeys, 'TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT'>
>;

export const offboardingReasonLabels: {
  [key in NonDirectOffboardingReasons]: string;
} = {
  [relocationReasons.RESIGNATION_REASON_RELOCATION_FROM_ENTITY_TO_ENTITY_BY_EMPLOYEE]:
    'Resignation by employee - Relocation from entity to entity by employee',
  [relocationReasons.RESIGNATION_REASON_RELOCATION_FROM_ENTITY_TO_ENTITY_BY_CUSTOMER]:
    'Resignation by employee - Relocation from entity to entity by customer',

  [transferReasons.RESIGNATION_REASON_TRANSFER_FROM_REMOTE_TO_CUSTOMER]:
    'Resignation by employee - Transfer from Remote to customer',
  [transferReasons.RESIGNATION_REASON_TRANSFER_FROM_REMOTE_TO_OTHER_EOR]:
    'Resignation by employee - Transfer from Remote to other EOR',
  [transferReasons.RESIGNATION_REASON_TRANSFER_AND_RELOCATION_NEW_CUSTOMER_AND_NEW_COUNTRY]:
    'Resignation by employee - Transfer and relocation (new customer and new country)',
  [transferReasons.RESIGNATION_REASON_TRANSFER_BETWEEN_REMOTE_CUSTOMER]:
    'Resignation by employee - Transfer from customer to customer within Remote',

  [cancelationReasons.RESIGNATION_REASON_CANCELLATION_BEFORE_START_DATE]:
    'Resignation by employee - Cancellation before start date',
  [cancelationReasons.TERMINATION_BY_CUSTOMER_REASON_CANCELLATION_BEFORE_START_DATE]:
    'Termination by customer - Cancellation before start date',

  [conversionReasons.TERMINATION_BY_CUSTOMER_REASON_CONVERSION_TO_CONTRACTOR]:
    'Termination by customer - Conversion to contractor',
  [conversionReasons.RESIGNATION_REASON_CONVERSION_TO_CONTRACTOR]:
    'Resignation by employee - Conversion to contractor',
  [conversionReasons.RESIGNATION_REASON_CONVERSION_TO_GLOBAL_PAYROLL]:
    'Resignation by employee - Conversion to Global Payroll employee',
  [resignationReasons.RESIGNATION_REASON_PERSONAL_REASONS]:
    'Resignation by employee - Personal reasons',
  [resignationReasons.RESIGNATION_REASON_MUTUAL_AGREEMENT]:
    'Resignation by employee - Mutual agreement',
  [resignationReasons.RESIGNATION_REASON_OTHER_JOB_OPPORTUNITY]:
    'Resignation by employee - Other job opportunity',
  [resignationReasons.RESIGNATION_REASON_REMUNERATION_AND_BENEFITS]:
    'Resignation by employee - Remuneration and benefits',
  [resignationReasons.RESIGNATION_REASON_LEADERSHIP]: 'Resignation by employee - Leadership',
  [resignationReasons.RESIGNATION_REASON_LACK_OF_RECOGNITION]:
    'Resignation by employee - Lack of recognition',
  [resignationReasons.RESIGNATION_REASON_RETIREMENT]: 'Resignation by employee - Retirement',
  [resignationReasons.RESIGNATION_REASON_COMPANY_CULTURE_OR_VALUES]:
    'Resignation by employee - Company culture/values',
  [resignationReasons.RESIGNATION_REASON_POSITION_DOES_NOT_MEET_EXPECTATIONS]:
    'Resignation by employee - Position doesn’t meet expectations',
  [resignationReasons.RESIGNATION_REASON_INFRASTRUCTURE_CHALLENGES]:
    'Resignation by employee - Infrastructure challenges',
  [resignationReasons.RESIGNATION_REASON_RELATIONSHIP_WITH_COWORKERS]:
    'Resignation by employee - Relationship with coworkers',
  [resignationReasons.RESIGNATION_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES]:
    'Resignation by employee - Incapacity to perform inherent duties',
  [resignationReasons.RESIGNATION_REASON_DISSATISFACTION_WITH_REMOTE_SERVICE]:
    'Resignation by employee - Dissatisfaction with Remote service',
  [resignationReasons.RESIGNATION_REASON_RELOCATION_LEAVING_REMOTE]:
    'Resignation by employee - Relocation leaving Remote',
  [resignationReasons.RESIGNATION_REASON_OTHER]: 'Resignation by employee - Other',

  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_GROSS_MISCONDUCT]:
    'Termination by customer - Misconduct',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_PERFORMANCE]:
    'Termination by customer - Performance',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_WORKFORCE_REDUCTION]:
    'Termination by customer - Workforce reduction',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_VALUES]: 'Termination by customer - Values',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_COMPLIANCE_ISSUE]:
    'Termination by customer - Compliance issue',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES]:
    'Termination by customer - Incapacity to perform inherent duties',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_MUTUAL_AGREEMENT]:
    'Termination by customer - Mutual agreement',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_JOB_ABANDONMENT]:
    'Termination by customer - Job abandonment',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_DISSATISFACTION_WITH_REMOTE_SERVICE]:
    'Termination by customer - Dissatisfaction with Remote service',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_REDUNDANCY]:
    'Termination by customer - End of fixed-term contract: Redundancy',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_LOCAL_REGULATIONS_MAX_TERM_REACHED]:
    'Termination by customer - End of fixed-term contract: Local regulations (max term reached)',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_OPERATIONAL_REASONS]:
    'Termination by customer - End of fixed-term contract: Operational reasons',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_MISCONDUCT]:
    'Termination by customer - End of fixed-term contract: Misconduct',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_PERFORMANCE]:
    'Termination by customer - End of fixed-term contract: Performance',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_VALUES]:
    'Termination by customer - End of fixed-term contract: Values',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_COMPLIANCE_ISSUE]:
    'Termination by customer - End of fixed-term contract: Compliance issue',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_INCAPACITY_TO_PERFORM_INHERENT_DUTIES]:
    'Termination by customer - End of fixed-term contract: Incapacity to perform inherent duties',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT_OTHER]:
    'Termination by customer - End of fixed-term contract: Other',
  [terminationReasons.TERMINATION_BY_CUSTOMER_REASON_OTHER]: 'Termination by customer - Other',
  [terminationReasons.TERMINATION_BY_REMOTE_REASON_NO_VALID_RTW_NO_VISA_VISA_REVOKED_OR_NOT_RENEWED]:
    'Termination by Remote - No valid right to work (no visa, visa revoked or not renewed)',
  [terminationReasons.TERMINATION_BY_REMOTE_REASON_FORCED_OFFBOARDING_UNPAID_INVOICES]:
    'Termination by Remote - Forced offboarding (unpaid invoices)',
  [terminationReasons.TERMINATION_BY_REMOTE_REASON_FORCED_OFFBOARDING_FRAUD_FAKE_ID_FROM_CUSTOMER_OR_EE]:
    'Termination by Remote - Forced offboarding (fraud, fake ID from customer or EE)',
  [terminationReasons.TERMINATION_BY_REMOTE_REASON_DISCLOSABLE_OFFENCE]:
    'Termination by Remote - Disclosable offence',
  [terminationReasons.TERMINATION_BY_REMOTE_REASON_EE_DEATH]: 'Termination by Remote - EE death',
  [terminationReasons.TERMINATION_BY_REMOTE_REASON_RETIREMENT]:
    'Termination by Remote - Retirement',
  [terminationReasons.TERMINATION_BY_REMOTE_REASON_OTHER]: 'Termination by Remote - Other',
} as const;

// Needed to display old termination reasons that cannot be migrated automatically to new ones
// Not displayed as options
const legacyTerminationReasons = {
  DISMISSED: 'dismissed',
  REDUNDANCY: 'redundancy',
  RESIGNED: 'resigned',
  TRANSFERRED: 'transferred',
  UNKNOWN: 'unknown',
  CANCELLED_BEFORE_START_DATA_BY_CUSTOMER: 'cancelled_before_start_date_by_customer',
  CANCELLED_BEFORE_START_DATA_BY_EMPLOYEE: 'cancelled_before_start_date_by_employee',
  DECEASED: 'deceased',
  END_OF_CONTRACT: 'end_of_contract',
  INVOLUNTARY_CONDUCT: 'involuntary_conduct',
  INVOLUNTARY_PERFORMANCE: 'involuntary_performance',
  INVOLUNTARY_WORKFORCE_REDUCTION: 'involuntary_workforce_reduction',
  INVOLUNTARY_RESIGNATION_VALUES: 'involuntary_resignation_values',
  RELOCATION: 'relocation',
  RELOCATION_PLUS_TRANSFER: 'relocation_plus_transfer',
  RELOCATION_TO_ANOTHER_ENTITY: 'relocation_to_another_entity',
  RETIREMENT: 'retirement',
  TRANSFER_TO_ANOTHER_CUSTOMER: 'transfer_to_another_customer',
  VOLUNTARY_RESIGNATION_CUSTOMER_LEAVING_REMOTE: 'voluntary_resignation_customer_leaving_remote',
  VOLUNTARY_RESIGNATION_JOB_OFFER: 'voluntary_resignation_job_offer',
  VOLUNTARY_RESIGNATION_PERSONAL_REASONS: 'voluntary_resignation_personal_reasons',
  VOLUNTARY_RESIGNATION_COMPENSATION: 'voluntary_resignation_compensation',
  VOLUNTARY_RESIGNATION_BENEFITS: 'voluntary_resignation_benefits',
  VOLUNTARY_RESIGNATION_LEADERSHIP: 'voluntary_resignation_leadership',
  VOLUNTARY_RESIGNATION_LACK_OF_RECOGNITION: 'voluntary_resignation_lack_of_recognition',
  VOLUNTARY_RESIGNATION_MUTUAL_DECISION: 'voluntary_resignation_mutual_decision',
} as const;

export const legacyTerminationReasonLabels: {
  [key in ValueOf<typeof legacyTerminationReasons>]: string;
} = {
  [legacyTerminationReasons.DISMISSED]: 'Dismissed',
  [legacyTerminationReasons.REDUNDANCY]: 'Redundancy',
  [legacyTerminationReasons.RESIGNED]: 'Resigned',
  [legacyTerminationReasons.TRANSFERRED]: 'Transferred',
  [legacyTerminationReasons.UNKNOWN]: 'Unknown',
  [legacyTerminationReasons.CANCELLED_BEFORE_START_DATA_BY_CUSTOMER]:
    'Cancelled before start date - by customer',
  [legacyTerminationReasons.CANCELLED_BEFORE_START_DATA_BY_EMPLOYEE]:
    'Cancelled before start date - by employee',
  [legacyTerminationReasons.DECEASED]: 'Deceased',
  [legacyTerminationReasons.END_OF_CONTRACT]: 'End of contract',
  [legacyTerminationReasons.INVOLUNTARY_CONDUCT]: 'Involuntary - Conduct',
  [legacyTerminationReasons.INVOLUNTARY_PERFORMANCE]: 'Involuntary - Performance',
  [legacyTerminationReasons.INVOLUNTARY_WORKFORCE_REDUCTION]: 'Involuntary - Workforce Reduction',
  [legacyTerminationReasons.INVOLUNTARY_RESIGNATION_VALUES]: 'Involuntary - Values',
  [legacyTerminationReasons.RELOCATION]: 'Relocation',
  [legacyTerminationReasons.RELOCATION_PLUS_TRANSFER]: 'Relocation + Transfer',
  [legacyTerminationReasons.RELOCATION_TO_ANOTHER_ENTITY]: 'Relocation to another entity',
  [legacyTerminationReasons.RETIREMENT]: 'Retirement',
  [legacyTerminationReasons.TRANSFER_TO_ANOTHER_CUSTOMER]: 'Transfer to another customer',
  [legacyTerminationReasons.VOLUNTARY_RESIGNATION_CUSTOMER_LEAVING_REMOTE]:
    'Voluntary Resignation - Customer leaving Remote',
  [legacyTerminationReasons.VOLUNTARY_RESIGNATION_JOB_OFFER]: 'Voluntary Resignation - Job Offer',
  [legacyTerminationReasons.VOLUNTARY_RESIGNATION_PERSONAL_REASONS]:
    'Voluntary Resignation - Personal Reasons',
  [legacyTerminationReasons.VOLUNTARY_RESIGNATION_COMPENSATION]:
    'Voluntary Resignation - Compensation',
  [legacyTerminationReasons.VOLUNTARY_RESIGNATION_BENEFITS]: 'Voluntary Resignation - Benefits',
  [legacyTerminationReasons.VOLUNTARY_RESIGNATION_LEADERSHIP]: 'Voluntary Resignation - Leadership',
  [legacyTerminationReasons.VOLUNTARY_RESIGNATION_LACK_OF_RECOGNITION]:
    'Voluntary Resignation - Lack of recognition',
  [legacyTerminationReasons.VOLUNTARY_RESIGNATION_MUTUAL_DECISION]:
    'Voluntary Resignation - Mutual Decision',
} as const;

export const regrettableTerminationType = {
  REGRETTABLE: 'regrettable',
  NON_REGRETTABLE: 'non_regrettable',
} as const;

export type RegrettableTerminationType = ValueOf<typeof regrettableTerminationType>;

export const regrettableTerminationTypeLabels: {
  [key in RegrettableTerminationType]: string;
} = {
  [regrettableTerminationType.REGRETTABLE]: 'Regretted attrition',
  [regrettableTerminationType.NON_REGRETTABLE]: 'Non-regretted attrition',
} as const;

export const regrettableTerminationDescription: {
  [key in RegrettableTerminationType]: string;
} = {
  [regrettableTerminationType.REGRETTABLE]:
    'The departure of this employee is a loss for the company.',
  [regrettableTerminationType.NON_REGRETTABLE]:
    'The departure of this employee is not a loss for the company.',
} as const;

export const regrettableTerminationTypeOptions = generateSelectOptions(
  regrettableTerminationTypeLabels
);

export const terminationReasonOptions = generateSelectOptions(offboardingReasonLabels);

export const offboardingTypeReasonsMap: {
  [key in OffboardingType]: {
    [k in keyof Partial<OffboardingReasonTypeKeys>]: OffboardingReasons;
  };
} = {
  [offboardingType.RESIGNATION]: resignationReasons,
  [offboardingType.TERMINATION]: terminationReasons,
  [offboardingType.RELOCATION]: relocationReasons,
  [offboardingType.CANCELLATION]: cancelationReasons,
  [offboardingType.TRANSFER]: transferReasons,
  [offboardingType.CONVERSION]: conversionReasons,
} as const;

export const offboardingDetailsKey = {
  [offboardingType.RESIGNATION]: 'resignationDetails',
  [offboardingType.TERMINATION]: 'terminationDetails',
  [offboardingType.RELOCATION]: 'relocationDetails',
  [offboardingType.CANCELLATION]: 'cancellationDetails',
  [subType.TUPE]: 'resignationDetails',
} as const;

export const optionsPerOffboardingType = (type: OffboardingType | null) => {
  if (!type) {
    return [];
  }

  const reasons = offboardingTypeReasonsMap[type];
  const filteredLabels = pick(offboardingReasonLabels, Object.values(reasons));

  return generateSelectOptions(filteredLabels);
};

export const directOffboardingStatus = {
  SUBMITTED: 'submitted',
  DELETED: 'deleted',
  COMPLETED: 'completed',
  PENDING_PAYMENT: 'pending_payment',
} as const;

export const nonDeletedDirectOffboardingStatuses = Object.values(directOffboardingStatus).filter(
  (status) => status !== directOffboardingStatus.DELETED
);

export type DirectOffboardingStatus = ValueOf<typeof directOffboardingStatus>;

export const directOffboardingStatusLabels: { [key in DirectOffboardingStatus]: string } = {
  [directOffboardingStatus.SUBMITTED]: 'Submitted',
  [directOffboardingStatus.PENDING_PAYMENT]: 'Pending payment',
  [directOffboardingStatus.COMPLETED]: 'Completed',
  [directOffboardingStatus.DELETED]: 'Canceled',
};

export const directOffboardingStatusBadge: Record<string, BadgeType> = {
  [directOffboardingStatus.SUBMITTED]: 'pending',
  [directOffboardingStatus.DELETED]: 'inactive',
  [directOffboardingStatus.COMPLETED]: 'active',
  [directOffboardingStatus.PENDING_PAYMENT]: 'scheduled',
} as const;

export const directOffboardingStatusOptions = generateSelectOptions(
  directOffboardingStatusLabels
) as {
  value: DirectOffboardingStatus;
  label: string;
}[];

export const directOffboardingType = {
  TERMINATION: 'termination',
  RESIGNATION: 'resignation',
  MUTUAL_AGREEMENT: 'mutual_agreement',
} as const;

export type DirectOffboardingType = ValueOf<typeof directOffboardingType>;

export const directOffboardingTypeLabels: {
  [key in DirectOffboardingType]: string;
} = {
  [directOffboardingType.TERMINATION]: 'Termination',
  [directOffboardingType.RESIGNATION]: 'Resignation',
  [directOffboardingType.MUTUAL_AGREEMENT]: 'Mutual Agreement',
} as const;

export const directOffboardingTypeTone: Record<DirectOffboardingType, PillTone> = {
  [directOffboardingType.TERMINATION]: 'fuchsia',
  [directOffboardingType.RESIGNATION]: 'radiance',
  [directOffboardingType.MUTUAL_AGREEMENT]: 'turquoise',
} as const;

export const directOffboardingDescriptions: {
  [key in DirectOffboardingType]: string;
} = {
  [directOffboardingType.TERMINATION]: 'The company decided to end the employee’s employment.',
  [directOffboardingType.RESIGNATION]: 'The employee decided to end their employment.',
  [directOffboardingType.MUTUAL_AGREEMENT]:
    'The company and the employee came to a mutual decision to end the employment.',
} as const;

export const directOffboardingFieldName = {
  type: 'type',
  terminationReason: 'terminationReason',
  terminationReasonDescription: 'terminationReasonDescription',
  terminationDate: 'terminationDate',
  lastWorkingDay: 'lastWorkingDay',
  managerSlug: 'managerSlug',
  offboardingFiles: 'offboardingFiles',
  additionalNotes: 'additionalNotes',
  isOffboardingDuringProbation: 'isOffboardingDuringProbation',
  regrettableTermination: 'regrettableTermination',
  noticePeriodType: 'noticePeriodType',
  wouldReHirePersonInTheFuture: 'wouldReHirePersonInTheFuture',
  communicationDate: 'communicationDate',
  employeePersonalEmail: 'employeePersonalEmail',
  usedPto: 'usedPto',
  usedPtoDays: 'days',
  usedPtoHours: 'hours',
  usedPtoYear: 'year',
  ptoExcessDays: 'ptoExcessDays',
  ptoAdditionalNotes: 'ptoAdditionalNotes',
  timeoffReports: 'timeoffReports',
  severancePay: 'severance',
  severancePayAmount: 'severancePayAmount',
  severanceAdditionalNotes: 'severanceAdditionalNotes',
  additionalPayments: 'additionalPayments',
  additionalPaymentsItem: 'item',
  additionalPaymentsAmount: 'amount',
  employeeNeedsToMakeAdditionalPayments: 'employeeNeedsToMakeAdditionalPayments',
  additionalPaymentsDetails: 'additionalPaymentsDetails',
} as const;

export type DirectOffboardingFieldName = ValueOf<typeof directOffboardingFieldName>;

export const directOffboardingFieldLabel: {
  [key in DirectOffboardingFieldName]: string;
} = {
  [directOffboardingFieldName.type]: 'Offboarding type',
  [directOffboardingFieldName.terminationReason]: 'Offboarding reason',
  [directOffboardingFieldName.terminationReasonDescription]: 'Please provide a few details',
  [directOffboardingFieldName.terminationDate]: 'Last day of employment',
  [directOffboardingFieldName.lastWorkingDay]: 'Last working day',
  [directOffboardingFieldName.managerSlug]: 'Manager',
  [directOffboardingFieldName.offboardingFiles]: 'Offboarding documents',
  [directOffboardingFieldName.additionalNotes]: 'Additional notes',
  [directOffboardingFieldName.isOffboardingDuringProbation]: 'Offboarding during probation period',
  [directOffboardingFieldName.regrettableTermination]: 'Type of attrition',
  [directOffboardingFieldName.noticePeriodType]: 'For the notice period, which option applies?',
  [directOffboardingFieldName.wouldReHirePersonInTheFuture]:
    'Would you re-hire this person in the future?',
  [directOffboardingFieldName.communicationDate]: 'Communication date',
  [directOffboardingFieldName.employeePersonalEmail]: "Employee's personal email",
  [directOffboardingFieldName.usedPto]: 'Paid time off',
  [directOffboardingFieldName.usedPtoDays]: 'Days',
  [directOffboardingFieldName.usedPtoHours]: 'Hours',
  [directOffboardingFieldName.usedPtoYear]: 'Year',
  [directOffboardingFieldName.ptoExcessDays]: 'Excess PTO days',
  [directOffboardingFieldName.ptoAdditionalNotes]: 'Additional details',
  [directOffboardingFieldName.timeoffReports]: 'Time off reports',
  [directOffboardingFieldName.severancePay]: 'Severance pay',
  [directOffboardingFieldName.severancePayAmount]: 'Severance amount',
  [directOffboardingFieldName.severanceAdditionalNotes]: 'Additional details',
  [directOffboardingFieldName.additionalPayments]: 'Additional payments',
  [directOffboardingFieldName.additionalPaymentsItem]: 'Item',
  [directOffboardingFieldName.additionalPaymentsAmount]: 'Amount',
  [directOffboardingFieldName.employeeNeedsToMakeAdditionalPayments]:
    'Does the employee need to make any payments to the company before leaving? For example, educational funding that requires repayment or notice pay.',
  [directOffboardingFieldName.additionalPaymentsDetails]: 'Additional details',
} as const;

export const directOffboardingTypeOptions = [
  {
    label: directOffboardingTypeLabels[directOffboardingType.RESIGNATION],
    value: directOffboardingType.RESIGNATION,
    description: directOffboardingDescriptions[directOffboardingType.RESIGNATION],
  },
  {
    label: directOffboardingTypeLabels[directOffboardingType.TERMINATION],
    value: directOffboardingType.TERMINATION,
    description: directOffboardingDescriptions[directOffboardingType.TERMINATION],
  },
  {
    label: directOffboardingTypeLabels[directOffboardingType.MUTUAL_AGREEMENT],
    value: directOffboardingType.MUTUAL_AGREEMENT,
    description: directOffboardingDescriptions[directOffboardingType.MUTUAL_AGREEMENT],
  },
] as const;

export const directOffboardingTerminationReasons = {
  TERMINATION_BY_CUSTOMER_REASON_GROSS_MISCONDUCT:
    'termination_by_customer_reason_gross_misconduct',
  TERMINATION_BY_CUSTOMER_REASON_PERFORMANCE: 'termination_by_customer_reason_performance',
  TERMINATION_BY_CUSTOMER_REASON_WORKFORCE_REDUCTION:
    'termination_by_customer_reason_workforce_reduction',
  TERMINATION_BY_CUSTOMER_REASON_VALUES: 'termination_by_customer_reason_values',
  TERMINATION_BY_CUSTOMER_REASON_COMPLIANCE_ISSUE:
    'termination_by_customer_reason_compliance_issue',
  TERMINATION_BY_CUSTOMER_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES:
    'termination_by_customer_reason_incapacity_to_perform_inherent_duties',
  TERMINATION_BY_CUSTOMER_REASON_CANCELLATION_BEFORE_START_DATE:
    'termination_by_customer_reason_cancellation_before_start_date',
  TERMINATION_BY_CUSTOMER_REASON_JOB_ABANDONMENT: 'termination_by_customer_reason_job_abandonment',
  TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT:
    'termination_by_customer_reason_end_of_fixed_term_contract',
  TERMINATION_BY_CUSTOMER_REASON_CONVERSION_TO_CONTRACTOR:
    'termination_by_customer_reason_conversion_to_contractor',
  TERMINATION_BY_CUSTOMER_REASON_OTHER: 'termination_by_customer_reason_other',
} as const;

export type DirectOffboardingTerminationReasons = ValueOf<
  typeof directOffboardingTerminationReasons
>;

export const directOffboardingResignationReasons = {
  RESIGNATION_REASON_LEADERSHIP: 'resignation_reason_leadership',
  RESIGNATION_REASON_LACK_OF_RECOGNITION: 'resignation_reason_lack_of_recognition',
  RESIGNATION_REASON_RETIREMENT: 'resignation_reason_retirement',
  RESIGNATION_REASON_COMPANY_CULTURE_OR_VALUES: 'resignation_reason_company_culture_or_values',
  RESIGNATION_REASON_POSITION_DOES_NOT_MEET_EXPECTATIONS:
    'resignation_reason_position_does_not_meet_expectations',
  RESIGNATION_REASON_PERSONAL_REASONS: 'resignation_reason_personal_reasons',
  RESIGNATION_REASON_OTHER_JOB_OPPORTUNITY: 'resignation_reason_other_job_opportunity',
  RESIGNATION_REASON_REMUNERATION_AND_BENEFITS: 'resignation_reason_remuneration_and_benefits',
  RESIGNATION_REASON_INFRASTRUCTURE_CHALLENGES: 'resignation_reason_infrastructure_challenges',
  RESIGNATION_REASON_RELATIONSHIP_WITH_COWORKERS: 'resignation_reason_relationship_with_coworkers',
  RESIGNATION_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES:
    'resignation_reason_incapacity_to_perform_inherent_duties',
  RESIGNATION_REASON_OTHER: 'resignation_reason_other',
} as const;

export type DirectOffboardingResignationReasons = ValueOf<
  typeof directOffboardingResignationReasons
>;

const directOffboardingTerminationReasonLabels: {
  [key in DirectOffboardingTerminationReasons]: string;
} = {
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_GROSS_MISCONDUCT]:
    'Misconduct',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_PERFORMANCE]: 'Performance',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_WORKFORCE_REDUCTION]:
    'Workforce reduction',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_VALUES]: 'Values',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_COMPLIANCE_ISSUE]:
    'Compliance issues',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES]:
    'Incapacity to perform inherent duties',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_CANCELLATION_BEFORE_START_DATE]:
    "Canceling hiring before the employee's start date",
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_JOB_ABANDONMENT]:
    'Job abandonment',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT]:
    'End of fixed-term contract',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_CONVERSION_TO_CONTRACTOR]:
    'Employee is changing to a contractor position',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_OTHER]: 'Other',
} as const;

const directOffboardingResignationReasonLabels: {
  [key in DirectOffboardingResignationReasons]: string;
} = {
  [directOffboardingResignationReasons.RESIGNATION_REASON_LEADERSHIP]: 'Leadership',
  [directOffboardingResignationReasons.RESIGNATION_REASON_LACK_OF_RECOGNITION]:
    'Lack of recognition',
  [directOffboardingResignationReasons.RESIGNATION_REASON_RETIREMENT]: 'Retirement',
  [directOffboardingResignationReasons.RESIGNATION_REASON_COMPANY_CULTURE_OR_VALUES]:
    'Company culture or values',
  [directOffboardingResignationReasons.RESIGNATION_REASON_POSITION_DOES_NOT_MEET_EXPECTATIONS]:
    'Position doesn’t meet expectations',
  [directOffboardingResignationReasons.RESIGNATION_REASON_PERSONAL_REASONS]: 'Personal reasons',
  [directOffboardingResignationReasons.RESIGNATION_REASON_OTHER_JOB_OPPORTUNITY]:
    'Other job opportunity',
  [directOffboardingResignationReasons.RESIGNATION_REASON_REMUNERATION_AND_BENEFITS]:
    'Remuneration and benefits',
  [directOffboardingResignationReasons.RESIGNATION_REASON_INFRASTRUCTURE_CHALLENGES]:
    'Infrastructure challenges',
  [directOffboardingResignationReasons.RESIGNATION_REASON_RELATIONSHIP_WITH_COWORKERS]:
    'Relationship with coworkers',
  [directOffboardingResignationReasons.RESIGNATION_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES]:
    'Incapacity to perform inherent duties',
  [directOffboardingResignationReasons.RESIGNATION_REASON_OTHER]: 'Other',
} as const;

const directOffboardingTerminationReasonDescriptions: {
  [key in Exclude<
    DirectOffboardingTerminationReasons,
    'termination_by_customer_reason_other'
  >]: string;
} = {
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_GROSS_MISCONDUCT]:
    'The employee exhibited unacceptable behavior.',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_PERFORMANCE]:
    'The employee has performed poorly. An employer cannot dismiss an employee for poor performance without first giving them a reasonable opportunity to improve their performance.',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_WORKFORCE_REDUCTION]:
    'The employee’s position is being made redundant.',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_VALUES]:
    'The employee’s conduct does not align with company values.',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_COMPLIANCE_ISSUE]:
    'The employee violated key policies, processes, or procedures. For example, they failed to comply with their employment agreement’s confidentiality clause.',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES]:
    'The employee does not have the ability to fulfil the requirements of their role.',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_CANCELLATION_BEFORE_START_DATE]:
    'Select this reason only if the employee has not started work yet.',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_JOB_ABANDONMENT]:
    'The employee has failed to appear for work. The employer has not been able to contact the employee and does not know where the employee is.',
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_END_OF_FIXED_TERM_CONTRACT]:
    "The employee's fixed-term contract is coming to an end and will not be renewed or extended.",
  [directOffboardingTerminationReasons.TERMINATION_BY_CUSTOMER_REASON_CONVERSION_TO_CONTRACTOR]:
    'The employee is transitioning to a contractor position.',
} as const;

const directOffboardingResignationReasonDescriptions: {
  [key in Exclude<DirectOffboardingResignationReasons, 'resignation_reason_other'>]: string;
} = {
  [directOffboardingResignationReasons.RESIGNATION_REASON_LEADERSHIP]:
    'The employee feels misaligned with leadership, impacting their ability to contribute effectively.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_LACK_OF_RECOGNITION]:
    'The employee feels the company has not adequately acknowledged their work.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_RETIREMENT]: 'The employee is retiring.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_COMPANY_CULTURE_OR_VALUES]:
    'The employee feels their personal values do not align with company culture or values.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_POSITION_DOES_NOT_MEET_EXPECTATIONS]:
    "The employee feels there is a mismatch between the role's promised and actual responsibilities.",
  [directOffboardingResignationReasons.RESIGNATION_REASON_PERSONAL_REASONS]:
    'The employee is resigning because of personal circumstances.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_OTHER_JOB_OPPORTUNITY]:
    'The employee is resigning to take another job offer.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_REMUNERATION_AND_BENEFITS]:
    'The employee is pursuing better compensation and benefits.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_INFRASTRUCTURE_CHALLENGES]:
    'The employee feels the company’s infrastructural limitations affect their ability to perform effectively.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_RELATIONSHIP_WITH_COWORKERS]:
    'The employee feels there is misalignment in their relationship with their coworkers.',
  [directOffboardingResignationReasons.RESIGNATION_REASON_INCAPACITY_TO_PERFORM_INHERENT_DUTIES]:
    'The employee acknowledges that they do not have the ability to fulfil the requirements of their role.',
} as const;

export const directOffboardingReasonLabels = {
  ...directOffboardingTerminationReasonLabels,
  ...directOffboardingResignationReasonLabels,
} as const;

export const directOffboardingReasonDescriptions = {
  ...directOffboardingTerminationReasonDescriptions,
  ...directOffboardingResignationReasonDescriptions,
} as const;

export const optionsPerDirectOffboardingType = (type: OffboardingType) => {
  if (type === offboardingType.RESIGNATION) {
    return generateSelectOptions(directOffboardingResignationReasonLabels);
  }

  if (type === offboardingType.TERMINATION) {
    return generateSelectOptions(directOffboardingTerminationReasonLabels);
  }

  return [];
};

export const directOffboardingNoticePeriodType = {
  EMPLOYEE_WORKED: 'employee_worked',
  PAYMENT_IN_LIEU: 'payment_in_lieu',
} as const;

export type DirectOffboardingNoticePeriodType = ValueOf<typeof directOffboardingNoticePeriodType>;

export const directOffboardingNoticePeriodTypeLabels = {
  [directOffboardingNoticePeriodType.EMPLOYEE_WORKED]: 'Employee worked the notice period',
  [directOffboardingNoticePeriodType.PAYMENT_IN_LIEU]: 'Payment in Lieu of Notice (PILON) provided',
} as const;

export const directOffboardingNoticePeriodTypeOptions = generateSelectOptions(
  directOffboardingNoticePeriodTypeLabels
);

export const EMPLOYER_OFFBOARDING_PAGE_TABS = {
  EMPLOYEE: 'employee',
  DIRECT_EMPLOYEE: 'direct_employee',
} as const;

export const ADMIN_OFFBOARDING_PAGE_TABS = {
  HIRED_BY_REMOTE: 'hired_by_remote',
  HIRED_BY_COMPANY: 'hired_by_company',
} as const;

export const OFFBOARDING_ASSISTANT_COUNTRY_CODES = (
  publicRuntimeConfig.OFFBOARDING_ASSISTANT_ENABLED_COUNTRIES || ''
).split(',');

export const offboardingAssistantTerminationReasons = {
  GROSS_MISCONDUCT: 'gross_misconduct',
  PERFORMANCE: 'performance',
  WORKFORCE_REDUCTION: 'workforce_reduction',
} as const;

export type OffboardingAssistantTerminationReasons = ValueOf<
  typeof offboardingAssistantTerminationReasons
>;

export const offboardingAssistantTerminationReasonLabels: {
  [key in OffboardingAssistantTerminationReasons]: string;
} = {
  [offboardingAssistantTerminationReasons.PERFORMANCE]: 'Performance',
  [offboardingAssistantTerminationReasons.WORKFORCE_REDUCTION]: 'Workforce reduction',
  [offboardingAssistantTerminationReasons.GROSS_MISCONDUCT]: 'Misconduct',
};

export const offboardingAssistantTerminationReasonOptions = generateSelectOptions(
  offboardingAssistantTerminationReasonLabels
);

const OFFBOARDING_COMPLEXITY = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
} as const;

export const OFFBOARDING_COMPLEXITY_LABELS = {
  [OFFBOARDING_COMPLEXITY.LOW]: 'Low',
  [OFFBOARDING_COMPLEXITY.MEDIUM]: 'Medium',
  [OFFBOARDING_COMPLEXITY.HIGH]: 'High',
};

export type OffboardingComplexityType = ValueOf<typeof OFFBOARDING_COMPLEXITY>;

export const OFFBOARDING_COMPLEXITY_PILL_TONE: Record<OffboardingComplexityType, PillTone> = {
  [OFFBOARDING_COMPLEXITY.LOW]: 'success',
  [OFFBOARDING_COMPLEXITY.MEDIUM]: 'warning',
  [OFFBOARDING_COMPLEXITY.HIGH]: 'error',
};
