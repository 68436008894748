import type { FocusEvent } from 'react';
import { useMemo, useState } from 'react';
import type { InputActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { useFormGroupContext } from '../form-group';
import { defaultTransformCreatedOption } from '../helpers';

import * as selectComponents from './components';
import type { InputElementProps } from './InputElement';
import { getCustomOption } from './InputElement';
import type { SelectOption, SelectOptionBase } from './InputSelect';

type InputAndCreateElementProps<T extends SelectOptionBase> = InputElementProps<T> & {
  onCreateOption?: (options: T[]) => void;
};

// We need to explicitly set the `allowCreate` prop to `true` when the input allows users to create options
// so that the input's control does not overflow when the values fill the container.
const AllowCreateValueContainer = ({ children }: { children: React.ReactNode }) => (
  <selectComponents.ValueContainer allowCreate>{children}</selectComponents.ValueContainer>
);

export function InputAndCreateElement<T extends SelectOptionBase = SelectOption>({
  components,
  styles,
  identifiers,
  isClearable,
  isSearchable,
  isMenuOpen,
  onFocus,
  onBlur: passedOnBlur,
  onInputChange: passedOnInputChange,
  onMenuOpen,
  onMenuClose,
  menuRef,
  options,
  onCreateOption,
}: InputAndCreateElementProps<T>) {
  const {
    id,
    ref,
    handleOnBlur: formGroupOnBlur,
    onInputChange: formGroupOnInputChange,
    multiple,
    transformCreatedOption = defaultTransformCreatedOption,
    ...inputProps
  } = useFormGroupContext();

  const { Option: CustomOptionComponent, ...customComponents } = components || {};
  const [customOption, setCustomOption] = useState<T | undefined>(undefined);

  const privateOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    formGroupOnBlur?.(event);
    passedOnBlur?.(event);
  };

  // `onInputChange` can be passed both as a direct property to this component and via
  // the form group's context. We need to call both of them.
  const privateOnInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    formGroupOnInputChange?.(newValue, actionMeta);
    passedOnInputChange?.(newValue, actionMeta);
  };

  const CustomOption = useMemo(
    () => getCustomOption(CustomOptionComponent),
    [CustomOptionComponent]
  );

  function createOption(inputOption: T) {
    setCustomOption(inputOption);

    if (multiple) {
      const { value = [] } = inputProps;
      inputProps.onChange?.([...(Array.isArray(value) ? value : []), inputOption]);
    } else {
      inputProps.onChange?.(inputOption);
    }
    onCreateOption?.([...(options as T[]), inputOption]);
  }

  async function handleCreateOption(value: string) {
    // @ts-expect-error react-select specific props and handling
    createOption({ value: transformCreatedOption(value), label: value });
  }

  let selectOptions = options;
  if (customOption) {
    // Options can't have duplicate values
    // This can occur when creating a new option and managing the options list in the parent component
    selectOptions = options.find((item) => (item as T).value === customOption.value)
      ? options
      : [...options, customOption];
  }

  return (
    <CreatableSelect
      components={{
        ...selectComponents,
        Option: CustomOption,
        ...customComponents,
        ValueContainer: AllowCreateValueContainer,
      }}
      showPlaceholder={inputProps.size === 'sm' && inputProps.labelPlacement === 'outside'}
      styles={styles}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={inputProps.readOnly}
      {...identifiers}
      menuIsOpen={isMenuOpen}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      options={selectOptions}
      ref={ref}
      aria-label={inputProps.label}
      {...inputProps}
      onFocus={onFocus}
      onBlur={privateOnBlur}
      onInputChange={privateOnInputChange}
      tabSelectsValue={false}
      // @ts-expect-error menuRef is not a standard prop for react-select
      menuRef={menuRef}
      isMulti={multiple}
      onCreateOption={handleCreateOption}
    />
  );
}
