import type { Content } from '@radix-ui/react-dropdown-menu';
import { Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import type { ComponentProps, MouseEvent, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Children, isValidElement, useCallback } from 'react';

import { IconMoreHorizontal } from '../../icons/build/IconMoreHorizontal';
import { ButtonIcon, type ButtonIconVariant } from '../button';
import { SROnly } from '../text';

import { StyledTrigger } from './DropDownMenu.styled';
import { DropdownMenuItem } from './DropdownMenuItem';
import { DropdownMenuList } from './DropdownMenuList';

type SharedDropdownMenuProps = PropsWithChildren<{}>;

const MAX_VISIBLE_ITEMS = 5;
const TOGGLE_EXTRA_SPACING = 4; // additional spacing per toggle
const DIVIDER_EXTRA_SPACING = 7; // additional spacing per divider
const BASE_MENU_LIST_HEIGHT = 237; // height of 42 per item times 5 items, plus 3 spacing at bottom + additional 24px for 6th item to show

/**
 * Calculates the height of the menu based on the number and type of children
 */
const getMenuHeight = (children: ReactNode) => {
  const validChildren = Children.toArray(children).filter((child) =>
    isValidElement(child)
  ) as React.ReactElement[];

  const menuItemsLength = validChildren.length;
  const isListScrollable = menuItemsLength > MAX_VISIBLE_ITEMS;

  if (isListScrollable) {
    const firstFiveMenuItems = validChildren.slice(0, 5);

    const numberOfToggleItems = firstFiveMenuItems.filter(
      (child) => child?.props?.Toggle !== undefined
    ).length;

    const numberOfDividers = firstFiveMenuItems.filter(
      (child) => child?.props?.hasDivider === true
    ).length;

    return (
      BASE_MENU_LIST_HEIGHT +
      TOGGLE_EXTRA_SPACING * numberOfToggleItems +
      DIVIDER_EXTRA_SPACING * numberOfDividers
    );
  }

  return 'auto';
};

/* -------------------------------------------------------------------------------------------------
 * LegacyDropdownMenu
 * -----------------------------------------------------------------------------------------------*/

type BaseLegacyDropdownMenuProps = {
  /**
   * Defines the menu button variant
   * @deprecated Use the trigger prop to implement custom triggers.
   * */
  buttonVariant?: ButtonIconVariant;

  /**
   * Defines an icon to be used for the menu button, will overwrite the default
   * @deprecated Use the trigger prop to implement custom triggers.
   * */
  Icon?: React.ElementType;

  /**
   * Defines the position of the trigger to render against when dropdown is open
   * @deprecated We are using an automated approach for alignment.
   * */
  align?: ComponentProps<typeof Content>['align'];

  /**
   * Can be used to pass optional props directly to the menuButton
   * @deprecated Use the trigger prop to implement custom triggers.
   * */
  menuButtonProps?: {
    disabled?: boolean;
    [x: string]: unknown;
  };

  /**
   * Wether to stop the click events propagation or not - defaults to true
   * @deprecated Use `onClick` on the trigger button if needed.
   * */
  stopEventPropagation?: boolean;

  /**
   * The current state of the dropdown menu, also set via Trigger internally
   * @deprecated Use the trigger prop to implement custom triggers.
   * */
  dropdownOpen?: boolean;

  /**
   * Click event handler on the menu button
   * @deprecated Use the trigger prop to implement custom triggers.
   * */
  onClick?: (e: MouseEvent) => void;

  /**
   * Used to set the state of the dropdown menu from something other than the Trigger
   * @deprecated Use the trigger prop to implement custom triggers.
   * */
  setDropdownOpen?: () => void;

  /**
   * Can be used to hide the trigger and action button - defaults to true
   * @deprecated Use the trigger prop to implement custom triggers.
   * */
  showTrigger?: boolean;

  /**
   * @deprecated Use `data-testid`.
   */
  dataTestid?: string;

  /**
   * @deprecated Use styled-components.
   */
  menuListStyle?: Record<string, string | number>;

  disabled?: boolean;
};

type LegacyDropdownMenuProps = { trigger?: never } & BaseLegacyDropdownMenuProps;

function LegacyDropdownMenu({
  children,
  buttonVariant = 'outline',
  Icon = IconMoreHorizontal,
  align = 'end',
  menuButtonProps = {},
  dataTestid = 'actions-menu',
  menuListStyle = {},
  stopEventPropagation = true,
  dropdownOpen,
  setDropdownOpen,
  showTrigger = true,
  onClick,
  ...props
}: SharedDropdownMenuProps & LegacyDropdownMenuProps) {
  const { disabled, ...restMenuButtonProps } = menuButtonProps;

  const handleClick = useCallback(
    (e: MouseEvent) => {
      onClick?.(e);
      if (stopEventPropagation) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [onClick, stopEventPropagation]
  );

  return (
    <Root modal={false} open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <StyledTrigger asChild $showTrigger={showTrigger}>
        <ButtonIcon
          variant={buttonVariant}
          Icon={Icon}
          size="sm"
          aria-label="Actions"
          onClick={handleClick}
          disabled={disabled}
          {...restMenuButtonProps}
        >
          <SROnly>Actions</SROnly>
        </ButtonIcon>
      </StyledTrigger>
      {!disabled && (
        <Portal>
          <DropdownMenuList
            align={align}
            side="bottom"
            style={{
              maxHeight: getMenuHeight(children),
              ...menuListStyle,
            }}
            data-testid={dataTestid}
            {...props}
          >
            {children}
          </DropdownMenuList>
        </Portal>
      )}
    </Root>
  );
}

/* -------------------------------------------------------------------------------------------------
 * NewDropdownMenu
 * -----------------------------------------------------------------------------------------------*/

type RootProps = ComponentProps<typeof Root>;

export type NewDropdownMenuProps = {
  /**
   * The open state of the dropdown menu when it is initially rendered. Use when you do not need to control its open state.
   */
  defaultOpen?: RootProps['defaultOpen'];

  /**
   * The modality of the dropdown menu. When set to `true`, interaction with outside elements will be disabled and only menu content will be visible to screen readers.
   */
  modal?: RootProps['modal'];

  /**
   * Event handler called when the open state of the dropdown menu changes.
   */
  onOpenChange?: RootProps['onOpenChange'];

  /**
   * The controlled open state of the dropdown menu. Must be used in conjunction with `onOpenChange`.
   */
  open?: RootProps['open'];

  /**
   * The dropdown menu trigger.
   */
  trigger: ReactElement<{ disabled?: boolean }>;

  /**
   * Disables the dropdown menu trigger.
   */
  disabled?: boolean;
} & {
  [key in keyof BaseLegacyDropdownMenuProps]?: key extends 'align'
    ? BaseLegacyDropdownMenuProps[key]
    : never;
};

const NewDropdownMenu = ({
  children,
  defaultOpen,
  modal = false,
  onOpenChange,
  open,
  trigger,
  disabled,
  ...props
}: SharedDropdownMenuProps & NewDropdownMenuProps) => {
  // Allow the trigger to be disabled via the dropdown menu or the trigger itself
  const isDisabled: boolean = disabled ?? trigger.props?.disabled ?? false;

  return (
    <Root defaultOpen={defaultOpen} modal={modal} onOpenChange={onOpenChange} open={open}>
      <Trigger asChild disabled={isDisabled}>
        {trigger}
      </Trigger>
      <Portal>
        <DropdownMenuList
          {...props}
          collisionPadding={16}
          sideOffset={4}
          style={{
            maxHeight: getMenuHeight(children),
          }}
        >
          {children}
        </DropdownMenuList>
      </Portal>
    </Root>
  );
};

/* -------------------------------------------------------------------------------------------------
 * DropdownMenu
 * -----------------------------------------------------------------------------------------------*/

function isLegacyDropdownMenuProps(props: any): props is LegacyDropdownMenuProps {
  return props.trigger === undefined;
}

export type DropdownMenuProps = SharedDropdownMenuProps &
  (LegacyDropdownMenuProps | NewDropdownMenuProps);

/**
 * Renders a dropdown menu
 *
 * @example
 * // With regular button helper
 * <DropdownMenu trigger={<DropdownMenuButton />}>
 *   <MenuItem>My menu item</MenuItem>
 * </DropdownMenu>
 *
 * @example
 * // With icon button helper
 * <DropdownMenu trigger={<DropdownMenuIconButton />}>
 *   <MenuItem>My menu item</MenuItem>
 * </DropdownMenu>
 *
 * @example
 * // With custom button
 * <DropdownMenu trigger={<Button>Open Sesame</Button>}>
 *   <MenuItem>My menu item</MenuItem>
 * </DropdownMenu>
 *
 * @example
 * // (deprecated) With legacy implementation
 * <DropdownMenu>
 *   <MenuItem>My menu item</MenuItem>
 * </DropdownMenu>
 *
 * @example
 * // (deprecated) With legacy implementation and custom button
 * const [isMenuOpen, setIsMenuOpen] = useState(false);
 *
 * const toggleIsMenuOpen = () => setIsMenuOpen(!isMenuOpen)
 *
 * <Button onClick={toggleIsMenuOpen}>Open Sesame</Button>
 * <DropdownMenu showTrigger={false} dropdownOpen={isMenuOpen} setDropdownOpen={toggleIsMenuOpen}>
 *   <MenuItem>My menu item</MenuItem>
 * </DropdownMenu>
 */
export const DropdownMenu = (props: DropdownMenuProps) => {
  if (isLegacyDropdownMenuProps(props)) {
    const { menuButtonProps, ...restProps } = props;
    return (
      <LegacyDropdownMenu
        {...restProps}
        menuButtonProps={{
          ...menuButtonProps,
          disabled: menuButtonProps?.disabled || props.disabled,
        }}
      />
    );
  }

  return <NewDropdownMenu {...props} />;
};

DropdownMenu.Item = DropdownMenuItem;
