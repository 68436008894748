import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Text } from '../../../../core/text';
import { IconCheckAlt } from '../../../../icons/build/IconCheckAlt';
import { Box } from '../../../../layout';

interface OptionWrapperProps {
  $isFocused?: boolean;
  $isSelected?: boolean;
  $isDisabled?: boolean;
}

export const OptionWrapper = styled(Text).attrs({
  forwardedAs: 'div',
  variant: 'sm',
})<OptionWrapperProps>`
  --field-select-option-gap: ${({ theme }) => theme.space[4]}px;
  overflow-wrap: anywhere;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[900]};
  cursor: pointer;
  display: flex;
  gap: var(--field-select-option-gap);
  min-height: var(--field-select-menu-item-height);
  padding: ${({ theme }) => `${theme.space[2]}px ${theme.space[5]}px`};

  ${({ $isFocused, $isSelected }) =>
    ($isFocused || $isSelected) &&
    css`
      background: ${({ theme }) => theme.colors.grey[200]};
      &:hover {
        background: ${({ theme }) =>
          $isSelected ? theme.colors.grey[200] : theme.colors.grey[100]};
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${({ theme }) => transparentize(0.5, theme.colors.grey[500])};
      cursor: unset;
    `}
`;

export const OptionPrefix = styled(Box).attrs({ flexShrink: 0 })``;

export const OptionBody = styled(Box).attrs({
  display: 'flex',
  flexGrow: 1,
  flexDirection: ['column', 'row'],
})`
  @media (min-width: ${({ theme }) => theme.breakpointValues.small}px) {
    gap: var(--field-select-option-gap);
  }
`;

export const OptionValue = styled(Box)`
  @media (min-width: ${({ theme }) => theme.breakpointValues.small}px) {
    flex-grow: 1;
  }
  div[class*='-option'] {
    background: none; /* Note: Reset for default styles from react-select  */
    color: inherit;
    &:active {
      background: none; /* Note: Reset for default styles from react-select  */
    }
  }
`;

export const OptionSuffix = styled(Box).attrs({ flexShrink: 0 })`
  color: #646d76;

  @media (min-width: ${({ theme }) => theme.breakpointValues.small}px) {
    align-self: center;
  }
`;

export const SelectedIcon = styled(IconCheckAlt).attrs({ flexShrink: 0 })`
  width: 14px;
`;
