import { TIME_OFF_REQUESTS_ROUTE, TIME_OFF_ROUTE } from '@/src/constants/routes';

export const TIME_OFF_TABS = {
  REQUESTED: {
    label: 'All pending requests',
    value: 'requested',
  },
  SUMMARY: {
    label: 'Time off summary',
    value: 'summary',
  },
  DASHBOARD: {
    label: 'Team absences',
    value: 'dashboard',
  },
  POLICIES: {
    label: 'Custom leave policies',
    value: 'policies',
  },
};

export const TAB_QUERY_PARAMETER = 'tab';

export function getTimeOffRequestsRoute(isNewGlobalNavigationEnabled = true) {
  if (isNewGlobalNavigationEnabled) return { pathname: TIME_OFF_REQUESTS_ROUTE };

  return {
    pathname: TIME_OFF_ROUTE,
    query: {
      [TAB_QUERY_PARAMETER]: TIME_OFF_TABS.REQUESTED.value,
    },
  };
}
