import { homepageEvents, trackEvent } from '@remote-com/analytics';
import { Stack, Text } from '@remote-com/norma';
import { IconV2OutlineArrowRight } from '@remote-com/norma/icons/IconV2OutlineArrowRight';
import kebabCase from 'lodash/kebabCase';
import { useEffect } from 'react';

import { StandaloneLink } from '@/src/components/Button';
import { Fade } from '@/src/components/Fade';
import { WidgetCard } from '@/src/components/WidgetCard';
import { widgetSizes } from '@/src/domains/dashboard/DashboardLayout/constants';
import type { UserPromptAction } from '@/src/domains/userPrompts';
import { useUserPrompts } from '@/src/domains/userPrompts/context';
import { getActivePrompts } from '@/src/domains/userPrompts/helpers';
import type { Prompt } from '@/src/domains/userPrompts/helpers';
import type { $TSFixMe } from '@/types';

import {
  type UserPrompt,
  userPromptCards,
  type CallToActionButton,
  isCallToActionButtonClick,
  isCallToActionComponent,
} from './config';

function CallToActionButtonComponent({
  action,
  callToAction,
  completePrompt,
}: {
  action: string;
  callToAction: CallToActionButton;
  completePrompt: $TSFixMe;
}) {
  const handleOnClick = (cta: CallToActionButton) => {
    trackEvent(homepageEvents.contentCardClicked(kebabCase(action)));

    if (cta.completePromptOnClick) {
      completePrompt(action);
    }

    if (isCallToActionButtonClick(cta)) {
      cta.onClick();
    }
  };

  if (isCallToActionButtonClick(callToAction)) {
    return (
      <StandaloneLink
        onClick={() => handleOnClick(callToAction)}
        IconAfter={IconV2OutlineArrowRight}
      >
        {callToAction.text}
      </StandaloneLink>
    );
  }

  return (
    <StandaloneLink
      target={callToAction.openInNewTab ? '_blank' : '_self'}
      href={callToAction.href}
      onClick={() => handleOnClick(callToAction)}
      IconAfter={IconV2OutlineArrowRight}
    >
      {callToAction.text}
    </StandaloneLink>
  );
}

function UserPromptCardContent({ content }: Pick<UserPrompt, 'content'>) {
  if (typeof content === 'string') {
    return (
      <Text variant="sm" color="grey.600">
        {content}
      </Text>
    );
  }

  return content;
}

function UserPromptCard({
  content,
  action,
  title,
  subtitle,
  callToAction,
  canBeDismissed,
  updateAcrossAllProfiles,
}: UserPrompt & {
  action: UserPromptAction;
}) {
  const { completePrompt, openHelpMenuTooltip, isAnyPromptSkipped } = useUserPrompts();

  useEffect(() => {
    trackEvent(homepageEvents.contentCardLoaded(kebabCase(action)));
  }, [action]);

  return (
    <Fade isVisible>
      <WidgetCard
        title={title}
        onDismiss={
          canBeDismissed
            ? () => {
                if (!isAnyPromptSkipped) {
                  openHelpMenuTooltip();
                }
                completePrompt(action, {
                  dismissed: true,
                  optimisticUpdate: true,
                  updateAcrossAllProfiles,
                });
              }
            : undefined
        }
      >
        {subtitle ? <Text variant="baseMedium">{subtitle}</Text> : null}
        <UserPromptCardContent content={content} />
        {isCallToActionComponent(callToAction) ? (
          <Stack direction="row" gap={5} mt={7} pb={3}>
            {callToAction.component}
          </Stack>
        ) : (
          <CallToActionButtonComponent
            action={action}
            callToAction={callToAction}
            completePrompt={completePrompt}
          />
        )}
      </WidgetCard>
    </Fade>
  );
}

export function userPromptsWidgets(promptsList: Prompt[]) {
  if (!promptsList || !promptsList?.length) return [];

  const activePrompts = getActivePrompts(promptsList);

  return activePrompts.map((prompt) => ({
    id: prompt.action,
    size: widgetSizes.SMALL,
    component: (
      <UserPromptCard
        key={prompt.slug}
        action={prompt.action}
        {...userPromptCards[prompt.action]}
      />
    ),
  }));
}
