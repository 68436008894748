import { createContext, useState, useRef } from 'react';

const Popover = createContext();

const PopoverContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isListeningClickOutside, setIsListeningClickOutside] = useState(true);
  const targetRef = useRef();
  function togglePopover() {
    setIsOpen((bool) => !bool);
  }

  function setListenToClickOutside(bool) {
    setIsListeningClickOutside(bool);
  }
  return (
    <Popover.Provider
      value={{
        isOpen,
        togglePopover,
        targetRef,
        isListeningClickOutside,
        setListenToClickOutside,
      }}
    >
      {children}
    </Popover.Provider>
  );
};

export default Popover;
export { PopoverContextProvider };
