// eslint-disable-next-line remote/prefer-using-the-data-layer
import { makeApiService } from '@/src/services/ApiClient';

export const fetchDocumentsAsEmployee = makeApiService({
  path: '/employee/employments/[slug]/documents',
});

export const fetchMissingAndRequestedEmploymentDocuments = makeApiService({
  path: '/employee/document-requests',
});

export const fetchContractDocumentsAsEmployee = makeApiService({
  path: '/employee/contract-documents',
});

export const createDocumentAsEmployer = makeApiService({
  method: 'post',
  path: '/employer/documents',
});

export const fetchDocumentsAsEmployer = makeApiService({
  path: '/employer/documents',
});

export const createDocumentAsEmployee = makeApiService({
  method: 'post',
  path: '/account/upload-personal-file',
});

export const updateDocumentRequestStatus = makeApiService({
  method: 'put',
  path: '/employee/document-requests/[slug]',
});
