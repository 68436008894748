import { FeedbackMessage, FEEDBACK_MESSAGE_INFO, Text } from '@remote-com/norma';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { composeFieldExtra } from '@/src/components/Form/DynamicForm/composeFields';
import FormStateFeedback from '@/src/components/Form/FormStateFeedback';
import { DynamicFormModal } from '@/src/components/Modal/DynamicFormModal';
import { useCompanyHasTermsNotification } from '@/src/domains/registration/terms-of-service/hooks';
import useCompanyData from '@/src/domains/team/hooks/useCompanyData';
import { useCustomMutation } from '@/src/hooks/useCustomMutation';
import { acceptTermsOfService } from '@/src/services/Employer';

import {
  composeFieldAcceptTermsOfService,
  getAcceptTermsOfServiceValidationSchema,
  validateTermsOfServiceException,
} from './fields';

const formName = 'acceptTermsOfService';

const getFields = ({ isSuccess, setTermsAcceptedAt }) => [
  composeFieldExtra({
    visibilityCondition: () => isSuccess,
    Component: () => {
      return (
        <FormStateFeedback
          formState={{ successMessage: 'Successfully accepted the Terms of Service.' }}
        />
      );
    },
  }),
  composeFieldExtra({
    includeValueToApi: false,
    Component: () => {
      return (
        <>
          <Text mb={3} variant="lgMedium" color="grey.900">
            We have new Terms of Service
          </Text>
          <Text mb={7} variant="sm">
            We updated our Terms of Service to make it easier for you to onboard employees at the
            click of a button. No more signing Service Level Agreements and Service Orders for every
            employee! 🎉
          </Text>
          <FeedbackMessage variant={FEEDBACK_MESSAGE_INFO}>
            When you agree to the new Terms of Service, it replaces prior MSAs, country-specific
            SLAs, and SOs between you and Remote. The status of your TEAM plan SLAs, employees, and
            employment agreements will stay the same!
          </FeedbackMessage>
        </>
      );
    },
  }),
  composeFieldAcceptTermsOfService({ setTermsAcceptedAt }),
];

const fieldValues = {
  tosAcceptDate: false,
};

const AcceptTermsOfServiceModal = ({ onClose, params = {} }) => {
  /**
   * Once the mutation has completed we want the page to do a full refresh
   * before allowing the user to continue.
   *
   * window.location will take small amount of time to execute. This loading indicator
   * is to reflect the loading state of reloading page and the progress of the mutation.
   */
  const { checkForTermsNotification = true } = params;
  const [isLoading, setLoading] = useState(false);
  const [termsAcceptedAt, setTermsAcceptedAt] = useState(null);
  const { data: companyHasTermsNotification = false } = useCompanyHasTermsNotification({
    options: { enabled: checkForTermsNotification },
  });

  const {
    isLoading: isLoadingCompany,
    data: company,
    error: errorLoadingCompany,
  } = useCompanyData();

  const { mutate, customState, isSuccess } = useCustomMutation((tosAcceptDate) =>
    acceptTermsOfService({
      pathParams: { slug: company?.slug },
      bodyParams: { tosAcceptDate },
    })
  );

  function handleOnSubmit(values, { setFieldValue }) {
    setLoading(true);

    mutate(termsAcceptedAt, {
      onSuccess: () => {
        /**
         * Accepting the terms of service is fundamental change that gives
         * customer access to more features. By refreshing the page we ensure that
         * the customer has access to all the features available to companies whom
         * have accepted the terms of service.
         */

        // Refresh the current page (without the modal query param)
        const refreshUrl = new URL(window.location.href);
        refreshUrl.searchParams.delete('modal');
        window.location.href = refreshUrl.href;
      },
      onError: (exception) => {
        setLoading(false);

        const { data } = exception.response || {};
        const errors = data?.errors || [];
        validateTermsOfServiceException({ errors, resetInput: setFieldValue });
      },
    });
  }

  return (
    <DynamicFormModal
      TODO_MIGRATE_handleOnSubmit="TODO"
      onCancel={onClose}
      isLoadingData={isLoadingCompany}
      loadingDataError={errorLoadingCompany}
      confirmLoading={isLoading}
      formName={formName}
      modalProps={{
        visible: checkForTermsNotification ? companyHasTermsNotification : true,
        headerTitle: "Remote's Terms of Service",
        cancelButtonText: 'Skip for now',
        saveButtonText: 'Save and close',
        showCancelButton: true,
      }}
      fields={getFields({ isSuccess, setTermsAcceptedAt })}
      fieldValues={fieldValues}
      onSubmit={handleOnSubmit}
      formProps={{
        validationSchema: getAcceptTermsOfServiceValidationSchema(),
        formError: customState?.errorMessage,
      }}
    />
  );
};

AcceptTermsOfServiceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { AcceptTermsOfServiceModal };
