import styled from 'styled-components';

import { ButtonIcon, ButtonInline } from '../../../core/button';
import { ListDisc } from '../../../core/list';
import { Text } from '../../../core/text';
import { Tooltip } from '../../../core/tooltip';
import { Box } from '../../../foundations/layout/Box';
import { IconHelpCircle } from '../../../icons/build/IconHelpCircle';

const BoxStyled = styled(Box)`
  /* Semantically must be the first thing in the DOM for better A11Y UX,
  Visually place it at the footer for better UI */
  position: absolute;
  right: ${({ theme }) => theme.space[5]}px;
  bottom: 15px; /* pixel-perfect */
`;

const InstructionsStyled = styled(Box)`
  text-align: left;
`;

const tooltipSize = '45ch';
const tooltipStyles = {
  wrapper: {
    minWidth: tooltipSize,
  },
};

export function DatePickerRangeHints(props: {}) {
  return (
    <BoxStyled {...props}>
      <Tooltip
        // A11Y. Prevent SR from reading the tooltip on focus. Quickfix before Linear DEVXP-1398
        trigger="mouseenter click"
        appendTo="parent"
        styles={tooltipStyles}
        maxWidth={tooltipSize}
        label={
          <InstructionsStyled>
            <Text as="h6" variant="smSemiBold" mb={-2}>
              Date picker instructions:
            </Text>
            <ListDisc size="sm">
              <li>First, select the Start Date.</li>
              <li>Then you'll have to select the End Date.</li>
              <li>
                If you don't want to select an End Date, press twice the Start Date that you want.
              </li>
              <li>To modify the End Date, you need to start over.</li>
              <li>
                <span>
                  {/* Need extra wrapper because of flexbox in the link */}
                  For help, reach out to{' '}
                  <ButtonInline as="a" href="mailto:accessibility@remote.com">
                    accessibility@remote.com
                  </ButtonInline>
                  .
                </span>
              </li>
            </ListDisc>
          </InstructionsStyled>
        }
      >
        <ButtonIcon
          Icon={IconHelpCircle}
          variant="ghost"
          size="xs"
          aria-label="Date picker instructions"
        />
      </Tooltip>
    </BoxStyled>
  );
}
