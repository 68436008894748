import { employmentType, employmentTypeLabels } from '@/src/domains/employment/constants';

export const columnContractAccessorLabels = {
  contract: {
    activatedAt: 'Date activated',
    compensations: 'Gross compensations',
    status: 'Contract status',
    legalEntity: {
      address: {
        country: {
          name: 'Legal entity country',
        },
      },
    },
    country: {
      name: 'Employment country',
      currency: {
        code: 'Employment country currency',
      },
    },
    contractDetails: {
      details: {
        availablePto: 'Available PTO',
        availablePtoType: 'Available PTO type',
        eligibleToWorkInResidingCountry: 'Work eligibility',
        hasBonus: 'Has bonus?',
        hasCommissions: 'Has commissions?',
        isSalaryProrated: 'Salary prorated',
        workAddressIsHomeAddress: 'Work address = home address?',
        workHoursPerWeek: 'Weekly work hours',
        itEquipment: 'IT equipment',
        nonCompeteClauseApply: 'Has non-compete clause?',
        hasSigningBonus: 'Has signing bonus?',
        equityCompensation: {
          offerEquityCompensation: 'Offer equity compensation',
        },
        stateOfResidency: 'State of residency',
      },
    },
  },
};

export const columnAccessorLabels = {
  employment: {
    ...columnContractAccessorLabels,
    employeePspId: 'Employee PSP ID',
    externalEmploymentId: 'External employment ID',
    department: {
      name: 'Department',
    },
  },
};

/** Set of accessors not to be included in additional columns or column
 * configuration list because they're unnecessary and only add noise.
 * */

export const columnAccessorExclusionSet = new Set([
  // Should be removed from the "Data columns" list since the data is still present in the DB
  // but should be seen by admins unless they are actively in the process of overwriting it via a
  // Contract Details update.
  'employment.contract.contractDetails.details.contractDuration',

  // These are a hack! Because this data is only available on certain records, we've added these
  // columns manually (to ensure they are always present) to the TeamAdminTableComponent itself.
  // If we don't exclude them via here, they'll appear twice when any row in the Table has the
  // data.
  'employment.contract.contractDetails.details.probationLength',
  'employment.contract.contractDetails.details.probationLengthWeeks',
  'employment.contract.contractDetails.details.probationLengthDays',
  'employment.contract.contractDetails.details.probationLengthWorkingDays',
  'employment.contract.probationPeriodEndDate',

  'employment.country.code',
  'employment.contract.slug',
  'employment.contract.compensations',
  'employment.contract.country.slug',
  'employment.contract.country.code',
  'employment.contract.country.currency.name',
  'employment.contract.country.currency.slug',
  'employment.contract.country.currency.symbol',
  'employment.contract.files',
  'employment.slug',
  'employment.contract.legalEntity.slug',
  'employment.contract.legalEntity.contract.slug',
  'employment.contract.legalEntity.address.address',
  'employment.contract.legalEntity.address.addressLine2',
  'employment.contract.legalEntity.address.city',
  'employment.contract.legalEntity.address.country.features',
  'employment.contract.legalEntity.address.country.slug',
  'employment.contract.legalEntity.address.country.code',
  'employment.contract.legalEntity.address.localDetails',
  'employment.contract.legalEntity.address.postalCode',
  'employment.contract.legalEntity.address.state',
  'employment.contract.legalEntity.address.slug',
  'employment.department',
  'company.slug',
]);

export const ONBOARDING_TAB_VALUES = {
  ALL_HIRES: 'all_hires',
  HIRED_BY_REMOTE: 'hired_by_remote',
  HIRED_BY_MY_COMPANY: 'hired_by_my_company',
  CONTRACTOR: employmentType.CONTRACTOR,
};

export const ONBOARDING_TABS = {
  [ONBOARDING_TAB_VALUES.ALL_HIRES]: {
    label: 'All hires',
    value: ONBOARDING_TAB_VALUES.ALL_HIRES,
    totals(data) {
      return (
        data?.totalIncompleteFullTime +
        data?.totalIncompleteContractors +
        data?.totalIncompleteDirectEmployees
      );
    },
  },
  [ONBOARDING_TAB_VALUES.HIRED_BY_REMOTE]: {
    label: 'Hired by Remote',
    value: ONBOARDING_TAB_VALUES.HIRED_BY_REMOTE,
    totals(data) {
      // We can't use `totalIncompleteFullTimeEor` because if an employer adds an EOR employee but exits the flow before selecting a price option,
      // that employee won't be added to the EOR total because he/she isn't assigned an EOR product yet. So until it's fixed on the BE we need to compute the total
      return data?.totalIncompleteFullTime - data?.totalIncompleteFullTimeGlobalPayroll;
    },
  },
  [ONBOARDING_TAB_VALUES.HIRED_BY_MY_COMPANY]: {
    label: 'Hired by my company',
    value: ONBOARDING_TAB_VALUES.HIRED_BY_MY_COMPANY,
    totals(data) {
      return data?.totalIncompleteFullTimeGlobalPayroll + data?.totalIncompleteDirectEmployees;
    },
  },
  [ONBOARDING_TAB_VALUES.CONTRACTOR]: {
    label: `${employmentTypeLabels[employmentType.CONTRACTOR]}s`,
    value: ONBOARDING_TAB_VALUES.CONTRACTOR,
    totals(data) {
      return data?.totalIncompleteContractors;
    },
  },
};

export const CONTRACTOR_RATE_TYPES = /** @type {const} */ ({
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  ONE_OFF: 'one_off',
});

export const RECURRING_CONTRACTOR_RATE_TYPE_LABELS = /** @type {const} */ ({
  [CONTRACTOR_RATE_TYPES.HOURLY]: 'Hourly',
  [CONTRACTOR_RATE_TYPES.DAILY]: 'Daily',
  [CONTRACTOR_RATE_TYPES.WEEKLY]: 'Weekly',
  [CONTRACTOR_RATE_TYPES.MONTHLY]: 'Monthly',
});

export const CONTRACTOR_RATE_TYPE_UNITS = /** @type {const} */ ({
  [CONTRACTOR_RATE_TYPES.HOURLY]: 'hour',
  [CONTRACTOR_RATE_TYPES.DAILY]: 'day',
  [CONTRACTOR_RATE_TYPES.WEEKLY]: 'week',
  [CONTRACTOR_RATE_TYPES.MONTHLY]: 'month',
});

export const CONTRACTOR_RATE_PAY_FREQUENCIES = /** @type {const} */ ({
  WEEKLY: 'weekly',
  BI_WEEKLY: 'bi_weekly',
  SEMI_MONTHLY: 'semi_monthly',
  MONTHLY: 'monthly',
});

export const CONTRACTOR_RATE_PAY_FREQUENCY_LABELS = /** @type {const} */ ({
  [CONTRACTOR_RATE_PAY_FREQUENCIES.WEEKLY]: 'Weekly',
  [CONTRACTOR_RATE_PAY_FREQUENCIES.BI_WEEKLY]: 'Bi-weekly',
  [CONTRACTOR_RATE_PAY_FREQUENCIES.SEMI_MONTHLY]: 'Semi-monthly',
  [CONTRACTOR_RATE_PAY_FREQUENCIES.MONTHLY]: 'Monthly',
});
