import { Box, sharedTransition, themeV2 as theme } from '@remote-com/norma';
import isNaN from 'lodash/isNaN';
import styled, { css } from 'styled-components';

export const SegmentedBarTrack = styled(Box)<{
  $height: string;
  $trackColor: string;
}>`
  width: 100%;
  height: ${({ $height }) => $height};
  border-radius: ${({ $height }) => $height};
  background-color: ${({ $trackColor }) => $trackColor};
`;

export const SegmentedBarValue = styled(Box)<{
  $minWidth: string;
  $valueColor: string;
  $percentValue: number;
}>`
  border-radius: inherit;
  height: 100%;
  min-width: ${({ $minWidth }) => $minWidth};
  background-color: ${({ $valueColor }) => $valueColor};
  ${({ $percentValue }) =>
    !isNaN($percentValue) &&
    css`
      width: ${$percentValue}%;
    `};
  ${sharedTransition('width')};
`;

type SegmentedBarProps = {
  max: number;
  value: number;
  height?: string;
  className?: string;
  trackColor?: string;
  valueColor?: string;
};

const SegmentedBar = ({
  max,
  value,
  height = '10px',
  className,
  trackColor = theme.colors.bayoux_15,
  valueColor = theme.colors.brand[900],
}: SegmentedBarProps) => {
  const progressPercent = (value * 100) / max;

  if (isNaN(progressPercent)) {
    return null;
  }

  return (
    <SegmentedBarTrack
      $height={height}
      $trackColor={trackColor}
      className={className}
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={max}
    >
      <SegmentedBarValue
        $minWidth={height}
        $percentValue={progressPercent}
        $valueColor={valueColor}
      />
    </SegmentedBarTrack>
  );
};

export default SegmentedBar;
