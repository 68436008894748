import { Stack, Radio as RadioV2 } from '@remote-com/norma';

export const RadioGroup = ({ children, $direction, props }) => {
  const isRadioV2 = children.type === RadioV2.type;
  let gap = ['20px'];

  if (isRadioV2) {
    gap = $direction === 'row' ? [7] : [5];
  }

  return (
    <Stack mt={5} gap={gap} {...props} direction={$direction}>
      {children}
    </Stack>
  );
};
