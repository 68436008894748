import type { MouseEvent, MutableRefObject } from 'react';
import styled, { css } from 'styled-components';

import { IconV2OutlineChevronDown as IconChevronDown } from '../../icons/build/IconV2OutlineChevronDown';
import { getInputColors } from '../form-group';

export const InputSelectAsideIcon = styled(IconChevronDown)<{
  $hasError: boolean | undefined;
  $isReadOnly: boolean | undefined;
  $hasFocus: boolean | undefined;
  tabIndex: number | undefined;
  onClick: (e: MouseEvent<SVGSVGElement, MouseEvent<Element, MouseEvent>>) => void;
  ref: MutableRefObject<SVGSVGElement | undefined>;
}>`
  transition: transform 250ms cubic-bezier(0.37, 0, 0.63, 1);
  fill: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  outline: none;

  ${({ $hasError }) =>
    $hasError &&
    css`
      fill: ${({ theme }) => getInputColors(theme).borderError};
    `};

  ${({ $isReadOnly }) =>
    $isReadOnly &&
    css`
      cursor: not-allowed;
      fill: ${({ theme }) => getInputColors(theme).borderReadOnly};
    `};

  ${({ $hasFocus }) =>
    $hasFocus &&
    css`
      fill: ${({ theme }) => getInputColors(theme).borderFocus};
      transform: rotate(180deg);
    `};
`;
