import type { PayrollRun } from '@/src/api/config/employ/payrollRun.types';

export function renameFileForBulkUpload(file: { name: string }, rename?: string) {
  if (!rename) return undefined;

  const fileExtension = file.name.split('.').length > 1 ? `.${file.name.split('.').pop()}` : '';

  return `${rename}${fileExtension}`;
}

export function getLatestGrossToNetReport(payrollRun: PayrollRun.AdminPayrollRunResponse['data']) {
  return payrollRun.latestReportOfEachType?.find((report) => report.type === 'gross_to_net_report');
}
