import type { PillProps } from '@remote-com/norma';
import { IconV2DuotoneInfo } from '@remote-com/norma/icons/IconV2DuotoneInfo';
import type { ValueOf } from 'type-fest';

import { NORDIC_AND_SWISS_CURRENCIES } from '../payments/constants';

export const CONTRACTOR_BILLING_CURRENCIES_STRIPE_SUPPORT = new Set(NORDIC_AND_SWISS_CURRENCIES);

export const freeTrialStatuses = {
  ELIGIBLE: 'eligible',
  INELIGIBLE: 'ineligible',
  ACTIVE: 'active',
  EXPIRED: 'expired',
} as const;

export const contractorSupportedPayments = {
  STRIPE: 'stripe',
  WISE: 'wise',
  WISE_VIRTUAL: 'wise_virtual',
} as const;

export const billableActivityEventTypes = {
  ISSUED_CONTRACTOR_INVOICE: 'issued_contractor_invoice',
  PAID_CONTRACTOR_INVOICE: 'paid_contractor_invoice',
  SIGNED_CONTRACT_DOCUMENT: 'signed_contract_document',
} as const;

export const lastBillableActivityColumnId = 'billableContractorActivityPerformedAt';

export const billableActivityEventLabels = {
  [billableActivityEventTypes.ISSUED_CONTRACTOR_INVOICE]: 'Invoice',
  [billableActivityEventTypes.PAID_CONTRACTOR_INVOICE]: 'Payment',
  [billableActivityEventTypes.SIGNED_CONTRACT_DOCUMENT]: 'Contract',
} as const;

export const billableActivityPillProps = {
  [billableActivityEventTypes.ISSUED_CONTRACTOR_INVOICE]: {
    tone: 'info',
  },
  [billableActivityEventTypes.PAID_CONTRACTOR_INVOICE]: {
    tone: 'success',
  },
  [billableActivityEventTypes.SIGNED_CONTRACT_DOCUMENT]: {
    tone: 'error',
  },
};

export const contractorSubscriptionPlanIdentifiers = {
  STANDARD: 'urn:remotecom:resource:product:contractor:standard:monthly',
  PLUS: 'urn:remotecom:resource:product:contractor:plus:monthly',
  PROTECT: 'urn:remotecom:resource:product:contractor:aor:monthly',
} as const;

export type ContractorSubscriptionPlanIdentifier = ValueOf<
  typeof contractorSubscriptionPlanIdentifiers
>;

export const contractorSubscriptionPlanLabels = {
  [contractorSubscriptionPlanIdentifiers.STANDARD]: 'CM',
  [contractorSubscriptionPlanIdentifiers.PLUS]: 'CM Plus',
  [contractorSubscriptionPlanIdentifiers.PROTECT]: 'CM Protect',
} as const;

export const contractorSubscriptionPlanPillProps: Record<string, Partial<PillProps>> = {
  [contractorSubscriptionPlanIdentifiers.STANDARD]: {
    tone: 'primary',
    Icon: IconV2DuotoneInfo,
  },
  [contractorSubscriptionPlanIdentifiers.PLUS]: {
    tone: 'purple',
    Icon: IconV2DuotoneInfo,
  },
  [contractorSubscriptionPlanIdentifiers.PROTECT]: {
    tone: 'warning',
    Icon: IconV2DuotoneInfo,
  },
} as const;

export const contractorSubscriptionPlanTooltipLabel = {
  [contractorSubscriptionPlanIdentifiers.STANDARD]: 'Contractor Management',
  [contractorSubscriptionPlanIdentifiers.PLUS]:
    'Protected against misclassification risk with Contractor Management Plus.',
  [contractorSubscriptionPlanIdentifiers.PROTECT]:
    'This contractor is hired through Contractor Management Protect.',
} as const;

export const REMOTE_AI_ERROR_SOURCE = 'REMOTE_AI';
export const REMOTE_AI_SERVICES_AND_DELIVERABLES_ERROR_MESSAGE =
  'You cannot control how a contractor completes the Services and Deliverables. This means, for example, that you can’t specify their working hours or use of subcontractors. Please make sure this field includes only project names, descriptions, and deliverables. We do not allow hyperlinks.';

// For compliance reasons contractor's job title is referred to as "position"
export const CONTRACTOR_JOB_TITLE_LABEL = `Contractor's position`;
