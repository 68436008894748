// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineColumns = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M9.97 1.25h4.06c1.38 0 2.45 0 3.32.07.88.07 1.6.22 2.26.56a5.75 5.75 0 0 1 2.51 2.51c.34.66.49 1.38.56 2.26.07.87.07 1.94.07 3.32v4.06c0 1.38 0 2.45-.07 3.32-.07.88-.22 1.6-.56 2.26a5.75 5.75 0 0 1-2.51 2.51c-.66.34-1.38.49-2.26.56-.87.07-1.94.07-3.32.07H9.97c-1.38 0-2.45 0-3.32-.07a5.92 5.92 0 0 1-2.26-.56 5.75 5.75 0 0 1-2.51-2.51 5.92 5.92 0 0 1-.56-2.26c-.07-.87-.07-1.94-.07-3.32V9.97c0-1.38 0-2.45.07-3.32.07-.88.22-1.6.56-2.26a5.75 5.75 0 0 1 2.51-2.51 5.92 5.92 0 0 1 2.26-.56c.87-.07 1.94-.07 3.32-.07Zm2.78 20H14c1.41 0 2.43 0 3.22-.07.79-.06 1.3-.18 1.71-.4.8-.4 1.45-1.05 1.86-1.85.2-.41.33-.92.4-1.7.06-.8.06-1.82.06-3.23v-4c0-1.41 0-2.43-.07-3.22a4.47 4.47 0 0 0-.4-1.7 4.25 4.25 0 0 0-1.85-1.87 4.47 4.47 0 0 0-1.7-.4c-.8-.06-1.82-.06-3.23-.06h-1.25v18.5Zm-1.5-18.5v18.5H10c-1.41 0-2.43 0-3.22-.07a4.47 4.47 0 0 1-1.7-.4 4.25 4.25 0 0 1-1.87-1.85 4.47 4.47 0 0 1-.4-1.7c-.06-.8-.06-1.82-.06-3.23v-4c0-1.41 0-2.43.07-3.22.06-.79.18-1.3.4-1.7.4-.8 1.05-1.46 1.85-1.87.41-.2.92-.33 1.7-.4.8-.06 1.82-.06 3.23-.06h1.25Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineColumns);
export { ForwardRef as IconV2OutlineColumns };