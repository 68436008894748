export default async function fetchAllPagesWithCursor(
  queryFn: (params: unknown) => Promise<{ data: unknown[]; afterCursor: string | null }>,
  stateToUse: Record<string, unknown>
) {
  let rowsData: unknown[] = [];
  let done = false;
  let afterCursor = null;

  while (!done) {
    // eslint-disable-next-line no-await-in-loop
    const response = await queryFn({
      ...stateToUse,
      pagination_mode: 'for_export',
      after_cursor: afterCursor,
    });

    rowsData = [...rowsData, ...response.data];
    done = !response.afterCursor;
    afterCursor = response.afterCursor;
  }

  return rowsData;
}
