import { Stack, getInputColors } from '@remote-com/norma';
import styled, { css } from 'styled-components';

type AIAssistantWrapperProps = {
  $isExpanded: boolean;
};

type StyledInputWrapperProps = {
  $isLoading: boolean;
};

export const AI_FORM_ASSISTANT_HEIGHT = 44;

export const AIFormAssistantWrapper = styled(Stack).attrs({
  position: 'absolute',
  left: 3,
  padding: 3,
  border: '1px solid',
  borderRadius: 'md',
  borderColor: 'grey.200',
  backgroundColor: 'blank',
  zIndex: 2,
})<AIAssistantWrapperProps>`
  top: calc(100% - ${({ theme }) => theme.space[3]}px - ${AI_FORM_ASSISTANT_HEIGHT}px);
  box-shadow: 0px 0px ${({ theme }) => theme.space[4]}px 0px rgba(15, 20, 25, 0.08);
  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      width: calc(100% - (${({ theme }) => theme.space[3]}px * 2));
    `}

  // targeting Popover.Content (the only div child)
  > div {
    display: contents;
  }
`;

export const StyledInputWrapper = styled(Stack).attrs({
  direction: 'row',
  alignItems: 'center',
  gap: 3,
})<StyledInputWrapperProps>`
  border: 1px solid ${({ theme }) => theme.colors.grey[200]};
  border-radius: 999px;
  padding: ${({ theme }) =>
    `${theme.space[4]}px ${theme.space[5]}px ${theme.space[4]}px ${theme.space[6]}px`};

  &:focus-within,
  &:hover:focus-within {
    border-color: ${({ theme }) => getInputColors(theme).borderFocus};
  }
  &:hover {
    border-color: ${({ theme }) => getInputColors(theme).borderHover};
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      border-color: ${({ theme }) => theme.colors.grey[200]} !important;
    `}
`;

export const StyledInput = styled.textarea`
  appearance: none;
  border: 0;
  width: 100%;
  padding: 0;
  resize: none;
  max-height: 2lh;
  margin-bottom: 0 !important;
  ${({ theme }) => css`
    ${theme.typography.sm}
    color: ${theme.colors.grey[900]};
  `}

  &:disabled {
    color: ${({ theme }) => theme.colors.grey[500]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey[500]};
  }

  &:focus {
    outline: none;
  }
`;

export const StyledResultWrapper = styled(Stack).attrs({
  padding: 4,
  gap: 5,
  borderRadius: 'md',
  backgroundColor: 'background.base',
})`
  word-wrap: break-word;
  white-space: pre-line;
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

export const StyledAnimatedEllipsis = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis steps(1, end) 1s infinite;
    content: '';

    ${({ theme }) => css`
      ${theme.typography.xsMedium}
      color: ${theme.colors.brand[600]};
    `}
  }

  @keyframes ellipsis {
    0% {
      content: '';
    }
    25% {
      content: '•';
    }
    50% {
      content: '••';
    }
    75% {
      content: '•••';
    }
    100% {
      content: '';
    }
  }
`;
