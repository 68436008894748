import { getStartAndEndTimesInMinutes } from '@/src/components/Ui/Form/WorkWeekScheduleTableField/utils/getStartAndEndTimesInMinutes';

/**
 * Returns true if the lunch start and end time are within the working hours.
 * @param {{hours: number, minutes: number}} startTime Start time
 * @param {{hours: number, minutes: number}} endTime End time, if earlier than start time, it describes a time for the following day
 * @param {{hours: number, minutes: number}} lunchStartTime Lunch start time
 * @param {{hours: number, minutes: number}} lunchEndTime Lunch end time, if earlier than start time, it describes a time for the following day
 * @returns {boolean}
 */
export function isLunchWithinWorkingHours(startTime, endTime, lunchStartTime, lunchEndTime) {
  if (!startTime || !endTime || !lunchStartTime || !lunchEndTime) {
    return false;
  }

  const { startTimeInMinutes, endTimeInMinutes } = getStartAndEndTimesInMinutes(startTime, endTime);
  const { startTimeInMinutes: lunchStartTimeInMinutes, endTimeInMinutes: lunchEndTimeInMinutes } =
    getStartAndEndTimesInMinutes(lunchStartTime, lunchEndTime);

  return lunchStartTimeInMinutes >= startTimeInMinutes && lunchEndTimeInMinutes <= endTimeInMinutes;
}
