import { FormGroup, Input, useFormGroupContext } from '@remote-com/norma';
import { useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useState } from 'react';

export const stripeBaseStyles = {
  fontFamily: '',
  fontSize: '16px',
  lineHeight: '24px',
};

function InputElement({
  StripeElementsComponent,
  setError,
  setHasValue,
  setHasFocus,
  setTouched,
  ...props
}) {
  const { onBlur, onChange, onFocus, ...inputProps } = useFormGroupContext();

  const handleOnChange = (event) => {
    onChange?.(event);
    setError(event.error);
    setHasValue(!event.empty);
  };

  const handleOnFocus = () => {
    onFocus?.();
  };

  const handleOnBlur = (event) => {
    setHasFocus(false);
    setTouched(true);

    onBlur?.(event);
  };

  return props.readOnly ? (
    <Input {...inputProps} />
  ) : (
    <StripeElementsComponent
      onChange={handleOnChange}
      options={{
        placeholder: '',
        style: {
          base: stripeBaseStyles,
        },
      }}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    />
  );
}

export function InputStripeElements({ StripeElementsComponent, ...props }) {
  const [error, setError] = useState(undefined);
  const [hasFocus, setHasFocus] = useState(false);
  const [hasValue, setHasValue] = useState(props.readOnly ? !!props.value : false);
  const [touched, setTouched] = useState(false);

  const stripeElements = useElements();
  const elementTarget = StripeElementsComponent?.target;

  const handleOnClick = () => {
    const element = stripeElements.getElement(elementTarget);

    element?.focus();
  };

  return (
    <FormGroup
      dangerouslyHideA11yLabel={!props.readOnly}
      errorText={touched && error?.message ? error.message : ''}
      hasFocus={hasFocus}
      hasValue={hasValue}
      onClick={handleOnClick}
      {...props}
    >
      <InputElement
        setError={setError}
        setTouched={setTouched}
        setHasValue={setHasValue}
        setHasFocus={setHasFocus}
        StripeElementsComponent={StripeElementsComponent}
        {...props}
      />
    </FormGroup>
  );
}

InputStripeElements.propTypes = {
  StripeElementsComponent: PropTypes.element.isRequired,
};
