import { Box, Stack, Text } from '@remote-com/norma';
import type { ElementType, PropsWithChildren } from 'react';
import styled from 'styled-components';

/* -------------------------------------------------------------------------------------------------
 * Section
 * -----------------------------------------------------------------------------------------------*/

type SectionProps = PropsWithChildren<{}>;

const StyledSection = styled(Stack)`
  gap: ${({ theme }) => theme.space[3]}px;
`;

export const Section = (props: SectionProps) => {
  return <StyledSection {...props} />;
};

/* -------------------------------------------------------------------------------------------------
 * SectionHeader
 * -----------------------------------------------------------------------------------------------*/

type SectionHeaderProps = PropsWithChildren<{}>;

const StyledSectionHeader = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.space[2]}px;
`;

export const SectionHeader = (props: SectionHeaderProps) => {
  return <StyledSectionHeader {...props} />;
};

/* -------------------------------------------------------------------------------------------------
 * SectionTitle
 * -----------------------------------------------------------------------------------------------*/

type SectionTitleProps = PropsWithChildren<{}>;

const StyledSectionTitle = styled(Text).attrs({ forwardedAs: 'h4' })`
  ${({ theme }) => theme.typography.xsMedium}

  color: ${({ theme }) => theme.colors.grey[600]};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[2]}px;
`;

export const SectionTitle = (props: SectionTitleProps) => {
  return <StyledSectionTitle {...props} />;
};

/* -------------------------------------------------------------------------------------------------
 * SectionTitleIcon
 * -----------------------------------------------------------------------------------------------*/

type SectionTitleIconProps = {
  icon: ElementType;
};

const StyledSectionTitleIcon = styled.svg`
  --columns-configuration-drawer-section-icon-size: 16px;

  height: var(--columns-configuration-drawer-section-icon-size);
  width: var(--columns-configuration-drawer-section-icon-size);
`;

export const SectionTitleIcon = ({ icon }: SectionTitleIconProps) => {
  return <StyledSectionTitleIcon as={icon} />;
};
