import { Content } from '@radix-ui/react-collapsible';
import styled from 'styled-components';

import { Separator } from '@/src/components/Separator';

export const StyledContent = styled(Content)`
  @media (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: slideDown 300ms ease-out;
    }

    &[data-state='closed'] {
      animation: slideUp 300ms ease-out;
    }
  }

  @keyframes slideDown {
    from {
      clip-path: inset(0 0 100% 0);
      height: 0;
    }
    to {
      clip-path: inset(0);
      height: var(--radix-collapsible-content-height);
    }
  }

  @keyframes slideUp {
    from {
      clip-path: inset(0);
      height: var(--radix-collapsible-content-height);
    }
    to {
      clip-path: inset(0 0 100% 0);
      height: 0;
    }
  }
`;

export const StyledSeparator = styled(Separator)`
  margin: calc(${({ theme }) => `${theme.space[2]}px`} - 1px) -3px;
`;
