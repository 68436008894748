import styled, { css } from 'styled-components';

import { Text } from '../text';

import type { AvatarTone, AvatarSize } from '.';

const sizesMap = {
  xxs: {
    avatar: '24px',
    text: '2xs',
  },
  xs: {
    avatar: '32px',
    text: '2xs',
  },
  sm: {
    avatar: '36px',
    text: 'xs',
  },
  md: {
    avatar: '48px',
    text: 'base',
  },
  lg: {
    avatar: '60px',
    text: 'xl',
  },
  xl: {
    avatar: '80px',
    text: '2xl',
  },
};

const base = css<{ $size: AvatarSize }>`
  width: ${({ $size }) => sizesMap[$size].avatar};
  min-height: ${({ $size }) => sizesMap[$size].avatar};
  height: ${({ $size }) => sizesMap[$size].avatar};
  max-height: ${({ $size }) => sizesMap[$size].avatar};
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  overflow: hidden;
`;

export const Img = styled.img<{
  $size: AvatarSize;
}>`
  ${base};
  display: inline-block;
  object-fit: cover;
  object-position: center;
`;

type InitialsImageProps = {
  $tone: AvatarTone;
  $size: AvatarSize;
};

export const InitialsImage = styled(Text).attrs(({ $tone, $size }: InitialsImageProps) => ({
  bg: $tone.background,
  color: $tone.text,
  variant: sizesMap[$size].text,
}))<InitialsImageProps>`
  ${base};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ $size }) => ['xl', 'lg'].includes($size) && 'var(--typography-fontBrand)'};
  font-weight: ${({ $size }) => (['xxs', 'xs', 'sm'].includes($size) ? 600 : 500)};
  line-height: 1;
  text-transform: uppercase;
`;
