import { components } from 'react-select';

export default function EmployeeContractRemove({ children, ...rest }) {
  return (
    <components.MultiValueRemove
      {...rest}
      innerProps={{ ...rest.innerProps, 'data-dd-action-name': 'Redacted' }}
    >
      {children}
    </components.MultiValueRemove>
  );
}
