import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { InputCheckbox } from '@/src/components/Ui/Form';

/**
 * Consider using the CheckboxFieldLight component when dealing with forms that contain a large number of inputs,
 * such as the Role creation form, especially if you find delays when interacting with these inputs.
 *
 * In scenarios where a form has many inputs, using Formik Field component and the useField hook may result in excessive re-renders
 * when an input is modified, leading to performance degradation.
 */
export const CheckboxFieldLight = ({
  handleChange,
  checked,
  errors = {},
  touched = {},
  ...props
}) => {
  const [isChecked, setChecked] = useState(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    handleChange(event);
  };

  return (
    <InputCheckbox
      {...props}
      checked={isChecked}
      onChange={handleCheckboxChange}
      hasError={Boolean(errors[props.name] && touched[props.name])}
    />
  );
};

CheckboxFieldLight.propTypes = {
  /** Label for the input */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Description for the input */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
};
