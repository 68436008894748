import { Item as DropdownMenuItem } from '@radix-ui/react-dropdown-menu';

import { ProfilesMenuItem, type ProfilesMenuItemProps } from './ProfilesMenuItem';

type ProfilesMenuCollapsibleItemProps = {
  onSelect: () => void;
} & ProfilesMenuItemProps;

export const ProfilesMenuCollapsibleItem = ({
  onSelect,
  ...rest
}: ProfilesMenuCollapsibleItemProps) => {
  const handleSelectProfile = (event: Event) => {
    event.preventDefault();
    onSelect();
  };

  return (
    <DropdownMenuItem asChild onSelect={handleSelectProfile}>
      <ProfilesMenuItem {...rest} />
    </DropdownMenuItem>
  );
};
