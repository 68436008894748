import { trackEvent, pathnameToLocationOfAction, accountMenuEvents } from '@remote-com/analytics';
import type { AvatarTone } from '@remote-com/norma';
import {
  Avatar,
  Stack,
  Text,
  Tooltip,
  focusVisible,
  focusRingOffsetBorder,
} from '@remote-com/norma';
import { useRouter } from 'next/router';
import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@/src/components/Button';

const messages = {
  triggerLabel: 'Your account',
} as const;

const StyledAccountMenuTrigger = styled(Button).attrs({ variant: 'raw' })`
  border-radius: ${({ theme }) => theme.borderRadius.full};
  ${focusVisible(css`
    ${({ theme }) => focusRingOffsetBorder(theme.colors.brand[500])};
  `)}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    width: 100%;
    padding-left: ${({ theme }) => -theme.space[3]};
  }
`;

const SidebarAccountMenu = styled(Stack).attrs({
  flexDirection: 'row',
  gap: 3,
  alignItems: 'center',
  py: 2,
})``;

const StyledAvatar = styled(Avatar)`
  vertical-align: middle;
`;

const TriggerText = styled(Text).attrs({ variant: 'smMedium' })``;

interface AccountMenuTriggerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  displayLabel?: string;
  imageSrc?: string;
  name: string;
  tone?: AvatarTone;
}

export const AccountMenuTrigger = forwardRef<HTMLButtonElement, AccountMenuTriggerProps>(
  ({ displayLabel, imageSrc, name, tone, ...rest }, ref) => {
    const router = useRouter();

    function handleToggleMenuAccount() {
      const props = {
        locationOfAction: pathnameToLocationOfAction(router.pathname),
      };
      trackEvent(accountMenuEvents.ACCOUNT_MENU_CLICKED, props);
    }

    if (displayLabel) {
      return (
        <StyledAccountMenuTrigger
          onClick={handleToggleMenuAccount}
          aria-label={messages.triggerLabel}
          ref={ref}
          {...rest}
        >
          <SidebarAccountMenu>
            <StyledAvatar
              aria-hidden
              fallback={name}
              size="xxs"
              src={imageSrc}
              tone={tone}
              data-testid="account-menu-trigger-avatar"
            />
            <TriggerText>{displayLabel}</TriggerText>
          </SidebarAccountMenu>
        </StyledAccountMenuTrigger>
      );
    }

    return (
      <Tooltip type="caption" label={<span aria-hidden>{messages.triggerLabel}</span>}>
        <StyledAccountMenuTrigger
          onClick={handleToggleMenuAccount}
          aria-label={messages.triggerLabel}
          ref={ref}
          {...rest}
        >
          <StyledAvatar
            aria-hidden={!imageSrc}
            fallback={name}
            size="sm"
            src={imageSrc}
            tone={tone}
            data-testid="account-menu-trigger-avatar"
          />
        </StyledAccountMenuTrigger>
      </Tooltip>
    );
  }
);
