// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconEdit = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M1.88 3.88A3 3 0 0 1 4 3h7a1 1 0 1 1 0 2H4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7a1 1 0 1 1 2 0v7a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V6a3 3 0 0 1 .88-2.12Z" /><path fillRule="evenodd" clipRule="evenodd" d="M20 2.88c-.3 0-.58.12-.8.33l-9.3 9.3-.53 2.12 2.12-.53 9.3-9.3A1.12 1.12 0 0 0 20 2.87Zm-2.2-1.09a3.12 3.12 0 0 1 4.4 4.42l-9.5 9.5a1 1 0 0 1-.46.26l-4 1a1 1 0 0 1-1.21-1.21l1-4a1 1 0 0 1 .26-.47l9.5-9.5Z" /></svg>;
const ForwardRef = forwardRef(IconEdit);
export { ForwardRef as IconEdit };