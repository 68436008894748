import { useMemo, useState, useCallback } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { AutoCell } from '@/src/components/Table/Cells';
import { normalizeColumnsConfiguration } from '@/src/components/Table/hooks/useColumnsState/useColumnsState.typedHelpers';
import { useColumnsStateOrder } from '@/src/components/Table/hooks/useColumnsState/useColumnsStateOrder';
import { captureException } from '@/src/helpers/captureException';

import { COLUMN_STATES } from './useColumnsState.constants';
import {
  getDataAccessors,
  disableVisibilityProperties,
  sortLeftStickyColumnsLeft,
  sortRightStickyColumnsRight,
  onlyColumnsNeededForTableRendering,
  onlyExportableVisibleColumns,
  getFriendlyColumnName,
  getColumnNameWithFallback,
} from './useColumnsState.helpers';

/**
 * @typedef {import('@/src/components/Table/hooks/useColumnsState/types').ColumnState} ColumnState
 * @typedef {import('@/src/components/Table/hooks/useColumnsState/types').ColumnsConfigurationVisibility} ColumnsConfigurationVisibility
 * @typedef {import('@/src/components/Table/hooks/useColumnsState/types').AnyColumnsConfiguration} AnyColumnsConfiguration
 */

const stateTransitionRules = {
  [COLUMN_STATES.hidden]: COLUMN_STATES.hidden,
  [COLUMN_STATES.invisible]: COLUMN_STATES.visible,
  [COLUMN_STATES.visible]: COLUMN_STATES.invisible,
  [COLUMN_STATES.export]: COLUMN_STATES.visible,
  [COLUMN_STATES.view]: COLUMN_STATES.invisible,
};

export function useColumnsState({
  data,
  columns,
  columnLabels,
  columnAccessorExclusionSet,
  specialAdditionalColumnsConfig,
  columnConfigPersistenceKey,
  forceColumnOrderOnExport,
  columnAccessorInclusionSet,
}) {
  /**
   * @type {[AnyColumnsConfiguration[], Dispatch<SetStateAction<AnyColumnsConfiguration[]>>]}
   */
  const [savedConfigurations, setSavedConfigurations] = useLocalStorageState(
    columnConfigPersistenceKey,
    { defaultValue: [] }
  );

  /**
   * @type {[ColumnsConfigurationVisibility, Dispatch<SetStateAction<ColumnsConfigurationVisibility>>]}
   */
  const [visibilityConfigurations, setVisibilityConfigurations] = useState({});

  /**
   * @typedef {string | null} ActiveSavedConfigurationName
   * @type {[ActiveSavedConfigurationName, Dispatch<SetStateAction<ActiveSavedConfigurationName>>]}
   */
  const [activeConfigurationName, setActiveConfigurationName] = useState(null);

  const originalColumnsAccessors = useMemo(
    () =>
      columns.reduce((acc, cur) => {
        // If a column has a disableInView value, we don't include it here, so it's not excluded in additionalColumnAccessors.
        if (cur?.dataColumnAccessor && !cur?.disableInView) {
          return [...acc, cur.dataColumnAccessor];
        }

        return acc;
      }, []),
    [columns]
  );

  const additionalColumnAccessors = useMemo(
    () =>
      getDataAccessors({
        data,
        originalColumnsAccessors,
        columnAccessorExclusionSet,
        specialAdditionalColumnsConfig,
        columnAccessorInclusionSet,
      }),
    [
      data,
      originalColumnsAccessors,
      columnAccessorExclusionSet,
      specialAdditionalColumnsConfig,
      columnAccessorInclusionSet,
    ]
  );

  const additionalColumns = useMemo(
    () =>
      additionalColumnAccessors.map((accessor) => {
        const name = getFriendlyColumnName({ accessor, columnLabels });

        return {
          id: `data-${accessor}`,
          accessor,
          Header: name,
          Cell: AutoCell,
          disableSortBy: true,
          disableFilters: true,
          disableInView: true,
          disableExport: true,
        };
      }) ?? [],
    [additionalColumnAccessors, columnLabels]
  );

  const specialAdditionalColumns = useMemo(() => {
    if (!specialAdditionalColumnsConfig) {
      return [];
    }

    const specialAdditionalColumnsAccessors = Object.keys(specialAdditionalColumnsConfig);

    return specialAdditionalColumnsAccessors.map((accessor) => {
      const name = getFriendlyColumnName({ accessor, columnLabels });
      const config = specialAdditionalColumnsConfig[accessor];

      return {
        id: `data-${accessor}`,
        accessor,
        Header: name,
        Cell: ({ value, row: { original } }) =>
          config.cellType
            ? config.cellType({
                value,
                rowData: original,
                formatter: config.formatter,
              })
            : AutoCell,
        disableSortBy: true,
        disableFilters: true,
        disableInView: true,
        disableExport: true,
        ...config,
      };
    });
  }, [columnLabels, specialAdditionalColumnsConfig]);

  // If it's a React component, extract its children as a string (e.g., a header
  // that has a tooltip), otherwise, just return the header string
  const getHeaderText = (header) =>
    typeof header === 'string' ? header : header?.props?.children || '';

  const allColumns = useMemo(() => {
    /* Sort additionalColumns by name so that they're more predictably displayed
    in the table (i.e., same order as in the columns configuration list) */
    const sortedAdditionalColumns = [...additionalColumns, ...specialAdditionalColumns].sort(
      (a, b) => {
        const aHeaderText = getHeaderText(a.Header);
        const bHeaderText = getHeaderText(b.Header);
        return aHeaderText.localeCompare(bHeaderText);
      }
    );
    return [...columns, ...sortedAdditionalColumns];
  }, [columns, additionalColumns, specialAdditionalColumns]);

  /**
   * @type {ColumnState[]}
   */
  const defaultColumnsState = useMemo(
    () =>
      allColumns.map(
        ({
          id,
          accessor,
          Header,
          toggleName,
          disableInView,
          hiddenColumn,
          disableExport,
          sticky,
        }) => {
          const identifier = id || accessor;
          const name = getColumnNameWithFallback({ toggleName, Header, identifier });

          let defaultState = COLUMN_STATES.visible;

          if (hiddenColumn) {
            defaultState = COLUMN_STATES.hidden;
          } else if (disableInView && disableExport) {
            defaultState = COLUMN_STATES.invisible;
          } else if (disableInView) {
            defaultState = COLUMN_STATES.export;
          } else if (disableExport) {
            defaultState = COLUMN_STATES.view;
          }

          return [identifier, defaultState, name, sticky];
        }
      ),
    [allColumns]
  );

  const { changeColumnsOrder, order, setOrder, orderedColumnsState, resetColumnsOrder } =
    useColumnsStateOrder({
      initialColumnsState: defaultColumnsState,
      initialOrder: savedConfigurations?.customizations?.order,
      onChangeColumnOrder: () => {
        setActiveConfigurationName(null);
      },
    });

  /**
   * @type {ColumnState[]}
   */
  const columnsState = useMemo(() => {
    return orderedColumnsState.map(([id, defaultState, name, sticky]) => [
      id,
      visibilityConfigurations[id] || defaultState,
      name,
      sticky,
    ]);
  }, [orderedColumnsState, visibilityConfigurations]);

  const toggleColumnState = useCallback(
    (id) => {
      const [, defaultState] = orderedColumnsState.find(([columnId]) => columnId === id);
      const [, state] = columnsState.find(([columnId]) => columnId === id);

      const nextState = state === defaultState ? stateTransitionRules[state] : defaultState;

      if (state !== nextState) {
        setVisibilityConfigurations((currentState) => {
          return {
            ...currentState,
            [id]: nextState,
          };
        });
      }

      setActiveConfigurationName(null);
    },
    [setVisibilityConfigurations, orderedColumnsState, columnsState]
  );

  const viewColumns = useMemo(() => {
    // set is needed to avoid O(n*m) operation, searching in array while iterating another array
    const visibleColumns = new Set(
      columnsState.filter(onlyColumnsNeededForTableRendering).map(([id]) => id)
    );
    return allColumns
      .sort((columnA, columnB) => {
        const columnAId = columnA.id || columnA.accessor;
        const columnAIndex = columnsState.findIndex(([id]) => id === columnAId);

        const columnBId = columnB.id || columnB.accessor;
        const columnBIndex = columnsState.findIndex(([id]) => id === columnBId);

        return columnAIndex - columnBIndex;
      })
      .filter(({ accessor, id = accessor }) => visibleColumns.has(id))
      .map(disableVisibilityProperties)
      .sort(sortLeftStickyColumnsLeft)
      .sort(sortRightStickyColumnsRight);
  }, [allColumns, columnsState]);

  const handleExportColumns = useCallback(
    async (columnStateToExport, callbackFn) => {
      const visibleColumns = new Set(
        columnStateToExport.filter(onlyExportableVisibleColumns).map(([id]) => id)
      );

      let columnsToExport;

      if (forceColumnOrderOnExport) {
        // Maintain the original column order for export
        columnsToExport = defaultColumnsState
          .filter(([id]) => visibleColumns.has(id))
          .map(([id, , name]) => {
            const column = allColumns.find((col) => col.id === id || col.accessor === id);

            return {
              id,
              Header: name,
              accessor: column.accessor,
              Cell: column.Cell,
              exportData: column.exportData,
            };
          });
      } else {
        columnsToExport = allColumns
          .filter(({ accessor, id = accessor }) => visibleColumns.has(id))
          .map(disableVisibilityProperties)
          .sort(sortLeftStickyColumnsLeft)
          .sort(sortRightStickyColumnsRight);
      }

      await callbackFn(columnsToExport);
    },
    [forceColumnOrderOnExport, defaultColumnsState, allColumns]
  );

  /* -------------------------------------------------------------------------------------------------
   * Saved configurations
   * -----------------------------------------------------------------------------------------------*/

  /**
   * Resets the active column configuration
   */
  const resetColumnConfiguration = () => {
    setVisibilityConfigurations({});
    resetColumnsOrder();
    setActiveConfigurationName(null);
  };

  /**
   * Deletes a saved configuration from the store
   * @param {string} name
   */
  const deleteConfiguration = (name) => {
    setSavedConfigurations((currentSavedConfigurations) => {
      const existingIndex = currentSavedConfigurations.findIndex(
        (configuration) => configuration.name === name
      );

      return currentSavedConfigurations.filter((_, index) => index !== existingIndex);
    });
    setVisibilityConfigurations({});
    resetColumnsOrder();

    if (name === activeConfigurationName) {
      setActiveConfigurationName(null);
    }
  };

  /**
   * Saves a configuration to the store
   * @param {object} props
   * @param {string} props.name
   * @param {ColumnState[]} props.columnStateToSave
   */
  const saveCurrentConfiguration = ({ name, columnStateToSave }) => {
    /**
     * Creates the new state customizations object from the columnStateToSave "triple" ([id, state, name])
     *
     * @type {ColumnsConfigurationVisibility}
     */
    const visibilityConfigurationsToSave = columnStateToSave.reduce((acc, [id, state]) => {
      const [, defaultState] = defaultColumnsState.find(([columnId]) => columnId === id);

      if (state === defaultState) {
        return acc;
      }

      return { ...acc, [id]: state };
    }, {});

    const newStateCustomizations = {
      ...visibilityConfigurations,
      ...visibilityConfigurationsToSave,
    };

    setSavedConfigurations((currentSavedConfigurations) => {
      const existingIndex = currentSavedConfigurations.findIndex(
        (configuration) => configuration.name === name
      );

      return currentSavedConfigurations
        .filter((_, index) => index !== existingIndex)
        .concat({
          name,
          customizations: {
            order,
            visibility: newStateCustomizations,
          },
        });
    });

    setActiveConfigurationName(name);
    setVisibilityConfigurations(newStateCustomizations);
  };

  /**
   * Applies another saved configuration.
   *
   * @param {AnyColumnsConfiguration} configuration
   */
  const applyConfiguration = (configuration) => {
    try {
      /* Users might be using an outdated columns configuration format.
      We will try to convert it to the new format or throw an error.
      See more details in the docs for `normalizeColumnsConfiguration()`. */
      const normalizedColumnsConfiguration = normalizeColumnsConfiguration(configuration);

      setActiveConfigurationName(normalizedColumnsConfiguration.name);
      setVisibilityConfigurations(normalizedColumnsConfiguration.customizations.visibility || {});
      setOrder(normalizedColumnsConfiguration.customizations.order || []);
    } catch (error) {
      captureException('User attempted to activate invalid columns configuration.');
    }
  };

  /**
   * Toggles a saved configuration on or off.
   * Resets active configuration if provided configuration is already active.
   *
   * @param {AnyColumnsConfiguration} configuration
   */
  const toggleConfiguration = (configuration) => {
    if (activeConfigurationName === configuration.name) {
      setActiveConfigurationName(null);
      setVisibilityConfigurations({});
      resetColumnsOrder();
      return;
    }

    applyConfiguration(configuration);
  };

  return {
    /** The currently active configuration name, or null if none is active. */
    activeConfigurationName,
    /** The current columns state. */
    columnsState,
    /** The default columns state. */
    defaultColumnsState,
    /** Function to call when a configuration should be deleted. */
    deleteConfiguration,
    /** Function to call when exporting columns. */
    handleExportColumns,
    /** Function to call when resetting the active columns configuration. */
    resetColumnConfiguration,
    /** Function to call when saving the current columns configuration. */
    saveCurrentConfiguration,
    /** List of saved columns configurations. */
    savedConfigurations,
    /** Function to call when toggling the visibility of a column. */
    toggleColumnState,
    /** Function to call when toggling a configuration. */
    applyConfiguration,
    /** Function to call when toggling a configuration. */
    toggleConfiguration,
    /** Columns that will be visible in a table. */
    viewColumns,
    changeColumnsOrder,
    order,
    visibility: visibilityConfigurations,
  };
}
