import { Box, SROnly, Stack, Tooltip } from '@remote-com/norma';
import { IconFilters } from '@remote-com/norma/icons/IconFilters';
import { IconTimes } from '@remote-com/norma/icons/IconTimes';

import { StandaloneLink } from '@/src/components/Button';
import { FilterButton } from '@/src/components/Table/Components/TableComponents.styled';
import { useSelectedLegalEntityNameFromTable } from '@/src/components/Table/hooks/useSelectedNameFromTable';
import { employmentTypeLabels } from '@/src/domains/employment/constants';

function getFilterLabel(key, value, filterLabels) {
  if (key === 'employmentType') {
    return `Employment type: ${employmentTypeLabels[value]}`;
  }

  if (key === 'legalEntitySlug') {
    return `Legal entity slug: ${filterLabels[key] || value}`;
  }

  if (key === 'notInPayrollRun') {
    return `Not in this payroll run`;
  }

  return value;
}

const toggleFilter = (filterKey, setFilters) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    [filterKey]: {
      ...prevFilters[filterKey],
      isEnabled: !prevFilters[filterKey].isEnabled,
    },
  }));
};

export default function SelectorFilterBox({ filters, setFilters }) {
  // This is a bit hacky, but it works the same way as in the table filter labels
  const selectedLegalEntityName = useSelectedLegalEntityNameFromTable({
    tableState: {
      filters: [{ id: 'legal_entity_slug', value: filters.legalEntitySlug?.filterValue }],
    },
  });

  const filterLabels = {
    legalEntitySlug: selectedLegalEntityName,
  };

  return (
    <Stack gap="4">
      <Box display="flex">
        <StandaloneLink IconBefore={IconFilters} tone="secondary">
          Filters
        </StandaloneLink>
      </Box>

      <Stack gap="3" direction="row" flexWrap="wrap">
        {Object.entries(filters).map(([filterKey, { filterValue, isEnabled }]) => (
          <Tooltip
            type="caption"
            label={`Filter ${isEnabled ? 'enabled' : 'disabled'}`}
            key={`${filterKey}=${filterValue}`}
          >
            <FilterButton
              onClick={() => toggleFilter(filterKey, setFilters)}
              IconAfter={isEnabled ? IconTimes : null}
              disabled={!isEnabled}
            >
              <SROnly>Remove filter</SROnly>
              {getFilterLabel(filterKey, filterValue, filterLabels)}
            </FilterButton>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
}
