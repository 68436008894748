// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineEdit = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M18.56 3.7a1.25 1.25 0 0 0-1.11 0c-.16.09-.33.24-.92.83l-.77.77a3.25 3.25 0 0 0 2.94 2.94l.77-.77c.59-.59.74-.76.82-.92.17-.34.17-.76 0-1.1a4.65 4.65 0 0 0-.82-.92 4.65 4.65 0 0 0-.91-.82Zm1.97-.23-.09-.09a5.1 5.1 0 0 0-1.22-1.02 2.75 2.75 0 0 0-2.44 0 5.1 5.1 0 0 0-1.31 1.11l-1 1-8.63 8.63-.05.05c-.46.46-.76.76-1 1.1-.21.31-.4.64-.53.99-.15.39-.23.8-.36 1.45l-.02.06-.62 3.1a.75.75 0 0 0 .89.89l3.1-.62.06-.02a8.57 8.57 0 0 0 1.45-.36c.35-.14.68-.32.99-.53a8.56 8.56 0 0 0 1.15-1.05l8.63-8.63 1-1 .09-.09a5.1 5.1 0 0 0 1.02-1.22c.38-.77.38-1.67 0-2.44a5.1 5.1 0 0 0-1.02-1.22l-.09-.09Zm-3.08 6.02a4.76 4.76 0 0 1-2.94-2.94l-7.6 7.61c-.54.53-.74.73-.9.95-.14.21-.26.44-.35.68-.1.25-.16.53-.3 1.26l-.4 2 2-.4c.72-.15 1-.21 1.25-.3.24-.1.47-.22.68-.37.22-.15.42-.35.95-.88l7.6-7.6Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineEdit);
export { ForwardRef as IconV2OutlineEdit };