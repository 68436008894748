export const SERVICE_COST_AREAS = {
  // ONBOARDING: 'onboarding',
  EMPLOYEE_LIFECYCLE: 'employee_lifecycle',
  // LEGAL: 'legal',
  MOBILITY: 'mobility',
  VISA_AND_IMMIGRATION: 'visa_and_immigration',
} as const;

export const SERVICE_COST_AREAS_DISPLAY_TEXT = {
  // [SERVICE_COST_AREAS.ONBOARDING]: 'Onboarding',
  [SERVICE_COST_AREAS.EMPLOYEE_LIFECYCLE]: 'Employee lifecycle',
  // [SERVICE_COST_AREAS.LEGAL]: 'Legal',
  [SERVICE_COST_AREAS.MOBILITY]: 'Mobility services',
  [SERVICE_COST_AREAS.VISA_AND_IMMIGRATION]: 'Visa and immigration',
};

export const SERVICE_COST_SERVICE_TYPES = {
  // ARGENTINA_LOCAL_REGISTRATION_UPDATE: 'argentina_local_registration_update',
  HEALTH_CHECKS: 'health_checks',
  SAFETY_OTHER_TRAININGS: 'safety_other_trainings',
  OFFBOARDING_EXTERNAL_LEGAL_ADVICE: 'offboarding_external_legal_advice',
  SOLICITOR_FEE: 'solicitor_fee',
  // LEGAL_COUNSEL: 'legal_counsel',
  // TERMINATION: 'termination',
  IMMIGRATION_GUIDANCE: 'immigration_guidance',
  PRE_EMPLOYMENT_ELIGIBILITY_CHECK: 'pre_employment_eligibility_check',
  SOCIAL_SECURITY_NO_OBTAINMENT: 'social_security_no_obtainment',
  PHYSICAL_DOCUMENT_CHECK: 'physical_document_check',
  CHANGE_OF_EMPLOYER_DECLARATION: 'change_of_employer_declaration',
  WORK_PERMIT_CHANGE: 'work_permit_change',
  EXTENSION_RENEWAL_OF_STATUS: 'extension_renewal_of_status',
  NEW_HIRE_DECLARATION: 'new_hire_declaration',
  NEW_VISA_WORK_RESIDENCE_PERMIT: 'new_visa_work_residence_permit',
  TERMINATION_DE_REGISTRATION_NOTIFICATION: 'termination_de_registration_notification',
  FAMILY_REUNIFICATION_VISA_ASSISTANCE: 'family_reunification_visa_assistance',
  NOMAD_VISA_ASSISTANCE: 'nomad_visa_assistance',
  PERMANENT_RESIDENCE_ASSISTANCE: 'permanent_residence_assistance',
  DOMESTIC_EU_RELOCATION_ASSISTANCE: 'domestic_eu_relocation_assistance',
  AD_HOC_SERVICES: 'ad_hoc_services',
  REMOTE_WORK_REQUEST: 'remote_work_request',
} as const;

export const SERVICE_COST_SERVICE_TYPES_DISPLAY_TEXT = {
  // [SERVICE_COST_SERVICE_TYPES.ARGENTINA_LOCAL_REGISTRATION_UPDATE]:
  //   'Argentina local registration update',
  [SERVICE_COST_SERVICE_TYPES.HEALTH_CHECKS]: 'Mandatory health checks',
  [SERVICE_COST_SERVICE_TYPES.SAFETY_OTHER_TRAININGS]: 'Mandatory safety & other trainings',
  [SERVICE_COST_SERVICE_TYPES.OFFBOARDING_EXTERNAL_LEGAL_ADVICE]:
    'Non-standard offboarding external legal advise',
  [SERVICE_COST_SERVICE_TYPES.SOLICITOR_FEE]: 'Solicitor fee in UK for settlement agreement',
  // [SERVICE_COST_SERVICE_TYPES.LEGAL_COUNSEL]: 'Legal counsel costs',
  // [SERVICE_COST_SERVICE_TYPES.TERMINATION]: 'Termination costs',
  [SERVICE_COST_SERVICE_TYPES.IMMIGRATION_GUIDANCE]: 'Immigration guidance',
  [SERVICE_COST_SERVICE_TYPES.PRE_EMPLOYMENT_ELIGIBILITY_CHECK]: 'Pre-employment eligibility check',
  [SERVICE_COST_SERVICE_TYPES.SOCIAL_SECURITY_NO_OBTAINMENT]: 'Social security number obtainment',
  [SERVICE_COST_SERVICE_TYPES.PHYSICAL_DOCUMENT_CHECK]: 'Physical document check',
  [SERVICE_COST_SERVICE_TYPES.CHANGE_OF_EMPLOYER_DECLARATION]: 'Change of employer declaration',
  [SERVICE_COST_SERVICE_TYPES.WORK_PERMIT_CHANGE]: 'Work permit change',
  [SERVICE_COST_SERVICE_TYPES.EXTENSION_RENEWAL_OF_STATUS]: 'Extension/renewal of status',
  [SERVICE_COST_SERVICE_TYPES.NEW_HIRE_DECLARATION]: 'New hire declaration',
  [SERVICE_COST_SERVICE_TYPES.NEW_VISA_WORK_RESIDENCE_PERMIT]: 'New visa/work/residence permit',
  [SERVICE_COST_SERVICE_TYPES.TERMINATION_DE_REGISTRATION_NOTIFICATION]:
    'Termination/de-registration notification',
  [SERVICE_COST_SERVICE_TYPES.FAMILY_REUNIFICATION_VISA_ASSISTANCE]:
    'Family reunification visa assistance',
  [SERVICE_COST_SERVICE_TYPES.NOMAD_VISA_ASSISTANCE]: 'Nomad visa assistance',
  [SERVICE_COST_SERVICE_TYPES.PERMANENT_RESIDENCE_ASSISTANCE]: 'Permanent residence assistance',
  [SERVICE_COST_SERVICE_TYPES.DOMESTIC_EU_RELOCATION_ASSISTANCE]:
    'Domestic EU relocation assistance',
  [SERVICE_COST_SERVICE_TYPES.AD_HOC_SERVICES]: 'Ad-hoc services',
  [SERVICE_COST_SERVICE_TYPES.REMOTE_WORK_REQUEST]: 'Remote work request',
};

export const SERVICE_COSTS_BY_AREA = {
  // [SERVICE_COST_AREAS.ONBOARDING]: [SERVICE_COST_SERVICE_TYPES.ARGENTINA_LOCAL_REGISTRATION_UPDATE],
  [SERVICE_COST_AREAS.EMPLOYEE_LIFECYCLE]: [
    SERVICE_COST_SERVICE_TYPES.HEALTH_CHECKS,
    SERVICE_COST_SERVICE_TYPES.SAFETY_OTHER_TRAININGS,
    SERVICE_COST_SERVICE_TYPES.OFFBOARDING_EXTERNAL_LEGAL_ADVICE,
    SERVICE_COST_SERVICE_TYPES.SOLICITOR_FEE,
  ],
  // [SERVICE_COST_AREAS.LEGAL]: [
  //   SERVICE_COST_SERVICE_TYPES.LEGAL_COUNSEL,
  //   SERVICE_COST_SERVICE_TYPES.TERMINATION,
  // ],
  [SERVICE_COST_AREAS.MOBILITY]: [
    SERVICE_COST_SERVICE_TYPES.IMMIGRATION_GUIDANCE,
    SERVICE_COST_SERVICE_TYPES.PRE_EMPLOYMENT_ELIGIBILITY_CHECK,
    SERVICE_COST_SERVICE_TYPES.SOCIAL_SECURITY_NO_OBTAINMENT,
    SERVICE_COST_SERVICE_TYPES.PHYSICAL_DOCUMENT_CHECK,
    SERVICE_COST_SERVICE_TYPES.REMOTE_WORK_REQUEST,
  ],
  [SERVICE_COST_AREAS.VISA_AND_IMMIGRATION]: [
    SERVICE_COST_SERVICE_TYPES.CHANGE_OF_EMPLOYER_DECLARATION,
    SERVICE_COST_SERVICE_TYPES.WORK_PERMIT_CHANGE,
    SERVICE_COST_SERVICE_TYPES.EXTENSION_RENEWAL_OF_STATUS,
    SERVICE_COST_SERVICE_TYPES.NEW_HIRE_DECLARATION,
    SERVICE_COST_SERVICE_TYPES.NEW_VISA_WORK_RESIDENCE_PERMIT,
    SERVICE_COST_SERVICE_TYPES.TERMINATION_DE_REGISTRATION_NOTIFICATION,
    SERVICE_COST_SERVICE_TYPES.FAMILY_REUNIFICATION_VISA_ASSISTANCE,
    SERVICE_COST_SERVICE_TYPES.NOMAD_VISA_ASSISTANCE,
    SERVICE_COST_SERVICE_TYPES.PERMANENT_RESIDENCE_ASSISTANCE,
    SERVICE_COST_SERVICE_TYPES.DOMESTIC_EU_RELOCATION_ASSISTANCE,
    SERVICE_COST_SERVICE_TYPES.AD_HOC_SERVICES,
  ],
};

export const SERVICE_COST_BENEFICIARIES = {
  COMPANY: 'company',
  EMPLOYEE: 'employee',
};

export const SERVICE_TYPES_BY_BENEFICIARY_LEVEL = {
  [SERVICE_COST_BENEFICIARIES.COMPANY]: [],

  [SERVICE_COST_BENEFICIARIES.EMPLOYEE]: [
    // SERVICE_COST_SERVICE_TYPES.ARGENTINA_LOCAL_REGISTRATION_UPDATE,
    SERVICE_COST_SERVICE_TYPES.HEALTH_CHECKS,
    SERVICE_COST_SERVICE_TYPES.SAFETY_OTHER_TRAININGS,
    SERVICE_COST_SERVICE_TYPES.OFFBOARDING_EXTERNAL_LEGAL_ADVICE,
    SERVICE_COST_SERVICE_TYPES.SOLICITOR_FEE,
    // SERVICE_COST_SERVICE_TYPES.LEGAL_COUNSEL,
    // SERVICE_COST_SERVICE_TYPES.TERMINATION,
    SERVICE_COST_SERVICE_TYPES.IMMIGRATION_GUIDANCE,
    SERVICE_COST_SERVICE_TYPES.PRE_EMPLOYMENT_ELIGIBILITY_CHECK,
    SERVICE_COST_SERVICE_TYPES.SOCIAL_SECURITY_NO_OBTAINMENT,
    SERVICE_COST_SERVICE_TYPES.PHYSICAL_DOCUMENT_CHECK,
    SERVICE_COST_SERVICE_TYPES.CHANGE_OF_EMPLOYER_DECLARATION,
    SERVICE_COST_SERVICE_TYPES.WORK_PERMIT_CHANGE,
    SERVICE_COST_SERVICE_TYPES.EXTENSION_RENEWAL_OF_STATUS,
    SERVICE_COST_SERVICE_TYPES.NEW_HIRE_DECLARATION,
    SERVICE_COST_SERVICE_TYPES.NEW_VISA_WORK_RESIDENCE_PERMIT,
    SERVICE_COST_SERVICE_TYPES.TERMINATION_DE_REGISTRATION_NOTIFICATION,
    SERVICE_COST_SERVICE_TYPES.FAMILY_REUNIFICATION_VISA_ASSISTANCE,
    SERVICE_COST_SERVICE_TYPES.NOMAD_VISA_ASSISTANCE,
    SERVICE_COST_SERVICE_TYPES.PERMANENT_RESIDENCE_ASSISTANCE,
    SERVICE_COST_SERVICE_TYPES.DOMESTIC_EU_RELOCATION_ASSISTANCE,
    SERVICE_COST_SERVICE_TYPES.AD_HOC_SERVICES,
    SERVICE_COST_SERVICE_TYPES.REMOTE_WORK_REQUEST,
  ],
};

export const SERVICE_TYPE_ALLOWED_BENEFICIARIES = {
  ANY_LEVEL: 'any_level',
  COMPANY_LEVEL_ONLY: 'company_level_only',
  EMPLOYEE_LEVEL_ONLY: 'employee_level_only',
};

export const SERVICE_COST_PROVIDERS = {
  REMOTE: 'internal',
  THIRD_PARTY: 'external',
} as const;

export const serviceCostProvidersLabels = {
  [SERVICE_COST_PROVIDERS.REMOTE]: 'Remote',
  [SERVICE_COST_PROVIDERS.THIRD_PARTY]: 'Third-party provider',
};

export const SERVICE_STATUSES = {
  CANCELLED: 'cancelled',
  NOT_INVOICED: 'not_invoiced',
  INVOICED: 'invoiced',
} as const;

export const SERVICE_STATUSES_DISPLAY_TEXT = {
  [SERVICE_STATUSES.CANCELLED]: 'Canceled',
  [SERVICE_STATUSES.NOT_INVOICED]: 'Not invoiced',
  [SERVICE_STATUSES.INVOICED]: 'Invoiced',
};

export const SERVICE_COST_PROVIDERS_DISPLAY_TEXT = {
  [SERVICE_COST_PROVIDERS.REMOTE]: 'Remote',
  [SERVICE_COST_PROVIDERS.THIRD_PARTY]: 'Third-party',
};

/** Set of accessors not to be included in additional columns or column
 * configuration list because they're unnecessary and only add noise.
 * */

export const columnAccessorExclusionSet = new Set([
  'amount.amount',
  'amount.currency',
  'area',
  'employment',
  'employment.contract',
  'employment.product',
  'employment.user.integrationUsers',
  'employment.user.invitedBy',
  'employment.user.profilePicture',
  'employment.user.signupSource',
  'company.billingEmail',
  'company.hasTeamDeal',
  'company.logo',
  'company.phoneNumber',
  'company.registrationNumber',
  'company.termsOfServiceAcceptedAt',
  'company.useWiseHybrid',
  'invoicePeriod',
  'file',
  'purchaseDate',
  'remoteInvoice',
  'service',
  'status',
]);
