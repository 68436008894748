// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneCalendarMinus = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M10.85 1.25c-3.36 0-5.04 0-6.32.65A6 6 0 0 0 1.9 4.53c-.65 1.28-.65 2.96-.65 6.32v2.3c0 3.36 0 5.04.65 6.32a6 6 0 0 0 2.63 2.63c1.28.65 2.96.65 6.32.65h2.3c3.36 0 5.04 0 6.32-.65a6 6 0 0 0 2.63-2.63c.65-1.28.65-2.96.65-6.32v-2.3c0-3.36 0-5.04-.65-6.32a6 6 0 0 0-2.63-2.63c-1.28-.65-2.96-.65-6.32-.65h-2.3Z" /><path fillRule="evenodd" clipRule="evenodd" d="M8 .25c.41 0 .75.34.75.75v3a.75.75 0 0 1-1.5 0V1c0-.41.34-.75.75-.75Zm8 0c.41 0 .75.34.75.75v3a.75.75 0 0 1-1.5 0V1c0-.41.34-.75.75-.75ZM7.25 12c0-.41.34-.75.75-.75h8a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneCalendarMinus);
export { ForwardRef as IconV2DuotoneCalendarMinus };