import { Stack, Text } from '@remote-com/norma';
import { IconV2SolidStars } from '@remote-com/norma/icons/IconV2SolidStars';
import { useTheme } from 'styled-components';

import { StyledAnimatedEllipsis, StyledResultWrapper } from './AIFormAssistant.styled';
import AIFormAssistantResultActions from './AIFormAssistantResultActions';

type Props = {
  aiResult: string;
  isLoading: boolean;
  onCancel: () => void;
  onTryAgain: () => void;
  onUseResult: () => void;
};

const AIFormAssistantResult = ({
  aiResult,
  isLoading,
  onCancel,
  onTryAgain,
  onUseResult,
}: Props): JSX.Element => {
  const { colors } = useTheme();

  return (
    <StyledResultWrapper>
      <Text variant="sm" color="grey.700">
        {aiResult}
      </Text>
      {isLoading && (
        <Stack gap={2} direction="row" alignItems="center">
          <IconV2SolidStars width={16} color={colors.brand[600]} />
          <Text variant="xsMedium" color="brand.600">
            AI is writing
          </Text>
          <StyledAnimatedEllipsis />
        </Stack>
      )}
      {!isLoading && (
        <AIFormAssistantResultActions
          onCancel={onCancel}
          onTryAgain={onTryAgain}
          onUseResult={onUseResult}
        />
      )}
    </StyledResultWrapper>
  );
};

export default AIFormAssistantResult;
