import { Box, SROnly } from '@remote-com/norma';
import { useField } from 'formik';
import { useEffect } from 'react';

import { CheckboxCardField } from '@/src/components/Ui/Form';
import {
  Fieldset,
  CheckboxCardGrid,
  Legend,
} from '@/src/components/Ui/Form/inputs/CheckboxCard/CheckboxCard.styled';
import { type CheckboxCardGroupProps } from '@/src/components/Ui/Form/inputs/CheckboxCard/types';
import { FormFieldError } from '@/src/domains/registration/onboarding/shared/styles/Form';

export type CheckboxCardGroupFieldProps = Omit<CheckboxCardGroupProps, 'onChange' | 'value'>;

export function CheckboxCardGroupField({
  name,
  label,
  options,
  disabled,
  readOnly,
  hideLabel,
  ...props
}: CheckboxCardGroupFieldProps) {
  const labelId = `${name}-label`;
  const [, { error, touched }, { setValue }] = useField(name);
  const hasError = !!error && touched;
  const isDisabled = disabled || readOnly;

  useEffect(() => {
    const defaultValue = options.filter((option) => !!option.default).map((option) => option.value);

    if (defaultValue.length) {
      setValue(defaultValue);
    }
  }, [options, setValue]);

  return (
    <Fieldset role="group" {...props}>
      {hideLabel ? (
        <SROnly as="legend" id={labelId}>
          {label}
        </SROnly>
      ) : (
        <Legend id={labelId} color="grey.900" variant="2xlMedium" mb={6}>
          {label}
        </Legend>
      )}
      <CheckboxCardGrid aria-labelledby={labelId}>
        {options.map((option) => (
          <CheckboxCardField
            key={option.value}
            id={option.value}
            name={name}
            {...option}
            disabled={isDisabled}
            readOnly={isDisabled}
          />
        ))}
      </CheckboxCardGrid>

      {hasError && (
        <Box mt={6}>
          <FormFieldError name={name} />
        </Box>
      )}
    </Fieldset>
  );
}
