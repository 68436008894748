import { pathnameToLocationOfAction, todosOverlayEvents, trackEvent } from '@remote-com/analytics';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { TodosOverlay } from '@/src/domains/todos/TodosOverlay';
import {
  TodosOverlayContextProvider,
  useTodosOverlayContext,
} from '@/src/domains/todos/TodosOverlayProvider';
import { useTodosContext } from '@/src/domains/todos/TodosProvider';
import { UPDATE_TYPES } from '@/src/domains/updates/constants';
import { OverlayButtonIcon } from '@/src/domains/updates/OverlayButtonIcon';

type TodosOverlayButtonWithProps = {
  size?: 'sm' | 'md';
};

const TodosOverlayButtonWithContext = ({ size = 'sm' }: TodosOverlayButtonWithProps) => {
  const { closeOverlay } = useTodosOverlayContext();
  const { totalGroupedTodos = 0 } = useTodosContext();
  const { openOverlay } = useTodosOverlayContext();
  const { pathname } = useRouter();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    openOverlay();
    trackEvent(todosOverlayEvents.TODOS_OVERLAY_OPENED, {
      locationOfAction: pathnameToLocationOfAction(pathname),
      totalTodos: totalGroupedTodos,
    });
  };

  const handleClose = () => {
    closeOverlay();
    setTimeout(() => {
      buttonRef?.current?.querySelector('button')?.focus();
    }, 200);
  };

  return (
    <>
      <OverlayButtonIcon
        isActive={totalGroupedTodos > 0}
        onClick={handleClick}
        label="Things to do"
        size={size}
        ref={buttonRef}
        type={UPDATE_TYPES.TODOS}
      />
      <TodosOverlay onClose={handleClose} />
    </>
  );
};

export const TodosOverlayButton = ({ size }: TodosOverlayButtonWithProps) => {
  return (
    <TodosOverlayContextProvider>
      <TodosOverlayButtonWithContext size={size} />
    </TodosOverlayContextProvider>
  );
};
