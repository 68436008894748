import React, { forwardRef, useState, useMemo, useCallback, useEffect } from 'react';
import type { ActionMeta, OptionProps, MenuProps, SingleValue } from 'react-select';
import { components } from 'react-select';

import { Text } from '../../core/text';
import { useDebounce } from '../../hooks/useDebounce';
import { useProvidedRefOrCreate } from '../../hooks/useProvidedRefOrCreate';
import { Box } from '../../layout';
import { InputSelect } from '../input-select';
import type { SelectOptionBase } from '../input-select';

import { filterOption, getCountryOptionLabel } from './helpers';
import { FlagBadge, PreloadFlagsSprite } from './Svg/sprites/FlagBadge';

export interface CountryOption extends SelectOptionBase {
  name: string;
  dialCode: string;
  flagName?: string;
  missingFlag?: boolean;
}

interface CountryCodeSelectProps {
  label: string;
  name: string;
  touched?: boolean;
  options?: CountryOption[];
  ref?: React.Ref<HTMLInputElement>;
  isDisabled?: boolean;
  errorText?: string;
  displayErrorMessage?: boolean;
  value?: SingleValue<CountryOption>;
  onBlur?: () => void;
  onChange?: (newValue: SingleValue<CountryOption>, actionMeta: ActionMeta<CountryOption>) => void;
}

interface InputRef extends HTMLInputElement {
  inputRef?: HTMLInputElement;
}

function MenuCountry({ children, ...props }: MenuProps<CountryOption, false>) {
  return <components.Menu {...props}>{children}</components.Menu>;
}

/**
 * Note: We are using a Custom Option component because if we were to keep the label as the country name without a custom component,
 * the country name would appear as the text once an option is selected. This combination of a custom Option component with getCountryOptionLabel
 * allows for a customized rendering of the label in the menu and enables the use of different formatting when representing the selected option label.
 */
const OptionCountry = (props: OptionProps<CountryOption, false>) => {
  const {
    data: { name },
  } = props;
  return (
    <components.Option {...props}>
      <Text as="span" variant="sm">
        {name}
      </Text>
    </components.Option>
  );
};

export const CountryCodeSelect = forwardRef<HTMLInputElement, CountryCodeSelectProps>(
  ({ label, name, touched, options: countryOptions, ...props }, ref) => {
    const [selectWidth, setSelectWidth] = useState('100%');
    const inputRef = useProvidedRefOrCreate(ref);
    const options = useMemo(() => {
      return countryOptions?.map((country) => ({
        ...country,
        prefix: country.missingFlag ? (
          <Box as="span" width={20} />
        ) : (
          <FlagBadge countryName={country.flagName || country.name} />
        ),
        suffix: <Text>+{country.dialCode}</Text>,
        label: country.name,
        value: country.dialCode,
      }));
    }, [countryOptions]);

    const updateMenuWidth = useCallback(() => {
      const currentElement = inputRef as React.MutableRefObject<InputRef | null>; // Note: We need to cast to InputRef to access the inputRef property
      const parentEl = currentElement.current?.inputRef?.closest('fieldset');

      if (parentEl) {
        setSelectWidth(`${parentEl.offsetWidth}px`);
      }
    }, [inputRef]);

    const handleResize = useDebounce(updateMenuWidth, 500);

    useEffect(() => {
      updateMenuWidth();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [handleResize, updateMenuWidth]);

    const formatOptionLabel = (option: CountryOption) => {
      /**
       * Note: `formatOptionLabel` ensures we're ONLY displaying the dial code as the selected value (which is the expected behavior).
       * The country name is announced by screen readers via `getCountryOptionLabel` to ensure a better a11y experience.
       * Without `formatOptionLabel`, `getCountryOptionLabel` would also display the country name as part of the selected value (which is NOT the desired behavior).
       * This small override allows us to enhance a11y without altering the UI visually.
       */
      return (
        <Text as="span" variant="sm">
          +{option.dialCode}
        </Text>
      );
    };

    return (
      <>
        <PreloadFlagsSprite />
        <InputSelect<CountryOption>
          ref={inputRef}
          isClearable={false}
          label="Country code"
          name={name}
          options={options || []}
          components={{
            Option: OptionCountry,
            Menu: MenuCountry,
          }}
          styles={{
            menu: (provided) => {
              return {
                ...provided,
                width: `${selectWidth} !important`,
              };
            },
          }}
          filterOption={filterOption}
          getOptionLabel={getCountryOptionLabel}
          formatOptionLabel={formatOptionLabel}
          {...props}
        />
      </>
    );
  }
);
