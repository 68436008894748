import type { IndicatorsContainerProps } from 'react-select';
import styled from 'styled-components';

import { Stack } from '../../../layout';

const StyledIndicatorsContainer = styled(Stack).attrs({ direction: 'row' })`
  > * {
    margin-left: ${({ theme }) => `${theme.space[2]}px`};
  }
`;

export function IndicatorsContainer({ children }: IndicatorsContainerProps) {
  return <StyledIndicatorsContainer>{children}</StyledIndicatorsContainer>;
}
