// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineEditUnderline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M18.84 3.9a1.25 1.25 0 0 0-1.81.13l-.3.3a1.9 1.9 0 0 0 1.93 1.95l.3-.31.14-.13a1.25 1.25 0 0 0-.13-1.81 4.65 4.65 0 0 0-.13-.13Zm1.18-.94-.17-.17a2.75 2.75 0 0 0-3.7 0l-.17.17-.5.5-.02.02-6.74 6.74-.04.04c-.43.42-.7.7-.93 1.01-.2.28-.36.58-.5.9-.15.35-.25.72-.4 1.3v.07l-.58 2.28a.75.75 0 0 0 .91.9l2.28-.56.06-.02c.58-.14.96-.24 1.32-.39.31-.14.6-.3.89-.5.31-.23.58-.5 1-.93l6.8-6.78v-.02l.51-.5.17-.17a2.75 2.75 0 0 0 0-3.7l-.17-.17-.02-.02Zm-2.63 4.59a3.43 3.43 0 0 1-1.94-1.94l-5.67 5.67a7.8 7.8 0 0 0-.8.87c-.14.19-.26.4-.35.6-.1.24-.17.49-.33 1.15l-.27 1.07 1.07-.27a7.8 7.8 0 0 0 1.14-.33c.22-.09.42-.2.61-.34.2-.15.39-.33.87-.81l5.67-5.67ZM3.25 21c0-.41.34-.75.75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineEditUnderline);
export { ForwardRef as IconV2OutlineEditUnderline };