import {
  useDelete,
  useGet,
  useGetForTable,
  useInvalidateQuery,
  usePatch,
  usePost,
  usePut,
} from '@remote-com/data-layer';
import camelcaseKeys from 'camelcase-keys';
// We use `useQuery` for some workarounds
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery, useQueryClient } from 'react-query';

import { useGoogleDriveImporter } from '@/src/components/GoogleImporter/DriveImporter/store';
import { EXPENSES_ADMIN_QUERY } from '@/src/domains/expenses/constants';
import { renameFileForBulkUpload } from '@/src/domains/payroll/admin/helpers';
import { ACTIVE_PAYROLL_RUN_FILTER_STATUS_IDS } from '@/src/domains/payroll/constants';
import {
  invalidatePayrollOutputsQueries,
  invalidatePayrollRunMetadata,
  invalidateUpsertPayrollRun,
} from '@/src/domains/payroll/invalidators';
import { ADJUSTMENTS_QUERY_NAME, TIMEOFF_QUERY_NAME } from '@/src/domains/payroll/queryNames';
import { omitEmptyValues } from '@/src/helpers/general';
import useConcurrentFetchCalls from '@/src/hooks/useConcurrentFetchCalls';
import { useMutationWithCustomState } from '@/src/hooks/useMutationWithCustomState';
import useMutationWithDefaultParams from '@/src/hooks/useMutationWithDefaultParams';

export const useExistingAdminPayrolls = (values, entities) => {
  const periodRange = values?.periodRange;
  const country =
    values?.remoteEntity &&
    entities?.find((entity) => entity?.slug === values.remoteEntity)?.address?.country;

  return useGet('/api/v1/rivendell/payroll-runs', {
    params: {
      queryParams: {
        countrySlug: country?.slug,
        periodFrom: periodRange?.[0],
        periodTo: periodRange?.[1],
      },
    },
    options: {
      select: (response) => response.data.payrollRuns,
      enabled: !!periodRange && !!country,
    },
  });
};

export const useAdminPayrollRunsForTable = () =>
  useGetForTable('/api/v1/rivendell/payroll-runs', {
    tableOptions: {
      withQS: true,
      dataProperty: 'payrollRuns',
      transformParams: (params) => {
        const { payrollPeriod, ...queryParams } = params.queryParams;
        const parsedQueryParams = queryParams;

        if (payrollPeriod) {
          [parsedQueryParams.periodFrom, parsedQueryParams.periodTo] = payrollPeriod;
        }

        return {
          ...params,
          queryParams: {
            status: ACTIVE_PAYROLL_RUN_FILTER_STATUS_IDS,
            ...parsedQueryParams,
            aggregations: ['number_of_employees', 'number_of_payslips'],
          },
        };
      },
      globalFilterQueryKey: 'query',
      initialState: {
        sortBy: [{ id: 'expectedPayoutDate', desc: true }],
      },
      filterConfig: {
        status: {
          key: 'status',
          type: 'array',
        },
      },
    },
  });

export const useAdminPayrollRun = (payrollRunSlug, reactQueryOptions = {}) =>
  useGet('/api/v1/rivendell/payroll-runs/[slug]', {
    params: { pathParams: { slug: payrollRunSlug } },
    options: {
      select: (response) => response?.data,
      ...reactQueryOptions,
    },
  });

export const useAdminPayrollRunNotes = (payrollRunSlug, reactQueryOptions = {}) =>
  useGet('/api/v1/rivendell/payroll-runs/[payrollRunSlug]/notes', {
    params: { pathParams: { payrollRunSlug } },
    options: {
      select: (response) => response?.data,
      ...reactQueryOptions,
    },
  });

export const useAdminDeletePayrollRun = ({ onSuccess, ...reactQueryOptions } = {}) => {
  const queryClient = useQueryClient();
  return useDelete('/api/v1/rivendell/payroll-runs/[slug]', {
    onSuccess: async (response, variables) => {
      await Promise.all([
        invalidatePayrollRunMetadata(queryClient),
        invalidatePayrollOutputsQueries(queryClient),
        queryClient.invalidateQueries(ADJUSTMENTS_QUERY_NAME),
        queryClient.invalidateQueries(EXPENSES_ADMIN_QUERY),
        queryClient.invalidateQueries(['/api/v1/rivendell/expenses']),
        queryClient.invalidateQueries(['/api/v1/rivendell/incentives']),
        queryClient.invalidateQueries(TIMEOFF_QUERY_NAME),
        queryClient.invalidateQueries('/api/v1/rivendell/payslips'),
      ]);
      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryOptions,
  });
};

export function useAdminUpsertPayrollRun(
  payrollRunSlug,
  { onSuccess, ...reactQueryCallbacks } = {}
) {
  const queryClient = useQueryClient();
  const mutationOptions = {
    onSuccess: async (response, variables) => {
      await invalidateUpsertPayrollRun(queryClient);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryCallbacks,
  };

  const createMutation = useMutationWithCustomState(
    usePost('/api/v1/rivendell/payroll-runs', mutationOptions)
  );

  const updateMutation = useMutationWithDefaultParams(
    useMutationWithCustomState(usePut('/api/v1/rivendell/payroll-runs/[slug]', mutationOptions)),
    { pathParams: { slug: payrollRunSlug } }
  );

  return payrollRunSlug ? updateMutation : createMutation;
}

export function useAdminForceUpsertPayrollRun(
  payrollRunSlug,
  { onSuccess, ...reactQueryCallbacks } = {}
) {
  const queryClient = useQueryClient();
  const mutationOptions = {
    onSuccess: async (response, variables) => {
      await invalidateUpsertPayrollRun(queryClient);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryCallbacks,
  };

  const createMutation = useMutationWithCustomState(
    usePost('/api/v1/rivendell/payroll-runs/force-actions', mutationOptions)
  );

  const updateMutation = useMutationWithDefaultParams(
    useMutationWithCustomState(
      usePut('/api/v1/rivendell/payroll-runs/force-actions/[payrollRunSlug]', mutationOptions)
    ),
    { pathParams: { payrollRunSlug } }
  );

  return payrollRunSlug ? updateMutation : createMutation;
}

export function useAdminCreateYearEndPayrollRun({ onSuccess, ...reactQueryCallbacks } = {}) {
  const queryClient = useQueryClient();
  const mutationOptions = {
    onSuccess: async (response, variables) => {
      await invalidatePayrollRunMetadata(queryClient);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryCallbacks,
  };

  return useMutationWithCustomState(
    usePost('/api/v1/rivendell/payroll-runs/year-end-statement', mutationOptions)
  );
}

export function useAdminPayrollRunTotals(payrollRunSlug, options = {}) {
  return useGet('/api/v1/rivendell/payroll-runs/[slug]/totals', {
    params: { pathParams: { slug: payrollRunSlug } },
    options: {
      select: (response) => response.data,
      ...options,
    },
  });
}

function camelCaseOneLevelDeep(validations) {
  return Object.fromEntries(
    Object.entries(validations).map(([key, value]) => {
      return [key, camelcaseKeys(value)];
    })
  );
}

/**
 * Ideally this should be a `useGet` but the backend has this as POST request so
 * we need to do this non-ideal work around.
 *
 * @param {string} payrollRunSlug
 * @param {object} [config]
 * @returns {import('react-query').UseQueryResult<import('@/src/api/config/employ/payrollRun.types').PayrollRun.AdminPayrollRunResponse['data']['validations']>}
 */
export const useAdminPayrollRunValidations = (payrollRunSlug, config = {}) => {
  const { mutateAsync } = usePost('/api/v1/rivendell/payroll-runs/[slug]/validate');

  return useQuery(
    ['/api/v1/rivendell/payroll-runs/[slug]/validate', payrollRunSlug],
    async () => {
      const response = await mutateAsync({
        pathParams: { slug: payrollRunSlug },
      });
      const { validations } = response.data || {};

      return camelCaseOneLevelDeep(validations);
    },
    { retry: false, ...config }
  );
};

/**
 *
 * @param {string} payrollRunSlug
 */
export const useAdminPayrollRunFilesForTable = (payrollRunSlug) =>
  useGetForTable('/api/v1/rivendell/payroll-runs/[slug]/payroll-run-files', {
    params: { pathParams: { slug: payrollRunSlug } },
    tableOptions: {
      transformParams: (params) => ({
        ...params,
        queryParams: { ...params.queryParams },
      }),
      dataProperty: 'payrollRunFiles',
      initialState: { pageSize: 25 },
      withQS: true,
    },
  });

/**
 *
 * @param {import('@remote-com/data-layer').UsePutOptions<'/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-run-files/[slug]'>} params
 */
export const useAdminUpdatePayrollRunFile = ({ onSuccess, ...reactQueryCallbacks } = {}) => {
  const queryClient = useQueryClient();

  return usePut('/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-run-files/[slug]', {
    onSuccess: async (response, variables) => {
      await queryClient.invalidateQueries([
        '/api/v1/rivendell/payroll-runs/[slug]/payroll-run-files',
      ]);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryCallbacks,
  });
};

/**
 *
 * @param {import('@remote-com/data-layer').UseDeleteOptions<'/api/v1/rivendell/files/[slug]'>} params
 */
export const useAdminDeletePayrollRunFile = ({ onSuccess, ...reactQueryCallbacks } = {}) => {
  const queryClient = useQueryClient();

  return useDelete('/api/v1/rivendell/files/[slug]', {
    onSuccess: async (response, variables) => {
      queryClient.invalidateQueries(['/api/v1/rivendell/payroll-runs/[slug]/totals']);
      queryClient.invalidateQueries(['/api/v1/rivendell/payroll-runs/[slug]']);
      // Await just this one for better UX
      await queryClient.invalidateQueries([
        '/api/v1/rivendell/payroll-runs/[slug]/payroll-run-files',
      ]);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryCallbacks,
  });
};

export const useAdminRemoveContractFromPayrollRun = ({
  onSuccess,
  ...reactQueryCallbacks
} = {}) => {
  const queryClient = useQueryClient();

  return useDelete('/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-run-contracts', {
    onSuccess: async (response, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(['/api/v1/rivendell/contracts']),
        invalidatePayrollRunMetadata(queryClient),
      ]);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryCallbacks,
  });
};

/**
 * @param {Object} [props]
 * @param {Function} [props.onCompletion]
 * @param {Array} [props.additionalQueriesToInvalidate]
 * @param {Object} [props.options]
 */
export const useAdminPayrollBulkUpload = ({
  onCompletion,
  additionalQueriesToInvalidate = [],
  ...options
} = {}) => {
  const getFile = useGoogleDriveImporter((state) => state.getFile);
  const queryClient = useQueryClient();
  const { mutateAsync: adminUploadPayrollRunFile } = usePost(
    '/api/v1/rivendell/payroll-runs/[slug]/payroll-run-files'
  );

  function invalidateQueries() {
    return Promise.all([
      queryClient.invalidateQueries(['/api/v1/rivendell/payroll-runs/[slug]/payroll-run-files']),
      queryClient.invalidateQueries(['/api/v1/rivendell/payroll-runs/[slug]/totals']),
      ...additionalQueriesToInvalidate.map((query) => queryClient.invalidateQueries(query)),
    ]);
  }

  const hook = useConcurrentFetchCalls({
    onCompletion: (...args) => {
      invalidateQueries();

      if (onCompletion) onCompletion(...args);
    },
    ...options,
  });

  return {
    ...hook,
    upload: ({ files, payrollRunSlug, operationType, rename }) =>
      hook.run({
        requestParamsList: files,
        requestFn: async (file, signal) => {
          // If file has a "kind", it's a google drive file, otherwise it's a regular file
          const fileToUpload = file.kind ? await getFile(file) : file;

          return adminUploadPayrollRunFile({
            pathParams: { slug: payrollRunSlug },
            bodyParams: omitEmptyValues({
              ...operationType,
              file: fileToUpload,
              name: renameFileForBulkUpload(file, rename),
            }),
            signal,
          });
        },
      }),
    invalidateQueries,
  };
};

export const useAdminUpdatePayslipParsingRules = (
  payrollRunSlug,
  { onSuccess, ...options } = {}
) => {
  const queryClient = useQueryClient();
  const mutation = usePatch('/api/v1/rivendell/payslips/parsing-rules', {
    onSuccess: async (response, variables) => {
      await Promise.all([invalidatePayrollRunMetadata(queryClient)]);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...options,
  });

  return useMutationWithDefaultParams(mutation, { bodyParams: { payrollRunSlug } });
};

export const useAdminAddContractToPayrollRun = ({ onSuccess, ...reactQueryCallbacks } = {}) => {
  const queryClient = useQueryClient();

  return usePost('/api/v1/rivendell/payroll-runs/[slug]/payroll-run-contracts', {
    onSuccess: async (response, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(['/api/v1/rivendell/contracts']),
        invalidatePayrollRunMetadata(queryClient),
      ]);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryCallbacks,
  });
};

export const useAdminBatchRemovePayElementsFromPayrollRun = ({
  onSuccess,
  ...reactQueryOptions
} = {}) => {
  const queryClient = useQueryClient();
  const { invalidateQuery } = useInvalidateQuery();

  return useDelete('/api/v1/rivendell/payroll-runs/[payrollRunSlug]/employee-pay-elements/batch', {
    onSuccess: async (response, variables) => {
      await Promise.all([
        invalidatePayrollRunMetadata(queryClient),
        invalidateQuery('/api/v1/rivendell/employee-pay-elements'),
      ]);

      if (onSuccess) {
        onSuccess(response, variables);
      }
    },
    ...reactQueryOptions,
  });
};

/**
 *
 * @param {import('@remote-com/data-layer').UseDeleteOptions<"/api/v1/rivendell/files/delete-multiple">} props
 */
export function useDeletePayrollFilesAsAdminMutation({ onSuccess, ...reactQueryOptions } = {}) {
  const { invalidateQuery } = useInvalidateQuery();

  return useDelete('/api/v1/rivendell/files/delete-multiple', {
    onSuccess: async (response, variables, ctx) => {
      invalidateQuery('/api/v1/rivendell/payroll-runs/[slug]/payroll-run-files');
      invalidateQuery('/api/v1/rivendell/payroll-runs/[slug]/totals');
      invalidateQuery('/api/v1/rivendell/payroll-runs/[slug]');

      if (onSuccess) {
        onSuccess(response, variables, ctx);
      }
    },
    ...reactQueryOptions,
  });
}
