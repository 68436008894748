import { NUMBER_OF_DIGITS_PER_TIME_SEGMENT } from './constants';

/**
 * Display 24 hour day time string from hours and minutes
 * @param {{hours?: number, minutes?: number}} timeObj
 * @returns {string} The time in string form e.g., `"13:30"`
 */
export function displayDayTime({ hours = '', minutes = '' }) {
  return [
    hours.toString().padStart(NUMBER_OF_DIGITS_PER_TIME_SEGMENT, '0'),
    minutes.toString().padStart(NUMBER_OF_DIGITS_PER_TIME_SEGMENT, '0'),
  ].join(':');
}
