/* eslint-disable remote/disallow-data-layer-endpoint-assignment */
module.exports = {
  '/api/v1/account/notification-preferences': { serviceOptions: { asFormData: false } },
  '/api/v1/account/payment-preferences/[paymentPreferenceSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/profile-email': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/totp': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/totp/backup-codes': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/two-factor/optional-enrollment-reminder': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/career-development/performance-cycles/[cycleSlug]/performance-snapshot-instances/[snapshotInstanceSlug]/performance-snapshot-instance-assessments/[assessmentSlug]':
    {
      serviceOptions: { asFormData: false },
    },

  '/api/v1/employee/career-development/performance-cycles/[cycleSlug]/performance-snapshot-instances/[snapshotInstanceSlug]/performance-snapshot-instance-assessments/[assessmentSlug]/submissions':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/employee/clients/[clientSlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employee/contractor-invoices/recurring/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/contractor-invoices/retry-payouts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/employments/[employmentSlug]/nium-beneficiaries/[beneficiarySlug]': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/employee/employments/[employmentSlug]/payment-splits/[currencyCode]': {
    serviceOptions: {
      asFormData: false,
      toSnakeCase: false,
    },
  },
  '/api/v1/employee/expiring-documents/[expiringDocumentSlug]': {
    serviceOptions: {
      asFormData: true,
    },
  },
  '/api/v1/employee/scheduled-contractor-invoices/[slug]': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/employee/time-preferences/[timePreferenceSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employee/timesheets/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/benefits/employment/[slug]/benefit-offers': {
    serviceOptions: {
      asFormData: false,
      deepTransformResponse: false,
      toSnakeCase: false,
    },
  },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/calibratees/[teamEmploymentSlug]/group':
    { serviceOptions: { asFormData: false } },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/calibrator/groups/[calibrationGroupSlug]/calibrate':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/[calibrationGroupSlug]':
    { serviceOptions: { asFormData: false } },

  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/forms': {
    serviceOptions: {
      asFormData: false,
      transformResponseOptions: {
        stopPaths: ['data.performance_assessment_forms'],
      },
    },
  },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/performance-snapshots': {
    serviceOptions: {
      asFormData: false,
      transformResponseOptions: {
        stopPaths: ['data.performance_assessment_forms'],
      },
    },
  },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/performance-snapshots/[snapshotSlug]/performance-snapshot-instances/[snapshotInstanceSlug]/performance-snapshot-instance-assessments/[assessmentSlug]':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/performance-snapshots/[snapshotSlug]/performance-snapshot-instances/[snapshotInstanceSlug]/performance-snapshot-instance-assessments/[assessmentSlug]/submissions':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/employer/company/payment-settings': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/company/time-settings': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/compliance-requests': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/contract-eligibility/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/contracts/[contractSlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/custom-fields/batch/[employmentSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employee-pay-elements/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/administrative-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/bank-account': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/external-employment-id': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/federal-tax-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/home-address': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/personal-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/expenses': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/account-mapping': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/account-mapping/preferences': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/automatic-sync-config': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/email-recipients': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/features': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/manual-sync-config': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/sync-config': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/tenants': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/integration/[integrationName]/tenants/preferences': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/leave-policies/[policySlug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/leave-policies/[policySlug]/archive': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/leave-policies/[policySlug]/variants/[variantSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/leave-policies/[policySlug]/variants/[variantSlug]/activate': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/leave-policies/[policySlug]/variants/[variantSlug]/archive': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/administrative-details': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/bank-account': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/default-legal-entity': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/documents/[documentSlug]/accept': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/documents/[documentSlug]/sign': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/nium-beneficiaries/[beneficiarySlug]': {
    serviceOptions: {
      asFormData: false,
      transformParamsOptions: {
        // exclude nium beneficiary fields from snake-casing
        exclude: [/^(?!accountUsageType).*/],
      },
    },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/nium-beneficiaries/[beneficiarySlug]/statutory':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/pay-schedule': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/settings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/signatory-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/state-tax-requirements/[state]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/nium-kyb/entity-onboarding/applicant-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/nium-kyb/entity-onboarding/business-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/nium-kyb/entity-onboarding/compliance-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/nium-kyb/entity-onboarding/stakeholders': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/company/intentions': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/employments/[slug]/product': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/onboarding/pre-hire-check/[employmentSlug]/process': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/[paymentMethodSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payroll-runs/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/employer/roles': {
    serviceOptions: {
      asFormData: false,
      toSnakeCase: false,
    },
  },
  '/api/v1/employer/roles/[slug]': {
    serviceOptions: {
      asFormData: false,
      toSnakeCase: false,
    },
  },
  '/api/v1/employer/timesheets/[slug]/timesheet-notes': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/workflows/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/workflows/[slug]/validate': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/form_draft/[formName]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/prompts/[promptId]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/collections-settings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/compliance-profile': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/compliance-settings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/payment-settings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/tags/[tagId]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/compliance/automated-reserves/industry-sector-policies/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contract-documents/[slug]/archive': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contractor-invoices/[invoiceSlug]/retry-payout': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contractor-invoices/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contractor-invoices/[slug]/mark-as-paid-out': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contractor-invoices/[slug]/mark-as-rejected': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/contracts/amendment/[contractSlug]/approve': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/cost-approval-requests/[costApprovalRequestSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employee-pay-elements/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employment-requests/[requestSlug]/cancel': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employment-requests/[requestSlug]/mark-as-done': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/benefit-offers': {
    serviceOptions: { asFormData: false, toSnakeCase: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/federal-tax-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/tax-administrative-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/extra-entitlements/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-report-adjustments/[adjustmentSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-report-data/[rowSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items/[partialItemSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/invoice-reports/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/knowledge-database/fact-schemas': {
    serviceOptions: { asFormData: false, deepTransformResponse: false, toSnakeCase: false },
  },
  '/api/v1/rivendell/knowledge-database/fact-schemas/[name]': {
    serviceOptions: { asFormData: false, deepTransformResponse: false, toSnakeCase: false },
  },
  '/api/v1/rivendell/knowledge-database/facts/[factSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/administrative-details': {
    serviceOptions: {
      asFormData: false,
      transformParamsOptions: {
        // Legal entity admin details for IND include `pt_form5_sa` (PT Form5 Signing Authority) field.
        exclude: ['pt_form5_sa'],
      },
    },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/bank-account': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/compliance-profile': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/compliance-settings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/default-legal-entity': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/documents/[legalEntityDocumentSlug]/approve':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/pay-schedule': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/payroll-config': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/settings': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/signatory-details': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/state-tax-requirements/[state]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entity-pay-element-data-source/[payElementDataSourceSlug]': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/rivendell/legal-entity-pay-element-template-collections/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/offboardings/[offboardingSlug]/submit-to-payroll': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-integration/sync_benefits': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-outputs/[slug]/preview-summarization': {
    serviceOptions: {
      asFormData: false,
    },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-payment-references/[payrollPaymentReferenceSlug]':
    {
      serviceOptions: { asFormData: false },
    },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-run-files/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]': {
    serviceOptions: {
      asFormData: false,
      transformResponseOptions: { stopPaths: ['data.validations'] },
    },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payroll-outputs/batch-update-all': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/force-actions/[payrollRunSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/remote-invoices': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/remote-invoices/[slug]': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/rivendell/users/[userSlug]/default-bank-account': {
    serviceOptions: { asFormData: true },
  },
  '/api/v2/rivendell/knowledge-database/facts/[factIdentifier]/fact-values/[valueSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v2/rivendell/onboarding/employments/[employmentSlug]/job-title-eligibility/approve': {
    serviceOptions: { asFormData: true },
  },
  '/api/v2/rivendell/onboarding/employments/[employmentSlug]/job-title-eligibility/reject': {
    serviceOptions: { asFormData: true },
  },
};
