import isFunction from 'lodash/isFunction';
import type { FocusEvent } from 'react';
import { useMemo } from 'react';
import type { InputActionMeta, OptionProps } from 'react-select';
import ReactSelect from 'react-select';

import type { $TSFixMe } from '../../types';
import { useFormGroupContext } from '../form-group';

import * as selectComponents from './components';
import type { InputSelectProps, SelectOption, SelectOptionBase } from './InputSelect';

export type InputElementProps<T extends SelectOptionBase> = Pick<
  InputSelectProps<T>,
  | 'components'
  | 'closeMenuOnSelect'
  | 'styles'
  | 'isClearable'
  | 'isSearchable'
  | 'isLoading'
  | 'onFocus'
  | 'onBlur'
  | 'onChange'
  | 'onMenuOpen'
  | 'onMenuClose'
  | 'hideSelectedOptions'
  | 'options'
  | 'size'
> & {
  identifiers?: { id: string; inputId: string; instanceId: string };
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  isMenuOpen?: boolean;
  menuRef?: React.RefObject<HTMLDivElement>;
};

// NOTE: Previously we did not have an API for adding prefixes/suffixes to options
// so folks had to use custom components. This acts as a fallback to make legacy
// code work but also to allow for other, custom option designs in the future.
export const getCustomOption =
  (OptionComponent: OptionProps) =>
  ({ innerProps, ...restProps }: React.ComponentProps<$TSFixMe>) => {
    return isFunction(OptionComponent) ? (
      <selectComponents.Option {...restProps} innerProps={innerProps}>
        <OptionComponent {...restProps} />
      </selectComponents.Option>
    ) : (
      <selectComponents.Option {...restProps} innerProps={innerProps} />
    );
  };

export function InputElement<T extends SelectOptionBase = SelectOption>({
  components,
  styles,
  identifiers,
  isClearable,
  isSearchable,
  isMenuOpen,
  onFocus,
  onBlur: passedOnBlur,
  onInputChange: passedOnInputChange,
  menuRef,
  options,
  size,
  ...props
}: InputElementProps<T>) {
  const {
    id,
    ref,
    handleOnBlur: formGroupOnBlur,
    onInputChange: formGroupOnInputChange,
    multiple,
    ...inputProps
  } = useFormGroupContext();
  const { Option: CustomOptionComponent, ...customComponents } = components || {};

  const privateOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    formGroupOnBlur?.(event);
    passedOnBlur?.(event);
  };

  // `onInputChange` can be passed both as a direct property to this component and via
  // the form group's context. We need to call both of them.
  const privateOnInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    formGroupOnInputChange?.(newValue, actionMeta);
    passedOnInputChange?.(newValue, actionMeta);
  };

  const CustomOption = useMemo(
    () => getCustomOption(CustomOptionComponent),
    [CustomOptionComponent]
  );

  return (
    <ReactSelect
      components={{
        ...selectComponents,
        Option: CustomOption,
        ...customComponents,
      }}
      showPlaceholder={inputProps.size === 'sm' && inputProps.labelPlacement === 'outside'}
      styles={styles}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={inputProps.readOnly} // readOnly is preferable for A11Y (support keyboard navigation), but react-select only supports isDisabled.
      {...identifiers}
      menuIsOpen={isMenuOpen}
      options={options}
      ref={ref}
      aria-label={inputProps.label}
      {...props}
      {...inputProps}
      isMulti={multiple}
      onFocus={onFocus}
      onBlur={privateOnBlur}
      onInputChange={privateOnInputChange}
      tabSelectsValue={false}
      // @ts-expect-error
      menuRef={menuRef}
    />
  );
}
