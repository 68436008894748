import { IconV2OutlineColumns } from '@remote-com/norma/icons/IconV2OutlineColumns';

import { MenuItem } from '@/src/components/DropdownMenu';
import { useEditColumns } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/hooks/useEditColumns';

export const EditColumnsButton = () => {
  const { openEditColumnsDrawer } = useEditColumns();
  const menuItemProps = {
    onClick: (event) => {
      event.stopPropagation();
    },
    onSelect: () => {
      openEditColumnsDrawer();
    },
    Icon: IconV2OutlineColumns,
  };

  return (
    <MenuItem {...menuItemProps} data-testid="edit-table">
      Edit table
    </MenuItem>
  );
};
