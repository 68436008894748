import type { NodeViewProps } from '@tiptap/react';
import { useLayoutEffect, useRef } from 'react';

import {
  StyledNodeViewWrapper,
  Image,
  ResizeTriggerLeft,
  ResizeTriggerRight,
} from './ResizableImageComponent.styled';

const MIN_IMAGE_WIDTH = 100;
const MAX_IMAGE_WIDTH_DEFAULT = 200;

function constrain(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function ResizableImageComponent({
  updateAttributes,
  node,
  extension,
  editor,
}: NodeViewProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  const maxWidthRef = useRef<number>(MAX_IMAGE_WIDTH_DEFAULT);

  const { editable } = extension.options;

  /**
   * Computes and updates the maxWidth allowed for the image within the editor.
   *
   * This calculation ensures the image:
   * - Does not exceed the width of the editor, considering the editor's current size
   * - Accounts for the image's indentation relative to the editor's left edge
   *
   * The maxWidth is dynamically adjusted based on changes to the editor's and image's
   * dimensions and positions (e.g. viewport being resized)
   */
  useLayoutEffect(() => {
    const editorEl = editor.view.dom;
    const imageEl = imageRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      let editorRect: DOMRect | undefined;
      let imageRect: DOMRect | undefined;

      entries.forEach((entry) => {
        if (entry.target === editorEl) {
          editorRect = entry.target.getBoundingClientRect();
        } else if (entry.target === imageEl) {
          imageRect = entry.target.getBoundingClientRect();
        }
      });

      if (editorRect && imageRect) {
        maxWidthRef.current = editorRect.x + editorRect.width - imageRect.x;
      }
    });

    if (editorEl && imageEl) {
      resizeObserver.observe(editorEl);
      resizeObserver.observe(imageEl);
    }

    return () => resizeObserver.disconnect();
  }, [editor]);

  const onMouseDown =
    (handleType: 'left' | 'right') => (mouseDownEvent: React.MouseEvent<HTMLImageElement>) => {
      if (imageRef.current === null) return;

      const startSize = { x: imageRef.current.clientWidth, y: imageRef.current.clientHeight };
      const aspectRatio = startSize.x / startSize.y;
      const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

      function onMouseMove(mouseMoveEvent: MouseEvent) {
        /**
         * Direction is used to implement the following:
         *
         * - When dragging from the left trigger, the image should
         * enlarge going left and shrink going right.
         *
         * - When dragging from the right trigger, the image should
         * enlarge going right and shrink going left.
         */
        const direction = handleType === 'left' ? -1 : 1;
        const deltaX = mouseMoveEvent.pageX - startPosition.x;
        /**
         * We want to avoid users who drag "too much" to
         * create an image which is wider than the editor,
         * or smaller than a reasonable size.
         */
        const newWidth = constrain(
          startSize.x + deltaX * direction,
          MIN_IMAGE_WIDTH,
          maxWidthRef.current
        );
        const newHeight = newWidth / aspectRatio;

        updateAttributes({
          width: newWidth,
          height: newHeight,
        });
      }

      function onMouseUp() {
        document.body.removeEventListener('mousemove', onMouseMove);
      }

      document.body.addEventListener('mousemove', onMouseMove);
      document.body.addEventListener('mouseup', onMouseUp, { once: true });
    };

  return (
    <StyledNodeViewWrapper data-type={extension.name}>
      <Image
        ref={imageRef}
        {...node.attrs}
        src={node.attrs.src}
        alt={node.attrs.alt}
        title={node.attrs.title}
      />
      {editable && (
        <>
          <ResizeTriggerLeft onMouseDown={onMouseDown('left')} />
          <ResizeTriggerRight onMouseDown={onMouseDown('right')} />
        </>
      )}
    </StyledNodeViewWrapper>
  );
}
