// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconMap = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M7.5 1.13a1 1 0 0 1 .95-.02l7.52 3.76 6.53-3.74A1 1 0 0 1 24 2v16a1 1 0 0 1-.5.87l-7 4a1 1 0 0 1-.95.02l-7.52-3.76-6.53 3.74A1 1 0 0 1 0 22V6a1 1 0 0 1 .5-.87l7-4Zm.53 2L2 6.58v13.7l5.5-3.15a1 1 0 0 1 .95-.02l7.52 3.76L22 17.42V3.72l-5.5 3.15a1 1 0 0 1-.95.02L8.03 3.13Z" /><path fillRule="evenodd" clipRule="evenodd" d="M8 1a1 1 0 0 1 1 1v16a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M16 5a1 1 0 0 1 1 1v16a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Z" /></svg>;
const ForwardRef = forwardRef(IconMap);
export { ForwardRef as IconMap };