// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneAi = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 24 24" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" d="M3.25 10.85c0-.56 0-.84.1-1.05a1 1 0 0 1 .45-.44c.21-.11.49-.11 1.05-.11h14.3c.56 0 .84 0 1.05.1a1 1 0 0 1 .44.45c.11.21.11.49.11 1.05v5.5c0 2.24 0 3.36-.44 4.22a4 4 0 0 1-1.74 1.74c-.86.44-1.98.44-4.22.44h-4.7c-2.24 0-3.36 0-4.22-.44a4 4 0 0 1-1.74-1.74c-.44-.86-.44-1.98-.44-4.22z" clipRule="evenodd" opacity={0.32} /><path fillRule="evenodd" d="M19.91 4.03a.75.75 0 0 1 .22 1.03l-3.5 5.35a.75.75 0 0 1-1.26-.82l3.5-5.35a.75.75 0 0 1 1.04-.21M8.25 15.25A.75.75 0 0 1 9 14.5h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75m4.75-14a.75.75 0 0 1 .75.75c0 .69.56 1.25 1.25 1.25a.75.75 0 0 1 0 1.5c-.69 0-1.25.56-1.25 1.25a.75.75 0 0 1-1.5 0c0-.69-.56-1.25-1.25-1.25a.75.75 0 0 1 0-1.5c.69 0 1.25-.56 1.25-1.25a.75.75 0 0 1 .75-.75M7 4c.3 0 .55.24.55.55 0 .5.4.9.9.9a.55.55 0 0 1 0 1.1.91.91 0 0 0-.9.9.55.55 0 1 1-1.1 0 .91.91 0 0 0-.9-.9.55.55 0 0 1 0-1.1.91.91 0 0 0 .9-.9c0-.3.25-.55.55-.55" clipRule="evenodd" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneAi);
export { ForwardRef as IconV2DuotoneAi };