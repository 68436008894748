import { Media, MediaBody, MediaObject } from '@remote-com/norma';
import { IconDownload } from '@remote-com/norma/icons/IconDownload';

import { FileButton, FileLabel, FileLabelTitle } from './FileField.styled';
import { FieldTitle, FileDescription, StyledFileUploadField } from './FileUploadField.styled';

/**
 * File download field
 *
 * @component
 * @param {Object} props
 * @param {string} props.label
 * @param {string | React.ReactNode} props.description
 * @param {string=} props.fileName
 * @param {string} props.url
 * @param {Function=} props.onClick
 * @returns {JSX.Element}
 */
function FileDownload({ label, description, filename, url, onClick }) {
  return (
    <StyledFileUploadField>
      <Media center>
        <MediaBody>
          <FieldTitle>{label}</FieldTitle>
          {description && <FileDescription>{description}</FileDescription>}
        </MediaBody>
        <MediaObject $preserveSize>
          <FileButton
            as="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            download={filename}
            onClick={onClick}
          >
            <IconDownload width="16px" />
            <FileLabel>
              <FileLabelTitle>Download</FileLabelTitle>
            </FileLabel>
          </FileButton>
        </MediaObject>
      </Media>
    </StyledFileUploadField>
  );
}

export default FileDownload;
