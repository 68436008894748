import {
  HOURS_IN_DAY,
  MINUTES_IN_HOUR,
} from '@/src/components/Ui/Form/WorkScheduleTableField/utils/constants';
import { getAsMinutes } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/getAsMinutes';

/**
 * Returns a set of start and end times in minutes, taking into consideration
 * end times that span the following day
 * @param {{hours: number, minutes: number}} startTime Start time
 * @param {{hours: number, minutes: number}} endTime End time
 * @returns {{ startTimeInMinutes: number, endTimeInMinutes: number }}
 */
export function getStartAndEndTimesInMinutes(startTime, endTime) {
  let endTimeInMinutes = getAsMinutes(endTime);
  const startTimeInMinutes = getAsMinutes(startTime);

  // if timeEnd is "the same" or "earlier" than timeStart, we treat it as a time for the following day (so we add 24 hours to it)
  if (endTimeInMinutes <= startTimeInMinutes) {
    endTimeInMinutes += HOURS_IN_DAY * MINUTES_IN_HOUR;
  }

  return {
    startTimeInMinutes,
    endTimeInMinutes,
  };
}
