export const FIELDSET_VARIANTS = {
  DEFAULT: 'default',
  FOCUSED: 'focused',
  CARD: 'card',
};

// To be used by Remote v2 to make the focused fieldeset context aware.
// This will allow the focused variant to rendered differently depending on the parent.
export const FIELDSET_MODES = {
  FOCUS_ENABLED: 'focused-enabled',
};
