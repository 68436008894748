import { useGet } from '@remote-com/data-layer';
import { useState } from 'react';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery } from 'react-query';

import { fetchUser } from '@/src/services/Admin';

const useBasicEmployeeDetailsQueryKey = 'fetch-basic-employee-data';
const REFETCH_INTERVAL = 5000;
const MAX_FETCH_COUNT = 5;

export const useBasicEmployeeDetails = (userSlug, config = {}) =>
  useQuery(
    [useBasicEmployeeDetailsQueryKey, userSlug],
    () => fetchUser({ pathParams: { slug: userSlug } }),
    {
      select: ({ data }) => data,
      ...config,
      enabled: Boolean(userSlug),
    }
  );

// Fetch the country of the company's headquarters based on the company slug
export const useCompanyHeadquartersCountry = (companySlug) =>
  useGet('/api/v1/rivendell/companies/[slug]', {
    params: {
      pathParams: {
        slug: companySlug,
      },
    },
    options: {
      enabled: Boolean(companySlug),
      select: (res) => {
        const addresses = res.data?.addresses;
        if (!addresses || addresses.length === 0) return undefined;

        // Return the headquarters country
        const headquarters = addresses.find((address) => address.isHeadquarters);
        return headquarters ? headquarters.country : undefined;
      },
    },
  });

// Fetch the duplicate reports of the user based on the user slug
// TODO: removed with https://linear.app/remote/issue/EOREM-538/integrating-fe-and-be
const isDuplicateReportMocked = true;
// Until the rest of the feature is shipped, the mock will be empty. So nothing will change on the admin side.
const mockDuplicateReportResponse = {
  data: {},
  isLoading: false,
};
// end of TODO

export const useDuplicateReport = (userSlug) => {
  const [duplicateReportFetchCount, setDuplicateReportFetchCount] = useState(0);

  // TODO: remove with https://linear.app/remote/issue/EOREM-538/integrating-fe-and-be
  if (isDuplicateReportMocked) {
    return mockDuplicateReportResponse;
  }
  // end of TODO

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useGet('/api/v1/rivendell/users/[userSlug]/duplicate-reports', {
    params: {
      pathParams: {
        userSlug,
      },
    },
    options: {
      enabled: Boolean(userSlug) && duplicateReportFetchCount <= MAX_FETCH_COUNT,
      select: ({ data }) => {
        return data;
      },
      onSuccess: ({ data }) => {
        if (Object.entries(data).length === 0) setDuplicateReportFetchCount(MAX_FETCH_COUNT);
        else setDuplicateReportFetchCount((state) => state + 1);
      },
      refetchInterval: REFETCH_INTERVAL,
    },
  });
};

export const useCheckDuplicateProfile = (userSlug) => {
  return useGet('/api/v1/rivendell/users/[userSlug]/duplicate-check', {
    params: {
      pathParams: {
        userSlug,
      },
    },
    options: {
      enabled: false,
    },
  });
};
