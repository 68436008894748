import { Stack } from '@remote-com/norma';
import PropTypes from 'prop-types';

import DynamicForm from '@/src/components/Form/DynamicForm';
import { ImmersiveModal } from '@/src/components/ImmersiveModal';
import { Modal } from '@/src/components/Modal';
import { SkeletonBox } from '@/src/components/SkeletonBox';

export const DynamicFormModal = ({
  immersive,
  confirmLoading,
  contentAfterForm,
  contentBeforeForm,
  fields,
  fieldValues,
  formName,
  formProps,
  headerTitle,
  isLoadingData,
  loadingDataError,
  modalProps,
  onCancel,
  onDismiss,
  onSubmit,
  showErrorSummary,
  validate,
  renderFields,
  className,
  // eslint-disable-next-line camelcase
  TODO_MIGRATE_handleOnSubmit,
}) => {
  const ModalComponent = immersive ? ImmersiveModal : Modal;
  return (
    <ModalComponent
      confirmLoading={confirmLoading}
      formName={formName}
      onCancel={onCancel}
      onDismiss={onDismiss}
      visible
      headerTitle={headerTitle}
      showFooter={!isLoadingData}
      showSubmitButton={!loadingDataError}
      {...modalProps}
    >
      <SkeletonBox isLoading={isLoadingData} error={loadingDataError}>
        <Stack gap={7} className={className}>
          {contentBeforeForm}
          <DynamicForm
            // eslint-disable-next-line camelcase
            TODO_MIGRATE_handleOnSubmit={TODO_MIGRATE_handleOnSubmit}
            fields={fields}
            validate={validate}
            formName={formName}
            handleOnSubmit={onSubmit}
            showErrorSummary={showErrorSummary}
            fieldValues={fieldValues}
            renderFields={renderFields}
            {...formProps}
          />
          {contentAfterForm}
        </Stack>
      </SkeletonBox>
    </ModalComponent>
  );
};

DynamicFormModal.defaultProps = {
  immersive: false,
  confirmLoading: false,
  fieldValues: {},
  isLoadingData: false,
  showErrorSummary: true,
  formProps: {},
  modalProps: {},
};

DynamicFormModal.propTypes = {
  immersive: PropTypes.bool,
  /**  Check if the Form's mutation is in a loading state */
  confirmLoading: PropTypes.bool,
  /** Element to appear below form  */
  contentAfterForm: PropTypes.node,
  /** Element to appear above form  */
  contentBeforeForm: PropTypes.node,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldValues: PropTypes.object,
  formName: PropTypes.string.isRequired,
  /**  Check if the component's data is loading properly */
  isLoadingData: PropTypes.bool,
  /**  Check for any errors while loading the component's data */
  loadingDataError: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  showErrorSummary: PropTypes.bool,
  /** Additional dynamic form props. */
  formProps: PropTypes.object,
  /** Additional modal props. */
  modalProps: PropTypes.object,
  validate: PropTypes.func,
  renderFields: PropTypes.func,
  headerTitle: PropTypes.string,
  /** Makes it possible to customize the style of DynamicForm */
  className: PropTypes.string,
};
