import { Box } from '@remote-com/norma';
import styled from 'styled-components';

import { textInputTypes, checkboxTypes } from '@/src/components/Form/DynamicForm/constants';

export const InputSpacerStyled = styled(Box)`
  & + & {
    margin-top: ${({ theme, biggerMargin }) => (biggerMargin ? '38px' : `${theme.space[7]}px`)};

    /* Note: radio buttons and checkboxes should have a larger margin to the elements on top of them (50px) */
    &[data-inputtype='radio'],
    &[data-inputtype='checkbox'],
    &[data-inputtype='ack-check'] {
      margin-top: ${({ theme }) => `${theme.space[7]}px`};
    }

    &[data-inputcategory='textInput'] {
      margin-top: ${({ theme }) => `${theme.space[8]}px`};
    }
  }

  & {
    margin-left: ${({ theme, isNestedField }) => (isNestedField ? `${theme.space[5]}px` : '0px')};
  }

  &[data-inputtype='radio'] + &[data-inputtype='radio'] {
    margin-top: ${({ theme }) => `${theme.space[7]}px`};
  }

  /* Note: sibling checkboxes should have a smaller gap between them (16px).*/
  &[data-inputcategory='checkInput'] + &[data-inputcategory='checkInput'],
  &[data-inputtype='checkbox'] + &[data-inputtype='ack-check'],
  &[data-inputtype='ack-check'] + &[data-inputtype='checkbox'],
  &[data-inputtype='checkbox'] + &[data-inputtype='checkbox'],
  &[data-inputtype='ack-check'] + &[data-inputtype='ack-check'] {
    margin-top: ${({ theme }) => `${theme.space[5]}px`};
  }

  &[data-inputcategory='textInput'] + &[data-inputcategory='textInput'] {
    margin-top: ${({ theme }) => `${theme.space[6]}px`};
  }

  /* Note: Non text inputs should have a bigger margin if they appear after a text input.*/
  &[data-inputcategory='textInput'] + &:not([data-inputcategory='textInput']) {
    margin-top: ${({ theme }) => `${theme.space[8]}px`};
  }
  /* Note: Checkbox inputs need to have a left spacing of 16px when they're used along with other input elements to be aligned with them. */
  &[data-inputtype='checkbox'],
  &[data-inputcategory='checkInput'],
  &[data-inputtype='ack-check'] {
    padding-right: ${({ theme }) => `${theme.space[5]}px`};
    padding-left: ${({ theme }) => `${theme.space[5]}px`};
  }
  /* Note: to address the spacing within Benefits we need some additional CSS rules */
  &[data-inputtype='benefits'] {
    label,
    legend {
      padding-right: ${({ theme }) => `${theme.space[5]}px`};
      padding-left: ${({ theme }) => `${theme.space[5]}px`};
    }
  }
  /* Note: forced values are visually represented as a title and description texts and are aligned with other input elements. */
  &[data-inputtype='forcedValue'] {
    padding-right: ${({ theme }) => `${theme.space[5]}px`};
    padding-left: ${({ theme }) => `${theme.space[5]}px`};
  }
`;

export const InputSpacer = ({ type, hidden, ...props }) => {
  return (
    <InputSpacerStyled
      data-inputtype={type}
      hidden={hidden}
      {...props}
      {...(textInputTypes[type] && { 'data-inputcategory': 'textInput' })}
      {...(checkboxTypes[type] && { 'data-inputcategory': 'checkInput' })}
    />
  );
};
