import {
  Text,
  Box,
  StyledFieldsetLegendText,
  LegacyInputStyle as InputStyle,
} from '@remote-com/norma';
import { useFormikContext } from 'formik';
import { rem } from 'polished';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';

import { InputSpacer as InputSpacerV2 } from '@/src/components/Ui/Form/InputSpacer';
import { getDDSessionReplayAttribute } from '@/src/helpers/datadog';

import { AutoFocusInput } from './AutoFocusInput';

export const InputSpacer = styled(Box)`
  & + & {
    margin-top: ${({ theme, biggerMargin }) => (biggerMargin ? '38px' : `${theme.space[7]}px`)};

    /* Note: radio buttons and checkboxes should have a larger margin to the elements on top of them (50px) */
    &[data-inputtype='radio'],
    &[data-inputtype='checkbox'],
    &[data-inputtype='ack-check'] {
      margin-top: ${({ theme }) => `${theme.space[9]}px`};
    }
  }

  &[data-inputtype='radio'] + &[data-inputtype='radio'] {
    margin-top: ${({ theme }) => `${theme.space[7]}px`};
  }

  /* Note: sibling checkboxes should have a smaller gap between them (16px).
  Unfortunately we have checkbox/ack as input types for the same element so we need to consider all combinations */
  &[data-inputtype='checkbox'] + &[data-inputtype='ack-check'],
  &[data-inputtype='ack-check'] + &[data-inputtype='checkbox'],
  &[data-inputtype='checkbox'] + &[data-inputtype='checkbox'],
  &[data-inputtype='ack-check'] + &[data-inputtype='ack-check'] {
    margin-top: ${({ theme }) => `${theme.space[5]}px`};
  }
`;
/**
 *
 * @param {import('@/types').WildcardProps} props
 * @returns {JSX.Element}
 */
export const InputController = ({ type, value, hidden, ...props }) => {
  const { setFieldValue } = useFormikContext();

  const ddActionAttribute = getDDSessionReplayAttribute(props);

  useEffect(() => {
    // Dynamically enforce a value when a dependent field changes
    const hasValidEmptyValue = value === 0 || value === '';
    if (hasValidEmptyValue || (value && typeof value !== 'function')) {
      setFieldValue(props.name, value);
    }
  }, [value, props.name, setFieldValue]);

  return <InputSpacerV2 type={type} hidden={hidden} {...ddActionAttribute} {...props} />;
};

export const Input = styled(AutoFocusInput)`
  ${InputStyle}
`;

export const InputLabel = styled(Text).attrs({ forwardedAs: 'label', variant: 'xs' })`
  display: block;
  position: relative;

  > span:first-child {
    margin-bottom: ${({ theme }) => `${theme.space[3]}px`};
  }
`;

export const LabelText = styled.span`
  display: inline-block;
  color: var(--labelTextColor, ${({ theme }) => theme.colors.grey[500]});

  ${({ $size }) =>
    $size === 'md'
      ? css`
          font-size: ${rem('16px')};
        `
      : null}
`;

/**
 * @deprecated Moved to `@remote-com/norma`.
 *
 * The export here is for backward compatibility,
 * as many components are still using it from here.
 */
export { StyledFieldsetLegendText as FieldGroupLabel };

export const FieldGroupDescription = styled(Text).attrs({
  variant: 'sm',
  color: 'inputGroupDescription',
  display: 'block',
})``;

FieldGroupDescription.defaultProps = {
  mt: '4px', // field group descriptions are spaced 4px from the field they're describing
};

export const TopLabel = styled(Text).attrs(({ hasError }) => ({
  variant: 'xs',
  color: hasError ? 'error' : 'inputLabel',
}))`
  display: block;

  ${({ theme, show }) => {
    return css`
      ${theme.typography.xs}
      opacity: ${show ? 1 : 0};
      transform: translateY(${show ? '0' : '10px'});
      transition: 0.1s ease;
      transition-property: 'opacity, transform';
    `;
  }}

  strong {
    font-weight: 600;
  }
`;

export const InputRowGroup = styled(Box)`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }
`;

export const MaskedInput = styled(InputMask).attrs(() => ({
  type: 'text',
}))`
  ${InputStyle}

  ${({ $size, theme }) =>
    $size === 'md'
      ? css`
          font-size: ${theme.typography.forms.inputFontSize};
        `
      : null}

  ${({ value, theme }) => {
    let style = css`
      --inputColor: ${theme.colors.inputPlaceholder};

      &:hover,
      &:focus {
        --inputColor: ${theme.colors.inputValue};
      }
    `;

    if (value?.length > 0) {
      style = css`
        --inputColor: ${theme.colors.inputValue};
      `;
    }

    return style;
  }}
`;

export const InputCheckboxGroup = styled(Box)`
  ${InputLabel} + & {
    margin-top: 1rem;
  }
`;

export const TextareaStyles = css`
  resize: vertical;
  min-height: 44px;
  height: 110px;
  border: 2px solid var(--inputBorderColor);
  border-radius: 8px;
  padding: 8px;
`;

export const AutoExpandTextarea = styled.textarea`
  overflow-y: hidden;
  width: 100%;
  resize: none;
  border: ${({ theme }) => `1.5px solid ${theme.colors.grey[300]};`};
  border-radius: 8px;
  padding: 12px 18px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[900]};
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey[300]};
  }
  &:focus {
    outline: none;
  }
`;

export const Textarea = styled.textarea`
  ${TextareaStyles}
`;
