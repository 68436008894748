// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineSupport = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="m6.01 4.95 3.2 3.2a4.73 4.73 0 0 1 5.58 0l3.2-3.2A9.21 9.21 0 0 0 6 4.95Zm13.04 1.06-3.2 3.2a4.73 4.73 0 0 1 0 5.58l3.2 3.2a9.21 9.21 0 0 0 0-11.98Zm-1.06 13.04-3.2-3.2a4.73 4.73 0 0 1-5.58 0l-3.2 3.2a9.21 9.21 0 0 0 11.98 0ZM4.95 17.99l3.2-3.2a4.73 4.73 0 0 1 0-5.58l-3.2-3.2a9.21 9.21 0 0 0 0 11.98ZM4.4 4.39a10.72 10.72 0 0 1 15.2 0 10.72 10.72 0 0 1 0 15.21 10.72 10.72 0 0 1-15.2 0 10.72 10.72 0 0 1 0-15.2ZM12 8.76a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineSupport);
export { ForwardRef as IconV2OutlineSupport };