// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneEditBox = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M9.25 1.25c-2.8 0-4.2 0-5.27.54a5 5 0 0 0-2.19 2.19c-.54 1.07-.54 2.47-.54 5.27v5.5c0 2.8 0 4.2.54 5.27a5 5 0 0 0 2.19 2.18c1.07.55 2.47.55 5.27.55h5.5c2.8 0 4.2 0 5.27-.55a5 5 0 0 0 2.18-2.18c.55-1.07.55-2.47.55-5.27v-5.5c0-2.8 0-4.2-.55-5.27a5 5 0 0 0-2.18-2.19c-1.07-.54-2.47-.54-5.27-.54h-5.5Z" /><path d="m11.57 8.37-3 3c-.46.46-.7.7-.88.96a4 4 0 0 0-.43.75c-.13.3-.2.62-.37 1.26l-.53 2.14c-.13.51-.2.77-.13.95a.6.6 0 0 0 .34.34c.18.06.44 0 .95-.13l2.14-.53c.64-.16.96-.24 1.26-.37a4 4 0 0 0 .75-.43c.27-.19.5-.42.97-.89l3-3a5.45 5.45 0 0 1-2.77-1.5 5.47 5.47 0 0 1-1.3-2.55Z" /><path d="M16.6 11.08c.2.04.41-.03.56-.18a2.68 2.68 0 0 0 0-3.8l-.25-.25a2.7 2.7 0 0 0-3.82 0 .65.65 0 0 0-.18.55 4.5 4.5 0 0 0 1.05 2.5c.54.57 1.36.97 2.64 1.18Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneEditBox);
export { ForwardRef as IconV2DuotoneEditBox };