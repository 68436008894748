import type { BoxedIconProps } from '@remote-com/norma';
import { BoxedIcon, Stack, Text } from '@remote-com/norma';
import type { ElementType } from 'react';
import styled from 'styled-components';

export const CardStatusMessageWrapper = styled(Stack).attrs({
  alignItems: 'center',
})`
  text-align: center;
`;

export const CardStatusMessageTitle = styled(Text).attrs((attributes) => ({
  variant: 'baseMedium',
  mt: 6,
  ...attributes,
}))`
  text-wrap: balance;
`;

export const CardStatusMessageDescription = styled(Text).attrs((attributes) => ({
  color: 'grey.600',
  variant: 'sm',
  mt: 3,
  ...attributes,
}))``;

export type CardStatusMessageProps = {
  description?: string;
  icon: ElementType;
  title: string;
  iconTone?: BoxedIconProps['tone'];
};

export function CardStatusMessage({
  title,
  description,
  icon: IconElement,
  iconTone = 'brand',
}: CardStatusMessageProps) {
  return (
    <CardStatusMessageWrapper>
      <BoxedIcon Icon={IconElement} appearance="default" size="xl" tone={iconTone} />
      <CardStatusMessageTitle>{title}</CardStatusMessageTitle>
      <CardStatusMessageDescription>{description}</CardStatusMessageDescription>
    </CardStatusMessageWrapper>
  );
}
