import { useField } from 'formik';

import { WorkWeekScheduleTable } from '@/src/components/Ui/Form/WorkWeekScheduleTableField/WorkWeekScheduleTable';

export { getValidationSchema } from '@/src/components/Ui/Form/WorkWeekScheduleTableField/utils/getValidationSchema';
export {
  apiPayloadToState,
  stateToApiPayload,
} from '@/src/components/Ui/Form/WorkWeekScheduleTableField/utils/marshalling';
export { displayFieldInfoBlock } from '@/src/components/Ui/Form/WorkWeekScheduleTableField/utils/displayFieldInfoBlock';

/**
 * WorkWeekScheduleTableField
 *
 * Returns a field with multiple inputs to specify a work week hourly schedule.
 *
 * @export
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').WorkWeekScheduleField, 'type'>} props
 * @return {JSX.Element}
 */
export function WorkWeekScheduleTableField({
  name,
  label,
  description,
  displayLunchTime,
  ...props
}) {
  const [{ value }, { error, touched }, { setTouched, setValue }] = useField(name);

  return (
    <WorkWeekScheduleTable
      {...props}
      name={name}
      label={label}
      description={description}
      displayLunchTime={displayLunchTime}
      onChange={(nextValue) => setValue(nextValue)}
      value={value}
      error={error}
      touched={touched}
      setTouched={setTouched}
    />
  );
}
