// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconBookmarkPlus = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M19 .08a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Zm-12 5a1 1 0 0 0-1 1v14.06l5.42-3.87a1 1 0 0 1 1.16 0L18 20.14v-9.06a1 1 0 1 1 2 0v11a1 1 0 0 1-1.58.81L12 18.31l-6.42 4.58A1 1 0 0 1 4 22.08v-16a3 3 0 0 1 3-3h5a1 1 0 1 1 0 2H7Z" /></svg>;
const ForwardRef = forwardRef(IconBookmarkPlus);
export { ForwardRef as IconBookmarkPlus };