import get from 'lodash/get';

import { CountryCell, CountryMultiSelectFilter, TextCell } from '@/src/components/Table';
import { HIRE_TYPE_LABELS } from '@/src/domains/employment/constants';
import { isDirect, isContractor } from '@/src/domains/employment/helpers';

export const employmentCountryColumn = {
  id: 'countries',
  Header: 'Employment country',
  accessor: 'employment.contract.country',
  Cell: CountryCell,
  Filter: CountryMultiSelectFilter,
  exportData: (row) => get(row, 'employment.contract.country.code'),
  dataColumnAccessor: 'employment.contract.country.name',
};

export function typeofHireColumn({ hiddenColumn } = { hiddenColumn: false }) {
  return {
    Header: 'Type of hire',
    id: 'type_of_hire',
    accessor: (employment = {}) => {
      if (isContractor(employment)) {
        return HIRE_TYPE_LABELS.CONTRACTOR;
      }

      return HIRE_TYPE_LABELS.EMPLOYEE;
    },

    Cell: TextCell,
    disableFilters: true,
    disableSortBy: true,
    disableExport: true,
    hiddenColumn,
  };
}

export function payrollRunByColumn({ hiddenColumn } = { hiddenColumn: false }) {
  return {
    Header: 'Payroll run by',
    id: 'payroll_run_by',
    accessor: (employment = {}) => {
      if (isDirect(employment)) {
        return 'My company';
      }

      return 'Remote';
    },
    Cell: TextCell,
    disableFilters: true,
    disableSortBy: true,
    disableExport: true,
    hiddenColumn,
  };
}
