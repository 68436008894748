import type { DefaultTheme, ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components';

import { Box } from '../../../foundations/layout/Box';

const focusVisibleStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  box-shadow: 0 0 0 2px ${theme.colors.blank}, 0 0 0 4px ${theme.colors.brand[500]};
`;

export const DatePickerCalendarBasicContainer = styled(Box).attrs({
  as: 'article', // For A11Y reasons. Demo at !22321
  'aria-label': 'Date picker widget',
})<{}>`
  ${({ theme }) => css`
    --date-picker-column-gap: 4px;
    --date-picker-circle-size: 40px;
    --date-picker-padding-size: ${theme.space[6]}px;
    --date-picker-header-height: 56px;
    --date-picker-footer-height: 58px;
    --date-picker-z-index-disabled: 1;
    --date-picker-z-index-date: 2;

    background-color: white;
    border-radius: ${theme.borderRadius.md} var(--right-border-radius) ${theme.borderRadius.md}
      ${theme.borderRadius.md};
    box-shadow: 0px 0px ${theme.space[4]}px 0px #0f141914;

    user-select: none;
    width: 384px;

    .react-datepicker__day-names,
    .react-datepicker__week {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      place-items: center;
    }

    .react-datepicker__day-names {
      color: ${theme.colors.grey[900]};
      ${theme.typography.smMedium}
      margin: ${theme.space[5]}px ${theme.space[5]}px 0;
      height: var(--date-picker-circle-size);
    }

    .react-datepicker__month {
      display: grid;
      gap: ${theme.space[2]}px;
      grid-template-rows: repeat(6, 1fr);
      /* Use padding instead of margin to prevent the .focus-visible box-shadow from being clipped */
      padding: ${theme.space[2]}px ${theme.space[5]}px ${theme.space[5]}px;

      /* We need the added classname to increase specificity */
      &--disabled.react-datepicker__month-text {
        color: ${theme.colors.grey[200]};
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .react-datepicker__month.react-datepicker__monthPicker {
      grid-template-rows: repeat(4, 1fr);
    }

    .react-datepicker__month-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .react-datepicker__month-text,
    .react-datepicker__day {
      position: relative;
      border-radius: ${theme.borderRadius.full};
      color: ${theme.colors.grey[900]};
      cursor: pointer;
      display: grid;
      place-items: center;

      height: var(--date-picker-circle-size);
      width: var(--date-picker-circle-size);

      ${theme.typography.sm}

      --date-picker-marker-color: ${theme.colors.brand[600]};

      &:focus-within:not(&--selected),
      &:hover,
      &--keyboard-selected {
        background-color: ${theme.colors.grey[100]};
      }

      /* Focus styles for days outside the range or at the start/end of the range */
      &.focus-visible:not(&--in-range),
      &.focus-visible:is(&--range-start, &--range-end) {
        ${focusVisibleStyles}
      }

      /* Focus styles specifically for days within the range but not at the start or end */
      &.focus-visible.react-datepicker__day--in-range:not(:is(&--range-start, &--range-end)) {
        &::after {
          ${focusVisibleStyles}
        }
      }

      :focus-visible,
      &:focus:not(:focus-visible) {
        outline: 2px solid transparent;
      }

      &--outside-month {
        color: ${theme.colors.grey[400]};
      }

      &--selected {
        background-color: ${theme.colors.brand[600]};
        color: white;
        --date-picker-marker-color: ${theme.colors.white};

        &:focus,
        &:hover {
          background-color: ${theme.colors.brand[500]};
        }
      }

      &--disabled {
        color: ${theme.colors.grey[400]};
        cursor: not-allowed;
      }
    }
  `}
`;
