import type { ReactNode } from 'react';
import { createContext, useState, useCallback } from 'react';

import type { SafeWildcardProps } from '@/types';

/*
Heads up! Important behaviors:

1. The Modal context does NOT use or even require the Modal component.
  - Technical-wise, it only manages the render of a generic component as modal
    (as in the "modal" adjective).
  - In practice, the component usually based on the Modal component.

2. The "modalProps" does NOT stay the same. When we calls "showModal(Foo, bar)",
  Foo receives more than just "bar":
  - ModalContext injects control props (e.g. "hideModal") and other props for
    backward compatibility (e.g. "open").
  - ModalRoot injects common Modal props for convenient (e.g. "onDismiss").
  - In TypeScript, this means the props of "Foo" is a superset of "bar".
*/

/**
 * Props that the Component used in "showModal" will ultimately receive
 */
export type ModalContextComponentProps<UserProvidedProps extends SafeWildcardProps> =
  UserProvidedProps & {
    // Injected by ModalContext
    open: boolean;
    hideModal: () => void;

    // Injected by ModalRoot
    onCancel: () => void;
    onDismiss: () => void;
  };

/**
 * Type of a Component to be used in "showModal"
 *
 * ```tsx
 * export const CompanyUpdateModal: ModalContextComponent<{
 *   companySlug: string;
 * }> = (props) => {
 *   props.hideModal // typed as function
 *   props.companySlug // typed as string
 * }
 *
 * showModal(CompanyUpdateModal, {
 *   companySlug, // required as string
 * })
 * ```
 */
export type ModalContextComponent<UserProvidedProps extends SafeWildcardProps> = (
  props: ModalContextComponentProps<UserProvidedProps>
) => JSX.Element;

/**
 * Modal props AFTER ModalContext injection but BEFORE ModalRoot injection.
 * See notes at top to learn more.
 */
type InternalProps = {
  open: boolean; // Backward compatible
  onCancel?: () => void; // See ModalRoot
  onDismiss?: () => void; // See ModalRoot
  isLoading?: boolean;
  errorMessage?: string;
};

export type ModalContextState = {
  /**
   * Render a Component as modal
   */
  showModal: <UserProvidedProps extends SafeWildcardProps>(params: {
    /** The Component to render */
    component: ModalContextComponent<UserProvidedProps>;
    /** Custom props to pass to the component (e.g. "companySlug", "userSlug") */
    modalProps?: UserProvidedProps;
  }) => void;
  /**
   * Hide the currently rendered component. Despite the name, this does not
   * just "hide" but actually unmounts the component altogether.
   */
  hideModal: () => void;

  /**
   * Internal state of the context. Avoid using this if you can as it is not
   * the same as the props the Component will receive (see notes at top).
   *
   * In practice besides InternalProps this also contains UserProvidedProps
   * but as a global field we could not type the latter here.
   */
  modalProps: InternalProps;
  /**
   * This is technically a generic component, but as a field in a global context
   * we could not know its exact type.
   */
  component: null | ModalContextComponent<SafeWildcardProps>;
  /**
   * Manually update modal props in advanced cases.
   */
  setModalProps: (props: SafeWildcardProps) => void;
};

/**
 * This is exported for backward compatibility. Most places should use the
 * `useModalContext` hook instead.
 */
export const ModalContext = createContext<ModalContextState>({
  modalProps: { open: false },
  component: null,
  // @todo: Throw error here so we won't accidentally forget to render the
  // provider?
  showModal: () => {},
  hideModal: () => {},
  setModalProps: () => {},
});

type Props = {
  children: ReactNode;
};

/**
 * Technically it's simpler to have the state as `ModalValues | null`. However
 * for backward compatibility we need to support a case where the modal props
 * are set independent from the Component:
 *
 * ```ts
 * showModal(Foo, { bar, baz })
 * setModalProps({ bar: "newValue" })
 * ```
 *
 * We could simplify this state if we remove the "setModalProps" usages.
 */
type ModalValues = {
  component: null | ModalContextComponent<SafeWildcardProps>;
  props: InternalProps;
};

export const ModalProvider = ({ children }: Props): JSX.Element => {
  const [modalValues, setModalValues] = useState<ModalValues>({
    component: null,
    props: { open: false },
  });

  // useCallback is used to prevent infinite loop when using showModal / hideModal with useEffect
  const showModal: ModalContextState['showModal'] = useCallback((params) => {
    setModalValues({
      // This "as" helps SafeWildcardProps satisfies UserProvidedProps.
      // - In theory, this is not great because we are downplaying the component
      //   (i.e. it could accept more, but we say it's only a generic object).
      // - In practice, however, this is necessary due to the component being
      //   a global state (context). It also does not have practical downside,
      //   as we only render this component once, at ModalRoot.
      // Thank to this compromise, we could have a strict typing at the *public*
      // API ("showModal"), which is much more useful.
      component: params.component as ModalContextComponent<SafeWildcardProps>,
      props: { ...params.modalProps, open: true },
    });
  }, []);

  const hideModal = useCallback(() => {
    setModalValues((prev) => ({
      component: null,
      props: { ...prev.props, open: false },
    }));
  }, []);

  const setModalProps: ModalContextState['setModalProps'] = (props) => {
    setModalValues((prev) => ({
      ...prev,
      props: { open: prev.props.open, ...props },
    }));
  };

  const value = {
    component: modalValues.component,
    modalProps: modalValues.props,
    showModal,
    hideModal,
    setModalProps,
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export default ModalProvider;
