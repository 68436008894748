import {
  Drawer,
  DrawerLayout,
  DrawerPane,
  DrawerTitle,
  DrawerFooter,
  DrawerHeader,
} from '@remote-com/norma';
import { IconV2OutlineArrowLeft } from '@remote-com/norma/icons/IconV2OutlineArrowLeft';
import { IconV2OutlineBookmark } from '@remote-com/norma/icons/IconV2OutlineBookmark';
import { IconV2OutlineBookmarkPlus } from '@remote-com/norma/icons/IconV2OutlineBookmarkPlus';
import { IconV2OutlineRefreshCw } from '@remote-com/norma/icons/IconV2OutlineRefreshCw';
import type { ElementType, MouseEventHandler, PropsWithChildren } from 'react';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';
import { DeleteConfigModal } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/DeleteConfigModal';
import { EditColumnsView } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/EditColumnsView';
import { useSegmentedColumnsState } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/hooks/useSegmentedColumnsState';
import { SaveConfigModal } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/SaveConfigModal';
import { SavedTablesView } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/SavedTablesView';
import type { useColumnsState } from '@/src/components/Table/hooks/useColumnsState';
import type { AnyColumnsConfiguration } from '@/src/components/Table/hooks/useColumnsState/types';
import useClickOutside from '@/src/hooks/useClickOutside';
import { useModalContext } from '@/src/hooks/useModalContext';
import { useSearch } from '@/src/hooks/useSearch';

const messages = {
  drawerTitle: 'Edit table',
  resetTableButton: 'Reset table',
  saveTableButton: 'Save table',
  goToEditColumnsViewButton: 'Back',
  goToSavedTablesViewButton: 'Saved tables',
};

/* -------------------------------------------------------------------------------------------------
 * DrawerFooterButton
 * -----------------------------------------------------------------------------------------------*/

type DrawerFooterButton = PropsWithChildren<{
  icon?: ElementType;
  onClick: MouseEventHandler<HTMLButtonElement>;
}>;

const DrawerFooterButton = ({ icon, ...props }: DrawerFooterButton) => {
  return <Button {...props} IconBefore={icon} size="xs" variant="ghost" tone="secondary" />;
};

/* -------------------------------------------------------------------------------------------------
 * EditColumnsDrawer
 * -----------------------------------------------------------------------------------------------*/

const EDIT_COLUMNS_DRAWER_TITLE_ID = 'table-edit-columns-drawer-title';

const StyledDrawerPane = styled(DrawerPane)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[6]}px;
`;

const StyledDrawerFooter = styled(DrawerFooter)`
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[2]}px;
  position: sticky;
`;

type EditColumnsDrawerMode = 'edit-columns' | 'manage-saved-tables';

type EditColumnsDrawerProps = {
  /** Allows users to configure additional columns beyond the default columns configuration. */
  allowConfigureAllColumns?: boolean;
  /** The props from `useColumnsState` */
  columnsStateProps: ReturnType<typeof useColumnsState>;
  /** Controls the visibility of the drawer. */
  isVisible?: boolean;
  /** Function to call when the drawer should be closed. */
  onClose: () => void;
  /** Function to call when a configuration should be saved. */
  onSaveColumnsConfiguration?: (configuration: AnyColumnsConfiguration) => void;
  /** Function to control whether to listen to clicks outside the drawer. */
  setListenToClickOutside: (listen: boolean) => void;
  /** Toggle to enable the table views feature. */
  isTableViewsEnabledExperimental: boolean;
};

export const EditColumnsDrawer = ({
  allowConfigureAllColumns = true,
  columnsStateProps: {
    activeConfigurationName,
    columnsState,
    deleteConfiguration,
    order,
    resetColumnConfiguration,
    saveCurrentConfiguration,
    savedConfigurations,
    toggleColumnState,
    toggleConfiguration,
    changeColumnsOrder,
    visibility,
  },
  isVisible = true,
  onClose,
  onSaveColumnsConfiguration,
  setListenToClickOutside,
  isTableViewsEnabledExperimental,
}: EditColumnsDrawerProps) => {
  const drawerRef = useRef(null);

  const [currentView, setCurrentView] = useState<EditColumnsDrawerMode>('edit-columns');

  const { changeColumnOrder, segmentedColumnsState, toggleColumnVisibility } =
    useSegmentedColumnsState({
      allowConfigureAllColumns,
      columnsState,
      onToggleColumnVisibility: toggleColumnState,
      onChangeColumnOrder: changeColumnsOrder,
    });

  const hasNonDefaultColumnState = !!order.length || !!Object.keys(visibility).length;

  const searchProps = useSearch({
    items: [...segmentedColumnsState.visible, ...segmentedColumnsState.invisible],
    getItemSearchValue: ([, , name]) => name,
  });

  const { searchValue, onSearch } = searchProps;

  // Clear search field when clicking outside the drawer
  useClickOutside({
    refEl: drawerRef,
    listen: Boolean(searchValue),
    onClick: () => onSearch(''),
  });

  const { showModal } = useModalContext();

  const handleSaveCurrentConfiguration = (data: any) => {
    if (!saveCurrentConfiguration) return;
    saveCurrentConfiguration(data);
    onSaveColumnsConfiguration?.(data);
  };

  const handleDeleteConfiguration = (configurationName: string) => {
    setListenToClickOutside(false);

    showModal({
      component: DeleteConfigModal,
      modalProps: {
        messages: {
          title: 'Delete saved table?',
          description: (descriptionName: string) =>
            `Are you sure you want to delete the '${descriptionName}' saved table?`,
          deleteButton: 'Delete table',
        },
        configurationName,
        onConfirm: deleteConfiguration,
        setListenToClickOutside,
      },
    });
  };

  const openSaveConfigModal = ({ onClick }: any) => {
    setListenToClickOutside(false);

    showModal({
      component: SaveConfigModal,
      modalProps: {
        data: segmentedColumnsState.visible,
        onSave: handleSaveCurrentConfiguration,
        onClick,
        setListenToClickOutside,
      },
    });
  };

  const hasSavedConfigurations = !!savedConfigurations.length;
  const shouldShowFooter =
    (hasSavedConfigurations || hasNonDefaultColumnState) && !isTableViewsEnabledExperimental;

  return (
    <Drawer
      aria-labelledby={EDIT_COLUMNS_DRAWER_TITLE_ID}
      data-testid="columnsConfiguration"
      isVisible={isVisible}
      onClose={onClose}
      $zIndex={100}
      Trigger="TODO_MIGRATE_TO_COMPONENT_DONT_COPY"
      $layout="fullWidth"
    >
      <DrawerLayout>
        <DrawerHeader>
          <DrawerTitle id={EDIT_COLUMNS_DRAWER_TITLE_ID}>{messages.drawerTitle}</DrawerTitle>
        </DrawerHeader>
        {currentView === 'edit-columns' && (
          <>
            <StyledDrawerPane>
              <EditColumnsView
                {...searchProps}
                activeConfigurationName={activeConfigurationName || undefined}
                changeColumnOrder={changeColumnOrder}
                segmentedColumnsState={segmentedColumnsState}
                toggleColumnVisibility={toggleColumnVisibility}
              />
            </StyledDrawerPane>
            {shouldShowFooter && (
              <StyledDrawerFooter>
                {hasSavedConfigurations && (
                  <DrawerFooterButton
                    data-testid="go-to-manage-saved-tables-view"
                    icon={IconV2OutlineBookmark}
                    onClick={() => setCurrentView('manage-saved-tables')}
                  >
                    {messages.goToSavedTablesViewButton}
                  </DrawerFooterButton>
                )}
                {hasNonDefaultColumnState && (
                  <>
                    <DrawerFooterButton
                      data-testid="reset-table"
                      icon={IconV2OutlineRefreshCw}
                      onClick={resetColumnConfiguration}
                    >
                      {messages.resetTableButton}
                    </DrawerFooterButton>
                    <DrawerFooterButton
                      data-testid="save-table"
                      icon={IconV2OutlineBookmarkPlus}
                      onClick={() => openSaveConfigModal({ setListenToClickOutside })}
                    >
                      {messages.saveTableButton}
                    </DrawerFooterButton>
                  </>
                )}
              </StyledDrawerFooter>
            )}
          </>
        )}
        {currentView === 'manage-saved-tables' && (
          <>
            <StyledDrawerPane>
              <SavedTablesView
                activeConfigurationName={activeConfigurationName}
                onDeleteConfiguration={handleDeleteConfiguration}
                onToggleConfiguration={toggleConfiguration}
                savedConfigurations={savedConfigurations}
              />
            </StyledDrawerPane>
            <StyledDrawerFooter>
              <DrawerFooterButton
                icon={IconV2OutlineArrowLeft}
                onClick={() => setCurrentView('edit-columns')}
              >
                {messages.goToEditColumnsViewButton}
              </DrawerFooterButton>
            </StyledDrawerFooter>
          </>
        )}
      </DrawerLayout>
    </Drawer>
  );
};
