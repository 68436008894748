import { Tooltip } from '@remote-com/norma';
import { IconV2OutlineZap } from '@remote-com/norma/icons/IconV2OutlineZap';
import type { ResolvedPos } from '@tiptap/pm/model';
import type { Editor } from '@tiptap/react';
import get from 'lodash/get';
import type { Mark, Node as ProseMirrorNode } from 'prosemirror-model';
import type { Selection } from 'prosemirror-state';
import styled from 'styled-components';

import type { SmartFieldDefinition } from '@/src/api/config/employ/contract.types';
import type { User } from '@/src/api/config/employ/user.types';
import { ButtonInline } from '@/src/components/Button';
import type { UserAccountResponse } from '@/src/components/UserProvider';
import { ADMIN_USERS_ROUTE } from '@/src/constants/routes';
import { CONTRACT_MARK_TYPES } from '@/src/domains/contracts/ContractsEditor/constants';
import { CONTRACT_TEMPLATE_TYPE_LABELS } from '@/src/domains/contractTemplates/contractTemplates/constants';
import type {
  DocumentProperties,
  TemplateProperties,
} from '@/src/domains/documents/employer/DocumentsHub/genericEmployerDocument.types';
import type { ContractProperties } from '@/src/domains/documents/employer/types';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { userCan } from '@/src/domains/registration/auth/helpers';

export const filterNonDeprecatedFields = (smartFieldDefinitions: SmartFieldDefinition[] = []) =>
  smartFieldDefinitions.filter(({ meta }) => !meta?.isDeprecated);

const StyledIconV2OutlineZap = styled(IconV2OutlineZap).attrs(({ theme }) => ({
  fill: theme.colors.brand[600],
}))`
  margin-right: ${({ theme }) => theme.space[2]}px;
  vertical-align: middle;
`;

type GetDocumentNameProps = {
  contractProperties: ContractProperties | DocumentProperties | TemplateProperties;
  employeeData?: { user: User };
  user?: UserAccountResponse;
  isTemplate?: boolean;
};

export function isContractProperties(
  obj: ContractProperties | DocumentProperties | TemplateProperties
): obj is ContractProperties {
  return 'usedForAutomation' in obj;
}

export const getDocumentName = ({
  contractProperties,
  employeeData,
  user,
  isTemplate,
}: GetDocumentNameProps) => {
  const companyName = get(contractProperties, 'company.name');

  const type = CONTRACT_TEMPLATE_TYPE_LABELS[contractProperties.type];
  const name = contractProperties.name ? `: ${contractProperties.name}` : '';
  const company = companyName ? ` (${companyName})` : '';

  const isUsedForAutomation =
    isContractProperties(contractProperties) && contractProperties?.usedForAutomation;

  const employeeName =
    userCan('read', Resources.users, user) && employeeData?.user?.name ? (
      <>
        {' '}
        for{' '}
        <ButtonInline
          asTag="a"
          href={`${ADMIN_USERS_ROUTE}/${employeeData?.user?.slug}`}
          trackNavigation={['contracts', 'admin-user']}
          target="_blank"
          data-dd-action-name="User profile link"
        >
          {employeeData?.user?.name}
        </ButtonInline>
      </>
    ) : (
      ''
    );
  return (
    <>
      {isTemplate && isUsedForAutomation && (
        <Tooltip label="This template is used for automations">
          <StyledIconV2OutlineZap width="16" />
        </Tooltip>
      )}
      {type}
      {name}
      {company}
      {employeeName}
    </>
  );
};

export const findClickedCommentMark = (
  clickedPosition: number,
  node: ProseMirrorNode,
  nodePos: number
): Mark | undefined => {
  const textNode =
    // `node` is the paragraph or any other block node, but comment marks
    // surround TextNodes, see https://github.com/ProseMirror/prosemirror/issues/525
    clickedPosition === nodePos ? node : node.nodeAt(clickedPosition - nodePos - 1);

  return textNode?.marks.find((mark) => mark.type.name === CONTRACT_MARK_TYPES.COMMENT_TEXT);
};

export const isAnyCommentSelected = (editor: Editor) =>
  Object.keys(editor?.getAttributes(CONTRACT_MARK_TYPES.COMMENT_TEXT) ?? {}).length > 0;

export const canAddCommentToSelection = (editor: Editor) => {
  const selection: Selection & { $headCell?: ResolvedPos } = editor?.view.state.selection;

  // Disable comments that include other comments and table cell / table row / table column
  // comments. We currently do not handle the rendering of these and could be a confusing
  // interaction for the user. How should we communicate this to the user?
  const selectionIsEmpty = selection?.empty ?? true;
  const tableIsSelected = selection?.$headCell ?? false;

  return !selectionIsEmpty && !isAnyCommentSelected(editor) && !tableIsSelected;
};
