import { inputStyles } from '@remote-com/norma';
import { CardExpiryElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';

/**
 * Temporarily wrapping the CardNumberElement in a styled component to avoid circular dependency issue:
 * will be addressed in https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/34531
 */
export const CardExpiryElementStyled = styled((props) => <CardExpiryElement {...props} />)`
  ${inputStyles}
`;
