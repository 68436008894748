import type { GetResponse } from '@remote-com/data-layer';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { tooltipStyles } from '@/src/domains/reports/components/ReportWidgets/Common';

import type { BREAKDOWN_CATEGORIES } from './WorkforceBreakdownWidget';

type ChartData = {
  breakdownsKeys: string[] | undefined;
  chartDataMap: Record<string, any>;
  chartOptions: any;
  colors: Record<string, string>;
};

type UseChartDataProps = {
  data: GetResponse<'/api/v1/reports/people/[slug]'>['data'];
};

export function useChartData({ data }: UseChartDataProps): ChartData {
  const { breakdowns, summary } = data || {};
  const breakdownsKeys = breakdowns && Object.keys(breakdowns);
  const theme = useTheme();
  const { colors: themeColors } = theme;

  const colors = useMemo(
    () => ({
      active: themeColors.brand[600],
      onboarding: themeColors.green[600],
      inactive: themeColors.grey[400],
    }),
    [themeColors]
  ) as Record<(typeof BREAKDOWN_CATEGORIES)[number], string>;

  const getChartData = useCallback(
    (breakdownType: string) => {
      const breakdown = breakdowns?.[breakdownType];
      if (!breakdown || breakdown.data.length === 0) return null;

      return {
        labels: breakdown?.data.map((item) => item.label || item.range),
        datasets: Object.keys(summary).map((key) => ({
          label: summary[key].label,
          data: breakdown.data.map((item) => item[key]),
          backgroundColor: colors[key as keyof typeof colors],
          borderSkipped: 'middle',
          borderRadius: {
            topLeft: 8,
            topRight: 8,
          },
        })),
      };
    },
    [breakdowns, summary, colors]
  );

  const chartDataMap = useMemo(
    () =>
      Object.fromEntries(
        breakdownsKeys?.map((breakdownType) => [breakdownType, getChartData(breakdownType)]) || []
      ),
    [breakdownsKeys, getChartData]
  );

  const chartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.6,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...tooltipStyles(themeColors),
          callbacks: {
            label(tooltipItem: any) {
              const label = tooltipItem.dataset.label || '';
              const value = tooltipItem.raw;
              return `${label}: ${value}`;
            },
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          title: { display: false },
          grid: { display: false },
          ticks: {
            font: {
              size: 12,
              weight: 500,
              family: 'Inter',
            },
          },
        },
        y: {
          stacked: true,
          beginAtZero: true,
          title: { display: false },
          ticks: {
            font: {
              size: 12,
              weight: 500,
              family: 'Inter',
            },
          },
        },
      },
    }),
    [themeColors]
  );

  return { breakdownsKeys, chartDataMap, chartOptions, colors };
}
