// https:api-docs.transferwise.com/#recipient-accounts-banks-and-branches
export const THA_BANK_CODES = [
  { value: '045', label: 'BNP Paribas' },
  { value: '002', label: 'Bangkok Bank Public Company' },
  { value: '034', label: 'Bank For Agriculture And Agricultural Cooperatives' },
  { value: '025', label: 'Bank Of Ayudhya (Krungsri bank)' },
  { value: '022', label: 'CIMB' },
  { value: '017', label: 'Citibank' },
  { value: '031', label: 'HSBC' },
  { value: '070', label: 'ICBC' },
  { value: '066', label: 'Islamic Bank Of Thailand' },
  { value: '004', label: 'Kasikorn Bank' },
  { value: '069', label: 'Kiatnakin Bank' },
  { value: '006', label: 'Krung Thai Bank' },
  { value: '073', label: 'Land And Houses Retail Bank' },
  { value: '039', label: 'Mizuho Corporate Bank' },
  { value: '014', label: 'SIAM COMMERCIAL BANK PCL., THE' },
  { value: '020', label: 'Standard Chartered Bank ' },
  { value: '018', label: 'Sumitomo Mitsui Banking Corporation' },
  { value: '067', label: 'TISCO Bank' },
  { value: '011', label: 'TMBThanachart Bank' },
  { value: '033', label: 'The Government Housing Bank' },
  { value: '030', label: 'The Government Savings Bank' },
  { value: '071', label: 'The Thai Credit Retail Bank' },
  { value: '024', label: 'United Overseas Bank' },
];

export const THA_EOR_BANK_CODES = [
  ...THA_BANK_CODES,
  { value: '008', label: 'JP Morgan Chase Bank' },
  { value: '026', label: 'Mega International Commercial Bank' },
  { value: '027', label: 'Bank of America' },
  { value: '032', label: 'Deutsche Bank' },
  { value: '052', label: 'Bank of China' },
  { value: '080', label: 'Sumitomo Mitsui Trust Bank' },
];
