import { toast } from '@remote-com/norma';
import { openModal } from 'react-url-modal';

export function openVerifyDepositModal({ gustoAchSlug }: { gustoAchSlug?: string | null } = {}) {
  if (gustoAchSlug === null) {
    return toast.error(
      'Your account verification step is still being set up. Please try again later.'
    );
  }

  openModal({
    name: 'verify-deposits',
    params: {
      slug: gustoAchSlug,
      isGusto: true,
    },
  });
}
