import { Fragment, useMemo } from 'react';

import { FiltersInCols } from '@/src/components/Table/Components/TableComponents.styled';
import FiltersPopover from '@/src/components/Table/Components/Toolbar/FiltersPopover';
import { useSaveFilterContext } from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilterContext';
import { popoverFiltersToTableFilters, queryFromState } from '@/src/components/Table/helpers';
import { InputController } from '@/src/components/Ui/Form/Input';

export const FiltersPopoverTable = ({
  transformFilters,
  columns,
  setAllFilters,
  filterColumns,
  onFilterOpen,
  state,
  getFilterLabel,
  filterValidationSchema,
}) => {
  const formDefaultValues = queryFromState(state)?.filters || {};
  const { setLoadedSavedFilter } = useSaveFilterContext();
  const isLarge = filterColumns.length >= 6;

  const filtersApplied = useMemo(() => {
    const result = state?.filters?.map(({ id, value }) => ({
      value,
      id,
      label: getFilterLabel(id, value, state.filters),
      resetFilter: () => {
        const resettedFilters = state.filters.reduce((acc, filter) => {
          if (filter.id === id) {
            return acc;
          }
          return [...acc, filter];
        }, []);
        setAllFilters(transformFilters(resettedFilters));
      },
      // Hide a filter if respective column has disableFilters prop
      // For use cases where a filter is applied in the background but user can't see/interact with it
      // Or for a specific (temporary) use case where exclude_linked_drafts is applied but a filter button does not show
      // More here https://linear.app/remote/issue/WFP-723/onboarding-page-use-new-filter-option
      hidden:
        !!columns.find((col) => col.id === id && col.disableFilters) ||
        !!filterColumns.find((col) => col.id === id)?.hidden,
      Header: columns.find((col) => col.id === id)?.Header,
    }));

    return result;
  }, [state.filters, columns, filterColumns, setAllFilters, transformFilters, getFilterLabel]);

  return (
    <FiltersPopover
      filtersApplied={filtersApplied}
      formDefaultValues={formDefaultValues}
      onSubmit={(popoverFilters, filterName = null) => {
        if (setLoadedSavedFilter) {
          setLoadedSavedFilter(filterName);
        }
        setAllFilters(transformFilters(popoverFiltersToTableFilters(popoverFilters)));
      }}
      onReset={() => {
        if (setLoadedSavedFilter) {
          setLoadedSavedFilter(null);
        }
        setAllFilters([]);
      }}
      onOpen={onFilterOpen}
      large={isLarge}
      filterValidationSchema={filterValidationSchema}
    >
      {({ selectFieldProps }) => {
        const Wrapper = isLarge ? FiltersInCols : Fragment;
        const wrapperProps = isLarge
          ? {
              gridRowGap: 6,
            }
          : {};

        return (
          <Wrapper {...wrapperProps}>
            {filterColumns
              .sort(({ filterSortIndex: a }, { filterSortIndex: b }) => (b ?? 0) - (a ?? 0))
              .map((column) => {
                return (
                  <InputController key={column.id} data-testid={`${column.id}-filter-item`}>
                    {column.canFilter ? column.render('Filter', selectFieldProps) : null}
                  </InputController>
                );
              })}
          </Wrapper>
        );
      }}
    </FiltersPopover>
  );
};
