import { IconV2OutlineDownloadBase } from '@remote-com/norma/icons/IconV2OutlineDownloadBase';

import { Button } from '@/src/components/Button';
import { MenuItem } from '@/src/components/DropdownMenu';
import { ExportDataPopover } from '@/src/components/Table/Components/Toolbar/ExportDataPopover';
import { exportData } from '@/src/components/Table/export/export';
import { useModalContext } from '@/src/hooks/useModalContext';

export const ExportCSVButton = ({
  exportCSV,
  exportFooter,
  totalCount,
  columnsState,
  handleExportColumns,
  clientSidePagination,
  pageCount,
  queryFn,
  data,
  metadata,
  reactTableState,
  onExportData,
  exportCSVDisplayText = 'Export data',
  exportWithExcelSupport = false,
  allowConfigureAllColumns,
  renderOutsideOfMenu,
  useCursorPaginationForExports,
}) => {
  const { showModal } = useModalContext();

  const onUserAction = () => {
    showModal({
      component: ExportDataPopover,
      modalProps: {
        columnsState,
        totalCount,
        handleExportColumns,
        allowConfigureAllColumns,
        createCSV: async (columnsToExport) => {
          await exportData({
            hasPagination: pageCount > 1,
            data,
            metadata,
            reactTableState,
            pageCount,
            columns: columnsToExport,
            queryFn,
            exportCSV,
            exportFooter,
            clientSidePagination,
            includeBOM: exportWithExcelSupport,
            useCursorPaginationForExports,
          });

          onExportData?.({
            hasPagination: pageCount > 1,
            data,
            metadata,
            reactTableState,
            pageCount,
            columns: columnsToExport,
            queryFn,
            exportCSV,
            exportFooter,
          });
        },
      },
    });
  };

  if (renderOutsideOfMenu) {
    return (
      <Button
        onClick={onUserAction}
        IconBefore={IconV2OutlineDownloadBase}
        variant="outline"
        size="sm"
        tone="secondary"
      >
        {exportCSVDisplayText}
      </Button>
    );
  }

  return (
    <MenuItem onSelect={onUserAction} Icon={IconV2OutlineDownloadBase}>
      {exportCSVDisplayText}
    </MenuItem>
  );
};
