import type { MenuListProps } from 'react-select';
import styled from 'styled-components';

import { Box } from '../../../layout';
import type { $TSFixMe } from '../../../types';

const StyledMenuList = styled(Box)<$TSFixMe>`
  --field-select-menu-item-height: 44px;

  max-height: calc(var(--field-select-menu-item-height) * 5);
  overflow-y: auto;
  text-align: start;
`;

export function MenuList({ children, innerProps, innerRef }: MenuListProps) {
  return (
    <StyledMenuList {...innerProps} ref={innerRef}>
      {children}
    </StyledMenuList>
  );
}
