import type { UrlObject } from 'url';

import { useRouter } from 'next/router';

import { DASHBOARD_ROUTE } from '@/src/constants/routes';

type GoBackFn = (href?: string | UrlObject) => Promise<void | boolean>;

export const useGoBack = () => {
  const router = useRouter();
  const fallbackUrl = DASHBOARD_ROUTE;

  const goBack: GoBackFn = async (href = fallbackUrl) => {
    // Navigate back in history
    if (window.history.length > 1) return router.back();

    // If the user doesn't have a history (if the url was opened in a new tab, via an email, for example)
    // use the href to go to the specified page, instead of going back in history
    return router.push(href);
  };

  return { goBack };
};
