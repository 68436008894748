// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotonePercentage = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g opacity={0.32}><path d="M2.25 6a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z" /><path d="M18 14.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z" /></g><path fillRule="evenodd" clipRule="evenodd" d="M18.42 2.38c.34.23.43.7.2 1.04l-12 18a.75.75 0 0 1-1.24-.84l12-18a.75.75 0 0 1 1.04-.2Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotonePercentage);
export { ForwardRef as IconV2DuotonePercentage };