import { useInvalidateQuery, usePost } from '@remote-com/data-layer';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';

export type CreateTableViewParams = {
  tableView: TableViewsApi.CreateTableViewParams;
  onSuccess?: (data: TableViewsApi.TableView) => void;
  onError?: (exception: unknown) => void;
};

export function useCreateTableView() {
  const mutation = usePost('/api/v1/table-views');

  const { invalidateQuery } = useInvalidateQuery();
  const createTableView = async ({ tableView, onSuccess, onError }: CreateTableViewParams) => {
    await mutation.mutateAsync(
      {
        bodyParams: tableView,
      },
      {
        onSuccess: async (response: TableViewsApi.CreateTableViewResponse) => {
          invalidateQuery('/api/v1/table-views');
          if (typeof response.data.config === 'string') {
            response.data.config = JSON.parse(response.data.config);
          }
          onSuccess?.(response.data as TableViewsApi.TableView);
        },
        onError: (exception) => {
          onError?.(exception);
        },
      }
    );
  };

  return {
    createTableView,
    isLoading: mutation.isLoading,
  };
}
