import isFunction from 'lodash/isFunction';
import { useState } from 'react';

import { InputStripeElements } from '@/src/components/Ui/Form';

import { getCardBrandLogo } from './helpers';
import { CardNumberElementStyled, CreditCardVendorLogo } from './InputCard.styled';

export function InputCard({ onChange, ...props }) {
  const [brand, setBrand] = useState('unknown');

  const isCardBrandKnown = brand !== 'unknown';
  const cardBrandLogo = getCardBrandLogo(brand);

  const handleOnChange = (event) => {
    setBrand(event.brand);

    if (isFunction(onChange)) {
      onChange(event);
    }
  };

  return (
    <InputStripeElements
      aside={
        cardBrandLogo && isCardBrandKnown
          ? () => <CreditCardVendorLogo as={cardBrandLogo} />
          : undefined
      }
      StripeElementsComponent={CardNumberElementStyled}
      onChange={handleOnChange}
      {...props}
    />
  );
}
