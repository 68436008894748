import type { PropsWithChildren } from 'react';
import { createContext, useContext, useState, useEffect } from 'react';

import { useAutoSave } from '@/src/domains/contracts/shared/hooks/useAutoSave';
import { useContractActions } from '@/src/domains/contracts/shared/hooks/useContractActions';
import {
  isContractTemplateArchived,
  isContractTemplatePublished,
} from '@/src/domains/contractTemplates/helpers';
import type { $TSFixMe } from '@/types';

import type { EditorType } from '../shared/constants';

type SaveStatus = {
  hasError: boolean;
  isSaved: boolean;
  isSaving: boolean;
  reset: () => void;
};

type ContractsEditorContextProps = {
  contractType: string;
  isPublished: boolean;
  isArchived: boolean;
  saveStatus: SaveStatus;
  editorType: EditorType;
  isBilingual: boolean;
  setIsBilingual: (isBilingual: boolean) => void;
  serverEditorState: any;
  setEditorStateBuffer: (state: any) => void;
  setDocumentSmartFields: (fields: any) => void;
};

const ContractsEditorContext = createContext<ContractsEditorContextProps>({
  contractType: '',
  isPublished: false,
  isArchived: false,
  saveStatus: {
    hasError: false,
    isSaved: false,
    isSaving: false,
    reset: () => null,
  },
  // Default to viewer mode that is the most restrictive
  editorType: 'viewer',
  isBilingual: false,
  setIsBilingual: () => null,
  serverEditorState: undefined,
  setEditorStateBuffer: () => null,
  setDocumentSmartFields: () => null,
});

type ContractsEditorProviderProps = {
  editorType: EditorType;
  contractProperties: $TSFixMe;
};

// The ContractsEditorProvider wraps the contract editor instance and should be
// used to handle any stateful interactions within the editor (i.e. publishing, autosave).
// Mutations should NOT be created inside the provider itself, and should be relegated to
// useContractActions so that they can be used outside of the editor (i.e. tables, modals)
// when necessary
export const ContractsEditorProvider = ({
  editorType,
  children,
  contractProperties,
}: PropsWithChildren<ContractsEditorProviderProps>) => {
  const {
    slug: contractSlug,
    status: contractStatus,
    type: contractType,
    editorState: serverEditorState,
  } = contractProperties;
  const isArchived = isContractTemplateArchived(contractProperties);

  const [isSaved, setIsSaved] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isBilingual, setIsBilingual] = useState(contractProperties?.isBilingual);

  // Smartfields need to be stored explicitly on each editor update so that we can merge all smartfields and deduplicate them upon sending the request, rather than just looking at the editor content that is being saved. This means that a smartfield can be deleted on one editor, while still present in the other, and it will still be sent in the request. Initially, we set both to the same contractProperties.fields, so that when either editor updates, we keep the existing smartfields.
  const [documentSmartFields, setDocumentSmartFields] = useState({
    primaryContentSmartFields: contractProperties?.fields,
    secondaryContentSmartFields: contractProperties?.fields,
  });

  const { contractDocument } = useContractActions();

  const { setEditorStateBuffer } = useAutoSave({
    setHasError,
    setIsSaved,
    setIsSaving,
    isSaving,
    contractSlug,
    contractType,
    contractDocument,
    editorType,
    isBilingual,
    documentSmartFields,
  });

  const resetSaveStatus = () => {
    setHasError(false);
    setIsSaved(false);
    setIsSaving(false);
  };

  const [isPublished, setIsPublished] = useState(
    isContractTemplatePublished({ status: contractStatus })
  );

  useEffect(() => {
    setIsPublished(isContractTemplatePublished({ status: contractStatus }));
  }, [contractStatus]);

  return (
    <ContractsEditorContext.Provider
      value={{
        contractType,
        isPublished,
        isArchived,
        saveStatus: {
          hasError,
          isSaved,
          isSaving,
          reset: resetSaveStatus,
        },
        editorType,
        isBilingual,
        setIsBilingual,
        serverEditorState,
        setEditorStateBuffer,
        setDocumentSmartFields,
      }}
    >
      {children}
    </ContractsEditorContext.Provider>
  );
};

export const useContractsEditorContext = () => useContext(ContractsEditorContext);
