import type { BoxedIconTone } from '@remote-com/norma';
import { IconV2DuotoneBriefcase } from '@remote-com/norma/icons/IconV2DuotoneBriefcase';
import { IconV2DuotoneGlobe } from '@remote-com/norma/icons/IconV2DuotoneGlobe';
import { IconV2DuotoneUser } from '@remote-com/norma/icons/IconV2DuotoneUser';
import { IconV2DuotoneUsers } from '@remote-com/norma/icons/IconV2DuotoneUsers';
import type { Chart } from 'chart.js';
import type { ElementType } from 'react';

import type {
  DoughnutRadiusProps,
  EORFrequency,
  PricingPlanProductType,
  PricingPlanType,
} from '@/src/domains/pricingPlans/common/PricingPlans.types';

export const pricingPlanProductTypes = {
  EOR: 'eor',
  CONTRACTOR: 'contractor',
  GLOBAL_PAYROLL: 'global_payroll',
  REMOTE_TALENT: 'remote_talent',
  RECRUIT: 'recruit',
} as const;

export const pricingPlanProductTypesList = Object.values(pricingPlanProductTypes);

export const pricingPlanIdentifiers = {
  EOR: 'eor',
  EOR_LOW_COST: 'eor_low_cost',
  EOR_MONTHLY: 'eor_monthly',
  EOR_MONTHLY_LOW_INCOME: 'eor_monthly_low_income',
  CONTRACTOR: 'contractor',
  CONTRACTOR_PLUS: 'contractor_plus',
  GLOBAL_PAYROLL: 'global_payroll',
  REMOTE_TALENT: 'remote_talent',
  RECRUIT_FREE_TRIAL: 'recruit_free_trial',
  RECRUIT_ESSENTIAL: 'recruit_essential',
  RECRUIT_GROWTH: 'recruit_growth',
  RECRUIT_STRATEGIC: 'recruit_strategic',
} as const;

export const pricingPlanLabels: Record<PricingPlanType, string> = {
  [pricingPlanIdentifiers.EOR]: 'EOR Core',
  [pricingPlanIdentifiers.EOR_LOW_COST]: 'EOR Low-income',
  [pricingPlanIdentifiers.EOR_MONTHLY]: 'EOR Core',
  [pricingPlanIdentifiers.EOR_MONTHLY_LOW_INCOME]: 'EOR Low-income',
  [pricingPlanIdentifiers.CONTRACTOR]: 'Contractor Management',
  [pricingPlanIdentifiers.CONTRACTOR_PLUS]: 'Contractor Management Plus',
  [pricingPlanIdentifiers.GLOBAL_PAYROLL]: 'Remote Payroll',
  [pricingPlanIdentifiers.REMOTE_TALENT]: 'Remote Talent',
  [pricingPlanIdentifiers.RECRUIT_FREE_TRIAL]: 'Recruit Free Trial',
  [pricingPlanIdentifiers.RECRUIT_ESSENTIAL]: 'Recruit Essential',
  [pricingPlanIdentifiers.RECRUIT_GROWTH]: 'Recruit Growth',
  [pricingPlanIdentifiers.RECRUIT_STRATEGIC]: 'Recruit Strategic',
};

export const planTypeToProductType = {
  [pricingPlanIdentifiers.EOR]: pricingPlanProductTypes.EOR,
  [pricingPlanIdentifiers.EOR_LOW_COST]: pricingPlanProductTypes.EOR,
  [pricingPlanIdentifiers.EOR_MONTHLY]: pricingPlanProductTypes.EOR,
  [pricingPlanIdentifiers.EOR_MONTHLY_LOW_INCOME]: pricingPlanProductTypes.EOR,
  [pricingPlanIdentifiers.CONTRACTOR]: pricingPlanProductTypes.CONTRACTOR,
  [pricingPlanIdentifiers.CONTRACTOR_PLUS]: pricingPlanProductTypes.CONTRACTOR,
  [pricingPlanIdentifiers.GLOBAL_PAYROLL]: pricingPlanProductTypes.GLOBAL_PAYROLL,
  [pricingPlanIdentifiers.REMOTE_TALENT]: pricingPlanProductTypes.REMOTE_TALENT,
  [pricingPlanIdentifiers.RECRUIT_FREE_TRIAL]: pricingPlanProductTypes.RECRUIT,
  [pricingPlanIdentifiers.RECRUIT_ESSENTIAL]: pricingPlanProductTypes.RECRUIT,
  [pricingPlanIdentifiers.RECRUIT_GROWTH]: pricingPlanProductTypes.RECRUIT,
  [pricingPlanIdentifiers.RECRUIT_STRATEGIC]: pricingPlanProductTypes.RECRUIT,
};

// This custom plugin enhances our implementation of a Chart.js doughnut chart as a circular progressbar
// By default, Chart.js does not provide a way to visually transition from one data point to the next
// To achieve this effect, we use this custom plugin to draw a connecting circle between
// the primary and secondary data points in each dataset, immediately after the chart is drawn.
export const circularProgressOverlappingSegmentsPlugIn = {
  id: 'overlappingSegments',
  afterDatasetsDraw(chart: Chart) {
    const { ctx, data } = chart;

    const { x } = chart.getDatasetMeta(0).data[0];
    const { y } = chart.getDatasetMeta(0).data[0];
    const angle = Math.PI / 180;

    const length = data.labels ? data.labels.length - 1 : 1;

    chart.getDatasetMeta(0).data.forEach((_value: any, index: number) => {
      if (index < length && chart.getDataVisibility(index)) {
        const metaDataSet = chart.getDatasetMeta(0).data[index];

        const { innerRadius, outerRadius, endAngle } = metaDataSet as typeof metaDataSet &
          DoughnutRadiusProps;

        const radius = (outerRadius - innerRadius) / 2;

        const coordinates = [];

        for (let i = -0.04; i <= 0.04; i += 0.01) {
          coordinates.push({
            x: (innerRadius + radius) * Math.cos(endAngle + Math.PI + i),
            y: (innerRadius + radius) * Math.sin(endAngle + i),
          });
        }

        ctx.save();

        const bgColors = data.datasets[0].backgroundColor as string[];
        ctx.fillStyle = bgColors[index];

        ctx.translate(x, y);
        ctx.beginPath();

        coordinates.forEach((coordinate) => {
          ctx.arc(-coordinate.x, coordinate.y, radius + 0.9, 0, angle * 360, false);
        });

        ctx.fill();
        ctx.restore();
      }
    });
  },
};

export const pricingBandAssets: Record<
  PricingPlanProductType,
  {
    icon: ElementType;
    title: string;
    abbreviation: string | null;
    tone: BoxedIconTone;
  }
> = {
  eor: {
    icon: IconV2DuotoneUser,
    title: 'Employer of Record',
    abbreviation: 'EOR',
    tone: 'brand',
  },
  contractor: {
    icon: IconV2DuotoneUsers,
    title: 'Contractor Management',
    abbreviation: null,
    tone: 'purple',
  },
  global_payroll: {
    icon: IconV2DuotoneGlobe,
    title: 'Remote Payroll',
    abbreviation: null,
    tone: 'orange',
  },
  remote_talent: {
    icon: IconV2DuotoneBriefcase,
    title: 'Recruit Free Trial',
    abbreviation: null,
    tone: 'greenLight',
  },
  recruit: {
    icon: IconV2DuotoneBriefcase,
    title: 'Recruit',
    abbreviation: null,
    tone: 'greenLight',
  },
};

export const newPricingPlanColors = {
  eor: 'brand',
  contractor: 'purple',
  global_payroll: 'orange',
  remote_talent: 'greenLight',
  recruit: 'greenLight',
} as const;

export const creditTermsInfo = [
  {
    title:
      'Upon purchasing an EOR credit plan, you buy a specific number of credits, each covering one month of employment services for one employee.',
  },
  {
    title:
      'The initial purchase defines the total credits available for the plan duration; additional credits cannot be added mid-plan.',
  },
  {
    title:
      'Credits are used as soon as employment services are provided, decreasing the total credits available.',
  },
  {
    title:
      'Plans activate when: (1) a new employee is active on the platform; or (2) an existing employee has been moved under the plan. If neither of those has occurred, plans activate six months after purchase.',
  },
  {
    title:
      'You will be invoiced for the first billing cycle immediately upon plan purchase; thereafter you will be invoiced on each successive billing cycle.',
    instructions: [
      'Ex. For quarterly billing, you will be invoiced on plan purchase, and then approximately every 90 days until all credits are invoiced.',
    ],
  },
  {
    title:
      'If credits are used in excess of those paid for a billing cycle, Remote may issue an invoice for the amounts due on the next billing cycle.',
  },
  {
    title:
      'At the end of the plan, up to 25% of unused credits may roll-over upon agreement to a new credit plan.',
  },
  {
    title:
      'If a credit plan is detailed as “low-income,” it only applies to employees based in India and the Philippines.',
  },
];

export const eorPlanProductTypeIdentifiers = {
  CORE: 'core',
  LOW_INCOME: 'low-income',
} as const;

export const eorProductTypeIdentifiersToProductPriceTitle = {
  [eorPlanProductTypeIdentifiers.CORE]: 'EOR Core',
  [eorPlanProductTypeIdentifiers.LOW_INCOME]: 'EOR Non-Core',
} as const;

export const eorPlanProductIdentifiers: Record<EORFrequency, string> = {
  annual: 'urn:remotecom:resource:product:eor:annual-plan:standard:annual',
  monthly: 'urn:remotecom:resource:product:eor:standard:monthly',
};

export const monthlyPlanTypeIdentifiers = {
  FLAT: 'flat',
  TIERED: 'tier',
} as const;

export const eorMonthlyBounds = [
  {
    lowerBound: 0,
    upperBound: 4,
  },
  {
    lowerBound: 5,
    upperBound: 9,
  },
  {
    lowerBound: 10,
    upperBound: 19,
  },
  {
    lowerBound: 20,
    upperBound: 49,
  },
  {
    lowerBound: 50,
    upperBound: null,
  },
];

export const contractorMonthlyBounds = [
  {
    lowerBound: 0,
    upperBound: 9,
  },
  {
    lowerBound: 10,
    upperBound: 49,
  },
  {
    lowerBound: 50,
    upperBound: 99,
  },
  {
    lowerBound: 100,
    upperBound: 499,
  },
  {
    lowerBound: 500,
    upperBound: 999,
  },
  {
    lowerBound: 1000,
    upperBound: null,
  },
];

/** @deprecated - use pricingRevisionsNew instead
 * More revisions might be added in the future so this data model is not future-proof
 * Will be removed once all usage is removed
 */
export const pricingRevisions = {
  LEGACY: '2022',
  NEW: '2024',
} as const;

export const pricingRevisionsNew = [
  {
    label: '2024',
    value: '2024',
    isLatest: true,
  },
  {
    label: '2022',
    value: '2022',
    isLatest: false,
  },
] as const;

export const pricingRevisionValues = pricingRevisionsNew.reduce((acc, curr) => {
  return {
    ...acc,
    [curr.label]: curr.value,
  };
}, {}) as Record<
  (typeof pricingRevisionsNew)[number]['label'],
  (typeof pricingRevisionsNew)[number]['value']
>;

export const pricingPlansGroupTypes = {
  LEGAL_ENTITY: 'legal-entity',
  PRODUCT: 'product',
} as const;

export const groupedLegalEntityPlanTypesLabels: Record<PricingPlanProductType, string> = {
  eor: 'eor',
  contractor: 'contractors',
  global_payroll: 'payroll',
  remote_talent: 'talent',
  recruit: 'recruit',
};

export const recruitPlanTypes = {
  FREE_TRIAL: 'free-trial',
  ESSENTIAL: 'essential',
  GROWTH: 'growth',
  STRATEGIC: 'strategic',
} as const;

export const MAX_TALENT_INSIGHTS = 20;
