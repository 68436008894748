import styled, { css } from 'styled-components';

import { sharedTransition } from '../../../core/animations';
import { Button, ButtonInline } from '../../../core/button';
import { TextEllipsisCSS } from '../../../core/text/Text.styled';
import { Box } from '../../../foundations/layout/Box';
import { Stack } from '../../../foundations/layout/Stack';
import { IconV2OutlineClosePanel } from '../../../icons/build/IconV2OutlineClosePanel';

export const DatePickerHeaderNavigationWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;

  height: var(--date-picker-header-height);
  padding-inline: var(--date-picker-padding-size);
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
`;

export const DatePickerHeaderDateNavigation = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const DateInput = css`
  ${({ theme }) => css`
    ${theme.typography.baseMedium};
    color: ${theme.colors.grey[600]};

    :hover,
    :focus-within {
      color: ${theme.colors.brand[600]};
    }
  `}

  border: none;
`;

export const DatePickerHeaderMonthLabel = styled.label`
  position: relative;

  ${DateInput}

  @media screen and (max-width: 460px) {
    ${TextEllipsisCSS}
    width: 50px;
  }
`;

export const DatePickerHeaderMonthSelect = styled.select<{ $chars: number }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  cursor: pointer;
  opacity: 0;
`;

export const DatePickerHeaderYearInput = styled.input`
  ${DateInput}
  width: 4.2rem;
`;

export const DatePickerHeaderArrowNavigation = styled(Stack)`
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
`;

const MonthButton = styled(Button).attrs({ variant: 'raw' })`
  ${({ theme }) => css`
    box-sizing: border-box;
    background-color: transparent;
    border-radius: ${theme.borderRadius.full};
    border: 0;
    display: grid;
    overflow: hidden;
    padding: 0;
    place-items: center;
    transition: background-color 100ms ease-in-out;
    height: 32px;
    width: 32px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: ${theme.colors.irisBlue_15};
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }

    &:disabled {
      box-shadow: none;

      &:hover,
      &:focus {
        background-color: transparent;
        box-shadow: none;
      }
    }
  `}
`;

export const DatePickerHeaderMonthNavigationButton = styled(MonthButton)`
  height: 26px;
  width: 26px;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.grey[300]};
    color: ${theme.colors.grey[600]};
  `}
`;

export const DatePickerHeaderIcon = styled.svg`
  ${({ theme }) => css`
    fill: ${theme.colors.bayoux};
    height: 16px;

    &:disabled {
      fill: ${theme.colors.bayoux_30};
    }
  `}
`;

export const DatePickerHeaderLinkButton = styled(ButtonInline)`
  display: flex;
  gap: 5px;

  :hover {
    text-decoration: none;
  }
`;

export const DatePickerHeaderIconPanel = styled(IconV2OutlineClosePanel)<{ $active: boolean }>`
  ${sharedTransition('transform')}
  transform: scaleX(${({ $active }) => ($active ? -1 : 1)});
`;

export const DatePickerHeaderLoadingCalendarWeek = styled(Box)`
  width: 321px;
  position: absolute;
  height: 274px;
  top: 128px;
  left: 30px;
  background-color: #d9d9d9b0;
  z-index: 99;
  border-radius: 20px;
  animation: pulsate 1s ease-in-out infinite;
  cursor: not-allowed;
  @keyframes pulsate {
    0% {
      background-color: #d9d9d9b0;
    }
    50% {
      background-color: #cacacab0;
    }
    100% {
      background-color: #d9d9d9b0;
    }
  }
`;
