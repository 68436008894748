import { Stack, useFormGroupContext, Text } from '@remote-com/norma';
import dynamic from 'next/dynamic';

import { Button } from '@/src/components/Button';

const AiCollectFeedback = dynamic(() => import('@/src/components/AiCollectFeedback'), {
  ssr: false,
  loading: () => {
    return (
      <Text variant="xs" color="grey.500" mr="auto">
        Loading feedback buttons...
      </Text>
    );
  },
});

type Props = {
  onCancel: () => void;
  onTryAgain: () => void;
  onUseResult: () => void;
};

const AIFormAssistantResultActions = ({
  onCancel,
  onTryAgain,
  onUseResult,
}: Props): JSX.Element => {
  const { id } = useFormGroupContext();

  return (
    <Stack direction="row" gap={3} alignItems="center">
      <AiCollectFeedback
        contentSlug={`remote-ai-form-assistant-${id}`}
        contentType="remote-ai-form-assistant"
        variant="icons"
        question="Was the content generated by the AI Form Assistant helpful?"
        reasons={[
          'Not relevant to my needs',
          'Not clear or understandable',
          'Inaccurate information',
          'Incomplete or missing data',
        ]}
      />
      <Button size="xs" variant="outline" tone="secondary" onClick={onCancel} ml="auto">
        Cancel
      </Button>
      <Button size="xs" variant="outline" tone="secondary" onClick={onTryAgain}>
        Try again
      </Button>
      <Button size="xs" onClick={onUseResult}>
        Use this
      </Button>
    </Stack>
  );
};

export default AIFormAssistantResultActions;
