// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineBookmark = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M11.97 1.25h.06c1.38 0 2.45 0 3.32.07.88.07 1.6.22 2.26.56a5.75 5.75 0 0 1 2.51 2.51c.34.66.49 1.38.56 2.26.07.87.07 1.94.07 3.32V22a.75.75 0 0 1-1.09.67l-2.27-1.14c-2.14-1.07-3.08-1.53-4.05-1.71a7.25 7.25 0 0 0-2.68 0c-.97.18-1.91.64-4.05 1.71l-2.27 1.14A.75.75 0 0 1 3.25 22V9.97c0-1.38 0-2.45.07-3.32.07-.88.22-1.6.56-2.26a5.75 5.75 0 0 1 2.51-2.51 5.92 5.92 0 0 1 2.26-.56c.87-.07 1.94-.07 3.32-.07Zm-3.2 1.57c-.78.06-1.29.18-1.7.4-.8.4-1.45 1.05-1.86 1.85-.2.41-.33.92-.4 1.7-.06.8-.06 1.82-.06 3.23v10.79l1.3-.66c2-1 3.13-1.56 4.34-1.79 1.06-.2 2.16-.2 3.22 0 1.2.23 2.35.8 4.33 1.8l1.31.65V10c0-1.41 0-2.43-.07-3.22a4.47 4.47 0 0 0-.4-1.7 4.25 4.25 0 0 0-1.85-1.87 4.47 4.47 0 0 0-1.7-.4c-.8-.06-1.82-.06-3.23-.06s-2.43 0-3.22.07Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineBookmark);
export { ForwardRef as IconV2OutlineBookmark };