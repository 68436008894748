import { Box } from '@remote-com/norma';
import styled from 'styled-components';

export const ChartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ChartRow = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ChartWrapper = styled(Box)`
  flex-grow: 1;
  min-height: 300px;
`;
