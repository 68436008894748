import { useField } from 'formik';

import { CheckboxCard } from '@/src/components/Ui/Form';
import type { CheckboxCardProps } from '@/src/components/Ui/Form/inputs/CheckboxCard/types';

export const CheckboxCardField = ({ name, value, ...props }: CheckboxCardProps) => {
  const [field, { error, touched }] = useField({ name, type: 'checkbox', value });
  const hasError = !!error && touched;

  return <CheckboxCard {...field} hasError={hasError} {...props} />;
};
