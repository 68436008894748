// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneRocket = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M12.28 3.72 6.5 9.5c-1.1 1.1-1.64 1.64-1.93 2.22a4 4 0 0 0 0 3.56c.3.58.84 1.13 1.93 2.22 1.09 1.09 1.64 1.64 2.22 1.93a4 4 0 0 0 3.56 0c.58-.3 1.13-.84 2.22-1.93l5.8-5.8c.63-.63.94-.94 1.18-1.3a4 4 0 0 0 .47-1.05c.12-.42.15-.86.2-1.75l.05-.68c.13-1.87.19-2.8-.15-3.51a3 3 0 0 0-1.4-1.42c-.7-.34-1.64-.28-3.52-.17l-.7.04c-.9.05-1.35.08-1.77.2a4 4 0 0 0-1.06.47c-.37.24-.68.55-1.32 1.2Z" /><path fillRule="evenodd" clipRule="evenodd" d="M1.96 9.15c-.56-.37-.83-.55-.95-.8a1 1 0 0 1-.07-.69c.07-.26.3-.5.78-.97l.5-.5c.85-.85 1.28-1.28 1.77-1.55a4 4 0 0 1 1.94-.5c.56 0 1.14.17 2.3.5.37.11.56.16.65.27.12.12.16.3.12.45-.03.14-.17.28-.44.55l-4.13 4.13c-.16.16-.24.24-.34.28a.5.5 0 0 1-.27.03c-.1-.02-.2-.09-.4-.22l-1.46-.98Zm12.4 10.9c-.12-.2-.19-.3-.2-.4a.5.5 0 0 1 .02-.27c.04-.1.12-.18.28-.34l4.13-4.13c.27-.27.41-.41.55-.44a.5.5 0 0 1 .45.11c.1.1.16.29.27.66.33 1.16.5 1.74.5 2.3a4 4 0 0 1-.5 1.94c-.27.5-.7.92-1.55 1.77l-.5.5c-.47.48-.71.71-.97.78a1 1 0 0 1-.7-.07c-.24-.12-.42-.4-.8-.95l-.97-1.46ZM17 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-7.47 6.53a.75.75 0 0 0-1.06-1.06l-2.5 2.5a.75.75 0 0 0 1.06 1.06l2.5-2.5Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneRocket);
export { ForwardRef as IconV2DuotoneRocket };