/**
 * Copy-paste from react-to-text - https://github.com/lhansford/react-to-text/blob/main/src/index.tsx
 * Why: Because the package depends on react@^18.x, and we are at 17
 *      even though it worked fine locally. Bug opened https://github.com/lhansford/react-to-text/issues/294
 */

import type React from 'react';
import { isValidElement } from 'react';

function reactToText(node: React.ReactNode | object, resolvers?: any): string {
  if (typeof node === 'string' || typeof node === 'number' || typeof node === 'boolean') {
    return node.toString();
  }
  if (!node) {
    return '';
  }
  if (Array.isArray(node)) {
    return node.map((entry) => reactToText(entry, resolvers)).join('');
  }

  const [nodeType, nodeProps] = isValidElement(node) ? [node.type, node.props] : [null, null];
  // check if custom resolver is available
  if (nodeType && resolvers?.has(nodeType)) {
    const resolver = resolvers.get(nodeType)!;
    return resolver(nodeProps);
  }

  // Because ReactNode includes {} in its union we need to jump through a few hoops.
  const props: { children?: React.ReactNode } = (node as any).props ? (node as any).props : {};

  if (!props || !props.children) {
    return '';
  }

  return reactToText(props.children, resolvers);
}

export default reactToText;
