import isFinite from 'lodash/isFinite';
import isNull from 'lodash/isNull';
import React, { forwardRef } from 'react';
import type { ReactNode, MouseEvent } from 'react';

import { StyledTab, StyledTabCount } from './Tab.styled';

export interface TabProps {
  selected?: boolean;
  onClick?: (ev: MouseEvent<HTMLButtonElement>, tabValue: string | number) => void;
  value?: string | number;
  children: ReactNode | ReactNode[];
  count?: string | number;
  asAnchor?: boolean;
}

export const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ asAnchor, selected, onClick, value, children, count, ...props }, ref) => {
    const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(ev, value || '');
      }
    };
    const tabProps = {
      asAnchor,
      ref,
      selected,
      ...props,
      onClick: handleClick,
    };
    const showCount = isFinite(Number(count)) && !isNull(count);
    return (
      <StyledTab {...tabProps} data-tab={value}>
        {children}
        {showCount && <StyledTabCount>{` (${count})`}</StyledTabCount>}
      </StyledTab>
    );
  }
);
