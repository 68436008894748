import { FeedbackMessage, Box } from '@remote-com/norma';
import type { ReactNode } from 'react';

import { BlockErrorBoundary } from '@/src/components/BlockErrorBoundary';
import RemoteLogoLoader from '@/src/components/Ui/Loader/RemoteLoader';
import { getExceptionInfo } from '@/src/helpers/general';
import type { ComponentProps } from '@/types';

export interface SkeletonBoxProps extends ComponentProps<typeof Box> {
  /** When true, it renders the RemoteLoader */
  isLoading?: boolean;
  /** Error object, if an error was thrown */
  error?: unknown;
  /** Content to render if everything is ready */
  children: ReactNode;
}

export function SkeletonBox({ isLoading, error, children, ...restProps }: SkeletonBoxProps) {
  if (isLoading) {
    return (
      <Box flexGrow="1" {...restProps}>
        <RemoteLogoLoader />
      </Box>
    );
  }

  if (error) {
    // Let's cast this to avoid TS errors. Ideally we would use something like zod to assert this
    const trustedError = error as { response?: unknown; message?: string };
    // Support server Errors and new Errors
    const finalError = trustedError.response ? getExceptionInfo(error) : trustedError;
    return (
      <FeedbackMessage variant="error">
        {finalError.message || 'Something went wrong!'}
      </FeedbackMessage>
    );
  }

  if (!children) {
    return null;
  }

  return <BlockErrorBoundary>{children}</BlockErrorBoundary>;
}
