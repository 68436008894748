import React, { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

const FiltersInputSizeContext = createContext<boolean | undefined>(undefined);

type FiltersInputSizeProviderProps = {
  children: ReactNode;
};

/**
 * Aux hook that allows to determine whether the caller of this hook is rendered inside a table filter or not.
 * @returns {boolean}
 */
function useIsInsideTableFilter(): boolean {
  const isInsideTableFilter = useContext(FiltersInputSizeContext);
  return isInsideTableFilter === undefined ? false : isInsideTableFilter;
}

/**
 * Sets the filter popover rendering context.
 * This is needed because the inputs rendered inside a table filter should be small
 * and have the label placed outside the container.
 */
const FiltersInputSizeProvider = ({ children }: FiltersInputSizeProviderProps) => {
  const areInputsInsideAFilterPopover = true;

  return (
    <FiltersInputSizeContext.Provider value={areInputsInsideAFilterPopover}>
      {children}
    </FiltersInputSizeContext.Provider>
  );
};

export { FiltersInputSizeProvider, useIsInsideTableFilter };
