import type { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { BoxedIcon } from '../../core/boxed-icon';
import { Text, TextEllipsis } from '../../core/text';
import { Box } from '../../foundations/layout/Box';
import { Stack } from '../../foundations/layout/Stack';
import { IconFolderCheck } from '../../icons/build/IconFolderCheck';
import { IconFolderOpen } from '../../icons/build/IconFolderOpen';
import { IconFolderUpload } from '../../icons/build/IconFolderUpload';
import { IconProgressCircle25 } from '../../icons/build/IconProgressCircle25';
import { AsideBadge } from '../form-group';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled(Box)`
  max-width: 530px;
`;

const FileList = styled(Stack).attrs({
  direction: 'column',
  forwardedAs: 'ul',
  mt: 3,
})`
  padding: 0;
  list-style: none;
`;

const FileListEntryIcon = styled(Box).attrs({ forwardedAs: 'span' })`
  box-sizing: border-box;
  position: relative;
  top: 3px;
  padding: 0;
`;

const FileName = styled(Box).attrs({
  flex: '1 0 auto',
})`
  word-break: break-word;
  flex: 1;
`;

type ActionableAreaProps = {
  /**
   * @todo This does not seem to be used.
   * We only define it to keep the logic during Norma migration.
   * Consider removing it.
   */
  $hasErrors?: boolean;
};

const ActionableArea = styled(Stack).attrs({
  gap: 3,
  direction: 'row',
  py: 2,
})<ActionableAreaProps>`
  width: 100%;
  align-items: center;

  .modifiable {
    display: flex;

    > div {
      flex: 1;
    }

    div:first-child {
      margin-right: 6px;
    }
  }
`;

type FileListEntryProps = {
  isLoading?: boolean;
  noInteractions?: boolean;
  /**
   * @todo This does not seem to be used.
   * We only define it to keep the logic during Norma migration.
   * Consider removing it.
   */
  $hasErrors?: boolean;
};

const FileListEntry = styled(Stack).attrs({
  forwardedAs: 'li',
  direction: 'row',
  justifyContent: 'space-between',
  gap: 3,
  px: 3,
  py: 2,
})<FileListEntryProps>`
  position: relative;
  margin: 0;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.typography.sm};
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};

  :last-child {
    border-bottom: none;
  }

  ${({ noInteractions }) =>
    noInteractions &&
    css`
      border: none;
    `}
`;

const FileSizeWrapper = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: '70px',
  ml: 4,
})``;

const FileError = styled(Text).attrs({
  variant: 'sm',
  color: 'negative.foreground',
})`
  width: auto;
  flex-shrink: 0;
`;

const EllipsisLabel = styled(TextEllipsis)`
  display: block;
  text-align: left;
`;

const DropzoneRoot = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[5]}px;
  margin-left: auto;
  margin-right: auto;
`;

type DropzonActiveStyleProps = {
  $hasErrors: boolean;
};

const DropzoneActiveStyle = css<DropzonActiveStyleProps>`
  border-color: ${({ $hasErrors, theme }) =>
    $hasErrors ? theme.colors.red : theme.colors.primary};
  outline: none;
`;

type DropzoneFocusStyleProps = {
  $hasErrors: boolean;
};

const DropzoneFocusStyle = css<DropzoneFocusStyleProps>`
  box-shadow: ${({ $hasErrors, theme }) =>
    $hasErrors
      ? `0px 0px 0px ${theme.space[2]}px rgba(255, 0, 0, 0.1)`
      : `0px 0px 0px ${theme.space[2]}px rgba(0, 97, 255, 0.1)`};
`;

type DropAreaProps = {
  $hasErrors: boolean;
  $size: 'large' | 'small';
  isDragging: boolean;
};

const DropArea = styled(Stack).attrs<DropAreaProps>((props) => {
  // Heads up: Don't covert this function to an arrow,
  // as it will break the styled-components syntax highlight
  const { $size } = props;
  return {
    forwardedAs: 'label',
    gap: 5,
    direction: $size === 'large' ? 'column' : 'row',
  };
})<DropAreaProps>`
  background-color: ${({ $hasErrors, theme }) =>
    $hasErrors ? theme.colors.red[50] : theme.colors.blank};
  border-radius: ${({ theme }) => theme.space[5]}px;
  border: 1.5px dashed
    ${({ $hasErrors, theme }) => ($hasErrors ? theme.colors.red[400] : theme.colors.brand[400])};
  padding: ${({ theme, $size }) => ($size === 'large' ? theme.space[9] : theme.space[5])}px;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  transition-property: background-color border-color;
  cursor: pointer;
  position: relative;
  align-items: ${({ $size }) => ($size === 'large' ? 'center' : 'flex-start')};

  &:hover {
    ${DropzoneActiveStyle}
  }
  &:focus,
  &:focus-within {
    ${DropzoneFocusStyle}
  }
  ${({ isDragging }) =>
    isDragging &&
    css<DropAreaProps>`
      background-color: ${({ $hasErrors, theme }) =>
        $hasErrors ? theme.colors.red[50] : theme.colors.brand[50]};
    `}
`;

type DropAreaTitleGroupProps = {
  $size: 'large' | 'small';
};

const DropAreaTitleGroup = styled(Stack).attrs({
  gap: 2, // Don't put attrs on one line, it will break the syntax highlight
})<DropAreaTitleGroupProps>`
  ${({ $size }) =>
    $size === 'large'
      ? css`
          align-items: center;
          text-align: center;
        `
      : css`
          align-items: flex-start;
        `}
`;

const DropAreaTitle = styled.span`
  ${({ theme }) => theme.typography.sm};
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

const DropAreaSubtitle = styled.span`
  display: inline-block;
  ${({ theme }) => theme.typography.smMedium};
  color: ${({ theme }) => theme.colors.grey[900]};
`;

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  display: block !important; /** A11Y keyboard friendly - Override react-dropzone */
`;

const Label = styled(Text).attrs({
  forwardedAs: 'label',
  display: 'block',
  mb: 2,
  ml: '18px',
  variant: 'smMedium',
  color: 'grey.800',
})``;

const Description = styled(Text).attrs({
  display: 'block',
  mb: 3,
  ml: '18px',
  variant: 'xs',
  color: 'grey.600',
})``;

const StyledProgressCircle = styled(IconProgressCircle25)`
  animation: ${rotate} 1s linear infinite;
`;

const DropAreaImage = (props: {
  isDragging: boolean;
  isUploading: boolean;
  isStatusDone: boolean;
  hasErrors: boolean;
}): ReactElement => {
  const { isDragging, isUploading, isStatusDone, hasErrors } = props;
  if (hasErrors) {
    return <BoxedIcon Icon={IconFolderOpen} size="xl" tone="red" />;
  }
  if (isDragging) {
    return <BoxedIcon Icon={IconFolderUpload} size="xl" tone="brand" appearance="subtle" />;
  }
  if (isUploading) {
    return <BoxedIcon Icon={StyledProgressCircle} size="xl" tone="brand" />;
  }
  if (isStatusDone) {
    return <BoxedIcon Icon={IconFolderCheck} size="xl" tone="brand" />;
  }
  return <BoxedIcon Icon={IconFolderOpen} size="xl" tone="brand" />;
};

const ErrorMessage = styled(Text).attrs({
  forwardedAs: 'span',
  mr: 2,
  variant: 'xs',
  color: 'negative.foreground',
})``;

const StyledExtensionBadge = styled(AsideBadge)`
  width: max-content;
`;

/**
 * This export prevents polluting the global namespace.
 * The components here are named very generic (e.g., "Container"),
 * despite their specific use case.
 * They come from Employ, and were made at a time
 * we didn't have guidelines for naming components.
 * To avoid conflicts during the migration,
 * we keep them in a manual "namespace" here.
 *
 * @deprecated If you want to use something here,
 * consider helping by moving it to a more specific place.
 *
 * @todo Don't put everything here. Put them into where they are used
 */
export const InputFileStyled = {
  Container,
  FileList,
  FileListEntryIcon,
  FileName,
  ActionableArea,
  FileListEntry,
  FileSizeWrapper,
  FileError,
  EllipsisLabel,
  DropzoneRoot,
  DropArea,
  DropAreaTitleGroup,
  DropAreaTitle,
  DropAreaSubtitle,
  HiddenInput,
  Label,
  Description,
  DropAreaImage,
  ErrorMessage,
  StyledExtensionBadge,
};
