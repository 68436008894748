export function getFromConstObject<Key extends string | number | symbol, Value>(
  object: Record<Key, Value>,
  key: string
): Value | undefined;

export function getFromConstObject<Key extends string | number | symbol, Value>(
  object: Record<Key, Value>,
  key: string,
  fallback: Value
): Value;

/**
 *
 * This utility allows you to get a value from a constant object we so often use.
 *
 * With Typescript, we can't use arbitrary keys to index const objects, therefore
 * this little helper might be useful when we are dealing with arbitrary keys on const objects.
 *
 * If not passing a fallback, the returned object can be undefined. If a fallback is defined
 * the return value is always present.
 */

export function getFromConstObject<Key extends string | number | symbol, Value>(
  object: Record<Key, Value>,
  key: string,
  fallback?: Value
) {
  const result = object[key as Key];

  return result || fallback;
}
