import { Text } from '@remote-com/norma';
import styled from 'styled-components';

export const ReportsTableWrapper = styled.section`
  margin-top: ${({ theme }) => theme.space[8]}px;
`;

export const DateCellText = styled(Text)`
  font-weight: 500;
`;
