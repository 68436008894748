import { Stack, Box } from '../../layout';
import { Spinner } from '../spinner';

import { StyledIcon } from './MenuItem.styled';

export type MenuItemInternalProps = {
  isLoading?: boolean;
  Icon?: React.ElementType;
  Pill?: React.ReactNode;
  Toggle?: React.ReactNode;
};

export const MenuItemInternal = ({
  isLoading,
  Icon,
  Pill,
  Toggle,
  children,
}: React.PropsWithChildren<MenuItemInternalProps>) => (
  <Stack
    // Render this stack as <span /> so that it can be used inside of anchor links.
    as="span"
    direction="row"
    alignItems="center"
    width="100%"
    gap={4}
  >
    {isLoading && <Spinner />}
    {!isLoading && Icon && <StyledIcon data-testid="menu-item-icon" as={Icon} />}
    {children}
    {!isLoading && Pill && (
      <Box pl={3} ml="auto">
        {Pill}
      </Box>
    )}
    {!isLoading && <>{Toggle}</>}
  </Stack>
);
