export const NAVATTIC_EVENTS = {
  NAVATTIC_MODAL_CLOSE: 'navattic-modal-close',
  NAVATTIC_COMPLETE_FLOW: 'COMPLETE_FLOW',
  IS_NAVATTIC_EVENT: 'navattic:event',
};

export const NAVATTIC_PATH = 'https://remote.navattic.com';
// Navattic flow ids
export const NAVATTIC_EOR_EMPLOYER_FLOW_ID = 'a84n0fxy';
export const NAVATTIC_CM_EMPLOYER_FLOW_ID = 'e911h0tv7';
export const NAVATTIC_HRIS_EMPLOYER_FLOW_ID = 'la2x0y0q';
export const NAVATTIC_CONTRACTOR_ONBOARDING = 'du2c0ifn';
export const NAVATTIC_PAYMENT_METHODS = 'yx8j0otb';
export const NAVATTIC_GP_EMPLOYER_FLOW_ID = '8co04rr';
export const NAVATTIC_HRIS_MOBILE_APP_FLOW_ID = 'kb5u04o9';
export const NAVATTIC_TIME_OFF_FLOW_ID = 'umk20lf1';
export const NAVATTIC_EOR_EMPLOYEE_FLOW_ID = '7wm80bo8';
export const NAVATTIC_DIRECT_EMPLOYEE_FLOW_ID = 'wbxo0l2r';
export const NAVATTIC_GLOBAL_PAYROLL_EMPLOYEE_FLOW_ID = 'yq5p80hhc';
export const NAVATTIC_MODAL_STORED_KEY = 'navattic-modal';
export const NAVATTIC_FREELANCER_HUB_FLOW_ID = '485s0gul';
export const NAVATTIC_DIRECT_BULK = '3t6h02rt';
export const NAVATTIC_EMPLOYER_DASHBOARD = 'sa1g0ph0';
export const NAVATTIC_ROLES = '3y1os0b6e';
export const NAVATTIC_EOR_TOUR = '2y5j0v71';
export const NAVATTIC_CONTRACTOR_MANAGEMENT = 'nd40jz4';
export const NAVATTIC_EMPLOYEE_EXPERIENCE = 'v2tx0yzb';
export const NAVATTIC_TIME_TRACKING_INVOICE_TOUR = 'lqbw0o93';
export const NAVATTIC_REMOTE_MOBILE_APP_PROMO = 'hb40oxi';
export const NAVATTIC_TALENT_INSIGHTS_DEMO = 'yg40cva';
export const NAVATTIC_JOBS_NEW_USER_DEMO = 'py140dkg';
export const NAVATTIC_JOBS_CURRENT_USER_DEMO = 'pz2n01ev';

export type NavatticId =
  | typeof NAVATTIC_EOR_EMPLOYER_FLOW_ID
  | typeof NAVATTIC_CM_EMPLOYER_FLOW_ID
  | typeof NAVATTIC_HRIS_EMPLOYER_FLOW_ID
  | typeof NAVATTIC_CONTRACTOR_ONBOARDING
  | typeof NAVATTIC_PAYMENT_METHODS
  | typeof NAVATTIC_GP_EMPLOYER_FLOW_ID
  | typeof NAVATTIC_HRIS_MOBILE_APP_FLOW_ID
  | typeof NAVATTIC_TIME_OFF_FLOW_ID
  | typeof NAVATTIC_EOR_EMPLOYEE_FLOW_ID
  | typeof NAVATTIC_DIRECT_EMPLOYEE_FLOW_ID
  | typeof NAVATTIC_GLOBAL_PAYROLL_EMPLOYEE_FLOW_ID
  | typeof NAVATTIC_MODAL_STORED_KEY
  | typeof NAVATTIC_FREELANCER_HUB_FLOW_ID
  | typeof NAVATTIC_DIRECT_BULK
  | typeof NAVATTIC_EMPLOYER_DASHBOARD
  | typeof NAVATTIC_ROLES
  | typeof NAVATTIC_EOR_TOUR
  | typeof NAVATTIC_CONTRACTOR_MANAGEMENT
  | typeof NAVATTIC_EMPLOYEE_EXPERIENCE
  | typeof NAVATTIC_TIME_TRACKING_INVOICE_TOUR
  | typeof NAVATTIC_REMOTE_MOBILE_APP_PROMO
  | typeof NAVATTIC_JOBS_CURRENT_USER_DEMO
  | typeof NAVATTIC_JOBS_NEW_USER_DEMO;

// Navattic local storage keys
export const SHOW_NAVATTIC_TI_DEMO_KEY = 'show-navattic-ti-demo';
export const SHOW_NAVATTIC_JOBS_DEMO_KEY = 'show-navattic-jobs-demo';
