import { Box, SROnly, Text } from '@remote-com/norma';

import { displayDayTime } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/displayDayTime';
import { displayWorkingHours } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/displayWorkingHours';
import { parseDayTime } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/parseDayTime';
import {
  calculateWorkingHours,
  calculateWorkingHoursSimplified,
} from '@/src/components/Ui/Form/WorkWeekScheduleTableField/utils/calculateWorkingHours';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

import { fieldToLabel as defaultFieldToLabel, fieldsSimplified } from './utils/constants';
import {
  Description,
  FieldError,
  FieldSet,
  GridCell,
  GridHeaderCell,
  GridStyled,
  InputStyled,
  Legend,
} from './WorkWeekScheduleTable.styled';

const actionTypes = {
  START_TIME_CHANGED: 'START_TIME_CHANGED',
  END_TIME_CHANGED: 'END_TIME_CHANGED',
  LUNCH_START_TIME_CHANGED: 'LUNCH_START_TIME_CHANGED',
  LUNCH_END_TIME_CHANGED: 'LUNCH_END_TIME_CHANGED',
  START_TIME_BLURRED: 'START_TIME_BLURRED',
  END_TIME_BLURRED: 'END_TIME_BLURRED',
  LUNCH_START_TIME_BLURRED: 'LUNCH_START_TIME_BLURRED',
  LUNCH_END_TIME_BLURRED: 'LUNCH_END_TIME_BLURRED',
};

const actionTypeToField = {
  [actionTypes.START_TIME_CHANGED]: 'startTime',
  [actionTypes.END_TIME_CHANGED]: 'endTime',
  [actionTypes.LUNCH_START_TIME_CHANGED]: 'lunchStartTime',
  [actionTypes.LUNCH_END_TIME_CHANGED]: 'lunchEndTime',
  [actionTypes.START_TIME_BLURRED]: 'startTime',
  [actionTypes.END_TIME_BLURRED]: 'endTime',
  [actionTypes.LUNCH_START_TIME_BLURRED]: 'lunchStartTime',
  [actionTypes.LUNCH_END_TIME_BLURRED]: 'lunchEndTime',
};

const actionTypesSimplified = {
  START_TIME_CHANGED: 'START_TIME_CHANGED',
  END_TIME_CHANGED: 'END_TIME_CHANGED',
  START_TIME_BLURRED: 'START_TIME_BLURRED',
  END_TIME_BLURRED: 'END_TIME_BLURRED',
};

const actionTypeToFieldSimplified = {
  [actionTypesSimplified.START_TIME_CHANGED]: 'startTime',
  [actionTypesSimplified.END_TIME_CHANGED]: 'endTime',
  [actionTypesSimplified.START_TIME_BLURRED]: 'startTime',
  [actionTypesSimplified.END_TIME_BLURRED]: 'endTime',
};

function getErrorDescription(error, fieldToLabel) {
  if (typeof error === 'string') {
    return error;
  }

  const fieldsWithError = Object.keys(error);

  return `Please fill the ${getSingularPluralUnit(
    fieldsWithError.length,
    'field',
    'fields',
    true,
    false
  )} ${fieldsWithError.map((fieldName) => `'${fieldToLabel[fieldName]}'`).join(', ')}`;
}

const defaultHour = '0 hours';

const WorkingHours = class {
  constructor() {
    this.totalWorkHours = defaultHour;
  }

  workinghours(workingHours) {
    this.totalWorkHours = workingHours ? displayWorkingHours(workingHours) : defaultHour;
    return this.totalWorkHours;
  }
};

export const workingHoursClass = new WorkingHours();

function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.START_TIME_CHANGED:
    case actionTypes.END_TIME_CHANGED:
    case actionTypes.LUNCH_START_TIME_CHANGED:
    case actionTypes.LUNCH_END_TIME_CHANGED:
      return {
        ...state,
        [actionTypeToField[action.type]]: {
          ...state[actionTypeToField[action.type]],
          value: action.payload,
        },
      };
    case actionTypes.START_TIME_BLURRED:
    case actionTypes.END_TIME_BLURRED:
    case actionTypes.LUNCH_START_TIME_BLURRED:
    case actionTypes.LUNCH_END_TIME_BLURRED: {
      const inputValue = state[actionTypeToField[action.type]].value.trim();
      const parsedValue = inputValue ? parseDayTime(inputValue) : null;

      return {
        ...state,
        [actionTypeToField[action.type]]: {
          value: parsedValue ? displayDayTime(parsedValue) : '',
          parsed: parsedValue,
        },
      };
    }
  }

  return state;
}

function reducerSimplified(state = {}, action) {
  switch (action.type) {
    case actionTypesSimplified.START_TIME_CHANGED:
    case actionTypesSimplified.END_TIME_CHANGED:
      return {
        ...state,
        [actionTypeToFieldSimplified[action.type]]: {
          ...state[actionTypeToFieldSimplified[action.type]],
          value: action.payload,
        },
      };
    case actionTypesSimplified.START_TIME_BLURRED:
    case actionTypesSimplified.END_TIME_BLURRED: {
      const inputValue = state[actionTypeToFieldSimplified[action.type]].value.trim();
      const parsedValue = inputValue ? parseDayTime(inputValue) : null;

      return {
        ...state,
        [actionTypeToFieldSimplified[action.type]]: {
          value: parsedValue ? displayDayTime(parsedValue) : '',
          parsed: parsedValue,
        },
      };
    }
  }

  return state;
}

export function WorkWeekScheduleTable({ displayLunchTime = true, ...props }) {
  // const { displayLunchTime = true } = props;

  const fieldToLabel = displayLunchTime
    ? {
        ...defaultFieldToLabel,
        ...(props.customLabels ?? {}),
      }
    : { ...fieldsSimplified, ...(props.customLabels ?? {}) };

  function dispatch(action) {
    const nextValue = displayLunchTime
      ? reducer(props.value, action)
      : reducerSimplified(props.value, action);
    props.onChange(nextValue);
  }

  const hasError = props.touched && Boolean(props.error);

  const gridCellSharedProps = {
    $hasRowError: hasError,
  };

  const hasWorkingHours = displayLunchTime
    ? props.value.startTime?.parsed &&
      props.value.endTime?.parsed &&
      props.value.lunchStartTime?.parsed &&
      props.value.lunchEndTime?.parsed
    : props.value.startTime?.parsed && props.value.endTime?.parsed;

  const workingHours = displayLunchTime
    ? hasWorkingHours &&
      calculateWorkingHours(
        props.value.startTime.parsed,
        props.value.endTime.parsed,
        props.value.lunchStartTime.parsed,
        props.value.lunchEndTime.parsed
      )
    : hasWorkingHours &&
      calculateWorkingHoursSimplified(props.value.startTime.parsed, props.value.endTime.parsed);

  return (
    <FieldSet id={props.name}>
      <Box mb={16} display="flex" flexDirection="column" gridGap={1}>
        <Legend>{props.label}</Legend>
        <Text variant="sm" color="bayoux" as="p">
          Please describe times using the 24-hour time format.
        </Text>
      </Box>
      <GridStyled
        onBlurCapture={(evt) => {
          // Using capture phase to setTouched before triggering
          // change handler that occurs on blur events of begin / end
          // inputs.
          // currentTarget is the node with this handler attached
          // relatedTarget is the node about to receive focus
          if (
            evt.relatedTarget &&
            (evt.currentTarget === evt.relatedTarget ||
              evt.currentTarget.contains(evt.relatedTarget))
          ) {
            // Focus is still on or within the table
            return;
          }

          // Focus has left table
          props.setTouched(true);
        }}
        tabIndex={
          // This tabIndex={0} is important. It is used to keep focus within the
          // "table". Without this, focus easily escape which could result in
          // error state visibility changing rapidly.
          0
        }
        $hasError={hasError}
        $hasLunchTime={displayLunchTime}
      >
        <>
          <GridHeaderCell>{fieldToLabel.startTime}</GridHeaderCell>
          <GridHeaderCell $isToColumn />
          <GridHeaderCell>{fieldToLabel.endTime}</GridHeaderCell>
          {displayLunchTime && (
            <>
              <GridHeaderCell>{fieldToLabel.lunchStartTime}</GridHeaderCell>
              <GridHeaderCell $isToColumn />
              <GridHeaderCell>{fieldToLabel.lunchEndTime}</GridHeaderCell>
            </>
          )}
          <GridHeaderCell>Working hours</GridHeaderCell>
        </>
        <GridCell {...gridCellSharedProps}>
          <label htmlFor="start-time">
            <SROnly>{fieldToLabel.startTime}</SROnly>
            <InputStyled
              placeholder="00:00"
              required
              id="start-time"
              value={props.value.startTime.value}
              onChange={(evt) => {
                evt.stopPropagation();
                dispatch({
                  type: actionTypes.START_TIME_CHANGED,
                  payload: evt.target.value,
                });
              }}
              onBlur={() => dispatch({ type: actionTypes.START_TIME_BLURRED })}
              $hasError={props.touched && props.error?.startTime}
            />
          </label>
        </GridCell>
        <GridCell {...gridCellSharedProps} $isToColumn>
          to
        </GridCell>
        <GridCell {...gridCellSharedProps}>
          <label htmlFor="end-time">
            <SROnly>{fieldToLabel.endTime}</SROnly>
            <InputStyled
              placeholder="00:00"
              required
              id="end-time"
              value={props.value.endTime.value}
              onChange={(evt) => {
                evt.stopPropagation();
                dispatch({
                  type: actionTypes.END_TIME_CHANGED,
                  payload: evt.target.value,
                });
              }}
              onBlur={() => dispatch({ type: actionTypes.END_TIME_BLURRED })}
              $hasError={props.touched && props.error?.endTime}
            />
          </label>
        </GridCell>

        {displayLunchTime && (
          <>
            <GridCell {...gridCellSharedProps}>
              <label htmlFor="lunch-start-time">
                <SROnly>{fieldToLabel.lunchStartTime}</SROnly>
                <InputStyled
                  placeholder="00:00"
                  required
                  id="lunch-start-time"
                  value={props.value.lunchStartTime ? props.value.lunchStartTime.value : ''}
                  onChange={(evt) => {
                    evt.stopPropagation();
                    dispatch({
                      type: actionTypes.LUNCH_START_TIME_CHANGED,
                      payload: evt.target.value,
                    });
                  }}
                  onBlur={() => dispatch({ type: actionTypes.LUNCH_START_TIME_BLURRED })}
                  $hasError={props.touched && props.error?.lunchStartTime}
                />
              </label>
            </GridCell>
            <GridCell {...gridCellSharedProps} $isToColumn>
              to
            </GridCell>
            <GridCell {...gridCellSharedProps}>
              <label htmlFor="lunch-end-time">
                <SROnly>{fieldToLabel.lunchEndTime}</SROnly>
                <InputStyled
                  placeholder="00:00"
                  required
                  id="lunch-end-time"
                  value={props.value.lunchEndTime ? props.value.lunchEndTime.value : ''}
                  onChange={(evt) => {
                    evt.stopPropagation();
                    dispatch({
                      type: actionTypes.LUNCH_END_TIME_CHANGED,
                      payload: evt.target.value,
                    });
                  }}
                  onBlur={() => dispatch({ type: actionTypes.LUNCH_END_TIME_BLURRED })}
                  $hasError={props.touched && props.error?.lunchEndTime}
                />
              </label>
            </GridCell>
          </>
        )}

        <GridCell {...gridCellSharedProps}>
          {hasError ? (
            <FieldError>
              <Box display="flex" gridGap={2} alignItems="center">
                <span>Invalid</span>
              </Box>
            </FieldError>
          ) : (
            workingHoursClass.workinghours(workingHours)
          )}
        </GridCell>
      </GridStyled>
      <Description>
        {hasError && <FieldError>{getErrorDescription(props.error, fieldToLabel)}</FieldError>}
        {hasError && props.description && ' - '}
        {props.description}
      </Description>
    </FieldSet>
  );
}
