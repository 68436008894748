import type { GetResponse, UseGetOptions } from '@remote-com/data-layer';
import { useGet } from '@remote-com/data-layer';

export function useGetCompanyProfile(
  options?: UseGetOptions<
    '/boba/api/v1/employer/company-profiles',
    unknown,
    GetResponse<'/boba/api/v1/employer/company-profiles'>['data']
  >
) {
  return useGet('/boba/api/v1/employer/company-profiles', {
    options: {
      select: (response) => {
        return response.data;
      },
      ...options,
    },
  });
}
