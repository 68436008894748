import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';

import { useEditorTemplateActions } from '@/src/domains/contracts/contractTemplate/useEditorTemplateActions';
import {
  buildDocumentUpdateParams,
  buildTemplateUpdateValues,
} from '@/src/domains/contracts/helpers';
import { CONTRACT_EDITOR_TYPE } from '@/src/domains/contracts/shared/constants';
import { useSaveBeforeNavigation } from '@/src/domains/contracts/shared/hooks/useSaveBeforeNavigation';

function saveChangesByEditorType(toBeSaved, editorType, saveTemplate, saveDocument) {
  // Check if there are local changes to save
  if (toBeSaved === undefined || editorType === CONTRACT_EDITOR_TYPE.VIEWER) return;

  if (editorType === CONTRACT_EDITOR_TYPE.TEMPLATE) {
    saveTemplate();
  } else {
    saveDocument();
  }
}

export const useAutoSave = ({
  setHasError,
  setIsSaved,
  setIsSaving,
  isSaving,
  contractSlug,
  contractType,
  contractDocument,
  editorType,
  isBilingual,
  documentSmartFields,
}) => {
  const [editorStateBuffer, setEditorStateBuffer] = useState(undefined);
  const { update: updateTemplate } = useEditorTemplateActions();

  const handleBeginSaving = useCallback(() => {
    setIsSaving(true);
    setIsSaved(false);
  }, [setIsSaved, setIsSaving]);

  // Stable references used to check if editorStateBuffer contains changes
  const editorStateBufferRef = useRef(undefined);
  editorStateBufferRef.current = editorStateBuffer;

  const defaultAutoSaveOptions = useCallback(
    (options, previousEditorStateBuffer) => ({
      ...options,
      onSuccess: (...args) => {
        if (options.onSuccess) {
          options.onSuccess(...args);
        }

        setHasError(false);

        // If the user makes changes to the editor since the save began, this triggers
        // a second auto-save after the current one finishes. By not setting isSaved
        // to true if the editorStateBuffer is "dirty", we keep showing the "There
        // are unsaved changes" status until the second auto-save finishes (provided
        // that the user doesn't make any more changes in the meantime). We need to
        // access the ref since this runs before editorStateBuffer update. Same for
        // isSaving in onSettled.
        if (editorStateBufferRef.current === undefined) {
          setIsSaved(true);
        }
      },
      onError: (...args) => {
        if (options.onError) {
          options.onError(...args);
        }

        // Reset the editorStateBuffer to the last known state unless the user made more changes
        if (editorStateBufferRef.current === undefined && previousEditorStateBuffer) {
          setEditorStateBuffer(previousEditorStateBuffer);
        }

        setHasError(true);
        setIsSaved(false);
      },
      onSettled: () => {
        if (options.onSettled) {
          options.onSettled();
        }

        if (editorStateBufferRef.current === undefined) {
          setIsSaving(false);
        }
      },
    }),
    [setHasError, setIsSaved, setIsSaving]
  );

  const saveDocumentContent = useCallback(
    async (options = {}) => {
      if (editorStateBuffer !== undefined) {
        handleBeginSaving();
        setEditorStateBuffer(undefined);

        await contractDocument.update(
          contractType,
          buildDocumentUpdateParams({
            contentForSaving: editorStateBuffer,
            isBilingual,
            contractSlug,
          }),
          defaultAutoSaveOptions(options, editorStateBuffer)
        );
      }
    },
    [
      editorStateBuffer,
      handleBeginSaving,
      contractDocument,
      isBilingual,
      contractSlug,
      contractType,
      defaultAutoSaveOptions,
    ]
  );

  const saveTemplateContent = useCallback(
    (options = {}) => {
      if (editorStateBuffer !== undefined) {
        handleBeginSaving();
        setEditorStateBuffer(undefined);

        const values = buildTemplateUpdateValues({
          contractTemplateSlug: contractSlug,
          contentForSaving: editorStateBuffer,
          documentSmartFields,
          isBilingual,
        });

        updateTemplate(contractType, values, defaultAutoSaveOptions(options, editorStateBuffer));
      }
    },
    [
      editorStateBuffer,
      handleBeginSaving,
      updateTemplate,
      documentSmartFields,
      isBilingual,
      contractSlug,
      contractType,
      defaultAutoSaveOptions,
    ]
  );

  const autoSaveTemplate = useMemo(
    () => debounce(saveTemplateContent, 3000),
    [saveTemplateContent]
  );

  const autoSaveDocument = useMemo(
    () => debounce(saveDocumentContent, 3000),
    [saveDocumentContent]
  );

  // Clean up autoSaveTemplate and autoSaveDocument on unmount to avoid leaks
  useEffect(() => {
    const prevAutoSaveTemplate = autoSaveTemplate;
    const prevAutoSaveDocument = autoSaveDocument;
    return () => {
      prevAutoSaveTemplate.cancel();
      prevAutoSaveDocument.cancel();
    };
  }, [autoSaveTemplate, autoSaveDocument]);

  // Call autoSaveTemplate or autoSaveDocument when the editorStateBuffer changes
  useEffect(() => {
    saveChangesByEditorType(editorStateBuffer, editorType, autoSaveTemplate, autoSaveDocument);
  }, [autoSaveDocument, autoSaveTemplate, editorStateBuffer, editorType]);

  // Confirm before the user leaves if the editorStateBuffer value isn't saved
  useEffect(() => {
    const shouldPreventUserFromLeaving = isSaving || editorStateBuffer !== undefined;

    const alertUserIfDraftIsUnsaved = (e) => {
      if (shouldPreventUserFromLeaving) {
        // Cancel the event
        e.preventDefault(); // Preventing the default behavior in Firefox causes the prompt to always be shown
        // Chrome requires returnValue to be set
        e.returnValue = '';
      } else {
        // The absence of a returnValue property on the event guarantees the browser unload happens
        delete e.returnValue;
      }
    };

    // Add beforeUnload only if there is unsaved editorStateBuffer to avoid performance hit
    if (shouldPreventUserFromLeaving) {
      window.addEventListener('beforeunload', alertUserIfDraftIsUnsaved);
    }

    return () => {
      if (shouldPreventUserFromLeaving) {
        window.removeEventListener('beforeunload', alertUserIfDraftIsUnsaved);
      }
    };
  }, [editorStateBuffer, isSaving, setEditorStateBuffer]);

  /**
   * Depending on the editor type, trigger the appropriate save function.
   * It bypasses the debounce to ensure an immediate save of the current state.
   */
  const undebouncedSaveChangesCallback = useCallback(
    () =>
      saveChangesByEditorType(
        editorStateBuffer,
        editorType,
        saveTemplateContent,
        saveDocumentContent
      ),
    [editorStateBuffer, editorType, saveDocumentContent, saveTemplateContent]
  );

  const cancelPendingOperationsCallback = useCallback(() => {
    autoSaveTemplate.cancel();
    autoSaveDocument.cancel();
  }, [autoSaveDocument, autoSaveTemplate]);

  useSaveBeforeNavigation({
    shouldSave: editorStateBuffer !== undefined && !isSaving,
    cancelPendingOperations: cancelPendingOperationsCallback,
    saveChanges: undebouncedSaveChangesCallback,
  });

  return {
    editorStateBuffer,
    setEditorStateBuffer,
  };
};
