// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2SolidDollarSign = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12 1.25c.41 0 .75.34.75.75v1.25H14A4.75 4.75 0 0 1 18.75 8a.75.75 0 0 1-1.5 0c0-1.8-1.46-3.25-3.25-3.25h-1.25v6.7l3.1 1.04a4.24 4.24 0 0 1-1.34 8.26h-1.76V22a.75.75 0 0 1-1.5 0v-1.25H10A4.75 4.75 0 0 1 5.25 16a.75.75 0 0 1 1.5 0c0 1.8 1.46 3.25 3.25 3.25h1.25v-6.7l-3.1-1.04a4.24 4.24 0 0 1 1.34-8.26h1.76V2c0-.41.34-.75.75-.75Zm-.75 3.5H9.49a2.74 2.74 0 0 0-.87 5.33l2.63.88V4.75Zm1.5 8.3v6.2h1.76a2.74 2.74 0 0 0 .87-5.33l-2.63-.88Z" /></svg>;
const ForwardRef = forwardRef(IconV2SolidDollarSign);
export { ForwardRef as IconV2SolidDollarSign };