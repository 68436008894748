import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import Router, { useRouter } from 'next/router';
import { useState, useRef, useCallback } from 'react';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery, useQueryClient } from 'react-query';

import {
  APIResponseToState,
  decodeQueryParam,
  stateToAPIQuery,
  supportedTableParams,
  urlToTableState,
} from '@/src/components/Table/helpers';
import { DEFAULT_TABLE_PAGE_SIZE } from '@/src/components/Table/Table';
import { parseQueryFromUrl, stringifyQuery } from '@/src/helpers/queryParser';
import useComponentDidUpdate from '@/src/hooks/useDidUpdate';

const withGlobalFilterQueryKey = (globalFilterQueryKey) =>
  globalFilterQueryKey ? { globalFilterQueryKey } : {};
/**
 *
 * useTableFetcherHook
 *
 * @param {string|string[]} tableQueryKey
 * @param {function} queryFn
 * Options that would be passed to useQuery hook.
 * @param {string} options.useQueryOptions
 * Initial state used to create APIQuery and passed to Table, for example initial sorting.
 * @param {object} options.initialState
 *
 * Please refer to src/components/Table/helpers/helpers.js{stateToAPIQuery} for the rest of options
 * @param {object} options.filterConfig
 * @param {object} options.globalFilterQueryKey
 *
 * @deprecated Use the data layer `useGetForTable` hook instead
 */
const useTableFetcher = (tableQueryKey, queryFn, options = {}) => {
  const queryClient = useQueryClient();
  const { useQueryOptions, initialState = {}, ...stateToQueryAPIOptions } = options;

  if (initialState.globalFilter) {
    initialState.globalFilter = decodeQueryParam(initialState.globalFilter);
  }

  const [tableState, setTableState] = useState({
    ...initialState,
    ...withGlobalFilterQueryKey(options.globalFilterQueryKey),
    pageSize: initialState?.pageSize || DEFAULT_TABLE_PAGE_SIZE,
  });

  const onStateChange = useCallback(
    (state) => {
      setTableState({
        ...state,
        ...withGlobalFilterQueryKey(options.globalFilterQueryKey),
      });
    },
    [options.globalFilterQueryKey]
  );

  const APIQuery = stateToAPIQuery(tableState, stateToQueryAPIOptions);

  const queryKey = Array.isArray(tableQueryKey)
    ? [...tableQueryKey, APIQuery]
    : [tableQueryKey, APIQuery];

  const response = useQuery(queryKey, () => queryFn(APIQuery), {
    retry: false,
    ...useQueryOptions,
  });

  async function refetchData() {
    return queryClient.invalidateQueries(queryKey);
  }

  const { data, isLoading, isEmptyTable, pageIndex, pageCount, totalCount, metadata } =
    APIResponseToState(response);

  const isSearchOrFilterApplied = !!(tableState.filters?.length || tableState.globalFilter);

  // For initial render or page refresh
  const isInitialLoading =
    isLoading && !isSearchOrFilterApplied && tableState.pageIndex === undefined;

  return {
    tableState,
    isFetching: response.isFetching,
    isFetched: response.isFetched,
    isEmpty: isEmptyTable,
    isInitialStateEmpty: isEmptyTable && !isSearchOrFilterApplied && !pageIndex,
    isSearchOrFilterApplied,
    isInitialLoading,
    onStateChange,
    queryFn,
    queryKey,
    refetchData,
    data,
    isLoading,
    pageCount,
    totalCount,
    initialState: {
      pageIndex,
      ...initialState, // initialState.pageIndex can override default value from response
    },
    controlledPageIndex: pageIndex,
    metadata,
  };
};

export default useTableFetcher;

/**
 *
 * useTableFetcherWithQS
 *
 * For params definitions please refer to useTableFetcher
 *
 * @deprecated Use the data layer `useGetForTable` hook instead
 */
export const useTableFetcherWithQS = (tableQueryKey, queryFn, options = {}) => {
  const { asPath } = useRouter();

  const tableState = urlToTableState(asPath);

  // We want to load initial state only once
  const tableInitialState = useRef(tableState).current;
  const optionsInitialState = options.initialState;
  const initialState = isEmpty(tableInitialState) ? optionsInitialState : tableInitialState;

  const data = useTableFetcher(tableQueryKey, queryFn, { ...options, initialState });

  useComponentDidUpdate(() => {
    const newQueryString = stringifyQuery(
      {
        ...parseQueryFromUrl(Router.asPath),
        ...pick(data.tableState, supportedTableParams),
        // Use router's slug
        ...(!!Router?.query?.slug && { slug: Router.query.slug }),
        // Use router's userSlug
        ...(!!Router?.query?.userSlug && { userSlug: Router.query.userSlug }),
      },
      {
        skipNulls: true,
        skipEmptyStrings: true,
        skipCustomMatcher: (key, val) => key === 'pageIndex' && (val === 0 || val === '0'),
      }
    );

    if (newQueryString === '') {
      Router.replace(Router.pathname, undefined, { shallow: true });
    } else {
      Router.replace(
        {
          pathname: Router.asPath.split('?')[0],
          query: newQueryString,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [data.tableState]);

  return data;
};
