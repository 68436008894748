import type { DefaultTheme } from 'styled-components';

import type { ListDiscSize } from './ListDisc';

// Space scale values come from our theme foundations' space token
export const marginDensity: Record<ListDiscSize, number> = {
  sm: 4,
  md: 5,
  lg: 6,
};

export const sizes = (typography: DefaultTheme['typography']) => {
  return {
    sm: typography.xs,
    md: typography.sm,
    lg: typography.base,
  };
};

export const DEFAULT_SIZE = 'md';

export const tones = (colors: DefaultTheme['colors']) => ({
  primary: colors.brand[600],
  secondary: colors.grey[600],
});
