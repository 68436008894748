/**
 * Cleaning the query object in the Next.js router breaks
 * paths with params.
 *
 * We need to clean the query object manually instead by just
 * providing the URL to the Link component so we don't override
 * necessary query params that are actually path params but
 * Next.js doesn't differentiate them.
 *
 * @param {string} asPath - should be `useRouter().asPath`
 * @param {string} tab - the tab to be set as the `selectedTab`
 * @param {boolean} dropQuery - if `true` drops all other query params except `selectedTab`
 * @param {string} paramName - the url param name. Default is  "selectedTab"
 * @returns {string} - the generated href
 */
export function getTabHref(asPath = '', tab, dropQuery, paramName = 'selectedTab') {
  const [pathname] = asPath.split('?');

  const url = new URL(dropQuery ? pathname : asPath, 'http://localhost');

  url.searchParams.set(paramName, tab);

  // Don't do `url.toString` as we want to ignore the base url (localhost)
  return `${url.pathname}${url.search}`;
}
