import { datadogRum } from '@datadog/browser-rum';

import {
  columnsConfigurationSchema,
  legacyColumnsConfigurationSchema,
} from '@/src/components/Table/hooks/useColumnsState/schema';
import type {
  AnyColumnsConfiguration,
  ColumnsConfiguration,
} from '@/src/components/Table/hooks/useColumnsState/types';

/**
 * Validates and normalizes columns configurations to ensure compatibility with the latest format.
 *
 * This function addresses potential issues when storing customized table configurations in local storage by:
 * 1. Validating the provided configuration against the latest schema. Proceeds if validation is successful.
 * 2. If validation fails, it checks the configuration against known legacy formats and attempts to upgrade it to the latest format.
 * 3. Throws an error if the configuration cannot be validated or upgraded, indicating potential corruption.
 *
 * This approach ensures backward compatibility with legacy formats and maintains data integrity for user-customized tables.
 */
export const normalizeColumnsConfiguration = (
  configuration: AnyColumnsConfiguration
): ColumnsConfiguration => {
  try {
    const validatedConfiguration = columnsConfigurationSchema.validateSync(configuration, {
      stripUnknown: true,
    });

    return validatedConfiguration;
  } catch (error) {
    /* We want to try another approach if this throws an error. */
  }

  const validatedLegacyConfiguration = legacyColumnsConfigurationSchema.validateSync(
    configuration,
    {
      stripUnknown: true,
    }
  );

  const normalizedLegacyConfiguration: ColumnsConfiguration = {
    name: validatedLegacyConfiguration.name,
    customizations: {
      visibility: validatedLegacyConfiguration.config,
    },
  };

  /* Track usage of legacy columns config. */
  datadogRum.addAction('Converted legacy columns configuration to new format');

  return normalizedLegacyConfiguration;
};
