import type { MutableRefObject } from 'react';
import { useCallback, useState } from 'react';

import { useTimeout } from './useTimeout';

export type UseCopyToClipboardProps<ElementType> = {
  value: string;
  onClick?: (value: string) => void;
  targetRef?: MutableRefObject<ElementType | null>;
  timeout?: number;
};

export function useCopyToClipboard<ElementType extends HTMLElement>({
  value,
  onClick,
  targetRef,
  timeout = 2000,
}: UseCopyToClipboardProps<ElementType>) {
  // We want to call blur() on the target element after the value is copied. This way tooltip disappears and copy label is hidden
  const blurTimeout = timeout - 100;
  const [copied, setCopied] = useState(false);

  const setCopiedCallback = useCallback(() => {
    if (copied) {
      setCopied(false);
    }
  }, [setCopied, copied]);

  const blurCallback = useCallback(() => {
    if (copied) {
      targetRef?.current?.blur();
    }
  }, [copied, targetRef]);

  useTimeout(setCopiedCallback, timeout);
  useTimeout(blurCallback, blurTimeout);

  const onCopyClick = useCallback(
    (event?: { preventDefault: any }, val?: string) => {
      event?.preventDefault();
      setCopied(true);
      if (onClick) {
        onClick(val || value);
      } else {
        navigator.clipboard.writeText(val || value);
      }
    },
    [onClick, value]
  );

  return {
    onCopyClick,
    copied,
  };
}
