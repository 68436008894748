import { Box } from '@remote-com/norma';

import { getBenefitPlanTypeIcon } from '@/src/domains/benefits/constants';

function getGroupTypes({ selectedBenefitTier, benefitTiers }) {
  const plans =
    selectedBenefitTier === 'no' ? benefitTiers[0]?.benefitPlans : selectedBenefitTier.benefitPlans;

  const planTypes = plans.map(({ type }) => type).filter(Boolean);

  return [...new Set(planTypes)];
}

function getIcons(types) {
  // Split the icons between the custom and fallback ones,
  // so that we can reduce the duplicate fallback ones
  const [icons, fallbackIcon] = types.reduce(
    ([acc, fallback], type) => {
      const icon = getBenefitPlanTypeIcon(type);
      return [icon.fallback ? acc : [...acc, icon], icon.fallback ? icon : fallback];
    },
    [[], null]
  );

  return fallbackIcon ? [...icons, fallbackIcon] : icons;
}

export function GroupIcons({ group, circleSize = 24, iconSize = 16 }) {
  if (!group) {
    return null;
  }

  const icons = getIcons(getGroupTypes(group));

  return icons.map(({ backgroundColor, color, Icon, key }) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      size={circleSize}
      key={key}
      backgroundColor={backgroundColor}
      color={color}
    >
      <Icon width={iconSize} />
    </Box>
  ));
}
