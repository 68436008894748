// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineTrash = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M10.62 1.25h2.75c.52 0 .97 0 1.37.16.35.13.66.36.9.65.28.33.42.75.58 1.24l.04.1.28.85H22a.75.75 0 0 1 0 1.5h-1.3l-.6 10.3c-.06 1.04-.1 1.85-.2 2.52-.1.67-.24 1.24-.53 1.76a4.75 4.75 0 0 1-2.06 1.94c-.53.26-1.11.37-1.8.43-.66.05-1.48.05-2.51.05h-2c-1.03 0-1.85 0-2.52-.05a4.86 4.86 0 0 1-1.79-.43 4.75 4.75 0 0 1-2.06-1.94 4.86 4.86 0 0 1-.53-1.76c-.1-.67-.14-1.48-.2-2.52l-.6-10.3H2a.75.75 0 0 1 0-1.5h5.46l.28-.85.04-.1c.16-.49.3-.91.58-1.24.24-.3.55-.52.9-.65.4-.16.85-.16 1.36-.16Zm-2.64 4.5H19.2l-.6 10.18c-.06 1.07-.1 1.83-.19 2.43-.08.58-.19.95-.35 1.23a3.25 3.25 0 0 1-1.4 1.33c-.3.15-.67.23-1.26.28-.6.05-1.36.05-2.43.05h-1.94c-1.07 0-1.83 0-2.43-.05a3.4 3.4 0 0 1-1.25-.28 3.25 3.25 0 0 1-1.41-1.33 3.4 3.4 0 0 1-.35-1.23c-.09-.6-.13-1.36-.2-2.43L4.8 5.75H8Zm6.98-1.5H9.04l.12-.37c.22-.65.28-.78.35-.86.08-.1.18-.17.3-.22.1-.04.23-.05.92-.05h2.54c.69 0 .82.01.92.05.12.05.22.12.3.22.07.08.13.2.35.86l.12.37Zm-4.96 5c.41 0 .75.34.75.75v7a.75.75 0 0 1-1.5 0v-7c0-.41.34-.75.75-.75Zm4 0c.41 0 .75.34.75.75v4a.75.75 0 0 1-1.5 0v-4c0-.41.34-.75.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineTrash);
export { ForwardRef as IconV2OutlineTrash };