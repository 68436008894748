import type { ReactNode } from 'react';
import type { PlaceholderProps } from 'react-select';
import styled from 'styled-components';

import { Text } from '../../../core/text';

type StyledSelectProps = {
  showPlaceholder: boolean;
  size: 'sm' | 'md';
};

type StyledPlaceholderProps = {
  children: ReactNode;
  selectProps: StyledSelectProps;
};

const StyledPlaceholder = styled(Text).attrs(({ $size }: { $size: StyledSelectProps['size'] }) => ({
  variant: $size === 'sm' && 'sm',
}))<{ $size: 'sm' | 'md'; $showPlaceholder: boolean }>`
  color: ${({ theme }) => theme.colors.grey[600]};
  display: ${({ $showPlaceholder }) => ($showPlaceholder ? 'block' : 'none')};
  position: absolute;
`;

export const Placeholder = ({
  children,
  selectProps: { showPlaceholder, size },
}: PlaceholderProps & StyledPlaceholderProps) => (
  <StyledPlaceholder $showPlaceholder={showPlaceholder} $size={size}>
    {children}
  </StyledPlaceholder>
);
