import type { RadioCardProps, RadioCardGroupProps } from '@remote-com/norma';
import { RadioCard, RadioCardGroup } from '@remote-com/norma';
import type { FormikContextType, FormikValues } from 'formik';
import { Field, useField, useFormikContext } from 'formik';
import React, { useEffect, useId, useRef } from 'react';

import { ErrorComponent } from './RadioCardField.styled';

type RadioCardFieldProps = Omit<RadioCardProps, 'id'> & {
  formikBag?: FormikContextType<any>;
  // ID is made optional because we generate a unique ID if it's omitted. Consumers don't need to make sure they define unique IDs this way.
  id?: string;
  onValueChanged?: (prevValue: string, formikBag: FormikContextType<any>) => void;
};

export const RadioCardField = ({ onValueChanged, name, ...props }: RadioCardFieldProps) => {
  const prevValueRef = useRef<string>();
  const formikBag = useFormikContext<FormikValues>();
  const generatedId = useId();

  // Keep track of value changes and notify via `onValueChanged` if any
  useEffect(() => {
    if (!onValueChanged) return;

    // Only call `onValueChanged` if the value has changed
    const prevValue = prevValueRef.current ?? '';
    if (formikBag.values[name] !== prevValue) {
      onValueChanged(prevValue, formikBag);
    }

    // Update the ref with the current value
    prevValueRef.current = formikBag.values[name];
  }, [name, formikBag, onValueChanged]);

  return <RadioCard {...props} id={props.id ?? generatedId} name={name} FieldComponent={Field} />;
};

export const RadioCardGroupField = ({ name, children, ...props }: RadioCardGroupProps) => {
  const [, { error, touched }] = useField(name);

  return (
    <RadioCardGroup
      {...props}
      name={name}
      error={error}
      touched={touched}
      ErrorComponent={ErrorComponent}
    >
      {children}
    </RadioCardGroup>
  );
};
