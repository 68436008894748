// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconFolderCheck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M6.05 1.25c-1.68 0-2.52 0-3.16.33a3 3 0 0 0-1.31 1.3c-.33.65-.33 1.49-.33 3.17v10.3c0 2.24 0 3.36.44 4.22a4 4 0 0 0 1.74 1.74c.86.44 1.98.44 4.22.44h8.7c2.24 0 3.36 0 4.22-.44a4 4 0 0 0 1.74-1.74c.44-.86.44-1.98.44-4.22v-4.7c0-2.24 0-3.36-.44-4.22a4 4 0 0 0-1.74-1.74c-.86-.44-1.98-.44-4.22-.44h-1.1c-.53 0-.8 0-1.04-.06a2 2 0 0 1-1.03-.63 4.48 4.48 0 0 1-.52-.91c-.36-.72-.53-1.08-.78-1.36a3 3 0 0 0-1.54-.95c-.36-.09-.76-.09-1.56-.09H6.05Z" /><path fillRule="evenodd" clipRule="evenodd" d="M16.03 10.97c.3.3.3.77 0 1.06l-4.5 4.5c-.3.3-.77.3-1.06 0l-2-2a.75.75 0 1 1 1.06-1.06L11 14.94l3.97-3.97c.3-.3.77-.3 1.06 0Z" /></svg>;
const ForwardRef = forwardRef(IconFolderCheck);
export { ForwardRef as IconFolderCheck };