// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconPaperclip = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M15.78 2.39a3 3 0 0 0-2.12.88l-9.2 9.19a5 5 0 1 0 7.08 7.07l9.2-9.19a1 1 0 1 1 1.4 1.42l-9.18 9.19a7 7 0 0 1-9.9-9.9l9.18-9.2a5 5 0 1 1 7.08 7.08l-9.2 9.19a3 3 0 0 1-4.25-4.25l8.5-8.48a1 1 0 1 1 1.4 1.42L7.3 15.29A1 1 0 0 0 8.7 16.7l9.2-9.19a3 3 0 0 0-2.12-5.12Z" /></svg>;
const ForwardRef = forwardRef(IconPaperclip);
export { ForwardRef as IconPaperclip };