import isNil from 'lodash/isNil';

import {
  DefinitionTerm,
  DefinitionDescription,
  DefinitionListContainer,
  DefinitionList,
} from './SaveFilter.styled';

const FiltersList = ({ filters }) => {
  const filterList = Object.keys(filters)
    .map((key) => ({
      key,
      label: key,
      value: filters[key],
    }))
    // Remove filters that have an undefined or null value (e.g. that were cleared with isClearable filter's prop)
    .filter(({ value }) => !isNil(value));

  function transformValue(value) {
    if (typeof value === 'object') {
      return value.map((obj) => obj.label).join(', ');
    }
    return value;
  }

  return (
    <DefinitionList>
      {filterList.map(({ key, label, value }) => {
        return (
          <DefinitionListContainer key={key}>
            <DefinitionTerm>{label}</DefinitionTerm>
            <DefinitionDescription>{transformValue(value)}</DefinitionDescription>
          </DefinitionListContainer>
        );
      })}
    </DefinitionList>
  );
};

export default FiltersList;
