import isObject from 'lodash/isObject';
import * as Yup from 'yup';

import { COLUMN_STATES } from '@/src/components/Table/hooks/useColumnsState';

const columnsConfigurationVisibilitySchema = ({ required = false }: { required?: boolean } = {}) =>
  Yup.lazy((value) => {
    if (!isObject(value)) {
      if (required) {
        return Yup.object().required().strict(true);
      }

      return Yup.object();
    }

    const shape: Record<string, Yup.Schema<any>> = {};

    Object.keys(value).forEach((key) => {
      shape[key] = Yup.string().oneOf(Object.values(COLUMN_STATES)).required();
    });

    return Yup.object(shape).required().strict(true);
  });

/**
 * New columns configuration format including order and visibility.
 */
export const columnsConfigurationSchema = Yup.object({
  name: Yup.string().required(),
  customizations: Yup.object({
    order: Yup.array().of(Yup.string().required()),
    visibility: columnsConfigurationVisibilitySchema(),
  }).required(),
})
  .required()
  .strict(true);

/**
 * Legacy columns configuration format including only visibility.
 */
export const legacyColumnsConfigurationSchema = Yup.object({
  name: Yup.string().required(),
  config: columnsConfigurationVisibilitySchema({ required: true }),
})
  .required()
  .strict(true);
