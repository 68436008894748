import { trackEvent, tableEvents } from '@remote-com/analytics';
import flattenDeep from 'lodash/flattenDeep';
import xor from 'lodash/xor';

export const getColumnNames = (columns = []) => {
  return columns.map((column) => column.id);
};

export const trackViewEvent = ({ tableName, columns, countResults, isCompactTable }) => {
  trackEvent(tableEvents.TABLE_VIEW, {
    tableName,
    elementData: columns,
    countResults,
    elementType: isCompactTable ? 'density-compact' : 'density-large',
  });
};

export const trackSearchEvent = ({ tableName, countResults }) => {
  trackEvent(tableEvents.TABLE_SEARCH, {
    tableName,
    countResults,
  });
};

export const trackSortEvent = ({ tableName, sortBy, countResults }) => {
  if (!Array.isArray(sortBy) || !sortBy.length) {
    return;
  }

  const firstItem = sortBy[0];
  const action = firstItem.desc ? 'sort-descending' : 'sort-ascending';

  trackEvent(tableEvents.TABLE_SORT, {
    tableName,
    action,
    elementData: firstItem.id,
    countResults,
  });
};

export const trackFilterEvent = ({ tableName, filters, loadedSavedFilter, countResults }) => {
  if (!Array.isArray(filters) || !filters.length) {
    return;
  }

  filters.forEach((filter) => {
    const elementData = loadedSavedFilter ? 'saved-filter' : filter.id;
    const formFieldValues = loadedSavedFilter ? filter : filter.value;
    trackEvent(tableEvents.TABLE_FILTER, {
      tableName,
      elementData,
      formFieldValues,
      countResults,
    });
  });
};

export const trackSaveFilter = ({ tableName, config: { filterName, filters } }) => {
  trackEvent(tableEvents.TABLE_SAVE_FILTER, {
    tableName,
    elementData: filterName,
    formFieldValues: filters,
  });
};

export const trackExportDataEvent = ({ tableName, columns, countResults }) => {
  trackEvent(tableEvents.TABLE_EXPORT, {
    tableName,
    elementData: getColumnNames(columns),
    countResults,
  });
};

export const trackSaveTableConfigEvent = ({ tableName, config: { columnStateToSave } }) => {
  if (!columnStateToSave || !columnStateToSave.length) {
    return;
  }

  const elementData = flattenDeep(columnStateToSave).join(',');

  trackEvent(tableEvents.TABLE_SAVE_CONFIG, {
    tableName,
    elementData,
  });
};

export const trackEditColumnsEvent = ({ tableName, prevVisibleColumns, visibleColumns }) => {
  if (prevVisibleColumns.length === visibleColumns.length) {
    return;
  }

  const changedColumn = xor(prevVisibleColumns, visibleColumns);

  const action = prevVisibleColumns.length > visibleColumns.length ? 'hide-column' : 'add-column';

  trackEvent(tableEvents.TABLE_EDIT_COLUMNS, {
    tableName,
    action,
    elementData: changedColumn[0],
  });
};

export const trackCompactTablesEvent = ({ isCompactTable, tableName, countResults }) => {
  const eventName = isCompactTable
    ? tableEvents.TABLE_DENSITY_LARGE
    : tableEvents.TABLE_DENSITY_COMPACT;
  trackEvent(eventName, {
    tableName,
    countResults,
  });
};

export const trackHideIndividualColumnEvent = ({ tableName }) => {
  trackEvent(tableEvents.TABLE_HIDE_COLUMN, {
    tableName,
  });
};

export const trackRowMenuClick = ({ tableName }) => {
  const action = 'row-actions-click';
  trackEvent(tableEvents.TABLE_CLICK_ROW_MENU, {
    tableName,
    action,
  });
};

export const trackRowMenuActionClick = ({ tableName, action }) => {
  trackEvent(tableEvents.TABLE_ROW_MENU_ACTION_CLICK, {
    tableName,
    eventProps: {
      action,
    },
  });
};

/**
 * Track changes to the column order.
 * @param {object} props
 * @param {string} props.tableName
 * @param {string} props.columnId
 */
export const trackChangeColumnsOrderEvent = (props) => {
  trackEvent(tableEvents.TABLE_CHANGE_COLUMNS_ORDER, props);
};
