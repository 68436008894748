import { Stack, Box, Text } from '@remote-com/norma';
import styled from 'styled-components';

export const LegendContainer = styled(Stack)`
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
  margin: 10px 0 20px 0;
`;

export const LegendItem = styled(Stack).attrs({
  direction: 'row',
  alignItems: 'center',
  marginLeft: 45,
})``;

export const LegendColor = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const LegendLabel = styled(Text).attrs({
  variant: 'smMedium',
  color: 'grey.600',
})``;
