import { getActiveEmploymentCountry } from '@/src/domains/employment/helpers';
import handbooks from '@/src/domains/handbooks/handbooks/index.json';
import {
  isInternalEmployee,
  isUserGlobalPayroll,
  isEmployer,
} from '@/src/domains/registration/auth/helpers';
import { userCacheKeys } from '@/src/domains/userCache/constants';
import { getFromUserCache } from '@/src/domains/userCache/helpers';
import { fetchEmploymentEmployeeInfo } from '@/src/services/Employee';

function getHandbookIdForCountry(iso3166Alpha3CountryCode) {
  const handbookEntry = handbooks.find(({ title }) => title.includes(iso3166Alpha3CountryCode));
  return handbookEntry?.id || null;
}

export async function getUserHandbookId(user) {
  try {
    const { data: employmentEmployeeInfo } = await fetchEmploymentEmployeeInfo({
      pathParams: { employmentSlug: user?.activeEmployment?.slug },
    });

    const employmentCountry = await getActiveEmploymentCountry(employmentEmployeeInfo);

    if (!employmentCountry) {
      throw new Error('There is no employee handbook for country');
    }

    return getHandbookIdForCountry(employmentCountry?.code);
  } catch {
    return null;
  }
}

export function sanitizeHandbookTitle(title) {
  // Removes specific Notion country notation
  // Given "Portugal Employee Handbook [PRT]" returns "Portugal Employee Handbook"
  return title.replace(/(.*?)\s*\[[a-zA-Z]{3}\]$/, '$1').trim();
}

function getBlockType(block) {
  return block?.type;
}

function getPreviousBlockType(index, blocks) {
  return getBlockType(blocks[index - 1]);
}

function getNextBlockType(index, blocks) {
  return getBlockType(blocks[index + 1]);
}

const headerTypes = { header: 1, sub_header: 2, sub_sub_header: 3 };

function getAllAndLast(list) {
  return [
    list.slice(0, -1), // all but last
    list[list.length - 1], // last
  ];
}

export function unflatToc(toc) {
  return toc.reduce((acc, cur, index) => {
    if (acc.length === 0) {
      return [cur];
    }

    const [listWithoutLast, last] = getAllAndLast(acc);
    const isNested = headerTypes[cur.type] > headerTypes[toc[index - 1].type];
    const isLastList = last.items;

    if (isLastList) {
      const [subAll, subLast] = getAllAndLast(last.items);
      const subItems = subLast.items || [];

      // h2 > h3 || h1 > h3 || h3 > h3
      if (headerTypes[cur.type] === 3) {
        const newLast = {
          ...last,
          items: [
            ...subAll,
            {
              ...subLast,
              items: [...subItems, cur],
            },
          ],
        };
        return [...listWithoutLast, newLast];
      }

      // h3 > h2
      if (headerTypes[cur.type] === 2) {
        const newLast = {
          ...last,
          items: [...last.items, cur],
        };

        return [...listWithoutLast, newLast];
      }

      // h1
      return [...acc, cur];
    }

    // It's h1 > XX
    if (isNested) {
      const subItems = last.items || [];
      const newLast = {
        ...last,
        items: [...subItems, cur],
      };

      return [...listWithoutLast, newLast];
    }

    // It's parent (eg h3 > h1)
    return [...acc, cur];
  }, []);
}

// Remove especial characters and turn all into lowercase.
// eg "Non-Habitual Resident (NHR) Tax regime" -> "non-habitual-resident-nhr-tax-regime"
export function titleToId(text) {
  return `${text.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`;
}

export function groupLists(blocks) {
  const blocksCopy = [...blocks];
  const groupedLists = [];

  let tempList = null;
  const tempListTemplate = {
    id: null,
    startIndex: 0,
    endIndex: 0,
    type: 'wrapper',
    children: [],
  };

  const listTypes = ['numbered_list', 'bulleted_list'];

  // Build lists
  blocksCopy.map((block, index, siblings) => {
    const isListBlock = listTypes.includes(block.type);

    if (isListBlock) {
      const previousIsList = getPreviousBlockType(index, siblings) === block.type;
      const nextIsList = getNextBlockType(index, siblings) === block.type;

      if (!previousIsList) {
        // Init target
        tempList = {
          ...tempListTemplate,
          type: `${block.type}_${tempListTemplate.type}`,
          children: [],
        };
        tempList.id = block.id;
        tempList.startIndex = index;
      }

      // Appending _item to the original type prevents this code
      // from running in any processed items and generating an infinite loop
      tempList.children.push({ ...block, type: `${block.type}_item` });

      if (!nextIsList) {
        tempList.endIndex = index;
        groupedLists.push(tempList);
        tempList = null;
      }
    }

    return block;
  });

  // Apply lists
  const reversedLists = groupedLists.reverse();
  // Replace in reverse so we dont need to recalculate indexes
  reversedLists.forEach((group) => {
    blocksCopy.splice(group.startIndex, group.children.length, group);
  });

  return blocksCopy;
}

export const shouldShowHandbook = (user, isOnboarding = false) => {
  if (isEmployer(user)) {
    return true;
  }

  const hasUserHandbook =
    !!getFromUserCache(user, userCacheKeys.HANDBOOK_ID) && !isInternalEmployee(user);
  const displayHandbook = hasUserHandbook && !isUserGlobalPayroll(user);
  if (isOnboarding) {
    return hasUserHandbook;
  }
  return displayHandbook;
};
