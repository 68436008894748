import type { PalettesForStyledSystem } from '@remote-com/norma';

import { LegendContainer, LegendItem, LegendLabel, LegendColor } from './ChartLegend.styled';

export type ChartLegendProps = {
  items: {
    color: keyof PalettesForStyledSystem;
    label: string;
  }[];
};

export default function ChartLegend({ items }: ChartLegendProps) {
  return (
    <LegendContainer>
      {items.map((item) => (
        <LegendItem key={item.label}>
          <LegendColor backgroundColor={item.color} />
          <LegendLabel>{item.label}</LegendLabel>
        </LegendItem>
      ))}
    </LegendContainer>
  );
}
