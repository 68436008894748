const saturday = {
  value: 'saturday',
  label: 'Saturday',
  shortLabel: 'Sat',
};

const sunday = {
  value: 'sunday',
  label: 'Sunday',
  shortLabel: 'Sun',
};

export const weekdayOptions = [
  {
    value: 'monday',
    label: 'Monday',
    shortLabel: 'Mon',
  },
  {
    value: 'tuesday',
    label: 'Tuesday',
    shortLabel: 'Tue',
  },
  {
    value: 'wednesday',
    label: 'Wednesday',
    shortLabel: 'Wed',
  },
  {
    value: 'thursday',
    label: 'Thursday',
    shortLabel: 'Thu',
  },
  {
    value: 'friday',
    label: 'Friday',
    shortLabel: 'Fri',
  },
];

export const daysOfWeekOptions = [sunday, ...weekdayOptions, saturday];

export const daysOfWeekOptionsMondayStart = [...weekdayOptions, saturday, sunday];

export const dayInfoBySlug = new Map(
  daysOfWeekOptions.map((option, index) => [option.value, { ...option, sortIndex: index }])
);
