import { ButtonInline } from '@/src/components/Button';

type ContactRemoteSupportButtonProps = {
  is2FASetupCompleted: boolean;
};

export const ContactRemoteSupportButton = ({
  is2FASetupCompleted = true,
}: ContactRemoteSupportButtonProps) => {
  return (
    <ButtonInline
      href={
        is2FASetupCompleted
          ? 'https://support.remote.com/hc/en-us/requests/new'
          : 'https://support.remote.com/hc/en-us/articles/4424529540109-How-can-I-activate-two-factor-authentication-for-my-account#:~:text=To%20activate%202FA%20on%20Remote,Setup%20two%2Dfactor%20authentication%20button.&text=Manually%20enter%20your%20secret%20key,can%20type%20into%20the%20app'
      }
      asTag="a"
      target="_blank"
      rel="noopener noreferrer"
    >
      {is2FASetupCompleted ? 'Contact us' : 'Learn how to set up 2FA'}
    </ButtonInline>
  );
};
