import { Box, Text, focusRingBorderCSS, focusVisible, sharedTransition } from '@remote-com/norma';
import { IconV2SolidSpinner } from '@remote-com/norma/icons/IconV2SolidSpinner';
import styled, { css, keyframes } from 'styled-components';

import { ButtonInline } from '@/src/components/Button';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);  
  }
`;

export const StyledIconV2SolidSpinner = styled(IconV2SolidSpinner).attrs({
  title: 'Loading notifications',
})`
  animation: ${spin} 0.85s linear infinite;
`;

export const NotificationItemTitle = styled(Text).attrs({
  variant: 'smMedium',
  color: 'grey.900',
  forwardedAs: 'h2',
})``;

export const NotificationItemDescription = styled(Text).attrs({
  forwardedAs: 'p',
  variant: 'xs',
  color: 'grey.600',
})``;

export const NotificationItemDescriptionEmphasis = styled(Text).attrs({
  forwardedAs: 'span',
  variant: 'xsMedium',
  color: 'grey.600',
})``;

export const ItemContentDetails = styled.span`
  margin-bottom: ${({ theme }) => `${theme.space[2]}px`};
`;

export const InAppNotificationsItemWrapper = styled(Box)`
  position: relative;
`;

export const InAppNotificationsItem = styled(Box)`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.blank};
  display: flex;
  flex-direction: row;
  padding: var(--card-drawer-space-y, ${({ theme }) => theme.space[5]}px)
    var(--card-drawer-space-x, ${({ theme }) => theme.space[6]}px);
  transition: all 0.2s ease;
  word-break: break-word;
  cursor: pointer;

  ${sharedTransition('background-color')}

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[50]};
  }
`;

export const InAppNotificationsBody = styled(ButtonInline)`
  &:hover {
    text-decoration: none;
  }
  &[target='_blank'] {
    white-space: normal;
  }
  all: unset;

  ${focusVisible(css`
    ${({ theme }) => focusRingBorderCSS(theme.colors.primary)};
  `)};
`;

export const ItemAvatar = styled.span`
  margin-right: 12px;
`;
