import { DOCUMENT_TYPES } from '@/src/domains/documents/constants';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { makeApiService } from '@/src/services/ApiClient';

import { VERSIONS } from './defaults';

export const fetchCompanies = makeApiService({
  path: '/companies',
  version: VERSIONS.ADMIN_V1,
});

export const fetchCompanyDetails = makeApiService({
  path: '/companies/[companySlug]',
  version: VERSIONS.ADMIN_V1,
});

export const updateCompanyDetails = makeApiService({
  method: 'patch',
  path: '/companies/[companySlug]',
  version: VERSIONS.ADMIN_V1,
  asFormData: false,
});

export const updateCompanyBankAccount = makeApiService({
  path: '/companies/[slug]/bank-account',
  method: 'patch',
  version: VERSIONS.ADMIN_V1,
});

export const fetchCountries = makeApiService({
  path: '/countries',
  version: VERSIONS.ADMIN_V1,
});

export const createEmployeeInvite = makeApiService({
  path: `/onboarding/employments/[slug]/invite`,
  method: 'post',
  version: VERSIONS.ADMIN_V1,
});

export const fetchEmploymentMobilityDocuments = makeApiService({
  path: `/rivendell/employments/[slug]/mobility-documents/`,
});

export const updateEmploymentPreHireCheck = makeApiService({
  path: `/employments/[slug]/pre-hire-check/`,
  method: 'put',
  version: VERSIONS.ADMIN_V1,
  asFormData: false,
});

export const fetchContractDocuments = makeApiService({
  path: `/rivendell/contract-documents`,
});

export const fetchContractDocumentsQueryName = 'fetchContractDocuments';

export const previewContractDocument = makeApiService({
  path: `/rivendell/contract-documents/[slug]/preview`,
  method: 'post',
  asFormData: false,
});

export const fetchContractTemplates = makeApiService({
  path: `/rivendell/contract-templates`,
});

export const fetchContractTemplatesQueryName = 'fetchContractTemplates';

export const fetchContractTemplate = makeApiService({
  path: `/rivendell/contract-templates/[slug]`,
});

export const previewContractTemplate = makeApiService({
  path: `/rivendell/contract-templates/[slug]/preview`,
  method: 'post',
  asFormData: false,
});

export const fetchLegalEntities = makeApiService({
  path: `/legal-entities`,
  version: VERSIONS.ADMIN_V1,
});

export const fetchLegalEntity = makeApiService({
  path: `/legal-entities/[slug]`,
  version: VERSIONS.ADMIN_V1,
});

export const createLegalEntity = makeApiService({
  path: `/legal-entities`,
  method: 'post',
  version: VERSIONS.ADMIN_V1,
});

export const updateLegalEntity = makeApiService({
  path: `/legal-entities/[slug]`,
  method: 'patch',
  version: VERSIONS.ADMIN_V1,
});

export const updateCountry = makeApiService({
  method: 'put',
  path: '/countries/[slug]',
  version: VERSIONS.ADMIN_V1,
});

export const fetchUsers = makeApiService({
  path: '/users',
  version: VERSIONS.ADMIN_V1,
});

export const fetchUser = makeApiService({
  path: '/users/[slug]',
  version: VERSIONS.ADMIN_V1,
});

export const fetchEmployees = makeApiService({
  path: '/employees',
  version: VERSIONS.ADMIN_V1,
});

export const fetchOnboardingSpecialists = makeApiService({
  path: '/onboarding-specialists',
  version: VERSIONS.ADMIN_V1,
});

export const assignOnboardingSpecialist = makeApiService({
  path: '/employments/[employmentSlug]/onboarding-assignments',
  version: VERSIONS.ADMIN_V1,
  method: 'post',
});

export const unAssignOnboardingSpecialist = makeApiService({
  path: '/employments/[employmentSlug]/onboarding-assignments',
  version: VERSIONS.ADMIN_V1,
  method: 'delete',
});

export const resetUserPassword = makeApiService({
  path: '/users/[userSlug]/reset-password',
  version: VERSIONS.ADMIN_V1,
  method: 'post',
});

export const updateUserDetails = makeApiService({
  path: '/users/[slug]',
  method: 'put',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const turnOf2Fa = makeApiService({
  path: '/users/[slug]/auth/totp',
  method: 'delete',
  version: VERSIONS.ADMIN_V1,
});

export const upsertUserAdministrativeDetails = makeApiService({
  path: '/contracts/[contractSlug]/administrative-details',
  method: 'post',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const upsertUserEmploymentEligibilityDetails = (isUpdate) =>
  makeApiService({
    path: `/contracts/[contractSlug]/contract-eligibility`,
    method: isUpdate ? 'patch' : 'post',
    version: VERSIONS.ADMIN_V1,
  });

export const updateMobilityAssistanceStatus = makeApiService({
  path: `/mobility/[contractEligibilitySlug]/mobility-assistance-status`,
  method: 'patch',
  version: VERSIONS.ADMIN_V1,
});
export const createMobilityAssistanceStatus = makeApiService({
  path: `/mobility/[contractEligibilitySlug]/mobility-assistance-status`,
  method: 'post',
  version: VERSIONS.ADMIN_V1,
});

export const upsertUserEmergencyContact = makeApiService({
  path: '/users/[slug]/emergency-contact',
  method: 'post',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const upsertUserBusinessInfo = makeApiService({
  path: '/users/[slug]/business-info',
  method: 'post',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const upsertUserHomeAddress = makeApiService({
  path: '/users/[slug]/home-address',
  method: 'post',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const upsertUserPersonalDetails = makeApiService({
  path: '/users/[slug]/personal-details',
  method: 'post',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const fetchEmployeeDetails = makeApiService({
  path: '/users/[slug]/employments',
  version: VERSIONS.ADMIN_V1,
});

export const fetchEmployeeCompanies = makeApiService({
  path: '/users/[slug]/team-member-companies',
  version: VERSIONS.ADMIN_V1,
});

export const fetchPaymentsAndRefunds = makeApiService({
  method: 'get',
  path: '/outstanding-payments-and-refunds',
  version: VERSIONS.ADMIN_V1,
});

export const fetchPaymentsCreator = makeApiService({
  path: '/outstanding-payments/creators',
  version: VERSIONS.ADMIN_V1,
});

export const updatePaymentDetails = makeApiService({
  method: 'patch',
  path: '/outstanding-payments/[slug]',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const completeOutstandingPayment = makeApiService({
  method: 'put',
  path: '/outstanding-payments/[slug]/complete-payment',
  version: VERSIONS.ADMIN_V1,
});

export const ADMIN_PAYSLIPS_QUERY_NAME = 'fetchPayslips';

export const updateCompensation = makeApiService({
  method: 'patch',
  path: '/contracts/[contractSlug]/compensations/[compensationSlug]',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const createCompensation = makeApiService({
  method: 'post',
  path: '/contracts/[contractSlug]/compensations',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const batchUpdateCompensation = makeApiService({
  version: VERSIONS.ADMIN_V1,
  method: 'patch',
  path: '/compensations/batch',
  asFormData: false,
});

const updateInitialContract = makeApiService({
  method: 'post',
  path: '/employments/[employmentSlug]/contracts',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const createContractAmendment = makeApiService({
  method: 'post',
  path: '/contracts/[contractSlug]/amendment',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const updateContractAmendment = makeApiService({
  method: 'patch',
  path: '/contracts/[contractSlug]/amendment',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const deleteContract = makeApiService({
  method: 'delete',
  path: '/contracts/[contractSlug]',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const createRemotePaidServiceCost = makeApiService({
  method: 'post',
  path: '/remote-paid-services',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const createTeamPlanFees = makeApiService({
  method: 'post',
  path: '/pricing/team-plan-fees',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

async function handleUpdateInitialContract({
  employmentSlug,
  contractParams,
  activeCompensationSlug,
  compensationParams,
}) {
  await updateInitialContract({
    pathParams: { employmentSlug },
    bodyParams: contractParams,
  });

  if (activeCompensationSlug && compensationParams) {
    return updateCompensation({
      pathParams: {
        contractSlug: contractParams.contractSlug,
        compensationSlug: activeCompensationSlug,
      },
      bodyParams: compensationParams,
    });
  }

  return null;
}

export async function handleUpdateContractAmendment({
  amendedContractSlug,
  contractParams,
  compensationParams,
}) {
  const bodyParams = {
    ...contractParams,
    ...(compensationParams ? { compensation: compensationParams } : {}),
  };

  return updateContractAmendment({
    pathParams: { contractSlug: amendedContractSlug },
    bodyParams,
  });
}

export async function handleUpdateContract({
  employmentSlug,
  amendedContractSlug,
  contractParams,
  activeCompensationSlug,
  compensationParams,
}) {
  const isAmendment = Boolean(amendedContractSlug);

  if (isAmendment) {
    await handleUpdateContractAmendment({
      amendedContractSlug,
      contractParams,
      compensationParams,
    });
  } else {
    await handleUpdateInitialContract({
      employmentSlug,
      contractParams,
      activeCompensationSlug,
      compensationParams,
    });
  }
}

export async function createContractAndCompensation(
  employmentSlug,
  contractParams,
  compensationParams
) {
  const response = await updateInitialContract({
    pathParams: { employmentSlug },
    bodyParams: contractParams,
  });

  if (!compensationParams?.amount && !compensationParams?.grossRegularHourlyRate) {
    return null;
  }

  const contractSlug = response.data.slug;

  return createCompensation({
    pathParams: {
      contractSlug,
    },
    bodyParams: compensationParams,
  });
}

const uploadContractDocument = makeApiService({
  method: 'post',
  path: '/contracts/[contractSlug]/files',
  version: VERSIONS.ADMIN_V1,
});

/**
 * NOTE: Upload one file at the time until API supports multiple files upload
 */
export async function uploadContractDocumentMulti(contractSlug, { files, notifyUser }) {
  const filesByName = Object.keys(files);
  return Promise.all(
    filesByName.map((fileName) =>
      uploadContractDocument({
        pathParams: { contractSlug },
        bodyParams: {
          file: { file: files[fileName], notifyUser },
        },
      })
    )
  );
}

export const createEmployee = makeApiService({
  method: 'post',
  path: '/employees',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const bulkCreateEmployees = makeApiService({
  method: 'post',
  path: '/employees/bulk-create',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
  // Do not remove this. It will break the import.
  deepTransformResponse: false,
});

const uploadUserFile = makeApiService({
  method: 'post',
  path: '/users/[userSlug]/files',
  version: VERSIONS.ADMIN_V1,
});

/**
 * NOTE: Upload one file at the time until API supports multiple files upload
 */
export async function uploadUserFileMulti({ files, ...params }, userSlug) {
  const filesByName = Object.keys(files);
  return Promise.all(
    filesByName.map((fileName) =>
      uploadUserFile({
        pathParams: { userSlug },
        bodyParams: {
          file: {
            ...params,
            file: files[fileName],
            isPrivate: params.isPrivate ?? false,
            description: params.description ?? null,
            type: params.type ?? DOCUMENT_TYPES.PERSONAL,
            subType: params.subType ?? null,
          },
        },
      })
    )
  );
}

const uploadMobilityFile = makeApiService({
  path: '/employments/[slug]/mobility-documents',
  method: 'post',
  version: VERSIONS.ADMIN_V1,
});

/**
 * NOTE: Upload one file at the time until API supports multiple files upload
 */
export async function uploadMobilityFileMulti({ files, ...params }, slug) {
  const filesByName = Object.keys(files);
  return Promise.all(
    filesByName.map((fileName) =>
      uploadMobilityFile({
        pathParams: { slug },
        bodyParams: {
          file: {
            ...params,
            file: files[fileName],
            isPrivate: true,
            description: params.description ?? null,
            type: params.type ?? DOCUMENT_TYPES.MOBILITY,
            subType: params.subType ?? null,
          },
        },
      })
    )
  );
}

const uploadOffboardingFile = makeApiService({
  method: 'post',
  path: '/offboardings/[offboardingSlug]/files',
  version: VERSIONS.ADMIN_V1,
});

/**
 * NOTE: Upload one file at the time until API supports multiple files upload
 */
export async function uploadOffboardingFileMulti(
  offboardingSlug,
  { files, notifyUser, isPrivate }
) {
  return Promise.all(
    Object.values(files).map((file) => {
      return uploadOffboardingFile({
        pathParams: { offboardingSlug },
        bodyParams: {
          file: { file, notifyUser, isPrivate },
        },
      });
    })
  );
}

export const updateEmployment = makeApiService({
  method: 'patch',
  path: '/employments/[employmentSlug]',
  version: VERSIONS.ADMIN_V1,
  asFormData: false,
});

export const updateEmploymentDiscount = makeApiService({
  method: 'patch',
  path: '/employments/[slug]/product-discount',
  version: VERSIONS.ADMIN_V1,
  asFormData: false,
});

export const createPayment = makeApiService({
  method: 'post',
  path: '/outstanding-payments',
  version: VERSIONS.ADMIN_V1,
  asFormData: false,
});

export const fetchAdminComplianceSettings = makeApiService({
  method: 'get',
  path: '/companies/[slug]/compliance-settings',
  version: VERSIONS.ADMIN_V1,
});

export const updateAdminComplianceSettings = makeApiService({
  method: 'put',
  path: '/companies/[slug]/compliance-settings',
  version: VERSIONS.ADMIN_V1,
  asFormData: false,
});

export const fetchTransactionReceiptData = makeApiService({
  path: '/transaction-receipts/[slug]',
  version: VERSIONS.ADMIN_V1,
});

export const fetchCompanyAdminsList = makeApiService({
  path: '/companies/[companySlug]/team-members',
  version: VERSIONS.ADMIN_V1,
});

export const updateProvisionalStartDate = makeApiService({
  path: '/contracts/[slug]/provisional-start-date',
  method: 'patch',
  version: VERSIONS.ADMIN_V1,
});

export const fetchPaymentXML = makeApiService({
  method: 'post',
  path: '/payment-file/generate',
  asFormData: false,
  version: VERSIONS.ADMIN_V1,
});

export const fetchCompanyTeamPlans = makeApiService({
  path: '/team-plans?company_slug=[companySlug]',
  version: VERSIONS.ADMIN_V1,
});

export const fetchTeamPlanSeats = makeApiService({
  path: '/team-plans/[slug]/seats',
  version: VERSIONS.ADMIN_V1,
});

export const archiveCompanyTeamPlan = makeApiService({
  method: 'post',
  path: '/team-plans/[slug]/archive',
  version: VERSIONS.ADMIN_V1,
});

export const createDocumentRequest = makeApiService({
  method: 'post',
  path: '/document-requests',
  version: VERSIONS.ADMIN_V1,
});

export const updateDocumentRequest = makeApiService({
  method: 'patch',
  asFormData: false,
  path: '/document-requests/[slug]',
  version: VERSIONS.ADMIN_V1,
});

export const cancelScheduledPaymentAsAdmin = makeApiService({
  method: 'post',
  path: '/rivendell/outstanding-payments/[slug]/unschedule',
});
