import { Box } from '@remote-com/norma';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PopoverContextProvider } from './PopoverContext';

const StyledDiv = styled(Box)`
  display: inline-block;
  position: relative;
`;

/**
 * This component lacks some usability and accessibility features, and should be replaced with @radix-ui/react-popover to align with project standards.
 * A proof of concept has been successfully implemented, demonstrating this migration alongside the duplication of styles from the current implementation.
 * Refer to the implementation in `src/domains/timeoff/employer/TimeOffTimeline/TimeOffEvent/TimeOffEvent.tsx` for guidance.
 * For further details, please consult the corresponding merge request: [MR#25067](https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/25067).
 */
const Popover = ({ children, ...props }) => (
  <PopoverContextProvider>
    <StyledDiv {...props}>{children}</StyledDiv>
  </PopoverContextProvider>
);

Popover.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Popover;
