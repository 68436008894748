import capitalize from 'lodash/capitalize';

import { ButtonInline } from '@/src/components/Button';
import {
  composeFieldSelect,
  composeFieldText,
} from '@/src/components/Form/DynamicForm/composeFields';
import { convertToCents } from '@/src/helpers/currency';
import { baseString, genericRequiredLabel } from '@/src/helpers/validationSchema';

import {
  australiaCountryCode,
  canadaCountryCode,
  usCountryCode,
  ukraineCountryCode,
  argentinaCountryCode,
  brazilCountryCode,
  colombiaCountryCode,
  japanCountryCode,
  kyrgyzstanCountryCode,
  lithuaniaCountryCode,
  mexicoCountryCode,
  moroccoCountryCode,
  newZealandCountryCode,
  southAfricaCountryCode,
  indiaCountryCode,
  irelandCountryCode,
  nigeriaCountryCode,
  unitedArabEmiratesCountryCode,
  philippinesCountryCode,
  southKoreaCountryCode,
  switzerlandCountryCode,
  ukCountryCode,
  pakistanCountryCode,
  ugandaCountryCode,
  paraguayCountryCode,
  tunisiaCountryCode,
  jamaicaCountryCode,
  thailandCountryCode,
  zimbabweCountryCode,
  spainCountryCode,
  italyCountryCode,
} from './countryCodes.constants';
import {
  argentinaProvinces,
  canadaProvinces,
  australiaStates,
  usStates,
  brazilStates,
  availableUkraineProvinces,
  pakistanProvinces,
  spainAutonomousCommunities,
  italyProvinces,
  irelandCounties,
  southAfricaProvinces,
  mexicoStates,
  abuDhabiResidentialLocations,
  moroccoStates,
  colombiaStates,
  newZealandStates,
  nigeriaStatesLocalGovernmentAreas,
  switzerlandCantons,
  indiaStates,
  philippinesProvinces,
  availableCanadianProvinces,
  availableUSStates,
} from './states.constants';

export * from './states.constants';
export * from './dialCodes.constants';
export * from './countryCodes.constants';

export const COUNTRIES_PAGE_TABS = {
  COUNTRY_DETAILS: 'country-details',
  FORM_EDITOR: 'form-editor',
  COUNTRY_FACTS: 'country-facts',
  FORM_PREVIEW: 'form-preview',
  FIELD_INVENTORY: 'field-inventory',
  API_MIGRATION_STATUS: 'api-migration-status',
};

const buildStateOptionsWithCodes = (stateOptions) =>
  Object.entries(stateOptions).map(([stateCode, stateLabel]) => ({
    value: stateCode,
    label: `${stateLabel} (${stateCode})`,
  }));

const buildOptionsFromList = (list = []) =>
  list.map((value) => ({
    value,
    label: value,
  }));

const buildOptionsWithNames = (stateOptions) =>
  Object.entries(stateOptions).map(([stateName]) => ({
    value: stateName,
    label: stateName,
  }));

const buildOptionsByValue = (value, list) => {
  const itemsList = list[value];
  return itemsList && itemsList.length !== 0 ? itemsList.map((v) => ({ value: v, label: v })) : [];
};

export const argentinaProvincesOptions = buildOptionsFromList(argentinaProvinces);
export const canadaProvincesOptions = buildStateOptionsWithCodes(canadaProvinces);
export const australiaStatesOptions = buildStateOptionsWithCodes(australiaStates);
export const usStatesOptions = buildStateOptionsWithCodes(usStates);
export const brazilStatesOptions = buildStateOptionsWithCodes(brazilStates);
export const ukraineProvinceOptions = buildOptionsFromList(availableUkraineProvinces);
export const pakistanProvinceOptions = buildOptionsFromList(pakistanProvinces);
export const spainAutonomousCommunityOptions = buildOptionsFromList(spainAutonomousCommunities);
export const italyProvinceOptions = buildOptionsFromList(italyProvinces);
export const irelandCountyOptions = buildOptionsFromList(irelandCounties);
export const abuDhabiResidentialLocationsOptions = buildOptionsFromList(
  abuDhabiResidentialLocations
);

export const southAfricaProvincesOptions = buildOptionsFromList(southAfricaProvinces);
export const mexicoStatesOptions = buildOptionsFromList(mexicoStates);
export const moroccoStatesOptions = buildOptionsFromList(moroccoStates);
export const colombiaStatesOptions = buildOptionsFromList(colombiaStates);
export const newZealandStatesOptions = buildOptionsFromList(newZealandStates);
export const nigeriaStatesOptions = buildOptionsWithNames(nigeriaStatesLocalGovernmentAreas);
export const switzerlandCantonsOptions = buildOptionsFromList(switzerlandCantons);
export const indiaStatesOptions = buildOptionsFromList(indiaStates);

export const philippinesProvincesOptions = philippinesProvinces.map((state) => ({
  value: state,
  label: state,
}));

export const stateTypes = {
  PROVINCE: 'province',
  STATE: 'state',
  STATE_TERRITORY: 'state_territory',
  STATE_REGION: 'state_region',
  CANTON: 'canton',
  COUNTY: 'county',
  AUTONOMOUS_COMMUNITY: 'autonomous_community',
};

const addressTypes = {
  P_O_BOX: 'P.O. box',
  PHYSICAL: 'Physical',
  PRIVATE_BAG: 'Private bag',
  STREET: 'Street',
};

const moroccoAddressTypes = [
  addressTypes.PHYSICAL,
  addressTypes.STREET,
  addressTypes.P_O_BOX,
  addressTypes.PRIVATE_BAG,
];

export const moroccoAddressTypeOptions = buildOptionsFromList(moroccoAddressTypes);

const formatFieldValues = ({ name, label, description, required }) => ({
  name,
  label: label || capitalize(name),
  description,
  required,
});

export const countryAddressDetails = {
  [argentinaCountryCode]: {
    states: {
      type: stateTypes.PROVINCE,
      options: argentinaProvincesOptions,
    },
    localDetails: [composeFieldText(formatFieldValues({ name: 'municipality' }))],
  },
  [australiaCountryCode]: {
    states: {
      type: stateTypes.STATE_TERRITORY,
      options: australiaStatesOptions,
    },
    localDetails: [composeFieldText(formatFieldValues({ name: 'suburb' }))],
  },
  [brazilCountryCode]: {
    states: {
      type: stateTypes.STATE,
      options: brazilStatesOptions,
    },
    localDetails: [
      composeFieldText(
        formatFieldValues({
          name: 'district',
          label: 'District',
          description: 'Your neighborhood name',
        })
      ),
    ],
  },
  [canadaCountryCode]: {
    states: {
      type: stateTypes.PROVINCE,
      options: canadaProvincesOptions,
    },
  },
  [colombiaCountryCode]: {
    states: {
      type: stateTypes.STATE,
      options: colombiaStatesOptions,
    },
  },
  [japanCountryCode]: {
    displayOnlyRequiredFields: true,
    customLabels: {
      postalCode: {
        label: 'Postal code (no hyphens)',
        description: '郵便番号ハイフン不要',
      },
      address: {
        label: 'Address (Japanese character)',
        description: '住所（日本語）',
      },
    },
    localDetails: [
      composeFieldText(
        formatFieldValues({
          name: 'katakanaAddress',
          label: 'Address (Japanese katakana character)',
          description: '住所（フリガナ）',
        })
      ),
    ],
  },
  [kyrgyzstanCountryCode]: {
    localDetails: [
      composeFieldText(
        formatFieldValues({
          name: 'localityName',
          label: 'Locality name',
        })
      ),
    ],
  },
  [lithuaniaCountryCode]: {
    localDetails: [
      composeFieldText(
        formatFieldValues({
          name: 'postalCounty',
          label: 'Postal county',
        })
      ),
    ],
  },
  [mexicoCountryCode]: {
    states: {
      type: stateTypes.STATE,
      options: mexicoStatesOptions,
    },
  },
  [moroccoCountryCode]: {
    states: {
      type: stateTypes.STATE_REGION,
      options: moroccoStatesOptions,
    },
    localDetails: [
      composeFieldSelect({
        ...formatFieldValues({ name: 'addressType', label: 'Address type' }),
        options: moroccoAddressTypeOptions,
      }),
    ],
  },
  [newZealandCountryCode]: {
    states: {
      type: stateTypes.STATE,
      options: newZealandStatesOptions,
    },
    localDetails: [composeFieldText({ ...formatFieldValues({ name: 'suburb' }), required: false })],
  },
  [unitedArabEmiratesCountryCode]: {
    localDetails: [
      composeFieldSelect({
        ...formatFieldValues({
          name: 'residential',
          label: 'Residential location',
        }),
        required: true,
        options: abuDhabiResidentialLocationsOptions,
      }),
    ],
  },
  [nigeriaCountryCode]: {
    states: {
      type: stateTypes.STATE,
      options: nigeriaStatesOptions,
      onChange: ({ value, previousValue, setValue }) => {
        if (value !== previousValue) {
          setValue('localDetails.localGovernmentArea', '');
        }
      },
    },
    localDetails: [
      composeFieldSelect({
        ...formatFieldValues({
          name: 'localGovernmentArea',
          label: 'Local government area',
        }),
        schemaType: baseString.required(genericRequiredLabel),
        calculateDynamicProperties: (values) => {
          const stateValue = values.address?.state || values.state;
          return {
            options: buildOptionsByValue(stateValue, nigeriaStatesLocalGovernmentAreas),
          };
        },
      }),
    ],
  },
  [philippinesCountryCode]: {
    states: {
      type: stateTypes.PROVINCE,
      options: philippinesProvincesOptions,
    },
    localDetails: [composeFieldText(formatFieldValues({ name: 'barangay' }))],
  },
  [southAfricaCountryCode]: {
    states: {
      type: stateTypes.PROVINCE,
      options: southAfricaProvincesOptions,
    },
  },
  [southKoreaCountryCode]: {
    localDetails: [composeFieldText(formatFieldValues({ name: 'province', label: 'Province' }))],
  },
  [switzerlandCountryCode]: {
    states: {
      type: stateTypes.CANTON,
      options: switzerlandCantonsOptions,
    },
  },
  [ukCountryCode]: {
    customLabels: {
      postalCode: {
        description: 'Enter the correct UK post code format. Eg: KY11 9NN, N1 2NU, LS1 8EQ',
      },
    },
  },
  [ukraineCountryCode]: {
    states: {
      type: stateTypes.PROVINCE,
      options: ukraineProvinceOptions,
    },
  },
  [pakistanCountryCode]: {
    states: {
      type: stateTypes.PROVINCE,
      options: pakistanProvinceOptions,
    },
  },
  [spainCountryCode]: {
    states: {
      type: stateTypes.AUTONOMOUS_COMMUNITY,
      options: spainAutonomousCommunityOptions,
    },
  },
  [italyCountryCode]: {
    states: {
      type: stateTypes.PROVINCE,
      options: italyProvinceOptions,
    },
  },
  [irelandCountryCode]: {
    states: {
      type: stateTypes.COUNTY,
      options: irelandCountyOptions,
    },
  },
  [usCountryCode]: {
    states: {
      type: stateTypes.STATE,
      options: usStatesOptions,
    },
  },
  [indiaCountryCode]: {
    states: {
      type: stateTypes.STATE,
      options: indiaStatesOptions,
    },
  },
  [paraguayCountryCode]: {
    customLabels: {
      address: {
        description: (
          <>
            Street name and house number
            <span lang="es-PY"> "calle principal" </span>
          </>
        ),
      },
      addressLine2: {
        description: (
          <>
            Crossing street name
            <span lang="es-PY"> "calle transversal" </span>
          </>
        ),
      },
    },
    localDetails: [
      composeFieldText(
        formatFieldValues({
          name: 'neighborhood',
          label: 'Neighborhood',
          description: (
            <>
              Neighborhood from Spanish
              <span lang="es-PY"> "Barrio" </span>
            </>
          ),
        })
      ),
      composeFieldText(
        formatFieldValues({
          name: 'department',
          label: 'Department',
          description: (
            <>
              Department from Spanish
              <span lang="es-PY"> "Departmento" </span>
            </>
          ),
        })
      ),
    ],
  },
  [ugandaCountryCode]: {
    hidePostalCode: true,
    localDetails: [
      composeFieldText(formatFieldValues({ name: 'addressParish', label: 'Parish' })),
      composeFieldText(formatFieldValues({ name: 'addressVillage', label: 'Village' })),
      composeFieldText(
        formatFieldValues({ name: 'addressSubcountyCounty', label: 'Subcounty/County' })
      ),
      composeFieldText(formatFieldValues({ name: 'addressDistrict', label: 'District' })),
    ],
  },
  [tunisiaCountryCode]: {
    localDetails: [
      composeFieldText(
        formatFieldValues({
          name: 'apartmentNumber',
          label: 'Apartment number',
          description: '(Optional)',
          required: false,
        })
      ),
      composeFieldText(formatFieldValues({ name: 'buildingNumber', label: 'Building number' })),
      composeFieldText(
        formatFieldValues({ name: 'governorate', label: 'Governorate - State/Town' })
      ),
      composeFieldText(formatFieldValues({ name: 'municipality', label: 'Municipality' })),
      composeFieldText(formatFieldValues({ name: 'district', label: 'District' })),
    ],
  },
  [jamaicaCountryCode]: {
    localDetails: [
      composeFieldText(formatFieldValues({ name: 'state', label: 'State, province, or parish' })),
    ],
  },
  [thailandCountryCode]: {
    customLabels: {
      address: {
        label: 'Street',
        description: 'Your street name',
      },
      addressSecondary: {
        label: 'House number',
      },
    },
    localDetails: [
      composeFieldText(formatFieldValues({ name: 'addressBuildingName', label: 'Building name' })),
      composeFieldText(formatFieldValues({ name: 'addressMoo', label: 'Moo' })),
      composeFieldText(formatFieldValues({ name: 'addressSubdistrict', label: 'Subdistrict' })),
      composeFieldText(formatFieldValues({ name: 'addressDistrict', label: 'District' })),
    ],
  },
  [zimbabweCountryCode]: {
    hidePostalCode: true,
  },
};

// EOR is not available for all states for some countries.
export const countriesWithLimitedStateAvailability = [
  australiaCountryCode,
  canadaCountryCode,
  usCountryCode,
  ukraineCountryCode,
];

export const availableStateOptions = {
  [australiaCountryCode]: buildStateOptionsWithCodes(australiaStates),
  [canadaCountryCode]: buildStateOptionsWithCodes(availableCanadianProvinces),
  [usCountryCode]: buildStateOptionsWithCodes(availableUSStates),
  [ukraineCountryCode]: buildOptionsFromList(availableUkraineProvinces),
};

export const contractDetailsStateKeys = {
  [australiaCountryCode]: 'state',
  [canadaCountryCode]: 'provinceOfResidency',
  [usCountryCode]: 'stateOfResidency',
  [ukraineCountryCode]: 'state',
};

export const QUERIES = {
  HOLIDAYS: 'holidays',
  ALL_HOLIDAYS: 'all-holidays',
  COUNTRY_SUBDIVISION_HOLIDAYS: 'country-subdivision-holidays',
  COUNTRY_SUBDIVISION_EMPLOYER: 'country-subdivision-employer',
  COUNTRY_SUBDIVISION_EMPLOYEE: 'country-subdivision-employee',
};

export const countryFormsNames = {
  ADMINISTRATIVE_DETAILS: 'administrative-details',
  CONTRACT_DETAILS: 'contract-details',
  CONTRACT_AMENDMENTS: 'contract-amendments',
  ADDITIONAL_DOCUMENTS: 'additional-documents',
  ADDRESS_DETAILS: 'address-details',
  BANK_ACCOUNT_DETAILS: 'bank-account-details',
  EMERGENCY_CONTACT: 'emergency-contact',
  EMPLOYMENT_BASIC_INFORMATION: 'employment-basic-information',
  EMPLOYMENT_ELIGIBILITY: 'employment-eligibility',
  PERSONAL_DETAILS: 'personal-details',
  GLOBAL_PAYROLL_ADMINISTRATIVE_DETAILS: 'global-payroll-administrative-details',
  GLOBAL_PAYROLL_CONTRACT_DETAILS: 'global-payroll-contract-details',
  GLOBAL_PAYROLL_PERSONAL_DETAILS: 'global-payroll-personal-details',
  DIRECT_CONTRACT_DETAILS: 'direct-contract-details',
};

export const fullyMigratedForms = [
  countryFormsNames.EMERGENCY_CONTACT,
  countryFormsNames.EMPLOYMENT_BASIC_INFORMATION,
  countryFormsNames.EMPLOYMENT_ELIGIBILITY,
  countryFormsNames.PERSONAL_DETAILS,
];

export const fullyMigratedFeatures = [
  'json-schema--emergency-contact',
  'json-schema--personal-details',
  'json-schema--employment-basic-information',
  'json-schema--employment-eligibility',
];

export const employeeBasedCountryFormsNames = {
  [countryFormsNames.ADMINISTRATIVE_DETAILS]: 'employee-administrative-details',
  [countryFormsNames.CONTRACT_DETAILS]: 'contract-details',
  [countryFormsNames.ADDRESS_DETAILS]: 'address-details',
  [countryFormsNames.PERSONAL_DETAILS]: 'personal-details',
  [countryFormsNames.EMERGENCY_CONTACT]: 'emergency-contact-details',
  [countryFormsNames.EMPLOYMENT_BASIC_INFORMATION]: 'employment-basic-information',
  // Note: It's commented because although supported by the API validation, is not supported on the FE yet
  // [countryFormsNames.BANK_ACCOUNT_DETAILS]: 'employee-bank-account-details',
};

export const contractorBasedCountryFormsNames = {
  ...employeeBasedCountryFormsNames,
  [countryFormsNames.ADMINISTRATIVE_DETAILS]: 'contractor-administrative-details',
};

export const countryFormsNamesToFeature = {
  [countryFormsNames.ADMINISTRATIVE_DETAILS]: 'json-schema--administrative-details',
  [countryFormsNames.CONTRACT_DETAILS]: 'json-schema--contract-details',
  [countryFormsNames.CONTRACT_AMENDMENTS]: 'json-schema--contract-amendment',
  [countryFormsNames.ADDITIONAL_DOCUMENTS]: 'json-schema--additional-documents',
  [countryFormsNames.ADDRESS_DETAILS]: 'json-schema--address-details',
  [countryFormsNames.BANK_ACCOUNT_DETAILS]: 'json-schema--bank-account-details',
  [countryFormsNames.EMPLOYMENT_BASIC_INFORMATION]: 'json-schema--employment-basic-information',
  [countryFormsNames.EMPLOYMENT_ELIGIBILITY]: 'json-schema--employment-eligibility',
  [countryFormsNames.GLOBAL_PAYROLL_ADMINISTRATIVE_DETAILS]:
    'json-schema--global-payroll-administrative-details',
  [countryFormsNames.GLOBAL_PAYROLL_CONTRACT_DETAILS]:
    'json-schema--global-payroll-contract-details',
  [countryFormsNames.GLOBAL_PAYROLL_PERSONAL_DETAILS]:
    'json-schema--global-payroll-personal-details',
};

export const apiFormName = {
  [countryFormsNamesToFeature[countryFormsNames.ADMINISTRATIVE_DETAILS]]: 'Administrative Details',
  [countryFormsNamesToFeature[countryFormsNames.CONTRACT_DETAILS]]: 'Contract Details',
  [countryFormsNamesToFeature[countryFormsNames.CONTRACT_AMENDMENTS]]: 'Contract Amendments',
  [countryFormsNamesToFeature[countryFormsNames.EMPLOYMENT_BASIC_INFORMATION]]:
    'Employment Basic Information',
  [countryFormsNamesToFeature[countryFormsNames.ADDITIONAL_DOCUMENTS]]: 'Additional Documents',
  [countryFormsNamesToFeature[countryFormsNames.ADDRESS_DETAILS]]: 'Address Details',
  [countryFormsNamesToFeature[countryFormsNames.BANK_ACCOUNT_DETAILS]]: 'Bank Account Details',
  [countryFormsNamesToFeature[countryFormsNames.EMERGENCY_CONTACT]]: 'Emergency Contact',
  [countryFormsNamesToFeature[countryFormsNames.EMPLOYMENT_ELIGIBILITY]]: 'Employment Eligibility',
  [countryFormsNamesToFeature[countryFormsNames.PERSONAL_DETAILS]]: 'Personal Details',
  [countryFormsNamesToFeature[countryFormsNames.GLOBAL_PAYROLL_ADMINISTRATIVE_DETAILS]]:
    'Global Payroll Administrative Details',
  [countryFormsNamesToFeature[countryFormsNames.GLOBAL_PAYROLL_CONTRACT_DETAILS]]:
    'Global Payroll Contract Details',
  [countryFormsNamesToFeature[countryFormsNames.GLOBAL_PAYROLL_PERSONAL_DETAILS]]:
    'Global Payroll Personal Details',
  [countryFormsNamesToFeature[countryFormsNames.DIRECT_CONTRACT_DETAILS]]:
    'Direct Contract Details',
};

export const formNameOptions = [
  {
    value: countryFormsNames.CONTRACT_DETAILS,
    description:
      'Filled in by the customer to initiate the employee onboarding. Used to collect data for the Employment Agreement for the EOR (Employer of Record) product.',
    type: 'EOR',
    label: 'Contract Details',
  },
  {
    value: countryFormsNames.GLOBAL_PAYROLL_CONTRACT_DETAILS,
    description:
      'Filled in by the customer to initiate the employee onboarding. Used to collect data for the Employment Agreement for the GP (Global Payroll) product.',
    type: 'global_payroll',
    label: 'Contract Details',
  },
  {
    value: countryFormsNames.DIRECT_CONTRACT_DETAILS,
    description:
      'Filled in by the customer to initiate the employee onboarding. Used to collect data for the Employment Agreement for the HRIS product.',
    type: 'direct',
    label: 'Contract Details',
  },
  {
    value: countryFormsNames.CONTRACT_AMENDMENTS,
    description:
      'Filled in by the customer to initiate an amendment to an Employment Agreement. Used for the EOR (Employer of Record) product.',
    type: 'EOR',
    label: 'Contract Amendments',
  },
  {
    value: countryFormsNames.ADMINISTRATIVE_DETAILS,
    type: 'EOR',
    description:
      'Filled in during self-enrolment of an employee. This form is used for tax compliance and other administrative purposes.',
    label: 'Administrative Details',
  },
  {
    value: countryFormsNames.GLOBAL_PAYROLL_ADMINISTRATIVE_DETAILS,
    description:
      'Filled in by the customer during employee onboarding. Used to collect data for tax compliance and other administrative purposes.',
    type: 'global_payroll',
    label: 'Administrative Details',
  },
  {
    value: countryFormsNames.ADDITIONAL_DOCUMENTS,
    type: 'EOR',
    label: 'Additional Documents',
  },
  {
    value: countryFormsNames.ADDRESS_DETAILS,
    type: 'multi',
    label: 'Address Details',
  },
  {
    value: countryFormsNames.BANK_ACCOUNT_DETAILS,
    label: 'Bank Account Details',
    type: 'multi',
  },
  {
    value: countryFormsNames.EMERGENCY_CONTACT,
    type: 'multi',
    label: 'Emergency Contact',
  },
  {
    value: countryFormsNames.GLOBAL_PAYROLL_PERSONAL_DETAILS,
    type: 'global_payroll',
    label: 'Personal Details',
  },
  {
    value: countryFormsNames.PERSONAL_DETAILS,
    type: 'multi',
    label: 'Personal Details',
  },
  {
    value: countryFormsNames.EMPLOYMENT_ELIGIBILITY,
    type: 'EOR',
    label: 'Employment Eligibility',
  },
  {
    value: countryFormsNames.EMPLOYMENT_BASIC_INFORMATION,
    type: 'multi',
    label: 'Employment Basic Information',
  },
];

// TODO: add all other country features to this map; to be revised on this task:
// https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/2135
export const countryFeatures = {
  INTERNAL: 'internal',
};

export const countryFeaturesLabels = {
  [countryFeatures.INTERNAL]: 'Internal employment only',
};

export const weekendWeekDays = [0, 6];

/**
 * @typedef CountryOBConfig
 * @type {object}
 * @property {number[]} blockedDays
 * @property {number} minAnnualGrossSalary
 * @property {number} minHourlySalary
 * @property {number} integerCurrencyOnly
 * @property {number} minPTOPerWorkedHour
 */

/**
 * blockedDays is an array of weekdays in numbers i.e Sunday = 0, Monday = 1, Tuesday = 2 ...
 * blockedDays values are from https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/14487#note_1108982864
 * Values for the min annual salary can be found within this table: https://www.notion.so/remotecom/Minimum-annual-gross-salary-per-country-DRAFT-f12a8f67dd084b849dbccabe6802631c
 *
 *
 * @returns {Object.<string, CountryOBConfig>}
 */
export const getCountriesOnboardingConfig = () => {
  return {
    BGR: {
      blockedDays: [...weekendWeekDays],
    },
    BOL: {
      blockedDays: [...weekendWeekDays],
    },
    BRA: {
      blockedDays: [...weekendWeekDays],
    },
    COL: {
      blockedDays: [...weekendWeekDays],
    },
    ECU: {
      blockedDays: [...weekendWeekDays],
    },
    HND: {
      blockedDays: [...weekendWeekDays],
    },
    ISL: {
      blockedDays: [...weekendWeekDays],
    },
    KOR: {
      integerCurrencyOnly: true,
    },
    MEX: {
      blockedDays: [...weekendWeekDays],
    },
    NLD: {
      minPTOPerWorkedHour: 0.5,
    },
    PAN: {
      blockedDays: [...weekendWeekDays],
    },
    PER: {
      blockedDays: [...weekendWeekDays],
    },
    PRY: {
      blockedDays: [...weekendWeekDays],
    },
    SVN: {
      minAnnualGrossSalary: convertToCents(13967.59),
      maxWorkingHoursWeek: 40,
    },
    URY: {
      blockedDays: [...weekendWeekDays],
    },
    USA: {
      // For now, USA is the only country where we're aware of the need of a
      // direct deposit authorisation on the Bank Details. We're exposing this
      // as a country configuration here rather than an exception on the
      // Bank Details form for the sake of visibility and potential scalability.
      //
      // If more countries implement the same or a similar type of authorisation
      // that requires additional fields on the Bank Details, this should be
      // moved to a country-specific KDB Fact configuration similarly to MOTs.
      //
      // More about KDB facts:
      // https://www.notion.so/remotecom/KDB-Facts-43b0391ad4ec4794bafbfa2145589bcc
      requiresDirectDepositAuthorization: true,

      // If a country has a setup to collect tax & compliance information during
      // the onboarding, we create "tax-tasks" when the self-enrollment is finished
      // which represent the different actions the employee in order to complete
      // their tax enrollment after their self-enrollment.
      //
      // However, USA has an external dependency (Gusto Embedded) to create those
      // tasks, where we depend on their API to tell us which information is required
      // depending on the employee's state. This is done through Oban workers on
      // Tiger in an asynchronous way.
      //
      // This means there's a chance the tasks won't be created by the time the
      // self-enrollment is finished and the employee is redirected to the dashboard.
      // This only takes a few seconds, but if they proceed fast enough they'll
      // reach the dashboard while the tax & compliance page and to-do list are
      // still not available.
      //
      // By using this flag, we'll wait to present the self-enrollment completion
      // screen until we know that the tax tasks are ready.
      //
      // See EOR-635 for more details.
      hasAsyncTaxTasks: true,
    },
  };
};

export const tooltipDateLabels = {
  HOLIDAY_SEASON:
    'Due to holiday season and the limited availability of internal/external providers to process onboarding, payroll and benefits enrolments, you cannot select this date.',
  BLOCKED_WEEKEND: 'You cannot select a weekend day as the start date.',
  PASSED_DATE: 'Dates in the past cannot be selected.',
  ALLOWED_WEEKEND:
    "You'll need to make additional statutory payments if the start date is a non-typical working day.",
  HOLIDAY: (
    <>
      You cannot select a public holiday in the employee’s country as their start date. Please
      contact <ButtonInline href="mailto:help@remote.com">help@remote.com</ButtonInline> for more
      advice.
    </>
  ),
  MAINTENANCE_SPAIN: (
    <>
      The start date cannot be Dec 15 or 16. We’re doing maintenance work to improve our services in
      Spain on those days. For more information, please contact{' '}
      <ButtonInline href="mailto:help@remote.com">help@remote.com</ButtonInline>.
    </>
  ),
};

export const minimumGrossSalaryDisclaimers = {
  BY_STATE: 'BY_STATE',
  BY_YEARS_OF_SERVICE: 'BY_YEARS_OF_SERVICE',
  BY_STATE_AND_YEARS_OF_SERVICE: 'BY_STATE_AND_YEARS_OF_SERVICE',
  BY_AGE: 'BY_AGE',
};

/** the default MOT countries without a config */
export const DEFAULT_MINIMUM_ONBOARDING_TIME = 20;

/**
 * this is used as a route query param when the provisionalStartDate is invalid before sending and invite
 */
export const HAS_INVALID_PROVISIONAL_START_DATE = 'hasInvalidprovisionalStartDate';

export const COUNTRY_FACTS_NAMES = {
  MOT: 'mot',
  SELF_ENROLLMENT_EOR_INFORMATION: 'self_enrollment_eor_information',
  CURRENT_ONBOARDING_LIMITATIONS: 'current_onboarding_limitations',
  OFFBOARDING_WARNING: 'offboarding_warning',
  OFFBOARDING_DATE_WARNINGS: 'offboarding_date_warnings',
  EOR_SALARY_AMENDMENT_INFORMATION: 'eor_salary_amendment_information',
};
