import { toast } from '@remote-com/norma';

import { ModalActionable } from '@/src/components/Modal/ModalActionable';
import { useDeleteTableView } from '@/src/components/Table/hooks/useDeleteTableView';

type DeleteTableViewModalProps = {
  name: string;
  slug: string;
  hideModal: () => void;
  open: boolean;
  setListenToClickOutside: (listen: boolean) => void;
  messages: {
    title: string;
    description: (descriptionName: string) => string;
    deleteButton: string;
  };
  onDelete?: () => void;
};

export const DeleteTableViewModal = ({
  open,
  slug,
  name,
  hideModal,
  setListenToClickOutside,
  messages,
  onDelete,
}: DeleteTableViewModalProps) => {
  const { deleteTableView, isLoading: isDeleting } = useDeleteTableView();

  function closeModal() {
    setListenToClickOutside(true);
    hideModal();
  }

  const onSuccess = () => {
    closeModal();
    toast.success('Table view deleted');
    onDelete?.();
  };

  async function handleConfirm() {
    await deleteTableView({
      tableViewSlug: slug,
      onSuccess,
      onError: () => toast.error('Failed to delete table view.'),
    });
  }

  return (
    <ModalActionable
      title={messages.title}
      description={messages.description(name)}
      confirmLabel={messages.deleteButton}
      onConfirm={handleConfirm}
      onCancel={closeModal}
      tone="destructive"
      open={open}
      isLoading={isDeleting}
    />
  );
};
