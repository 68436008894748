import styled from 'styled-components';

import { Box, Stack } from '../../layout';

export const Fieldset = styled(Stack).attrs({
  forwardedAs: 'fieldset',
  gap: 3,
  direction: 'row',
  flexWrap: 'wrap',
})`
  border: none;
  padding: 0;
  margin: 0;
  /** Making sure the phone input grows and has a minimum width*/
  > :nth-child(2) {
    flex: 1;
    min-width: 200px;
  }
`;

/**
 * Auxiliary input that is not tabbable, nor clickable. It's used mainly as a "focus proxy". Once it receives focus (
 * after an ErrorSummary link click), it redirects the focus to the correct field. This is an one-off scenario, caused
 * because we're breaking a single formik field into 2 separate inputs.
 */
export const AuxiliaryInput = styled(Box).attrs({
  forwardedAs: 'input',
  type: 'text',
  tabIndex: -1,
  position: 'absolute',
  width: 0,
  height: 0,
})`
  pointer-events: none;
  opacity: 0;
`;
