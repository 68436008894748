import { generateSelectOptions } from '@/src/helpers/forms';

export const INFORMATION_REQUEST_STATUSES = {
  REQUESTED: 'requested',
  COMPLETED: 'completed',
};

export const INFORMATION_REQUEST_STATUS_BADGES = {
  [INFORMATION_REQUEST_STATUSES.REQUESTED]: 'pending',
  [INFORMATION_REQUEST_STATUSES.COMPLETED]: 'active',
};

export const INFORMATION_REQUEST_STATUS_LABELS = {
  [INFORMATION_REQUEST_STATUSES.REQUESTED]: 'Requested',
  [INFORMATION_REQUEST_STATUSES.COMPLETED]: 'Completed',
};

export const MOBILITY_REQUEST_TYPES = {
  TRAVEL_LETTER: 'travel_letter',
  REMOTE_WORK: 'remote_work',
};

export const MOBILITY_REQUEST_TYPE_LABELS = {
  [MOBILITY_REQUEST_TYPES.TRAVEL_LETTER]: 'Travel request',
  [MOBILITY_REQUEST_TYPES.REMOTE_WORK]: 'Remote work request',
};

export const commonMobilityRequestStatuses = {
  CANCELLED: 'cancelled',
  PENDING: 'pending',
  APPROVED_BY_REMOTE: 'approved_by_remote',
  APPROVED_BY_MANAGER: 'approved_by_manager',
  DECLINED_BY_REMOTE: 'declined_by_remote',
  DECLINED_BY_MANAGER: 'declined_by_manager',
  AWAITING_TAX_APPROVAL: 'awaiting_tax_approval',
};

export const COMMON_MOBILITY_REQUEST_STATUSES_BADGES = {
  [commonMobilityRequestStatuses.CANCELLED]: 'canceled',
  [commonMobilityRequestStatuses.PENDING]: 'pending',
  [commonMobilityRequestStatuses.APPROVED_BY_REMOTE]: 'active',
  [commonMobilityRequestStatuses.DECLINED_BY_REMOTE]: 'error',
  [commonMobilityRequestStatuses.APPROVED_BY_MANAGER]: 'active',
  [commonMobilityRequestStatuses.DECLINED_BY_MANAGER]: 'error',
  [commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL]: 'pending',
};

export const COMMON_MOBILITY_REQUEST_STATUSES_LABELS = {
  [commonMobilityRequestStatuses.CANCELLED]: 'Cancelled',
  [commonMobilityRequestStatuses.PENDING]: 'Submitted',
  [commonMobilityRequestStatuses.APPROVED_BY_REMOTE]: 'Approved by Remote',
  [commonMobilityRequestStatuses.DECLINED_BY_REMOTE]: 'Declined by Remote',
  [commonMobilityRequestStatuses.APPROVED_BY_MANAGER]: 'Approved by Manager',
  [commonMobilityRequestStatuses.DECLINED_BY_MANAGER]: 'Declined by Manager',
  [commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL]: 'Awaiting tax approval',
};

export const NON_REMOTE_ADMIN_REQUEST_STATUS_BADGES = {
  ...COMMON_MOBILITY_REQUEST_STATUSES_BADGES,
  [commonMobilityRequestStatuses.PENDING]: 'info',
  [commonMobilityRequestStatuses.APPROVED_BY_MANAGER]: 'info',
  [commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL]: 'info',
};

export const NON_REMOTE_ADMIN_REQUEST_STATUS_LABELS = {
  ...COMMON_MOBILITY_REQUEST_STATUSES_LABELS,
  [commonMobilityRequestStatuses.PENDING]: 'Awaiting manager approval',
  [commonMobilityRequestStatuses.APPROVED_BY_MANAGER]: 'Under review by Remote',
  [commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL]: 'Under review  by Remote',
};

export const mobilityRequestsApprovalStatusLabels = {
  [commonMobilityRequestStatuses.APPROVED_BY_REMOTE]: 'Approved',
  [commonMobilityRequestStatuses.DECLINED_BY_REMOTE]: 'Declined',
  [commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL]: 'Awaiting tax approval',
};

export const mobilityRequestsApprovalStatusLabelsOptions = generateSelectOptions(
  mobilityRequestsApprovalStatusLabels
);

export const mobilityRequestsStatusOptions = generateSelectOptions(
  COMMON_MOBILITY_REQUEST_STATUSES_LABELS
);

export const adminCanEditMobilityRequestStatuses = [
  commonMobilityRequestStatuses.DECLINED_BY_MANAGER,
  commonMobilityRequestStatuses.DECLINED_BY_REMOTE,
  commonMobilityRequestStatuses.APPROVED_BY_REMOTE,
  commonMobilityRequestStatuses.CANCELLED,
];

export const adminCanCancelMobilityRequestStatuses = [
  commonMobilityRequestStatuses.PENDING,
  commonMobilityRequestStatuses.DECLINED_BY_REMOTE,
  commonMobilityRequestStatuses.APPROVED_BY_MANAGER,
  commonMobilityRequestStatuses.DECLINED_BY_MANAGE,
  commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL,
];

export const defaultTableStatusFilter = [
  commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL,
  commonMobilityRequestStatuses.APPROVED_BY_MANAGER,
];
