// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineEyeOff = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M2.47 2.47c.3-.3.77-.3 1.06 0l18 18a.75.75 0 1 1-1.06 1.06l-2.4-2.4A11.5 11.5 0 0 1 12 20.75c-3.72 0-6.4-1.47-8.17-3.3C2.11 15.67 1.25 13.5 1.25 12c0-1.69 1.11-4.32 3.44-6.25L2.47 3.53a.75.75 0 0 1 0-1.06Zm3.28 4.35c-2.1 1.69-3 3.97-3 5.18 0 1.02.64 2.84 2.17 4.42A9.52 9.52 0 0 0 12 19.25c2.02 0 3.66-.48 4.98-1.21l-2.91-2.91a3.75 3.75 0 0 1-5.2-5.2L5.75 6.82Zm4.22 4.21a2.25 2.25 0 0 0 3 3l-3-3ZM12 4.75a11.03 11.03 0 0 0-3.27.48.75.75 0 0 1-.46-1.43l.54-.15c.97-.26 2.03-.4 3.19-.4 3.72 0 6.4 1.47 8.17 3.3 1.72 1.79 2.58 3.97 2.58 5.45 0 1.09-.46 2.53-1.35 3.9l-.38.55a.75.75 0 1 1-1.2-.9l.32-.46A6.22 6.22 0 0 0 21.25 12c0-1.02-.64-2.84-2.17-4.42A9.52 9.52 0 0 0 12 4.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineEyeOff);
export { ForwardRef as IconV2OutlineEyeOff };