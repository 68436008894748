// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneNoteText = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M10.85 22.75c-3.36 0-5.04 0-6.32-.65a6 6 0 0 1-2.63-2.63c-.65-1.28-.65-2.96-.65-6.32v-2.3c0-3.36 0-5.04.65-6.32A6 6 0 0 1 4.53 1.9c1.28-.65 2.96-.65 6.32-.65h2.3c3.36 0 5.04 0 6.32.65a6 6 0 0 1 2.63 2.63c.65 1.28.65 2.96.65 6.32v4.4h-2.7c-1.68 0-2.52 0-3.16.33a3 3 0 0 0-1.31 1.3c-.33.65-.33 1.49-.33 3.17v2.7h-4.4Z" /><path fillRule="evenodd" clipRule="evenodd" d="M8 7.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H8Zm0 4a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H8Zm0 4a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5H8Zm11.41 6.4c-.7.7-1.66 1.1-2.66 1.1v-2.8c0-1.12 0-1.68.22-2.1a2 2 0 0 1 .87-.88c.43-.22.99-.22 2.11-.22h2.8c0 1-.4 1.96-1.1 2.66l-2.24 2.24Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneNoteText);
export { ForwardRef as IconV2DuotoneNoteText };