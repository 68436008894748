// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineLightBulb = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M4.25 9a7.75 7.75 0 1 1 11.5 6.78v.25c0 .44 0 .81-.02 1.12-.02.31-.07.61-.19.9-.28.68-.81 1.21-1.49 1.5-.29.11-.59.16-.9.18-.3.02-.68.02-1.12.02h-.06c-.44 0-.81 0-1.12-.02a2.82 2.82 0 0 1-.9-.19 2.75 2.75 0 0 1-1.5-1.49 2.82 2.82 0 0 1-.18-.9c-.02-.3-.02-.68-.02-1.12v-.25a7.75 7.75 0 0 1-4-6.78ZM12 2.75a6.25 6.25 0 0 0-2.68 11.9c.26.12.43.39.43.68V16c0 .48 0 .8.02 1.04.01.24.04.36.08.44.12.3.37.55.67.67.08.04.2.07.44.08.24.02.56.02 1.04.02s.8 0 1.04-.02c.24-.01.36-.04.44-.08.3-.12.55-.37.67-.67.04-.08.07-.2.08-.44.02-.24.02-.56.02-1.04v-.67c0-.3.17-.56.43-.68A6.25 6.25 0 0 0 12 2.75ZM9.48 10c0-.41.34-.75.75-.75h3.54a.75.75 0 0 1 0 1.5h-1.02V15a.75.75 0 0 1-1.5 0v-4.25h-1.02a.75.75 0 0 1-.75-.75Zm-.23 12c0-.41.34-.75.75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineLightBulb);
export { ForwardRef as IconV2OutlineLightBulb };