import { Stack, Text } from '@remote-com/norma';
import { IconBookmarkPlus } from '@remote-com/norma/icons/IconBookmarkPlus';

import { ActionButton } from './SaveFilter.styled';

const SaveButton = (props) => {
  return (
    <ActionButton variant="raw" {...props}>
      <Stack direction="row" gap={3} alignItems="center" color="lynch">
        <IconBookmarkPlus width="14px" />
        <Text variant="smMedium">Save filter</Text>
      </Stack>
    </ActionButton>
  );
};

export const SaveFilterActionBar = ({ onSave }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      bg="grey.50"
      height={46}
      gap={6}
      px={6}
      borderRadius={4}
    >
      <SaveButton onClick={onSave} />
    </Stack>
  );
};
