// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineBilling = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M9.13 1.02a2.75 2.75 0 0 1 1.74 0c.29.1.54.25.79.44.23.17.5.4.82.68l.02.02c.55.47.7.59.86.65.2.08.4.12.62.1.16-.03.34-.1 1-.39l2.72-1.2a.75.75 0 0 1 1.05.68v9.25h.08c.53 0 .98 0 1.34.03.38.03.74.1 1.08.27.52.26.94.68 1.2 1.2.17.34.24.7.27 1.08.03.36.03.8.03 1.34V20A2.75 2.75 0 0 1 20 22.75H8.37c-1.1 0-1.96 0-2.66-.06a4.88 4.88 0 0 1-1.87-.46 4.75 4.75 0 0 1-2.07-2.07 4.88 4.88 0 0 1-.46-1.87c-.06-.7-.06-1.56-.06-2.66V2a.75.75 0 0 1 1.05-.69l2.72 1.21c.65.3.84.36 1 .38.21.03.43 0 .62-.09.16-.06.31-.18.86-.65l.02-.02.82-.68c.25-.19.5-.34.8-.44ZM20 21.25c.69 0 1.25-.56 1.25-1.25v-4.8c0-.57 0-.96-.02-1.25a1.36 1.36 0 0 0-.12-.52 1.25 1.25 0 0 0-.54-.54c-.1-.05-.23-.1-.52-.12-.3-.02-.68-.02-1.25-.02h-.05V20c0 .69.56 1.25 1.25 1.25Zm-2.45 0H8.4c-1.13 0-1.94 0-2.57-.05-.62-.05-1-.15-1.3-.3-.62-.32-1.11-.81-1.43-1.42-.15-.3-.25-.7-.3-1.31-.05-.63-.05-1.44-.05-2.57V3.15l1.66.74.1.04c.51.23.92.42 1.35.46.46.05.94-.01 1.37-.2.4-.17.73-.46 1.17-.83l.07-.06c.35-.3.58-.5.77-.64.18-.13.29-.19.37-.21.25-.09.53-.09.78 0 .08.02.19.08.37.21.2.14.42.34.77.64l.07.06c.44.37.78.66 1.17.83.43.19.9.25 1.37.2.43-.04.84-.23 1.36-.46l.1-.04 1.65-.74V20c0 .45.1.88.3 1.25ZM10 8.25c.41 0 .75.34.75.75v.25H12a.75.75 0 0 1 0 1.5H9.5a.75.75 0 0 0 0 1.5h1a2.25 2.25 0 0 1 .25 4.49V17a.75.75 0 0 1-1.5 0v-.25H8a.75.75 0 0 1 0-1.5h2.5a.75.75 0 0 0 0-1.5h-1a2.25 2.25 0 0 1-.25-4.49V9c0-.41.34-.75.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineBilling);
export { ForwardRef as IconV2OutlineBilling };