import {
  ButtonInlineStyles,
  Box,
  HTMLRendered,
  FieldError,
  FieldDescription,
} from '@remote-com/norma';
import { connect, getIn } from 'formik';
import styled, { css } from 'styled-components';

export const SubmitContainer = styled(Box)`
  --spacingTop: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacingTop);
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    --spacingTop: 100px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    --spacingTop: 150px;
  }
`;

const JSONSchemaElementsStyle = css`
  & .jsf-statement,
  & .jsf-description,
  & .jsf-extra {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    strong {
      font-weight: 600;
    }

    a {
      ${ButtonInlineStyles}
      ${({ theme }) => css`
        --button-colorText: var(--colors-irisBlue);
        --button-lineColor: ${theme.colors.irisBlue_75};
      `}

      &:after {
        content: '';
        background-color: ${({ theme }) => theme.colors.irisBlue};
        /* NOTE: we use react-svgr which converts svg imports in React component but we can't use that format in CSS. */
        /* We tried https://react-svgr.com/docs/webpack/#use-svgr-and-asset-svg-in-the-same-project but it turns out that the generated base64 was not valid and could not be used with the background. So we're putting this svg inside the public folder so we can use the url in CSS */
        mask-image: url(/images/icons/arrow-up-right.svg);
        mask-repeat: no-repeat;
        mask-position: bottom;
        display: inline-flex;
        width: 8px;
        height: 0.5rem;
        margin-left: ${({ theme }) => theme.space[2]}px;
      }
    }

    a[href$='.pdf']:after,
    a[href$='.doc']:after,
    a[href$='.docx']:after,
    a[href$='.xls']:after,
    a[href$='.xlsx']:after {
      mask-image: url(/images/icons/download-icon.svg);
      width: 10px;
      height: 1rem;
    }
  }
`;

export const FormContainer = styled.form`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  ${SubmitContainer} {
    margin: 0;
  }

  ${JSONSchemaElementsStyle}
`;

export const InputsContainer = styled(Box)`
  margin: var(--dynamicFormContainerMargin, 45px 0 80px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InvisibleFieldError = styled(FieldError)`
  visibility: hidden;
`;

const ConditionalWrapper = ({ condition, Wrapper, children }) =>
  condition ? <Wrapper>{children}</Wrapper> : children;

function getDisplayError(error) {
  if (Array.isArray(error)) return error.join(', ');

  if (typeof error === 'object') {
    try {
      return JSON.stringify(error);
    } catch (err) {
      return 'Something went wrong';
    }
  }

  return error;
}

// TODO: move this FormFieldError to a better place
/**
 * FormFieldError
 *
 * This component renders an error message if a field has
 * an error and it's already been touched.
 *
 * preventJump - Endures a similar component is rendered in place
 * so when it appears, the form elements do not jump
 */
export const FormFieldError = connect(
  ({ name, formik, preventJump, description, Wrapper, ...props }) => {
    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name) || getIn(formik.touched, `${name}-blocker`);

    const noErrorOutput = preventJump ? <InvisibleFieldError>&nbsp;</InvisibleFieldError> : null;

    return touch && error ? (
      <ConditionalWrapper condition={!!Wrapper} Wrapper={Wrapper}>
        <FieldError {...props} data-testid="error-message">
          {getDisplayError(error)}
          {description && <FieldDescription> - </FieldDescription>}
          {description && <HTMLRendered Tag={FieldDescription}>{description}</HTMLRendered>}
        </FieldError>
      </ConditionalWrapper>
    ) : (
      noErrorOutput
    );
  }
);
