import { getSvgFlagName } from '../../helpers';
import { SvgUse } from '../SvgUse';

export type FlagBadgeSize = 'sm' | 'md';

const flagSizes: Record<string, number> = {
  md: 26,
  sm: 20,
};

const svgPath = '/images/flags/sprite/flags.svg';

// Preload SVG sprite as it's quite big, ~1MB (not compressed).
export const PreloadFlagsSprite = () => (
  <link rel="preload" as="image" href={svgPath} type="image/svg+xml" key={svgPath} />
);

type FlagBadgeProps = {
  countryName: string;
  size?: FlagBadgeSize;
};

export const FlagBadge = ({ countryName, size = 'sm' }: FlagBadgeProps) => {
  return (
    <SvgUse
      href={`${svgPath}#${getSvgFlagName(countryName)}`}
      width={flagSizes[size]}
      height={flagSizes[size]}
    />
  );
};
