import { useQuery } from 'react-query';

import { queryClient } from '@/src/config/queryClient';
import {
  fetchAllCountries,
  fetchNationalityCountries,
  fetchCompanyCountries,
} from '@/src/services/Country';

async function fetchData(requestConfig) {
  const { data } = await fetchAllCountries(requestConfig);
  return data;
}

async function fetchCompanyCountriesData() {
  const { data } = await fetchCompanyCountries();
  return data;
}

async function fetchNationalityCountriesData() {
  const { data } = await fetchNationalityCountries();
  return data;
}

/**
 * @param {Object} [props]
 * @param {Object} [props.queryParams]
 * @param {Object} [props.options]
 */
export default function useCountriesData({ queryParams, options = {} } = {}) {
  return useQuery(['fetchCountries', queryParams], () => fetchData({ queryParams }), options);
}

export function useCompanyCountriesData(options = {}) {
  return useQuery(['fetchCompanyCountries'], fetchCompanyCountriesData, options);
}

export function useNationalityCountriesData(options = {}) {
  return useQuery(['fetchAllCountries'], fetchNationalityCountriesData, options);
}

export function getCountriesForRemoteControlPanel() {
  const qKey = 'remoteControlPanelCountries';
  return queryClient.getQueryData([qKey]) || queryClient.fetchQuery([qKey], fetchData);
}
