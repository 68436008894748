import { useInvalidateQuery, useDelete } from '@remote-com/data-layer';

type DeleteTableViewParams = {
  tableViewSlug: string;
  onSuccess?: () => void;
  onError?: (exception: unknown) => void;
};

export function useDeleteTableView() {
  const { invalidateQuery } = useInvalidateQuery();

  const mutation = useDelete('/api/v1/table-views/[tableViewSlug]');

  const deleteTableView = ({ tableViewSlug, onSuccess, onError }: DeleteTableViewParams) => {
    return mutation.mutateAsync(
      { pathParams: { tableViewSlug } },
      {
        onSuccess: async () => {
          await invalidateQuery('/api/v1/table-views');
          onSuccess?.();
        },
        onError: (exception) => {
          onError?.(exception);
        },
      }
    );
  };

  return { deleteTableView, isLoading: mutation.isLoading };
}
