import kebabCase from 'lodash/kebabCase';
import type { CSSObject } from 'styled-components';
import type { SetOptional } from 'type-fest';
import type { Schema } from 'yup';
import { array, object } from 'yup';

import { supportedTypes, yesNoOptions } from '@/src/components/Form/DynamicForm/constants';
import { getValidationSchema as getWorkWeekScheduleTableFieldValidationSchema } from '@/src/components/Ui/Form/WorkWeekScheduleTableField';
import { daysOfWeekOptions } from '@/src/constants/days';
import {
  baseString,
  emailSchema,
  fileArrayValidation,
  genericRequiredLabel,
  getCurrencyAmountSchema,
  getCurrencyWithNegativeAmountSchema,
  phoneNumberValidationOptional,
  requiredFileArrayValidation,
  timeSchema,
  dateRangeSchema,
  generateDateSchema,
  validateAck,
  validateIsNumber,
  validateIsNumberOptional,
  validationForFields,
} from '@/src/helpers/validationSchema';

import type {
  AckCheckField,
  CheckBoxField,
  CountriesField,
  CurrenciesField,
  DateField,
  DateRangeField,
  EmailField,
  ExtraField,
  BaseField,
  FieldsetField,
  FileField,
  GroupArrayField,
  HiddenField,
  MoneyField,
  NthFieldGroup,
  NumberField,
  RadioField,
  SelectField,
  SignatureField,
  TelephoneField,
  TextAreaField,
  TextField,
  TimeField,
  WorkWeekScheduleField,
  WeekdaysSelectField,
  RadioCardField,
  CheckBoxGroupField,
  FormField,
} from './types';

// --------------------------------------------- //

/**
 * IMPORTANT NOTICE:
 * composeField* functions must use the respective "json-schema-form/internals/_composeField*"
 * as base (when it exists) to ensure both fns are consistent when connected to DynamicForm.
 * More info here: https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/3887#note_734461063
 */

/**
 * Return base attributes needed for a country field.
 */
export function composeFieldCountries<
  Option extends Record<string, any>,
  IsMulti extends boolean = false,
  IsClearable extends boolean = false
>({
  required = true,
  countries,
  ...attrs
}: Omit<CountriesField<Option, IsMulti, IsClearable>, 'type'>): CountriesField<
  Option,
  IsMulti,
  IsClearable
> {
  const schema = required ? baseString.required(genericRequiredLabel) : baseString;
  return {
    type: supportedTypes.COUNTRIES,
    options: countries,
    schema,
    required,
    ...attrs,
  };
}

/**
 * Return base attributes needed for a multi country field.
 * ! This can be removed in favor of composeFieldCountries with optional multiple attribute
 */
export function composeFieldCountriesMulti(attrs: Omit<CountriesField, 'type'>): CountriesField {
  return composeFieldCountries({
    multiple: true,
    ...attrs,
  });
}

/**
 * Return base attributes needed for a number field.
 */
export function composeFieldNumber(params: Omit<NumberField, 'type'>): NumberField {
  const { required = true, percentage, ...attrs } = params;
  const schema = required
    ? validateIsNumber.required(genericRequiredLabel)
    : validateIsNumberOptional;

  return {
    type: supportedTypes.NUMBER,
    schema,
    required,
    percentage,
    ...attrs,
  };
}

/**
 * Return base attributes needed for a textarea field.
 */
export function composeFieldTextarea({
  required = true,
  maxLength = 1000,
  rows = 4,
  ...attrs
}: Omit<SetOptional<TextAreaField, 'required' | 'rows' | 'maxLength'>, 'type'>): TextAreaField {
  let schema = baseString;
  if (required) {
    schema = schema.required(genericRequiredLabel);
  }
  if (maxLength !== null) {
    schema = schema.max(maxLength);
  }

  return {
    type: supportedTypes.TEXTAREA,
    schema,
    maxLength,
    rows,
    required,
    ...attrs,
  };
}

/**
 * Return base attributes needed for an extra field.
 * By default this field "name" is excluded from the form values.
 */
export function composeFieldExtra(params: Omit<ExtraField, 'type'>): ExtraField {
  const { Component, includeValueToApi, ...attrs } = params;
  return {
    type: supportedTypes.EXTRA,
    Component,
    includeValueToApi,
    ...attrs,
  };
}

/**
 * Return base attributes needed for a select field.
 * Value transformations are defined at "fieldTypesTransformations"
 */
export function composeFieldSelect<
  Option extends Record<string, any>,
  IsMulti extends boolean = false,
  IsClearable extends boolean = false
>(
  params: Omit<SelectField<Option, IsMulti, IsClearable>, 'type'>
): SelectField<Option, IsMulti, IsClearable> {
  const { required = true, isClearable, ...attrs } = params;
  let schema = baseString;

  if (required) {
    schema = schema.required(genericRequiredLabel);
  } else if (isClearable) {
    schema = schema.nullable();
  }

  return {
    type: supportedTypes.SELECT,
    schema,
    required,
    isClearable,
    ...attrs,
  };
}

/**
 * Extracts schema of fieldset fields, if fieldset has valueGroupingDisabled (no name),
 * moves its fields schema a level up
 */
function extractFieldsetFieldsSchema(fields: FieldsetField[]): Record<string, Schema<any>> {
  return fields.reduce((acc, field) => {
    if (field.valueGroupingDisabled) {
      return { ...acc, ...extractFieldsetFieldsSchema(field.fields) };
    }
    return { ...acc, [field.name || '']: field.schema };
  }, {});
}

/**
 * Return attributes needed for a fieldset.
 */
export function composeFieldset({
  name,
  label,
  fields,
  validation,
  ...attrs
}: Omit<FieldsetField, 'type'> & {
  validation?: FormField[];
}): FieldsetField {
  const fieldsetSchema = object().shape(extractFieldsetFieldsSchema(fields));
  const validationSchema = !validation
    ? { schema: fieldsetSchema }
    : validationForFields(validation, object(), fieldsetSchema, undefined);

  return {
    key: name,
    type: supportedTypes.FIELDSET,
    ...(!attrs.valueGroupingDisabled && { name }),
    label,
    fields,
    ...validationSchema,
    ...attrs,
  };
}

function sanityTypeCheckFieldsNameKey(
  groupName: string,
  groupFields: (BaseField & { nameKey: string })[]
) {
  /* NOTE: Type check groupFields on runtime to remind devs of including "name" and "nameKey",
           otherwise it can create sneaky bugs within the form flow:
    - "name" is required to prefill the form fields (it's also the convention in all fields)
    - "nameKey" is required for Formik validationSchema (Yup) to work with Array values.
    Note: I (Sandrina) still believe we could accomplish this with only "name"
          but I didn't find a way... if you find one, let us know!
  */
  groupFields.forEach((field) => {
    if (!field.nameKey || !field.name) {
      throw Error(
        `Missing "nameKey" and/or "name" key in the field "${
          field.name || field.nameKey
        }" within the FieldGroup "${groupName}"`
      );
    }
  });
}

/**
 * Return attributes needed for a group array field.
 */
export function composeNthFieldGroup({
  nthFieldGroup,
}: {
  nthFieldGroup: NthFieldGroup;
}): GroupArrayField[] {
  const initialFields = nthFieldGroup.fields(0);
  sanityTypeCheckFieldsNameKey(nthFieldGroup.name, initialFields);

  const nthFieldSchema =
    nthFieldGroup.schema ||
    array().of(
      object().shape(
        initialFields.reduce(
          (acc, { nameKey, schema: fieldSchema }) => ({ ...acc, [nameKey]: fieldSchema }),
          {}
        )
      )
    );

  return [
    {
      type: supportedTypes.GROUP_ARRAY,
      ...nthFieldGroup,
      schema: nthFieldSchema,
    },
  ];
}

/**
 * Return base attributes needed for a text field.
 */
export function composeFieldText(params: Omit<TextField, 'type'>): TextField {
  const { required = true, maxLength = 255, ...attrs } = params;
  const schema = required
    ? baseString.required(genericRequiredLabel).max(maxLength)
    : baseString.max(maxLength);

  return {
    type: supportedTypes.TEXT,
    schema,
    required,
    ...attrs,
  };
}

/**
 * Return base attributes needed for a work week schedule field.
 */
export function composeFieldWorkWeekSchedule({
  name,
  label,
  description,
  required = true,
  displayLunchTime,
  ...attrs
}: Omit<WorkWeekScheduleField, 'type'>): WorkWeekScheduleField {
  let schema = getWorkWeekScheduleTableFieldValidationSchema(displayLunchTime);
  if (required) {
    schema = schema.required();
  }

  return {
    type: supportedTypes.WORK_WEEK_SCHEDULE,
    name,
    label,
    description,
    displayLunchTime,
    schema,
    ...attrs,
  };
}

/**
 * Return base attributes needed for a currencies select field.
 */
export function composeFieldCurrencies<Option extends Record<string, any>>({
  currencies,
  name,
  label,
  required = true,
  ...attrs
}: Omit<CurrenciesField<Option>, 'type'>): CurrenciesField<Option> {
  const schema = required ? baseString.required(genericRequiredLabel) : baseString;

  return {
    type: supportedTypes.CURRENCIES,
    name,
    label,
    placeholder: 'Select currency',
    currencies,
    schema,
    required,
    ...attrs,
  };
}

/**
 * Composes a file field with schema and data-testid
 */
export function composeFieldFile({
  name,
  required = true,
  ...attrs
}: Omit<FileField, 'type'>): FileField {
  const schema = required ? requiredFileArrayValidation : fileArrayValidation;
  const dataTestid = name && kebabCase(name);

  return {
    type: supportedTypes.FILE,
    dataTestid,
    name,
    required,
    schema,
    ...attrs,
  };
}

/**
 * Composes a multi-select field with week days
 *
 * @param {Object}  props - The field props.
 * @param {string}  props.name - The field name.
 * @param {string}  props.label - The field label.
 */
export function composeFieldWeekdaysSelect({
  name,
  label,
  ...attrs
}: Omit<WeekdaysSelectField, 'type'>): WeekdaysSelectField {
  return composeFieldSelect({
    name,
    label,
    displayLabel: true,
    placeholder: 'Select day',
    multiple: true,
    options: daysOfWeekOptions,
    ...attrs,
  });
}

/**
 * Return base attributes needed for a tel field.
 * @param {Object} attrs
 * @param {String} attrs.name - field name
 * @param {String} attrs.label - field label
 * @param {Boolean} attrs.required - field required
 * @return {Object}
 */
export function composeFieldTel({
  required = true,
  ...attrs
}: Omit<SetOptional<TelephoneField, 'options'>, 'type'>): SetOptional<TelephoneField, 'options'> {
  const schema = required
    ? phoneNumberValidationOptional.required(genericRequiredLabel)
    : phoneNumberValidationOptional;

  return {
    type: supportedTypes.TEL,
    schema,
    required,
    ...attrs,
  };
}

/**
 * Return base attributes needed for a email field.
 * @param {Object} attrs
 * @param {String} attrs.name - field name
 * @param {String} attrs.label - field label
 * @param {Boolean} attrs.required - field required
 * @return {Object}
 */
export function composeFieldEmail({
  name,
  label,
  required = true,
  ...attrs
}: Omit<EmailField, 'type'>): EmailField {
  const schema = required ? emailSchema.required(genericRequiredLabel) : emailSchema;

  return {
    type: supportedTypes.EMAIL,
    schema,
    name,
    label,
    required,
    ...attrs,
  };
}
/**
 * Return base attributes needed for a hidden field.
 */
export function composeFieldHidden(params: Omit<HiddenField, 'type'>): HiddenField {
  const { name, label, ...attrs } = params;

  return {
    type: supportedTypes.HIDDEN,
    name,
    label,
    ...attrs,
  };
}

/**
 * Composes a Signature input and preview field
 */
export function composeFieldSignature({
  name,
  label,
  required = true,
  maxLength = 255,
  ...attrs
}: Omit<SignatureField, 'type'>): SignatureField {
  const schema = required
    ? baseString.required(genericRequiredLabel).max(maxLength)
    : baseString.max(maxLength);

  return {
    type: supportedTypes.SIGNATURE,
    name,
    label,
    required,
    schema,
    ...attrs,
  };
}

/**
 * IMPORTANT NOTICE:
 * composeField* functions must use the respective "json-schema-form/internals/_composeField*"
 * as base (when it exists) to ensure both fns are consistent when connected to DynamicForm.
 * More info here: https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/3887#note_734461063
 */

export type FieldOption = {
  value: string;
  label: string | JSX.Element;
  description?: string;
  disabled?: boolean;
  attrs?: Object;
  extra?: JSX.Element;
  inputWrapperStyles?: CSSObject;
};

export function composeFieldRadio(
  params: Omit<RadioField, 'type'> | Omit<RadioCardField, 'type'>
): RadioField | RadioCardField {
  const { required = true, direction = 'row', ...attrs } = params;
  const schema = required ? baseString.required(genericRequiredLabel) : baseString;
  return {
    type: supportedTypes.RADIO,
    schema,
    required,
    direction,
    ...attrs,
  };
}
/*
 * Return base attributes needed for a radio field with yes / no options.
 */
export function composeFieldYesNo({
  name,
  label,
  required = true,
  ...attrs
}: Omit<SetOptional<RadioField, 'options'>, 'type'>) {
  return composeFieldRadio({
    name,
    label,
    required,
    options: yesNoOptions,
    ...attrs,
  });
}

/**
 * Returns two inputs needed to make this field type work properly.
 * - Input 1: the visual checkbox for UI purposes only (ack the info).
 * - Input 2: a hidden input that holds the value needed for the API.
 */
export function composeFieldAck(params: Omit<AckCheckField, 'type'>): AckCheckField {
  const {
    name,
    label,
    description,
    defaultValue = 'acknowledged',
    checkboxValue,
    ...attrs
  } = params;
  return {
    type: supportedTypes.ACK_CHECK,
    name,
    label,
    description,
    checkboxValue: checkboxValue || defaultValue,
    schema: validateAck,
    required: true,
    ...attrs,
  };
}

export function composeFieldCheckbox({
  required = true,
  ...attrs
}: Omit<CheckBoxField, 'type'> | Omit<CheckBoxGroupField, 'type'>):
  | CheckBoxField
  | CheckBoxGroupField {
  const schema = required ? baseString.required(genericRequiredLabel) : baseString;

  if ('options' in attrs) {
    return {
      type: supportedTypes.CHECKBOX,
      schema,
      required,
      ...attrs,
    };
  }

  const { checkboxValue, defaultValue, ...singleCheckboxAttrs } = attrs;

  return {
    type: supportedTypes.CHECKBOX,
    schema,
    required,
    // Changing the condition to checkboxValue != null will allow passing the bool value of false to checkboxValue.
    checkboxValue: checkboxValue ?? defaultValue,
    ...singleCheckboxAttrs,
  };
}

/**
 * Return base attributes needed for a date field.
 */
export function composeFieldDate({
  required = true,
  requiredMsg,
  minDate,
  maxDate,
  ...attrs
}: Omit<DateField, 'type'>): DateField {
  return {
    type: supportedTypes.DATE,
    schema: generateDateSchema({ name: attrs.name, required, requiredMsg, minDate, maxDate }),
    required,
    minDate,
    maxDate,
    ...attrs,
  };
}

/**
 * Return base attributes needed for a date range field.
 */
export function composeFieldDateRange({
  required = true,
  ...attrs
}: Omit<DateRangeField, 'type'>): DateRangeField {
  const schema = required ? dateRangeSchema.required(genericRequiredLabel) : dateRangeSchema;
  return {
    type: supportedTypes.DATE_RANGE,
    required,
    schema,
    ...attrs,
  };
}

/**
 * Return base attributes needed for a money field.
 */
export function composeFieldMoney(
  params: Omit<SetOptional<MoneyField, 'currency'>, 'type'>
): MoneyField {
  const {
    currency = 'EUR',
    required = true,
    allowNegativeValues = false,
    integerCurrencyOnly = false,
    ...attrs
  } = params;
  const schema = allowNegativeValues
    ? getCurrencyWithNegativeAmountSchema(integerCurrencyOnly)
    : getCurrencyAmountSchema(integerCurrencyOnly);

  return {
    type: supportedTypes.MONEY,
    value: (value: string | number) => value?.toString() || '',
    schema: required ? schema.required(genericRequiredLabel) : schema,
    required,
    currency,
    ...attrs,
  };
}

export function composeFieldTime(params: Omit<TimeField, 'type'>): TimeField {
  const { required = true, ...attrs } = params;
  const schema = required ? timeSchema.required(genericRequiredLabel) : timeSchema;

  return {
    type: supportedTypes.TIME,
    schema,
    required,
    ...attrs,
  };
}
