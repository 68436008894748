import { Text } from '@remote-com/norma';
import { useFormikContext } from 'formik';
import Head from 'next/head';

import type { SignatureField, TextField } from '@/src/components/Form/DynamicForm/types';
import { InputField } from '@/src/components/Ui/Form';
import { InputController } from '@/src/components/Ui/Form/Input';

import { SignatureContainer, SignatureDisclaimer, SignaturePreview } from './InputSignature.styled';

type Props = Omit<TextField, 'type'> & Omit<SignatureField, 'type'>;

const SIGNATURE_PREVIEW_PLACEHOLDER = 'Signature preview';

function InputSignature({ previewDescription, signatureDisclaimer, ...props }: Props) {
  const { values } = useFormikContext<Record<string, unknown>>();
  const { name } = props;
  const signatureValue = name ? values[name] : null;

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        {/* This font is only needed in this page */}
        {/* eslint-disable-next-line @next/next/no-page-custom-font */}
        <link
          href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <InputController>
        <InputField type="text" {...props} />
      </InputController>
      <SignatureContainer>
        <SignaturePreview $isPlaceholder={!signatureValue}>
          {signatureValue || SIGNATURE_PREVIEW_PLACEHOLDER}
        </SignaturePreview>
        {signatureDisclaimer && (
          <SignatureDisclaimer>
            <Text fontStyle="italic" color="grey.600" variant="xs" textAlign="center">
              {signatureDisclaimer}
            </Text>
          </SignatureDisclaimer>
        )}
      </SignatureContainer>
      {previewDescription ? (
        <Text color="grey.600" mt={6} variant="xs" textAlign="center">
          {previewDescription}
        </Text>
      ) : null}
    </>
  );
}

export default InputSignature;
