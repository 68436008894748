import getConfig from 'next/config';

import { HELP_CENTER_ROUTE } from '@/src/constants/routes';
import { makeApiService } from '@/src/services/ApiClient';

const {
  publicRuntimeConfig: { ZENDESK_URL },
} = getConfig();

export const ZENDESK_HELP_CENTER_URL = `${ZENDESK_URL}/hc/en-us/articles`;
export const ZENDESK_HELP_CENTER_URL_PRODUCTION = `${HELP_CENTER_ROUTE}/hc/en-us/articles`;
export const ZENDESK_TICKET_URL = `${ZENDESK_URL}/agent/tickets`;

export const fetchZendeskMessagingJWT = makeApiService({ path: '/zendesk/jwt/messaging' });
export const fetchZendeskSsoJWT = makeApiService({ path: '/zendesk/sso' });
export const fetchZendeskArticle = makeApiService({
  path: '/zendesk/articles/[articleId]',
});

export const createZendeskTicket = makeApiService({
  method: 'post',
  path: '/zendesk/tickets',
  asFormData: false,
  toSnakeCase: false,
});
