import { Text, toast } from '@remote-com/norma';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';

import { SelectAllRowsButton } from '@/src/components/Table/Components/Toolbar/SelectAllRowsButton';
import { SelectedRowsActionButton } from '@/src/components/Table/Components/Toolbar/SelectedRowsActions';
import { createExportState } from '@/src/components/Table/export/export';
import {
  BULK_SELECT_PAGE_SIZE,
  MAX_BULK_SELECT_ROWS,
} from '@/src/components/Table/helpers/constants';
import { makeAllPageFetcherFunction } from '@/src/helpers/api';
import { captureException } from '@/src/helpers/captureException';

function BulkSelection({
  selectedFlatRows,
  isCheckboxDisabled,
  totalCount: totalRowsOnAllPages,
  pageCount,
  queryFn,
  state,
  BulkEditComponent,
  bulkEditOnClick,
  shouldFetchAllPagesOnSelectAll,
  shouldRenderSelectAll,
  isAllPageRowsSelected,
  toggleAllRowsSelected,
  selectAllLabel,
  selectionSupportingText,
  maxBulkSelectRows,
}) {
  const shouldShowCustomBulkEditComponent = BulkEditComponent && isFunction(BulkEditComponent);

  // Quick flag for use cases in which we don't care about the selected rows. Only if all are selected.
  const [areAllRowsFromAllPagesSelected, setAreAllRowsFromAllPagesSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBulkRows, setSelectedBulkRows] = useState([]);
  const areAllRowsSelected = shouldShowCustomBulkEditComponent
    ? /**
       * If we are rendering a custom edit component, the fetchAllRows function is not used. So, we
       * have to assume that all rows are selected if the selected count matchs the total OR
       * if the `isAllpageRowsSelected` flag is true.
       */
      selectedFlatRows.length === totalRowsOnAllPages || isAllPageRowsSelected
    : selectedFlatRows.length === totalRowsOnAllPages || areAllRowsFromAllPagesSelected;

  const maxBulkAllowedRows = maxBulkSelectRows ?? MAX_BULK_SELECT_ROWS;
  const maxBulkAllowedPages = Math.ceil(maxBulkAllowedRows / BULK_SELECT_PAGE_SIZE);

  const selectedRows = useMemo(
    () => selectedFlatRows.map(({ original }) => original),
    [selectedFlatRows]
  );

  const shouldShowBulkSelectAll =
    shouldRenderSelectAll &&
    (shouldFetchAllPagesOnSelectAll
      ? pageCount > 1 && isAllPageRowsSelected && selectedBulkRows.length !== totalRowsOnAllPages
      : pageCount > 1 && isAllPageRowsSelected && !areAllRowsSelected);

  useEffect(() => {
    const selectedRowsChecked = isFunction(isCheckboxDisabled)
      ? selectedFlatRows.filter((row) => !isCheckboxDisabled(row))
      : selectedFlatRows;

    setSelectedBulkRows(selectedRowsChecked);
  }, [selectedRows, isCheckboxDisabled, selectedFlatRows]);

  /**
   * When the prop 'shouldFetchAllPagesOnSelectAll' is false, we should not fetch anything to be able
   * to batch update.
   * Don't forget we still have a limit of pages we can fetch, with maxBulkAllowedPages. Hence the
   * need of putting setAreAllRowsFromAllPagesSelected on an else, because even we fetch the other
   * pages, sometimes not ALL pages were fetched.
   */
  async function fetchAllRows() {
    if (shouldFetchAllPagesOnSelectAll) {
      setIsLoading(true);
      const stateToUse = createExportState({ reactTableState: state });
      const fetchAllPages = makeAllPageFetcherFunction(
        queryFn,
        (results) => results.flatMap((res) => res.data?.data || res.data),
        { pageLimit: maxBulkAllowedPages, pageSize: BULK_SELECT_PAGE_SIZE }
      );
      const dataToUse = await fetchAllPages({ ...stateToUse, disable_pagination: true }).catch(
        (exception) => {
          captureException(exception);
          toast.error('Something went wrong. Please try again.');

          return selectedRows;
        }
      );

      setSelectedBulkRows(dataToUse.map((original) => ({ original })));
      setIsLoading(false);
    } else {
      toggleAllRowsSelected(true);
      // We also need to set this to true so we signal BulkEditComponents that only care
      // About if all slugs are selected.
      // EG: Payroll run tables where we don't need the selected rows. We only need to now if all are selected
      // So we can call a bulk delete endpoint without any arguments (it's faster).
      setAreAllRowsFromAllPagesSelected(true);
    }
  }

  if (!selectedBulkRows.length) return null;

  // when shouldFetchAllPagesOnSelectAll is FALSE, we can update ALL rows because we don't have the
  // problem of fetching the other pages to get all slugs.
  // That's why we're using the prop maxBulkAllowedPages, to limit the amount of pages we fetch and
  // not block the page for too long
  return (
    <>
      {shouldShowCustomBulkEditComponent ? (
        <BulkEditComponent
          areAllRowsSelected={areAllRowsSelected}
          selectedRows={selectedBulkRows}
          isLoading={isLoading}
          totalCount={totalRowsOnAllPages}
          tableState={state}
        />
      ) : (
        <SelectedRowsActionButton
          data-testid="table-toolbar-edit-selected-btn"
          onClick={() => bulkEditOnClick(selectedBulkRows)}
          isLoading={isLoading}
        >
          Edit {pageCount > 1 && areAllRowsSelected ? totalRowsOnAllPages : selectedBulkRows.length}{' '}
          selected
        </SelectedRowsActionButton>
      )}
      {selectionSupportingText && <Text variant="smMedium">{selectionSupportingText}</Text>}
      {shouldShowBulkSelectAll && (
        <SelectAllRowsButton
          disabled={isLoading}
          label={selectAllLabel}
          maxBulkAllowedRows={maxBulkAllowedRows}
          onClick={fetchAllRows}
          totalCount={totalRowsOnAllPages}
        />
      )}
    </>
  );
}

BulkSelection.propTypes = {
  selectAllLabel: PropTypes.string,
  selectionSupportingText: PropTypes.string,
};

export default BulkSelection;
