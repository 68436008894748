// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconDownloadBase = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M16.68 16.5c0 .47-.38.85-.85.85H4.17a.85.85 0 1 1 0-1.7h11.66c.47 0 .85.38.85.85Z" /><path fillRule="evenodd" clipRule="evenodd" d="M14.77 8.73c.33.33.33.87 0 1.2L10.6 14.1a.85.85 0 0 1-1.2 0L5.23 9.93a.85.85 0 0 1 1.2-1.2L10 12.3l3.57-3.57a.85.85 0 0 1 1.2 0Z" /><path fillRule="evenodd" clipRule="evenodd" d="M10 2.65c.47 0 .85.38.85.85v10a.85.85 0 0 1-1.7 0v-10c0-.47.38-.85.85-.85Z" /></svg>;
const ForwardRef = forwardRef(IconDownloadBase);
export { ForwardRef as IconDownloadBase };