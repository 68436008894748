import { EMPTY_INDICATOR, Stack, Text, Tooltip, themeV2 } from '@remote-com/norma';
import { IconV2OutlineLock } from '@remote-com/norma/icons/IconV2OutlineLock';
import { IconV2OutlineUnlock } from '@remote-com/norma/icons/IconV2OutlineUnlock';
import type { ComponentType } from 'react';
import React from 'react';

import type { CryptoCurrency, Currency } from '@/src/api/config/employ/shared.types';
import { AlignRight, EmptyCell } from '@/src/components/Table';
import { TooltipHeader } from '@/src/components/Table/Headers';
import { formatMoneyWithoutCurrency } from '@/src/helpers/currency';

import { CRYPTO_CURRENCY_CODES } from '../payments/constants';
import { WITHDRAWAL_METHOD_TYPES } from '../withdrawalMethods/constants';

import { PAY_OUT_METHOD } from './constants';
import type { ContractorInvoice, ContractorInvoiceWithFX, Quote, QuotePreview } from './types';

export const hasFXProperties = (ci: ContractorInvoice): ci is ContractorInvoiceWithFX => {
  // We just check a single property for performance reasons, as we assume
  // that if the contractor invoice has `chosenAmount` it will have the rest
  // of the FX properties.
  return Object.prototype.hasOwnProperty.call(ci, 'chosenAmount');
};

export const invoiceIsStripeInSameCurrency = (ci: ContractorInvoiceWithFX) => {
  return (
    ci.withdrawalMethod.type === WITHDRAWAL_METHOD_TYPES.STRIPE_CONNECT &&
    ci.chosenCurrency.code === ci.targetCurrency.code
  );
};

export const invoiceIsStripeUSDtoUSDC = (ci: ContractorInvoiceWithFX) => {
  return (
    ci.withdrawalMethod.type === WITHDRAWAL_METHOD_TYPES.STRIPE_CONNECT &&
    ci.chosenCurrency.code === 'USD' &&
    ci.targetCurrency.code === 'USDC'
  );
};

const getLatestQuote = (quotes?: Quote[]): Quote | null => quotes?.[0] ?? null;

export const quoteHasUnknownSwiftFee = (quote: Quote): boolean => {
  return quote.payOutMethod === 'swift';
};

/**
 * The payOutMethod 'swift' (SWIFT_SHA) means there's an unknown SWIFT fee
 * for the contractor. For this reason, we don't say that the payout is
 * guaranteed even if we guarantee the amount we send, because they will
 * receive less than we send.
 */
export const hasUnknownSwiftFee = (invoice: ContractorInvoice): boolean => {
  if (!hasFXProperties(invoice)) {
    return false;
  }

  if (getLatestQuote(invoice.contractorInvoiceQuotes)?.payOutMethod === 'swift') {
    return true;
  }

  return false;
};

export const getLatestQuoteFromInvoice = (invoice: ContractorInvoice): Quote | null => {
  if (!hasFXProperties(invoice) || invoice.contractorInvoiceQuotes == null) {
    return null;
  }

  return getLatestQuote(invoice.contractorInvoiceQuotes);
};

/**
 * Returns whether the invoice uses the guaranteed payout mode.
 *
 * A guaranteed payout mode means that we have created a quote on invoice
 * creation, so we (and the contractor) know the exact amount that we will
 * send, even if there's FX fluctuations.
 *
 * This is opposed to the non-guaranteed mode, where the amount we send depends
 * on the FX at the moment of payout.
 */
export const usesGuaranteedPayoutMode = (invoice: ContractorInvoice): boolean => {
  return invoice.payoutMode === 'guaranteed';
};

/**
 * Returns whether the invoice has a guaranteed payout amount.
 *
 * This is slightly different from usesGuaranteedPayoutMode, as there are cases
 * where we guarantee the amount we send, but we can't guarantee the payout
 * amount that the contractor will receive.
 *
 * At the moment the only case where this happens is when the payout is SWIFT
 * but we don't know the exact SWIFT fee. (see hasUnknownSwiftFee for more
 * details).
 */
export const hasGuaranteedPayoutAmount = (invoice: ContractorInvoice): boolean => {
  if (!hasFXProperties(invoice)) {
    return false;
  }

  if (invoiceIsStripeInSameCurrency(invoice)) {
    return true;
  }

  if (invoiceIsStripeUSDtoUSDC(invoice)) {
    return true;
  }

  return usesGuaranteedPayoutMode(invoice) && !hasUnknownSwiftFee(invoice);
};

/**
 * We have two flows where we can guarantee the amount:
 * - Invoice in the contractor currency
 * - Invoice in the billing currency
 * This function returns which flow the invoice is in, or throws an error for an invalid chosen currency
 */
export const guaranteedFlow = (
  quote: Quote
): 'invoiceInContractorCurrency' | 'invoiceInBillingCurrency' => {
  const { chosenCurrency, sourceCurrency, targetCurrency } = quote;

  /**
   * The order is important in case all currencies are the same.
   * In that case, we consider the flow as "invoiceInContractorCurrency", this way we
   * prioritize the payout amount, and the company will pay any SWIFT fees if they apply.
   */
  if (chosenCurrency.code === targetCurrency.code) {
    return 'invoiceInContractorCurrency';
  }

  if (chosenCurrency.code === sourceCurrency.code) {
    return 'invoiceInBillingCurrency';
  }

  /**
   * We should not get here. If there's a quote, the chosenCurrency
   * should be either the target or the source currency.
   */
  throw new Error('Quote chosenCurrency is not in target or source currency.');
};

/**
 * Returns who (contractor or employer) bears the FX.
 * The one who pays the FX is the one that will see an amount that's different
 * from the invoice amount.
 *
 * For example, for an invoice in EUR, if the contractor bears the FX it means
 * that the employer is billed in EUR, so they will have no FX. The contractor
 * might have a different payout/target currency, so they will pay any FX fees
 * or spreads.
 *
 * In the other case, for an invoice in EUR if the employer bears the FX it
 * means that the contractor is paid in EUR, and will receive the invoice
 * amount, but the employer might be billed in a different currency and might
 * have to pay FX fees or spreads.
 *
 */
export const whoPaysFX = (quote: Quote): 'contractor' | 'company' => {
  switch (guaranteedFlow(quote)) {
    case 'invoiceInBillingCurrency':
      return 'contractor';
    case 'invoiceInContractorCurrency':
      return 'company';
  }
};

export const getInvoiceCurrencyAndAmount = (
  invoice: ContractorInvoice
): { amount: number; currency: Currency } => {
  if (hasFXProperties(invoice)) {
    return { amount: invoice.chosenAmount, currency: invoice.chosenCurrency };
  }

  return { amount: invoice.amount, currency: invoice.currency };
};

export const getBilledCurrencyAndAmount = (
  invoice: ContractorInvoice
): { amount: number; currency: Currency } => {
  if (hasFXProperties(invoice)) {
    return { amount: invoice.sourceAmount, currency: invoice.sourceCurrency };
  }

  return { amount: invoice.amount, currency: invoice.currency };
};

export const getPayoutCurrencyAndAmount = (
  invoice: ContractorInvoice
): { amount: number; currency: Currency | CryptoCurrency } | null => {
  if (hasFXProperties(invoice)) {
    if (invoiceIsStripeInSameCurrency(invoice)) {
      return { amount: invoice.chosenAmount, currency: invoice.chosenCurrency };
    }
    if (invoice.targetAmount == null) {
      return null;
    }

    return { amount: invoice.targetAmount, currency: invoice.targetCurrency };
  }

  return { amount: invoice.amount, currency: invoice.currency };
};

export const getContractorLineItemCurrencyAndAmount = (
  invoice: ContractorInvoice,
  index?: number
) => {
  const currency = hasFXProperties(invoice) ? invoice.chosenCurrency : invoice.currency;

  if (
    invoice.contractorInvoiceItems === undefined ||
    index === undefined ||
    index < 0 ||
    index >= invoice.contractorInvoiceItems.length
  ) {
    return {
      amount: 0,
      currency,
    };
  }

  return {
    amount: invoice.contractorInvoiceItems![index].amount,
    currency,
  };
};

export const isCryptoCurrencyCode = (currencyCode: string) => {
  return CRYPTO_CURRENCY_CODES.has(currencyCode);
};

export const hasPayoutAmount = (invoice: ContractorInvoice): boolean =>
  getPayoutCurrencyAndAmount(invoice) != null;

const formatCryptoCurrency = (amount: number, currencyCode: string) => {
  switch (currencyCode) {
    // USDC is pegged one to one with USD, so we can be confident about formatting
    // if additional crypto currencies are added, we should add a case for each
    case 'USDC':
      return formatMoneyWithoutCurrency(amount, { impliedCurrencyCode: 'USD' });
    default:
      return null;
  }
};

export const formatMoneyThatCouldBeFiatOrCrypto = ({
  amount,
  currencyCode,
  addCurrencyCode,
}: {
  amount?: number;
  currencyCode?: string;
  addCurrencyCode: boolean;
}) => {
  let formatted;
  if (!amount || !currencyCode) {
    return EMPTY_INDICATOR;
  }
  if (isCryptoCurrencyCode(currencyCode)) {
    formatted = formatCryptoCurrency(amount, currencyCode);
  } else {
    formatted = formatMoneyWithoutCurrency(amount, { impliedCurrencyCode: currencyCode });
  }
  if (!formatted) {
    return EMPTY_INDICATOR;
  }

  if (addCurrencyCode) {
    return `${formatted} ${currencyCode}`;
  }

  return formatted;
};

const generateAmountFormatterWithCryptoSupport =
  (
    amountAndCurrencyGetter: (
      invoice: ContractorInvoice
    ) => { amount: number; currency: Currency | CryptoCurrency } | null
  ) =>
  (addCurrencyCode: boolean) =>
  (invoice?: ContractorInvoice) => {
    if (!invoice) {
      return EMPTY_INDICATOR;
    }

    const result = amountAndCurrencyGetter(invoice);

    if (!result) {
      return EMPTY_INDICATOR;
    }

    return formatMoneyThatCouldBeFiatOrCrypto({
      amount: result.amount,
      currencyCode: result.currency.code,
      addCurrencyCode,
    });
  };

const generateAmountFormatter =
  (
    amountAndCurrencyGetter: (
      invoice: ContractorInvoice,
      index?: number
    ) => { amount: number; currency: Currency } | null
  ) =>
  (addCurrencyCode: boolean) =>
  (invoice?: ContractorInvoice, index?: number) => {
    if (!invoice) {
      return EMPTY_INDICATOR;
    }

    const result = amountAndCurrencyGetter(invoice, index);

    if (!result) {
      return EMPTY_INDICATOR;
    }

    const { amount, currency } = result;

    const formatted = formatMoneyWithoutCurrency(amount, { impliedCurrencyCode: currency?.code });

    if (!formatted) {
      return EMPTY_INDICATOR;
    }

    if (addCurrencyCode) {
      return `${formatted} ${currency?.code}`;
    }

    return formatted;
  };

export const formatInvoiceAmountForExport = generateAmountFormatter(getInvoiceCurrencyAndAmount)(
  false
);
export const formatBilledAmountForExport = generateAmountFormatter(getBilledCurrencyAndAmount)(
  false
);
export const formatPayoutAmountForExport = generateAmountFormatterWithCryptoSupport(
  getPayoutCurrencyAndAmount
)(false);

export const formatInvoiceAmount = generateAmountFormatter(getInvoiceCurrencyAndAmount)(true);
export const formatBilledAmount = generateAmountFormatter(getBilledCurrencyAndAmount)(true);
export const formatPayoutAmount = generateAmountFormatterWithCryptoSupport(
  getPayoutCurrencyAndAmount
)(true);

export const formatContractorInvoiceItemAmount = generateAmountFormatter(
  getContractorLineItemCurrencyAndAmount
)(true);
/**
 * This returns the payout amount in the payout currency, but if it's not
 * available (because it's not a guaranteed flow), it returns the invoice
 * amount, in the invoice currency.
 * This is useful for cases where we have a phrase like "we have sent
 * [payoutAmount] to the contractors account", where it's better to include
 * the invoice amount than nothing.
 */
export const formatPayoutAmountWithFallback = generateAmountFormatterWithCryptoSupport(
  (invoice: ContractorInvoice) =>
    getPayoutCurrencyAndAmount(invoice) ?? getInvoiceCurrencyAndAmount(invoice)
)(true);

export const formatEstimatedPayoutAmountFromQuotePreview = (quotePreview: QuotePreview) =>
  formatMoneyThatCouldBeFiatOrCrypto({
    amount: parseInt(quotePreview.contractorReceivedAmount.amount, 10),
    currencyCode: quotePreview.contractorReceivedAmount.code,
    addCurrencyCode: true,
  });

export const formatAmountWithCurrencyCodeSuffix = (amount: number, currencyCode: string) =>
  `${formatMoneyWithoutCurrency(amount, { impliedCurrencyCode: currencyCode })} ${currencyCode}`;

export const formatAmountWithCurrencySuffix = (amount: number, currency: Currency) =>
  formatAmountWithCurrencyCodeSuffix(amount, currency.code);

export function formatFxRate(rate: number | undefined, fallback: string): string;
export function formatFxRate(rate: number | undefined, fallback?: undefined): string | undefined;
export function formatFxRate(rate: number, fallback?: undefined): string;
export function formatFxRate(
  rate: number | undefined,
  fallback?: string | undefined
): string | undefined {
  if (rate === undefined || rate === null) {
    return fallback;
  }
  return rate.toFixed(4);
}

export const formatBilledFxRate = (billedRate: number | undefined): string => {
  if (!billedRate) {
    return EMPTY_INDICATOR;
  }

  const decimalPlaces = Math.pow(10, 4);

  // Business decision to 'round-up' when showing employers the billed rate (inverse of remote
  // rate) so that the billed amount appears as the same or less than the invoice amount * billed
  // rate
  return String(Math.ceil(billedRate * decimalPlaces) / decimalPlaces);
};

/**
 * Returns the rate used for this invoice. This is always the rate used to
 * calculate targetAmount = sourceAmount / remoteRate.
 */
export const getRemoteRate = (invoice: ContractorInvoice): number | undefined => {
  const remoteRate = getLatestQuoteFromInvoice(invoice)?.remoteRate;

  if (remoteRate == null) {
    return;
  }

  return parseFloat(remoteRate);
};

/**
 * Returns the ECB rate that existed at the time the Remote rate was locked in.
 * This is just used for comparison purposes to comply with CBPR2.
 */
export const getEcbRate = (invoice: ContractorInvoice): number | undefined => {
  const ecbRate = getLatestQuoteFromInvoice(invoice)?.ecbRate;

  if (ecbRate == null) {
    return;
  }

  return parseFloat(ecbRate);
};

/**
 * Returns the rate to show to the employer. This is the rate that explains the
 * sourceAmount compared to the invoiceAmount.
 * When employer currency matches invoice currency, no rate applies as conversion isn't needed.
 * In others, the rate is the inverse of the remoteRate. This is because the
 * remoteRate shows the rate from sourceAmount -> targetAmount, and we want to show the
 * inverse of that for the employer.
 *
 * For example, if remoteRate is 0.7, this means that to get the targetAmount
 * we have to do `targetAmount = sourceAmount / 0.7`.
 * If we want to do the inverse calculation, to get the sourceAmount from the
 * targetAmount, we would need to do `sourceAmount = targetAmount / (1 / 0.7)`.
 *
 */
export const getBilledRate = (invoice: ContractorInvoice): number | undefined => {
  if (!hasFXProperties(invoice)) {
    return;
  }

  if (invoice.chosenCurrency.code === invoice.sourceCurrency.code) {
    // If the chosenCurrency is the same as the billedCurrency, the remoteRate
    // is used only on the contractor side, so for the employer there's no FX rate.
    return;
  }

  const remoteRate = getRemoteRate(invoice);

  if (remoteRate == null) {
    return;
  }

  return 1 / remoteRate;
};

/**
 *
 * The ECB rate is used for comparison with the remote rate.
 * In the case of a billed rate, it is the inverse of the remote rate, so, we
 * also have to invert the ECB rate so the comparison makes sense.
 * See getBilledRate for more details.
 */
export const getBilledEcbRate = (invoice: ContractorInvoice): number | undefined => {
  if (!hasFXProperties(invoice)) {
    return;
  }

  if (invoice.chosenCurrency.code === invoice.sourceCurrency.code) {
    // If the chosenCurrency is the same as the billedCurrency, the remoteRate
    // is used only on the contractor side, so for the employer there's no FX rate.
    return;
  }

  const ecbRate = getEcbRate(invoice);

  if (ecbRate == null) {
    return;
  }

  return 1 / ecbRate;
};

/**
 * Fallback behavior, where whoever pays FX also pays SWIFT fees
 *
 * Even if this function returns a value, it does not mean that there's a SWIFT
 * fee to be paid, just that in this currency configuration, the party returned
 * is responsible for the fee if it exists.
 *
 * With payment settings and swift_our, the company can decide to pay SWIFT
 * fees on behalf of contractors using SWIFT USD withdrawal methods only, and
 * where this applies, this new logic overrides this function (see  getWhoPaysSwiftFeeFromQuote)
 */
export const whoPaysSwiftFee = whoPaysFX;

export const getSwiftFeeFromQuote = (
  quote: Quote
): { amount: number; currency: Currency } | null => {
  const { processingFees, processingFeesCurrency } = quote;

  if (processingFees == null || processingFeesCurrency == null || processingFees <= 0) {
    return null;
  }

  return { amount: processingFees, currency: processingFeesCurrency };
};

export const getSwiftFee = (
  invoice: ContractorInvoice
): { amount: number; currency: Currency } | null => {
  if (!hasFXProperties(invoice)) return null;

  const latestQuote = getLatestQuoteFromInvoice(invoice);
  if (!latestQuote) return null;
  return getSwiftFeeFromQuote(latestQuote);
};

/**
 * When paying out to SWIFT withdrawal methods which do not have swift_our support (e.g. non-USD)
 * we do not know in advance the sometimes sizeable fees applied by the contractors bank on receipt of payout
 * and so cannot fully guarantee the payout for the associated invoice
 */
export const invoiceIsSwiftEstimated = (ci: ContractorInvoice) => {
  const quote = getLatestQuoteFromInvoice(ci);
  return quote?.payOutMethod === PAY_OUT_METHOD.SWIFT;
};

/**
 * Used as a display fallback for employers
 * @returns 0 formatted in the billing currency
 */
export const getFormattedNoFees = (invoice: ContractorInvoice) => {
  // support invoices without FX which are in billing currency by default
  if (!hasFXProperties(invoice)) {
    return formatAmountWithCurrencySuffix(0, invoice.currency);
  }
  return formatAmountWithCurrencySuffix(0, invoice.sourceCurrency);
};

const generateInvoiceMoneyColumns = ({
  id,
  currencyId,
  exportFormatter,
  currencyCodeFormatter,
  headerTitle,
  headerTooltipContent,
  disableSortBy,
  hidden = false,
  Cell,
}: {
  id: string;
  currencyId: string;
  exportFormatter: (value: ContractorInvoice) => string;
  currencyCodeFormatter: (value: ContractorInvoice) => string;
  headerTitle: string;
  headerTooltipContent?: React.ReactNode;
  disableSortBy: boolean;
  hidden?: boolean;
  Cell: ComponentType<{
    value: ContractorInvoice;
    rows: Array<{ original: ContractorInvoice }>;
  }>;
}) => [
  /**
   * This is the normal column that is visible to the user.
   *
   * The export data just exports the number, without any currency information.
   * This is because it is much easier to parse.
   */
  {
    Header: headerTooltipContent ? (
      <TooltipHeader tooltip={headerTooltipContent}>{headerTitle}</TooltipHeader>
    ) : (
      headerTitle
    ),
    toggleName: headerTitle,
    exportHeader: headerTitle,
    id,
    align: 'right',
    accessor: (invoice: ContractorInvoice) => invoice,
    Cell,
    exportData: (value: ContractorInvoice) => exportFormatter(value),
    disableFilters: true,
    disableSortBy,
    hiddenColumn: hidden,
  },

  /**
   * For the user to have currency information in the export, we add a hidden
   * column with the currency code.
   */
  {
    Header: `${headerTitle} currency`,
    id: currencyId,
    accessor: (invoice: ContractorInvoice) => invoice,
    exportData: (value: ContractorInvoice) => currencyCodeFormatter(value),
    disableInView: true,
    disableFilters: true,
    hiddenColumn: hidden,
  },
];

export const invoiceAmountTableColumns = ({
  disableSortBy = false,
  showHeaderToolTip = false,
} = {}) =>
  generateInvoiceMoneyColumns({
    // id and currencyId should move to `chosenAmount` and `chosenCurrencyCode` after the properties are added.
    id: 'amount',
    headerTitle: 'Invoice amount',
    currencyId: 'currencyCode',
    exportFormatter: formatInvoiceAmountForExport,
    currencyCodeFormatter: (value: ContractorInvoice) =>
      getInvoiceCurrencyAndAmount(value).currency.code,
    headerTooltipContent: showHeaderToolTip
      ? 'Amount in the currency raised on the invoice'
      : undefined,
    disableSortBy,
    Cell: ({ value }: { value: ContractorInvoice }) => {
      return <AlignRight>{formatInvoiceAmount(value)}</AlignRight>;
    },
  });

export const billedAmountTableColumns = ({
  disableSortBy = true,
  showHeaderToolTip = false,
} = {}) =>
  generateInvoiceMoneyColumns({
    id: 'sourceAmount',
    headerTitle: 'Billed amount',
    currencyId: 'sourceCurrencyCode',
    exportFormatter: formatBilledAmountForExport,
    currencyCodeFormatter: (value: ContractorInvoice) =>
      getBilledCurrencyAndAmount(value).currency.code,
    headerTooltipContent: showHeaderToolTip
      ? 'Amount raised on invoice converted to your billing currency using Remote rate at time of invoice creation'
      : undefined,
    disableSortBy,
    hidden: false,
    Cell: ({ value }: { value: ContractorInvoice }) => {
      return <AlignRight>{formatBilledAmount(value)}</AlignRight>;
    },
  });

/**
 * This column is only meant to be used in the contractor view.
 */
export const payoutAmountTableColumns = ({
  disableSortBy = true,
  showHeaderToolTip = false,
} = {}) =>
  generateInvoiceMoneyColumns({
    id: 'targetAmount',
    headerTitle: 'Payout amount',
    currencyId: 'targetCurrencyCode',
    exportFormatter: formatPayoutAmountForExport,
    currencyCodeFormatter: (value: ContractorInvoice) =>
      getPayoutCurrencyAndAmount(value)?.currency?.code ?? '-',
    headerTooltipContent: showHeaderToolTip
      ? 'This is the amount that will be paid out to your withdrawal method. In some cases, the amount you receive cannot be guaranteed so we are unable to provide an estimate.'
      : undefined,
    disableSortBy,
    hidden: false,

    Cell: ({ value }: { value: ContractorInvoice }) => (
      <AlignRight>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end">
          {(() => {
            if (usesGuaranteedPayoutMode(value) && hasUnknownSwiftFee(value)) {
              return (
                <>
                  <Text>{formatPayoutAmount(value)}</Text>
                  <Tooltip label="We will send you a payout of this amount, but your bank will charge additional fees. The amount of your bank fees is unknown to us.">
                    <IconV2OutlineUnlock fill={themeV2.colors.grey[600]} width={16} />
                  </Tooltip>
                </>
              );
            }

            if (hasGuaranteedPayoutAmount(value)) {
              return (
                <>
                  <Text>{formatPayoutAmount(value)}</Text>
                  <Tooltip label="This amount is guaranteed and you will receive a payout of this amount.">
                    <IconV2OutlineLock fill={themeV2.colors.green[800]} width={16} />
                  </Tooltip>
                </>
              );
            }

            const quote = getLatestQuoteFromInvoice(value);

            if (quote) {
              return <Text>{formatPayoutAmount(value)}</Text>;
            }

            return <EmptyCell />;
          })()}
        </Stack>
      </AlignRight>
    ),
  });
