import { Text, Stack, Box, Pill } from '@remote-com/norma';
import { components } from 'react-select';

import { filterNonDeprecatedFields } from '@/src/domains/contracts/shared/helpers';

export function getDisplayableSmartFields(editorStorage) {
  return [
    ...editorStorage.placeholderSmartFields,
    ...filterNonDeprecatedFields(editorStorage.smartFieldDefinitions),
  ].map(({ key, isPlaceholder }) => ({
    value: key,
    label: key,
    isPlaceholder,
  }));
}

export const OptionSmartField = ({ children, ...props }) => {
  const {
    data: { isPlaceholder },
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      <Stack
        direction="row"
        gap={3}
        width="100%"
        justifyContent="space-between"
        pr={isSelected ? 0 : 4}
      >
        {children}
        {isPlaceholder && (
          <Box>
            <Pill tone="warning">Placeholder</Pill>
          </Box>
        )}
      </Stack>
    </components.Option>
  );
};

export const GroupLabel = ({ label }) => (
  <Box px={5} py={3}>
    <Text variant="xsMedium" color="grey.500">
      {label}
    </Text>
  </Box>
);
