import { IconChevronLeft } from '@remote-com/norma/icons/IconChevronLeft';
import PropTypes from 'prop-types';

import { Button, ButtonIcon, StandaloneLink } from '@/src/components/Button';

export const GoBackButton = ({ hideLabel, label, Icon, variant, ...props }) => {
  return hideLabel || !label ? (
    <ButtonIcon
      aria-label={label || 'Back'}
      Icon={Icon || IconChevronLeft}
      variant={variant}
      {...props}
    />
  ) : (
    <StandaloneLink data-testid="go-back-button" IconBefore={Icon || IconChevronLeft} {...props}>
      {label}
    </StandaloneLink>
  );
};

GoBackButton.propTypes = {
  ...Button.propTypes,
  ...ButtonIcon.propTypes,
  hideLabel: PropTypes.bool,
};
