import { Stack } from '@remote-com/norma';
import { IconV2DuotoneTimesFull } from '@remote-com/norma/icons/IconV2DuotoneTimesFull';

import { Button } from '@/src/components/Button';
import { CardStatusMessage } from '@/src/components/Cards';
import { useTodosContext } from '@/src/domains/todos/TodosProvider';

export const TodosOverlayError = () => {
  const { refetch } = useTodosContext();

  return (
    <Stack px={8} py={9} gap={6} alignItems="center">
      <CardStatusMessage
        icon={IconV2DuotoneTimesFull}
        iconTone="red"
        title="Things to do failed to load"
        description="Something went wrong. Please try again"
      />
      <Button size="sm" onClick={refetch} variant="outline" tone="secondary">
        Try again
      </Button>
    </Stack>
  );
};
