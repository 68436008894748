import { useCallback } from 'react';
import type { ValueOf } from 'type-fest';

import type {
  ArchiveVariables,
  CreateVariables,
  DuplicateVariables,
  UpdateVariables,
} from '@/src/domains/contracts/contractTemplate/useEditorTemplateActions/types';
import { useContractTemplateActions } from '@/src/domains/contracts/contractTemplate/useEditorTemplateActions/useContractTemplateActions';
import type { CONTRACT_TEMPLATE_TYPES } from '@/src/domains/contractTemplates/contractTemplates/constants';

type EditorTemplateType = ValueOf<typeof CONTRACT_TEMPLATE_TYPES>;
type ContractTemplateActions = ReturnType<typeof useContractTemplateActions>;

export function useEditorTemplateActions() {
  const contractTemplateActions = useContractTemplateActions();

  const resetContractDocumentActions = contractTemplateActions.reset;

  const resetAll = useCallback(() => {
    resetContractDocumentActions();
  }, [resetContractDocumentActions]);

  return {
    create<TemplateType extends EditorTemplateType>(
      type: TemplateType,
      variables: CreateVariables,
      mutationOptions: Parameters<ContractTemplateActions['create']>[1]
    ) {
      return contractTemplateActions.create(
        variables,
        mutationOptions as Parameters<ContractTemplateActions['create']>[1]
      );
    },
    duplicate<TemplateType extends EditorTemplateType>(
      type: TemplateType,
      variables: DuplicateVariables,
      mutationOptions: Parameters<ContractTemplateActions['duplicate']>[1]
    ) {
      return contractTemplateActions.duplicate(variables, mutationOptions);
    },
    update<TemplateType extends EditorTemplateType>(
      type: TemplateType,
      variables: UpdateVariables,
      mutationOptions: Parameters<ContractTemplateActions['update']>[1]
    ) {
      return contractTemplateActions.update(variables, mutationOptions);
    },
    archive<TemplateType extends EditorTemplateType>(
      type: TemplateType,
      variables: ArchiveVariables,
      mutationOptions: Parameters<ContractTemplateActions['archive']>[1]
    ) {
      return contractTemplateActions.archive(variables, mutationOptions);
    },
    reset: resetAll,
    isLoading: contractTemplateActions.isLoading,
    error: contractTemplateActions.error,
  };
}
