import { Pill, Text } from '@remote-com/norma';
import { IconArrowDown } from '@remote-com/norma/icons/IconArrowDown';
import { NodeViewWrapper } from '@tiptap/react';
import { useState } from 'react';

import { isCompanySignatory, isRecipientSignatory } from '@/src/domains/contracts/helpers';

import {
  PillWrapper,
  PlaceholderSmartField,
  SignatureContent,
  SignatureDetails,
  StyledButtonInline,
  WithSignatureUnderline,
  Wrapper,
} from './styled';

const getSignatureText = (
  isCompanySignature,
  contractSignatory,
  hasBeenSignedByRecipient,
  recipientSignatureValue
) => {
  /*
   * When a signature is stored on the contract signatory,
   * it means the signatory has signed the entire document.
   * Therefore we simply show that signature.
   */
  if (contractSignatory?.signature) {
    return contractSignatory.signature;
  }

  /*
   * For companies:
   * - They either have signed the document or not.
   *
   * For recipients:
   * - Each field can be signed or unsigned independently.
   */
  if (!isCompanySignature && hasBeenSignedByRecipient) {
    return recipientSignatureValue;
  }

  /**
   * If we've made it here, the recipient hasn't signed this specific field.
   * The consuming code will show some sort of placeholder instead.
   */
  return null;
};

export const SignatureComponent = (props) => {
  const { extension, node, updateAttributes } = props;
  const {
    signatories,
    handleOnClickRecipientSignature,
    handleOnClickCompanySignature,
    recipientSignatureValue,
  } = extension.options;

  const { isCompanySignature } = node.attrs;
  const [hasBeenSignedByRecipient, setHasBeenSignedByRecipient] = useState(false);

  const contractSignatory = isCompanySignature
    ? signatories.find(isCompanySignatory)
    : signatories.find(isRecipientSignatory);

  const signatureText = getSignatureText(
    isCompanySignature,
    contractSignatory,
    hasBeenSignedByRecipient,
    recipientSignatureValue
  );

  const signatureTimestamp = contractSignatory?.signedAt;
  const signatoryEmail = contractSignatory?.user?.email;
  const signatoryName = contractSignatory?.user?.name;
  const procurationUser = contractSignatory?.procurationUser;

  const recipientOnClick = handleOnClickRecipientSignature
    ? () => {
        /*
         * If a signature value is provided, we want to show that signature
         * when user clicks on the signature field.
         */
        if (recipientSignatureValue) {
          updateAttributes({ hasBeenSignedByRecipient: true });
          setHasBeenSignedByRecipient(true);
        }

        /*
         * The parent can optionally handle the click itself.
         * e.g show a prompt to provide the signature value.
         */
        handleOnClickRecipientSignature?.();
      }
    : null;

  const handleOnClick = isCompanySignature ? handleOnClickCompanySignature : recipientOnClick;

  /**
   * We want to show the signature prompt only to recipients
   * who have not yet signed the document.
   *
   * This is true when:
   * 1. It's not a company signature (i.e., it's a recipient signature)
   * 2. There's a click handler available for the signature
   * 3. The signature text is not yet provided
   */
  const canRecipientSign = !!handleOnClickRecipientSignature;
  const isUnsignedRecipientSignature = !isCompanySignature && canRecipientSign;
  const shouldShowSignaturePrompt = isUnsignedRecipientSignature && !signatureText;

  return (
    <NodeViewWrapper className="react-component">
      <Wrapper>
        {shouldShowSignaturePrompt ? (
          <>
            <PillWrapper>
              <Pill Icon={IconArrowDown} tone="fuchsia">
                MISSING SIGNATURE
              </Pill>
            </PillWrapper>
            <WithSignatureUnderline>
              <StyledButtonInline variant="outline" onClick={handleOnClick}>
                Click here to sign
              </StyledButtonInline>
            </WithSignatureUnderline>
          </>
        ) : (
          <WithSignatureUnderline>
            <SignatureContent
              color={signatureText ? 'grey.900' : 'grey.300'}
              data-dd-action-name="Signature"
              isSigned={!!signatureText}
              onClick={handleOnClick}
            >
              {signatureText || 'signature placeholder'}
            </SignatureContent>
          </WithSignatureUnderline>
        )}
        <SignatureDetails>
          {signatoryName ? (
            <Text variant="smSemiBold" data-dd-action-name="Signatory name">
              {procurationUser && 'p.p. '}
              {signatoryName}
            </Text>
          ) : (
            <PlaceholderSmartField variant="raw">
              <Text variant="smSemiBold">{`{signatory name}`}</Text>
            </PlaceholderSmartField>
          )}
          {procurationUser && (
            <>
              <Text variant="sm" data-dd-action-name="Procuration user name" mt={5}>
                {`For ${procurationUser.name}`}
              </Text>
              <Text variant="sm" data-dd-action-name="Procuration user email">
                {procurationUser.email}
              </Text>
            </>
          )}
          {signatureTimestamp ? (
            <Text variant="sm">{signatureTimestamp}</Text>
          ) : (
            <PlaceholderSmartField variant="raw">{`{signing timestamp}`}</PlaceholderSmartField>
          )}
          {signatoryEmail ? (
            <Text variant="sm" data-dd-action-name="Signatory email">
              {signatoryEmail}
            </Text>
          ) : (
            <PlaceholderSmartField variant="raw">{`{signatory email}`}</PlaceholderSmartField>
          )}
        </SignatureDetails>
      </Wrapper>
    </NodeViewWrapper>
  );
};
