import { useEffect, useState } from 'react';

import useLocalStorage from '@/src/hooks/useLocalStorage';

import { SAVED_FILTERS_KEY } from '../helpers/constants';

/**
 * Hook to manage saved filters for a specific table.
 * @param {string} tableId - The ID of the table to manage filters for.
 * @returns {[Array<{id: string, label: string, filters: any}>,
 *          (label: string, data: any) => void,
 *          (id: string, label: string) => void,
 *          (id: string, label: string, newLabel: string) => void]} -
 *          Returns an array where:
 *          - The first element is an array of saved filters.
 *          - The second element is a function to add a filter.
 *          - The third element is a function to remove a filter.
 *          - The fourth element is a function to update a filter.
 */
export const useSaveFilter = (tableId) => {
  const [value, setValue] = useLocalStorage(SAVED_FILTERS_KEY, []);
  // state with saved filters for the given tableId
  const [savedFilters, setSavedFilters] = useState([]);

  // maintain localstorage as SSOT but update savedFilters state
  useEffect(() => {
    setSavedFilters(value.filter((item) => item.id === tableId));
  }, [tableId, value, setSavedFilters]);

  const addFilter = (label, data) => {
    const newFilter = {
      id: tableId,
      label,
      filters: data,
    };

    if (
      savedFilters.some(
        (filter) => filter.label.trim().toLowerCase() === label.trim().toLowerCase()
      )
    ) {
      throw Error(`The filter name ${label} has already been taken for table ${tableId}`);
    }

    return setValue((prev) => [...prev, newFilter]);
  };

  const removeFilter = (id, label) => {
    const newValue = value.filter((item) => !(item.id === id && item.label === label));
    setValue(newValue);
  };

  const updateFilter = (id, label, newLabel) => {
    const updValue = value.map((item) =>
      item.id === id && item.label === label ? { ...item, label: newLabel } : item
    );
    setValue(updValue);
  };

  return [savedFilters, addFilter, removeFilter, updateFilter];
};
