// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconGift = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 24 24" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M4 11a1 1 0 0 1 1 1v9h14v-9a1 1 0 1 1 2 0v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V12a1 1 0 0 1 1-1z" /><path fillRule="evenodd" clipRule="evenodd" d="M1 7a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm2 1v3h18V8H3z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 6a1 1 0 0 1 1 1v15a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1z" /><path fillRule="evenodd" clipRule="evenodd" d="M7.5 3a1.5 1.5 0 0 0 0 3h3.15a8.2 8.2 0 0 0-.46-1.02C9.56 3.84 8.69 3 7.5 3zM12 7l.98-.2v-.02a4.23 4.23 0 0 0-.06-.24 10.7 10.7 0 0 0-.98-2.52C11.19 2.66 9.8 1 7.5 1a3.5 3.5 0 1 0 0 7H12a1 1 0 0 0 .98-1.2L12 7z" /><path fillRule="evenodd" clipRule="evenodd" d="M12.06 4.02C12.81 2.66 14.2 1 16.5 1a3.5 3.5 0 1 1 0 7H12a1 1 0 0 1-.98-1.2L12 7l-.98-.2v-.02l.02-.05c0-.05.02-.11.04-.19a10.7 10.7 0 0 1 .98-2.52zM13.35 6h3.15a1.5 1.5 0 0 0 0-3c-1.2 0-2.06.84-2.69 1.98A8.2 8.2 0 0 0 13.35 6zm-.37 1.2z" /></svg>;
const ForwardRef = forwardRef(IconGift);
export { ForwardRef as IconGift };