import { IconV2OutlineEdit } from '@remote-com/norma/icons/IconV2OutlineEdit';
import { IconV2OutlineTrash } from '@remote-com/norma/icons/IconV2OutlineTrash';

import {
  ConfigurationListItem as ListItem,
  ConfigurationListItemActions as ListItemActions,
  ConfigurationListItemButton as ListItemButton,
  ConfigurationListItemName as ListItemName,
} from '@/src/components/Table/Components/ColumnsConfigurationDrawer/ConfigurationList';

const messages = {
  deleteView: 'Delete',
  renameView: 'Rename',
};

type TableViewsListItemProps = {
  name: string;
  onDeleteView: (configurationName: string) => void;
  onRenameView: (configurationName: string) => void;
};

export const TableViewsListItem = ({
  name,
  onDeleteView,
  onRenameView,
}: TableViewsListItemProps) => {
  const handleDeleteView = () => onDeleteView(name);
  const handleRenameView = () => onRenameView(name);

  return (
    <ListItem data-testid="saved-table-view-item">
      <ListItemName>{name}</ListItemName>
      <ListItemActions>
        <ListItemButton
          data-testid="rename-saved-table-item"
          icon={IconV2OutlineEdit}
          label={messages.renameView}
          onClick={handleRenameView}
        />
        <ListItemButton
          data-testid="delete-saved-table-item"
          icon={IconV2OutlineTrash}
          label={messages.deleteView}
          onClick={handleDeleteView}
          tone="destructive"
        />
      </ListItemActions>
    </ListItem>
  );
};
