// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSavings = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M1 8.15c0-1.6 1.31-2.91 2.92-2.91a1 1 0 1 1 0 2 .91.91 0 0 0-.69 1.51c.1.11.24.21.45.27.21-.17.44-.33.68-.49 1.9-1.19 4.47-1.72 7.57-1.62a28.47 28.47 0 0 1 3.55.34c.7-1.1 1.93-2 3.35-2h.1A1.43 1.43 0 0 1 20.3 7l-.62 2.62c.34.42.63.88.87 1.36l.45.06a2.25 2.25 0 0 1 1.97 2.23v2.17c0 1.13-.85 2.1-1.97 2.23l-.31.04c-.4.87-.95 1.66-1.63 2.34v1.74c0 1.24-1 2.25-2.25 2.25h-2.49a2.25 2.25 0 0 1-2.18-1.72h-.88a2.25 2.25 0 0 1-2.18 1.72h-2.5c-1.23 0-2.24-1.01-2.24-2.25v-.82a7.15 7.15 0 0 1-3.34-7.1 7.4 7.4 0 0 1 1.24-3.33A2.93 2.93 0 0 1 1 8.14Zm14.79 1.2-.01-.01h-.04a8.42 8.42 0 0 0-.95-.18c-.67-.1-1.67-.2-2.93-.25-2.87-.1-5 .41-6.43 1.32a5.13 5.13 0 0 0-2.44 3.9 5.09 5.09 0 0 0 2.77 5.32 1 1 0 0 1 .6.91v1.43c0 .14.1.25.24.25h2.49c.13 0 .25-.11.25-.25v-.47a1 1 0 0 1 1-1h2.75a1 1 0 0 1 1 1v.47c0 .14.1.25.24.25h2.5c.13 0 .24-.11.24-.25v-2.18a1 1 0 0 1 .34-.75 5.82 5.82 0 0 0 1.64-2.4 1 1 0 0 1 .82-.66l.89-.11a.25.25 0 0 0 .22-.25v-2.17c0-.12-.1-.23-.22-.25l-1-.12a1 1 0 0 1-.8-.62 5.86 5.86 0 0 0-1.1-1.72l-.35-.4.65-2.75c-.5.25-.98.73-1.2 1.32a1 1 0 0 1-1.17.61Zm2.58-2.8Z" /><path fillRule="evenodd" clipRule="evenodd" d="M10 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-1a1 1 0 0 0 0 2 1 1 0 0 0 0-2Z" /></svg>;
const ForwardRef = forwardRef(IconSavings);
export { ForwardRef as IconSavings };