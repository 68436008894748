import { error } from '@/src/helpers/general';

import type {
  AudienceCondition,
  AudienceConditionEqual,
  AudienceConditionIn,
  AudienceConditionSemverGreaterOrEqual,
  AudienceConditionSemverLessOrEqual,
} from './audience';
import type { FeatureFlagUserAttributes } from './config';
import { parseSemver } from './semverParser';

function isNil(value: any) {
  return value === null || typeof value === 'undefined';
}
/**
 * Evaluates an "equal" operator for a given user attribute value.
 */
export function exactEvaluator(
  condition: AudienceConditionEqual,
  userAttributeValue: FeatureFlagUserAttributes[AudienceCondition['attribute']],
  featureFlagName: string,
  audienceKey: string
): boolean {
  const conditionValue = condition.value;
  const conditionValueType = typeof conditionValue;
  const conditionAttribute = condition.attribute;

  const userValueType = typeof userAttributeValue;

  if (
    userValueType !== 'undefined' &&
    userAttributeValue !== null &&
    conditionValueType !== userValueType
  ) {
    error(
      `Type mismatch for "${conditionAttribute}" (condition: ${conditionValueType} vs user attribute: ${userValueType}) attribute, on audience "${audienceKey}" for Feature Flag "${featureFlagName}"`
    );
    return false;
  }

  return conditionValue === userAttributeValue;
}
/**
 * Evaluates an "in" operator for a given user attribute value.
 */
export function inArrayEvaluator(
  condition: AudienceConditionIn,
  userAttributeValue: FeatureFlagUserAttributes[AudienceCondition['attribute']]
): boolean {
  return !isNil(userAttributeValue) && condition.value.includes(userAttributeValue);
}

export function semverGteEvaluator(
  condition: AudienceConditionSemverGreaterOrEqual,
  userAttributeValue: FeatureFlagUserAttributes[AudienceCondition['attribute']]
): boolean {
  if (isNil(userAttributeValue)) return false;

  const userSemver = parseSemver(userAttributeValue as string);
  const conditionSemver = parseSemver(condition.value);

  if (!userSemver || !conditionSemver) return false;

  if (userSemver.major !== conditionSemver.major) {
    return userSemver.major > conditionSemver.major;
  }
  if (userSemver.minor !== conditionSemver.minor) {
    return userSemver.minor > conditionSemver.minor;
  }
  return userSemver.patch >= conditionSemver.patch;
}

export function semverLteEvaluator(
  condition: AudienceConditionSemverLessOrEqual,
  userAttributeValue: FeatureFlagUserAttributes[AudienceCondition['attribute']]
): boolean {
  if (isNil(userAttributeValue)) return false;

  const userSemver = parseSemver(userAttributeValue as string);
  const conditionSemver = parseSemver(condition.value);

  if (!userSemver || !conditionSemver) return false;

  if (userSemver.major !== conditionSemver.major) {
    return userSemver.major < conditionSemver.major;
  }
  if (userSemver.minor !== conditionSemver.minor) {
    return userSemver.minor < conditionSemver.minor;
  }
  return userSemver.patch <= conditionSemver.patch;
}
