import {
  focusVisible,
  Text,
  Box,
  Stack,
  ButtonInlineStyles,
  sharedTransition,
} from '@remote-com/norma';
import { IconV2OutlineTimes } from '@remote-com/norma/icons/IconV2OutlineTimes';
import styled, { css } from 'styled-components';

import { Button } from '@/src/components/Button';
import { InputSpacer } from '@/src/components/Ui/Form/Input';
import { InputSpacerStyled as InputSpacerV2 } from '@/src/components/Ui/Form/InputSpacer';

export const FiltersArea = styled(Stack).attrs({
  direction: 'row',
  gap: 3,
})`
  align-items: center;
  flex-wrap: wrap;

  ${InputSpacer}, ${InputSpacerV2} {
    ${({ filtersSpacing, theme }) =>
      filtersSpacing &&
      css`
        margin-top: ${theme.space[filtersSpacing]}px;
        &:first-child {
          margin-top: 0;
        }
      `}
  }
`;

export const FilterButton = styled(Button).attrs({
  IconAfter: IconV2OutlineTimes,
  variant: 'outline',
  size: 'sm',
})``;

export const ResizeHandle = styled(Box)`
  display: block;
  position: absolute;
  cursor: col-resize;
  width: ${({ theme }) => theme.space[4]}px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 5px solid ${({ theme }) => theme.colors.brand[500]};
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export const SortIcon = styled.svg`
  margin-left: ${({ theme }) => theme.space[2]}px;
  width: ${({ theme }) => theme.space[5]}px;
  height: ${({ theme }) => theme.space[5]}px;
  fill: ${({ theme }) => theme.colors.grey[900]};
`;

export const StyledTh = styled.th`
  ${({ theme, $align, $hasColumnMenu, $isMenuOpen, resizing }) => css`
    ${sharedTransition()}
    ${theme.typography['2xsSemiBold']}
    align-items: center;
    line-height: 18px;
    position: relative;
    white-space: nowrap;
    padding: ${theme.space[5]}px;
    text-align: ${$align};
    display: flex;
    justify-content: ${$align === 'right' ? 'flex-end' : 'flex-start'};
    text-transform: uppercase;
    background-color: ${theme.colors.grey[100]};
    isolation: isolate;
    color: ${theme.colors.grey[600]};

    &:hover {
      background-color: ${$hasColumnMenu && theme.colors.grey[200]};
    }

    ${$hasColumnMenu &&
    $isMenuOpen &&
    css`
      background-color: ${theme.colors.grey[200]};
    `}

    &:first-child {
      padding-left: ${theme.space[4]}px;
    }

    &:last-child {
      padding-right: ${theme.space[4]}px;
    }

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      &:last-child {
        padding-right: 20px;
      }
      &:first-child {
        padding-left: 20px;
      }
    }

    ${resizing &&
    css`
      ${ResizeHandle} {
        opacity: 1;
      }
    `}
  `}
`;

export const SortingButton = styled(Button).attrs({ variant: 'raw' })`
  all: unset;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[900]};
  margin-right: -18px;
  ${focusVisible(css`
    color: ${({ theme }) => theme.colors.grey[900]};
    box-shadow: ${({ theme }) =>
      `0 0 0 3px ${theme.colors.blank}, 0 0 0 5px ${theme.colors.grey[500]}`};
    background: ${({ theme }) => theme.colors.blank};
    border-radius: 5px;
    ${SortIcon} {
      opacity: 1;
    }
  `)}
`;

export const ParagraphEl = styled.span`
  ${({ theme }) => theme.typography.sm};
  color: ${({ $type, theme }) => ($type === 'secondary' ? theme.colors.grey[500] : 'inherit')};
`;

const ToolbarBaseStack = styled(Stack).attrs({
  direction: 'row',
  gap: 3,
})``;

export const Toolbar = styled(ToolbarBaseStack).attrs({ px: 5, py: 4 })`
  align-items: flex-start;
  min-height: 58px;
  position: relative;
  z-index: 20;
`;

const ToolbarBodyBaseStack = styled(ToolbarBaseStack)`
  align-items: center;
  flex-wrap: wrap;
  min-height: 34px;
`;

export const ToolbarControls = styled(ToolbarBodyBaseStack)``;

export const ToolbarActions = styled(ToolbarBodyBaseStack)`
  justify-content: flex-end;
  margin-left: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-shrink: 0;
  }
`;

export const ToolbarActionsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: 24px;
  }
`;

export const FiltersInCols = styled(Box).attrs(({ gridRowGap = 7 }) => ({
  display: 'grid',
  gridColumnGap: 6,
  gridRowGap,
}))`
  grid-template-columns: repeat(2, minmax(auto, 288px));

  ${InputSpacer}, ${InputSpacerV2} {
    margin-top: 0;
  }
`;

export const RowFull = ({ colSpan, children }) => (
  <tr>
    <td style={{ gridColumn: `1 / ${colSpan + 1}` }} colSpan={colSpan}>
      {children}
    </td>
  </tr>
);

// Do not use display grid, it doesn't work well in Safari
export const StateWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[9]}px;
`;

export const DeprecatedCustomTableFooter = styled.footer`
  background: ${({ theme }) => theme.colors.background.base};
  padding: 16px;
  border-radius: 0 0 10px 10px;
`;

export const UnAssignedAvatar = styled(Box)`
  border: 1px dashed ${({ theme }) => theme.colors.grey[300]};
  border-radius: 50%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.grey[300]};
  aspect-ratio: 1/1;
`;

export const SpecialistName = styled(Text)`
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MainSelectorWrapper = styled(Box)`
  button {
    &:focus-visible,
    &:focus.focus-visible,
    &:hover,
    &:focus,
    &:focus-visible {
      border-color: none;
      box-shadow: none;
      ${SpecialistName} {
        color: ${({ theme }) => theme.colors.primary};
      }
      ${UnAssignedAvatar} {
        border-style: solid;
        border-color: rgba(0, 97, 255, 0.7);
        background: rgba(0, 97, 255, 0.1);
        svg {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

export const OptionText = styled(Text).attrs({
  variant: 'smMedium',
})`
  width: calc(100% - 44px); /* Check icon width + right */
`;

export const OptionUserText = styled(Text).attrs({ variant: 'smMedium' })`
  max-width: calc(100% - 28px);
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserOptionContainer = styled(Box).attrs({ display: 'flex', alignItems: 'center' })`
  width: 100%;
`;

export const UserCheckedContainer = styled(Box).attrs({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
})`
  width: calc(100% - 36px);
`;

export const CheckedBase = styled(Box)`
  background: ${({ theme }) => theme.colors.blank};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  position: absolute;
`;

export const Checked = styled(CheckedBase)`
  right: 16px;
`;

export const CheckedPersonIcon = styled(CheckedBase)`
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
`;

export const Separator = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[200]};
`;

export const SelectedPersonWrapper = styled(Box)`
  &:hover {
    ${SpecialistName} {
      color: ${({ theme }) => theme.colors.primary};
    }
    ${UnAssignedAvatar} {
      border-style: solid;
      border-color: rgba(0, 97, 255, 0.7);
      background: rgba(0, 97, 255, 0.1);
      svg {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const PersonTextContainer = styled(Box)`
  width: calc(100% - 44px);
`;

export const UserSelectButton = styled(Button)`
  display: flex;
  align-items: center;
  ${({ $isCompactTable }) => ($isCompactTable ? 'padding: 0;' : 'padding: 4px 2px;')}
  ${({ location }) => {
    if (location === 'inline') {
      return css`
        ${ButtonInlineStyles}
        display: flex;
        &:focus-visible {
          box-shadow: none;
        }
      `;
    }
    return css`
      border-width: 2px;
      border-color: transparent;
      border-style: solid;
      border-radius: 40px;
      &:focus-visible {
        border-color: ${({ theme }) => theme.colors.primary};
        background: rgba(0, 97, 255, 0.1);
      }
    `;
  }}
`;
