import type { UseGetOptions } from '@remote-com/data-layer';
import { useGet } from '@remote-com/data-layer';

export default function useCompanyData(
  reactQueryOptions: UseGetOptions<'/api/v1/employer/company'> = {}
) {
  return useGet('/api/v1/employer/company', {
    options: {
      ...reactQueryOptions,
      select: (response) => {
        return response.data.company;
      },
    },
  });
}
