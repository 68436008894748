import {
  CONDITIONAL_OPERATORS,
  CON_VALUE_CONSTANT_REGEX,
} from '@/src/domains/contracts/ContractsEditor/constants';
import { comparisonValueTypes } from '@/src/domains/contractTemplates/contractTemplates/modals/ConditionalModal/constants';

export const getComparisonValueType = (value) => {
  if (!value) {
    return '';
  }

  return value.startsWith('(') ? comparisonValueTypes.CONSTANT : comparisonValueTypes.SMARTFIELD;
};

export const getComparisonConstantOrSmartField = (value) => {
  const comparisonValueType = getComparisonValueType(value);

  if (!value || !comparisonValueType) {
    return {};
  }

  if (comparisonValueType === comparisonValueTypes.CONSTANT) {
    return { comparisonConstant: value.match(CON_VALUE_CONSTANT_REGEX)[1] };
  }

  return { comparisonSmartField: value };
};

export const hasComparisonValue = (operator) =>
  ![CONDITIONAL_OPERATORS.EXISTS, CONDITIONAL_OPERATORS.NOT_EXISTS].includes(operator);

export const hasMultipleComparisonValues = (operator) =>
  [CONDITIONAL_OPERATORS.IN, CONDITIONAL_OPERATORS.NOT_IN].includes(operator);

export const hasComparisonSmartField = (operator) =>
  hasComparisonValue(operator) && !hasMultipleComparisonValues(operator);

export const getFormattedComparisonValue = ({
  comparisonValueType,
  comparisonConstant,
  comparisonSmartField,
  operator,
}) => {
  if (!hasComparisonValue(operator)) {
    return '';
  }

  if (comparisonValueType === comparisonValueTypes.SMARTFIELD) {
    return comparisonSmartField;
  }

  return `(${comparisonConstant})`;
};

export const createConditionalNodeProps = ({
  smartField,
  operator,
  comparisonConstant,
  comparisonSmartField,
  comparisonValueType,
}) => {
  const formattedComparisonValue = getFormattedComparisonValue({
    comparisonValueType,
    comparisonConstant,
    comparisonSmartField,
    operator,
  });

  const comparisonValueFields = formattedComparisonValue ? [formattedComparisonValue] : [];
  const comparisonValueConditional = formattedComparisonValue
    ? `__${formattedComparisonValue}`
    : '';

  return {
    fields: [smartField, ...comparisonValueFields],
    operator,
    conditional: `${smartField}__${operator}${comparisonValueConditional}`,
  };
};
