import type { Node } from '@tiptap/pm/model';

import { CONTRACT_NODE_TYPES } from '@/src/domains/contracts/ContractsEditor/constants';

// Use non-breaking space when padding, so that the short label
// does not break across multiple lines.
const NON_BREAKING_SPACE = '\u00A0';

export function findFieldsById(doc: Node, fieldId: string) {
  const results: { node: Node; pos: number }[] = [];
  doc.descendants((node, pos) => {
    if (
      node.type.name === CONTRACT_NODE_TYPES.MANUALLY_ENTERED_FIELD &&
      node.attrs.id === fieldId
    ) {
      results.push({ node, pos });
    }
  });
  return results;
}

export function padNodeLabel(nodeLabel: string, minCharacterCount: number) {
  const padSize = minCharacterCount - nodeLabel.length;

  if (padSize <= 0) {
    return nodeLabel;
  }

  const padEnd = Math.round(padSize / 2);
  return nodeLabel
    .padEnd(nodeLabel.length + padEnd, NON_BREAKING_SPACE)
    .padStart(minCharacterCount, NON_BREAKING_SPACE);
}
