// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineCommentDots = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M9.97 1.25h4.06c1.38 0 2.45 0 3.32.07.88.07 1.6.22 2.26.56a5.75 5.75 0 0 1 2.51 2.51c.34.66.49 1.38.56 2.26.07.87.07 1.94.07 3.32v9.23c0 .49 0 .9-.02 1.22-.02.32-.07.65-.22.95a2.25 2.25 0 0 1-2.17 1.23 2.45 2.45 0 0 1-.93-.3c-.29-.15-.64-.36-1.06-.61l-.02-.02a9.9 9.9 0 0 0-.98-.55c-.39-.17-.8-.29-1.22-.34-.25-.03-.5-.03-1.13-.03H9.97c-1.38 0-2.45 0-3.32-.07a5.92 5.92 0 0 1-2.26-.56 5.75 5.75 0 0 1-2.51-2.51 5.92 5.92 0 0 1-.56-2.26c-.07-.87-.07-1.94-.07-3.32V9.97c0-1.38 0-2.45.07-3.32.07-.88.22-1.6.56-2.26a5.75 5.75 0 0 1 2.51-2.51 5.92 5.92 0 0 1 2.26-.56c.87-.07 1.94-.07 3.32-.07Zm-3.2 1.57c-.78.06-1.29.18-1.7.4-.8.4-1.45 1.05-1.86 1.85-.2.41-.33.92-.4 1.7-.06.8-.06 1.82-.06 3.23v2c0 1.41 0 2.43.07 3.22.06.79.18 1.3.4 1.71.4.8 1.05 1.45 1.85 1.86.41.2.92.33 1.7.4.8.06 1.82.06 3.23.06h5.05c.56 0 .91 0 1.26.04.57.07 1.12.23 1.65.46.32.14.62.32 1.1.61l.04.03c.45.27.76.45 1 .57.23.12.32.14.35.14.3.02.58-.14.72-.41.01-.02.04-.1.06-.38.02-.26.02-.62.02-1.14V10c0-1.41 0-2.43-.07-3.22a4.47 4.47 0 0 0-.4-1.7 4.25 4.25 0 0 0-1.85-1.87 4.47 4.47 0 0 0-1.7-.4c-.8-.06-1.82-.06-3.23-.06h-4c-1.41 0-2.43 0-3.22.07ZM6.26 11a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm4.5 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm4.5 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineCommentDots);
export { ForwardRef as IconV2OutlineCommentDots };