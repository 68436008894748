import { EMPTY_INDICATOR, Tooltip } from '@remote-com/norma';
import PropTypes from 'prop-types';

import { formatTime, formatYearMonthDay } from '@/src/helpers/date';

/**
 * @returns {import('react').ReactElement}
 */
function Timestamp({ datetimeString }) {
  if (!datetimeString) {
    return EMPTY_INDICATOR;
  }

  const date = formatYearMonthDay(datetimeString);
  const time = `${formatTime(datetimeString)} UTC`;

  return <Tooltip label={time}>{date}</Tooltip>;
}

PropTypes.defaultProps = {
  datetimeString: null,
};

PropTypes.propTypes = {
  datetimeString: PropTypes.string,
};

export default Timestamp;
