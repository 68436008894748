import { Stack, Text } from '@remote-com/norma';
import { IconFilters } from '@remote-com/norma/icons/IconFilters';
import PropTypes from 'prop-types';

import { composeFieldText } from '@/src/components/Form/DynamicForm/composeFields';
import { DynamicFormModal } from '@/src/components/Modal/DynamicFormModal';
import FiltersList from '@/src/components/Table/Components/Toolbar/SaveFilters/FilterList';
import { getValidationSchema } from '@/src/helpers/forms';
import { baseString } from '@/src/helpers/validationSchema';

import { IconBox } from './SaveFilter.styled';

const FORM_NAME = 'saveFilterForm';

const filterNameField = composeFieldText({
  name: 'name',
  label: 'Filter Name',
  description: 'Give your filter configuration an easily identifiable name.',
  required: true,
  schema: baseString.required(),
});

const SaveFilterModal = ({ filterName, filters, onSave, onClose }) => {
  const form = {
    fields: [filterNameField],
    values: { name: filterName },
  };

  const validationSchema = getValidationSchema({ fields: form.fields });

  const handleSave = ({ name }) => {
    onSave(name, filters);
  };

  const renderFilterList = () => (
    <>
      <Stack mt={32} direction="row" alignItems="center" justifyContent="flex-start" gap={16}>
        <IconBox>
          <IconFilters />
        </IconBox>
        <Text variant="lgMedium" color="grey.900">
          Filter Configuration
        </Text>
      </Stack>

      <FiltersList filters={filters} />
    </>
  );

  return (
    <DynamicFormModal
      TODO_MIGRATE_handleOnSubmit="TODO"
      formName={FORM_NAME}
      modalProps={{ visible: true, headerTitle: 'Save Filter' }}
      fields={form.fields}
      fieldValues={form.values}
      onCancel={onClose}
      onSubmit={handleSave}
      confirmLoading={false}
      formProps={{
        validationSchema,
        children: renderFilterList,
      }}
    />
  );
};

SaveFilterModal.defaultProps = {
  filterName: '',
};

SaveFilterModal.propTypes = {
  filterName: PropTypes.string,
  filters: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default SaveFilterModal;
