import { IconV2OutlineBriefcase } from '@remote-com/norma/icons/IconV2OutlineBriefcase';

import { ButtonIcon } from '@/src/components/Button';
import { JOBS_ROUTE } from '@/src/constants/routes';

export const RemoteJobsButton = () => {
  return (
    <ButtonIcon
      asTag="a"
      type="button"
      tone="secondary"
      variant="ghost"
      size="sm"
      label="Explore remote.com/jobs"
      href={JOBS_ROUTE}
      Icon={IconV2OutlineBriefcase}
      target="_blank"
    />
  );
};
