import { handleNavigationTracking } from '@remote-com/analytics';
import type { DropdownMenu } from '@remote-com/norma';
import type { ComponentPropsWithoutRef } from 'react';

import { MenuItem } from '@/src/components/DropdownMenu';

type AccountMenuItemProps = { trackingKey: string } & ComponentPropsWithoutRef<
  typeof DropdownMenu.Item
>;

export const AccountMenuItem = ({ onSelect, trackingKey, ...rest }: AccountMenuItemProps) => {
  const handleSelect = () => {
    handleNavigationTracking(['account-widget', trackingKey]);
    onSelect?.();
  };

  return <MenuItem onClick={handleSelect} {...rest} />;
};
