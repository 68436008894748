import {
  trackEvent,
  sidebarEvents,
  pathnameToLocationOfAction,
  handleNavigationTracking,
} from '@remote-com/analytics';
import { DropdownMenu, Stack } from '@remote-com/norma';
import { IconV2OutlineConversation } from '@remote-com/norma/icons/IconV2OutlineConversation';
import { IconV2OutlineFilesText } from '@remote-com/norma/icons/IconV2OutlineFilesText';
import { IconV2OutlineHelpCircle } from '@remote-com/norma/icons/IconV2OutlineHelpCircle';
import { IconV2OutlineLightBulb } from '@remote-com/norma/icons/IconV2OutlineLightBulb';
import { IconV2OutlinePromotion } from '@remote-com/norma/icons/IconV2OutlinePromotion';
import { IconV2OutlineRocket } from '@remote-com/norma/icons/IconV2OutlineRocket';
import { IconV2OutlineSupport } from '@remote-com/norma/icons/IconV2OutlineSupport';
import { IconV2OutlineTimes } from '@remote-com/norma/icons/IconV2OutlineTimes';
import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
import { openModal } from 'react-url-modal';
import styled from 'styled-components';

import { DropdownMenuButtonIcon, MenuItem } from '@/src/components/DropdownMenu';
import { FeatureFlag, isFlagActive } from '@/src/components/FeatureFlag';
import { useUser } from '@/src/components/UserProvider/context';
import {
  ADMIN_EMPLOYMENT_REQUESTS_ROUTE,
  EMPLOYMENT_REQUESTS_ROUTE,
  HELP_CENTER_ROUTE,
  REMOTE_ACCESSIBILITY_STATEMENT,
  REMOTE_STATUS,
  REMOTE_PRIVACY_POLICY,
} from '@/src/constants/routes';
import {
  shouldShowRequestsToEmployee,
  shouldShowRequestsToEmployer,
} from '@/src/domains/employment/requests/helpers';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { isEmployer, isCandidate } from '@/src/domains/registration/auth/helpers';
import { useUserPrompts } from '@/src/domains/userPrompts';
import { openZendeskWidget, closeZendeskWidget } from '@/src/domains/zendesk/helpers';
import { isOnClient } from '@/src/helpers/general';

declare global {
  interface Window {
    zE: any;
  }
}

export const HELP_MENU_TRIGGER_ID = 'global-help-menu-trigger';

const {
  publicRuntimeConfig: { PRODUCT_FEEDBACK_FORM_URL },
} = getConfig();

const BeamerMenuItem = styled(MenuItem)`
  .beamer_icon.active {
    animation: unset;
    background-color: ${({ theme }) => theme.colors.brand[100]} !important;
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    color: ${({ theme }) => theme.colors.brand[700]};
    display: grid;
    height: 22px;
    padding: 0;
    place-items: center;
    right: 15px;
    top: 50%;
    translate: 0 -50%;
    width: 22px;

    ${({ theme }) => theme.typography['2xsSemiBold']}
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  max-height: unset !important;
`;

const StyledHelpMenuTrigger = styled(DropdownMenuButtonIcon)`
  --help-menu-trigger-space: ${({ theme }) => theme.space[5]}px;

  margin-bottom: var(--help-menu-trigger-space);
  margin-right: var(--help-menu-trigger-space);
`;

export const HelpMenu = () => {
  const router = useRouter();
  const { userIsAdmin, user } = useUser();
  const { setPastPromptsDrawerOpen } = useUserPrompts();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isZendeskChatOpen, setIsZendeskChatOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const enableChatbot = useIsFeatureFlagEnabled('knowledge_ai_chatbot');

  const showZendeskChatOption =
    !enableChatbot &&
    isFlagActive('ZENDESK_WEB_WIDGET_ENABLED') &&
    !userIsAdmin &&
    !isCandidate(user);

  const isUserEmployer = isEmployer(user);

  // If we do a reload, the component unmounts so states are reset, but the zendesk widgets stays open
  // for some reason. This closes it on unmount.
  useEffect(() => {
    const closeWidgetOnUnmount = () => {
      if (window.zE) {
        closeZendeskWidget();
      }
    };

    if (window.zE) {
      // With zendesk now using custom launcher, chat dialog can be close via built-in
      // close button in chat dialog's header
      window.zE('messenger:on', 'close', () => {
        setIsZendeskChatOpen(false);
        setIsDropdownOpen(false);
      });
    }
    return () => {
      closeWidgetOnUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnClient() ? window.zE : null]);

  useEffect(() => {
    const handleRouteChange = () => {
      // If the route changes, we want to keep behavior similar to the default zendesk chat, that is when the chat is open,
      // it stays open when navigating to a different page, but we don't want to keep the dropdown
      // open when changing routes.
      if (!isZendeskChatOpen) {
        setIsDropdownOpen(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, setIsDropdownOpen, isZendeskChatOpen]);

  const handleDropdownClick = (open: boolean) => {
    if (isZendeskChatOpen) {
      setIsZendeskChatOpen(false);
      closeZendeskWidget();
      // We keep the chat open when navigating routes, but when closing the zendesk chat after navigation we want
      // to be sure we don't open the dropdown again.
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(open);
      const props = {
        elementName: 'help',
        elementType: 'button',
        locationOfAction: pathnameToLocationOfAction(router.pathname),
        userType: user?.role,
      };
      trackEvent(sidebarEvents.OBJECT_CLICKED, props);
    }
  };
  const REQUESTS_ROUTE = userIsAdmin ? ADMIN_EMPLOYMENT_REQUESTS_ROUTE : EMPLOYMENT_REQUESTS_ROUTE;
  const locationOfAction = pathnameToLocationOfAction(router.pathname);
  const shouldShowRequests =
    user &&
    (userIsAdmin || shouldShowRequestsToEmployee(user) || shouldShowRequestsToEmployer(user));

  const openNavatticModal = () => {
    openModal({ name: 'product-tour-modal' });
  };

  const handleTourLibrary = () => {
    setIsDropdownOpen(false);
    setPastPromptsDrawerOpen(true);
  };

  const handleZendeskChatState = () => {
    openZendeskWidget();
    setIsZendeskChatOpen(true);
  };

  return (
    <Stack ref={dropdownRef}>
      <StyledDropdownMenu
        data-testid="page-header-help-menu"
        onOpenChange={handleDropdownClick}
        open={isDropdownOpen && !isZendeskChatOpen}
        trigger={
          enableChatbot ? (
            <DropdownMenuButtonIcon
              data-testid="page-header-help-menu-toggle"
              Icon={
                isDropdownOpen || isZendeskChatOpen ? IconV2OutlineTimes : IconV2OutlineHelpCircle
              }
              label="Toggle help menu"
              size="sm"
              tone="secondary"
              id={HELP_MENU_TRIGGER_ID}
              variant="ghost"
            />
          ) : (
            <StyledHelpMenuTrigger
              data-testid="page-header-help-menu-toggle"
              Icon={
                isDropdownOpen || isZendeskChatOpen ? IconV2OutlineTimes : IconV2OutlineHelpCircle
              }
              label="Toggle help menu"
              size="sm"
              tone="primary"
              id={HELP_MENU_TRIGGER_ID}
              variant="solid"
            />
          )
        }
      >
        <MenuItem
          href={HELP_CENTER_ROUTE}
          target="_blank"
          rel="noopener noreferrer"
          Icon={IconV2OutlineSupport}
          onClick={() => handleNavigationTracking([locationOfAction, 'help-center'])}
        >
          Help center
        </MenuItem>

        {showZendeskChatOption ? (
          <MenuItem
            onClick={handleZendeskChatState}
            Icon={IconV2OutlineConversation}
            onSelect={() => handleNavigationTracking([locationOfAction, 'support-chat'])}
          >
            Support chat
          </MenuItem>
        ) : null}
        {shouldShowRequests && (
          <Link href={REQUESTS_ROUTE} legacyBehavior>
            <MenuItem
              Icon={IconV2OutlineFilesText}
              onSelect={() => handleNavigationTracking([locationOfAction, 'requests'])}
            >
              Requests
            </MenuItem>
          </Link>
        )}
        <FeatureFlag name="BEAMER_WEB_WIDGET_ENABLED">
          <BeamerMenuItem
            Icon={IconV2OutlinePromotion}
            className="beamerWidget"
            onSelect={() => handleNavigationTracking([locationOfAction, 'whats-new'])}
          >
            What’s new?
          </BeamerMenuItem>
        </FeatureFlag>
        {isUserEmployer && (
          <MenuItem
            onClick={openNavatticModal}
            Icon={IconV2OutlineRocket}
            onSelect={() => handleNavigationTracking([locationOfAction, 'product-tour'])}
          >
            Product tour
          </MenuItem>
        )}
        {isUserEmployer && (
          <MenuItem
            onClick={handleTourLibrary}
            Icon={IconV2OutlinePromotion}
            onSelect={() => handleNavigationTracking([locationOfAction, 'card-library'])}
          >
            Tour library
          </MenuItem>
        )}
        <MenuItem
          href={PRODUCT_FEEDBACK_FORM_URL}
          Icon={IconV2OutlineLightBulb}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleNavigationTracking([locationOfAction, 'product-feedback'])}
        >
          Give product feedback
        </MenuItem>
        <MenuItem
          href={REMOTE_PRIVACY_POLICY}
          hasDivider
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleNavigationTracking([locationOfAction, 'privacy-policy'])}
        >
          Privacy policy
        </MenuItem>
        <MenuItem
          href={REMOTE_ACCESSIBILITY_STATEMENT}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleNavigationTracking([locationOfAction, 'remote-a11y-statement'])}
        >
          Accessibility statement
        </MenuItem>
        <MenuItem
          href={REMOTE_STATUS}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleNavigationTracking([locationOfAction, 'remote-status'])}
        >
          Remote status
        </MenuItem>
      </StyledDropdownMenu>
    </Stack>
  );
};
