import type { MenuProps } from 'react-select';
import styled from 'styled-components';

import { Button } from '../../../core/button';
import { Text } from '../../../core/text';

import { Menu } from './Menu';

const SelectAllOptionWrapper = styled(Button).attrs({ variant: 'raw', type: 'button' })`
  height: var(--field-select-menu-item-height);
  width: 100%;
  padding: ${({ theme }) => `${theme.space[2]}px ${theme.space[5]}px`};

  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[300]};
  background: ${({ theme }) => theme.colors.blank};

  &:hover {
    background: ${({ theme }) => theme.colors.grey[100]};
  }
`;

export const SelectAllMenu = ({ children, ...props }: MenuProps) => {
  const { options, setValue, getValue } = props;
  const areAllSelected = getValue().length === options.length;

  const handleSelectAll = () => {
    if (areAllSelected) {
      setValue([], 'deselect-option');
    } else {
      setValue(options, 'select-option');
    }
  };

  return (
    <Menu {...props}>
      {children}

      <SelectAllOptionWrapper onClick={handleSelectAll}>
        <Text variant="smMedium">{areAllSelected ? 'Deselect all' : 'Select all'}</Text>
      </SelectAllOptionWrapper>
    </Menu>
  );
};
