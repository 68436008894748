import { employeeCreateContractorInvoiceEvents, trackEvent } from '@remote-com/analytics';
import type { BadgeProps, BadgeType, PillTone } from '@remote-com/norma';
import type { ValueOf } from 'type-fest';

import type { RecurringInvoiceStatus } from '@/src/api/config/employ/recurringContractorInvoice.types';
import type { UserAccountResponse } from '@/src/components/UserProvider';
import type { ScheduledInvoiceStatus } from '@/src/domains/contractorInvoices/status';
import type { ContractorInvoiceItemType } from '@/src/domains/contractorInvoices/types';
import type { GenerationSource } from '@/src/domains/invoices/constants';
import { invoiceGenerationSource } from '@/src/domains/invoices/constants';
import { isProductContractorProtect } from '@/src/domains/pricing/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { userCan } from '@/src/domains/registration/auth/helpers';
import type { WithdrawalMethod } from '@/src/domains/withdrawalMethods/types';
import { generateSelectOptions } from '@/src/helpers/forms';
import type { ScreamingSnakeCaseMapping } from '@/src/types/case';

export type InvoiceGenerationTypes = 'time_tracking' | 'manual';

export const invoiceGenerationTypes: ScreamingSnakeCaseMapping<InvoiceGenerationTypes> = {
  TIME_TRACKING: 'time_tracking',
  MANUAL: 'manual',
} as const;

export const invoiceGenerationTypeOptions = [
  {
    id: invoiceGenerationTypes.MANUAL,
    value: invoiceGenerationTypes.MANUAL,
    label: 'Enter manually',
    description: 'Add item and amount myself',
    onClick: () => {
      trackEvent(employeeCreateContractorInvoiceEvents.TIME_TRACKING_BILLABLE_ITEMS, {
        elementData: invoiceGenerationTypes.MANUAL,
      });
    },
  },
  {
    id: invoiceGenerationTypes.TIME_TRACKING,
    value: invoiceGenerationTypes.TIME_TRACKING,
    label: 'Use time tracking',
    description: 'Add my hours worked to invoice',
    onClick: () => {
      trackEvent(employeeCreateContractorInvoiceEvents.TIME_TRACKING_BILLABLE_ITEMS, {
        elementData: invoiceGenerationTypes.TIME_TRACKING,
      });
    },
  },
];

export const invoicesTabs = {
  INVOICES: {
    value: 'invoices',
    label: 'Invoices',
    isTabVisible: () => true,
  },
  RECURRING_INVOICES: {
    value: 'recurring-invoices',
    label: 'Recurring invoices',
    isTabVisible: (user: UserAccountResponse) =>
      userCan('create', Resources.recurring_invoices, user),
  },
  SCHEDULED_INVOICES: {
    value: 'scheduled-invoices',
    label: 'Scheduled invoices',
    isTabVisible: (user: UserAccountResponse) =>
      userCan('read', Resources.scheduled_invoices, user),
  },
};

export const contractorInvoiceTabs = {
  INVOICES: {
    value: 'invoices',
    label: 'Invoices',
    isTabVisible: () => true,
  },
  RECURRING_INVOICES: {
    value: 'your-schedules',
    label: 'Your schedules',
    isTabVisible: (user: UserAccountResponse) =>
      userCan('create', Resources.recurring_invoices, user) &&
      !isProductContractorProtect(user.activeEmployment?.product),
  },
  SCHEDULED_INVOICES: {
    value: 'company-schedules',
    label: 'Company schedules',
    isTabVisible: (user: UserAccountResponse) =>
      userCan('read', Resources.scheduled_invoices, user) &&
      !isProductContractorProtect(user.activeEmployment?.product),
  },
};

// TODO: Delete after 2024-10-31 :)
export const oldTabsValueMapping = {
  [invoicesTabs.RECURRING_INVOICES.value]: contractorInvoiceTabs.RECURRING_INVOICES.value,
  [invoicesTabs.SCHEDULED_INVOICES.value]: contractorInvoiceTabs.SCHEDULED_INVOICES.value,
};

export const tabQueryParameter = 'tab';

export const recurringInvoiceStatuses: ScreamingSnakeCaseMapping<RecurringInvoiceStatus> = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  COMPLETED: 'completed',
  DELETED: 'deleted',
  PENDING_CONTRACTOR_ACTION: 'pending_contractor_action',
};

export const RECURRING_INVOICE_STATUSES_DISALLOWING_WITHDRAWAL_DELETION =
  new Set<RecurringInvoiceStatus>([recurringInvoiceStatuses.ACTIVE]);

export const recurringInvoiceStatusLabels: Record<RecurringInvoiceStatus, string> = {
  [recurringInvoiceStatuses.ACTIVE]: 'Active',
  [recurringInvoiceStatuses.INACTIVE]: 'Inactive',
  [recurringInvoiceStatuses.COMPLETED]: 'Completed',
  [recurringInvoiceStatuses.DELETED]: 'Deleted',
  [recurringInvoiceStatuses.PENDING_CONTRACTOR_ACTION]: 'Pending contractor action',
};

export const recurringInvoiceBadgeTypes: Record<RecurringInvoiceStatus, BadgeType> = {
  [recurringInvoiceStatuses.ACTIVE]: 'active',
  [recurringInvoiceStatuses.INACTIVE]: 'inactive',
  [recurringInvoiceStatuses.COMPLETED]: 'archived',
  [recurringInvoiceStatuses.DELETED]: 'inactive',
  [recurringInvoiceStatuses.PENDING_CONTRACTOR_ACTION]: 'pending',
};

export const editableRecurringInvoiceStatuses: Set<
  Extract<RecurringInvoiceStatus, 'active' | 'inactive' | 'pending_contractor_action'>
> = new Set([
  recurringInvoiceStatuses.ACTIVE,
  recurringInvoiceStatuses.INACTIVE,
  recurringInvoiceStatuses.PENDING_CONTRACTOR_ACTION,
]);

export const noMoreRecurrenceStatuses = new Set([
  recurringInvoiceStatuses.INACTIVE,
  recurringInvoiceStatuses.COMPLETED,
]);

export const invoiceItemTypes: ScreamingSnakeCaseMapping<ContractorInvoiceItemType> = {
  MANUAL: 'manual',
  TIME_TRACKING: 'time_tracking',
  EXPENSE: 'expense',
};

export const invoicePeriodicityTypes = {
  ONE_TIME: 'one_time',
  WEEKLY: 'weekly',
  BI_WEEKLY: 'bi_weekly',
  SEMI_MONTHLY: 'semi_monthly',
  MONTHLY: 'monthly',
} as const;

export type InvoicePeriodicity = ValueOf<typeof invoicePeriodicityTypes>;

// Ordering of the properties here matches the order in which
// these labels will appear in the invoice form's "Frequency"
// dropdown. They are currently sorted by decreasing frequency.
const recurringInvoicePeriodicityLabels = {
  [invoicePeriodicityTypes.WEEKLY]: 'Weekly',
  [invoicePeriodicityTypes.BI_WEEKLY]: 'Bi-weekly',
  [invoicePeriodicityTypes.SEMI_MONTHLY]: 'Semi-monthly',
  [invoicePeriodicityTypes.MONTHLY]: 'Monthly',
};

export const invoicePeriodicityLabels = {
  [invoicePeriodicityTypes.ONE_TIME]: 'One time',
  ...recurringInvoicePeriodicityLabels,
};

export const recurringInvoicePeriodicityOccurrenceTypes = {
  NEVER: 'never',
  MULTIPLE: 'multiple',
};

export type RecurringInvoicePeriodicityOccurrenceType = ValueOf<
  typeof recurringInvoicePeriodicityOccurrenceTypes
>;

// Periodicity options for company-facing invoice schedule form:
export const scheduledInvoicePeriodicityOptions = generateSelectOptions(invoicePeriodicityLabels);

// Periodicity options for contractor-facing invoice forms:
export const invoicePeriodicityOptions = generateSelectOptions(invoicePeriodicityLabels);
export const recurringInvoicePeriodicityOptions = generateSelectOptions(
  recurringInvoicePeriodicityLabels
);

export const scheduledInvoiceStatuses = {
  ACTIVE: 'active',
  BLOCKED_BY_ONBOARDING: 'blocked_by_onboarding',
  COMPLETED: 'completed',
  DELETED: 'deleted',
  GENERATION_FAILED: 'generation_failed',
  INACTIVE: 'inactive',
  PROCESSING: 'processing',
  // These two are new statuses that are going to be replacement for blocked_by_onboarding and generation_failed
  PENDING_CONTRACTOR_ACTION: 'pending_contractor_action',
  PENDING_COMPANY_ACTION: 'pending_company_action',
  GENERATION_FAILED_UNRELATED_TO_WITHDRAWAL_METHOD:
    'generation_failed_unrelated_to_withdrawal_method',
};

export const SCHEDULED_INVOICE_STATUSES_DISALLOWING_WITHDRAWAL_DELETION =
  new Set<ScheduledInvoiceStatus>([
    scheduledInvoiceStatuses.ACTIVE,
    scheduledInvoiceStatuses.GENERATION_FAILED,
    scheduledInvoiceStatuses.PROCESSING,
  ]);

export const scheduledInvoiceGenerationErrorCodes = {
  NO_WITHDRAWAL_METHODS_AVAILABLE: 'no_withdrawal_methods_available',
  WITHDRAWAL_METHOD_SELECTION_REQUIRED: 'withdrawal_method_selection_required',
  VALIDATION_ERROR: 'validation_error',
  INVOICE_AMOUNT_LIMIT_EXCEEDED: 'invoice_amount_limit_exceeded',
  QUOTE_CREATION_FAILED: 'quote_creation_failed',
  INVALID_STATUS: 'invalid_status',
  NO_CONTRACTOR_EMPLOYMENT: 'no_contractor_employment',
  INTERNAL_ERROR: 'internal_error',
};

export type ScheduledInvoiceGenerationError = ValueOf<typeof scheduledInvoiceGenerationErrorCodes>;

type ScheduledInvoiceBadgePropsGenerationError = Record<
  ScheduledInvoiceGenerationError,
  BadgeProps
>;

export const scheduledInvoiceGenerationErrorBadges: ScheduledInvoiceBadgePropsGenerationError = {
  [scheduledInvoiceGenerationErrorCodes.NO_CONTRACTOR_EMPLOYMENT]: {
    label: 'No active employment',
    type: 'pending',
  },
  [scheduledInvoiceGenerationErrorCodes.NO_WITHDRAWAL_METHODS_AVAILABLE]: {
    label: 'No withdrawal methods available',
    type: 'pending',
  },
  [scheduledInvoiceGenerationErrorCodes.WITHDRAWAL_METHOD_SELECTION_REQUIRED]: {
    label: 'Withdrawal method selection required',
    type: 'pending',
  },
  [scheduledInvoiceGenerationErrorCodes.INVALID_STATUS]: {
    label: 'Invalid status',
    type: 'error',
  },
  [scheduledInvoiceGenerationErrorCodes.INTERNAL_ERROR]: {
    label: 'Internal error',
    type: 'error',
  },
  [scheduledInvoiceGenerationErrorCodes.INVOICE_AMOUNT_LIMIT_EXCEEDED]: {
    label: 'Invoice amount limit exceeded',
    type: 'error',
  },
  [scheduledInvoiceGenerationErrorCodes.QUOTE_CREATION_FAILED]: {
    label: 'Quote creation failed',
    type: 'error',
  },
  [scheduledInvoiceGenerationErrorCodes.VALIDATION_ERROR]: {
    label: 'Validation error',
    type: 'error',
  },
};

export const userFacingScheduledInvoiceGenerationErrorBadges: ScheduledInvoiceBadgePropsGenerationError =
  {
    [scheduledInvoiceGenerationErrorCodes.NO_WITHDRAWAL_METHODS_AVAILABLE]: {
      label: 'No withdrawal methods available',
      type: 'pending',
    },
    [scheduledInvoiceGenerationErrorCodes.WITHDRAWAL_METHOD_SELECTION_REQUIRED]: {
      label: 'Withdrawal method selection required',
      type: 'pending',
    },
    [scheduledInvoiceGenerationErrorCodes.INVOICE_AMOUNT_LIMIT_EXCEEDED]: {
      label: 'Invoice amount limit exceeded',
      type: 'error',
    },
    [scheduledInvoiceGenerationErrorCodes.QUOTE_CREATION_FAILED]: {
      label: 'Quote creation failed',
      type: 'error',
    },
    [scheduledInvoiceGenerationErrorCodes.VALIDATION_ERROR]: {
      label: 'Validation error',
      type: 'error',
    },
  };

export const editableScheduledInvoiceStatuses = new Set([
  scheduledInvoiceStatuses.ACTIVE,
  scheduledInvoiceStatuses.BLOCKED_BY_ONBOARDING,
  scheduledInvoiceStatuses.GENERATION_FAILED,
  scheduledInvoiceStatuses.INACTIVE,
  scheduledInvoiceStatuses.PENDING_CONTRACTOR_ACTION,
  scheduledInvoiceStatuses.GENERATION_FAILED_UNRELATED_TO_WITHDRAWAL_METHOD,
]);

export const excludedFilterRecurringInvoiceStatuses = new Set<RecurringInvoiceStatus>([
  recurringInvoiceStatuses.DELETED,
]);

export const excludedFilterScheduledInvoiceStatuses = new Set([
  scheduledInvoiceStatuses.DELETED,
  scheduledInvoiceStatuses.BLOCKED_BY_ONBOARDING,
  scheduledInvoiceStatuses.GENERATION_FAILED,
]);

export const adminExcludedFilterScheduledInvoiceStatuses = new Set([
  scheduledInvoiceStatuses.BLOCKED_BY_ONBOARDING,
  scheduledInvoiceStatuses.GENERATION_FAILED,
]);

export const deletableScheduledInvoiceStatuses = new Set([
  ...editableScheduledInvoiceStatuses,
  scheduledInvoiceStatuses.COMPLETED,
]);

export const invoiceItemDefaultValue = {
  description: '',
  amount: '',
};

export const SMALL_INVOICE_NUMBER_MAX_LENGTH = 10;
export const LARGE_INVOICE_NUMBER_MAX_LENGTH = 25;

export const LARGE_INVOICE_NUMBER_SUPPORTED_CURRENCIES = new Set(['RUB', 'PKR']);

export const withdrawalMethodAllowsLargeInvoiceNumber = (withdrawalMethod: WithdrawalMethod) =>
  LARGE_INVOICE_NUMBER_SUPPORTED_CURRENCIES.has(withdrawalMethod?.currency?.code);

export const INVOICE_PAYMENT_TERMS = {
  WITHIN_7_DAYS: 'within_7_days',
  WITHIN_14_DAYS: 'within_14_days',
  WITHIN_15_DAYS: 'within_15_days',
  WITHIN_30_DAYS: 'within_30_days',
  END_OF_THE_MONTH: 'end_of_the_month',
  UPON_RECEIPT: 'upon_receipt',
} as const;

const INVOICE_PAYMENT_TERMS_LABELS = {
  [INVOICE_PAYMENT_TERMS.WITHIN_7_DAYS]: 'Within 7 days of receipt',
  [INVOICE_PAYMENT_TERMS.WITHIN_14_DAYS]: 'Within 14 days of receipt',
  [INVOICE_PAYMENT_TERMS.WITHIN_15_DAYS]: 'Within 15 days of receipt',
  [INVOICE_PAYMENT_TERMS.WITHIN_30_DAYS]: 'Within 30 days of receipt',
  [INVOICE_PAYMENT_TERMS.END_OF_THE_MONTH]: 'End of the month',
  [INVOICE_PAYMENT_TERMS.UPON_RECEIPT]: 'Upon receipt',
};

export const INVOICE_PAYMENT_TERMS_SUMMARY = {
  [INVOICE_PAYMENT_TERMS.WITHIN_7_DAYS]: 'within 7 days of receipt',
  [INVOICE_PAYMENT_TERMS.WITHIN_14_DAYS]: 'within 14 days of receipt',
  [INVOICE_PAYMENT_TERMS.WITHIN_15_DAYS]: 'within 15 days of receipt',
  [INVOICE_PAYMENT_TERMS.WITHIN_30_DAYS]: 'within 30 days of receipt',
  [INVOICE_PAYMENT_TERMS.END_OF_THE_MONTH]: 'at the end of the month',
  [INVOICE_PAYMENT_TERMS.UPON_RECEIPT]: 'upon receipt',
};

export const paymentTermsOptions = generateSelectOptions(INVOICE_PAYMENT_TERMS_LABELS);
export const cmProtectPaymentTermsOptions = generateSelectOptions({
  [INVOICE_PAYMENT_TERMS.WITHIN_30_DAYS]: 'Within 30 days of receipt',
});

// Timeout in milliseconds when the user cannot close the immersive modal
// by clicking on its backdrop after closing the `FilePreviewer` - added
// to prevent users from accidentally double-clicking the previewer's back
// button and closing the immersive as well.
export const CANT_HIDE_MODAL_AFTER_PREVIEW_CLOSE_TIMEOUT = 1000;

export const invoiceGenerationSourceLabels: Record<GenerationSource, string> = {
  [invoiceGenerationSource.COMPANY]: 'Company',
  [invoiceGenerationSource.CONTRACTOR]: 'You',
};

export const invoiceGenerationSourceOptions = generateSelectOptions(invoiceGenerationSourceLabels);

export const SWIFT_FEE_ALLOCATION_TYPE = {
  // handling old contractor invoice quotes without the new payOutMethod & guaranteedPayoutsSwiftFeePayer
  // when contractor liable for fees
  SWIFT_LEGACY_CONTRACTOR_PAYS: 'swiftLegacyContractorPays',
  // when company liable for fees
  SWIFT_LEGACY_COMPANY_PAYS: 'swiftLegacyCompanyPays',

  // default to non-USD SWIFT withdrawal methods
  SWIFT_SHA_CONTRACTOR_PAYS: 'swiftShaContractorPays',
  // for USD SWIFT withdrawal methods when payment setting is 'company'
  SWIFT_OUR_COMPANY_PAYS: 'swiftOurCompanyPays',
  // for USD SWIFT withdrawal methods when payment setting is 'contractor'
  SWIFT_OUR_CONTRACTOR_PAYS: 'swiftOurContractorPays',
} as const;

export type SwiftFeeAllocationType = ValueOf<typeof SWIFT_FEE_ALLOCATION_TYPE>;

export const PAY_OUT_METHOD = {
  LOCAL: 'local',
  SWIFT: 'swift',
  SWIFT_OUR: 'swift_our',
} as const;

export type PayOutMethod = ValueOf<typeof PAY_OUT_METHOD>;

export const GUARANTEED_PAYOUTS_SWIFT_FEE_PAYER = {
  CONTRACTOR: 'contractor',
  COMPANY: 'company',
} as const;

export type GuaranteedPayoutsSwiftFeePayer = ValueOf<typeof GUARANTEED_PAYOUTS_SWIFT_FEE_PAYER>;

export const SWIFT_PAYER_LABELS = {
  CONTRACTOR_PAYS: 'Contractor pays',
  COMPANY_PAYS: 'Company pays',
} as const;

export type SwiftPayerLabel = ValueOf<typeof SWIFT_PAYER_LABELS>;

export const GENERATED_BY_TIME_TRACKING = {
  true: 'Time tracking',
  false: 'Manual',
} as const;

export const GENERATED_BY_TIME_TRACKING_LABELS = {
  true: 'Billable items type: Time tracking',
  false: 'Billable items type: Manual',
} as const;

export const generatedByTimeTrackingOptions = generateSelectOptions(GENERATED_BY_TIME_TRACKING);

export const fundsReturnedContractorsFaultReasons = [
  {
    label: 'Invalid account details',
    value: 'invalid_account_details',
  },
  {
    label: 'Incorrect account type',
    value: 'incorrect_account_type',
  },
  {
    label: 'Incorrect bank account currency',
    value: 'incorrect_bank_account_currency',
  },
  {
    label: 'Mismatched Billing address to Bank location',
    value: 'mismatched_billing_address_to_bank_location',
  },
  {
    label: 'Account closed',
    value: 'account_closed',
  },
  {
    label: 'Account blocked',
    value: 'account_blocked',
  },
  {
    label: 'Max deposit limit breached',
    value: 'max_deposit_limit_breached',
  },
];

export const fundsReturnedProviderFaultReasons = [
  {
    label: 'Payment provider downtime',
    value: 'payment_provider_downtime',
  },
];

export const fundsReturnedReasons = [
  ...fundsReturnedProviderFaultReasons,
  ...fundsReturnedContractorsFaultReasons,
];

export const CONTRACTOR_INVOICE_ITEM_PILL_LABELS: Record<ContractorInvoiceItemType, string> = {
  manual: 'Billable item',
  time_tracking: 'Billable item',
  expense: 'Expense',
};

export const CONTRACTOR_INVOICE_ITEM_PILL_TONES: Record<ContractorInvoiceItemType, PillTone> = {
  manual: 'blue',
  time_tracking: 'blue',
  expense: 'purple',
};
