import type { useMachine } from '@xstate/react';
import noopRaw from 'lodash/noop';
import type { MutableRefObject } from 'react';
import type { AnyEventObject } from 'xstate';
import { createMachine, assign, sendParent, send, spawn, actions, forwardTo } from 'xstate';

import type { InputFileCallbacksRef, InputFileObject } from './helpers';

/**
 * xstate's typing requires the callbacks to be async functions.
 */
const noop = noopRaw as () => Promise<void>;

const isDev = () => console && process.env.NODE_ENV === 'development';

const findActorByFileObject = (
  context: { actors: Record<string, ReturnType<typeof spawn>> },
  { fileObject }: AnyEventObject
) => {
  return context.actors[fileObject.name];
};

// file.test.png → file.test 2.png
const renameFileObjectDuplicate = (fileObject: InputFileObject): InputFileObject => {
  const { name, file } = fileObject;

  const lastDotIndex = name.lastIndexOf('.');
  const newName = `${name.slice(0, lastDotIndex)} 2${name.slice(lastDotIndex)}`;

  const newFile = new File([file], newName, {
    type: file.type,
  });

  return {
    ...fileObject,
    name: newName,
    file: newFile,
  };
};

type Machine = Parameters<typeof useMachine>[0];

export const createInputFileStateMachine = (props: {
  callbackRefs: MutableRefObject<InputFileCallbacksRef>;
  singleFile: boolean;
}): Machine => {
  const { callbackRefs, singleFile } = props;

  /**
   * Falling back to "noop" is likely wrong here.
   * Most of the implementation here expects that "uploadFile" returns a slug.
   * The slug indicates that the file is on the server.
   * Returning "void" would simply break the uploading.
   * This is kept during Norma migration.
   *
   * @todo
   */
  const uploadFile = callbackRefs.current.uploadFile ?? noop;
  /**
   * Not sure why but defaulting to "noop" using "=" syntax loses the typing.
   */
  const deleteFile = callbackRefs.current.deleteFile ?? noop;
  const onChange = callbackRefs.current.onChange ?? noop;

  const fileMachine = createMachine<{
    fileObject: InputFileObject | null;
  }>(
    {
      predictableActionArguments: true,
      id: 'file-actor-machine',
      initial: 'idle',
      context: {
        fileObject: null,
      },
      states: {
        idle: {
          entry: 'updateFileObject',
          on: {
            INIT: {
              actions: [
                assign({
                  fileObject: (context, { fileObject }) => fileObject || context.fileObject,
                }),
                // Invalid files will not go through the `uploading` state but should still trigger onChange events
                actions.choose([
                  {
                    cond: 'fileIsNotValid',
                    actions: 'notifyParentFilesChanged',
                  },
                ]),
              ],
            },
            DELETE_FILE: {
              target: 'should_delete_from_server',
            },
            CHANGE_DOCUMENT_TYPE: {
              actions: [
                assign({
                  fileObject: (_, event) => {
                    return {
                      ...event.fileObject,
                      documentType: event.fileObject.documentType,
                    };
                  },
                }),
                'notifyParentFilesChanged',
              ],
            },
            RENAME_FILE: {
              actions: [
                assign({
                  fileObject: (_, event) => {
                    return {
                      ...event.fileObject,
                      newName: event.fileObject.newName,
                    };
                  },
                }),
                'notifyParentFilesChanged',
              ],
            },
          },
          always: {
            cond: 'isValidAndNotUploaded',
            target: 'uploading',
          },
        },
        uploading: {
          invoke: {
            id: 'uploadFile',
            // The "as" is type-wise wrong.
            // We only guarantee the file is not null manually,
            // which will break easily in the future.
            src: (context) => uploadFile(context.fileObject as InputFileObject),
            onDone: {
              target: 'idle',
              actions: [
                assign({
                  fileObject: (context, event) => ({
                    ...context.fileObject,
                    ...event.data,
                    isUploading: false,
                  }),
                }),
                'notifyParentFilesChanged',
              ],
            },
            onError: {
              target: 'idle',
              actions: assign({
                fileObject: (context, event) => {
                  // eslint-disable-next-line no-console
                  if (isDev()) console.warn('FileUploader error', event);
                  return {
                    // This is most likely wrong.
                    // The file object could be null,
                    // or worse, an empty object.
                    // We keep the logic during Norma migration.
                    ...(context.fileObject as InputFileObject),
                    isUploading: false,
                    errors: [{ code: 'uploading-error', event }],
                  };
                },
              }),
            },
          },
        },
        should_delete_from_server: {
          always: [
            {
              cond: 'fileIsUploaded',
              target: 'deleting',
            },
            {
              cond: 'fileIsNotUploaded',
              target: 'deleted',
            },
          ],
        },
        deleting: {
          invoke: {
            id: 'deleteFile',
            // The "as" here is type-wise wrong.
            // We only guarantee the file is not null manually.
            // However, we keep the logic during Norma migration.
            src: (context) => deleteFile(context.fileObject as InputFileObject),
            onDone: {
              target: 'deleted',
              actions: [
                assign({
                  fileObject: (context) => context.fileObject,
                }),
              ],
            },
            onError: {
              target: 'idle',
              actions: assign({
                fileObject: (context, event) => {
                  // eslint-disable-next-line no-console
                  if (isDev()) console.warn('FileUploader error', event);
                  return {
                    // The "as" here is likely wrong.
                    // See the previous comments.
                    ...(context.fileObject as InputFileObject),
                    errors: [{ code: 'deleting-error', event }],
                  };
                },
              }),
            },
          },
        },
        deleted: {
          type: 'final',
          entry: [
            sendParent(({ fileObject }) => ({
              type: 'FILE_DELETED',
              fileObject,
            })),
            'notifyParentFilesChanged',
          ],
        },
      },
    },
    {
      actions: {
        updateFileObject: assign({
          fileObject: (context, { fileObject }) => fileObject || context.fileObject,
        }),
        notifyParentFilesChanged: sendParent('FILES_CHANGED'),
      },
      guards: {
        isValidAndNotUploaded: ({ fileObject }) => {
          if (!fileObject) {
            return false;
          }

          if (fileObject.errors?.length) {
            return false;
          }

          if (fileObject.slug) {
            return false;
          }
          return true;
        },
        fileIsUploaded: ({ fileObject }) => {
          if (!fileObject) {
            return false;
          }

          return Boolean(fileObject.slug);
        },
        fileIsNotUploaded: ({ fileObject }) => {
          if (!fileObject) {
            return true;
          }

          return !fileObject.slug;
        },
        fileIsNotValid: ({ fileObject }) => Boolean(fileObject?.errors?.length),
      },
    }
  );

  const fileListStateMachine = createMachine<{
    actors: Record<string, ReturnType<typeof spawn>>;
  }>(
    {
      predictableActionArguments: true,
      id: 'file-list-machine',
      initial: 'idle',
      context: {
        actors: {},
      },
      states: {
        idle: {
          on: {
            SHOW_FILE: {
              actions: actions.choose([
                {
                  cond: 'actorExists',
                  actions: send('UPDATE_FILE', {
                    to: findActorByFileObject,
                  }),
                },
                {
                  cond: 'actorDoesNotExist',
                  actions: send((context, event) => ({
                    ...event,
                    type: 'CREATE_ACTOR',
                  })),
                },
              ]),
            },
            ADD_FILE: {
              actions: actions.choose([
                {
                  cond: 'actorExists',
                  actions: send((context, event) => ({
                    ...event,
                    fileObject: renameFileObjectDuplicate(event.fileObject),
                  })),
                },
                {
                  cond: 'actorDoesNotExist',
                  actions: send((context, event) => ({
                    ...event,
                    type: 'CREATE_ACTOR',
                  })),
                },
              ]),
            },
            CREATE_ACTOR: {
              actions: assign({
                actors: (context, { fileObject }) => {
                  const { name } = fileObject;
                  const actor = spawn(fileMachine, {
                    name,
                    sync: true,
                  });
                  // Not sure why "fileObject" is passed here.
                  // "send" only accepts one argument.
                  // We try to keep the logic during Norma migration.
                  // @ts-expect-error
                  actor.send('INIT', { fileObject });
                  return {
                    ...context.actors,
                    [name]: actor,
                  };
                },
              }),
            },
            CHANGE_DOCUMENT_TYPE: {
              actions: [forwardTo(findActorByFileObject)],
            },
            RENAME_FILE: {
              actions: [forwardTo(findActorByFileObject)],
            },
            DELETE_FILE: {
              actions: [forwardTo(findActorByFileObject)],
            },
            FILE_DELETED: {
              actions: assign({
                actors: (context, event) => {
                  const { name } = event.fileObject;
                  const actor = context.actors[name];
                  const newActors = { ...context.actors };
                  delete newActors[name];
                  // This was previously "stop()".
                  // While migrating to TypeScript, we added "?" to follow the "stop" typing.
                  // It's likely fine, but if there's regression revert this.
                  actor.stop?.();
                  return newActors;
                },
              }),
            },
            FILES_CHANGED: {
              actions: (context) => {
                const allFiles = Object.values(context.actors)
                  .map((fileActor) => {
                    // Not sure why but fileActor does not have "state".
                    // It's likely that the typing is wrong.
                    // We keep the logic during Norma migration.
                    const { fileObject } = (fileActor as any).state.context;
                    return fileObject;
                  })
                  // Only include files that are uploaded after that is done
                  .filter((fileObject) => !fileObject.isUploading);
                if (singleFile) {
                  onChange(allFiles[allFiles.length - 1]);
                } else {
                  onChange(allFiles);
                }
              },
            },
          },
        },
      },
    },
    {
      guards: {
        actorExists: (context, event) => Boolean(findActorByFileObject(context, event)),
        actorDoesNotExist: (context, event) => !findActorByFileObject(context, event),
      },
    }
  );

  return fileListStateMachine;
};
