import type { GetResponse } from '@remote-com/data-layer';
import { isAxiosError } from 'axios';

import { COMPANY_PROFILE_STATUS } from '@/src/domains/talent/employer/company/constants';

export const companyProfileIsMissingRequiredDetails = (
  data?: GetResponse<'/boba/api/v1/employer/company-profiles'>['data']
) => {
  return data?.status === COMPANY_PROFILE_STATUS.CREATED;
};

export function companyProfileIsSoftDeleted(
  data?: GetResponse<'/boba/api/v1/employer/company-profiles'>['data']
) {
  return data?.status === COMPANY_PROFILE_STATUS.DELETED;
}

export const companyProfileIsNotCreated = (error: unknown) =>
  isAxiosError(error) && error.response?.status === 404;
