export const cleanString = (string) => (string || '').toLowerCase().trim();

export const createNote = (notes, type) => {
  const explanation = `This ${type} was added in our bulk uploader`;
  const noReceipt = 'and for that reason does not have a receipt.';
  let processedNote = explanation;
  if (type === 'expense') {
    processedNote += ` ${noReceipt}`;
  }

  if (notes) {
    processedNote = `${notes}.\n ${processedNote}`;
  }

  if (processedNote.length > 250) {
    processedNote = `${processedNote.slice(0, 247)}...`;
  }

  // the backend has a max of 250 characters for notes
  return processedNote;
};

export const toLowerCaseArray = (arr) =>
  arr &&
  arr.map((row) =>
    Object.keys(row).reduce((acc, rowData) => {
      acc[cleanString(rowData)] = row[rowData];
      return acc;
    }, {})
  );
