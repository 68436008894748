import type { EmployeePayElement } from '@/src/domains/payElements/types';

import { getEmployeePayElementOccurrenceLabel } from './helpers';

type Props = {
  row: { original: EmployeePayElement };
};

export default function EmployeePayElementOccurrenceCell({ row }: Props) {
  return <>{getEmployeePayElementOccurrenceLabel(row.original)}</>;
}
