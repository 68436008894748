import React, { memo, useCallback, useState } from 'react';
import type { ChangeEvent, ClipboardEvent, ElementType, ReactNode } from 'react';

import { Box, Stack } from '../../layout';
import type { FormGroupProps } from '../form-group';
import { ErrorMessage } from '../form-group';

import { SingleInput } from './SingleInput';

/**
 * Checks if a provided string can be converted to Number and if so, returns it.
 * Otherwise, an empty string is returned
 *
 * @param {String} val — value to test
 * @returns {String}
 */
function getValueIfNumber(val: string): string {
  if (!val) {
    return val;
  }

  return val.length === 1 && Number(val) >= 0 ? val : '';
}

export type VerificationCodeInputProps = {
  /**
   * Specifies how the browser should attempt to automatically complete the input based on user input
   * @default 'off'
   */
  autoComplete?: string;
  /**
   * Specifies whether the input should automatically get focus
   * @default true
   */
  autoFocus?: boolean;
  /**
   * Change event callback
   */
  onChange?: (optValue: string) => void;
  /**
   * The number of SingleInput components to display
   * @default 6
   */
  length?: number;
  /**
   * Text displayed when the control is in an error state
   */
  errorText?: ReactNode;
  /**
   * Specifies whether the input is required
   */
  required?: boolean;
  /**
   * The value of the input element
   * @default ''
   */
  value?: string;
  // Omitting props that are set internally
} & Omit<
  FormGroupProps,
  'placeholder' | 'errorText' | 'children' | 'autoFocus' | 'value' | 'label' | 'onChange'
>;

/**
 * These props are passed by Formik through the `InputOTP` wrapper component in Employ
 * File path: apps/employ/src/components/Ui/Form/inputs/InputOTP/InputOTP.tsx
 */
type FormikRelatedProps = {
  setValues: (values: { totp: string }) => void;
  Field: ElementType;
};

/**
 * This custom type mimics the basic structure of the "FieldProps" type from Formik.
 * This way we avoid using "any" as type for the "field" prop in the Field component
 * which is actually handled by Formik through the InputOTP component in Employ.
 */
type FormikFieldProps = {
  /** Name of the field */
  name: string;
  /** Value of the field */
  value: string;
  /** Change event handler */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Blur event handler */
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
};

type FormikFieldRenderProps = {
  field: FormikFieldProps;
};

const VerificationCodeInputComponent = ({
  autoComplete,
  autoFocus,
  errorText,
  onChange,
  length = 6,
  value,
  setValues,
  Field,
  ...props
}: VerificationCodeInputProps & FormikRelatedProps) => {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(new Array(length).fill(''));

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      setOTPValues(otp);
      const otpValue = otp.join('');
      setValues({ totp: otpValue });
    },
    [setValues]
  );

  // Helper to return value with the right type: 'text' or 'number'
  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str?: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str || '';
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus input based on index
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      const pressedKey = e.key;
      const isModifier = e.metaKey || e.ctrlKey;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (!isModifier && pressedKey.match(/^[^0-9]$/)) {
            e.preventDefault();
          }
          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { clipboardData } = e;
      if (!clipboardData) return;

      const pastedData = clipboardData
        .getData('text/plain')
        .trim()
        .split('')
        .filter((char) => !!getValueIfNumber(char))
        .slice(0, length - activeInput);

      if (pastedData) {
        // The new OTP value is a merge between the previous values and the (valid) pasted characters
        const updatedOTPValues = [...otpValues.slice(0, activeInput), ...pastedData];

        if (updatedOTPValues.length === 0) {
          return;
        }

        setActiveInput(Math.min(updatedOTPValues.length, length - 1));
        handleOtpChange(updatedOTPValues);
      }
    },
    [activeInput, length, otpValues, handleOtpChange]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = getValueIfNumber(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput]
  );

  return (
    <Box mb={5}>
      <Stack
        alignItems="center"
        justifyContent="center"
        data-testid="otp-input-root"
        direction="row"
        gap={[2, 3]}
      >
        {Array(length)
          .fill('')
          .map((_, index) => (
            <Box key={index} mr={index === 2 ? [3, 4] : 0}>
              <SingleInput
                aria-label={`${index === 0 ? 'Please enter verification code. ' : ''}${'Digit'} ${
                  index + 1
                }`}
                autoComplete="off"
                autoFocus
                focus={activeInput === index}
                key={`SingleInput-${index}`}
                label={`Digit ${index + 1}`}
                onBlur={onBlur}
                onInput={handleOnChange}
                onFocus={handleOnFocus(index)}
                onKeyDown={handleOnKeyDown}
                onPaste={handleOnPaste}
                type="tel"
                value={otpValues && otpValues[index]}
                errorText={errorText}
                {...props}
              />
            </Box>
          ))}
        <Field name={props.name}>
          {({ field }: FormikFieldRenderProps) => <input hidden {...field} />}
        </Field>
      </Stack>

      {errorText ? (
        <Box width={['328px', null, null, '400px']} mx="auto" mt={2}>
          <ErrorMessage>{errorText}</ErrorMessage>
        </Box>
      ) : null}
    </Box>
  );
};

export const VerificationCodeInput = memo(VerificationCodeInputComponent);
