import { generateSelectOptionsFromValues } from '@/src/helpers/forms';

export const EMPLOYMENT_VERIFICATION = {
  PENDING: 'pending',
  VERIFIED: 'verified',
  DECLINED: 'declined',
};

export const employmentVerificationLabel = {
  [EMPLOYMENT_VERIFICATION.PENDING]: 'Pending',
  [EMPLOYMENT_VERIFICATION.VERIFIED]: 'Verified',
  [EMPLOYMENT_VERIFICATION.DECLINED]: 'Declined',
};

export const employmentVerificationBadge = {
  [EMPLOYMENT_VERIFICATION.PENDING]: 'pending',
  [EMPLOYMENT_VERIFICATION.VERIFIED]: 'active',
  [EMPLOYMENT_VERIFICATION.DECLINED]: 'error',
};

export const employmentVerificationOptions = generateSelectOptionsFromValues(
  Object.values(EMPLOYMENT_VERIFICATION),
  employmentVerificationLabel
);
