import { Stack, LabelInputWrapper } from '@remote-com/norma';
import styled, { css } from 'styled-components';

import { FormFieldError } from '@/src/domains/registration/onboarding/shared/styles/Form';

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

export const Radio = styled(Stack).attrs({
  direction: 'row',
  gap: 4,
})`
  position: relative;
  align-items: center;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
  }

  &[aria-disabled='true'] {
    opacity: 0.5;
  }

  ${({ hasDescription }) =>
    hasDescription &&
    css`
      align-items: flex-start;
    `};
`;

export const RadioOptionWithInputContainer = styled(Stack).attrs({
  gap: 3,
  direction: 'row',
  alignItems: 'center',
})`
  ${() =>
    css`
      ${LabelInputWrapper} {
        padding: 0;
      }
      input {
        padding: 0 12px;
        width: auto;
        height: 100%;
      }
    `}
`;

export const RadioOptionWithInputError = styled(FormFieldError)`
  margin-top: -12px;
`;
