export const FEEDBACK_MESSAGE_ERROR = 'error';
export const FEEDBACK_MESSAGE_WARNING = 'warning';
export const FEEDBACK_MESSAGE_SUCCESS = 'success';
export const FEEDBACK_MESSAGE_INFO = 'info';
export const FEEDBACK_MESSAGE_NEUTRAL = 'neutral';
export const FEEDBACK_MESSAGE_TIME = 'time';

export type FeedbackMessageVariant =
  | typeof FEEDBACK_MESSAGE_ERROR
  | typeof FEEDBACK_MESSAGE_WARNING
  | typeof FEEDBACK_MESSAGE_SUCCESS
  | typeof FEEDBACK_MESSAGE_INFO
  | typeof FEEDBACK_MESSAGE_NEUTRAL;
