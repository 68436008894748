import { useInvalidateQuery, usePatch, usePost } from '@remote-com/data-layer';
import { useCallback } from 'react';

import { useUser } from '@/src/components/UserProvider/context';
import type {
  ArchiveVariables,
  CreateVariables,
  DuplicateVariables,
  ExtractMutationOptions,
  UpdateVariables,
} from '@/src/domains/contracts/contractTemplate/useEditorTemplateActions/types';
import { CONTRACT_TEMPLATE_STATUS } from '@/src/domains/contractTemplates/contractBuilder/constants';
import {
  getCountrySlugs,
  getRemoteEntitySlugForSLATemplate,
} from '@/src/domains/contractTemplates/helpers';

export function useContractTemplateActions() {
  const { userIsAdmin } = useUser();
  const { invalidateQuery } = useInvalidateQuery();

  const createAsAdminMutation = usePost('/api/v1/rivendell/contract-templates', {
    async onSuccess() {
      await invalidateQuery('/api/v1/rivendell/contract-templates');
    },
  });
  const createAsEmployerMutation = usePost('/api/v1/employer/contract-templates', {
    async onSuccess() {
      await invalidateQuery('/api/v1/employer/contract-templates');
    },
  });
  const updateAsAdminMutation = usePatch('/api/v1/rivendell/contract-templates/[slug]', {
    async onSettled(variables, error, params) {
      // Refetch after error or success
      await Promise.all([
        invalidateQuery('/api/v1/rivendell/contract-templates'),
        invalidateQuery('/api/v1/rivendell/contract-templates/[slug]', {
          params: { pathParams: params.pathParams },
        }),
      ]);
    },
  });
  const updateAsEmployerMutation = usePatch('/api/v1/employer/contract-templates/[slug]', {
    async onSettled(variables, error, params) {
      // Refetch after error or success
      await Promise.all([
        invalidateQuery('/api/v1/employer/contract-templates'),
        invalidateQuery('/api/v1/employer/contract-templates/[slug]', {
          params: { pathParams: params.pathParams },
        }),
      ]);
    },
  });

  const createTemplate = <TVariables extends CreateVariables | DuplicateVariables>(
    variables: TVariables,
    mutationOptions: ExtractMutationOptions<
      typeof createAsAdminMutation | typeof createAsEmployerMutation
    >
  ) => {
    const { countrySlug, companySlug, remoteEntitySlug, type, ...rest } = variables;
    const bodyParams = {
      ...rest,
      type,
      countrySlugs: getCountrySlugs(countrySlug),
    };

    if (userIsAdmin) {
      createAsAdminMutation.mutate(
        {
          bodyParams: {
            ...bodyParams,
            companySlug: companySlug || null,
            ...getRemoteEntitySlugForSLATemplate(type, remoteEntitySlug),
          },
        },
        mutationOptions
      );
    } else {
      createAsEmployerMutation.mutate({ bodyParams }, mutationOptions);
    }
  };

  const updateTemplate = (
    variables: UpdateVariables,
    mutationOptions: ExtractMutationOptions<
      typeof updateAsAdminMutation | typeof updateAsEmployerMutation
    >
  ) => {
    const { contractTemplateSlug, countrySlug, remoteEntitySlug, type, ...rest } = variables;
    const pathParams = { slug: contractTemplateSlug };
    const bodyParams = {
      ...rest,
      type,
      ...(countrySlug ? { countrySlugs: getCountrySlugs(countrySlug) } : {}),
    };

    if (userIsAdmin) {
      updateAsAdminMutation.mutate(
        {
          pathParams,
          bodyParams: {
            ...bodyParams,
            ...getRemoteEntitySlugForSLATemplate(type, remoteEntitySlug),
          },
        },
        mutationOptions
      );
    } else {
      updateAsEmployerMutation.mutate({ pathParams, bodyParams }, mutationOptions);
    }
  };

  const archiveTemplate = (
    variables: ArchiveVariables,
    mutationOptions: ExtractMutationOptions<
      typeof updateAsAdminMutation | typeof updateAsEmployerMutation
    >
  ) => {
    const mutation = userIsAdmin ? updateAsAdminMutation : updateAsEmployerMutation;

    mutation.mutate(
      {
        pathParams: {
          slug: variables.contractTemplateSlug,
        },
        bodyParams: {
          status: CONTRACT_TEMPLATE_STATUS.ARCHIVED,
        },
      },
      mutationOptions
    );
  };

  const create = createTemplate<CreateVariables>;
  const duplicate = createTemplate<DuplicateVariables>;
  const reset = useCallback(
    () =>
      [
        createAsAdminMutation,
        createAsEmployerMutation,
        updateAsAdminMutation,
        updateAsEmployerMutation,
      ].forEach((mutation) => mutation.reset()),
    [
      createAsAdminMutation,
      createAsEmployerMutation,
      updateAsAdminMutation,
      updateAsEmployerMutation,
    ]
  );

  return {
    create,
    duplicate,
    update: updateTemplate,
    archive: archiveTemplate,
    reset,
    isLoading:
      createAsAdminMutation.isLoading ||
      createAsEmployerMutation.isLoading ||
      updateAsAdminMutation.isLoading ||
      updateAsEmployerMutation.isLoading,
    error:
      createAsAdminMutation.error ??
      createAsEmployerMutation.error ??
      updateAsAdminMutation.error ??
      updateAsEmployerMutation.error,
  };
}
