import type { Currency } from '@remote-com/talent/api/boba/shared.types';
import isBefore from 'date-fns/isBefore';
import isPlainObject from 'lodash/isPlainObject';

import type {
  Benefit,
  BenefitDetails,
  BenefitGroup,
  BenefitPlan,
  Carrier,
  CarrierOrPlatform,
  EmploymentBenefitOffer,
} from '@/src/api/config/employ/benefits.types';
import type { LegalEntityApi } from '@/src/api/config/employ/legalEntity.types';
import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import { benefitPlanCostTypes } from '@/src/domains/benefits/constants';
import { employmentType } from '@/src/domains/employment/constants';
import { isContractor, isEmployee, isEmployer } from '@/src/domains/registration/auth/helpers';
import { friendlyMoney } from '@/src/helpers/currency';
import { friendlyLabel, PERCENT_CHAR, friendlyPlaceholderDash } from '@/src/helpers/general';

/**
 * Returns a structured name for the benefit plan.
 * It will only return if the benefit plan exists and it's not an array (this is a special
 * fix when we're using a multi select field).
 */
export const getBenefitPlanShortDisplayName = (
  benefitPlan: BenefitPlan,
  { showSlug = false, prependInactive = false } = {}
) => {
  return benefitPlan && !Array.isArray(benefitPlan)
    ? `${prependInactive && benefitPlan.status === 'inactive' ? '(Inactive) ' : ''}${
        benefitPlan.name
      } (${benefitPlan.carrier?.name || benefitPlan?.country?.name})${
        showSlug ? ` [${benefitPlan.slug}]` : ''
      }`
    : friendlyLabel(null);
};

const isValidCost = (value: any) => !!value || value === 0;

export function friendlyBenefitPlanCost({
  planCostType,
  currency,
  employerPremiumFlat,
  employerPremiumPercentage,
}: Partial<BenefitPlan> = {}) {
  const flatCost = employerPremiumFlat;
  const percentageCost = employerPremiumPercentage;

  if (planCostType === benefitPlanCostTypes.FLAT && isValidCost(flatCost)) {
    return friendlyMoney(flatCost, currency);
  }
  if (planCostType === benefitPlanCostTypes.PERCENTAGE && isValidCost(percentageCost)) {
    return `${percentageCost}%`;
  }

  return friendlyPlaceholderDash;
}

export const friendlyPercentage = (value: any) =>
  isValidCost(+value) && value !== null ? `${value}%` : friendlyPlaceholderDash;

/**
 * Used for amount fields when there's either a flat or percentage value
 * Returns appropriate helper - currency symbol/percentage symbol or null
 */
export function getAmountHelperText(costType: string | undefined, currency: Currency | undefined) {
  switch (costType) {
    case benefitPlanCostTypes.FLAT:
      return currency?.code || '';
    case benefitPlanCostTypes.PERCENTAGE:
      return PERCENT_CHAR;
    default:
      return null;
  }
}

export const benefitsFieldDisplayFieldInfoBlock = ({
  name,
  title = 'Benefits',
  value,
}: {
  name?: string;
  title?: string | React.ReactElement;
  value: Object | any;
}) => ({
  title,
  ...(name ? { name } : {}),
  value:
    value && typeof value === 'object'
      ? Object.entries(value).map(([key, val]) => ({
          title: ['defaultGroup', 'default_group'].includes(key) ? '' : key,
          value: val === 'no' ? 'None' : val,
        }))
      : value,
});

/**
 * Checks if user should see Benefits entry in sidebar menu
 * @param {Object} user - current user object
 * @returns {Boolean}
 */
export const shouldShowBenefitsSidebarEntry = (user: UserAccount) =>
  isContractor(user) ||
  isEmployer(user) ||
  (isEmployee(user) && user.activeEmployment?.type !== employmentType.DIRECT);

/**
 * @deprecated Used only in the old onboarding. Assumes selectedBenefitTier can be a string (n/a in Octopus)
 * Checks if a group has a tier selected
 * @param {Object} group - benefit group object
 * @returns {Boolean}
 */
export const groupHasSelection = (group?: BenefitGroup & { selectedBenefitTier: string }) =>
  isPlainObject(group?.selectedBenefitTier);

/**
 * Splits renewal instruction by new lines. Removes empty entries.
 * @param {String} value - string renewal instruction
 * @returns {String[]}
 */
export const splitRenewalInstructions = (value: string = '') =>
  (value || '').split(/\r?\n/).filter(Boolean);

/**
 * Returns the list of countries that are already using the benefit costs ledger
 * @param {BenefitDetails[]} - list of country benefit details
 */
export function getCostLedgerCountryCodes(benefitDetails: BenefitDetails[] = []): string[] | [] {
  const today = new Date();
  return benefitDetails
    .filter(
      ({ benefitCostsLaunchOn }) =>
        benefitCostsLaunchOn && isBefore(new Date(benefitCostsLaunchOn), today)
    )
    .map(({ country }) => country.code);
}

type Option = {
  value: string;
  label: string;
};

/**
 * Returns a string composed of the labels from an OptionType array having the same values
 * @param {string[]} values - list of values to be found in the options
 * @param {Option[]} options - list of options to search in
 * @param {string} delimiter - delimiter for joining strings
 */
export const joinLabelsFromOptionsValues = (
  values: string[],
  options: Option[] = [],
  delimiter: string = ', '
): string => {
  const valuesSet = new Set(values);

  return options
    .reduce((acc, option) => {
      if (valuesSet.has(option.value)) {
        acc.push(option.label);
      }
      return acc;
    }, [] as string[])
    .join(delimiter);
};

/**
 * Returns an object with the Provider info, given a Carrier.
 * Will return null if the Provider has been hidden.
 * @param {Carrier} carrier - carrier
 */
export function getProvider(carrier: Carrier) {
  if (!carrier || carrier.hideFromEmployee) {
    return null;
  }
  return {
    description: carrier.description || null,
    name: carrier.name,
    url: carrier.url || null,
  };
}

/**
 * Returns an object with the Platform info, given a Carrier.
 * Will return null if the Platform has been hidden.
 * @param {Carrier} carrier - carrier
 */
export function getPlatform(carrier: Carrier) {
  if (!carrier || carrier.benefitPlatformHideFromEmployee || !carrier.benefitPlatformName) {
    return null;
  }
  return {
    description: carrier.benefitPlatformDescription,
    name: carrier.benefitPlatformName,
    url: carrier.benefitPlatformUrl,
  };
}

/**
 * Returns an object with the providers and platforms, from a list of benefits.
 * To be used in Employee-facing scenarios, since it removes providers/carriers that have been
 * configured by Admins to be hidden from employees
 * @param {Benefit[]} benefits - list of benefits
 */
export function getPlatformsAndProviders(benefits: Benefit[] | undefined): {
  providers: CarrierOrPlatform[];
  platforms: CarrierOrPlatform[];
} {
  const result = (benefits || []).reduce(
    ({ providers, platforms }, { benefitPlan }) => {
      const { carrier } = benefitPlan;
      const provider = getProvider(carrier);
      const platform = getPlatform(carrier);

      return {
        providers: {
          ...providers,
          ...(provider ? { [provider.name]: provider } : {}),
        },
        platforms: {
          ...platforms,
          ...(platform ? { [platform.name]: platform } : {}),
        },
      };
    },
    {
      providers: {},
      platforms: {},
    }
  );

  return {
    providers: Object.values(result.providers),
    platforms: Object.values(result.platforms),
  };
}

export const getInitialValuesFromOffers = (offers: EmploymentBenefitOffer[]) =>
  offers.reduce(
    (acc, { benefitGroup: { slug, filter }, benefitTier }) => ({
      ...acc,
      [slug]: {
        value: benefitTier?.slug || 'no',
        ...(filter?.slug ? { filter: filter.slug } : {}),
      },
    }),
    {}
  );

export function shouldForceNewPillForEmployers(activeLegalEntities: LegalEntityApi.LegalEntity[]) {
  const includedCountries = ['USA', 'ESP', 'IRL', 'GBR'];

  for (const entity of activeLegalEntities) {
    const countryCheck = includedCountries.includes(entity.address.country.code);
    const globalPayrollCheck = entity.activeServices?.some((service) => {
      return service.status === 'active' && service.group === 'global_payroll';
    });

    if (countryCheck && globalPayrollCheck) {
      return true;
    }
  }

  return false;
}
