import { useEffect, useState } from 'react';

import { useDebounce } from './useDebounce';

/**
 * Hook that gets the window width and provides updates when the screen is resized.
 */
export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState<number>();

  const handleResize = useDebounce(() => {
    setWindowWidth(window.innerWidth);
  });

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return {
    windowWidth,
  };
};
