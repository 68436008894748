import { SeparatorList, SeparatorListItem } from '@/src/components/SeparatorList';
import { useTodosContext } from '@/src/domains/todos/TodosProvider';

import { TodosOverlayListItem } from './TodosOverlayListItem';

export const TodosOverlayList = () => {
  const { todos } = useTodosContext();

  return (
    <SeparatorList>
      {todos.map((todo) => (
        <SeparatorListItem key={todo.slug} data-testid="todo-list-item">
          <TodosOverlayListItem todo={todo} />
        </SeparatorListItem>
      ))}
    </SeparatorList>
  );
};
