import type { BillingDocument } from '@/src/api/config/employ/billingDocuments.types';
import type { InvoiceReport } from '@/src/api/config/employ/invoiceReport.types';
import { useUser } from '@/src/components/UserProvider/context';
import {
  getInvoiceReportFields,
  getInvoiceReportFlags,
} from '@/src/domains/invoices/shared/helpers';

export function useDownloadBreakdownCSVEnabled(invoiceReport: InvoiceReport | BillingDocument) {
  const { userIsAdmin } = useUser();
  const { invoicePeriod } = getInvoiceReportFields(invoiceReport);
  const { isCorrection, isReconciliation, isRebill, isPreFundingInvoice } =
    getInvoiceReportFlags(invoiceReport);

  if (!invoicePeriod) {
    return false;
  }

  if (isCorrection || isRebill) {
    return false;
  }

  const invoicePeriodAsDate = new Date(invoicePeriod);

  // pre-funding breakdowns were enabled starting from August 2023
  if (isPreFundingInvoice && invoicePeriodAsDate >= new Date('2023-08')) {
    return true;
  }

  if (isReconciliation) {
    if (userIsAdmin) {
      return true;
    }
    // For Customers detailed breakdowns are enabled starting from January 2023.
    return invoicePeriodAsDate >= new Date('2023-01');
  }

  return false;
}
