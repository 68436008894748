import type { ValueOf } from 'type-fest';

import { generateSelectOptions } from '@/src/helpers/forms';

export const CONTRACT_WORK_SCHEDULES = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
} as const;

export const CONTRACT_WORK_SCHEDULES_LABELS = {
  [CONTRACT_WORK_SCHEDULES.FULL_TIME]: 'Full-time',
  [CONTRACT_WORK_SCHEDULES.PART_TIME]: 'Part-time',
} as const satisfies Record<ValueOf<typeof CONTRACT_WORK_SCHEDULES>, string>;

export const CONTRACT_WORK_SCHEDULES_OPTIONS = generateSelectOptions(
  CONTRACT_WORK_SCHEDULES_LABELS
);

export const DATE_SMARTFIELD_SUFFIXES = {
  DD_MM_YYYY: 'DD_MM_YYYY',
  MM_DD_YYYY: 'MM_DD_YYYY',
  YYYY_MM_DD: 'YYYY_MM_DD',
  DD_MONTH_YYYY: 'DD_MONTH_YYYY',
} as const;
