import { sharedTransition } from '@remote-com/norma';
import { IconTimes } from '@remote-com/norma/icons/IconTimes';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonIcon } from '@/src/components/Button';

const ButtonCloseStyled = styled(ButtonIcon)`
  ${sharedTransition('transform')};
  --iconColor: red;
  fill: var(--svgColor);

  &:hover,
  &:focus {
    transform: rotate(90deg);
    --iconColor: green;
  }
`;

export const ButtonClose = (props) => (
  <ButtonCloseStyled variant="ghost" Icon={IconTimes} {...props} />
);

ButtonClose.propTypes = {
  'aria-label': PropTypes.string.isRequired,
};
