import type { ComponentPropsWithRef, SyntheticEvent } from 'react';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import type { RequireAtLeastOne } from 'type-fest';

import type { WildcardProps } from '../../types';
import { focusRingOffsetBorder, focusVisible } from '../common.styled';

interface BaseToggleProps extends ComponentPropsWithRef<'button'>, WildcardProps {
  /**
   * Indicates the current state of the toggle.
   */
  checked?: boolean;
  /**
   * Controls the interactability of the toggle.
   */
  disabled?: boolean;
  /**
   * A callback function that is invoked when the toggle is clicked.
   */
  onClick: () => void;
  /**
   * Defines the size of the toggle button.
   */
  size?: 'sm' | 'md';
  /**
   * Indicates if the component fully relies on parent state for its rendering.
   */
  controlled?: boolean;
}

type ToggleProps = RequireAtLeastOne<BaseToggleProps, 'label' | 'aria-labelledby'>;

// eslint-disable-next-line remote/norma-prefer-button
const ToggleRoot = styled.button<{
  $size: NonNullable<ToggleProps['size']>;
}>`
  --norma-switch-height: ${({ $size }) => {
    switch ($size) {
      case 'sm':
        return '20px';
      case 'md':
        return '30px';
    }
  }};
  --norma-switch-width: calc(var(--norma-switch-height) * 1.6);
  --norma-switch-inset: calc(var(--norma-switch-height) * 0.1);

  background-color: var(--norma-switch-background);
  border-radius: var(--norma-switch-height);
  border: 0;
  cursor: pointer;
  height: var(--norma-switch-height);
  padding: 0;
  transition: all ease-in-out 150ms;
  vertical-align: middle;
  width: var(--norma-switch-width);

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &[aria-checked='false'] {
    --norma-switch-background: ${({ theme }) => theme.colors.grey[500]};

    &:hover {
      --norma-switch-background: ${({ theme }) => theme.colors.grey[600]};
    }

    ${focusVisible(css`
      ${({ theme }) => focusRingOffsetBorder(theme.colors.grey[500], theme.colors.blank)};
    `)};

    &[aria-disabled='true'] {
      --norma-switch-background: ${({ theme }) => theme.colors.grey[300]};
    }
  }

  &[aria-checked='true'] {
    --norma-switch-background: ${({ theme }) => theme.colors.brand[600]};

    &:hover {
      --norma-switch-background: ${({ theme }) => theme.colors.brand[700]};
    }

    ${focusVisible(css`
      ${({ theme }) => focusRingOffsetBorder(theme.colors.brand[500], theme.colors.blank)};
    `)};

    &[aria-disabled='true'] {
      --norma-switch-background: ${({ theme }) => theme.colors.brand[300]};
    }
  }
`;

const ToggleThumb = styled.span`
  display: grid;
  height: var(--norma-switch-height);
  padding: var(--norma-switch-inset);
  position: relative;
  transition: all ease-in-out 150ms;
  width: var(--norma-switch-height);

  [aria-checked='true'] > & {
    transform: translateX(calc(var(--norma-switch-width) - var(--norma-switch-height)));
  }

  &::before {
    background: ${({ theme }) => theme.colors.blank};
    border-radius: var(--norma-switch-height);
    content: '';
  }
`;

export const Toggle = ({
  label,
  checked = false,
  disabled = false,
  onClick,
  size = 'sm',
  controlled = false,
  ...props
}: ToggleProps) => {
  const wrapperRef = useRef<HTMLButtonElement>(null);

  function handleClick(event: SyntheticEvent) {
    if (disabled) {
      event.preventDefault();
    } else {
      if (!controlled) {
        const isChecked = wrapperRef.current?.getAttribute('aria-checked') === 'true';
        wrapperRef.current?.setAttribute('aria-checked', isChecked ? 'false' : 'true');
      }

      onClick();
    }
  }

  return (
    <ToggleRoot
      {...props}
      type="button"
      ref={wrapperRef}
      onClick={handleClick}
      aria-checked={checked ? 'true' : 'false'}
      aria-label={label}
      $size={size}
      aria-disabled={disabled}
      role="switch"
    >
      <ToggleThumb />
    </ToggleRoot>
  );
};
