import type {
  ContractTemplateTypesValue,
  EmployerManagedContractTemplate,
} from '@/src/api/config/employ/contractTemplates.types';
import { CONTRACT_TEMPLATE_SELECTORS } from '@/src/domains/contracts/contractTemplate/constants';
import type { TemplateCountry } from '@/src/domains/contracts/contractTemplate/useEditorTemplateActions/types';
import { CONTRACT_TEMPLATE_STATUS } from '@/src/domains/contractTemplates/contractBuilder/constants';
import { CONTRACT_TEMPLATE_TYPES } from '@/src/domains/contractTemplates/contractTemplates/constants';

export function getCountrySlugs(countryData: TemplateCountry | TemplateCountry[]) {
  if (Array.isArray(countryData)) {
    return countryData.map((country) => country.slug);
  }

  return [countryData.slug];
}

export function getCountryCode(countryData?: TemplateCountry | TemplateCountry[]) {
  if (Array.isArray(countryData)) {
    return null;
  }

  return countryData?.code;
}

export const getRemoteEntitySlugForSLATemplate = (
  type?: ContractTemplateTypesValue,
  remoteEntitySlug?: { value: string }
) => {
  if (type === CONTRACT_TEMPLATE_TYPES.SERVICE_LEVEL_AGREEMENT) {
    return {
      remoteEntitySlug: remoteEntitySlug?.value || '',
    };
  }

  return {};
};

/**
 * An object representing all possible responses from the `getContractTemplateType` helper.
 * @typedef {Object} ContractTemplateType
 * @property {boolean} isEmploymentAgreement
 * @property {boolean} isEmploymentAgreementAmendment
 * @property {boolean} isContractorAgreement
 * @property {boolean} isContractorServicesAgreement
 * @property {boolean} isContractorStatementOfWork
 * @property {boolean} isServiceLevelAgreement
 * @property {boolean} isCustomContractorAgreement
 * @property {boolean} isCustomContractorAgreementV2
 * @property {boolean} isGlobalOfferLetter
 * @property {boolean} isEmploymentVerificationLetter
 * @property {boolean} isGenericEmploymentDocument
 * @property {boolean} isOffboardingDocument
 * @property {boolean} isEmployerManagedDocument
 */

/**
 * @param {string} type - The current template type
 * @return {ContractTemplateType} - It returns the respective type as true
 */
export const getContractTemplateType = (type: ContractTemplateTypesValue) => {
  return {
    isEmploymentAgreement: type === CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT,
    isEmploymentAgreementAmendment: type === CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT,
    isContractorAgreement: type === CONTRACT_TEMPLATE_TYPES.CONTRACTOR_AGREEMENT,
    isContractorServicesAgreement: type === CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT,
    isContractorStatementOfWork: type === CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK,
    isServiceLevelAgreement: type === CONTRACT_TEMPLATE_TYPES.SERVICE_LEVEL_AGREEMENT,
    isCustomContractorAgreement: type === CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT,
    isCustomContractorAgreementV2: type === CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2,
    isGlobalOfferLetter: type === CONTRACT_TEMPLATE_TYPES.GLOBAL_OFFER_LETTER,
    isEmploymentVerificationLetter: type === CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER,
    isGenericEmploymentDocument: type === CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT,
    isOffboardingDocument: type === CONTRACT_TEMPLATE_TYPES.OFFBOARDING_DOCUMENT,
    isEmployerManagedDocument: type === CONTRACT_TEMPLATE_TYPES.EMPLOYER_MANAGED_DOCUMENT,
  };
};

/**
 * @param {Object} contractTemplate - Template properties
 * @return {boolean} - It returns true is status is archived
 */
export const isContractTemplateArchived = (
  contractTemplate?: Partial<EmployerManagedContractTemplate>
) => contractTemplate?.status === CONTRACT_TEMPLATE_STATUS.ARCHIVED;

/**
 * @param {Object} contractTemplate - Template properties
 * @return {boolean} - It returns true is status is published
 */
export const isContractTemplatePublished = (
  contractTemplate?: Partial<EmployerManagedContractTemplate>
) => contractTemplate?.status === CONTRACT_TEMPLATE_STATUS.PUBLISHED;

/**
 * @param {Object} contractTemplate - Template properties
 * @return {boolean} - It returns true if the template is used for Contractor Management Protect (CM Protect) product
 */
export const isContractorProtectContractTemplate = (
  contractTemplate?: EmployerManagedContractTemplate
) =>
  Array.isArray(contractTemplate?.selector) &&
  contractTemplate.selector.includes(CONTRACT_TEMPLATE_SELECTORS.AOR);

const CONTRACT_TEMPLATE_TYPES_WITH_REMOTE_SIGNATURE = new Set([
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT,
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT,
  CONTRACT_TEMPLATE_TYPES.GLOBAL_OFFER_LETTER,
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER,
  CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT,
  CONTRACT_TEMPLATE_TYPES.OFFBOARDING_DOCUMENT,
  CONTRACT_TEMPLATE_TYPES.PROBATION_CONFIRMATION_LETTER,
  CONTRACT_TEMPLATE_TYPES.TRAVEL_LETTER,
]);

const CONTRACT_TEMPLATE_TYPES_WITH_EMPLOYER_SIGNATURE = new Set([
  CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2,
  CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT,
  CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK,
  CONTRACT_TEMPLATE_TYPES.EMPLOYER_MANAGED_DOCUMENT,
]);

const CONTRACT_TEMPLATE_TYPES_WITH_CONTRACTOR_SIGNATURE = new Set([
  CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2,
  CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT,
  CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK,
]);

export const isContractTemplateWithRemoteSignature = (
  contractTemplate?: EmployerManagedContractTemplate
) => {
  const type = contractTemplate?.type;

  return (
    type &&
    (CONTRACT_TEMPLATE_TYPES_WITH_REMOTE_SIGNATURE.has(type) ||
      isContractorProtectContractTemplate(contractTemplate))
  );
};

export const isContractTemplateWithEmployerSignature = (
  contractTemplate?: EmployerManagedContractTemplate
) => {
  const type = contractTemplate?.type;

  return (
    type &&
    CONTRACT_TEMPLATE_TYPES_WITH_EMPLOYER_SIGNATURE.has(type) &&
    !isContractorProtectContractTemplate(contractTemplate)
  );
};

export const isContractTemplateWithContractorSignature = (
  contractTemplate?: EmployerManagedContractTemplate
) => {
  const type = contractTemplate?.type;
  return type && CONTRACT_TEMPLATE_TYPES_WITH_CONTRACTOR_SIGNATURE.has(type);
};
