import { Box, Stack } from '@remote-com/norma';
import { useState } from 'react';
import { object } from 'yup';

import FormModal from '@/src/components/Modal/FormModal';
import {
  Section,
  SectionTitle,
} from '@/src/components/Table/Components/ColumnsConfigurationDrawer/Section';
import { COLUMN_STATES } from '@/src/components/Table/hooks/useColumnsState';
import type { ColumnState } from '@/src/components/Table/hooks/useColumnsState/types';
import { InputField, CheckboxField as InputCheckbox } from '@/src/components/Ui/Form';
import { InputController } from '@/src/components/Ui/Form/Input';
import { baseString, genericRequiredLabel } from '@/src/helpers/validationSchema';

const messages = {
  tableNameInputDescription: 'Give the table an easily identifiable name.',
  tableNameInputLabel: 'Table name',
  title: 'Save table',
  visibleColumnsSectionTitle: 'Shown in table',
};

const validationSchema = object().shape({
  name: baseString.required(genericRequiredLabel),
});

type ColumnCheckboxProps = {
  column: ColumnState;
  onChange: (columnId: string) => void;
};

const ColumnCheckbox = ({ column: [id, columnState, name], onChange }: ColumnCheckboxProps) => (
  <InputCheckbox
    key={id}
    size="sm"
    name={id}
    label={name}
    checked={columnState === COLUMN_STATES.visible || columnState === COLUMN_STATES.view}
    onChange={() => onChange(id)}
  />
);

type SaveConfigModalProps = {
  data: ColumnState[];
  hideModal: () => void;
  onSave: ({ name, columnStateToSave }: { name: string; columnStateToSave: ColumnState[] }) => void;
  open: boolean;
  setListenToClickOutside: (listen: boolean) => void;
};

export const SaveConfigModal = ({
  open,
  data,
  hideModal,
  onSave,
  setListenToClickOutside,
}: SaveConfigModalProps) => {
  const [columnStateToSave, setColumnStateToSave] = useState<ColumnState[]>(data);

  const initialValues = {
    name: '',
  };

  const handleColumnVisibilityChange = (id: string) => {
    const columnIndexToChange = columnStateToSave.findIndex(([columnId]) => columnId === id);
    const newColumnStateToSave = [...columnStateToSave];

    newColumnStateToSave[columnIndexToChange][1] =
      columnStateToSave[columnIndexToChange][1] === COLUMN_STATES.visible
        ? COLUMN_STATES.invisible
        : COLUMN_STATES.visible;

    setColumnStateToSave(newColumnStateToSave);
  };

  function closeModal() {
    setListenToClickOutside(true);
    hideModal();
  }

  function handleSave({ values }: { values: { name: string } }) {
    onSave({ name: values.name, columnStateToSave });
    closeModal();
  }

  return (
    <FormModal
      modalProps={{
        visible: open,
        headerTitle: messages.title,
        onSave: handleSave,
        formName: 'save-column-configuration',
        onCancel: closeModal,
      }}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {() => (
        <Stack gap={6}>
          <InputController>
            <InputField
              name="name"
              label={messages.tableNameInputLabel}
              description={messages.tableNameInputDescription}
              type="text"
            />
          </InputController>
          <Section>
            <SectionTitle>{messages.visibleColumnsSectionTitle}</SectionTitle>
            <Box>
              {columnStateToSave.map(([id, columnState, name]) => (
                <ColumnCheckbox
                  key={id}
                  column={[id, columnState, name]}
                  onChange={handleColumnVisibilityChange}
                />
              ))}
            </Box>
          </Section>
        </Stack>
      )}
    </FormModal>
  );
};
