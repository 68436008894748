import { Stack } from '@remote-com/norma';
import { Command } from 'cmdk';
import type { KeyboardEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { ScrollArea } from '@/src/components/ScrollArea';
import { useUserProfiles } from '@/src/domains/account/hooks/useUserProfiles';
import { PaginatedProfilesMenuEmpty } from '@/src/domains/navigation/profiles-menu/PaginatedProfilesMenu/PaginatedProfilesMenuEmpty';
import { PaginatedProfilesMenuSearch } from '@/src/domains/navigation/profiles-menu/PaginatedProfilesMenu/PaginatedProfilesMenuSearch';
import { ProfilesMenuFetching } from '@/src/domains/navigation/profiles-menu/PaginatedProfilesMenu/ProfilesMenuFetching';
import { ProfilesMenuItem } from '@/src/domains/navigation/profiles-menu/ProfilesMenuItem';

const StyledWrapper = styled(Stack)`
  max-height: 256px;
  gap: 3px;
`;

const StyledHeader = styled(Stack)`
  flex-shrink: 0;
`;

const StyledBody = styled(ScrollArea)`
  box-sizing: border-box;
  display: grid;
  flex-shrink: 1;
  flex-grow: 1;
  height: 100%;
`;

type PaginatedProfilesMenuProps = {
  onSelect: (value: number) => void;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  triggerRef?: React.RefObject<HTMLButtonElement>;
};

export const PaginatedProfilesMenu = ({
  onSelect,
  searchInputRef,
  triggerRef,
}: PaginatedProfilesMenuProps) => {
  const [value, setValue] = useState('');

  const {
    selectedProfile,
    profiles: { list: profilesList, fetchNextPage, currentPage, totalPages, isFetchingNextPage },
    searchQuery,
    onSearch,
  } = useUserProfiles();

  const hasNextPage = currentPage < totalPages;

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const handleSearchInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (value === String(profilesList[0]?.profileIndex) && event.key === 'ArrowUp') {
      // Focus the trigger when the user presses the up arrow key on the first item.
      triggerRef?.current?.focus();
    }
  };

  return (
    <Command shouldFilter={false} value={value} onValueChange={setValue}>
      <StyledWrapper>
        <StyledHeader>
          <Command.Input asChild value={searchQuery}>
            <PaginatedProfilesMenuSearch
              onKeyDown={handleSearchInputKeyDown}
              onSearch={onSearch}
              ref={searchInputRef}
              value={searchQuery}
            />
          </Command.Input>
        </StyledHeader>
        <Command.List asChild>
          <StyledBody>
            {profilesList?.map((profile, index) => {
              const isProfileSelected = selectedProfile?.profileIndex === profile.profileIndex;
              const hasRef = !searchQuery && index === profilesList.length - 1;

              return (
                <ProfilesMenuItem
                  // ProfilesMenuItem doesn't play nicely with `asChild` for some reason.
                  as={Command.Item}
                  checked={isProfileSelected}
                  key={profile.profileIndex}
                  isAccountsMenu={false}
                  // @ts-expect-error Types aren't working as expected because of `as` prop.
                  onSelect={() => onSelect(profile.profileIndex)}
                  profile={profile}
                  ref={hasRef ? ref : undefined}
                  value={String(profile.profileIndex)}
                />
              );
            })}
            <Command.Empty asChild>
              <PaginatedProfilesMenuEmpty searchQuery={searchQuery} />
            </Command.Empty>
            {hasNextPage && (
              <Command.Loading>
                <ProfilesMenuFetching />
              </Command.Loading>
            )}
          </StyledBody>
        </Command.List>
      </StyledWrapper>
    </Command>
  );
};
