import { friendlyLabel } from '@/src/helpers/general';

export function convertToPercent({ value }) {
  return parseFloat((value * 100).toFixed(2));
}

export function convertToFraction({ value }) {
  return parseFloat(value) / 100;
}

export function calculateDecimalPercentage(partialValue, totalValue) {
  return partialValue && totalValue ? partialValue / totalValue : undefined;
}

/**
 * @param {Object} value: discount as fraction (api format)
 */
export function friendlyDiscount({ value }) {
  if (value === undefined || value === null) {
    return friendlyLabel(value);
  }
  const percent = convertToPercent({ value });
  return `${percent % 1 === 0 ? percent : percent.toFixed(2)}%`;
}
