import enGB from 'date-fns/locale/en-GB';
import mergeRefs from 'merge-refs';
import type {
  ChangeEventHandler,
  FocusEventHandler,
  InputHTMLAttributes,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import { cloneElement, forwardRef, useEffect, useRef, useState } from 'react';
import type {
  ReactDatePicker,
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import DatePickerComponent, { registerLocale, setDefaultLocale } from 'react-datepicker';
import type { Except } from 'type-fest';

import { Button } from '../../core/button';
import { SROnly } from '../../core/text';
import { Tooltip } from '../../core/tooltip';
import {
  convertLocalTimeStringToDateObj,
  formatFullMonthDayOrdinal,
  formatFullMonthDay,
  formatFullMonth,
} from '../../foundations/date';
import { Input as FormGroupInput } from '../form-group/FormGroup.styled';

import {
  DatePickerDayContents,
  DatePickerFooter,
  DatePickerStyledWrapper,
} from './DatePicker.styled';
import type { DatePickerHeaderLinkProps } from './Header/Header';
import { datePickerCustomHeader } from './ReactDatePickerCustomHeader';

// NOTE: We are registering and setting the `en-GB` locale as default
// to have weeks start on a Monday in the calendar UI.
registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');

type InputValueFormatter = (value: string | undefined) => string | undefined;

/**
 * ReactDatePicker is a class component, so its ref is the class itself.
 * However, it manually attaches an `input` property to the ref, but not the class.
 * This is likely because we are using a custom input?
 * If so, it may be better if we use the input ref directly.
 * See: https://remote-com.slack.com/archives/C020B57P9QU/p1723023842225039?thread_ts=1723019277.829529&cid=C020B57P9QU
 */
type ReactDatePickerRef = ReactDatePicker & {
  input: HTMLInputElement;
  /**
   * This is the internal state (this.state) of the class component.
   * Not sure why but it is only `{}` in TypeScript,
   * so we need to redefine it here.
   */
  state: {
    open: boolean;
  };
};

/**
 * Heads up! This is not exhaustive,
 * as ReactDatePickerProps expects generic types to decide whether
 * it's a single date or a range of dates.
 *
 * However, due to our forwardRef, we're not sure how to type it.
 */
type Props = ReactDatePickerProps & {
  inputValueFormatter?: InputValueFormatter;
  /**
   * Heads up: This is used in web-native addEventListener,
   * so it's _not_ React's ChangeEventHandler.
   */
  onChangeNative?: EventListener;
  isSaveDisabled?: boolean;
  // Weird this is not optional
  cancelCallback: () => void;
  // Same weird this is not optional
  saveCallback: () => void;
  calendarOpenCallback?: () => void;
  datePickerRef?: MutableRefObject<ReactDatePickerRef | null>;
  errorText?: string;
  /**
   * react-datepicker's `maxDate` prop only supports `Date` objects,
   * but we want to support strings for backwards compatibility.
   */
  maxDate: ReactDatePickerProps['maxDate'] | string;
  /** See `maxDate` */
  minDate: ReactDatePickerProps['maxDate'] | string;
  setCalendarMonth?: (month: string) => void;
  isLoadingWorkCalendars?: boolean;
  saveTooltip?: ReactNode;
  headerLinkProps?: DatePickerHeaderLinkProps;
  disableYearSelection?: boolean;
  isExpanded?: boolean;
  /**
   * This acts like a custom "children",
   * but historically it was a direct patch for ProvisionalStartDate,
   * meaning it's also used for other logic, such as an expanded interface.
   * We should support this in a more generic way.
   *
   * @deprecated Use customExpandedContent instead
   */
  dangerouslyStartDateChildren?: ReactNode;
  /**
   * Custom content for the date picker's expanded interface.
   * When provided, it forces the date picker into an expanded state.
   */
  customExpandedContent?: ReactNode;
};

const defaultInputValueFormatter: InputValueFormatter = (value) => value;

const formatConfig = {
  isSourceInUtc: false,
  formatInLocalTime: true,
};

function PickerSummary(
  props: Pick<Props, 'startDate' | 'endDate' | 'selected' | 'showMonthYearPicker'> & {
    id: string;
  }
): null | ReactElement {
  const { id, startDate, endDate, showMonthYearPicker, selected } = props;

  if (showMonthYearPicker) {
    // TODO: Support dateRange with month (start, end). It seems it does not exist in the Platform at all yet.
    if (selected && !Array.isArray(selected)) {
      return <SROnly id={id}>Selected {formatFullMonth(selected, formatConfig)}.</SROnly>;
    }
    return <SROnly id={id}>No dates selected.</SROnly>;
  }

  if (!startDate && !endDate) {
    return <SROnly id={id}>No dates selected.</SROnly>;
  }
  if (startDate && (!endDate || endDate === startDate)) {
    return <SROnly id={id}>Selected {formatFullMonthDayOrdinal(startDate, formatConfig)}.</SROnly>;
  }
  if (startDate && endDate && endDate !== startDate) {
    // If both dates are equal, it's not really a range, it's just a single date stored.
    return (
      <SROnly id={id}>
        Selected dates from {formatFullMonthDay(startDate, formatConfig)} to{' '}
        {formatFullMonthDay(endDate, formatConfig)}
      </SROnly>
    );
  }
  return null;
}

type InputProps =
  // "size" and "color" are conflicted with our theme props
  Except<InputHTMLAttributes<HTMLInputElement>, 'size' | 'color'> &
    Pick<Props, 'errorText' | 'value' | 'selectsRange' | 'showMonthYearPicker'> & {
      inputRef: Ref<HTMLInputElement>;
      /**
       * This is optional in Props,
       * but our wrapper here will always provide a value for the input.
       */
      inputValueFormatter: NonNullable<Props['inputValueFormatter']>;
      /** This is provided by react-datepicker, so it's always defined.  */
      onBlur: FocusEventHandler<HTMLInputElement>;
      /** Same as onBlur. */
      onKeyDown: KeyboardEventHandler<HTMLInputElement>;
      /** Same as onBlur.  */
      onChange: ChangeEventHandler<HTMLInputElement>;
      /** This is provided by our wrapper, so it's also always defined. */
      customBlur: FocusEventHandler<HTMLInputElement>;
      /** Same as customBlur. */
      customKeyDown: KeyboardEventHandler<HTMLInputElement>;
    };

// NOTE: We are using a custom input for the date picker to pass down
// custom props such as `data-testid`.
const CustomDatePickerInput = forwardRef<HTMLInputElement, InputProps>(
  ({ inputRef, errorText, ...props }, ref) => {
    const value = props.inputValueFormatter(props.value);
    const handleBlur: FocusEventHandler<HTMLInputElement> = (ev) => {
      // This is react-datepicker's own onBlur event
      props.onBlur(ev);
      props.customBlur(ev);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (ev) => {
      // This is react-datepicker's own onKeyDown event
      props.onKeyDown(ev);
      props.customKeyDown(ev);
    };

    return (
      <>
        <FormGroupInput
          type="text"
          ref={mergeRefs<HTMLInputElement>(ref, inputRef)}
          {...props}
          // TODO:/IMPROVE: Replace this by useFormGroupContext (inputProps)
          // but first fix that because the aria-describedby is incorrect. Check MR !28403 for details.
          aria-describedby={`${props.name}-errormessage ${props.name}-description ${props.name}-description-format`}
          aria-invalid={errorText ? 'true' : undefined}
          aria-labelledby={`${props.name}-label`}
          value={value}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          // Range datepickers must be always readOnly because MR!2902
          readOnly={props.selectsRange || props.readOnly}
          // So we need CSS to rollback default readonly styles
          $dateRangeEditable={!props.readOnly}
        />
        <SROnly aria-hidden id={`${props.name}-description-format`}>
          {props.showMonthYearPicker ? 'Format YYYY-MM.' : 'Format YYYY-MM-DD.'}
        </SROnly>
      </>
    );
  }
);

CustomDatePickerInput.displayName = 'CustomReactDatePickerInputRef';

const ReactDatePickerWrapper = forwardRef<HTMLInputElement, Props>(function ReactDatePickerWrapper(
  {
    calendarContainer,
    minDate,
    maxDate,
    onCalendarClose,
    inputValueFormatter = defaultInputValueFormatter,
    onChangeNative,
    isSaveDisabled,
    cancelCallback,
    saveCallback,
    calendarOpenCallback,
    datePickerRef,
    errorText,
    dangerouslyStartDateChildren,
    customExpandedContent,
    ...props
  },
  ref
) {
  // Note: React Datepicker opens the calendar whenever the input is focused. We don't want that to happen every time:
  // - when a user presses "Save"/"Cancel", the input should be focused but the calendar should remain closed
  // - when the input is already filled, if the user tabs into it, it should not open the calendar by default
  const [allowOpenOnFocus, setAllowOpenOnFocus] = useState(true);
  const waitingForForcedFocusOnClose = useRef(false);

  // Heads up! This was previously just `useRef(datePickerRef)`,
  // and there was no issue, but we have reasons to believe the previous code is wrong,
  // and only luckily worked because it is updated later with "merge refs".
  // See: https://remote-com.slack.com/archives/C020B57P9QU/p1723092341087989?thread_ts=1723019277.829529&cid=C020B57P9QU
  const internalDatePickerRef = useRef<ReactDatePickerRef | null>(null);

  const lastPressedKey = useRef<KeyboardEvent | null>(null);
  const {
    children,
    placeholderText,
    setCalendarMonth,
    isLoadingWorkCalendars,
    onMonthChange,
    ...restProps
  } = props;
  const dates = {
    max: convertLocalTimeStringToDateObj(maxDate ?? null),
    min: convertLocalTimeStringToDateObj(minDate ?? null),
  };
  const pickerSummaryId = `datepicker-summary-${props.name}`;

  const handleChangeRaw: ChangeEventHandler = (event) => {
    // NOTE: We are using `event.preventDefault()` here because otherwise, when used
    // inside a label, the date picker will not close after selecting a date.
    const isDayButton = event.target.classList.contains('react-datepicker__day');
    const isMonthButton = event.target.classList.contains('react-datepicker__month-text');
    if (isDayButton || isMonthButton) event.preventDefault();
  };

  /**
   * Closes the datepicker calendar (by calling react datepicker's setOpen function).
   * It then focuses the main input without opening the calendar again.
   */
  function closePopoverAndFocusInput() {
    if (!internalDatePickerRef?.current) return;

    const { setOpen, input } = internalDatePickerRef.current;
    setAllowOpenOnFocus(false);
    setOpen(false);
    // Ensures programmatic focus takes precedence over allowOpenOnFocus when a focus
    // operation is waiting to take place. It avoids any callback that sets a new `props.selected`
    // value from re-enabling allowOpenOnFocus, keeping the datepicker closed
    waitingForForcedFocusOnClose.current = true;
    // Waits for datepicker internals to run when closing via setOpen and for blur event to occur
    // Avoids a race condition where focus is triggered while the datepicker is still open
    setTimeout(() => {
      input.focus();
      waitingForForcedFocusOnClose.current = false;
    });
  }

  const handleCancel: MouseEventHandler = (event) => {
    if (!internalDatePickerRef?.current) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    closePopoverAndFocusInput();
    cancelCallback();
  };

  const handleOnSave: MouseEventHandler = (event) => {
    if (!internalDatePickerRef?.current) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    closePopoverAndFocusInput();
    saveCallback();
  };

  const handleCalendarBlur: FocusEventHandler = () => {
    if (!props.selected) {
      cancelCallback();
    }
  };

  const handleInputBlurClose: FocusEventHandler<HTMLInputElement> = (event) => {
    // "onBlur" is often provided by FormGroup,
    // when DatePicker control is used as a field.
    // When being used as a control alone,
    // it's likely there's no explicit "onBlur" callback.
    props.onBlur?.(event);

    if (!internalDatePickerRef?.current) {
      return;
    }
    // When leaving a datepicker field using shift+tab, the popper remains open.
    // This fixes it by tracking the last pressed key, and is called when the
    // button loses focus
    const { setOpen, state } = internalDatePickerRef.current;

    const { key, shiftKey } = lastPressedKey.current || {};

    if (key === 'Tab' && shiftKey) {
      setOpen(false);
      saveCallback();
    }

    // If the calendar is not open and the input is being blurred, it means that
    // the user is moving to a different input. In this case, check if we need to allow the "open on focus" behavior
    if (!state.open) {
      setAllowOpenOnFocus(!props.selected);
    }
  };

  const handleInputKeyDown: KeyboardEventHandler = (ev) => {
    lastPressedKey.current = ev;
    if (!internalDatePickerRef?.current) return;
    const { state, setOpen } = internalDatePickerRef.current;
    if (
      !state.open && // Open calendar only if currently closed,
      // so that when it's open, it automatically closes on first Enter.
      (ev.key === 'Enter' || ev.key === ' ' || ev.key === 'ArrowDown')
    ) {
      setOpen(true);

      ev.preventDefault(); // prevent submit, scroll, etc
    }
  };

  function handleCalendarOpen() {
    lastPressedKey.current = null;
    calendarOpenCallback?.();
  }

  const handleCalendarKeyDown: KeyboardEventHandler = (ev) => {
    if (!internalDatePickerRef?.current) return;
    const { state } = internalDatePickerRef.current;
    if (state.open && ev.key === 'Escape') {
      ev.stopPropagation(); // In a modal, prevents modal from closing
    }
  };

  const renderCustomHeader = (customHeaderProps: ReactDatePickerCustomHeaderProps) =>
    datePickerCustomHeader({
      customHeaderProps,
      maxDate: dates.max ?? undefined,
      minDate: dates.min ?? undefined,
      onChangeMonth: (date) => {
        const currentYear = date.getFullYear();
        const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        const currentMonthAndYear = `${currentYear}-${currentMonth}`;
        if (setCalendarMonth) {
          setCalendarMonth(currentMonthAndYear);
        }
      },
      isLoadingWorkCalendars,
      headerLinkProps: props.headerLinkProps,
      showMonthYearPicker: props.showMonthYearPicker,
      disableYearSelection: props.disableYearSelection,
      selectsRange: props.selectsRange,
    });

  // `props.selected` is a `Date` that changes on every render, but we only
  // want to reset the state (`openOnFocus`) when its value is actually changed.
  //
  // If this effect depends on `props.selected` directly, the input will always
  // be forced to focus on every render.
  //
  // See:
  // - https://remote-com.slack.com/archives/C03LM6MA4LU/p1696487990359319?thread_ts=1696438273.112309&cid=C03LM6MA4LU
  const selectedTimestamp = props.selected ? props.selected.getTime() : null;
  useEffect(() => {
    // Whenever we have a new "selected value", prevent the "open on focus" behavior if the value is truthy
    if (!waitingForForcedFocusOnClose.current) {
      setAllowOpenOnFocus(!selectedTimestamp);
    }
    // React Datepicker library sets a -1 to the clear button, so we're fixing that whenever we have a value selected (There's an open issue on this: https://github.com/Hacker0x01/react-datepicker/issues/3884)
    if (!!selectedTimestamp && internalDatePickerRef.current?.input) {
      const clearButton = internalDatePickerRef.current?.input.parentElement?.querySelector(
        '.react-datepicker__close-icon'
      );
      clearButton?.setAttribute('tabindex', '');
      clearButton?.setAttribute('aria-label', 'Clear date input');
    }
  }, [selectedTimestamp, internalDatePickerRef]);

  useEffect(() => {
    // We need this native change event to better control the input value and validations.
    // Check parent that uses the callback onChangeNative or MR !22321 for details.
    const input = internalDatePickerRef.current?.input;
    const handler: EventListener = (event) => {
      onChangeNative?.(event);
    };

    input?.addEventListener('change', handler);
    return () => {
      input?.removeEventListener('change', handler);
    };
  }, [internalDatePickerRef, onChangeNative]);

  const isExpanded =
    props?.headerLinkProps?.isHeaderLinkOpen ||
    Boolean(dangerouslyStartDateChildren) ||
    Boolean(customExpandedContent);
  return (
    <DatePickerStyledWrapper $isExpanded={isExpanded}>
      <DatePickerComponent
        ref={mergeRefs<ReactDatePickerRef>(datePickerRef, internalDatePickerRef)}
        calendarContainer={calendarContainer}
        customInput={
          <CustomDatePickerInput
            // Sorry for the "any", but it's not possible to type the input props.
            // The key here is that ReactDatePicker will _cloneElement_ the input,
            // and provide more props, even update some props, while doing so.
            //
            // In other words, we either have a correct type at the implementation,
            // or a correct type at the usage (here), but not both,
            // because ReactDatePicker will modify them in between.
            // We choose the later in this case.
            {...(restProps as any)}
            errorText={errorText}
            inputValueFormatter={inputValueFormatter}
            customBlur={handleInputBlurClose}
            customKeyDown={handleInputKeyDown}
            inputRef={ref}
          />
        }
        onCalendarClose={onCalendarClose}
        onClickOutside={onCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        inputMode="decimal"
        maxDate={dates.max}
        minDate={dates.min}
        onChangeRaw={handleChangeRaw}
        popperPlacement="bottom-start"
        renderCustomHeader={renderCustomHeader}
        renderDayContents={(day) => <DatePickerDayContents>{day}</DatePickerDayContents>}
        shouldCloseOnSelect={false}
        placeholderText={placeholderText}
        onMonthChange={onMonthChange}
        {...restProps}
        isClearable={props.readOnly ? false : props.isClearable}
        preventOpenOnFocus={!allowOpenOnFocus}
        onBlur={handleCalendarBlur}
        formatWeekDay={(day) => day.slice(0, 3)}
        // The typing is wrong here. The prop is available in the source,
        // even at our outdated 4.8.0 version.
        // See: https://github.com/Hacker0x01/react-datepicker/blob/9b633f3d1afb9a59fedf8e37f31c108215da755a/src/index.jsx#L218
        // @ts-expect-error
        selectsDisabledDaysInRange
        onKeyDown={handleCalendarKeyDown}
      >
        <PickerSummary
          id={pickerSummaryId}
          startDate={props.startDate}
          endDate={props.endDate}
          showMonthYearPicker={restProps.showMonthYearPicker}
          selected={restProps.selected}
        />
        {children &&
          (typeof children === 'function'
            ? children({ onSave: handleOnSave, onChange: restProps.onChange })
            : // There's little we can do besides an "any" here.
              // "cloneElement" is fragile to begin with, see https://react.dev/reference/react/cloneElement.
              // Moreover, ReactNode itself could eventually be a function,
              // causing the check above not exhaustive.
              cloneElement(children as any, {
                onSave: handleOnSave,
                onChange: restProps.onChange,
              }))}
        {dangerouslyStartDateChildren || customExpandedContent}
        <DatePickerFooter $hasChildren={Boolean(children)}>
          <Button size="xs" variant="outline" tone="primary" onClick={handleCancel}>
            Cancel
          </Button>
          {/* NOTE: "Save" must be the 2nd button because of A11Y. Loom explanation at MR!22321 */}
          <Tooltip label={props.saveTooltip}>
            <Button
              disabled={isSaveDisabled}
              size="xs"
              variant="solid"
              tone="primary"
              onClick={handleOnSave}
              aria-describedby={pickerSummaryId}
            >
              Save
            </Button>
          </Tooltip>
        </DatePickerFooter>
      </DatePickerComponent>
    </DatePickerStyledWrapper>
  );
});

export default ReactDatePickerWrapper;
