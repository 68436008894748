import { Box } from '@remote-com/norma';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import styled from 'styled-components';

import { useUserContext } from '@/src/components/UserProvider';
import { useContractsEditorContext } from '@/src/domains/contracts/ContractsEditor/ContractsEditorProvider';
import { CONTRACT_TEMPLATE_TYPES } from '@/src/domains/contractTemplates/contractTemplates/constants';
import { isEmployer } from '@/src/domains/registration/auth/helpers';

const StyledNodeViewContent = styled(NodeViewContent)`
  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 65px;

    .bilingual-editor & {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
      width: 100%;
    }

    /* NOTE: This overwrites the styles inside the SignatureBlockComponent to force */
    /* left alignment for documents that ony have a single signature */
    & > div:only-child div {
      display: grid;
      justify-items: start;
    }
  }
`;

const PlaceholderSignatureWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 65px;

  .bilingual-editor & {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
  }
`;

const PlaceholderSignature = styled(Box)`
  color: #b1becd;
  text-align: center;
  padding: 32px;
  border: 1px solid #cbd3dc;
  background-color: #f4f7fc;
`;

export const SignaturesWrapperComponent = () => {
  const { user } = useUserContext();
  const { contractType } = useContractsEditorContext();

  const isEmploymentAgreementForCustomer =
    contractType === CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT && isEmployer(user);

  if (isEmploymentAgreementForCustomer) {
    return (
      <NodeViewWrapper className="react-component">
        <PlaceholderSignatureWrapper>
          <PlaceholderSignature>Remote signature placeholder</PlaceholderSignature>
          <PlaceholderSignature>Employee signature placeholder</PlaceholderSignature>
        </PlaceholderSignatureWrapper>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper className="react-component">
      <StyledNodeViewContent />
    </NodeViewWrapper>
  );
};
