import { getAsHours } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/getAsHours';
import { parseDayTime } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/parseDayTime';
import {
  calculateWorkingHours,
  calculateWorkingHoursSimplified,
} from '@/src/components/Ui/Form/WorkWeekScheduleTableField/utils/calculateWorkingHours';

/**
 *
 * @typedef {{
 *   startTime: string,
 *   endTime: string,
 *   lunchStartTime: string,
 *   lunchEndTime: string,
 *   workingHours: number
 * }} ApiPayload
 *
 */

/**
 * Prepares component state to be sent to api as payload
 * @returns {ApiPayload}
 */
export function stateToApiPayload(state, prop) {
  return prop.displayLunchTime
    ? {
        startTime: state.startTime?.value || '',
        endTime: state.endTime?.value || '',
        lunchStartTime: state.lunchStartTime?.value || '',
        lunchEndTime: state.lunchEndTime?.value || '',
        workingHours:
          state.startTime?.parsed &&
          state.endTime?.parsed &&
          state.lunchStartTime?.parsed &&
          state.lunchEndTime?.parsed
            ? getAsHours(
                calculateWorkingHours(
                  state.startTime?.parsed,
                  state.endTime?.parsed,
                  state.lunchStartTime?.parsed,
                  state.lunchEndTime?.parsed
                )
              )
            : null,
      }
    : {
        startTime: state.startTime?.value || '',
        endTime: state.endTime?.value || '',
        workingHours:
          state.startTime?.parsed && state.endTime?.parsed
            ? getAsHours(
                calculateWorkingHoursSimplified(state.startTime?.parsed, state.endTime?.parsed)
              )
            : null,
      };
}

/**
 * Creates component state from apiPayload
 */
export function apiPayloadToState(payload, prop) {
  // Skip parsing if payload has been parsed before
  // We will hit this code when getDisplayFields is called with already parsed values
  if (payload.startTime?.parsed) {
    return payload;
  }

  return prop.displayLunchTime
    ? {
        startTime: {
          value: payload.startTime || '',
          parsed: payload.startTime ? parseDayTime(payload.startTime) : null,
        },
        endTime: {
          value: payload.endTime || '',
          parsed: payload.endTime ? parseDayTime(payload.endTime) : null,
        },
        lunchStartTime: {
          value: payload.lunchStartTime || '',
          parsed: payload.lunchStartTime ? parseDayTime(payload.lunchStartTime) : null,
        },
        lunchEndTime: {
          value: payload.lunchEndTime || '',
          parsed: payload.lunchEndTime ? parseDayTime(payload.lunchEndTime) : null,
        },
      }
    : {
        startTime: {
          value: payload.startTime || '',
          parsed: payload.startTime ? parseDayTime(payload.startTime) : null,
        },
        endTime: {
          value: payload.endTime || '',
          parsed: payload.endTime ? parseDayTime(payload.endTime) : null,
        },
      };
}
