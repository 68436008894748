import { getYear } from 'date-fns';
import type { ChangeEvent } from 'react';

import { themeV2 as theme } from '../../../foundations';
import { IconV2OutlineChevronLeft } from '../../../icons/build/IconV2OutlineChevronLeft';
import { IconV2OutlineChevronRight } from '../../../icons/build/IconV2OutlineChevronRight';

import {
  DatePickerHeaderDateNavigation,
  DatePickerHeaderYearInput,
  DatePickerHeaderArrowNavigation,
  DatePickerHeaderMonthNavigationButton,
  DatePickerHeaderNavigationWrapper,
} from './Header.styled';

type MonthPickerHeaderProps = {
  changeYear: (year: string) => void;
  date: Date;
  decreaseYear: () => void;
  increaseYear: () => void;
  nextYearButtonDisabled: boolean;
  prevYearButtonDisabled: boolean;
  minDate?: Date;
  maxDate?: Date;
};

export function MonthPickerHeader({
  changeYear,
  date,
  decreaseYear,
  increaseYear,
  nextYearButtonDisabled,
  prevYearButtonDisabled,
  minDate,
  maxDate,
}: MonthPickerHeaderProps) {
  const handleChangeYear = ({ target }: ChangeEvent<HTMLInputElement>) => {
    let updatedYear = target.value;

    if (updatedYear.length > 1) {
      // NOTE: Number inputs accept leading zeroes. To make for a
      // better user experience, we're trimming all leading zeroes
      // from the value string.
      updatedYear = updatedYear.replace(/^0+/, '');

      // NOTE: We are not expecting years with more than 4 digits.
      // Since number inputs don't accept the `maxLength` attribute,
      // we're limiting the length here.
      updatedYear = updatedYear.slice(0, 4);

      target.value = updatedYear;
    }

    changeYear(updatedYear);
  };

  const minYear = minDate?.getFullYear();
  const maxYear = maxDate?.getFullYear();

  return (
    <DatePickerHeaderNavigationWrapper>
      <DatePickerHeaderDateNavigation>
        <DatePickerHeaderYearInput
          aria-label="Year"
          onChange={handleChangeYear}
          type="number"
          value={getYear(date)}
          data-testid="datepicker-input-year"
          min={minYear}
          max={maxYear}
        />
      </DatePickerHeaderDateNavigation>
      <DatePickerHeaderArrowNavigation>
        <DatePickerHeaderMonthNavigationButton
          aria-label="Previous year"
          disabled={prevYearButtonDisabled}
          onClick={decreaseYear}
          type="button"
          data-testid="datepicker-button-year-prev"
        >
          <IconV2OutlineChevronLeft height={16} fill={theme.colors.grey[600]} />
        </DatePickerHeaderMonthNavigationButton>
        <DatePickerHeaderMonthNavigationButton
          aria-label="Next year"
          disabled={nextYearButtonDisabled}
          onClick={increaseYear}
          type="button"
          data-testid="datepicker-button-year-next"
        >
          <IconV2OutlineChevronRight height={16} fill={theme.colors.grey[600]} />
        </DatePickerHeaderMonthNavigationButton>
      </DatePickerHeaderArrowNavigation>
    </DatePickerHeaderNavigationWrapper>
  );
}
