// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineExpand = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M5.47 15.47c.3-.3.77-.3 1.06 0L12 20.94l5.47-5.47a.75.75 0 1 1 1.06 1.06l-6 6c-.3.3-.77.3-1.06 0l-6-6a.75.75 0 0 1 0-1.06Zm0-6.94a.75.75 0 0 1 0-1.06l6-6c.3-.3.77-.3 1.06 0l6 6a.75.75 0 0 1-1.06 1.06L12 3.06 6.53 8.53c-.3.3-.77.3-1.06 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineExpand);
export { ForwardRef as IconV2OutlineExpand };