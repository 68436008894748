import { Box, Stack, Text, themeV2 as theme } from '@remote-com/norma';
import styled from 'styled-components';

export const ModalActionableOuterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const ModalActionableInnerWrapper = styled(Stack).attrs({ gap: 8 })`
  max-width: 410px;
`;

export const ModalActionableHeader = styled(Stack).attrs({ gap: 5 })`
  text-align: center;
`;

export const ModalActionableTitle = styled(Text).attrs({
  color: 'grey.900',
  forwardedAs: 'h2',
  variant: '2xlMedium',
})``;

export const ModalActionableDescription = styled(Text).attrs({
  color: 'grey.600',
  forwardedAs: 'div',
  variant: 'paragraph',
})`
  a {
    color: ${theme.colors.primary};
  }
`;

export const ModalActionableFooter = styled(Stack).attrs({ gap: 3 })`
  align-items: center;
`;

export const ModalActionableActions = styled(Stack).attrs({ direction: 'row', gap: 6 })`
  justify-content: center;
`;
