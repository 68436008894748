import type { MutableRefObject } from 'react';
import { useCallback, useEffect } from 'react';

type FakeRef = MutableRefObject<{ contains: () => boolean }>;

type ClickOutsideProps = {
  onClick: () => void;
  listen?: boolean;
  refEl: MutableRefObject<HTMLElement | null> | FakeRef;
  whitelistEl?: (element: HTMLElement) => void;
};

export default function useClickOutside({
  refEl,
  listen,
  onClick,
  whitelistEl,
}: ClickOutsideProps) {
  const closeOnClickOutside = useCallback(
    (e) => {
      // We want to allow clicking outside for some particular elements e.g.: dropdowns or datepickers
      const elementWhitelisted = whitelistEl ? whitelistEl(e.target) : false;

      if (refEl.current && !refEl.current.contains(e.target) && !elementWhitelisted) {
        onClick();
      }
    },
    [refEl, onClick, whitelistEl]
  );

  useEffect(() => {
    if (listen) {
      document.addEventListener('click', closeOnClickOutside, { capture: true });
    }

    return () => document.removeEventListener('click', closeOnClickOutside, { capture: true });
  }, [listen, closeOnClickOutside]);
}
