// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconArrowUp = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12 4a1 1 0 0 1 1 1v14a1 1 0 1 1-2 0V5a1 1 0 0 1 1-1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M11.3 4.3a1 1 0 0 1 1.4 0l7 7a1 1 0 0 1-1.4 1.4L12 6.42l-6.3 6.3a1 1 0 0 1-1.4-1.42l7-7Z" /></svg>;
const ForwardRef = forwardRef(IconArrowUp);
export { ForwardRef as IconArrowUp };