import { Stack, Pill, Tooltip } from '@remote-com/norma';
import { IconInfo } from '@remote-com/norma/icons/IconInfo';
import styled from 'styled-components';

const Icon = styled(IconInfo)`
  width: 16px;
  z-index: 2;

  margin-left: ${({ theme }) => theme.space[2]}px;
  color: ${({ theme }) => theme.colors.lynch};
  cursor: pointer;
`;

export type TierCostProps = {
  cost: string;
  disclaimer?: string | null;
};

/**
 * @deprecated - use Octopus
 */
export const TierCost = ({ cost, disclaimer }: TierCostProps) => (
  <Stack direction="row" alignItems="center" ml="auto">
    <Pill tone="neutralLight">{cost}</Pill>
    {!!disclaimer && (
      <Tooltip label={disclaimer}>
        <Icon data-testid="info-icon" />
      </Tooltip>
    )}
  </Stack>
);
