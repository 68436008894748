import debounce from 'lodash/debounce';
import type { ReactElement, ReactNode, MouseEvent } from 'react';
import React, { Children, cloneElement, isValidElement, useEffect, useRef, useState } from 'react';

import { HiddenContainer, TabStyle } from './Tab.styled';
import { TabMore } from './TabMore';

const MORE_TAB_WIDTH = 64;

export type TabsProps<TValue extends string | number> = {
  value?: TValue;
  fullWidth?: boolean;
  children: ReactNode;
  selectedTab?: TValue;
  setQueryParamOnClick?: boolean | 'drop-query';
  onClick?: (ev: MouseEvent<HTMLElement>, tabValue: any) => void;
};

export const Tabs = <TValue extends string | number>({
  value,
  onClick,
  fullWidth = false,
  children: childrenProps,
  selectedTab,
  setQueryParamOnClick,
}: TabsProps<TValue>) => {
  const ref = useRef<HTMLElement>(null);
  const [startHidingFromIndex, setStartHidingFromIndex] = useState(-1);
  const [moreTabIndexToShow, setMoreTabIndexToShow] = useState<number>();

  function selectHiddenTabFromUrl() {
    const tabsList = Children.map(childrenProps, (child: ReactNode, childIndex: number) => {
      if (isValidElement(child)) {
        return child?.props.value === undefined ? childIndex : child.props.value;
      }
      return childIndex;
    });
    const tabIndex = tabsList?.indexOf(selectedTab);
    if (tabIndex && tabIndex > startHidingFromIndex) {
      setMoreTabIndexToShow(tabIndex);
    }
  }

  function updateHideIndex() {
    if (ref.current) {
      // the gap between tabs varies based on screen size
      const tabPaddingAmount = parseInt(window?.getComputedStyle(ref?.current)?.gap || '0');
      let totalTabsOffsetWidth = 0;
      // Note: setting state at this point so that the next DOM calculation takes into account all the tab elements
      // setStartHidingFromIndex(-1);
      let indexToHide = -1;
      // Tabs can be either a button or a link. Exclude More button from the selector
      const tabElements = Array.from(ref.current.querySelectorAll('button:not([id^=menu]), a'));
      tabElements.some((tab, tabIndex) => {
        if (tab instanceof HTMLElement) {
          totalTabsOffsetWidth += tab.offsetWidth + tabPaddingAmount;
          if (
            ref?.current?.parentNode instanceof HTMLElement &&
            totalTabsOffsetWidth > ref?.current.parentNode.offsetWidth - MORE_TAB_WIDTH
          ) {
            indexToHide = tabIndex - 1;
            return true;
          }
        }
        return false;
      });
      setStartHidingFromIndex(indexToHide);
    }
  }

  useEffect(() => {
    const handleTabsVisibility = () => {
      updateHideIndex();
    };

    const debouncedHandleTabsVisibility = debounce(handleTabsVisibility, 350);
    handleTabsVisibility();
    selectHiddenTabFromUrl();

    window.addEventListener('resize', debouncedHandleTabsVisibility);
    return () => {
      window.removeEventListener('resize', debouncedHandleTabsVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClickOnHiddenTab(
    ev: MouseEvent<HTMLElement>,
    tabValue: TValue,
    childIndex: number
  ) {
    setMoreTabIndexToShow(childIndex !== startHidingFromIndex ? childIndex : undefined);
    onClick?.(ev, tabValue);
  }

  function handleClickOnVisibleTab(ev: MouseEvent<HTMLElement>, tabValue: TValue) {
    onClick?.(ev, tabValue);
  }

  function getChildren() {
    return Children.map(childrenProps, (child, childIndex) => {
      if (!isValidElement(child)) {
        return null;
      }

      const childValue = child.props.value === undefined ? childIndex : child.props.value;
      const selected = childValue === value;

      function canDisplayTab() {
        // TODO: Current implementation of TabMore broke tab rendering for those TabBars that have "fullWidth" prop passed around. It needs to be fixed to support it, this is a temporary fix/hack to always show tabs (do not show "More") if fullWidth is passed.
        if (fullWidth) {
          return true;
        }
        // if first time the component renders show all tabs OR if all tabs fit in the screen
        if (startHidingFromIndex === -1) {
          return true;
        }
        // when a tab within More menu is selected swap the last visible tab inside the tab bar with the one selected
        if (moreTabIndexToShow) {
          if (childIndex === startHidingFromIndex) {
            return false;
          }
          if (moreTabIndexToShow === childIndex) {
            return true;
          }
        }
        return childIndex <= startHidingFromIndex;
      }

      const canDisplay = canDisplayTab();

      const childProps: Partial<TabsProps<TValue>> & { selected: boolean } & {
        display: 'visible' | 'hidden';
      } = {
        selected,
        onClick: (ev: MouseEvent<HTMLElement>) =>
          canDisplay
            ? handleClickOnVisibleTab(ev, childValue)
            : handleClickOnHiddenTab(ev, childValue, childIndex),
        value: childValue,
        setQueryParamOnClick,
        display: canDisplay ? 'visible' : 'hidden',
      };

      return cloneElement(child, childProps);
    });
  }

  const children = getChildren() ?? [];
  const [visibleChildren, hiddenChildren] = children.reduce<
    [ReactElement<TabsProps<TValue>>[], ReactElement<TabsProps<TValue>>[]]
  >(
    (tabs, child) => {
      if (isValidElement<TabsProps<TValue>>(child) && 'display' in child.props) {
        tabs[child.props.display === 'visible' ? 0 : 1].push(child);
      }
      return tabs;
    },
    [[], []]
  );

  return (
    <TabStyle ref={ref} $fullWidth={fullWidth}>
      {visibleChildren}
      {/* TODO: Current implementation of TabMore broke tab rendering for those TabBars that have "fullWidth" prop passed around. It needs to be fixed to support it, this is a temporary fix/hack to always show tabs (do not show "More") if fullWidth is passed. */}
      {!fullWidth && startHidingFromIndex >= 0 && <TabMore>{hiddenChildren}</TabMore>}
      {!fullWidth && <HiddenContainer>{hiddenChildren}</HiddenContainer>}
    </TabStyle>
  );
};
