import type { GetResponse } from '@remote-com/data-layer';
import { endOfMonth, isFuture } from 'date-fns';
import type { SetNonNullable, ValueOf } from 'type-fest';

import type { ProductIdentifierType } from '@/src/api/config/api.types';
import type { UserAccountResponse } from '@/src/components/UserProvider';
import {
  contractorSubscriptionPlanIdentifiers,
  freeTrialStatuses,
  lastBillableActivityColumnId,
} from '@/src/domains/paidContractorProduct/constants';
import { isProductContractorProtect } from '@/src/domains/pricing/helpers';
import type { ModifiedPricingPlan } from '@/src/domains/pricingPlans/common/PricingPlans.types';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { userCan } from '@/src/domains/registration/auth/helpers';
import { convertUtcStringToDateObj, friendlyYearMonth } from '@/src/helpers/date';
import type { $TSFixMe } from '@/types';

type FreeTrialStatus = ValueOf<typeof freeTrialStatuses>;
type ContractorSubscription =
  GetResponse<'/api/v1/employer/contractor-subscriptions'>['data'][number];
type ContractorPricingPlan =
  GetResponse<'/api/v1/employer/employments/[employmentSlug]/pricing-plan'>['data'];

function getUTCRangeFromMonth(yyyyMm: string) {
  const utcFrom = `${yyyyMm}-01T00:00:00Z`;
  const utcTo = endOfMonth(convertUtcStringToDateObj(utcFrom)!).toISOString();

  return { utcFrom, utcTo };
}

// `GET /api/v1/employer/dashboard/employees` supports filtering by billable activity time range in UTC.
// However, the activity filter UI of the team table is implemented as month picker. This transform function
// turns a single `yyyy-MM` value to a range of two dates that represent the given month at UTC offset.
// Service end date range is transformed to two string values contractExpirationDateAfter and contractExpirationDateBefore
export function transformContractorsTableDateRangeFilters(params: $TSFixMe) {
  const {
    [lastBillableActivityColumnId]: lastBillableActivityMonth,
    serviceEndDate,
    ...restOfQueryParams
  } = params.queryParams ?? {};
  const transformedQueryParams = restOfQueryParams;

  if (lastBillableActivityMonth) {
    const { utcFrom, utcTo } = getUTCRangeFromMonth(lastBillableActivityMonth);
    transformedQueryParams.billableContractorActivityPerformedAtFrom = utcFrom;
    transformedQueryParams.billableContractorActivityPerformedAtTo = utcTo;
  }

  if (serviceEndDate) {
    [
      transformedQueryParams.contractExpirationDateAfter,
      transformedQueryParams.contractExpirationDateBefore,
    ] = serviceEndDate;
  }

  return {
    ...params,
    queryParams: transformedQueryParams,
  };
}

// `GET /api/v1/employer/billable-contractor-activities` supports filtering by billable activity time range in UTC.
// However, the activity filter UI of the team table is implemented as month picker. This transform function
// turns a single `yyyy-MM` value to a range of two dates that represent the given month at UTC offset.
//
// This function is very similar to the above, but uses a different set of parameter names - it's possible to
// DRY and create a generator function that would return a transformer, but feels like this makes code harder to
// understand.
export function transformBillableActivityTableParams(params: $TSFixMe) {
  const { performedAt, ...restOfQueryParams } = params.queryParams ?? {};
  const transformedQueryParams = restOfQueryParams;

  if (performedAt) {
    const { utcFrom, utcTo } = getUTCRangeFromMonth(performedAt);
    transformedQueryParams.performedAtFrom = utcFrom;
    transformedQueryParams.performedAtTo = utcTo;
  }

  return {
    ...params,
    queryParams: transformedQueryParams,
  };
}

export function isFreeTrialActive(
  contractorSubscription?: ContractorSubscription | ModifiedPricingPlan
) {
  return new Set<FreeTrialStatus | undefined>([
    freeTrialStatuses.ELIGIBLE,
    freeTrialStatuses.ACTIVE,
  ]).has(contractorSubscription?.freeTrial?.status);
}

export function hasActiveContractorSubscriptionProduct(
  contractorSubscriptions?: ContractorSubscription[]
) {
  return contractorSubscriptions?.some(
    (contractorSubscription) => contractorSubscription.product.active
  );
}

export function hasActiveAorProductInSubscriptions(
  contractorSubscriptions?: ContractorSubscription[]
) {
  return contractorSubscriptions?.some(
    (contractorSubscription) =>
      contractorSubscription.product.active &&
      isProductContractorProtect(contractorSubscription.product)
  );
}

export function isCurrentBillingPeriod(utcDateString?: string) {
  if (utcDateString) {
    const date = convertUtcStringToDateObj(utcDateString);

    if (date) {
      const currentBillingPeriod = friendlyYearMonth(new Date());
      return friendlyYearMonth(date) === currentBillingPeriod;
    }
  }

  return false;
}

export function isProductChangePending(
  pricingPlan?: ContractorPricingPlan
): pricingPlan is SetNonNullable<ContractorPricingPlan, 'newProduct'> {
  return (
    !!pricingPlan?.newProduct &&
    // BE does not automatically nullify `newProduct` when it becomes the
    // current one, so we additionally check if the product identifiers differ.
    pricingPlan.newProduct.identifier !== pricingPlan.currentProduct.identifier
  );
}

export function isNewProductEffectiveInFuture(
  pricingPlan?: ContractorPricingPlan
): pricingPlan is SetNonNullable<ContractorPricingPlan, 'newProductEffectiveAt'> {
  if (pricingPlan?.newProductEffectiveAt) {
    const startingDate = convertUtcStringToDateObj(pricingPlan.newProductEffectiveAt);
    if (startingDate) {
      return isFuture(startingDate);
    }
  }
  return false;
}

export function canUserDeactivateContractor(user?: UserAccountResponse) {
  return (
    // Permission check from `TigerWeb.API.V1.EmployerController` `deactivate_employment` action:
    userCan('delete', Resources.employer.people, user) &&
    // Permission check from `TigerWeb.API.V1.Employer.PricingPlanController` `show` action: before
    // a contractor is deactivated, we fetch the pricing plan to inform the user about the
    // particular conditions at which the contractor plan is going to be terminated, see
    // `ConfirmDeactivateContractorModal` for the exact copy.
    (userCan('read', Resources.employer.hiring, user) ||
      userCan('read', Resources.employer.people, user) ||
      userCan('read', Resources.employer.people_employment, user))
  );
}

// Original copy was provided in https://linear.app/remote/issue/CM-1856
export function getCsaDisclaimer(contractorSubscriptionIdentifier?: ProductIdentifierType) {
  if (
    contractorSubscriptionIdentifier &&
    contractorSubscriptionIdentifier !== contractorSubscriptionPlanIdentifiers.STANDARD
  ) {
    return 'Remote provides its Contractor Services Agreement (CSA) “as is”; it does not constitute legal advice or create an attorney-client relationship with Remote. Remote is not liable for the content You enter herein and You are encouraged to seek independent legal advice tailored to the specific relationship between You and Your Contractor.';
  }
  return 'Remote provides its Contractor Services Agreement (CSA) “as is”; it does not constitute legal advice or create an attorney-client relationship with Remote. Remote is not liable for the content You enter herein and You are encouraged to seek independent legal advice tailored to the specific relationship between You and Your Contractor. By use of the CSA, You release Remote from liability for any claim for damages or losses which may arise related to Your use thereof.';
}
