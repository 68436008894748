import getConfig from 'next/config';
import type { RefObject } from 'react';
import { useEffect } from 'react';

const {
  publicRuntimeConfig: { ENVIRONMENT, REMOTE_CONTROL_PANEL_ENABLED },
} = getConfig();

/**
 * Exposes some select props to the input reference, to be used by RemoteControlPanel Form Filler.
 * These exposed properties are needed so the tool can update select values from outside the Formik context.
 */
export function useSelectReferenceOnRemoteControlPanel<Options>(
  selectRef: RefObject<any>,
  options: Options,
  multiple: boolean
) {
  useEffect(() => {
    const isEnabled = ENVIRONMENT !== 'production' && REMOTE_CONTROL_PANEL_ENABLED;

    if (isEnabled && selectRef && selectRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      selectRef.current.inputRef.__remoteControlProps = {
        options,
        multiple,
        setValue: selectRef.current.setValue,
      };
    }
  }, [options, selectRef, multiple]);
}
