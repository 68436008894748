import { generateSelectOptions } from '@/src/helpers/forms';

export const queryNames = {
  GET_PAYROLL_CALENDARS_QUERY_NAME: 'fetch-payroll-calendars',
  GET_PAYROLL_CALENDAR_QUERY_NAME: 'fetch-payroll-calendar',
};

// The payroll cycle events only shown in the setup step
export const payrollCycleSetupEvents = {
  cutoffDate: 'cutoffDate',
  inputsSharedWithPsp: 'inputsSharedWithPsp',
  outputsReturnedByPsp: 'outputsReturnedByPsp',
  outputsProcessed: 'outputsProcessed',
  customerApprovedOutputs: 'customerApprovedOutputs',
  pspInformedAboutCustomerApproval: 'pspInformedAboutCustomerApproval',
  paymentFileSharedWithCustomer: 'paymentFileSharedWithCustomer',
  paymentFileUploadedByCustomer: 'paymentFileUploadedByCustomer',
};

// All payroll cycle events
export const payrollCycleEvents = {
  ...payrollCycleSetupEvents,
  paymentsDepositedIntoBankAccounts: 'paymentsDepositedIntoBankAccounts',
  holidays: 'holidays',
};

export const PAYROLL_CYCLE_EVENTS = [
  {
    id: payrollCycleEvents.cutoffDate,
    label: 'Input Cut-off',
  },
  {
    id: payrollCycleEvents.inputsSharedWithPsp,
    label: 'Payroll input review',
    description: 'Remote to prepare input and share it with PSP',
  },
  {
    id: payrollCycleEvents.outputsReturnedByPsp,
    label: 'Payroll processing',
    description: 'PSP to process payroll and share output with Remote',
  },
  {
    id: payrollCycleEvents.outputsProcessed,
    label: 'Payroll output delivery',
    description: 'Remote to process output in platform (share output with customer)',
  },
  {
    id: payrollCycleEvents.customerApprovedOutputs,
    label: 'Approval date',
    description: 'Customer to approve',
  },
  {
    id: payrollCycleEvents.pspInformedAboutCustomerApproval,
    label: 'Payment file preparation',
    description: 'Remote to inform payroll approval to PSP & to request file creation internally',
  },
  {
    id: payrollCycleEvents.paymentFileSharedWithCustomer,
    label: 'Payment file delivery',
    description: 'Remote to prepare and share file with the customer',
  },
  {
    id: payrollCycleEvents.paymentFileUploadedByCustomer,
    label: 'Customer payment execution',
    description: 'Customer to upload file to their bank',
  },
  {
    id: payrollCycleEvents.paymentsDepositedIntoBankAccounts,
    label: 'Pay Day',
  },
];

export const DEFAULT_PRINTABLE_EVENTS = [
  payrollCycleEvents.cutoffDate,
  payrollCycleEvents.customerApprovedOutputs,
  payrollCycleEvents.paymentsDepositedIntoBankAccounts,
  payrollCycleEvents.holidays,
];

export const payrollCalendarStatus = {
  draft: 'draft',
  active: 'active',
};

export const payrollCalendarStatusLabels = {
  [payrollCalendarStatus.draft]: 'Draft',
  [payrollCalendarStatus.active]: 'Active',
};

export const payrollCalendarStatusOptions = generateSelectOptions(payrollCalendarStatusLabels);
