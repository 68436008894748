import { IconV2SolidArrowUpRight } from '@remote-com/norma/icons/IconV2SolidArrowUpRight';
import startCase from 'lodash/startCase';
import { openModal } from 'react-url-modal';
import type { ValueOf } from 'type-fest';

import { StandaloneLink } from '@/src/components/Button';
import { TextCell } from '@/src/components/Table';
import { useUser } from '@/src/components/UserProvider/context';
import { ADMIN_USER_DETAILS_ROUTE, USER_PROFILE_ROUTE } from '@/src/constants/routes';
import {
  PAY_ELEMENT_DATA_SOURCES,
  PAY_ELEMENT_DATA_SOURCE_LABELS,
} from '@/src/domains/payElements/constants';
import { getFromConstObject } from '@/src/helpers/getFromConstObject';

export const PAY_ELEMENT_DATA_SOURCE_MODALS = {
  [PAY_ELEMENT_DATA_SOURCES.EXPENSE]: 'expense-details',
  [PAY_ELEMENT_DATA_SOURCES.INCENTIVE]: 'incentive-details',
  [PAY_ELEMENT_DATA_SOURCES.TIMEOFF]: 'timeoff-details',
} as const;

type Props = {
  value?: {
    slug?: string;
    type: ValueOf<typeof PAY_ELEMENT_DATA_SOURCES>;
  };
  userSlug: string;
  employmentSlug: string;
};

export default function DataSourceCell({ value, userSlug, employmentSlug }: Props) {
  const { userIsAdmin } = useUser();
  if (!value?.type) return <TextCell value="None" />;

  const dataSourceModal = getFromConstObject(PAY_ELEMENT_DATA_SOURCE_MODALS, value.type);

  if (value.type === PAY_ELEMENT_DATA_SOURCES.SALARY) {
    const route = userIsAdmin
      ? `${ADMIN_USER_DETAILS_ROUTE.replace('[userSlug]', userSlug)}?selectedTab=employment`
      : `${USER_PROFILE_ROUTE.replace('[employmentSlug]', employmentSlug)}?selectedTab=job`;
    return (
      <StandaloneLink IconAfter={IconV2SolidArrowUpRight} href={route} target="_blank">
        {PAY_ELEMENT_DATA_SOURCE_LABELS[value.type]}
      </StandaloneLink>
    );
  }

  if (!dataSourceModal || !value.slug)
    return (
      <TextCell
        value={getFromConstObject(
          PAY_ELEMENT_DATA_SOURCE_LABELS,
          value.type,
          startCase(value.type)
        )}
      />
    );

  return (
    <StandaloneLink
      IconAfter={IconV2SolidArrowUpRight}
      onClick={() =>
        openModal({
          name: dataSourceModal,
          params: { slug: value.slug },
        })
      }
    >
      {PAY_ELEMENT_DATA_SOURCE_LABELS[value.type]}
    </StandaloneLink>
  );
}
