// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneDollarCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M12 1.25a10.75 10.75 0 1 0 0 21.5 10.75 10.75 0 0 0 0-21.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 5.75c.41 0 .75.34.75.75v.75H13A2.75 2.75 0 0 1 15.75 10a.75.75 0 0 1-1.5 0c0-.69-.56-1.25-1.25-1.25h-.25v2.7l1.3.44a2.5 2.5 0 0 1-.8 4.86h-.5v.75a.75.75 0 0 1-1.5 0v-.75H11A2.75 2.75 0 0 1 8.25 14a.75.75 0 0 1 1.5 0c0 .69.56 1.25 1.25 1.25h.25v-2.7l-1.3-.44a2.5 2.5 0 0 1 .8-4.86h.5V6.5c0-.41.34-.75.75-.75Zm-.75 3h-.5a1 1 0 0 0-.32 1.94l.82.27V8.75Zm1.5 4.3v2.2h.5a1 1 0 0 0 .32-1.94l-.82-.27Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneDollarCircle);
export { ForwardRef as IconV2DuotoneDollarCircle };