import { useState, useMemo } from 'react';

import { IconEye } from '../../icons/build/IconEye';
import { IconEyeOff } from '../../icons/build/IconEyeOff';

import { Wrapper, Value, ButtonStyled } from './MaskSecret.styled';

type MaskSecretProps = {
  /** Content that will be masked. */
  children: string;
  /** Number of characters that will stay revealed from the masked value. */
  revealN?: number;
  /** If `true`, displays the "Show/Mask" toggle */
  hasToggle?: boolean;
  /**
   * If `true` it hides the "Show/Mask" text. The `aria-label` attribute
   * will always be present.
   * */
  hiddenLabel?: boolean;
  /**
   * Callback function that is called when the "Show/Mask" toggle is clicked.
   */
  onClick?: () => void;
};

/**
 *
 * @param {String} text - The text to mask
 * @param {Number} revealN - The last N characters to not mask
 * @param {Number} maxMaskLength - The total length of masked string
 * @returns {String} - The string masked
 * @example
 * maskString('PT001 007') -> "***** 007"
 * maskString('PT001 007', 1) -> "***** **3"
 * maskString('PT001 007', 1, 4) -> "****3"
 */
export const maskString = (text: string, revealN: number, maxMaskLength?: number) => {
  const size = text.length - revealN;

  return `${text
    // Get all the chars but the last N
    .substring(0, size)
    // Replace those chars with '*'
    .replace(/\S/g, '*')
    // Truncate the masked string length if it is too long
    .substring(0, maxMaskLength ?? size)}${
    // Concat last N original chars
    text.substring(size)
  }`;
};

export function MaskSecret({
  children,
  revealN = 3,
  hasToggle = true,
  hiddenLabel = false,
  onClick,
  ...props
}: MaskSecretProps) {
  const [isObscured, setIsObscured] = useState(true);
  const textObscured = useMemo(() => maskString(children, revealN), [children, revealN]);

  return (
    <Wrapper {...props}>
      <Value>{isObscured ? textObscured : children}</Value>
      {hasToggle && (
        <ButtonStyled
          tone="secondary"
          size="s"
          // Keep a fixed aria-label, to be consistent with aria-pressed.
          // Otherwise the label would contradict the status when pressed.
          aria-label="Reveal full text"
          aria-pressed={!isObscured}
          onClick={() => {
            setIsObscured(!isObscured);
            onClick?.();
          }}
          IconBefore={isObscured ? IconEye : IconEyeOff}
        >
          {!hiddenLabel && (isObscured ? 'Show' : 'Mask')}
        </ButtonStyled>
      )}
    </Wrapper>
  );
}
