import { Box } from '@remote-com/norma';
import styled from 'styled-components';

import ReportingLibraryWidget from '@/src/domains/reports/components/ReportWidgets/ReportingLibraryWidget';
import type { ReportTemplate } from '@/src/domains/reports/types';

type ReportingLibraryDashboardProps = {
  report: ReportTemplate;
};

const ReportGrid = styled(Box).attrs({
  display: 'grid',
  gap: 6,
})`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 490px 1fr;

  div:nth-child(1) {
    grid-column: 1 / 4;
  }

  div:nth-child(2) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  div:nth-child(3) {
    grid-row: 2 / 3;
    grid-column: 2 / 4;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    div:nth-child(1),
    div:nth-child(2),
    div:nth-child(3) {
      grid-column: 1;
      grid-row: auto;
    }
  }
`;

export default function ReportingLibraryDashBoard({ report }: ReportingLibraryDashboardProps) {
  return (
    <ReportGrid>
      {report?.widgets?.map((widget) => {
        return <ReportingLibraryWidget key={widget.slug} widget={widget} />;
      })}
    </ReportGrid>
  );
}
