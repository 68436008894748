import type { ReactNode } from 'react';
import { createContext } from 'react';

import type { FIELDSET_MODES } from './constants';

interface FieldsetContextProps {
  mode?: (typeof FIELDSET_MODES)[keyof typeof FIELDSET_MODES];
}

export const FieldsetContext = createContext<FieldsetContextProps | undefined>(undefined);

interface FieldsetContextProviderProps {
  children: ReactNode | ReactNode[];
  mode?: string;
}

export function FieldsetContextProvider({ children, mode }: FieldsetContextProviderProps) {
  return <FieldsetContext.Provider value={{ mode }}>{children}</FieldsetContext.Provider>;
}
