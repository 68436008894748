import { useField } from 'formik';
import { useRef } from 'react';

import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import { useBasicEmployeeDetails } from '@/src/views/rivendell/users/Details/hooks';

export function UserSelectField({ filters = {}, ...selectFieldProps }) {
  const [{ value }] = useField(selectFieldProps.name);
  const initialSlug = useRef(value).current;

  const { isLoading, data: selectedEmployee } = useBasicEmployeeDetails(initialSlug, {
    enabled: !!initialSlug,
  });

  const defaultOptions = selectedEmployee ? [selectedEmployee] : [];

  return (
    <PaginatedSearchSelectField
      data-testid="user-select-field"
      query={{
        path: '/api/v1/rivendell/users',
        params: { queryParams: { ...filters } },
        options: { select: (res) => getDataWithRenamedProperty(res, 'users') },
      }}
      searchQueryAlias="name"
      loadingMessage={() => 'Loading users…'}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.slug}
      defaultOptions={defaultOptions}
      isLoadingDefaultOptions={isLoading}
      isControlled
      {...selectFieldProps}
    />
  );
}
