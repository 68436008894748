import { Pill, SROnly, Stack, Text } from '@remote-com/norma';
import { IconEdit2 } from '@remote-com/norma/icons/IconEdit2';
import { IconV2OutlineLock } from '@remote-com/norma/icons/IconV2OutlineLock';

import type { Currency } from '@/src/api/config/employ/shared.types';
import { Button } from '@/src/components/Button';
import { TextCell } from '@/src/components/Table';
import { viewOnlyColumn } from '@/src/components/Table/helpers';
import { ADMIN_DASHBOARD_ROUTE, DASHBOARD_ROUTE } from '@/src/constants/routes';
import {
  PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS_COPY,
  PAY_ELEMENT_OCCURRENCES,
  PAY_ELEMENT_CATEGORIES_LABELS,
} from '@/src/domains/payElements/constants';
import DataSourceCell from '@/src/domains/payElements/EmployeePayElementsTable/DataSourceCell';
import type { EmployeePayElement, PayElementOccurrence } from '@/src/domains/payElements/types';
import EmployeePayElementNameCell from '@/src/domains/payroll/shared/payElements/EmployeePayElementNameCell';
import EmployeePayElementOccurrenceCell from '@/src/domains/payroll/shared/payElements/EmployeePayElementOccurrenceCell';
import {
  getFormattedPayElementValue,
  getEmployeePayElementOccurrenceVariation,
} from '@/src/domains/payroll/shared/payElements/helpers';
import {
  type PayElementType,
  EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS,
} from '@/src/domains/team/shared/UserProfile/EmployeeProfilePayElements/constants';
import { getFromConstObject } from '@/src/helpers/getFromConstObject';

import ValueFieldCell from '../CreateEmployeePayElement/CreateEmployeePayElementsInline/TableFieldCells/AmountField';
import DateFieldCell from '../CreateEmployeePayElement/CreateEmployeePayElementsInline/TableFieldCells/EffectiveDateField';

import {
  StyledIconChevron,
  ReadOnlyCell,
  EditableCell,
} from './EditEmployeePayElementTable.styled';

type EditableEmployeePayElement = EmployeePayElement & { isEditable: true };

export const buildCloseButtonPathWithSlug = ({
  userIsAdmin,
  closeButtonPath,
  payElement,
}: {
  userIsAdmin: boolean;
  closeButtonPath: string;
  payElement: EmployeePayElement | undefined;
}) => {
  const userProfileQueryParamSlug = userIsAdmin ? '[userSlug]' : '[employmentSlug]';
  const userProfileSlug = userIsAdmin
    ? payElement?.employment.user.slug
    : payElement?.employment.slug;
  const fallbackRoute = userIsAdmin ? ADMIN_DASHBOARD_ROUTE : DASHBOARD_ROUTE;
  const closeButtonPathWithSlug = userProfileSlug
    ? closeButtonPath.replace(userProfileQueryParamSlug, userProfileSlug)
    : fallbackRoute;

  return closeButtonPathWithSlug;
};

export const getOccurrence = (payElement?: EmployeePayElement) => {
  if (!payElement) {
    return undefined;
  }

  const occurrenceVariation = payElement && getEmployeePayElementOccurrenceVariation(payElement);
  const occurrence =
    occurrenceVariation.variation ===
    EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS.ONE_OFF_TEMPORARY_PAY_ELEMENT
      ? PAY_ELEMENT_OCCURRENCES.TEMPORARY
      : PAY_ELEMENT_OCCURRENCES.PERMANENT;

  return occurrence;
};

export const buildCopy = (occurrence?: PayElementOccurrence) => {
  if (!occurrence) {
    return {
      title: '',
      subTitle: '',
    };
  }

  const occurrenceTypeCopy =
    occurrence === PAY_ELEMENT_OCCURRENCES.TEMPORARY ? 'one-time' : 'recurring';

  return {
    title: `Edit ${occurrenceTypeCopy} pay element`,
    subTitle:
      occurrence === PAY_ELEMENT_OCCURRENCES.TEMPORARY
        ? 'This pay element will appear on a single payroll run.'
        : 'This pay element appears on every payroll run.',
  };
};

/**
 * Builds the columns for the EditEmployeePayElementTable component.
 * These are dynamically generated based on the occurrence.
 *
 * For TEPEs, the table has a single editable row.
 * For PEPEs, the table has 2 rows, one read-only and one editable.
 */
export const buildColumns = ({
  occurrence,
  currency,
}: {
  occurrence: PayElementOccurrence;
  currency?: Currency;
}) =>
  [
    {
      Header: '',
      id: 'iconColumn',
      accessor: () => null,
      ...viewOnlyColumn,
      // We disable ellipsis in this and other columns, because the <TableCell> component adds an extra
      // <div> if ellipsis is enabled, which breaks the "two-levels-per-row" layout of this table
      ellipsis: false,
      width: 40,
      maxWidth: 40,
      Cell: (props: {
        row: {
          original: EditableEmployeePayElement;
          isExpanded: boolean;
        };
      }) => {
        const { isEditable, pastValues } = props.row.original;

        const canBeExpanded = pastValues && pastValues.length > 0 && !isEditable;

        const ExpandRowButton = canBeExpanded ? (
          <Button
            variant="raw"
            aria-expanded={props.row.isExpanded}
            aria-label={`${props.row.isExpanded ? 'Collapse' : 'Expand'} this row.`}
          >
            <StyledIconChevron width="14px" $expanded={props.row.isExpanded} />
          </Button>
        ) : null;

        const ActionIcon = isEditable ? (
          <IconEdit2 width="14px" />
        ) : (
          <IconV2OutlineLock width="16px" color="grey.900" />
        );

        const screenReaderMessage = `${
          canBeExpanded ? 'This pay element has past values. Click on the row to display them.' : ''
        } ${isEditable ? 'This row is editable.' : 'This row is read only.'}`;

        return (
          <Stack gap={6} justifyContent="space-between" height="100%" paddingY={4}>
            <SROnly>{screenReaderMessage}</SROnly>
            {ExpandRowButton}
            {ActionIcon}
          </Stack>
        );
      },
    },
    {
      Header: 'Pay element',
      id: 'name',
      accessor: 'name',
      ...viewOnlyColumn,
      Cell: (props: {
        value: string;
        row: {
          original: EditableEmployeePayElement;
          isExpanded: boolean;
        };
      }) => {
        const { isEditable } = props.row.original;
        const { isExpanded } = props.row;

        if (occurrence === PAY_ELEMENT_OCCURRENCES.TEMPORARY) {
          return <EmployeePayElementNameCell {...props} />;
        }

        const columnHint = isEditable
          ? 'Proposed'
          : `Current${isExpanded ? ' (and past history)' : ''}`;

        return (
          <Stack gap={6} width="340px" paddingY={3}>
            <Text variant="smMedium" color="grey.900" aria-hidden>
              {columnHint}
            </Text>
            <EmployeePayElementNameCell {...props} />
          </Stack>
        );
      },
    },
    {
      Header: 'Category',
      id: 'payrollOutputAmountField',
      accessor: 'payrollOutputAmountField',
      ...viewOnlyColumn,
      ellipsis: false,
      Cell: (props: { value: EmployeePayElement['payrollOutputAmountField'] }) => {
        const config = getFromConstObject(PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS_COPY, props.value);

        return (
          <ReadOnlyCell>
            {config ? <Pill tone={config.color}>{config.label}</Pill> : ''}
          </ReadOnlyCell>
        );
      },
    },
    {
      Header: 'Element type',
      id: 'category',
      accessor: 'category',
      ...viewOnlyColumn,
      ellipsis: false,
      Cell: (props: { value: EmployeePayElement['category'] }) => {
        return (
          <ReadOnlyCell>
            {getFromConstObject(PAY_ELEMENT_CATEGORIES_LABELS, props.value)}
          </ReadOnlyCell>
        );
      },
    },
    {
      Header: 'Occurrence',
      id: 'occurrence',
      accessor: 'occurrence',
      ...viewOnlyColumn,
      ellipsis: false,
      Cell: (props: { row: { original: EmployeePayElement } }) => {
        return (
          <ReadOnlyCell>
            <EmployeePayElementOccurrenceCell row={props.row} />
          </ReadOnlyCell>
        );
      },
    },
    {
      Header: 'Amount',
      id: 'value',
      accessor: (payElement: PayElementType) => getFormattedPayElementValue({ ...payElement }),
      align: 'right',
      ...viewOnlyColumn,
      ellipsis: false,
      Cell: (props: {
        value: string;
        row: { original: EditableEmployeePayElement; index: number };
      }) => {
        if (props.row.original.isEditable) {
          return (
            <EditableCell>
              <ValueFieldCell row={props.row} currency={currency} fieldName="value" />
            </EditableCell>
          );
        }

        return (
          <ReadOnlyCell $alignRight>
            <TextCell {...props} />
          </ReadOnlyCell>
        );
      },
    },
    {
      Header: 'Effective date',
      id: 'effectiveDate',
      accessor: 'effectiveDate',
      ...viewOnlyColumn,
      ellipsis: false,
      Cell: (props: {
        value: string;
        row: { original: EditableEmployeePayElement; index: number };
      }) => {
        // When editing the pay element, show effective date as an editable field, otherwise show read-only field.
        if (props.row.original.isEditable) {
          return (
            <EditableCell>
              <DateFieldCell row={props.row} fieldName="effectiveDate" />
            </EditableCell>
          );
        }

        return (
          <ReadOnlyCell>
            <TextCell {...props} />
          </ReadOnlyCell>
        );
      },
    },
    occurrence === PAY_ELEMENT_OCCURRENCES.PERMANENT
      ? {
          Header: 'End date',
          id: 'endDate',
          accessor: 'endDate',
          ...viewOnlyColumn,
          ellipsis: false,
          Cell: (props: {
            value: string;
            row: { original: EditableEmployeePayElement; index: number };
          }) => {
            // When editing the pay element, show end date as an editable field, otherwise show read-only field.
            if (props.row.original.isEditable) {
              return (
                <EditableCell>
                  <DateFieldCell row={props.row} fieldName="endDate" isOptional />
                </EditableCell>
              );
            }

            return (
              <ReadOnlyCell>
                <TextCell {...props} />
              </ReadOnlyCell>
            );
          },
        }
      : undefined,
    {
      Header: 'Data Source',
      accessor: 'dataSource',
      Cell: ({ row }: { row: { original: EmployeePayElement } }) => (
        <DataSourceCell
          value={row.original.dataSource}
          userSlug={row.original.employment.user.slug}
          employmentSlug={row.original.employment.slug}
        />
      ),
      ...viewOnlyColumn,
    },
  ].filter(Boolean);

export const buildRows = (payElement: EmployeePayElement, occurrence: PayElementOccurrence) => {
  // For TEPEs, we display a single editable row
  if (occurrence === PAY_ELEMENT_OCCURRENCES.TEMPORARY) {
    return [{ ...payElement, isEditable: true }];
  }

  // For PEPEs, we display a two-row layout
  return [
    // One row is read-only, and displays the current values and the past values (if any)
    { ...payElement, isEditable: false },
    // The other row is editable
    { ...payElement, isEditable: true, clickDisabled: true },
  ];
};
