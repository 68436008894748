import type { DraggableProps, DroppableProps, OnDragEndResponder } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Box } from '@remote-com/norma';
import { IconV2OutlineBookmark } from '@remote-com/norma/icons/IconV2OutlineBookmark';
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { ColumnsConfigurationSearch } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/ColumnsConfigurationSearch';
import { ConfigurationList } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/ConfigurationList';
import { EditColumnsListItem } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/EditColumnsListItem';
import type {
  ChangeColumnOrderProps,
  Segment,
  SegmentedColumnsState,
  ToggleColumnVisibiltyProps,
} from '@/src/components/Table/Components/ColumnsConfigurationDrawer/hooks/useSegmentedColumnsState';
import {
  Section,
  SectionHeader,
  SectionTitle,
  SectionTitleIcon,
} from '@/src/components/Table/Components/ColumnsConfigurationDrawer/Section';
import type { ColumnState } from '@/src/components/Table/hooks/useColumnsState/types';

/* -------------------------------------------------------------------------------------------------
 * ColumnsListEmptyState
 * -----------------------------------------------------------------------------------------------*/

const columnsListEmptyStateMessages = {
  text: 'Drag and drop columns here.',
};

type ColumnsListEmptyStateProps = {
  text?: string;
};

const ColumnsListEmptyStateWrapper = styled(Box)`
  --columns-list-empty-state-wrapper-height: 36px;

  ${({ theme }) => theme.typography.sm}

  border-color: ${({ theme }) => theme.colors.grey[100]};
  border-radius: calc(var(--columns-list-empty-state-wrapper-height) / 2);
  border-style: dashed;
  border-width: 1px;
  color: ${({ theme }) => theme.colors.grey[500]};
  display: grid;
  height: var(--columns-list-empty-state-wrapper-height);
  padding: 0 ${({ theme }) => theme.space[5]}px;
  place-items: center;
  position: absolute;
  text-align: center;
  width: 100%;
`;

const ColumnsListEmptyState = ({
  text = columnsListEmptyStateMessages.text,
}: ColumnsListEmptyStateProps) => {
  return <ColumnsListEmptyStateWrapper>{text}</ColumnsListEmptyStateWrapper>;
};

/* -------------------------------------------------------------------------------------------------
 * DraggableColumnsListItem
 * -----------------------------------------------------------------------------------------------*/

const StyledDraggableEditColumnsListItem = styled(EditColumnsListItem)<{ $isDragging: boolean }>`
  background-color: ${({ $isDragging }) =>
    $isDragging && 'var(--column-list-item-interaction-color)'};
`;

type DraggableColumnsListItemProps = {} & Pick<
  ComponentPropsWithoutRef<typeof EditColumnsListItem>,
  'columnState' | 'onToggleColumnVisibility'
> &
  Omit<DraggableProps, 'children'>;

const DraggableColumnsListItem = ({
  columnState,
  onToggleColumnVisibility,
  ...draggableProps
}: DraggableColumnsListItemProps) => {
  return (
    <Draggable {...draggableProps}>
      {(provided, { isDragging }) => {
        return (
          <StyledDraggableEditColumnsListItem
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            $isDragging={isDragging}
            data-testid="draggable-table-column-item"
            columnState={columnState}
            onToggleColumnVisibility={isDragging ? undefined : onToggleColumnVisibility}
            ref={provided.innerRef}
            type="draggable"
          />
        );
      }}
    </Draggable>
  );
};

/* -------------------------------------------------------------------------------------------------
 * DroppableColumnsList
 * -----------------------------------------------------------------------------------------------*/

type DroppableColumnsListProps = PropsWithChildren<{
  id: Segment;
  columnsState: ColumnState[];
  onToggleColumnVisibility: (columnId: string) => void;
}> &
  Omit<DroppableProps, 'children' | 'droppableId'>;

const StyledConfigurationList = styled(ConfigurationList)`
  min-height: calc(
    36px + var(--column-list-space)
  ); /* The height of a list item + spacing to avoid jumps. */
  position: relative;

  /* 
  @hello-pangea/dnd doesn't take gap into account when calculating placeholder size.
  We have to use margin-bottom on list items and negative margin-bottom on the list itself
  to avoid layout shifts.
  */
  gap: 0;
  margin-bottom: calc(var(--column-list-space) * -1);

  > * {
    margin-bottom: var(--column-list-space);
  }
`;

const DroppableColumnsList = ({
  columnsState,
  id,
  onToggleColumnVisibility,
  ...droppableProps
}: DroppableColumnsListProps) => {
  return (
    <Droppable {...droppableProps} droppableId={id}>
      {(provided) => {
        return (
          <StyledConfigurationList {...provided.droppableProps} ref={provided.innerRef}>
            {columnsState.length ? (
              columnsState.map((columnState, index) => {
                const [columnId] = columnState;

                return (
                  <DraggableColumnsListItem
                    draggableId={columnId}
                    index={index}
                    key={columnId}
                    columnState={columnState}
                    onToggleColumnVisibility={() => onToggleColumnVisibility(columnId)}
                  />
                );
              })
            ) : (
              <ColumnsListEmptyState />
            )}
            {provided.placeholder}
          </StyledConfigurationList>
        );
      }}
    </Droppable>
  );
};

/* -------------------------------------------------------------------------------------------------
 * EditColumnsView
 * -----------------------------------------------------------------------------------------------*/

const editColumnsViewMessags = {
  invisibleColumnsSectionTitle: 'Hidden',
  noSearchResults: 'No results match your search.',
  searchInputLabel: 'Search columns',
  visibleColumnsSectionTitle: 'Shown in table',
};

type EditColumnsViewProps = {
  activeConfigurationName?: string;
  changeColumnOrder: (props: ChangeColumnOrderProps) => void;
  onSearch: (value: string) => void;
  toggleColumnVisibility: (props: ToggleColumnVisibiltyProps) => void;
  searchResults: ColumnState[];
  searchValue: string;
  segmentedColumnsState: SegmentedColumnsState;
};

export const EditColumnsView = ({
  activeConfigurationName,
  changeColumnOrder,
  onSearch,
  searchResults,
  searchValue,
  segmentedColumnsState,
  toggleColumnVisibility,
}: EditColumnsViewProps) => {
  const handleChangeOrder: OnDragEndResponder = ({
    destination,
    source,
    draggableId: columnId,
  }) => {
    if (!destination) {
      return;
    }

    changeColumnOrder({
      id: columnId,
      source: { segment: source.droppableId as Segment, index: source.index },
      destination: { segment: destination.droppableId as Segment, index: destination.index },
    });
  };

  return (
    <>
      <ColumnsConfigurationSearch
        label={editColumnsViewMessags.searchInputLabel}
        onSearch={onSearch}
        renderResult={(columnState) => {
          const [id] = columnState;
          return (
            <EditColumnsListItem
              key={id}
              columnState={columnState}
              onToggleColumnVisibility={(columnId) => {
                toggleColumnVisibility({ id: columnId, currentSegment: 'visible' });
              }}
            />
          );
        }}
        searchResults={searchResults}
        searchValue={searchValue}
      />
      {!searchValue && (
        <DragDropContext onDragEnd={handleChangeOrder}>
          <Section>
            <SectionHeader>
              {activeConfigurationName ? (
                <>
                  <SectionTitleIcon icon={IconV2OutlineBookmark} />
                  <SectionTitle>{activeConfigurationName}</SectionTitle>
                </>
              ) : (
                <SectionTitle>{editColumnsViewMessags.visibleColumnsSectionTitle}</SectionTitle>
              )}
            </SectionHeader>
            <DroppableColumnsList
              columnsState={segmentedColumnsState.visible}
              id="visible"
              onToggleColumnVisibility={(columnId) => {
                toggleColumnVisibility({ id: columnId, currentSegment: 'visible' });
              }}
            />
          </Section>
          <Section>
            <SectionHeader>
              <SectionTitle>{editColumnsViewMessags.invisibleColumnsSectionTitle}</SectionTitle>
            </SectionHeader>
            <DroppableColumnsList
              columnsState={segmentedColumnsState.invisible}
              id="invisible"
              onToggleColumnVisibility={(columnId) => {
                toggleColumnVisibility({ id: columnId, currentSegment: 'invisible' });
              }}
            />
          </Section>
        </DragDropContext>
      )}
    </>
  );
};
