import {
  SROnly,
  Tooltip,
  Stack,
  Box,
  Text,
  toast,
  FiltersInputSizeProvider,
} from '@remote-com/norma';
import { IconBookmark } from '@remote-com/norma/icons/IconBookmark';
import { IconV2OutlineFilters } from '@remote-com/norma/icons/IconV2OutlineFilters';
import { Formik } from 'formik';
import capitalize from 'lodash/capitalize';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { Button } from '@/src/components/Button';
import { Separator } from '@/src/components/Separator';
import {
  FilterButton,
  FiltersArea,
} from '@/src/components/Table/Components/TableComponents.styled';
import { FiltersContainer } from '@/src/components/Table/Components/Toolbar/FiltersPopover.styled';
import FilterLoader from '@/src/components/Table/Components/Toolbar/SaveFilters/FilterLoader';
import { SaveFilterActionBar } from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilterActionBar';
import { useSaveFilterContext } from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilterContext';
import SaveFilterModal from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilterModal';
import { Popover, PopoverTrigger, PopoverBox, PopoverContext } from '@/src/components/Ui/Popover';
import { useModalContext } from '@/src/hooks/useModalContext';

const FiltersPopover = ({
  children,
  filtersApplied,
  formDefaultValues,
  onSubmit,
  onReset,
  onPreload,
  onOpen,
  large,
  theme,
  filterValidationSchema,
}) => {
  const [openedBefore, setOpenedBefore] = useState(false);
  const { showModal, hideModal } = useModalContext();

  // Safe guard: in Countries table <FiltersPopover> is used without the <Toolbar> component as a parent which means the context is not set properly

  const { enabled, savedFilters, addFilter, trackOnSaveFilter } = useSaveFilterContext() || {
    enabled: false,
    savedFilters: [],
    addFilter: () => {},
    trackOnSaveFilter: () => {},
  };

  const displaySavedFilters = enabled && savedFilters.length > 0;

  function handleFilterReset(formValues, togglePopover) {
    togglePopover();
    onReset(formValues);
  }

  function handleFilterSubmit(formValues, togglePopover) {
    togglePopover();
    onSubmit(formValues);
  }

  function handleFilterBtnClick() {
    if (isFunction(onOpen)) {
      onOpen();
    }

    if (!onPreload || openedBefore) return;

    setOpenedBefore(true);
    onPreload();
  }

  function loadFilter(filter, togglePopover) {
    togglePopover();
    onSubmit(filter.value, filter.label);
  }

  function handleOnSaveFilter(filterName, filters) {
    try {
      addFilter(filterName, filters);
      trackOnSaveFilter({ filterName, filters });
      toast.success({
        title: 'Save Filter',
        description: `${filterName} was saved successfully!`,
      });
      hideModal();
      onSubmit(filters); // apply the filter to the table
    } catch (e) {
      toast.error({
        title: 'Save Filter',
        description: `Failed to save ${filterName} filter`,
      });
    }
  }

  return (
    <FiltersInputSizeProvider>
      <FiltersArea filtersSpacing={5}>
        <Popover data-testid="filtersArea">
          <PopoverTrigger>
            <Button
              tone="secondary"
              variant="outline"
              size="sm"
              IconBefore={IconV2OutlineFilters}
              onClick={handleFilterBtnClick}
            >
              Filter
            </Button>
          </PopoverTrigger>

          <PopoverBox
            $align="bottom-start"
            $width={large ? '664px' : theme.boxWidth}
            $noPadding
            data-testid="filtersContent"
          >
            <PopoverContext.Consumer>
              {({ togglePopover }) => {
                const selectFieldProps = {
                  maxHeight: '270px',
                };
                return (
                  <Formik
                    initialValues={formDefaultValues}
                    validationSchema={filterValidationSchema}
                    onSubmit={(formValue) => handleFilterSubmit(formValue, togglePopover)}
                    onReset={(formValue) => handleFilterReset(formValue, togglePopover)}
                  >
                    {({
                      handleSubmit,
                      handleReset,
                      values,
                      setFieldValue,
                      touched,
                      dirty,
                      isValid,
                      // isSubmitting
                    }) => (
                      <Box padding="md">
                        <FiltersContainer>
                          <form
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                            data-testid="table-filters-form"
                            id="formFilter"
                          >
                            <ThemeProvider theme={{ variant: 'outline', size: 'extra-small' }}>
                              {children({
                                selectFieldProps,
                                values,
                                setFieldValue,
                              })}
                              {/* </ThemeProvider> */}
                              <Stack direction="row" gap="3" mt={7} justifyContent="space-between">
                                <Button type="reset" variant="outline" size="sm">
                                  Reset
                                </Button>
                                {/* BUG/TODO later: Using isLoading prop (preventing actual click)
                            it breaks the related tests because in reality is still loading. Why?
                            This is how it was before. Note that the ButtonStale does not
                            disable interaction given isLoading, that's why it was working. if we added
                            disabled={isLoading} the test would break too. */}
                                {/* <ButtonStale type="submit" primary medium isLoading={isSubmitting}>
                            Apply
                          </ButtonStale> */}
                                <Button
                                  type="submit"
                                  size="sm"
                                  disabled={!isValid}
                                  // isLoading={isSubmitting}
                                  data-testid="apply-filters-button"
                                >
                                  Apply filters
                                </Button>
                              </Stack>
                              {displaySavedFilters && (
                                <Box
                                  mt={24}
                                  display="grid"
                                  gridTemplateColumns={large ? '1fr 1fr' : '1fr'}
                                >
                                  <Box>
                                    <Stack direction="row" color="lynch" mb={3}>
                                      <IconBookmark width="11px" />
                                      <Text ml={2} variant="xs">
                                        Saved filters
                                      </Text>
                                    </Stack>
                                    <FilterLoader
                                      onChange={(filter) => loadFilter(filter, togglePopover)}
                                    />
                                  </Box>
                                </Box>
                              )}
                            </ThemeProvider>
                          </form>
                        </FiltersContainer>
                        {/* enable only if the form is dirty and `applyFilter` is not set (to avoid displaying the ActionBar when loading a filter) */}
                        {enabled && dirty && touched.applyFilter === undefined && (
                          <>
                            <Separator />
                            <SaveFilterActionBar
                              onSave={() =>
                                showModal({
                                  component: SaveFilterModal,
                                  modalProps: {
                                    filters: values,
                                    onSave: handleOnSaveFilter,
                                    onClose: hideModal,
                                  },
                                })
                              }
                            />
                          </>
                        )}
                      </Box>
                    )}
                  </Formik>
                );
              }}
            </PopoverContext.Consumer>
          </PopoverBox>
        </Popover>
        {filtersApplied.map(({ label, resetFilter, hidden, id, Header }) => {
          if (hidden) {
            return null;
          }

          return (
            <Tooltip
              type="caption"
              label={isString(Header) ? Header : capitalize(startCase(id))}
              key={`${id}-${label}`}
            >
              <FilterButton onClick={resetFilter} data-testid="filterButton">
                <SROnly>Remove filter</SROnly>
                {isString(label) ? String(label) : label}
              </FilterButton>
            </Tooltip>
          );
        })}
      </FiltersArea>
    </FiltersInputSizeProvider>
  );
};

FiltersPopover.defaultProps = {
  theme: {},
};

FiltersPopover.propTypes = {
  /** Render function expecting a list of filter fields. It passes down an object:
   - selectFieldProps: Add it to each react-select field. It ensures the popover does not close when selecting a menu option.  */
  children: PropTypes.func,
  /** A list with current applied filters */
  filtersApplied: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
      resetFilter: PropTypes.func.isRequired,
      hidden: PropTypes.bool,
    })
  ),
  /** Initial values of those fields, to be passed down to Formik. */
  formDefaultValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.array])
  ),
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  onOpen: PropTypes.func,
  theme: PropTypes.shape({
    boxWidth: PropTypes.string,
  }),
  /** Should this be a larger version? For filters with columns */
  large: PropTypes.bool,
};

export default FiltersPopover;
