// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineRefreshCw = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M1.25 12C1.25 6.06 6.03 1.25 12 1.25a12.4 12.4 0 0 1 7.98 2.92c.5.4.91.82 1.27 1.2V3a.75.75 0 0 1 1.5 0v4.5c0 .41-.34.75-.75.75h-4.5a.75.75 0 0 1 0-1.5h2.99a12.7 12.7 0 0 0-1.47-1.42A10.9 10.9 0 0 0 12 2.75 9.23 9.23 0 0 0 2.75 12a.75.75 0 0 1-1.5 0ZM22 11.25c.41 0 .75.34.75.75 0 5.94-4.81 10.75-10.75 10.75-3.02 0-5.44-1.5-7.07-2.94-.45-.4-.84-.8-1.18-1.16V21a.75.75 0 0 1-1.5 0v-4.5c0-.41.34-.75.75-.75h4.5a.75.75 0 0 1 0 1.5H4.51c.37.43.84.93 1.41 1.44C7.42 20 9.5 21.25 12 21.25c5.1 0 9.25-4.14 9.25-9.25 0-.41.34-.75.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineRefreshCw);
export { ForwardRef as IconV2OutlineRefreshCw };