import { isFuture, isPast, parseISO } from 'date-fns';
import sortBy from 'lodash/sortBy';

import { ADMIN_PEOPLE_ROUTE, TEAM_ROUTE } from '@/src/constants/routes';
import { employmentType } from '@/src/domains/employment/constants';
import { contractorSubscriptionPlanIdentifiers } from '@/src/domains/paidContractorProduct/constants';
import {
  PRODUCT_PRICING_FREQUENCY,
  PRODUCT_TIER_STATUS,
  PRODUCT_TIERS,
  productEmploymentTypes,
} from '@/src/domains/pricing/constants';
import { canAddEmployeeToTeamPlan } from '@/src/domains/teamPlan/helpers';
import { friendlyKeyToLabel } from '@/src/helpers/general';
import { stringifyQuery } from '@/src/helpers/queryParser';

export function isProductEOR(product, options = {}) {
  const isEOR = product?.employmentType === productEmploymentTypes.EOR;
  const isFrequency = options?.frequency ? options?.frequency === product?.frequency : true;
  const isTier = options?.tier ? options?.tier === product?.tier : true;

  return isEOR && isFrequency && isTier;
}

export function isProductEORAnnual(product) {
  return product?.employmentType === productEmploymentTypes.EOR_ANNUAL_PLAN;
}

export function isProductTeamPlan(product) {
  return product?.employmentType === productEmploymentTypes.EOR_TEAM_PLAN;
}

export function isProductContractorStandard(product) {
  return product?.identifier === contractorSubscriptionPlanIdentifiers.STANDARD;
}

export function isProductContractorPlus(product) {
  return product?.identifier === contractorSubscriptionPlanIdentifiers.PLUS;
}

export function isProductContractorProtect(product) {
  return product?.identifier === contractorSubscriptionPlanIdentifiers.PROTECT;
}

export function isProductGlobalPayroll(product) {
  return (
    product?.employmentType === productEmploymentTypes.GLOBAL_PAYROLL &&
    product?.frequency === PRODUCT_PRICING_FREQUENCY.MONTHLY
  );
}

export function isProductRecruit(product) {
  return product?.employmentType === productEmploymentTypes.RECRUIT;
}

export function isPlusProductOrProductIntent(product, productIntents = []) {
  return (
    product?.identifier === contractorSubscriptionPlanIdentifiers.PLUS ||
    productIntents?.some(
      (intent) => intent.identifier === contractorSubscriptionPlanIdentifiers.PLUS
    )
  );
}

export function isContractorProtectProductOrProductIntent(product, productIntents = []) {
  return (
    product?.identifier === contractorSubscriptionPlanIdentifiers.PROTECT ||
    productIntents?.some(
      (intent) => intent.identifier === contractorSubscriptionPlanIdentifiers.PROTECT
    )
  );
}

export function getProductIntentOrProduct(product, productIntents = []) {
  return productIntents?.[0] || product;
}

/**
 *
 * @param {*} countryProductPrices (API data)
 * @returns
 * [
 *  {
 *   country,
 *   pricingList: [countryProductPrice]
 *  }
 * ]
 */
export function transformCountryProductPricesForTable(countryProductPrices) {
  // Sort by country name
  const sortedData = sortBy(
    countryProductPrices,
    (countryProductPrice) => countryProductPrice.country.name
  );

  // Transform data for table
  const groupedData = sortedData.reduce((output, countryProductPrice) => {
    const { country, product, productPrice } = countryProductPrice;
    const { code: countryCode } = country;
    const { slug: productSlug } = product;

    // Create country
    if (!output[countryCode]) {
      output[countryCode] = {
        pricingList: [],
        country,
      };
    }

    // Fill Country pricingList -> Product + Product price
    if (!output[countryCode].pricingList[productSlug]) {
      output[countryCode].pricingList.push({
        ...countryProductPrice,
        product,
        productPrice: {
          ...productPrice,
          prices: sortBy(productPrice.prices, (price) => price.currency.name),
        },
      });
    }

    return output;
  }, {});

  return Object.values(groupedData);
}

export function getPricingByProductType({ pricingList = [], type }) {
  switch (type) {
    case 'contractor_monthly':
      return pricingList.filter((countryProductPrice) => {
        return isProductContractorStandard(countryProductPrice?.product);
      });

    case 'eor_annually':
      return pricingList.filter((countryProductPrice) => {
        return isProductEOR(countryProductPrice?.product, {
          frequency: PRODUCT_PRICING_FREQUENCY.ANNUAL,
          tier: PRODUCT_TIERS.STANDARD,
        });
      });

    case 'eor_annual_plan':
      return pricingList.filter((countryProductPrice) => {
        return isProductEORAnnual(countryProductPrice?.product);
      });

    case 'eor_premium':
      return pricingList.filter((countryProductPrice) => {
        return isProductEOR(countryProductPrice?.product, {
          frequency: PRODUCT_PRICING_FREQUENCY.MONTHLY,
          tier: PRODUCT_TIERS.PREMIUM,
        });
      });

    case 'eor_monthly':
    default:
      return pricingList.filter((countryProductPrice) =>
        isProductEOR(countryProductPrice?.product, {
          frequency: PRODUCT_PRICING_FREQUENCY.MONTHLY,
          tier: PRODUCT_TIERS.STANDARD,
        })
      );
  }
}

export const friendlyEmploymentType = (params) =>
  employmentType === productEmploymentTypes.EOR
    ? params?.employmentType.toUpperCase()
    : friendlyKeyToLabel(params?.employmentType);

export function isEorPremiumAccepted(companyProductTiers = []) {
  const [eorCompanyProductTier] = companyProductTiers?.filter((product) =>
    isProductEOR(product, { tier: PRODUCT_TIERS.PREMIUM })
  );

  return eorCompanyProductTier?.status === PRODUCT_TIER_STATUS.ACCEPTED;
}

export function canAddEmployeeToPremiumEOR({ companyProductTiers = [], teamPlan, seat } = {}) {
  if (isEorPremiumAccepted(companyProductTiers)) {
    /**
     * We don't show the pricing step for customers on a tiered plan. However,
     * if a tiered plan customer has a TEAM plan, we'll try to fill up all the remaining seats.
     * */

    return !canAddEmployeeToTeamPlan({ teamPlan, seat });
  }

  return false;
}

export const getActiveEmploymentsForProductPath = ({ isUserAdmin, companySlug, productSlug }) => {
  if (isUserAdmin) {
    return `${ADMIN_PEOPLE_ROUTE}?${stringifyQuery({
      filters: [
        { id: 'company', value: companySlug },
        { id: 'type', value: [employmentType.FULL_TIME] },
        { id: 'status', value: 'active' },
        { id: 'product_slugs', value: [productSlug] },
      ],
    })}`;
  }

  return `${TEAM_ROUTE}?selectedTab=active`;
};

const hasTieredPlanEnded = (productTier) => {
  const endDate = parseISO(productTier?.endDate);
  return isPast(endDate);
};

export function adminGroupCompanyProductTierByActivity(companyProductTiers) {
  return {
    activeProducts: companyProductTiers.filter(
      (productTier) =>
        !hasTieredPlanEnded(productTier) && productTier.status !== PRODUCT_TIER_STATUS.ARCHIVED
    ),
    inactiveProducts: companyProductTiers.filter(
      (productTier) =>
        hasTieredPlanEnded(productTier) || productTier.status === PRODUCT_TIER_STATUS.ARCHIVED
    ),
  };
}

/**
 *
 * @param {array} companyProductTiers
 * @returns array
 */
export function getCompanyActivePremiumProductTiers(companyProductTiers = []) {
  return companyProductTiers.filter(
    (productTier) =>
      productTier?.tier === PRODUCT_TIERS.PREMIUM &&
      productTier?.status === PRODUCT_TIER_STATUS.ACCEPTED &&
      !hasTieredPlanEnded(productTier)
  );
}

export function employerGroupCompanyProductTierByActivity(companyProductTiers) {
  return {
    activeProducts: companyProductTiers?.filter(
      (productTier) =>
        !hasTieredPlanEnded(productTier) && productTier.status !== PRODUCT_TIER_STATUS.SHARED
    ),
    inactiveProducts: companyProductTiers?.filter((productTier) => hasTieredPlanEnded(productTier)),
  };
}

/**
 * Return a list of product types based on the employment.product.employmentType.
 * If no product has been set we fall back to the employment type.
 * @param {Object} employment
 * @param {Boolean} isNewPricingRevision
 * @returns {Array}
 */
export const getEmploymentProductTypes = (employment, isNewPricingRevision) => {
  const productEmploymentType = employment?.product?.employmentType;
  if (productEmploymentType === productEmploymentTypes.GLOBAL_PAYROLL) {
    return [productEmploymentTypes.GLOBAL_PAYROLL];
  }

  const EOR_CHECK = isNewPricingRevision
    ? productEmploymentType === productEmploymentTypes.EOR ||
      productEmploymentType === productEmploymentTypes.EOR_ANNUAL_PLAN
    : productEmploymentType === productEmploymentTypes.EOR ||
      productEmploymentType === productEmploymentTypes.EOR_TEAM_PLAN;

  const eorProductTypes = isNewPricingRevision
    ? [productEmploymentTypes.EOR, productEmploymentTypes.EOR_ANNUAL_PLAN]
    : [productEmploymentTypes.EOR, productEmploymentTypes.EOR_TEAM_PLAN];

  if (EOR_CHECK) {
    return eorProductTypes;
  }

  if (employment?.type === employmentType.FULL_TIME) {
    return [...eorProductTypes, productEmploymentTypes.GLOBAL_PAYROLL];
  }

  if (employment?.type === employmentType.CONTRACTOR) {
    return [productEmploymentTypes.CONTRACTOR];
  }

  return [];
};

export function getActiveTeamPlans(teamPlans = [], providedSlug) {
  const currentPlans = teamPlans.filter(
    ({ startDate }) => !isFuture(parseISO(`${startDate}T00:00:00Z`))
  );

  const isNotExpired = currentPlans.filter(({ endDate }) => {
    return !isPast(parseISO(endDate));
  });

  const validTeamPlans = isNotExpired.filter(
    ({ totalSeats, seatsInUse, slug }) => totalSeats - seatsInUse > 0 || providedSlug === slug
  );

  return { validTeamPlans, currentPlans };
}
