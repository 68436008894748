import { Box } from '@remote-com/norma';

import type { CardStatusMessageProps } from '@/src/components/Cards';
import { CardStatusMessage } from '@/src/components/Cards';

export function WidgetCardStatusMessage(props: CardStatusMessageProps) {
  return (
    <Box pt={4} pb={6}>
      <CardStatusMessage {...props} />
    </Box>
  );
}
