// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconFlag = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M3 3.54c0-.66.34-1.36 1.04-1.7C4.77 1.5 6.14 1 8 1c1.45 0 3.12.57 4.52 1.06l.24.08c.64.22 1.23.43 1.78.59.62.18 1.1.27 1.46.27.75 0 1.54-.17 2.2-.37 1.25-.39 2.8.47 2.8 1.99v8.97c0 .51-.2 1.12-.77 1.5-.57.38-1.65.91-3.23.91-.64 0-1.35-.16-2.02-.35-.6-.18-1.25-.4-1.88-.62l-.24-.09C11.38 14.43 10.05 14 9 14c-1.2 0-2.42.26-3.33.5-.22.07-.45.1-.67.08V22a1 1 0 1 1-2 0V3.54Zm2 .06A7.4 7.4 0 0 1 8 3c1.05 0 2.38.43 3.86.94l.24.09c.63.22 1.28.44 1.88.62.67.2 1.38.35 2.02.35 1.03 0 2.02-.23 2.79-.46a.2.2 0 0 1 .2.07H19v8.89c-.35.2-1.02.5-2 .5-.36 0-.84-.1-1.46-.27-.55-.16-1.14-.37-1.78-.6l-.24-.07C12.12 12.57 10.45 12 9 12c-1.45 0-2.87.3-3.86.58a.13.13 0 0 1-.14-.05V3.6Z" /></svg>;
const ForwardRef = forwardRef(IconFlag);
export { ForwardRef as IconFlag };