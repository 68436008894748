// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconEdit3 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M11 20a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2h-9a1 1 0 0 1-1-1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M18 3.88c-.3 0-.58.12-.8.33L4.9 16.5l-.53 2.12 2.12-.53 12.3-12.3A1.12 1.12 0 0 0 18 3.87Zm-2.2-1.09a3.12 3.12 0 0 1 4.4 4.42L7.7 19.7a1 1 0 0 1-.46.26l-4 1a1 1 0 0 1-1.21-1.21l1-4a1 1 0 0 1 .26-.47L15.8 2.8Z" /></svg>;
const ForwardRef = forwardRef(IconEdit3);
export { ForwardRef as IconEdit3 };