import type { ElementType } from 'react';
import type { DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { sharedTransition } from '../../core/animations';
import { Button } from '../../core/button';
import { Text } from '../../core/text';
import { Stack, Box } from '../../layout';

import type { Direction, RadioCardVariant, Size } from './constants';
import { RADIO_CARD_VARIANTS } from './constants';

export type ThemeProps = DefaultTheme & StyledThemeProps;

type StyledThemeProps = {
  $direction?: Direction;
  $ErrorComponent?: ElementType;
  $hasError?: boolean | string; // We need to add string as a type option here because of Formik's error type
  hasSuffix?: boolean;
  htmlFor?: string;
  isDisabled?: boolean;
  isExpanded?: boolean;
  size?: Size;
  variant?: RadioCardVariant;
};

const RadioState = styled.span<StyledThemeProps>`
  display: block;
  position: relative;
  background-color: ${({ theme }) => theme.colors.blank};
  border: 2px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 100%;
  height: 24px;
  width: 24px;
  ${sharedTransition('background, border-color')}
  transition-duration: 0.1s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    ${sharedTransition('background, border-color')}
    transition-duration: 0.1s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }

  input:checked ~ &:hover {
    background-color: ${({ theme }) => theme.colors.brand[50]};
  }

  input:checked ~ & {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  input:checked ~ &::before {
    background: ${({ theme }) => theme.colors.primary};
  }

  input:focus ~ & {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.grey[200]};
  }

  input:checked:focus ~ & {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.brand[50]};
  }
`;

const DecoratorSelector = styled.span``;

const textSizeMap: Record<
  string,
  (theme: { typography: DefaultTheme['typography'] }) => FlattenSimpleInterpolation
> = {
  sm: (theme) => theme.typography.smMedium,
  lg: (theme) => theme.typography.lgMedium,
};

// Do not use <label>, to support passing form inputs as child
export const Wrapper = styled(Stack).attrs(({ variant }: StyledThemeProps) => ({
  position: 'relative',
  bg: 'blank',
  gap: variant === RADIO_CARD_VARIANTS.CARD_EXPANDABLE ? 0 : 4,
}))<StyledThemeProps>`
  border-radius: ${({ theme }) => theme.space[4]}px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  flex-direction: ${({ hasSuffix, variant }) =>
    hasSuffix || variant === RADIO_CARD_VARIANTS.CARD_EXPANDABLE ? 'row' : 'row-reverse'};
  ${({ theme, size, variant }) => {
    switch (size) {
      case 'sm':
        return css`
          padding: 16px ${variant === RADIO_CARD_VARIANTS.CARD_EXPANDABLE ? 18 : theme.space[6]}px;
        `;
      case 'lg':
        return css`
          box-shadow: 0px ${theme.space[4]}px 60px ${theme.colors.grey[200]};
          padding: ${theme.space[6]}px ${theme.space[7]}px;
        `;
      default:
        return null;
    }
  }}

  ${({ $direction, variant }) => {
    if ($direction === 'row' && variant === RADIO_CARD_VARIANTS.CARD) {
      return css`
        align-items: flex-start;
      `;
    }
    return css`
      align-items: center;
    `;
  }}

  ${({ variant }) =>
    variant === RADIO_CARD_VARIANTS.CARD_SIMPLE &&
    css`
      flex-direction: column-reverse;

      ${RadioState} {
        /* Removes the Radio input */
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: ${({ theme }) => theme.colors.blank};
        /* Not removed via appearance */
        margin: 0;
        position: absolute;
        opacity: 0;
      }
    `}

  & + & {
    margin-top: ${({ theme, variant }) =>
      variant === RADIO_CARD_VARIANTS.CARD_EXPANDABLE ? theme.space[5] : theme.space[6]}px;
  }

  ${({ isDisabled, theme }) =>
    !isDisabled &&
    css`
      &:hover ${DecoratorSelector} {
        border-color: ${theme.colors.primary};
      }
    `}
`;

export const ContentWrapper = styled(Stack).attrs({
  gap: 2,
  flexGrow: 1,
  position: 'relative',
})<StyledThemeProps>`
  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      padding-top: ${theme.space[2]}px;
      padding-bottom: ${theme.space[2]}px;
    `}

  ${({ $direction }) =>
    $direction === 'column-reverse' &&
    css`
      flex-direction: column-reverse;
      gap: 0;
    `}
    ${({ variant }) =>
    variant === RADIO_CARD_VARIANTS.CARD_SIMPLE &&
    css`
      align-items: center;
      label {
        padding: 0;
      }
      span {
        white-space: nowrap;
      }
    `}
`;

export const Label = styled(Text).attrs({
  forwardedAs: 'label',
})<StyledThemeProps>`
  display: flex;
  align-items: center;
  ${({ theme, size }) => textSizeMap[size || 'sm'](theme)};
  ${({ theme, isDisabled }) => {
    if (isDisabled) {
      return css`
        line-height: 1;
        color: ${theme.colors.grey[600]};
      `;
    }
    return css`
      color: ${theme.colors.grey[900]};
    `;
  }}
  font-family: ${({ size }) =>
    size === 'lg' ? 'var(--typography-fontBrand)' : 'var(--typography-fontPrimary)'};
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      margin-left: 36px;
      margin-bottom: ${({ theme }) => theme.space[2]}px;
    `}
`;

export const Description = styled.span<StyledThemeProps>`
  ${({ theme, size }) => (size === 'sm' ? theme.typography.xs : theme.typography.sm)};
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const Suffix = styled.span`
  position: relative; /* to be above Decorator */
`;

export const Input = styled.input<{ as?: string }>`
  position: absolute;
  opacity: 0;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          z-index: auto;
        `
      : css`
          cursor: pointer;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
        `}
`;

export const RadioStateInCard = styled(RadioState)<StyledThemeProps>`
  position: relative;
  flex-shrink: 0;
  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      border-color: ${theme.colors.grey[400]};
      &:hover {
        background-color: transparent;
      }
    `}
  ${({ size }) =>
    size === 'sm' &&
    css`
      width: 24px;
      height: 24px;
    `}
    ${({ hasSuffix, theme, variant }) =>
    (variant === RADIO_CARD_VARIANTS.CARD_EXPANDABLE || hasSuffix) &&
    css`
      margin-right: ${theme.space[4]}px;
    `}
    ${({ theme, isExpanded }) =>
    isExpanded &&
    css`
      position: absolute;
      top: ${theme.space[5]}px;
      bottom: 0;
      left: 18px;
    `}
`;

export const Decorator = styled(DecoratorSelector)<StyledThemeProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.space[4]}px;
  ${sharedTransition('border-color')}

  ${({ size, theme, isDisabled }) => {
    if (isDisabled) {
      return css`
        background-color: ${theme.colors.grey[50]};
        border: 1px dashed ${theme.colors.grey[500]};
      `;
    }
    switch (size) {
      case 'sm':
        return css`
          border: 1px solid ${theme.colors.grey[300]};

          ${Input}:checked ~ & {
            background-color: ${theme.colors.brand[50]};
          }
        `;
      case 'lg':
        return css`
          border: 2px solid transparent;
        `;
      default:
        return null;
    }
  }}

  ${Input}:checked ~ & {
    border-color: ${({ theme }) => theme.colors.brand[600]};
  }

  ${Input}:focus-within ~ & {
    border-color: ${({ theme }) => theme.colors.brand[600]};
    ${({ theme }) =>
      css`
        box-shadow: 0px 0px 0px ${theme.space[2]}px ${theme.colors.brand[100]};
      `}
  }
`;

export const Fieldset = styled.fieldset<StyledThemeProps>`
  border: none;
  margin: 0;
  padding: 0;

  ${({ $direction }) =>
    $direction === 'row' &&
    css`
      display: flex;
      flex-wrap: wrap;

      ${Wrapper} {
        flex: 1;
      }
    `}

  ${({ $hasError, theme }) => {
    return (
      $hasError &&
      css`
        ${Input},
        ${Input}:focus-within {
          & ~ ${DecoratorSelector} {
            border-color: ${theme.colors.red[700]};
          }
        }
      `
    );
  }}
`;

export const RadioCardsRowContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 20px;

  /* This is to ensure that spans (like Tooltip wrappers) act as flex containers to make RadioCard components span full width and align properly */
  & > span {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }

  & > *:not(:first-of-type) {
    margin-top: 0;
  }
`;

export const IllustrationContainer = styled.span`
  align-items: center;
  display: flex;
  height: auto;
  width: auto;
  margin-right: ${({ theme }) => theme.space[2]}px;
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.breakpointValues.medium - 1}px) {
    display: none;
  }
`;

export const ToggleButton = styled(Button).attrs({
  variant: 'raw',
})`
  position: absolute;
  top: ${({ theme }) => theme.space[3]}px;
  right: ${({ theme }) => theme.space[5]}px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  &:focus {
    border-radius: 10px;
  }
  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
  }
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      color: ${({ theme }) => theme.colors.brand[600]};
    `}
`;
