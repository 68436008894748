import type { ReactElement } from 'react';

import { Text } from '../../../core/text';
import {
  getFileNameWithoutExtension,
  dangerouslyGetFilePreviewPageUrl,
} from '../../../foundations';
import { IconV2OutlinePaperclip } from '../../../icons/build/IconV2OutlinePaperclip';
import { Box, Stack } from '../../../layout';
import type {
  InputFileHandlers,
  InputFileModifiable,
  InputFileObject,
  InputFileUploadConfig,
} from '../helpers';
import { InputFileStyled } from '../styled';

import { InputFileListEntry } from './entry';

const { FileList: List, FileListEntry: ListEntry, FileListEntryIcon } = InputFileStyled;

type Props = InputFileHandlers & {
  fileObjects: InputFileObject[];
  singleFile: boolean;
  uploadConfig: InputFileUploadConfig;
  modifiable?: InputFileModifiable;
};

export function InputFileList(props: Props): ReactElement {
  /**
   * @deprecated This is actually quite bad.
   * It does absolutely nothing in practice,
   * as it gets a string but not returning anything.
   * However, it's actually created on every render.
   * We only keep it to avoid logic changes during Norma migration.
   * (And yes, the part about "creating on every render" is also kept)
   *
   * @todo Remove this. In fact, it's using a dangerous function,
   * so double bonus here.
   */
  const defaultOnFileClick: Props['onFileClick'] = ({ slug: fileSlug }) => {
    if (fileSlug) {
      dangerouslyGetFilePreviewPageUrl({ fileSlug });
    }
  };

  const {
    fileObjects = [],
    singleFile,
    uploadConfig,
    modifiable,
    onFileClick = defaultOnFileClick,
    onDeleteClick,
    onRename,
    onChangeDocumentType,
  } = props;

  if (fileObjects.length === 0) {
    return (
      <List>
        <ListEntry noInteractions data-testid="file-entry-no-file-added">
          <Stack
            px={3}
            direction="row"
            alignItems="center"
            gap="3"
            /**
             * This is wrong. It should be `mb={1}`, as it's a number.
             * However, it's kept to avoid changes during Norma migration.
             */
            mb={'1' as any}
          >
            <FileListEntryIcon>
              <IconV2OutlinePaperclip width="16px" />
            </FileListEntryIcon>
            <Box minHeight="30px" display="flex" alignItems="center">
              <Text color="grey.900">No file added</Text>
            </Box>
          </Stack>
        </ListEntry>
      </List>
    );
  }

  return (
    <List>
      {/* If we're in single file mode, we should only show one file at a time (when replacing one file with another, we get 2 files
        in the list for a brief second) */}
      {(singleFile ? fileObjects.reverse().slice(0, 1) : fileObjects).map((fileObject) => {
        const { name, newName, size: fileSize, documentType } = fileObject ?? {};
        // If the name is modifiable, we should show the name without the extension
        const fileName = modifiable ? getFileNameWithoutExtension(name) : name;

        return (
          <InputFileListEntry
            fileSize={fileSize}
            fileObject={fileObject}
            key={name}
            name={newName || fileName}
            documentType={documentType}
            onFileClick={onFileClick}
            onDeleteClick={onDeleteClick}
            onRename={onRename}
            onChangeDocumentType={onChangeDocumentType}
            uploadConfig={uploadConfig}
            modifiable={modifiable}
          />
        );
      })}
    </List>
  );
}
