export const MAR_WISE_BANK_CODES = [
  { value: 'ABBMMAMC', label: 'Al Barid Bank' },
  { value: 'ARABMAMC', label: 'Arab Bank Plc.' },
  { value: 'AIBSMAMT', label: 'Attijari International Bank S.A.' },
  { value: 'BCMAMAMC', label: 'Attijariwafa Bank' },
  { value: 'BCPOMAM10MS', label: 'BANQUE POPULAIRE OUARZAZATE' },
  { value: 'BSABMAMC', label: 'Banco De Sabadell, S.A.' },
  { value: 'BKAMMAMR', label: 'Bank Al-Maghrib' },
  { value: 'BCPOMAMCYSR', label: 'Banque Centrale Populaire - Casablanca' },
  { value: 'BCPOMAMC', label: 'Banque Centrale Populaire' },
  { value: 'BMCEMAMC', label: 'Banque Marocaine Du Commerce Exterieur' },
  { value: 'BMCIMAMC', label: "Banque Marocaine Pour Le Commerce Et L'Industrie" },
  { value: 'BCPOMAMCCES', label: 'Banque Populaire Agadir' },
  { value: 'BCPOMAMCALH', label: 'Banque Populaire Al Hoceima' },
  { value: 'BCPOMAMCBNM', label: 'Banque Populaire Beni Mellal' },
  { value: 'BCPOMAMCBRK', label: 'Banque Populaire Berkane' },
  { value: 'BCPOMAMCCAS', label: 'Banque Populaire Casa-Anfa' },
  { value: 'BCPOMAMCELJ', label: 'Banque Populaire El Jadida' },
  { value: 'BCPOMAMCFES', label: 'Banque Populaire Fes' },
  { value: 'BCPOMAMCGHA', label: 'Banque Populaire Gharb' },
  { value: 'BCPOMAMCLAY', label: 'Banque Populaire Laayoune' },
  { value: 'BCPOMAMCMKH', label: 'Banque Populaire Marrakech' },
  { value: 'BCPOMAMCMEK', label: 'Banque Populaire Meknes' },
  { value: 'BCPOMAMCNAD', label: 'Banque Populaire Nador' },
  { value: 'BCPOMAMCOUJ', label: 'Banque Populaire Oujda' },
  { value: 'BCPOMAMCRAB', label: 'Banque Populaire Rabat' },
  { value: 'BCPOMAMCSAF', label: 'Banque Populaire Safi' },
  { value: 'BCPOMAMCTAT', label: 'Banque Populaire Tanger' },
  { value: 'BCPOMAM10MV', label: 'Banque Populaire Taza' },
  { value: 'BCPOMAM10MW', label: 'Banque Populaire Tetouan' },
  { value: 'BCPOMAM10MY', label: 'Banque Populaire Tinznit' },
  { value: 'CAFGMAMCXXX', label: 'CFG Bank' },
  { value: 'CADGMAMR', label: 'Caisse De Depot Et De Gestion' },
  { value: 'CAFGMAMC', label: 'Casablanca Finance Markets' },
  { value: 'RCDGMAMC', label: 'Cdg Capital' },
  { value: 'CITIMAMC', label: 'Citibank Maghreb' },
  { value: 'CNCAMAMR', label: 'Credit Agricole Du Maroc' },
  { value: 'CDMAMAMC', label: 'Credit Du Maroc' },
  { value: 'CIHMMAMC', label: 'Credit Immobilier Et Hotelier' },
  { value: 'GOCPMAMC', label: 'Groupe Ocp' },
  { value: 'CAIXMAMC', label: 'La Caixa, Succursale Maroc' },
  { value: 'MEDCMAMC', label: 'Mediafinance' },
  { value: 'SGMBMAMC', label: 'Societe Generale Marocaine De Banques' },
  { value: 'SGTGMAMC', label: 'Societe Generale Tanger Offshore' },
  { value: 'UMABMAMC', label: 'Union Marocaine De Banques' },
];
