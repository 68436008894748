// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconHelpCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12a11 11 0 1 1 22 0 11 11 0 0 1-22 0Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12.26 8.02a2 2 0 0 0-2.23 1.31 1 1 0 0 1-1.88-.66A4 4 0 0 1 15.92 10c0 1.53-1.13 2.54-1.95 3.08a8.04 8.04 0 0 1-1.68.85l-.04.01h-.01l-.32-.94.32.95a1 1 0 0 1-.64-1.9h.02a3.87 3.87 0 0 0 .36-.15c.25-.11.57-.27.89-.48.69-.46 1.05-.95 1.05-1.42a2 2 0 0 0-1.66-1.98Z" /><path fillRule="evenodd" clipRule="evenodd" d="M11 17a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z" /></svg>;
const ForwardRef = forwardRef(IconHelpCircle);
export { ForwardRef as IconHelpCircle };