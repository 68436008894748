import { themeV2 } from '@remote-com/norma';
import chunk from 'lodash/chunk';

import type { ReportsApi } from '@/src/api/config/employ/reports.types';

import type { ChartLegendProps } from '../ChartLegend/ChartLegend';

type GroupLabel = '1-1' | '2-4' | '5-7' | '>8';

export function calculateBarThickness(departments: string[] | undefined) {
  const DEFAULT_BAR_THICKNESS = 18;

  if (!departments || departments.length === 0) return DEFAULT_BAR_THICKNESS;

  if (departments.length <= 6) return 26;
  if (departments.length <= 15) return 21;
  return DEFAULT_BAR_THICKNESS;
}

const datasetGroupColorLabels = {
  '1-1': themeV2.colors.brand[800],
  '2-4': themeV2.colors.brand[600],
  '5-7': themeV2.colors.brand[500],
  '>8': themeV2.colors.brand[300],
};

export function createPaginatedData(data: ReportsApi.SpanOfControlData) {
  return {
    departmentsPaginated: chunk(data?.departments, 10),
    managerGroupsPaginated: chunk(data?.managerGroups, 10),
  };
}

export function createDataSets(data: ReportsApi.SpanOfControlData, currentPage: number) {
  const { departmentsPaginated, managerGroupsPaginated } = createPaginatedData(data);

  const departments =
    (departmentsPaginated[currentPage] as { name: string }[])?.map((d) => d.name) || [];
  const dataSets = (Object.keys(datasetGroupColorLabels) as GroupLabel[])?.map(
    (group: GroupLabel) => {
      return {
        label: group,
        borderRadius: 8,
        backgroundColor: datasetGroupColorLabels[group],
        data: managerGroupsPaginated[currentPage]?.map((managerGroup: any) => {
          const groupData = managerGroup.groups.find((g: any) => g.label === group);
          return groupData ? groupData.value : 0;
        }),
      };
    }
  );

  return {
    departments,
    dataSets: dataSets || [],
    totalPages: Math.ceil(data.departments.length / 10),
  };
}

export const legendItems = Object.entries(datasetGroupColorLabels).map(([key, value]) => {
  return {
    color: value,
    label: key,
  };
}) as unknown as ChartLegendProps['items'];

// stolen from TableFooter.tsx
export function getDepartmentsCountCopy({
  pageIndex,
  totalRecords,
}: {
  pageIndex: number;
  totalRecords: number;
}) {
  const pageSize = 10;
  const recordsStartIndex = pageIndex * pageSize + 1;
  const recordsEndIndex = (pageIndex + 1) * pageSize;

  return `${recordsStartIndex}-${Math.min(
    recordsEndIndex,
    totalRecords || 0
  )} of ${totalRecords} departments`;
}
