// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineAlertTriangle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M13.75 3.36a4.25 4.25 0 0 0-3.5 0c-.56.25-1.12.79-1.86 1.86a65.8 65.8 0 0 0-2.72 4.61l-.35.63A63.2 63.2 0 0 0 2.91 15c-.5 1.15-.67 1.88-.6 2.48a4.25 4.25 0 0 0 1.74 2.97c.48.35 1.2.57 2.45.69 1.25.1 2.89.11 5.15.11h.7c2.26 0 3.9 0 5.15-.11 1.24-.12 1.97-.34 2.45-.7.97-.7 1.6-1.77 1.74-2.96.07-.6-.1-1.33-.6-2.48a63.24 63.24 0 0 0-2.41-4.54l-.35-.63a65.83 65.83 0 0 0-2.72-4.6c-.74-1.08-1.3-1.62-1.86-1.87ZM9.63 1.99a5.75 5.75 0 0 1 4.74 0c.94.43 1.7 1.25 2.47 2.38a66.24 66.24 0 0 1 2.77 4.7l.4.69c1.07 1.93 1.91 3.42 2.45 4.63.54 1.22.84 2.26.72 3.26a5.75 5.75 0 0 1-2.35 4.02c-.82.59-1.87.84-3.2.96-1.32.12-3.03.12-5.24.12h-.78c-2.21 0-3.92 0-5.24-.12a6.32 6.32 0 0 1-3.2-.96 5.75 5.75 0 0 1-2.35-4.02c-.12-1 .18-2.04.72-3.26.54-1.2 1.38-2.7 2.45-4.63l.4-.7c1.12-2 1.99-3.56 2.77-4.69C7.93 3.24 8.69 2.42 9.63 2ZM12 8.25c.41 0 .75.34.75.75v4a.75.75 0 0 1-1.5 0V9c0-.41.34-.75.75-.75Zm-1.25 8.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineAlertTriangle);
export { ForwardRef as IconV2OutlineAlertTriangle };