// Add data types to window.navigator ambiently for implicit use in the entire project. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />

import { useGet } from '@remote-com/data-layer';
import { getRandomColorForInitialsImage } from '@remote-com/norma';
import type { ReactNode } from 'react';
import { useContext, useState } from 'react';

import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import { transformPermissions } from '@/src/domains/account/helpers';
import { setDOMUserInfo } from '@/src/domains/registration/auth/helpers/userData';
import { fetchUserCache } from '@/src/domains/userCache/services';
import { isUserOnMacOS } from '@/src/helpers/general';
import { useCustomMutation } from '@/src/hooks/useCustomMutation';
import { transformLoginsResponse } from '@/src/services/User';
import type { $TSFixMe } from '@/types';

import type { Login, UserContextUser } from './context';
import UserContext from './context';

export type UserAccountResponse = UserAccount;

type TUserProvider = {
  user?: UserContextUser;
  children: ReactNode;
};

const UserProvider = ({ user, children }: TUserProvider) => {
  const [currentUser, setCurrentUser] = useState<UserContextUser | undefined>(user);
  const [loginMethods, setLoginMethods] = useState<Login | undefined>();
  const [timeToSignOutRemaining, setTimeToSignOutRemaining] = useState<number | undefined>();
  const [channelReference, setChannelReference] = useState();
  const [userAuthData, setUserAuthenticationData] = useState<{ email: string; password: string }>();

  const { refetch } = useGet('/api/v1/account', {
    options: {
      enabled: false,
      select: transformPermissions, // permissions data must be formatted to snake_case as done in /services/User#getUser
      onSuccess: async (userData) => {
        const initialsColor = getRandomColorForInitialsImage(userData.data.name);
        const isOnMacOS = isUserOnMacOS();
        const userCache = await fetchUserCache(userData.data);
        setCurrentUser({
          ...userData.data,
          userCache,
          isOnMacOS,
          initialsColor,
        });
      },
    },
  });

  const { refetch: refetchLogins } = useGet('/api/v1/account/logins', {
    options: {
      enabled: !!user,
      select: transformLoginsResponse,
      onSuccess: async (data) => {
        setLoginMethods(data);
        const stableUser = currentUser || user;
        const isOnMacOS = isUserOnMacOS();
        const userCache = await fetchUserCache(stableUser);
        if (stableUser) {
          setCurrentUser({
            ...stableUser,
            userCache,
            isOnMacOS,
          });
        }
      },
    },
  });

  async function refreshUser() {
    try {
      await refetchLogins();
      // eslint-disable-next-line no-empty
    } catch {}
    try {
      const userData = await refetch();
      const { userCache, ...restUser } = userData.data.data || {};
      /**
       * THIS FUNCTION IS EVIL AND IT SHOULD BE REMOVED WHEN EMPLOY IS MIGRATED TO AN SPA.
       * This function can't be called inside the onSuccess callback because it would break the E2E freelancer/selfSignUp.spec.ts, with the error: "Error: Loading initial props cancelled"
       */
      setDOMUserInfo({ ...restUser });
      return userData.data.data;
      // eslint-disable-next-line no-empty
    } catch {}
  }

  function setInactivityTimeRemaining(time: number) {
    setTimeToSignOutRemaining(time);
  }

  function setChannelRef(ch: any) {
    setChannelReference(ch);
  }

  function setUserAuthData({ email, password }: { email: string; password: string }) {
    setUserAuthenticationData({ email, password });
  }

  const value = {
    user: currentUser,
    loginMethods,
    refreshUser,
    setInactivityTimeRemaining,
    timeToSignOutRemaining,
    setChannelRef,
    channelReference,
    userAuthData,
    setUserAuthData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);

/**
 * Performs a mutation an update user on success, both in React.context and in the DOM
 * @param {Function} mutationFn function that performs an asynchronous task and returns a promise.
 * @param {*} options useMutation options
 * @returns
 */
export function useMutationAndUpdateUser(
  mutationFn: () => Promise<any>,
  options: { onSuccess?: (data: $TSFixMe) => void; onError?: (err: $TSFixMe) => void } = {}
) {
  const { refreshUser } = useUserContext();
  return useCustomMutation(mutationFn, {
    ...options,
    onSuccess: async (data: $TSFixMe) => {
      try {
        await refreshUser?.();
        options.onSuccess?.(data);
      } catch (err) {
        options?.onError?.(err);
      }
    },
  });
}

export default UserProvider;
