import type { GetResponse } from '@remote-com/data-layer';
import { Stack, themeV2 } from '@remote-com/norma';
import type { StackProps } from '@remote-com/norma';
import { IconV2DuotoneGraph } from '@remote-com/norma/icons/IconV2DuotoneGraph';
import { IconV2DuotoneLayers } from '@remote-com/norma/icons/IconV2DuotoneLayers';
import { IconV2DuotonePercentage } from '@remote-com/norma/icons/IconV2DuotonePercentage';
import { IconV2SolidArrowUp } from '@remote-com/norma/icons/IconV2SolidArrowUp';
import styled from 'styled-components';

import { useBrowserContext } from '@/src/components/BrowserContext';
import RemoteLogoLoader from '@/src/components/Ui/Loader/RemoteLoader';
import { useUser } from '@/src/components/UserProvider/context';
import { WidgetCard } from '@/src/components/WidgetCard';
import { ActivitySection } from '@/src/domains/reports/components/ReportWidgets/ActivitySection';
import SegmentedBar from '@/src/domains/reports/components/ReportWidgets/SegmentedBar';
import type { CardblockInfoProps } from '@/src/domains/reports/components/ReportWidgets/types';
import WidgetCardBlock from '@/src/domains/reports/components/ReportWidgets/WidgetCardBlock';
import { getScopedName } from '@/src/domains/reports/constants';
import { getScopesFromRoles } from '@/src/domains/reports/helpers';

const Wrapper = styled(Stack).attrs(() => ({
  gap: 4,
  borderTop: '1px solid',
  borderColor: 'grey.200',
}))<StackProps>``;

type OrganizationalStructureWidgetProps = {
  data: GetResponse<'/api/v1/reports/people/[slug]'>['data'];
  isLoading: boolean;
};

export default function OrganizationalStructureWidget({
  data,
  isLoading,
}: OrganizationalStructureWidgetProps) {
  const { isScreenMD } = useBrowserContext();
  const { user } = useUser();
  const userDataScopes = getScopesFromRoles(user?.assignedRoles);

  if (isLoading)
    return (
      <WidgetCard title="">
        <RemoteLogoLoader $space="xl" />
      </WidgetCard>
    );

  if (!data || !data.summary || !data.metrics) {
    return null;
  }

  const title =
    getScopedName({
      slug: 'organizational-structure',
      scopes: userDataScopes,
      type: 'widgets',
    }) || data.title;

  const {
    summary: { managers, nonManagers },
    metrics: { managerEmployeeRatio, spanOfControl, organizationalLayers, directReports } = {},
  } = data;

  const cardBlockInfo: CardblockInfoProps = [
    {
      label: managerEmployeeRatio?.label,
      icon: IconV2DuotonePercentage,
      tone: 'blueLight',
      count: managerEmployeeRatio?.value,
      tooltips: [
        {
          title: 'What is Manager-to-Employee Ratio?',
          body: 'The number of employees (including contractors) for every manager within your company.',
        },
        {
          title: 'How is the Manager-to-Employee Ratio calculated?',
          body: 'The number of managers (employees who have at least one person reporting to them) divided by the number of non-manager employees.',
        },
      ],
    },
    {
      label: spanOfControl?.label,
      icon: IconV2DuotoneGraph,
      tone: 'blueLight',
      count: spanOfControl?.value,
      tooltips: [
        {
          title: 'What is average span of control?',
          body: 'The average number of direct reports per manager.',
        },
        {
          title: 'How is the average span of control calculated?',
          body: 'The total number of direct report relationships divided by the number of managers.',
        },
      ],
    },
    {
      label: organizationalLayers?.label,
      icon: IconV2DuotoneLayers,
      tone: 'blueLight',
      count: organizationalLayers?.value,
      tooltips: [
        {
          title: 'What are Organizational layers?',
          body: 'The number of management levels in the company.',
        },
      ],
    },
    {
      label: directReports?.label,
      icon: IconV2SolidArrowUp,
      tone: 'blueLight',
      count: directReports?.value,
      user: directReports?.topManager,
    },
  ];

  const totalStaff = managers.value + nonManagers.value;
  const activitySectionLabel = !isScreenMD ? 'total' : null;

  return (
    <WidgetCard title={title}>
      <Stack gap={4} pb={6}>
        <Stack direction="row" gap={10}>
          <ActivitySection
            title={managers?.label}
            count={managers?.value}
            label={activitySectionLabel}
            titleColor="blueLight.500"
          />
          <ActivitySection
            title={nonManagers?.label}
            count={nonManagers?.value}
            label={activitySectionLabel}
            titleColor="purple.600"
          />
        </Stack>
        <SegmentedBar
          value={managers?.value}
          max={totalStaff}
          trackColor={themeV2.colors.purple[600]}
          valueColor={themeV2.colors.blueLight[500]}
        />
      </Stack>
      <Wrapper pt={6}>
        {cardBlockInfo.map(({ label, icon, count, tooltips, tone, user: userBlock }) => (
          <WidgetCardBlock
            key={label}
            icon={icon}
            label={label}
            count={count}
            tooltips={tooltips}
            tone={tone}
            user={userBlock}
          />
        ))}
      </Wrapper>
    </WidgetCard>
  );
}
