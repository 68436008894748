import { Box, Stack, Text } from '@remote-com/norma';
import { IconV2OutlineChevronLeft } from '@remote-com/norma/icons/IconV2OutlineChevronLeft';
import { IconV2OutlineChevronRight } from '@remote-com/norma/icons/IconV2OutlineChevronRight';
import { IconV2OutlineChevronsLeft } from '@remote-com/norma/icons/IconV2OutlineChevronsLeft';
import { IconV2OutlineChevronsRight } from '@remote-com/norma/icons/IconV2OutlineChevronsRight';
import range from 'lodash/range';
import React, { Fragment, useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { PAGINATION_RANGE_LIMIT } from '@/src/components/Table/TableFooter/constants';
import { TableFooterButton } from '@/src/components/Table/TableFooter/TableFooterButton';
import { TableFooterSelect } from '@/src/components/Table/TableFooter/TableFooterSelect';

const StyledRecordsCount = styled(Text).attrs({ color: 'grey.600', variant: 'xs' })``;

type TableFooterProps = {
  /**
   * Specifies whether or not the table footer will stick to the bootom of the viewport
   */
  isSticky?: boolean;
  /**
   * Specifies whether or not page size is supported
   */
  hasPageSizeSupport?: boolean;
  /**
   * Will be executed when changing the page index
   * @param updatedPageIndex The updated page index
   */
  onPageChange: (updatedPageIndex: number) => void;
  /**
   * Will be executed when changing the page size
   * @param updatedPageSize The updated page size
   */
  onPageSizeChange: (updatedPageSize: number) => void;
  /**
   * The current page index
   */
  pageIndex: number;
  /**
   * The number of entries on one page
   */
  pageSize: number;
  /**
   * The available options for the page size selector
   */
  pageSizeOptions: number[];
  /**
   * The total amount of pages
   */
  totalPages: number;
  /**
   * The total amount of records across all pages
   */
  totalRecords: number;
  /**
   * Controls the loading state
   */
  isLoading?: boolean;
  /**
   * Adds custom footer content before the pagination
   */
  customContent?: React.ReactNode;
};

type RecordsCountProps = Pick<
  TableFooterProps,
  'pageIndex' | 'pageSize' | 'totalPages' | 'totalRecords'
>;

function RecordsCount({ pageIndex, pageSize, totalPages, totalRecords }: RecordsCountProps) {
  const recordsStartIndex = pageIndex * pageSize + 1;
  const recordsEndIndex = (pageIndex + 1) * pageSize;

  const getRecordsCountCopy = () => {
    if (totalPages === 1) {
      if (totalRecords === 1) {
        return `${totalRecords} record`;
      }

      return `${totalRecords} records`;
    }

    return `${recordsStartIndex}-${Math.min(
      recordsEndIndex,
      totalRecords || 0
    )} of ${totalRecords} records`;
  };

  return (
    <StyledRecordsCount data-testid="table-footer-records-count">
      {getRecordsCountCopy()}
    </StyledRecordsCount>
  );
}

const PaginationWrapper = styled(Stack).attrs({
  alignItems: 'center',
  direction: 'row',
  gap: 2,
})``;

type PaginationProps = { disabled?: boolean } & Pick<
  TableFooterProps,
  'onPageChange' | 'pageIndex' | 'totalPages'
>;

export function Pagination({
  disabled = false,
  pageIndex,
  onPageChange,
  totalPages,
}: PaginationProps) {
  const isFirstPage = pageIndex === 0;
  const isLastPage = pageIndex === totalPages - 1;

  const handleFirstPageClick = () => {
    if (!isFirstPage) onPageChange(0);
  };

  const handlePreviousPageClick = () => {
    if (!isFirstPage) onPageChange(pageIndex - 1);
  };

  const handleNextPageClick = () => {
    if (!isLastPage) onPageChange(pageIndex + 1);
  };

  const handleLastPageClick = () => {
    if (!isLastPage) onPageChange(totalPages - 1);
  };

  const handleSelectPage = (value: string) => onPageChange(Number(value));

  const paginationRange = range(
    Math.max(pageIndex - PAGINATION_RANGE_LIMIT, 0),
    Math.min(pageIndex + PAGINATION_RANGE_LIMIT + 1, totalPages)
  );

  return (
    <PaginationWrapper data-testid="table-footer-pagination">
      <TableFooterButton
        disabled={disabled || isFirstPage}
        icon={IconV2OutlineChevronsLeft}
        label="First page"
        onClick={handleFirstPageClick}
        data-testid="table-footer-pagination-first-page-button"
      />
      <TableFooterButton
        disabled={disabled || isFirstPage}
        icon={IconV2OutlineChevronLeft}
        label="Previous page"
        onClick={handlePreviousPageClick}
        data-testid="table-footer-pagination-previous-page-button"
      />
      <TableFooterSelect
        disabled={disabled}
        hideLabel
        label="Selected page"
        value={pageIndex.toString()}
        onValueChange={handleSelectPage}
        data-testid="table-footer-pagination-page-select"
      >
        {paginationRange.map((paginationIndex) => (
          <TableFooterSelect.Item
            key={paginationIndex}
            value={paginationIndex.toString()}
            label={(paginationIndex + 1).toString()}
          />
        ))}
      </TableFooterSelect>
      <TableFooterButton
        disabled={disabled || isLastPage}
        icon={IconV2OutlineChevronRight}
        label="Next page"
        onClick={handleNextPageClick}
        data-testid="table-footer-pagination-next-page-button"
      />
      <TableFooterButton
        disabled={disabled || isLastPage}
        icon={IconV2OutlineChevronsRight}
        label="Last page"
        onClick={handleLastPageClick}
        data-testid="table-footer-pagination-last-page-button"
      />
    </PaginationWrapper>
  );
}

type PageSizeSelectorProps = {
  disabled?: boolean;
} & Pick<TableFooterProps, 'onPageSizeChange' | 'pageSize' | 'pageSizeOptions'>;

function PageSizeSelector({
  disabled,
  pageSize,
  onPageSizeChange,
  pageSizeOptions,
}: PageSizeSelectorProps) {
  /**
   * When a page has less rows than the desired page size, we might receive
   * the total number of rows instead of the page size value.
   */
  const pageSizeValue =
    pageSizeOptions.find((pageSizeOption) => pageSizeOption >= pageSize) || pageSizeOptions[0];

  return (
    <TableFooterSelect
      label="Rows"
      value={pageSizeValue?.toString()}
      onValueChange={(value) => onPageSizeChange(Number(value))}
      data-testid="table-footer-page-size-select"
      disabled={disabled}
    >
      {pageSizeOptions.map((pageSizeOption, index) => {
        const pageSizeOptionString = pageSizeOption.toString();
        return (
          <TableFooterSelect.Item
            key={index}
            value={pageSizeOptionString}
            label={pageSizeOptionString}
          />
        );
      })}
    </TableFooterSelect>
  );
}

export const StyledTableFooterStickyWrapper = styled(Box)`
  bottom: 0;
  position: sticky;
  z-index: 10;
`;

const StyledTableFooterStickyWrapperBody = styled(Box)`
  overflow: hidden;
  &[data-sticky] {
    /*
     We don't want the table footer to have rounded corners when sticky.
     To avoid defining border-radii everywhere (because the table can't have overflow: hidden),
     we're setting a background color here.
    */
    background-color: ${({ theme }) => theme.colors.blank};
  }
`;

type TableFooterStickyWrapper = PropsWithChildren<{}>;

export function TableFooterStickyWrapper(props: TableFooterStickyWrapper) {
  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: '0px 0px -1px',
  });

  return (
    <StyledTableFooterStickyWrapper ref={ref} data-testid="table-footer-sticky-wrapper">
      <StyledTableFooterStickyWrapperBody data-sticky={!inView || undefined} {...props} />
    </StyledTableFooterStickyWrapper>
  );
}

export const StyledTableFooter = styled(Stack).attrs({
  alignItems: 'center',
  backgroundColor: 'blank',
  direction: 'row',
  gap: 4,
  px: 6,
})`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[200]};
  height: 48px;
  justify-content: flex-end;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const StyledTableFooterRecordsWrapper = styled(Box).attrs({ mr: 'auto' })``;

export function TableFooter({
  isLoading,
  isSticky = true,
  hasPageSizeSupport = true,
  onPageChange,
  onPageSizeChange,
  pageIndex,
  pageSize,
  pageSizeOptions,
  totalPages,
  totalRecords,
  customContent,
}: TableFooterProps) {
  const [internalTableFooterState, setInternalTableFooterState] = useState<
    Pick<
      TableFooterProps,
      | 'hasPageSizeSupport'
      | 'pageIndex'
      | 'pageSize'
      | 'pageSizeOptions'
      | 'totalPages'
      | 'totalRecords'
      | 'customContent'
    >
  >({
    hasPageSizeSupport,
    pageIndex,
    pageSize,
    pageSizeOptions,
    totalPages,
    totalRecords,
    customContent,
  });

  useEffect(() => {
    /**
     * We use internal state instead of rendering props directly in order to keep showing the footer
     * between table loads. While the table is loading, the passed props would cause the footer not to render.
     * */
    if (!isLoading) {
      setInternalTableFooterState({
        hasPageSizeSupport,
        pageIndex,
        pageSize,
        pageSizeOptions,
        totalPages,
        totalRecords,
        customContent,
      });
    }
  }, [
    isLoading,
    hasPageSizeSupport,
    pageIndex,
    pageSize,
    pageSizeOptions,
    totalPages,
    totalRecords,
    customContent,
  ]);

  /**
   * We can't reliably calculate the records count without the total records count
   */
  const showRecordsCount = !!internalTableFooterState.totalRecords;
  const showcustomContent = !!internalTableFooterState.customContent;
  const showPagination = internalTableFooterState.totalPages >= 2;
  const showPageSizeSelector = internalTableFooterState.hasPageSizeSupport && showRecordsCount;

  /**
   * Some tables currently don't support any of the table footer features.
   * In those cases we still want to display the footer but it shouldn't be sticky.
   */
  const hasTableFooterSupport =
    showRecordsCount || showcustomContent || showPagination || showPageSizeSelector;

  const WrapperComponent = isSticky && hasTableFooterSupport ? TableFooterStickyWrapper : Fragment;

  return (
    <WrapperComponent>
      <StyledTableFooter>
        {showRecordsCount && (
          <StyledTableFooterRecordsWrapper>
            <RecordsCount
              pageIndex={internalTableFooterState.pageIndex}
              pageSize={internalTableFooterState.pageSize}
              totalPages={internalTableFooterState.totalPages}
              totalRecords={internalTableFooterState.totalRecords}
            />
          </StyledTableFooterRecordsWrapper>
        )}
        {showcustomContent && customContent}
        {showPagination && (
          <Pagination
            pageIndex={internalTableFooterState.pageIndex}
            onPageChange={onPageChange}
            totalPages={internalTableFooterState.totalPages}
            disabled={isLoading}
          />
        )}
        {showPageSizeSelector && (
          <PageSizeSelector
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            pageSizeOptions={pageSizeOptions}
            disabled={isLoading}
          />
        )}
      </StyledTableFooter>
    </WrapperComponent>
  );
}
