import { IconV2OutlineTrash } from '@remote-com/norma/icons/IconV2OutlineTrash';

import {
  ConfigurationListItem,
  ConfigurationListItemActions,
  ConfigurationListItemButton,
  ConfigurationListItemName,
  ConfigurationListItemVisibilityToggle,
} from '@/src/components/Table/Components/ColumnsConfigurationDrawer/ConfigurationList';
import type { AnyColumnsConfiguration } from '@/src/components/Table/hooks/useColumnsState/types';

const messages = {
  deleteConfiguration: 'Delete',
};

type SavedConfigurationsListItemProps = {
  /** The currently active configuration name, or null if none is active. */
  activeConfiguration: string | null;
  /** The columns configuration. */
  configuration: AnyColumnsConfiguration;
  /** Function to call when deleting the configuration. */
  onDeleteConfiguration: (configurationName: string) => void;
  /** Function to call when toggling a configuration. */
  onToggleConfiguration: (configuration: AnyColumnsConfiguration) => void;
};

export const SavedConfigurationsListItem = ({
  activeConfiguration,
  configuration,
  onDeleteConfiguration,
  onToggleConfiguration,
}: SavedConfigurationsListItemProps) => {
  const configurationName = configuration.name;

  const isActive = configurationName === activeConfiguration;

  const handleToggleVisibility = () => onToggleConfiguration(configuration);

  const handleDeleteConfiguration = () => onDeleteConfiguration(configurationName);

  return (
    <ConfigurationListItem data-testid="saved-table-item" isActive={isActive}>
      <ConfigurationListItemName>{configurationName}</ConfigurationListItemName>
      <ConfigurationListItemActions>
        <ConfigurationListItemVisibilityToggle
          data-testid="toggle-saved-table-item"
          isActive={isActive}
          onClick={handleToggleVisibility}
        />
        <ConfigurationListItemButton
          data-testid="delete-saved-table-item"
          icon={IconV2OutlineTrash}
          label={messages.deleteConfiguration}
          onClick={handleDeleteConfiguration}
          tone="destructive"
        />
      </ConfigurationListItemActions>
    </ConfigurationListItem>
  );
};
