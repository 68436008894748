import { Box } from '@remote-com/norma';
import styled from 'styled-components';

export const FiltersContainer = styled(Box)`
  box-sizing: border-box;
  padding: ${({ theme }) => theme.space[4]}px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: ${({ theme }) => theme.space[7]}px;
  }
`;
