import { sharedTransition, Tooltip } from '@remote-com/norma';
import type { ElementType, MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@/src/components/Button';

export const TableFooterButtonRoot = styled(Button).attrs({ variant: 'raw' })`
  ${({ theme }) => css`
    --table-footer-button-color-background: ${theme.colors.blank};
    --table-footer-button-color-border: ${theme.colors.grey[200]};
    --table-footer-button-color-foreground: ${theme.colors.grey[600]};
  `}

  ${sharedTransition('background-color, border, color')}

  ${({ theme }) => theme.typography.xsMedium}

  align-items: center;
  background-color: var(--table-footer-button-color-background);
  border-radius: 8px;
  border: 1px solid var(--table-footer-button-color-border);
  box-sizing: border-box;
  color: var(--table-footer-button-color-foreground);
  display: flex;
  gap: ${({ theme }) => theme.space[2]}px;
  height: 28px;
  min-width: 28px;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.space[2]}px;

  &:hover {
    ${({ theme }) => css`
      --table-footer-button-color-border: ${theme.colors.grey[600]};
    `}
  }

  &[aria-disabled='true'] {
    ${({ theme }) => css`
      --table-footer-button-color-border: ${theme.colors.grey[200]};
      --table-footer-button-color-foreground: ${theme.colors.grey[200]};
    `}
  }
`;

const Icon = styled.svg`
  height: 16px;
  width: 16px;
`;

type TableFooterButtonProps = {
  disabled?: boolean;
  icon: ElementType;
  label: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  'data-testid'?: string;
};

export function TableFooterButton({
  disabled,
  icon,
  label,
  onClick,
  'data-testid': dataTestid,
}: TableFooterButtonProps) {
  return (
    <Tooltip label={!disabled && label} type="caption">
      <TableFooterButtonRoot
        aria-label={label}
        onClick={onClick}
        disabled={disabled}
        data-testid={dataTestid}
      >
        <Icon as={icon} />
      </TableFooterButtonRoot>
    </Tooltip>
  );
}
