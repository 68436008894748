import isEqual from 'lodash/isEqual';
import { useCallback, useMemo } from 'react';

import {
  onlyConfigurableColumns,
  onlyInvisibleColumns,
  onlyNonDataColumns,
  onlyVisibleColumns,
} from '@/src/components/Table/hooks/useColumnsState';
import type { ColumnState } from '@/src/components/Table/hooks/useColumnsState/types';

/* -------------------------------------------------------------------------------------------------
 * Constants
 * -----------------------------------------------------------------------------------------------*/

export const COLUMNS_ORDER_SEGMENTS = ['visible', 'invisible'] as const;

/* -------------------------------------------------------------------------------------------------
 * Shared types
 * -----------------------------------------------------------------------------------------------*/

type ColumnsState = ColumnState[];

type ColumnId = string;

type ColumnIndex = number;

export type Segment = (typeof COLUMNS_ORDER_SEGMENTS)[number];

export type SegmentedColumnsState = Record<Segment, ColumnsState>;

type ChangeColumnOrderTarget = {
  segment: Segment;
  index: ColumnIndex;
};

/* -------------------------------------------------------------------------------------------------
 * Helpers
 * -----------------------------------------------------------------------------------------------*/

const composeInitialColumnsState = ({
  allowConfigureAllColumns = true,
  columnsState,
}: {
  allowConfigureAllColumns?: boolean;
  columnsState: ColumnsState;
}): ColumnsState => {
  /* Filter out any columns that can't be configured (hidden, blocked, …). */
  const configurableColumns = columnsState.filter(onlyConfigurableColumns);

  /* Filter out additional columns which are not included in the default columns configuration. */
  if (!allowConfigureAllColumns) {
    return configurableColumns.filter(onlyNonDataColumns);
  }

  return configurableColumns;
};

const segmentColumnsState = (columnsState: ColumnsState): SegmentedColumnsState => {
  return {
    invisible: columnsState.filter(onlyInvisibleColumns),
    visible: columnsState.filter(onlyVisibleColumns),
  };
};

/* -------------------------------------------------------------------------------------------------
 * Hook
 * -----------------------------------------------------------------------------------------------*/

export type ChangeColumnOrderProps = {
  id: ColumnId;
  destination: ChangeColumnOrderTarget;
  source: ChangeColumnOrderTarget;
};

export type ToggleColumnVisibiltyProps = {
  id: ColumnId;
  currentSegment: Segment;
};

export type UseSegmentedColumnsStateProps = {
  /** Enables configuration for all columns. Defaults to true. */
  allowConfigureAllColumns?: boolean;
  /** Initial state of columns (visibility and order). */
  columnsState: ColumnState[];
  /** Function to call when the column order changes */
  onChangeColumnOrder: (props: { id: ColumnId; index: ColumnIndex }) => void;
  /** Function to call when the column visibility is toggled */
  onToggleColumnVisibility: (id: ColumnId) => void;
};

export const useSegmentedColumnsState = ({
  allowConfigureAllColumns,
  columnsState,
  onChangeColumnOrder,
  onToggleColumnVisibility,
}: UseSegmentedColumnsStateProps) => {
  const segmentedColumnsState = useMemo(() => {
    const initialColumnsState = composeInitialColumnsState({
      allowConfigureAllColumns,
      columnsState,
    });

    return segmentColumnsState(initialColumnsState);
  }, [allowConfigureAllColumns, columnsState]);

  return {
    /**
     * Segmented state of columns categorized into 'visible' and 'invisible',
     * aiding in column manipulation based on visibility.
     */
    segmentedColumnsState,
    /**
     * Modifies column order within or across segments. Applies changes to column
     * order and handles segment transitions, updating visibility as needed.
     */
    changeColumnOrder: useCallback(
      ({ id, source, destination }: ChangeColumnOrderProps) => {
        /* Exit early if source and destination are identical. */
        if (isEqual(source, destination)) return;

        /* Toggle column visibility when transitioning segments. */
        if (source.segment !== destination.segment) {
          onToggleColumnVisibility(id);
        }

        let nextIndex = destination.index;

        /* Compensate for transition to higher segment index. */
        if (source.segment === 'visible' && destination.segment === 'invisible') {
          nextIndex -= 1;
        }

        /* Normalize index for merged columns state. */
        if (destination.segment === 'invisible') {
          nextIndex += segmentedColumnsState.visible.length;
        }

        onChangeColumnOrder({
          id,
          index: nextIndex,
        });
      },
      [onChangeColumnOrder, onToggleColumnVisibility, segmentedColumnsState]
    ),
    /**
     * Toggles the visibility of a column, updating its segment and order
     * within the new segment based on current state.
     */
    toggleColumnVisibility: useCallback(
      ({ id, currentSegment }: ToggleColumnVisibiltyProps) => {
        onToggleColumnVisibility(id);

        const nextIndex =
          currentSegment === 'visible'
            ? segmentedColumnsState.visible.length - 1
            : segmentedColumnsState.visible.length;

        onChangeColumnOrder({
          id,
          index: nextIndex,
        });
      },
      [onChangeColumnOrder, onToggleColumnVisibility, segmentedColumnsState]
    ),
  };
};
