export const PRY_BANK_CODES = [
  { value: '042', label: 'BANCO ATLAS' },
  { value: '030', label: 'BANCO BASA' },
  { value: '203', label: 'BANCO CENTRAL DE' },
  { value: '228', label: 'BANCO CENTRAL DE' },
  { value: '020', label: 'BANCO CONTINENTA' },
  { value: '005', label: 'BANCO DO BRASIL' },
  { value: '041', label: 'BANCO FAMILIAR' },
  { value: '004', label: 'BANCO GNB PY SA' },
  { value: '017', label: 'BANCO ITAU' },
  { value: '002', label: 'BANCO NAC FOMENT' },
  { value: '003', label: 'BANCO NACION' },
  { value: '040', label: 'BANCO RIO' },
  { value: '008', label: 'BANCO SUDAMERIS' },
  { value: '043', label: 'BANCOP S.A.' },
  { value: '001', label: 'CITIBANK' },
  { value: '316', label: 'COOP 26 DE ABRIL' },
  { value: '325', label: 'COOP A. CONCORDI' },
  { value: '334', label: 'COOP BARRIOJAREN' },
  { value: '328', label: 'COOP BUEN CAMINO' },
  { value: '322', label: 'COOP COFUDEP' },
  { value: '318', label: 'COOP COOMECIPAR' },
  { value: '335', label: 'COOP COOPEC' },
  { value: '313', label: 'COOP COOPEDIEZ' },
  { value: '319', label: 'COOP COOPEDUC' },
  { value: '314', label: 'COOP COOPERSAM' },
  { value: '331', label: 'COOP EMILIANO' },
  { value: '326', label: 'COOP GUAI' },
  { value: '320', label: 'COOP LOMA PLATA' },
  { value: '329', label: 'COOP MBORAYHU' },
  { value: '336', label: 'COOP MEDALLA' },
  { value: '315', label: 'COOP NEULAND' },
  { value: '333', label: 'COOP NSC' },
  { value: '330', label: 'COOP PINOZA' },
  { value: '317', label: 'COOP REDUCTO' },
  { value: '321', label: 'COOP SAN CRISTOB' },
  { value: '323', label: 'COOP SAN PEDRO' },
  { value: '324', label: 'COOP TOBATI' },
  { value: '337', label: 'COOP YPACARAI' },
  { value: '327', label: 'COOPEJAS' },
  { value: '305', label: 'COOPERATIVA  COO' },
  { value: '309', label: 'COOPERATIVA CHOR' },
  { value: '311', label: 'COOPERATIVA COOP' },
  { value: '303', label: 'COOPERATIVA COPA' },
  { value: '307', label: 'COOPERATIVA FERN' },
  { value: '304', label: 'COOPERATIVA LAMB' },
  { value: '306', label: 'COOPERATIVA LUOU' },
  { value: '308', label: 'COOPERATIVA MBUR' },
  { value: '300', label: 'COOPERATIVA MERC' },
  { value: '338', label: 'COOPERATIVA MULT' },
  { value: '310', label: 'COOPERATIVA NAZA' },
  { value: '312', label: 'COOPERATIVA SAGR' },
  { value: '302', label: 'COOPERATIVA UNIV' },
  { value: '301', label: 'COOPERATIVA YOAY' },
  { value: '332', label: 'COPAVIC' },
  { value: '113', label: 'FINAN. PYO. JAPO' },
  { value: '207', label: 'FINAN. SANTA ANA' },
  { value: '206', label: 'FINANCIERA FIC S' },
  { value: '107', label: 'FINANCIERA UENO' },
  { value: '208', label: 'FINEXPAR S.A.' },
  { value: '209', label: 'FINLATINA S.A.' },
  { value: '118', label: 'INTERFISA S.A.E.' },
  { value: '104', label: 'SOLAR BANCO S.A.' },
  { value: '178', label: 'TU FINANCIERA S.' },
  { value: '039', label: 'VISION BANCO SA' },
];
