import { useGet, useInvalidateQuery, usePost, useDelete } from '@remote-com/data-layer';
import { isFuture } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { useUserCan } from '@/src/components/UserCan';
import { useUserContext } from '@/src/components/UserProvider';
import { canUserManageContractorAgreements } from '@/src/domains/contracts/helpers';
import { PURCHASE_OPTION_CONTENT } from '@/src/domains/employment/newEmployment/steps/PricingPlan/constants';
import { contractorSupportedPayments } from '@/src/domains/paidContractorProduct/constants';
import { filterAndSortContractorSubscriptions } from '@/src/domains/paidContractorProduct/helpers';
import useCompanyDetailsData from '@/src/domains/paymentMethods/shared/hooks/useCompanyDetailsData';
import { CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE } from '@/src/domains/pricing/constants';
import { isProductContractorStandard } from '@/src/domains/pricing/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { convertUtcStringToDateObj } from '@/src/helpers/date';
import { useCustomMutation } from '@/src/hooks/useCustomMutation';

function noop() {}

export function usePaidContractorSubscriptionProduct() {
  const { userCan } = useUserCan();

  return useCompanyDetailsData({
    select: (data) =>
      data?.company?.products.find(
        (product) => product.active && isProductContractorStandard(product)
      ),
    enabled: userCan('read', Resources.employer.company_details),
  });
}

export function useIsCountrySupportedForContractorOnboarding() {
  const { data: availableCountries = [], isLoading: isLoadingAvailableCountries } = useGet(
    '/api/v1/employer/contractor-countries',
    {
      options: {
        select: (res) => res.data,
      },
    }
  );

  const availableCountriesMapped = availableCountries?.reduce(
    (acc, country) => ({ ...acc, [country.code]: country }),
    {}
  );

  const isLoading = isLoadingAvailableCountries;

  // Return true if it's allowed to invite a contractor from the specified country
  const isCountrySupportedForContractorOnboarding = useCallback(
    (country) => {
      if (isLoading) return false;

      return availableCountriesMapped[country?.code]?.supportedPayments?.length > 0;
    },
    [availableCountriesMapped, isLoading]
  );

  // Return true if country only supports Stripe as a payment
  const isStripeOnlyCountryPaymentSupported = useCallback(
    (country) => {
      if (isLoading) {
        return false;
      }

      // Verify if Stripe is the only supported payment
      const supportedPayments = availableCountriesMapped[country?.code]?.supportedPayments;
      return (
        supportedPayments?.length === 1 &&
        supportedPayments[0] === contractorSupportedPayments.STRIPE
      );
    },
    [availableCountriesMapped, isLoading]
  );

  // Return true if country only supports Wise virtual as
  // a payment
  const shouldSetupWiseVirtualAccount = useCallback(
    (country) => {
      if (isLoading) {
        return false;
      }

      // Verify if Wise virtual is the only supported payment
      const supportedPayments = availableCountriesMapped[country?.code]?.supportedPayments;
      return (
        supportedPayments?.length === 1 &&
        supportedPayments[0] === contractorSupportedPayments.WISE_VIRTUAL
      );
    },
    [availableCountriesMapped, isLoading]
  );

  const supportedPaymentProviders = useMemo(() => {
    return new Set(
      Object.values(availableCountriesMapped).flatMap((country) => country.supportedPayments)
    );
  }, [availableCountriesMapped]);

  return {
    isCountrySupportedForContractorOnboarding,
    isLoading,
    isStripeOnlyCountryPaymentSupported,
    shouldSetupWiseVirtualAccount,
    supportedPaymentProviders,
    contractorCountries: {
      countriesList: availableCountries,
      countriesBySlug: availableCountries?.reduce((acc, country) => {
        acc[country.slug] = country;
        return acc;
      }, {}),
    },
  };
}

/**
 * Fetches employer contractor subscriptions.
 *
 * @param {Object} params
 * @returns {import('react-query').UseQueryResult<import('@/src/api/config/employ/contractorSubscription.types').ContractorSubscription[]>}
 */
export function useFetchEmployerContractorSubscriptions({ employmentSlug, options = {} }) {
  return useGet('/api/v1/employer/contractor-subscriptions', {
    params: { queryParams: { employmentSlug } },
    options: {
      select: (res) =>
        filterAndSortContractorSubscriptions(res.data)?.map((subscription) => {
          const { currency, companyProductDiscount } = subscription;

          return {
            ...subscription,
            price: { ...subscription.price, currency },
            companyProductDiscount: isFuture(
              convertUtcStringToDateObj(companyProductDiscount?.expirationDate)
            )
              ? companyProductDiscount
              : null,
          };
        }),
      ...options,
    },
  });
}

export function useFetchContractorPricingPlan({ employmentSlug, enabled = true }) {
  return useGet('/api/v1/employer/employments/[employmentSlug]/pricing-plan', {
    params: {
      pathParams: {
        employmentSlug,
      },
    },
    options: {
      select: (res) => res.data,
      enabled: enabled && !!employmentSlug,
    },
  });
}

export function useContractorSubscriptionPlanChange({ employmentSlug, onSuccess = noop }) {
  const { user } = useUserContext();
  const { invalidateQuery } = useInvalidateQuery();

  const { mutateAsync } = usePost(
    '/api/v1/employer/employments/[employmentSlug]/contractor-plus-subscription',
    {
      onSuccess: async (res, params) => {
        const isDowngrade =
          params.bodyParams.operation === CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE.DOWNGRADE;

        await Promise.all([
          invalidateQuery('/api/v1/employer/employments/[employmentSlug]/pricing-plan', {
            params: { pathParams: { employmentSlug } },
          }),
          // Creating or cancelling the upgrade intent affects the types of templates that
          // are available for the contractor.
          invalidateQuery('/api/v1/employer/contract-templates/available', {
            params: { queryParams: { employmentSlug } },
          }),
          isDowngrade ? invalidateQuery('/api/v1/employer/contract-documents') : Promise.resolve(),
        ]);
      },
    }
  );

  return useCustomMutation(
    ({ operation }) => {
      if (
        operation === CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE.UPGRADE &&
        !canUserManageContractorAgreements(user)
      ) {
        throw new Error(
          `You do not have the permission to create and manage contractor agreements, which is required to switch the contractor to the ${PURCHASE_OPTION_CONTENT.CONTRACTOR_PLUS.title} plan. Please ask your company owner to grant you the permission to manage existing or onboard new hires.`
        );
      }

      return mutateAsync({
        pathParams: { employmentSlug },
        bodyParams: { operation },
      });
    },
    { onSuccess }
  );
}

function useInvalidatePricingPlan() {
  const { invalidateQuery } = useInvalidateQuery();
  return (employmentSlug) =>
    invalidateQuery('/api/v1/employer/employments/[employmentSlug]/pricing-plan', {
      params: { pathParams: { employmentSlug } },
    });
}

export function useCreateContractorProtectIntent() {
  const invalidatePricingPlan = useInvalidatePricingPlan();
  return usePost('/api/v1/employer/employments/[employmentSlug]/aor-subscription-intent', {
    onSuccess: async (res, params) => {
      const { employmentSlug } = params.pathParams;
      await invalidatePricingPlan(employmentSlug);
    },
  });
}

export function useDeleteContractorProtectIntent() {
  const invalidatePricingPlan = useInvalidatePricingPlan();
  return useDelete('/api/v1/employer/employments/[employmentSlug]/aor-subscription-intent', {
    onSuccess: async (res, params) => {
      const { employmentSlug } = params.pathParams;
      await invalidatePricingPlan(employmentSlug);
    },
  });
}
