import { IconTrash2 } from '@remote-com/norma/icons/IconTrash2';

import { StandaloneLink } from '@/src/components/Button';
import { Modal } from '@/src/components/Modal';
import { isSmartFieldAvailable } from '@/src/domains/contracts/ContractsEditor/helpers';

import { EditSmartField } from './EditSmartField';

const FORM_NAME = 'edit-smartfield-form';

export function EditSmartFieldModal({
  open,
  node,
  updateAttributes,
  editor,
  hideModal,
  deleteNode,
}) {
  function onSubmit(values) {
    updateAttributes({
      field: values.smartField,
      label: values.smartField,
      isUnavailable: !isSmartFieldAvailable(
        values.smartField,
        editor.storage.smartFieldDefinitions
      ),
    });
    hideModal();
  }

  function onRemoveSmartField() {
    deleteNode();
    hideModal();
  }

  return (
    <Modal
      visible={open}
      onCancel={hideModal}
      headerTitle="Edit smartfield"
      formName={FORM_NAME}
      saveButtonText="Save"
      data-testid={FORM_NAME}
    >
      <EditSmartField
        node={node}
        handleModalSubmit={onSubmit}
        editorStorage={editor?.storage}
        formName={FORM_NAME}
      />
      <StandaloneLink
        mt="7"
        tone="destructive"
        onClick={onRemoveSmartField}
        IconBefore={IconTrash2}
      >
        Remove smartfield
      </StandaloneLink>
    </Modal>
  );
}
