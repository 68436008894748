import { forwardRef } from 'react';

import type { FormGroupProps } from '../form-group';
import { AsideBadge, FormGroup, useFormGroupContext } from '../form-group';
import { inputModeAttrs } from '../helpers';

import { StyledInputText, StyledReactInputMask } from './InputText.styled';

export type InputTextProps = {
  /**
   * Type of input to render ([MDN Documentation](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types)).
   * Note that there might already be other components that are more suitable for your use case (e.g. `InputPassword`, …)
   */
  type?: HTMLInputElement['type'];
  /**
   * When true, the percentage prop displays an AsideBadge with a percentage sign (only for numerical inputs).
   */
  percentage?: boolean;
  /*
   The inputmode global attribute provides a hint to browsers for devices with onscreen keyboards to help them decide which keyboard to display when a user has selected any input or textarea element. ([MDN Documentation](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode))
  */
  inputMode?: HTMLInputElement['inputMode'];
  /**
   * Apply a mask to the input value to apply formatting to the user input.
   * Uses `react-input-mask` under the hood. Find syntax at https://www.npmjs.com/package/react-input-mask#mask--string
   */
  mask?: string | Array<string | RegExp>;
  /**
   * Maximum length of the input.
   */
  maxLength?: HTMLInputElement['maxLength'];
  /**
   * The pattern attribute is a regular expression that the input's value is checked against on form submission.
   */
  pattern?: string;
  /**
   * Placeholder text for the input.
   */
  placeholder?: string | undefined;
} & Omit<FormGroupProps, 'children' | 'counter'>;

type InputElementProps = Pick<InputTextProps, 'mask' | 'placeholder' | 'maxLength'> & {
  typeAttrs: { type: HTMLInputElement['type'] } | typeof inputModeAttrs;
};

function InputElement({ typeAttrs, mask, placeholder }: InputElementProps) {
  const inputProps = useFormGroupContext();

  const maskProps = {
    ...inputProps,
    ...typeAttrs,
    mask: mask || '',
    placeholder,
  };

  if (placeholder) {
    maskProps.placeholder = placeholder;
  }

  return mask ? (
    <StyledReactInputMask {...maskProps} />
  ) : (
    <StyledInputText {...inputProps} {...typeAttrs} />
  );
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  ({ maxLength, percentage, type = 'text', mask, placeholder, ...props }, ref) => {
    const isTypeNumber = type === 'number';
    const typeAttrs = isTypeNumber ? inputModeAttrs : { type };

    return (
      <FormGroup
        aside={
          isTypeNumber && percentage
            ? (asideProps) => <AsideBadge {...asideProps}>%</AsideBadge>
            : null
        }
        {...props}
        placeholder={placeholder}
        ref={ref}
      >
        <InputElement
          typeAttrs={typeAttrs}
          mask={mask}
          maxLength={maxLength}
          placeholder={placeholder}
        />
      </FormGroup>
    );
  }
);
