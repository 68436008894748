import { MINUTES_IN_HOUR } from './constants';
import { simplifyTimeAmounts } from './simplifyTimeAmounts';

/**
 * Display the working hours as a string
 * @param {{hours?: number, minutes?: number}} workingHours Working hours
 * @returns {string} working hours as string
 */

export function displayWorkingHours(workingHours) {
  const { hours, minutes } = simplifyTimeAmounts(workingHours);

  // Get fractional hours e.g., 30 minutes == 0.5 hours
  const fractionHours = minutes / MINUTES_IN_HOUR;

  const totalHours = hours + fractionHours;

  let totalHoursStr = totalHours.toFixed(2);
  if (totalHoursStr.endsWith('0')) {
    // "1.50" -> "1.5"
    totalHoursStr = totalHoursStr.slice(0, -1);
  }

  return Number.isInteger(totalHours)
    ? `${totalHours} ${totalHours === 1 ? 'hour' : 'hours'}`
    : `${totalHoursStr} hours`;
}
