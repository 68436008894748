import { InputText } from '@remote-com/norma';
import { Field, useFormikContext } from 'formik';

import { InputPassword } from '@/src/components/Ui/Form/inputs/InputPassword';

/**
 * getInputForType
 *
 * Returns the relevant component for a given input type
 *
 * @param {String} type - The input type
 */
function getInputForType(type) {
  switch (type) {
    case 'password':
      return InputPassword;
    default:
      return InputText;
  }
}

/**
 * InputField
 *
 * Formik input field abstraction, containing label, description and field error
 *
 * @export
 * @param {(import('@/src/components/Form/DynamicForm/types').RenderInputTextField) & {id?: string}} props
 * @return {JSX.Element}
 */
export const InputField = (props) => {
  /**
   * We use the restProps to pass to the InputComponent,
   * otherwise the onChange gets replaced
   */
  const { name, onChange, type, ...restProps } = props;
  const { values, setValues, setFieldTouched } = useFormikContext();

  const InputComponent = getInputForType(type);

  function handleOnChange(e, nativeOnChange) {
    if (onChange) onChange(e, values, setValues, setFieldTouched);
    nativeOnChange(e);
  }

  return (
    <Field name={name}>
      {({ field, meta: { error, touched } }) => (
        <InputComponent
          {...field}
          errorText={error && touched ? error : ''}
          isOptional={!props.required && !props['aria-required']}
          name={name}
          onChange={(e) => handleOnChange(e, field.onChange)}
          type={type}
          value={field.value}
          {...restProps}
        />
      )}
    </Field>
  );
};
