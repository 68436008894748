import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { cloneElement, useReducer, useEffect, createContext, useContext, useState } from 'react';

import { captureHTTPException } from '@/src/helpers/captureException';
import { applyFormikFieldErrors, getExceptionInfo } from '@/src/helpers/general';

export const formActions = {
  ERROR: 'error',
  LOADING: 'loading',
  RESET: 'reset',
  SUCCESS: 'success',
};

// setFieldError is a formik function to set an error message on each input
const initialState = {
  isLoading: false,
  exception: null,
  errorMessage: null,
  inputErrors: null,
  successMessage: null,
  successDescription: null,
  setFieldError: null,
  response: null,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case formActions.RESET:
      return initialState;
    case formActions.LOADING:
      return {
        ...state,
        isLoading: true,
        exception: null,
        errorMessage: null,
        inputErrors: null,
        successMessage: null,
        successDescription: null,
        setFieldError: null,
      };
    case formActions.ERROR: {
      const { exception, errorMessage, setFieldError, errorFieldMap, errorFieldsetMap } =
        action.payload;
      const { message, errors } = getExceptionInfo(exception);

      // We might get an api response that marks some fields as errors but their name does not match
      // the one in the form (case of date-ranges for example). For these cases we can use a `errorFieldMap` to map
      // API error fields to form fields
      if (errorFieldMap && errors) {
        Object.entries(errorFieldMap).forEach(([apiKey, formKey]) => {
          errors[formKey] = errors[apiKey];
          delete errors[apiKey];
        });
      }

      // We might get an API response that needs to be mapped within a fieldset
      // This is to ensure that the error is displayed in the correct fieldset field
      // This is temporary, a refactor will remove the need to map errors https://linear.app/remote/issue/WFP-684/refactor-manager-assignment-field-in-onboarding-flows-and-ee-profile
      if (errorFieldsetMap && errors) {
        Object.entries(errorFieldsetMap).forEach(([apiKey, { formKey, fields }]) => {
          const errorValue = get(errors, apiKey);

          if (errorValue) {
            fields.forEach((field) => {
              if (!errors[formKey]) {
                errors[formKey] = [];
              }

              const existingErrorIndex = errors[formKey].findIndex(
                (err) => Object.keys(err)[0] === field
              );

              if (existingErrorIndex === -1) {
                errors[formKey].push({ [field]: errorValue });
              }
            });
          }
        });

        // Clean up original error keys after all mappings are done
        Object.values(errorFieldsetMap).forEach(({ excludeErrorKey }) => {
          if (excludeErrorKey) {
            delete errors[excludeErrorKey];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        exception,
        errorMessage: errorMessage || message,
        inputErrors: errors,
        setFieldError,
        successMessage: null,
        successDescription: null,
        response: null,
      };
    }
    case formActions.SUCCESS:
      return {
        ...state,
        isLoading: false,
        exception: null,
        errorMessage: null,
        inputErrors: null,
        successMessage: action.payload.successMessage,
        successDescription: action.payload.successDescription,
        setFieldError: null,
        response: action.payload.response,
      };
    default:
      return state;
  }
};

/**
 * @deprecated in favor of useCustomMutation.
 * Read useCustomMutation docs for migration guidance.
 */
export function useFormEvents(defaultState = {}) {
  const [formState, dispatch] = useReducer(formReducer, {
    ...initialState,
    ...defaultState,
  });

  useEffect(() => {
    if (formState.inputErrors && formState.setFieldError) {
      applyFormikFieldErrors(formState.inputErrors, formState.setFieldError);
    }
    if (formState.exception) {
      captureHTTPException(formState.exception, {
        extra: { ...formState?.exception?.response?.data },
      });
    }
  }, [formState]);

  return {
    formState,
    dispatch,
  };
}

const FormContext = createContext();

function Form({ children }) {
  const [actions, setActions] = useState();
  return (
    <FormContext.Provider value={{ setActions }}>
      {cloneElement(children, {
        ...actions,
      })}
    </FormContext.Provider>
  );
}

export function withForm(Component) {
  return function withFormComponent(props) {
    return (
      <Form>
        <Component {...props} />
      </Form>
    );
  };
}

export const useFormikEvents = (defaultState = {}) => {
  const { setFieldError, ...formikProps } = useFormikContext();
  const { formState, dispatch } = useFormEvents(defaultState);
  const { setActions } = useContext(FormContext);

  useEffect(() => {
    const dispatchSubmit = async (asyncFn) => {
      dispatch({ type: formActions.LOADING });
      try {
        await asyncFn();
      } catch (exception) {
        dispatch({ type: formActions.ERROR, payload: { exception, setFieldError } });
      }
    };

    const dispatchSuccess = (successMessage = '') => {
      dispatch({ type: formActions.RESET });
      if (successMessage) {
        dispatch({
          type: formActions.SUCCESS,
          payload: { successMessage },
        });
      }
    };

    setActions(() => ({
      dispatchSubmit,
      dispatchSuccess,
    }));
  }, [dispatch, setActions, setFieldError]);

  return {
    formState,
    dispatch,
    formik: {
      ...formikProps,
    },
  };
};

const FeedbackMessageContext = createContext();

export function useFeedbackMessageContext() {
  const feedbackMessageContext = useContext(FeedbackMessageContext);

  if (typeof feedbackMessageContext === 'undefined') {
    throw new Error('useFeedbackMessageContext must be used within a FeedbackMessageProvider');
  }

  return feedbackMessageContext;
}

export function FeedbackMessageProvider({ children }) {
  const { formState, dispatch } = useFormEvents();
  return (
    <FeedbackMessageContext.Provider value={{ formState, dispatch }}>
      {children}
    </FeedbackMessageContext.Provider>
  );
}
