import { Box } from '@remote-com/norma';
import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';

const StyledCardDrawerFooterWrapper = styled(Box)`
  ${({ theme }) => theme.typography.xs};

  background-color: ${({ theme }) => theme.colors.grey[50]};
  border-top: 1px solid ${({ theme }) => theme.colors.grey[200]};
  color: ${({ theme }) => theme.colors.grey[500]};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: var(--card-drawer-space-y) var(--card-drawer-space-x);
`;

type StyledCardDrawerFooterProps = PropsWithChildren<{}>;

export const CardDrawerFooter = forwardRef<HTMLDivElement, StyledCardDrawerFooterProps>(
  (props, ref) => {
    return <StyledCardDrawerFooterWrapper ref={ref} {...props} />;
  }
);
