export const URY_BANK_CODES = [
  { value: '001', label: 'BROU' },
  { value: '091', label: 'HIPOTECARIO' },
  { value: '113', label: 'BANCO ITAU' },
  { value: '128', label: 'SCOTIABANK' },
  { value: '137', label: 'SANTANDER' },
  { value: '153', label: 'BBVA' },
  { value: '157', label: 'HSBC BANK URUGUAY' },
  { value: '162', label: 'HERITAGE' },
  { value: '246', label: 'NACION ARGENTINA' },
  { value: '361', label: 'DE LA PROV DE BS AS' },
  { value: '530', label: 'NOBILIS' },
  { value: '552', label: 'VARLIX' },
  { value: '558', label: 'INDUMEX' },
  { value: '566', label: 'GALES' },
  { value: '582', label: 'AVENIDA' },
  { value: '587', label: 'NIXUS' },
  { value: '607', label: 'FUCEREP' },
  { value: '624', label: 'REDPAGOS' },
  { value: '626', label: 'PREX' },
  { value: '999', label: 'OCA BLUE' },
];

export const URY_WISE_BANK_CODES = [
  {
    value: '308',
    label: 'AFINIDAD AFAP FONDOS DE AHORRO PREVISIONAL',
  },
  {
    value: '153',
    label: 'BANCO BILBAO VIZCAYA ARGENTARIA URUGUAYS.A.',
  },
  {
    value: '001',
    label: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY',
  },
  {
    value: '113',
    label: 'BANCO ITAU',
  },
  {
    value: '137',
    label: 'BANCO SANTANDER',
  },
  {
    value: '110',
    label: 'BANDES URUGUAY S.A.',
  },
  {
    value: '162',
    label: 'BANQUE HERITAGE (URUGUAY) S.A.',
  },
  {
    value: '374',
    label: 'BNL DE URUGUAY S.A.',
  },
  {
    value: '549',
    label: 'BOLSA DE VALORES DE MONTEVIDEO',
  },
  {
    value: '134',
    label: 'DISCOUNT BANK (LATIN AMERICA) BANCOS PRIVADOS',
  },
  {
    value: '452',
    label: 'FORTIGOLD S.A. CASAS DE CAMBIO',
  },
  {
    value: '157',
    label: 'HSBC BANK (URUGUAY) S.A.',
  },
  {
    value: '128',
    label: 'SCOTIABANK URUGUAY S.A.',
  },
  {
    value: '457',
    label: 'VALDIG S.A. - MONTEVIDEO CASAS DE CAMBIO',
  },
  {
    value: '473',
    label: 'VANTRO S.A. CASAS DE CAMBIO',
  },
];
