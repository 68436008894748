import { useUser } from '@/src/components/UserProvider/context';

export const useBillingTableStorageKey = ({ isCompanyScoped }) => {
  const { userIsAdmin } = useUser();

  return `[${userIsAdmin ? 'ADMIN' : 'EMPLOYER'} - BILLING${isCompanyScoped ? ' - COMPANY' : ''}]`;
};

export const useBillingDocumentsTableStorageKey = ({ isCompanyScoped }) => {
  const { userIsAdmin } = useUser();

  return `[${userIsAdmin ? 'ADMIN' : 'EMPLOYER'} - BILLING-DOCUMENTS${
    isCompanyScoped ? ' - COMPANY' : ''
  }]`;
};
