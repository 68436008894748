import PropTypes from 'prop-types';

export const SvgUse = ({ href, ...props }) => (
  <svg {...props}>
    <use href={href} />
  </svg>
);

SvgUse.propTypes = {
  href: PropTypes.string,
};
