import type { GetRequest, TableOptions } from '@remote-com/data-layer';
import { useGetForTable } from '@remote-com/data-layer';
import camelCase from 'lodash/camelCase';
import { useContext, useEffect } from 'react';
import type { ValueOf } from 'type-fest';

import UserContext from '@/src/components/UserProvider/context';
import { paymentStatus } from '@/src/domains/payments/constants';
import { isAdmin } from '@/src/domains/registration/auth/helpers';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import { convertToCents } from '@/src/helpers/currency';
import { getYesterdayAsUTCWithDefaultFormat } from '@/src/helpers/date';
import { setLocalStorageItem } from '@/src/helpers/localStorage';

import { useInvoiceTableStorageKey } from './useInvoiceTableStorageKey';

const parseInvoiceReportQueryParams = ({
  finalizedAt,
  minInvoiceAmount,
  maxInvoiceAmount,
  minTotalInvoiceAmount,
  maxTotalInvoiceAmount,
  paymentRefundStatus,
  paymentDueDate,
  paymentDate,
  ...queryParams
}: Record<string, string | string[]>) => {
  const parsedQueryParams = queryParams;

  if (finalizedAt) {
    const [finalizedFrom, finalizedTo] = finalizedAt as string[];
    parsedQueryParams.finalizedFrom = finalizedFrom;
    parsedQueryParams.finalizedTo = finalizedTo;
  }

  if (minTotalInvoiceAmount) {
    const minTotalInvoiceAmountInCents = Array.isArray(minTotalInvoiceAmount)
      ? minTotalInvoiceAmount.map((invoiceAmount: string) => convertToCents(invoiceAmount))
      : convertToCents(minTotalInvoiceAmount);

    parsedQueryParams.minTotalInvoiceAmount = (minTotalInvoiceAmountInCents ?? '').toString();
  }

  if (maxTotalInvoiceAmount) {
    const maxTotalInvoiceAmountInCents = Array.isArray(maxTotalInvoiceAmount)
      ? maxTotalInvoiceAmount.map((invoiceAmount: string) => convertToCents(invoiceAmount))
      : convertToCents(maxTotalInvoiceAmount);

    parsedQueryParams.maxTotalInvoiceAmount = (maxTotalInvoiceAmountInCents ?? '').toString();
  }

  if (Array.isArray(paymentRefundStatus)) {
    const params = paymentRefundStatus.reduce(
      (acc, value) => {
        const [status, group] = value.split('-');

        if (group === 'payments') {
          acc = {
            ...acc,
            paymentStatus: [...acc.paymentStatus, ...status.split(';')],
          };
        }

        if (group === 'credits') {
          acc = {
            ...acc,
            refundStatus: [...acc.refundStatus, ...status.split(';')].map(
              (currentStatus) =>
                currentStatus === paymentStatus.CREDIT_DUE ? paymentStatus.CREATED : currentStatus // BE uses 'created' status for payments and credits
            ),
          };
        }

        return acc;
      },
      { paymentStatus: [] as string[], refundStatus: [] as string[] }
    );

    parsedQueryParams.paymentStatus = [...new Set(params.paymentStatus)];
    parsedQueryParams.refundStatus = [...new Set(params.refundStatus)];
  }

  if (paymentDueDate) {
    const [paymentDueDateFrom, paymentDueDateTo] = paymentDueDate as string[];
    parsedQueryParams.paymentDueDateFrom = paymentDueDateFrom;
    parsedQueryParams.paymentDueDateTo = paymentDueDateTo;
  }

  if (paymentDate) {
    const [paymentDateFrom, paymentDateTo] = paymentDate as string[];
    parsedQueryParams.paymentDateFrom = paymentDateFrom;
    parsedQueryParams.paymentDateTo = paymentDateTo;
  }

  return parsedQueryParams;
};

export const employerBillingDocumentTableColumnIds = {
  invoicePeriod: 'period',
  paymentStatus: 'paymentRefundStatus',
  invoiceType: 'types',
  legalEntity: 'legalEntitySlug',
  currencyCode: 'currencyCode',
  amount: 'amount',
  amountDue: 'amountDue',
  issueDate: 'finalizedAt',
  dueDate: 'paymentDueDate',
  paymentDate: 'paymentDate',
  invoiceNumber: 'number',
  minimumAmount: 'minTotalInvoiceAmount',
  maximumAmount: 'maxTotalInvoiceAmount',
  invoiceCurrency: 'invoiceCurrency',
  includeCreditNotes: 'includeCreditNotes',
} as const;

type BillingDocumentColumnId = ValueOf<typeof employerBillingDocumentTableColumnIds>;
type BillingDocumentSortableColumnId = Extract<
  BillingDocumentColumnId,
  | typeof employerBillingDocumentTableColumnIds.invoicePeriod
  | typeof employerBillingDocumentTableColumnIds.dueDate
  | typeof employerBillingDocumentTableColumnIds.invoiceNumber
>;

const billingDocumentEquivalentSortMap: Record<BillingDocumentSortableColumnId, string> = {
  [employerBillingDocumentTableColumnIds.invoicePeriod]: 'invoice_period',
  [employerBillingDocumentTableColumnIds.dueDate]: 'due_date',
  [employerBillingDocumentTableColumnIds.invoiceNumber]: 'invoice_number',
};

const parseBillingDocumentQueryParams = ({
  period,
  paymentRefundStatus,
  types,
  issueDate,
  paymentDueDate,
  paymentDate,
  minTotalInvoiceAmount,
  maxTotalInvoiceAmount,
  invoiceCurrency,
  includeCreditNotes,
  sortBy,
  ...queryParams
}: Record<BillingDocumentColumnId | 'sortBy' | 'issueDate', string | string[]>) => {
  const parsedQueryParams: Record<string, string | string[]> = queryParams;

  if (period) {
    parsedQueryParams.invoicePeriod = period;
  }

  if (Array.isArray(paymentRefundStatus)) {
    const params = paymentRefundStatus.reduce(
      (acc, value) => {
        const [status, group] = value.split('-');

        if (group === 'payments') {
          acc = {
            ...acc,
            paymentStatus: [...acc.paymentStatus, ...status.split(';')],
          };
        }

        if (group === 'credits') {
          acc = {
            ...acc,
            creditStatus: [...acc.creditStatus, ...status.split(';')],
          };
        }

        return acc;
      },
      { paymentStatus: [] as string[], creditStatus: [] as string[] }
    );

    // Check if 'overdue' is present in paymentStatus
    // Overdue is an FE only status which when sent,
    // we replace with status Due('created') and set dueDateTo to yesterday.
    if (params.paymentStatus.includes('overdue')) {
      // Check if both 'overdue' and 'created' are present
      if (params.paymentStatus.includes('created')) {
        // Remove 'overdue' and do not set dueDateTo
        params.paymentStatus = params.paymentStatus.filter((status) => status !== 'overdue');
      } else {
        // Replace 'overdue' with 'created' and set dueDateTo
        params.paymentStatus = params.paymentStatus.map((status) =>
          status === 'overdue' ? 'created' : status
        );
        parsedQueryParams.dueDateTo = getYesterdayAsUTCWithDefaultFormat();
      }
    }

    parsedQueryParams.paymentStatuses = [...new Set(params.paymentStatus)];
    parsedQueryParams.creditStatuses = [...new Set(params.creditStatus)];
  }

  if (types) {
    parsedQueryParams.invoiceTypes = types;
  }

  // Issue date
  if (issueDate) {
    const [issueDateFrom, issueDateTo] = issueDate as string[];
    parsedQueryParams.issueDateFrom = issueDateFrom;
    parsedQueryParams.issueDateTo = issueDateTo;
  }

  // Due date
  if (paymentDueDate) {
    const [dueDateFrom, dueDateTo] = paymentDueDate as string[];
    parsedQueryParams.dueDateFrom = dueDateFrom;
    parsedQueryParams.dueDateTo = dueDateTo;
  }

  // Payment date
  if (paymentDate) {
    const [paymentDateFrom, paymentDateTo] = paymentDate as string[];
    parsedQueryParams.paymentDateFrom = paymentDateFrom;
    parsedQueryParams.paymentDateTo = paymentDateTo;
  }

  if (minTotalInvoiceAmount) {
    parsedQueryParams.amountMin = minTotalInvoiceAmount;
  }

  if (maxTotalInvoiceAmount) {
    parsedQueryParams.amountMax = maxTotalInvoiceAmount;
  }

  if (invoiceCurrency) {
    parsedQueryParams.amountCurrency = invoiceCurrency;
  }

  if (includeCreditNotes) {
    parsedQueryParams.includeCreditMemos = includeCreditNotes;
  }

  if (sortBy) {
    parsedQueryParams.sortBy =
      billingDocumentEquivalentSortMap[
        camelCase(sortBy as string) as BillingDocumentSortableColumnId
      ] ?? sortBy;
  }

  return parsedQueryParams;
};

type InvoiceReportsEndpoint =
  | '/api/v1/rivendell/invoice-reports'
  | '/api/v1/employer/invoice-reports';

type BillingDocumentsEndpoint = '/api/v1/billing-platform/employer/billing-documents';

const defaultTableOptions = {
  initialState: {
    pageIndex: 0,
    pageSize: 25,
  },
  withQS: true,
  globalFilterQueryKey: 'number',
};

export const useInvoiceTable = () => {
  const { user } = useContext(UserContext);
  const isAdminUser = isAdmin(user);
  const storageKey = useInvoiceTableStorageKey();

  const tableOptionsInvoiceReports: TableOptions<
    InvoiceReportsEndpoint,
    'invoiceReports',
    GetRequest<InvoiceReportsEndpoint>
  > = {
    ...defaultTableOptions,
    transformParams: ({ queryParams, ...params }) => ({
      ...params,
      queryParams: parseInvoiceReportQueryParams(queryParams),
    }),
  };

  const tableOptionsBillingDocuments: TableOptions<
    BillingDocumentsEndpoint,
    'billingDocuments',
    GetRequest<BillingDocumentsEndpoint>
  > = {
    ...defaultTableOptions,
    globalFilterQueryKey: 'invoice_number',
    transformParams: ({ queryParams, ...params }) => ({
      ...params,
      queryParams: parseBillingDocumentQueryParams(queryParams),
    }),
    initialState: {
      ...defaultTableOptions.initialState,
      sortBy: [{ id: 'issueDate', desc: true }],
    },
  };

  const adminInvoiceReports = useGetForTable('/api/v1/rivendell/invoice-reports', {
    options: {
      enabled: isAdminUser,
      select: (data) => getDataWithRenamedProperty(data, 'invoiceReports'),
    },
    tableOptions: tableOptionsInvoiceReports,
  });

  const employerBillingDocuments = useGetForTable(
    '/api/v1/billing-platform/employer/billing-documents',
    {
      options: {
        enabled: !isAdminUser,
        select: (data) => getDataWithRenamedProperty(data, 'billingDocuments'),
      },
      tableOptions: tableOptionsBillingDocuments,
    }
  );

  let tableProps = null;
  if (isAdminUser) {
    tableProps = adminInvoiceReports;
  } else {
    tableProps = employerBillingDocuments;
  }

  const { tableState } = tableProps;

  useEffect(() => {
    setLocalStorageItem(storageKey, tableState);
  }, [storageKey, tableState]);

  return tableProps;
};
