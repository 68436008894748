import { Field } from 'formik';
import PropTypes from 'prop-types';

import { CheckboxField as InputCheckbox } from '@/src/components/Ui/Form';

import { StyledFormFieldError } from './AckField.styled';

/**
 * AckField
 *
 * Renders a checkbox field
 * Although it looks like a checkbox (and it is an input type="checkbox"),
 * the value saved to Formik is props.checkboxValue
 *
 * @export
 * @param {import('@/src/components/Form/DynamicForm/types').RenderAckField} props
 * @return {JSX.Element}
 */
export default function AckField({ name, checkboxValue, default: defaultVal, onChange, ...props }) {
  const isBooleanCheckbox = typeof checkboxValue === 'boolean';
  return (
    <Field name={name}>
      {({ field: { value, ...field }, form: { setFieldValue } }) => (
        <>
          <InputCheckbox
            // providing a checked prop here ensures that unsupported legacy
            // values from the db do not check a checkbox by default. Coerce both
            // values to string for comparison to account for booleans passed as props
            // See https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/9746
            checked={isBooleanCheckbox ? value : String(value) === String(checkboxValue)}
            name={name}
            // destructure value so that setFieldValue works properly
            {...field}
            {...props}
            onChange={(event) => {
              if (isBooleanCheckbox) {
                // This defaults back to regular checkbox behavior if default value is a boolean
                setFieldValue(name, event.target.checked);
                onChange?.(event.target.checked);
              } else {
                // Pass to formik the checkboxValue instead of checkbox native
                // value of "true"/"false". This saves us from extra workarounds
                // down in the road when dealing with DynamicForm and submit
                const checkedValue = event.target.checked ? checkboxValue : '';
                setFieldValue(name, checkedValue);
                onChange?.(checkedValue);
              }
            }}
          />
          <StyledFormFieldError name={name} />
        </>
      )}
    </Field>
  );
}

AckField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checkboxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    .isRequired,
  onChange: PropTypes.func,
};
