import { fieldToLabel as defaultFieldToLabel, fieldsSimplified } from './constants';

export function displayFieldInfoBlock({ field, formFieldValue, keepFieldName }) {
  const fieldToLabel = field.displayLunchTime
    ? {
        ...defaultFieldToLabel,
        ...(field.customLabels ?? {}),
      }
    : { ...fieldsSimplified, ...(field.customLabels ?? {}) };

  const subfields = field.displayLunchTime
    ? ['startTime', 'endTime', 'lunchStartTime', 'lunchEndTime', 'workingHours']
    : ['startTime', 'endTime', 'workingHours'];

  return {
    title: `${field.label}`,
    ...(keepFieldName ? { name: field.name } : {}),
    value: subfields.map((fieldName) => ({
      ...(keepFieldName ? { name: fieldName } : {}),
      title: fieldToLabel[fieldName],
      value: formFieldValue?.[fieldName],
    })),
  };
}
