export const teamPlanStatus = {
  CREATED: 'created',
  SHARED: 'shared',
  ACCEPTED: 'accepted',
  ARCHIVED: 'archived',
  OUT_OF_SEATS: 'out_of_seats',
  EXPIRED: 'expired',
};

export const teamPlanStatusLabels = {
  [teamPlanStatus.CREATED]: 'Draft',
  [teamPlanStatus.SHARED]: 'Pending approval',
  [teamPlanStatus.ACCEPTED]: 'Active',
  [teamPlanStatus.ARCHIVED]: 'Archived',
  [teamPlanStatus.OUT_OF_SEATS]: 'Out of seats',
  [teamPlanStatus.EXPIRED]: 'Expired',
};

export const teamPlanStatusBadge = {
  [teamPlanStatus.CREATED]: 'scheduled',
  [teamPlanStatus.SHARED]: 'pending',
  [teamPlanStatus.ACCEPTED]: 'active',
  [teamPlanStatus.ARCHIVED]: 'inactive',
  [teamPlanStatus.OUT_OF_SEATS]: 'error',
  [teamPlanStatus.EXPIRED]: 'error',
};

export const teamPlanStatusOptions = Object.entries(teamPlanStatusLabels).map(([value, label]) => ({
  value,
  label,
}));

export const TEAM_PLAN_ACTIONS = {
  CREATE: 'create',
  EDIT: 'edit',
  SEND: 'send',
};

export const teamPlanBillingFrequencies = {
  ANNUAL: 'annual',
  QUARTERLY: 'quarterly',
};

export const teamPlanBillingFrequencyOptions = [
  { label: 'Annually', value: teamPlanBillingFrequencies.ANNUAL },
  { label: 'Quarterly', value: teamPlanBillingFrequencies.QUARTERLY },
];

export const teamPlanSeatDurationStrategies = {
  UNBOUNDED: 'unbounded',
};

export const teamPlanSeatDurationStrategyLabels = {
  [teamPlanSeatDurationStrategies.UNBOUNDED]: 'Unbound',
};

export const teamPlanSeatDurationStrategyOptions = Object.entries(
  teamPlanSeatDurationStrategyLabels
).map(([value, label]) => ({
  value,
  label,
}));

export const teamPlanSeatStatus = {
  ACTIVE: 'active',
  IN_USE: 'in_use',
  INACTIVE: 'inactive',
  RESERVED: 'reserved',
  OCCUPIED: 'occupied',
};

export const teamPlanSeatStatusOptions = [
  { label: 'Active seats', value: teamPlanSeatStatus.ACTIVE },
  { label: 'Seats in use', value: teamPlanSeatStatus.IN_USE },
  { label: 'Reserved seats', value: teamPlanSeatStatus.RESERVED },
  { label: 'Inactive seats', value: teamPlanSeatStatus.INACTIVE },
];
