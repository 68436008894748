import type { BadgeType } from '@remote-com/norma';

import type {
  ExtraEntitlementStatus,
  ExtraEntitlementType,
} from '@/src/api/config/employ/extraEntitlements.types';
import type {
  BalanceDetailsItemType,
  LeavePolicyVariantStatus,
} from '@/src/api/config/employ/leavePolicies.types';
import type {
  AllPossibleTimeOffType,
  TimeOffStatus,
  TimeoffType,
} from '@/src/api/config/employ/timeoff.types';
import { generateSelectOptions } from '@/src/helpers/forms';

/**
 * This list corresponds with the time off types we
 * receive from the backend. One mustn't add a new
 * type if the backend doesn't recognize it.
 */
export const timeOffType = {
  PAID_TIME_OFF: 'paid_time_off', // for employees
  TIME_OFF: 'time_off', // for contractors
  SICK_LEAVE: 'sick_leave',
  PUBLIC_HOLIDAY: 'public_holiday',
  UNPAID_LEAVE: 'unpaid_leave',
  EXTENDED_LEAVE: 'extended_leave',
  IN_LIEU_TIME: 'in_lieu_time',
  MATERNITY_LEAVE: 'maternity_leave',
  PATERNITY_LEAVE: 'paternity_leave',
  PARENTAL_LEAVE: 'parental_leave',
  BEREAVEMENT: 'bereavement',
  MILITARY_LEAVE: 'military_leave',
  OTHER: 'other',
  CASUAL_LEAVE: 'casual_leave',
} satisfies Record<Uppercase<TimeoffType>, TimeoffType>;

export const timeOffTypeActiveLabels = {
  [timeOffType.PAID_TIME_OFF]: 'Paid time off',
  [timeOffType.TIME_OFF]: 'Time off',
  [timeOffType.SICK_LEAVE]: 'Sick',
  [timeOffType.PUBLIC_HOLIDAY]: 'Public holiday',
  [timeOffType.PARENTAL_LEAVE]: 'Parental leave',
  [timeOffType.PATERNITY_LEAVE]: 'Paternity leave',
  [timeOffType.MATERNITY_LEAVE]: 'Maternity leave',
  [timeOffType.UNPAID_LEAVE]: 'Unpaid leave',
  [timeOffType.EXTENDED_LEAVE]: 'Extended leave',
  [timeOffType.BEREAVEMENT]: 'Bereavement',
  [timeOffType.IN_LIEU_TIME]: 'In lieu time',
  [timeOffType.MILITARY_LEAVE]: 'Military leave',
  [timeOffType.OTHER]: 'Other',
  [timeOffType.CASUAL_LEAVE]: 'Casual leave',
} satisfies Record<TimeoffType, string>;

export const timeOffTypeLabels = {
  ...timeOffTypeActiveLabels,
};

export const timeOffTypeOptions = generateSelectOptions(timeOffTypeLabels);
export const timeOffTypeActiveOptions = generateSelectOptions(timeOffTypeActiveLabels);

export const allPossibleTimeOffType = {
  ...timeOffType,
  EX_FESTIVITA: 'ex_festivita',
  ROL: 'rol',
  CUSTOM_COMPANY_LEAVE: 'custom_company_leave',
} satisfies Record<Uppercase<AllPossibleTimeOffType>, AllPossibleTimeOffType>;

export const statutoryTimeOffTypeLabels = {
  ...timeOffTypeLabels,
  [allPossibleTimeOffType.EX_FESTIVITA]: 'Ex Festività',
  [allPossibleTimeOffType.ROL]: 'ROL',
} satisfies Record<
  Exclude<AllPossibleTimeOffType, typeof allPossibleTimeOffType.CUSTOM_COMPANY_LEAVE>,
  string
>;

export const when = {
  UPCOMING: 'upcoming',
  NOW: 'now',
  PAST: 'past',
};

export const whenOptions = [
  {
    label: 'Upcoming',
    value: when.UPCOMING,
  },
  {
    label: 'Now',
    value: when.NOW,
  },
  {
    label: 'Past',
    value: when.PAST,
  },
];

export const timeOffStatus = {
  REQUESTED: 'requested', // Means it was requested by the employee
  CANCEL_REQUESTED: 'cancel_requested', // Means the employee asked employer to cancel and is waiting for approval
  APPROVED: 'approved', // Means it was approved
  TAKEN: 'taken', // Means the time off was used
  CANCELLED: 'cancelled', // Means it was canceled by the employee
  DECLINED: 'declined', // Means it was declined by the employer
} satisfies Record<Uppercase<TimeOffStatus>, TimeOffStatus>;

export const timeOffStatusLabels = {
  [timeOffStatus.REQUESTED]: 'Requested',
  [timeOffStatus.CANCEL_REQUESTED]: 'Cancel requested',
  [timeOffStatus.APPROVED]: 'Approved',
  [timeOffStatus.TAKEN]: 'Taken',
  [timeOffStatus.CANCELLED]: 'Canceled',
  [timeOffStatus.DECLINED]: 'Declined',
} satisfies Record<TimeOffStatus, string>;

export const timeOffStatusOptions = generateSelectOptions(timeOffStatusLabels);

export const timeOffStatusBadgeMapping = {
  [timeOffStatus.REQUESTED]: 'pending',
  [timeOffStatus.CANCEL_REQUESTED]: 'pending',
  [timeOffStatus.APPROVED]: 'active',
  [timeOffStatus.TAKEN]: 'info',
  [timeOffStatus.CANCELLED]: 'inactive',
  [timeOffStatus.DECLINED]: 'error',
} satisfies Record<TimeOffStatus, BadgeType>;

export const extraEntitlementStatus = {
  ACTIVE: 'active',
  TAKEN: 'taken',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
} satisfies Record<Uppercase<ExtraEntitlementStatus>, ExtraEntitlementStatus>;

export const extraEntitlementStatusBadgeMapping = {
  [extraEntitlementStatus.ACTIVE]: 'active',
  [extraEntitlementStatus.TAKEN]: 'info',
  [extraEntitlementStatus.CANCELED]: 'inactive',
  [extraEntitlementStatus.EXPIRED]: 'error',
} satisfies Record<ExtraEntitlementStatus, BadgeType>;

export const extraLeaveType = {
  CARRYOVER: 'carryover',
  ADDITIONAL: 'additional',
  TRANSFER: 'transfer',
} satisfies Record<Uppercase<ExtraEntitlementType>, ExtraEntitlementType>;

export const extraLeaveLabels = {
  [extraLeaveType.CARRYOVER]: 'Carryover leave',
  [extraLeaveType.ADDITIONAL]: 'Additional leave',
  [extraLeaveType.TRANSFER]: 'Transferred leave',
} satisfies Record<ExtraEntitlementType, string>;

export const extraLeaveSummaryType = {
  ...extraLeaveType,
  BASE: 'base',
} satisfies Record<Uppercase<BalanceDetailsItemType>, BalanceDetailsItemType>;

export const leaveTimeOffSummaryLabels = {
  [extraLeaveSummaryType.CARRYOVER]: 'Carryover',
  [extraLeaveSummaryType.ADDITIONAL]: 'Additional',
  [extraLeaveSummaryType.TRANSFER]: 'Transferred',
  [extraLeaveSummaryType.BASE]: 'Annual paid time off',
} satisfies Record<BalanceDetailsItemType, string>;

export const extraLeaveTypeOptions = generateSelectOptions(extraLeaveLabels);
export const DEFAULT_WORK_HOURS = 8;

export const extraEntitlementStatusLabels = {
  [extraEntitlementStatus.ACTIVE]: 'Active',
  [extraEntitlementStatus.TAKEN]: 'Taken',
  [extraEntitlementStatus.CANCELED]: 'Canceled',
  [extraEntitlementStatus.EXPIRED]: 'Expired',
} satisfies Record<ExtraEntitlementStatus, string>;

export const extraLeaveStatusOptions = generateSelectOptions(extraEntitlementStatusLabels);

// TODO: Investigate if this is still being used
export const AVAILABLE_PTO_TYPE = {
  UNLIMITED: 'unlimited',
  FIXED: 'fixed',
} as const;

export const PTO_BALANCE_APPROVED_COUNTRIES = [
  'AUS',
  'AUT',
  'CAN',
  'CHE',
  'DEU',
  'ESP',
  'GBR',
  'GRC',
  'IND',
  'IRL',
  'ITA',
  'KEN',
  'LUX',
  'MEX',
  'NLD',
  'PHL',
  'POL',
  'PRT',
  'SGP',
  'SWE',
  'USA',
  'ZAF',
];

export const leavePolicyVariantStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DRAFT: 'draft',
} satisfies Record<Uppercase<LeavePolicyVariantStatus>, LeavePolicyVariantStatus>;

export const leavePolicyVariantStatusLabels = {
  [leavePolicyVariantStatus.ACTIVE]: 'Active',
  [leavePolicyVariantStatus.ARCHIVED]: 'Archived',
  [leavePolicyVariantStatus.DRAFT]: 'Draft',
} satisfies Record<LeavePolicyVariantStatus, string>;

export const leavePolicyVariantStatusBadgeMapping = {
  [leavePolicyVariantStatus.ACTIVE]: 'active',
  [leavePolicyVariantStatus.ARCHIVED]: 'inactive',
  [leavePolicyVariantStatus.DRAFT]: 'pending',
} satisfies Record<LeavePolicyVariantStatus, BadgeType>;

export const leavePolicyVariantStatusOptions = generateSelectOptions(
  leavePolicyVariantStatusLabels
);

export const leavePolicyVariantStatusBadges = {
  [leavePolicyVariantStatus.ACTIVE]: 'active',
  [leavePolicyVariantStatus.ARCHIVED]: 'archived',
  [leavePolicyVariantStatus.DRAFT]: 'pending',
} satisfies Record<LeavePolicyVariantStatus, BadgeType>;
