import axios from 'axios';

import { googleTagManager } from '../googleTagManager';

export const fetchCookiesConfig = async ({ cookiesConfigURL }) => {
  try {
    const { data: sharedConfig } = await axios.get(cookiesConfigURL);

    const configWithEventHandlers = {
      ...sharedConfig,
      position: 'right',
      closeStyle: 'icon',
      branding: {
        ...sharedConfig.branding,
        fontFamily: 'inherit',
        toggleBackground: '#0061ff',
      },
      optionalCookies: sharedConfig.optionalCookies.map((obj) => ({
        ...obj,
        onAccept: () => {
          googleTagManager.trackCookieConsent({ accept: true, name: obj.name });
        },
        onRevoke: () => {
          googleTagManager.trackCookieConsent({ accept: true, name: obj.name });
        },
      })),
    };

    return configWithEventHandlers;
  } catch (error) {
    console.error(error);
    return {};
  }
};
