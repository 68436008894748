import { useGet } from '@remote-com/data-layer';
import camelcaseKeys from 'camelcase-keys';
import omit from 'lodash/omit';
import { useMemo } from 'react';

import {
  BasicSelectFilter,
  BasicTextFilter,
  DateCell,
  DateRangeFilter,
  TextCell,
  AutoCell,
} from '@/src/components/Table';
import { useUserCan } from '@/src/components/UserCan';
import {
  CUSTOM_FIELD_ACCESSOR_PREFIX,
  CUSTOM_FIELD_ID_PREFIX,
  CUSTOM_FIELD_TYPES,
} from '@/src/domains/customFields/constants';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { captureException } from '@/src/helpers/captureException';

// The field definition name must be camelCased using the 'camelcase-keys' package
// so that it follows the same transformation rules as the ApiClient does
// when processing the `GET '/api/v1/employer/custom-field-definitions'` request
const camelCaseDefinitionName = (name) => {
  const [fieldName] = Object.keys(camelcaseKeys({ [name]: {} }));
  return fieldName;
};
const hasCustomFieldPrefix = (str) => str.includes(CUSTOM_FIELD_ID_PREFIX);
const BOOLEAN_FIELD_SELECT_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const getCustomFieldFilter = (definition, props) => {
  switch (definition.dataType) {
    case CUSTOM_FIELD_TYPES.date:
      return <DateRangeFilter {...props} />;
    case CUSTOM_FIELD_TYPES.boolean:
      return (
        <BasicSelectFilter
          {...props}
          options={BOOLEAN_FIELD_SELECT_OPTIONS}
          withSearchIcon={false}
        />
      );
    case CUSTOM_FIELD_TYPES.string:
    case CUSTOM_FIELD_TYPES.text:
      return <BasicTextFilter {...props} />;
    case CUSTOM_FIELD_TYPES.integer:
      return <BasicTextFilter type="number" {...props} />;
    case CUSTOM_FIELD_TYPES.singleSelect:
      return (
        <BasicSelectFilter
          {...props}
          options={definition?.metadata?.options?.map((option) => {
            return {
              value: option.value,
              label: option.label,
            };
          })}
        />
      );
    default:
      throw new Error(`Unsupported custom field data type: '${definition.dataType}'`);
  }
};

export const getCustomFieldCell = (definition) => {
  switch (definition.dataType) {
    case CUSTOM_FIELD_TYPES.date:
      return DateCell;
    case CUSTOM_FIELD_TYPES.string:
    case CUSTOM_FIELD_TYPES.text:
    case CUSTOM_FIELD_TYPES.integer:
    case CUSTOM_FIELD_TYPES.decimal:
      return TextCell;
    case CUSTOM_FIELD_TYPES.boolean:
      return AutoCell;
    case CUSTOM_FIELD_TYPES.singleSelect:
      return TextCell;
    default:
      throw new Error(`Unsupported custom field data type: '${definition.dataType}'`);
  }
};

export const useTeamMembersCustomFieldDefinitions = () => {
  const { userCan } = useUserCan();
  return useGet('/api/v1/employer/custom-field-definitions', {
    params: {
      queryParams: {
        definitionKey: 'team_members',
        pageSize: 100,
      },
    },
    options: {
      select: (res) =>
        res.data?.customFieldDefinitions.map((definition) => {
          const camelCasedName = camelCaseDefinitionName(definition.name);

          return {
            ...definition,
            // Augment a definition with its `columnId` and `filterId`
            columnId: `${CUSTOM_FIELD_ACCESSOR_PREFIX}${camelCasedName}`,
            filterId: `${CUSTOM_FIELD_ID_PREFIX}${camelCasedName}`,
          };
        }),
      enabled:
        userCan('read', Resources.employer.custom_field_definitions) &&
        userCan('read', Resources.employer.custom_field_values),
    },
  });
};
/**
 * Transform a request params so that custom field filters are grouped together
 * in a `customFieldDefinitions` object where its keys represent the custom
 * field definition `slug` and its properties the filter value
 * Input:
 * {
 *   queryParams: {
 *     status: 'active',
 *     'data-customFields.project': 'Area 51',
 *   }.
 * }
 * Output:
 * {
 *   queryParams: {
 *     status: 'active',
 *     customFieldDefinitions: {
 *       '4e690c79-0f7e-4457-bf42-10ce3c046381': 'Area 51',
 *     },
 *   },
 * }
 * @param {Array} definitions Custom field definitions
 * @param {Object} params Params of an API endpoint
 */
export const transformCustomFieldsParams = (definitions = [], params) => {
  if (definitions?.length <= 0) return params;

  const { queryParams } = params;
  const customFields = Object.keys(queryParams).filter(hasCustomFieldPrefix);
  if (customFields?.length <= 0) return params;

  const queryParamsWithoutCustomFields = omit(queryParams, customFields);
  const customFieldQueryParams = {
    customFieldDefinitions: customFields.reduce((acc, filterId) => {
      const definition = definitions.find((x) => x.filterId === filterId);
      if (!definition) return acc;

      return { ...acc, [definition.slug]: queryParams[filterId] };
    }, {}),
  };

  return {
    ...params,
    queryParams: {
      ...queryParamsWithoutCustomFields,
      ...customFieldQueryParams,
    },
  };
};

/**
 * Construct `specialAdditionalColumns` for custom field definitions so that
 * these can be used to filter a table
 * @param {Object[]} definitions Custom field definitions
 * @param {Object} initialState The table initial state
 */
export const useBuildCustomFieldsColumnsConfig = (definitions = [], initialState = {}) => {
  const { filters = [] } = initialState;
  const filterIds = new Set(filters.map((filter) => filter.id));

  const specialAdditionalColumnsConfig = useMemo(
    () =>
      definitions.reduce((acc, definition) => {
        try {
          return {
            ...acc,
            [definition.columnId]: {
              Filter: (props) => getCustomFieldFilter(definition, props),
              Cell: getCustomFieldCell(definition),
              accessor: (rowData) => {
                const [customFieldsObj, customFieldsField] = definition.columnId.split('.');
                // The custom field name string can have special characters, so we need to use the bracket notation
                return rowData[customFieldsObj][customFieldsField];
              },
              disableFilters: false,
              // Shows the column in table view if filter is currently applied
              // This is needed when the table is loaded with the filter in URL params
              ...(filterIds.has(definition.filterId)
                ? {
                    disableInView: false,
                  }
                : {}),
            },
          };
        } catch (exception) {
          captureException(exception);
          return acc;
        }
      }, {}),
    // `filterIds` is intentionally excluded from the dependencies array so that
    // only the initial configuration and visibility properties are computed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [definitions]
  );

  return { specialAdditionalColumnsConfig };
};
