import { slideInFromBottom, Box, Stack } from '@remote-com/norma';
import { IconChevronRight } from '@remote-com/norma/icons/IconChevronRight';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';
import { ButtonClose } from '@/src/components/Button/ButtonClose';

export const Card = styled(Box)`
  --spacing: ${({ theme }) => theme.space[7]}px;

  position: relative;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  padding: var(--spacing);
  background-color: var(--colors-blank);
  color: ${({ theme }) => theme.colors.grey[900]};
  border-radius: 10px;

  ${(props) => slideInFromBottom(props.animate, props.animateMaxItems)}

  & + & {
    margin-top: var(--spacing);
  }
`;

Card.defaultProps = {
  direction: 'row',
  animate: false,
  animateMaxItems: 0,
};

export const CardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardImgContainer = styled(Box)`
  max-width: 25%;
  align-self: center;
  & > svg {
    width: 100%;
  }
`;

export const CardTitle = styled.h2`
  ${({ theme }) => theme.typography.xlMedium}
  ${({ noMargin, theme }) => !noMargin && `margin-bottom: ${theme.space[5]}px;`}

  flex-grow: 1;
`;

export const CardTitleWithAction = ({ children, action }) => (
  <Stack direction="horizontal" alignItems="center">
    <CardTitle noMargin>{children}</CardTitle>
    {action}
  </Stack>
);

export const CardBody = styled(Box)`
  ${({ theme }) => theme.typography.base}
  color: var(--colors-bayoux);
  flex-grow: 1;
`;

const ButtonCTA = styled(Button)`
  margin-left: -10px;
  min-height: auto;
  min-width: auto;
  padding: 6px 10px;
`;

const ButtonIconSvg = styled(IconChevronRight)`
  transition: transform 150ms ease-in-out;

  ${ButtonCTA}:hover &,
  ${ButtonCTA}:focus & {
    transform: translate(3px);
  }
`;

export const CardCTA = (props) => {
  const { allowDismiss, actionRequired, onDismiss, onClick, ...forwardedProps } = props;

  const handleClick = () => {
    if (onClick) onClick();
    if (allowDismiss && !actionRequired && onDismiss) {
      onDismiss();
    }
  };

  return (
    <ButtonCTA
      data-testid="cta-button"
      variant="ghost"
      {...forwardedProps}
      IconAfter={ButtonIconSvg}
      onClick={handleClick}
    />
  );
};

export const CardCTAsContainer = styled(Box)`
  margin-top: 16px;

  *:not(:first-child) {
    margin-left: 35px;
  }
`;

export const CardCloseButton = styled(ButtonClose)`
  position: absolute;
  top: 12px;
  right: 12px;
`;
