// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineArrowCornerCw = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M21 5.25c.41 0 .75.34.75.75v.63c0 1.1 0 1.96-.06 2.66a4.88 4.88 0 0 1-.46 1.87 4.75 4.75 0 0 1-2.07 2.07c-.55.28-1.16.4-1.87.46-.7.06-1.56.06-2.66.06H4.81l3.72 3.72a.75.75 0 1 1-1.06 1.06l-5-5a.75.75 0 0 1 0-1.06l5-5a.75.75 0 0 1 1.06 1.06l-3.72 3.72h9.79c1.13 0 1.94 0 2.57-.05.62-.05 1-.15 1.3-.3.62-.32 1.11-.81 1.43-1.42.15-.3.25-.7.3-1.31.05-.63.05-1.44.05-2.57V6c0-.41.34-.75.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineArrowCornerCw);
export { ForwardRef as IconV2OutlineArrowCornerCw };