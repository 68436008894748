// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineExternalLink = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M9.38 2.25H10a.75.75 0 1 1 0 1.5h-.5c-1.44 0-2.02 0-2.5.12A4.25 4.25 0 0 0 3.88 7c-.11.47-.12 1.05-.12 2.49V13c0 1.41 0 2.43.07 3.22.06.79.18 1.3.4 1.7.4.8 1.05 1.46 1.85 1.87.41.2.92.33 1.7.4.8.06 1.82.06 3.23.06h3.5c1.44 0 2.02 0 2.5-.12A4.25 4.25 0 0 0 20.12 17c.11-.47.12-1.05.12-2.49V14a.75.75 0 0 1 1.5 0v.62c0 1.28 0 2.06-.16 2.72a5.75 5.75 0 0 1-4.25 4.25c-.66.16-1.44.16-2.72.16h-3.65c-1.38 0-2.45 0-3.32-.07a5.92 5.92 0 0 1-2.26-.56 5.75 5.75 0 0 1-2.51-2.51 5.92 5.92 0 0 1-.56-2.26c-.07-.87-.07-1.94-.07-3.32V9.38c0-1.28 0-2.06.16-2.72A5.75 5.75 0 0 1 6.66 2.4c.66-.16 1.44-.16 2.72-.16Zm4.87.75c0-.41.34-.75.75-.75h6c.41 0 .75.34.75.75v6a.75.75 0 0 1-1.5 0V4.81l-7.72 7.72a.75.75 0 1 1-1.06-1.06l7.72-7.72H15a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineExternalLink);
export { ForwardRef as IconV2OutlineExternalLink };