import { FormGroup, useFormGroupContext, Input } from '@remote-com/norma';
import { IconEye } from '@remote-com/norma/icons/IconEye';
import { IconEyeOff } from '@remote-com/norma/icons/IconEyeOff';
import { useState } from 'react';

import { ButtonIcon } from '@/src/components/Button';

function InputElement({ isRevealingPassword }) {
  const inputProps = useFormGroupContext();

  return <Input {...inputProps} type={isRevealingPassword ? 'text' : 'password'} />;
}
export const InputPassword = (props) => {
  const [isRevealingPassword, setRevealPassword] = useState(false);

  function handleRevealPassword(event) {
    event.stopPropagation();

    setRevealPassword(!isRevealingPassword);
  }

  return (
    <FormGroup
      {...props}
      aside={() => (
        <ButtonIcon
          Icon={isRevealingPassword ? IconEyeOff : IconEye}
          variant="ghost"
          size="sm"
          tone="secondary"
          label={isRevealingPassword ? 'Hide password' : 'Reveal password'}
          aria-pressed={isRevealingPassword}
          onClick={handleRevealPassword}
        />
      )}
    >
      <InputElement isRevealingPassword={isRevealingPassword} />
    </FormGroup>
  );
};
