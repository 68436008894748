import type { SingleValueProps } from 'react-select';
import styled, { css } from 'styled-components';

import { Box } from '../../../layout';
import type { FormGroupLabelPlacement, FormGroupSize } from '../../form-group';
import { getInputColors, inputStyles } from '../../form-group';

const StyledSingleValue = styled(Box)<{
  $size?: FormGroupSize;
  $isDisabled?: boolean;
  $isClearable: boolean;
  size: FormGroupSize;
  labelPlacement: FormGroupLabelPlacement;
}>`
  ${inputStyles}

  ${({ $size, theme }) => $size === 'sm' && theme.typography.sm}
  ${({ size }) =>
    size === 'sm' &&
    css`
      margin-top: 1px;
    `}

  align-items: center;
  justify-content: flex-start;
  color: ${({ theme, $isDisabled }) => $isDisabled && getInputColors(theme).textValueReadOnly};
  inset: 0;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
`;

export function SingleValue({
  children,
  isDisabled,
  selectProps: { isClearable, size },
}: SingleValueProps & {
  isDisabled: boolean;
  selectProps: { isClearable: boolean; size: FormGroupSize };
}) {
  return (
    <StyledSingleValue
      size={size}
      labelPlacement="inside"
      $isDisabled={isDisabled}
      $isClearable={isClearable}
      $size={size}
    >
      {children}
    </StyledSingleValue>
  );
}
