import type { BadgeType } from '@remote-com/norma';

import type { NiumOnboardingStatus } from '@/src/api/config/employ/employerKyb.types';

export const NIUM_ONBOARDING_STATUS = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  INITIATED: 'initiated',
  ACTION_REQUIRED: 'action_required',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  FAILED: 'failed',
  RFI_REQUESTED: 'rfi_requested',
  RFI_RESPONDED: 'rfi_responded',
} as const;

export const NIUM_KYC_STATUS = {
  IGNORED: 'ignored',
  PENDING: 'pending',
  FAILED: 'failed',
  ACCEPTED: 'accepted',
} as const;

export const NIUM_ONBOARDING_STEP_STATUS = {
  AUTOFILLED: 'autofilled',
  PENDING: 'pending',
  COMPLETED: 'completed',
} as const;

export const NIUM_ONBOARDING_SUBMITTED_STATUSES: Array<
  Omit<NiumOnboardingStatus, 'rejected' | 'not-started' | 'failed'>
> = [
  NIUM_ONBOARDING_STATUS.INITIATED,
  NIUM_ONBOARDING_STATUS.IN_PROGRESS,
  NIUM_ONBOARDING_STATUS.RFI_REQUESTED,
  NIUM_ONBOARDING_STATUS.RFI_RESPONDED,
  NIUM_ONBOARDING_STATUS.ACTION_REQUIRED,
  NIUM_ONBOARDING_STATUS.COMPLETED,
];

export const NIUM_ONBOARDING_ACTIVE_STATUSES: Array<Omit<NiumOnboardingStatus, 'failed'>> = [
  NIUM_ONBOARDING_STATUS.REJECTED,
  NIUM_ONBOARDING_STATUS.NOT_STARTED,
  ...NIUM_ONBOARDING_SUBMITTED_STATUSES,
];

export const NIUM_ONBOARDING_STATUS_LABELS: Record<
  (typeof NIUM_ONBOARDING_STATUS)[keyof typeof NIUM_ONBOARDING_STATUS],
  string
> = {
  not_started: 'Not Started',
  in_progress: 'In Progress',
  initiated: 'Initiated',
  action_required: 'Action Required',
  completed: 'Completed',
  rejected: 'Rejected',
  failed: 'Failed',
  rfi_requested: 'RFI Requested',
  rfi_responded: 'RFI Responded',
};

export const niumStatusToBadgeType: Record<string, BadgeType> = {
  not_started: 'inactive',
  in_progress: 'pending',
  rfi_requested: 'pending',
  rfi_responded: 'pending',
  action_required: 'pending',
  pending: 'pending',
  ignored: 'pending',
  accepted: 'active',
  completed: 'active',
  rejected: 'error',
  failed: 'error',
  info: 'info',
};

export const legalEntityStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const LEGAL_ENTITY_STATUS_LABELS: Record<
  (typeof legalEntityStatus)[keyof typeof legalEntityStatus],
  string
> = {
  [legalEntityStatus.ACTIVE]: 'Active',
  [legalEntityStatus.ARCHIVED]: 'Archived',
} as const;
