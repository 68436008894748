/**
 * SelectedRowsActions
 *
 * Components to be used for interactions with selected rows in tables.
 */

import type { ButtonProps } from '@remote-com/norma';
import { Stack, DropdownMenu } from '@remote-com/norma';
import { IconV2OutlineChevronDown } from '@remote-com/norma/icons/IconV2OutlineChevronDown';
import { useState } from 'react';
import type { ComponentProps, MouseEvent, PropsWithChildren } from 'react';

import { Button } from '@/src/components/Button';

type BulkEditComponentProps = {
  isLoading?: boolean;
  selectedRows?: unknown[];
};

type SelectedRowsActionButtonProps = {
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
} & BulkEditComponentProps &
  Omit<ButtonProps, 'size' | 'variant'>;

/**
 * Button for interactions with selected rows (editing, approving, …).
 *
 * - If possible, follow copy guidelines for the label (e.g `Approve {count} invoices`).
 * - Adjust `tone` prop when using multiple actions (e.g. `secondary`, `destructive`, …).
 * - Use `SelectedRowsActionsMenuToggle` when your table requires more than two actions.
 *
 * @example
 * // One action
 * <Table BulkEditComponent={<SelectedRowsActionButton onClick={() => {}} />} />
 *
 * @example
 * // Two actions
 * <Table BulkEditComponent={
 *   <>
 *     <SelectedRowsActionButton onClick={() => {}} />
 *     <SelectedRowsActionButton tone="secondary" onClick={() => {}} />
 *   </>
 * } />
 */
export const SelectedRowsActionButton = ({
  children,
  disabled = false,
  tone = 'primary',
  isLoading = false,
  ...props
}: PropsWithChildren<SelectedRowsActionButtonProps>) => {
  return (
    <Button {...props} disabled={disabled || isLoading} tone={tone} variant="outline" size="sm">
      {children}
    </Button>
  );
};

type SelectedRowsActionsDropdownMenuProps = { label?: string } & BulkEditComponentProps &
  ComponentProps<typeof DropdownMenu>;

/**
 * Wrapper around our DropdownMenu component for use in the table toolbar.
 *
 * Menu items are exported as a compound component with name `Item`.
 *
 * @example
 * <Table BulkEditComponent={(props) => (
 *   <SelectedRowsActionsDropdownMenu {...props}>
 *     <SelectedRowsActionsDropdownMenu.Item onSelect={() => {}}>Edit selected rows</SelectedRowsActionsDropdownMenu.Item>
 *   </SelectedRowsActionsDropdownMenu>
 * )} />
 */
export const SelectedRowsActionsDropdownMenu = ({
  children,
  isLoading = false,
  selectedRows = [],
  label,
}: SelectedRowsActionsDropdownMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const selectedRowsCount = selectedRows.length;

  const defaultLabel =
    selectedRowsCount === 1
      ? `${selectedRowsCount} item selected`
      : `${selectedRowsCount} items selected`;

  return (
    <Stack position="relative">
      <SelectedRowsActionButton
        disabled={isLoading}
        onClick={() => setOpen(!open)}
        IconAfter={IconV2OutlineChevronDown}
      >
        {label || defaultLabel}
      </SelectedRowsActionButton>
      <DropdownMenu
        align="start"
        buttonVariant="ghost"
        dropdownOpen={open}
        setDropdownOpen={() => setOpen(!open)}
        showTrigger={false}
      >
        {children}
      </DropdownMenu>
    </Stack>
  );
};

SelectedRowsActionsDropdownMenu.Item = DropdownMenu.Item;
