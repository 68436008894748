export const COLUMN_STATES = {
  /** Hidden in view and in the export file. There is no way to enable this column back.
     * hidden columns are
     used to create filters on values, that are not present in the table. E.g. table has start date, end date;
     but we want a range filter that applies start and end date under the hood. This is pretty much useQuery quirk.
     */
  hidden: 'hidden',
  /**
   * Invisible column, hidden on the page and in the exported file.
   */
  invisible: 'invisible',
  /**
   * Visible column, visible on the page and in the exported file.
   */
  visible: 'visible',
  /**
   * Some columns that only have the disableInView property, are visible in the exported file but are not visible in the table initially. They can be made visible via column configuration.
   */
  export: 'only-export',
  /**
   * Some columns that only have the disableExport property, are only visible in the page and are not visible in the exported file. They can be made invisible via column configuration.
   */
  view: 'only-view',
} as const;
