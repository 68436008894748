import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';

function selectEmployeeOptions({ data }) {
  return {
    currentPage: data.currentPage,
    totalPages: data.totalPages,
    data: data.employments.map((employmentInfo) => {
      const { user, company, employment } = employmentInfo;

      return {
        // When we need the user.slug we use the 'slug' directly
        ...user,
        company,
        employment,
        // When we need the employment.slug we can use the 'employment.slug' or 'employmentSlug' directly
        employmentSlug: employment?.slug,
        employmentShortSlug: employment?.shortSlug,
        currency: employment?.contract?.country?.currency,
      };
    }),
  };
}

export function EmployeeSelectField({ filters = {}, showEmploymentCountry, ...selectFieldProps }) {
  return (
    <PaginatedSearchSelectField
      data-testid="employee-select-field"
      query={{
        path: '/api/v1/rivendell/employees',
        params: {
          queryParams: filters,
        },
        options: {
          select: selectEmployeeOptions,
        },
      }}
      loadingMessage={() => 'Loading employees…'}
      getOptionLabel={(option) => {
        const country = option?.employment?.contract?.country?.name;
        let label = `${option.name} (${option.employmentShortSlug})`;

        if (showEmploymentCountry && country) {
          label += ` - ${country}`;
        }

        return label;
      }}
      getOptionValue={(option) => option.slug}
      disableReactSelectSearch
      {...selectFieldProps}
    />
  );
}
