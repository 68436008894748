import { useWidgetData } from '@/src/domains/reports/hooks/useWidgetData';
import type { ReportWidget } from '@/src/domains/reports/types';

type ReportingLibraryWidgetProps = {
  widget: ReportWidget;
};

export default function ReportingLibraryWidget({ widget }: ReportingLibraryWidgetProps) {
  const { slug, Component } = widget;

  const reportData = useWidgetData({
    slug,
    options: { enabled: !!slug },
    path: widget?.path,
  });

  const { data, isLoading } = reportData;

  if ((!data && !isLoading) || !Component) {
    return null;
  }

  return <Component data={data} isLoading={isLoading} />;
}
