import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';

import { DEFAULT_SEPARATOR_COLOR } from '@/src/components/Separator/constants';

const StyledSeparatorList = styled.ul`
  --separator-list-border-color: ${DEFAULT_SEPARATOR_COLOR};

  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SeparatorList = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(
  (
    {
      // To ensure screen readers correctly announce list items in Safari, we need to set the appropriate role.
      // This is necessary because setting `list-style: none` removes the semantic meaning in Safari.
      role = 'list',
      ...props
    },
    ref
  ) => {
    return <StyledSeparatorList ref={ref} role={role} {...props} />;
  }
);

const StyledSeparatorListItem = styled.li`
  &:not(:first-child) {
    border-top: 1px solid var(--separator-list-border-color);
  }
`;

export const SeparatorListItem = forwardRef<HTMLLIElement, HTMLAttributes<HTMLLIElement>>(
  (props, ref) => {
    return <StyledSeparatorListItem ref={ref} {...props} />;
  }
);
