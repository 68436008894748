import { trackEvent, signInEvents } from '@remote-com/analytics';
import { usePost } from '@remote-com/data-layer';
import { FeedbackMessage, FEEDBACK_MESSAGE_ERROR, Box, Text, Stack } from '@remote-com/norma';
import { Formik } from 'formik';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { object } from 'yup';

import { Button } from '@/src/components/Button';
import { Modal } from '@/src/components/Modal';
import { InputField } from '@/src/components/Ui/Form';
import { captureHTTPException } from '@/src/helpers/captureException';
import { emailSchema } from '@/src/helpers/validationSchema';

export function ResetPasswordModal({
  onClose,
  email: passedEmail,
}: {
  onClose: () => void;
  email: string;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  const { mutate, isLoading } = usePost('/api/v1/reset-password');

  const initialValues = { email: passedEmail };
  const validationEmailSchema = object().shape({
    email: emailSchema.required(),
  });

  function handleSubmission({ email }: { email: string }) {
    setErrorMessage('');
    mutate(
      {
        bodyParams: {
          user: {
            email,
          },
        },
      },
      {
        onSuccess: () => {
          setPasswordResetSuccess(true);
        },
        onError: (exception) => {
          const errMsg = 'We were not able to send you an email with password reset instructions.';
          setErrorMessage(errMsg);
          trackEvent(signInEvents.RECOVER_PASSWORD_SUCCESS, {
            locationOfAction: 'recover-password-form',
            eventType: 'reset-failed',
            resultFeedback: errMsg,
          });
          captureHTTPException(exception);
        },
      }
    );
  }

  return (
    <Modal
      visible
      headerTitle={passwordResetSuccess ? '' : 'Forgot password?'}
      confirmLoading={isLoading}
      saveButtonText="Send reset link"
      showFooter={!passwordResetSuccess}
      onCancel={() => {
        setErrorMessage('');
        onClose();
      }}
      formName="reset-password"
    >
      {passwordResetSuccess ? (
        <Stack alignItems="center" gap={6} mb={5}>
          <Text variant="2xlMedium">Email on the way!</Text>
          <Text variant="sm">
            We sent you password reset instructions. If it doesn't show up soon, check your spam
            folder. We sent it from the email address <strong>no‑reply@remote.com</strong>.
          </Text>
          <Button
            isLoading={isLoading}
            tone="primary"
            data-testid="modal-save-button"
            onClick={() => {
              onClose();
            }}
          >
            Continue
          </Button>
        </Stack>
      ) : (
        <div>
          <Text variant="sm" mb={6}>
            All good. Enter your account's email address and we’ll send you a link to reset your
            password.
          </Text>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmission}
            validationSchema={validationEmailSchema}
            enableReinitialize
          >
            {({ errors, handleSubmit, touched, handleChange }) => {
              return (
                <form id="reset-password" onSubmit={handleSubmit} noValidate>
                  <Box mb={5}>
                    <InputField
                      id="email"
                      name="email"
                      type="email"
                      label="Email address"
                      autoComplete="username"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event);
                      }}
                      errorText={touched.email && errors.email ? errors.email : ''}
                      required
                    />
                  </Box>
                </form>
              );
            }}
          </Formik>
          {errorMessage && (
            <FeedbackMessage variant={FEEDBACK_MESSAGE_ERROR}>{errorMessage}</FeedbackMessage>
          )}
        </div>
      )}
    </Modal>
  );
}

export default ResetPasswordModal;
