// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneCommentDots = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M9.25 1.25c-2.8 0-4.2 0-5.27.54a5 5 0 0 0-2.19 2.19c-.54 1.07-.54 2.47-.54 5.27v3.5c0 2.8 0 4.2.54 5.27a5 5 0 0 0 2.19 2.18c1.07.55 2.47.55 5.27.55h5.33c.82 0 1.23 0 1.63.07a5 5 0 0 1 1.04.28c.38.15.74.37 1.44.79l.43.25c1.18.72 1.78 1.07 2.27 1.03a1.5 1.5 0 0 0 1.07-.6c.29-.4.29-1.1.29-2.48V9.25c0-2.8 0-4.2-.55-5.27a5 5 0 0 0-2.18-2.19c-1.07-.54-2.47-.54-5.27-.54h-5.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M6.25 11a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm4.5 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm4.5 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneCommentDots);
export { ForwardRef as IconV2DuotoneCommentDots };