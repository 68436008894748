import { Field, useField } from 'formik';
import PropTypes from 'prop-types';

import { InputCheckbox } from '@/src/components/Ui/Form';

/**
 *
 * @param {Omit<import('@/types').ComponentProps<typeof InputCheckbox>, "Input">}
 * @returns {JSX.Element}
 */

export const CheckboxField = ({ Input, ...props }) => {
  const [, { error, touched }] = useField(props.name);
  return <InputCheckbox Input={Field} {...props} hasError={error && touched} />;
};

CheckboxField.propTypes = {
  /** Label for the input */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Description for the input */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * DEPRECATED: Whether the label should be hidden (works only in v1)
   * @deprecated Works only in v1
   * */
  hideLabel: PropTypes.bool,
};
