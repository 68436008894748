export const CONTRACT_NODE_TYPES = {
  SMART_FIELD: 'smartField',
  // v1 conditionals
  CONDITIONAL_CONTENT: 'conditionalContent',
  // v2 conditionals
  INLINE_CONDITIONAL: 'inlineConditionalNode',
  BLOCK_CONDITIONAL: 'blockConditionalNode',
  LIST_ITEM_CONDITIONAL: 'listItemConditionalNode',
  MANUALLY_ENTERED_FIELD: 'manuallyEnteredField',
  SIGNATURE: 'signatureField',
};

export const CONTRACT_MARK_TYPES = {
  COMMENT_TEXT: 'commentText',
};

export const BLOCK_CON_START_REGEX = /«CON__(.*?)»/g;
export const LIST_ITEM_CON_REGEX = /^«CON__(.*?)«END_CON»/g;
export const INLINE_CON_REGEX = /«CON__(.*?)«END_CON»/g;
export const INLINE_CON_CONTENT_REGEX = /»(.*?)«END_CON»/;
export const CON_END_REGEX = /«END_CON»/;
export const CON_CONDITION_REGEX = /«CON__(.*?)»/;

// i.e. (Value1) or (Value1|Value2)
export const CON_VALUE_CONSTANT_REGEX = /\(([^)]+)\)/;

export const LIST_ITEM_NODE_TYPES = ['unorderedList', 'orderedList', 'bulletList'];
export const CONDITIONAL_NODE_TYPES = { LIST_ITEM: 'listItem', BLOCK: 'block', INLINE: 'inline' };

export const CONDITIONAL_NODE_TYPE_LABELS = {
  [CONDITIONAL_NODE_TYPES.INLINE]: 'Inline conditional',
  [CONDITIONAL_NODE_TYPES.BLOCK]: 'Block conditional',
  [CONDITIONAL_NODE_TYPES.LIST_ITEM]: 'List item conditional',
};

export const conditionalOptions = Object.keys(CONDITIONAL_NODE_TYPE_LABELS).map((key) => ({
  value: key,
  label: CONDITIONAL_NODE_TYPE_LABELS[key],
}));

export const CONDITIONAL_VALUE_SEPARATOR = '|';
export const CONDITIONAL_CONSTANT_IND = '(';

export const CONDITIONAL_OPERATORS = {
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  EXISTS: 'EXISTS',
  NOT_EXISTS: 'NOT_EXISTS',
  GREATER_THAN: 'GREATER_THAN',
  LESS_THAN: 'LESS_THAN',
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
  IN: 'IN',
  NOT_IN: 'NOT_IN',
};

export const CON_LIST_VALUE_TYPES = ['number', 'alpha', 'roman'];
export const ROMAN_NUMERALS = [
  '',
  'C',
  'CC',
  'CCC',
  'CD',
  'D',
  'DC',
  'DCC',
  'DCCC',
  'CM',
  '',
  'X',
  'XX',
  'XXX',
  'XL',
  'L',
  'LX',
  'LXX',
  'LXXX',
  'XC',
  '',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
];
