import isFunction from 'lodash/isFunction';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getDDSessionReplayAttribute } from '@/src/helpers/datadog';

import { QuickActionsRow } from '../Cells';

import { CellSelector } from './TableCell';

const RowStyled = styled.tr`
  &:first-child ${CellSelector} {
    border-top: 0;
  }

  ${CellSelector} {
    ${({ $isSubRow }) =>
      $isSubRow &&
      css`
        border-top: 0;
      `}
    ${({ $isExpanded, theme }) =>
      $isExpanded &&
      css`
        border-bottom: 1px solid ${theme.colors.grey[200]};
      `}

    ${({ $isCheckboxDisabled, theme }) =>
      $isCheckboxDisabled &&
      css`
        color: ${theme.colors.grey[500]};
      `}

    background-color: ${({ theme, $isExpanded, $isSubRow }) => {
      if ($isExpanded || $isSubRow) {
        return theme.colors.grey[50];
      }

      return theme.colors.blank;
    }};
  }

  @media (hover: hover) {
    &:hover {
      ${QuickActionsRow} {
        svg {
          fill: inherit;
          transform: scale(1);
        }
      }
      ${CellSelector} {
        background-color: ${({ theme, $isSelected }) => {
          if ($isSelected) {
            return theme.colors.grey[100];
          }

          return theme.colors.grey[50];
        }};
      }
      cursor: ${({ $isCheckboxDisabled, onClick }) => {
        if ($isCheckboxDisabled) {
          return 'default';
        }
        if (onClick) {
          return 'pointer';
        }
        return 'unset';
      }};
    }
  }
`;

const RowClickable = styled(RowStyled)`
  cursor: pointer;

  /* NOTE: Use .class instead of tagName as selector for performance reasons.
  In huge tables (+400 rows) the hover was a little laggy */
  &:hover {
    ${CellSelector} {
      background-color: ${({ theme, $isSelected }) => {
        if ($isSelected) {
          return theme.colors.brand[50];
        }
        return theme.colors.grey[50];
      }};
      cursor: ${({ $isCheckboxDisabled }) => {
        if ($isCheckboxDisabled) {
          return 'default';
        }
        return 'unset';
      }};
    }
  }

  ${CellSelector} {
    background-color: ${({ theme, $isSelected, $isExpanded, $isSubRow }) => {
      if ($isSelected) {
        return theme.colors.brand[50];
      }

      if ($isExpanded || $isSubRow) {
        return theme.colors.grey[50];
      }

      return theme.colors.blank;
    }};
  }
`;

const LinkRow = styled(RowClickable).attrs({ as: Link })`
  cursor: pointer !important;
`;

const TableRow = ({
  children,
  onClick,
  isSelected,
  isCheckboxDisabled,
  isExpanded,
  isSubRow,
  ...props
}) => {
  const dataTestId = {
    'data-testid': props['data-testid'] ?? 'Table row',
  };
  const dataDogAttributeName = getDDSessionReplayAttribute(dataTestId);

  const tableRowProps = { ...dataDogAttributeName, ...dataTestId, ...props };

  if (props.href) {
    return <LinkRow {...tableRowProps}>{children}</LinkRow>;
  }

  if (!isFunction(onClick)) {
    return (
      <RowStyled
        {...tableRowProps}
        $isSelected={isSelected}
        $isCheckboxDisabled={isCheckboxDisabled}
        $isExpanded={isExpanded}
        $isSubRow={isSubRow}
      >
        {children}
      </RowStyled>
    );
  }

  // Useful to open a modal
  return (
    <RowClickable
      onClick={onClick}
      {...tableRowProps}
      $isSelected={isSelected}
      $isCheckboxDisabled={isCheckboxDisabled}
    >
      {children}
    </RowClickable>
  );
};

TableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  /* Indicates if RowClickable is active */
  isSelected: PropTypes.bool,
  isCheckboxDisabled: PropTypes.bool,
};

export default TableRow;
