import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';
import { type SpaceProps } from 'styled-system';

import { Stack } from '../../layout';
import type { ColorPropsWithCustomPalette } from '../../utils/types';

import type { CardSize, CardTone } from './Card';

const paddingSize: { [key in CardSize]: SpaceProps<DefaultTheme>['p'] } = {
  sm: 6,
  md: 7,
};

const backgroundColors: { [key in CardTone]: ColorPropsWithCustomPalette['color'] } = {
  neutral: 'grey.50',
  primary: 'blank',
};

const borderStyle: { [key in CardTone]: string } = {
  neutral: '1px solid',
  primary: 'none',
};

type CardWrapperProps = {
  $size: CardSize;
  $tone: CardTone;
};

export const CardWrapper = styled(Stack).attrs(({ $size, $tone }: CardWrapperProps) => ({
  p: paddingSize[$size],
  bg: backgroundColors[$tone],
  border: borderStyle[$tone],
  borderColor: 'grey.100',
  borderRadius: 'lg',
  position: 'relative',
}))<CardWrapperProps>``;
