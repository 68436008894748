import { Skeleton, Stack } from '@remote-com/norma';

import { SeparatorList, SeparatorListItem } from '@/src/components/SeparatorList';

const TodoSkeleton = () => {
  return (
    <SeparatorListItem>
      <Stack direction="row" alignItems="flex-start" px={6} py={5} gap={4}>
        <Skeleton variant="rect" width={40} height={40} />
        <Stack>
          <Skeleton width={104} />
          <Skeleton width={160} />
          <Stack direction="row" gap={3} mt={4}>
            <Skeleton width={80} />
            <Skeleton width={40} />
          </Stack>
        </Stack>
      </Stack>
    </SeparatorListItem>
  );
};

export const TodosOverlayLoader = () => {
  return (
    <SeparatorList>
      <TodoSkeleton />
      <TodoSkeleton />
      <TodoSkeleton />
    </SeparatorList>
  );
};
