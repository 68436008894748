import { DOCUMENT_TYPES } from '@/src/domains/documents/constants';
import { createDocumentAsEmployee } from '@/src/domains/documents/services';
import { makeApiService } from '@/src/services/ApiClient';

export const fetchEmploymentEmployeeInfo = makeApiService({
  path: '/employee/employments/[employmentSlug]',
});

/**
 * Employee uploads a document
 * @param {Object} fields
 */
export async function uploadPersonalFile(params) {
  const bodyParams = {
    file: {
      ...params,
      description: params.description ?? null,
      type: params.type || DOCUMENT_TYPES.PERSONAL,
      ...(params.subType ? { subType: params.subType } : {}),
    },
  };

  return createDocumentAsEmployee({ bodyParams });
}

export const deleteFile = makeApiService({
  method: 'delete',
  path: '/employee/files/[slug]',
});

export const fetchEmployeeInvoices = makeApiService({
  path: '/employee/contractor-invoices',
  asFormData: true,
});

export const fetchEmployeeIncentives = makeApiService({
  path: '/employee/incentives',
});

/** to upload invoices as FormData */
export const createEmployeeContractorInvoicesAsFormData = makeApiService({
  method: 'post',
  path: '/employee/contractor-invoices',
});
