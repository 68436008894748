import { Box } from '@remote-com/norma';
import styled from 'styled-components';

export const CountryFlag = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 0.5ch;

  /* if it doesn't have URL. */
  &:not([src]) {
    display: none;
  }
`;

export const CountryInfo = styled(Box)<{ flagSpacing?: string }>`
  display: flex;
  align-items: center;

  ${({ flagSpacing }) =>
    flagSpacing &&
    `img {
    margin-right: ${flagSpacing};
  }`}
`;

export const InlineCountryInfo = styled.span`
  display: inline-flex;
  align-items: center;
  white-space: pre;
`;
