import styled from 'styled-components';

import { Box } from '../../foundations/layout/Box';
import { Stack } from '../../foundations/layout/Stack';
import type { ColorPropsWithCustomPalette } from '../../utils/types';
import { Text } from '../text';

import type { SubtlePillProps, PillToneV2 } from './Pill';

// DEFAULT VARIANT

const defaultPillToneColors: {
  [key in PillToneV2]: {
    background: ColorPropsWithCustomPalette['color'];
    text: ColorPropsWithCustomPalette['color'];
  };
} = {
  blue: { background: 'brand.50', text: 'brand.600' },
  pink: { background: 'pink.100', text: 'pink.800' },
  purple: { background: 'purple.100', text: 'purple.800' },
  cyan: { background: 'cyan.200', text: 'cyan.800' },
  fuchsia: { background: 'fuchsia.100', text: 'fuchsia.800' },
  blueLight: { background: 'blueLight.200', text: 'blueLight.800' },
  neutralLight: { background: 'grey.100', text: 'grey.600' },
  neutralDark: { background: 'grey.300', text: 'grey.900' },
  warning: { background: 'yellow.100', text: 'yellow.800' },
  error: { background: 'red.100', text: 'red.700' },
  success: { background: 'green.100', text: 'green.800' },
};

type PillDefaultProps = {
  $tone: PillToneV2;
};

export const PillDefault = styled(Stack).attrs(({ $tone }: PillDefaultProps) => ({
  bg: defaultPillToneColors[$tone]?.background || defaultPillToneColors.blue.background,
  color: defaultPillToneColors[$tone]?.text || defaultPillToneColors.blue.text,
  direction: 'row',
  gap: 2,
}))<PillDefaultProps>`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: inline-flex;
  height: 22px;
  justify-content: center;
  padding: 0 8px;
  text-transform: uppercase;
`;

export const PillDefaultIcon = styled.svg`
  height: 16px;
  width: 16px;
`;

export const PillDefaultText = styled(Text).attrs({
  variant: '2xsSemiBold',
})``;

// SUBTLE VARIANT

const subtlePillSignalToneColors: { [key in PillToneV2]: ColorPropsWithCustomPalette['color'] } = {
  blue: 'brand.600',
  pink: 'pink.600',
  purple: 'purple.600',
  cyan: 'cyan.600',
  fuchsia: 'fuchsia.600',
  blueLight: 'blueLight.600',
  neutralLight: 'grey.400',
  neutralDark: 'grey.700',
  warning: 'yellow.600',
  error: 'red.600',
  success: 'green.600',
};

export const PillSubtle = styled(Stack).attrs({
  direction: 'row',
  gap: 2,
})<SubtlePillProps>`
  padding: 0 8px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blank};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  display: inline-flex;
  height: 22px;
  justify-content: center;
`;

type PillSubtleSignalProps = {
  $tone: PillToneV2;
};

export const PillSubtleSignal = styled(Box).attrs(({ $tone }: PillSubtleSignalProps) => ({
  bg: subtlePillSignalToneColors[$tone] || subtlePillSignalToneColors.blue,
}))<PillSubtleSignalProps>`
  aspect-ratio: 1/1;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  height: 6px;
`;

export const PillSubtleText = styled(Text).attrs({ color: 'grey.500', variant: 'xs' })``;
