import { VerificationCodeInput } from '@remote-com/norma';
import type { VerificationCodeInputProps } from '@remote-com/norma';
import type { FormikValues } from 'formik';
import { Field, useFormikContext } from 'formik';
import type { ReactElement } from 'react';

export const InputOTP = ({
  name,
  autoComplete,
  autoFocus,
  errorText,
  length,
  required,
  readOnly,
  value,
  ...props
}: VerificationCodeInputProps): ReactElement => {
  const { setValues } = useFormikContext<FormikValues>();

  return (
    <VerificationCodeInput
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      errorText={errorText}
      length={length}
      required={required}
      value={value}
      name={name}
      setValues={setValues}
      Field={Field}
      {...props}
    />
  );
};
