// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconTooth = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M20.72 3.76c-1.04-1.4-1.86-1.73-2.64-1.73a8.1 8.1 0 0 0-2.4.56h-.04l-.1.04c-.98.32-2.23.74-3.61.74-1.4 0-2.71-.45-3.66-.76l-.1-.04A8.18 8.18 0 0 0 5.74 2c-.75 0-1.52.3-2.5 1.72-1.23 1.8-.65 3.74.29 6.3v.03c.2.5.4 1.07.56 1.62.16.53.31 1.2.31 1.85 0 1.85.2 4.27.72 6.17.27.96.58 1.63.87 2.02.14.18.24.26.28.28l.02.01h.02c.07 0 .2.01.48-.47.35-.62.6-1.5.9-2.65.3-1.1.63-2.3 1.11-3.22.5-.95 1.47-2.16 3.2-2.16.62 0 1.26.16 1.83.56.55.37.9.87 1.15 1.33.46.85.75 2 1.03 3.21.26 1.17.48 2.09.85 2.74.16.29.3.43.4.5.09.05.22.11.49.11.04 0 .19.01.47-.4a7.2 7.2 0 0 0 .88-2.34c.47-2.08.54-4.5.54-5.69 0-.64.15-1.29.3-1.81.15-.53.35-1.1.53-1.6l.01-.02.02-.05c.93-2.64 1.48-4.59.22-6.28zm1.65 7a9.54 9.54 0 0 0-.73 2.76c0 2.45-.28 10.43-3.9 10.43-2.58 0-3.2-2.72-3.68-4.9-.57-2.5-.99-3.55-2.06-3.55-1.24 0-1.77 1.61-2.38 3.9C9.05 21.54 8.4 24 6.3 24c-3.08 0-3.9-6.58-3.9-10.48 0-.75-.37-1.76-.75-2.82C.78 8.31-.3 5.35 1.6 2.6 2.8.82 4.12 0 5.74 0 6.77 0 7.8.34 8.8.67a10 10 0 0 0 3.13.7c1.06 0 2.04-.33 3.09-.67l.03-.01c.98-.32 1.99-.66 3.03-.66 1.62 0 2.96.8 4.25 2.54 1.99 2.67.91 5.7.05 8.14l-.01.04z" /></svg>;
const ForwardRef = forwardRef(IconTooth);
export { ForwardRef as IconTooth };