import { IconV2OutlineChevronRight } from '@remote-com/norma/icons/IconV2OutlineChevronRight';

import { StandaloneLink } from '@/src/components/Button';
import { CardDrawerHeader, CardDrawerTitle } from '@/src/components/CardDrawer';
import { TODOS_ROUTE } from '@/src/constants/routes';
import { useTodosOverlayContext } from '@/src/domains/todos/TodosOverlayProvider';
import { useTodosContext } from '@/src/domains/todos/TodosProvider';

export const TodosOverlayHeader = () => {
  const { totalGroupedTodos, status } = useTodosContext();
  const { closeOverlay } = useTodosOverlayContext();

  const hasCount = (totalGroupedTodos || 0) > 0 && status === 'success';

  const handleViewAllClick = () => {
    closeOverlay();
  };

  return (
    <CardDrawerHeader>
      <CardDrawerTitle count={hasCount ? totalGroupedTodos : undefined}>
        Things to do
      </CardDrawerTitle>
      <StandaloneLink
        IconAfter={IconV2OutlineChevronRight}
        href={TODOS_ROUTE}
        onClick={handleViewAllClick}
      >
        View all
      </StandaloneLink>
    </CardDrawerHeader>
  );
};
