// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconClock = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12a11 11 0 1 1 22 0 11 11 0 0 1-22 0Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 5a1 1 0 0 1 1 1v5.38l3.45 1.73a1 1 0 1 1-.9 1.78l-4-2A1 1 0 0 1 11 12V6a1 1 0 0 1 1-1Z" /></svg>;
const ForwardRef = forwardRef(IconClock);
export { ForwardRef as IconClock };