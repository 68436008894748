// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneBank = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} fillRule="evenodd" clipRule="evenodd" d="M18 3.84c-2.14-1.72-3.21-2.57-4.4-2.9a6 6 0 0 0-3.2 0c-1.19.33-2.26 1.18-4.4 2.9l-1.15.92C3.53 5.82 2.87 6.34 2.4 7a6 6 0 0 0-.92 1.93c-.22.78-.22 1.63-.22 3.32v.9c0 3.36 0 5.04.65 6.32a6 6 0 0 0 2.63 2.63c1.28.65 2.96.65 6.32.65h2.3c3.36 0 5.04 0 6.32-.65a6 6 0 0 0 2.63-2.63c.65-1.28.65-2.96.65-6.32v-.9c0-1.7 0-2.54-.22-3.32A6 6 0 0 0 21.61 7c-.48-.66-1.14-1.18-2.46-2.24L18 3.84Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 6.75c.41 0 .75.34.75.75v.75H13A2.75 2.75 0 0 1 15.75 11a.75.75 0 0 1-1.5 0c0-.69-.56-1.25-1.25-1.25h-.25v2.7l1.3.44a2.5 2.5 0 0 1-.8 4.86h-.5v.75a.75.75 0 0 1-1.5 0v-.75H11A2.75 2.75 0 0 1 8.25 15a.75.75 0 0 1 1.5 0c0 .69.56 1.25 1.25 1.25h.25v-2.7l-1.3-.44a2.5 2.5 0 0 1 .8-4.86h.5V7.5c0-.41.34-.75.75-.75Zm-.75 3h-.5a1 1 0 0 0-.32 1.94l.82.27V9.75Zm1.5 4.3v2.2h.5a1 1 0 0 0 .32-1.94l-.82-.27Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneBank);
export { ForwardRef as IconV2DuotoneBank };