import { REMOTE_INVOICE_DETAILS_ROUTE, REMOTE_RECEIPT_DETAILS_ROUTE } from '@/src/constants/routes';
import { paymentTypes, paymentStatus } from '@/src/domains/payments/constants';

export const billingDocumentTypes = {
  CONTRACTOR_TRANSACTION_RECEIPT: 'contractor_transaction_receipt',
  REMOTE_INVOICE: 'remote_invoice',
};

// Type options that are available for admins when filtering "Billing" page
// table.
export const adminBillingDocumentTypeOptions = [
  { value: billingDocumentTypes.REMOTE_INVOICE, label: 'Invoice' },
  { value: billingDocumentTypes.CONTRACTOR_TRANSACTION_RECEIPT, label: 'Transaction receipt' },
];

export const billingFrequency = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
};

export const billingFrequencyOptions = [
  { value: billingFrequency.ANNUALLY, label: 'Annually' },
  { value: billingFrequency.MONTHLY, label: 'Monthly' },
];

const remoteInvoicePaymentMapping = {
  key: 'remoteInvoices',
  labelDocNumber: 'Invoice number',
  route: REMOTE_INVOICE_DETAILS_ROUTE,
  docSlug: '[invoiceSlug]',
  linkDetailsText: 'Remote_Invoice',
  pdfTitle: 'Remote Invoice',
};

export const paymentMapping = {
  [paymentTypes.PAYROLL_RUN]: remoteInvoicePaymentMapping,
  [paymentTypes.CONTRACTOR]: {
    key: 'transactionReceipts',
    labelDocNumber: 'Transaction receipt number',
    route: REMOTE_RECEIPT_DETAILS_ROUTE,
    docSlug: '[receiptSlug]',
    uploadCta: 'Upload contractor invoice',
    linkDetailsText: 'Transaction_receipt',
    pdfTitle: 'Remote transaction receipt',
  },
};

export const employerPaymentStatusAlternativeLabels = {
  [paymentStatus.PAY_IN_FAILED]: 'Payment failed',
  [paymentStatus.PARTIAL_FAILURE]: 'Partial failure',
  [paymentStatus.BLOCKED]: 'Blocked',
};

export const STRIPE_DISPUTE_REASONS_ERROR_MESSAGES = {
  general:
    'The payment method used to pay this invoice is no longer valid because of a disputed payment. Please resolve this with your bank, then contact us to re-enable this method.',
  debit_not_authorized:
    'The payment method used to pay this invoice is no longer valid because of a disputed payment. Please resolve this with your bank, then contact us to re-enable this method.',
  incorrect_account_details:
    'The payment method used to pay this invoice is no longer valid because the bank account details are incorrect. Please pay using a different method, or delete this method and add it again with the correct details.',
  bank_cannot_process:
    'The payment method used to pay this invoice is no longer valid because of a disputed payment. Please resolve this with your bank, then contact us to re-enable this method.',
  insufficient_funds:
    'The payment method used to pay this invoice is no longer valid because of insufficient funds. Please add funds to your bank account, then contact us to re-enable this method.',
  customer_initiated:
    'The payment method used to pay this invoice is no longer valid because of a disputed payment. Please resolve this with your bank, then contact us to re-enable this method.',
  fraudulent:
    'The payment method used to pay this invoice is no longer valid because of a disputed payment. Please resolve this with your bank, review the account details, then try again.',
};

export const billingTabs = {
  BILLING: 'billing',
  BILLING_LEGACY: 'legacy',
  CUSTOMER_INVOICES: 'invoices-table',
  COUNTRY_PAYROLL_STATUS: 'invoices-payroll-status',
  SETTINGS: 'invoices-settings',
};
