import type { InputCurrencyProps } from '@remote-com/norma';
import { InputCurrency } from '@remote-com/norma';
import type { FormikValues } from 'formik';
import { useField, useFormikContext } from 'formik';
import React from 'react';

type InputCurrencyFieldProps = Omit<InputCurrencyProps, 'onValueChange' | 'onBlur'> & {
  id?: string;
  onValueChange?: (
    unformattedValue: string | undefined,
    values: FormikValues,
    // eslint-disable-next-line no-shadow
    setValues: (values: FormikValues, shouldValidate?: boolean) => void
  ) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export function InputCurrencyField({
  name,
  onValueChange,
  onBlur,
  placeholder,
  label,
  ...props
}: InputCurrencyFieldProps): JSX.Element {
  const { values, setValues } = useFormikContext<FormikValues>();
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);

  const handleValueChange = (unformattedValue: string) => {
    setValue(unformattedValue || '');
    if (onValueChange) onValueChange(unformattedValue, values, setValues);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setTouched(true);
    if (onBlur) onBlur(event);
  };

  return (
    <InputCurrency
      errorText={error && touched ? error : ''}
      name={name}
      onBlur={handleBlur}
      onValueChange={handleValueChange}
      label={label}
      placeholder={placeholder}
      value={value}
      {...props}
    />
  );
}
