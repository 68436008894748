import { Stack } from '@remote-com/norma';
import { IconEdit3 } from '@remote-com/norma/icons/IconEdit3';

import { HoverContent, HoverText, HoverableContent, NoHoverContent } from './ContentTag.styles';

export function EditableContentTag({
  children,
  enableHover,
  onClick,
  hoverContent = (
    <Stack direction="row" gap={2} justifyContent="center" px={2}>
      <IconEdit3 width={12} />
      <HoverText>Edit</HoverText>
    </Stack>
  ),
  ...props
}) {
  return (
    <HoverableContent onClick={onClick} $enableHover={enableHover} {...props}>
      <NoHoverContent>{children}</NoHoverContent>
      {enableHover && <HoverContent>{hoverContent}</HoverContent>}
    </HoverableContent>
  );
}
