import { Stack } from '@remote-com/norma';
import { components } from 'react-select';
import styled, { ThemeConsumer } from 'styled-components';
import { object } from 'yup';

import { SelectField } from '@/src/components/Ui/Form/formikIntegration/SelectField';
import { InputController } from '@/src/components/Ui/Form/Input';
import { getCurrencyFlagSrc } from '@/src/domains/countries/helpers';
import { requiredCurrencySchema } from '@/src/helpers/validationSchema';

export const desiredCurrencySchema = object().shape({
  desiredCurrency: requiredCurrencySchema,
});

export const validationSchema = object().shape({}).concat(desiredCurrencySchema);

// No support for crypto currencies yet
const FiatCurrencyFlag = styled.img`
  width: 20px;
`;

export const CurrencyWithFlag = ({ className, code, children }) => {
  const currencyFlagSrc = getCurrencyFlagSrc(code);

  return (
    <Stack className={className} direction="row" gap={4} width="100%">
      <FiatCurrencyFlag src={currencyFlagSrc} alt="" />
      {children}
    </Stack>
  );
};

export const OptionCurrency = ({ children, ...props }) => {
  const {
    data: { code },
  } = props;

  return (
    <components.Option {...props}>
      <CurrencyWithFlag code={code}>{children}</CurrencyWithFlag>
    </components.Option>
  );
};

export const SingleValueCurrency = ({ children, ...props }) => {
  const {
    data: { code },
  } = props;

  return (
    <components.SingleValue {...props}>
      <CurrencyWithFlag code={code}>{children}</CurrencyWithFlag>
    </components.SingleValue>
  );
};

/**
 * Returns a select field that renders a list of currencies.
 *
 * @export
 * @type {import('@/src/components/Form/DynamicForm/types').RenderCurrenciesField}
 * @return {JSX.Element}
 */
export const CurrencyFields = ({
  currencies,
  biggerMargin = false,
  description = 'Choose the currency you want your payments to be processed in. Each currency has different requirements',
  label = 'Desired currency',
  placeholder = 'Select desired currency',
  withFlags = false,
  ...props
}) => {
  const customComponents = withFlags
    ? { Option: OptionCurrency, SingleValue: SingleValueCurrency }
    : {};

  return (
    <ThemeConsumer>
      {(theme) => (
        <InputController biggerMargin={biggerMargin}>
          <SelectField
            name="desiredCurrency"
            label={label}
            description={description}
            placeholder={placeholder}
            components={customComponents}
            options={currencies}
            getOptionLabel={(currency) => currency.code}
            getOptionValue={(currency) => currency.slug}
            size={theme.size}
            {...props}
          />
        </InputController>
      )}
    </ThemeConsumer>
  );
};
