// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2SolidDragDrop = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M7.75 1.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z" /><path d="M5 12a2.75 2.75 0 1 1 5.5 0A2.75 2.75 0 0 1 5 12Z" /><path d="M7.75 17.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z" /><path d="M13 4a2.75 2.75 0 1 1 5.5 0A2.75 2.75 0 0 1 13 4Z" /><path d="M15.75 9.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z" /><path d="M13 20a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2SolidDragDrop);
export { ForwardRef as IconV2SolidDragDrop };