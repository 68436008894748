// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconEyeOff = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g clipPath="url(#a)"><path fillRule="evenodd" clipRule="evenodd" d="M10.13 5.21A8.12 8.12 0 0 1 12 5c3.1 0 5.6 1.77 7.4 3.68a18.68 18.68 0 0 1 2.44 3.3l-.45.7c-.44.68-.95 1.43-1.32 1.87a1 1 0 0 0 1.54 1.28c.45-.54 1.03-1.39 1.46-2.06a53.43 53.43 0 0 0 .72-1.15l.05-.08.02-.02L23 12l.9-.45h-.01l-.01-.03a9.13 9.13 0 0 0-.2-.35 20.68 20.68 0 0 0-2.83-3.85C18.9 5.22 15.9 3 12 3c-.78 0-1.56.09-2.33.27a1 1 0 0 0 .46 1.94ZM12 3v1-1ZM6.77 5.35a1 1 0 0 0-1.32-.08 19.45 19.45 0 0 0-5.33 6.26 1 1 0 0 0-.01.92L1 12l-.9.45h.01l.01.03a7.17 7.17 0 0 0 .2.35 20.68 20.68 0 0 0 2.83 3.85C5.1 18.78 8.1 21 12 21h.02c2.36-.04 4.65-.83 6.53-2.26a1 1 0 0 0 .1-1.5l-3.82-3.82v-.01l-4.24-4.23v-.01L6.76 5.35Zm-4.2 7.36a17.1 17.1 0 0 1-.43-.7c1-1.75 2.31-3.3 3.85-4.6l2.55 2.54a4 4 0 0 0 5.51 5.51l2.32 2.33A9.07 9.07 0 0 1 12 19c-3.1 0-5.6-1.78-7.39-3.68-.88-.95-1.56-1.9-2.03-2.61Zm7.36-.67c0-.2.03-.4.1-.6l2.53 2.53a2 2 0 0 1-2.63-1.93Z" /><path d="m23 12 .86.52a1 1 0 0 0 .03-.97L23 12Z" /><path fillRule="evenodd" clipRule="evenodd" d="M.3.3a1 1 0 0 1 1.4 0l22 22a1 1 0 0 1-1.4 1.4l-22-22A1 1 0 0 1 .3.3Z" /></g><defs><clipPath id="a"><rect width={24} height={24} /></clipPath></defs></svg>;
const ForwardRef = forwardRef(IconEyeOff);
export { ForwardRef as IconEyeOff };