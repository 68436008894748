import type { DefaultTheme, StyledComponent } from 'styled-components';
import styled, { css } from 'styled-components';

import { HTMLRendered } from '../../core/html-rendered';
import { Text } from '../../core/text';
import type { themeV2 } from '../../foundations/theme';
import { Box, Stack } from '../../layout';

import type { FormGroupLabelPlacement, FormGroupSize } from './FormGroup';

type NormaTheme = typeof themeV2;

export const getInputColors = (theme: NormaTheme) => ({
  background: theme.colors.blank,
  backgroundError: theme.colors.negative.background,
  backgroundReadOnly: theme.colors.grey[100],
  border: theme.colors.grey[400],
  borderFocus: theme.colors.primary,
  borderHover: theme.colors.grey[900],
  borderReadOnly: theme.colors.grey[500],
  borderError: theme.colors.negative.foreground,
  textLabel: theme.colors.grey[600],
  textMeta: theme.colors.grey[600],
  textValue: theme.colors.grey[900],
  textValueReadOnly: theme.colors.grey[600],
  textError: theme.colors.negative.foreground,
});

export const Container = styled(Stack).attrs({
  gap: 3,
  width: 'inherit',
})``;

type LabelInputWrapperProps = { $size: FormGroupSize };

export const LabelInputWrapper = styled(Box).attrs(({ $size }: { $size: FormGroupSize }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  pl: $size === 'md' ? 5 : 4,
  pr: $size === 'md' ? 5 : 4,
  py: 3,
}))<LabelInputWrapperProps>`
  flex-grow: 1;
`;

type LabelProps = {
  $size: FormGroupSize;
  $placement: FormGroupLabelPlacement;
  $isReadOnly?: boolean;
};

export const Label = styled.label<LabelProps>`
  align-items: center;
  color: ${({ theme }) => getInputColors(theme).textLabel};
  display: flex;
  min-height: ${({ theme }) => theme.space[5]}px;
  height: ${({ theme }) => theme.space[5]}px;
  cursor: ${({ $isReadOnly }) => ($isReadOnly ? 'not-allowed' : 'auto')};
  ${({ $size, $placement, theme }) =>
    $size === 'sm' &&
    $placement === 'inside' &&
    css`
      position: absolute;
      top: ${theme.space[4]}px;
      left: ${theme.space[4]}px;
      right: ${theme.space[4]}px;
    `}
`;

const LabelTextActiveStyles = css`
  transform: translateY(0) scale(0.75);
`;

type LabelTextProps = {
  $size: FormGroupSize;
  $placement: FormGroupLabelPlacement;
  $hasError?: boolean;
  $hasFocus?: boolean;
  $hasValue?: boolean;
  theme: NormaTheme;
};

export const LabelText = styled.span<LabelTextProps>`
  color: ${({ theme }) => getInputColors(theme).textLabel};
  display: block;
  max-width: 100%;
  overflow: hidden;
  padding: ${({ theme }) =>
    `${theme.space[1]}px 0`}; /* NOTE: This fixes overflow issues with descenders and ascenders */
  text-overflow: ellipsis;

  ${({ $size, $placement, theme }) => {
    if ($size === 'sm') {
      if ($placement === 'outside') {
        return theme.typography.xs;
      }
      return theme.typography.sm;
    }
    return theme.typography.base;
  }}

  line-height: ${({ $size }) => ($size === 'sm' ? 1.25 : 1)};

  ${({ $size }) =>
    $size === 'sm'
      ? css`
          white-space: nowrap;
        `
      : css`
          transform-origin: center left;
          transform: translateY(${({ theme }) => `${theme.space[4]}px`});
          transition: all 150ms cubic-bezier(0.37, 0, 0.63, 1);
        `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${({ theme }) => getInputColors(theme).textError};
    `}

    ${({ $hasValue, $size, $placement }) =>
    $size === 'sm' &&
    $placement === 'inside' &&
    $hasValue &&
    css`
      display: none;
    `}

  ${({ $hasValue, $hasFocus, $size }) =>
    $size === 'md' && ($hasValue || $hasFocus) && LabelTextActiveStyles}

  @media (prefers-reduced-motion) {
    ${({ $size }) =>
      $size === 'md' &&
      css`
        ${LabelTextActiveStyles}
      `}
  }
`;

type BodyFocusStylesProps = {
  $size: FormGroupSize;
  theme: NormaTheme;
};

const bodyFocusStyles = css<BodyFocusStylesProps>`
  border-color: ${({ theme }) => getInputColors(theme).borderFocus};
  box-shadow: 0 0 0 1px ${({ theme }) => getInputColors(theme).borderFocus};

  ${({ $size }) =>
    $size === 'md' &&
    css`
      & ${LabelText} {
        ${LabelTextActiveStyles}
      }
    `}
`;

type BodyProps = {
  $size: FormGroupSize;
  $hasError?: boolean;
  $hasFocus?: boolean;
  $isReadOnly?: boolean;
  $internalIsInputSelect?: boolean;
  theme: NormaTheme;
};

export const Body = styled(Stack).attrs(({ $size }: { $size: FormGroupSize }) => ({
  direction: 'row',
  minHeight: $size === 'sm' ? 38 : 56,
}))<BodyProps>`
  background-color: ${({ theme }) => getInputColors(theme).background};
  border-radius: 12px;
  border: 0;
  border: solid 1px ${({ theme }) => getInputColors(theme).border};
  box-shadow: none;
  cursor: ${({ $internalIsInputSelect }) => ($internalIsInputSelect ? 'pointer' : 'text')};
  ${({ theme }) => theme.typography.base}
  position: relative;
  transition: 200ms cubic-bezier(0, 0, 0.1, 1) 0ms;

  ${({ $hasError, $hasFocus, $isReadOnly }) =>
    !$hasError &&
    !$isReadOnly &&
    !$hasFocus &&
    css`
      &:hover {
        border-color: ${({ theme }) => getInputColors(theme).borderHover};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
      }
    `}

  ${({ $isReadOnly }) =>
    !$isReadOnly &&
    css`
      &:focus-within {
        ${bodyFocusStyles}
      }
    `}

  ${({ $hasFocus, $isReadOnly, $size }) =>
    !$isReadOnly &&
    $hasFocus &&
    $size === 'md' &&
    css`
      ${bodyFocusStyles}
    `}

  ${({ $isReadOnly }) =>
    $isReadOnly &&
    css`
      background-color: ${({ theme }) => getInputColors(theme).backgroundReadOnly};
      border-style: dashed;
      cursor: not-allowed;
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      background-color: ${({ theme }) => getInputColors(theme).backgroundError};
    `}

  ${({ $hasError, $hasFocus }) =>
    $hasError &&
    !$hasFocus &&
    css`
      border-color: ${({ theme }) => getInputColors(theme).borderError};
    `}
`;

type InputStylesProps = {
  size?: FormGroupSize;
  labelPlacement?: FormGroupLabelPlacement;
  $dateRangeEditable?: boolean;
  type?: string;
  isSearchable?: boolean;
};

export const inputStyles = css<InputStylesProps>`
  all: unset;
  appearance: none;
  border: none;
  display: block;
  min-height: 24px;
  width: 100%;

  &:focus {
    outline: 0;
  }

  ${({ theme, size, labelPlacement, $dateRangeEditable }) => css`
    color: ${getInputColors(theme).textValue};

    ${size === 'sm' && theme.typography.sm}

    &[readonly] {
      ${$dateRangeEditable
        ? css`
            cursor: pointer;
          `
        : css`
            cursor: not-allowed;
            color: ${getInputColors(theme).textValueReadOnly};
          `}
    }

    /** We're still rendering placeholder for testing purposes (we have a lot of legacy
   * *byPlaceholderText queries) but in some cases we're making it transparent to comply to design */
    --formGroupPlaceholderColor: ${size === 'md' || labelPlacement === 'inside'
      ? 'transparent'
      : theme.colors.grey[600]};
  `}

  input::placeholder {
    color: var(--formGroupPlaceholderColor);
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: var(--formGroupPlaceholderColor);
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--formGroupPlaceholderColor);
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--formGroupPlaceholderColor);
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: var(--formGroupPlaceholderColor);
  }
  textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: var(--formGroupPlaceholderColor);
  }
  textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--formGroupPlaceholderColor);
  }
  textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--formGroupPlaceholderColor);
  }
  textarea:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: var(--formGroupPlaceholderColor);
  }
  textarea::placeholder {
    color: var(--formGroupPlaceholderColor);
  }
`;

export const Input = styled(Box).attrs({ as: 'input' })`
  ${inputStyles}
` as StyledComponent<
  // Need to cast because Box's typing does not take "props.as" into account
  'input',
  DefaultTheme,
  InputStylesProps,
  never
>;

export const Aside = styled(Box).attrs(({ $size }: { $size: FormGroupSize }) => ({
  pr: $size === 'sm' ? 4 : 5,
}))<{ $size: FormGroupSize }>`
  /* Ensures the Aside component does not shrink or overflow, maintaining content integrity. Addresses an issue where currency code badges were wrapping incorrectly. 
  Slack thread: https://remote-com.slack.com/archives/C020B57P9QU/p1715797261894059 */
  flex-shrink: 0;
  display: grid;
  place-items: center;
`;

export const MetaContainer = styled(Stack).attrs({
  direction: 'row',
  justifyContent: 'space-between',
})``;

export const Meta = styled(Text).attrs({
  forwardedAs: 'div',
  px: 5,
  variant: 'xs',
})`
  color: ${({ theme }) => getInputColors(theme).textMeta};
`;

export const ErrorMessage = styled(Text).attrs({
  forwardedAs: 'span',
  mr: 2,
  variant: 'xs',
})`
  color: ${({ theme }) => getInputColors(theme).textError};
`;

export const MetaSeparator = styled(Text).attrs({ forwardedAs: 'span', mr: 2 })``;

export const Description = styled(HTMLRendered).attrs({
  Tag: Text,
  forwardedAs: 'span',
})``;

export const Details = styled(HTMLRendered).attrs({
  Tag: Text,
  forwardedAs: 'div',
})``;

// Aside components

const getAsideBadgeColors = (theme: NormaTheme) => ({
  background: theme.colors.grey[100],
  backgroundError: theme.colors.red[200],
  backgroundReadOnly: theme.colors.grey[200],
  text: theme.colors.grey[600],
  textError: theme.colors.negative.foreground,
  textReadOnly: theme.colors.grey[500],
});

export const AsideBadge = styled(Text).attrs({ p: 2, variant: 'xsSemiBold' })<{
  $isReadOnly?: boolean;
  $hasError?: boolean;
}>`
  background-color: ${({ theme }) => getAsideBadgeColors(theme).background};
  border-radius: 4px;
  color: ${({ theme }) => getAsideBadgeColors(theme).text};

  ${({ $isReadOnly }) =>
    $isReadOnly &&
    css`
      background-color: ${({ theme }) => getAsideBadgeColors(theme).backgroundReadOnly};
      color: ${({ theme }) => getAsideBadgeColors(theme).textReadOnly};
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      background-color: ${({ theme }) => getAsideBadgeColors(theme).backgroundError};
      color: ${({ theme }) => getAsideBadgeColors(theme).textError};
    `}
`;

const getAsideIconColors = (theme: NormaTheme) => ({
  default: theme.colors.grey[600],
  error: theme.colors.negative.foreground,
  focus: theme.colors.primary,
  readOnly: theme.colors.grey[500],
});

export const AsideIcon = styled.svg<{
  $isReadOnly?: boolean;
  $hasError?: boolean;
  $hasFocus?: boolean;
}>`
  color: ${({ theme }) => getAsideIconColors(theme).default};

  ${({ $isReadOnly }) =>
    $isReadOnly &&
    css`
      color: ${({ theme }) => getAsideIconColors(theme).readOnly};
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${({ theme }) => getAsideIconColors(theme).error};
    `}

  ${({ $hasFocus }) =>
    $hasFocus &&
    css`
      color: ${({ theme }) => getAsideIconColors(theme).focus};
    `}
`;
