export const NPL_WISE_BANK_CODES = [
  {
    value: '977001',
    label: 'APEX DEVELOPMENT BANK LTD.',
  },
  {
    value: '977029',
    label: 'Agricultural Development Bank',
  },
  {
    value: '977031',
    label: 'Annapurna Bikash Bank',
  },
  {
    value: '977032',
    label: 'Axel Bank',
  },
  {
    value: '977033',
    label: 'Bageshwori Development Bank',
  },
  {
    value: '977002',
    label: 'Bank Of Kathmandu',
  },
  {
    value: '977034',
    label: 'Bank of Asia',
  },
  {
    value: '977013',
    label: 'Bhrikutee Development Bank',
  },
  {
    value: '977035',
    label: 'Biratlaxmi Bikas Bank',
  },
  {
    value: '977036',
    label: 'Bishwa Bikash Bank',
  },
  {
    value: '977037',
    label: 'Business Universal Development Bank',
  },
  {
    value: '977038',
    label: 'Century Commerical Bank',
  },
  {
    value: '977018',
    label: 'Citizens Bank International',
  },
  {
    value: '977022',
    label: 'City Development Bank',
  },
  {
    value: '977039',
    label: 'Civil Bank',
  },
  {
    value: '977040',
    label: 'Clean Energy Development Bank',
  },
  {
    value: '977041',
    label: 'Commerz and Trust Bank Nepal',
  },
  {
    value: '977042',
    label: 'Ekata Bikas Bank',
  },
  {
    value: '977043',
    label: 'Everest Bank  Limited',
  },
  {
    value: '977044',
    label: 'Excel Development Bank',
  },
  {
    value: '977045',
    label: 'Federal Bank',
  },
  {
    value: '977046',
    label: 'Gandaki Bikas Bank Ltd',
  },
  {
    value: '977047',
    label: 'Garima Bikas Bank Limited',
  },
  {
    value: '977048',
    label: 'Gaurishankar Development Bank',
  },
  {
    value: '977049',
    label: 'Global IME Bank',
  },
  {
    value: '977050',
    label: 'Grand Bank Nepal',
  },
  {
    value: '977008',
    label: 'Green Development Bank',
  },
  {
    value: '977012',
    label: 'Guheshwari Merchant Banking and Finance',
  },
  {
    value: '977051',
    label: 'Gulmi Bikas Bank',
  },
  {
    value: '977052',
    label: 'H and B Development Bank',
  },
  {
    value: '977053',
    label: 'Hamro Bikas Bank',
  },
  {
    value: '977054',
    label: 'Himalayan Bank',
  },
  {
    value: '977055',
    label: 'Infrastructure Development Bank',
  },
  {
    value: '977056',
    label: 'Innovative Development Bank',
  },
  {
    value: '977057',
    label: 'International Development Bank',
  },
  {
    value: '977015',
    label: 'JHIMRUK BIKAS BANK',
  },
  {
    value: '977059',
    label: 'Jyoti Bikash Bank',
  },
  {
    value: '977062',
    label: 'KANCHAN DEVELOPMENT BANK LTD.',
  },
  {
    value: '977060',
    label: 'Kabeli Bikas Bank',
  },
  {
    value: '977061',
    label: 'Kailash Bikas Bank',
  },
  {
    value: '977027',
    label: 'Kamana Sewa Bikas Bank',
  },
  {
    value: '977063',
    label: 'Karnali Bikash Bank',
  },
  {
    value: '977021',
    label: 'Kasthamandap Development Bank',
  },
  {
    value: '977064',
    label: 'Kist Bank Ltd.',
  },
  {
    value: '977024',
    label: 'Kumari Bank',
  },
  {
    value: '977065',
    label: 'Laliguraas Development Bank',
  },
  {
    value: '977066',
    label: 'Laxmi Bank',
  },
  {
    value: '977068',
    label: 'Machhapucharre Bank',
  },
  {
    value: '977104',
    label: 'Mahalaxmi Bikas Bank',
  },
  {
    value: '977070',
    label: 'Malika Vikas Bank',
  },
  {
    value: '977071',
    label: 'Manakamana Development Bank',
  },
  {
    value: '977017',
    label: 'Manaslu Bikas Bank',
  },
  {
    value: '977003',
    label: 'Mega Bank Nepal',
  },
  {
    value: '977105',
    label: 'Mission Development Bank',
  },
  {
    value: '977011',
    label: 'Muktinath Bikas Bank',
  },
  {
    value: '977072',
    label: 'NABIL BANK LTD.',
  },
  {
    value: '977073',
    label: 'NDEP Development Bank',
  },
  {
    value: '977106',
    label: 'NIC Asia Bank',
  },
  {
    value: '977026',
    label: 'NMB BANK LIMITED',
  },
  {
    value: '977074',
    label: 'Nepal Bangladesh Bank Limited',
  },
  {
    value: '977075',
    label: 'Nepal Bank',
  },
  {
    value: '977077',
    label: 'Nepal CSI Development Bank',
  },
  {
    value: '977076',
    label: 'Nepal Credit & Commerce Bank Limited',
  },
  {
    value: '977078',
    label: 'Nepal Industrial and Commercial Bank',
  },
  {
    value: '977079',
    label: 'Nepal Investment Bank',
  },
  {
    value: '977080',
    label: 'Nepal SBI Bank',
  },
  {
    value: '977081',
    label: 'Nilgiri Bikas Bank',
  },
  {
    value: '977082',
    label: 'Nirdhan Uthhan Bank',
  },
  {
    value: '977083',
    label: 'Paschimanchal Development Bank',
  },
  {
    value: '977084',
    label: 'Pathibhara Bikas Bank',
  },
  {
    value: '977019',
    label: 'Prabhu Bank',
  },
  {
    value: '977085',
    label: 'Prime Commercial Bank',
  },
  {
    value: '977086',
    label: 'Public Development Bank',
  },
  {
    value: '977087',
    label: 'Rapti Bheri Development Bank',
  },
  {
    value: '977088',
    label: 'Rara Bikash Bank',
  },
  {
    value: '977089',
    label: 'Rastriya Banijya Bank',
  },
  {
    value: '977016',
    label: 'Reliable Development Bank',
  },
  {
    value: '977090',
    label: 'Resunga Bikas Bank',
  },
  {
    value: '977091',
    label: 'Rising Development Bank',
  },
  {
    value: '977025',
    label: 'SANIMA BANK LTD',
  },
  {
    value: '977092',
    label: 'Sahayogi Development Bank',
  },
  {
    value: '977109',
    label: 'Sajha Bikas Bank',
  },
  {
    value: '977094',
    label: 'Shangri-la Development Bank Ltd',
  },
  {
    value: '977010',
    label: 'Shine Resunga Development Bank',
  },
  {
    value: '977095',
    label: 'Siddhartha Bank Limited',
  },
  {
    value: '977096',
    label: 'Sindhu Bikas Bank',
  },
  {
    value: '977097',
    label: 'Standard Chartered Bank',
  },
  {
    value: '977098',
    label: 'Sunrise Bank',
  },
  {
    value: '977099',
    label: 'Supreme Development Bank',
  },
  {
    value: '977006',
    label: 'Tourism Development Bank',
  },
  {
    value: '977007',
    label: 'Triveni Bikash Bank',
  },
  {
    value: '977112',
    label: 'UNITED FINANCE LTD',
  },
  {
    value: '977023',
    label: 'Vibor Bikas Bank',
  },
  {
    value: '977100',
    label: 'Western Development Bank',
  },
  {
    value: '977009',
    label: 'Yeti Development Bank',
  },
];

export const NPL_BANK_CODES = [
  { value: '0001', label: 'Nepal Rastra Bank' },
  { value: '0101', label: 'Nepal Bank Limited' },
  { value: '0201', label: 'Rastriya Banijya Bank Limited' },
  { value: '0301', label: 'Agriculture Development Bank Ltd' },
  { value: '0401', label: 'Nabil Bank Ltd.' },
  { value: '0501', label: 'Nepal Investment Mega Bank Limited' },
  { value: '0601', label: 'Standard Chartered Bank Nepal Limited' },
  { value: '0701', label: 'Himalayan Bank Limited' },
  { value: '0801', label: 'Nepal SBI Bank Limited' },
  { value: '1001', label: 'Everest Bank Limited' },
  { value: '1501', label: 'Machhapuchre Bank Limited' },
  { value: '1601', label: 'Kumari Bank Ltd.' },
  { value: '1801', label: 'Siddhartha Bank Limited' },
  { value: '1901', label: 'Global IME Bank Limited' },
  { value: '2001', label: 'Citizens Bank International Limited' },
  { value: '2101', label: 'Prime Commercial Bank Limited' },
  { value: '2201', label: 'Laxmi Sunrise Bank Limited' },
  { value: '2301', label: 'NIC Asia Bank Limited' },
  { value: '2501', label: 'NMB Bank Limited' },
  { value: '2601', label: 'Prabhu Bank Limited' },
  { value: '4501', label: 'Sanima Bank Ltd.' },
  { value: '5401', label: 'Lumbini Bikas Bank Ltd' },
  { value: '5901', label: 'Saptakoshi Development Bank Ltd.' },
  { value: '6001', label: 'Jyoti Bikash Bank Ltd' },
  { value: '6401', label: 'Sindhu Bikash Bank Ltd.' },
  { value: '6601', label: 'Garima Bikas Bank Limited' },
  { value: '6801', label: 'Kamana Sewa Bikas Bank Ltd' },
  { value: '7201', label: 'Muktinath Bikas Bank Limited' },
  { value: '7301', label: 'Shangrila Development Bank Limited' },
  { value: '7401', label: 'Tinau Bikas Bank Limited' },
  { value: '7502', label: 'Excel Development Bank Ltd' },
  { value: '7509', label: 'Miteri Development Bank Limited' },
  { value: '7514', label: 'HAMRO BIKAS BANK LIMITED' },
  { value: '7515', label: 'Kankai Bikas Bank Ltd' },
  { value: '7516', label: 'Best Finance Company Ltd' },
  { value: '7517', label: 'Green Development Bank Ltd' },
  { value: '7518', label: 'Nepal Infrastructure Bank Ltd.' },
  { value: '7519', label: 'City Express Finance Company Ltd.' },
  { value: '7602', label: 'Nepal Community Development Bank Ltd' },
  { value: '7604', label: 'Janaki Finance Company Ltd.' },
  { value: '7606', label: 'Sahara Bikash Bank Limited' },
  { value: '8101', label: 'Shine Resunga Development Bank Ltd.' },
  { value: '8201', label: 'Srijana Finance Limited' },
  { value: '8301', label: 'Gurkhas Finance Limited' },
  { value: '9001', label: 'Goodwill Finance Limited' },
  { value: '9201', label: 'Shree Investment' },
  { value: '9701', label: 'Lalitpur Finance Co. Ltd' },
  { value: '9801', label: 'United Finance Ltd.' },
  { value: '9902', label: 'Progressive Finance Co. Ltd.' },
  { value: '9905', label: 'Pokhara Finance Ltd.' },
  { value: '9906', label: 'Central Finance Ltd' },
  { value: '9915', label: 'Guheshwori Merchant Banking and Finance Limited' },
  { value: '9919', label: 'ICFC Finance Limited' },
  { value: '9931', label: 'Mahalaxmi Bikash Bank Ltd.' },
  { value: '9935', label: 'Manjushree Finance Limited' },
  { value: '9936', label: 'Mission Development Bank Limited' },
  { value: '9939', label: 'Reliance Finance Ltd.' },
];
