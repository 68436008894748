// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlinePaperclip = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M15.97 4.03a4.2 4.2 0 0 0-5.94 0L5.28 8.78a6.67 6.67 0 1 0 9.44 9.44l6.75-6.75a.75.75 0 0 1 1.06 1.06l-6.75 6.75A8.17 8.17 0 1 1 4.22 7.72l4.75-4.75a5.7 5.7 0 1 1 8.06 8.06l-4.75 4.75a3.22 3.22 0 1 1-4.56-4.56l4.75-4.75a.75.75 0 1 1 1.06 1.06l-4.75 4.75a1.72 1.72 0 1 0 2.44 2.44l4.75-4.75a4.2 4.2 0 0 0 0-5.94Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlinePaperclip);
export { ForwardRef as IconV2OutlinePaperclip };