import type { ComponentProps } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Box } from '../../layout';

import type { Skeleton } from './Skeleton';

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

type SkeletonComponentProps = {
  $variant: NonNullable<ComponentProps<typeof Skeleton>['variant']>;
  height: number;
};

export const Component = styled(Box).attrs(({ height }: SkeletonComponentProps) => ({
  as: 'span',
  height: height || '1em',
}))<SkeletonComponentProps>`
  animation: ${pulseAnimation} 1.5s ease-in-out 0.5s infinite;
  display: block;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: ${({ theme }) => theme.borderRadius['2xs']};

  ${({ $variant, theme }) => {
    switch ($variant) {
      case 'text':
        return css`
          margin-top: 0;
          margin-bottom: 0;
          height: auto;
          transform-origin: 0 60%;
          transform: scale(1, 0.6);

          &:empty:before {
            content: '\\00a0';
          }
        `;
      case 'rect':
        return css``;
      case 'circle':
        return css`
          border-radius: ${theme.borderRadius.full};
        `;
      default:
        return null;
    }
  }};
`;
