import { RADIO_CARD_VARIANTS } from '@remote-com/norma';

import {
  composeFieldHidden,
  composeFieldRadio,
} from '@/src/components/Form/DynamicForm/composeFields';
import {
  EXPIRING_DOCUMENT_TYPE_LABELS,
  EXPIRING_DOCUMENT_TYPES,
  EXPIRING_DOCUMENT_OPTIONS,
  EXPIRING_DOCUMENT_OPTION_TO_STATUS_MAP,
} from '@/src/domains/expiringDocuments/constants';
import { friendlyLabel } from '@/src/helpers/general';

// This could be an alternative approach when mapping status 1:1 to flows is not enough anymore
// export const EXPIRING_DOCUMENT_RESPONSE_OPTIONS = {
//   RENEWED_WITH_DOCUMENT: 'renewed_with_document',
//   RENEWED_WITHOUT_DOCUMENT: 'renewed_without_document',
//   OTHER: 'other',
// };

export const documentType = composeFieldHidden({
  name: 'type',
});

export const expirationDate = composeFieldHidden({
  name: 'expirationDate',
});

export const employmentCountryField = composeFieldHidden({
  name: 'employmentCountry',
});

export const hiddenFields = [documentType, expirationDate, employmentCountryField];

export const status = composeFieldRadio({
  name: 'status',
  label: 'Renewal process',
  'aria-label': 'Renewal process',
  description:
    'Select the option that best fits your current status with regards to renewing the expiring document.',
  variant: RADIO_CARD_VARIANTS.CARD,
  direction: 'column',
  calculateDynamicProperties: ({
    [documentType.name]: type,
    [employmentCountryField.name]: employmentCountry,
  }) => {
    // We might not be able to continue mapping the flows 1:1 to a statuses, e.g we have multiple flows that end in
    // a pending renewal but have different questions/interactions. We can put them in the same option and
    // branch of based on further answers within the flow but for this to work the top-level description has to
    // cover all cases so that employees can choose the option fitting their situation.
    switch (type) {
      case EXPIRING_DOCUMENT_TYPES.WORK_PERMIT:
        return {
          options: [
            {
              id: EXPIRING_DOCUMENT_OPTIONS.ALREADY_RENEWED,
              value: EXPIRING_DOCUMENT_OPTIONS.ALREADY_RENEWED,
              label: 'Already renewed',
              description:
                'You have already renewed the document and can upload it or give a date when you can.',
            },
            {
              id: EXPIRING_DOCUMENT_OPTIONS.RENEWAL_IN_PROGRESS,
              value: EXPIRING_DOCUMENT_OPTIONS.RENEWAL_IN_PROGRESS,
              label: 'Renewal in progress',
              description:
                'You have started the renewal process and can give a date when you expect the renewed document.',
            },
            {
              id: EXPIRING_DOCUMENT_OPTIONS.CHANGED_COUNTRY,
              value: EXPIRING_DOCUMENT_OPTIONS.CHANGED_COUNTRY,
              label: 'Changed country',
              description: `The document that is about to expire can not be renewed because you are not living in ${employmentCountry.name} anymore.`,
            },
            {
              id: EXPIRING_DOCUMENT_OPTIONS.LEAVING_REMOTE,
              value: EXPIRING_DOCUMENT_OPTIONS.LEAVING_REMOTE,
              label: 'Leaving Remote',
              description: `Your employment with Remote is ending before the document will expire.`,
            },

            {
              id: EXPIRING_DOCUMENT_OPTIONS.CANNOT_START_YET,
              value: EXPIRING_DOCUMENT_OPTIONS.CANNOT_START_YET,
              label: 'Not started',
              description:
                'I will be renewing my document, but I have not started my application yet.',
            },
            // Enable and add further options when implementing the respective flow in dragon and tiger.
            {
              // This option should always be last
              id: EXPIRING_DOCUMENT_OPTIONS.SOMETHING_ELSE,
              value: EXPIRING_DOCUMENT_OPTIONS.SOMETHING_ELSE,
              label: 'Something else',
              description:
                'None of the other options fit, get in contact with our specialist through Zendesk.',
            },
          ],
        };
      default: {
        return {
          options: [
            {
              id: EXPIRING_DOCUMENT_OPTIONS.ALREADY_RENEWED,
              value: EXPIRING_DOCUMENT_OPTIONS.ALREADY_RENEWED,
              label: 'Already renewed',
              description:
                'You have already renewed the document and can upload it or give a date when you can.',
            },
          ],
        };
      }
    }
  },
});

export function getFriendlyDocumentTypeName(type) {
  return friendlyLabel(EXPIRING_DOCUMENT_TYPE_LABELS[type], 'Document');
}

/**
 * Normalizes the reminderDate value expected in the backend across multiple flows.
 * Depending on the flow the field that is mapped to the reminder date in the backend
 * requires different properties. In order to avoid complex dynamic props to handle all flows
 * each flow defines its own variant of the field and can rename it using this helper function.
 *
 * We need to use different field names to avoid React key conflicts.
 * @param {string} fieldName Name of the field that should be mapped to reminderDate
 * @returns Function accepting form values, returning them with the given field name as reminderDate
 */
export function normalizeReminderDate(fieldName) {
  return (formValues) => {
    const { [fieldName]: reminderDate, ...values } = formValues;

    if (reminderDate) {
      values.reminderDate = reminderDate;
    }

    return values;
  };
}

/**
 * Normalizes the `description` value expected in the backend across multiple flows.
 * In order to avoid complex dynamic props to handle all flows
 * each flow defines its own variant of the field and can rename it using this helper function.
 *
 * We need to use different field names to avoid React key conflicts.
 * @param {string} fieldName Name of the field that should be mapped to description
 * @returns Function accepting form values, returning them with the given field name as description
 */
export function normalizeDescription(fieldName) {
  return (formValues) => {
    const { [fieldName]: description, ...values } = formValues;

    if (description) {
      values.description = description;
    }

    return values;
  };
}

export function addDetailsValue(fieldName) {
  return (formValues) => {
    const { [fieldName]: detailsField, ...values } = formValues;

    if (detailsField) {
      values.details = { ...values?.details, [fieldName]: detailsField };
    }

    return values;
  };
}

export function mapOptionValueToStatus(formValues) {
  return { ...formValues, status: EXPIRING_DOCUMENT_OPTION_TO_STATUS_MAP[formValues.status] };
}
