import type { GetResponse } from '@remote-com/data-layer';

export const columnAccessorExclusionSet = new Set([
  'pspCode',
  'payrollOutputAmountField',
  'legalEntityPayElement.taxableEnum',
  'legalEntityPayElement.payrollDataSource',
  'legalEntityPayElement.grossedUp',
  'legalEntityPayElement.grossUpSupported',
  'legalEntityPayElement.deletable',
  'legalEntityPayElement.archivable',
  'legalEntityPayElement.pspCode',
  'legalEntityPayElement.taxable',
  'legalEntityPayElement.payrollOutputAmountField',
  'legalEntityPayElement.archivedAt',
  'legalEntityPayElement.createdBy.slug',
  'legalEntityPayElement.createdBy.name',
  'legalEntityPayElement.legalEntity.slug',
  'legalEntityPayElement.legalEntity.name',
  'legalEntityPayElement.slug',
  'legalEntityPayElement.requirement',
  'legalEntityPayElement.category',
  'legalEntityPayElement.type',
  'legalEntityPayElement.name',
  'legalEntityPayElement.code',
  'grossUpTaxPercentage',
  'fromPermanentEmployeePayElement',
  'archivedAt',
  'createdBy.slug',
  'createdBy.name',
  'payrollRun.lastEditor',
  'payrollRun.mappingRules',
  'payrollRun.creator',
  'payrollRun.legalEntity',
  'payrollRun.slug',
  'payrollRun.periodStart',
  'payrollRun.periodEnd',
  'payrollRun.currency',
  'payrollRun.period',
  'payrollRun.status',
  'payrollRun.name',
  'employment.shortSlug',
  'employment.contract',
  'employment.slug',
  'employment.product',
  'employment.user.slug',
  'employment.user.role',
  'dataSource.slug',
  'dataSource.type',
  'slug',
  'occurrence',
  'currency.slug',
  'currency.code',
  'category',
  'name',
  'value',
  'type',
  'status',
  'engineShouldGrossUp',
  'fromPermanentEmployeePayElement.archivedAt',
  'fromPermanentEmployeePayElement.category',
  'fromPermanentEmployeePayElement.code',
  'fromPermanentEmployeePayElement.createdBy.name',
  'fromPermanentEmployeePayElement.createdBy.slug',
  'fromPermanentEmployeePayElement.currency.code',
  'fromPermanentEmployeePayElement.currency.name',
  'fromPermanentEmployeePayElement.currency.slug',
  'fromPermanentEmployeePayElement.currency.symbol',
  'fromPermanentEmployeePayElement.dataSource',
  'fromPermanentEmployeePayElement.effectiveDate',
  'fromPermanentEmployeePayElement.endDate',
  'fromPermanentEmployeePayElement.engineShouldGrossUp',
  'fromPermanentEmployeePayElement.grossUpTaxPercentage',
  'fromPermanentEmployeePayElement.insertedAt',
  'fromPermanentEmployeePayElement.name',
  'fromPermanentEmployeePayElement.note',
  'fromPermanentEmployeePayElement.occurrence',
  'fromPermanentEmployeePayElement.payrollOutputAmountField',
  'fromPermanentEmployeePayElement.payrollOutputCategory',
  'fromPermanentEmployeePayElement.periodEnd',
  'fromPermanentEmployeePayElement.pspCode',
  'fromPermanentEmployeePayElement.slug',
  'fromPermanentEmployeePayElement.status',
  'fromPermanentEmployeePayElement.type',
  'fromPermanentEmployeePayElement.updatedAt',
  'fromPermanentEmployeePayElement.value',
  'legalEntityPayElement.description',
  'nextOccurrence.effectiveDate',
  'nextOccurrence.value',
  'payrollOutputCategory',
  'payrollRun',
  'payrollRun.engineProcessing',
  'payrollRun.expectedPayoutDate',
  'payrollRun.paymentsInfo.groupRef',
  'payrollRun.paymentsInfo.requester',
  'payrollRun.type',
  'periodEnd',
  'updatedAt',
]);

export type PayElementType =
  GetResponse<'/api/v1/employer/employee-pay-elements'>['data']['employeePayElements'][number];

// For context: https://www.notion.so/remotecom/Employee-Pay-Elements-Customer-view-Maintaining-the-current-implementation-9220ba75efe040b2b3e62af120d97beb?pvs=4
export const EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS = {
  ONE_OFF_TEMPORARY_PAY_ELEMENT: 'one-off-temporary-pay-element',
  NEXT_OCCURRENCE_OF_PERMANENT_PAY_ELEMENT: 'next-occurrence-of-permanent-pay-element',
  TEMPORARY_PAY_ELEMENT_LINKED_TO_PERMANENT_PAY_ELEMENT:
    'temporary-pay-element-linked-to-permanent-pay-element',
} as const;
