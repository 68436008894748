import { mergeAttributes, Node, ReactNodeViewRenderer } from '@tiptap/react';

import { yesNoValues } from '@/src/components/Form/DynamicForm/constants';
import { SignaturesWrapperComponent } from '@/src/domains/contracts/ContractsEditor/nodes/SignatureBlockField/SignaturesWrapperComponent';
import { TEMPLATE_SMART_TAGS } from '@/src/domains/contractTemplates/contractTemplates/constants';
import {
  isContractTemplateWithEmployerSignature,
  isContractTemplateWithRemoteSignature,
} from '@/src/domains/contractTemplates/helpers';

const employerSignature = ({ companyName }) => ({
  type: 'signatureBlockField',
  content: [
    {
      content: [
        {
          marks: [
            {
              type: 'bold',
            },
          ],
          text: companyName || 'The Company',
          type: 'text',
        },
      ],
      type: 'paragraph',
    },
    {
      type: 'signatureField',
      attrs: {
        isCompanySignature: true,
      },
    },
  ],
});

const recipientSignature = {
  type: 'signatureBlockField',
  content: [
    {
      content: [
        {
          marks: [
            {
              type: 'bold',
            },
          ],
          text: 'The undersigned',
          type: 'text',
        },
      ],
      type: 'paragraph',
    },
    {
      type: 'signatureField',
    },
  ],
};

const adminSignature = ({
  signerName,
  jobTitle,
  file,
  contactEmailSmartTag = TEMPLATE_SMART_TAGS.REMOTE_CONTACT_EMAIL,
  includeRecipientSignature,
}) => {
  return {
    type: 'signatureBlockField',
    content: [
      {
        content: [
          {
            marks: [
              {
                type: 'bold',
              },
            ],
            text: 'Remote',
            type: 'text',
          },
        ],
        type: 'paragraph',
      },
      {
        type: 'paragraph',
      },
      {
        content: [
          {
            attrs: {
              src: file,
            },
            type: 'image',
          },
        ],
        type: 'paragraph',
      },
      {
        type: 'paragraph',
      },
      {
        content: [
          {
            text: signerName,
            type: 'text',
          },
        ],
        type: 'paragraph',
      },
      {
        content: [
          {
            marks: [
              {
                type: 'bold',
              },
            ],
            text: jobTitle,
            type: 'text',
          },
        ],
        type: 'paragraph',
      },
      {
        content: [
          {
            attrs: {
              field: TEMPLATE_SMART_TAGS.EMPLOYMENT_AGREEMENT_DATE,
              label: TEMPLATE_SMART_TAGS.EMPLOYMENT_AGREEMENT_DATE,
            },
            type: 'smartField',
          },
        ],
        type: 'paragraph',
        attrs: {
          textAlign: includeRecipientSignature === yesNoValues.YES ? 'center' : 'left',
        },
      },
      {
        content: [
          {
            attrs: {
              field: contactEmailSmartTag,
              label: contactEmailSmartTag,
            },
            type: 'smartField',
          },
        ],
        type: 'paragraph',
        attrs: {
          textAlign: includeRecipientSignature === yesNoValues.YES ? 'center' : 'left',
        },
      },
    ],
  };
};

export const SignaturesWrapper = Node.create({
  name: 'signaturesWrapper',
  group: 'block',
  content: 'block*',

  addCommands() {
    return {
      addSignaturesWrapper:
        ({ contractProperties, adminSignatureAttrs }) =>
        ({ commands }) => {
          const signatureContent = [];

          if (isContractTemplateWithEmployerSignature(contractProperties)) {
            signatureContent.push(employerSignature({ companyName: this.options?.company?.name }));
          }

          if (isContractTemplateWithRemoteSignature(contractProperties)) {
            signatureContent.push(adminSignature(adminSignatureAttrs));
          }

          if (adminSignatureAttrs?.includeRecipientSignature === yesNoValues.YES) {
            signatureContent.push(recipientSignature);
          }

          return commands.insertContent({
            type: 'doc',
            content: [
              {
                type: 'paragraph',
              },
              {
                type: this.name,
                content: signatureContent,
              },
              {
                type: 'paragraph',
              },
            ],
          });
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (element) => element.getAttribute('data-type') === this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-type': this.name, id: 'contract-signatures-block' }, HTMLAttributes),
      0,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SignaturesWrapperComponent);
  },
});
