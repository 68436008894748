import type { ForwardedRef } from 'react';
import { useRef } from 'react';

/**
 * There are some situations where we only want to create a new ref if one is not provided to a component
 * or hook as a prop. However, due to the `rules-of-hooks`, we cannot conditionally make a call to `React.useRef`
 * only in the situations where the ref is not provided as a prop.
 * This hook aims to encapsulate that logic, so the consumer doesn't need to be concerned with violating `rules-of-hooks`.
 */
export function useProvidedRefOrCreate<T>(providedRef?: ForwardedRef<T>) {
  const createdRef = useRef<T>(null);
  return providedRef ?? createdRef;
}
