import { format } from 'date-fns-tz';

import { EVENT_TYPES } from '@/src/components/CalendarYear/constants';
import { PAYROLL_CYCLE_EVENTS } from '@/src/domains/payroll/admin/PayrollCalendars/constants';
import { PAYROLL_EVENT_COLORS } from '@/src/domains/payroll/admin/PayrollCalendars/styles';

function isDateObject(date) {
  return date instanceof Date;
}

/**
 * Creates a new date timezone free. It's usefull to create dates that are absolute and not
 * moments in time. Ex: 4th of July is always in the 4th of July no matter the timezone. But
 * a meeting could be in the 5th of July at 4PM. This moment in time is relative to timezone.
 * This function removes the amount of timeoffset from the date to guarantee there's no interference
 *
 * Ex: in US timezone (GMT -5000 EST)
 * ```js
 * const d1 = new Date("2023-01-01");
 * d1 => 2022, Dec 31 19:00:00.000 GMT -5000
 * ```
 *
 * ```js
 * const d2 = createDate("2023-01-01")
 * d2 => 2023, Jan 01 00:00:00.000 GMT -5000
 * ```
 * @param {Date | string | number} date a Date to convert
 * @returns the given date without the offset of the timezone to guarantee that timezone won't affect the date.
 */
export function createDate(date) {
  const d = isDateObject(date) ? date : new Date(date);

  const offsetMillis = d.getTimezoneOffset() * 60 * 1000;
  const dateWithoutTimeOffset = new Date(d.getTime() + offsetMillis);
  return new Date(dateWithoutTimeOffset);
}

export function createDateYMD(year, month, day) {
  return createDate(
    `${year}-${month + 1 < 10 ? '0' : ''}${month + 1}-${day < 10 ? '0' : ''}${day}`
  );
}

export function formatDate(date) {
  return format(date, 'yyyy-MM-dd');
}

export function getEventDescription(cycleStepKey) {
  return PAYROLL_CYCLE_EVENTS.find((event) => event.id === cycleStepKey)?.label;
}

export function createCustomEvent({ cycleStepKey, date }) {
  return {
    date,
    description: getEventDescription(cycleStepKey),
    label: {
      name: EVENT_TYPES.CUSTOM_EVENT,
      style: PAYROLL_EVENT_COLORS[cycleStepKey]?.color?.style,
    },
  };
}
