export const PAYROLL_AS_A_PRODUCT_PAGE_SIZE = 10;

export const payrollRunsTableInitialState = {
  pageIndex: 0,
  pageSize: PAYROLL_AS_A_PRODUCT_PAGE_SIZE,
};

export const payrollLandingTabs = {
  PAYROLL_RUNS: 'payroll-runs',
  EMPLOYEES: 'employees',
  REPORTS: 'reports',
};

export const payrollLandingTabsLabels = {
  [payrollLandingTabs.PAYROLL_RUNS]: 'Payroll runs',
  [payrollLandingTabs.EMPLOYEES]: 'Employees',
  [payrollLandingTabs.REPORTS]: 'Reports',
};
