// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneFolderOpen = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M11.43 9.25c-1.44 0-2.17 0-2.75.26A3 3 0 0 0 7.4 10.6c-.36.53-.48 1.25-.71 2.67L5.42 20.9c-.1.64-.16.96-.06 1.2a1 1 0 0 0 .44.53c.23.13.55.13 1.2.13h10.57c1.44 0 2.17 0 2.75-.26a3 3 0 0 0 1.27-1.08c.36-.53.48-1.25.71-2.67l.96-5.76c.22-1.29.32-1.93.14-2.43a2 2 0 0 0-.88-1.03c-.46-.27-1.11-.27-2.41-.27h-8.68Z" /><path d="M1.25 6.05c0-1.68 0-2.52.33-3.16a3 3 0 0 1 1.3-1.31c.65-.33 1.49-.33 3.17-.33h1.73c.8 0 1.2 0 1.56.1a3 3 0 0 1 1.54.94c.25.28.42.64.78 1.36.24.48.36.72.52.9a2 2 0 0 0 1.03.64c.24.06.5.06 1.04.06h4a2.5 2.5 0 0 1 2.5 2.5h-9.23c-1.93 0-2.9 0-3.67.35a4 4 0 0 0-1.7 1.44c-.47.7-.63 1.66-.95 3.56l-1.54 9.25a.48.48 0 0 1-.48.4 1.93 1.93 0 0 1-1.93-1.93V6.05Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneFolderOpen);
export { ForwardRef as IconV2DuotoneFolderOpen };