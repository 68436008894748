import { Content, Overlay, Root, Portal } from '@radix-ui/react-dialog';
import { trackEvent, COMMON_EVENT_NAMES } from '@remote-com/analytics';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ModalOverlay } from '@/src/components/Modal';
import RemoteLogoLoader from '@/src/components/Ui/Loader/RemoteLoader';
import { useUserContext } from '@/src/components/UserProvider';

import { constructCalendlyUrl } from './helpers';

const ModalContent = styled(Content)`
  min-width: 900px;
  max-width: 1000px;
  height: 90%;
  max-height: 680px;
`;

const Iframe = styled.iframe`
  display: block;
  border: none;
  width: 1000px;
  height: 100%;
`;

export function CalendlyModal({ isVisible, onClose }: { isVisible: boolean; onClose: () => void }) {
  const { user } = useUserContext();
  const [isLoadingCalendly, setCalendlyLoading] = useState(true);

  useEffect(() => {
    function trackCalendlyEvent(event: MessageEvent) {
      if (event) {
        const isCalendlyEvent =
          event.origin === 'https://calendly.com' &&
          event?.data?.event &&
          event.data.event.indexOf('calendly.') === 0;

        if (isCalendlyEvent) {
          trackEvent({
            name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
            eventProps: {
              formName: event.data.event,
              locationOfAction: 'schedule-a-call-with-expert-modal',
            },
          });
        }
      }
    }

    window.addEventListener('message', trackCalendlyEvent);

    return () => {
      window.removeEventListener('message', trackCalendlyEvent);
    };
  }, []);

  function handleClose(event: any) {
    event.stopPropagation();
    onClose();
    setCalendlyLoading(true);
  }

  function handleOnLoad() {
    setCalendlyLoading(false);
  }

  const calendlyURL = constructCalendlyUrl(user);

  return (
    <Root open={isVisible} modal>
      <Portal>
        <Overlay asChild>
          <ModalOverlay>
            {isLoadingCalendly && <RemoteLogoLoader />}
            <ModalContent onEscapeKeyDown={handleClose} onPointerDownOutside={handleClose}>
              <Iframe
                src={calendlyURL}
                sandbox="allow-same-origin allow-scripts allow-forms"
                allow="fullscreen"
                title="Schedule a call"
                data-testid="schedule-a-call-iframe"
                onLoad={handleOnLoad}
              />
            </ModalContent>
          </ModalOverlay>
        </Overlay>
      </Portal>
    </Root>
  );
}
