import { SROnly } from '@remote-com/norma';

import { ACTIONS_COLUMN_ID } from '@/src/components/Table/helpers/constants';

export const ActionsColumn = {
  id: ACTIONS_COLUMN_ID,
  Header: <SROnly>Actions</SROnly>,
  accessor: (column) => column,
  disableSortBy: true,
  disableFilters: true,
  disableExport: true,
  disableRowClick: true,
  style: {
    width: 'max-content',
  },
};
