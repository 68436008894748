import type { CSSProperties } from 'styled-components';

export const RADIO_CARD_VARIANTS = {
  CARD: 'card',
  CARD_SIMPLE: 'card-simple',
  CARD_EXPANDABLE: 'card-expandable',
} as const;

export type RadioCardVariant = (typeof RADIO_CARD_VARIANTS)[keyof typeof RADIO_CARD_VARIANTS];

export type Direction = Extract<
  CSSProperties['flexDirection'],
  'row' | 'column-reverse' | 'column'
>;

export type Size = 'sm' | 'lg';
