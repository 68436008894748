import type { ReactNode } from 'react';
import React, { useContext, useState, createContext } from 'react';

import { EditColumnsDrawer } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/EditColumnsDrawer';
import type { useColumnsState } from '@/src/components/Table/hooks/useColumnsState';

type EditColumnsContextType = {
  isOpen: boolean;
  isListeningClickOutside: boolean;
  setListenClickOutside: (listen: boolean) => void;
  openEditColumnsDrawer: () => void;
  closeEditColumnsDrawer: () => void;
};

const EditColumnsContext = createContext<EditColumnsContextType | null>(null);

type EditColumnProps = {
  children: ReactNode;
  columnsStateProps: ReturnType<typeof useColumnsState>;
  allowConfigureAllColumns: boolean;
  isTableViewsEnabledExperimental: boolean;
};

export function EditColumnsProvider({
  children,
  columnsStateProps,
  allowConfigureAllColumns,
  isTableViewsEnabledExperimental,
}: EditColumnProps) {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isListeningClickOutside, setListenClickOutside] = useState(true);

  const providerValue: EditColumnsContextType = {
    isOpen: !!isDrawerVisible,
    isListeningClickOutside,
    setListenClickOutside,
    openEditColumnsDrawer: () => setIsDrawerVisible(true),
    closeEditColumnsDrawer: () => {
      setIsDrawerVisible(false);
      setListenClickOutside(true); // reset to default state when the drawer is closed
    },
  };

  return (
    <EditColumnsContext.Provider value={providerValue}>
      {children}
      {isDrawerVisible && (
        <EditColumnsDrawer
          columnsStateProps={columnsStateProps}
          allowConfigureAllColumns={allowConfigureAllColumns}
          isVisible={isDrawerVisible}
          onClose={providerValue.closeEditColumnsDrawer}
          setListenToClickOutside={providerValue.setListenClickOutside}
          isTableViewsEnabledExperimental={isTableViewsEnabledExperimental}
        />
      )}
    </EditColumnsContext.Provider>
  );
}

export const useEditColumns = (): EditColumnsContextType => {
  const context = useContext(EditColumnsContext);
  if (!context) {
    throw new Error('useEditColumns must be used within an EditColumnsProvider');
  }
  return context;
};
