import { Box, Tooltip } from '@remote-com/norma';
import { IconAlertTriangle } from '@remote-com/norma/icons/IconAlertTriangle';
import { IconEye } from '@remote-com/norma/icons/IconEye';
import { IconEyeOff } from '@remote-com/norma/icons/IconEyeOff';
import { IconTimesheet } from '@remote-com/norma/icons/IconTimesheet';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import { useTheme, css } from 'styled-components';

import { getFriendlyFieldName } from '@/src/domains/contracts/ContractsEditor/helpers';
import { EditableContentTag } from '@/src/domains/contracts/ContractsEditor/nodes/shared/ContentTag';
import { EditConditionalModal } from '@/src/domains/contractTemplates/contractTemplates/modals/ConditionalModal/EditConditionalModal';
import { useModalContext } from '@/src/hooks/useModalContext';

import { CONDITIONAL_NODE_TYPES } from '../../constants';

import { getFriendlyOperator } from './helpers';
import {
  Condition,
  ConditionalField,
  InlineConditionalContent,
  BlockCondition,
  ExplainerText,
  ConditionalListItem,
  ConditionalContent,
  ListItemWrapper,
  ConditionalOperator,
} from './styled';

const inlineSpanStyle = {
  display: 'inline',
};

const tooltipStyle = {
  wrapper: css`
    padding: 8px 12px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey[800]};
  `,
};

export const createNodePresentationProps = ({
  conditionalResult,
  isSmartFieldAvailable,
  isSmartFieldPlaceholder,
  isParentDisabled,
  colors,
}) => {
  if (isParentDisabled) {
    // This only occurs in contract documents, and forces the styling of nested
    // child nodes when the parent node condition is false so that the editor
    // gives a visual representation that all nested children will be disabled and
    // not show up in the final document
    return {
      border: colors.grey[600],
      background: colors.grey[100],
      text: colors.grey[600],
      Icon: IconEyeOff,
      iconFill: colors.grey[600],
      toolTipLabel: '',
    };
  }

  // The only time we should have an unknown smartfield is if a conditional block is pasted in
  // When the editor is reloaded at any point, a placeholder smartfield is automatically created, so
  // this only occurs when an unknown field is pasted in for the first time
  const unknownSmartField = !isSmartFieldAvailable && !isSmartFieldPlaceholder;
  const hasConditionalValue =
    conditionalResult && isSmartFieldAvailable && !isSmartFieldPlaceholder;

  if (hasConditionalValue) {
    return {
      border: colors.brand[700],
      background: colors.brand[100],
      text: colors.brand[700],
      Icon: IconEye,
      iconFill: colors.brand[700],
      tooltipLabel: '',
    };
  }

  if (isSmartFieldPlaceholder && conditionalResult) {
    return {
      border: colors.yellow[900],
      background: colors.yellow[100],
      text: colors.yellow[900],
      Icon: IconTimesheet,
      iconFill: colors.yellow[900],
      tooltipLabel: 'This smartfield is a placeholder and not available yet',
    };
  }

  if (unknownSmartField) {
    return {
      border: colors.red[900],
      background: colors.red[100],
      text: colors.red[900],
      Icon: IconAlertTriangle,
      iconFill: colors.red[900],
      tooltipLabel: 'This smartfield is unavailable or missing data',
    };
  }

  // return missing value styles if it doesn't fit in any of the above
  return {
    border: colors.grey[600],
    background: colors.grey[100],
    text: colors.grey[600],
    Icon: IconEyeOff,
    iconFill: colors.grey[600],
    tooltipLabel: '',
  };
};

const determineWrapperElement = (conditionalType) => {
  switch (conditionalType) {
    case CONDITIONAL_NODE_TYPES.INLINE:
      return 'span';
    case CONDITIONAL_NODE_TYPES.LIST_ITEM:
      return 'li';
    case CONDITIONAL_NODE_TYPES.BLOCK:
    default:
      return 'div';
  }
};

const ConditionalFields = ({
  fields,
  operator,
  handleClick,
  enableHover,
  nodePresentation,
  showFieldIcon = false,
}) => {
  const operatorName = getFriendlyOperator(operator);
  const operatorText = fields.length > 1 ? ` ${operatorName} ` : ` ${operatorName}`;

  return (
    <EditableContentTag onClick={handleClick} enableHover={enableHover}>
      <ConditionalField $showFieldIcon={showFieldIcon}>
        <Box mr={showFieldIcon ? 2 : 0}>{getFriendlyFieldName(fields[0])}</Box>
        {showFieldIcon && <nodePresentation.Icon fill={nodePresentation.iconFill} width="12px" />}
      </ConditionalField>
      <ConditionalOperator $weight={400}>
        <em>{operatorText}</em>
      </ConditionalOperator>
      {fields.length > 1 && getFriendlyFieldName(fields[1])}
    </EditableContentTag>
  );
};

const BlockConditionHeader = ({
  fields,
  operator,
  conditionalResult,
  handleClick,
  hasConditionalEditing,
  nodePresentation,
  showFieldIcon,
}) => {
  return (
    <Box display="flex">
      <nodePresentation.Icon fill={nodePresentation.iconFill} width="16px" />
      <BlockCondition
        hasConditionalEditing={hasConditionalEditing}
        contentEditable={false}
        conditionalResult={conditionalResult}
        $nodePresentation={nodePresentation}
      >
        <Tooltip
          trigger="mouseenter"
          disabled={!nodePresentation.tooltipLabel}
          label={nodePresentation.tooltipLabel}
          styles={tooltipStyle}
        >
          <span>
            <ConditionalFields
              fields={fields}
              operator={operator}
              handleClick={handleClick}
              enableHover={hasConditionalEditing}
              nodePresentation={nodePresentation}
              showFieldIcon={showFieldIcon}
            />
          </span>
        </Tooltip>
      </BlockCondition>
    </Box>
  );
};

const InlineCondition = ({
  fields,
  operator,
  conditionalResult,
  handleClick,
  hasConditionalEditing,
  nodePresentation,
  showFieldIcon,
}) => {
  return (
    <NodeViewWrapper as="span" data-conditional-result={conditionalResult}>
      <Condition
        hasConditionalEditing={hasConditionalEditing}
        contentEditable={false}
        conditionalResult={conditionalResult}
        $nodePresentation={nodePresentation}
      >
        <Tooltip
          trigger="mouseenter"
          disabled={!nodePresentation.tooltipLabel}
          label={nodePresentation.tooltipLabel}
          styles={tooltipStyle}
        >
          <span>
            <ConditionalFields
              fields={fields}
              operator={operator}
              handleClick={handleClick}
              enableHover={hasConditionalEditing}
              nodePresentation={nodePresentation}
              showFieldIcon={showFieldIcon}
            />
          </span>
        </Tooltip>
      </Condition>
      <InlineConditionalContent $conditionalResult={conditionalResult} />
    </NodeViewWrapper>
  );
};

const ListItemCondition = ({
  fields,
  operator,
  conditionalResult,
  listPosition,
  handleClick,
  hasConditionalEditing,
  nodePresentation,
  showFieldIcon,
}) => {
  // The negative margin in the wrapper style ensures that the numbers & letters
  // align w/their sibling elements
  const wrapperStyle = {
    border: `1px dashed ${nodePresentation.border}`,
    padding: 12,
    borderRadius: 4,
    marginLeft: -30,
    marginTop: 12,
    marginBottom: 12,
    wordBreak: 'break-word',
  };

  return (
    <NodeViewWrapper as="div" style={wrapperStyle} data-conditional-result={conditionalResult}>
      <BlockConditionHeader
        fields={fields}
        operator={operator}
        conditionalResult={conditionalResult}
        contentEditable={false}
        handleClick={handleClick}
        hasConditionalEditing={hasConditionalEditing}
        nodePresentation={nodePresentation}
        showFieldIcon={showFieldIcon}
      />
      {!conditionalResult && listPosition && (
        <ExplainerText variant="sm">{`Conditional hidden, please check any references for ${listPosition}`}</ExplainerText>
      )}
      <ConditionalListItem>
        <ListItemWrapper>
          <ConditionalContent />
        </ListItemWrapper>
      </ConditionalListItem>
    </NodeViewWrapper>
  );
};

export const ConditionalCondition = (props) => {
  const { showModal } = useModalContext();

  const {
    fields,
    operator,
    conditionalResult,
    conditionalType,
    isSmartFieldAvailable,
    isSmartFieldPlaceholder,
    isParentDisabled,
  } = props.node.attrs;
  const { isViewOnly, editableConditionals } = props.extension.options;
  const isInline = conditionalType === CONDITIONAL_NODE_TYPES.INLINE;
  const isListItem = conditionalType === CONDITIONAL_NODE_TYPES.LIST_ITEM;
  const { colors } = useTheme();

  const hasConditionalEditing = props.editor.isEditable && editableConditionals;

  const showFieldIcon = !isSmartFieldAvailable || isSmartFieldPlaceholder;
  const nodePresentation = createNodePresentationProps({
    isSmartFieldPlaceholder,
    isSmartFieldAvailable,
    isParentDisabled,
    conditionalResult,
    colors,
  });

  function showEditConditionalModal() {
    if (!hasConditionalEditing) {
      return;
    }

    showModal({
      component: EditConditionalModal,
      modalProps: {
        nodePosition: props.getPos(),
        node: props.node,
        editor: props.editor,
        updateNodeAttributes: props.updateAttributes,
        currentValues: { fields, operator, conditionalType },
      },
    });
  }

  if (isViewOnly && !conditionalResult) {
    return null;
  }

  if (isViewOnly) {
    const wrapperElementType = determineWrapperElement(conditionalType);

    return (
      <NodeViewWrapper as={wrapperElementType}>
        <NodeViewContent style={wrapperElementType === 'span' ? inlineSpanStyle : null} />
      </NodeViewWrapper>
    );
  }

  if (isInline) {
    return (
      <InlineCondition
        {...props.node.attrs}
        {...props.extension.options}
        handleClick={showEditConditionalModal}
        hasConditionalEditing={hasConditionalEditing}
        nodePresentation={nodePresentation}
        showFieldIcon={showFieldIcon}
      />
    );
  }

  if (isListItem) {
    return (
      <ListItemCondition
        {...props.node.attrs}
        {...props.extension.options}
        handleClick={showEditConditionalModal}
        hasConditionalEditing={hasConditionalEditing}
        nodePresentation={nodePresentation}
        showFieldIcon={showFieldIcon}
      />
    );
  }

  const wrapperStyle = {
    border: `1px dashed ${nodePresentation.border}`,
    padding: 12,
    borderRadius: 4,
  };

  return (
    <NodeViewWrapper as="div" style={wrapperStyle} data-conditional-result={conditionalResult}>
      <BlockConditionHeader
        fields={fields}
        operator={operator}
        conditionalResult={conditionalResult}
        handleClick={showEditConditionalModal}
        hasConditionalEditing={hasConditionalEditing}
        nodePresentation={nodePresentation}
        showFieldIcon={showFieldIcon}
      />
      <ConditionalContent />
    </NodeViewWrapper>
  );
};
