import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import styled from 'styled-components';

import { getFriendlyName } from '@/src/domains/contracts/ContractsEditor/helpers';

export const StyledNodeViewContent = styled(NodeViewContent)`
  ${({ theme }) => theme.typography.xsMedium};
  margin: 6px;
  padding: 2px 8px;
  border: ${({ theme }) => `1px dashed ${theme.colors.radiance}`};
  border-radius: 4px;
`;

export const StyledCondition = styled.span`
  ${({ theme }) => theme.typography.smMedium};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.radiance};
  background: ${({ theme }) => theme.colors.cyan[200]};
  border-radius: 4px;
  padding: 4px;
  text-transform: uppercase;
  user-select: none;
`;

export const ConditionalComponent = (props) => {
  const { conditional, value, content, display } = props.node.attrs;
  const { hasStyling } = props.extension.options;

  if (display) {
    if (hasStyling) {
      return (
        <NodeViewWrapper as="span">
          <StyledCondition contentEditable={false}>
            {getFriendlyName(conditional)}: <strong>{value}</strong>
          </StyledCondition>
          <StyledNodeViewContent as="span">{content}</StyledNodeViewContent>
        </NodeViewWrapper>
      );
    }

    return content;
  }

  return null;
};
