import styled, { css } from 'styled-components';

import type { Theme } from '../../foundations/theme';
import { sharedTransition } from '../animations';
import Button from '../button/Button';
import { focusVisible } from '../common.styled';
import { Text } from '../text/Text';

export const Title = styled(Text).attrs(({ theme }) => ({
  variant: 'smMedium',
  color: theme.colors.grey[900],
}))`
  display: block;
`;

export const Message = styled(Text).attrs(({ theme }) => ({
  variant: 'xs',
  color: theme.colors.grey[600],
}))``;

function getColorsByTone($tone: string, theme: Theme) {
  return {
    brand: {
      iconFill: theme.colors.brand[600],
      iconBackgroundColor: theme.colors.brand[50],
      borderColor: theme.colors.brand[300],
      hoverBackgroundColor: theme.colors.brand[50],
      focusRingBorderColor: theme.colors.brand[500],
    },
    orange: {
      iconFill: theme.colors.orange[500],
      iconBackgroundColor: theme.colors.orange[50],
      borderColor: theme.colors.orange[400],
      hoverBackgroundColor: theme.colors.orange[50],
      focusRingBorderColor: theme.colors.orange[600],
    },
    green: {
      iconFill: theme.colors.green[600],
      iconBackgroundColor: theme.colors.green[50],
      borderColor: theme.colors.green[400],
      hoverBackgroundColor: theme.colors.green[50],
      focusRingBorderColor: theme.colors.green[600],
    },
    cyan: {
      iconFill: theme.colors.cyan[600],
      iconBackgroundColor: theme.colors.cyan[50],
      borderColor: theme.colors.cyan[500],
      hoverBackgroundColor: theme.colors.cyan[50],
      focusRingBorderColor: theme.colors.cyan[600],
    },
    pink: {
      iconFill: theme.colors.pink[600],
      iconBackgroundColor: theme.colors.pink[50],
      borderColor: theme.colors.pink[400],
      hoverBackgroundColor: theme.colors.pink[50],
      focusRingBorderColor: theme.colors.pink[600],
    },
    yellow: {
      iconFill: theme.colors.yellow[600],
      iconBackgroundColor: theme.colors.yellow[50],
      borderColor: theme.colors.yellow[400],
      hoverBackgroundColor: theme.colors.yellow[50],
      focusRingBorderColor: theme.colors.yellow[600],
    },
    grey: {
      iconFill: theme.colors.grey[600],
      iconBackgroundColor: theme.colors.grey[50],
      borderColor: theme.colors.grey[300],
      hoverBackgroundColor: theme.colors.grey[100],
      focusRingBorderColor: theme.colors.grey[600],
    },
  }[$tone];
}

export const ButtonCallout = styled((props) => <Button variant="raw" {...props} />)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.space[3]}px ${({ theme }) => theme.space[5]}px
    ${({ theme }) => theme.space[3]}px ${({ theme }) => theme.space[3]}px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 1px solid;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.blank};
  ${sharedTransition('background-color')}

  &:focus.focus-visible,
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &[aria-disabled='true']:hover {
    cursor: not-allowed;
    background-color: initial;
  }

  a {
    text-decoration: none;
  }

  ${({ theme, $tone }) => {
    const colors = getColorsByTone($tone, theme);
    if (!colors) return null;
    return css`
      --iconFill: ${colors?.iconFill};
      --iconBackgroundColor: ${colors?.iconBackgroundColor};
      border-color: ${colors?.borderColor};

      &:hover {
        background-color: ${colors?.hoverBackgroundColor};
        --iconBackgroundColor: ${theme.colors.blank};
      }

      ${focusVisible(css`
        background-color: ${colors?.hoverBackgroundColor};
        --iconBackgroundColor: ${theme.colors.blank};

        border-color: ${colors?.borderColor};
        box-shadow: 0 0 0 2px ${theme.colors.blank}, 0 0 0 4px ${colors?.focusRingBorderColor};
        outline: 1px solid transparent;
      `)};
    `;
  }}

  > svg {
    display: none;
  }
`;

export const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 14px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: var(--iconBackgroundColor);
  ${sharedTransition('background-color')}
  &:focus {
    background-color: ${({ theme }) => theme.colors.blank};
  }
`;

export const LogoContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 14px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;
