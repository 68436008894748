import { useGet } from '@remote-com/data-layer';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';

type ListTableViewsParams = {
  queryParams: {
    persistenceKey: string;
  };
  enabled: boolean;
};

export function useListTableViews({ queryParams, enabled }: ListTableViewsParams) {
  return useGet<
    '/api/v1/table-views',
    TableViewsApi.ListTableViewsResponse,
    { data: TableViewsApi.TableView[] }
  >('/api/v1/table-views', {
    params: {
      queryParams,
    },
    options: {
      select: (data) => ({
        ...data,
        data: data.data?.views?.map((view) => {
          return {
            ...view,
            config: typeof view.config === 'string' ? JSON.parse(view.config) : view.config,
          };
        }),
      }),
      enabled,
    },
  });
}
