import { Box } from '@remote-com/norma';
import type { Ref } from 'react';
import { forwardRef } from 'react';

import { ButtonIcon } from '@/src/components/Button';
import { UPDATE_TYPES, OVERLAY_BUTTON_ICONS } from '@/src/domains/updates/constants';
import { Dot } from '@/src/domains/updates/OverlayButtonIcon/OverlayButtonIcon.styled';

type UpdateType = (typeof UPDATE_TYPES)[keyof typeof UPDATE_TYPES];
type OverlayIconWrapperProps = {
  isActive: boolean;
  size: 'sm' | 'md';
  type: UpdateType;
};

const OverlayIconWrapper = ({ size, type, isActive, ...props }: OverlayIconWrapperProps) => {
  const Icon = OVERLAY_BUTTON_ICONS[type];

  return (
    <Box display="inline-flex" position="relative">
      <Dot data-testid="active-dot" $isActive={isActive} $size={size} />
      <Icon {...props} />
    </Box>
  );
};

type OverlayButtonIconProps = OverlayIconWrapperProps & {
  onClick: () => void;
  label: string;
};

export const OverlayButtonIcon = forwardRef(
  (
    {
      isActive,
      onClick,
      label,
      size = 'sm',
      type = UPDATE_TYPES.NOTIFICATIONS,
    }: OverlayButtonIconProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <ButtonIcon
        Icon={(props) => (
          <OverlayIconWrapper size={size} type={type} isActive={isActive} {...props} />
        )}
        size={size}
        variant="ghost"
        tone="secondary"
        onClick={onClick}
        label={label}
        aria-haspopup="dialog"
        ref={ref}
      />
    );
  }
);
