// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneStructure = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g opacity={0.32}><path d="M5.25 1.25h1.5c1.4 0 2.1 0 2.63.27.48.24.86.62 1.1 1.1.27.53.27 1.23.27 2.63v1.5c0 1.4 0 2.1-.27 2.63a2.5 2.5 0 0 1-1.1 1.1c-.53.27-1.23.27-2.63.27h-1.5c-1.4 0-2.1 0-2.63-.27a2.5 2.5 0 0 1-1.1-1.1c-.27-.53-.27-1.23-.27-2.63v-1.5c0-1.4 0-2.1.27-2.63a2.5 2.5 0 0 1 1.1-1.1c.53-.27 1.23-.27 2.63-.27Z" /><path d="M18.75 13.25h-1.5c-1.4 0-2.1 0-2.63.27a2.5 2.5 0 0 0-1.1 1.1c-.27.53-.27 1.23-.27 2.63v1.5c0 1.4 0 2.1.27 2.64.24.47.62.85 1.1 1.09.53.27 1.23.27 2.63.27h1.5c1.4 0 2.1 0 2.64-.27a2.5 2.5 0 0 0 1.09-1.1c.27-.53.27-1.23.27-2.63v-1.5c0-1.4 0-2.1-.27-2.63a2.5 2.5 0 0 0-1.1-1.1c-.53-.27-1.23-.27-2.63-.27Z" /></g><path fillRule="evenodd" clipRule="evenodd" d="M22.75 5.25c0-1.4 0-2.1-.27-2.63a2.5 2.5 0 0 0-1.1-1.1c-.53-.27-1.23-.27-2.63-.27h-1.5c-1.4 0-2.1 0-2.63.27a2.5 2.5 0 0 0-1.1 1.1c-.27.53-.27 1.23-.27 2.63v1.5c0 1.4 0 2.1.27 2.63.24.48.62.86 1.1 1.1.53.27 1.23.27 2.63.27h1.5c1.4 0 2.1 0 2.64-.27a2.5 2.5 0 0 0 1.09-1.1c.27-.53.27-1.23.27-2.63v-1.5Zm-21.5 12c0-1.4 0-2.1.27-2.63a2.5 2.5 0 0 1 1.1-1.1c.53-.27 1.23-.27 2.63-.27h1.5c1.4 0 2.1 0 2.63.27.48.24.86.62 1.1 1.1.27.53.27 1.23.27 2.63v1.5c0 1.4 0 2.1-.27 2.64a2.5 2.5 0 0 1-1.1 1.09c-.53.27-1.23.27-2.63.27h-1.5c-1.4 0-2.1 0-2.63-.27a2.5 2.5 0 0 1-1.1-1.1c-.27-.53-.27-1.23-.27-2.63v-1.5Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneStructure);
export { ForwardRef as IconV2DuotoneStructure };