import { BoxedIcon } from '@remote-com/norma';
import type { BoxedIconTone } from '@remote-com/norma';
import type { ElementType } from 'react';

import { ItemAvatar } from '@/src/domains/inAppNotifications/InAppNotifications.styled';

type NotificationItemIconProps = {
  /**
   * The base color of the box and icon.
   */
  tone?: BoxedIconTone;
  /**
   * The icon to be displayed within the box.
   *
   * It is recommended to use icons from the Duotone set with this component.
   */
  Icon: ElementType;
};

export const NotificationItemIcon = ({ tone, Icon }: NotificationItemIconProps) => (
  <ItemAvatar>
    <BoxedIcon Icon={Icon} tone={tone} size="lg" />
  </ItemAvatar>
);
