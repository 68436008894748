// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconCheckAlt = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M3.3 7.54a1.4 1.4 0 0 0-1.9 0 1.24 1.24 0 0 0 0 1.82l4.55 4.34 8.92-8.5a1.24 1.24 0 0 0 0-1.82 1.4 1.4 0 0 0-1.91 0l-7.01 6.68L3.3 7.54Z" /></svg>;
const ForwardRef = forwardRef(IconCheckAlt);
export { ForwardRef as IconCheckAlt };