export const SLV_BANK_CODES = [
  { label: 'ABANK', value: '31' },
  { label: 'ASOCIACION COOP. DE', value: '41' },
  { label: 'BAC', value: '25' },
  { label: 'BANCO AGRICOLA', value: '1' },
  { label: 'BANCO ATLANTIDA', value: '29' },
  { label: 'BANCO AZUL', value: '35' },
  { label: 'BANCO COOPERATIVO VI', value: '40' },
  { label: 'BANCO CUSCATLAN', value: '9' },
  { label: 'BANCO DE FOMENTO AGR', value: '10' },
  { label: 'BANCO HIPOTECARIO S.', value: '6' },
  { label: 'BANCO INDUSTRIAL', value: '32' },
  { label: 'BANCO PROMERICA', value: '18' },
  { label: 'CITIBANK N.A.', value: '0' },
  { label: 'DAVIVIENDA', value: '5' },
  { label: 'FEDERACION CAJAS DE', value: '34' },
  { label: 'SAC CREDICOMER', value: '38' },
  { label: 'SAC MULTIMONEY SA', value: '36' },
  { label: 'SOCIEDAD DE AHORRO Y', value: '33' },
];
