import { Box, Text } from '@remote-com/norma';
import styled, { css } from 'styled-components';

const LunchTimeTemplateColumns = css`
  grid-template-columns:
    repeat(6, min-content) /* Start - to - End - Lunch start - to - Lunch end */
    minmax(min-content, 2fr); /* Working hours */
`;

const NoLunchTimeTemplateColumns = css`
  grid-template-columns:
    repeat(3, min-content) /* Start - to - End */
    minmax(min-content, 2fr); /* Working hours */
`;

export const GridStyled = styled(Box)`
  display: grid;
  /* The grid-template-columns property handles the positioning of the table cells */
  /* Then this would accommodate for having 5 and 3 fields in the block */
  ${({ $hasLunchTime }) => ($hasLunchTime ? LunchTimeTemplateColumns : NoLunchTimeTemplateColumns)}

  grid-template-rows: 45px; /* Header row */
  grid-auto-rows: 50px; /* Body rows */
  width: 100%;
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  overscroll-behavior-x: contain;

  ${({ theme, $hasError }) => css`
    ${theme.typography.sm};
    background: ${theme.colors.blank};
    background-clip: padding-box;
    box-shadow: ${$hasError ? `0px 0px 0px 2px ${theme.colors.red[600]}` : 'none'};
    &:focus-within {
      box-shadow: ${theme.shadows.small}, 0px 0px 0px 2px ${theme.colors.primary};
    }
  `};
`;

export const GridCell = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 15px;

  &:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    padding-right: 30px;
  }

  ${({ $isToColumn, theme, $hasRowError }) => {
    let background;
    if ($hasRowError) {
      // eslint-disable-next-line prefer-destructuring
      background = theme.colors.red[200];
    } else {
      // eslint-disable-next-line prefer-destructuring
      background = theme.colors.brand[50];
    }

    return css`
      border-bottom: 1px solid transparent;
      background: ${background};
      color: ${theme.colors.grey[900]};
      ${$isToColumn &&
      css`
        padding: 0;
        justify-content: center;
      `}

      & abbr[title] {
        text-decoration: none;
        color: ${theme.colors.grey[900]};
      }
    `;
  }}
`;

export const GridHeaderCell = styled(GridCell)`
  text-transform: uppercase;
  border-bottom: 0;

  ${({ theme }) => css`
    ${theme.typography.smMedium}
    background: ${theme.colors.grey[100]};
  `}

  ${({ $isToColumn }) =>
    $isToColumn &&
    css`
      padding: 0;
    `}
`;

export const InputStyled = styled.input`
  border-radius: 8px;
  padding: 4px 5px;
  width: 7ch;
  height: 33px;
  font-variant-numeric: tabular-nums;
  text-align: center;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey[300]};
    opacity: 1;
  }

  ${({ theme, $hasError, disabled }) => css`
    ${theme.typography.sm}
    border: 2px solid ${!disabled && $hasError ? theme.colors.red[600] : theme.colors.grey[300]};
    background: ${theme.colors.blank};
    color: inherit;

    &:focus {
      outline: none;
      border-color: ${theme.colors.primary};
    }

    &:disabled {
      color: ${theme.colors.grey[300]};
      cursor: not-allowed;
    }
  `}
`;

export const Description = styled(Text).attrs({
  forwardedAs: 'p',
  color: 'grey.500',
  variant: 'xs',
})`
  margin: 0;
`;

export const FieldError = styled.span`
  color: ${({ theme }) => theme.colors.red[600]};
`;

export const Legend = styled.legend`
  display: block;

  ${({ theme }) => css`
    ${theme.typography.xs}
    color: ${theme.colors.primary};
  `}
`;

export const FieldSet = styled.fieldset`
  /* Reset */
  padding: 0;
  border: 0;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 8px;
`;
