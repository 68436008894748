import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import type { FormGroupProps } from '../form-group';
import { FormGroup, useFormGroupContext } from '../form-group';

import { TextareaWrapper, Textarea } from './InputTextarea.styled';
import { InputTextareaCounter } from './InputTextareaCounter';

type InputElementProps = {
  maxLength?: number;
  rows?: number;
};

function InputElement({ maxLength, rows }: InputElementProps) {
  const inputProps = useFormGroupContext();

  return (
    <TextareaWrapper>
      <Textarea maxLength={maxLength} rows={rows} {...inputProps} />
    </TextareaWrapper>
  );
}

export type InputTextareaProps = {
  /** Set the maximum number of characters that can be entered in the textarea. */
  maxLength?: number;
  /** If true, show the character count - defaults to true. */
  showCharacterCount?: boolean;
  rows?: number;
  children?: ReactNode;
} & Omit<FormGroupProps, 'children' | 'counter' | 'placeholder' | 'size'>;

const InputTextarea = forwardRef<HTMLTextAreaElement, InputTextareaProps>(function InputTextarea(
  { maxLength, rows = 3, showCharacterCount = true, children, ...props }: InputTextareaProps,
  ref
) {
  const formGroupProps = {
    ...props,
    description: props.description,
    counter: maxLength && showCharacterCount && (
      <InputTextareaCounter maxLength={maxLength} value={props.value} />
    ),
  };

  return (
    <FormGroup {...formGroupProps} ref={ref} label={props.label}>
      <InputElement maxLength={maxLength} rows={rows} />
      {children}
    </FormGroup>
  );
});

const InputTextareaWithCounter = InputTextarea as typeof InputTextarea & {
  Counter: typeof InputTextareaCounter;
};
InputTextareaWithCounter.Counter = InputTextareaCounter;

export { InputTextareaWithCounter as InputTextarea };
