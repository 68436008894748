import { isFlagActive } from '@/src/components/FeatureFlag';
import { getUserHandbookId } from '@/src/domains/handbooks/helpers';
import { fetchPayslipsAsEmployee } from '@/src/domains/payslips/services';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import {
  isContractor,
  isEmployee,
  isEmployer,
  userCan,
} from '@/src/domains/registration/auth/helpers';
import { userCacheKeys } from '@/src/domains/userCache/constants';
import { captureHTTPException } from '@/src/helpers/captureException';
import { makeGet } from '@/src/services/ApiClient/functions/makeRequest';
import { fetchEmployeeIncentives, fetchEmployeeInvoices } from '@/src/services/Employee';
import { getDirectReports } from '@/src/services/Employer';

const userCacheFetchFunctions = {
  [userCacheKeys.INVOICES_COUNT]: async (user) => {
    if (isEmployee(user)) {
      const { data } = await fetchEmployeeInvoices();
      return data?.length ?? 0;
    }
    return 0;
  },
  [userCacheKeys.PAYSLIPS_COUNT]: async (user) => {
    if (isContractor(user) && user?.activeEmployment?.slug !== undefined) {
      const { data } = await fetchPayslipsAsEmployee({
        pathParams: { slug: user.activeEmployment.slug },
      });
      return data?.length ?? 0;
    }
    return 0;
  },
  [userCacheKeys.INCENTIVES_COUNT]: async (user) => {
    if (
      isFlagActive('EMPLOYEE_INCENTIVES') &&
      userCan('read', Resources.incentives, user) &&
      isEmployee(user)
    ) {
      const { data } = await fetchEmployeeIncentives();
      return data?.length ?? 0;
    }

    return 0;
  },
  [userCacheKeys.DIRECT_REPORTS_COUNT]: async (user) => {
    if (userCan('read', Resources.people, user) && isEmployer(user)) {
      const { data } = await getDirectReports();

      return data?.reports?.length ?? 0;
    }

    return 0;
  },
  [userCacheKeys.HANDBOOK_ID]: async (user) => {
    if (userCan('read', Resources.handbooks, user) && isEmployee(user)) {
      return getUserHandbookId(user);
    }
    return null;
  },
  [userCacheKeys.COMPANY_DATA]: async (user) => {
    const hasPermissions =
      userCan('read', Resources.company, user) || userCan('read', Resources.company_details, user);
    if (isEmployer(user) && hasPermissions) {
      const { data } = await makeGet('/api/v1/employer/company');
      return data.company || {};
    }
    return {};
  },
};

/**
 * @returns {import("@/src/components/UserProvider/context").UserContextUser['userCache']}
 */
export async function fetchUserCache(user = {}) {
  if (!user.userCache) {
    user.userCache = {};
  }
  const cache = {};

  await Promise.all(
    Object.entries(userCacheFetchFunctions).map(async ([cacheKey, fetchFunction]) => {
      if (!(cacheKey in user.userCache)) {
        cache[cacheKey] = await fetchFunction(user).catch((error) => {
          captureHTTPException(error);
          return null;
        });
      } else {
        cache[cacheKey] = user.userCache[cacheKey];
      }
    })
  );

  return cache;
}
