import { Box, Text, Stack, BoxedIcon } from '@remote-com/norma';
import { forwardRef } from 'react';
import type { ChangeEvent } from 'react';

import {
  Label,
  StyledCheckboxState,
  ContentWrapper,
  Input,
} from '@/src/components/Ui/Form/inputs/CheckboxCard';

import type { CheckboxCardProps } from './types';

const disabledProps = {
  readOnly: true,
  'aria-disabled': true,
  onChange: (e: ChangeEvent) => {
    // aria-disabled does not prevent interaction
    // as the disabled attr
    e.preventDefault();
  },
};

export const CheckboxCard = forwardRef<HTMLInputElement, CheckboxCardProps>(function CheckboxCard(
  {
    id,
    name,
    value,
    label,
    description,
    Icon,
    iconTone,
    IconImage,
    checked,
    disabled,
    readOnly,
    hasError,
    onChange,
    ...props
  },
  ref
) {
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;
  const isDisabled = disabled || readOnly;

  const renderIconOrImage = () => {
    if (!Icon && !IconImage) return null;

    return (
      <Box mb={6}>
        {Icon ? <BoxedIcon Icon={Icon} tone={iconTone || 'brand'} /> : <IconImage />}
      </Box>
    );
  };

  return (
    <Label $disabled={isDisabled}>
      {/* Not using Norma's InputCheckbox here because it comes with a label that we don't want */}
      <Input
        ref={ref}
        name={name}
        value={value}
        checked={checked}
        aria-labelledby={labelId}
        aria-describedby={descriptionId}
        onChange={onChange}
        disabled={disabled}
        {...props}
        {...(isDisabled && disabledProps)}
      />
      {/* checked and hasError are required to show correct error state */}
      <StyledCheckboxState $hasError={hasError} $checked={checked} />

      <ContentWrapper $checked={checked} $hasError={hasError} $disabled={isDisabled}>
        {renderIconOrImage()}

        <Stack gap={3} mt="auto">
          <Text id={labelId} variant="lgMedium" pr={6} color={isDisabled ? 'grey.600' : 'grey.900'}>
            {label}
          </Text>

          {description && (
            <Text id={descriptionId} variant="sm" color="grey.600">
              {description}
            </Text>
          )}
        </Stack>
      </ContentWrapper>
    </Label>
  );
});
