// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneEditUnderline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="m14.34 4.6-5.76 5.76c-.47.47-.7.7-.9.97a4 4 0 0 0-.42.75c-.13.3-.2.62-.37 1.26l-.6 2.4c-.1.42-.16.64-.1.79a.5.5 0 0 0 .28.28c.15.06.37 0 .8-.1l2.4-.6c.63-.16.95-.24 1.25-.37a4 4 0 0 0 .75-.43c.27-.19.5-.42.97-.89l5.76-5.76a5.15 5.15 0 0 1-4.06-4.06Z" /><path d="M15.97 2.97a2.87 2.87 0 1 1 4.06 4.06l-.19.19a3.63 3.63 0 0 1-4.06-4.06l.19-.19Z" /><path fillRule="evenodd" clipRule="evenodd" d="M3.25 21c0-.41.34-.75.75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneEditUnderline);
export { ForwardRef as IconV2DuotoneEditUnderline };