import { TextEllipsis } from '@remote-com/norma';
import { IconV2OutlinePaperclip } from '@remote-com/norma/icons/IconV2OutlinePaperclip';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';

// @TODO Consider adding this "inset" variant to Norma.
// The paddings are smaller and don't take up space around.
const ButtonDocument = styled(Button).attrs({
  variant: 'ghost',
  size: 'xs',
  px: 4,
  mx: -4,
  py: '6px', // visually better than 4px or 8px
  my: '-6px',
})`
  display: inline-flex;
  min-width: auto;
  max-width: 100%; /* to support ellipsis */
  line-height: 1.4; /* to not cut characters below x line (eg y,p,g...) */
  color: ${({ theme }) => theme.colors.grey[900]};

  &:hover {
    background: none;
    color: var(--colors-irisBlue);
    filter: none;
  }
`;

const IconPaperclipStyled = styled(IconV2OutlinePaperclip)`
  flex-shrink: 0;
  width: 16px;
  margin-right: ${({ theme }) => theme.space[3]}px;
`;

export function DocumentButton({ children, ...props }) {
  return (
    <ButtonDocument {...props}>
      <IconPaperclipStyled />
      <TextEllipsis>{children}</TextEllipsis>
    </ButtonDocument>
  );
}
