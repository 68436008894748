import { Box } from '@remote-com/norma';
import { Formik } from 'formik';
import { useState } from 'react';
import { object, string } from 'yup';

import { ErrorSummary } from '@/src/components/Form/ErrorSummary';
import { SelectField } from '@/src/components/Ui/Form/formikIntegration/SelectField';
import { InputController } from '@/src/components/Ui/Form/Input';

import { getDisplayableSmartFields, OptionSmartField, GroupLabel } from './helpers';

export const EditSmartField = ({ handleModalSubmit, node, editorStorage, formName }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const initialValues = {
    smartField: node.attrs.field,
  };

  const smartFieldOptions = getDisplayableSmartFields(editorStorage);

  const validationSchema = object({
    smartField: string().oneOf(smartFieldOptions.map((i) => i.value)),
  });

  const onSubmit = (values) => {
    setErrorMessage('');

    try {
      handleModalSubmit(values);
    } catch (_err) {
      setErrorMessage(
        'Something went wrong, please try again. If this problem persists, please get in touch with support.'
      );
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form id={formName} data-testid={formName} onSubmit={handleSubmit}>
          <Box mt={4} pt={1}>
            <InputController>
              <SelectField
                name="smartField"
                label="Smartfield"
                options={smartFieldOptions}
                transformValue={(option) => option.value}
                components={{ Option: OptionSmartField }}
                formatGroupLabel={GroupLabel}
              />
            </InputController>
          </Box>
          <ErrorSummary errorMessage={errorMessage} />
        </form>
      )}
    </Formik>
  );
};
