import { Stack, themeV2 as theme } from '@remote-com/norma';
import { IconV2DuotoneCalendarPerson } from '@remote-com/norma/icons/IconV2DuotoneCalendarPerson';
import { IconV2DuotoneUserArrowRight } from '@remote-com/norma/icons/IconV2DuotoneUserArrowRight';
import { IconV2DuotoneUserX } from '@remote-com/norma/icons/IconV2DuotoneUserX';

import { ActivitySection } from '@/src/domains/reports/components/ReportWidgets/ActivitySection';
import SegmentedBar from '@/src/domains/reports/components/ReportWidgets/SegmentedBar';
import type { CardblockInfoProps } from '@/src/domains/reports/components/ReportWidgets/types';
import WidgetCardBlock from '@/src/domains/reports/components/ReportWidgets/WidgetCardBlock';

export const WorkforceBreakdownSummary = ({ summary }: { summary: any }) => {
  const {
    active: { value: activeCount },
    inactive: { value: inactiveCount },
    onboarding: { value: onboardingCount },
    offboarding: { value: offboardingCount },
    onLeave: { value: onLeaveCount },
  } = summary;

  const cardBlockInfo: CardblockInfoProps = [
    {
      label: 'Onboarding',
      icon: IconV2DuotoneUserArrowRight,
      tone: 'green',
      count: onboardingCount,
      tooltips: [
        {
          body: 'New hires who are currently going through the onboarding process.',
        },
      ],
    },
    {
      label: 'Offboarding',
      icon: IconV2DuotoneUserX,
      tone: 'pink',
      count: offboardingCount,
      tooltips: [
        {
          body: 'Employees who are in the process of leaving the company, including those resigning, retiring, or being terminated.',
        },
      ],
    },
    {
      label: 'On leave',
      icon: IconV2DuotoneCalendarPerson,
      tone: 'yellow',
      count: onLeaveCount,
      tooltips: [
        {
          body: 'Employees currently on approved leave (e.g., vacation, medical leave) who are expected to return to work after their leave period.',
        },
      ],
    },
  ];

  const totalEmployees = activeCount + inactiveCount;

  return (
    <Stack gap={7}>
      <Stack gap={4}>
        <Stack direction="row" gap={6}>
          <ActivitySection title="active" count={activeCount} titleColor="brand.500" />
          <ActivitySection title="inactive" count={inactiveCount} titleColor="grey.500" />
        </Stack>
        <SegmentedBar
          value={activeCount}
          max={totalEmployees}
          valueColor={theme.colors.brand[500]}
          trackColor={theme.colors.grey[200]}
        />
      </Stack>
      <Stack gap={4}>
        {cardBlockInfo.map(({ label, icon, count, tooltips, tone }) => (
          <WidgetCardBlock
            key={label}
            icon={icon}
            label={label}
            count={count}
            tooltips={tooltips}
            tone={tone}
          />
        ))}
      </Stack>
    </Stack>
  );
};
