// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineSettings = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M6.78 1.45c1.19-.7 2.7-.4 3.55.7l.45.58c.25.32.7.52 1.22.52s.97-.2 1.22-.52l.45-.59a2.75 2.75 0 0 1 3.55-.7l1.3.76c1.2.69 1.7 2.15 1.18 3.42l-.28.69c-.16.38-.1.86.16 1.31.26.46.66.75 1.06.8l.73.1a2.75 2.75 0 0 1 2.38 2.73v1.5a2.75 2.75 0 0 1-2.38 2.73l-.73.1c-.4.05-.8.34-1.06.8a1.5 1.5 0 0 0-.16 1.31l.28.69a2.75 2.75 0 0 1-1.17 3.42l-1.3.75c-1.2.69-2.71.4-3.56-.7l-.45-.58c-.25-.32-.7-.52-1.22-.52s-.97.2-1.22.52l-.45.59a2.75 2.75 0 0 1-3.55.7l-1.3-.76a2.75 2.75 0 0 1-1.18-3.42l.28-.69c.16-.38.1-.86-.16-1.31a1.53 1.53 0 0 0-1.06-.8l-.73-.1a2.75 2.75 0 0 1-2.38-2.73v-1.5a2.75 2.75 0 0 1 2.38-2.73l.73-.1c.4-.05.8-.34 1.06-.8.27-.45.32-.93.16-1.31l-.28-.69A2.75 2.75 0 0 1 5.47 2.2l1.3-.75Zm2.36 1.61a1.25 1.25 0 0 0-1.61-.31l-1.3.75c-.55.31-.78.98-.54 1.56l.28.68a3 3 0 0 1-.25 2.63 3.02 3.02 0 0 1-2.16 1.54l-.73.1c-.62.08-1.08.61-1.08 1.24v1.5c0 .63.46 1.16 1.08 1.24l.73.1c.98.14 1.73.79 2.16 1.54a3 3 0 0 1 .25 2.63l-.28.68c-.24.58-.01 1.25.53 1.56l1.3.75c.55.32 1.24.18 1.62-.31l.45-.59c.6-.77 1.54-1.1 2.41-1.1.87 0 1.8.33 2.4 1.1l.46.59c.38.5 1.07.63 1.61.31l1.3-.75c.55-.31.78-.98.54-1.56l-.28-.68a3 3 0 0 1 .25-2.63 3.02 3.02 0 0 1 2.16-1.54l.73-.1a1.25 1.25 0 0 0 1.08-1.24v-1.5c0-.63-.46-1.16-1.08-1.24l-.73-.1a3.02 3.02 0 0 1-2.16-1.54 3 3 0 0 1-.25-2.63l.28-.68c.24-.58.01-1.25-.53-1.56l-1.3-.75a1.25 1.25 0 0 0-1.62.31l-.45.59A3 3 0 0 1 12 4.75a3 3 0 0 1-2.4-1.1l-.46-.59ZM12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM8.25 12a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineSettings);
export { ForwardRef as IconV2OutlineSettings };