// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneSettings = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M10.3 2.11a4.82 4.82 0 0 0-.73-.84A2 2 0 0 0 7.8.92c-.24.07-.5.22-1 .5l-1.37.8c-.5.3-.76.44-.94.61a2 2 0 0 0-.58 1.7c.04.25.15.52.37 1.06l.26.64c.18.45.12.97-.13 1.4a1.6 1.6 0 0 1-1.15.8l-.68.1c-.57.07-.86.11-1.1.21a2 2 0 0 0-1.18 1.35c-.06.25-.06.54-.06 1.12v1.58c0 .58 0 .87.06 1.12a2 2 0 0 0 1.18 1.35c.24.1.53.14 1.1.21l.68.1c.5.06.9.38 1.15.8.25.43.31.95.13 1.4l-.26.64c-.22.54-.33.8-.37 1.06a2 2 0 0 0 .58 1.7c.18.17.44.32.94.6l1.37.8c.5.3.76.44 1 .5a2 2 0 0 0 1.76-.34c.2-.16.38-.39.73-.84l.43-.55c.3-.39.78-.59 1.27-.59.5 0 .97.2 1.27.59l.43.55c.35.45.53.68.73.84a2 2 0 0 0 1.76.35c.24-.07.5-.22 1-.5l1.37-.8c.5-.3.76-.44.94-.61a2 2 0 0 0 .58-1.7 4.82 4.82 0 0 0-.37-1.06l-.26-.64a1.6 1.6 0 0 1 .13-1.4 1.6 1.6 0 0 1 1.15-.8l.68-.1c.57-.07.86-.11 1.1-.21a2 2 0 0 0 1.18-1.35c.06-.25.06-.54.06-1.12v-1.58c0-.58 0-.87-.06-1.12a2 2 0 0 0-1.18-1.35c-.24-.1-.53-.14-1.1-.21l-.68-.1a1.6 1.6 0 0 1-1.15-.8 1.6 1.6 0 0 1-.13-1.4l.26-.64c.22-.54.33-.8.37-1.06a2 2 0 0 0-.58-1.7 4.81 4.81 0 0 0-.94-.6l-1.37-.8c-.5-.3-.76-.44-1-.5a2 2 0 0 0-1.76.34c-.2.16-.38.39-.73.84l-.43.55c-.3.39-.78.59-1.27.59-.5 0-.97-.2-1.27-.59l-.43-.55Z" /><path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneSettings);
export { ForwardRef as IconV2DuotoneSettings };