import { Text, Tooltip, Stack } from '@remote-com/norma';
import { IconV2OutlineInfo } from '@remote-com/norma/icons/IconV2OutlineInfo';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';

export const FlexButton = styled(Button)`
  display: flex;
`;

export const TooltipHeader = ({ children, tooltip, ...props }) => (
  <Stack direction="row" alignItems="center" gap={2} {...props}>
    {children}
    <Tooltip label={tooltip} maxWidth={props?.maxWidth}>
      <FlexButton variant="raw">
        <IconV2OutlineInfo width="16" height="16" />
      </FlexButton>
    </Tooltip>
  </Stack>
);

export const TableTitle = ({ children, ...props }) => (
  <Text color="grey.900" variant="smMedium" mb={6} {...props}>
    {children}
  </Text>
);
