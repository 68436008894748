import { isDev, isOnClient, isTest } from '@/src/helpers/general';
import { makeGet } from '@/src/services/ApiClient/functions/makeRequest';

import type { FeatureFlagAudience } from './audience';
import type { FeatureFlagConfig } from './config';
import type { FeatureFlagName } from './types';

interface Flag {
  enabled: boolean;
  key: FeatureFlagName;
  audiences: FeatureFlagAudience[];
}

interface AllFlag {
  flags: Flag[];
  version: string;
}

export interface FeatureFlagFetchResponse {
  featureFlags: AllFlag;
}

/**
 * This cache is expected to be shared between different requests,
 * _even_ if they are from _different_ users.
 * The feature flag config is specifically designed to be user-agnostic.
 * See: https://linear.app/remote/issue/DEVXP-1864/cache-feature-flag-config
 */
const cacheRef: {
  value: null | {
    config: FeatureFlagConfig;
    fetchedAt: number; // Same format as Date.now()
  };
} = {
  value: null,
};

const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

/**
 * Skip the cache and fetch the feature flag config from the server directly.
 * For convenience, this also takes care of the transform,
 * but not updating the cache to minimise side effects.
 */
async function forceFetch(): Promise<FeatureFlagConfig> {
  const shouldFetchDisabledFlags = isDev() || isTest();
  // Purposefully not including the param, to expose less of the API to the client
  const { data } = await makeGet(`/api/v1/config`, {
    queryParams: shouldFetchDisabledFlags
      ? {
          includeDisabledFeatureFlags: shouldFetchDisabledFlags,
        }
      : {},
  });
  const config = data.featureFlags.flags.reduce<FeatureFlagConfig>((acc, flag) => {
    const { key, enabled, audiences } = flag;
    acc[flag.key] = { name: key, enabled, audiences };
    return acc;
  }, {});
  return config;
}

const ongoingFetch: { promise: Promise<FeatureFlagConfig> | null } = { promise: null };

export async function fetchFeatureFlagConfig(): Promise<FeatureFlagConfig> {
  const isClient = isOnClient();
  // Client-side should cache the feature flag config for REFRESH_INTERVAL to avoid
  // unnecessary API requests
  if (isClient) {
    const now = Date.now();
    const cache = cacheRef.value;
    // Cache hit!
    const hit = cache && now - cache.fetchedAt < REFRESH_INTERVAL;
    if (hit) {
      return cache.config;
    }
    // Cache miss (or expired), let's fetch the config
    const config = await forceFetch();
    cacheRef.value = { config, fetchedAt: now };
    return config;
  }

  // If there's an ongoing fetch, return the ongoing Promise
  if (ongoingFetch.promise) {
    return ongoingFetch.promise;
  }

  /**
   * Server-side does not need to cache the feature flag config but we need to make
   * sure that only one fetch is happening at a time to prevent memory leaks.
   *
   * This is safe because the config is user-agnostic and can be shared between different
   * requests.
   */
  ongoingFetch.promise = (async () => {
    try {
      const config = await forceFetch();
      return config;
    } finally {
      // Reset the ongoing fetch once complete
      ongoingFetch.promise = null;
    }
  })();

  return ongoingFetch.promise;
}
