import styled from 'styled-components';

import { Stack } from '../../layout';
import { Button } from '../button';
import { Text } from '../text';

import type { ChipProps } from './Chip';

export const ChipContainer = styled(Stack).attrs(({ size }: Pick<ChipProps, 'size'>) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'grey.100',
  borderRadius: '9999px',
  direction: 'row',
  gap: size === 'xs' ? 2 : 3,
  height: size === 'xs' ? '22px' : '32px',
  width: 'auto',
  pl: size === 'xs' ? 3 : 4,
  pr: size === 'xs' ? 2 : 3,
  // minWidth is added to allow Chip components to elide content when reaching the limit of the container's width.
  minWidth: 0,
}))<ChipProps>`
  &:hover {
    background: ${({ theme }) => theme.colors.grey[200]};
    cursor: pointer;
  }
`;

export const ChipContent = styled(Text).attrs({
  color: 'grey.900',
  variant: 'sm',
})<ChipProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CloseButton = styled(Button).attrs({
  variant: 'raw',
  tone: 'secondary',
})<ChipProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  flex-grow: 0;
  flex-shrink: 0;
  height: ${({ $size }) => ($size === 'xs' ? '16px' : '20px')};
  width: ${({ $size }) => ($size === 'xs' ? '16px' : '20px')};
  &:hover {
    background: ${({ theme }) => theme.colors.grey[300]};
    cursor: pointer;
  }
  &:focus,
  &:focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.grey[500]} !important;
  }
  & > svg {
    width: 100%;
    height: 100%;
    transform: scale(0.6);
  }
`;
