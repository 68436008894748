import { Content } from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';
import type { ElementRef, ComponentPropsWithoutRef } from 'react';

import { MenuList } from '../menu-components/MenuList.styled';

export const DropdownMenuList = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof MenuList>
>((props, ref) => {
  return <MenuList {...props} as={Content} ref={ref} />;
});
