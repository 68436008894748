import { create } from 'zustand';

import {
  filterPdfFiles,
  generatePdfFile,
  getAllFilesFromDriveFolder,
  getGoogleDriveId,
} from '@/src/components/GoogleImporter/DriveImporter/helpers';
import { useGoogleLibrariesStore } from '@/src/components/GoogleImporter/useGoogleLibrariesStore';
import { useGoogleSignInStore } from '@/src/components/GoogleImporter/useGoogleSignInStore';

const defaultState = {
  isLoading: false,
  error: null,

  tokenClient: null,
  driveFolderUrl: '',
  filesToUpload: [],
  isGettingFiles: false,
  hasImportedFilesMetadata: false,
};

/**
 * Zustand store with the logic to import files from Google Drive.
 */
export const useGoogleDriveImporter = create((set, get) => ({
  ...useGoogleSignInStore(set, get),
  ...useGoogleLibrariesStore(set, get),
  ...defaultState,

  setDriveFolderUrl: (driveFolderUrl) => set({ driveFolderUrl }),

  /**
   * Don't forget to reset the state of the other slices
   * In this case, the useGoogleSignInStore and useGoogleLibrariesStore slice.
   */
  resetStore: () => {
    const { isSignedIn } = get();
    set({
      ...defaultState,
      isInitializing: false,
      isGapiInitialized: false,
      isGisInitialized: false,
      isSignedIn,
    });
  },
  resetErrors: () => set({ error: '' }),

  getFolderInfo: async (driveFolderUrl) => {
    set({ isGettingFiles: true, hasImportedFilesMetadata: false });

    const driveId = getGoogleDriveId(driveFolderUrl);

    let files;
    try {
      files = await getAllFilesFromDriveFolder(driveId);
    } catch (error) {
      set({ error: error.message });
    }

    // Sometimes the response is null due to losing authentication
    const pdfFilesMetadata = filterPdfFiles(files);
    set({ filesToUpload: pdfFilesMetadata, isGettingFiles: false, hasImportedFilesMetadata: true });
    return pdfFilesMetadata;
  },
  getFile: async (file = []) => {
    const response = await window.gapi.client.drive.files.get({
      fileId: file.id,
      alt: 'media',
    });
    return generatePdfFile(file.name, response.body);
  },
  getFiles: async (filesToUpload = []) => {
    const files = [];
    try {
      filesToUpload.forEach(async (file) => {
        const response = await window.gapi.client.drive.files.get({
          fileId: file.id,
          alt: 'media',
        });
        files.push(generatePdfFile(file.name, response.body));
      });
    } catch (error) {
      set({ error: error.message });
    }

    set({ isGettingFiles: false, hasImportedFilesMetadata: true });
    return files;
  },
}));
