// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconCoffee = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M17 8a1 1 0 0 1 1-1h1a5 5 0 1 1 0 10h-1a1 1 0 1 1 0-2h1a3 3 0 0 0 0-6h-1a1 1 0 0 1-1-1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M1 8a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v9a5 5 0 0 1-5 5H6a5 5 0 0 1-5-5V8Zm2 1v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V9H3Z" /><path fillRule="evenodd" clipRule="evenodd" d="M6 0a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M10 0a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M14 0a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1Z" /></svg>;
const ForwardRef = forwardRef(IconCoffee);
export { ForwardRef as IconCoffee };