// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneMail = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M9.25 2.25c-2.8 0-4.2 0-5.27.54a5 5 0 0 0-2.19 2.19c-.54 1.07-.54 2.47-.54 5.27v3.5c0 2.8 0 4.2.54 5.27a5 5 0 0 0 2.19 2.18c1.07.55 2.47.55 5.27.55h5.5c2.8 0 4.2 0 5.27-.55a5 5 0 0 0 2.18-2.18c.55-1.07.55-2.47.55-5.27v-3.5c0-2.8 0-4.2-.55-5.27a5 5 0 0 0-2.18-2.19c-1.07-.54-2.47-.54-5.27-.54h-5.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M5.38 7.58a.75.75 0 0 1 1.04-.2l1 .66c1.65 1.1 2.34 1.56 3.07 1.78.98.3 2.04.3 3.02 0 .73-.22 1.42-.68 3.07-1.78l1-.66a.75.75 0 1 1 .84 1.24l-1 .67-.12.08c-1.5 1-2.39 1.6-3.35 1.88a6.75 6.75 0 0 1-3.9 0c-.96-.29-1.85-.88-3.35-1.88l-.12-.08-1-.67a.75.75 0 0 1-.2-1.04Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneMail);
export { ForwardRef as IconV2DuotoneMail };