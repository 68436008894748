import {
  trackEvent,
  inAppNotificationEvents,
  pathnameToLocationOfAction,
} from '@remote-com/analytics';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { useInAppNotifications } from '@/src/domains/inAppNotifications/hooks';
import { OverlayButtonIcon } from '@/src/domains/updates/OverlayButtonIcon';

const InAppNotificationsOverlay = dynamic(
  () =>
    import('@/src/domains/inAppNotifications/InAppNotificationsOverlay').then(
      (mod) => mod.InAppNotificationsOverlay
    ),
  { ssr: false }
);

export const NotificationsButton = ({ size = 'sm' }) => {
  const { openOverlay, closeOverlay, isOverlayOpen, updates, isLoading, isError, refetch } =
    useInAppNotifications();

  const totalUnreadNotificationCount = updates?.notifications?.totalUnreadNotifications || 0;
  const totalNotificationCount = updates?.notifications?.totalCount || 0;
  const { pathname } = useRouter();

  const buttonRef = useRef(null);

  const handleOnDismiss = () => {
    closeOverlay();
    setTimeout(() => {
      buttonRef?.current?.querySelector('button')?.focus();
    }, 200);
  };

  return (
    <>
      <OverlayButtonIcon
        isActive={totalUnreadNotificationCount > 0}
        onClick={() => {
          openOverlay();
          trackEvent(inAppNotificationEvents.NOTIFICATION_BELL_CLICKED, {
            elementName: 'notifications-bell',
            elementType: 'button',
            locationOfAction: pathnameToLocationOfAction(pathname),
            elementData: totalUnreadNotificationCount,
          });
        }}
        label="Notifications"
        size={size}
        ref={buttonRef}
      />

      <InAppNotificationsOverlay
        isOpen={isOverlayOpen}
        isLoading={isLoading}
        isError={isError}
        refetch={refetch}
        onDismiss={handleOnDismiss}
        closeOverlay={closeOverlay}
        totalUnreadNotificationCount={totalUnreadNotificationCount}
        totalNotificationCount={totalNotificationCount}
        notifications={updates?.notifications}
      />
    </>
  );
};
