import type { PropsWithChildren, Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const COMPACT_VIEW_STORAGE_KEY = 'table-compact-view-active';

const TableUserSettingsContext = createContext<{
  isCompactTable: boolean;
  setCompactView: Dispatch<SetStateAction<boolean>>;
}>({
  isCompactTable: false,
  setCompactView: () => {},
});

export const TableUserSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isCompactTable, setCompactView] = useLocalStorageState(COMPACT_VIEW_STORAGE_KEY, {
    defaultValue: false,
  });

  return (
    <TableUserSettingsContext.Provider
      value={{
        isCompactTable: !!isCompactTable,
        setCompactView: (newValue: SetStateAction<boolean>) => {
          setCompactView((prevValue: boolean) =>
            typeof newValue === 'function' ? newValue(prevValue || false) : newValue
          );
        },
      }}
    >
      {children}
    </TableUserSettingsContext.Provider>
  );
};

export const useTableUserSettingsContext = () => useContext(TableUserSettingsContext);
