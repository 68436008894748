import { Pill } from '@remote-com/norma';

import { ChartAxisLabelWrapper } from './ChartAxisLabel.styled';

type ChartAxisLabelProps = {
  orientation: 'horizontal' | 'vertical';
  children: React.ReactNode;
};

export default function ChartAxisLabel({
  orientation = 'horizontal',
  children,
}: ChartAxisLabelProps) {
  return (
    <ChartAxisLabelWrapper $isVertical={orientation === 'vertical'}>
      <Pill tone="blue">{children}</Pill>
    </ChartAxisLabelWrapper>
  );
}
