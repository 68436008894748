import { useGet } from '@remote-com/data-layer';
import { useMemo } from 'react';

import { useUser } from '@/src/components/UserProvider/context';

export const useCheckInvoiceOrigin = (slug: string) => {
  const { userIsAdmin } = useUser();

  const isValidSlug = !!slug;

  const {
    data: customerResponse,
    isLoading: isInvoiceOriginLoading,
    isError: isInvoiceOriginError,
  } = useGet('/api/v1/billing-platform/employer/billing-documents/[slug]/origin', {
    params: { pathParams: { slug } },
    options: {
      select: ({ data }) => data,
      enabled: !userIsAdmin && isValidSlug,
    },
  });

  const {
    data: adminResponse,
    isLoading: isAdminInvoiceOriginLoading,
    isError: isAdminInvoiceOriginError,
  } = useGet('/api/v1/billing-platform/rivendell/billing-documents/[slug]/origin', {
    params: { pathParams: { slug } },
    options: {
      select: ({ data }) => data,
      enabled: userIsAdmin && isValidSlug,
    },
  });

  // Handle the case when the feature is not enabled
  const defaultState = useMemo(
    () => ({
      data: {
        originatingSystem: 'legacy',
        resourceSlug: slug,
      },
      isLoading: false,
      isError: false,
    }),
    [slug]
  );

  const getOriginatingSystem = (state: typeof adminResponse | typeof customerResponse) => {
    if (!state) return '';

    const hasInvoiceReportSlug = !!state.invoiceReportSlug;
    return hasInvoiceReportSlug ? 'legacy' : 'zuora';
  };

  if (userIsAdmin && isValidSlug) {
    return {
      data: {
        resourceSlug: adminResponse?.invoiceReportSlug || adminResponse?.billingDocumentSlug,
        originatingSystem: getOriginatingSystem(adminResponse),
      },
      isLoading: isAdminInvoiceOriginLoading,
      isError: isAdminInvoiceOriginError,
    };
  }

  if (!userIsAdmin && isValidSlug) {
    return {
      data: {
        resourceSlug: customerResponse?.invoiceReportSlug || customerResponse?.billingDocumentSlug,
        originatingSystem: getOriginatingSystem(customerResponse),
      },
      isLoading: isInvoiceOriginLoading,
      isError: isInvoiceOriginError,
    };
  }

  return defaultState;
};
