import { useGetInfinite, useGet } from '@remote-com/data-layer';
import type { PaginatedResponse } from '@remote-com/talent/api/boba/shared.types';
import { useState } from 'react';
import type { UseInfiniteQueryResult } from 'react-query';

import type { UserProfile } from '@/src/api/config/employ/userProfiles.types';
import { useUser } from '@/src/components/UserProvider/context';
import { companyAdminLabels } from '@/src/domains/registration/auth/constants';
import { USER_PROFILE_TYPES } from '@/src/domains/userProfile/constants';
import type { $TSFixMe } from '@/types';

function getNextPageParam(lastPage: $TSFixMe) {
  if (lastPage?.data?.totalPages > lastPage?.data?.currentPage) {
    return lastPage?.data.currentPage + 1;
  }

  // Return `undefined` to signal that the end of list is reached.
  return undefined;
}

export const useUserProfiles = () => {
  const { user } = useUser();
  const [searchQuery, setSearchQuery] = useState('');

  const { data: currentUserProfileData } = useGet('/api/v1/session/current-user', {
    options: {
      enabled: !!user,
    },
  });
  const {
    data: profilesData,
    isLoading: isLoadingUserProfiles,
    isFetching: isFetchingUserProfiles,
    isFetchingNextPage: isFetchingUserProfilesNextPage,
    fetchNextPage: fetchNextUserProfilesPage,
  } = useGetInfinite('/api/v2/user-profiles', {
    params: {
      queryParams: {
        pageSize: '10',
        ...(searchQuery ? { term: searchQuery } : {}),
      },
    },
    options: {
      staleTime: Infinity,
      getNextPageParam,
      keepPreviousData: true,
      enabled: !!user,
    },
  }) as UseInfiniteQueryResult<{ data: PaginatedResponse & { userProfiles: UserProfile[] } }>;

  const freelancerProfileIndex =
    profilesData?.pages?.reduce<number>((acc, val: $TSFixMe) => {
      if (acc !== -1) {
        return acc;
      }

      const freelancerProfile = val?.data?.userProfiles?.find((profile: $TSFixMe) => {
        return profile.profileType === USER_PROFILE_TYPES.FREELANCER;
      });

      return freelancerProfile?.profileIndex || acc;
    }, -1) ?? -1;

  /*
    (@iam-team) We go over every page and every user profile within that page and check that all possible profiles are company admin and NOT company owner. We want to have this information because if such account exists (all profiles company admins and no profile is company owner) we do not allow self-editing email for these users.
  */
  const allProfilesAreEmployerNonOwnerProfiles = () => {
    return profilesData?.pages.every((page: $TSFixMe) => {
      return page?.data?.userProfiles?.every((p: $TSFixMe) => {
        return (
          p.profileType === USER_PROFILE_TYPES.COMPANY_MANAGER &&
          p.assignedRoles?.[0].name !== companyAdminLabels.OWNER
        );
      });
    });
  };

  const paginationDataPage = profilesData?.pages.at(-1)?.data;

  const paginationData = {
    currentPage: paginationDataPage?.currentPage || 0,
    totalCount: paginationDataPage?.totalCount || 0,
    totalPages: paginationDataPage?.totalPages || 0,
  };

  return {
    freelancerProfileIndex,
    hasMultipleProfiles: paginationData.totalCount > 1,
    isLoading: isLoadingUserProfiles,
    allProfilesAreEmployerNonOwnerProfiles,
    profiles: {
      fetchNextPage: fetchNextUserProfilesPage,
      isFetching: isFetchingUserProfiles,
      isFetchingNextPage: isFetchingUserProfilesNextPage,
      isLoading: isLoadingUserProfiles,
      list: profilesData?.pages?.flatMap((page) => page.data.userProfiles) || [],
      ...paginationData,
    },
    searchQuery,
    onSearch: (query: string) => setSearchQuery(query),
    selectedProfile: currentUserProfileData?.data,
  } as const;
};
