import { Node, mergeAttributes, PasteRule, ReactNodeViewRenderer } from '@tiptap/react';

import { ConditionalComponent } from './ConditionalComponent';

const pasteRegex = /«COND_.*?«END_COND»/g;

export const ConditionalContent = Node.create({
  name: 'conditionalContent',
  group: 'inline',
  inline: true,
  content: 'inline*',
  isolating: true,
  atom: true,

  addOptions() {
    return {
      ...this.parent?.(),
      // Disables custom styling for conditional content in, e.g., employee view, and displays plain text only.
      hasStyling: true,
    };
  },

  addAttributes() {
    return {
      conditional: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.conditional) {
            return {};
          }

          return {
            'data-smart-conditional': attributes.conditional,
          };
        },
        parseHTML: (element) => element.getAttribute('data-smart-conditional'),
      },
      value: {
        default: null,
        renderHTML: (attributes) => {
          return {
            'data-smart-value': attributes.value,
          };
        },
        parseHTML: (element) => element.getAttribute('data-smart-value'),
      },
      content: {
        default: null,
      },
      display: {
        default: true,
      },
    };
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: pasteRegex,
        handler: ({ state, range, match }) => {
          const { tr } = state;

          // Matches "CONDITIONAL" in «COND_CONDITIONAL_(value)»
          const conditional = match[0].match(/«COND_(.*?)_\(/)[1];

          // Matches "value" in «COND_CONDITIONAL_(value)»
          const value = match[0].match(/_\((.*?)\)»/)[1];

          const content = match[0].match(/\)»(.*?)«END_COND»/)[1];

          tr.replaceWith(
            range.from,
            range.to,
            this.type.create({
              conditional,
              value,
              content,
            })
          );
        },
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ConditionalComponent);
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({ 'data-type': this.name }, HTMLAttributes),
      node.attrs.content,
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element) => element.getAttribute('data-type') === this.name,
      },
    ];
  },

  renderText({ node }) {
    return `«COND_${node.attrs.conditional}_(${node.attrs.value})»${node.attrs.content}«END_COND»`;
  },
});
