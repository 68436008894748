import getConfig from 'next/config';
import Script from 'next/script';
import { useEffect } from 'react';

import { captureException } from '@/src/helpers/captureException';

const {
  publicRuntimeConfig: { CAPTCHA_KEY, CAPTCHA_ENABLED },
} = getConfig();

// CAPTCHA_KEY is the Site key from staging
const URL = `https://www.recaptcha.net/recaptcha/api.js?render=${CAPTCHA_KEY}`;

export const ReCAPTCHA = ({ preventRemoval }: { preventRemoval?: boolean }) => {
  useEffect(() => {
    return () => {
      // Remove captcha badge on component unmount
      const badge = window.document.querySelector('.grecaptcha-badge');
      if (badge && !preventRemoval) {
        badge.remove();
      }
    };
  }, [preventRemoval]);

  const onError = (e: Error) => {
    captureException(new Error('Failed to load ReCAPTCHA.', { cause: e }));
  };

  const onReady = () => {
    const { grecaptcha } = window as any;
    if (CAPTCHA_KEY && grecaptcha && typeof grecaptcha.reset === 'function') {
      /**
       * Be mindful that the documentation for reCaptcha V3 can be somewhat misleading.
       * It does not mention the 'reset' function at all and states that the execution function resets the token with each call.
       * While it's true that the token is created on each call, this is not sufficient when using 2FA.
       * Calling 'reset' is necessary to make it work within a 2FA flow.
       */
      grecaptcha.reset(CAPTCHA_KEY);
    }
  };

  return CAPTCHA_ENABLED ? <Script src={URL} onReady={onReady} onError={onError} /> : null;
};
