import {
  trackEvent,
  buildZendeskArticleDrawerOpenEvent,
  pathnameToLocationOfAction,
} from '@remote-com/analytics';
import {
  FeedbackMessage,
  Drawer,
  DrawerLayout,
  DrawerPane,
  DrawerHeader,
  DrawerTitle,
  DrawerCopy,
  DrawerStyledButtonInline,
  Box,
} from '@remote-com/norma';
import type { DrawerProps } from '@remote-com/norma';
import { IconInfo } from '@remote-com/norma/icons/IconInfo';
import type { HTMLReactParserOptions } from 'html-react-parser';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';

import { ButtonInline } from '@/src/components/Button';
import { transformHtmlToComponents } from '@/src/helpers/transformHtmlToComponents';
import {
  ZENDESK_HELP_CENTER_URL,
  ZENDESK_HELP_CENTER_URL_PRODUCTION,
} from '@/src/services/Zendesk';

import {
  ImageWrapper,
  ShapeWrapper,
  BoxIllustration,
  GraySemiCircleShape,
  DrawerErrorContent,
  DrawerErrorText,
} from './JSFHelpCenterDrawer.styled';

const {
  publicRuntimeConfig: { ENVIRONMENT },
} = getConfig();

const DEFAULT_ERROR_TITLE = 'Help center unavailable';
const DEFAULT_ERROR_CONTENT =
  "The help center content is currently unavailable. For assistance, please don't hesitate to contact us at support.remote.com.";

type JSFHelpCenterDrawerProps = {
  /**
   * The ID for the help center article. This ID is used to link to the article directly from the drawer
   */
  articleId: string;
  /**
   * The title of the help center article
   */
  articleTitle: string;
  /**
   * The content of the help center article. This string can contain HTML
   */
  articleContent: string;
  /**
   * The call to action text that will be displayed after the field's description
   */
  articleCta: string;
  /**
   * When this property is true, the error state will be shown
   */
  articleError?: boolean | null;
  /**
   * The description to be shown before the article call to action
   */
  description?: string | ReactNode;
  /**
   * Custom function that parses the article content's HTML and transforms
   * HTML tags into custom elements
   */
  transformArticleContent?: (articleContent: string, options?: HTMLReactParserOptions) => ReactNode;
};

type DrawerWithHelpCenterArticleProps = DrawerProps &
  Omit<JSFHelpCenterDrawerProps, 'description' | 'articleCta'>;

function DrawerWithHelpCenterArticle({
  articleId,
  articleTitle,
  articleContent,
  articleError,
  transformArticleContent,
  ...drawerProps
}: DrawerWithHelpCenterArticleProps) {
  const errorContent =
    articleError && articleContent
      ? `${articleContent} For assistance, please don't hesitate to contact us at support.remote.com.`
      : DEFAULT_ERROR_CONTENT;

  return (
    <Drawer {...drawerProps} aria-label="Help center article drawer" $layout="fullWidth">
      <DrawerLayout>
        {articleError && (
          <>
            <DrawerHeader>
              <DrawerTitle>{articleTitle || DEFAULT_ERROR_TITLE}</DrawerTitle>
            </DrawerHeader>
            <DrawerErrorContent>
              <Box>
                <ImageWrapper>
                  <ShapeWrapper>
                    <GraySemiCircleShape />
                  </ShapeWrapper>
                  <BoxIllustration />
                </ImageWrapper>
                <DrawerErrorText variant="base" mt={6}>
                  {errorContent}
                </DrawerErrorText>
              </Box>
              {ENVIRONMENT !== 'production' && (
                <FeedbackMessage mt={40}>
                  <strong>Dev Only message:</strong> See{' '}
                  <ButtonInline
                    asTag="a"
                    href={`${ZENDESK_HELP_CENTER_URL_PRODUCTION}/${articleId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    live Zendesk article
                  </ButtonInline>
                  .
                  <br />
                  Due to authentication constraints, Zendesk articles from our live environment (
                  <strong>support.remote.com</strong>) can only be retrieved in production (
                  <strong>employ.remote.com</strong>). Articles from the Zendesk sandbox can be
                  retrieved in staging, review apps and locally.
                </FeedbackMessage>
              )}
            </DrawerErrorContent>
          </>
        )}

        {!articleError && (
          <>
            <DrawerHeader>
              <DrawerTitle>{articleTitle}</DrawerTitle>
            </DrawerHeader>
            <DrawerPane>
              {transformArticleContent ? (
                <DrawerCopy $tone="default" $variant="default">
                  {transformArticleContent(articleContent)}
                </DrawerCopy>
              ) : (
                <DrawerCopy
                  dangerouslySetInnerHTML={{ __html: articleContent }}
                  $tone="default"
                  $variant="default"
                />
              )}
            </DrawerPane>
          </>
        )}
      </DrawerLayout>
    </Drawer>
  );
}

export const JSFHelpCenterDrawer = ({
  articleId,
  articleTitle,
  articleContent,
  articleCta,
  articleError,
  description = '',
}: JSFHelpCenterDrawerProps) => {
  const { pathname } = useRouter();
  const [isVisible, setIsVisible] = useState(false);

  const openDrawer = useCallback(() => {
    setIsVisible(true);

    trackEvent(buildZendeskArticleDrawerOpenEvent(articleId, ZENDESK_HELP_CENTER_URL), {
      locationOfAction: pathnameToLocationOfAction(pathname),
    });
  }, [articleId, pathname]);

  const closeDrawer = () => setIsVisible(false);

  const zendeskDrawerProps = {
    articleId,
    articleTitle,
    articleContent,
    articleError,
    isVisible,
    onClose: closeDrawer,
    'aria-label': 'Help center drawer',
  };

  return (
    <>
      {description ? <>{description} </> : null}
      <DrawerStyledButtonInline onClick={openDrawer}>
        {articleCta}
        <IconInfo width="16px" />
      </DrawerStyledButtonInline>
      <DrawerWithHelpCenterArticle
        transformArticleContent={transformHtmlToComponents}
        Trigger="TODO_MIGRATE_TO_COMPONENT_DONT_COPY"
        {...zendeskDrawerProps}
      />
    </>
  );
};
