// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineHelpCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12 2.75a9.25 9.25 0 1 0 0 18.5 9.25 9.25 0 0 0 0-18.5ZM1.25 12a10.75 10.75 0 1 1 21.5 0 10.75 10.75 0 0 1-21.5 0ZM12 8.75c-.69 0-1.25.56-1.25 1.25v.5a.75.75 0 0 1-1.5 0V10a2.75 2.75 0 1 1 5.5 0v.12c0 .76-.3 1.5-.84 2.03l-1.38 1.38a.75.75 0 1 1-1.06-1.06l1.38-1.38c.26-.26.4-.6.4-.97V10c0-.69-.56-1.25-1.25-1.25ZM10.75 16a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineHelpCircle);
export { ForwardRef as IconV2OutlineHelpCircle };