// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneBankHouse = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g opacity={0.48}><path d="M8 11H4v7h4v-7Z" /><path d="M16 11h-4v7h4v-7Z" /></g><g opacity={0.32}><path d="M12 11H8v7h4v-7Z" /><path d="M20 11h-4v7h4v-7Z" /></g><path d="M1.25 19c0-.96.79-1.75 1.75-1.75h18c.96 0 1.75.79 1.75 1.75v2.25H23c.41 0 .75.34.75.75s-.34.75-.75.75H1A.76.76 0 0 1 .25 22c0-.41.34-.75.75-.75h.25V19Z" /><path d="m21.37 5.75-9-3.6c-.2-.08-.54-.08-.74 0l-9 3.6c-.35.14-.63.55-.63.93V10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V6.68c0-.38-.28-.79-.63-.93ZM12 8.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneBankHouse);
export { ForwardRef as IconV2DuotoneBankHouse };