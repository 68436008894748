import { Box } from '@remote-com/norma';
import styled from 'styled-components';

const verticalStyles = `
  writing-mode: vertical-lr;
  white-space: nowrap;
  transform: rotate(180deg);
  margin-right: 18px;

  > * {
    width: 22px;
    height: 100%;
    display: flex;
    padding: 8px 0;
    align-items: center;
  }
`;

const horizontalStyles = `
  justify-content: center;
  margin-top: 18px;
  width: 100%;
`;

export const ChartAxisLabelWrapper = styled(Box)<{ $isVertical: boolean }>`
  display: flex;
  align-items: center;
  ${({ $isVertical }) => ($isVertical ? verticalStyles : horizontalStyles)}
`;
