import { simplifyTimeAmounts } from '@/src/components/Ui/Form/WorkScheduleTableField/utils/simplifyTimeAmounts';
import { getStartAndEndTimesInMinutes } from '@/src/components/Ui/Form/WorkWeekScheduleTableField/utils/getStartAndEndTimesInMinutes';

/**
 * Calculate the working hours
 * @param {{hours: number, minutes: number}} startTime Start time
 * @param {{hours: number, minutes: number}} endTime End time, if earlier than start time, it describes a time for the following day
 * @param {{hours: number, minutes: number}} lunchStartTime Lunch start time
 * @param {{hours: number, minutes: number}} lunchEndTime Lunch end time, if earlier than start time, it describes a time for the following day
 * @returns {{hours: number, minutes: number} | null} total working hours
 */
export function calculateWorkingHours(startTime, endTime, lunchStartTime, lunchEndTime) {
  if (!startTime || !endTime || !lunchStartTime || !lunchEndTime) {
    return null;
  }

  const { startTimeInMinutes, endTimeInMinutes } = getStartAndEndTimesInMinutes(startTime, endTime);
  const { startTimeInMinutes: lunchStartTimeInMinutes, endTimeInMinutes: lunchEndTimeInMinutes } =
    getStartAndEndTimesInMinutes(lunchStartTime, lunchEndTime);

  const recessDurationInMinutes = lunchEndTimeInMinutes - lunchStartTimeInMinutes;

  const workingHoursInMinutes = endTimeInMinutes - startTimeInMinutes - recessDurationInMinutes;

  if (workingHoursInMinutes < 0) {
    return null;
  }

  return simplifyTimeAmounts({
    minutes: workingHoursInMinutes,
  });
}

/**
 * Calculate the working hours (without lunch time)
 * @param {{hours: number, minutes: number}} startTime Start time
 * @param {{hours: number, minutes: number}} endTime End time, if earlier than start time, it describes a time for the following day
 * @returns {{hours: number, minutes: number} | null} total working hours
 */
export function calculateWorkingHoursSimplified(startTime, endTime) {
  if (!startTime || !endTime) {
    return null;
  }

  const { startTimeInMinutes, endTimeInMinutes } = getStartAndEndTimesInMinutes(startTime, endTime);
  const workingHoursInMinutes = endTimeInMinutes - startTimeInMinutes;

  if (workingHoursInMinutes < 0) {
    return null;
  }

  return simplifyTimeAmounts({
    minutes: workingHoursInMinutes,
  });
}
