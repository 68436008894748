import { useFormikContext } from 'formik';

import { DirectReportsOnlyFilter } from '@/src/components/Table/Filters';
import {
  employeeInvoiceDataSources,
  invoiceReportFilterTypeLabels,
  invoiceReportTypeFilterValues,
} from '@/src/domains/invoices/constants';

export const eorInvoiceTypeLabels = {
  [employeeInvoiceDataSources.ESTIMATES]:
    invoiceReportFilterTypeLabels[invoiceReportTypeFilterValues.ESTIMATES],
  [employeeInvoiceDataSources.ACTUALS]:
    invoiceReportFilterTypeLabels[invoiceReportTypeFilterValues.ACTUALS],
  [employeeInvoiceDataSources.MANUAL_EOR]:
    invoiceReportFilterTypeLabels[invoiceReportTypeFilterValues.MANUAL_EOR],
  [employeeInvoiceDataSources.REMOTE_PAID_SERVICES]:
    invoiceReportFilterTypeLabels[invoiceReportTypeFilterValues.REMOTE_PAID_SERVICES],
  [employeeInvoiceDataSources.ONBOARDING_RESERVE]:
    invoiceReportFilterTypeLabels[invoiceReportTypeFilterValues.ONBOARDING_RESERVE],
};

export const eorTypeOfInvoiceOptions = [
  employeeInvoiceDataSources.ACTUALS,
  employeeInvoiceDataSources.ESTIMATES,
  employeeInvoiceDataSources.MANUAL_EOR,
  employeeInvoiceDataSources.REMOTE_PAID_SERVICES,
  employeeInvoiceDataSources.ONBOARDING_RESERVE,
].map((value) => ({
  value,
  label: eorInvoiceTypeLabels[value],
}));

export const contractorInvoiceLabelOptions = [
  'Contractor invoice',
  'Remote invoice - Contractor Subscription Fees',
].map((value) => ({
  value,
  label: value,
}));

export const DirectReportsFilter = (props: any) => {
  const formikContext = useFormikContext<{
    // eslint-disable-next-line camelcase
    direct_reports_only?: boolean;
    manager?: Object | null;
  }>();
  const directReportsEnabled = formikContext?.values?.direct_reports_only;

  return (
    <DirectReportsOnlyFilter
      label="Show direct reports only"
      onClick={() => {
        if (directReportsEnabled) {
          // eslint-disable-next-line camelcase
          const { manager, direct_reports_only, ...rest } = formikContext.values;
          // Reset the manager field
          formikContext.setValues({
            ...rest,
            direct_reports_only: false,
          });
        } else {
          formikContext.setValues({
            ...formikContext.values,
            manager: null,
            direct_reports_only: true,
          });
        }
      }}
      {...props}
    />
  );
};
