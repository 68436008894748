// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconMinusCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M2.54 10a7.46 7.46 0 1 1 14.92 0 7.46 7.46 0 0 1-14.92 0ZM10 .8a9.2 9.2 0 1 0 0 18.4A9.2 9.2 0 0 0 10 .8ZM6.67 9.11a.87.87 0 1 0 0 1.76h6.66a.87.87 0 1 0 0-1.76H6.67Z" /></svg>;
const ForwardRef = forwardRef(IconMinusCircle);
export { ForwardRef as IconMinusCircle };