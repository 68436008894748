import { datadogRum } from '@datadog/browser-rum';
import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  endOfWeek,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  nextDay as nextDayFns,
  startOfWeek,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

import { createObjectUrlFromBlob, generateAndDownloadDocument } from '@/src/domains/files/helpers';
import { createDate } from '@/src/domains/payroll/admin/PayrollCalendars/helpers';
import { isContractor } from '@/src/domains/registration/auth/helpers';
import { timeOffStatusLabels, timeOffType } from '@/src/domains/timeoff/constants';
import {
  DEFAULT_TIME_TRACKING_ENTRY,
  TIMESHEET_STATUS,
  TIMETRACKING_TYPE,
  TIMETRACKING_TYPE_LABELS,
  TIME_TRACKING_CALCULATION_MODES,
} from '@/src/domains/timeTracking/constants';
import { makeAllPageFetcherFunction } from '@/src/helpers/api';
import { captureHTTPException } from '@/src/helpers/captureException';
import {
  dateStringToLocalTime,
  formatDayOfTheWeek,
  formatFullDayOfTheWeek,
  formatFullMonthDay,
  formatFullMonthDayYear,
  formatShortDayShortMonth,
  formatTime24,
  formatYearMonthDay,
  getUserTimezone,
} from '@/src/helpers/date';
import { generateSelectOptions } from '@/src/helpers/forms';
import { friendlyLabel, friendlyPlaceholderDash } from '@/src/helpers/general';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';
import { makeGet } from '@/src/services/ApiClient/functions/makeRequest';

/**
 * @typedef {import("@/src/domains/timeTracking/employee/TimeTrackingContext").TimeTrackingStateDecorated} TimeTrackingStateDecorated
 * @typedef {import("@/src/domains/timeTracking/employee/TimeTrackingContext").TimeTrackingStoreItem} TimeTrackingStoreItem
 */

/**
 * @typedef {Object} TimeTrackingPayload
 * @property {string|undefined} clockIn - The clock-in time.
 * @property {string|undefined} clockOut - The clock-out time.
 * @property {string} timezone - The timezone applicable to the times.
 * @property {string|null} notes - Optional notes associated with the time tracking.
 * @property {TimeTrackingType} type - The type of time tracking.
 */

/**
 * Get a user-readable date display (with the day of the week)
 * @param {string} date
 * @param {string} timezone
 * @returns {string} 'Friday Mar 17'
 */
export function formatDate(date, timezone = 'UTC') {
  return new Date(Date.parse(date)).toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    timeZone: timezone,
  });
}

function isNumber(value) {
  return !Number.isNaN(Number(value));
}

/**
 *
 * @param {string} time
 * @returns boolean
 * '10:12' => false
 * '10:12:00' => true
 */
function isTimeWithSeconds(time = '') {
  return time.split(':').length === 3;
}

/**
 *
 * @param {string} time
 * @returns boolean
 * '10:12' => true
 * '10:12:00' => false
 */
function isTimeWithoutSeconds(time = '') {
  return time.split(':').length === 2;
}

/**
 * Calculates the number of hours and minutes from a given duration in minutes.
 *
 * @param {number} duration - The duration in minutes.
 * @returns {{ hours: number, minutes: number }} - An object containing the number of hours and minutes.
 */
export const getMinutesAndHours = (duration) => {
  const input = isNumber(duration) ? duration : 0;
  return {
    hours: Math.floor(input / 60),
    minutes: input % 60,
  };
};

/**
 * Displays user-readablce date range
 * @param {string} start '2022-01-01'
 * @param {string} end '2022-01-05
 * @param {Object} [options] - An options object.
 * @param {boolean} [options.isAccessible=false] - `isAccessible` is an optional flag that indicates whether the format
 *        should consider accessibility adjustments. Defaults to `false`.
 * @returns {string} 'January 1 - 5, 2022'
 */
export function formatTableDateRange(start, end, { isAccessible = false } = {}) {
  const dayStart = dateStringToLocalTime(start);
  const dayEnd = dateStringToLocalTime(end);
  const separator = isAccessible ? 'to' : '-';
  const timezone = getUserTimezone();

  if (isSameMonth(dayStart, dayEnd)) {
    const formattedDayEnd = isAccessible
      ? formatFullMonthDayYear(dayEnd, { timezone })
      : `${dayEnd.getDate()}, ${dayEnd.getFullYear()}`;

    return `${formatFullMonthDay(dayStart, {
      timezone,
    })} ${separator} ${formattedDayEnd}`;
  }

  const formatStart = isSameYear(dayStart, dayEnd) ? formatFullMonthDay : formatFullMonthDayYear;
  return `${formatStart(dayStart, { timezone })} ${separator} ${formatFullMonthDayYear(dayEnd, {
    timezone,
  })}`;
}

/**
 * Get a human-readable display of amount of time.
 *
 * @param {Object} [time] - Object with hours and minutes.
 * @param {number=} time.hours - Hours.
 * @param {number=} time.minutes - Minutes.
 * @param {boolean} showZeroMinutes - Whether to show zero minutes or not.
 * @param {boolean} isAccessible - whether the format should consider accessibility adjustments or not.
 * @param {boolean} friendlyLabel - whether to return a friendly label or not
 * when there is no time
 * @param {{showZeroMinutes?: boolean, isAccessible?: boolean, friendlyLabel?:
 * boolean}=} options
 *
 * @returns {string} Time string in format 'hh[h] mm[m]'.
 */
export function transformTimeTrackingTotalToDisplay(time, options) {
  if (!time || (!time.hours && !time.minutes)) {
    if (options?.friendlyLabel) {
      return friendlyPlaceholderDash;
    }

    if (options?.isAccessible) {
      return '0 hours';
    }

    return '0h';
  }

  let finalHours = time.hours ?? 0;
  let finalMinutes = time.minutes ?? 0;

  if (time.minutes >= 60) {
    const numHours = Math.floor(time.minutes / 60);
    finalHours = time.hours + numHours;
    finalMinutes = time.minutes % 60;
  }

  const visibleMinutesString = options?.isAccessible
    ? getSingularPluralUnit(finalMinutes, 'minute', 'minutes', false)
    : `${finalMinutes}m`;
  const minutesString =
    finalMinutes > 0 || options?.showZeroMinutes ? ` ${visibleMinutesString}` : '';
  const hoursString = options?.isAccessible
    ? getSingularPluralUnit(finalHours, 'hour', 'hours', false)
    : `${finalHours}h`;

  return `${hoursString}${minutesString}`;
}

/**
 * Get a number-formatted display of amount of time.
 *
 * @param {Object} time - Object with hours and minutes.
 * @param {number} time.hours - Hours.
 * @param {number} time.minutes - Minutes.
 *
 * @returns {string} Time string in format 'hh:mm'.
 */
export function transformTimeTrackingTotalToNumberFormat(time) {
  if (!time || (!time?.hours && !time?.minutes)) return '0.0';

  let finalHours = time.hours;
  let finalMinutes = time.minutes;

  if (time.minutes >= 60) {
    const numHours = Math.floor(time.minutes / 60);
    finalHours = time.hours + numHours;
    finalMinutes = time.minutes % 60;
  }

  finalMinutes = Math.round((finalMinutes / 6) * 10);

  const minutesString = finalMinutes < 10 && finalMinutes > 0 ? `0${finalMinutes}` : finalMinutes;
  return `${finalHours}.${minutesString}`;
}

/**
 * Formats a duration in minutes into a human-readable string.
 *
 * @param {number} duration - The duration in minutes.
 * @returns {string} A human-readable string representing the duration.
 */
export const formatTimeDuration = (duration) => {
  return transformTimeTrackingTotalToDisplay(getMinutesAndHours(duration), {
    showZeroMinutes: false,
  });
};

/**
 * @param {string} start
 * @param {string | null} end
 * @param {string} timezone
 * @returns
 */
export const calculateTimeDifferenceInSeconds = (start, end, timezone) => {
  const startTime = new Date(start);
  const endTime = end ? new Date(end) : zonedTimeToUtc(new Date(), timezone);
  return differenceInSeconds(endTime, startTime);
};

export function handleRowSelection({ checked, setSelected, selected, original }) {
  const finalSlugs = checked
    ? [...selected, original.slug]
    : selected.filter((slug) => slug !== original.slug);
  setSelected(finalSlugs);
}

export function handleAllRowsSelection({ setSelected, rows, checked }) {
  if (checked) {
    const finalSlugs = rows.map(({ original }) => original.slug);
    setSelected(finalSlugs);
    return;
  }

  setSelected([]);
}

function downloadDocument(content, fileName) {
  try {
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    generateAndDownloadDocument({
      content: createObjectUrlFromBlob(blob),
      name: fileName,
    });
  } catch (e) {
    captureHTTPException(e);
    throw new Error(e);
  }
}

/**
 * Adds items to an array if a condition is met.
 *
 * @param {boolean} condition - The condition to check.
 * @param {T} items - The items to add to the array.
 * @returns {T} - The resulting array with the added items, or an empty array if the condition is not met.
 */
const addItemToArrayIf = (condition, items) => (condition ? items : []);

export function createSingleRowData(values, isAdmin, isEmployer) {
  const finalValues = [
    ...addItemToArrayIf(isAdmin || isEmployer, [
      {
        columnName: 'Employee',
        value: values?.employment?.user?.name || '-',
      },
      {
        columnName: 'Country',
        value: values?.country?.name || '-',
      },
    ]),
    {
      columnName: 'Work week start',
      value: formatYearMonthDay(values?.startDate),
    },
    {
      columnName: 'Work week end',
      value: formatYearMonthDay(values?.endDate),
    },
    {
      columnName: 'Hours worked',
      value: `${values?.totalHours?.hours || '0'}`,
    },
    {
      columnName: 'Minutes worked',
      value: `${values?.totalHours?.minutes || '0'}`,
    },
    {
      columnName: 'Overtime hours',
      value: `${values?.overtimeHours?.hours || '0'}`,
    },
    {
      columnName: 'Overtime minutes',
      value: `${values?.overtimeHours?.minutes || '0'}`,
    },
    {
      columnName: 'Break hours',
      value: `${values?.breakHours?.hours || '0'}`,
    },
    {
      columnName: 'Break minutes',
      value: `${values?.breakHours?.minutes || '0'}`,
    },
    {
      columnName: 'On-call hours',
      value: `${values?.onCallHours?.hours || '0'}`,
    },
    {
      columnName: 'On-call minutes',
      value: `${values?.onCallHours?.minutes || '0'}`,
    },
    {
      columnName: 'Night hours',
      value: `${values?.nightHours?.hours || '0'}`,
    },
    {
      columnName: 'Night minutes',
      value: `${values?.nightHours?.minutes || '0'}`,
    },
    {
      columnName: 'Weekend hours',
      value: `${values?.weekendHours?.hours || '0'}`,
    },
    {
      columnName: 'Weekend minutes',
      value: `${values?.weekendHours?.minutes || '0'}`,
    },
    {
      columnName: 'Holiday hours',
      value: `${values?.holidayHours?.hours || '0'}`,
    },
    {
      columnName: 'Holiday minutes',
      value: `${values?.holidayHours?.minutes || '0'}`,
    },
    ...addItemToArrayIf(isAdmin || isEmployer, [
      {
        columnName: 'Notes for Payroll',
        value: values?.notes || '-',
      },
    ]),
    {
      columnName: 'Submitted date',
      value: formatYearMonthDay(values?.submittedAt),
    },
    ...addItemToArrayIf(isAdmin, [
      {
        columnName: 'Employee PSP ID',
        value: values?.employment?.employeePspId,
      },
    ]),
  ];

  const columns = finalValues.map(({ columnName }) => `"${columnName}"`).join(',');
  const data = finalValues.map(({ value }) => `"${value}"`).join(',');
  return `${columns}\n${data}`;
}

export function exportSingleRowData(values, isAdmin, isEmployer) {
  const content = createSingleRowData(values, isAdmin, isEmployer);
  downloadDocument(content, `timesheets-${values?.employment?.user?.name}.csv`);
}

const csvWrap = (text) => `"${friendlyLabel(text)}"`;

function adminTimesheetParser(row) {
  const {
    companyName,
    companySlug,
    employeePspId,
    employeeName,
    userSlug,
    clockIn,
    clockOut,
    timezone,
    totalHours,
    nightHours,
    weekendHours,
    holidayHours,
    timeBreakdown,
    type,
    notes,
  } = row;
  return `${csvWrap(companyName)},${csvWrap(companySlug)},${csvWrap(employeePspId)},${csvWrap(
    employeeName
  )},${csvWrap(userSlug)},${csvWrap(formatYearMonthDay(clockIn, { timezone }))},${csvWrap(
    formatDayOfTheWeek(clockIn, { timezone })
  )},${csvWrap(formatTime24(clockIn, { timezone }))},${csvWrap(
    formatTime24(clockOut, { timezone })
  )},${csvWrap(transformTimeTrackingTotalToNumberFormat(totalHours))},${csvWrap(
    transformTimeTrackingTotalToNumberFormat(nightHours)
  )},${csvWrap(transformTimeTrackingTotalToNumberFormat(weekendHours))},${csvWrap(
    transformTimeTrackingTotalToNumberFormat(holidayHours)
  )},${csvWrap(transformTimeTrackingTotalToNumberFormat(timeBreakdown?.day?.regular))},${csvWrap(
    transformTimeTrackingTotalToNumberFormat(timeBreakdown?.day?.weekend)
  )},${csvWrap(transformTimeTrackingTotalToNumberFormat(timeBreakdown?.day?.holiday))},${csvWrap(
    transformTimeTrackingTotalToNumberFormat(timeBreakdown?.night?.regular)
  )},${csvWrap(transformTimeTrackingTotalToNumberFormat(timeBreakdown?.night?.weekend))},${csvWrap(
    transformTimeTrackingTotalToNumberFormat(timeBreakdown?.night?.holiday)
  )},${csvWrap(TIMETRACKING_TYPE_LABELS[type])},${csvWrap(notes)}\n`;
}

function adminTimeOffParser(row) {
  const {
    companyName,
    companySlug,
    employeePspId,
    employeeName,
    userSlug,
    day,
    timeOffDays,
    holiday,
  } = row;

  function getType() {
    // each row will either have timeOffdays or holiday; never both
    return holiday ? 'PUBLIC HOLIDAY' : timeOffDays[0].timeoff?.timeoffType;
  }
  function getNotes() {
    return holiday ? holiday.name : timeOffDays[0].timeoff?.slug;
  }
  return `${csvWrap(companyName)},${csvWrap(companySlug)},${csvWrap(employeePspId)},${csvWrap(
    employeeName
  )},${csvWrap(userSlug)},${csvWrap(day)},${csvWrap(formatDayOfTheWeek(day))},,,,,,,,,,,,,${csvWrap(
    getType()
  )},${csvWrap(getNotes())}\n`;
}

/**
 * Converts the timesheets info into a CSV-friendly string.
 * See unit tests for expected inputs and outputs
 * @param {object} tableData
 * @param {object} workCalendarDays
 * @returns string
 */
export function createAdminTimesheetDetails(tableData, workCalendarDays) {
  const dataMap = tableData
    .map((timesheet) => {
      const {
        timeTrackings,
        company: { name: companyName, slug: companySlug },
        employment: {
          employeePspId,
          user: { name: employeeName, slug: userSlug },
        },
      } = timesheet;
      return timeTrackings.map((time) => {
        return {
          companyName,
          companySlug,
          employeePspId,
          employeeName,
          userSlug,
          ...time,
        };
      });
    })
    .flat()
    .map((row) => adminTimesheetParser(row));

  const workCalendarDataMap = workCalendarDays
    .map((days, index) => {
      if (!days.length) return null;

      const userInfo = tableData[index];
      const {
        company: { name: companyName, slug: companySlug },
        employment: {
          employeePspId,
          user: { name: employeeName, slug: userSlug },
        },
      } = userInfo;
      const basics = { companyName, companySlug, employeePspId, employeeName, userSlug };

      return days.map((day) => {
        const { day: date, holiday, timeOffDays } = day;
        const eachTimeOffDays = timeOffDays?.map((timeOffDay) => {
          return { ...basics, ...{ day: date, timeOffDays: [timeOffDay] } };
        });

        if (holiday && timeOffDays) {
          // if there are both holidays and timeOffDays
          // need to separate them into different rows
          return [{ ...basics, ...{ day: date, holiday } }, ...eachTimeOffDays];
        }

        return eachTimeOffDays?.length
          ? eachTimeOffDays
          : {
              ...basics,
              ...day,
            };
      });
    })
    .flat(2)
    .filter((days) => days !== null)
    .map((day) => adminTimeOffParser(day));

  const columnHeaders = [
    'Company',
    'Company Slug',
    'Employee PSP ID',
    'Employee Name',
    'Employee User Slug',
    'Date',
    'Day',
    'Start Time',
    'End Time',
    'Total Hours',
    'Night Hours',
    'Weekend Hours',
    'Holiday Hours',
    'Day-Weekday Hours',
    'Day-Weekend Hours',
    'Day-Holiday Hours',
    'Night-Weekday Hours',
    'Night-Weekend Hours',
    'Night-Holiday Hours',
    'Type',
    'Time tracking notes / Holiday names / Timeoff slugs',
  ];
  const columns = columnHeaders.map((name) => `"${name}"`).join(',');
  return `${columns}\n${dataMap.join('')}${workCalendarDataMap.join('')}`;
}

async function fetchAdminWorkCalendars(tableData) {
  function getData(slug, startDate, endDate) {
    return makeGet('/api/v1/rivendell/employments/[employmentSlug]/work-calendars', {
      pathParams: {
        employmentSlug: slug,
      },
      queryParams: {
        startDate,
        endDate,
      },
    });
  }

  const allWorkCalendarDays = await Promise.all(
    tableData.map(({ employment, startDate, endDate }) =>
      getData(employment.slug, startDate, endDate)
    )
  ).then((values) =>
    values.map(({ data }) =>
      data?.workCalendar?.dates.filter((date) => !!date?.holiday || !!date?.timeOffDays)
    )
  );
  return allWorkCalendarDays;
}

export async function exportAdminTimesheetDetails(tableProps) {
  let dataToUse = tableProps.data;
  if (tableProps.pageCount > 1) {
    const fetchAllPages = makeAllPageFetcherFunction(tableProps.queryFn, (results) =>
      results.flatMap((res) => res.data?.data || res.data)
    );
    dataToUse = await fetchAllPages({ disable_pagination: true });
  }

  const workCalendarDays = await fetchAdminWorkCalendars(dataToUse);
  const dataMap = createAdminTimesheetDetails(dataToUse, workCalendarDays);
  downloadDocument(dataMap, 'TimeTracking-TimesheetDetailsExport.csv');
}

/**
 * Converts the timesheets info into a CSV-friendly string.
 * See unit tests for expected inputs and outputs
 * @param {object} tableData
 * @returns string
 */
export function createEmployerTimesheetDetails(tableData) {
  const employerColumns = [
    'Employee Name',
    'Employment Short Slug',
    'Employee User Slug',
    'Date',
    'Day',
    'Start Time',
    'End Time',
    'Total Hours',
    'Night Hours',
    'Weekend Hours',
    'Holiday Hours',
    'Day-Weekday Hours',
    'Day-Weekend Hours',
    'Day-Holiday Hours',
    'Night-Weekday Hours',
    'Night-Weekend Hours',
    'Night-Holiday Hours',
    'Type',
    'Notes',
  ];
  const columns = employerColumns.map((name) => `"${name}"`).join(',');
  const dataMap = tableData
    .map((timesheet) => {
      const {
        timeTrackings,
        employment: {
          user: { name: employeeName, slug: employeeUserSlug },
          shortSlug: employmentShortSlug,
        },
      } = timesheet;
      return timeTrackings.map((time) => {
        return {
          employeeName,
          employmentShortSlug,
          employeeUserSlug,
          ...time,
        };
      });
    })
    .flat()
    .map(
      ({
        employeeName,
        employmentShortSlug,
        employeeUserSlug,
        clockIn,
        timezone,
        clockOut,
        totalHours,
        nightHours,
        weekendHours,
        holidayHours,
        timeBreakdown,
        type,
        notes,
      }) =>
        `${csvWrap(employeeName)},${csvWrap(employmentShortSlug)},${csvWrap(
          employeeUserSlug
        )},${csvWrap(formatYearMonthDay(clockIn, { timezone }))},${csvWrap(
          formatDayOfTheWeek(clockIn, { timezone })
        )},${csvWrap(formatTime24(clockIn, { timezone }))},${csvWrap(
          formatTime24(clockOut, { timezone })
        )},${csvWrap(transformTimeTrackingTotalToNumberFormat(totalHours))},${csvWrap(
          transformTimeTrackingTotalToNumberFormat(nightHours)
        )},${csvWrap(transformTimeTrackingTotalToNumberFormat(weekendHours))},${csvWrap(
          transformTimeTrackingTotalToNumberFormat(holidayHours)
        )},${csvWrap(
          transformTimeTrackingTotalToNumberFormat(timeBreakdown?.day?.regular)
        )},${csvWrap(
          transformTimeTrackingTotalToNumberFormat(timeBreakdown?.day?.weekend)
        )},${csvWrap(
          transformTimeTrackingTotalToNumberFormat(timeBreakdown?.day?.holiday)
        )},${csvWrap(
          transformTimeTrackingTotalToNumberFormat(timeBreakdown?.night?.regular)
        )},${csvWrap(
          transformTimeTrackingTotalToNumberFormat(timeBreakdown?.night?.weekend)
        )},${csvWrap(
          transformTimeTrackingTotalToNumberFormat(timeBreakdown?.night?.holiday)
        )},${csvWrap(TIMETRACKING_TYPE_LABELS[type])},${csvWrap(notes)}\n`
    );

  return `${columns}\n${dataMap.join('')}`;
}

export function exportEmployerTimesheetDetails(tableData) {
  const data = createEmployerTimesheetDetails(tableData);
  downloadDocument(data, 'TimeTracking-TimesheetDetailsExport.csv');
}

/**
 * Computes the total worked time in minutes from an array of time trackings.
 * @param {Object[]} timeTrackings - The array of time trackings.
 * @param {number} timeTrackings[].duration - The duration of the time tracking in minutes.
 * @param {boolean} timeTrackings[].error - Whether the time tracking has an error.
 * @param {string} timeTrackings[].type - The time tracking type to check if we should add the duration.
 * @returns {number} The total worked time in minutes.
 */
export const computeWorkedTime = (timeTrackings) =>
  timeTrackings.reduce((acc, cur) => {
    if (
      !cur.error &&
      cur.duration >= 0 &&
      ![TIMETRACKING_TYPE.BREAK, TIMETRACKING_TYPE.UNPAID_BREAK].includes(cur.type)
    ) {
      acc += cur.duration;
    }
    return acc;
  }, 0);
/**
 * Returns an array of objects representing days between a start and end date.
 * @param {string} start - The start date in 'YYYY-MM-dd' format.
 * @param {string} end - The end date in 'YYYY-MM-dd' format.
 * @param {function} isWorkWeekDay from useWorkCalendars hook, passed through TimeTrackingReducer
 * @returns {DayData[]} The array of day objects.
 * @property {string} day - The day in 'YYYY-MM-dd' format.
 * @property {Object[]} timeTrackings - The array of time tracking entries for the day.
 * @property {number} workedTime - The total worked time in minutes for the day.
 * @property {string} status - The status of the timesheet for the day.
 */
export const getDaysArray = (start, end, isWorkWeekDay, prefillTimeTracking = true) => {
  const arr = [];
  for (const dt = createDate(start); dt <= createDate(end); dt.setDate(dt.getDate() + 1)) {
    const dayTimeTrackings =
      prefillTimeTracking && isWorkWeekDay(dt, { hasAdditionalChecks: true })
        ? [DEFAULT_TIME_TRACKING_ENTRY]
        : [];
    arr.push({
      day: formatYearMonthDay(dt, {
        isSourceInUtc: false,
        formatInLocalTime: true,
      }),
      timeTrackings: dayTimeTrackings,
    });
  }
  return arr;
};

/** @param {string} value */
export const isDay =
  (value) =>
  /** @param {{day: string}} */
  ({ day }) =>
    day === value;

/**
 * Calculates the duration between clock-in and clock-out times.
 *
 * @param {object} timeData - Object containing clock-in and clock-out times, and type.
 * @param {string} timeData.clockIn - Clock-in time.
 * @param {string} timeData.clockOut - Clock-out time.
 * @param {string} timeData.type - Type of duration.
 * @param {object} [options] - Optional configuration options.
 * @param {string} [options.calculationMode] - Calculation mode for handling negative durations. Possible values are:
 *   - 'NEGATIVE_DEFAULTS_TO_ZERO': If the duration is negative, it will be considered as zero.
 *   - 'ALLOW_CLOCK_OUT_NEXT_DAY': If the duration is negative, it will be adjusted by adding 24 hours.
 * @returns Object containing the calculated duration and the type.
 */
export const getDuration = ({ clockIn: start, clockOut: end, type }, options = {}) => {
  if (!start || !end) {
    return {
      duration: 0,
    };
  }

  const [startH, startM] = start.split(':').map((x) => Number(x));
  const [endH, endM] = end.split(':').map((x) => Number(x));
  const startTime = startH * 60 + startM;
  const endTime = endH * 60 + endM;
  const difference = endTime - startTime;

  let duration = difference;

  switch (options.calculationMode) {
    case TIME_TRACKING_CALCULATION_MODES.NEGATIVE_DEFAULTS_TO_ZERO:
      duration = Math.max(difference, 0);
      break;
    case TIME_TRACKING_CALCULATION_MODES.ALLOW_CLOCK_OUT_NEXT_DAY:
      duration = difference + (difference <= 0 ? 24 * 60 : 0);
      break;
    default:
      break;
  }

  return {
    duration,
    type,
  };
};

/**
 * Computes total worked time for the given days.
 *
 * @param {Array} days - The array of days with each day object containing the workedTime property.
 * @returns {number} The total number of minutes worked.
 */
export const computeTotalWorkedTime = (days) =>
  days.reduce((acc, cur) => {
    acc += cur.workedTime;
    return acc;
  }, 0);

/**
 * Checks if two time intervals overlap.
 *
 * @param {TimeTrackingStoreItem} intervalA - The first time interval.
 * @param {TimeTrackingStoreItem} intervalB - The second time interval.
 * @param {boolean} [bIsPrevDay] - Indicates whether the second interval is from the previous day.
 * @returns {boolean} Returns true if the intervals overlap, false otherwise.
 */
const checkOverlap = (intervalA, intervalB, bIsPrevDay) => {
  const { clockIn: AClockIn, clockOut: AClockOut } = intervalA;
  const { clockIn: BClockIn, clockOut: BClockOut } = intervalB;
  if (!AClockIn || !AClockOut || !BClockIn || !BClockOut) {
    return false;
  }
  const dates = ['1970-01-10', '1970-01-11', '1970-01-12'];
  /**
   * @param {Object} param0
   * @param {string} param0.clockIn
   * @param {string} param0.clockOut
   */
  const isNightShift = ({ clockIn, clockOut }) => clockOut <= clockIn;
  /**
   * @param {TimeTrackingStoreItem} interval
   * @param {boolean} [isPrevDay]
   */
  const getDateForInterval = (interval, isPrevDay) => {
    const index = isPrevDay ? 0 : 1;
    return isNightShift(interval) ? dates[index + 1] : dates[index];
  };

  const aStart = `${dates[1]}T${AClockIn}:00Z`;
  const aEnd = `${getDateForInterval(intervalA)}T${AClockOut}:00Z`;
  const bStart = `${bIsPrevDay ? dates[0] : dates[1]}T${BClockIn}:00Z`;
  const bEnd = `${getDateForInterval(intervalB, bIsPrevDay)}T${BClockOut}:00Z`;

  return aStart < bEnd && aEnd > bStart;
};

/**
 * Finds overlapping time intervals between two sets of time intervals.
 *
 * @param {TimeTrackingStoreItem[]} timeIntervals - The array of time intervals to check for overlaps.
 * @param {TimeTrackingStoreItem[] | null} timeIntervalsPrevDay - The array of time intervals from the previous day to check for overlaps with current day's intervals.
 * @returns An array containing objects representing the overlapping time
 * intervals. Each object contains either "index" and "overlapsWith" properties
 * for same-day overlaps, or "index" and "overlapsWithPrevDay" properties for
 * overlaps with the previous day's intervals.
 */
export const findOverlaps = (timeIntervals, timeIntervalsPrevDay) => {
  /** @type {{ index: number; overlapsWith: number; }[] */
  const sameDayValidation = timeIntervals.flatMap((current, i) => {
    return timeIntervals
      .slice(0, i)
      .map((previous, prevIndex) =>
        checkOverlap(current, previous) ? { index: i, overlapsWith: prevIndex } : null
      )
      .filter(Boolean);
  });

  /** @type {{ index: number; overlapsWithPrevDay: number; }[] */
  const prevDayValidation = [];
  if (timeIntervalsPrevDay?.length > 0) {
    timeIntervals.forEach((current, i) => {
      timeIntervalsPrevDay.forEach((prev, t) => {
        if (checkOverlap(current, prev, true)) {
          prevDayValidation.push({ index: i, overlapsWithPrevDay: t });
        }
      });
    });
  }

  return [...sameDayValidation, ...prevDayValidation];
};

/** Sanitizes time value by replacing '0:00' with '00:00' if the input is a string.
 * This is needed because of mask input.
 * @param {unknown} time - The time value to sanitize. Can be any type but only strings are processed.
 * @returns {unknown} The sanitized time string if input was a string, otherwise returns the input unchanged.
 */
export const sanitizeTime = (time) => {
  return time === '0:00' ? '00:00' : time;
};

/**
* Returns the start and end dates of the week relative to the current date.
*
@param {number} weekOffset - The number of weeks to offset from the current week. Negative values are allowed.
@returns {string[]} An array containing the start and end dates of the week in 'YYYY-MM-DD' format.
*/
export const getStartAndEndOfWeek = (weekOffset) => {
  const weekOptions = { weekStartsOn: 1 };
  const now = addWeeks(new Date(), weekOffset);
  const monday = endOfDay(startOfWeek(now, weekOptions));
  const sunday = endOfWeek(now, weekOptions);
  return [monday, sunday].map((date) =>
    formatYearMonthDay(date, {
      isSourceInUtc: false,
      formatInLocalTime: true,
    })
  );
};

/**
 * Builds an input time in UTC based on the provided day, time, and timezone.
 *
 * @param {Object} options - The options for building the input time.
 * @param {string} options.day - The day in the format 'YYYY-MM-DD'.
 * @param {string} options.time - The time in the format 'HH:mm'.
 * @param {string} options.timezone - The timezone to consider.
 * @returns {string | undefined} The input time in UTC as an ISO string, or undefined if an error occurs.
 */
export const buildInputTimeUtc = ({ day, time, timezone }) => {
  try {
    return zonedTimeToUtc(
      `${day}T${isTimeWithSeconds(time) ? time : `${time}:00`}`,
      timezone
    )?.toISOString();
  } catch (e) {
    datadogRum.addError(e, { time, day, timezone });
    return undefined;
  }
};

/**
 * Gets the time zone offset in milliseconds for a specified time zone and date.
 *
 * @param {string} timeZone - The target time zone (default is 'UTC').
 * @param {Date} date - The date for which to calculate the offset (default is the current date and time).
 * @returns {number} The time zone offset in milliseconds.
 */
export const getTimezoneOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return tzDate.getTime() - utcDate.getTime();
};

/**
 * Calculates the beginning of the day for a given date and timezone.
 * @param {Date} day - The date for which to calculate the beginning of the day.
 * @param {string} timezone - The timezone of the day.
 * @returns {Date} The beginning of the day in the specified timezone.
 */
function getBeginningOfDay(day, timezone) {
  const dayDate = new Date(day);
  const offsetMillis = getTimezoneOffset(timezone, dayDate);
  return new Date(dayDate.getTime() - offsetMillis);
}

/**
 * Calculates the start and end of a given day based on the provided timezone.
 *
 * @param {Date} day - The day for which to calculate the start and end.
 * @param {string} timezone - The timezone in which the day is defined.
 * @returns {Object} An object containing the start and end of the day as ISO strings.
 */
export const getDayStartAndEnd = (day, timezone) => {
  const beginningOfDay = getBeginningOfDay(day, timezone);
  return {
    start: beginningOfDay.toISOString(),
    end: new Date(beginningOfDay.getTime() + (24 * 60 * 60 * 1000 - 1))?.toISOString(),
  };
};

/**
 * Checks if the given clock-in time is inside the specified day.
 *
 * @param {Object} params - The parameters for checking the clock-in time.
 * @param {Date} params.day - The day to check.
 * @param {string} params.timezone - The timezone in which the day is defined.
 * @param {Date} params.clockIn - The clock-in time to check.
 * @returns {boolean} Whether the clock-in time is inside the specified day.
 */
export const clockInIsInsideDay = ({ day, timezone, clockIn }) => {
  const { start: startOfDayData, end: endOfDayData } = getDayStartAndEnd(day, timezone);
  return (
    new Date(startOfDayData) <= new Date(clockIn) && new Date(endOfDayData) > new Date(clockIn)
  );
};

/**
 * @param {Object} params
 * @param {string} params.clockInDay
 * @param {string} params.clockOutDay
 * @param {string} params.clockIn
 * @param {string} params.clockOut
 * @param {string} params.timezone
 * @param {string|null} params.notes
 * @param {TimeTrackingType} params.type
 */
const getTimeTracking = ({
  clockInDay,
  clockOutDay,
  clockIn,
  clockOut,
  timezone,
  notes = '',
  type,
}) => {
  return {
    clockIn: buildInputTimeUtc({ day: clockInDay, time: clockIn, timezone }),
    clockOut:
      isTimeWithSeconds(clockOut) || isTimeWithoutSeconds(clockOut)
        ? buildInputTimeUtc({
            day: clockOutDay,
            time: clockOut,
            timezone,
          })
        : null,
    timezone,
    notes,
    type,
  };
};

/**
 * @param {Object} params
 * @param {TimeTrackingStateDecorated["start"]} params.startDate
 * @param {TimeTrackingStateDecorated["end"]} params.endDate
 * @param {TimeTrackingStateDecorated["dayData"]} params.dayData
 * @param {string} params.timezone
 */
export const buildTimesheetCreationPayload = ({ startDate, endDate, dayData, timezone }) => {
  if (!startDate || !endDate) {
    return null;
  }
  const allTrackings = dayData.reduce(
    /** @param {TimeTrackingPayload[]} acc */
    (acc, curr) => {
      const transformedTrackings = curr.timeTrackings
        .filter(
          ({ duration, durationInSeconds }) =>
            duration !== 0 || (durationInSeconds && durationInSeconds !== 0)
        )
        .filter(({ error }) => !error)
        .map((tracking) => {
          const nextDayDate = new Date(curr.day);
          nextDayDate.setDate(nextDayDate.getDate() + 1);
          const nextDay = formatYearMonthDay(nextDayDate);
          const isNightShift = tracking.clockOut <= tracking.clockIn;
          return getTimeTracking({
            clockInDay: curr.day,
            clockOutDay: isNightShift ? nextDay : curr.day,
            clockIn: tracking.clockIn,
            clockOut: tracking.clockOut,
            timezone,
            notes: tracking.notes,
            type: tracking.type,
          });
        });
      return acc.concat(transformedTrackings);
    },
    []
  );

  return {
    startDate,
    endDate,
    timeTrackings: allTrackings,
  };
};

export const isOpenOrInCalibration = (status) =>
  [TIMESHEET_STATUS.OPEN, TIMESHEET_STATUS.IN_CALIBRATION].includes(status);

/**
 * Get the number of timeTracking items with error flag set to true.
 *
 * @param {Array} timeTrackingState - The time tracking state object.
 * @returns {Number} - The number of timeTracking items with error flag set to true.
 */
export const getNumberOfErrors = (timeTrackingState) => {
  function getAllTimeTrackings(dayData) {
    return dayData.reduce((acc, cur) => {
      acc.push(...cur.timeTrackings);
      return acc;
    }, []);
  }

  return getAllTimeTrackings(timeTrackingState.dayData).filter(({ error }) => Boolean(error))
    .length;
};

export function getErrors(timeTrackingState) {
  const errors = [];
  timeTrackingState.dayData.forEach(({ day, timeTrackings }) => {
    timeTrackings.forEach(({ error }) => {
      if (error) {
        errors.push({ date: day, message: error });
      }
    });
  });
  return errors;
}

/**
 * Checks whether today's date falls between two given dates.
 * Assumes that endDay is always after startDay
 * @param {string} startDay '2023-05-08'
 * @param {string} endDay '2023-05-14'
 * @returns boolean
 */
export const isTodayWithinThisWeek = (startDay, endDay) => {
  const today = new Date();
  const start = dateStringToLocalTime(startDay);
  const end = dateStringToLocalTime(endDay);
  return (
    isSameDay(today, start) ||
    isSameDay(today, end) ||
    (isAfter(today, start) && isBefore(today, end))
  );
};

/**
 * Checks if two strings of days are in the same week.
 * You can customize what day of the week the week begins on; default is Monday.
 * @param {string} dateLeft '2023-01-01'
 * @param {string} dateRight '2023-02-06
 * @param {number} weekStartsOn indicates the day of the week that the week starts on. 0 is Sunday, 1 is Monday, etc.
 */
export const areDatesInSameWeek = (dateLeft, dateRight, weekStartsOn = 1) => {
  const left = dateStringToLocalTime(dateLeft);
  const right = dateStringToLocalTime(dateRight);
  return differenceInCalendarWeeks(left, right, { weekStartsOn }) === 0;
};

/**
 * Returns a string representation of an ordinal number.
 *
 * @param {number} order - The number to stringify.
 * @returns {string} A string that represents the ordinal number.
 */
export const stringifyNumberOrder = (order) => {
  const special = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
  ];
  const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];
  if (order < 20) return special[order];
  if (order % 10 === 0) return `${deca[Math.floor(order / 10) - 2]}ieth`;
  if (order > 99) return order;
  return `${deca[Math.floor(order / 10) - 2]}y-${special[order % 10]}`;
};

/**
 * Returns an error message indicating that the time entry's hours overlap with another time entry.
 *
 * @param {Object} params - The parameters for calculating the error message.
 * @param {number|null} params.overlapsWith - The index of the overlapping time entry.
 * @param {number|null} params.overlapsWithPrevDay - Indicates if the overlapping time entry is from the previous day.
 * @returns {string} - The error message indicating the presence of overlapping hours.
 */
export const getOverlapError = ({ overlapsWith, overlapsWithPrevDay }) => {
  const index = isNil(overlapsWith) ? overlapsWithPrevDay : overlapsWith;
  return `This time entry's hours overlap with the ${stringifyNumberOrder(index + 1)} time entry${
    isNil(overlapsWithPrevDay) ? '' : ' of the previous day'
  }. Make sure none of the hours overlap.`;
};

export const getUserTimeTrackingTypeOptions = (user) =>
  generateSelectOptions(
    isContractor(user)
      ? omit(TIMETRACKING_TYPE_LABELS, [TIMETRACKING_TYPE.OVERTIME]) // Contractors cannot track overtime
      : TIMETRACKING_TYPE_LABELS
  );

export function getCardTimeOffText(timeOffDay) {
  const { hours, timeoff } = timeOffDay || {};
  const type =
    timeoff?.timeoffType === timeOffType.SICK_LEAVE ? 'sick leave' : timeoff?.leavePolicy.name;
  const status = timeoff?.status ? `${timeOffStatusLabels[timeoff?.status]} ` : '';
  return `${status}${type} (${hours}h). `;
}

/**
 * Helper function to generate total minutes in the timeTrackings array.
 * Used for user analytics (Rudderstack / Mixpanel) purposes
 *
 * FIXME: it can be undefined, but the code is not dealing with that
 * @param {TimeTrackingPayload[] | undefined} timeTrackings
 */
export function getTimeTrackingTotals(timeTrackings) {
  let totalRegularMinutes = 0;
  let totalOvertimeMinutes = 0;
  let totalBreakMinutes = 0;
  let totalOnCallMinutes = 0;

  timeTrackings.forEach(({ clockIn, clockOut, type }) => {
    const totalMinutes = differenceInMinutes(new Date(clockOut), new Date(clockIn));
    if (type === TIMETRACKING_TYPE.BREAK || type === TIMETRACKING_TYPE.UNPAID_BREAK) {
      totalBreakMinutes += totalMinutes;
    }
    if (type === TIMETRACKING_TYPE.OVERTIME) {
      totalOvertimeMinutes += totalMinutes;
    }
    if (type === TIMETRACKING_TYPE.REGULAR_HOURS) {
      totalRegularMinutes += totalMinutes;
    }
    if (type === TIMETRACKING_TYPE.ON_CALL) {
      totalOnCallMinutes += totalMinutes;
    }
  });

  return {
    totalRegularMinutes,
    totalOvertimeMinutes,
    totalBreakMinutes,
    totalOnCallMinutes,
    totalWorkedThisWeek: totalOnCallMinutes + totalOvertimeMinutes + totalRegularMinutes,
  };
}

export function convertTimeTrackingValuesToTimePreferencesApi(dayData) {
  const makeWorkHours = (timeTracking, day) => {
    const { clockIn, clockOut, type } = timeTracking;
    const clockOutDay = clockIn > clockOut ? addDays(new Date(day), 1) : day;
    return {
      clockIn: {
        time: clockIn,
        weekday: formatFullDayOfTheWeek(day).toLowerCase(),
      },
      clockOut: {
        time: clockOut,
        weekday: formatFullDayOfTheWeek(clockOutDay).toLowerCase(),
      },
      type,
    };
  };

  const final = [];
  dayData.forEach(({ day, timeTrackings }) => {
    if (timeTrackings) {
      const times = timeTrackings.map((time) => makeWorkHours(time, day));
      final.push(...times);
    }
  });
  return final;
}

/**
 * Converts the timeTrackings from a timesheet to work hours format.
 * Used to fulfill the `Pre-fill with last week's hours` prompt.
 * @param {*} timeTrackings
 * @returns
 */
export function convertTimesheetTimeTrackingsToWorkHours(timeTrackings) {
  const final = [];
  timeTrackings
    .filter(({ type }) => [TIMETRACKING_TYPE.REGULAR_HOURS, TIMETRACKING_TYPE.BREAK].includes(type))
    .forEach(({ clockIn, clockOut, type, timezone }) => {
      const startDay = formatFullDayOfTheWeek(clockIn, { timezone });
      const startTime = formatTime24(clockIn, { timezone });
      const endDay = formatFullDayOfTheWeek(clockOut, { timezone });
      const endTime = formatTime24(clockOut, { timezone });

      final.push({
        clockIn: {
          time: startTime,
          weekday: startDay.toLowerCase(),
        },
        clockOut: {
          time: endTime,
          weekday: endDay.toLowerCase(),
        },
        type,
      });
    });
  return final;
}

/**
 * The API stores time with seconds. Helper function to return just hours.
 * and minutes.
 * @param {string} time '23:00:00'
 * @returns string '23:00'
 */
export function removeSecondsFromTime(time) {
  return time.substring(0, 5);
}

/**
 * @param {string} day "friday" the sendAtDay value from time preferences API
 * @returns the next date of the day user selected
 * E.g., today is Monday, Oct 30. So the "next Friday" is "Nov 3"
 */
export const getNextDay = (day) => {
  const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const nextDate = nextDayFns(new Date(), WEEKDAYS.indexOf(day));
  return formatShortDayShortMonth(nextDate);
};

/**
 * Replaces underscores (_) in a timezone string with empty spaces to make it more readable.
 *
 * @param {string} timezone - The timezone string to prettify.
 * @returns {string} - The prettified timezone string with underscores replaced by empty spaces.
 */
export function prettifyTimezone(timezone) {
  return timezone?.replace(/_/g, ' ');
}

/**
 * Get a screen-reader friendly string of time quantity
 * e.g. "8h" -> "8 hours"
 * "5h 12m" -> "5 hours 12 minutes"
 * "1m" -> "1 minute"
 * @param {string} time
 * @returns string
 */
export function getTotalTimeSRText(time) {
  if (!time) return '';
  if (time === '0h') return '0 hours';
  const hoursMatch = time.match(/(\d+)h/);
  const minutesMatch = time.match(/(\d+)m/);
  const hours = hoursMatch ? parseInt(hoursMatch, 10) : 0;
  const minutes = minutesMatch ? parseInt(minutesMatch, 10) : 0;
  const hoursA11y = hours > 0 ? getSingularPluralUnit(hours, 'hour ', 'hours ', false) : '';
  const minutesA11y = minutes > 0 ? getSingularPluralUnit(minutes, 'minute', 'minutes', false) : '';

  return `${hoursA11y}${minutesA11y}`.trim();
}

export const getHHmm = (time) => time.split(':').slice(0, 2).join(':');

export const formatTimeWithFallback = (time, timezone) => {
  return formatTime24(time, {
    timezone,
    fallback: getHHmm(time),
  });
};

/**
 * Hashes a string and maps it to an index within the range of the given array length.
 *
 * @param {string} str - The string to hash.
 * @param {number} arrayLength - The length of the array to map the hash value to.
 * @returns {number} - The index within the array length range.
 */
export function hashStringToIndex(str, arrayLength) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash = (hash * 31 + charCode) % 0xaffffff1;
  }

  return Math.abs(hash) % arrayLength;
}

/**
 * Helper function to determine if the HoursAndMinutes is 0
 * @param {{ hours: number, minutes: number}} time
 * @returns boolean
 */
export function isTimeZero(time) {
  return time.hours === 0 && time.minutes === 0;
}
