import { Text } from '@remote-com/norma';
import { NodeViewContent } from '@tiptap/react';
import styled, { css } from 'styled-components';

export const ConditionalContent = styled(NodeViewContent)`
  ${({ theme }) => theme.typography.sm};
  margin-top: 12px;
`;

export const ConditionalListItem = styled.li`
  margin-left: 16px;
`;
export const ListItemWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const InlineConditionalContent = styled(NodeViewContent)`
  height: 100%;
  line-height: 1.4;
  margin-left: 4px;
  margin-right: 4px;
  padding: 1px 4px;
  border: ${({ theme, $conditionalResult }) =>
    `1px dashed ${$conditionalResult ? theme.colors.brand[600] : theme.colors.grey[400]}`};
  border-radius: 4px;
  display: inline;
`;

export const Condition = styled.span`
  display: inline-flex;
  border-radius: 4px;
  user-select: none;
  padding: 1px 0;
  font-size: ${({ theme }) => theme.typography.xs};
  color: ${({ $nodePresentation }) => $nodePresentation.text};
  background: ${({ $nodePresentation }) => $nodePresentation.background};
  border: ${({ $nodePresentation }) => `1px dashed ${$nodePresentation.background}`};

  ${({ hasConditionalEditing }) =>
    hasConditionalEditing &&
    css`
      &:hover {
        outline: 1px solid ${({ theme }) => theme.colors.brand[600]};
      }
    `};
`;

export const BlockCondition = styled(Condition)`
  display: flex;
  align-items: center;
  margin-left: 4px;
  padding: 4px 8px;

  ${({ hasConditionalEditing }) =>
    hasConditionalEditing &&
    css`
      &:hover {
        outline: 1px solid ${({ theme }) => theme.colors.brand[600]};
      }
    `};
`;

export const ExplainerText = styled(Text)`
  color: ${({ theme }) => theme.colors.lynch};
  margin-top: 4px;
`;

export const ConditionalField = styled.span`
  font-weight: 700;
  display: inline-flex;
  justify-content: space-between;
`;

export const ConditionalOperator = styled.span`
  font-weight: 400;
`;
