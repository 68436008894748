export const HGK_BANK_CODES = [
  {
    value: '552',
    label: 'AAREAL BANK AG, WIESBADEN, GERMANY [552]',
  },
  {
    value: '307',
    label: 'ABN AMRO BANK N.V. [307]',
  },
  {
    value: 'A10',
    label: 'AGRICULTURAL BANK OF CHINA LIMITED FRANKFURT BRANCH [A10]',
  },
  {
    value: '222',
    label: 'AGRICULTURAL BANK OF CHINA LIMITED [222]',
  },
  {
    value: '340',
    label: 'AGRICULTURAL BANK OF CHINA LIMITED, SINGAPORE BRANCH [340]',
  },
  {
    value: '400',
    label: 'AGRICULTURAL BANK OF CHINA, BEIJING [400]',
  },
  {
    value: '395',
    label: 'AIRSTAR BANK LIMITED [395]',
  },
  {
    value: '256',
    label: 'ALLAHABAD BANK [256]',
  },
  {
    value: '402',
    label: 'ALLIED BANKING CORPORATION (HK) LTD. [402]',
  },
  {
    value: '393',
    label: 'ANT BANK (HONG KONG) LIMITED [393]',
  },
  {
    value: '152',
    label: 'AUSTRALIA AND NEW ZEALAND BANKING GROUP LTD [152]',
  },
  {
    value: '261',
    label: 'AXIS BANK LIMITED [261]',
  },
  {
    value: '234',
    label: 'BANCA MONTE DEI PASCHI DI SIENA S.P.A. - HONG KONG BRANCH [234]',
  },
  {
    value: '147',
    label: 'BANCO BILBAO VIZCAYA ARGENTARIA, S.A. [147]',
  },
  {
    value: '356',
    label: 'BANCO DO BRASIL S. A., TOKYO BRANCH [356]',
  },
  {
    value: '267',
    label: 'BANCO SANTANDER, S.A. [267]',
  },
  {
    value: '049',
    label: 'BANGKOK BANK PUBLIC COMPANY LTD [049]',
  },
  {
    value: '553',
    label: 'BANK FOR FOREIGN TRADE OF VIETNAM [553]',
  },
  {
    value: '278',
    label: 'BANK J. SAFRA SARASIN LTD [278]',
  },
  {
    value: '320',
    label: 'BANK JULIUS BAER & CO. LTD. [320]',
  },
  {
    value: '055',
    label: 'BANK OF AMERICA, NATIONAL ASSOCIATION [055]',
  },
  {
    value: '259',
    label: 'BANK OF BARODA [259]',
  },
  {
    value: '283',
    label: 'BANK OF CHINA (ELUOSI) [283]',
  },
  {
    value: '012',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [012]',
  },
  {
    value: '014',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [014]',
  },
  {
    value: '019',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [019]',
  },
  {
    value: '026',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [026]',
  },
  {
    value: '030',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [030]',
  },
  {
    value: '031',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [031]',
  },
  {
    value: '033',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [033]',
  },
  {
    value: '036',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [036]',
  },
  {
    value: '064',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [064]',
  },
  {
    value: '070',
    label: 'BANK OF CHINA (HONG KONG) LIMITED [070]',
  },
  {
    value: '290',
    label: 'BANK OF CHINA (HUNGARY) CLOSE LIMITED [290]',
  },
  {
    value: '282',
    label: 'BANK OF CHINA (MALAYSIA) BERHAD [282]',
  },
  {
    value: '284',
    label: 'BANK OF CHINA LIMITED JAKARTA BRANCH [284]',
  },
  {
    value: '298',
    label: 'BANK OF CHINA LIMITED JOHANNESBURG BRANCH [298]',
  },
  {
    value: '301',
    label: 'BANK OF CHINA LIMITED LUXEMBOURG BRANCH [301]',
  },
  {
    value: '279',
    label: 'BANK OF CHINA LIMITED MANILA BRANCH [279]',
  },
  {
    value: '291',
    label: 'BANK OF CHINA LIMITED MILAN BRANCH [291]',
  },
  {
    value: '304',
    label: 'BANK OF CHINA LIMITED NEW YORK BRANCH [304]',
  },
  {
    value: '303',
    label: 'BANK OF CHINA LIMITED PARIS BRANCH [303]',
  },
  {
    value: '327',
    label: 'BANK OF CHINA LIMITED PHNOM PENH BRANCH [327]',
  },
  {
    value: '285',
    label: 'BANK OF CHINA LIMITED SEOUL BRANCH [285]',
  },
  {
    value: '286',
    label: 'BANK OF CHINA LIMITED SINGAPORE BRANCH [286]',
  },
  {
    value: '288',
    label: 'BANK OF CHINA LIMITED SYDNEY BRANCH [288]',
  },
  {
    value: 'A00',
    label: 'BANK OF CHINA LIMITED TAIPEI BRANCH [A00]',
  },
  {
    value: '280',
    label: 'BANK OF CHINA LIMITED TOKYO BRANCH [280]',
  },
  {
    value: '338',
    label: 'BANK OF CHINA LIMITED, HONG KONG BRANCH [338]',
  },
  {
    value: '311',
    label: 'BANK OF CHINA LIMITED, PANAMA BRANCH [311]',
  },
  {
    value: '292',
    label: 'BANK OF CHINA LONDON BRANCH [292]',
  },
  {
    value: 'A12',
    label: 'BANK OF CHINA LTD. - ABU DHABI [A12]',
  },
  {
    value: '300',
    label: 'BANK OF CHINA, LUXEMBOURG S.A. [300]',
  },
  {
    value: '428',
    label: 'BANK OF COMMUNICATIONS CO., LTD. HEAD OFFICE, SHANGHAI [428]',
  },
  {
    value: '429',
    label: 'BANK OF COMMUNICATIONS CO., LTD. SHENZHEN BRANCH [429]',
  },
  {
    value: '027',
    label: 'BANK OF COMMUNICATIONS CO., LTD. [027]',
  },
  {
    value: '382',
    label: 'BANK OF COMMUNICATIONS HONG KONG LIMITED [382]',
  },
  {
    value: '349',
    label: 'BANK OF COMMUNICATIONS SEOUL BRANCH [349]',
  },
  {
    value: '555',
    label: 'BANK OF HANGZHOU, HANGZHOU [555]',
  },
  {
    value: '058',
    label: 'BANK OF INDIA [058]',
  },
  {
    value: '506',
    label: 'BANK OF JIANGSU CO. LTD., NANJING [506]',
  },
  {
    value: '086',
    label: 'BANK OF MONTREAL [086]',
  },
  {
    value: '542',
    label: 'BANK OF MONTREAL, GUANGZHOU BRANCH [542]',
  },
  {
    value: '478',
    label: 'BANK OF NINGBO CO. LTD., ZHEJIANG [478]',
  },
  {
    value: '372',
    label: 'BANK OF SHANGHAI (HONG KONG) LIMITED [372]',
  },
  {
    value: '272',
    label: 'BANK OF SINGAPORE LIMITED [272]',
  },
  {
    value: '201',
    label: 'BANK OF TAIWAN [201]',
  },
  {
    value: '241',
    label: 'BANK SINOPAC [241]',
  },
  {
    value: '244',
    label: 'BANK SINOPAC [244]',
  },
  {
    value: '355',
    label: 'BANK SINOPAC, LOS ANGELES BRANCH [355]',
  },
  {
    value: '431',
    label: 'BANK SINOPAC, TAIPEI [431]',
  },
  {
    value: '074',
    label: 'BARCLAYS BANK PLC [074]',
  },
  {
    value: '067',
    label: 'BDO UNIBANK, INC. [067]',
  },
  {
    value: '275',
    label: 'BNP PARIBAS SECURITIES SERVICES [275]',
  },
  {
    value: '240',
    label: 'BNP PARIBAS WEALTH MANAGEMENT [240]',
  },
  {
    value: '056',
    label: 'BNP PARIBAS [056]',
  },
  {
    value: '252',
    label: 'BOC CREDIT CARD (INTERNATIONAL) LIMITED [252]',
  },
  {
    value: '350',
    label: 'BSI LTD [350]',
  },
  {
    value: '351',
    label: 'CAMBODIA MEKONG BANK PUBLIC LIMITED [351]',
  },
  {
    value: '092',
    label: 'CANADIAN IMPERIAL BANK OF COMMERCE [092]',
  },
  {
    value: '366',
    label: 'CANADIAN IMPERIAL BANK OF COMMERCE, CANADA [366]',
  },
  {
    value: '262',
    label: 'CANARA BANK [262]',
  },
  {
    value: '263',
    label: 'CATHAY BANK [263]',
  },
  {
    value: '236',
    label: 'CATHAY UNITED BANK COMPANY, LIMITED [236]',
  },
  {
    value: '206',
    label: 'CHANG HWA COMMERCIAL BANK LTD [206]',
  },
  {
    value: '529',
    label: 'CHANGSHA CITY COMMERCIAL BANK [529]',
  },
  {
    value: '018',
    label: 'CHINA CITIC BANK INTERNATIONAL LIMITED [018]',
  },
  {
    value: '051',
    label: 'CHINA CITIC BANK INTERNATIONAL LIMITED [051]',
  },
  {
    value: '437',
    label: 'CHINA CITIC BANK, BEIJING [437]',
  },
  {
    value: '009',
    label: 'CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED [009]',
  },
  {
    value: '221',
    label: 'CHINA CONSTRUCTION BANK CORPORATION [221]',
  },
  {
    value: '358',
    label: 'CHINA CONSTRUCTION BANK SEOUL BRANCH [358]',
  },
  {
    value: '276',
    label: 'CHINA DEVELOPMENT BANK CORPORATION [276]',
  },
  {
    value: '368',
    label: 'CHINA EVERBRIGHT BANK CO., LTD [368]',
  },
  {
    value: '434',
    label: 'CHINA EVERBRIGHT BANK, BEIJING [434]',
  },
  {
    value: '447',
    label: 'CHINA GUANGFA BANK CO. LTD, BEIJING [447]',
  },
  {
    value: '549',
    label: 'CHINA GUANGFA BANK CO., LTD. GUANGZHOU [549]',
  },
  {
    value: 'A09',
    label: 'CHINA MERCHANTS BANK CO., LTD, SINGAPORE BRANCH [A09]',
  },
  {
    value: '238',
    label: 'CHINA MERCHANTS BANK CO., LTD. [238]',
  },
  {
    value: '435',
    label: 'CHINA MERCHANTS BANK, HEAD OFFICE, SHENZHEN [435]',
  },
  {
    value: '353',
    label: 'CHINA MINSHENG BANKING CORP., LTD. [353]',
  },
  {
    value: '436',
    label: 'CHINA MINSHENG BANKING CORPORATION LTD., BEIJING [436]',
  },
  {
    value: '507',
    label: 'CHINA RESOURCES BANK OF ZHUHAI CO LTD., ZHUHAI [507]',
  },
  {
    value: '039',
    label: 'CHIYU BANKING CORPORATION LTD [039]',
  },
  {
    value: '041',
    label: 'CHONG HING BANK LTD [041]',
  },
  {
    value: '374',
    label: 'CIMB BANK BERHAD [374]',
  },
  {
    value: '343',
    label: 'CIMB THAI BANK PUBLIC COMPANY LIMITED [343]',
  },
  {
    value: '531',
    label: 'CITIBANK (CHINA) CO LTD, SHENZHEN BRANCH [531]',
  },
  {
    value: '250',
    label: 'CITIBANK (HONG KONG) LIMITED [250]',
  },
  {
    value: '006',
    label: 'CITIBANK N.A. [006]',
  },
  {
    value: '097',
    label: 'COMMERZBANK AG [097]',
  },
  {
    value: '153',
    label: 'COMMONWEALTH BANK OF AUSTRALIA [153]',
  },
  {
    value: '524',
    label: 'CONCORD BANK LTD., NINGBO [524]',
  },
  {
    value: '868',
    label: 'CONTINUOUS LINKED SETTLEMENT BANK INTERNATIONAL [868]',
  },
  {
    value: '323',
    label: 'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A. (TRADIN [323]',
  },
  {
    value: '183',
    label: 'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A. [183]',
  },
  {
    value: '319',
    label: 'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A.(TRADING [319]',
  },
  {
    value: '205',
    label: 'COUTTS & CO LTD [205]',
  },
  {
    value: '513',
    label: 'COUTTS AND CO. LTD., SINGAPORE [513]',
  },
  {
    value: '339',
    label: 'CREDIT AGRICOLE (SUISSE) S.A. [339]',
  },
  {
    value: '005',
    label: 'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK [005]',
  },
  {
    value: '233',
    label: 'CREDIT SUISSE AG [233]',
  },
  {
    value: '229',
    label: 'CTBC BANK CO., LTD. [229]',
  },
  {
    value: '040',
    label: 'DAH SING BANK LTD [040]',
  },
  {
    value: '016',
    label: 'DBS BANK (HONG KONG) LIMITED [016]',
  },
  {
    value: '054',
    label: 'DEUTSCHE BANK AG [054]',
  },
  {
    value: '328',
    label: 'DNB NOR BANK ASA, SINGAPORE BRANCH [328]',
  },
  {
    value: '439',
    label: 'DONGGUAN RURAL CREDIT COOPERATIVES UNION, DONGGUAN [439]',
  },
  {
    value: '113',
    label: 'DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK [113]',
  },
  {
    value: '243',
    label: 'E.SUN COMMERCIAL BANK, LTD [243]',
  },
  {
    value: '557',
    label: 'E.SUN COMMERCIAL BANK, LTD., TAIPEI [557]',
  },
  {
    value: '258',
    label: 'EAST WEST BANK [258]',
  },
  {
    value: '346',
    label: 'EDMOND DE ROTHSCHILD (SUISSE) S.A. [346]',
  },
  {
    value: '237',
    label: 'EFG BANK AG [237]',
  },
  {
    value: '227',
    label: 'ERSTE GROUP BANK AG [227]',
  },
  {
    value: '440',
    label: 'EXPORT-IMPORT BANK OF THAILAND, BANGKOK [440]',
  },
  {
    value: '253',
    label: 'FALCON PRIVATE BANK LTD. [253]',
  },
  {
    value: '260',
    label: 'FAR EASTERN INTERNATIONAL BANK [260]',
  },
  {
    value: '362',
    label: 'FINABANK N.V. [362]',
  },
  {
    value: '203',
    label: 'FIRST COMMERCIAL BANK [203]',
  },
  {
    value: '128',
    label: 'FUBON BANK (HONG KONG) LIMITED [128]',
  },
  {
    value: '391',
    label: 'FUSION BANK LIMITED [391]',
  },
  {
    value: '325',
    label: 'GRANIT BANK ZRT [325]',
  },
  {
    value: '540',
    label: 'GUIYANG COMMERCIAL BANK, GUIYANG [540]',
  },
  {
    value: '322',
    label: 'HABIB BANK ZURICH (HONG KONG) LIMITED [322]',
  },
  {
    value: '112',
    label: 'HANA BANK [112]',
  },
  {
    value: '024',
    label: 'HANG SENG BANK LTD [024]',
  },
  {
    value: '308',
    label: 'HDFC BANK LTD. [308]',
  },
  {
    value: '935',
    label: 'HKT Payment Limited [935]',
  },
  {
    value: '888',
    label: 'HONG KONG MONETARY AUTHORITY [888]',
  },
  {
    value: '248',
    label: 'HONG LEONG BANK BERHAD [248]',
  },
  {
    value: '004',
    label: 'HSBC - THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD [004]',
  },
  {
    value: '450',
    label: 'HSBC BANGLADESH [450]',
  },
  {
    value: '464',
    label: 'HSBC BANK (TAIWAN) LIMITED [464]',
  },
  {
    value: '457',
    label: 'HSBC BANK MALAYSIA BERHAD [457]',
  },
  {
    value: '347',
    label: 'HSBC BANK PLC [347]',
  },
  {
    value: '106',
    label: 'HSBC BANK USA, NATIONAL ASSOCIATION [106]',
  },
  {
    value: '466',
    label: 'HSBC BANK VIETNAM LTD [466]',
  },
  {
    value: '451',
    label: 'HSBC BRUNEI [451]',
  },
  {
    value: '452',
    label: 'HSBC INDIA [452]',
  },
  {
    value: '453',
    label: 'HSBC INDONESIA [453]',
  },
  {
    value: '454',
    label: 'HSBC JAPAN [454]',
  },
  {
    value: '458',
    label: 'HSBC MANILA [458]',
  },
  {
    value: '235',
    label: 'HSBC PRIVATE BANK (SUISSE) SA [235]',
  },
  {
    value: '460',
    label: 'HSBC SEOUL BRANCH [460]',
  },
  {
    value: '462',
    label: 'HSBC SINGAPORE [462]',
  },
  {
    value: '463',
    label: 'HSBC SRI LANKA [463]',
  },
  {
    value: '465',
    label: 'HSBC THAILAND [465]',
  },
  {
    value: '198',
    label: 'HUA NAN COMMERCIAL BANK LTD [198]',
  },
  {
    value: '550',
    label: 'HUA NAN COMMERCIAL BANK LTD., TAIPEI [550]',
  },
  {
    value: '468',
    label: 'HUA XIA BANK, BEIJING [468]',
  },
  {
    value: '341',
    label: 'ICBC STANDARD BANK PLC [341]',
  },
  {
    value: '251',
    label: 'ICICI BANK LIMITED [251]',
  },
  {
    value: '050',
    label: 'INDIAN OVERSEAS BANK [050]',
  },
  {
    value: '029',
    label: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD [029]',
  },
  {
    value: '072',
    label: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD [072]',
  },
  {
    value: '330',
    label: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD [330]',
  },
  {
    value: '214',
    label: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED [214]',
  },
  {
    value: '469',
    label: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA, BEIJING [469]',
  },
  {
    value: '377',
    label: 'INDUSTRIAL BANK CO., LTD. [377]',
  },
  {
    value: '445',
    label: 'INDUSTRIAL BANK COMPANY LTD., FUZHOU [445]',
  },
  {
    value: '271',
    label: 'INDUSTRIAL BANK OF KOREA [271]',
  },
  {
    value: '274',
    label: 'INDUSTRIAL BANK OF TAIWAN CO., LTD. [274]',
  },
  {
    value: '470',
    label: 'INDUSTRIAL BANK OF TAIWAN, TAIPEI [470]',
  },
  {
    value: '145',
    label: 'ING BANK N.V. [145]',
  },
  {
    value: '161',
    label: 'INTESA SANPAOLO S.P.A. [161]',
  },
  {
    value: '375',
    label: 'J.P. MORGAN SECURITIES (ASIA PACIFIC) LIMITED [375]',
  },
  {
    value: '541',
    label: 'JIANG YIN RURAL COMMERCIAL BANK, JIANG YIN CITY, JIANG SU [541]',
  },
  {
    value: '317',
    label: 'JOINT STOCK COMMERCIAL BANK "LANTA-BANK" (CJSC) [317]',
  },
  {
    value: '407',
    label: 'JOINT STOCK COMMERCIAL BANK FOR INVESTMENT AND DEVELOPMENT O [407]',
  },
  {
    value: '007',
    label: 'JPMORGAN CHASE BANK [007]',
  },
  {
    value: '305',
    label: 'JSC AB (BANK OF CHINA KAZAKHSTAN) [305]',
  },
  {
    value: 'A06',
    label: 'JSC CAPITAL BANK [A06]',
  },
  {
    value: '380',
    label: 'KASIKORNBANK PUBLIC COMPANY LIMITED [380]',
  },
  {
    value: '178',
    label: 'KBC BANK N.V. [178]',
  },
  {
    value: '318',
    label: 'KDB ASIA LIMITED [318]',
  },
  {
    value: '363',
    label: 'KEB ASIA FINANCE LIMITED [363]',
  },
  {
    value: '046',
    label: 'KOREA EXCHANGE BANK [046]',
  },
  {
    value: 'A02',
    label: 'KOREA EXCHANGE BANK, KOREA [A02]',
  },
  {
    value: '264',
    label: 'LAND BANK OF TAIWAN CO., LTD. [264]',
  },
  {
    value: '342',
    label: 'LGT BANK AG [342]',
  },
  {
    value: '388',
    label: 'LIVI BANK LIMITED [388]',
  },
  {
    value: '344',
    label: 'MACQUARIE BANK LIMITED [344]',
  },
  {
    value: '379',
    label: 'MASHREQ BANK - PUBLIC SHAREHOLDING COMPANY [379]',
  },
  {
    value: '352',
    label: 'MASHREQBANK PSC [352]',
  },
  {
    value: '063',
    label: 'MAYBANK (MALAYAN BANKING BERHAD) [063]',
  },
  {
    value: '242',
    label: 'MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD. [242]',
  },
  {
    value: '254',
    label: 'MELLI BANK PLC [254]',
  },
  {
    value: '295',
    label: 'METROPOLITAN BANK & TRUST COMPANY [295]',
  },
  {
    value: '138',
    label: 'MITSUBISHI UFJ TRUST AND BANKING CORPORATION [138]',
  },
  {
    value: '109',
    label: 'MIZUHO BANK, LTD. [109]',
  },
  {
    value: '389',
    label: 'Mox Bank Limited [389]',
  },
  {
    value: '043',
    label: 'NANYANG COMMERCIAL BANK LTD [043]',
  },
  {
    value: '150',
    label: 'NATIONAL AUSTRALIA BANK LTD [150]',
  },
  {
    value: '277',
    label: 'NATIONAL BANK OF ABU DHABI [277]',
  },
  {
    value: '331',
    label: 'NATIONAL BANK OF CANADA [331]',
  },
  {
    value: '060',
    label: 'NATIONAL BANK OF PAKISTAN [060]',
  },
  {
    value: '210',
    label: 'NATIXIS [210]',
  },
  {
    value: '035',
    label: 'OCBC WING HANG BANK LIMITED [035]',
  },
  {
    value: '044',
    label: 'OCBC WING HANG BANK LIMITED [044]',
  },
  {
    value: '367',
    label: 'ORIENT EXPRESS BANK [367]',
  },
  {
    value: '373',
    label: 'ORIX ASIA LIMITED [373]',
  },
  {
    value: '022',
    label: 'OVERSEA-CHINESE BANKING CORPORATION LTD [022]',
  },
  {
    value: '119',
    label: 'PHILIPPINE NATIONAL BANK [119]',
  },
  {
    value: '357',
    label: 'PICTET & CIE (EUROPE) S.A. [357]',
  },
  {
    value: '485',
    label: 'PING AN BANK CO., LTD, SHENZHEN [485]',
  },
  {
    value: '218',
    label: 'PORTIGON AG [218]',
  },
  {
    value: '281',
    label: 'PT BANK CENTRAL ASIA TBK, INDONESIA [281]',
  },
  {
    value: '480',
    label: 'PT. BANK MANDIRI (PERSERO) TBK, HONG KONG BRANCH [480]',
  },
  {
    value: '066',
    label: 'PT. BANK NEGARA INDONESIA (PERSERO) TBK. [066]',
  },
  {
    value: '028',
    label: 'PUBLIC BANK (HONG KONG) LIMITED [028]',
  },
  {
    value: '266',
    label: 'PUNJAB NATIONAL BANK [266]',
  },
  {
    value: '370',
    label: 'RAIFFEISEN BANK INTERNATIONAL AG [370]',
  },
  {
    value: '354',
    label: 'RAIFFEISEN BANK INTERNATIONAL AG, SINGAPORE BRANCH [354]',
  },
  {
    value: '080',
    label: 'ROYAL BANK OF CANADA [080]',
  },
  {
    value: '296',
    label: 'SAIGON THUONG TIN COMMERCIAL JOINT STOCK BANK [296]',
  },
  {
    value: '025',
    label: 'SHANGHAI COMMERCIAL BANK LTD [025]',
  },
  {
    value: '345',
    label: 'SHANGHAI PUDONG DEVELOPMENT BANK CO., LTD [345]',
  },
  {
    value: '484',
    label: 'SHANGHAI PUDONG DEVELOPMENT BANK, SHANGHAI [484]',
  },
  {
    value: '486',
    label: 'SHENZHEN DEVELOPMENT BANK, SHENZHEN [486]',
  },
  {
    value: '509',
    label: 'SHENZHEN RURAL COMMERCIAL BANK [509]',
  },
  {
    value: '273',
    label: 'SHINHAN BANK [273]',
  },
  {
    value: '316',
    label: 'SKANDINAVISKA ENSKILDA BANKEN AB [316]',
  },
  {
    value: '255',
    label: 'SOCIETE GENERALE BANK & TRUST [255]',
  },
  {
    value: '081',
    label: 'SOCIETE GENERALE [081]',
  },
  {
    value: '818',
    label: 'STANDARD CHARTERED BANK (HONG KONG) LIMITED - EURO CLEARING [818]',
  },
  {
    value: '003',
    label: 'STANDARD CHARTERED BANK (HONG KONG) LIMITED [003]',
  },
  {
    value: '249',
    label: 'STANDARD CHARTERED BANK HONG KONG BRANCH [249]',
  },
  {
    value: '536',
    label: 'STANDARD CHARTERED BANK PLC, LONDON [536]',
  },
  {
    value: '521',
    label: 'STANDARD CHARTERED BANK, BANGKOK [521]',
  },
  {
    value: '489',
    label: 'STANDARD CHARTERED BANK, BEIJING BRANCH [489]',
  },
  {
    value: '515',
    label: 'STANDARD CHARTERED BANK, JAKARTA BRANCH [515]',
  },
  {
    value: '519',
    label: 'STANDARD CHARTERED BANK, MALAYSIA BERHAD [519]',
  },
  {
    value: '490',
    label: 'STANDARD CHARTERED BANK, NANJING BRANCH [490]',
  },
  {
    value: '517',
    label: 'STANDARD CHARTERED BANK, PHILIPPINES BRANCH [517]',
  },
  {
    value: '518',
    label: 'STANDARD CHARTERED BANK, SEOUL KOREA [518]',
  },
  {
    value: '491',
    label: 'STANDARD CHARTERED BANK, SHANGHAI BRANCH [491]',
  },
  {
    value: '492',
    label: 'STANDARD CHARTERED BANK, SHENZHEN BRANCH [492]',
  },
  {
    value: '516',
    label: 'STANDARD CHARTERED BANK, SINGAPORE BRANCH [516]',
  },
  {
    value: '522',
    label: 'STANDARD CHARTERED BANK, SRI LANKA [522]',
  },
  {
    value: '520',
    label: 'STANDARD CHARTERED BANK, TAIWAN [520]',
  },
  {
    value: '493',
    label: 'STANDARD CHARTERED BANK, TIANJIN BRANCH [493]',
  },
  {
    value: 'A07',
    label: 'STANDARD CHARTERED BANK, TOKYO [A07]',
  },
  {
    value: '494',
    label: 'STANDARD CHARTERED BANK, XIAMEN BRANCH [494]',
  },
  {
    value: '495',
    label: 'STANDARD CHARTERED BANK, ZHUHAI BRANCH [495]',
  },
  {
    value: '082',
    label: 'STATE BANK OF INDIA [082]',
  },
  {
    value: '220',
    label: 'STATE STREET BANK AND TRUST COMPANY [220]',
  },
  {
    value: '065',
    label: 'SUMITOMO MITSUI BANKING CORPORATION [065]',
  },
  {
    value: '371',
    label: 'SUMITOMO MITSUI TRUST BANK, LIMITED [371]',
  },
  {
    value: '496',
    label: 'SUNNY BANK, TAIPEI [496]',
  },
  {
    value: '165',
    label: 'SVENSKA HANDELSBANKEN AB (PUBL) [165]',
  },
  {
    value: '378',
    label: 'TA CHONG BANK, LTD. [378]',
  },
  {
    value: '061',
    label: 'TAI SANG BANK LTD [061]',
  },
  {
    value: '038',
    label: 'TAI YAU BANK LTD [038]',
  },
  {
    value: '498',
    label: 'TAICHUNG COMMERCIAL BANK, TAICHUNG [498]',
  },
  {
    value: '239',
    label: 'TAIPEI FUBON COMMERCIAL BANK CO., LTD. [239]',
  },
  {
    value: '245',
    label: 'TAISHIN INTERNATIONAL BANK CO., LTD [245]',
  },
  {
    value: '499',
    label: 'TAISHIN INTERNATIONAL BANK, TAIPEI [499]',
  },
  {
    value: '230',
    label: 'TAIWAN BUSINESS BANK HONG KONG BRANCH [230]',
  },
  {
    value: '265',
    label: 'TAIWAN COOPERATIVE BANK, LTD. [265]',
  },
  {
    value: '337',
    label: 'TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD. [337]',
  },
  {
    value: '474',
    label: 'TAIWAN SHIN KONG COMMERCIAL BANK COMPANY LIMITED, TAIPEI [474]',
  },
  {
    value: '502',
    label: 'THE BANK OF BERMUDA LIMITED, HONG KONG BRANCH [502]',
  },
  {
    value: '015',
    label: 'THE BANK OF EAST ASIA, LTD [015]',
  },
  {
    value: '139',
    label: 'THE BANK OF NEW YORK MELLON [139]',
  },
  {
    value: '076',
    label: 'THE BANK OF NOVA SCOTIA [076]',
  },
  {
    value: '329',
    label: 'THE BANK OF NOVA SCOTIA, SINGAPORE [329]',
  },
  {
    value: '047',
    label: 'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD. [047]',
  },
  {
    value: '170',
    label: 'THE CHIBA BANK LTD [170]',
  },
  {
    value: '202',
    label: 'THE CHUGOKU BANK LTD [202]',
  },
  {
    value: '188',
    label: 'THE HACHIJUNI BANK, LTD [188]',
  },
  {
    value: '828',
    label: 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD - USD CLEA [828]',
  },
  {
    value: '554',
    label: 'THE HOUSING BANK FOR TRADE AND FINANCE, JORDAN [554]',
  },
  {
    value: '224',
    label: 'THE IYO BANK LTD [224]',
  },
  {
    value: '360',
    label: 'THE ROYAL BANK OF SCOTLAND N.V. [360]',
  },
  {
    value: '008',
    label: 'THE ROYAL BANK OF SCOTLAND PLC [008]',
  },
  {
    value: '528',
    label: 'THE RURAL CREDIT COOPERATIVES UNION OF SHUNDE [528]',
  },
  {
    value: '269',
    label: 'THE SHANGHAI COMMERCIAL & SAVINGS BANK LTD [269]',
  },
  {
    value: '199',
    label: 'THE SHIGA BANK, LTD. [199]',
  },
  {
    value: '186',
    label: 'THE SHIZUOKA BANK, LTD [186]',
  },
  {
    value: '487',
    label: 'THE SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED [487]',
  },
  {
    value: '085',
    label: 'THE TORONTO-DOMINION BANK [085]',
  },
  {
    value: '500',
    label: 'TMB BANK PUBLIC COMPANY LIMITED, BANGKOK [500]',
  },
  {
    value: '501',
    label: 'TMB BANK PUBLIC COMPANY LIMITED, HONG KONG [501]',
  },
  {
    value: '312',
    label: 'TURK EKONOMI BANKASI A.S. [312]',
  },
  {
    value: '103',
    label: 'UBS AG, HONG KONG [103]',
  },
  {
    value: '045',
    label: 'UCO BANK [045]',
  },
  {
    value: '164',
    label: 'UNICREDIT BANK AG [164]',
  },
  {
    value: '268',
    label: 'UNION BANK OF INDIA [268]',
  },
  {
    value: '071',
    label: 'UNITED OVERSEAS BANK LTD [071]',
  },
  {
    value: '504',
    label: 'UNITED SUBURBAN AND RURAL CREDIT COOPERATIVE WUXI, WUXI [504]',
  },
  {
    value: '293',
    label: 'VIETNAM JOINT STOCK COMMERCIAL BANK FOR INDUSTRY AND TRADE [293]',
  },
  {
    value: '505',
    label: 'VIETNAM TECHNOLOGICAL AND COMMERCIAL JOINT STOCK BANK, HANOI [505]',
  },
  {
    value: '390',
    label: 'WELAB BANK LIMITED [390]',
  },
  {
    value: '180',
    label: 'WELLS FARGO BANK, N.A. [180]',
  },
  {
    value: '297',
    label: 'WELLS FARGO BANK, N.A., LONDON BRANCH [297]',
  },
  {
    value: '151',
    label: 'WESTPAC BANKING CORPORATION [151]',
  },
  {
    value: '020',
    label: 'WING LUNG BANK LTD [020]',
  },
  {
    value: '118',
    label: 'WOORI BANK [118]',
  },
  {
    value: 'A05',
    label: 'WOORI BANK, KOREA [A05]',
  },
  {
    value: '547',
    label: 'XIAMEN BANK CO., LTD., XIAMEN [547]',
  },
  {
    value: '387',
    label: 'ZA BANK LIMITED [387]',
  },
  {
    value: 'A04',
    label: 'ZAO INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MOSCOW) [A04]',
  },
  {
    value: '525',
    label: 'ZIBO CITY COMMERCIAL BANK, SHANDONG [525]',
  },
];
