// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneCalendarQuestion = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M10.85 1.25c-3.36 0-5.04 0-6.32.65A6 6 0 0 0 1.9 4.53c-.65 1.28-.65 2.96-.65 6.32v2.3c0 3.36 0 5.04.65 6.32a6 6 0 0 0 2.63 2.63c1.28.65 2.96.65 6.32.65h2.3c3.36 0 5.04 0 6.32-.65a6 6 0 0 0 2.63-2.63c.65-1.28.65-2.96.65-6.32v-2.3c0-3.36 0-5.04-.65-6.32a6 6 0 0 0-2.63-2.63c-1.28-.65-2.96-.65-6.32-.65h-2.3Z" /><path fillRule="evenodd" clipRule="evenodd" d="M8.75 1a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0V1Zm8 0a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0V1Z" /><path fillRule="evenodd" clipRule="evenodd" d="M10.6 8.63c-.24.32-.33.74-.33 1.03a.75.75 0 1 1-1.5-.03c0-.49.14-1.24.62-1.9A3.08 3.08 0 0 1 12 6.55c1.22 0 2.08.43 2.6 1.12.5.64.63 1.42.63 1.97a3.1 3.1 0 0 1-1.4 2.55c-1 .72-1.4 1.35-1.4 2.23a.75.75 0 1 1-1.5 0c.01-1.54.8-2.56 2.02-3.44.67-.49.79-1 .79-1.34 0-.36-.1-.76-.32-1.06-.2-.25-.58-.53-1.41-.53-.8 0-1.2.3-1.4.58ZM10.45 17a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneCalendarQuestion);
export { ForwardRef as IconV2DuotoneCalendarQuestion };