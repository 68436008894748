// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineConversation = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M6.77 1.25h6.46c.81 0 1.47 0 2 .04.55.05 1.03.14 1.47.37.7.36 1.28.93 1.64 1.64.23.44.32.92.37 1.47.04.53.04 1.19.04 2v.73a.75.75 0 0 1-1.5 0v-.7c0-.85 0-1.45-.04-1.9a2.4 2.4 0 0 0-.2-.92 2.25 2.25 0 0 0-.99-.98 2.4 2.4 0 0 0-.91-.21c-.46-.04-1.06-.04-1.91-.04H6.8c-.85 0-1.45 0-1.9.04-.46.04-.72.1-.92.2-.42.22-.77.57-.98.99-.1.2-.17.46-.21.91-.04.46-.04 1.06-.04 1.91v6.45a20.97 20.97 0 0 0 .03.94v.01c.12.43.58.66.99.5h.01l.04-.03.15-.1.58-.42.02-.02.43-.3c.51-.32 1.1-.52 1.7-.57l.52-.01h1.03v-.08c0-.53 0-.98.03-1.34.03-.38.1-.74.27-1.08.26-.52.68-.94 1.2-1.2.34-.17.7-.24 1.08-.27.36-.03.8-.03 1.34-.03h6.66c.53 0 .98 0 1.34.03.38.03.74.1 1.08.27.52.26.94.68 1.2 1.2.17.34.24.7.27 1.08.03.36.03.8.03 1.34v7.2c0 1.65-1.98 2.5-3.18 1.35a3.02 3.02 0 0 0-2.08-.83h-5.32c-.53 0-.98 0-1.34-.03a2.8 2.8 0 0 1-1.08-.27 2.75 2.75 0 0 1-1.2-1.2 2.8 2.8 0 0 1-.27-1.07c-.03-.37-.03-.81-.03-1.35v-2.22H6.82c-.36.04-.71.15-1.02.35l-.35.25h-.01l-.61.46c-.17.11-.34.22-.52.29a2.25 2.25 0 0 1-3.05-2.08l-.01-.75v-6.5c0-.81 0-1.47.04-2 .05-.55.14-1.03.37-1.47.36-.7.93-1.28 1.64-1.64.44-.23.92-.32 1.47-.37.53-.04 1.19-.04 2-.04Zm2.98 15.7c0 .57 0 .95.02 1.25.03.28.07.42.12.51.12.24.3.43.54.55.1.04.23.08.52.1.3.03.68.03 1.25.03h5.3c1.15 0 2.27.45 3.1 1.25.25.23.65.06.65-.28V13.2c0-.57 0-.96-.02-1.25a1.36 1.36 0 0 0-.12-.52 1.25 1.25 0 0 0-.54-.54c-.1-.05-.23-.1-.52-.12-.3-.02-.68-.02-1.25-.02h-6.6c-.57 0-.96 0-1.25.02-.29.03-.43.07-.52.12-.23.12-.42.3-.54.54-.05.1-.1.23-.12.52-.02.3-.02.68-.02 1.25v3.74Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineConversation);
export { ForwardRef as IconV2OutlineConversation };