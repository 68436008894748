export const offPayslipCostTypes = {
  ADDITIONAL_TAX: 'additional_tax',
  BACKGROUND_CHECK: 'background_check',
  BENEFITS: 'benefits',
  EMPLOYER_WORKERS_COMPENSATION: 'employer_workers_compensation',
  HEALTH_CHECK: 'health_check',
  OFF_CYCLE_PAYMENTS_COST: 'off_cycle_payments_cost',
  RECRUITMENT: 'recruitment',
  SPECIAL_TAX_APPLICATION_COST: 'special_tax_application_cost',
  TRAINING: 'training',
  TEAM_DEAL: 'team_deal',
  VISA_AND_IMMIGRATION: 'visa_check',
  MOBILITY_SERVICES: 'mobility_services',
  NON_STANDART_TERMINATION_LEGAL_FEES: 'non_standard_termination_legal_fees',
  OTHER: 'other',
  MOBILITY: 'mobility', // Virtual filter option combining mobility_services and visa_check
};

export const offPayslipCostTypeLabels = {
  [offPayslipCostTypes.ADDITIONAL_TAX]: 'Additional Tax',
  [offPayslipCostTypes.BACKGROUND_CHECK]: 'Background check',
  [offPayslipCostTypes.BENEFITS]: 'Benefits',
  [offPayslipCostTypes.EMPLOYER_WORKERS_COMPENSATION]: 'Employer Workers Compensation',
  [offPayslipCostTypes.HEALTH_CHECK]: 'Health Check',
  [offPayslipCostTypes.OFF_CYCLE_PAYMENTS_COST]: 'Off-cycle payments cost',
  [offPayslipCostTypes.RECRUITMENT]: 'Recruitment',
  [offPayslipCostTypes.SPECIAL_TAX_APPLICATION_COST]: 'Special Tax application cost',
  [offPayslipCostTypes.TRAINING]: 'Training',
  [offPayslipCostTypes.TEAM_DEAL]: 'Team deal',
  [offPayslipCostTypes.VISA_AND_IMMIGRATION]: 'Visa & Immigration',
  [offPayslipCostTypes.MOBILITY_SERVICES]: 'Mobility services',
  [offPayslipCostTypes.NON_STANDART_TERMINATION_LEGAL_FEES]: 'Non-standard termination legal fees',
  [offPayslipCostTypes.OTHER]: 'Other',
};

const removeVirtualFilters = (offPayslipCostType) => !['MOBILITY'].includes(offPayslipCostType);

const offPayslipCostsMovedToServiceCosts = [
  'HEALTH_CHECK',
  'TRAINING',
  'VISA_AND_IMMIGRATION',
  'MOBILITY_SERVICES',
  'NON_STANDART_TERMINATION_LEGAL_FEES',
];

export const offPayslipCostTypesOptions = Object.keys(offPayslipCostTypes)
  .filter(removeVirtualFilters)
  .map((key) => {
    const isMovedCost = offPayslipCostsMovedToServiceCosts.includes(key);
    let label = offPayslipCostTypeLabels[offPayslipCostTypes[key]];
    if (isMovedCost) {
      label += ' (should now be added as a service cost)';
    }
    return {
      value: offPayslipCostTypes[key],
      label,
      disabled: isMovedCost,
    };
  });

export const offPayslipCostsStatuses = /** @type {const} */ ({
  CANCELLED: 'cancelled',
  NOT_INVOICED: 'not_invoiced',
  INVOICED: 'invoiced',
});

export const offPayslipCostsStatusLabels = {
  [offPayslipCostsStatuses.CANCELLED]: 'Canceled',
  [offPayslipCostsStatuses.NOT_INVOICED]: 'Not invoiced',
  [offPayslipCostsStatuses.INVOICED]: 'Invoiced',
};

export const offPayslipCostsStatusesOptions = Object.keys(offPayslipCostsStatusLabels).map(
  (key) => ({
    value: key,
    label: offPayslipCostsStatusLabels[key],
  })
);

export const offPayslipCostsStatusOptions = [
  {
    value: offPayslipCostsStatuses.CANCELLED,
    label: offPayslipCostsStatusLabels[offPayslipCostsStatuses.CANCELLED],
  },
  {
    value: offPayslipCostsStatuses.NOT_INVOICED,
    label: offPayslipCostsStatusLabels[offPayslipCostsStatuses.NOT_INVOICED],
  },
];

export const beneficiaryValues = {
  EMPLOYEE: 'employee',
  COMPANY: 'company',
};
const beneficiaryLabels = {
  EMPLOYEE: 'Employee',
  COMPANY: 'Company',
};
export const beneficiaryOptions = [
  {
    value: beneficiaryValues.EMPLOYEE,
    label: beneficiaryLabels.EMPLOYEE,
  },
  {
    value: beneficiaryValues.COMPANY,
    label: beneficiaryLabels.COMPANY,
  },
];

/** Set of accessors not to be included in additional columns or column
 * configuration list because they're unnecessary and only add noise.
 * */

export const columnAccessorExclusionSet = new Set([
  'currency.code',
  'currency.name',
  'currency.slug',
  'currency.symbol',
  'employment.company.logo',
  'employment.company.name',
  'employment.company.slug',
  'employment.company.status',
  'employment.contract',
  'employment.slug',
  'employment.user.email',
  'employment.user.invitedBy',
  'employment.user.profilePicture',
  'employment.user.role',
  'employment.user.signupSource',
  'employment.user.slug',
  'employment.user.status',
  'company.billingEmail',
  'company.slug',
  'company.logo',
  'company.name',
  'company.registrationNumber',
  'company.status',
  'company.termsOfServiceAcceptedAt',
  'company.vat',
  'slug',
  'remoteInvoice',
  'remoteInvoice.formattedNumber',
  'remoteInvoice.creditNote',
  'remoteInvoice.slug',
  'remoteInvoice.status',
]);
