import { WITHDRAWAL_METHODS_ROUTE } from '@/src/constants/routes';
import type { ScreamingSnakeCaseMapping } from '@/src/types/case';

export type WithdrawalMethodType = 'bank_account' | 'stripe_connect' | 'nium_beneficiary_account';

export const WITHDRAWAL_METHOD_TYPES: ScreamingSnakeCaseMapping<WithdrawalMethodType> = {
  BANK_ACCOUNT: 'bank_account',
  STRIPE_CONNECT: 'stripe_connect',
  NIUM_BENEFICIARY_ACCOUNT: 'nium_beneficiary_account',
} as const;

export const WITHDRAWAL_METHOD_LABELS: Record<WithdrawalMethodType, string> = {
  [WITHDRAWAL_METHOD_TYPES.BANK_ACCOUNT]: 'Bank account',
  [WITHDRAWAL_METHOD_TYPES.STRIPE_CONNECT]: 'Stripe',
  [WITHDRAWAL_METHOD_TYPES.NIUM_BENEFICIARY_ACCOUNT]: 'Remote Payments',
};

export const ADMIN_WITHDRAWAL_METHOD_LABELS: Record<WithdrawalMethodType, string> = {
  [WITHDRAWAL_METHOD_TYPES.BANK_ACCOUNT]: 'Bank account (Wise)',
  [WITHDRAWAL_METHOD_TYPES.STRIPE_CONNECT]: 'Stripe',
  [WITHDRAWAL_METHOD_TYPES.NIUM_BENEFICIARY_ACCOUNT]: 'Remote Payments (Nium)',
};

export const WITHDRAWAL_METHOD_TYPE_LABELS: Record<WithdrawalMethodType, string> = {
  [WITHDRAWAL_METHOD_TYPES.BANK_ACCOUNT]: 'Wise',
  [WITHDRAWAL_METHOD_TYPES.STRIPE_CONNECT]: 'Stripe Connect',
  [WITHDRAWAL_METHOD_TYPES.NIUM_BENEFICIARY_ACCOUNT]: 'Remote Payments',
};

export const ADD_WITHDRAWAL_METHOD_MODAL_ROUTE = `${WITHDRAWAL_METHODS_ROUTE}?modal=new-withdrawal-method-modal`;
