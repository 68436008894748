import { toast } from '@remote-com/norma';

import { Modal } from '@/src/components/Modal';
import { createConditionalNodeProps } from '@/src/domains/contractTemplates/contractTemplates/modals/ConditionalModal/helpers';

import { ConditionalModal } from './ConditionalModal';

const FORM_NAME = 'edit-conditional-form';

const determineRemovalFunction = (editor, conditionalType) => {
  switch (conditionalType) {
    case 'inline':
      return editor.commands.removeInlineConditional;
    case 'block':
      return editor.commands.removeBlockConditional;
    case 'listItem':
      return editor.commands.removeListItemConditional;
    default:
      return null;
  }
};

export const EditConditionalModal = ({
  open,
  hideModal,
  editor,
  updateNodeAttributes,
  currentValues,
  node,
  nodePosition,
}) => {
  const editConditional = (conditionalValues) => {
    const conditionalNodeProps = createConditionalNodeProps(conditionalValues);

    updateNodeAttributes(conditionalNodeProps);

    hideModal();
  };

  const handleRemoveConditional = () => {
    if (currentValues.conditionalType) {
      const removeConditional = determineRemovalFunction(editor, currentValues.conditionalType);

      removeConditional(nodePosition, node);

      hideModal();

      toast.success("You've successfully deleted the conditional.");
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={hideModal}
      headerTitle="Edit conditional"
      formName={FORM_NAME}
      saveButtonText="Save"
      data-testid={FORM_NAME}
    >
      <ConditionalModal
        handleModalSubmit={editConditional}
        editorStorage={editor?.storage}
        currentValues={currentValues}
        formName={FORM_NAME}
        handleRemoveConditional={handleRemoveConditional}
      />
    </Modal>
  );
};
