import { Text, Stack } from '@remote-com/norma';
import styled, { css } from 'styled-components';

export const ProvisionalStartDateWrapper = styled(Stack)`
  ${({ theme }) => css`
    position: absolute;
    background-color: ${theme.colors.blank};
    top: 0px;
    left: 384px;
    width: 304px;
    height: 100%;
    border-left: 1px solid ${theme.colors.grey[200]};
    box-shadow: 8px 0px 12px #0f141914;
    border-radius: 0 5px 0 0;

    @media screen and (max-width: ${theme.breakpoints.medium}) {
      border-radius: ${theme.space[4]}px;
      box-shadow: none;
      border-left: none;
      left: 0px;
      z-index: 19;
      width: 384px;
    }
  `};
`;

export const HeaderWrapper = styled(Stack)`
  height: var(--date-picker-header-height);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
`;

export const HeaderText = styled(Text).attrs({
  color: 'bayoux',
  forwardedAs: 'h3',
  variant: 'smMedium',
})`
  text-transform: uppercase;
  text-align: center;
`;
