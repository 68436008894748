import { toast } from '@remote-com/norma';

import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import { error as errorHandler } from '@/src/helpers/general';

import type { FeatureFlagAttributeValue } from '../config';
import type { FeatureFlagUserProviderProps } from '../context';
import { FeatureFlagProvider } from '../context';

// These are the attributes supported  by the SDK
const ATTRIBUTE = {
  UserCountry: 'userCountry',
  UserType: 'userType',
  CompanySlug: 'companySlug',
} as const;

type SupportedAttribute = (typeof ATTRIBUTE)[keyof typeof ATTRIBUTE];

export interface EmployUserAttributes
  extends Record<SupportedAttribute, FeatureFlagAttributeValue> {}

interface EmployFeatureFlagProviderProps extends Pick<FeatureFlagUserProviderProps, 'config'> {
  /**
   * The account object that will be used to generate user attributes for Feature Flag audience targeting
   */
  account?: UserAccount;
  /** Error that occurred while fetching the feature flag config */
  error?: Error;
}

/**
 * EmployFeatureFlagProvider is a wrapper around the FeatureFlagProvider that provides a pre-defined set of
 * user attributes (see `EmployUserAttributes`) based on the account object.
 * It also provides a userId, which can be the account slug OR any other user identifier (an URL/Query param, a random ID, etc.).
 */
export const EmployFeatureFlagProvider: React.FC<EmployFeatureFlagProviderProps> = ({
  children,
  config,
  account,
  error,
}) => {
  if (error) {
    errorHandler(error, { ownership: ['Development Experience'] });
    toast.error(
      'There has been an error fetching app configuration. Some functionality may be limited.'
    );
  }

  return (
    <FeatureFlagProvider
      config={config}
      userId={account?.slug ?? null}
      attributes={account?.userAttributes ?? {}}
    >
      {children}
    </FeatureFlagProvider>
  );
};
