import { NodeRange } from '@tiptap/pm/model';
import { mergeAttributes, PasteRule, ReactNodeViewRenderer, Node } from '@tiptap/react';

import {
  isSmartFieldAvailable,
  isSmartFieldPlaceholder,
} from '@/src/domains/contracts/ContractsEditor/helpers';

import {
  CONDITIONAL_NODE_TYPES,
  INLINE_CON_REGEX,
  LIST_ITEM_CON_REGEX,
  BLOCK_CON_START_REGEX,
  CONDITIONAL_OPERATORS,
  LIST_ITEM_NODE_TYPES,
} from '../../constants';

import { ConditionalCondition } from './ConditionalCondition';
import {
  inlineConHandler,
  blockConHandler,
  listItemConHandler,
  PARSE_HTML_PRIORITY_HIGHEST,
  findParentListItem,
} from './helpers';

const nodeHasNestedList = (node) => {
  const isListItemNode = node?.type?.name === 'listItem';

  return isListItemNode && LIST_ITEM_NODE_TYPES.includes(node.lastChild.type.name);
};

const createListItemContent = (selection, doc) => {
  // Determine if the parent list item has nested lists
  const parentListItem = findParentListItem(selection);
  const hasNestedLists = nodeHasNestedList(parentListItem.node);

  if (hasNestedLists) {
    return {
      // The 2 offset is to exclude the page break after a list item
      // without this, there will be an extra space after the list items
      slice: doc.slice(parentListItem.from, parentListItem.to - 2),
      from: parentListItem.from - 2,
      to: parentListItem.to,
    };
  }

  return {
    // The 1 offset is to include the wrapping paragraph node
    slice: doc.slice(selection.from - 1, selection.to + 1),
    // The 2 offsets here include wrapping nodes for spacing
    from: parentListItem.from - 2,
    to: parentListItem.to + 2,
  };
};

const renderConditionalResultHTML = (attributes) => {
  if (!attributes.conditionalResult) {
    return {
      'data-conditional-result': 'false',
    };
  }

  return {
    'data-conditional-result': `${attributes.conditionalResult}`,
  };
};

const renderConditionHTML = (attributes) => {
  if (!attributes.conditional) {
    return {};
  }

  return {
    'data-conditional': attributes.conditional,
  };
};

const renderConditionalTypeHTML = (attributes) => {
  if (!attributes.conditionalType) {
    return { 'data-conditional-type': CONDITIONAL_NODE_TYPES.INLINE };
  }

  return {
    'data-conditional-type': `${attributes.conditionalType}`,
  };
};

const parseConditionalResultHTML = (element) =>
  element.getAttribute('data-conditional-result') === 'true';

const parseConditionHTML = (element) => element.getAttribute('data-conditional');
const parseConditionalTypeHTML = (element) => element.getAttribute('data-conditional-type');
const parseFieldsHTML = (element) =>
  element
    .getAttribute('data-conditional')
    .split('__')
    .filter((cond) => !Object.keys(CONDITIONAL_OPERATORS).includes(cond));

const removeBlockOrInlineConditional = (nodePosition, conditionalNode) => {
  return ({ editor, tr, dispatch }) => {
    if (nodePosition === undefined) {
      return null;
    }

    const slice = editor.state.doc.slice(
      nodePosition + 1,
      nodePosition - 1 + conditionalNode.nodeSize
    );

    const transaction = tr.replaceWith(
      nodePosition,
      nodePosition + conditionalNode.nodeSize,
      slice.content
    );

    return dispatch(transaction);
  };
};

function renderText({ node }) {
  return `«CON__${node.attrs.conditional}»`;
}

function parseHTML() {
  return [
    {
      tag: 'span',
      getAttrs: (element) => element.getAttribute('data-type') === this.name,
    },
  ];
}

// Inline has to be separate from conditionals because of the schema requirements
// for prosemirror and treating block and inline nodes in the schema separately
// Additionally, when pasting in content that is inside a block conditional, the regex
// range passed to the handler will be incorrect
export const InlineConditionals = Node.create({
  name: 'inlineConditionalNode',
  group: 'inline',
  inline: true,
  content: 'inline*',
  atom: false,

  addOptions() {
    return {
      ...this.parent?.(),
      // Is view only means the editor is in View Only mode without the ability
      // to edit. Commonly used for sharing documents / viewing by an employee
      isViewOnly: false,
      editableConditionals: false,
    };
  },

  addAttributes() {
    return {
      conditionalResult: {
        default: true,
        renderHTML: renderConditionalResultHTML,
        parseHTML: parseConditionalResultHTML,
      },
      conditional: {
        default: null,
        renderHTML: renderConditionHTML,
        parseHTML: parseConditionHTML,
      },
      fields: {
        default: null,
        parseHTML: parseFieldsHTML,
      },
      operator: {
        default: null,
      },
      content: {
        default: null,
      },
      conditionalType: {
        default: CONDITIONAL_NODE_TYPES.INLINE,
        renderHTML: renderConditionalTypeHTML,
        parseHTML: parseConditionalTypeHTML,
      },
      isSmartFieldAvailable: {
        default: true,
      },
      isSmartFieldPlaceholder: {
        default: false,
      },
      isParentDisabled: {
        default: false,
      },
    };
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: INLINE_CON_REGEX,
        handler: inlineConHandler(this.editor),
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ConditionalCondition);
  },

  renderHTML({ HTMLAttributes }) {
    const isTruthyConditional = HTMLAttributes['data-conditional-result'] === 'true';

    return [
      'span',
      mergeAttributes(
        {
          'data-type': this.name,
          style: `display: ${isTruthyConditional ? 'inline' : 'none'}`,
          'data-conditional-result': isTruthyConditional,
        },
        HTMLAttributes
      ),
      0,
    ];
  },

  parseHTML,

  renderText,

  addCommands() {
    return {
      removeInlineConditional: removeBlockOrInlineConditional,
      addInlineConditional: ({ fields, operator, conditional }) => {
        return ({ state, tr, dispatch, editor }) => {
          const { from, to, empty } = editor.view.state.selection;

          if (empty || !dispatch) {
            return null;
          }

          // Slices will contain all marks and nested content
          const slice = state.doc.slice(from, to);

          const { placeholderSmartFields, smartFieldDefinitions } = editor.storage;
          const isValidSmartField = isSmartFieldAvailable(fields[0], smartFieldDefinitions);
          const isPlaceholder = isSmartFieldPlaceholder(
            fields[0],
            smartFieldDefinitions,
            placeholderSmartFields
          );

          // https://prosemirror.net/docs/ref/#model.Schema.node
          const newNode = editor.schema.node(
            this.name,
            {
              conditional,
              operator,
              fields,
              isSmartFieldAvailable: isValidSmartField,
              isSmartFieldPlaceholder: isPlaceholder,
            },
            slice.content
          );

          // don’t dispatch an empty fragment because this can lead to strange errors
          if (newNode.toString() === '<>') {
            return true;
          }

          tr.replaceWith(from, to, newNode);

          return true;
        };
      },
    };
  },
});

export const ListItemConditionals = Node.create({
  name: 'listItemConditionalNode',
  group: 'block',
  content: 'paragraph block*',
  defining: true,
  atom: false,

  addOptions() {
    return {
      ...this.parent?.(),
      isViewOnly: false,
      editableConditionals: false,
    };
  },

  addAttributes() {
    return {
      conditionalResult: {
        default: true,
        renderHTML: renderConditionalResultHTML,
        parseHTML: parseConditionalResultHTML,
      },
      conditional: {
        default: null,
        renderHTML: renderConditionHTML,
        parseHTML: parseConditionHTML,
      },
      fields: {
        default: null,
        parseHTML: parseFieldsHTML,
      },
      operator: {
        default: null,
      },
      listPosition: {
        default: null,
      },
      content: {
        default: null,
      },
      conditionalType: {
        default: CONDITIONAL_NODE_TYPES.LIST_ITEM,
        renderHTML: renderConditionalTypeHTML,
        parseHTML: parseConditionalTypeHTML,
      },
      isSmartFieldAvailable: {
        default: true,
      },
      isSmartFieldPlaceholder: {
        default: false,
      },
      isParentDisabled: {
        default: false,
      },
    };
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: LIST_ITEM_CON_REGEX,
        handler: listItemConHandler(this.editor),
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ConditionalCondition);
  },

  renderHTML({ HTMLAttributes }) {
    const isTruthyConditional = HTMLAttributes['data-conditional-result'] === 'true';

    if (isTruthyConditional) {
      return ['li', mergeAttributes({ 'data-type': this.name }, HTMLAttributes), 0];
    }

    return [
      'li',
      mergeAttributes({ 'data-type': this.name, style: 'display: none' }, HTMLAttributes),
      0,
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'li',
        getAttrs: (element) => element.getAttribute('data-type') === this.name,
        priority: PARSE_HTML_PRIORITY_HIGHEST,
      },
    ];
  },

  renderText,

  addCommands() {
    return {
      removeListItemConditional: (nodePosition, conditionalNode) => {
        return ({ editor, tr, dispatch }) => {
          if (nodePosition === undefined) {
            return null;
          }

          const slice = editor.state.doc.slice(
            nodePosition + 1,
            nodePosition - 1 + conditionalNode.nodeSize
          );

          const newNode = editor.schema.node(CONDITIONAL_NODE_TYPES.LIST_ITEM, {}, slice.content);

          const transaction = tr.replaceWith(
            nodePosition,
            nodePosition + conditionalNode.nodeSize,
            newNode
          );

          return dispatch(transaction);
        };
      },
      addListItemConditional: ({ fields, operator, conditional }) => {
        return ({ tr, dispatch, editor }) => {
          const { selection, doc } = editor.view.state;

          if (selection.empty || !dispatch) {
            return null;
          }

          const listItemContent = createListItemContent(selection, doc);

          const { placeholderSmartFields, smartFieldDefinitions } = editor.storage;
          const isValidSmartField = isSmartFieldAvailable(fields[0], smartFieldDefinitions);
          const isPlaceholder = isSmartFieldPlaceholder(
            fields[0],
            smartFieldDefinitions,
            placeholderSmartFields
          );

          const newNode = editor.schema.node(
            this.name,
            {
              conditional,
              operator,
              fields,
              isSmartFieldAvailable: isValidSmartField,
              isSmartFieldPlaceholder: isPlaceholder,
            },
            listItemContent.slice.content
          );

          if (newNode.toString() === '<>') {
            return true;
          }

          tr.replaceWith(listItemContent.from, listItemContent.to, newNode);

          return true;
        };
      },
    };
  },
});

export const BlockConditionals = Node.create({
  name: 'blockConditionalNode',
  group: 'block',
  content: 'block+',

  addOptions() {
    return {
      ...this.parent?.(),
      isViewOnly: false,
      editableConditionals: false,
    };
  },

  addAttributes() {
    return {
      conditionalResult: {
        default: true,
        renderHTML: renderConditionalResultHTML,
        parseHTML: parseConditionalResultHTML,
      },
      conditional: {
        default: null,
        renderHTML: renderConditionHTML,
        parseHTML: parseConditionHTML,
      },
      fields: {
        default: null,
        parseHTML: parseFieldsHTML,
      },
      operator: {
        default: null,
      },
      listPosition: {
        default: null,
      },
      content: {
        default: null,
      },
      conditionalType: {
        default: CONDITIONAL_NODE_TYPES.BLOCK,
        renderHTML: renderConditionalTypeHTML,
        parseHTML: parseConditionalTypeHTML,
      },
      isSmartFieldAvailable: {
        default: true,
      },
      isSmartFieldPlaceholder: {
        default: false,
      },
      isParentDisabled: {
        default: false,
      },
    };
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: BLOCK_CON_START_REGEX,
        handler: blockConHandler(this.editor),
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ConditionalCondition);
  },

  renderHTML({ HTMLAttributes }) {
    const isTruthyConditional = HTMLAttributes['data-conditional-result'] === 'true';

    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name, style: `display: ${isTruthyConditional ? 'inline' : 'none'}` },
        HTMLAttributes
      ),
      0,
    ];
  },

  parseHTML,

  renderText,

  addCommands() {
    return {
      removeBlockConditional: removeBlockOrInlineConditional,
      addBlockConditional: ({ fields, operator, conditional }) => {
        return ({ commands, editor, tr, dispatch }) => {
          const { selection } = editor.view.state;
          const rootNode = selection.$from.node(1);

          const selectionHasList =
            rootNode?.hasMarkup(editor.schema.nodes.orderedList) ||
            rootNode?.hasMarkup(editor.schema.nodes.bulletList);

          const { placeholderSmartFields, smartFieldDefinitions } = editor.storage;
          const isValidSmartField = isSmartFieldAvailable(fields[0], smartFieldDefinitions);
          const isPlaceholder = isSmartFieldPlaceholder(
            fields[0],
            smartFieldDefinitions,
            placeholderSmartFields
          );

          if (selectionHasList) {
            const range = new NodeRange(selection.$from, selection.$to, 0);
            const transaction = tr.wrap(range, [
              {
                type: editor.schema.nodes.blockConditionalNode,
                attrs: {
                  conditional,
                  operator,
                  fields,
                  isSmartFieldAvailable: isValidSmartField,
                  isSmartFieldPlaceholder: isPlaceholder,
                },
              },
            ]);

            return dispatch(transaction);
          }

          return commands.wrapIn(this.name, {
            conditional,
            operator,
            fields,
            isSmartFieldAvailable: isValidSmartField,
            isSmartFieldPlaceholder: isPlaceholder,
          });
        };
      },
    };
  },
});
