import { MINUTES_IN_HOUR } from './constants';

/**
 * Convert time or duration to minutes
 * @param {{hours?: number, minutes?: number}} time time
 * @returns {number} the time or duration as minutes
 */

export function getAsMinutes(time) {
  const hours = time.hours ?? 0;
  const minutes = time.minutes ?? 0;

  return hours * MINUTES_IN_HOUR + minutes;
}
