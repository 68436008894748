import { Text, DrawerCenterContent, Box } from '@remote-com/norma';
import { PersonSearchingIllustration } from '@remote-com/norma/illustrations/PersonSearchingIllustration';
import styled from 'styled-components';

// TODO: This is a hack :/
// These shapes should be added to Norma with the ability to customizecolors & width / height
export const GraySemiCircleShape = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 449 450"
    fill="#E3E9EF"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M223.638 203.699V0C164.143 0.527727 107.265 24.4655 65.3817 66.6039C23.4984 108.742 0 165.671 0 225C0 284.329 23.4984 341.258 65.3817 383.396C107.265 425.534 164.143 449.472 223.638 450V246.283C228.933 302.006 254.872 353.758 296.387 391.429C337.902 429.1 392.01 449.984 448.141 450V0C392.013 0.0157355 337.907 20.8969 296.393 58.5643C254.878 96.2318 228.938 147.98 223.638 203.699Z" />
  </svg>
);

export const BoxIllustration = styled(PersonSearchingIllustration)`
  width: 100%;
  height: 'auto';
  z-index: 2;
`;

export const ShapeWrapper = styled(Box).attrs({
  height: '100%',
  width: '100%',
  position: 'absolute',
})`
  max-width: 200px;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const ImageWrapper = styled(Box).attrs({
  display: 'flex',
  flex: 1,
  position: 'relative',
  height: 'auto',
  width: '100%',
})`
  margin: 0 auto;
  min-width: 200px;
  max-width: 200px;
`;

// Remove header height to eliminate scrolling
export const DrawerErrorContent = styled(DrawerCenterContent)`
  height: calc(100vh - 73px);
`;

export const DrawerErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;
