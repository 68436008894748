import PropTypes from 'prop-types';
import { isValidElement } from 'react';

import { ButtonIcon } from '@/src/components/Button';

import {
  ActionItem,
  Actions,
  CardStyled,
  Details,
  DetailsContent,
  IconCollapse,
  IconExpand,
  Summary,
  SummaryContent,
} from './CardCollapsible.styled';

export function CardCollapsible({ summary, actions, children, ...props }) {
  return (
    <CardStyled {...props}>
      <Details>
        <Summary>
          <IconExpand aria-hidden="true" />
          <IconCollapse aria-hidden="true" />
          <SummaryContent>{summary}</SummaryContent>
        </Summary>
        <DetailsContent pb={6}>{children}</DetailsContent>
      </Details>
      {actions?.length > 0 && (
        <Actions>
          {actions.map((action, index) => (
            <ActionItem key={`${action.label}-${index}`} ml={3}>
              {isValidElement(action) ? (
                action
              ) : (
                <ButtonIcon variant="ghost" tone="primary" size="sm" {...action} />
              )}
            </ActionItem>
          ))}
        </Actions>
      )}
    </CardStyled>
  );
}

CardCollapsible.propTypes = {
  summary: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        Icon: PropTypes.elementType.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
      PropTypes.element,
    ])
  ),
};
