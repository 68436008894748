import getConfig from 'next/config';
import type { Dispatch, SetStateAction } from 'react';
import type { UseMutationOptions } from 'react-query';
// eslint-disable-next-line remote/prefer-using-the-data-layer -- data-layer uses axios, and we need to use fetch
import { useMutation } from 'react-query';
import snakecaseKeys from 'snakecase-keys';

import { captureHTTPException } from '@/src/helpers/captureException';

const {
  publicRuntimeConfig: { REMOTE_AI_API_BASE_URL },
} = getConfig();

type UseAIFormAssistantStreamValues = {
  formTitle: string;
  formContext: string;
  formValues: string;
  formField: string;
  prompt: string;
  userInput: string;
};

/**
 * A hook to fetch stream text for a given form field, using AI. It calls `setData` with the stream text as it is being received.
 * It uses `fetch` to call the AI API, as `axios` does not support streaming: https://github.com/axios/axios/issues/5806
 */
const useAIFormAssistantStreamMutation = (
  setData: Dispatch<SetStateAction<string>>,
  options?: UseMutationOptions<Response, Error, UseAIFormAssistantStreamValues>
) => {
  const generateFormFieldContent = (values: UseAIFormAssistantStreamValues) =>
    fetch(`${REMOTE_AI_API_BASE_URL}/api/v1/generate/form-field-content`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(snakecaseKeys(values)),
    }).then((response) => {
      if (!response.ok || !response.body) {
        captureHTTPException({ response });
        throw new Error(response.statusText || 'Failed to generate form field content');
      }

      return response;
    });

  const handleSuccess = async (
    data: Response,
    variables: UseAIFormAssistantStreamValues,
    context: unknown
  ) => {
    if (!data.body) {
      return;
    }

    const reader = data.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let result = '';
    let streamDone = false;

    while (!streamDone) {
      // eslint-disable-next-line no-await-in-loop -- Required to read the stream
      const { value, done } = await reader.read();
      streamDone = done;
      if (done) break;

      result += decoder.decode(value, { stream: true });
      setData(result);
    }

    options?.onSuccess?.(data, variables, context);
  };

  return useMutation({
    mutationFn: generateFormFieldContent,
    ...options,
    onSuccess: handleSuccess,
  });
};

export default useAIFormAssistantStreamMutation;
