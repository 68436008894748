// https://www.notion.so/remotecom/Ecuador-cf0d9a055e5b43faa2ef2fec4e9775fb?pvs=4#318024e408e14dc4a29a276f43ed8bfe
export const ECU_BANK_CODES = [
  { value: '001', label: 'BANCO AMAZONAS' },
  { value: '002', label: 'BANCO BOLIVARIANO' },
  { value: '005', label: 'BANCO COFIEC' },
  { value: '006', label: 'BANCO COMERCIAL DE MANABI' },
  { value: '007', label: 'BANCO DE FOMENTO' },
  { value: '008', label: 'BANCO DE GUAYAQUIL S.A' },
  { value: '009', label: 'BANCO DE LA PRODUCCION' },
  { value: '010', label: 'BANCO DE LOJA' },
  { value: '011', label: 'BANCO DEL AUSTRO' },
  { value: '012', label: 'BANCO DEL LITORAL S.A.' },
  { value: '013', label: 'BANCO DEL PACIFICO' },
  { value: '014', label: 'BANCO GENERAL RUMINAHUI' },
  { value: '015', label: 'BANCO INTERNACIONAL' },
  { value: '016', label: 'LLOYDS BANK (BLSA)' },
  { value: '017', label: 'BANCO PROMERICA' },
  { value: '018', label: 'BANCO MACHALA' },
  { value: '019', label: 'BANCO PICHINCHA' },
  { value: '020', label: 'BANCO SOLIDARIO' },
  { value: '021', label: 'BANCO SUDAMERICANO' },
  { value: '022', label: 'BANCO TERRITORIAL' },
  { value: '023', label: 'BANCO UNIBANCO SA.' },
  { value: '024', label: 'COOP.  AHORRO Y CRED. DE LA PEQUEÑA EMPRESA DE PASTAZA' },
  { value: '025', label: 'COOP. AHORRO Y CREDITO 23 DE JULIO' },
  { value: '026', label: 'COOP. AHORRO Y CREDITO 29 DE OCTUBRE' },
  { value: '027', label: 'COOP. AHORRO Y CREDITO ANDALUCIA' },
  { value: '028', label: 'COOP. AHORRO Y CREDITO COTOCOLLAO' },
  { value: '029', label: 'COOP. AHORRO Y CREDITO DESARROLLO PUEBLOS' },
  { value: '030', label: 'COOP. AHORRO Y CREDITO EL SAGRARIO' },
  { value: '031', label: 'COOP. AHORRO Y CREDITO GUARANDA LTDA.' },
  { value: '032', label: 'COOP. AHORRO Y CREDITO NACIONAL' },
  { value: '033', label: 'COOP. AHORRO Y CREDITO OSCUS' },
  { value: '034', label: 'COOP. AHORRO Y CREDITO PABLO MUÑOZ VEGA.' },
  { value: '035', label: 'COOP. AHORRO Y CREDITO PROGRESO' },
  { value: '036', label: 'COOP. AHORRO Y CREDITO RIOBAMBA' },
  { value: '038', label: 'COOP. AHORRO Y CREDITO TULCAN' },
  { value: '040', label: 'COOP. CACPECO  LTDA' },
  { value: '041', label: 'COOP. DE AHORRO Y CREDITO ATUNTAQUI LTDA.' },
  { value: '042', label: 'COOP. PREVISION AHORRO Y  DESARROLLO' },
  { value: '043', label: 'MUTUALISTA AMBATO' },
  { value: '044', label: 'MUTUALISTA AZUAY' },
  { value: '047', label: 'MUTUALISTA IMBABURA' },
  { value: '049', label: 'MUTUALISTA PICHINCHA' },
  { value: '051', label: 'BANCO PROCREDIT ' },
  { value: '052', label: 'BANCO DELBANK' },
  { value: '053', label: 'BANCO DE LA VIVIENDA' },
  { value: '054', label: 'COOP.  ALIANZA DEL VALLE' },
  { value: '055', label: 'COOP. LA  DOLOROSA' },
  { value: '056', label: 'COOP. JUVENTUD ECUATORIANA PROGRESISTA' },
  { value: '057', label: 'COOP. SAN FRANCISCO' },
  { value: '058', label: 'COOP. 9 DE OCTUBRE' },
  { value: '059', label: 'COOP. COOPMEGO' },
  { value: '061', label: 'COOP. PADRE JULIAN LORENTE' },
  { value: '062', label: 'COOP. 11 DE JUNIO' },
  { value: '063', label: 'COOP. SANTA ROSA' },
  { value: '064', label: 'COOP. CHONE LTDA' },
  { value: '065', label: 'COOP. COMERCIO' },
  { value: '066', label: 'COOP. SANTA ANA' },
  { value: '067', label: 'COOP. CALCETA' },
  { value: '068', label: 'COOP. JESUS DEL GRAN PODER' },
  { value: '069', label: 'PACIFICARD' },
  { value: '070', label: 'DINERS CLUB' },
  { value: '071', label: 'BANCO CAPITAL S.A.' },
  { value: '072', label: 'COOP. AHORRO Y CREDITO CAMARA DE COMERCIO DE QUITO LTDA.' },
  { value: '073', label: 'FINANCIERA - FINANCORP' },
  { value: '074', label: 'COOP. DE AHORRO Y CREDITO SAN FRANCISCO DE ASIS LTDA.' },
  { value: '075', label: 'COOP. DE AHORRO Y CREDITO JARDIN AZUAYO' },
  { value: '076', label: 'COOP. DE AHORRO Y CREDITO DE LA PEQUEÑA EMPRESA CACPE BIBLIAN LTDA.' },
  { value: '077', label: 'COOP. DE AHORRO Y CREDITO SAN JOSE LTDA.' },
  { value: '078', label: 'COOP. DE AHORRO Y CREDITO 15 DE ABRIL LTDA.' },
  { value: '079', label: 'COOP.DE AHORRO Y CREDITO SAN PEDRO DE TABOADA LTDA.' },
  { value: '080', label: 'COOP. DE AHORRO Y CREDITO CAMARA DE COMERCIO DE AMBATO LTDA.' },
  { value: '081', label: 'COOP. DE AHORRO Y CREDITO MUSHUC RUNA LTDA.' },
  { value: '082', label: 'COOP. DE AHORRO Y CREDITO EDUC.DEL TUNGURAHUA LTDA.' },
  { value: '083', label: 'COOPERATIVA DE AHORRO Y CREDITO ARTESANOS LTDA.' },
  { value: '084', label: 'COOPERATIVA DE AHORRO Y CREDITO SANTA ANITA LTDA.' },
  {
    value: '085',
    label: 'COOP. AHORRO. Y CREDI. MUJERES UNIDAS TANTANAKUSHKA WARMIKUNAPAK CACMU LTDA.',
  },
  { value: '086', label: 'COOP. AHORRO Y CREDITO ACCION RURAL' },
  { value: '087', label: 'BANCO PARA LA ASISTENCIA COMUNITARIA FINCA S.A.' },
  { value: '088', label: 'INTERDIN S.A.' },
];
