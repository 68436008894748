/* eslint-disable no-underscore-dangle */

import { subDays } from 'date-fns';

import type {
  BillingDocument,
  BillingDocumentBreakdownRow,
  BillingDocumentBreakdownTotals,
  ContractorPaymentsInvoiceBreakdownRow,
} from '@/src/api/config/employ/billingDocuments.types';
import type {
  InvoiceReportTableItem,
  InvoiceReport,
  InvoiceReportData,
  InvoiceReportAdjustment,
} from '@/src/api/config/employ/invoiceReport.types';
import type { Currency, ExMoneyValue } from '@/src/api/config/employ/shared.types';
import { billableParentResources } from '@/src/domains/billables/constants';
import { FILTER_TYPES } from '@/src/domains/eventsTimeline/constants';
import type { EmployeeInvoiceStatus } from '@/src/domains/invoices/constants';
import {
  propertiesInLocalCurrency,
  employeeInvoiceStatuses,
  employeeInvoiceDataSources,
  employeeInvoiceDisplayTypeLabels,
  employeeInvoiceDisplayTypes,
} from '@/src/domains/invoices/constants';
import {
  getAggregatedTaxationItems,
  isExMoneyValue,
  sumInvoiceTaxationItems,
} from '@/src/domains/invoices/helpers';
import type { BillingDocumentBreakdownRowWithCents } from '@/src/domains/invoices/types';
import { paymentStatus } from '@/src/domains/payments/constants';
import { PAYABLE_STATUSES } from '@/src/domains/payments/employer/OutstandingPaymentDetails/constants';
import { getRefundsTotal } from '@/src/domains/payments/shared/helpers';
import { PAYMENT_COMPLETED_STATUSES } from '@/src/domains/payments/status';
import type { OutstandingPayment, OutstandingRefund } from '@/src/domains/payments/types';
import { convertToCents } from '@/src/helpers/currency';
import {
  compareDates,
  formatYearMonthDay,
  getTodayAsUTCWithDefaultFormat,
} from '@/src/helpers/date';
import type { $TSFixMe } from '@/types';
/**
 * Type-guard for BillingDocument - 'originatingSystem' is a field unique to BillingDocument
 */
export function isBillingDocument(
  invoiceReport: InvoiceReport | InvoiceReportTableItem | BillingDocument
): invoiceReport is BillingDocument {
  return invoiceReport && 'originatingSystem' in invoiceReport;
}

/**
 * An invoice report will be considered a CN if:
 * 1. total amount is negative
 * 3. or it's a partial for a service invoice
 */
function _getIsCreditNote(invoiceReport: InvoiceReport | InvoiceReportTableItem | BillingDocument) {
  if (isBillingDocument(invoiceReport)) {
    return invoiceReport?.resourceType === 'credit_memo';
  }

  const isServiceInvoicePartial =
    invoiceReport?.dataSource === employeeInvoiceDataSources.REMOTE_PAID_SERVICES &&
    invoiceReport?.isPartialCorrection;

  return (invoiceReport?.totalInvoiceAmount ?? 0) < 0 || isServiceInvoicePartial;
}

/**
 * Parsing payment status for billing documents
 * Billing documents updates async so we need to check for amount due to be 0
 * to determine if a payment is completed
 */
const getBillingDocumentOutstandingPayment = (
  outstandingPayments: OutstandingPayment[] = [],
  invoicePaymentDueAmount: number
): OutstandingPayment | undefined => {
  // For now we assume there is only one outstanding payment
  const outstandingPayment = outstandingPayments[0];
  if (!outstandingPayment) return undefined;

  let outstandingPaymentStatus = outstandingPayment.status;

  if (invoicePaymentDueAmount === 0) {
    outstandingPaymentStatus = paymentStatus.COMPLETED;
  } else if (outstandingPaymentStatus === paymentStatus.COMPLETED) {
    outstandingPaymentStatus = paymentStatus.COLLECTING;
  }

  return {
    ...outstandingPayment,
    status: outstandingPaymentStatus,
  };
};

/**
 * Creates an outstanding refund object from a billing document,
 * compatible with both Legacy and Zuora invoices for consistent use across the platform.
 *
 * @param {BillingDocument} billingDocument - The billing document to extract refund information from.
 * @returns {OutstandingRefund} - The constructed outstanding refund object.
 */
const getBillingDocumentOutstandingRefund = (billingDocument: BillingDocument) => {
  if (!_getIsCreditNote(billingDocument)) {
    return undefined;
  }

  const {
    legacyOutstandingRefund,
    amount,
    amountApplied,
    amountRefunded,
    originatingSystem,
    company,
    isCompanyMigrated,
  } = billingDocument;

  const amountInCents = convertToCents(amount?.amount) ?? 0;
  const amountAppliedInCents = convertToCents(amountApplied?.amount) ?? 0;
  const amountRefundedInCents = convertToCents(amountRefunded?.amount) ?? 0;

  const balanceCreditAvailable = amountInCents - (amountAppliedInCents + amountRefundedInCents);

  if (isCompanyMigrated && originatingSystem === 'legacy') {
    const outstandingRefund: Partial<OutstandingRefund> = {
      amount: balanceCreditAvailable,
      company,
      slug: '',
      status: balanceCreditAvailable === 0 ? paymentStatus.CLOSED : paymentStatus.CREDIT_DUE,
    };

    return outstandingRefund;
  }

  let outstandingRefundStatus = legacyOutstandingRefund?.status;

  if (balanceCreditAvailable === 0) {
    outstandingRefundStatus = paymentStatus.CLOSED;
  } else {
    outstandingRefundStatus = paymentStatus.CREDIT_DUE; // "Credit available"
  }

  return {
    ...legacyOutstandingRefund,
    status:
      originatingSystem === 'legacy' ? legacyOutstandingRefund?.status : outstandingRefundStatus,
  };
};

/**
 * Converts every value on the breakdown totals to cents
 */
const getBreakdownTotalValuesInCents = (totals: BillingDocumentBreakdownTotals) => {
  if (!totals) return;

  return Object.entries(totals).reduce((totalsConvertedInCents, [key, value]) => {
    totalsConvertedInCents[key as keyof BillingDocumentBreakdownTotals] = convertToCents(
      isExMoneyValue(value) ? value?.amount : value
    ) as number;
    return totalsConvertedInCents;
  }, {} as BillingDocumentBreakdownTotals<number>);
};

const convertExMoneyValueToCents = (value?: ExMoneyValue) => {
  return {
    currency: value?.currency,
    amount: convertToCents(value?.amount as string) as number,
  };
};

const setBreakdownRowLocalCurrency = (row: BillingDocumentBreakdownRowWithCents) => {
  const localCurrency = propertiesInLocalCurrency
    .map((prop) => row[`${prop}LocalAmount` as keyof typeof row])
    .filter(isExMoneyValue);

  if (localCurrency.length === 0) {
    return row;
  }

  return {
    ...row,
    localCurrency: { code: localCurrency?.[0]?.currency } as Currency,
  };
};

/**
 * Converts every key on the breakdown row to cents when the key contains 'amount'
 */

const transformBreakdownRow = (
  breakdownRows?: BillingDocumentBreakdownRow[] | ContractorPaymentsInvoiceBreakdownRow[]
): BillingDocumentBreakdownRowWithCents[] | undefined => {
  if (!breakdownRows) return;

  return breakdownRows
    .map((breakdown) => {
      const keyNames = Object.entries(breakdown);

      const breakdownConvertedInCents: BillingDocumentBreakdownRowWithCents = keyNames.reduce(
        (breakdownRowWithConvertedAmounts, [key, value]) => {
          if (key.match(/amount/i) && isExMoneyValue(value)) {
            breakdownRowWithConvertedAmounts[key] = convertExMoneyValueToCents(value);
          } else {
            breakdownRowWithConvertedAmounts[key] = value;
          }

          return breakdownRowWithConvertedAmounts;
        },
        {} as $TSFixMe
      );

      return {
        ...breakdownConvertedInCents,
        // in legacy it's used `totalInvoiceAmount`
        // in zuora we use `totalAmount`
        totalInvoiceAmount: breakdownConvertedInCents.totalAmount,
      };
    })
    .map(setBreakdownRowLocalCurrency);
};

const mapEmploymentReportData = <
  T extends InvoiceReportData | BillingDocumentBreakdownRow | InvoiceReportAdjustment
>(
  data?: T[]
): T[] | undefined => {
  return data?.map((breakdown) => {
    return {
      ...breakdown,
      ...(breakdown.employment
        ? {
            employment: {
              ...breakdown.employment,
              name:
                'snapshot' in breakdown.employment
                  ? breakdown.employment.snapshot?.name
                  : breakdown.employment.user?.name,
            },
          }
        : {}),
    };
  });
};

// Contractor Payments invoices require special handling as their breakdown rows are grouped by contractor
// A single contractor can have multiple invoices listed in contractorBillableItems
// Each contractor invoice should be displayed as an individual row in the invoice breakdowns table
const mapContractorPaymentsEmploymentReportData = <T extends BillingDocumentBreakdownRow>(
  data?: T[]
): ContractorPaymentsInvoiceBreakdownRow[] | undefined => {
  return mapEmploymentReportData(data)?.flatMap(
    (breakdown) =>
      breakdown.meta?.contractorBillableItems?.map((item) => {
        const { country, type, employment } = breakdown;

        return {
          country,
          type,
          employment,
          totalAmount: item.totalAmount,
          contractorBillableItem: {
            ...item,
            // Convert billable item amounts to cents so that the money formatting is consistent
            taxAmount: convertExMoneyValueToCents(item.taxAmount),
            amount: convertExMoneyValueToCents(item.amount),
            totalAmount: convertExMoneyValueToCents(item.totalAmount),
          },
        };
      }) ?? []
  );
};

function getBillingDocumentEmployeeName(billingDocument: BillingDocument) {
  return billingDocument?.breakdownRows?.[0]?.employment?.snapshot?.name || null;
}

function getInvoiceReportCommonFields(
  invoiceReport: InvoiceReport | InvoiceReportTableItem | BillingDocument
) {
  if (isBillingDocument(invoiceReport)) {
    const employeeName = getBillingDocumentEmployeeName(invoiceReport);
    const isContractorPaymentsInvoice =
      invoiceReport.invoiceCode === employeeInvoiceDataSources.CONTRACTOR_PAYMENTS;

    return {
      actualsInvoiceReports: invoiceReport?.reconciliationBillingDocuments,
      actualsInvoiceSlug: null,
      adminNote: invoiceReport?.adminNote,
      companyInvoiceReportData: undefined,
      correctedInvoiceReport: invoiceReport?.correctedBillingDocument,
      correctingInvoiceReports: invoiceReport?.correctionBillingDocuments
        ? invoiceReport?.correctionBillingDocuments.map((doc) => ({
            ...doc,
            originatingSystem: 'zuora' as BillingDocument['originatingSystem'],
            isPartialCorrection: true,
            isCorrection: true,
          }))
        : undefined,
      correctionDescription: null,
      correctionPrimaryReason: null,
      correctionSecondaryReason: null,
      correctionSupportingLink: null,
      customerNote: invoiceReport?.customerNote,
      employeeName,
      employmentInvoiceReportData: undefined,
      estimatesInvoiceReport: invoiceReport?.prefundingBillingDocument,
      estimatesInvoiceSlug: invoiceReport?.prefundingBillingDocument?.slug,
      invoiceReportTaxes: [],
      invoiceDescription: null,
      invoiceEarliestDate: null,
      invoiceGroup: null,
      invoiceTotals: getBreakdownTotalValuesInCents(invoiceReport?.breakdownTotals),
      invoiceReportAdjustments: undefined,
      invoiceUpdatedAt: null,
      isEdited: false,
      isManualImport: false,
      isPartialCorrection: invoiceReport?.isCorrection,
      isRebill: false,
      rebilledInvoiceReport: null,
      rebillingInvoiceReport: null,
      suggestedDueDate: null,
      supplierCustomerDetail: null,
      taxSummary: undefined,

      // Section Fields From Billing Documents
      isCorrection: invoiceReport?.isCorrection,
      invoiceCompanyName: invoiceReport?.company?.name,
      invoiceCompanySlug: invoiceReport?.company?.slug,
      invoiceCurrency: invoiceReport?.amount?.currency,
      invoiceDataSource: invoiceReport?.invoiceCode,
      invoiceInsertedAt: invoiceReport?.insertedAt,
      invoicePeriod: invoiceReport?.invoicePeriod,
      invoicePreTaxAmount: convertToCents(invoiceReport?.preTaxAmount?.amount) as number,
      // Used for Global Payroll invoices
      invoiceLegalEntity: invoiceReport?.legalEntity,
      invoiceRemoteEntity: invoiceReport?.remoteLegalEntity?.name,
      remoteEntity: invoiceReport?.remoteLegalEntity,
      // BillingDocuments for legacy invoices and credit notes provide the invoice report slug as resourceIdentifier
      invoiceReportSlug:
        invoiceReport?.originatingSystem === 'legacy'
          ? invoiceReport?.resourceIdentifier
          : invoiceReport?.slug,
      invoiceStatus: employeeInvoiceStatuses.FINAL,
      invoiceTaxTotal: convertToCents(invoiceReport?.totalTaxAmount?.amount) as number,
      estimateInvoiceTaxTotal: sumInvoiceTaxationItems(
        invoiceReport?.invoiceOverviewTotals ?? [],
        true
      ),
      invoiceTotalAmount: convertToCents(invoiceReport?.amount?.amount) as number,
      invoiceOverviewTotals: invoiceReport?.invoiceOverviewTotals ?? [],
      originatingSystem: invoiceReport?.originatingSystem,
      resourceType: invoiceReport?.resourceType,
      resourceIdentifier: invoiceReport?.resourceIdentifier,
      zuoraFileId: invoiceReport?.zuoraFileId,
      billingDocumentBreakdowns: transformBreakdownRow(
        isContractorPaymentsInvoice
          ? mapContractorPaymentsEmploymentReportData(invoiceReport.breakdownRows)
          : (mapEmploymentReportData(invoiceReport?.breakdownRows) as BillingDocumentBreakdownRow[])
      ),
      zuoraPlatformLink: invoiceReport?.zuoraPlatformLink,
      riskReserveSlug: invoiceReport?.billableItems?.find(
        (billableItem) => billableItem.parentResource === billableParentResources.RISK_RESERVE
      )?.parentResourceSlug,
      isCompanyMigrated: invoiceReport?.isCompanyMigrated,
    };
  }

  /**
   * Type-guard: Checking for a field of InvoiceReport to ensure
   * the TS compiler assigns the correct type inside of the block.
   *
   * More details on: https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/19950#note_1586154620
   */
  if (invoiceReport && 'employmentInvoiceReportData' in invoiceReport) {
    return {
      actualsInvoiceReports: null,
      actualsInvoiceSlug: invoiceReport?.actualsInvoiceReportSlug,
      adminNote: invoiceReport?.adminNote,
      companyInvoiceReportData: invoiceReport?.companyInvoiceReportData,
      correctedInvoiceReport: invoiceReport?.correctedInvoiceReport,
      correctingInvoiceReports: invoiceReport?.correctingInvoiceReports,
      correctionDescription: invoiceReport?.correctionDescription,
      correctionPrimaryReason: invoiceReport?.correctionPrimaryReason,
      correctionSecondaryReason: invoiceReport?.correctionSecondaryReason,
      correctionSupportingLink: invoiceReport?.correctionSupportingLink,
      customerNote: invoiceReport?.customerNote,
      employeeName: invoiceReport?.employeeName,
      employmentInvoiceReportData: mapEmploymentReportData(
        invoiceReport?.employmentInvoiceReportData
      ),
      estimatesInvoiceReport: invoiceReport?.estimatesInvoiceReport,
      estimatesInvoiceSlug: invoiceReport?.estimatesInvoiceReportSlug,
      invoiceCompanyName: invoiceReport?.company?.name,
      invoiceCompanySlug: invoiceReport?.company?.slug,
      invoiceCurrency: invoiceReport?.invoiceCurrency,
      invoiceDataSource: invoiceReport?.dataSource,
      invoiceDescription: invoiceReport?.description,
      invoiceEarliestDate: invoiceReport?.earliestInvoiceDate,
      invoiceGroup: invoiceReport?.invoiceGroup,
      invoiceInsertedAt: invoiceReport?.insertedAt,
      invoicePeriod: invoiceReport?.invoicePeriod,
      invoicePreTaxAmount: invoiceReport?.preTaxAmount,
      invoiceRemoteEntity: invoiceReport?.legalEntity?.name,
      remoteEntity: invoiceReport?.legalEntity,
      invoiceReportAdjustments: mapEmploymentReportData(invoiceReport?.invoiceReportAdjustments),
      invoiceReportSlug: invoiceReport?.slug,
      invoiceReportTaxes: (invoiceReport?.invoiceReportTaxGroups ?? [])
        .map((taxGroup) => taxGroup?.invoiceReportTaxes ?? [])
        .flat(),
      invoiceStatus: invoiceReport?.status,
      invoiceTaxTotal: invoiceReport?.taxTotal,
      estimateInvoiceTaxTotal: invoiceReport?.estimatesInvoiceReport?.taxTotal ?? 0,
      invoiceTotalAmount: invoiceReport?.totalInvoiceAmount,
      invoiceTotals: invoiceReport?.totals ?? {},
      invoiceUpdatedAt: invoiceReport?.updatedAt,
      isCorrection: invoiceReport?.hasCorrectedInvoiceReport,
      isEdited: invoiceReport?.isEdited,
      isManualImport: invoiceReport?.isManualImport,
      isPartialCorrection: invoiceReport?.isPartialCorrection,
      isRebill: invoiceReport?.isRebill,
      originatingSystem: 'legacy', // BillingDocument only
      invoiceOverviewTotals: [], // BillingDocument only
      rebilledInvoiceReport: invoiceReport?.rebilledInvoiceReport,
      rebillingInvoiceReport: invoiceReport?.rebillingInvoiceReport,
      suggestedDueDate: invoiceReport?.suggestedDueDate,
      supplierCustomerDetail: invoiceReport?.supplierCustomerDetail,
      billingDocumentBreakdowns: undefined,
      taxSummary: invoiceReport?.taxSummary,
      zuoraPlatformLink: undefined,
      riskReserveSlug: invoiceReport?.riskReserveSlug,
      isCompanyMigrated: undefined,
    };
  }

  return {
    actualsInvoiceReports: null,
    actualsInvoiceSlug: null,
    adminNote: null,
    companyInvoiceReportData: undefined,
    correctedInvoiceReport: null,
    correctingInvoiceReports: undefined,
    correctionDescription: null,
    correctionPrimaryReason: null,
    correctionSecondaryReason: null,
    correctionSupportingLink: null,
    customerNote: null,
    employeeName: invoiceReport?.employeeName,
    employmentInvoiceReportData: undefined,
    estimatesInvoiceReport: null,
    estimatesInvoiceSlug: null,
    invoiceCompanyName: invoiceReport?.company?.name,
    invoiceCompanySlug: invoiceReport?.company?.slug,
    invoiceCurrency: invoiceReport?.invoiceCurrency,
    invoiceDataSource: invoiceReport?.dataSource,
    invoiceDescription: invoiceReport?.description,
    invoiceEarliestDate: null,
    invoiceGroup: null,
    invoiceInsertedAt: invoiceReport?.insertedAt,
    invoicePeriod: invoiceReport?.invoicePeriod,
    invoicePreTaxAmount: invoiceReport?.preTaxAmount,
    invoiceRemoteEntity: invoiceReport?.legalEntity?.name,
    remoteEntity: invoiceReport?.legalEntity,
    invoiceReportAdjustments: undefined,
    invoiceReportSlug: invoiceReport?.slug,
    invoiceReportTaxes: (invoiceReport?.invoiceReportTaxGroups ?? [])
      .map((taxGroup) => taxGroup?.invoiceReportTaxes ?? [])
      .flat(),
    invoiceStatus: invoiceReport?.status,
    invoiceTaxTotal: invoiceReport?.taxTotal,
    estimateInvoiceTaxTotal: 0,
    invoiceTotalAmount: invoiceReport?.totalInvoiceAmount,
    invoiceTotals: invoiceReport?.totals ?? {},
    invoiceUpdatedAt: invoiceReport?.updatedAt,
    isCorrection: invoiceReport?.hasCorrectedInvoiceReport,
    isEdited: invoiceReport?.isEdited,
    isManualImport: invoiceReport?.isManualImport,
    isPartialCorrection: invoiceReport?.isPartialCorrection,
    isRebill: invoiceReport?.isRebill,
    originatingSystem: 'legacy', // BillingDocument only
    invoiceOverviewTotals: [], // BillingDocument only
    rebilledInvoiceReport: null,
    rebillingInvoiceReport: null,
    suggestedDueDate: null,
    supplierCustomerDetail: null,
    billingDocumentBreakdowns: undefined,
    taxSummary: invoiceReport?.taxSummary,
    zuoraPlatformLink: undefined,
    isCompanyMigrated: undefined,
  };
}

/**
 * Get a normalized structure from either an invoice report or a billing document
 * @param invoiceReport - The invoice report/billing document to get the fields for
 * @returns The fields from the invoice report/billing document
 */
export const getInvoiceReportFields = (
  invoiceReport: InvoiceReport | InvoiceReportTableItem | BillingDocument
) => {
  const commonFields = getInvoiceReportCommonFields(invoiceReport);

  if (isBillingDocument(invoiceReport)) {
    const paymentDueAmount = convertToCents(invoiceReport?.amountDue?.amount) as number;
    const paymentCreditedAmount = convertToCents(invoiceReport?.amountCredited?.amount) as number;
    const paymentPaidAmount = convertToCents(invoiceReport?.amountPaid?.amount) as number;
    const paymentAmountToBePaid =
      commonFields?.invoiceTotalAmount && paymentCreditedAmount
        ? commonFields.invoiceTotalAmount - paymentCreditedAmount
        : null;

    const paymentTotalAmount = convertToCents(invoiceReport?.amount?.amount) as number;
    const paymentCreditApplied = convertToCents(invoiceReport?.amountApplied?.amount) as number;
    const paymentRefunded = convertToCents(invoiceReport?.amountRefunded?.amount) as number;
    const paymentCreditAvailable = paymentTotalAmount - (paymentCreditApplied + paymentRefunded);

    const invoiceOutstandingPayment = getBillingDocumentOutstandingPayment(
      invoiceReport?.outstandingPayments,
      paymentDueAmount
    );

    const invoiceOutstandingRefund = getBillingDocumentOutstandingRefund(invoiceReport);

    const aggregatedTaxationItems = getAggregatedTaxationItems(
      invoiceReport?.invoiceOverviewTotals
    );

    return {
      ...commonFields,
      creditNoteSlug: null,
      invoiceFiles: invoiceReport?.originatingSystem === 'legacy' ? invoiceReport.legacyFiles : [],
      invoiceOutstandingPayment,
      invoiceOutstandingRefund,
      rebillOriginalInvoiceSlug: null,
      rebillSlug: null,
      // Section Fields From Billing Documents
      ...(_getIsCreditNote(invoiceReport as BillingDocument)
        ? {
            documentName: 'Credit note',
            isCreditNote: true,
          }
        : { documentName: 'Invoice', isCreditNote: false }),
      documentNumber: invoiceReport?.invoiceNumber,
      finalizedAt: invoiceReport?.issueDate,
      invoiceSlug: invoiceReport?.slug,
      paymentDueDate: invoiceReport?.dueDate,
      paymentDueAmount,
      paymentCreditedAmount,
      paymentPaidAmount,
      paymentAmountToBePaid,
      paymentRefunded,
      paymentCreditApplied,
      paymentCreditAvailable,
      aggregatedTaxationItems,
      creditApplicationTotals: invoiceReport?.creditApplicationTotals ?? [],
      creditMemoApplications: invoiceReport?.creditMemoApplications ?? [],
      legacyFonoaInvoiceId: invoiceReport?.legacyFonoaInvoiceId,
      isCompanyMigrated: invoiceReport?.isCompanyMigrated,
    };
  }

  if (_getIsCreditNote(invoiceReport as InvoiceReport)) {
    const invoiceOutstandingPayment = invoiceReport?.creditNote?.outstandingPayment;
    const invoiceOutstandingRefund = invoiceReport?.creditNote?.outstandingRefund;

    return {
      ...commonFields,
      creditNoteSlug: invoiceReport?.creditNote?.slug,
      documentName: 'Credit note',
      documentNumber: invoiceReport?.creditNote?.formattedNumber,
      finalizedAt: invoiceReport?.creditNote?.insertedAt
        ? formatYearMonthDay(invoiceReport.creditNote.insertedAt) // normalizing with invoice finalizedAt format
        : undefined,
      invoiceFiles: invoiceReport?.creditNote?.files,
      invoiceOutstandingPayment,
      invoiceOutstandingRefund,
      invoiceSlug: null,
      isCreditNote: true,
      rebillOriginalInvoiceSlug: null,
      rebillSlug: null,
      paymentDueDate: null,
      paymentCreditedAmount: null,
      paymentDueAmount: PAYMENT_COMPLETED_STATUSES.includes(
        invoiceOutstandingRefund?.status as $TSFixMe
      )
        ? 0
        : invoiceOutstandingRefund?.amount,
      paymentPaidAmount: null,
      paymentAmountToBePaid: null,
      aggregatedTaxationItems: [],
      creditApplicationTotals: [] as BillingDocument['creditApplicationTotals'],
      creditMemoApplications: [] as BillingDocument['creditMemoApplications'],
      paymentRefunded: 0,
      paymentCreditApplied: 0,
      paymentCreditAvailable: 0,
      legacyFonoaInvoiceId: null,
      isCompanyMigrated: null,
    };
  }

  const invoiceOutstandingPayment = invoiceReport?.remoteInvoice?.outstandingPayment;
  const invoiceOutstandingRefund = invoiceReport?.remoteInvoice?.outstandingRefund;

  return {
    ...commonFields,
    creditNoteSlug: null,
    documentName: 'Invoice',
    documentNumber: invoiceReport?.remoteInvoice?.formattedNumber,
    finalizedAt: invoiceReport?.remoteInvoice?.finalizedAt,
    invoiceFiles: invoiceReport?.remoteInvoice?.files,
    invoiceOutstandingPayment,
    invoiceOutstandingRefund,
    invoiceSlug: invoiceReport?.remoteInvoice?.slug,
    isCreditNote: false,
    rebillOriginalInvoiceSlug: invoiceReport?.remoteInvoice?.rebillOriginalRemoteInvoiceSlug,
    rebillSlug: invoiceReport?.remoteInvoice?.rebillSlug,
    paymentDueDate: invoiceOutstandingPayment?.dueDate,
    paymentDueAmount: PAYMENT_COMPLETED_STATUSES.includes(
      invoiceOutstandingPayment?.status as $TSFixMe
    )
      ? 0
      : invoiceOutstandingPayment?.amount,
    paymentCreditedAmount: getRefundsTotal(invoiceOutstandingPayment?.creditedOutstandingRefunds),
    paymentPaidAmount: null,
    paymentAmountToBePaid: invoiceOutstandingPayment?.amount,
    aggregatedTaxationItems: [],
    creditApplicationTotals: [] as BillingDocument['creditApplicationTotals'],
    creditMemoApplications: [] as BillingDocument['creditMemoApplications'],
    paymentRefunded: 0,
    paymentCreditApplied: 0,
    paymentCreditAvailable: 0,
    legacyFonoaInvoiceId: null,
    isCompanyMigrated: null,
  };
};

export function getInvoiceReportFlags(
  invoiceReport: InvoiceReport | InvoiceReportTableItem | BillingDocument
) {
  const invoiceReportFields = getInvoiceReportFields(invoiceReport);
  const isBillingDocumentResponse = isBillingDocument(invoiceReport);

  const {
    invoiceDataSource,
    invoiceTotalAmount,
    isEdited,
    isManualImport,
    isRebill,
    isCorrection,
    isPartialCorrection,
    isCreditNote,
    invoiceStatus,
    invoiceOutstandingRefund,
    invoiceOutstandingPayment,
    companyInvoiceReportData,
    employmentInvoiceReportData,
    invoiceReportAdjustments,
    invoiceFiles,
    paymentDueDate,
    zuoraFileId,
    invoiceTaxTotal,
    invoiceReportTaxes,
    aggregatedTaxationItems,
    estimatesInvoiceReport,
    invoiceOverviewTotals,
    billingDocumentBreakdowns,
    estimateInvoiceTaxTotal,
  } = invoiceReportFields;

  const isRemotePaidServiceInvoice = [
    employeeInvoiceDataSources.REMOTE_PAID_SERVICES,
    employeeInvoiceDataSources.EOR_SUPPLEMENTAL_SERVICES,
  ].includes(invoiceDataSource);

  const isPreFundingInvoice = [
    employeeInvoiceDataSources.ESTIMATES,
    employeeInvoiceDataSources.EOR_PREFUNDING,
  ].includes(invoiceDataSource);
  const isContractorPaymentsInvoice =
    invoiceDataSource === employeeInvoiceDataSources.CONTRACTOR_PAYMENTS;

  const isReconciliation = [
    employeeInvoiceDataSources.ACTUALS,
    employeeInvoiceDataSources.EOR_RECONCILIATION,
  ].includes(invoiceDataSource);

  const isOnboardingReserveInvoice = [
    employeeInvoiceDataSources.ONBOARDING_RESERVE,
    employeeInvoiceDataSources.EOR_RISK_RESERVE,
    employeeInvoiceDataSources.CONTRACTOR_PROTECT_RISK_RESERVE,
  ].includes(invoiceDataSource);

  const isGlobalPayroll = invoiceDataSource === employeeInvoiceDataSources.GLOBAL_PAYROLL;

  const isContractorSubscriptionBill = [
    employeeInvoiceDataSources.CONTRACTOR_FEES,
    employeeInvoiceDataSources.CONTRACTORS_SUBSCRIPTION,
  ].includes(invoiceDataSource);
  const isContractorProtectOnboardingReserveInvoice =
    invoiceDataSource === employeeInvoiceDataSources.CONTRACTOR_PROTECT_RISK_RESERVE;

  const isRecruitSubscription =
    invoiceDataSource === employeeInvoiceDataSources.RECRUIT_SUBSCRIPTION;

  const isOneOff = invoiceDataSource === employeeInvoiceDataSources.MANUAL_EOR;

  const isTotalNegative = (invoiceTotalAmount ?? 0) < 0;

  const isReconciliationCredit = isBillingDocument(invoiceReport)
    ? isReconciliation && isCreditNote && !isCorrection
    : isReconciliation &&
      isTotalNegative &&
      !isCorrection &&
      !(invoiceReport as InvoiceReport)?.correctedInvoiceReport;

  const isFinal = invoiceStatus === employeeInvoiceStatuses.FINAL;
  const isEmptyInvoiceReport =
    employmentInvoiceReportData?.length === 0 &&
    companyInvoiceReportData?.length === 0 &&
    invoiceReportAdjustments?.length === 0;

  const isManualEdit = !!isEdited;

  const hasRefund = Boolean(invoiceOutstandingRefund);
  const hasPayment = Boolean(invoiceOutstandingPayment);

  const isPaid = invoiceOutstandingPayment?.status === paymentStatus.COMPLETED;
  const isCredited = invoiceOutstandingRefund?.status === paymentStatus.CREDITED;

  const isZuoraInvoice = isBillingDocumentResponse && invoiceReport?.originatingSystem === 'zuora';
  const canBeFinalized = !isFinal && !isZuoraInvoice;

  const isValidatorsEnabled =
    !isOneOff && !isOnboardingReserveInvoice && !isCorrection && !isRebill && !isZuoraInvoice;

  const nonEditableStatuses: EmployeeInvoiceStatus[] = [
    employeeInvoiceStatuses.SCHEDULED,
    employeeInvoiceStatuses.FINAL,
  ];
  const isEditable =
    (!nonEditableStatuses.includes(invoiceStatus as EmployeeInvoiceStatus) || isManualImport) &&
    !isZuoraInvoice;

  const hasInvoiceFiles = isZuoraInvoice ? !!zuoraFileId : isFinal && !!invoiceFiles?.[0]?.slug;

  const isInvoicePayable =
    PAYABLE_STATUSES.some((status) => status === invoiceOutstandingPayment?.status) &&
    invoiceTotalAmount &&
    invoiceTotalAmount > 0 &&
    hasInvoiceFiles;

  // is payment status due and payment due date is in the past
  const isInvoicePaymentOverdue =
    invoiceOutstandingPayment?.status === paymentStatus.CREATED &&
    paymentDueDate &&
    compareDates(paymentDueDate, getTodayAsUTCWithDefaultFormat()) === -1;

  const shouldDisplayReconciliationInvoiceSummary = isZuoraInvoice
    ? invoiceOverviewTotals &&
      invoiceOverviewTotals.some(
        (totalRow) => totalRow.estimateAmount && Number(totalRow.estimateAmount.amount) !== 0
      )
    : estimatesInvoiceReport;

  // Zuora Invoice types
  const isPrepaidInvoice =
    isBillingDocumentResponse && invoiceDataSource === employeeInvoiceDataSources.EOR_PREPAID;

  const isHrisSubscription =
    isBillingDocumentResponse && invoiceDataSource === employeeInvoiceDataSources.HRIS_SUBSCRIPTION;

  const isTalentSubscription =
    isBillingDocumentResponse &&
    invoiceDataSource === employeeInvoiceDataSources.TALENT_SUBSCRIPTION;

  // Taxes
  const hasInvoiceTax = Boolean(invoiceTaxTotal) && invoiceTaxTotal !== 0;
  const hasEstimateTax = Boolean(estimateInvoiceTaxTotal) && estimateInvoiceTaxTotal !== 0;
  const hasTaxes = shouldDisplayReconciliationInvoiceSummary
    ? hasInvoiceTax || hasEstimateTax
    : hasInvoiceTax;
  const hasTaxItems = isZuoraInvoice
    ? aggregatedTaxationItems.length > 0
    : invoiceReportTaxes?.length > 0;

  const shouldHideBreakdowns =
    isZuoraInvoice && (!billingDocumentBreakdowns || billingDocumentBreakdowns.length === 0);

  const hasPIIFreeBreakdownCSV =
    isZuoraInvoice &&
    !isCreditNote &&
    !isCorrection &&
    (isReconciliation || isPreFundingInvoice || isRemotePaidServiceInvoice);

  const isEmptyOverviewTotals = isZuoraInvoice && invoiceOverviewTotals.length === 0;

  return {
    canBeFinalized,
    hasPayment,
    hasRefund,
    isContractorSubscriptionBill,
    isContractorPaymentsInvoice,
    isContractorProtectOnboardingReserveInvoice,
    isCreditNote,
    isCredited,
    isEditable,
    isEdited,
    isEmptyInvoiceReport,
    isFinal,
    isGlobalPayroll,
    isManualEdit,
    isManualImport,
    isOnboardingReserveInvoice,
    isOneOff,
    isPaid,
    isCorrection,
    isPartialCorrection,
    isPreFundingInvoice,
    isRebill,
    isReconciliation,
    isReconciliationCredit,
    isRemotePaidServiceInvoice,
    isValidatorsEnabled,
    isInvoicePayable,
    isInvoicePaymentOverdue,
    isPrepaidInvoice,
    isHrisSubscription,
    isTalentSubscription,
    isZuoraInvoice,
    hasInvoiceFiles,
    isBillingDocument: isBillingDocumentResponse,
    hasTaxes,
    hasTaxItems,
    shouldDisplayReconciliationInvoiceSummary,
    shouldHideBreakdowns,
    hasPIIFreeBreakdownCSV,
    isEmptyOverviewTotals,
    isRecruitSubscription,
  };
}

export const getInvoiceReportDocumentType = (
  invoiceReport: InvoiceReport | InvoiceReportTableItem | BillingDocument,
  isAdminUser = false
) => {
  const isBillingDocumentResponse = isBillingDocument(invoiceReport);

  const {
    isCreditNote,
    isCorrection,
    isPartialCorrection,
    isReconciliationCredit,
    isRemotePaidServiceInvoice,
    isOnboardingReserveInvoice,
    isContractorSubscriptionBill,
    isContractorPaymentsInvoice,
    isContractorProtectOnboardingReserveInvoice,
    isOneOff,
    isGlobalPayroll,
    isPreFundingInvoice,
    isReconciliation,
    isPrepaidInvoice,
    isHrisSubscription,
    isTalentSubscription,
    isRecruitSubscription,
  } = getInvoiceReportFlags(invoiceReport);

  if (isRemotePaidServiceInvoice) {
    if (isBillingDocumentResponse) {
      return isCreditNote
        ? employeeInvoiceDisplayTypes.REMOTE_PAID_SERVICES_CREDIT_NOTE
        : employeeInvoiceDisplayTypes.REMOTE_PAID_SERVICES;
    }
    return isPartialCorrection
      ? employeeInvoiceDisplayTypes.REMOTE_PAID_SERVICES_CREDIT_NOTE
      : employeeInvoiceDisplayTypes.REMOTE_PAID_SERVICES;
  }

  // Legacy only - no concept of partials in BillingDocument
  if (isPartialCorrection) {
    if (isAdminUser) {
      return isCreditNote
        ? employeeInvoiceDisplayTypes.PARTIAL_CREDIT_NOTE
        : employeeInvoiceDisplayTypes.PARTIAL_INVOICE;
    }

    return isCreditNote
      ? employeeInvoiceDisplayTypes.CREDIT_NOTE
      : employeeInvoiceDisplayTypes.REMOTE_INVOICE;
  }

  if (isCorrection) {
    if (isAdminUser) {
      return isCreditNote
        ? employeeInvoiceDisplayTypes.FULL_CREDIT_NOTE
        : employeeInvoiceDisplayTypes.FULL_INVOICE;
    }

    return isCreditNote
      ? employeeInvoiceDisplayTypes.CREDIT_NOTE
      : employeeInvoiceDisplayTypes.REMOTE_INVOICE;
  }

  if (isReconciliationCredit) {
    return employeeInvoiceDisplayTypes.REMOTE_RECONCILIATION_CREDIT;
  }

  if (isOnboardingReserveInvoice) {
    return isContractorProtectOnboardingReserveInvoice
      ? employeeInvoiceDisplayTypes.CONTRACTOR_PROTECT_RISK_RESERVE
      : employeeInvoiceDisplayTypes.ONBOARDING_RESERVE_INVOICE;
  }

  if (isContractorSubscriptionBill) {
    return employeeInvoiceDisplayTypes.CONTRACTOR_SUBSCRIPTION_BILL;
  }

  if (isOneOff) {
    return employeeInvoiceDisplayTypes.MANUAL_EOR;
  }

  if (isGlobalPayroll) {
    return employeeInvoiceDisplayTypes.GLOBAL_PAYROLL;
  }

  if (isPreFundingInvoice) {
    return employeeInvoiceDisplayTypes.REMOTE_INVOICE_ESTIMATES;
  }

  if (isReconciliation) {
    return employeeInvoiceDisplayTypes.REMOTE_RECONCILIATION_INVOICE;
  }

  // Zuora only types
  if (isPrepaidInvoice) {
    return employeeInvoiceDisplayTypes.PREPAID_INVOICE;
  }

  if (isHrisSubscription) {
    return employeeInvoiceDisplayTypes.HRIS_SUBSCRIPTION_INVOICE;
  }

  if (isTalentSubscription) {
    return employeeInvoiceDisplayTypes.TALENT_SUBSCRIPTION_INVOICE;
  }

  if (isContractorPaymentsInvoice) {
    return employeeInvoiceDisplayTypes.CONTRACTOR_PAYMENTS;
  }

  if (isRecruitSubscription) {
    return employeeInvoiceDisplayTypes.RECRUIT_SUBSCRIPTION;
  }

  // Final fallbacks
  if (isCreditNote) {
    return employeeInvoiceDisplayTypes.CREDIT_NOTE;
  }

  return employeeInvoiceDisplayTypes.REMOTE_INVOICE;
};

export const getInvoiceReportDocumentTypeLabel = (
  invoiceReport: InvoiceReport | InvoiceReportTableItem | BillingDocument,
  isAdminUser = false
) => {
  const invoiceReportDocumentType = getInvoiceReportDocumentType(invoiceReport, isAdminUser);
  const { isRebill, employeeName } = getInvoiceReportFields(invoiceReport);
  const { isOnboardingReserveInvoice } = getInvoiceReportFlags(invoiceReport);

  let label = employeeInvoiceDisplayTypeLabels[invoiceReportDocumentType];

  if (isRebill) {
    label = `${label} (Rebill)`;
  }

  if (isOnboardingReserveInvoice) {
    label = employeeName ? `${label} for ${employeeName}` : label;
  }

  return label;
};

export const getInvoiceReportEventsTimelineFilters = (invoiceReport: InvoiceReport) => {
  const { finalizedAt, invoiceUpdatedAt } = getInvoiceReportFields(invoiceReport);

  let date;
  if (finalizedAt) {
    date = new Date(finalizedAt);
  } else if (invoiceUpdatedAt) {
    date = new Date(invoiceUpdatedAt);
  } else {
    date = new Date();
  }

  const DAYS_BACK_TO_THE_PAST = 61;

  return {
    [FILTER_TYPES.dateRange]: [
      formatYearMonthDay(subDays(date, DAYS_BACK_TO_THE_PAST)),
      formatYearMonthDay(date),
    ],
    [FILTER_TYPES.excludeAutomationEvents]: true,
  };
};
