import { Box, Pill, Stack, Text } from '@remote-com/norma';
import type { PillTone } from '@remote-com/norma';
import { IconArrowRight } from '@remote-com/norma/icons/IconArrowRight';
import type { ReactNode } from 'react';

import { Button } from '@/src/components/Button';
import ContentContainer from '@/src/components/Ui/ContentContainer';
import { PageHeader } from '@/src/components/Ui/Layout/PageHeader';
import type { PublicAssetPath } from '@/src/constants/publicAssetPaths';
import { staticAssetUrl } from '@/src/helpers/general';

import { StyledImage, ContentWrapper } from './PaywallPage.styled';

export type PageHeaderProps = {
  siteTitle?: string;
  title: ReactNode;
  subtitle?: ReactNode;
};

export type PaywallPageProps = {
  pill: {
    icon: React.ComponentType;
    tone: PillTone;
    text: string;
  };
  title: string;
  description: string;
  cta: {
    href: string;
    text: string;
    trackNavigation?: string[];
  };
  img: {
    url: PublicAssetPath;
    alt: string;
  };
  Footer?: React.ComponentType;
  pageHeader: PageHeaderProps;
};

export function PaywallPage({
  pill,
  title,
  description,
  cta,
  img,
  Footer,
  pageHeader,
}: PaywallPageProps) {
  return (
    <ContentContainer data-dd-privacy="allow">
      <PageHeader
        siteTitle={pageHeader.siteTitle || null}
        title={pageHeader.title}
        subtitle={pageHeader.subtitle}
      />
      <Stack
        direction="row"
        bg="blank"
        justifyContent="space-between"
        borderRadius={16}
        height="420px"
      >
        <Box display="flex" flexDirection="column" justifyContent="center" pl={32}>
          <ContentWrapper>
            <Pill Icon={pill.icon} tone={pill.tone}>
              {pill.text}
            </Pill>
            <Text variant="xlMedium" as="h2">
              {title}
            </Text>
            <Text variant="sm" color="grey.600" as="p">
              {description}
            </Text>
            <Button
              trackNavigation={cta.trackNavigation}
              href={cta.href}
              IconAfter={IconArrowRight}
            >
              {cta.text}
            </Button>
            {Footer && <Footer />}
          </ContentWrapper>
        </Box>
        <Box>
          <StyledImage
            width={420}
            height={420}
            src={staticAssetUrl(img.url)}
            alt={img.alt}
            priority
          />
        </Box>
      </Stack>
    </ContentContainer>
  );
}
