import { PAYROLL_RUN_DETAILS_ROUTE } from '@/src/constants/routes';
import {
  PAY_ELEMENT_DATA_SOURCES,
  PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS,
} from '@/src/domains/payElements/constants';
import { PAYROLL_RUN_STATUSES, productTypes } from '@/src/domains/payroll/constants';
import { remoteEntityName } from '@/src/domains/payroll/employer/constants';
import { PAYROLL_RUN_DETAILS_VARIANTS } from '@/src/domains/payroll/employer/EmployerPayrollRunListItem/constants';
import { isPayrollBeforeOrAtCutoff } from '@/src/domains/payroll/shared/helpers';
import { getOccurrence } from '@/src/views/dashboard/PayElements/EditEmployeePayElement/helpers';

export const getPayrollRunViewVariant = (payrollRunStatus) => {
  let variant;

  switch (payrollRunStatus) {
    case PAYROLL_RUN_STATUSES.PREPARING.id:
    case PAYROLL_RUN_STATUSES.PROCESSING.id:
      variant = PAYROLL_RUN_DETAILS_VARIANTS.INPUTS;
      break;

    case PAYROLL_RUN_STATUSES.WAITING_FOR_CUSTOMER_APPROVAL.id:
    case PAYROLL_RUN_STATUSES.COMPLETED.id:
    case PAYROLL_RUN_STATUSES.FINALIZED.id:
      variant = PAYROLL_RUN_DETAILS_VARIANTS.OUTPUTS;
      break;

    default:
      break;
  }
  return variant;
};

export const buildPayrollRunViewHref = (payrollRun) => {
  const variant = getPayrollRunViewVariant(payrollRun.status);

  if (variant) {
    return {
      pathname: PAYROLL_RUN_DETAILS_ROUTE,
      query: { payrollSlug: payrollRun.slug, variant },
    };
  }
  return null;
};

export const isGPPayrollRun = (payrollRun) =>
  payrollRun?.productType === productTypes.GLOBAL_PAYROLL;

export const getPayrollRunEntityName = (payrollRun) =>
  isGPPayrollRun(payrollRun) ? payrollRun?.legalEntity?.name : remoteEntityName;

export const getPayrollRunEntityTooltip = (payrollRun) => {
  const name = isGPPayrollRun(payrollRun)
    ? "Your company's entity manages this payroll run"
    : "Employees on this payroll run are hired and paid by Remote's entity";
  const maxWidth = isGPPayrollRun(payrollRun) ? '193px' : '244px';

  return {
    name,
    maxWidth,
  };
};

/**
 * Temporary pay elements can be updated by the employer in the payroll run if:
 * - The payroll run is before or at the cutoff date
 * - The payroll run is in the preparing status
 * - The payroll run is a global payroll
 * - The pay element was created by the employer
 *
 * Occurrences of recurring pay elements can be updated by the employer in the payroll run if:
 * - The payroll run is before or at the cutoff date
 * - The payroll run is in the preparing status
 * - The payroll run is a global payroll
 * - Its permanent pay element was created by the employer
 * - The pay element does not have a data source
 *
 * Salary pay elements cannot be updated by the employer in the payroll run
 *
 */
export function canEmployerUpdatePayElementInPayrollRun({ employeePayElement, payrollRun }) {
  const isBeforeOrAtCutoff = isPayrollBeforeOrAtCutoff({ cutoffDate: payrollRun.cutoffDate });
  const isPreparing = payrollRun.status === PAYROLL_RUN_STATUSES.PREPARING.id;
  const isGlobalPayroll = payrollRun.productType === productTypes.GLOBAL_PAYROLL;

  const isTemporaryPayElement = getOccurrence(employeePayElement) === 'temporary';
  const isRecurringPayElement = getOccurrence(employeePayElement) === 'permanent';
  const recurringHasDataSource = isRecurringPayElement && !!employeePayElement.dataSource;

  // If the pay element is temporary, we check if it was created by the employer.
  // If the pay element is recurring, we need to check if its permanent pay element was created by the employer.
  const createdByEmployer = isRecurringPayElement
    ? employeePayElement.fromPermanentEmployeePayElement.createdBy.roles[0].userType === 'employer'
    : employeePayElement.createdBy.roles[0].userType === 'employer';
  const commonConditions = isBeforeOrAtCutoff && isPreparing && isGlobalPayroll;

  const isSalaryPayElement =
    employeePayElement?.payrollOutputAmountField ===
      PAY_ELEMENT_OUTPUT_AMOUNT_FIELDS.COMPENSATION_BASE_SALARY_AMOUNT ||
    employeePayElement?.dataSource?.type === PAY_ELEMENT_DATA_SOURCES.SALARY;

  return (
    !isSalaryPayElement &&
    ((commonConditions && isTemporaryPayElement && createdByEmployer) ||
      (commonConditions && isRecurringPayElement && createdByEmployer && !recurringHasDataSource))
  );
}
