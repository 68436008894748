import isString from 'lodash/isString';

import {
  canadaCountryCode,
  usCountryCode,
  countryFormsNames,
  countryFormsNamesToFeature,
  fullyMigratedForms,
} from '@/src/domains/countries/constants';
import { CONTRACT_ORIGIN_TYPES } from '@/src/domains/employment/newEmployment/constants';
import { staticAssetUrl } from '@/src/helpers/general';

/**
 * Check if provided country has specified feature
 *
 * @param {Object} country - The country object
 * @param {String[]} country.features
 * @param {String} feature - The feature key as provided by fetchAvailableCountryFeatures
 */
export function countryHasFeature(country, feature) {
  const { features = [] } = country || {};
  return features.includes(feature);
}

export function countrySupportsUSState(country) {
  const { code } = country || {};
  return code === usCountryCode;
}

const territoryToCountry = {
  'French Guiana': 'France',
  'French Polynesia': 'France',
  Guadeloupe: 'France',
  Mayotte: 'France',
  'New Caledonia': 'France',
  Réunion: 'France',
  'Saint Pierre and Miquelon': 'France',
  'Wallis and Futuna Islands': 'France',
};

export function getSvgFlagName(country) {
  if (!country) return null;

  const countryName = typeof country === 'string' ? country : country.name;
  const flagCountryName = territoryToCountry[countryName] || countryName;
  let name = flagCountryName.toLowerCase();
  name = name.replace(/ /g, '-').replace(/\./g, '');

  // Remove strings between (and including) brackets like: (UAE)
  if (name.indexOf('(') !== -1) {
    name = name.substring(0, name.indexOf('-('));
  }

  return name;
}

export function getFlagSrc(country) {
  if (!country) return null;

  return staticAssetUrl(`/images/flags/${getSvgFlagName(country)}.svg`);
}

const currencyCodeToFileName = {
  EUR: 'eur',
  AFN: 'afghanistan',
  ALL: 'albania',
  DZD: 'algeria',
  AOA: 'angola',
  XCD: 'saint-vincent-and-the-grenadines',
  ARS: 'argentina',
  AMD: 'armenia',
  AUD: 'australia',
  AZN: 'azerbaijan',
  BSD: 'bahamas',
  BHD: 'bahrain',
  BDT: 'bangladesh',
  BBD: 'barbados',
  BYN: 'belarus',
  BZD: 'belize',
  XOF: 'togo',
  BMD: 'bermuda',
  BTN: 'bhutan',
  BOB: 'bolivia',
  BAM: 'bosnia-and-herzegovina',
  BWP: 'botswana',
  BRL: 'brazil',
  USD: 'united-states',
  BND: 'brunei',
  BGN: 'bulgaria',
  BIF: 'burundi',
  CVE: 'cabo-verde',
  KHR: 'cambodia',
  XAF: 'republic-of-the-congo',
  CAD: 'canada',
  KYD: 'cayman-islands',
  CLP: 'chile',
  CNY: 'china',
  COP: 'colombia',
  KMF: 'comoros',
  CRC: 'costa-rica',
  HRK: 'croatia',
  ANG: 'curacao',
  CZK: 'czech-republic',
  CDF: 'democratic-republic-of-the-congo',
  DKK: 'denmark',
  DJF: 'djibouti',
  DOP: 'dominican-republic',
  EGP: 'egypt',
  ERN: 'eritrea',
  ETB: 'ethiopia',
  FJD: 'fiji',
  GMD: 'gambia',
  GEL: 'georgia',
  GHS: 'ghana',
  GTQ: 'guatemala',
  GNF: 'guinea',
  GYD: 'guyana',
  HTG: 'haiti',
  HNL: 'honduras',
  HKD: 'hong-kong',
  HUF: 'hungary',
  ISK: 'iceland',
  INR: 'india',
  IDR: 'indonesia',
  IQD: 'iraq',
  ILS: 'israel',
  JMD: 'jamaica',
  JPY: 'japan',
  JEP: 'jersey',
  JOD: 'jordan',
  KZT: 'kazakhstan',
  KES: 'kenya',
  KWD: 'kuwait',
  KGS: 'kyrgyzstan',
  LAK: 'laos',
  LBP: 'lebanon',
  LSL: 'lesotho',
  LRD: 'liberia',
  LYD: 'libya',
  CHF: 'switzerland',
  MGA: 'madagascar',
  MWK: 'malawi',
  MYR: 'malaysia',
  MVR: 'maldives',
  MRU: 'mauritania',
  MUR: 'mauritius',
  MXN: 'mexico',
  MDL: 'moldova',
  MNT: 'mongolia',
  MAD: 'morocco',
  MZN: 'mozambique',
  MMK: 'myanmar',
  NAD: 'namibia',
  NPR: 'nepal',
  NZD: 'new-zealand',
  NIO: 'nicaragua',
  NGN: 'nigeria',
  MKD: 'north-macedonia',
  NOK: 'norway',
  OMR: 'oman',
  PKR: 'pakistan',
  PAB: 'panama',
  PGK: 'papua-new-guinea',
  PYG: 'paraguay',
  PEN: 'peru',
  PHP: 'philippines',
  PLN: 'poland',
  QAR: 'qatar',
  RON: 'romania',
  RUB: 'russia',
  RWF: 'rwanda',
  WST: 'samoa',
  STN: 'sao-tome-and-principe',
  SAR: 'saudi-arabia',
  RSD: 'serbia',
  SCR: 'seychelles',
  SLL: 'sierra-leone',
  SGD: 'singapore',
  SBD: 'solomon-islands',
  SOS: 'somalia',
  ZAR: 'south-africa',
  KRW: 'south-korea',
  SSP: 'south-sudan',
  LKR: 'sri-lanka',
  SDG: 'sudan',
  SRD: 'suriname',
  SZL: 'swaziland',
  SEK: 'sweden',
  TWD: 'taiwan',
  TJS: 'tajikistan',
  TZS: 'tanzania',
  THB: 'thailand',
  TOP: 'tonga',
  TTD: 'trinidad-and-tobago',
  TND: 'tunisia',
  TRY: 'turkey',
  TMT: 'turkmenistan',
  UGX: 'uganda',
  UAH: 'ukraine',
  AED: 'united-arab-emirates',
  GBP: 'united-kingdom',
  UYU: 'uruguay',
  UZS: 'uzbekistan',
  VEF: 'venezuela',
  VND: 'vietnam',
  YER: 'yemen',
  ZMW: 'zambia',
  ZWD: 'zimbabwe',
};
// currency flags are usually the country flag
export function getCurrencyFlagSrc(currencyCode) {
  const name = currencyCodeToFileName[currencyCode?.toUpperCase()];

  if (!name) return undefined;

  return `/images/flags/${name}.svg`;
}

export function filterCountriesByFeature(feature) {
  return (country) => country.features.includes(feature);
}

/**
 * Finds a country in a list of countries using slug, country code or country object.
 * @param {string | object} term country slug, country code or country object.
 * @param {object} countries list of country objects
 * @returns {object | null}
 */
export function findCountry(term, countries) {
  if (!term) {
    return null;
  }

  const identifier = isString(term) ? term.toLowerCase() : term.slug;

  return countries.find(({ slug, code }) => {
    if (slug === identifier) {
      return true;
    }

    if (code.toLowerCase() === identifier) {
      return true;
    }

    return false;
  });
}

export function getCountryStatusBadge(features, totalFeatures) {
  if (features.length === 0) {
    return {
      label: 'Inactive',
      labelExtra: 'All features are off',
      type: 'inactive',
    };
  }
  if (features.length === totalFeatures) {
    return {
      label: 'Active',
      labelExtra: 'All features are on',
      type: 'active',
    };
  }
  return {
    label: 'Semi-active',
    labelExtra: 'Some features are on',
    type: 'pending',
  };
}

export function isCountryUK(country = null) {
  return country?.code === 'GBR';
}

export function isCountryUS(country = null) {
  return country?.code === 'USA';
}

export function isCountrySAU(country = null) {
  return country?.code === 'SAU';
}

export function getStateLabel(country) {
  const { code } = country || {};
  switch (code) {
    case canadaCountryCode:
      return 'Province';
    default:
      return 'State';
  }
}

export const countryWithRemoteContract = (country) =>
  country.contractor.contractOrigin?.includes(CONTRACT_ORIGIN_TYPES.REMOTE_CONTRACT);

export const toCountrySlug = (country) => country?.countrySlug;

/**
 * Helper function that checks if specific country + form name combo is API enabled
 * @param {string} countrySelected country details
 * @param {string} formName form name
 * @returns {boolean}
 */
export function isCountryFormActive(countrySelected, formName) {
  if (fullyMigratedForms.includes(formName)) {
    return true;
  }
  if (!countrySelected || !formName) {
    return false;
  }

  const selectedFormName = Object.keys(countryFormsNames).find(
    (key) => countryFormsNames[key] === formName
  );

  if (!selectedFormName) {
    return false;
  }

  const formHasAPIEnabled = countryHasFeature(
    countrySelected,
    countryFormsNamesToFeature[countryFormsNames[selectedFormName]]
  );
  return formHasAPIEnabled;
}

/*
Sources for Country names with/without articles
{@link https://www.worldatlas.com/articles/countries-whose-official-names-begin-with-the.html}
{@link https://www.grammar-quizzes.com/article4c.html}
 */
const countryNamesWithPrecedingArticle = [
  // The Gambia and The Bahamas officially include "the" in their country name
  'Gambia',
  'Bahamas',
  // geophysical names and a physical entity
  'Marshall Islands',
  'Cayman Islands',
  'British Virgin Islands',
  'Maldives',
  'Democratic Republic of the Congo',
  'Netherlands',
  // country includes a common noun
  'United Kingdom (UK)',
  'United States',
  'United Arab Emirates (UAE)',
  // Countries whose name is plural
  'Philippines',
  'Comoros',
  'Seychelles',
];

/**
 * Helper function that returns the country name with article "the" if necessary
 * @param {string} countryName country name
 * @param {boolean} capitalArticle whether the article, the, should be capitalized or not
 * @returns {string}
 */
export const getCountryNameWithArticle = (countryName, capitalArticle = false) => {
  return countryNamesWithPrecedingArticle.includes(countryName)
    ? `${capitalArticle ? 'T' : 't'}he ${countryName}`
    : countryName;
};

/**
 * Helper function that returns if the country name with article "the" if necessary
 * @param {string} countryName country name
 * @returns {boolean}
 */
export const countryNameShouldHaveArticle = (countryName) => {
  return countryNamesWithPrecedingArticle.includes(countryName);
};
