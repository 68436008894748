import type {
  ContractorOnlyUserPromptAction,
  NotRequiredUserPromptAction,
  UserPromptAction,
} from '@/src/domains/userPrompts/constants';

import { userPromptCards } from './widgets/userPromptsWidget/config';

export type PromptStatus = 'active' | 'completed' | 'skipped';

export type Prompt = {
  action: UserPromptAction;
  priority: number;
  required: boolean;
  slug: string;
  status: PromptStatus;
};
type FilteredPrompt = {
  action: NotRequiredUserPromptAction;
  priority: number;
  required: boolean;
  slug: string;
  status: PromptStatus;
};

export type ContractorOnlyUserPrompt = Omit<Prompt, 'action'> & {
  action: ContractorOnlyUserPromptAction;
};

export function convertListToMapByAction(list: Prompt[]) {
  if (!list) return {};

  return list?.reduce<Partial<Record<UserPromptAction, Prompt>>>((acc, prompt) => {
    acc[prompt.action] = prompt;
    return acc;
  }, {});
}

export function isAnyPromptSkipped(prompts: Prompt[]) {
  return prompts?.some((prompt) => prompt.status === 'skipped');
}

function isActive(prompt: Prompt) {
  return prompt.status === 'active';
}
function hasConfig(prompt: Prompt) {
  return prompt.action in userPromptCards;
}
function getFirstThreePrompts(list: FilteredPrompt[]) {
  return list.slice(0, 3);
}
export function getActivePrompts(promptsList: Prompt[]) {
  const prompts = promptsList.filter(isActive).filter(hasConfig) as FilteredPrompt[];

  return getFirstThreePrompts(prompts);
}
