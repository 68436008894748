// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconCommentDots = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M13.68.99c.88.07 1.6.22 2.26.55a5.75 5.75 0 0 1 2.52 2.52c.33.65.48 1.38.55 2.26.07.87.07 1.94.07 3.31V16.07c0 .38 0 .69-.05.93a2.25 2.25 0 0 1-2.91 1.65c-.24-.08-.5-.24-.83-.44l-.08-.04-.45-.26a4.25 4.25 0 0 0-2.26-.5H9.14c-1.14 0-2.04 0-2.76-.04a5.96 5.96 0 0 1-1.91-.4 5.75 5.75 0 0 1-3.12-3.1 5.96 5.96 0 0 1-.38-1.92C.92 11.23.92 10.34.92 9.2v-.05c0-1.14 0-2.04.05-2.76.05-.74.15-1.35.38-1.91a5.75 5.75 0 0 1 3.12-3.12A5.96 5.96 0 0 1 6.38.97C7.1.92 8 .92 9.14.92h1.23c1.37 0 2.44 0 3.3.07Zm-.12 1.5c-.8-.07-1.81-.07-3.23-.07H9.17c-1.18 0-2.02 0-2.69.04a4.5 4.5 0 0 0-1.44.28 4.25 4.25 0 0 0-2.3 2.3 4.5 4.5 0 0 0-.28 1.44c-.04.67-.04 1.51-.04 2.69 0 1.17 0 2.02.04 2.68.05.66.13 1.09.28 1.44a4.25 4.25 0 0 0 2.3 2.3c.35.15.78.24 1.44.28.67.05 1.51.05 2.69.05h3.93c.83.05 1.63.27 2.36.66l.51.3h.01c.45.27.56.33.61.35.43.14.88-.12.98-.55l.01-.7V9.67c0-1.42 0-2.43-.06-3.23a4.47 4.47 0 0 0-.4-1.7 4.25 4.25 0 0 0-1.86-1.86m-1.7-.4c.78.07 1.3.19 1.7.4ZM5.08 9.17a1.17 1.17 0 1 1 2.34 0 1.17 1.17 0 0 1-2.34 0Zm3.75 0a1.17 1.17 0 1 1 2.34 0 1.17 1.17 0 0 1-2.34 0Zm3.75 0a1.17 1.17 0 1 1 2.34 0 1.17 1.17 0 0 1-2.34 0Z" /></svg>;
const ForwardRef = forwardRef(IconCommentDots);
export { ForwardRef as IconCommentDots };