export const fieldToLabel = {
  startTime: 'Start time',
  endTime: 'End time',
  lunchStartTime: 'Lunch start',
  lunchEndTime: 'Lunch end',
  workingHours: 'Working hours',
};

export const fieldsSimplified = {
  startTime: 'Start time',
  endTime: 'End time',
  workingHours: 'Working hours',
};
