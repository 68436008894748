import styled from 'styled-components';

// Note: we're not using Norma's Box here because "align" is a prop that's used by Box and
// Box's `shouldForwardProp` option does not allow repeated props to be passed down to its children
// We could adapt Box's code to fix this edge case but the simplest route is to use a plain div.
// eslint-disable-next-line remote/norma-prefer-layout
export const MenuList = styled.div`
  background: ${({ theme }) => theme.colors.blank};
  border: ${({ theme }) => `1px solid ${theme.colors.grey[100]}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.small};
  min-width: 240px;
  padding: calc(${({ theme }) => `${theme.space[2]}px`} - 1px);
  white-space: nowrap;
  &:focus {
    outline: 0;
  }

  /* This is needed to add scroll when there are more than 5 Menuitems in the  */
  overflow-y: auto;
`;
