import type { BillingDocument } from '@/src/api/config/employ/billingDocuments.types';
import type { InvoiceReport } from '@/src/api/config/employ/invoiceReport.types';
import { useUser } from '@/src/components/UserProvider/context';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import {
  employeeInvoiceStatuses,
  employeeInvoiceDisplayTypeLabels,
} from '@/src/domains/invoices/constants';
import {
  getInvoiceReportFields,
  getInvoiceReportFlags,
  getInvoiceReportDocumentType,
  getInvoiceReportDocumentTypeLabel,
} from '@/src/domains/invoices/shared/helpers';
import { StyledPill } from '@/src/domains/invoices/shared/InvoiceReport/InvoiceReport.style';
import { formatPayrollPeriodDate } from '@/src/helpers/date';

export const useInvoiceReportDetailsTitle = (invoiceReport: InvoiceReport | BillingDocument) => {
  const { userIsAdmin } = useUser();

  const documentType = getInvoiceReportDocumentType(invoiceReport, userIsAdmin);

  const { invoiceStatus, invoiceGroup, documentNumber } = getInvoiceReportFields(invoiceReport);
  const { isManualImport, isEdited, isRebill } = getInvoiceReportFlags(invoiceReport);

  const isDraftInvoiceReport =
    invoiceStatus === employeeInvoiceStatuses.DRAFT ||
    invoiceStatus === employeeInvoiceStatuses.DRAFT_READY;

  let pageTitle = employeeInvoiceDisplayTypeLabels[documentType];

  const toAppend = [isRebill ? 'Rebill' : null, isDraftInvoiceReport ? 'Draft' : null]
    .filter(Boolean)
    .join(' - ');

  if (toAppend) {
    pageTitle = `${pageTitle} (${toAppend})`;
  }

  if (documentNumber) {
    pageTitle = `${pageTitle} #${documentNumber}`;
  }

  if (invoiceGroup?.name) {
    pageTitle = `${pageTitle} - ${invoiceGroup.name}`;
  }

  return (
    <>
      {pageTitle}
      {userIsAdmin && (
        <>
          {isManualImport && (
            <StyledPill tone="primary" data-testid="manual-imported-pill">
              Manually imported
            </StyledPill>
          )}
          {isEdited && <StyledPill tone="primary">Manually edited</StyledPill>}
        </>
      )}
    </>
  );
};

export const useInvoiceReportDetailsSiteTitle = (
  invoiceReport: InvoiceReport | BillingDocument
) => {
  const { userIsAdmin } = useUser();
  const isMultiEntityBillingXpEnabled = useIsFeatureFlagEnabled('multi_entity_billing_xp');

  const invoiceTypeLabel = getInvoiceReportDocumentTypeLabel(invoiceReport, userIsAdmin);
  const { invoiceCompanyName, invoiceLegalEntity, invoicePeriod, documentNumber } =
    getInvoiceReportFields(invoiceReport);
  const { isZuoraInvoice } = getInvoiceReportFlags(invoiceReport);

  const entityName =
    isZuoraInvoice && isMultiEntityBillingXpEnabled ? invoiceLegalEntity?.name : invoiceCompanyName;

  if (userIsAdmin && entityName && invoicePeriod) {
    return `${entityName} | ${formatPayrollPeriodDate(invoicePeriod)} | ${invoiceTypeLabel}`;
  }

  return `${invoiceTypeLabel} #${documentNumber}`;
};
