import { Box } from '@remote-com/norma';
import styled from 'styled-components';

import { StyledTabBarBadge } from './Badge';

const tabBarBg = (theme) => ({
  blank: theme.colors.white,
  backgroundDashboard: theme.colors.background.base,
});

const TabBar = styled(Box).attrs((props) => ({
  ...props,
  as: 'nav',
  mb: props.mb ?? 7,
}))`
  --itemSpacingX: 0;
  --itemSpacingY: 16px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.1875em;
  color: ${({ theme }) => theme.colors.grey[500]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  --tabs-gradientSize: ${({ theme }) => theme.space[6]}px;

  ${StyledTabBarBadge} {
    margin-top: -6px;
    margin-left: 2px;
    position: absolute;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(var(--tabs-gradientSize) + 8px); /* 4px to ensure it covers the text */
      height: calc(100% - 8px); /* 8px to not cover horizontal scrollbar */
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${({ theme, $bg }) => tabBarBg(theme)[$bg] || tabBarBg(theme).backgroundDashboard}
      );
    }
  }
`;

export default TabBar;
