import { useGet, usePut, useInvalidateQuery, usePost } from '@remote-com/data-layer';
import isEmpty from 'lodash/isEmpty';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useState, useEffect, useRef } from 'react';

import type { UserAccountResponse } from '@/src/components/UserProvider';
import { useUserContext } from '@/src/components/UserProvider';
import { isContractor, isEmployer } from '@/src/domains/registration/auth/helpers';
import type { UserPromptAction } from '@/src/domains/userPrompts/constants';
import type { Prompt } from '@/src/domains/userPrompts/helpers';
import { convertListToMapByAction, isAnyPromptSkipped } from '@/src/domains/userPrompts/helpers';
import { PastPromptsDrawer } from '@/src/domains/userPrompts/pastPromptsDrawer';
import { debug } from '@/src/helpers/general';

type CompletePromptOptions = {
  dismissed?: boolean;
  optimisticUpdate?: boolean;
  updateAcrossAllProfiles?: boolean;
  onSuccess?: () => void;
};

type PromptsMap = ReturnType<typeof convertListToMapByAction>;

type UserPromptsContext = {
  promptsList: Prompt[];
  completePrompt: (action: UserPromptAction, options?: CompletePromptOptions) => void;
  promptsMap: PromptsMap;
  isLoading: boolean;
  setPastPromptsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  openHelpMenuTooltip: () => void;
  isHelpMenuWithTooltipOpen: boolean;
};

const Context = createContext<UserPromptsContext>(null!);
const TWO_MIN = 120000;
const SEVEN_SEC = 7000;

type UserPromptsProviderProps = React.PropsWithChildren<{ user: UserAccountResponse }>;

export const UserPromptsProvider = ({ user, children }: UserPromptsProviderProps) => {
  const isUserContractor = isContractor(user);
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [promptsMap, setPromptsMap] = useState<PromptsMap>({});
  const [pastPromptsDrawerOpen, setPastPromptsDrawerOpen] = useState(false);
  const [isHelpMenuWithTooltipOpen, setHelpMenuWithTooltipOpen] = useState(false);
  const tooltipTimeoutRef = useRef<NodeJS.Timeout>();

  const { invalidateQuery } = useInvalidateQuery();

  const { isLoading } = useGet('/api/v1/prompts', {
    params: {
      queryParams: {
        ...(isUserContractor
          ? { sortBy: 'action' }
          : {
              sortBy: 'priority',
              order: 'desc',
            }),
      },
    },
    options: {
      onSuccess: ({ data }) => {
        setPrompts(data);
        setPromptsMap(convertListToMapByAction(data));
      },
      enabled: !!user,
      refetchInterval: TWO_MIN,
    },
  });

  const { mutate: mutateOne } = usePut('/api/v1/prompts/[promptId]', {
    onSuccess: () => {
      invalidateQuery('/api/v1/prompts');
    },
  });

  const { mutate: mutateAll } = usePost('/api/v1/prompts/update-across-all-profiles', {
    onSuccess: () => {
      invalidateQuery('/api/v1/prompts');
    },
  });

  function completePrompt(action: UserPromptAction, options: CompletePromptOptions = {}) {
    const {
      dismissed = false,
      optimisticUpdate = false,
      updateAcrossAllProfiles = false,
      onSuccess,
    } = options;
    const prompt = promptsMap[action];

    if (isEmpty(promptsMap) || prompt?.status !== 'active') return;

    debug('userPrompts::completePrompt: ', { action, prompt, promptsMap });

    if (optimisticUpdate) {
      setPrompts((prevPrompts) => prevPrompts.filter((p) => p.action !== action));
    }

    if (updateAcrossAllProfiles) {
      mutateAll(
        {
          bodyParams: {
            action,
            status: dismissed ? 'skipped' : 'completed',
          },
        },
        {
          onSuccess,
        }
      );
    } else {
      mutateOne(
        {
          pathParams: { promptId: prompt.slug },
          bodyParams: {
            status: dismissed ? 'skipped' : 'completed',
          },
        },
        {
          onSuccess,
        }
      );
    }
  }

  function openHelpMenuTooltip() {
    setHelpMenuWithTooltipOpen(true);
    tooltipTimeoutRef.current = setTimeout(() => setHelpMenuWithTooltipOpen(false), SEVEN_SEC);
  }

  useEffect(() => {
    return () => clearTimeout(tooltipTimeoutRef.current);
  }, [tooltipTimeoutRef]);

  return (
    <Context.Provider
      value={{
        promptsList: prompts,
        promptsMap,
        completePrompt,
        setPastPromptsDrawerOpen,
        openHelpMenuTooltip,
        isHelpMenuWithTooltipOpen,
        isLoading,
      }}
    >
      {children}
      {pastPromptsDrawerOpen && <PastPromptsDrawer setOpen={setPastPromptsDrawerOpen} />}
    </Context.Provider>
  );
};

export const useUserPrompts = () => {
  const {
    promptsList,
    promptsMap,
    completePrompt,
    setPastPromptsDrawerOpen,
    openHelpMenuTooltip,
    isHelpMenuWithTooltipOpen,
    isLoading,
  } = useContext(Context) || {};
  const { user } = useUserContext();

  if (!completePrompt && isEmployer(user)) debug('useUserPrompts: missing context provider');

  return {
    promptsList: promptsList || [],
    promptsMap: promptsMap || {},
    completePrompt: completePrompt || (() => {}),
    setPastPromptsDrawerOpen: setPastPromptsDrawerOpen || (() => {}),
    openHelpMenuTooltip: openHelpMenuTooltip || (() => {}),
    isHelpMenuWithTooltipOpen: isHelpMenuWithTooltipOpen || false,
    isLoading,
    isAnyPromptSkipped: isAnyPromptSkipped(promptsList) || false,
  };
};
