import capitalize from 'lodash/capitalize';
import isFunction from 'lodash/isFunction';

import { cleanString } from '@/src/domains/bulkUpload/admin/helpers/strings';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

const getRequiredFields = ({ row, currentProcessor, mappedData }) => {
  if (!mappedData || !currentProcessor) {
    return [];
  }
  const { rules } = currentProcessor;

  const requiredFields = Object.keys(rules).filter((key) => {
    return isFunction(rules[key].required) ? rules[key].required(row) : rules[key].required;
  });

  return requiredFields;
};

export const validateJSONSchemaValue = ({ value, schema }) => {
  let errors = [];

  if (schema) {
    try {
      schema.validateSync(value);
    } catch (error) {
      errors = error.errors;
    }
  }

  return errors;
};

const validateJSONSchemaRow = ({ row, currentProcessor }) => {
  const { rules } = currentProcessor;
  const validationErrors = {};

  Object.keys(rules).forEach((rule) => {
    const { schema, required } = rules[rule];
    const value = row[rule];

    if (!required && value === '') return;

    const errors = validateJSONSchemaValue({ value, schema });

    if (errors.length) {
      validationErrors[rule] = errors;
    }
  });

  return validationErrors;
};

export const getMissingRowDataRules = ({ row, currentProcessor, mappedData }) => {
  const schemaValidationErrors = validateJSONSchemaRow({ row, currentProcessor });
  const missingRequiredFields = getRequiredFields({ row, mappedData, currentProcessor }).filter(
    (field) => !row[field]
  );
  const missingRowDataRules = Array.from(
    new Set([...Object.keys(schemaValidationErrors), ...missingRequiredFields])
  );

  return missingRowDataRules;
};

export const isMissingData = ({ mappedData, currentProcessor }) => {
  let hasMissingData = false;
  const missingDataRows = [];

  for (let i = 0; i < mappedData.length; i++) {
    const row = mappedData[i];

    const missingRowDataRules = getMissingRowDataRules({ row, currentProcessor, mappedData });

    if (missingRowDataRules.length) {
      hasMissingData = true;
      missingDataRows.push(row);
    }
  }

  return { hasMissingData, missingDataRows };
};

export const getIncompleteRows = ({ mappedData, currentProcessor }) => {
  const rowWithMissingData = mappedData.filter((row) =>
    getRequiredFields({ row, mappedData, currentProcessor }).every((field) => row[field])
  );

  return mappedData.length - rowWithMissingData.length;
};

const getRequiredLabels = ({ mappedData, currentProcessor }) => {
  const labels = new Set();

  mappedData.forEach((row) => {
    const keys = getMissingRowDataRules({ row, currentProcessor, mappedData });

    keys.forEach((key) => {
      const label = currentProcessor.rules[key].label || key;

      labels.add(cleanString(capitalize(label)));
    });
  });

  return labels;
};

export const getRequiredErrorText = ({ mappedData, currentProcessor }) => {
  const labels = [...getRequiredLabels({ mappedData, currentProcessor })];
  const labelsCount = labels.length;

  if (!labelsCount) {
    return null;
  }

  const sentenceStart = labelsCount === 2 ? labels.join(' and ') : labels.join(', ');

  return `${sentenceStart} ${getSingularPluralUnit(
    labelsCount,
    'is a required field. Please fill it in the table to continue',
    'are required fields. Please fill them in the table to continue',
    true,
    false
  )}`;
};
