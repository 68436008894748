import { isPast } from 'date-fns';

import {
  teamPlanStatus,
  teamPlanStatusBadge,
  teamPlanStatusLabels,
} from '@/src/domains/teamPlan/constants';
import { convertToPercent } from '@/src/helpers/discounts';

export function constructDiscountLabel({ name, percent }) {
  return `${name} (${convertToPercent({ value: percent })}%)`;
}

export function hasTeamPlanAvailableSeats(teamPlan, seat) {
  const isSeatReserved = teamPlan?.slug === seat?.teamPlan?.slug;

  return isSeatReserved || teamPlan?.totalSeats - teamPlan?.seatsInUse > 0;
}

export function isTeamPlanExpired(teamPlan) {
  return teamPlan?.endDate ? isPast(new Date(teamPlan.endDate)) : false;
}

export function getTeamPlanStatusBadgeProps(teamPlan) {
  const { status, totalSeats, seatsInUse } = teamPlan;

  if (isTeamPlanExpired(teamPlan) && status !== teamPlanStatus.ARCHIVED) {
    return {
      type: teamPlanStatusBadge[teamPlanStatus.EXPIRED],
      label: teamPlanStatusLabels[teamPlanStatus.EXPIRED],
    };
  }

  return totalSeats - seatsInUse <= 0 && status === teamPlanStatus.ACCEPTED
    ? {
        type: teamPlanStatusBadge[teamPlanStatus.OUT_OF_SEATS],
        label: teamPlanStatusLabels[teamPlanStatus.OUT_OF_SEATS],
      }
    : {
        type: teamPlanStatusBadge[status],
        label: teamPlanStatusLabels[status],
      };
}

export function calculateAvailableSeats({ totalSeats, activeSeats, reservedSeats }) {
  return totalSeats - activeSeats - reservedSeats;
}

export function canAddEmployeeToTeamPlan({ teamPlan, seat }) {
  // If the seat is reserved, we'll try add add the seat to the team plan.
  const isSeatReserved = teamPlan?.slug === seat?.teamPlan?.slug;

  /**
   * We don't show the pricing step for customers on a tiered plan. However,
   * if a tiered plan customer has a TEAM plan, we'll try fill up all the remaining seats.
   * */
  const isTeamPlanValid =
    !!teamPlan &&
    !isTeamPlanExpired(teamPlan) &&
    (hasTeamPlanAvailableSeats(teamPlan) || isSeatReserved);

  return isTeamPlanValid;
}
