export const usStates = /** @type {const} */ ({
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  AS: 'American Samoa',
  GU: 'Guam',
  MP: 'Northern Mariana Islands',
  PR: 'Puerto Rico',
  UM: 'U.S. Minor Outlying Islands',
  VI: 'U.S. Virgin Islands',
});

export const canadaProvinces = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const southAfricaProvinces = [
  'Eastern Cape',
  'Free State',
  'Gauteng',
  'KwaZulu-Natal',
  'Limpopo',
  'Mpumalanga',
  'Northern Cape',
  'Western Cape',
  'North West',
];

export const mexicoStates = [
  'Aguascalientes',
  'Baja California',
  'Baja California Sur',
  'Campeche',
  'Chiapas',
  'Mexico City',
  'Chihuahua',
  'Coahuila',
  'Colima',
  'Durango',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'México',
  'Michoacán',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Querétaro',
  'Quintana Roo',
  'San Luis Potosí',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  'Yucatán',
  'Zacatecas',
];

export const moroccoStates = [
  'Béni Mellal-Khénifra',
  'Casablanca-Settat',
  'Dakhla-Oued Ed-Dahab',
  'Drâa-Tafilalet',
  'Fès-Meknès',
  'Guelmim-Oued Noun',
  'Laâyoune-Sakia El Hamra',
  'Marrakesh-Safi ',
  'Oriental',
  'Rabat-Salé-Kénitra',
  'Souss-Massa',
  'Tanger-Tetouan-Al Hoceima',
];

export const brazilStates = {
  AC: 'Acre',
  AL: 'Alagoas',
  AM: 'Amazonas',
  AP: 'Amapá',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MG: 'Minas Gerais',
  MS: 'Mato Grosso do Sul',
  MT: 'Mato Grosso',
  PA: 'Pará',
  PB: 'Paraíba',
  PE: 'Pernambuco',
  PI: 'Piauí',
  PR: 'Paraná',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RO: 'Rondônia',
  RR: 'Roraima',
  RS: 'Rio Grande do Sul',
  SC: 'Santa Catarina',
  SE: 'Sergipe',
  SP: 'São Paulo',
  TO: 'Tocantins',
};

export const australiaStates = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

export const availableUkraineProvinces = [
  'Cherkasy',
  'Chernihiv',
  'Chernivtsi',
  'Dnipropetrovsk',
  'Donetsk',
  'Ivano - Frankivsk',
  'Kharkiv',
  'Kherson',
  'Khmelnytskyi',
  'Kyiv',
  'Kirovohrad',
  'Luhansk',
  'Lviv',
  'Mykolaiv',
  'Odessa',
  'Poltava',
  'Rivne',
  'Sumy',
  'Ternopil',
  'Vinnytsia',
  'Volyn',
  'Zakarpattia',
  'Zaporizhia',
  'Zhytomyr',
];

export const pakistanProvinces = [
  'Province of Punjab - Lahore',
  'Province of Sindh - Karachi',
  'Province of Balochistan - Quetta',
  'Province of Khyber-Pakhtunkhwa - Peshawar',
  'The Islamabad Capital Territory - Islamabad',
  'The State of Azad Jammu & Kashmir - Muzzafarabad',
  'The State of Gilgit-Baltistan - Gilgit',
];

export const colombiaStates = [
  'Capital District',
  'Amazonas',
  'Antioquia',
  'Arauca',
  'Atlántico',
  'Bolívar',
  'Boyacá',
  'Caldas',
  'Caquetá',
  'Casanare',
  'Cauca',
  'Cesar',
  'Chocó',
  'Córdoba',
  'Cundinamarca',
  'Guainía',
  'Guaviare',
  'Huila',
  'La Guajira',
  'Magdalena',
  'Meta',
  'Nariño',
  'Norte de Santander',
  'Putumayo',
  'Quindío',
  'Risaralda',
  'San Andrés y Providencia',
  'Santander',
  'Sucre',
  'Tolima',
  'Valle del Cauca',
  'Vaupés',
  'Vichada',
];

export const switzerlandCantons = [
  'Zürich',
  'Bern; Berne',
  'Luzern',
  'Uri',
  'Schwyz',
  'Obwalden',
  'Nidwalden',
  'Glarus',
  'Zug',
  'Fribourg; Freiburg',
  'Solothurn',
  'Basel-Stadt',
  'Basel-Landschaft',
  'Schaffhausen',
  'Appenzell Ausserrhoden',
  'Appenzell Innerrhoden',
  'St. Gallen',
  'Graubünden; Grischun; Grigioni',
  'Aargau',
  'Thurgau',
  'Ticino',
  'Vaud',
  'Valais; Wallis',
  'Neuchâtel',
  'Genève',
  'Jura',
];

export const argentinaProvinces = [
  'Capital Federal',
  'Buenos Aires',
  'Catamarca',
  'Córdoba',
  'Corrientes',
  'Chaco',
  'Chubut',
  'Entre Ríos',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquén',
  'Río Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego',
  'Tucumán',
];

export const newZealandStates = [
  'Auckland',
  'Bay Of Plenty',
  'Canterbury',
  'Gisborne',
  "Hawke's Bay",
  'Wanganui - Manawatu',
  'Marlborough',
  'Nelson - Tasman',
  'Northland',
  'Otago',
  'Southland',
  'Taranaki',
  'Waikato',
  'Wellington',
  'West Coast',
];

export const philippinesProvinces = [
  'Abra',
  'Agusan del Norte',
  'Agusan del Sur',
  'Aklan',
  'Albay',
  'Antique',
  'Apayao',
  'Aurora',
  'Basilan',
  'Bataan',
  'Batanes',
  'Batangas',
  'Benguet',
  'Biliran',
  'Bohol',
  'Bukidnon',
  'Bulacan',
  'Cagayan',
  'Camarines Norte	Daet',
  'Camarines Sur',
  'Camiguin',
  'Capiz',
  'Catanduanes	Virac',
  'Cavite',
  'Cebu',
  'Cotabato',
  'Mindanao',
  'Davao del Norte',
  'Davao del Sur',
  'Davao Occidental',
  'Davao Oriental',
  'Dinagat Islands',
  'Eastern Samar',
  'Guimaras',
  'Ifugao',
  'Ilocos Norte',
  'Ilocos Sur',
  'Iloilo',
  'Isabela',
  'Kalinga',
  'La Union',
  'Laguna',
  'Lanao del Norte',
  'Lanao del Sur',
  'Leyte',
  'Maguindanao',
  'Marinduque',
  'Masbate',
  'Metro Manila',
  'Misamis Occidental',
  'Misamis Oriental',
  'Mountain Province',
  'Negros Occidental',
  'Negros Oriental',
  'Northern Samar',
  'Nueva Ecija',
  'Nueva Vizcaya',
  'Occidental Mindoro',
  'Oriental Mindoro',
  'Palawan',
  'Pampanga',
  'Pangasinan',
  'Quezon',
  'Quirino',
  'Rizal',
  'Romblon',
  'Samar',
  'Sarangani',
  'Siquijor',
  'Sorsogon',
  'South Cotabato',
  'Southern Leyte',
  'Sultan Kudarat',
  'Sulu',
  'Surigao del Norte',
  'Surigao del Sur',
  'Tarlac',
  'Tawi-Tawi',
  'Zambales',
  'Zamboanga del Norte',
  'Zamboanga del Sur',
  'Zamboanga Sibugay',
];

export const indiaStates = [
  'Andaman Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra Nagar Haveli and Daman Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
];

export const nigeriaStatesLocalGovernmentAreas = {
  Abia: [
    'Aba North',
    'Aba South',
    'Arochukwu',
    'Bende',
    'Ikwuano',
    'Isiala Ngwa North',
    'Isiala Ngwa South',
    'Isuikwuato',
    'Obi Ngwa',
    'Ohafia',
    'Osisioma Ngwa',
    'Ugwunagbo',
    'Ukwa East',
    'Ukwa West',
    'Umuahia North',
    'Umuahia South',
    'Umu Nneochi',
  ],
  Adamawa: [
    'Demsa',
    'Fufore',
    'Ganye',
    'Girei',
    'Gombi',
    'Guyuk',
    'Hong',
    'Jada',
    'Lamurde',
    'Madagali',
    'Maiha',
    'Mayo-Belwa',
    'Michika',
    'Mubi North',
    'Mubi South',
    'Numan',
    'Shelleng',
    'Song',
    'Toungo',
    'Yola North ',
    'Yola South',
  ],
  'Akwa Ibom': [
    'Abak',
    'Eastern Obolo',
    'Eket',
    'Esit-Eket',
    'Essien Udim',
    'Etim-Ekpo',
    'Etinan',
    'Ibeno',
    'Ibesikpo-Asutan',
    'Ibiono-Ibom',
    'Ika',
    'Ikono',
    'Ikot Abasi',
    'Ikot Ekpene',
    'Ini',
    'Itu',
    'Mbo',
    'Mkpat-Enin',
    'Nsit-Atai',
    'Nsit-Ibom',
    'Nsit-Ubium',
    'Obot-Akara',
    'Okobo',
    'Onna',
    'Oron',
    'Oruk Anam',
    'Ukanafun',
    'Udung-Uko',
    'Uruan',
    'Urue-Offong/Oruko',
    'Uyo',
  ],
  Anambra: [
    'Aguata',
    'Awka North',
    'Awka South',
    'Anambra East',
    'Anambra West',
    'Anaocha',
    'Ayamelum',
    'Dunukofia',
    'Ekwusigo',
    'Idemili North',
    'Idemili South',
    'Ihiala',
    'Njikoka',
    'Nnewi North',
    'Nnewi South',
    'Ogbaru',
    'Onitsha North',
    'Onitsha South',
    'Orumba North',
    'Orumba South',
    'Oyi',
  ],
  Bauchi: [
    'Bauchi',
    'Tafawa Balewa',
    'Dass',
    'Toro',
    'Bogoro',
    'Ningi',
    'Warji',
    'Ganjuwa',
    'Kirfi',
    'Alkaleri',
    'Darazo',
    'Misau',
    'Giade',
    'Shira',
    'Jama’are',
    'Katangum',
    'Itas/Gadau',
    'Zaki',
    'Gamawa',
    'Damban',
  ],
  Bayelsa: [
    'Brass',
    'Ekeremor',
    'Kolokuma/Opokuma',
    'Nembe',
    'Ogbia',
    'Sagbama',
    'Southern Ijaw',
    'Yenagoa',
  ],
  Benue: [
    'Ado',
    'Agatu',
    'Apa',
    'Buruku',
    'Gboko',
    'Guma',
    'Gwer East',
    'Gwer West',
    'Katsina-Ala',
    'Konshisha',
    'Kwande',
    'Logo',
    'Makurdi',
    'Obi',
    'Ogbadibo',
    'Ohimini',
    'Oju',
    'Okpokwu',
    'Otukpo',
    'Tarka',
    'Ukum',
    'Ushongo',
    'Vandeikya',
  ],
  Borno: [
    'Hawul',
    'Jere',
    'Kaga',
    'Kala/Balge',
    'Konduga',
    'Kukawa',
    'Kwaya Kusar',
    'Mafa',
    'Magumeri',
    'Maiduguri',
    'Marte',
    'Mobbar',
    'Monguno',
    'Ngala',
    'Nganzai',
    'Shani',
    'Abadam',
    'Askira/Uba',
    'Bama',
    'Bayo',
    'Biu',
    'Chibok',
    'Damboa',
    'Dikwa',
    'Gubio',
    'Guzamala',
    'Gwoza',
  ],
  'Cross River': [
    'Abi',
    'Akamkpa',
    'Akpabuyo',
    'Bekwarra',
    'Bakassi',
    'Biase',
    'Boki',
    'Calabar Municipal',
    'Calabar South',
    'Etung',
    'Ikom',
    'Obanliku',
    'Obubra',
    'Obudu',
    'Odukpani',
    'Ogoja',
    'Yakuur',
    'Yala',
  ],
  Delta: [
    'Aniocha North',
    'Aniocha South',
    'Bomadi',
    'Burutu',
    'Ethiope East',
    'Ethiope West',
    'Ika North East',
    'Ika South',
    'Isoko North',
    'Isoko South',
    'Ndokwa East',
    'Ndokwa West',
    'Okpe',
    'Oshimili North',
    'Oshimili South',
    'Sapele',
    'Udu',
    'Ughelli North',
    'Ughelli South',
    'Ukwuani',
    'Uvwie',
    'Warri South-West',
    'Patani',
    'Warri North',
    'Warri South',
  ],
  Ebonyi: [
    'Abakaliki',
    'Afikpo North',
    'Afikpo South (Edda)',
    'Ebonyi',
    'Ezza North',
    'Ezza South',
    'Ikwo',
    'Ishielu',
    'Ivo',
    'Izzi',
    'Ohaozara',
    'Ohaukwu',
    'Onicha',
  ],
  Edo: [
    'Akoko-Edo',
    'Egor',
    'Esan Central',
    'Esan North-East',
    'Esan South-East',
    'Esan West',
    'Etsako Central',
    'Etsako East',
    'Etsako West',
    'Igueben',
    'Ikpoba-Okha',
    'Oredo',
    'Orhionmwon',
    'Ovia North-East',
    'Ovia South-West',
    'Owan East',
    'Owan West',
    'Uhunmwonde',
  ],
  Ekiti: [
    'Ado-Ekiti',
    'Ikere',
    'Oye',
    'Aiyekire (Gbonyin)',
    'Efon',
    'Ekiti East',
    'Ekiti South-West',
    'Ekiti West',
    'Emure',
    'Ido-Osi',
    'Ijero',
    'Ikole',
    'Ilejemeje',
    'Irepodun/Ifelodun',
    'Ise/Orun',
    'Moba',
  ],
  Enugu: [
    'Aninri',
    'Awgu',
    'Enugu East',
    'Enugu North',
    'Enugu South',
    'Ezeagu',
    'Igbo Etiti',
    'Igbo Eze North',
    'Igbo Eze South',
    'Isi Uzo',
    'Nkanu East',
    'Nkanu West',
    'Nsukka',
    'Oji River',
    'Udenu',
    'Udi',
    'Uzo-UwanI',
  ],
  Gombe: [
    'Akko',
    'Balanga',
    'Billiri',
    'Dukku',
    'Funakaye',
    'Gombe',
    'Kaltungo',
    'Kwami',
    'Nafada',
    'Shongom',
    'Yamaltu/Deba',
  ],
  Imo: [
    'Aboh Mbaise',
    'Ahiazu Mbaise',
    'Ehime Mbano',
    'Ezinihitte Mbaise',
    'Ideato North',
    'Ideato South',
    'Ihitte/Uboma',
    'Ikeduru',
    'Isiala Mbano',
    'Isu',
    'Mbaitoli',
    'Ngor Okpala',
    'Njaba',
    'Nkwerre',
    'Nwangele',
    'Obowo',
    'Oguta',
    'Ohaji/Egbema',
    'Okigwe',
    'Onuimo',
    'Orlu',
    'Orsu',
    'Oru East',
    'Oru West',
    'Owerri Municipal',
    'Owerri North',
    'Owerri West',
    'Njaba South',
  ],
  Jigawa: [
    'Auyo',
    'Babura',
    'Biriniwa',
    'Birnin Kudu',
    'Buji',
    'Dutse',
    'Gagarawa',
    'Garki',
    'Gumel',
    'Guri',
    'Gwaram',
    'Gwiwa',
    'Hadejia',
    'Jahun',
    'Kafin Hausa',
    'Kaugama',
    'Kazaure',
    'Kiri Kasama',
    'Kiyawa',
    'Maigatari',
    'Malam Madori',
    'Miga',
    'Ringim',
    'Roni',
    'Sule Tankarkar',
    'Taura',
    'Yankwashi',
  ],
  Kaduna: [
    'Birnin Gwari',
    'Chikun',
    'Giwa',
    'Igabi',
    'Ikara',
    'Jaba',
    'Jema’a',
    'Kachia',
    'Kaduna North',
    'Kaduna South',
    'Kagarko',
    'Kajuru',
    'Kaura',
    'Kauru',
    'Kubau',
    'Kudan',
    'Lere',
    'Makarfi',
    'Sabon Gari',
    'Sanga',
    'Soba',
    'Zangon Kataf',
    'Zaria',
  ],
  Kano: [
    'Ajingi',
    'Albasu',
    'Bagwai',
    'Bebeji',
    'Bichi',
    'Bunkure',
    'Dala',
    'Dambatta',
    'Dawakin Kudu',
    'Dawakin Tofa',
    'Doguwa',
    'Fagge',
    'Gabasawa',
    'Garko',
    'Garum Mallam',
    'Gaya',
    'Gezawa',
    'Gwale',
    'Gwarzo',
    'Kabo',
    'Kano Municipal',
    'Karaye',
    'Kibiya',
    'Kiru',
    'kumbotso',
    'Kunchi',
    'Kura',
    'Madobi',
    'Makoda',
    'Minjibir',
    'Nasarawa',
    'Rano',
    'Rimin Gado',
    'Rogo',
    'Shanono',
    'Sumaila',
    'Takai',
    'Tarauni',
    'Tofa',
    'Tsanyawa',
    'Tudun Wada',
    'Ungogo',
    'Warawa',
    'Wudil',
  ],
  Katsina: [
    'Bakori',
    'Batagarawa',
    'Batsari',
    'Baure',
    'Bindawa',
    'Charanchi',
    'Dan Musa',
    'Dandume',
    'Danja',
    'Daura',
    'Dutsi',
    'Dutsin-Ma',
    'Faskari',
    'Funtua',
    'Ingawa',
    'Jibia',
    'Kafur',
    'Kaita',
    'Kankara',
    'Kankia',
    'Katsina',
    'Kurfi',
    'Kusada',
    "Mai'Adua",
    'Malumfashi',
    'Mani',
    'Mashi',
    'Matazu',
    'Musawa',
    'Rimi',
    'Sabuwa',
    'Safana',
    'Sandamu',
    'Zango',
  ],
  Kebbi: [
    'Aleiro',
    'Arewa Dandi',
    'Argungu',
    'Augie',
    'Bagudo',
    'Birnin Kebbi',
    'Bunza',
    'Dandi',
    'Fakai',
    'Gwandu',
    'Jega',
    'Kalgo',
    'Koko/Besse',
    'Maiyama',
    'Ngaski',
    'Sakaba',
    'Shanga',
    'Suru',
    'Wasagu',
    'Yauri',
    'Zuru',
  ],
  Kogi: [
    'Adavi',
    'Ajaokuta',
    'Ankpa',
    'Bassa',
    'Dekina',
    'Ibaji',
    'Idah',
    'Igalamela-Odolu',
    'Ijumu',
    'Kabba/Bunu',
    'Koton Karfe',
    'Lokoja',
    'Mopa-Muro',
    'Ofu',
    'Ogori/Magongo',
    'Okehi',
    'Okene',
    'Olamaboro',
    'Omala',
    'Yagba East',
    'Yagba West',
  ],
  Kwara: [
    'Asa',
    'Baruten',
    'Edu',
    'Ekiti',
    'Ifelodun',
    'Ilorin East',
    'Ilorin South',
    'Ilorin West',
    'Irepodun',
    'Isin',
    'Kaiama',
    'Moro',
    'Offa',
    'Oke Ero',
    'Oyun',
    'Pategi',
  ],
  Lagos: [
    'Agege',
    'Alimosho',
    'Apapa',
    'Ifako-Ijaye',
    'Ikeja',
    'Kosofe',
    'Mushin',
    'Oshodi-Isolo',
    'Shomolu',
    'Eti-Osa',
    'Lagos Island',
    'Lagos Mainland',
    'Surulere',
    'Ojo',
    'Ajeromi-Ifelodun',
    'Amuwo-Odofin',
    'Badagry',
    'Ikorodu',
    'Ibeju-Lekki',
    'Epe',
  ],
  Nasarawa: [
    'Karu',
    'Keffi',
    'Kokona',
    'Nasarawa',
    'Toto',
    'Akwanga',
    'Eggon',
    'Wamba',
    'Awe',
    'Doma',
    'Keana',
    'Lafia',
    'Obi',
  ],
  Niger: [
    'Agaie',
    'Agwara',
    'Bida',
    'Borgu',
    'Bosso',
    'Chanchaga',
    'Edati',
    'Gbako',
    'Gurara',
    'Katcha',
    'Kontagora',
    'Lapai',
    'Lavun',
    'Magama',
    'Mariga',
    'Mashegu',
    'Mokwa',
    'Munya',
    'Paikoro',
    'Rafi',
    'Rijau',
    'Shiroro',
    'Suleja',
    'Tafa',
    'Wushishi',
  ],
  Ogun: [
    'Abeokuta North',
    'Abeokuta South',
    'Ado-Odo/Ota',
    'Ewekoro',
    'Ifo',
    'Ijebu East',
    'Ijebu North',
    'Ijebu North-East',
    'Ijebu Ode',
    'Ikenne',
    'Imeko Afon',
    'Ipokia',
    'Obafemi Owode',
    'Odogbolu',
    'Odeda',
    'Ogun Waterside',
    'Remo North',
    'Sagamu',
    'Yewa North (formerly Egbado North)',
    'Yewa South (formerly Egbado South)',
  ],
  Ondo: [
    'Akoko North-East',
    'Akoko North-West',
    'Akoko South-East',
    'Akoko South-West',
    'Akure North',
    'Akure South',
    'Ese Odo',
    'Idanre',
    'Ifedore',
    'Ilaje',
    'Ile Oluji/Okeigbo',
    'Irele',
    'Odigbo',
    'Okitipupa',
    'Ondo East',
    'Ondo West',
    'Ose',
    'Owo',
  ],
  Osun: [
    'Aiyedaade',
    'Aiyedire',
    'Atakunmosa East',
    'Atakunmosa West',
    'Boluwaduro',
    'Boripe',
    'Ede North',
    'Ede South',
    'Egbedore',
    'Ejigbo',
    'Ife Central',
    'Ife East',
    'Ife North',
    'Ife South',
    'Ifedayo',
    'Ifelodun',
    'Ila',
    'Ilesa East',
    'Ilesa West',
    'Irepodun',
    'Irewole',
    'Isokan',
    'Iwo',
    'Obokun',
    'Odo Otin',
    'Ola Oluwa',
    'Olorunda',
    'Oriade',
    'Orolu',
    'Osogbo',
  ],
  Oyo: [
    'Afijio Jobele',
    'Akinyele Moniya',
    'Atiba Ofa Meta',
    'Atisbo Tede-Okeogun',
    'Egbeda Egbeda',
    'Ibadan North Agodi Gate',
    'Ibadan North-East Iwo Road',
    'Ibadan North-West Dugbe/Onireke',
    'Ibadan South-East Mapo',
    'Ibadan South-West Ring Road',
    'Ibarapa Central Igbo Ora',
    'Ibarapa East Eruwa',
    'Ibarapa North Ayete',
    'Ido Ido',
    'Irepo Kisi-Okeogun',
    'Iseyin Iseyin-Okeogun',
    'Itesiwaju Otu-Okeogun',
    'Iwajowa Iwereile-Okeogun',
    'Kajola Okeho-Okeogun',
    'Lagelu Iyanaofa',
    'Ogbomosho North Ogbomoso',
    'Ogbomosho South Arowomole',
    'Ogo Oluwa Ajawa',
    'Olorunsogo Igbeti-Okeogun',
    'Oluyole Idi Ayunre',
    'Ona Ara Akanran',
    'Orelope Igboho-Okeogun',
    'Ori Ire Ikoyi',
    'Oyo East Kosobo',
    'Oyo West Ojongbodu',
    'Saki East Agoamodu-Okeogun',
    'Saki West Shaki-Okeogun',
    'Surulere Iresa Adu',
  ],
  Plateau: [
    'Barkin Ladi',
    'Bassa',
    'Bokkos',
    'Jos East',
    'Jos North',
    'Jos South',
    'Kanam',
    'Kanke',
    'Langtang North',
    'Langtang South',
    'Mangu',
    'Mikang',
    'Pankshin',
    "Qua'an Pan",
    'Riyom',
    'Shendam',
    'Wase',
  ],
  Rivers: [
    'Port Harcourt',
    'Obio-Akpor',
    'Okrika',
    'Ogu–Bolo',
    'Eleme',
    'Tai',
    'Gokana',
    'Khana',
    'Oyigbo',
    'Opobo–Nkoro',
    'Andoni',
    'Bonny',
    'Degema',
    'Asari-Toru',
    'Akuku-Toru',
    'Abua–Odual',
    'Ahoada West',
    'Ahoada East',
    'Ogba–Egbema–Ndoni',
    'Emohua',
    'Ikwerre',
    'Etche',
    'Omuma',
  ],
  Sokoto: [
    'Binji',
    'Bodinga',
    'Dange Shuni',
    'Gada',
    'Goronyo',
    'Gudu',
    'Gwadabawa',
    'Illela',
    'Isa',
    'Kebbe',
    'Kware',
    'Rabah',
    'Sabon Birni',
    'Shagari',
    'Silame',
    'Sokoto North',
    'Sokoto South',
    'Tambuwal',
    'Tangaza',
    'Tureta',
    'Wamako',
    'Warn',
    'Yabo',
  ],
  Taraba: [
    'Ardo Kola',
    'Bali',
    'Donga',
    'Gashaka',
    'Gassol',
    'Ibi',
    'Jalingo',
    'Karim Lamido',
    'Kurmi',
    'Lau',
    'Sardauna',
    'Takum',
    'Ussa',
    'Wukari',
    'Yorro',
    'Zing',
  ],
  Yobe: [
    'Bade',
    'Bursari',
    'Damaturu',
    'Geidam',
    'Gujba',
    'Gulani',
    'Fika',
    'Fune',
    'Jakusko',
    'Karasuwa',
    'Machina',
    'Nangere',
    'Nguru',
    'Potiskum',
    'Tarmuwa',
    'Yunusari',
    'Yusufari',
  ],
  Zamfara: [
    'Anka',
    'Bakura',
    'Birnin Magaji/Kiyaw',
    'Bukkuyum',
    'Bungudu',
    'Chafe (Tsafe)',
    'Gummi',
    'Gusau',
    'Kaura Namoda',
    'Maradun',
    'Maru',
    'Shinkafi',
    'Talata Mafara',
    'Zurmi',
  ],
  'Federal Capital Territory': ['Abaji', 'Abuja', 'Bwari', 'Gwagwalada', 'Kuje', 'Kwali'],
};

export const abuDhabiResidentialLocations = [
  'Nakhlat Deira',
  'Al Corniche',
  'Al Ras',
  'Al Daghaya',
  'Al Buteen',
  'Al Sabkha',
  'Ayal Nasir',
  'Al Murar',
  'Naif',
  'Al Rigga',
  'Corniche Deira',
  'Al Baraha',
  'Al Muteena',
  'Al Muraqqabat',
  'Riggat Al Buteen',
  'Abu Hail',
  'Hor Al Anz',
  'Al Khabaisi',
  'Port Saeed',
  'Al Hamriya Port',
  'Al Wuheida',
  'Hor Al Anz East',
  'Al Mamzar',
  'Nadd Shamma',
  'Al Garhoud',
  'Umm Ramool',
  'Al Rashidiya',
  "Dubai Int'l Airport",
  'Al Twar First',
  'Al Twar Second',
  'Al Twar Third',
  'Al Nahda First',
  'Al Qusais First',
  'Al Qusais Second',
  'Al Qusais Third',
  'Al Nahda Second',
  'Al Qusais Ind. First',
  'Al Qusais Ind. Second',
  'Muhaisanah Third',
  'Muhaisanah Fourth',
  'Al Qusais Ind. Third',
  'Al Qusais Ind. Fourth',
  'Al Qusais Ind. Fifth',
  'Mirdif',
  'Mushraif',
  'Muhaisnah First',
  'Al Mizhar First',
  'Al Mizhar Second',
  'Muhaisanah Second',
  'Oud Al Muteena First',
  'Oud Al Muteena Second',
  'Muhaisanah Fifth',
  'Oud Al Muteena Third',
  'Wadi Alamardi',
  'Al Khwaneej First',
  'Al Khwaneej Second',
  'Aleyas',
  'Al Ttay',
  'World Island',
  'Jumeira Island One',
  'Al Shindagha',
  'Al Souq Al Kabeer',
  'Al Hamriya',
  'Umm Hurair First',
  'Umm Hurair Second',
  'Al Raffa',
  'Mankhool',
  'Al Karama',
  'Oud Metha',
  'Madinat Dubai Al Melaheyah',
  'Al Hudaiba',
  'Al Jafiliya',
  'Al Kifaf',
  "Za'abeel First",
  'Al Jadaf',
  'Jumeira First',
  "Al Bada'a",
  'Al Satwa',
  'Trade Center First',
  'Trade Center Second',
  "Za'abeel Second",
  'Jumeira Second',
  'Al Wasl',
  'Burj Khalifa',
  'Buisness Bay',
  'Al Merkadh',
  'Jumeira Third',
  'Al Safa First',
  'Al Qouz First',
  'Al Qouz Second',
  'Umm Suqeim First',
  'Al Safa Second',
  'Al Qouz Third',
  'Al Qouz Fourth',
  'Umm Suqeim Second',
  'Al Manara',
  'Al Qouze Ind.first',
  'Al Qouze Ind.second',
  'Umm Suqeim Third',
  'Umm Al Sheif',
  'Al Qouze Ind.third',
  'Al Qouze Ind.fourth',
  'Al Safouh First',
  'Al Barsha First',
  'Al Barsha Third',
  'Al Barsha Second',
  'Nakhlat Jumeira',
  'Al Safouh Second',
  'Al Thanyah First',
  'Al Thanyah Second',
  'Al Thanyah Third',
  'Marsa Dubai',
  'Al Thanyah Fifth',
  'Al Thanyah Fourth',
  'Al Kheeran',
  'Ras Al Khor',
  'Al Khairan First',
  'Al Khairan Second',
  'Nadd Al Hamar',
  "Al Warqa'a First",
  "Al Warqa'a Second",
  "Al Warqa'a Third",
  "Al Warqa'a Fourth",
  "Al Warqa'a Fifth",
  'Wadi Alshabak',
  'Nakhlat Jabal Ali',
  'Al Wajehah Al Bhariyah',
  'Hessyan First',
  'Hessyan Second',
  'Saih Shuaib 1',
  'Jabal Ali Industrial Third',
  'Jabal Ali Industrial Second',
  'Madinat Al Mataar',
  'Saih Shuaib 2',
  'Saih Shuaib 3',
  'Saih Shuaib 4',
  'Jabal Ali First',
  'Jabal Ali Second',
  'Jabal Ali Third',
  'Mena Jabal Ali',
  'Dubai Investment Park Second',
  'Dubai Investment Park First',
  'Jabal Ali Industrial First',
  'Bu Kadra',
  'Ras Al Khor Ind. First',
  'Ras Al Khor Ind. Second',
  'Ras Al Khor Ind. Third',
  'Nadd Al Shiba Second',
  'Nadd Al Shiba Third',
  'Nadd Al Shiba Fourth',
  'Nadd Al Shiba First',
  'Warsan First',
  'Warsan Second',
  'Warsan Fourth',
  'Nadd Hessa',
  'Hadaeq Sheikh Mohammed Bin Rashid',
  'Wadi Al Safa 2',
  'Wadi Al Safa 3',
  'Wadi Al Safa 4',
  'Wadi Al Safa 5',
  'Wadi Al Asafa 6',
  'Wadi Al Asafa 7',
  'Al Barsha South First',
  'Al Barsha South Second',
  'Al Barsha South Third',
  'Al Hebiah First',
  'Al Hebiah Second',
  'Al Hebiah Third',
  'Al Barsha South Fourth',
  'Al Hebiah Fourth',
  'Al Hebiah Fifth',
  'Al Barsha South Fifth',
  "Me'aisem First",
  "Me'aisem Second",
  'Al Awir First',
  'Al Awir Second',
  'Enkhali',
  'Al Wohoosh',
  'Lehbab First',
  'Al Meryal',
  'Nazwah',
  'Warsan Third',
  'Al Rowaiyah First',
  'Al Rowaiyah Second',
  'Al Rowaiyah Third',
  'Mereiyeel',
  'Umm Al Daman',
  'Le Hemaira',
  'Lehbab Second',
  "Umm Al Mo'meneen",
  'Margham',
  'Al Maha',
  'Umm Eselay',
  'Remah',
  'Margab',
  'Yaraah',
  'Hatta',
  'Umm Nahad First',
  'Umm Nahad Second',
  'Umm Nahad Third',
  'Umm Nahad Fourth',
  'Al Yufrah 1',
  'Al Yufrah 2',
  'Al Marmoom',
  'Al Yalayis 1',
  'Al Yalayis 2',
  'Al Yalayis 3',
  'Al Yalayis 4',
  'Al Yalayis 5',
  'Al Lesaily',
  'Grayteesah',
  "Al Fagaa'",
  'Saih Al Salam',
  'Al Hathmah',
  'Al Selal',
  'Ghadeer Barashy',
  'Saih Al Dahal',
  "Al O'shoosh",
  "Saih Shua'alah",
  'Mugatrah',
  'Al Layan 1',
  'Al Layan 2',
  'Hefair',
  'Other Emirates (Outside Dubai)',
  'Dubai (District Unknown)',
];

// While not all provinces are enabled, we'll leave 'inactive'
// provinces commented out so that they can be easily activated.
export const availableCanadianProvinces = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  // NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  // NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  // YT: 'Yukon',
};

export const availableUSStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IN: 'Indiana',
  ID: 'Idaho',
  IL: 'Illinois',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MD: 'Maryland',
  MA: 'Massachusetts',
  ME: 'Maine',
  MI: 'Michigan',
  MO: 'Missouri',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  DC: 'Washington DC',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

// While not all provinces are enabled, we'll leave 'inactive'
// probation periods commented out so that they can be easily activated.
export const availableCanadianProbationPeriods = {
  AB: {
    min: 0,
    max: 90,
    unit: 'day',
  },
  BC: {
    min: 0,
    max: 3,
    unit: 'month',
  },
  MB: {
    min: 0,
    max: 30,
    unit: 'day',
  },
  NB: {
    min: 0,
    max: 6,
    unit: 'month',
  },
  NL: {
    min: 0,
    max: 3,
    unit: 'month',
  },
  // NT: {},
  NS: {
    min: 0,
    max: 3,
    unit: 'month',
  },
  // NU: {},
  ON: {
    min: 0,
    max: 3,
    unit: 'month',
  },
  PE: {
    min: 0,
    max: 6,
    unit: 'month',
  },
  QC: {
    min: 0,
    max: 3,
    unit: 'month',
  },
  SK: {
    min: 0,
    max: 13,
    unit: 'week',
  },
  // YT: {}
};

export const spainAutonomousCommunities = [
  'Andalusia',
  'Aragon',
  'Asturias',
  'Balearic Islands',
  'Basque Country',
  'Canary Islands',
  'Cantabria',
  'Castile and León',
  'Castilla–La Mancha',
  'Catalonia',
  'Extremadura',
  'Galicia',
  'La Rioja',
  'Madrid',
  'Murcia',
  'Navarre',
  'Valencia',
  'Ceuta',
  'Melilla',
];

export const italyProvinces = [
  'Agrigento',
  'Alessandria',
  'Ancona',
  'Arezzo',
  'Ascoli Piceno',
  'Asti',
  'Avellino',
  'Bari',
  'Barletta-Andria-Tr.',
  'Belluno',
  'Benevento',
  'Bergamo',
  'Biella',
  'Bologna',
  'Bolzano',
  'Brescia',
  'Brindisi',
  'Cagliari',
  'Caltanisetta',
  'Campobasso',
  'Caserta',
  'Catania',
  'Catanzaro',
  'Chieti',
  'Como',
  'Cosenza',
  'Cremona',
  'Crotone',
  'Cuneo',
  'Enna',
  'Fermo',
  'Ferrara',
  'Florence',
  'Foggia',
  'Forli/Cesana',
  'Frosinone',
  'Genova',
  'Grosseto',
  'Imperia',
  'Isernia',
  "L'Aquila",
  'La Spezia',
  'Latina',
  'Lecce',
  'Lecco',
  'Livorno',
  'Lodi',
  'Lucca',
  'Macerata',
  'Mantova',
  'Massa Carrara',
  'Matera',
  'Messina',
  'Milan',
  'Modena',
  'Monza e Brianza',
  'Naples',
  'Novara',
  'Nuoro',
  'Oristano',
  'Padova',
  'Palermo',
  'Parma',
  'Pavia',
  'Perugia',
  'Pesaro-Urbino',
  'Pescara',
  'Piacenza',
  'Pisa',
  'Pistoia',
  'Potenza',
  'Prato',
  'Ragusa',
  'Ravenna',
  'Reggio Calabria',
  'Reggio Emilia',
  'Rieti',
  'Rimini',
  'Rome',
  'Rovigo',
  'Salerno',
  'Sassari',
  'Savona',
  'Siena',
  'Siracusa',
  'Sondrio',
  'South Sardinia',
  'Taranto',
  'Teramo',
  'Terni',
  'Trapani',
  'Trento',
  'Treviso',
  'Turin',
  'Varese',
  'Venice',
  'Verbania',
  'Vercelli',
  'Verona',
  'Vibo Valentia',
  'Vicenza',
  'Viterbo',
];

export const irelandCounties = [
  'Carlow',
  'Cavan',
  'Clare',
  'Cork',
  'Donegal',
  'Dublin',
  'Galway',
  'Kerry',
  'Kildare',
  'Kilkenny',
  'Laois',
  'Leitrim',
  'Limerick',
  'Longford',
  'Louth',
  'Mayo',
  'Meath',
  'Monaghan',
  'Offaly',
  'Roscommon',
  'Sligo',
  'Tipperary',
  'Waterford',
  'Westmeath',
  'Wexford',
  'Wicklow',
];
