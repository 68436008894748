import { useGet } from '@remote-com/data-layer';

import { requestStatus } from '@/src/domains/employment/requests/constants';
import { requestTypes } from '@/src/domains/employment/requestsHub/shared/constants';
import { pickKey } from '@/src/helpers/general';
import { commonMobilityRequestStatuses } from '@/src/views/rivendell/users/Details/components/Mobility/constants';

const mapRequestStatusToTableStatus = (status) => {
  switch (status) {
    case commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL:
    case commonMobilityRequestStatuses.APPROVED_BY_MANAGER:
      return requestStatus.IN_REVIEW;
    case commonMobilityRequestStatuses.DECLINED_BY_REMOTE:
    case commonMobilityRequestStatuses.DECLINED_BY_MANAGER:
      return requestStatus.CANCELED;
    case commonMobilityRequestStatuses.APPROVED_BY_REMOTE:
      return requestStatus.DONE;
    case commonMobilityRequestStatuses.CANCELLED:
      return requestStatus.CANCELED;
    default:
      return requestStatus.SUBMITTED;
  }
};

const formatStatusParams = (statuses = []) => {
  const mappedStatusesParam = [];
  statuses?.forEach((status) => {
    switch (status) {
      case requestStatus.IN_REVIEW:
        mappedStatusesParam.push(
          commonMobilityRequestStatuses.APPROVED_BY_MANAGER,
          commonMobilityRequestStatuses.AWAITING_TAX_APPROVAL
        );
        break;
      case requestStatus.CANCELED:
        mappedStatusesParam.push(
          commonMobilityRequestStatuses.DECLINED_BY_REMOTE,
          commonMobilityRequestStatuses.DECLINED_BY_MANAGER,
          commonMobilityRequestStatuses.CANCELLED
        );
        break;
      case requestStatus.DONE:
        mappedStatusesParam.push(commonMobilityRequestStatuses.APPROVED_BY_REMOTE);
        break;
      case requestStatus.SUBMITTED:
        mappedStatusesParam.push(commonMobilityRequestStatuses.PENDING);
        break;
      default:
        break;
    }
  });
  return mappedStatusesParam;
};

const formatApiQueryParams = (apiQueryParams) => {
  const { status } = apiQueryParams;
  // requestType within apiQueryParams is snake_case
  const requestType = pickKey(apiQueryParams, 'requestType');

  return {
    ...apiQueryParams,
    // Filter correctly on requestType
    ...(requestType?.includes(requestTypes.TRAVEL) ? { requestType: null } : { requestType }),
    ...(status ? { status: formatStatusParams(status) } : {}),
  };
};

const formatMobilityRequestData = (data, key) => {
  const updatedRequests = data?.[key]?.map((request) => {
    return {
      ...request,
      status: mapRequestStatusToTableStatus(request.status),
    };
  });
  return { ...data, [key]: updatedRequests };
};

const formatMobilityRequestEmployerData = (data, key) => {
  const updatedRequests = data?.[key]?.map((request) => {
    return {
      ...request,
      employment: { user: request.user },
      status: mapRequestStatusToTableStatus(request.status),
    };
  });
  return { ...data, [key]: updatedRequests };
};

export function useTravelRequestsFetchFunction({
  params: { submittedByEmploymentSlug } = {},
  apiQueryParams = {},
  isEmployer = false,
  isAdmin = false,
}) {
  const { refetch: employeeRequest } = useGet('/api/v1/employee/travel-letter-request', {
    // Retry is false here, if there is a mismatch in the params from employment-requests, it could cause this to fail.
    // Allowing for retry will elongate the loading unnecessarily
    options: {
      enabled: !isEmployer && !isAdmin,
      retry: false,
      select: ({ data }) => formatMobilityRequestData(data, 'travelLetterRequests'),
    },
    params: {
      queryParams: {
        employmentSlug: submittedByEmploymentSlug,
        ...formatApiQueryParams(apiQueryParams),
      },
    },
  });

  const { refetch: employerRequest } = useGet('/api/v1/employer/mobility/travel-letter-request', {
    // Retry is false here, if there is a mismatch in the params from employment-requests, it could cause this to fail.
    // Allowing for retry will elongate the loading unnecessarily
    options: {
      enabled: isEmployer && !isAdmin,
      retry: false,
      select: ({ data }) => formatMobilityRequestEmployerData(data, 'travelLetterRequests'),
    },
    params: {
      queryParams: {
        ...formatApiQueryParams(apiQueryParams),
      },
    },
  });

  return isEmployer ? employerRequest : employeeRequest;
}

export function useRemoteWorkingRequestsFetchFunction({
  params: { submittedByEmploymentSlug } = {},
  apiQueryParams = {},
  isEmployer = false,
  isAdmin = false,
}) {
  const { refetch: employeeRequest } = useGet('/api/v1/employee/remote-work-request', {
    // Retry is false here, if there is a mismatch in the params from employment-requests, it could cause this to fail.
    // Allowing for retry will elongate the loading unnecessarily
    options: {
      enabled: !isEmployer && !isAdmin,
      retry: false,
      select: ({ data }) => formatMobilityRequestData(data, 'remoteWorkRequests'),
    },
    params: {
      queryParams: {
        employmentSlug: submittedByEmploymentSlug,
        ...formatApiQueryParams(apiQueryParams),
      },
    },
  });

  const { refetch: employerRequest } = useGet('/api/v1/employer/mobility/remote-work-request', {
    // Retry is false here, if there is a mismatch in the params from employment-requests, it could cause this to fail.
    // Allowing for retry will elongate the loading unnecessarily
    options: {
      enabled: isEmployer && !isAdmin,
      retry: false,
      select: ({ data }) => formatMobilityRequestEmployerData(data, 'remoteWorkRequests'),
    },
    params: {
      queryParams: {
        ...formatApiQueryParams(apiQueryParams),
      },
    },
  });

  return isEmployer ? employerRequest : employeeRequest;
}
