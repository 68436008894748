import { Root, Portal, Close, Content, Overlay } from '@radix-ui/react-dialog';
import { Box, Card, SROnly, Stack } from '@remote-com/norma';
import type { AnimationProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import { transparentize } from 'polished';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useReducedMotion } from '@/src/hooks/useReducedMotion';

const getCardDrawerOverlayMotionProps = (): AnimationProps => {
  return {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };
};

const getCardDrawerContentWrapperMotionProps = (prefersReducedMotion: boolean): AnimationProps => {
  if (prefersReducedMotion) {
    return {
      initial: {
        opacity: 0,
        x: '8px',
      },
      animate: {
        opacity: 1,
        x: 0,
      },
      exit: {
        opacity: 0,
        x: '8px',
      },
    };
  }

  return {
    initial: { x: '120%' },
    animate: {
      x: 0,
      transition: {
        type: 'tween',
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
    exit: {
      x: '120%',
      transition: { type: 'tween', duration: 0.15, ease: 'easeIn' },
    },
  };
};

const CardDrawerWrapper = styled(Box)`
  align-items: flex-start;
  display: flex;
  inset: 0;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.space[5]}px;
  position: fixed;
  z-index: 9999;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: ${({ theme }) => theme.space[6]}px;
  }
`;

const CardDrawerOverlay = styled(motion(Box))`
  backdrop-filter: blur(1.5px);
  background: rgba(15, 20, 25, 0.25);
  inset: 0;
  position: absolute;
`;

const CardDrawerContentWrapper = styled(motion(Stack))`
  max-height: 100%;
  max-width: 480px;
  width: 100%;

  &:focus-visible {
    outline: none;
  }
`;

const CardDrawerContent = styled(Card)`
  --card-drawer-space-x: ${({ theme }) => theme.space[6]}px;
  --card-drawer-space-y: ${({ theme }) => theme.space[5]}px;
  --card-drawer-shadow-color: ${({ theme }) => transparentize(0.85, theme.colors.grey[900])};

  background-color: ${({ theme }) => theme.colors.blank};
  box-shadow: -8px 4px 32px var(--card-drawer-shadow-color);
  border: none;
  overflow: hidden;
  padding: 0;
`;

interface CardDrawerProps extends ComponentPropsWithoutRef<typeof Content> {
  open?: boolean;
  onClose?: () => void;
}

/**
 * A drawer component that slides in from the right side of the screen.
 */
export const CardDrawer = forwardRef<ElementRef<typeof Content>, CardDrawerProps>(
  ({ children, open = false, onClose, ...props }, ref) => {
    /*
      NOTE: When using `react-url-modal`, the component will be removed when `onClose()` is executed.
      This prevents the exit animation from playing. To counter this, we have to handle state internally
      and execute `onClose()` only after the animation has completed.
    */
    const [isReallyVisible, setIsReallyVisible] = useState(open);

    useEffect(() => {
      if (open) {
        setIsReallyVisible(open);
      }
    }, [open]);

    const prefersReducedMotion = useReducedMotion();

    return (
      <AnimatePresence onExitComplete={onClose}>
        {isReallyVisible && (
          <Root open={open} modal onOpenChange={setIsReallyVisible}>
            <Portal>
              <CardDrawerWrapper>
                <Overlay asChild>
                  <CardDrawerOverlay {...getCardDrawerOverlayMotionProps()} />
                </Overlay>
                <Content asChild ref={ref} {...props}>
                  <CardDrawerContentWrapper
                    {...getCardDrawerContentWrapperMotionProps(prefersReducedMotion)}
                  >
                    <Close asChild>
                      <SROnly as="button">Close</SROnly>
                    </Close>
                    <CardDrawerContent>{children}</CardDrawerContent>
                  </CardDrawerContentWrapper>
                </Content>
              </CardDrawerWrapper>
            </Portal>
          </Root>
        )}
      </AnimatePresence>
    );
  }
);
