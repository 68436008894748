import { countryNameWithAlternatives, territoryToCountry } from './constants';

const countryNameWithAlternativesList = Object.values(countryNameWithAlternatives).map(
  (list) => list.map((val) => val.toLowerCase()) // needed for equality check
);

export const getCountryNameWithAlternatives = (countryName = '') => {
  const alternativeNames = countryNameWithAlternativesList.find((names) =>
    names.includes(countryName.toLowerCase())
  );
  // Return the country name when no alternatives are available
  return alternativeNames || [countryName.toLowerCase()];
};

// Use country name and dial code as label to show it as selected value and make it more friendly for screen readers
export const getCountryOptionLabel = (country: any) => {
  return `+${country.dialCode} ${country.name}`;
};

export const filterOption = (option: any, input: string) => {
  const { data } = option;
  const allNamesWithDialCode = [
    ...getCountryNameWithAlternatives(data.label),
    // to simplify the search, we put dial codes with names as they are strings as well
    data.dialCode,
    getCountryOptionLabel(data),
  ];

  return allNamesWithDialCode.some((name) => name.startsWith(input.toLowerCase()));
};

export function getSvgFlagName(country: any) {
  if (!country) return null;

  const countryName = typeof country === 'string' ? country : country.name;
  const flagCountryName = territoryToCountry[countryName] || countryName;
  let name = flagCountryName.toLowerCase();
  name = name.replace(/ /g, '-').replace(/\./g, '');

  // Remove strings between (and including) brackets like: (UAE)
  if (name.indexOf('(') !== -1) {
    name = name.substring(0, name.indexOf('-('));
  }

  return name;
}
