import { makeApiService } from '@/src/services/ApiClient';

export const fetchAllCountries = makeApiService({
  path: `/countries`,
});

export const fetchCompanyCountries = makeApiService({
  path: '/employer/countries',
});

// TODO: it seems we only use it in the employee onboarding
export const fetchEmployerOnboardingCountries = makeApiService({
  path: '/countries/employer-onboarding',
});

export const fetchNationalityCountries = makeApiService({
  path: '/countries/employee-nationality',
});
