// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneGift = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} fillRule="evenodd" clipRule="evenodd" d="M11.25 12.78v-.05a.5.5 0 0 0-.48-.48H4.05c-.28 0-.42 0-.53.05a.5.5 0 0 0-.22.22c-.05.11-.05.25-.05.53v3.3c0 2.24 0 3.36.44 4.22a4 4 0 0 0 1.74 1.74c.86.44 1.98.44 4.22.44h4.7c2.24 0 3.36 0 4.22-.44a4 4 0 0 0 1.74-1.74c.44-.86.44-1.98.44-4.22v-3.3c0-.28 0-.42-.05-.53a.5.5 0 0 0-.22-.22c-.11-.05-.25-.05-.53-.05h-6.72a.5.5 0 0 0-.48.48v3.52a.75.75 0 0 1-1.5 0v-3.47Z" /><path fillRule="evenodd" clipRule="evenodd" d="M6.75 4.5c0-.97.78-1.75 1.75-1.75h.08c.8 0 1.52.53 1.75 1.3l.66 2.2H8.5c-.97 0-1.75-.78-1.75-1.75Zm-.99 1.75a3.25 3.25 0 0 1 2.74-5h.08c1.47 0 2.77.96 3.19 2.37l.23.77.23-.77a3.33 3.33 0 0 1 3.19-2.37h.08a3.25 3.25 0 0 1 2.74 5h2.26a2.25 2.25 0 0 1 0 4.5h-17a2.25 2.25 0 0 1 0-4.5h2.26Zm9.74 0a1.75 1.75 0 1 0 0-3.5h-.08c-.8 0-1.52.53-1.75 1.3L13 6.25h2.49Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneGift);
export { ForwardRef as IconV2DuotoneGift };