import { useCallback, useEffect, useRef } from 'react';

/**
 * React Hooks version of setTimeout.
 *
 * New callback reference will cancel previous callbacka and schedule a new one.
 */
export const useTimeout = (
  // function to call. No args passed.
  // if you create a new callback each render, then previous callback will be cancelled on render.
  callback: CallableFunction = () => {},
  timeout: number = 0
) => {
  const timeoutIdRef = useRef<number | null>();

  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = null;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  useEffect(() => {
    timeoutIdRef.current = setTimeout(callback, timeout);
    return cancel;
  }, [callback, timeout, cancel]);

  return cancel;
};
