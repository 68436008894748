const googleSignInState = {
  isSignedIn: false,
};

/**
 * Zustand store slice with the logic to authenticate/signout the user.
 */
export const useGoogleSignInStore = (set, get) => ({
  ...googleSignInState,
  signOut: () => {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      window.google.accounts.oauth2.revoke(token.access_token);
      window.gapi.client.setToken('');
      set({ isSignedIn: false });
    }
  },
  signIn: () => {
    const { tokenClient } = get();
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      set({ isSignedIn: true });
    };

    if (window.gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: '' });
    }
  },
});
