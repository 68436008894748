import { useState } from 'react';

export const useTooltipModal = (visible = false) => {
  const [isVisible, setIsVisible] = useState(visible);

  const toggle = () => setIsVisible((state) => !state);
  const open = () => setIsVisible(true);
  const close = () => setIsVisible(false);

  return {
    isVisible,
    toggle,
    open,
    close,
  };
};
