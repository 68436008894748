import { FeedbackMessage, FEEDBACK_MESSAGE_ERROR } from '@remote-com/norma';
import type { ReactNode } from 'react';

import { Button } from '@/src/components/Button';
import { Modal } from '@/src/components/Modal';
import {
  ModalActionableOuterWrapper,
  ModalActionableInnerWrapper,
  ModalActionableHeader,
  ModalActionableTitle,
  ModalActionableDescription,
  ModalActionableFooter,
  ModalActionableActions,
} from '@/src/components/Modal/ModalActionable/ModalActionable.styled';
import RemoteLogoLoader from '@/src/components/Ui/Loader/RemoteLoader';
import type { ComponentProps } from '@/types';

export type ModalActionableTone = 'destructive' | 'primary';

type Props = {
  title: ReactNode | string;
  description?: ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  open?: boolean;
  onCancel?: () => void;
  onDismiss?: () => void;
  onConfirm?: () => void;
  errorMessage?: string | null;
  tone?: ModalActionableTone;
  isLoading?: boolean;
  isInitialDataLoading?: boolean;
  isMultiActionable?: boolean;
  cancelButtonProps?: ComponentProps<typeof Button>;
  confirmButtonProps?: ComponentProps<typeof Button>;
  cancelButtonLabel?: string;
  showCancel?: boolean;
  showConfirm?: boolean;
  'data-testid'?: string;
  children?: ReactNode;
};

export function ModalActionable({
  title,
  description,
  confirmLabel,
  cancelLabel = 'Cancel',
  open,
  onCancel,
  onDismiss,
  onConfirm,
  errorMessage,
  tone = 'destructive',
  isLoading,
  isInitialDataLoading,
  'data-testid': dataTestId = undefined, // TODO by @Francisco Sousa: do this to avoid TS errors for consumers of this component. Will refactor soon.
  isMultiActionable,
  confirmButtonProps = {},
  cancelButtonProps = {},
  cancelButtonLabel = 'Cancel',
  showCancel = true,
  showConfirm = true,
  children,
}: Props) {
  return (
    <Modal
      visible={open}
      onCancel={onCancel}
      onDismiss={onDismiss}
      showFooter={false}
      data-testid={dataTestId}
      $maxWidth="500px"
      closeIconButtonSize="md"
    >
      <ModalActionableOuterWrapper>
        {isInitialDataLoading ? (
          <RemoteLogoLoader />
        ) : (
          <ModalActionableInnerWrapper>
            <ModalActionableHeader>
              <ModalActionableTitle>{title}</ModalActionableTitle>
              {description && (
                <ModalActionableDescription>{description}</ModalActionableDescription>
              )}
              {errorMessage && (
                <FeedbackMessage variant={FEEDBACK_MESSAGE_ERROR}>{errorMessage}</FeedbackMessage>
              )}
            </ModalActionableHeader>
            {children}
            <ModalActionableFooter>
              <ModalActionableActions>
                {isMultiActionable && (
                  <Button
                    tone="destructive"
                    variant="outline"
                    isLoading={isLoading}
                    onClick={onCancel}
                    {...cancelButtonProps}
                  >
                    {cancelButtonLabel}
                  </Button>
                )}
                {showConfirm && (
                  <Button
                    tone={tone}
                    variant="solid"
                    isLoading={isLoading}
                    onClick={onConfirm}
                    data-testid="confirm-modal-button"
                    {...confirmButtonProps}
                  >
                    {confirmLabel}
                  </Button>
                )}
              </ModalActionableActions>
              {showCancel && (
                <Button variant="ghost" tone="secondary" onClick={onCancel} disabled={isLoading}>
                  {cancelLabel}
                </Button>
              )}
            </ModalActionableFooter>
          </ModalActionableInnerWrapper>
        )}
      </ModalActionableOuterWrapper>
    </Modal>
  );
}
