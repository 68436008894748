import { Text } from '@remote-com/norma';
import styled from 'styled-components';

const FileNameWrapper = styled(Text).attrs({ variant: 'base', color: 'inputValue' })`
  display: inline-flex;
  max-width: 300px;
  padding-left: ${({ theme }) => theme.space[2]}px;
`;

const FileNameLabel = styled.span`
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const FileNameExtension = styled.span`
  flex-shrink: 0;
`;

const FileNameDisplay = ({ filename = '' }) => {
  const extensionPosition = filename.lastIndexOf('.');
  const name = filename.substr(0, extensionPosition);
  const extension = filename.substr(extensionPosition);

  return (
    <FileNameWrapper title={filename}>
      <FileNameLabel>{name}</FileNameLabel>
      <FileNameExtension>{extension}</FileNameExtension>
    </FileNameWrapper>
  );
};

export default FileNameDisplay;
