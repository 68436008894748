import { Box } from '@remote-com/norma';
import type { KeyboardEventHandler } from 'react';
import { forwardRef } from 'react';

import SearchField from '@/src/components/Table/Components/SearchField';

const messages = {
  label: 'Search profiles',
  placeholder: 'Search…',
} as const;

type PaginatedProfilesMenuSearchPropss = {
  className?: string;
  label?: string;
  onSearch: (query: string) => void;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  value: string;
};

export const PaginatedProfilesMenuSearch = forwardRef<
  HTMLInputElement,
  PaginatedProfilesMenuSearchPropss
>(
  (
    {
      className,
      label = messages.label,
      onSearch,
      placeholder = messages.placeholder,
      value,
      ...props
    },
    ref
  ) => {
    return (
      <Box className={className}>
        <SearchField
          autofocus
          label={label}
          placeholder={placeholder}
          ref={ref}
          setSearchQuery={onSearch}
          value={value}
          variant="secondary"
          width="100%"
          {...props}
        />
      </Box>
    );
  }
);
