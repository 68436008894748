// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineBell = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M4.25 9a7.75 7.75 0 0 1 15.5 0v2.36c0 .55.2 1.08.57 1.5l.87.98a3.17 3.17 0 0 1-1.29 5.08l-.24.1c-.98.35-1.98.63-2.98.85a5.24 5.24 0 0 1-9.36 0c-1-.22-2-.5-2.98-.86l-.24-.09a3.17 3.17 0 0 1-1.3-5.08l.88-.99c.37-.4.57-.94.57-1.49V9Zm5.14 11.21A3.93 3.93 0 0 0 12 21.25c.95 0 1.93-.4 2.6-1.04-1.72.2-3.48.2-5.2 0ZM12 2.75A6.25 6.25 0 0 0 5.75 9v2.36c0 .92-.34 1.8-.95 2.49l-.87.98c-.78.89-.43 2.28.68 2.68l.24.1a20.91 20.91 0 0 0 14.3 0l.24-.1c1.11-.4 1.46-1.8.68-2.68l-.87-.98a3.75 3.75 0 0 1-.95-2.49V9c0-3.45-2.8-6.25-6.25-6.25Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineBell);
export { ForwardRef as IconV2OutlineBell };