import { Pill, Tooltip, Box, WarningTooltip, type WarningTooltipProps } from '@remote-com/norma';
import upperFirst from 'lodash/upperFirst';
import type { ReactNode, FC } from 'react';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';

import { TextCell } from './TextCell';

export const Error = styled(Button).attrs({ variant: 'raw' })`
  display: flex;
  background-color: ${({ theme }) => theme.colors.red[100]};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.red[700]};
  padding: 0 ${({ theme }) => theme.space[3]}px;
  overflow-x: auto;
  max-width: ${(props) => props.maxWidth || 'auto'};
`;

type ErrorTooltipProps = {
  errors: string;
  children: NonNullable<ReactNode>;
};

function ErrorTooltip({ errors, children }: ErrorTooltipProps) {
  return <Tooltip label={upperFirst(errors)}>{children}</Tooltip>;
}

type ErrorCellProps = {
  value?: {
    value?: string;
    errors?: string;
    customError?: {
      label: string;
      description: string;
    };
  };
  CellComponent?: FC<{ value: string | undefined }>;
};

export const ErrorCell = ({
  value: cellValue = {},
  maxWidth = 'auto',
  CellComponent = TextCell,
}: ErrorCellProps & { maxWidth?: string }) => {
  const { value, errors, customError } = cellValue;

  if (customError) {
    return (
      <Tooltip label={upperFirst(customError.description)}>
        <Pill tone="error">{customError.label}</Pill>
      </Tooltip>
    );
  }

  if (!errors) {
    return <CellComponent value={upperFirst(value)} />;
  }

  // Gives precedence to error tooltip instead of text preview tooltip
  const disableCellComponentTooltipProps = !errors ? { tooltipProps: { disabled: true } } : {};
  const missingRequiredField = !value && !!errors;

  return (
    <ErrorTooltip errors={errors}>
      <Error variant="raw" maxWidth={maxWidth}>
        <CellComponent
          value={missingRequiredField ? 'Required' : value}
          {...disableCellComponentTooltipProps}
        />
      </Error>
    </ErrorTooltip>
  );
};

const ErrorBox = styled(Box)<{ $isCompactTable?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.red[100]};

  &:last-child {
    padding-right: ${({ theme }) => theme.space[3]}px;
  }

  &:first-child {
    padding-right: ${({ theme }) => theme.space[3]}px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: ${({ $isCompactTable }) =>
      $isCompactTable ? '3px 12px 3px 12px;' : '8px 18px 8px 18px;'};

    &:last-child {
      padding-right: 20px;
    }
    &:first-child {
      padding-left: 20px;
    }
  }
`;

const CornerBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 10px solid ${({ theme }) => theme.colors.red[600]};
  border-left: 10px solid transparent;
`;

type CornerTooltipProps = Omit<WarningTooltipProps, 'children'>;

const CornerTooltip = ({ content }: CornerTooltipProps) => {
  return (
    <WarningTooltip content={content} position="right">
      {/* Tooltip direct child node must be tab focusable, so tooltip content becomes accessible by keyboard */}
      <CornerBox tabIndex={0} />
    </WarningTooltip>
  );
};

type ErrorCellWithCornerTooltipProps = {
  value?: {
    value?: string;
    errors?:
      | {
          title: string;
          description: string;
        }
      | Array<{ title: string; description: string }>;
  };
  CellComponent?: FC<{ value: string | undefined }>;
};

export const ErrorCellWithCornerTooltip = ({
  value: cellValue = {},
  CellComponent = TextCell,
}: ErrorCellWithCornerTooltipProps) => {
  const { value, errors } = cellValue;

  return (
    <ErrorBox>
      {errors && <CornerTooltip content={errors} />}
      <CellComponent value={value} />
    </ErrorBox>
  );
};
