import styled from 'styled-components';

import { Box } from '../../layout';

import type { BoxedIconAppearance, BoxedIconSize, BoxedIconTone } from './BoxedIcon';

const getBackgroundColor = ($appearance: BoxedIconAppearance, $tone: BoxedIconTone): string => {
  if ($appearance === 'default') {
    if ($tone === 'brand') {
      return `${$tone}.50`;
    }
    return `${$tone}.100`;
  }
  return 'blank';
};

const attributesinPixels: {
  [key in BoxedIconSize]: {
    iconHeight: number;
    wrapperHeight: number;
  };
} = {
  sm: {
    iconHeight: 16,
    wrapperHeight: 34,
  },
  md: {
    iconHeight: 20,
    wrapperHeight: 40,
  },
  lg: {
    iconHeight: 24,
    wrapperHeight: 48,
  },
  xl: {
    iconHeight: 24,
    wrapperHeight: 56,
  },
};

type WrapperProps = {
  $appearance: BoxedIconAppearance;
  $size: BoxedIconSize;
  $tone: BoxedIconTone;
};

export const Wrapper = styled(Box).attrs(({ $appearance, $tone }: WrapperProps) => ({
  backgroundColor: getBackgroundColor($appearance, $tone),
  color: `${$tone}.600`,
}))<WrapperProps>`
  aspect-ratio: 1/1;
  border-radius: ${({ $size, theme }) =>
    $size === 'sm' ? theme.borderRadius.sm : theme.borderRadius.md};
  display: grid;
  height: ${({ $size }) => `${attributesinPixels[$size].wrapperHeight}px`};
  place-items: center;
`;

type IconProps = {
  $size: BoxedIconSize;
};

export const Icon = styled.svg<IconProps>`
  height: ${({ $size }) => `${attributesinPixels[$size].iconHeight}px`};
`;
