import { Item } from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import { normaMenuItemStyles } from '../menu-components/MenuItem.styled';

export { Divider, StyledIcon, StyledMenuLinkWrapper } from '../menu-components/MenuItem.styled';

export const StyledMenuItem = styled(Item)`
  ${normaMenuItemStyles}
`;
