import type { ReportsApi } from '@/src/api/config/employ/reports.types';
import type { Amount } from '@/src/api/config/employ/shared.types';
import {
  EmployerCountryMultiSelectFilter,
  DateRangeFilter,
  MultiSelectFilter,
  CountryCell,
  DateCell,
  DateWithTimeCell,
  ShortSlugCellWithCopy,
  CountrySelectFilter,
} from '@/src/components/Table';
import { TooltipHeader } from '@/src/components/Table/Headers';
import type { ColumnType } from '@/src/components/Table/types';
import {
  HIRE_TYPE_LABELS,
  employmentType,
  employmentTypeOptions,
} from '@/src/domains/employment/constants';
import { PersonCellWithContractorProduct } from '@/src/domains/paidContractorProduct/employer/PersonCellWithContractorProduct';
import { currencyForRow } from '@/src/domains/reports/helpers';
import { typeofHireColumn } from '@/src/domains/team/shared/columns';
import { friendlyMoneyWithoutCurrency } from '@/src/helpers/currency';
import { formatFullMonthDayYear, formatFullMonthDayYearHourMinutes } from '@/src/helpers/date';
import { friendlyLabel } from '@/src/helpers/general';
import {
  DIRECT_REPORTS_ONLY,
  NAME_COLUMN_ID,
  PROBATION_END_DATE_COLUMN_ID,
} from '@/src/views/dashboard/people/PeopleRoot/constants';
import type { $TSFixMe } from '@/types';

import {
  exportFriendlyMoneyWithoutCurrency,
  exportMoneyValue,
  MoneyCell,
  MoneyWithoutCurrencyCell,
} from './cells';
import { monthColumn } from './columns';
import {
  eorTypeOfInvoiceOptions,
  contractorInvoiceLabelOptions,
  eorInvoiceTypeLabels,
  DirectReportsFilter,
} from './filters';

export const columnDefinitions: Record<string, ColumnType[]> = {
  'gross-to-net-summary': [
    monthColumn(true),
    {
      Header: 'Countries',
      id: 'countrySlugs',
      accessor: ({ countriesCount }: { countriesCount: number }) => countriesCount || 0,
      disableSortBy: true,
      align: 'right',
      Filter: (props: $TSFixMe) => (
        <EmployerCountryMultiSelectFilter {...props} valueField="slug" />
      ),
    },
    {
      Header: 'Employees',
      id: 'employeesCount',
      accessor: 'employeesCount',
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: (
        <TooltipHeader tooltip="Base salary, Benefits, Expenses, Incentives & Other">
          Gross amount
        </TooltipHeader>
      ),
      id: 'grossPayTotalAmount',
      accessor: 'grossPayTotalAmount',
      Cell: MoneyCell,
      exportData: ({ grossPayTotalAmount }: { grossPayTotalAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(grossPayTotalAmount.convertedAmount, {
          impliedCurrencyCode: grossPayTotalAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Salary',
      id: 'compensationBaseSalaryAmount',
      accessor: 'compensationBaseSalaryAmount',
      Cell: MoneyCell,
      exportData: ({ compensationBaseSalaryAmount }: { compensationBaseSalaryAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(compensationBaseSalaryAmount.convertedAmount, {
          impliedCurrencyCode: compensationBaseSalaryAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: (
        <TooltipHeader tooltip="This includes both cash and non-cash benefits">
          Benefits
        </TooltipHeader>
      ),
      id: 'compensationBenefitsAmount',
      accessor: 'compensationBenefitsAmount',
      Cell: MoneyCell,
      exportData: ({ compensationBenefitsAmount }: { compensationBenefitsAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(compensationBenefitsAmount.convertedAmount, {
          impliedCurrencyCode: compensationBenefitsAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Benefits Cash',
      id: 'compensationBenefitsCashAmount',
      accessor: 'compensationBenefitsCashAmount',
      Cell: MoneyCell,
      exportData: ({
        compensationBenefitsCashAmount,
      }: {
        compensationBenefitsCashAmount: Amount;
      }) =>
        friendlyMoneyWithoutCurrency(compensationBenefitsCashAmount.convertedAmount, {
          impliedCurrencyCode: compensationBenefitsCashAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      disableExport: true,
      disableInView: true,
      align: 'right',
    },
    {
      Header: 'Benefits Non-cash',
      id: 'compensationBenefitsNoncashAmount',
      accessor: 'compensationBenefitsNoncashAmount',
      Cell: MoneyCell,
      exportData: ({
        compensationBenefitsNoncashAmount,
      }: {
        compensationBenefitsNoncashAmount: Amount;
      }) =>
        friendlyMoneyWithoutCurrency(compensationBenefitsNoncashAmount.convertedAmount, {
          impliedCurrencyCode: compensationBenefitsNoncashAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      disableExport: true,
      disableInView: true,
      align: 'right',
    },
    {
      Header: 'Incentives',
      id: 'compensationIncentivesAmount',
      accessor: 'compensationIncentivesAmount',
      Cell: MoneyCell,
      exportData: ({ compensationIncentivesAmount }: { compensationIncentivesAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(compensationIncentivesAmount.convertedAmount, {
          impliedCurrencyCode: compensationIncentivesAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Expenses',
      id: 'compensationExpensesAmount',
      accessor: 'compensationExpensesAmount',
      Cell: MoneyCell,
      exportData: ({ compensationExpensesAmount }: { compensationExpensesAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(compensationExpensesAmount.convertedAmount, {
          impliedCurrencyCode: compensationExpensesAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Other',
      id: 'compensationOtherAmount',
      accessor: 'compensationOtherAmount',
      Cell: MoneyCell,
      exportData: ({ compensationOtherAmount }: { compensationOtherAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(compensationOtherAmount.convertedAmount, {
          impliedCurrencyCode: compensationOtherAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Holiday Accruals',
      id: 'accrualHolidayAmount',
      accessor: 'accrualHolidayAmount',
      Cell: MoneyCell,
      exportData: ({ accrualHolidayAmount }: { accrualHolidayAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(accrualHolidayAmount.convertedAmount, {
          impliedCurrencyCode: accrualHolidayAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      disableExport: true,
      disableInView: true,
      align: 'right',
    },
    {
      Header: 'Severance Accruals',
      id: 'accrualSeveranceAmount',
      accessor: 'accrualSeveranceAmount',
      Cell: MoneyCell,
      exportData: ({ accrualSeveranceAmount }: { accrualSeveranceAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(accrualSeveranceAmount.convertedAmount, {
          impliedCurrencyCode: accrualSeveranceAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      disableExport: true,
      disableInView: true,
      align: 'right',
    },
    {
      Header: 'Other Accruals',
      id: 'accrualOtherAmount',
      accessor: 'accrualOtherAmount',
      Cell: MoneyCell,
      exportData: ({ accrualOtherAmount }: { accrualOtherAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(accrualOtherAmount.convertedAmount, {
          impliedCurrencyCode: accrualOtherAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      disableExport: true,
      disableInView: true,
      align: 'right',
    },
    {
      Header: (
        <TooltipHeader tooltip="This includes both statutory and non-statutory deductions.">
          Deductions
        </TooltipHeader>
      ),
      id: 'deductionsTotalAmount',
      accessor: 'deductionsTotalAmount',
      Cell: MoneyCell,
      exportData: ({ deductionsTotalAmount }: { deductionsTotalAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(deductionsTotalAmount.convertedAmount, {
          impliedCurrencyCode: deductionsTotalAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Deductions Statutory',
      id: 'deductionStatutoryTotalAmount',
      accessor: 'deductionStatutoryTotalAmount',
      Cell: MoneyCell,
      exportData: ({ deductionStatutoryTotalAmount }: { deductionStatutoryTotalAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(deductionStatutoryTotalAmount.convertedAmount, {
          impliedCurrencyCode: deductionStatutoryTotalAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      disableExport: true,
      disableInView: true,
      align: 'right',
    },
    {
      Header: 'Deductions Non-Statutory',
      id: 'deductionNonstatutoryTotalAmount',
      accessor: 'deductionNonstatutoryTotalAmount',
      Cell: MoneyCell,
      exportData: ({
        deductionNonstatutoryTotalAmount,
      }: {
        deductionNonstatutoryTotalAmount: Amount;
      }) =>
        friendlyMoneyWithoutCurrency(deductionNonstatutoryTotalAmount.convertedAmount, {
          impliedCurrencyCode: deductionNonstatutoryTotalAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      disableExport: true,
      disableInView: true,
      align: 'right',
    },
    {
      Header: 'Net Pay Adjustment',
      id: 'netPayAdjustmentAmount',
      accessor: 'netPayAdjustmentAmount',
      Cell: MoneyCell,
      exportData: ({ netPayAdjustmentAmount }: { netPayAdjustmentAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(netPayAdjustmentAmount.convertedAmount, {
          impliedCurrencyCode: netPayAdjustmentAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Net amount',
      id: 'netPayTotalAmount',
      accessor: 'netPayTotalAmount',
      Cell: MoneyCell,
      exportData: ({ netPayTotalAmount }: { netPayTotalAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(netPayTotalAmount.convertedAmount, {
          impliedCurrencyCode: netPayTotalAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Employer Contributions',
      id: 'employerContributionAmount',
      accessor: 'employerContributionAmount',
      Cell: MoneyCell,
      exportData: ({ employerContributionAmount }: { employerContributionAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(employerContributionAmount.convertedAmount, {
          impliedCurrencyCode: employerContributionAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Currency',
      id: 'currencyForExportOnly',
      exportData: (data: ReportsApi.ReportData) => currencyForRow(data) ?? '',
      disableSortBy: true,
      disableFilters: true,
      disableInView: true,
    },
  ],
  'workforce-cost-summary': [
    monthColumn(),
    {
      Header: 'Type of hire',
      id: 'typeOfHire',
      accessor: ({ typeOfHire }: { typeOfHire: string }) => {
        if (typeOfHire === employmentType.CONTRACTOR) {
          return HIRE_TYPE_LABELS.CONTRACTOR;
        }

        return HIRE_TYPE_LABELS.EMPLOYEE;
      },
      disableSortBy: true,
      Filter: (props: { column: Object }) => (
        <MultiSelectFilter
          {...props}
          placeholder="Select type of hire"
          options={employmentTypeOptions.filter(({ value }) => value !== employmentType.DIRECT)}
          data-testid="type-of-hire-filter"
        />
      ),
    },
    {
      Header: 'Total amount',
      id: 'totalAmount',
      accessor: 'totalAmount',
      Cell: MoneyCell,
      exportData: ({ totalAmount }: { totalAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(totalAmount.convertedAmount, {
          impliedCurrencyCode: totalAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Currency',
      id: 'currencyForExportOnly',
      accessor: 'totalAmount',
      exportData: ({ totalAmount }: { totalAmount: Amount }) => totalAmount.convertedCurrency.code,
      disableSortBy: true,
      disableFilters: true,
      disableInView: true,
    },
  ],
  'year-to-date-costs-summary': [
    monthColumn(true),
    {
      Header: 'Salary costs',
      id: 'salaryCosts',
      accessor: 'salaryAmount',
      Cell: MoneyCell,
      exportData: ({ salaryAmount }: { salaryAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(salaryAmount.convertedAmount, {
          impliedCurrencyCode: salaryAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Non Salary costs',
      id: 'nonSalaryAmount',
      accessor: 'nonSalaryAmount',
      Cell: MoneyCell,
      exportData: ({ nonSalaryAmount }: { nonSalaryAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(nonSalaryAmount.convertedAmount, {
          impliedCurrencyCode: nonSalaryAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
    {
      Header: 'Total cost',
      id: 'totalCost',
      accessor: 'totalAmount',
      Cell: MoneyCell,
      exportData: ({ totalAmount }: { totalAmount: Amount }) =>
        friendlyMoneyWithoutCurrency(totalAmount.convertedAmount, {
          impliedCurrencyCode: totalAmount.convertedCurrency?.code,
        }),
      disableSortBy: true,
      disableFilters: true,
      align: 'right',
    },
  ],
  'consolidated-invoices-eor': [
    {
      Header: 'Label',
      id: 'label',
      accessor: 'label',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Invoice name',
      id: 'invoiceName',
      accessor: 'invoiceName',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Type of invoice',
      id: 'typeOfInvoice',
      accessor: ({ typeOfInvoice }: { typeOfInvoice: string }) =>
        eorInvoiceTypeLabels[typeOfInvoice],
      exportData: ({ typeOfInvoice }: { typeOfInvoice: string }) =>
        eorInvoiceTypeLabels[typeOfInvoice],
      disableSortBy: true,
      Filter: (props: { column: Object }) => (
        <MultiSelectFilter {...props} options={eorTypeOfInvoiceOptions} />
      ),
    },
    {
      Header: 'Remote entity name',
      id: 'remoteEntityName',
      accessor: 'remoteEntityName',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Invoice period',
      id: 'invoicePeriod',
      accessor: 'invoicePeriod',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Invoice number',
      id: 'invoiceNumber',
      accessor: 'invoiceNumber',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Finalized at',
      id: 'finalizedAt',
      accessor: 'finalizedAt',
      disableSortBy: true,
      Cell: DateWithTimeCell,
      Filter: (props: { column: Object }) => (
        <DateRangeFilter {...props} data-testid="date-range-filter" />
      ),
      exportData: ({ finalizedAt }: { finalizedAt: string }) =>
        formatFullMonthDayYearHourMinutes(finalizedAt),
    },
    {
      Header: 'Customer company name',
      id: 'customerCompanyName',
      accessor: 'customerCompanyName',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Employee name',
      id: 'employeeName',
      accessor: 'employeeName',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Country of employment',
      id: 'countryOfEmployment',
      accessor: 'countryOfEmployment',
      disableSortBy: true,
      Cell: (props: $TSFixMe) => {
        return <CountryCell value={{ name: props.value }} />;
      },
      Filter: (props: { column: Object }) => (
        <EmployerCountryMultiSelectFilter {...props} valueField="slug" />
      ),
    },
    {
      Header: 'Invoice currency',
      id: 'invoiceCurrency',
      accessor: 'invoiceCurrency',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Local currency',
      id: 'localCurrency',
      accessor: 'localCurrency',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Base salary invoiced',
      id: 'baseSalaryInvoicedAmount',
      accessor: 'baseSalaryInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ baseSalaryInvoicedAmount }: { baseSalaryInvoicedAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(baseSalaryInvoicedAmount),
    },
    {
      Header: 'Base salary local',
      id: 'baseSalaryLocalAmount',
      accessor: 'baseSalaryLocalAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ baseSalaryLocalAmount }: { baseSalaryLocalAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(baseSalaryLocalAmount),
    },
    {
      Header: 'Employer contribution invoiced',
      id: 'employerContributionInvoicedAmount',
      accessor: 'employerContributionInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({
        employerContributionInvoicedAmount,
      }: {
        employerContributionInvoicedAmount: Amount;
      }) => exportFriendlyMoneyWithoutCurrency(employerContributionInvoicedAmount),
    },
    {
      Header: 'Employer contribution local',
      id: 'employerContributionLocalAmount',
      accessor: 'employerContributionLocalAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({
        employerContributionLocalAmount,
      }: {
        employerContributionLocalAmount: Amount;
      }) => exportFriendlyMoneyWithoutCurrency(employerContributionLocalAmount),
    },
    {
      Header: 'Benefits invoiced',
      id: 'benefitsInvoicedAmount',
      accessor: 'benefitsInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ benefitsInvoicedAmount }: { benefitsInvoicedAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(benefitsInvoicedAmount),
    },
    {
      Header: 'Benefits local',
      id: 'benefitsLocalAmount',
      accessor: 'benefitsLocalAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ benefitsLocalAmount }: { benefitsLocalAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(benefitsLocalAmount),
    },
    {
      Header: 'On payslip benefits invoiced',
      id: 'onPayslipBenefitsInvoicedAmount  ',
      accessor: 'onPayslipBenefitsInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({
        onPayslipBenefitsInvoicedAmount,
      }: {
        onPayslipBenefitsInvoicedAmount: Amount;
      }) => exportFriendlyMoneyWithoutCurrency(onPayslipBenefitsInvoicedAmount),
    },
    {
      Header: 'On payslip benefits local',
      id: 'onPayslipBenefitsLocalAmount',
      accessor: 'onPayslipBenefitsLocalAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ onPayslipBenefitsLocalAmount }: { onPayslipBenefitsLocalAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(onPayslipBenefitsLocalAmount),
    },
    {
      Header: 'Off payslip benefits invoiced',
      id: 'offPayslipBenefitsInvoicedAmount',
      accessor: 'offPayslipBenefitsInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({
        offPayslipBenefitsInvoicedAmount,
      }: {
        offPayslipBenefitsInvoicedAmount: Amount;
      }) => exportFriendlyMoneyWithoutCurrency(offPayslipBenefitsInvoicedAmount),
    },
    {
      Header: 'Off payslip costs invoiced',
      id: 'offPayslipCostsInvoicedAmount',
      accessor: 'offPayslipCostsInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ offPayslipCostsInvoicedAmount }: { offPayslipCostsInvoicedAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(offPayslipCostsInvoicedAmount),
    },
    {
      Header: 'Off payslip costs entered amount aggregated',
      id: 'offPayslipCostsEnteredAmountAggregated',
      accessor: 'offPayslipCostsEnteredAmountAggregated',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyWithoutCurrencyCell,
      exportData: ({
        offPayslipCostsEnteredAmountAggregated,
      }: {
        offPayslipCostsEnteredAmountAggregated: string;
      }) => exportMoneyValue(offPayslipCostsEnteredAmountAggregated),
    },
    {
      Header: 'Off payslip costs entered currency aggregated',
      id: 'offPayslipCostsEnteredCurrencyAggregated',
      accessor: 'offPayslipCostsEnteredCurrencyAggregated',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ value }: { value: string[] }) => value?.map((v: string) => v).join(', '),
    },
    {
      Header: 'Off payslip cost level',
      id: 'offPayslipCostLevel',
      accessor: 'offPayslipCostLevel',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Expenses invoiced',
      id: 'expensesInvoicedAmount',
      accessor: 'expensesInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ expensesInvoicedAmount }: { expensesInvoicedAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(expensesInvoicedAmount),
    },
    {
      Header: 'Expenses local',
      id: 'expensesLocalAmount',
      accessor: 'expensesLocalAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ expensesLocalAmount }: { expensesLocalAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(expensesLocalAmount),
    },
    {
      Header: 'Incentives invoiced',
      id: 'incentivesInvoicedAmount',
      accessor: 'incentivesInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ incentivesInvoicedAmount }: { incentivesInvoicedAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(incentivesInvoicedAmount),
    },
    {
      Header: 'Incentives local',
      id: 'incentivesLocalAmount',
      accessor: 'incentivesLocalAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ incentivesLocalAmount }: { incentivesLocalAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(incentivesLocalAmount),
    },
    {
      Header: 'Management fees invoiced',
      id: 'managementFeesInvoicedAmount',
      accessor: 'managementFeesInvoicedAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ managementFeesInvoicedAmount }: { managementFeesInvoicedAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(managementFeesInvoicedAmount),
    },
    {
      Header: 'Adjustment year',
      id: 'adjustmentYear',
      accessor: 'adjustmentYear',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Total invoiced amount',
      id: 'totalInvoicedAmountAmount',
      accessor: 'totalInvoicedAmountAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ totalInvoicedAmountAmount }: { totalInvoicedAmountAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(totalInvoicedAmountAmount),
    },
    {
      Header: 'Net amount',
      id: 'netAmountAmount',
      accessor: 'netAmountAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ netAmountAmount }: { netAmountAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(netAmountAmount),
    },
  ],
  'consolidated-invoices-contractor': [
    {
      Header: 'Invoice number',
      id: 'invoiceNumber',
      accessor: 'invoiceNumber',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Contractor',
      id: 'contractor',
      accessor: 'contractor',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Invoice amount',
      id: 'invoiceAmountAmount',
      accessor: 'invoiceAmountAmount',
      disableSortBy: true,
      disableFilters: true,
      Cell: MoneyCell,
      exportData: ({ invoiceAmountAmount }: { invoiceAmountAmount: Amount }) =>
        exportFriendlyMoneyWithoutCurrency(invoiceAmountAmount),
    },
    {
      Header: 'Invoice currency',
      id: 'currency',
      accessor: 'currency',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date',
      id: 'date',
      accessor: 'date',
      disableSortBy: true,
      Cell: DateCell,
      Filter: (props: { column: Object }) => (
        <DateRangeFilter {...props} data-testid="date-range-filter" />
      ),
      exportData: ({ date }: { date: string }) => formatFullMonthDayYear(date),
    },
    {
      Header: 'Label',
      id: 'label',
      accessor: 'label',
      disableSortBy: true,
      Filter: (props: { column: Object }) => (
        <MultiSelectFilter {...props} options={contractorInvoiceLabelOptions} />
      ),
    },
  ],
  'team-member-directory': [
    {
      Header: 'Name',
      id: NAME_COLUMN_ID,
      Cell: (props: any) => <PersonCellWithContractorProduct {...props} wrapNameInLink={false} />,
      accessor: ({ name, photo, slug, product }) => ({ name, photo, slug, product }),
      exportData: ({ name }) => name,
      disableFilters: true,
      sticky: 'left',
      maxWidth: 250,
    },
    {
      id: 'jobTitle',
      Header: 'Role',
      accessor: 'contract.jobTitle',
      disableFilters: true,
    },
    {
      id: 'department',
      Header: 'Department',
      accessor: 'department.name',
      disableFilters: true,
    },
    {
      id: 'startDate',
      Header: 'Start date',
      accessor: 'contract.provisionalStartDate',
      Cell: DateCell,
      disableFilters: true,
    },
    {
      id: 'country',
      Header: 'Employment country',
      accessor: 'contract.country',
      Cell: CountryCell,
      Filter: CountrySelectFilter,
    },
    typeofHireColumn(),
    {
      id: 'userEmail',
      Header: 'User email',
      accessor: 'userEmail',
      disableFilters: true,
    },
    {
      id: 'workEmail',
      Header: 'Work email',
      accessor: 'workEmail',
      disableFilters: true,
    },

    {
      Header: 'Contract end date',
      id: 'contractEndDate',
      accessor: ({ contract }) =>
        friendlyLabel(contract?.contractDetails?.details?.contractEndDate),
      hiddenColumn: true,
      Filter: DateRangeFilter,
    },
    {
      Header: 'Probation end date',
      id: PROBATION_END_DATE_COLUMN_ID,
      accessor: 'contract.probationPeriodEndDate',
      hiddenColumn: true,
      Filter: DateRangeFilter,
    },
    {
      Header: 'Direct reports only',
      id: DIRECT_REPORTS_ONLY,
      accessor: DIRECT_REPORTS_ONLY,
      hiddenColumn: true,
      Filter: DirectReportsFilter,
    },
  ],
  'team-addresses': [
    {
      Header: 'Name',
      id: NAME_COLUMN_ID,
      Cell: (props: any) => <PersonCellWithContractorProduct {...props} wrapNameInLink={false} />,
      accessor: ({ name, photo, slug, product }) => ({ name, photo, slug, product }),
      exportData: ({ name }) => name,
      disableFilters: true,
      sticky: 'left',
      maxWidth: 250,
    },
    {
      id: 'employmentShortSlug',
      Header: 'Employment ID',
      accessor: (value: any) => ({ shortSlug: value.shortSlug }),
      exportData: (row: any) => row.shortSlug,
      Cell: ShortSlugCellWithCopy,
      disableFilters: true,
    },
    {
      Header: 'Provisional start date',
      accessor: 'contract.provisionalStartDate',
      id: 'provisional_start_date',
      Cell: DateCell,
      exportData: ({ contract }) => contract.provisionalStartDate || '-',
      disableFilters: true,
    },
    {
      id: 'address',
      Header: 'Home address: Address',
      accessor: 'homeAddress.address',
      disableFilters: true,
    },
    {
      id: 'addressLine2',
      Header: 'Home address: Address Line 2',
      accessor: 'homeAddress.addressLine2',
      disableFilters: true,
    },
    {
      id: 'city',
      Header: 'Home address: City',
      accessor: 'homeAddress.city',
      disableFilters: true,
    },
    {
      id: 'state',
      Header: 'Home address: State',
      accessor: 'homeAddress.state',
      disableFilters: true,
    },
    {
      id: 'postalCode',
      Header: 'Home address: Postal Code',
      accessor: 'homeAddress.postalCode',
      disableFilters: true,
    },
    {
      id: 'country',
      Header: 'Home address: country',
      accessor: 'homeAddress.country',
      Cell: CountryCell,
      Filter: CountrySelectFilter,
    },
    {
      Header: 'Direct reports only',
      id: DIRECT_REPORTS_ONLY,
      accessor: DIRECT_REPORTS_ONLY,
      hiddenColumn: true,
      Filter: DirectReportsFilter,
    },
  ],
  'emergency-contacts': [
    {
      Header: 'Name',
      id: NAME_COLUMN_ID,
      Cell: (props: any) => <PersonCellWithContractorProduct {...props} wrapNameInLink={false} />,
      accessor: ({ name, photo, slug, product }) => ({ name, photo, slug, product }),
      exportData: ({ name }) => name,
      disableFilters: true,
      sticky: 'left',
      maxWidth: 250,
    },
    {
      id: 'employmentShortSlug',
      Header: 'Employment ID',
      accessor: (value) => ({ shortSlug: value.shortSlug }),
      exportData: (row) => row.shortSlug,
      Cell: ShortSlugCellWithCopy,
      disableFilters: true,
    },
    {
      id: 'emergencyContactFullName',
      Header: "Emergency contact's full name",
      accessor: 'emergencyContact.name',
      disableFilters: true,
    },
    {
      id: 'emergencyContactRelationship',
      Header: 'Relationship to employee',
      accessor: 'emergencyContact.relationship',
      disableFilters: true,
    },
    {
      id: 'emergencyContactEmail',
      Header: "Emergency's contact email",
      accessor: 'emergencyContact.email',
      disableFilters: true,
    },
    {
      id: 'emergencyContactPhoneNumber',
      Header: "Emergency's contact phone number",
      accessor: 'emergencyContact.phoneNumber',
      disableFilters: true,
    },
    {
      id: 'country',
      Header: 'Employment country',
      accessor: 'contract.country',
      hiddenColumn: true,
      Filter: CountrySelectFilter,
    },
    {
      Header: 'Direct reports only',
      id: DIRECT_REPORTS_ONLY,
      accessor: DIRECT_REPORTS_ONLY,
      hiddenColumn: true,
      Filter: DirectReportsFilter,
    },
  ],
};
