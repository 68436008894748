import { useMemo } from 'react';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';
import {
  getVisibilityStateDiff,
  getFiltersStateDiff,
  getOrderStateDiff,
  getSortByStateDiff,
  numberOfOrderChangesWithOffset,
} from '@/src/components/Table/helpers/tableViewHelpers';
import type { ColumnsConfigurationVisibility } from '@/src/components/Table/hooks/useColumnsState/types';
import type { SortBy } from '@/src/components/Table/types';

interface UseTableViewStateDiffProps {
  view: TableViewsApi.TableView;
  tableStateVisibility: ColumnsConfigurationVisibility;
  tableStateFilters: TableViewsApi.TransformedFilter[];
  tableStateOrder: string[];
  tableStateSortBy: SortBy[];
  isTableViewsEnabledExperimental: boolean;
}

export const useTableViewStateDiff = ({
  view,
  tableStateVisibility,
  tableStateFilters,
  tableStateOrder,
  tableStateSortBy,
  isTableViewsEnabledExperimental,
}: UseTableViewStateDiffProps) => {
  const diffResult = useMemo(() => {
    if (!isTableViewsEnabledExperimental) {
      return {
        numberOfVisibilityChanges: 0,
        numberOfFilterChanges: 0,
        numberOfOrderChanges: 0,
        numberOfSortByChanges: 0,
        orderChangesWithOffset: 0,
        numberOfTableStateChanges: 0,
      };
    }

    const {
      columns: { visibility = {}, order = [] } = {},
      filters = [],
      sortBy = [],
    } = view.config || {};

    const numberOfVisibilityChanges = getVisibilityStateDiff(visibility, tableStateVisibility);
    const numberOfFilterChanges = getFiltersStateDiff(filters, tableStateFilters, visibility);
    const numberOfOrderChanges = getOrderStateDiff(order, tableStateOrder);
    const numberOfSortByChanges = getSortByStateDiff(sortBy, tableStateSortBy);
    const orderChangesWithOffset = numberOfOrderChangesWithOffset(
      numberOfOrderChanges,
      numberOfVisibilityChanges
    );

    const numberOfTableStateChanges =
      numberOfVisibilityChanges +
      numberOfFilterChanges +
      orderChangesWithOffset +
      numberOfSortByChanges;

    return {
      numberOfVisibilityChanges,
      numberOfFilterChanges,
      numberOfOrderChanges,
      numberOfSortByChanges,
      orderChangesWithOffset,
      numberOfTableStateChanges,
    };
  }, [
    isTableViewsEnabledExperimental,
    view,
    tableStateVisibility,
    tableStateFilters,
    tableStateOrder,
    tableStateSortBy,
  ]);

  return diffResult;
};
