import { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import useLocalStorageState from 'use-local-storage-state';

import { useTooltipModal } from '@/src/components/Ui/TooltipModal';
import { useUser } from '@/src/components/UserProvider/context';
import { useUserLinkedAccounts } from '@/src/domains/account/hooks/useUserLinkedAccounts';
import { useUserProfiles } from '@/src/domains/account/hooks/useUserProfiles';
import {
  MULTI_ACCOUNTS_ANNOUNCEMENT_KEY,
  MULTI_PROFILES_ANNOUNCEMENT_KEY,
} from '@/src/domains/navigation/profiles-menu';
import { isEmployerMissingEmailConfirmation } from '@/src/domains/registration/auth/helpers';

export const useProfilesMenuTooltip = () => {
  const { hasMultipleProfiles } = useUserProfiles();
  const { user } = useUser();
  const { hasMultipleLinkedAccounts } = useUserLinkedAccounts();
  const { isVisible: isTooltipVisible, open, close } = useTooltipModal();
  const {
    breakpointValues: { medium },
  } = useTheme();
  const [multiProfilesAnnouncementKey, setMultiProfilesAnnouncementKey] = useLocalStorageState(
    MULTI_PROFILES_ANNOUNCEMENT_KEY
  );
  const [multiAccountsAnnouncementKey, setMultiAccountsAnnouncementKey] = useLocalStorageState(
    MULTI_ACCOUNTS_ANNOUNCEMENT_KEY
  );

  // This is general boolean that controls if tooltip (either profiles or accounts) should be shown
  const shouldShowTooltip = useMemo(() => {
    const profilesMenu = !multiProfilesAnnouncementKey && hasMultipleProfiles;
    const accountsMenu = !multiAccountsAnnouncementKey && hasMultipleLinkedAccounts;
    const userIsMissingEmailConfirmation = isEmployerMissingEmailConfirmation(user);
    return (
      (profilesMenu || accountsMenu) &&
      window.innerWidth >= medium &&
      !userIsMissingEmailConfirmation
    );
  }, [
    multiProfilesAnnouncementKey,
    hasMultipleProfiles,
    multiAccountsAnnouncementKey,
    hasMultipleLinkedAccounts,
    user,
    medium,
  ]);

  // Account specific tooltip visibility
  const accountsTooltipVisible = useMemo(() => {
    const accountsMenu = !multiAccountsAnnouncementKey && hasMultipleLinkedAccounts;
    return accountsMenu && window.innerWidth >= medium;
  }, [multiAccountsAnnouncementKey, hasMultipleLinkedAccounts, medium]);

  // Profile specific tooltip visibility
  const profilesTooltipVisible = useMemo(() => {
    // early return for small screens or no multi profiles
    if ((typeof window !== 'undefined' && window.innerWidth < medium) || !hasMultipleProfiles) {
      return false;
    }

    // if we have tooltip present for the accounts, we should not show the profiles tooltip, this check accounts when localstorage key does not exist (assumption is we still want to show accounts tooltip if we have multiple linked accounts!)
    if (hasMultipleLinkedAccounts && multiAccountsAnnouncementKey === undefined) {
      return false;
    }

    // check is almost identical to the one above but this accounts for localstorage key being present but indicating that we should show accounts tooltip ("multiAccountsAnnouncementKey")
    if (
      hasMultipleLinkedAccounts &&
      multiAccountsAnnouncementKey !== undefined &&
      !multiAccountsAnnouncementKey
    ) {
      return false;
    }

    // Final check for profiles key and multi profiles
    return !multiProfilesAnnouncementKey && hasMultipleProfiles;
  }, [
    hasMultipleProfiles,
    multiProfilesAnnouncementKey,
    multiAccountsAnnouncementKey,
    medium,
    hasMultipleLinkedAccounts,
  ]);

  useEffect(() => {
    if (shouldShowTooltip) {
      open();
    }
  }, [shouldShowTooltip, open]);

  // Close the tooltip if another component set the key
  useEffect(() => {
    if (isTooltipVisible && multiProfilesAnnouncementKey && multiAccountsAnnouncementKey) {
      close();
    }
  }, [isTooltipVisible, multiProfilesAnnouncementKey, close, multiAccountsAnnouncementKey]);

  const closeTooltip = () => {
    close();
    if (profilesTooltipVisible) {
      setMultiProfilesAnnouncementKey(true);
    }
    if (accountsTooltipVisible) {
      setMultiAccountsAnnouncementKey(true);
    }
  };

  return {
    shouldShowTooltip,
    isTooltipVisible,
    closeTooltip,
    accountsTooltipVisible,
    profilesTooltipVisible,
  };
};
