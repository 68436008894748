import { SROnly } from '@remote-com/norma';
import { useEffect, useState, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';

import { PortalContent } from './EditCell.styled';

/**
 * NOTE:
 *
 * This component wraps the input in a portal so we can break out of the table and render the input separately
 * It is currently only used for the textarea input type as we need a larger area to render a textarea
 *
 * It also handles the positioning of the portal itself so it never goes off-screen
 */
export const EditCellPortal = ({
  cellRef,
  children,
}: {
  cellRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });
  const portalRef = useRef<HTMLDivElement>(null);

  const updatePosition = useCallback(() => {
    if (!cellRef.current || !portalRef.current) return;

    const cellRect = cellRef.current.getBoundingClientRect();
    const portalRect = portalRef.current.getBoundingClientRect();
    const gap = 8; // Gap from the edge of the screen
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let { top } = cellRect;
    let { left } = cellRect;
    const width = Math.min(cellRect.width * 2, 400); // Max portal width of 400px

    // Adjust top position if it's off-screen at the top
    if (top < gap) {
      top = gap;
    }

    // Adjust bottom position if it's off-screen at the bottom
    const portalHeight = portalRect.height;
    if (top + portalHeight > windowHeight - gap) {
      top = windowHeight - portalHeight - gap;
    }

    // Ensure top is never negative
    top = Math.max(gap, top);

    // Adjust left position if it's off-screen
    if (left < gap) {
      left = gap;
    } else if (left + width > windowWidth - gap) {
      left = windowWidth - width - gap;
    }

    setPosition({ top, left, width });
  }, [cellRef]);

  useEffect(() => {
    updatePosition();

    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);

    const resizeObserver = new ResizeObserver(updatePosition);
    if (cellRef.current) {
      resizeObserver.observe(cellRef.current);
    }

    return () => {
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
      resizeObserver.disconnect();
    };
  }, [cellRef, updatePosition]);

  if (!cellRef.current) return null;

  return createPortal(
    <PortalContent
      ref={portalRef}
      role="dialog"
      aria-modal="true"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: `${position.width}px`,
      }}
    >
      {children}
      <SROnly>Press Tab to save the value or Esc to cancel.</SROnly>
    </PortalContent>,
    document.body
  );
};
