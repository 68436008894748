import { Box } from '@remote-com/norma';
import { motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

const MotionBox = motion(Box);
type Transition = {
  duration: number;
  delay?: number;
  ease:
    | Array<number>
    | 'linear'
    | 'easeIn'
    | 'easeOut'
    | 'easeInOut'
    | 'circIn'
    | 'circOut'
    | 'circInOut'
    | 'backIn'
    | 'backOut'
    | 'backInOut'
    | 'anticipate';
};

type FadeProps = {
  /** Enter transition config */
  enter?: Transition;
  /** Leave transition config */
  leave?: Transition;
  /** Controls if content should be visible or not (triggers enter or leave transition accordingly) */
  isVisible?: boolean;
  /** If provided, hides content after X amount in milliseconds */
  timeVisible?: number;
};

/**
 * Fade component
 * Fades content in/out with a pre-defined easing and duration, which can be customized via `enter` and `leave` props.
 * Note: This component uses a `Box` underneath so all style props work.
 */
export function Fade({
  isVisible = true,
  timeVisible,
  children,
  enter = {
    duration: 1,
    ease: 'easeOut',
  },
  leave,
  ...props
}: PropsWithChildren<FadeProps>) {
  const [show, setShow] = useState(isVisible);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    // If content should only be visible for X ms, hide it after that timeout
    if (timeVisible && show) {
      timeout = setTimeout(() => setShow(false), timeVisible);
    }

    return () => timeout && clearTimeout(timeout);
  }, [timeVisible, show, isVisible]);

  // if isVisible prop changes, react to it
  useEffect(() => {
    setShow(isVisible);
  }, [isVisible]);

  // if no "leave" transition is specified, use the "enter" one
  const leaveTransition = leave || enter;

  const variants = {
    visible: {
      opacity: 1,
      transition: enter,
    },
    hidden: {
      opacity: 0,
      transition: leaveTransition,
    },
  };

  return (
    <MotionBox
      data-testid="fade-motion-box"
      initial={{ opacity: 0 }}
      variants={variants}
      animate={show ? 'visible' : 'hidden'}
      {...props}
    >
      {children}
    </MotionBox>
  );
}
