// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconHeart = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12.35 3.79a6.5 6.5 0 1 1 9.2 9.2l-8.84 8.83a1 1 0 0 1-1.42 0l-8.84-8.84a6.5 6.5 0 0 1 9.2-9.19l.35.35.35-.35Zm4.6.1a4.5 4.5 0 0 0-3.18 1.31L12.7 6.26a1 1 0 0 1-1.42 0L10.23 5.2a4.5 4.5 0 0 0-6.36 6.37L12 19.7l8.13-8.13a4.5 4.5 0 0 0-3.18-7.68Z" /></svg>;
const ForwardRef = forwardRef(IconHeart);
export { ForwardRef as IconHeart };