import { USER_TYPE, homepageEvents, trackEvent } from '@remote-com/analytics';
import {
  Box,
  Drawer,
  DrawerLayout,
  DrawerPane,
  DrawerHeader,
  DrawerTitle,
} from '@remote-com/norma';
import kebabCase from 'lodash/kebabCase';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import Tab from '@/src/components/Tabs/Tab';
import TabPanel from '@/src/components/Tabs/TabPanel';
import Tabs from '@/src/components/Tabs/Tabs';
import TabBar from '@/src/components/Ui/TabBar/index';
import { useUser } from '@/src/components/UserProvider/context';
import { isEmployer } from '@/src/domains/registration/auth/helpers';
import type { NotRequiredUserPromptAction } from '@/src/domains/userPrompts';
import { useUserPrompts } from '@/src/domains/userPrompts';
import type { UserPrompt } from '@/src/domains/userPrompts/widgets/userPromptsWidget/config';
import {
  isCallToActionButtonClick,
  isCallToActionComponent,
  userPromptCards,
} from '@/src/domains/userPrompts/widgets/userPromptsWidget/config';

import { ButtonStyled, Title, Description, Icon } from './PastPromptsDrawer.styled';

type EmployerCompletedViewSegment = 'all' | 'employer' | 'employee' | 'contractor';
export type CompletedViewSegment = EmployerCompletedViewSegment;

const employerTabs: Record<EmployerCompletedViewSegment, EmployerCompletedViewSegment> = {
  all: 'all',
  employee: 'employee',
  contractor: 'contractor',
  employer: 'employer',
};

type UserType = keyof typeof USER_TYPE;

type PastPromptCardProps = {
  completedView: UserPrompt['completedView'];
  callToAction: UserPrompt['callToAction'];
  closeDrawer: () => void;
  action: NotRequiredUserPromptAction;
  userType: UserType;
};

const PastPromptCard = ({
  completedView,
  callToAction,
  closeDrawer,
  action,
  userType,
}: PastPromptCardProps) => {
  const router = useRouter();

  const content = (
    <>
      <div>
        <Title>{completedView.title}</Title>
        <Description>{completedView.description}</Description>
      </div>
      <Icon width="16" height="16" />
    </>
  );

  const handleClick = (onClick?: (r: NextRouter) => void) => {
    onClick?.(router);
    trackEvent(
      homepageEvents.contentCardClicked(kebabCase(action), 'card-library', USER_TYPE[userType])
    );
    closeDrawer();
  };

  if (isCallToActionComponent(callToAction)) {
    return (
      <ButtonStyled onClick={() => handleClick(callToAction.onCompletedViewClick)}>
        {content}
      </ButtonStyled>
    );
  }

  if (isCallToActionButtonClick(callToAction)) {
    return <ButtonStyled onClick={() => handleClick(callToAction.onClick)}>{content}</ButtonStyled>;
  }

  return (
    <ButtonStyled href={callToAction.href} onClick={handleClick}>
      {content}
    </ButtonStyled>
  );
};

function buildSegmentUserPromptCards({
  segment,
  setPastPromptsDrawerOpen,
  userType,
}: {
  segment: CompletedViewSegment;
  setPastPromptsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  userType: UserType;
}) {
  return Object.entries(userPromptCards).reduce<JSX.Element[]>((acc, [action, card]) => {
    if (card.completedView.segment === segment || segment === 'all') {
      acc.push(
        <PastPromptCard
          key={action}
          action={action as NotRequiredUserPromptAction}
          callToAction={card.callToAction}
          completedView={card.completedView}
          closeDrawer={() => setPastPromptsDrawerOpen(false)}
          userType={userType}
        />
      );
    }

    return acc;
  }, []);
}

function EmployerPane() {
  const { setPastPromptsDrawerOpen } = useUserPrompts();
  const [currentTab, setCurrentTab] = useState(employerTabs.all);

  const allCards = buildSegmentUserPromptCards({
    segment: employerTabs.all,
    setPastPromptsDrawerOpen,
    userType: 'EMPLOYER',
  });
  const employerCards = buildSegmentUserPromptCards({
    segment: employerTabs.employer,
    setPastPromptsDrawerOpen,
    userType: 'EMPLOYER',
  });
  const employeeCards = buildSegmentUserPromptCards({
    segment: employerTabs.employee,
    setPastPromptsDrawerOpen,
    userType: 'EMPLOYER',
  });
  const contractorCards = buildSegmentUserPromptCards({
    segment: employerTabs.contractor,
    setPastPromptsDrawerOpen,
    userType: 'EMPLOYER',
  });

  return (
    <>
      <TabBar>
        <Tabs value={currentTab} onClick={(_, newTab) => setCurrentTab(newTab)}>
          <Tab
            value={employerTabs.all}
            count={allCards.length}
            trackNavigation={['card-library', employerTabs.all]}
          >
            All
          </Tab>
          <Tab
            value={employerTabs.employer}
            count={employerCards.length}
            trackNavigation={['card-library', employerTabs.employer]}
          >
            Employer
          </Tab>
          <Tab
            value={employerTabs.employee}
            count={employeeCards.length}
            trackNavigation={['card-library', employerTabs.employee]}
          >
            Employee
          </Tab>
          <Tab
            value={employerTabs.contractor}
            count={contractorCards.length}
            trackNavigation={['card-library', employerTabs.contractor]}
          >
            Contractor
          </Tab>
        </Tabs>
      </TabBar>

      <TabPanel value={currentTab} index={employerTabs.all}>
        {allCards}
      </TabPanel>
      <TabPanel value={currentTab} index={employerTabs.employer}>
        {employerCards}
      </TabPanel>
      <TabPanel value={currentTab} index={employerTabs.employee}>
        {employeeCards}
      </TabPanel>
      <TabPanel value={currentTab} index={employerTabs.contractor}>
        {contractorCards}
      </TabPanel>
    </>
  );
}

export function PastPromptsDrawer({ setOpen }: { setOpen: Dispatch<SetStateAction<boolean>> }) {
  const { user } = useUser();

  return (
    <Drawer
      isVisible
      aria-label="Tour library"
      onClose={() => setOpen(false)}
      Trigger="TODO_MIGRATE_TO_COMPONENT_DONT_COPY"
      $layout="fullWidth"
    >
      <DrawerLayout>
        <DrawerHeader display="flex" gridGap={4}>
          <Box>
            <DrawerTitle>Getting started</DrawerTitle>
          </Box>
        </DrawerHeader>
        <DrawerPane>{isEmployer(user) ? <EmployerPane /> : null}</DrawerPane>
      </DrawerLayout>
    </Drawer>
  );
}
