import type { Args } from '@storybook/react';
import type { Ref } from 'react';

export const EMPTY_INDICATOR = '—';

/**
 * Hides the specified args from the Storybook controls.
 * @param keys - The keys of the args to hide.
 * @returns The args with the specified keys hidden.
 */
export const hideArgs = <T = Args>(keys: Array<keyof (T & { ref: Ref<HTMLInputElement> })>) => {
  return keys.reduce(
    (acc, flagName) => ({
      ...acc,
      [flagName]: {
        table: {
          disable: true,
        },
      },
    }),
    {}
  );
};

/**
 * Disables the specified args in the Storybook controls.
 * @param keys - The keys of the args to disable.
 * @returns The args with the specified keys disabled.
 */
export const disableArgs = <T = Args>(keys: Array<keyof (T & { ref: Ref<HTMLInputElement> })>) => {
  return keys.reduce(
    (acc, flagName) => ({
      ...acc,
      [flagName]: {
        control: {
          disable: true,
        },
      },
    }),
    {}
  );
};

export const isIosDevice = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
