// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneFileDelete = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M13.25 2.85c0-.56 0-.84-.1-1.05a1 1 0 0 0-.45-.44c-.21-.11-.49-.11-1.05-.11h-.4c-2.8 0-4.2 0-5.27.54a5 5 0 0 0-2.19 2.19c-.54 1.07-.54 2.47-.54 5.27v5.5c0 2.8 0 4.2.54 5.27a5 5 0 0 0 2.19 2.18c1.07.55 2.47.55 5.27.55h1.5c2.8 0 4.2 0 5.27-.55a5 5 0 0 0 2.18-2.18c.55-1.07.55-2.47.55-5.27v-4.4c0-.56 0-.84-.1-1.05a1 1 0 0 0-.45-.44c-.21-.11-.49-.11-1.05-.11h-2.7c-1.12 0-1.68 0-2.1-.22a2 2 0 0 1-.88-.87c-.22-.43-.22-.99-.22-2.11v-2.7Z" /><path fillRule="evenodd" clipRule="evenodd" d="M20.35 6.3c.26.25.4.6.4.95h-4.4c-.56 0-.84 0-1.05-.1a1 1 0 0 1-.44-.45c-.11-.21-.11-.49-.11-1.05v-4.4c.36 0 .7.14.96.4l4.64 4.64Zm-9.82 5.17a.75.75 0 1 0-1.06 1.06L10.94 14l-1.47 1.47a.75.75 0 1 0 1.06 1.06L12 15.06l1.47 1.47a.75.75 0 1 0 1.06-1.06L13.06 14l1.47-1.47a.75.75 0 1 0-1.06-1.06L12 12.94l-1.47-1.47Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneFileDelete);
export { ForwardRef as IconV2DuotoneFileDelete };