import { IconV2SolidDragDrop } from '@remote-com/norma/icons/IconV2SolidDragDrop';
import { IconV2SolidLock } from '@remote-com/norma/icons/IconV2SolidLock';
import type { ElementRef } from 'react';
import { forwardRef } from 'react';

import {
  ConfigurationListItem,
  ConfigurationListItemActions,
  ConfigurationListItemIcon,
  ConfigurationListItemName,
  ConfigurationListItemVisibilityToggle,
} from '@/src/components/Table/Components/ColumnsConfigurationDrawer/ConfigurationList';
import type { ColumnState } from '@/src/components/Table/hooks/useColumnsState/types';

type EditColumnsListItemType = 'static' | 'locked' | 'draggable';

type EditColumnsListItemProps = {
  /** The current column state. */
  columnState: ColumnState;
  /** Function to call when toggling the visibility of a column. */
  onToggleColumnVisibility?: (columnId: string) => void;
  /** The interactivity type of the item. */
  type?: EditColumnsListItemType;
};

const getIconForType = (type: EditColumnsListItemType) => {
  switch (type) {
    case 'draggable':
      return IconV2SolidDragDrop;
    case 'locked':
      return IconV2SolidLock;
    default:
      return undefined;
  }
};

export const EditColumnsListItem = forwardRef<ElementRef<'div'>, EditColumnsListItemProps>(
  (
    { columnState: [id, visibility, name], onToggleColumnVisibility, type = 'static', ...rest },
    ref
  ) => {
    const isVisible = visibility === 'only-view' || visibility === 'visible';

    const Icon = getIconForType(type);

    const handleClick = () => {
      onToggleColumnVisibility?.(id);
    };

    return (
      <ConfigurationListItem {...rest} isActive={isVisible} ref={ref}>
        {Icon && <ConfigurationListItemIcon icon={Icon} />}
        <ConfigurationListItemName>{name}</ConfigurationListItemName>
        {onToggleColumnVisibility && (
          <ConfigurationListItemActions>
            <ConfigurationListItemVisibilityToggle isActive={isVisible} onClick={handleClick} />
          </ConfigurationListItemActions>
        )}
      </ConfigurationListItem>
    );
  }
);
