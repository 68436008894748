import { Stack, Box, AsideIcon, FormGroup, useFormGroupContext } from '@remote-com/norma';
import { IconProgressCircle25 } from '@remote-com/norma/icons/IconProgressCircle25';
import { IconV2OutlineCalendar } from '@remote-com/norma/icons/IconV2OutlineCalendar';
import type { ReactElement } from 'react';
import styled, { css, useTheme, keyframes } from 'styled-components';

import { DatePicker } from './DatePicker';

/**
 * This is defined during the moving of DatePicker to Norma.
 * DatePicker is typed as best as we can,
 * but this InputDatePicker cannot be typed properly in the same pass.
 * This is mainly because its props are shared between the picker and the field.
 * We should revisit this later.
 */
type FixMeAnyProps = any;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled(Box).attrs({
  display: 'flex',
  top: 0,
  right: 0,
  bottom: 0,
})`
  margin: auto;
  svg {
    animation: ${spin} 500ms infinite linear;
  }
`;

const FormGroupWrapper = styled(Box)`
  .react-datepicker__close-icon {
    right: ${({ theme }) => `-${theme.space[3]}px`};
    border-radius: 50%;
    ${({ size }) =>
      size === 'sm'
        ? css`
            top: 50%;
            transform: translateY(-50%);
          `
        : css`
            top: 4px;
          `}
  }
`;

function InputElement(allProps: FixMeAnyProps): ReactElement {
  const {
    autoComplete,
    field,
    placeholder,
    autoFocus,
    dataTestid,
    onCalendarClose,
    onChange,
    selected,
    size,
    selectsRange,
    isClearable,
    ...props
  } = allProps;

  // TODO: figure out why passing the `ref` breaks the inner month selector
  // See: [DEVXP-339](https://linear.app/remote/issue/DEVXP-339/bug-in-staging-click-on-calendar-icon-does-not-bring-up-calendar)
  const { value, ref, ...inputProps } = useFormGroupContext();

  const handleOnCalendarClose = () => {
    onCalendarClose?.();
  };

  return (
    <FormGroupWrapper size={inputProps.size}>
      <DatePicker
        autoComplete={autoComplete}
        {...field}
        {...props}
        {...inputProps}
        placeholderText={placeholder}
        autoFocus={autoFocus}
        data-testid={dataTestid}
        onCalendarClose={handleOnCalendarClose}
        selected={selected}
        size={inputProps.size}
        selectsRange={selectsRange}
        isClearable={isClearable}
      />
    </FormGroupWrapper>
  );
}

export function InputDatePicker(props: FixMeAnyProps): ReactElement {
  const {
    'data-testid': dataTestid,
    autoComplete,
    autoFocus,
    description,
    errorText,
    extra,
    field,
    isClearable,
    label,
    name,
    hideLabel,
    onChange,
    onChangeNative,
    onBlur,
    placeholder,
    props: innerProps,
    selected,
    selectsRange,
    size,
    labelPlacement,
    touched,
    value,
    displayErrorMessage,
    asideIcon = IconV2OutlineCalendar,
    isLoading,
    readOnly,
  } = props;

  const { colors } = useTheme();

  return (
    <FormGroup
      description={description}
      errorText={errorText}
      displayErrorMessage={displayErrorMessage}
      extra={extra}
      label={label || placeholder}
      placeholder={placeholder}
      name={name}
      /**
       * "touched" is not defined in FormGroup,
       * BUT FormGroup also spreads its props,
       * which in turn is spreaded further down,
       * so we are not sure yet if this is a bug or not.
       */
      {...{ touched }}
      value={value}
      aside={(asideProps) => {
        return (
          <Stack direction="row" gap={3}>
            {isLoading ? (
              <LoadingSpinner aria-label="Loading">
                <IconProgressCircle25 width="22" fill={colors.grey[600]} />
              </LoadingSpinner>
            ) : null}
            <AsideIcon as={asideIcon} {...asideProps} />
          </Stack>
        );
      }}
      labelPlacement={labelPlacement}
      size={size}
      onBlur={onBlur}
      onChange={onChange}
      readOnly={readOnly || innerProps?.disabled}
      hideLabel={hideLabel}
    >
      <InputElement
        autoComplete={autoComplete}
        field={field}
        {...innerProps}
        placeholder={placeholder}
        errorText={errorText}
        autoFocus={autoFocus}
        dataTestid={dataTestid}
        onChange={onChange}
        onChangeNative={onChangeNative}
        selected={selected}
        size={size}
        selectsRange={selectsRange}
        isClearable={isClearable}
      />
    </FormGroup>
  );
}
