import get from 'lodash/get';
import omit from 'lodash/omit';
import snakeCase from 'lodash/snakeCase';

import fetchAllPagesWithCursor from '@/src/components/Table/export/fetchAllPagesWithCursor';
import { createObjectUrlFromBlob, generateAndDownloadDocument } from '@/src/domains/files/helpers';
import { makeAllPageFetcherFunction } from '@/src/helpers/api';
import { captureHTTPException } from '@/src/helpers/captureException';
import { arrayToCsvCell } from '@/src/helpers/csv';

export const createExportState = ({ reactTableState }) => {
  const stateToUse = omit(reactTableState, [
    'selectedRowIds',
    'sortBy',
    'filters',
    'pageIndex',
    'globalFilter',
    'globalFilterQueryKey',
  ]);

  if (reactTableState.sortBy?.length > 0) {
    const [sort] = reactTableState.sortBy;
    stateToUse.sortBy = snakeCase(sort.id);
    stateToUse.order = sort.desc ? 'desc' : 'asc';
  }

  if (reactTableState.filters?.length > 0) {
    reactTableState.filters.forEach(({ id, value }) => {
      stateToUse[id] = value;
    });
  }

  return stateToUse;
};

const defaultValue = '';

const getCellValue = async ({ col, row, metadata }) => {
  const accessor = col.exportData ?? col.accessor ?? col.id;
  if (!accessor) return defaultValue;

  switch (typeof accessor) {
    case 'string':
      // The custom field name string can have special characters (such as square brackets), we must pass the property path as an array.
      // Note: This would still break if the custom field contains a period.
      return get(row, accessor.split('.'));
    case 'function':
      return accessor({ ...row, metadata });
    default:
      return defaultValue;
  }
};

const formatCellValue = (value) => {
  switch (typeof value) {
    case 'undefined': {
      return defaultValue;
    }
    case 'object': {
      if (value === null) {
        return defaultValue;
      }
      if (Array.isArray(value)) {
        return arrayToCsvCell(value);
      }
      return JSON.stringify(value).replaceAll('"', '""');
    }
    case 'number': {
      if (Number.isNaN(value)) {
        return defaultValue;
      }
      return value;
    }
    case 'string': {
      return value.replaceAll('"', '""');
    }
    default:
      return value;
  }
};

const generateHeaderCell = ({ column, columns, data }) => {
  if (typeof column.exportHeader === 'string') {
    return column.exportHeader;
  }

  if (typeof column.Header === 'function') {
    return column.Header({ columns, data, isExport: true });
  }

  if (column.Header?.$$typeof && column.Header?.props?.children) {
    return column.Header.props.children.toString();
  }

  return column.Header;
};

const generateFooterCell = ({ column, columns, data, metadata }) => {
  if (typeof column.footerExportData === 'function') {
    return column.footerExportData({ columns, data, metadata });
  }

  if (typeof column.Footer === 'function') {
    return column.Footer({ columns, data, metadata, isExport: true });
  }

  if (column.Footer?.$$typeof && column.Footer?.props?.children) {
    return column.Footer.props.children.toString();
  }

  return column.Footer;
};

/**
 * exportCSV takes state and converts it to a CSV string and downloads it
 *
 * @param {boolean} hasPagination
 *
 * Data passed to this table
 * @param {array} data
 *
 * Columns passed to this table
 * @param {array} columns
 *
 * The fetch function, used to fetch data from the backend when there is more than one page
 * @param {function} queryFn
 *
 * Current table state
 * @param {object} state
 *
 * @returns
 */
export const createExportData = async ({
  hasPagination,
  data,
  metadata,
  reactTableState = {},
  columns,
  queryFn,
  exportFooter,
  clientSidePagination,
  useCursorPaginationForExports,
}) => {
  let rowsData = data;

  // If it has pagination we need to go over all the pages and create a new array of data that we will use.
  // However, we don't need to do this in case of client side pagination because we already have all the data.
  // If using cursor based pagination, there's also no need to do this.
  if (hasPagination && !clientSidePagination && !useCursorPaginationForExports) {
    const stateToUse = createExportState({ reactTableState });

    const fetchAllPages = makeAllPageFetcherFunction(
      queryFn,
      (results) => results.flatMap((res) => res.data?.data || res.data),
      { pageSize: 100 }
    );

    rowsData = await fetchAllPages({ ...stateToUse, disable_pagination: true });
  } else if (hasPagination && useCursorPaginationForExports) {
    rowsData = await fetchAllPagesWithCursor(queryFn, createExportState({ reactTableState }));
  }

  const sanitizedRowsData = await Promise.all(
    rowsData.map((row) =>
      Promise.all(
        columns.map(async (col) => {
          try {
            const raw = await getCellValue({ row, col, metadata });
            const value = formatCellValue(raw);
            return value;
          } catch {
            return defaultValue;
          }
        })
      )
    )
  );

  const headerRow = columns.map((column) => generateHeaderCell({ column, columns, data }));
  const footerRow = exportFooter
    ? columns.map((column) => generateFooterCell({ column, columns, data, metadata }))
    : null;

  const csvData = exportFooter
    ? [headerRow, ...sanitizedRowsData, footerRow]
    : [headerRow, ...sanitizedRowsData];

  const csv = csvData
    .map((row) => row.map((value) => (Number.isNaN(value) ? `"-"` : `"${value}"`)).join(','))
    .join('\n');

  return csv;
};

// This is divided into two functions for testing purposes
export const exportData = async ({ exportCSV, includeBOM = false, ...props }) => {
  const name = typeof exportCSV === 'string' ? exportCSV : 'export';
  const fileName = `${name}.csv`;
  try {
    const csv = await createExportData(props);
    const blob = new Blob(
      [
        includeBOM ? '\ufeff' : '', // Byte-order mark (BOM) to support direct Excel import
        csv,
      ],
      { type: 'text/plain;charset=utf-8' }
    );
    generateAndDownloadDocument({
      content: createObjectUrlFromBlob(blob),
      name: fileName,
    });
  } catch (e) {
    captureHTTPException(e);
    throw new Error(e);
  }
};
