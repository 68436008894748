import { useGet } from '@remote-com/data-layer';
import type { GetRequest, GetPath } from '@remote-com/data-layer';

import { getDataWithRenamedProperty } from '@/src/helpers/api';
import type { $TSFixMe } from '@/types';

type useWidgetDataProps<KPath extends GetPath> = {
  path?: KPath;
  slug: string;
  options?: $TSFixMe;
  queryParams?: GetRequest<KPath>['queryParams'];
};

export function useWidgetData<KPath extends GetPath>({
  path = '/api/v1/reports/people/[slug]' as KPath,
  slug,
  options = {},
  queryParams = {},
}: useWidgetDataProps<KPath>) {
  const requestParams = {
    pathParams: { slug },
    queryParams,
  } as GetRequest<KPath>;

  return useGet(path, {
    params: requestParams,
    options: {
      ...options,
      enabled: !!slug,
      select: (response) => getDataWithRenamedProperty(response, 'data'),
    },
  });
}
