import {
  FeedbackMessage,
  FEEDBACK_MESSAGE_SUCCESS,
  FEEDBACK_MESSAGE_ERROR,
  Box,
} from '@remote-com/norma';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  ${({ $styles }) => $styles}
`;

const FormStateFeedback = ({ formState, $styles }) => {
  const { errorMessage, successMessage, successDescription } = formState;

  let content = null;

  if (errorMessage) {
    content = <FeedbackMessage variant={FEEDBACK_MESSAGE_ERROR}>{errorMessage}</FeedbackMessage>;
  } else if (successDescription && successMessage) {
    content = (
      <FeedbackMessage variant={FEEDBACK_MESSAGE_SUCCESS} title={successMessage}>
        {successDescription}
      </FeedbackMessage>
    );
  } else if (successMessage) {
    content = (
      <FeedbackMessage variant={FEEDBACK_MESSAGE_SUCCESS}>{successMessage}</FeedbackMessage>
    );
  }

  return content && <Wrapper $styles={$styles}>{content}</Wrapper>;
};

FormStateFeedback.propTypes = {
  formState: PropTypes.shape({
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    successDescription: PropTypes.string,
  }),
  $styles: PropTypes.object,
};

export default FormStateFeedback;
