import { createQueryKey, useGet, useGetInfinite } from '@remote-com/data-layer';
import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { getNotificationContent } from '@/src/domains/inAppNotifications/config';

const refetchInterval = 60 * 60 * 1000;
const UPDATE_NOTIFICATION_QUERY_PARAMS = { page: 1, pageSize: 20 };

export const createUpdatesQueryKey = () => createQueryKey('/api/v1/updates');
export const createUpdatesNotificationsQueryKey = () =>
  createQueryKey('/api/v1/updates/notifications', {
    queryParams: UPDATE_NOTIFICATION_QUERY_PARAMS,
    queryKeyOptions: { isInfinite: true },
  });

function getNextPageParam({ data }) {
  if (data?.totalPages > data?.currentPage) {
    return data.currentPage + 1;
  }
  // Return `undefined` to signal that the end of list is reached.
  return undefined;
}

function mergeNotifications(pages) {
  return pages.reduce((result, { data }) => {
    // In theory, the filtered results should always match the notifications received from the server.
    // However, if we encounter a notification with missing data, it will be represented as a null notification.
    // This can potentially disrupt the layout of the notifications container, as we rely on calculating the height of each notification.
    const notifications = data.items.filter((notification) => {
      const { body } = getNotificationContent(notification) ?? {};
      return !!body;
    });
    return [...result, ...notifications];
  }, []);
}

export const useInAppNotifications = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: updates,
    isLoading,
    isError,
    refetch,
  } = useGet('/api/v1/updates', {
    params: {},
    options: {
      select: ({ data }) => {
        /**
         * ⚠️ ATTENTION: DO NOT REMOVE OR ADD ANY NOTIFICATIONS FROM THE API RESPONSE!!
         *
         * If you want items to appear only for specific users or under certain conditions,
         * create them only for relevant users. Altering which items are displayed can lead
         * to side effects that may result in customer support requests.
         *
         * If you have any questions or doubts, reach out to #dev-team-web-experience
         */

        return data;
      },
      refetchInterval,
      staleTime: refetchInterval,
      refetchOnWindowFocus: true,
      onSuccess: ({ notifications: latestNotifications }) => {
        const cache = queryClient.getQueryState(createUpdatesNotificationsQueryKey());
        if (cache) {
          const notifications = latestNotifications.items.filter((notification) => {
            const { body } = getNotificationContent(notification) ?? {};
            return !!body;
          });
          const firstPage = cache?.data?.pages[0].data;
          if (firstPage) {
            // Compare first page of notifications with the incoming notifications. When diff is empty means there's no new notifications
            const newItems = differenceBy(notifications, firstPage.items, 'slug');
            // Whenever new notifications are received, they will be added to the top of the first page of notifications
            if (newItems.length > 0) {
              const clonedCache = cloneDeep(cache.data);
              clonedCache.pages[0].data.items = [...newItems, ...cache?.data.pages[0].data.items];
              queryClient.setQueryData(createUpdatesNotificationsQueryKey(), clonedCache);
            }
          }
        }
      },
    },
  });

  const openOverlay = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  return {
    openOverlay,
    closeOverlay,
    isOverlayOpen,
    isLoading,
    isError,
    refetch,
    updates,
  };
};

export const useInfiniteNotifications = () => {
  const queryClient = useQueryClient();
  return useGetInfinite('/api/v1/updates/notifications', {
    params: {
      queryParams: UPDATE_NOTIFICATION_QUERY_PARAMS,
    },
    options: {
      getNextPageParam,
      keepPreviousData: true,
      initialData: () => {
        const notifications =
          queryClient.getQueryState('/api/v1/updates')?.data?.data.notifications;

        if (notifications) {
          return {
            pages: [
              {
                data: {
                  ...notifications,
                  currentPage: notifications.currentPage ?? 1,
                },
              },
            ],
          };
        }
        return undefined;
      },
      select: ({ pages }) => {
        const { totalPages, currentPage } = pages[pages.length - 1].data;
        return {
          items: mergeNotifications(pages),
          totalPages,
          currentPage,
        };
      },
    },
  });
};
