// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneUserPlus = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M4.25 6a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Z" /><path fillRule="evenodd" clipRule="evenodd" d="M20.75 7a.75.75 0 0 0-1.5 0v2.25H17a.75.75 0 0 0 0 1.5h2.25V13a.75.75 0 0 0 1.5 0v-2.25H23a.75.75 0 0 0 0-1.5h-2.25V7ZM.25 18.95a5.7 5.7 0 0 1 5.7-5.7h6.1a5.7 5.7 0 0 1 5.7 5.7c0 2.1-1.7 3.8-3.8 3.8h-9.9a3.8 3.8 0 0 1-3.8-3.8Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneUserPlus);
export { ForwardRef as IconV2DuotoneUserPlus };