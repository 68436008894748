import { InputSelectComponents, Stack } from '@remote-com/norma';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { ThemeConsumer, ThemeProvider } from 'styled-components';

import { FlagIcon, PreloadFlagsSprite } from '@/src/components/Svg/sprites/FlagIcon';
import { SelectField } from '@/src/components/Ui/Form/formikIntegration/SelectField';
import { countryPropType } from '@/src/helpers/validationSchema';

/**
 * The only way to change the native properties of the input element in react-select is to pass a
 * custom input component with different props. The reason is these properties are hardcoded in the
 * input specification in the library.
 *
 * https://github.com/JedWatson/react-select/issues/3500#issuecomment-480410333
 *
 * The reason for this fix is because chrome ignores the property autocomplete="off" and for this
 * use case, we really need to disable autocomplete.
 *
 * For more information about Google decision on ignoring autocomplete, check:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164
 *
 * To disable 1Password we use the attribute name with the keyword search
 * https://1password.community/discussion/117501/as-a-web-developer-how-can-i-disable-1password-filling-for-a-specific-field
 */
export const InputCountry = ({ autoComplete, ...props }) => {
  return (
    <InputSelectComponents.Input
      name="country-search"
      {...props}
      autoComplete="fixing-autocomplete"
    />
  );
};

export const OptionCountry = ({ children, ...props }) => {
  const {
    data: { name, label },
    selectProps,
  } = props;
  const { SideElement } = selectProps;

  return (
    <components.Option {...props}>
      <Stack direction="row" gap={4} width="100%">
        <FlagIcon name={name || label} />
        {children}
        {SideElement && <SideElement option={props.data} />}
      </Stack>
    </components.Option>
  );
};

function getCountryOptionLabel(country) {
  return country.label || country.name;
}

function getCountryOptionValue(country) {
  return country.slug || country.value;
}

/**
 * It's common to refer to some countries with acronyms or synonyms
 * This list aims to bypass the BE limitation and handles the missing ones for a better UX
 */
const countryNameWithAlternatives = {
  US: ['US', 'USA', 'United States', 'United States (US)', 'States'],
  UK: [
    'United Kingdom',
    'United Kingdom (UK)',
    'Kingdom', // If users start their search by typing Kingdom
    'UK',
    'England',
    'Great Britain',
    'Britain', // If users start their search by typing Britain
    'GB',
    'Scotland',
    'Wales',
    'Northern Ireland',
  ],
  UAE: [
    'UAE',
    'United Arab Emirates',
    'United Arab Emirates (UAE)',
    'Arab Emirates',
    'Emirates', // If users start their search by typing Emirates
  ],
  NZ: ['New Zealand', 'Zealand', 'NZ'],
  ASM: ['American Samoa', 'Samoa'],
  ATG: ['Antigua and Barbuda', 'Barbuda'],
  BIH: ['Bosnia and Herzegovina', 'Herzegovina'],
  VGB: ['British Virgin Islands', 'Virgin Islands'],
  COD: ['Democratic Republic of the Congo', 'Republic of the Congo', 'Congo'],
  SLV: ['El Salvador', 'Salvador'],
  GNQ: ['Equatorial Guinea', 'Guinea'],
  FLK: ['Falkland Islands (Malvinas)', 'Malvinas'],
  GUF: ['French Guiana', 'Guiana'],
  PYF: ['French Polynesia', 'Polynesia'],
  NCL: ['New Caledonia', 'Caledonia'],
  MKD: ['North Macedonia', 'Macedonia'],
  COG: ['Republic of the Congo', 'Congo'],
  SHN: ['Saint Helena', 'Helena'],
  KNA: ['Saint Kitts and Nevis', 'Kitts and Nevis', 'Nevis'],
  LCA: ['Saint Lucia', 'Lucia'],
  SPM: ['Saint Pierre and Miquelon', 'Pierre and Miquelon', 'Miquelon'],
  VCT: ['Saint Vincent and the Grenadines', 'Vincent and the Grenadines', 'Grenadines'],
  SMR: ['San Marino', 'Marino'],
  STP: ['Sao Tome and Principe', 'Principe'],
  SAU: ['Saudi Arabia', 'Arabia'],
  ZAF: ['South Africa', 'Africa'],
  KOR: ['South Korea', 'Korea'],
  SSD: ['South Sudan', 'Sudan'],
  TTO: ['Trinidad and Tobago', 'Tobago'],
  TCA: ['Turks and Caicos Islands', 'Caicos Islands'],
  VIR: ['U.S. Virgin Islands', 'Virgin Islands'],
  WLF: ['Wallis and Futuna Islands', 'Futuna Islands'],
};

const countryNameWithAlternativesList = Object.values(countryNameWithAlternatives).map(
  (list) => list.map((val) => val.toLowerCase()) // needed for equality check
);

export const getCountryNameWithAlternatives = (countryName = '') => {
  const alternativeNames = countryNameWithAlternativesList.find((names) =>
    names.includes(countryName.toLowerCase())
  );
  // Return the country name when no alternatives are available
  return alternativeNames || [countryName.toLowerCase()];
};

export const getCountrySearchTerms = (countryOptionLabel = '', countries = []) => {
  const searchTerms = new Set();

  // Find the matching country and add the country label
  const countryMatch = countries.find(
    (country) =>
      country.name?.toLowerCase() === countryOptionLabel ||
      country.label?.toLowerCase() === countryOptionLabel
  );
  searchTerms.add(countryOptionLabel);

  // Add the country code if available
  if (countryMatch?.code) {
    searchTerms.add(countryMatch.code.toLowerCase());
  }

  // Find alternative names if they exist
  const alternativeNames = countryNameWithAlternativesList.find((names) =>
    names.includes(countryOptionLabel)
  );
  if (alternativeNames?.length > 0)
    alternativeNames.forEach((alt) => {
      searchTerms.add(alt.toLowerCase());
    });

  return searchTerms;
};

const createFilterOption = (countries) => (option, input) => {
  const countryOptionLabel = option.label.toLowerCase();
  const searchTerms = getCountrySearchTerms(countryOptionLabel, countries);
  if (searchTerms.size === 0) return false;

  return searchTerms
    ? [...searchTerms].some((term) => term.startsWith(input.toLowerCase()))
    : false;
};

/**
 * CountrySelectField
 *
 * @export
 * @type {import('@/src/components/Form/DynamicForm/types').RenderCountriesField}
 * @return {JSX.Element}
 */
export const CountrySelectField = ({ countries, ...props }) => {
  const countryOptions = countries || props?.options || [];

  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider theme={{ withSearchIcon: theme.variant === 'outline' }}>
          <PreloadFlagsSprite />
          <SelectField
            data-testid="country-select-field"
            components={{
              Input: InputCountry,
              Option: OptionCountry,
            }}
            getOptionLabel={getCountryOptionLabel}
            getOptionValue={getCountryOptionValue}
            options={countryOptions}
            ref={props.innerRef}
            isControlled
            filterOption={createFilterOption(countryOptions)}
            {...props}
          />
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

CountrySelectField.propTypes = {
  countries: PropTypes.arrayOf(countryPropType),
};

export const CountryMultiSelectField = ({ countries, ...props }) => (
  <SelectField multiple components={{ Option: OptionCountry }} options={countries} {...props} />
);

CountryMultiSelectField.propTypes = {
  countries: PropTypes.arrayOf(countryPropType),
};
