import type * as CSS from 'csstype';
import type { DefaultTheme } from 'styled-components';
import styled, { css, keyframes } from 'styled-components';

import { IconChevronDown } from '../../icons/build/IconChevronDown';
import { Box } from '../../layout';
import { focusVisible } from '../common.styled';
import { CopyToClipboardButton } from '../copy-to-clipboard/CopyToClipboard.styled';
import { FeedbackMessage } from '../feedback-message';
import { MaskSecret } from '../mask-secret';
import { Pill } from '../pill';

export const InfoBlockHeading = styled(Box)<{ $justifyContent?: CSS.Property.JustifyContent }>`
  display: flex;
  ${({ $justifyContent }) => css`
    justify-content: ${$justifyContent || 'space-between'};
  `}
`;

export const InfoBlockActions = styled(Box)<{ $isHeader: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme, $isHeader }) => theme.space[$isHeader ? 6 : 4]}px;
`;

export const InfoBlockListNestedItemActions = styled(InfoBlockActions)`
  margin-left: auto;
`;

const titleColumnWidthPercentage = 40;

const calculateIndentationOffset = (indentationLevel: number, theme: DefaultTheme) => {
  const spacingCoefficient = theme.space[6];
  return `${spacingCoefficient * indentationLevel}px`;
};

export const InfoBlockListItem = styled(Box)<{ $indentationLevel: number }>`
  --indentationOffset: ${({ $indentationLevel, theme }) =>
    calculateIndentationOffset($indentationLevel, theme)};

  box-sizing: border-box;
  padding: var(--item-vertical-padding) 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  display: flex;
`;

export const StyledIconChevronDown = styled(IconChevronDown)`
  /* Workaround as Safari fails to respect flex alignment in summary element */
  position: relative;
  top: 3px;

  fill: var(--colors-irisBlue);

  width: 16px;
  height: 16px;
  margin: 0;
  margin-left: ${({ theme }) => theme.space[2]}px;
  transition: transform 0.2s ease;
`;

export const InfoBlockListItemTitle = styled.dt`
  flex-shrink: 0;
  align-items: baseline;
  display: flex;
  padding-right: ${({ theme }) => theme.space[5]}px;
  width: ${titleColumnWidthPercentage}%;
  ${({ theme }) => theme.typography.smMedium}
`;

export const InfoBlockListNestedItemTitle = styled.summary`
  display: flex;
  padding: var(--item-vertical-padding) 0;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  ${({ theme }) => theme.typography.smMedium}
  cursor: pointer;

  list-style: none;
  ::marker,
  ::-webkit-details-marker {
    display: none;
  }

  ${focusVisible(css`
    outline: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--colors-irisBlue);
  `)}
`;

export const InfoBlockListNestedItemTitleWrapper = styled.span<{ $indentationLevel: number }>`
  display: inline-flex;

  /* The following code is what makes the top level value of items that have nested list,
  be vertically aligned with every other value. It's similar to what's done for InfoBlockListItemTitle. */
  --indentationOffset: ${({ $indentationLevel, theme }) =>
    calculateIndentationOffset($indentationLevel, theme)};
  --rootListElementWidth: calc(100% + var(--indentationOffset));
  width: calc(
    var(--rootListElementWidth) * ${titleColumnWidthPercentage / 100} - var(--indentationOffset)
  );
`;

export const InfoBlockListNestedItemTopValue = styled.span`
  color: ${({ theme }) => theme.colors.bayoux};
  ${({ theme }) => theme.typography.sm}
`;

export const InfoBlockListNestedItem = styled.details`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};

  ${InfoBlockListItem} {
    margin-left: ${({ theme }) => theme.space[6]}px;

    ${InfoBlockListItemTitle} {
      /* The following code is what makes all the values in the right column be vertically aligned in nested lists.
      The right column will always have the same width.
      The left column will vary on width, depending on the level of nesting.
      https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/7735#note_962713215 */
      --rootListElementWidth: calc(100% + var(--indentationOffset));
      width: calc(
        var(--rootListElementWidth) * ${titleColumnWidthPercentage / 100} - var(--indentationOffset)
      );
      ${({ theme }) => theme.typography.sm}
    }
  }

  ${InfoBlockListItem}:last-child {
    border-bottom: none;
  }

  &[open] {
    & > summary {
      ${StyledIconChevronDown} {
        transform: rotate(180deg);
      }
    }
  }

  & & {
    margin-left: ${({ theme }) => theme.space[6]}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  }

  & &:last-child {
    border-bottom: none;
  }
`;

export const InfoBlockListItemValue = styled.dd`
  color: var(--colors-bayoux);
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex: 1 2 auto;
  width: 33%;
  ${({ theme }) => theme.typography.sm}

  /* This "cancels" the CopyToClipboard's outer padding while keeping the same clickable area,
   so that the copyable values end up with the same height as the regular text. */
  ${CopyToClipboardButton} {
    margin-top: -${({ theme }) => theme.space[4]}px;
    margin-bottom: -${({ theme }) => theme.space[4]}px;
    margin-left: -7px;
  }
`;

export const TextContentWithEllipsis = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: pre-line;
  word-wrap: break-word;
`;

const determineMargin = (hasLeftMargin?: boolean, hasTopMargin?: boolean) => {
  if (hasLeftMargin) {
    return '24px 0 0 48px';
  }

  if (hasTopMargin) {
    /* When there is no left margin, the space between
    the HeadingText and the InfoBlockList is more noticeable,
    reducing this to 19px reduces the large space
    */
    return '19px 0 0 0';
  }

  return '0';
};

export const StyledFeedbackMessage = styled(FeedbackMessage)<{
  $hasLeftMargin: boolean;
  $hasTopMargin: boolean;
}>`
  margin: ${({ $hasLeftMargin, $hasTopMargin }) => determineMargin($hasLeftMargin, $hasTopMargin)};
`;

export const InfoBlockList = styled.dl<{
  $hasLeftMargin?: boolean;
  $hasTopMargin?: boolean;
  $size?: 'md' | 'sm';
}>`
  margin: ${({ $hasLeftMargin, $hasTopMargin }) => determineMargin($hasLeftMargin, $hasTopMargin)};

  /* Note: design define a 34px height for small size, so we need to use a value outside the scale */
  --item-vertical-padding: ${({ theme, $size }) => ($size === 'md' ? theme.space[4] : 5)}px;
`;

export const InfoBlockListItemValueEmptyOptional = styled.span`
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const InfoBlockMaskSecretStyled = styled(MaskSecret)`
  width: 100%;
`;

export const PillStyled = styled(Pill)`
  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const pulseAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
`;

export const SkeletonLine = styled.span`
  position: relative;
  display: block;
  height: 1em;
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border-radius: ${({ theme }) => theme.borderRadius['2xs']};
  animation: ${pulseAnimation} 1.5s ease-in-out 0.5s infinite;

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    border-right: 16px solid var(--colors-blank);
    padding-right: ${({ theme }) => theme.space[5]}px;
  }
`;
