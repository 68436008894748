import styled, { css } from 'styled-components';

import { Box } from '../../foundations/layout/Box';

import { Input } from './Input';
import { INPUT_STATES } from './styles';

type LabelProps = { checked?: boolean; disabled?: boolean };

export const Label = styled(Box).attrs({
  as: 'label',
  display: 'flex',
  cursor: 'pointer',
})<LabelProps>`
  ${(props) => {
    return css`
      ${Input} {
        ${props.checked ? INPUT_STATES.default.checked : INPUT_STATES.default.resting}
      }

      &:hover {
        ${!props.disabled &&
        css`
          ${Input} {
            ${props.checked ? INPUT_STATES.hover.checked : INPUT_STATES.hover.resting}
          }
        `}
      }

      &:focus {
        ${Input} {
          ${props.checked ? INPUT_STATES.focus.checked : INPUT_STATES.focus.resting}
        }
      }

      &:active {
        ${Input} {
          ${props.checked ? INPUT_STATES.active.checked : INPUT_STATES.active.resting}
        }
      }

      &[disabled] {
        pointer-events: none;
        ${Input} {
          ${props.checked ? INPUT_STATES.disabled.checked : INPUT_STATES.disabled.resting}
        }
      }
    `;
  }}
`;
