import { Avatar, Box, SROnly, Stack, menuItemStyles } from '@remote-com/norma';
import { IconV2OutlineCollapse } from '@remote-com/norma/icons/IconV2OutlineCollapse';
import { IconV2OutlineExpand } from '@remote-com/norma/icons/IconV2OutlineExpand';
import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';

import type { UserLinkedAccount } from '@/src/api/config/api.types';
import type { UserProfile } from '@/src/api/config/employ/userProfiles.types';
import { TruncatedText } from '@/src/components/TruncatedText';
import { getProfileItemCopy } from '@/src/domains/navigation/profiles-menu/helpers';
import { ProfileIcon } from '@/src/domains/navigation/profiles-menu/ProfileIcon';

// We don't want any focus styles and with `Button` we'd have to override them.
// eslint-disable-next-line remote/norma-prefer-button
const StyledWrapper = styled.button`
  ${menuItemStyles}

  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[4]}px;
  padding: ${({ theme }) => `${theme.space[3]}px ${theme.space[4]}px`};
  text-align: left;
`;

const StyledProfileIcon = styled(ProfileIcon)`
  flex-shrink: 0;
`;

const StyledBody = styled(Stack)`
  flex-grow: 1;
  width: 0;
`;

const StyledProfileName = styled(TruncatedText)`
  ${({ theme }) => theme.typography.smMedium}
`;

const StyledCompanyName = styled(TruncatedText)`
  ${({ theme }) => theme.typography['2xs']}

  color: ${({ theme }) => theme.colors.grey[600]};
`;

const StyledToggleIconWrapper = styled(Box)`
  display: grid;
  flex-shrink: 0;
  height: 34px;
  place-items: center;
  width: 34px;
`;

const StyledToggleIcon = styled.svg`
  color: ${({ theme }) => theme.colors.grey[700]};
  height: 16px;
  width: 16px;
`;

export interface ProfilesMenuTriggerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  open?: boolean;
  isAccountsMenu: boolean;
  profile?: UserProfile;
  account?: UserLinkedAccount;
}

export const ProfilesMenuTrigger = forwardRef<HTMLButtonElement, ProfilesMenuTriggerProps>(
  ({ open = false, profile, account, isAccountsMenu, ...rest }, ref) => {
    const profileItemCopy = profile && getProfileItemCopy(profile);

    return (
      <StyledWrapper
        ref={ref}
        data-testid={isAccountsMenu ? 'accounts-menu-trigger' : 'profiles-menu-trigger'}
        {...rest}
      >
        {!isAccountsMenu && profile && (
          <>
            <SROnly>Selected Profile: </SROnly>
            <StyledProfileIcon
              countryName={profile.countryName}
              data-testid={`country-flag-${profile.countryName}`}
            />
            <StyledBody>
              <StyledProfileName>{profileItemCopy!.title}</StyledProfileName>
              <StyledCompanyName>{profileItemCopy!.subtitle}</StyledCompanyName>
            </StyledBody>
            <StyledToggleIconWrapper>
              <StyledToggleIcon as={open ? IconV2OutlineCollapse : IconV2OutlineExpand} />
            </StyledToggleIconWrapper>
          </>
        )}
        {isAccountsMenu && account && (
          <>
            <SROnly>Selected Account: </SROnly>
            <Box>
              <Avatar size="sm" fallback={account.email} src={account?.profilePicture} />
            </Box>
            <StyledBody>
              <StyledProfileName>{account.email}</StyledProfileName>
            </StyledBody>

            <StyledToggleIconWrapper>
              <StyledToggleIcon as={open ? IconV2OutlineCollapse : IconV2OutlineExpand} />
            </StyledToggleIconWrapper>
          </>
        )}
      </StyledWrapper>
    );
  }
);
