import { Skeleton, TableBodyInputSizeProvider } from '@remote-com/norma';
import isFunction from 'lodash/isFunction';
import React, { useEffect, useRef } from 'react';

import TableCell from '@/src/components/Table/Components/TableCell';
import { filterHiddenCells } from '@/src/components/Table/helpers';

import TableRow from './TableRow';

const DEFAULT_NUMBER_OF_SKELETON_ROWS = 6;

/**
 * Returns <tbody /> and its rows
 */
export const TableBody = ({
  onRowClick,
  isCheckboxDisabled,
  isLoading,
  prepareRow,
  rows,
  rowProps,
  page,
  clientSidePagination,
  numberOfColumns,
  renderRowSubComponent,
  hasSelectCheckboxes,
  getTableBodyProps,
}) => {
  // If clientSidePagination is true, we should read our data from a different source: `page`.
  const data = clientSidePagination ? page : rows;

  // In order to avoid CLS, we keep track of the number of previously loaded rows so we can replace them
  // with skeleton rows while fetching new data
  const numberOfLoadedRows = useRef(data.length || DEFAULT_NUMBER_OF_SKELETON_ROWS);

  useEffect(() => {
    // Whenever we finish fetching data, update the number of rows
    if (!isLoading) {
      numberOfLoadedRows.current = data.length || DEFAULT_NUMBER_OF_SKELETON_ROWS;
    }
  }, [data, isLoading]);

  if (isLoading) {
    return (
      <tbody {...getTableBodyProps()} data-testid="loader">
        {Array.from(Array(numberOfLoadedRows.current)).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from(Array(numberOfColumns)).map((_unused, colIndex) => (
              <TableCell key={colIndex}>
                <Skeleton variant="text" width={(rowIndex + colIndex + 1) % 3 === 0 ? 80 : 100} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
    );
  }

  return (
    <tbody {...getTableBodyProps()}>
      <TableBodyInputSizeProvider>
        {data.map((row) => {
          prepareRow(row);

          const handleTableRowClick =
            isFunction(onRowClick) && !row.original.clickDisabled && ((e) => onRowClick(row, e));

          return (
            <React.Fragment key={row.id}>
              <TableRow
                {...row.getRowProps(rowProps)}
                onClick={handleTableRowClick}
                isSelected={row.isSelected}
                isCheckboxDisabled={
                  isFunction(isCheckboxDisabled) ? isCheckboxDisabled(row) : false
                }
                isExpanded={row.isExpanded && row.subRows.length && row.depth === 0}
                isSubRow={row.depth > 0}
              >
                {filterHiddenCells(row.cells).map((cell) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell
                    $overflowVisible={!cell.column.ellipsis}
                    $maxWidth={cell.column.maxWidth}
                    $hasSelectCheckbox={hasSelectCheckboxes}
                    $ellipsis={cell.column.ellipsis}
                    $noSpacing={cell.column.noSpacing}
                    $styles={cell.column.tdCssStyles}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
              {row.isExpanded && renderRowSubComponent?.({ row })}
            </React.Fragment>
          );
        })}
      </TableBodyInputSizeProvider>
    </tbody>
  );
};
