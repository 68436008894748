import { useGet } from '@remote-com/data-layer';

export default function useCompanyDetailsData(options = {}) {
  return useGet('/api/v1/employer/company', {
    options: {
      ...options,
      select: ({ data }) => (options.select ? options.select(data) : data),
    },
  });
}
