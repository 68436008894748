// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineActivity = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M15 4.25c.3 0 .57.18.69.45l2.8 6.55H22a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.69-.45L15 6.9 9.69 19.3a.75.75 0 0 1-1.38 0l-2.8-6.55H2a.75.75 0 0 1 0-1.5h4c.3 0 .57.18.69.45L9 17.1l5.31-12.4a.75.75 0 0 1 .69-.45Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineActivity);
export { ForwardRef as IconV2OutlineActivity };