import type { YesNoValue } from '@/src/components/Form/DynamicForm/types';
import { generateSelectOptions, generateSelectOptionsAsJson } from '@/src/helpers/forms';
import type { ScreamingSnakeCaseMapping } from '@/src/types/case';

export const textInputTypes = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  SELECT: 'select',
  COUNTRIES: 'countries',
  TEL: 'tel',
  EMAIL: 'email',
  MONEY: 'money',
  DATE: 'date',
  DATE_RANGE: 'date-range',
  CURRENCIES: 'currencies',
  TIME: 'time',
} as const;

export const checkboxTypes = {
  CHECKBOX: 'checkbox',
  ACK_CHECK: 'ack-check',
} as const;

export const supportedTypes = {
  FILE: 'file',
  RADIO: 'radio',
  /** @deprecated */
  RADIO_CARD: 'radio-card',
  GROUP_ARRAY: 'group-array',
  EXTRA: 'extra',
  STATEMENT: 'statement',
  HIDDEN: 'hidden',
  FIELDSET: 'fieldset',
  FIELDSET_FLAT: 'fieldset-flat',
  WORK_SCHEDULE: 'work-schedule',
  WORK_WEEK_SCHEDULE: 'work-week-schedule',
  BENEFITS: 'benefits',
  SIGNATURE: 'signature',
  SELECT_MULTIPLE: 'select-multiple',
  ...textInputTypes,
  ...checkboxTypes,
} as const;

export const yesNoValues: ScreamingSnakeCaseMapping<YesNoValue> = {
  YES: 'yes',
  NO: 'no',
};

export const yesNoLabels = {
  [yesNoValues.YES]: 'Yes',
  [yesNoValues.NO]: 'No',
};
const noLabel = { [yesNoValues.NO]: yesNoLabels[yesNoValues.NO] };

export const yesNoOptions = generateSelectOptions(yesNoLabels);
export const yesNoOptionsAsJson = generateSelectOptionsAsJson(yesNoLabels);

export const noOption = generateSelectOptions(noLabel);
export const noOptionAsJson = generateSelectOptionsAsJson(noLabel);

export const yesNoBooleanLabels = {
  true: 'Yes',
  false: 'No',
};

export const yesNoBooleanOptions = generateSelectOptions(yesNoBooleanLabels);

export const billingAddressLabels = {
  [yesNoValues.YES]: 'Same as my residential address',
  [yesNoValues.NO]: 'Different than my residential address',
};

export const billingAddressOptions = generateSelectOptions(billingAddressLabels);

export const flatFieldsetsSchemaKey = 'x-jsf-fieldsets';
