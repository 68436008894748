import { useState } from 'react';

import { getLocalStorageItem, setLocalStorageItem } from '@/src/helpers/localStorage';

function useLocalStorage<T>(key: string, initialValue?: T): [T | null, (value: T) => boolean] {
  const [storedValue, setStoredValue] = useState(getLocalStorageItem(key, initialValue));
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      setLocalStorageItem(key, valueToStore);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  return [storedValue, setValue];
}

export default useLocalStorage;
