import { useEffect, useRef } from 'react';

export const AutoFocusInput = ({ autoFocus, value, defaultValue, ...props }) => {
  const inputElement = useRef();

  useEffect(() => {
    // setTimeout is needed for the focus to trigger
    setTimeout(() => {
      if (autoFocus && inputElement.current) {
        inputElement.current.focus();
      }
    }, 0);
  }, [autoFocus]);

  // TODO: refactor readOnly input mechanism to get rid of defaultValue, and use value instead
  const controlledValue = {};
  if (props.readOnly && typeof defaultValue !== 'undefined') {
    controlledValue.defaultValue = defaultValue;
  } else {
    controlledValue.value = typeof value === 'undefined' ? '' : value;
  }

  return <input {...controlledValue} {...props} ref={inputElement} />;
};
