import pickBy from 'lodash/pickBy';
import type { InputProps } from 'react-select';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import type { InputSelectProps } from '..';
import { Box } from '../../../layout';
import { inputStyles, useFormGroupContext } from '../../form-group';

const StyledInput = styled(Box).attrs({ as: 'input' })`
  ${inputStyles}
  caret-color: ${({ isSearchable, theme }: { isSearchable?: boolean; theme: DefaultTheme }) =>
    isSearchable ? theme.colors.grey[900] : 'transparent'};
  flex: 1;
`;

export function Input({
  innerRef,
  theme,
  cx,
  isDisabled,
  selectProps,
  ...restProps
}: InputProps & { selectProps: InputSelectProps }) {
  /**
   * NOTE: Data attributes are not being passed down through `react-select`
   * so we need to extract them from `inputProps` and pass them down manually. 🤷
   * See: https://linear.app/remote/issue/DEVXP-325/data-testid-and-other-data-attributes-are-not-being-passed-down
   */
  const inputProps = useFormGroupContext();
  const dataAttributes = pickBy(inputProps, (_, key) => key.startsWith('data-'));
  return (
    // @ts-expect-error
    <StyledInput
      disabled={isDisabled}
      ref={innerRef}
      {...restProps}
      {...dataAttributes}
      size={selectProps.size}
      isSearchable={selectProps.isSearchable}
    />
  );
}
