import { diff } from 'deep-object-diff';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';
import type { AnyColumnsConfiguration } from '@/src/components/Table/hooks/useColumnsState';
import { COLUMN_STATES } from '@/src/components/Table/hooks/useColumnsState';
import type { ColumnsConfigurationVisibility } from '@/src/components/Table/hooks/useColumnsState/types';
import { normalizeColumnsConfiguration } from '@/src/components/Table/hooks/useColumnsState/useColumnsState.typedHelpers';
import type { SortBy } from '@/src/components/Table/types';
import { getExceptionInfo } from '@/src/helpers/general';

type buildTableViewProps = {
  name: string;
  columnsConfiguration?: AnyColumnsConfiguration;
  filters?: any;
  sortBy?: SortBy[];
  persistenceKey: string;
};

// Constructs a table view object, used on the frontend, and also what we persist Tiger
export const buildTableView = ({
  name,
  columnsConfiguration,
  filters,
  sortBy,
  persistenceKey,
}: buildTableViewProps): TableViewsApi.CreateTableViewParams => {
  return {
    name,
    config: JSON.stringify({
      columns:
        columnsConfiguration && normalizeColumnsConfiguration(columnsConfiguration)?.customizations,
      filters,
      sortBy,
    }),
    persistenceKey,
  };
};

const createDiffableFilterObject = (
  filters: TableViewsApi.TransformedFilter[],
  visibility: ColumnsConfigurationVisibility
) =>
  Object.fromEntries(
    filters
      .filter((filter) => visibility[filter.id] !== COLUMN_STATES.invisible)
      .map((f) => [f.id, f.value])
  );

const createDiffableSortByObject = (sortBy: SortBy[]) =>
  Object.fromEntries(sortBy.map((f) => [f.id, f.desc]));

export const getVisibilityStateDiff = (
  base: ColumnsConfigurationVisibility = {},
  compare: ColumnsConfigurationVisibility = {}
) => {
  const difference = diff(base, compare);
  return Object.keys(difference).length;
};

export const getFiltersStateDiff = (
  baseFilters: TableViewsApi.TransformedFilter[] = [],
  compareFilters: TableViewsApi.TransformedFilter[] = [],
  visibility: ColumnsConfigurationVisibility = {}
) => {
  const baseObj = createDiffableFilterObject(baseFilters, visibility);
  const compareObj = createDiffableFilterObject(compareFilters, visibility);
  const difference = diff(baseObj, compareObj);
  return Object.keys(difference).length;
};

export const numberOfOrderChangesWithOffset = (
  numberOfOrderChanges: number,
  numberOfVisibilityChanges: number
) =>
  numberOfVisibilityChanges && numberOfOrderChanges
    ? numberOfOrderChanges - 1
    : numberOfOrderChanges;

export const getOrderStateDiff = (base: string[] = [], compare: string[] = []) => {
  if (base.length !== compare.length) {
    return 1;
  }

  const orderHasChanged = base.some((item, index) => item !== compare[index]);
  return orderHasChanged ? 1 : 0;
};

export const getSortByStateDiff = (base: SortBy[] = [], compare: SortBy[] = []) => {
  const baseObj = createDiffableSortByObject(base);
  const compareObj = createDiffableSortByObject(compare);
  const difference = diff(baseObj, compareObj);
  // Since the key never changes, we always want to consider changes
  // to the sort by state as a single change to the table views state
  return Object.keys(difference).length > 0 ? 1 : 0;
};

export const getTableViewErrorInfo = (exception: unknown) => {
  const info = getExceptionInfo(exception);
  if (info.errors.uniqueViewName[0] === 'has already been taken') {
    return 'A table view with this name already exists.';
  }

  return getExceptionInfo(exception).message;
};
