import { useGet } from '@remote-com/data-layer';
import camelCase from 'lodash/camelCase';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';

import { useTableFetcherWithQS } from '@/src/components/Table/hooks/useTableFetcher';
import { useUserCan } from '@/src/components/UserCan';
import { useUserContext } from '@/src/components/UserProvider';
import { EMPLOYER_REQUESTS_TABS, requestStatus } from '@/src/domains/employment/requests/constants';
import {
  useRemoteWorkingRequestsFetchFunction,
  useTravelRequestsFetchFunction,
} from '@/src/domains/employment/requests/mobilityRequestsHooks';
import {
  fetchEmployerRequests,
  fetchEmployeeRequests,
  fetchAdminRequests,
} from '@/src/domains/employment/requests/services';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { isEmployer as isEmployerFn } from '@/src/domains/registration/auth/helpers';
import { userCacheKeys } from '@/src/domains/userCache/constants';
import { getFromUserCache } from '@/src/domains/userCache/helpers';
import { captureHTTPException } from '@/src/helpers/captureException';

export const EMPLOYMENT_REQUESTS_QUERY = 'employmentRequestsQuery';

function getFetchFunction({ isEmployer, isAdmin }) {
  if (isAdmin) {
    return fetchAdminRequests;
  }

  if (isEmployer) {
    return fetchEmployerRequests;
  }

  return fetchEmployeeRequests;
}

const sortDataLocally = (data, params) => {
  const { sortBy, order } = params;
  // if no sorting, just return the data
  if (!sortBy) {
    return data;
  }
  // sortBy will be in snake_case since it is sent to the API
  return orderBy(data, camelCase(sortBy), order);
};

/**
 * Combining all the mobility request data (travel letter, remote work)
 * @param {Array} allPages - array of page counts [1,2,3,4]
 * @param {Boolean} param.isEmployer
 * @param {Boolean} param.isAdmin
 * @param {Boolean} param.employerCanViewMobilityRequests
 * @param {Function} param.travelRequestsFetchFn
 * @param {Function} param.remoteWorkingRequestsFetchFn
 * @param {String} param.selectedTab
 * @returns {Object} - mobilityRequests, mobilityTotalCount, mobilityTotalPages
 */
const getMobilityRequestData = async ({
  isEmployer,
  employerCanViewMobilityRequests,
  isAdmin,
  travelRequestsFetchFn,
  remoteWorkingRequestsFetchFn,
  selectedTab,
}) => {
  let travelLetterRequests = [];
  let totalCountTravelLetter = 0;
  let totalPagesTravelLetter = 0;
  let remoteWorkingRequests = [];
  let totalCountRemoteWorking = 0;
  let totalPagesRemoteWork = 0;

  if ((isEmployer && employerCanViewMobilityRequests) || (!isAdmin && !isEmployer)) {
    if (!isAdmin) {
      // Admins don't see travel and remote work on the Request table
      try {
        const { data: travelRequests } = await travelRequestsFetchFn();
        // If this fails, the fetchFn should be unaffected
        if (travelRequests?.totalCount > 0) {
          if (isEmployer && selectedTab === EMPLOYER_REQUESTS_TABS.EMPLOYER.value) {
            travelLetterRequests = [];
          } else {
            travelLetterRequests = travelRequests?.travelLetterRequests;
          }
          totalPagesTravelLetter = travelRequests.totalPages;
          totalCountTravelLetter = travelRequests.totalCount;
        }
        const { data: remoteWorking } = await remoteWorkingRequestsFetchFn();
        // If this fails, the fetchFn should be unaffected
        if (remoteWorking?.totalCount > 0) {
          if (isEmployer && selectedTab === EMPLOYER_REQUESTS_TABS.EMPLOYER.value) {
            remoteWorkingRequests = [];
          } else {
            remoteWorkingRequests = remoteWorking?.remoteWorkRequests;
          }
          totalCountRemoteWorking = remoteWorking.totalCount;
          totalPagesRemoteWork = remoteWorking.totalPages;
        }
      } catch (e) {
        captureHTTPException(e);
      }
    }
  }

  const mobilityRequests = [...travelLetterRequests, ...remoteWorkingRequests] ?? [];
  const mobilityTotalCount = totalCountTravelLetter + totalCountRemoteWorking;
  const mobilityTotalPages = Math.max(totalPagesTravelLetter, totalPagesRemoteWork);

  return {
    mobilityRequests,
    mobilityTotalCount,
    mobilityTotalPages,
  };
};

/**
 * When on the EmployeeRequestTable or EmployerRequestTable, Employee tab,
 * we need to combine the total count of all the mobility requests to get the true page size
 * When not on those pages, pageSize will be taken directly from /employment-requests
 * @param {Array} allPages - array of page counts [1,2,3,4]
 * @param {Boolean} param.isEmployer
 * @param {Boolean} param.isAdmin
 * @param {String} param.selectedTab
 * @returns {Object}
 */
const getTotalPageCount = (allPages, { isEmployer, isAdmin, selectedTab }) => {
  // Need to gather all the page counts and set the totalPages to the highest
  const totalPages = Math.max(...allPages);

  return (isEmployer && selectedTab === EMPLOYER_REQUESTS_TABS.EMPLOYEE.value) ||
    (!isEmployer && !isAdmin)
    ? { totalPages }
    : {};
};

const accumulateTotalsFromObject = (totalsObject) => {
  const { submittedByEmployment, submittedByCompanyAdmin } = totalsObject;
  return submittedByEmployment + submittedByCompanyAdmin;
};

/**
 * Fetch all requests for the current employer or employee
 *
 * @export
 * @returns {Object}
 */
export function useFetchEmploymentRequests({
  isEmployer,
  isAdmin,
  params = {},
  options = {},
  selectedTab,
} = {}) {
  const { userCan } = useUserCan();
  const [totals, setTotals] = useState(null);
  // Set the apiQueryParams to state, useTravelRequestsFetchFunction needs access
  const [apiQueryParams, setApiQueryParams] = useState({});
  const [totalRequests, setTotalRequests] = useState(null);

  const travelRequestsFetchFn = useTravelRequestsFetchFunction({
    params,
    apiQueryParams,
    isEmployer,
    isAdmin,
  });

  const remoteWorkingRequestsFetchFn = useRemoteWorkingRequestsFetchFunction({
    params,
    apiQueryParams,
    isEmployer,
    isAdmin,
  });

  const employerCanViewMobilityRequests = userCan('read', Resources.employer.mobility_request);

  const fetchFunction = async (fetchParams, APIQuery) => {
    const fetchFn = getFetchFunction({ isEmployer, isAdmin });

    const { data } = await fetchFn({
      queryParams: { ...fetchParams, ...APIQuery },
    });

    const { mobilityRequests, mobilityTotalCount, mobilityTotalPages } =
      await getMobilityRequestData({
        isEmployer,
        employerCanViewMobilityRequests,
        isAdmin,
        travelRequestsFetchFn,
        remoteWorkingRequestsFetchFn,
        selectedTab,
      });

    const { totals: totalsObject, totalCount } = data;
    if (isAdmin || isEmployer) {
      const totalsSum = accumulateTotalsFromObject(totalsObject);
      const allTotals = totalsSum + mobilityTotalCount;
      setTotalRequests(allTotals);
    } else {
      // Counts should consider travelLetter count as well
      const allTotals = totalCount + mobilityTotalCount;
      setTotalRequests(allTotals);
    }

    setTotals({
      ...totalsObject,
      submittedByEmployment: totalsObject?.submittedByEmployment + mobilityTotalCount,
    });

    // Append travelLetterRequest data and sort
    const sortedData = sortDataLocally([...data.employmentRequests, ...mobilityRequests], APIQuery);

    // Need to gather all the page counts and set the totalPages to the highest
    const totalPages = getTotalPageCount([data.totalPages, mobilityTotalPages], {
      isEmployer,
      isAdmin,
      selectedTab,
    });

    return {
      ...data,
      totalCount: totalRequests,
      ...totalPages,
      data: sortedData,
    };
  };

  const tableProps = useTableFetcherWithQS(
    [EMPLOYMENT_REQUESTS_QUERY, params],
    (APIQuery) => {
      let apiQueryForMobility = APIQuery;

      // check if we're using `request_type` in the filter
      // and take it out from the queryParams used in the
      // travel-letter and remote-working requests
      if (APIQuery.request_type) {
        const { request_type: requestType, ...rest } = APIQuery;
        apiQueryForMobility = rest;
      }

      setApiQueryParams(apiQueryForMobility);
      return fetchFunction(params, APIQuery);
    },
    { ...params, ...options }
  );

  return { totals, totalRequests, tableProps };
}

/**
 * Fetch pending employment requests for the current employer
 *
 * @export
 * @returns {Object}
 */
export function useEmployerPendingEmploymentRequests({ queryParams = {}, options = {} } = {}) {
  const { data, ...rest } = useGet('/api/v1/employer/employment-requests', {
    params: {
      queryParams: {
        status: [requestStatus.SUBMITTED, requestStatus.IN_REVIEW],
        ...queryParams,
      },
    },
    options,
  });

  return { data: data?.data.employmentRequests, ...rest };
}

/**
 * Fetch pending employment requests for the current employee
 *
 * @export
 * @returns {Object}
 */
export function useEmployeePendingEmploymentRequests({ queryParams = {}, options = {} } = {}) {
  const { data, ...rest } = useGet('/api/v1/employee/employment-requests', {
    params: {
      queryParams: {
        status: [requestStatus.SUBMITTED, requestStatus.IN_REVIEW],
        ...queryParams,
      },
    },
    options,
  });

  return { data: data?.data.employmentRequests, ...rest };
}

/**
 * Fetch an employer request data
 *
 * @export
 * @returns {Object}
 */
export function useEmployerFetchRequestData({ requestSlug, queryParams = {}, options = {} } = {}) {
  return useGet('/api/v1/employer/employment-requests/[employmentRequestSlug]', {
    params: { pathParams: { employmentRequestSlug: requestSlug }, queryParams },
    options: { select: (res) => res.data, ...options },
  });
}

/**
 * Fetch an employee request data
 *
 * @export
 * @returns {Object}
 */
export function useEmployeeFetchRequestData({ requestSlug, queryParams = {}, options = {} } = {}) {
  return useGet('/api/v1/employee/employment-requests/[employmentRequestSlug]', {
    params: { pathParams: { employmentRequestSlug: requestSlug }, queryParams },
    options: { select: (res) => res.data, ...options },
  });
}

/**
 * Check if bulk amendments are enabled for the current employer
 *
 * @export
 * @returns {Boolean}
 */
export function useBulkAmendmentsEnabled() {
  const { userCan } = useUserCan();
  const { user } = useUserContext();
  const companyData = getFromUserCache(user, userCacheKeys.COMPANY_DATA);

  return (
    useIsFeatureFlagEnabled('customer_bulk_amendments') &&
    isEmployerFn(user) &&
    !!companyData?.hasEorEmployees &&
    userCan('create', Resources.employer.people_contracts, user)
  );
}
