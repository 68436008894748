export const httpRegex = new RegExp('^(http|https)://');

export const SOURCE_TYPE = {
  MANUAL: 'manual',
  SCRAPE: 'scrape',
} as const;

export const STATUS = {
  CREATED: 'created',
  ACTIVE: 'active',
  DELETED: 'deleted',
} as const;
