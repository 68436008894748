import { isPast, isToday, parseISO } from 'date-fns';

import { queryClient } from '@/src/config/queryClient';
import { employmentStatus } from '@/src/domains/employment/constants';
import { EMPLOYMENT_REQUESTS_QUERY } from '@/src/domains/employment/requests/hooks';
import { requestTypePerUser } from '@/src/domains/employment/requestsHub/shared/constants';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import {
  isContractor,
  isDirectEmployee,
  isEmployee,
  isEmployer,
  isInternalEmployee,
  isInternalEmployer,
  isUserGlobalPayroll,
  userCan,
} from '@/src/domains/registration/auth/helpers';
import { userCacheKeys } from '@/src/domains/userCache/constants';
import { getFromUserCache } from '@/src/domains/userCache/helpers';

export function invalidateEmploymentRequestsQueries() {
  queryClient.invalidateQueries(EMPLOYMENT_REQUESTS_QUERY);
}
/**
 * Determines if an employer has access to the Requests page
 * Only employers with at least one EOR employee can view and start requests for their (EOR) employees
 *
 * @export
 * @param {Object} user
 * @param {Boolean} allowInternal
 * @return {Boolean}
 */
export function shouldShowRequestsToEmployer(user, allowInternal = true) {
  const companyData = getFromUserCache(user, userCacheKeys.COMPANY_DATA);

  const employerCanViewRequests =
    isEmployer(user) &&
    !!companyData?.hasEorEmployees &&
    userCan('read', Resources.employer.employment_requests, user);

  return allowInternal
    ? employerCanViewRequests
    : employerCanViewRequests && !isInternalEmployer(user);
}

/**
 * Determines if an employee has access to the Requests page
 * as well as direct employees.
 *
 * @export
 * @param {Object} user
 * @param {Boolean} allowInternal
 * @return {Boolean}
 */
export function shouldShowRequestsToEmployee(user, allowInternal = true) {
  const employeeCanViewRequests =
    isEmployee(user) && !isUserGlobalPayroll(user) && !isDirectEmployee(user);

  return allowInternal
    ? employeeCanViewRequests
    : employeeCanViewRequests && !isInternalEmployee(user);
}

/**
 * Determines if an internal employee can create requests
 *
 * @export
 * @param {Object} user
 * @return {Boolean}
 */
export function canInternalEmployeeCreateRequests(user) {
  return isInternalEmployee(user) && userCan('create', Resources.employment_requests, user);
}

/**
 * Determines if an employee can request an employment verification letter
 *
 * @export
 * @param {Object} employment
 * @return {Boolean}
 */
export function canRequestVerificationLetter(employment) {
  const { contract, status } = employment;

  const isEmploymentActive = status === employmentStatus.ACTIVE;
  const startDate = parseISO(contract?.startDate);
  const hasPastStartDate = startDate && (isPast(startDate) || isToday(startDate));

  return isEmploymentActive && hasPastStartDate;
}

/**
 * Determines if an employee can submit their resignation
 *
 * @export
 * @param {Object} employment
 * @return {Boolean}
 */
export function canRequestResignation(user, employment) {
  const isEmploymentActive = employment.status === employmentStatus.ACTIVE;

  const userIsFullTimeEmployee = isEmployee(user);
  const userIsInternalEmployee = isInternalEmployee(user);

  return isEmploymentActive && userIsFullTimeEmployee && !userIsInternalEmployee;
}

/**
 * Determines if a request was created via an automated process
 * Automated requests don't link to external resources (Zendesk) and have
 * an automation type
 *
 * @export
 * @param {Object} request
 * @return {Boolean}
 */
export function isAutomationRequest(request) {
  return !request.externalId && Boolean(request.automationType);
}

/**
 * Determines if a user has access to the Time tracking page.
 * Only contractor or employee has access
 *
 * @export
 * @param {Object} user
 * @return {Boolean}
 */
export function shouldShowTimeTrackingToUser(user) {
  return isEmployee(user) || isContractor(user);
}

/**
 * Determines if a specific type of request can be shown to the user
 * @param {string} requestType
 * @param {string} userRole
 * @returns {Boolean}
 */
export function canStartRequestOfType(requestType, userRole) {
  return !!requestTypePerUser[userRole] && requestTypePerUser[userRole].includes(requestType);
}
