import { DOCUMENT_TYPES } from '@/src/domains/documents/constants';
import { generateSelectOptionsFromValues } from '@/src/helpers/forms';

export const EXPIRING_DOCUMENT_STATUSES = {
  PENDING: 'pending',
  RENEWED: 'renewed',
  PENDING_RENEWAL: 'pending_renewal',
  ASSISTANCE_REQUIRED: 'assistance_required',
  NOT_RENEWABLE: 'not_renewable',
  NOT_RENEWING: 'not_renewing',
  CHANGING_PERMIT_TYPE: 'changing_permit_type',
} as const;

export const EXPIRING_DOCUMENT_OPTIONS = {
  ALREADY_RENEWED: 'already_renewed',
  RENEWAL_IN_PROGRESS: 'renewal_in_progress',
  CHANGED_COUNTRY: 'changed_country',
  LEAVING_REMOTE: 'leaving_remote',
  CANNOT_START_YET: 'cannot_start_yet',
  SOMETHING_ELSE: 'something_else',
  CHANGING_PERMIT_TYPE: 'changing_permit_type',
};

export const EXPIRING_DOCUMENT_OPTION_TO_STATUS_MAP = {
  [EXPIRING_DOCUMENT_OPTIONS.ALREADY_RENEWED]: EXPIRING_DOCUMENT_STATUSES.RENEWED,
  [EXPIRING_DOCUMENT_OPTIONS.RENEWAL_IN_PROGRESS]: EXPIRING_DOCUMENT_STATUSES.PENDING_RENEWAL,
  [EXPIRING_DOCUMENT_OPTIONS.CHANGED_COUNTRY]: EXPIRING_DOCUMENT_STATUSES.NOT_RENEWABLE,
  [EXPIRING_DOCUMENT_OPTIONS.LEAVING_REMOTE]: EXPIRING_DOCUMENT_STATUSES.NOT_RENEWING,
  [EXPIRING_DOCUMENT_OPTIONS.CANNOT_START_YET]: EXPIRING_DOCUMENT_STATUSES.PENDING,
  [EXPIRING_DOCUMENT_OPTIONS.SOMETHING_ELSE]: EXPIRING_DOCUMENT_STATUSES.PENDING,
  [EXPIRING_DOCUMENT_OPTIONS.CHANGING_PERMIT_TYPE]: EXPIRING_DOCUMENT_STATUSES.CHANGING_PERMIT_TYPE,
};

export const EXPIRING_DOCUMENT_STATUS_LABELS = {
  [EXPIRING_DOCUMENT_STATUSES.PENDING]: 'Pending',
  [EXPIRING_DOCUMENT_STATUSES.RENEWED]: 'Renewed',
  [EXPIRING_DOCUMENT_STATUSES.PENDING_RENEWAL]: 'Pending renewal',
  [EXPIRING_DOCUMENT_STATUSES.ASSISTANCE_REQUIRED]: 'Assistance required',
  [EXPIRING_DOCUMENT_STATUSES.NOT_RENEWABLE]: 'Not renewable',
  [EXPIRING_DOCUMENT_STATUSES.NOT_RENEWING]: 'Not renewing',
  [EXPIRING_DOCUMENT_STATUSES.CHANGING_PERMIT_TYPE]: 'Changing Permit Type',
};

export const expiringDocumentStatusOptions = generateSelectOptionsFromValues(
  Object.values(EXPIRING_DOCUMENT_STATUSES),
  EXPIRING_DOCUMENT_STATUS_LABELS
);

export const EXPIRING_DOCUMENT_STATUS_PILL_TONE = {
  [EXPIRING_DOCUMENT_STATUSES.PENDING]: 'neutralDark',
  [EXPIRING_DOCUMENT_STATUSES.RENEWED]: 'success',
  [EXPIRING_DOCUMENT_STATUSES.PENDING_RENEWAL]: 'warning',
  [EXPIRING_DOCUMENT_STATUSES.ASSISTANCE_REQUIRED]: 'warning',
  [EXPIRING_DOCUMENT_STATUSES.NOT_RENEWABLE]: 'error',
  [EXPIRING_DOCUMENT_STATUSES.NOT_RENEWING]: 'warning',
};

export const EXPIRING_DOCUMENT_STATUS_BADGE_TYPE = {
  [EXPIRING_DOCUMENT_STATUSES.PENDING]: 'pending',
  [EXPIRING_DOCUMENT_STATUSES.RENEWED]: 'active',
  [EXPIRING_DOCUMENT_STATUSES.PENDING_RENEWAL]: 'attention',
  [EXPIRING_DOCUMENT_STATUSES.ASSISTANCE_REQUIRED]: 'attention',
  [EXPIRING_DOCUMENT_STATUSES.NOT_RENEWABLE]: 'error',
  [EXPIRING_DOCUMENT_STATUSES.NOT_RENEWING]: 'attention',
};

export const EXPIRING_DOCUMENT_TYPES = {
  PASSPORT: 'passport',
  WORK_PERMIT: 'work_permit',
} as const;

export const EXPIRING_DOCUMENT_ADMIN_TYPE_LABELS = {
  [EXPIRING_DOCUMENT_TYPES.PASSPORT]: 'Passport',
  [EXPIRING_DOCUMENT_TYPES.WORK_PERMIT]: 'Work permit',
};

export const EXPIRING_DOCUMENT_TYPE_LABELS = {
  [EXPIRING_DOCUMENT_TYPES.PASSPORT]: 'Passport',
  [EXPIRING_DOCUMENT_TYPES.WORK_PERMIT]: 'Visa, work permission or residence document',
};

export const expiringDocumentTypeOptions = generateSelectOptionsFromValues(
  Object.values(EXPIRING_DOCUMENT_TYPES),
  EXPIRING_DOCUMENT_TYPE_LABELS
);

export const EXPIRING_DOCUMENT_FILE_TYPE_BY_DOCUMENT_TYPE = {
  [EXPIRING_DOCUMENT_TYPES.PASSPORT]: DOCUMENT_TYPES.MOBILITY,
  [EXPIRING_DOCUMENT_TYPES.WORK_PERMIT]: DOCUMENT_TYPES.MOBILITY,
};

export const EXPIRING_DOCUMENT_ESCALATION_REASON = {
  UNRESPONSIVE: 'unresponsive',
  EXPIRED: 'expired',
  MANUAL: 'manual',
  LATE_RENEWAL: 'late_renewal',
  RENEWAL_AT_RISK: 'renewal_at_risk',
  NOT_RENEWABLE: 'not_renewable',
  INTERNAL_TODO: 'internal_todo',
  CHANGING_PERMIT_TYPE: 'changing_permit_type',
  /**
   * Escalation reason for cases that have already been started with the
   * previous approach of sending document requests to the employee.
   * They are excluded from the default dashboard but can be used explicitly
   * in filters to go through old cases and get them into the new process.
   */
  LEGACY: 'legacy',
};

export const EXPIRING_DOCUMENT_ESCALATION_REASON_LABELS = {
  [EXPIRING_DOCUMENT_ESCALATION_REASON.UNRESPONSIVE]: 'Unresponsive',
  [EXPIRING_DOCUMENT_ESCALATION_REASON.EXPIRED]: 'Expired',
  [EXPIRING_DOCUMENT_ESCALATION_REASON.MANUAL]: 'Manual',
  [EXPIRING_DOCUMENT_ESCALATION_REASON.LATE_RENEWAL]: 'Late renewal',
  [EXPIRING_DOCUMENT_ESCALATION_REASON.RENEWAL_AT_RISK]: 'Renewal at risk',
  [EXPIRING_DOCUMENT_ESCALATION_REASON.NOT_RENEWABLE]: 'Not renewable',
  [EXPIRING_DOCUMENT_ESCALATION_REASON.INTERNAL_TODO]: 'Internal ToDo',
  [EXPIRING_DOCUMENT_ESCALATION_REASON.LEGACY]: 'Legacy',
};

export const expiringDocumentEscalationReasonOptions = generateSelectOptionsFromValues(
  Object.values(EXPIRING_DOCUMENT_ESCALATION_REASON),
  EXPIRING_DOCUMENT_ESCALATION_REASON_LABELS
);

export const EXPIRING_DOCUMENTS_EXTERNAL_TYPES = {
  ZENDESK: 'zendesk',
};

export const EXPIRING_DOCUMENTS_EXTERNAL_TYPE_LABELS = {
  [EXPIRING_DOCUMENTS_EXTERNAL_TYPES.ZENDESK]: 'Zendesk',
};

export const EXPIRING_DOCUMENT_REMINDER_TYPES = {
  OWNER: 'owner',
  INTERNAL: 'internal',
};

export const EXPIRING_DOCUMENT_REMINDER_TYPE_LABELS = {
  [EXPIRING_DOCUMENT_REMINDER_TYPES.OWNER]: 'Owner',
  [EXPIRING_DOCUMENT_REMINDER_TYPES.INTERNAL]: 'Internal',
};

export const expiringDocumentReminderTypeOptions = generateSelectOptionsFromValues(
  Object.values(EXPIRING_DOCUMENT_REMINDER_TYPES),
  EXPIRING_DOCUMENT_REMINDER_TYPE_LABELS
);
