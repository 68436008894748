import styled, { css } from 'styled-components';

import { sharedTransition } from '../../../core/animations';

import { DatePickerCalendarBasicContainer } from './BasicContainer.styled';

export const DatePickerRegularContainer = styled(DatePickerCalendarBasicContainer)`
  .react-datepicker__day-names,
  .react-datepicker__week {
    gap: var(--date-picker-column-gap);
  }

  ${({ theme }) => css`
    .react-datepicker__day {
      ${sharedTransition('background-color')}

      /* Background for "today", keep default selected styling */
      &.react-datepicker__day--today:not(.react-datepicker__day--selected):not(
          .react-datepicker__day--in-selecting-range
        ):not(.react-datepicker__day--in-range):not(:hover) {
        color: ${theme.colors.brand[600]};
        border: 1px solid ${theme.colors.brand[600]};
      }
      &.react-datepicker__day--highlighted:not(.react-datepicker__day--selected) {
        background-color: ${theme.colors.yellow[200]};
      }
    }
  `}
`;
