import { Box } from '@remote-com/norma';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import styled from 'styled-components';

import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import { useUserCan } from '@/src/components/UserCan';
import { getFlagSrc } from '@/src/domains/countries/helpers';
import { useAdminPayrollRun } from '@/src/domains/payroll/admin/hooks';
import { PAYROLL_RUN_STATUSES } from '@/src/domains/payroll/constants';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import usePrevious from '@/src/hooks/usePrevious';

const PayrollRunFlag = styled.img`
  width: ${({ theme }) => theme.space[6]}px;
`;

const PayrollRunName = styled(Box).attrs({
  display: 'block',
  maxWidth: '220px',
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.typography.smMedium};
  margin: 0 ${({ theme }) => theme.space[3]}px;
`;

const getPayrollRunOptionLabel = (option) => {
  if (!option.slug) return <PayrollRunName>{option.label}</PayrollRunName>;

  const country = option?.legalEntity?.address?.country;

  return (
    <Box display="flex" alignItems="flex-start">
      <PayrollRunFlag src={getFlagSrc(country.name)} alt={`Flag of ${country.name}`} />
      <PayrollRunName>{option.name}</PayrollRunName>
    </Box>
  );
};

const getPayrollRunOptionValue = (option) => option.slug || option.value;

/**
 *
 * @param {import('@/types').WildcardProps} props
 * @returns {JSX.Element}
 */
export default function PayrollRunSelect({
  filters = {},
  description,
  initialOptions,
  employmentSlug,
  ...props
}) {
  const { userCan: userCanFn } = useUserCan();
  const [{ value }] = useField(props.name);
  const initialSlug = useRef(value).current;
  const { isLoading, data: selectedPayrollRun } = useAdminPayrollRun(initialSlug, {
    enabled: !!initialSlug && initialSlug !== 'none',
  });
  const previousEmploymentSlug = usePrevious(employmentSlug);
  const hasEmployeeChanged = previousEmploymentSlug !== employmentSlug;
  const defaultOptions = selectedPayrollRun ? [selectedPayrollRun] : [];

  return (
    <PaginatedSearchSelectField
      label="Payroll run"
      query={{
        path: '/api/v1/rivendell/payroll-runs',
        params: {
          queryParams: {
            ...filters,
            sortBy: 'expected_payout_date',
            order: 'desc',
            eligibleForEmploymentSlug: employmentSlug,
          },
        },
        options: {
          select: (res) => getDataWithRenamedProperty(res, 'payrollRuns'),
          enabled: userCanFn('read', Resources.payroll_runs),
        },
      }}
      searchQueryAlias="name"
      loadingMessage={() => 'Loading payroll runs…'}
      getOptionLabel={getPayrollRunOptionLabel}
      getOptionValue={getPayrollRunOptionValue}
      description={`${description || ''}${
        employmentSlug
          ? ' Select a payroll run from the Remote entity with which this employee has a contract'
          : ''
      }`}
      initialOptions={initialOptions}
      transformValue={(option) => option.slug}
      isControlled
      {...props}
      defaultOptions={defaultOptions}
      isLoadingDefaultOptions={isLoading}
      disableReactSelectSearch
      // When employee is changed we get a new list of Payroll Runs
      // This allows us to to NOT have the default behaviour of adding the previously selected option to the new options list
      clearPinnedOptions={hasEmployeeChanged}
    />
  );
}

PayrollRunSelect.propTypes = {
  filters: PropTypes.object,
  description: PropTypes.string,
  initialOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

PayrollRunSelect.defaultProps = {
  filters: {
    status: [PAYROLL_RUN_STATUSES.PREPARING.id],
  },
  initialOptions: [],
};
