import { Box } from '@remote-com/norma';
import { IconV2DuotoneUsers } from '@remote-com/norma/icons/IconV2DuotoneUsers';
import truncate from 'lodash/truncate';
import { forwardRef } from 'react';

import { CardStatusMessage } from '@/src/components/Cards';

const messages = {
  searchQueryDescription: (searchQuery: string) => `No profiles found for "${searchQuery}".`,
  title: 'No results',
} as const;

type PaginatedProfilesMenuEmptyProps = {
  searchQuery?: string;
  title?: string;
};

export const PaginatedProfilesMenuEmpty = forwardRef<
  HTMLDivElement,
  PaginatedProfilesMenuEmptyProps
>(({ searchQuery, title = messages.title, ...props }, ref) => {
  const description = searchQuery
    ? messages.searchQueryDescription(truncate(searchQuery, { length: 8 }))
    : undefined;

  return (
    <Box p={6} ref={ref} {...props}>
      <CardStatusMessage icon={IconV2DuotoneUsers} description={description} title={title} />
    </Box>
  );
});
