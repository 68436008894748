// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneSupport = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} fillRule="evenodd" clipRule="evenodd" d="M14.83 9.17 19.6 4.4a10.72 10.72 0 0 1 0 15.2l-4.77-4.77a3.99 3.99 0 0 0 0-5.66Zm-5.66 5.66a3.99 3.99 0 0 1 0-5.66L4.4 4.4a10.72 10.72 0 0 0 0 15.2l4.77-4.77Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 1.25c-2.97 0-5.66 1.2-7.6 3.15l4.77 4.77a3.99 3.99 0 0 1 5.66 0L19.6 4.4A10.72 10.72 0 0 0 12 1.25ZM9.17 14.83 4.4 19.6a10.72 10.72 0 0 0 15.2 0l-4.77-4.77a3.99 3.99 0 0 1-5.66 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneSupport);
export { ForwardRef as IconV2DuotoneSupport };