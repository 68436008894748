import { STATUS } from '@remote-com/talent/domains/company/constants';
import getConfig from 'next/config';
import type { ValueOf } from 'type-fest';

const {
  publicRuntimeConfig: { TALENT_STRIPE_NL_BILLING_PORTAL, TALENT_STRIPE_US_BILLING_PORTAL },
} = getConfig();

export const COMPANY_PROFILE_STATUS = STATUS;

export const STRIPE_COUNTRIES = {
  US: 'US',
  NL: 'NL',
} as const;

export const STRIPE_PORTAL_URL = {
  [STRIPE_COUNTRIES.US]: TALENT_STRIPE_US_BILLING_PORTAL,
  [STRIPE_COUNTRIES.NL]: TALENT_STRIPE_NL_BILLING_PORTAL,
} as Record<ValueOf<typeof STRIPE_COUNTRIES>, string>;
