import { focusVisible, Box } from '@remote-com/norma';
import { IconChevronDown } from '@remote-com/norma/icons/IconChevronDown';
import { IconChevronUp } from '@remote-com/norma/icons/IconChevronUp';
import styled, { css } from 'styled-components';

import { Card } from '@/src/components/Ui/Card';

export const CardStyled = styled(Card)`
  position: relative;
  --spacing: 0;
  & + & {
    /* Let the implementation decide what spacing should be used */
    margin-top: 0;
  }
`;

export const IconExpand = styled(IconChevronDown)`
  fill: var(--colors-irisBlue);
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

export const IconCollapse = styled(IconChevronUp)`
  fill: var(--colors-irisBlue);
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

export const Summary = styled.summary`
  display: flex;
  list-style: none;
  align-items: center;
  cursor: pointer;
  list-style: none;
  outline: 0;
  border: 2px solid transparent;
  border-radius: 10px;
  padding: ${({ theme }) => theme.space[6]}px;

  ::marker,
  ::-webkit-details-marker {
    display: none;
  }

  ${focusVisible(css`
    border: 2px solid var(--colors-irisBlue);
  `)}

  ${IconExpand} {
    display: inline-block;
  }

  ${IconCollapse} {
    display: none;
  }

  details[open] > & {
    ${IconExpand} {
      display: none;
    }

    ${IconCollapse} {
      display: inline-block;
    }
  }
`;

export const SummaryContent = styled.span``;

export const Details = styled.details`
  flex: 1;
`;

export const DetailsContent = styled(Box)`
  margin-left: ${({ theme }) => theme.space[8] + theme.space[4]}px;
`;

export const Actions = styled(Box)`
  position: absolute;
  top: ${({ theme }) => theme.space[3] + theme.space[4]}px;
  right: ${({ theme }) => theme.space[3] + theme.space[4]}px;
  display: flex;
`;

export const ActionItem = styled(Box)``;
