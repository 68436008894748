import { differenceInDays, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function isPayrollBeforeOrAtCutoff({ cutoffDate }: { cutoffDate: string }): boolean {
  // Convert current date to UTC and parse cutoff date to date object
  const currentDateUTC = utcToZonedTime(new Date(), 'UTC');
  const cutoffDateParsed = parseISO(cutoffDate);
  const dayDifference = differenceInDays(currentDateUTC, cutoffDateParsed);

  // Check if current date is at or before cutoff date
  return dayDifference <= 0;
}
