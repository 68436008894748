import { MINUTES_IN_HOUR } from './constants';

/**
 * Simplify a time amount. Simplify means for minute quantities
 * higher than 59 are consolidated into hours.
 * @param {{hours?: number, minutes?: number}} timeAmount
 * @returns {{hours: number, minutes: number}} Simplified time amount
 */

export function simplifyTimeAmounts(timeAmount) {
  let hours = timeAmount.hours ?? 0;
  let minutes = timeAmount.minutes ?? 0;

  // Roll minutes into full hours
  const minutesRolledIntoFullHours = Math.trunc(minutes / MINUTES_IN_HOUR);
  minutes %= MINUTES_IN_HOUR;
  hours += minutesRolledIntoFullHours;

  return {
    hours,
    minutes,
  };
}
