import {
  buildReportDownloadOnRowClick,
  buildReportDownloadOnRowMenuClick,
  trackEvent,
} from '@remote-com/analytics';
import type { GetResponse } from '@remote-com/data-layer';
import { Stack } from '@remote-com/norma';
import { IconDownload } from '@remote-com/norma/icons/IconDownload';
import { IconEye } from '@remote-com/norma/icons/IconEye';
import { PersonDocumentPileIllustration } from '@remote-com/norma/illustrations/PersonDocumentPileIllustration';
import { useField } from 'formik';
import { useMemo, useState } from 'react';
import type { ValueOf } from 'type-fest';

import type { Country } from '@/src/api/config/employ/shared.types';
import { EmptyState } from '@/src/components/EmptyState';
import Table, {
  BasicSelectFilter,
  DateCell,
  DateRangeFilter,
  EmployerCountrySelectFilter,
  EmployerLegalEntityFilter,
  RowAction,
  RowActionsMenu,
} from '@/src/components/Table';
import { ActionsColumn } from '@/src/components/Table/Columns';
import { dateRangeFilterLabel } from '@/src/components/Table/helpers';
import {
  useSelectedCountryNameFromTable,
  useSelectedLegalEntityNameFromTableAsEmployer,
} from '@/src/components/Table/hooks/useSelectedNameFromTable';
import { PAYROLL_RUN_DETAILS_ROUTE } from '@/src/constants/routes';
import { getFlagSrc } from '@/src/domains/countries/helpers';
import { CountryFlag } from '@/src/domains/countries/styles';
import { formatDateRange } from '@/src/helpers/date';

import { productTypes } from '../../constants';
import { remoteEntityName } from '../constants';
import { useDownloadGrossToNetReport } from '../hooks';

import type { employmentCompanyType } from './constants';
import {
  REPORT_TYPE_LABELS,
  columnAccessorExclusionSet,
  employmentCompanyTypeLabels,
  employmentCompanyTypeOptions,
} from './constants';
import { usePayrollReports } from './hooks';

type PayrollReportRow =
  GetResponse<'/api/v1/employer/payroll-reports'>['data']['payrollReports'][number];

const EmployerPayrollReports = () => {
  const tableProps = usePayrollReports();

  const { mutate } = useDownloadGrossToNetReport();

  // Store selected legal entity whole object to be able to compare its country with the selected country filter
  const [selectedLegalEntityObject, setSelectedLegalEntityObject] = useState<
    PayrollReportRow['legalEntity'] | null
  >(null);

  const columns = useMemo(
    () => [
      {
        Header: 'Report name',
        id: 'name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'Country',
        id: 'countrySlug',
        accessor: 'countrySlug',
        Filter: (props: { column: Object }) => {
          const [, , { setValue: setLegalEntitySlugValue }] = useField('legalEntitySlug');

          return (
            <EmployerCountrySelectFilter
              {...props}
              valueField="slug"
              isClearable
              onChange={(country: Country | null) => {
                // If the country being selected is not the same as the one from the selected legal entity, clear the legal entity filter
                if (
                  country !== null &&
                  selectedLegalEntityObject?.address?.country?.slug !== country?.slug
                ) {
                  setLegalEntitySlugValue(null);
                }
              }}
            />
          );
        },
        hiddenColumn: true,
        filterSortIndex: 1,
      },
      {
        Header: 'Entity',
        id: 'legalEntitySlug',
        accessor: ({ legalEntity, productType }: PayrollReportRow) => {
          const isGlobalPayroll = productType === productTypes.GLOBAL_PAYROLL;
          if (isGlobalPayroll) {
            return legalEntity?.name;
          }
          return remoteEntityName;
        },
        Cell: ({
          value,
          row: { original },
        }: {
          value: string;
          row: { original: PayrollReportRow };
        }) => {
          const countryName = original?.legalEntity?.address?.country?.name;
          return (
            <Stack direction="row" gap={2}>
              <CountryFlag src={getFlagSrc(countryName) ?? undefined} alt={`${countryName} flag`} />
              {value}
            </Stack>
          );
        },
        disableSortBy: true,
        Filter: (props: { column: Object }) => {
          const [{ value: isInternalValue }, , { setValue: setIsInternalValue }] =
            useField('isInternal');

          const [{ value: countrySlugValue }] = useField('countrySlug');

          return (
            <EmployerLegalEntityFilter
              {...props}
              filters={{ countrySlug: countrySlugValue }}
              onChange={(legalEntity: PayrollReportRow['legalEntity'] | null) => {
                setSelectedLegalEntityObject(legalEntity);

                // If isInternal is true when a legal entity filter is being selected, clear isInternal filter
                if (isInternalValue === 'true') {
                  setIsInternalValue(null);
                }
              }}
              countrySlug={countrySlugValue}
            />
          );
        },
      },
      {
        Header: 'Report type',
        id: 'type',
        accessor: ({ type }: PayrollReportRow) => REPORT_TYPE_LABELS[type],
        disableFilters: true,
      },
      {
        Header: 'Pay period',
        id: 'payPeriod',
        accessor: ({ periodStart, periodEnd }: PayrollReportRow) =>
          formatDateRange(periodStart, periodEnd, '-'),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Date created',
        id: 'createdAt',
        accessor: 'createdAt',
        Cell: DateCell,
        Filter: (props: { column: Object }) => (
          <DateRangeFilter {...props} data-testid="date-created-filter" />
        ),
      },
      {
        Header: 'Employees hired by',
        id: 'isInternal',
        accessor: 'isInternal',
        Filter: (props: { column: Object }) => {
          const [, , { setValue: setLegalEntitySlugValue }] = useField('legalEntitySlug');

          return (
            <BasicSelectFilter
              {...props}
              withSearchIcon={false}
              isClearable
              isControlled
              placeholder="Select one"
              options={employmentCompanyTypeOptions}
              onChange={(isInternal: { value: 'true' | 'false'; label: string } | null) => {
                // If legal entity filter is selected when isInternal is being selected as true, clear legal entity filter
                if (isInternal?.value === 'true') {
                  setLegalEntitySlugValue(null);
                }
              }}
            />
          );
        },
        hiddenColumn: true,
      },
      {
        ...ActionsColumn,
        Cell: ({ value }: { value: PayrollReportRow }) => (
          <RowActionsMenu>
            <RowAction
              Icon={IconDownload}
              onSelect={() => {
                const fileSlug = value.files[0]?.slug;
                mutate({ fileSlug } as any);
                trackEvent(buildReportDownloadOnRowMenuClick());
              }}
            >
              Download report
            </RowAction>
            {value.payrollRun && (
              <RowAction
                Icon={IconEye}
                href={{
                  pathname: PAYROLL_RUN_DETAILS_ROUTE,
                  query: {
                    payrollSlug: value.payrollRun?.slug,
                  },
                }}
              >
                View payroll run
              </RowAction>
            )}
          </RowActionsMenu>
        ),
      },
    ],
    [mutate, selectedLegalEntityObject?.address?.country?.slug]
  );

  const selectedCountryName = useSelectedCountryNameFromTable(tableProps, 'countrySlug');

  const selectedLegalEntityName = useSelectedLegalEntityNameFromTableAsEmployer(
    tableProps,
    'legalEntitySlug'
  );

  const getFilterLabel = (
    id: string,
    value: string | string[] | ValueOf<typeof employmentCompanyType>
  ) => {
    if (id === 'countrySlug') {
      return selectedCountryName;
    }
    if (id === 'legalEntitySlug') {
      return selectedLegalEntityName;
    }
    if (id === 'createdAt') {
      return dateRangeFilterLabel(value);
    }
    if (id === 'isInternal' && typeof value !== 'object') {
      return employmentCompanyTypeLabels[value];
    }

    return value;
  };

  if (tableProps.totalCount === 0 && !tableProps.isSearchOrFilterApplied) {
    return (
      <EmptyState
        brandShape="quarter"
        title="No reports yet"
        description="After a payroll run is completed, the payroll report for that run will be available here."
        illustration={PersonDocumentPileIllustration}
        data-testid="empty-state"
      />
    );
  }

  return (
    <Table
      name="payroll-reports"
      {...tableProps}
      columns={columns}
      columnAccessorExclusionSet={columnAccessorExclusionSet}
      filters
      getFilterLabel={getFilterLabel}
      data-testid="payroll-reports-table"
      rowProps={{ 'data-testid': 'payroll-reports-table-row' }}
      onRowClick={({ original }) => {
        mutate({ fileSlug: original.files[0]?.slug } as any);
        trackEvent(buildReportDownloadOnRowClick());
      }}
    />
  );
};

export default EmployerPayrollReports;
