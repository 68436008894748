type PolymorphicSelectValue = null | undefined | string | number | Record<any, any> | Array<any>;

const isFilled = (value: PolymorphicSelectValue) => {
  if (typeof value === 'string') {
    return value !== '';
  }

  if (typeof value === 'undefined' || value === null) {
    return false;
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return true;
};

export const isValueFilled = (
  value: PolymorphicSelectValue,
  defaultValue?: PolymorphicSelectValue,
  hasUserInput?: boolean
) => {
  if (hasUserInput) {
    return true;
  }

  return isFilled(value) || isFilled(defaultValue);
};

export function defaultTransformCreatedOption(value: string) {
  return value.replace(/\s/g, '_').toLowerCase();
}
