import type { MouseEvent } from 'react';

import { StandaloneLink } from '@/src/components/Button';

type SelectAllRowsButtonProps = {
  disabled: boolean;
  label?: string;
  maxBulkAllowedRows: number;
  onClick: (event: MouseEvent) => void;
  totalCount: number;
};

export const SelectAllRowsButton = ({
  disabled,
  label,
  onClick,
  totalCount,
  maxBulkAllowedRows,
}: SelectAllRowsButtonProps) => {
  const isRowCountLessThanMaxAllowed = totalCount <= maxBulkAllowedRows;

  const getLabel = () => {
    if (label) {
      return label;
    }

    if (isRowCountLessThanMaxAllowed) {
      return `Select all ${totalCount} items`;
    }

    return `Select ${maxBulkAllowedRows} items`;
  };

  return (
    <StandaloneLink
      data-testid="table-toolbar-select-all-btn"
      disabled={disabled}
      onClick={onClick}
    >
      {getLabel()}
    </StandaloneLink>
  );
};
