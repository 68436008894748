import { Pill, Box } from '@remote-com/norma';
import styled from 'styled-components';

import { ButtonInline } from '@/src/components/Button';
import { TooltipHeader } from '@/src/components/Table/Headers';

export const InvoiceReportSection = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[9]}px;

  h3 {
    ${({ theme }) => theme.typography.xlMedium}
    margin: 0 0 ${({ theme }) => theme.space[6]}px;
  }
`;

export const MoneyTooltipHeader = styled(TooltipHeader)`
  justify-content: flex-end;
`;

export const TableSortButton = styled(ButtonInline)`
  text-transform: inherit;
  color: inherit;
`;

export const StyledPill = styled(Pill)`
  vertical-align: middle;
  margin-left: ${({ theme }) => theme.space[3]}px;
`;

export const StyledDiscountPill = styled(Pill)`
  vertical-align: middle;
  margin-right: ${({ theme }) => theme.space[3]}px;
  cursor: help;
`;
