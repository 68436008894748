import { Box, SROnly, Tooltip } from '@remote-com/norma';
import { useField } from 'formik';

import type { Currency } from '@/src/api/config/employ/shared.types';
import { supportedTypes } from '@/src/components/Form/DynamicForm/constants';
import { InputController } from '@/src/components/Ui/Form/Input';
import { PAY_ELEMENT_UNIT_TYPES } from '@/src/domains/payElements/constants';
import type { EmployeePayElement } from '@/src/domains/payElements/types';
import { EmployeePayElementField } from '@/src/domains/payroll/shared/payElements/EmployeePayElementField';

export default function ValueFieldCell({
  currency,
  row: { original, index },
  fieldName,
}: {
  row: { original: Pick<EmployeePayElement, 'legalEntityPayElement'>; index: number };
  fieldName: string;
  currency?: Currency;
}) {
  const { type } = original.legalEntityPayElement;

  const fieldId = `${fieldName}-${index}`;

  // This should match the value of the `aria-errormessage` attribute as defined in FormGroup's getFormGroupIds helper function
  const errorMessageId = `${fieldId}-errormessage`;

  const [field, { error, touched }] = useField(fieldName);

  const inputControllerType =
    type === PAY_ELEMENT_UNIT_TYPES.AMOUNT ? supportedTypes.CURRENCIES : supportedTypes.NUMBER;

  const shouldDisplayError = !!error && touched;

  return (
    <Tooltip
      // We don't want 'label' to ever be falsy. If it's falsy at some point, and then it's not, the tooltip
      // will cause a re-render that will make the input lose focus, which is bad UX.
      // To avoid 'label' from ever being falsy, we render a string with a space, and hide the tooltip via CSS
      // when there are no errors to display. It's a bit hacky, but works well.
      label={shouldDisplayError ? error : ' '}
      styles={!shouldDisplayError ? { wrapper: { display: 'none' } } : undefined}
      interactive={false}
      position="bottom"
      animation={false}
    >
      <Box minWidth="180px" width="100%" key={fieldId}>
        <SROnly id={errorMessageId} aria-live="assertive">
          {shouldDisplayError && error}
        </SROnly>
        <InputController type={inputControllerType}>
          <EmployeePayElementField
            payElementType={type}
            currency={currency?.code}
            name={field.name}
            label="Amount"
            id={fieldId} // if there are multiple rows, each input's label will have a different id
            autoFocus={false}
            displayErrorMessage={false}
            data-testid={fieldId}
          />
        </InputController>
      </Box>
    </Tooltip>
  );
}
