import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { Box, sharedTransition } from '@remote-com/norma';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

type ScrollbarThumbTone = 'dark' | 'default' | 'light';

const StyledScrollbar = styled(Box)`
  ${sharedTransition('background-color')}

  box-sizing: content-box;
  display: flex;
  padding: ${({ theme }) => theme.space[1]}px;
  touch-action: none;
  user-select: none;

  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: var(--norma-scroll-area-scrollbar-thumb-size);
  }

  &[data-orientation='vertical'] {
    width: var(--norma-scroll-area-scrollbar-thumb-size);
  }
`;

const getScrollbarThumbColor = (tone: ScrollbarThumbTone, theme: DefaultTheme) => {
  switch (tone) {
    case 'dark':
      return theme.colors.grey[900];
    case 'light':
      return theme.colors.grey[50];
    default:
      return theme.colors.grey[300];
  }
};

const StyledScrollbarThumb = styled(Box)<{ $tone: ScrollbarThumbTone }>`
  background-color: ${({ $tone, theme }) => getScrollbarThumbColor($tone, theme)};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  flex: 1;
  position: relative;
  z-index: 90;
`;

type ScrollbarToneProps = {
  /**
   * Adjusts the color of the scrollbar thumb.
   *
   * **Attention:** Use only in rare cases where background colors cause low contrast.
   */
  tone?: ScrollbarThumbTone;
};

interface ScrollbarProps
  extends ScrollbarToneProps,
    ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar> {}

/**
 * Renders custom scrollbars.
 */
const ScrollAreaScrollbar = forwardRef<
  ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  ScrollbarProps
>(({ orientation = 'vertical', tone = 'default', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar asChild ref={ref} orientation={orientation} {...props}>
    <StyledScrollbar>
      <ScrollAreaPrimitive.ScrollAreaThumb asChild>
        <StyledScrollbarThumb $tone={tone} />
      </ScrollAreaPrimitive.ScrollAreaThumb>
    </StyledScrollbar>
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));

ScrollAreaScrollbar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

const StyledScrollAreaRoot = styled(Box)`
  --norma-scroll-area-scrollbar-thumb-size: 6px;

  overflow: hidden;
`;

const ScrollAreaRoot = forwardRef<
  ElementRef<typeof ScrollAreaPrimitive.Root>,
  ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>
>(({ children, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.Root asChild ref={ref} {...props}>
      <StyledScrollAreaRoot>{children}</StyledScrollAreaRoot>
    </ScrollAreaPrimitive.Root>
  );
});

ScrollAreaRoot.displayName = ScrollAreaPrimitive.Root.displayName;

const StyledScrollAreaViewport = styled(Box)`
  border-radius: inherit;
  height: 100%;
  width: 100%;
`;

const ScrollAreaViewport = forwardRef<
  ElementRef<typeof ScrollAreaPrimitive.Viewport>,
  ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Viewport>
>(({ children, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.Viewport asChild ref={ref} {...props}>
      <StyledScrollAreaViewport>{children}</StyledScrollAreaViewport>
    </ScrollAreaPrimitive.Viewport>
  );
});

ScrollAreaViewport.displayName = ScrollAreaPrimitive.Viewport.displayName;

const ScrollAreaCorner = forwardRef<
  ElementRef<typeof ScrollAreaPrimitive.Corner>,
  ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Corner>
>(({ children, ...props }, ref) => {
  return <ScrollAreaPrimitive.Corner ref={ref} {...props} />;
});

ScrollAreaCorner.displayName = ScrollAreaPrimitive.Corner.displayName;

interface ScrollAreaProps
  extends ScrollbarToneProps,
    ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {}

/**
 * Renders a scroll area with custom scrollbars.
 */
const ScrollArea = forwardRef<ElementRef<typeof ScrollAreaPrimitive.Root>, ScrollAreaProps>(
  ({ children, tone, ...props }, ref) => (
    <ScrollAreaRoot asChild ref={ref} {...props}>
      <ScrollAreaViewport>{children}</ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical" tone={tone} />
      <ScrollAreaScrollbar orientation="horizontal" tone={tone} />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaRoot>
  )
);

ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

export { ScrollArea, ScrollAreaCorner, ScrollAreaRoot, ScrollAreaScrollbar, ScrollAreaViewport };
