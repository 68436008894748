// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconEyeGlasses = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M3.98 6.18c.22-1.05.6-1.52 1-1.77C5.41 4.13 6.04 4 7 4a1 1 0 0 0 0-2c-1.05 0-2.17.12-3.1.72-.98.62-1.6 1.65-1.88 3.08v.03L.28 15.72A5.5 5.5 0 1 0 10.8 19h2.42a5.5 5.5 0 1 0 10.5-3.28l-1.72-9.9V5.8c-.3-1.43-.91-2.46-1.9-3.08A5.68 5.68 0 0 0 17 2a1 1 0 1 0 0 2c.95 0 1.58.13 2.03.4.39.26.77.73.99 1.78l1.13 6.5A5.5 5.5 0 0 0 13.03 17h-2.05a5.5 5.5 0 0 0-8.13-4.32l1.13-6.5ZM2 17.58l.23-1.34A3.5 3.5 0 1 1 2 17.58Zm19.77-1.34a3.5 3.5 0 1 0 .23 1.34l-.23-1.34Z" /></svg>;
const ForwardRef = forwardRef(IconEyeGlasses);
export { ForwardRef as IconEyeGlasses };