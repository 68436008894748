import { useField } from 'formik';
import { useRef } from 'react';

import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import { useAdminCompanyDetails } from '@/src/domains/compliance/queries';
import { getDataWithRenamedProperty } from '@/src/helpers/api';

function getCompanySlug(company) {
  return company?.slug;
}

export function CompanySelectField(selectFieldProps) {
  const [{ value }] = useField(selectFieldProps.name);

  const initialSlug = useRef(value).current;

  const { isLoading, data: selectedCompany } = useAdminCompanyDetails(initialSlug, {
    enabled: !!initialSlug,
  });

  const defaultOptions = selectedCompany ? [selectedCompany] : [];

  return (
    <PaginatedSearchSelectField
      query={{
        path: '/api/v1/rivendell/companies',
        options: {
          select: (res) => getDataWithRenamedProperty(res, 'companies'),
        },
      }}
      data-testid="company-select-field"
      loadingMessage={() => 'Loading companies…'}
      getOptionLabel={(company) => company.name}
      getOptionValue={getCompanySlug}
      transformValue={getCompanySlug}
      defaultOptions={defaultOptions}
      isLoadingDefaultOptions={isLoading}
      isControlled
      isClearable
      {...selectFieldProps}
    />
  );
}
