import { Text } from '@remote-com/norma';
import type { ReactElement } from 'react';

import { ButtonIcon } from '../../../core/button';
import { convertFileBytesToMb as convertBytesToMb } from '../../../foundations';
import { IconV2OutlineAlertTriangle } from '../../../icons/build/IconV2OutlineAlertTriangle';
import { IconV2OutlinePaperclip } from '../../../icons/build/IconV2OutlinePaperclip';
import { IconV2OutlineTimes } from '../../../icons/build/IconV2OutlineTimes';
import type {
  InputFileObject,
  InputFileModifiable,
  InputFileUploadConfig,
  InputFileHandlers,
} from '../helpers';
import { InputFileStyled } from '../styled';

import { InputFileListEntryFields } from './entry-fields';

const {
  ActionableArea,
  FileError,
  FileListEntry: ListEntry,
  FileListEntryIcon,
  FileName,
  FileSizeWrapper,
} = InputFileStyled;

const friendlyFileSize = (bytes: number) => {
  if (!bytes) {
    return '0kB';
  }
  const sizeInMb = convertBytesToMb(bytes);
  if (sizeInMb < 1) {
    return `${(sizeInMb * 1024).toFixed(2)}KB`;
  }
  return `${sizeInMb.toFixed(2)}MB`;
};

type Props = InputFileHandlers & {
  name: string;
  fileObject: InputFileObject;
  fileSize?: number;
  documentType?: string;
  uploadConfig: InputFileUploadConfig;
  modifiable?: InputFileModifiable;
};

export const InputFileListEntry = (props: Props): ReactElement => {
  const {
    fileObject,
    fileSize,
    name,
    documentType,
    uploadConfig,
    onDeleteClick,
    onFileClick,
    onRename,
    onChangeDocumentType,
    modifiable,
  } = props;

  const { isDeleting, isUploading } = fileObject;
  const actionsDisabled = isUploading || isDeleting;
  const hasErrors = Boolean(fileObject.errors?.length);
  const isInvalidFileType = fileObject.errors?.some((e) => e.code === 'file-invalid-type');
  const isFileTooLarge = fileObject.errors?.some((e) => e.code === 'file-too-large');

  const isStatusDone = !isUploading && !isDeleting && !hasErrors;

  return (
    <ListEntry
      isLoading={isUploading}
      $hasErrors={hasErrors}
      data-testid={`file-entry-${name}${isStatusDone ? '-loaded' : ''}`}
    >
      <ActionableArea $hasErrors={hasErrors}>
        {!modifiable && (
          <FileListEntryIcon color={hasErrors ? 'negative.foreground' : 'grey.900'}>
            {hasErrors ? (
              <IconV2OutlineAlertTriangle width="16px" />
            ) : (
              <IconV2OutlinePaperclip width="16px" />
            )}
          </FileListEntryIcon>
        )}
        <FileName>
          <Text onClick={() => onFileClick(fileObject)} color={hasErrors ? 'grey.600' : 'grey.900'}>
            {modifiable ? (
              <InputFileListEntryFields
                modifiable={modifiable}
                name={name}
                fileObject={fileObject}
                documentType={documentType}
                uploadConfig={uploadConfig}
                onRename={onRename}
                onChangeDocumentType={onChangeDocumentType}
              />
            ) : (
              name
            )}
          </Text>
        </FileName>
      </ActionableArea>
      {!hasErrors && !modifiable && fileSize && (
        <FileSizeWrapper>
          <Text variant="sm" color="grey.500">
            {friendlyFileSize(fileSize)}
          </Text>
        </FileSizeWrapper>
      )}
      {isInvalidFileType && <FileError>Invalid file type</FileError>}
      {!isInvalidFileType && isFileTooLarge && <FileError>File too large</FileError>}
      {!isUploading && (
        <ButtonIcon
          disabled={actionsDisabled}
          label={`Remove ${name}`}
          Icon={IconV2OutlineTimes}
          variant="ghost"
          tone="secondary"
          size="xs"
          // "isLoading" is no longer defined at "ButtonIcon",
          // but we don't want to change the logic during the migration.
          {...{ isLoading: isDeleting }}
          onClick={() => onDeleteClick(fileObject)}
        />
      )}
    </ListEntry>
  );
};
