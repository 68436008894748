import type { ReactElement } from 'react';

/** The old file uploader that Dynamic Form is built with */
import { FileUploadField as LegacyField } from '@/src/components/Form/File';

/** The new file uploader from Norma that Dynamic Form should use now */
import { FileUploaderField as CurrentField } from './FileUploaderField';

/**
 * List of props that the new file uploader supports.
 * Note that this is _not_ the only source of compatibility checks,
 * even though it is the main one.
 *
 * See:
 * - getUseLegacyField for all checks
 * - FileUploaderFieldCompat for the actual transformation
 *
 * Also, to make this easy to read (for human) as a lookup table,
 * let's keep the keys in alphabetical order.
 */
const SUPPORTED_KEYS = [
  'accept',
  'aria-required',
  'data-testid',
  'description',
  'disableSkippableCheck',
  'displayLabel',
  'id',
  'label',
  'multiple',
  'name',
  'skippable',
];

function getUseLegacyField(props: any): boolean {
  // This is quite important because the components in between often
  // pass props explicitly, such as:
  // <Foo bar={bar} />
  // In other words, undefined prop may be declared or not.
  const definedKeys = Object.entries(props)
    // Heads up: we should never filter falsy, or even "null" here.
    // In some props "null" is treated differently from "undefined".
    .filter(([, value]) => value !== undefined)
    .map(([key]) => key);

  // This almost never happens in practice, but it's a good safety net.
  // After we migrated all fields, we will remove the whole function altogether.
  if (definedKeys.length === 0) return true;

  // This is really tricky!
  // Due to historical reasons, there are actually 3 "required" outcomes.
  // Our check here means we only support 1 of them.
  // See:
  // - https://linear.app/remote/issue/DEVXP-2296/[file]-support-disable-skippable-prop
  if (props.skippable === true && !props.disableSkippableCheck) return true;

  // This is the happy case where we can finally use the new file uploader!
  if (definedKeys.every((key) => SUPPORTED_KEYS.includes(key))) return false;

  return true;
}

/**
 * Backwards compatibility layer for the FileUploaderField component.
 * This is supposed to be used in Dynamic Form to allow for a smooth transition.
 * It will try to use the new file uploader as much as possible,
 * and fall back to the old one if it can't.
 * See:
 * - https://linear.app/remote/issue/DEVXP-2278/use-norma-file-uploader-in-dynamic-form
 */
export function FileUploaderFieldCompat(props: any): ReactElement {
  const useLegacy = getUseLegacyField(props);

  if (useLegacy) return <LegacyField {...props} />;

  return (
    // Sorry, having a catch-all patch like this is really bad,
    // but there's no better way to do as of now.
    // The thing is, the new uploader field is supposed to be an uncontrolled component
    // (where Formik controls it).
    // However, its typing reflects that it's _both_ controlled and uncontrolled!
    // This means we need to pass _both_ "defaultValue" and "value",
    // which is even worse than no typing altogether.
    // @ts-expect-error
    <CurrentField
      // Simple props that we can just pass through.
      // Because of the sensity (and fragility) of this component,
      // we should never use spread operator here.
      id={props.id}
      // See "id"
      name={props.name}
      // See "id"
      label={props.label}
      // See "id"
      description={props.description}
      // See "id"
      data-testid={props['data-testid']}
      // There is no explicit "required" actually.
      // There is only the aria tag that is provided by our Dynamic Form.
      // The "skippable" prop is simply a direct derived value from this,
      // so we don't need to map "skippable" at all.
      required={props['aria-required']}
      // Note that this does _not_ mean replacing the selected file with another,
      // which is always possible.
      // This means updating the selected file details, such as name,
      // which we don't support in Dynamic Form.
      modifiable={false}
      // This is straightforward with a catch:
      // the file uploader actually has a default value of "documents".
      // Meanwhile, the default expectation of Dynamic Form is "anything".
      supportedFileTypes={
        props.accept
          ? // "accept" expects an array
            props.accept.replaceAll(', ', ',').split(',')
          : // "null" means "anything",
            // but "undefined" means "fallback", which means "documents".
            null
      }
      // "single" is surprisingly complicated here.
      // The key here is that the new field actually changes its value format
      // between single and multiple support!
      // - Single: File | null
      // - Multiple: File[]
      // This breaks _a lot_, as the previous field in Dynamic Form always expects File[],
      // and single or not only affects the user's interface.
      //
      // Therefore, our best bet here is to _always_ use the multiple mode,
      // so that we have a consistent value interface.
      // Meanwhile, we need a new prop to manage the actual "single" constraint.
      //
      // To learn more, start at the implementation of "single file legacy".
      singleFile={false}
      // See "single file"
      singleFileLegacy={!props.multiple}
    />
  );
}
