import merge from 'lodash/merge';
import Script from 'next/script';

import { CookieScriptsStyleOverrides } from './CookieConsent.styled';
import { fetchCookiesConfig } from './services';

const FEATURE_FLAG_PREFIX = 'FLAG_*';
const isDev = () => !['production', 'sandbox'].includes(process.env.NODE_ENV);

function showCookieBanner({ branding, cookiesConfigURL }) {
  return async () => {
    try {
      const globalConfig = await fetchCookiesConfig({ cookiesConfigURL });
      const localConfig = isDev()
        ? {
            ...globalConfig,
            // The FLAG_* cookies are used by the Remote Control Panel to force-enable
            // the value of a feature flag. Note that this is only supported in
            // non-production environments and is used mostly for debugging and testing
            // features that are not yet available to users.
            necessaryCookies: `${globalConfig.necessaryCookies},${FEATURE_FLAG_PREFIX}`,
          }
        : globalConfig;

      if (typeof window !== 'undefined' && !!window.CookieControl?.load) {
        const config = {
          ...localConfig,
          branding: merge(localConfig.branding, { buttonIcon: branding?.buttonIconUrl }),
        };

        window.CookieControl.load(config);
      }
    } catch (e) {
      console.error(e);
    }
  };
}

/**
 * @param {Object} props
 * @param {boolean} [props.hasCaptcha]
 * @param {Object} [props.branding]
 * @param {string} [props.branding.buttonIconUrl]
 * @param {string} [props.baseURL]
 */
export function CookieConsent({ hasCaptcha, branding }) {
  if (typeof window === 'undefined') return null;

  return (
    <>
      <CookieScriptsStyleOverrides $hasCaptcha={hasCaptcha} />
      <Script
        // eslint-disable-next-line no-underscore-dangle
        nonce={window.__webpack_nonce__}
        src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
        onLoad={showCookieBanner({
          branding,
          cookiesConfigURL: `https://remote.com/cookie-control-config.json`,
        })}
        strategy="lazyOnload"
      />
    </>
  );
}
