import { Box } from '@remote-com/norma';
import styled, { css } from 'styled-components';

import { Button } from '@/src/components/Button';

export const ActionButton = styled(Button).attrs({
  variant: 'raw',
})`
  &:hover {
    cursor: pointer;
  }
`;

export const IconBox = styled(Box)`
  width: 36px;
  height: auto;
  padding: 9px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.brand[50]};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  svg {
    color: var(--colors-irisBlue);
  }
`;

export const DefinitionList = styled.dl`
  list-style: none;
`;

export const DefinitionListContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.space[4]}px 0;
    border-bottom: 1px solid ${theme.colors.grey[200]};
  `};
`;

export const DefinitionTerm = styled.dt`
  ${({ theme }) => css`
    ${theme.typography.smMedium};
    color: ${theme.colors.grey[900]};
  `};
`;

export const DefinitionDescription = styled.dd`
  ${({ theme }) => css`
    ${theme.typography.sm};
    color: ${theme.colors.bayoux};
  `};
`;
