import type { ElementType } from 'react';

import { Wrapper, Icon } from './BoxedIcon.styled';

export type BoxedIconAppearance = 'default' | 'subtle';

export type BoxedIconSize = 'sm' | 'md' | 'lg' | 'xl';

export const boxedIconTone = [
  'blueLight',
  'brand',
  'cyan',
  'fuchsia',
  'green',
  'greenLight',
  'grey',
  'orange',
  'pink',
  'purple',
  'red',
  'yellow',
] as const;

export type BoxedIconTone = (typeof boxedIconTone)[number];

export type BoxedIconProps = {
  /**
   * Modifies the box's appearance.
   *
   * - `default`: for use on white backgrounds. Sets a colored background and border.
   * - `subtle`: for use on colored backgrounds. Sets a white background and no border.
   */
  appearance?: BoxedIconAppearance;
  /**
   * The base color of the box and icon.
   */
  tone?: BoxedIconTone;
  /**
   * The icon to be displayed within the box.
   *
   * It is recommended to use icons from the Duotone set with this component.
   */
  Icon: ElementType;
  /**
   * The icon and box size
   *
   * - `sm`: small
   * - `md`: medium
   * - `lg`: large
   * - `xl`: extra large
   */
  size?: BoxedIconSize;
};

export function BoxedIcon({
  appearance = 'default',
  tone = 'grey',
  Icon: IconElement,
  size = 'md',
}: BoxedIconProps) {
  return (
    <Wrapper $appearance={appearance} $size={size} $tone={tone}>
      <Icon as={IconElement} $size={size} />
    </Wrapper>
  );
}
