import { Box, Button, Tooltip } from '@remote-com/norma';
import { IconV2OutlineShieldCheck } from '@remote-com/norma/icons/IconV2OutlineShieldCheck';

export function ContractorPlusProductIndicator() {
  return (
    <Tooltip label="Protected against misclassification risk with Contractor Management Plus.">
      <Button variant="raw" aria-label="Contractor Management Plus">
        <Box color="brand.600" display="flex">
          <IconV2OutlineShieldCheck width={16} />
        </Box>
      </Button>
    </Tooltip>
  );
}
