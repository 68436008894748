import { Box, Tooltip } from '@remote-com/norma';
import { NodeViewWrapper } from '@tiptap/react';
import { forwardRef } from 'react';

import { EditableContentTag } from '@/src/domains/contracts/ContractsEditor/nodes/shared/ContentTag';
import { EditSmartFieldModal } from '@/src/domains/contractTemplates/contractTemplates/modals/SmartFieldModal/EditSmartFieldModal';
import { useModalContext } from '@/src/hooks/useModalContext';

import {
  StyledIconTimesheet,
  StyledIconAlertTriangle,
  getTooltipStyle,
  SmartFieldNodeViewWrapperStyled,
  LabelContainer,
  LabelTextWrapper,
} from './SmartField.styles';

const getIcon = (unavailableAsPlaceholder, unavailableAsPending) =>
  unavailableAsPlaceholder || unavailableAsPending ? (
    <StyledIconTimesheet />
  ) : (
    <StyledIconAlertTriangle />
  );

function UnavailableSmartfieldTooltip({ children, node, extension }) {
  const unavailableIsError =
    !extension.options.unavailableAsPlaceholder && !extension.options.unavailableAsPending;

  let label = 'This smartfield is invalid or unavailable';
  if (extension.options.unavailableAsPlaceholder) {
    label = 'This smartfield is a placeholder and not available yet';
  } else if (extension.options.unavailableAsPending) {
    label = 'Data is missing or pending counterparty review';
  }

  return (
    <Tooltip
      trigger="mouseenter"
      disabled={!node.attrs.isUnavailable}
      label={label}
      styles={getTooltipStyle(unavailableIsError)}
    >
      {children}
    </Tooltip>
  );
}

export const SmartfieldComponent = ({ node, updateAttributes, editor, extension, deleteNode }) => {
  const { editableSmartFields } = extension.options;
  const { isEditable } = editor;
  const isSmartFieldEditable = editableSmartFields && isEditable;

  const { showModal } = useModalContext();

  function showEditSmartFieldModal() {
    if (!isSmartFieldEditable) return;

    showModal({
      component: EditSmartFieldModal,
      modalProps: {
        node,
        editor,
        updateAttributes,
        deleteNode,
      },
    });
  }

  return (
    <UnavailableSmartfieldTooltip node={node} extension={extension}>
      <SmartFieldNodeViewWrapper
        node={node}
        isEditable={isSmartFieldEditable}
        extension={extension}
      >
        {/**
         * Shielding the text content of this node from
         * actually being edited. This change is linked to
         * the Node being marked as editable via the `content` property
         * More context: https://linear.app/remote/issue/COD-1291/contracts-editor-bug-with-cmdarrow-to-move-to-beginning-of-text
         */}
        <LabelContainer contentEditable={false}>
          <EditableContentTag onClick={showEditSmartFieldModal} enableHover={isSmartFieldEditable}>
            <Box {...(node.attrs.isUnavailable ? { pr: '10px', mr: '3' } : {})}>
              <LabelTextWrapper>{node.attrs.label}</LabelTextWrapper>
            </Box>
            <Box>
              {node.attrs.isUnavailable
                ? getIcon(
                    extension.options.unavailableAsPlaceholder,
                    extension.options.unavailableAsPending
                  )
                : null}
            </Box>
          </EditableContentTag>
        </LabelContainer>
      </SmartFieldNodeViewWrapper>
    </UnavailableSmartfieldTooltip>
  );
};

const SmartFieldNodeViewWrapper = forwardRef(function SmartFieldNodeViewWrapper(
  { node, isEditable, extension, ...props },
  ref
) {
  const { isUnavailable } = node.attrs;
  const { unavailableAsPlaceholder, unavailableAsPending, hideUnavailableSmartFields, hasStyling } =
    extension.options;

  return (
    <NodeViewWrapper
      as={SmartFieldNodeViewWrapperStyled}
      ref={ref}
      data-type={extension.name}
      data-has-styling={hasStyling}
      data-is-unavailable={isUnavailable}
      data-is-placeholder={isUnavailable && (unavailableAsPlaceholder || unavailableAsPending)}
      isDisplayed={isUnavailable && hideUnavailableSmartFields}
      isUnavailable={isUnavailable}
      isEditable={isEditable}
      {...props}
    />
  );
});
