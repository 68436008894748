import { bookitEvents, trackEvent } from '@remote-com/analytics';
import { toast } from '@remote-com/norma';
import { useEffect, useState, useRef } from 'react';
import type { MutableRefObject } from 'react';

import { useUser } from '@/src/components/UserProvider/context';
import { splitName } from '@/src/domains/marketing/bookit/helpers';
import useCompanyLocationDetailsData from '@/src/domains/paymentMethods/shared/hooks/useCompanyLocationDetailsData';
import { useEventListener } from '@/src/hooks/useEventListener';

import { bookitOrgId, leanDataFieldId, calendaringObjectName } from './config';
import type { BookitInstance, BookitPayload, InstanceConfig, UniqueInstanceName } from './types';

function submit(bookit: BookitInstance, payload: BookitPayload) {
  return new Promise((resolve, reject) => {
    if (!bookit) {
      reject(new Error('Bookit instance is not initialized'));
      return;
    }

    bookit.submit({
      formData: payload,
      cb: (response) => {
        const { status, formInput, calendarLink, errorMessage } = response;

        if (status === 'success') {
          resolve({ status, formInput, calendarLink });
        } else {
          reject(new Error(errorMessage));
        }
      },
    });
  });
}

// calendaringObjectName and bookitOrgId are unlikely to change between instances so we can make them constants
// nodeName and redirectURL are likely to change between instances so we pass them in as params
function getInstance({ nodeName, redirectURL }: InstanceConfig): BookitInstance | null {
  /**
   * Instance calendaring name
   *
   * After the initial implementation of this script, during testing stage, we noticed the
   * getUniqueId() method was not returning a unique id.
   * To get around this, the team, at the time Milind Rajavasireddy from LeanData provided the following
   * suggestion (adapted by me to our setup below): For each use of useBookit, we initialise a new instance, which
   * will have its own unique id. $calendaringObjectName represents the base script. Our instance will have a unique name.
   */

  const instanceCalendaringName: UniqueInstanceName = `${calendaringObjectName}-${Date.now()}`;
  const baseInstance = window[calendaringObjectName];

  // Initialize LeanData with unique namespace
  if (baseInstance) {
    baseInstance.initialize(bookitOrgId, nodeName, leanDataFieldId, {
      autoSubmit: true,
      calendarTimeoutLength: 300,
      hideLoadingScreen: false,
      mobileCalendarOptions: {
        calendarTimeoutRedirectURL: redirectURL,
      },
      namespace: instanceCalendaringName,
    });
  }

  // Return the unique instance
  return window[instanceCalendaringName] || null;
}

export function useBookit({ nodeName, redirectURL }: InstanceConfig) {
  const { user } = useUser();
  const { data: companyLocation } = useCompanyLocationDetailsData();

  // Checks if external script is loaded and ready
  const [ready, setReadyState] = useState(false);

  // If the function already exists it means we've already loaded it before and therefore we're ready
  useEffect(() => {
    const calendaringObjectIsSet = typeof window[calendaringObjectName]?.initialize === 'function';

    setReadyState(calendaringObjectIsSet);
  }, []);

  // Used in script to set ready state
  function onLoad() {
    setReadyState(true);
  }

  // Create one instance per hook instance
  const instanceRef: MutableRefObject<BookitInstance | null> = useRef(null);

  useEffect(() => {
    if (ready) {
      instanceRef.current = getInstance({ nodeName, redirectURL });
    }
  }, [ready, redirectURL, nodeName]);

  // Listen to successful booking event
  const onBookitMessage = (e: MessageEvent) => {
    // The following handler is used as a temporary workaround to avoid a CSP related bug that prevents inline event handlers being fired
    // Linear issue: https://linear.app/remote/issue/PLG-225/leandata-bookit-modal-close-button-not-functioning
    const clickHandler = () => {
      (window as any).LDBookItPopup.closePopUp();
    };
    let closeButton: Element | null = null;

    switch (e.data.message) {
      case 'LD_POST_BOOKING_IMMEDIATE':
        trackEvent(bookitEvents.SUBMITTED);
        toast.success('Booking successful');
        break;
      case 'LD_POPUP_CLOSED':
        // Reset instance for next use
        // Note: This avoids a bug caused by attempting to re-open the modal and connect to a previous instance
        instanceRef.current = getInstance({ nodeName, redirectURL });

        break;
      case 'LD_ROUTING_RESPONSE':
        // This is part of the temporary workaround mentioned above
        closeButton = document.querySelector('.bookit-close');
        if (closeButton) {
          // The { once: true } option ensures the handler is removed after it has been executed once
          closeButton.addEventListener('click', clickHandler, { once: true });
        }
        break;
    }
  };

  useEventListener('message', onBookitMessage);

  const pushBookitId = (payload: BookitPayload) => {
    const bookit = instanceRef.current;
    const payloadWithBookitData = {
      ...payload,
      [leanDataFieldId]: bookit?.getUniqueId(),
    };
    return payloadWithBookitData;
  };

  const bookDemo = async (url: string, clickEvent?: () => void) => {
    if (clickEvent) {
      clickEvent();
    }

    const bookit = instanceRef.current;
    if (!bookit) {
      toast.error('Unable to contact LeanData');
      return;
    }

    if (user && companyLocation && ready) {
      try {
        const { firstName, lastName } = splitName(user.name);

        const payload = {
          firstname: firstName,
          lastname: lastName,
          email: user.email,
          company: user.company,
          company_headquarters: companyLocation.headquarters?.country?.name,
          url,
        };

        await submit(bookit, pushBookitId(payload));
      } catch (e) {
        toast.error('Unable to contact LeanData');
      }
    }
  };

  return {
    ready,
    onLoad,
    bookDemo,
  };
}
