import type { DefaultTheme } from 'styled-components';

type Colors = DefaultTheme['colors'];
export const tooltipStyles = (colors: Colors) => {
  return {
    usePointStyle: true,
    backgroundColor: colors.white,
    borderColor: colors.grey[200],
    borderWidth: 1,
    titleColor: colors.grey[600],
    bodyColor: colors.grey[800],
    cornerRadius: 8,
    caretSize: 8,
    caretPadding: 10,
    padding: 12,
    displayColors: true,
    bodyFont: {
      size: 12,
    },
    boxPadding: 4,
    bodyAlign: 'center',
    bodySpacing: 6,
    position: 'average',
    xAlign: 'center',
    yAlign: 'bottom',
  };
};
