import { useMemo } from 'react';

import type { BillingDocument } from '@/src/api/config/employ/billingDocuments.types';
import { getInvoiceReportFlags } from '@/src/domains/invoices/shared/helpers';

export const useInvoiceHighLevelBreakdownFlag = ({
  billingDocument,
}: {
  billingDocument: BillingDocument;
}) => {
  const {
    isZuoraInvoice,
    isPreFundingInvoice,
    isReconciliation,
    isReconciliationCredit,
    isOnboardingReserveInvoice,
  } = getInvoiceReportFlags(billingDocument);

  const isEnabledForPreFundingInvoice = useMemo(() => {
    return isZuoraInvoice && isPreFundingInvoice;
  }, [isZuoraInvoice, isPreFundingInvoice]);

  const isEnabledForReconciliationInvoice = useMemo(() => {
    return isZuoraInvoice && (isReconciliation || isReconciliationCredit);
  }, [isZuoraInvoice, isReconciliation, isReconciliationCredit]);

  const isEnabledForRiskReserve = useMemo(() => {
    return isZuoraInvoice && isOnboardingReserveInvoice;
  }, [isOnboardingReserveInvoice, isZuoraInvoice]);

  return {
    isEnabledForPreFundingInvoice,
    isEnabledForReconciliationInvoice,
    isEnabledForRiskReserve,
  };
};
