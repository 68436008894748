export const EVENT_RESOURCE_TYPE = {
  address: 'Address',
  adjustment: 'Adjustment',
  administrative_details: 'Administrative details',
  bank_account: 'Bank account',
  benefit: 'Benefit',
  benefit_plan: 'Benefit plan',
  benefit_tier: 'Benefit tier',
  company: 'Company',
  company_compliance_setting: 'Compliance',
  company_note: 'Company note',
  compensation: 'Compensation',
  contract: 'Contract',
  contract_details: 'Contract details',
  contract_document: 'Contract document',
  right_to_work: 'Right to work',
  contract_signatory: 'Contract signatory',
  contract_template: 'Contract template',
  contractor_invoice: 'Contractor invoice',
  country: 'Country',
  country_product_price: 'Country product price',
  department: 'Department',
  discount: 'Discount',
  document: 'Document',
  emergency_contact: 'Emergency contact',
  employee_deposit: 'Employee deposit',
  employment: 'Employment',
  employment_fee: 'Employment fee',
  expense: 'Expense',
  file: 'File',
  holiday: 'Holiday',
  incentive: 'Incentive',
  issued_credit: 'Issued credit',
  note: 'Note',
  mobility: 'Mobility',
  offboarding: 'Offboarding',
  off_payslip_cost: 'Off payslip cost',
  outstanding_payment: 'Outstanding payment',
  payment_method: 'Payment method',
  recurring_contractor_invoice: 'Recurring contractor invoice',
  integration: 'Integration',
  integration_key: 'Integration key',
  payroll: 'Payroll',
  payroll_run: 'Payroll run',
  payroll_timeoff: 'Payroll timeoff',
  payslip: 'Payslip',
  personal_details: 'Personal details',
  pre_hire: 'Pre hire and visa costs',
  pricing_plan: 'Pricing plan',
  product_price: 'Product price',
  product: 'Product',
  product_discount: 'Product discount',
  recurring_incentive: 'Recurring incentive',
  service_provider: 'Service provider',
  team_member: 'Team member',
  timeoff: 'Timeoff',
  transferwise_recipient_account: 'Transferwise recipient account',
  user: 'User',
  user_relationship: 'User relationship',
};

export const FILTER_TYPES = {
  dateRange: 'date-range',
  eventResourceType: 'event-resource-type',
  excludeAutomationEvents: 'exclude-automation-events',
};

export const EVENT_PROPERTY_LABELS = {
  preferred_pronouns: 'Pronouns',
};

export const DATE_RANGE_DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
};
