// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineFilesText = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M8.37 1.25h1.26c1.1 0 1.96 0 2.66.06.71.06 1.32.18 1.87.46.89.45 1.62 1.18 2.07 2.07.28.55.4 1.16.46 1.87.06.7.06 1.56.06 2.66v7.26c0 1.1 0 1.96-.06 2.66a4.88 4.88 0 0 1-.46 1.87 4.75 4.75 0 0 1-2.07 2.07c-.55.28-1.16.4-1.87.46-.7.06-1.56.06-2.66.06H8.37c-1.1 0-1.96 0-2.66-.06a4.88 4.88 0 0 1-1.87-.46 4.75 4.75 0 0 1-2.07-2.07 4.88 4.88 0 0 1-.46-1.87c-.06-.7-.06-1.56-.06-2.66V8.37c0-1.1 0-1.96.06-2.66.06-.71.18-1.32.46-1.87a4.75 4.75 0 0 1 2.07-2.07c.55-.28 1.16-.4 1.87-.46.7-.06 1.56-.06 2.66-.06ZM5.83 2.8c-.62.05-1 .15-1.3.3-.62.32-1.11.81-1.43 1.42-.15.3-.25.7-.3 1.31-.05.63-.05 1.44-.05 2.57v7.2c0 1.13 0 1.94.05 2.57.05.62.15 1 .3 1.3.32.62.81 1.11 1.42 1.43.3.15.7.25 1.31.3.63.05 1.44.05 2.57.05h1.2c1.13 0 1.94 0 2.57-.05.62-.05 1-.15 1.3-.3.62-.32 1.11-.81 1.43-1.42.15-.3.25-.7.3-1.31.05-.63.05-1.44.05-2.57V8.4c0-1.13 0-1.94-.05-2.57-.05-.62-.15-1-.3-1.3-.32-.62-.81-1.11-1.42-1.43-.3-.15-.7-.25-1.31-.3-.63-.05-1.44-.05-2.57-.05H8.4c-1.13 0-1.94 0-2.57.05ZM19 5.25c.41 0 .75.34.75.75v12a.75.75 0 0 1-1.5 0V6c0-.41.34-.75.75-.75ZM5.25 7c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H6A.75.75 0 0 1 5.25 7ZM22 7.25c.41 0 .75.34.75.75v8a.75.75 0 0 1-1.5 0V8c0-.41.34-.75.75-.75ZM5.25 11c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Zm0 4c0-.41.34-.75.75-.75h2a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineFilesText);
export { ForwardRef as IconV2OutlineFilesText };