import type { LoadingIndicatorProps } from 'react-select';
import styled from 'styled-components';

import { Spinner } from '../../../core/spinner';
import { Stack } from '../../../layout';
import type { $TSFixMe } from '../../../types';

const SpinnerWrapper = styled(Stack)<{ size: 'sm' | 'md' }>`
  /* NOTE: for vertical alignment inside FormGroup */
  margin-top: ${({ size }) => size === 'md' && '-17px'};
`;

export function LoadingIndicator({
  innerProps,
  selectProps: { size },
  ...props
}: LoadingIndicatorProps & $TSFixMe) {
  // react-select uses Emotion for styling internally. To prevent errors in the console, we need to remove the Emotion prop `cx` from being passed down to our styled component.
  const { cx, ...rest } = props;
  return (
    <SpinnerWrapper size={size} {...innerProps} {...rest}>
      <Spinner color="primary" />
    </SpinnerWrapper>
  );
}
