import { SROnly, TextEllipsisCSS } from '@remote-com/norma';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTableUserSettingsContext } from '@/src/components/Table/Components/TableUserSettingsProvider';

export const CellSelector = styled.td`
  ${({ theme }) => theme.typography.sm}

  > div, strong {
    ${({ $ellipsis }) => $ellipsis && TextEllipsisCSS}
  }

  /* Width here is set to 100% to ensure that columns with center-
   * or right-text-alignment stretch to the full cell width and
   * therefore allow its children to show the alignment. */
  > div {
    width: 100%;
  }

  ${({ colSpan }) => `grid-column: span ${colSpan}`};
  ${({ $overflowVisible }) => $overflowVisible && `overflow: visible;`};

  color: ${({ theme }) => theme.colors.grey[900]};

  height: ${({ $isCompactTable }) => ($isCompactTable ? '40px' : '54px')};

  padding: 12px;

  border-top: ${({ $noBorderTop, theme }) =>
    $noBorderTop ? null : `1px solid ${theme.colors.grey[200]}`};

  ${({ $noSpacing, $isCompactTable, theme }) =>
    $noSpacing
      ? `
          padding: 0;

          > div {
            height: 100%;
          }
        `
      : `
          &:last-child {
            padding-right: ${theme.space[3]}px;
          }

          &:first-child {
            padding-right: ${theme.space[3]}px;
          }

          @media screen and (min-width: ${theme.breakpoints.medium}) {
            padding: ${$isCompactTable ? '3px 12px 3px 12px;' : '8px 18px 8px 18px;'};

            &:last-child {
              padding-right: 20px;
            }
            &:first-child {
              padding-left: 20px;
            }
          }
        `}

  ${({ $hasSelectCheckbox }) => $hasSelectCheckbox && `position: relative;`}

  ${({ $styles }) => $styles}
`;

const TableCell = ({ children, srOnly, ...props }) => {
  const { isCompactTable } = useTableUserSettingsContext();

  return (
    <CellSelector {...props} $isCompactTable={isCompactTable}>
      {srOnly && <SROnly>{children}</SROnly>}
      {/* You can't have ellipses on flex children */}
      {props.$ellipsis ? <div>{children}</div> : children}
    </CellSelector>
  );
};

TableCell.defaultProps = {
  $ellipsis: true,
  $overflowVisible: false,
};

TableCell.propTypes = {
  as: PropTypes.oneOf(['th', 'td']),
  $ellipsis: PropTypes.bool,
  $overflowVisible: PropTypes.bool,
  $noBorderTop: PropTypes.bool,
};

export default TableCell;
