import { useInvalidateQuery, usePatch } from '@remote-com/data-layer';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';

type UpdateTableViewParams = {
  tableView: TableViewsApi.PartialTableViewWithStringifiedConfig;
  tableViewSlug: string;
  onSuccess?: (data: TableViewsApi.TableView) => void;
  onError?: (exception: unknown) => void;
};

export function useUpdateTableView() {
  const mutation = usePatch('/api/v1/table-views/[tableViewSlug]');

  const { invalidateQuery } = useInvalidateQuery();
  const updateTableView = ({
    tableView,
    tableViewSlug,
    onSuccess,
    onError,
  }: UpdateTableViewParams) => {
    delete tableView.persistenceKey;
    return mutation.mutateAsync(
      {
        pathParams: { tableViewSlug },
        bodyParams: tableView,
      },
      {
        onSuccess: async (response: TableViewsApi.UpdateTableViewResponse) => {
          invalidateQuery('/api/v1/table-views');
          if (typeof response.data.config === 'string') {
            response.data.config = JSON.parse(response.data.config);
          }
          onSuccess?.(response.data as TableViewsApi.TableView);
        },
        onError: (exception) => {
          onError?.(exception);
        },
      }
    );
  };

  return {
    updateTableView,
    isLoading: mutation.isLoading,
  };
}
