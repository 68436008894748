import { AsideBadge, InputText } from '@remote-com/norma';
import { secondsInHour } from 'date-fns';
import { useField } from 'formik';
import round from 'lodash/round';
import type { ChangeEvent, ComponentPropsWithoutRef, FocusEvent } from 'react';
import { useEffect, useState } from 'react';

const operateOnNumericString = (fn: (value: number) => number) => (inputString: string) => {
  const input = Number.parseFloat(inputString);
  if (Number.isNaN(input)) return;
  const output = fn(input);
  return output.toString();
};

// api endpoints -> string with the number of seconds, integer
// inputs, display -> string with the number of hours, up to two decimals
const fromApiToDisplay = operateOnNumericString((n) => round(n / secondsInHour, 2));
const fromDisplayToApi = operateOnNumericString((n) => round(n * secondsInHour, 0));

export interface DurationInputFieldProps
  extends Omit<ComponentPropsWithoutRef<typeof InputText>, 'onChange'> {
  name: string;
  value?: string;
  onValueChange?: (seconds: string) => void;
}

export function DurationInputField(props: DurationInputFieldProps) {
  // these two props are conflicting with `FieldHookConfig`,
  // so we remove them before passing the rest to `useField`
  const { size, inputMode, ...fieldProps } = props;

  const [{ value: formValue, onBlur: formOnBlur }, { error, touched }, { setValue: setFormValue }] =
    useField<string>(fieldProps);

  const errorText = error && touched ? error : '';

  const [inputValue = '', setInputValue] = useState(() => fromApiToDisplay(formValue));
  useEffect(() => {
    setInputValue(fromApiToDisplay(formValue));
  }, [formValue]);

  const handleChange = (e: ChangeEvent) => {
    setInputValue((e.target as HTMLInputElement).value);
  };

  const handleBlur = (e: FocusEvent) => {
    formOnBlur(e);
    const parsedInput = fromDisplayToApi(inputValue);
    const newFormValue = parsedInput ?? formValue;
    setFormValue(newFormValue);
    const newInputValue = fromApiToDisplay(newFormValue);
    setInputValue(newInputValue);
  };

  return (
    <InputText
      {...props}
      type="number"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      errorText={errorText}
      aside={(asideProps) => <AsideBadge {...asideProps}>hours</AsideBadge>}
    />
  );
}
