import type { ValueOf } from 'type-fest';

import { CONTRACT_TEMPLATE_STATUS } from '@/src/domains/contractTemplates/contractBuilder/constants';
import { CONTRACT_DOCUMENT_STATUS } from '@/src/domains/contractTemplates/contractDocuments/constants';
import { CONTRACT_TEMPLATE_TYPES } from '@/src/domains/contractTemplates/contractTemplates/constants';
import { generateSelectOptions } from '@/src/helpers/forms';

export const REVIEWER_TYPE = {
  REMOTE_ADMINS: 'remote_admins',
  COMPANY: 'company',
} as const;

export type EditorType = ValueOf<typeof CONTRACT_EDITOR_TYPE>;

export const CONTRACT_EDITOR_TYPE = {
  DOCUMENT: 'document',
  TEMPLATE: 'template',
  VIEWER: 'viewer',
} as const;

export const BUILDER_FEATURES = {
  HAS_EDITING: 'has_editing',
  HAS_COMMENTING: 'has_commenting',
  HAS_SIDEBAR: 'has_sidebar',
  HAS_PREVIOUS_REVISION_BUTTON: 'has_previous_revision_button',
} as const;

export const EDITOR_FEATURES = {
  EDITING_CONTENT: 'editing_content',
  EDITING_MANUALLY_ENTERED_FIELDS: 'editing_manually_entered_fields',
  COMMENTS: 'comments',
  SIDEBAR: 'sidebar',
  PREVIOUS_REVISION_BUTTON: 'previous_revision_button',
  DOCUMENT_BILINGUAL_TOGGLE: 'document_bilingual_toggle',
  SMARTFIELDS: 'smartfields',
  CONDITIONALS: 'conditionals',
  SEND_ACTION: 'send_action',
} as const;

export const CONTRACT_TABLE_ACTIONS = {
  CREATE: 'create',
  PUBLISH: 'publish',
  VIEW: 'view',
  PREVIEW: 'preview',
  DOWNLOAD: 'download',
  ARCHIVE: 'archive',
  EDIT: 'edit',
  SEND_TO_EMPLOYEE: 'send_to_employee',
  VIEW_PROFILE: 'view_profile',
  ADD_REVISION: 'add_revision',
  ATTACH_TO_PROFILE: 'attach_to_profile',
  VIEW_AND_COMMENT: 'view_and_comment',
  DELETE: 'delete',
  NOTIFY_OF_UPLOAD: 'notify_of_upload',
  VIEW_COMPANY_PROFILE: 'view_company_profile',
  REASSIGN_EMPLOYER_SIGNATORY: 'reassign_employer_signatory',
  DOWNLOAD_SIGNATURE_AUDIT: 'download_signature_audit',
  VIEW_IN_EDITOR: 'view_in_editor',
} as const;

// Features for contract document editor (`ContractDraftBuilder`).
const CONTRACT_DOCUMENT_EDITOR_FEATURES_BY_TYPE = {
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [
      EDITOR_FEATURES.COMMENTS,
      EDITOR_FEATURES.SIDEBAR,
    ],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [
      EDITOR_FEATURES.COMMENTS,
      EDITOR_FEATURES.SIDEBAR,
    ],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.GLOBAL_OFFER_LETTER]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [
      EDITOR_FEATURES.COMMENTS,
      EDITOR_FEATURES.SIDEBAR,
    ],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
    ],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.PROBATION_CONFIRMATION_LETTER]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
    ],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
    ],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
    ],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.COMMENTS,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
    ],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS, EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.OFFBOARDING_DOCUMENT]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SIDEBAR,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.EMPLOYER_MANAGED_DOCUMENT]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SEND_ACTION,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [],
  },
  [CONTRACT_TEMPLATE_TYPES.TRAVEL_LETTER]: {
    [CONTRACT_DOCUMENT_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SIDEBAR,
    ],
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: [EDITOR_FEATURES.SIDEBAR],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.SIDEBAR],
  },
} as const;

// Features for contract template editor (`ContractTemplateBuilder`).
//
// If a document type or its status are not mentioned, it means all features disabled
// for this combination.
const CONTRACT_TEMPLATE_EDITOR_FEATURES_BY_TYPE = {
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.GLOBAL_OFFER_LETTER]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [EDITOR_FEATURES.SIDEBAR],
  },
  [CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.PROBATION_CONFIRMATION_LETTER]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.DOCUMENT_BILINGUAL_TOGGLE,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.OFFBOARDING_DOCUMENT]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.EMPLOYER_MANAGED_DOCUMENT]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
    ],
  },
  [CONTRACT_TEMPLATE_TYPES.TRAVEL_LETTER]: {
    [CONTRACT_TEMPLATE_STATUS.DRAFT]: [
      EDITOR_FEATURES.EDITING_CONTENT,
      EDITOR_FEATURES.SIDEBAR,
      EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS,
      EDITOR_FEATURES.SMARTFIELDS,
      EDITOR_FEATURES.CONDITIONALS,
    ],
  },
} as const;

// Features for contract document viewer (`ContractDraftViewer`).
//
// If a document type or its status are not mentioned, it means all features disabled
// for this combination.
const CONTRACT_VIEWER_FEATURES_BY_TYPE = {
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT]: {
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [EDITOR_FEATURES.COMMENTS],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS],
  },
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT]: {
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [EDITOR_FEATURES.COMMENTS],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.COMMENTS],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS],
  },
  [CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT]: {
    [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: [EDITOR_FEATURES.COMMENTS],
    [CONTRACT_DOCUMENT_STATUS.REVISED]: [EDITOR_FEATURES.COMMENTS],
    [CONTRACT_DOCUMENT_STATUS.FINISHED]: [EDITOR_FEATURES.COMMENTS],
  },
} as const;

export const CONTRACT_EDITOR_FEATURES_BY_TYPE = {
  [CONTRACT_EDITOR_TYPE.DOCUMENT]: CONTRACT_DOCUMENT_EDITOR_FEATURES_BY_TYPE,
  [CONTRACT_EDITOR_TYPE.TEMPLATE]: CONTRACT_TEMPLATE_EDITOR_FEATURES_BY_TYPE,
  [CONTRACT_EDITOR_TYPE.VIEWER]: CONTRACT_VIEWER_FEATURES_BY_TYPE,
} as const;

export const CONTRACT_LANGUAGE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

export const CONTRACT_LANGUAGE_LABELS = {
  [CONTRACT_LANGUAGE.PRIMARY]: 'Primary language',
  [CONTRACT_LANGUAGE.SECONDARY]: 'Secondary language',
} as const;

export const CONTRACT_LANGUAGE_OPTIONS = generateSelectOptions(CONTRACT_LANGUAGE_LABELS);

export const BILINGUAL_EDITOR_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

export const COMMENT_HIGHLIGHT_SOURCES = {
  SIDEBAR: 'sidebar',
  EDITOR: 'editor',
} as const;

export const DOCUMENT_COMMENT_STATUSES = {
  STATIC: 'static',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  DELETED: 'deleted',
} as const;
