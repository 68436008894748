// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneCheckSquare = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M14.03 1.25H9.97c-1.38 0-2.45 0-3.32.07-.88.07-1.6.22-2.26.56a5.75 5.75 0 0 0-2.51 2.51 5.92 5.92 0 0 0-.56 2.26c-.07.87-.07 1.94-.07 3.32v4.06c0 1.38 0 2.45.07 3.32.07.88.22 1.6.56 2.26a5.75 5.75 0 0 0 2.51 2.51c.66.34 1.38.49 2.26.56.87.07 1.94.07 3.32.07h4.06c1.38 0 2.45 0 3.32-.07.88-.07 1.6-.22 2.26-.56a5.75 5.75 0 0 0 2.51-2.51c.34-.66.49-1.38.56-2.26.07-.87.07-1.94.07-3.32V9.97c0-1.38 0-2.45-.07-3.32a5.92 5.92 0 0 0-.56-2.26 5.75 5.75 0 0 0-2.51-2.51 5.92 5.92 0 0 0-2.26-.56c-.87-.07-1.94-.07-3.32-.07Z" /><path fillRule="evenodd" clipRule="evenodd" d="M16.5 8.45c.31.28.33.75.05 1.06l-5.5 6a.75.75 0 0 1-1.08.02l-2.5-2.5a.75.75 0 1 1 1.06-1.06l1.95 1.95 4.97-5.43a.75.75 0 0 1 1.06-.04Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneCheckSquare);
export { ForwardRef as IconV2DuotoneCheckSquare };