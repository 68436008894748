import { Radio } from '@remote-com/norma';
import { useField } from 'formik';

import { throwError } from '@/src/helpers/general';

export const RadioField = ({ onChange, onBlur, checked, ...props }) => {
  if (typeof checked !== 'undefined') {
    throwError(
      `'checked' doesn't have any effect on Formik forms, you need to add the default value to the form state, try initialValues.`
    );
  }

  const [{ onChange: onChangeField, onBlur: onBlurField, ...field }] = useField({
    ...props,
    type: 'radio',
  });

  const handleOnChange = (e) => {
    onChangeField?.(e);
    onChange?.(e);
  };

  const handleOnBlur = (e) => {
    onBlurField?.(e);
    onBlur?.(e);
  };

  return <Radio {...props} {...field} onChange={handleOnChange} onBlur={handleOnBlur} />;
};
