import type { ScreamingSnakeCaseMapping } from '@/src/types/case';

import type { WidgetKey } from './types';

export const ROW_HEIGHT: number = 1; // 1 to make it work with variable height widgets
export const GAP: number = 24;
export const PADDING: number = 0;

// Any new widget MUST have a unique key
export const widgetKeys: ScreamingSnakeCaseMapping<WidgetKey> = {
  TODOS: 'todos',
  PRODUCT_DEMO: 'product_demo',
  FULL_ACCOUNT: 'full_account',
  TEAM: 'team',
  PUBLIC_HOLIDAYS: 'public_holidays',
  TEAM_BY_COUNTRY: 'team_by_country',
  UPCOMING_BIRTHDAYS: 'upcoming_birthdays',
  WHOS_AWAY: 'whos_away',
  UPCOMING_PTO: 'upcoming_pto',
  GENDER_DIVERSITY: 'gender_diversity',
  BOOK_A_CALL: 'book_a_call',
  ADD_DIRECT_EMPLOYEE: 'add_direct_employee',
  ADD_EOR_EMPLOYEE: 'add_eor_employee',
  ADD_GLOBAL_PAYROLL_EMPLOYEE: 'add_global_payroll_employee',
  CONTRACTOR_ONBOARDING: 'contractor_onboarding',
  CONTRACTOR_PAYMENT_METHODS: 'contractor_payment_methods',
  HRIS_MOBILE_APP: 'hris_mobile_app',
  PERFORM_COST_CALCULATION: 'perform_cost_calculation',
  REFERRAL_PROGRAM: 'referral_program',
  TAKE_FREELANCER_HUB_TOUR: 'take_freelancer_hub_tour',
  TAKE_INVOICING_FROM_TIME_TRACKING_TOUR: 'take_invoicing_from_time_tracking_tour',
  TAKE_QUICK_EMPLOYER_TOUR: 'take_quick_employer_tour',
  TIME_OFF: 'time_off',
  UNSET: 'unset',
  USE_COMPENSATION_EXPLORER: 'use_compensation_explorer',
  VIEW_MARKETPLACE: 'view_marketplace',
  DIRECT_REPORTS: 'direct_reports',
  SCHEDULE_CALL_PILOT: 'schedule_call_pilot',
  ADMIN_ROLES_PERMISSION: 'admin_roles_permission',
  BOOK_GLOBAL_PAYROLL_DEMO: 'book_global_payroll_demo',
  BULK_ADD_DIRECT_EMPLOYEE: 'bulk_add_direct_employee',
  EMPLOYER_DASHBOARD: 'employer_dashboard',
  VIEW_CONTRACTOR_MANAGEMENT_TOUR: 'view_contractor_management_tour',
  VIEW_EMPLOYEE_EXPERIENCE_TOUR: 'view_employee_experience_tour',
  VIEW_EOR_TOUR: 'view_eor_tour',
  BENEFITS_SAFETY_WING: 'benefits_safety_wing',
  BENEFITS_INTEGRATIONS: 'benefits_integrations',
  QUICK_TASKS: 'quick_tasks',
  RECENT_INVOICES: 'recent_invoices',
  CLIENTS: 'clients',
  REMOTE_GUIDE: 'remote_guide',
  YEAR_TO_DATE_EARNINGS: 'year_to_date_earnings',
  HOW_TO_CREATE_AN_INVOICE: 'how_to_create_an_invoice',
  SUBMIT_INVOICES_ON_THE_GO: 'submit_invoices_on_the_go',
  DUPLICATE_AN_EXISTING_INVOICE: 'duplicate_an_existing_invoice',
  EXPLORE_NEW_OPPORTUNITIES: 'explore_new_opportunities',
  UPCOMING_DATES: 'upcoming_dates',
} as const;

export const widgetSizes = {
  SMALL: 1, // spans 1 column in width
  MEDIUM: 2, // spans 2 columns in width
  LARGE: 3, // spans 3 columns in width
} as const;
