import { useCallback, useEffect } from 'react';

export default function useKeyPressDetection({ listen = true, key = '', onKeyPress = () => {} }) {
  const handleOnKeyPress = useCallback(
    (event) => {
      if (typeof event.key === 'string' && event.key.toLowerCase() === key) {
        onKeyPress();
      }
    },
    [key, onKeyPress]
  );

  // Add key press listener if form is hidden
  useEffect(() => {
    if (listen) {
      document.addEventListener('keyup', handleOnKeyPress, true);
    } else {
      document.removeEventListener('keyup', handleOnKeyPress, true);
    }

    return () => {
      document.removeEventListener('keyup', handleOnKeyPress, true);
    };
  }, [listen, handleOnKeyPress]);
}
