import * as React from 'react';
import type { InputActionMeta } from 'react-select';

import type { FormGroupLabelPlacement } from '..';
import type { $TSFixMe } from '../../../types';

const FormGroupContext = React.createContext<undefined | FormGroupProviderProps>(undefined);

interface FormGroupProviderProps {
  id?: string;
  'data-testid'?: string;
  handleOnBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  ref?: React.ForwardedRef<unknown | $TSFixMe>;
  onChange?: (arg: unknown) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  menuRef?: $TSFixMe;
  multiple?: boolean;
  transformCreatedOption?: (label: string) => { label: string; value: string };
  value?: string;
  size?: $TSFixMe; // Note: using FormGroupSize here somehow breaks the InputText component
  labelPlacement?: FormGroupLabelPlacement;
  readOnly?: boolean;
  label?: string | React.ReactElement;
  placeholder?: string;
  name?: string;
  setHasInputFocus?: (value: boolean) => void;
}

function useFormGroupContext() {
  const context = React.useContext(FormGroupContext);
  if (context === undefined) {
    throw new Error('useFormGroupContext must be used within a FormGroupProvider');
  }

  return context;
}

const FormGroupProvider = React.forwardRef(({ children, ...props }, ref) => {
  const value = {
    ...props,
    ref,
  };

  return <FormGroupContext.Provider value={value}>{children}</FormGroupContext.Provider>;
});

export { FormGroupProvider, useFormGroupContext };
