import { FeedbackMessage, HTMLRendered } from '@remote-com/norma';

import { supportedTypes } from '@/src/components/Form/DynamicForm/constants';
import {
  isFieldVisible,
  isValueEmpty,
  containsHTML,
  wrapWithSpan,
  maybeGetHelpCenterWithDescription,
} from '@/src/components/Form/DynamicForm/helpers';
import { FieldWithForcedValueAsSentence } from '@/src/components/Ui/Form/FieldWithForcedValueAsSentence';
import { InputController } from '@/src/components/Ui/Form/Input';

function checkFieldHasForcedValue(field) {
  // A field to be considered "forced value" must:
  return (
    field.const !== undefined && // Only accepts a specific value
    field.const === field.default && // It can be prefilled, meaning it's not critical
    field.inputType !== 'checkbox' && // Because checkbox must always be visible
    field.inputType !== 'hidden' // Because hidden inputs shouldn't be visible
  );
}

/**
 * DynamicFormField
 *
 * @export
 * @param {Object} props
 * @param {import('@/src/components/Form/DynamicForm/types').FormField} props.input - Field type object.
 * @param {number=} props.index - Index of the field.
 * @param {import('formik').FormikValues} props.values - Object with form values mapped to field names.
 * @param {boolean=} props.showFieldsDeprecated - Flag to show deprecated fields.
 * @param {boolean=} props.biggerMargin - Flag to increase fields spacing.
 * @param {boolean=} props.isNestedField - Flag to increase fields left spacing.
 * @param {import('@/src/components/Form/DynamicForm/renderField').RenderFieldFunction} props.renderField - Function to render a field.
 * @param {import('@/src/components/Form/DynamicForm/renderField').FormikSetFieldValue} props.setValue - Function to set a value.
 * @return {JSX.Element}
 */
export function DynamicFormField({
  input,
  index,
  values,
  showFieldsDeprecated,
  biggerMargin,
  isNestedField,
  renderField,
  setValue,
  shouldShowComponent = () => true,
  ...props
}) {
  if (input.calculateDynamicProperties) {
    input = { ...input, ...(input.calculateDynamicProperties(values) || {}) };
  }

  if (!isFieldVisible(input, values)) return null;

  const {
    type,
    jsonType,
    name,
    value,
    label,
    deprecated,
    readOnly,
    statement,
    default: defaultValue,
    checkboxValue,
  } = input;

  const inputControllerProps = {
    type,
    name,
    value,
    checkboxValue,
    defaultValue,
    readOnly,
    key: name || label || index,
    hidden:
      type === supportedTypes.HIDDEN ||
      (!showFieldsDeprecated && !!deprecated && readOnly && isValueEmpty(values[input.name])),
    biggerMargin,
    isNestedField,
  };

  const location = input?.meta?.helpCenter?.location;

  let inputModified = {
    ...input,
    description: maybeGetHelpCenterWithDescription(input, location),
    extra: containsHTML(input.extra) ? wrapWithSpan(input.extra, 'jsf-extra') : input.extra,
  };

  // This if is to show the help center link inside the statement instead of the
  // field description. For now it is only used in Chile and Colombia's
  // contract details forms.
  // https://linear.app/remote/issue/EOR-968/clarify-annual-gross-salary-description-chile-chl
  let statementModified = statement;

  if (location === 'statement') {
    statementModified = {
      ...statement,
      description: maybeGetHelpCenterWithDescription(input, location),
    };

    inputModified = {
      ...inputModified,
      description: input.description,
    };
  }

  if (checkFieldHasForcedValue(input)) {
    const { title, description, severity } = input.statement || {};
    const renderedTitle = title || input.label;
    const renderedDescription = description || inputModified.description;
    const isHiddenField = !renderedDescription && !input.statement?.title;

    return (
      <FieldWithForcedValueAsSentence
        name={input.name}
        forcedValue={input.const}
        title={renderedTitle}
        description={renderedDescription}
        severity={severity}
        isHiddenField={isHiddenField}
      />
    );
  }

  const shouldShowStatement = shouldShowComponent(input) && statement;

  const isStatementOnly =
    shouldShowStatement && type === supportedTypes.HIDDEN && jsonType === 'null';

  if (isStatementOnly) {
    return (
      <InputController {...inputControllerProps} hidden={false}>
        <Statement statement={statementModified} fieldType={type} statementOnlyField />
      </InputController>
    );
  }

  return (
    <InputController {...inputControllerProps}>
      {renderField(inputModified, setValue, values, props)}
      {shouldShowStatement && <Statement statement={statementModified} fieldType={type} />}
    </InputController>
  );
}

function Statement({ statement, fieldType, statementOnlyField }) {
  if (fieldType === 'group-array') return null;

  const title = !containsHTML(statement.title) ? (
    statement.title
  ) : (
    <HTMLRendered Tag="span">{statement.title}</HTMLRendered>
  );

  const content = containsHTML(statement.description) ? (
    wrapWithSpan(statement.description, 'jsf-statement')
  ) : (
    <HTMLRendered Tag="span">{statement.description}</HTMLRendered>
  );

  return (
    <FeedbackMessage title={title} variant={statement.severity} mt={statementOnlyField ? 0 : 6}>
      {content}
    </FeedbackMessage>
  );
}
