// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineTimesFull = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g><g><path d="M15.36 9.7a.75.75 0 1 0-1.06-1.06l-2.3 2.3-2.3-2.3A.75.75 0 0 0 8.64 9.7l2.3 2.3-2.3 2.3a.75.75 0 0 0 1.06 1.06l2.3-2.3 2.3 2.3a.75.75 0 0 0 1.06-1.06l-2.3-2.3 2.3-2.3Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 1.25a10.75 10.75 0 1 0 0 21.5 10.75 10.75 0 0 0 0-21.5ZM2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0Z" /></g></g></svg>;
const ForwardRef = forwardRef(IconV2OutlineTimesFull);
export { ForwardRef as IconV2OutlineTimesFull };