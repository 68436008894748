import { homepageEvents, trackEvent } from '@remote-com/analytics';
import { Text, Box } from '@remote-com/norma';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { openModal } from 'react-url-modal';

import { WidgetCard } from '@/src/components/WidgetCard';
import { BookACallButton } from '@/src/domains/marketing/bookit/BookACallButton';
import { nodeNames } from '@/src/domains/marketing/bookit/constants';
import { NoWrapButton } from '@/src/domains/userPrompts/widgets/common.styled';

const openDemo = () => {
  openModal({ name: 'product-tour-modal' });
  trackEvent(homepageEvents.contentCardClicked('product-tour'));
};

export const BOOK_A_CALL_WIDGET_STORAGE_KEY = 'book-a-call-widget-dismissed';

type Props = {
  onDismiss?: () => void;
};
export const BookACallWidget = ({ onDismiss }: Props) => {
  const router = useRouter();

  useEffect(() => {
    trackEvent(homepageEvents.contentCardLoaded('book-your-free-onboarding-call'));
  }, []);

  return (
    <WidgetCard title="Book your free onboarding call" onDismiss={onDismiss} hasBodyPadding>
      <Text variant="sm" color="grey.600">
        Schedule a 30-minute chat with a global HR expert and get answers to your questions right
        away.
      </Text>
      <Text variant="sm" color="grey.600" mt={6}>
        You can also take a self-guided tour at your own leisure.
      </Text>

      <Box
        mt={7}
        display="flex"
        justifyContent="space-between"
        marginX="auto"
        maxWidth={{ default: '100%', large: '355px' }}
      >
        <BookACallButton
          size="sm"
          url="https://employ.remote.com"
          clickEvent={() => trackEvent(homepageEvents.contentCardClicked('talk-to-expert'))}
          instanceConfig={{
            nodeName: nodeNames.TALK_TO_SALES,
            redirectURL: `https://employ.remote.com${router.asPath}`,
          }}
        >
          Talk to an expert
        </BookACallButton>

        <NoWrapButton type="button" size="sm" variant="outline" onClick={openDemo}>
          Take a tour
        </NoWrapButton>
      </Box>
    </WidgetCard>
  );
};
