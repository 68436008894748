import type { BoxedIconTone } from '@remote-com/norma';
import { IconAlertTriangle } from '@remote-com/norma/icons/IconAlertTriangle';
import type { ElementType } from 'react';

import type { ProductEmploymentType } from '@/src/api/config/api.types';
import type { ProductUsage } from '@/src/api/config/employ/productUsageAndAccess.types';
import { PRICING_PLAN_NAMES, pricingPlansDisplayNames } from '@/src/domains/pricing/constants';
import { useProductUsageAndAccessContext } from '@/src/domains/productUsageAndAccess';
import {
  PRODUCT_ACCESS_STATE,
  PURCHASE_PRODUCT_URLS,
} from '@/src/domains/productUsageAndAccess/constants';
import { findProductUsageByEmploymentType } from '@/src/domains/productUsageAndAccess/helpers';
import type {
  FeatureAccessName,
  ActiveOrLastActivePricingPlan,
  FeatureAccessWithUsage,
} from '@/src/domains/productUsageAndAccess/types';
import { isDev } from '@/src/helpers/general';

import { SkeletonUsageCard, UsageCard } from './UsageCard';

export type FeatureUsageContent = ({
  feature,
  activeOrLastActivePricingPlan,
}: {
  feature: FeatureAccessWithUsage;
  activeOrLastActivePricingPlan: ActiveOrLastActivePricingPlan;
}) => string;

type ContentProps = {
  title: FeatureUsageContent;
  description: FeatureUsageContent;
};

type WithIcon = ContentProps & {
  icon: { Icon: ElementType; tone: BoxedIconTone };
};

export type CardStates = {
  [PRODUCT_ACCESS_STATE.PLAN_ACTIVE]: WithIcon;
  [PRODUCT_ACCESS_STATE.PLAN_PAUSED]: WithIcon;
  [PRODUCT_ACCESS_STATE.PLAN_EXPIRED]: ContentProps;
  [PRODUCT_ACCESS_STATE.FEATURE_USAGE_EXCEEDED]: ContentProps;
  [PRODUCT_ACCESS_STATE.FREE_TRIAL_ACTIVE]?: WithIcon;
  [PRODUCT_ACCESS_STATE.FREE_TRIAL_EXPIRED]?: ContentProps;
};

type ProductUsageCardProps = {
  productEmploymentType: ProductEmploymentType;
  featureName: FeatureAccessName;
  cardStates: CardStates;
};

const CTA_CONFIG = {
  [PRODUCT_ACCESS_STATE.PLAN_ACTIVE]: {
    href: PURCHASE_PRODUCT_URLS[PRODUCT_ACCESS_STATE.PLAN_ACTIVE],
    label: 'Manage plan',
  },
  [PRODUCT_ACCESS_STATE.PLAN_PAUSED]: {
    href: PURCHASE_PRODUCT_URLS[PRODUCT_ACCESS_STATE.PLAN_PAUSED],
    label: 'Manage plan',
  },
  [PRODUCT_ACCESS_STATE.PLAN_EXPIRED]: {
    href: PURCHASE_PRODUCT_URLS[PRODUCT_ACCESS_STATE.PLAN_EXPIRED],
    label: 'Renew plan',
  },
  [PRODUCT_ACCESS_STATE.FEATURE_USAGE_EXCEEDED]: {
    href: PURCHASE_PRODUCT_URLS[PRODUCT_ACCESS_STATE.FEATURE_USAGE_EXCEEDED],
    label: 'Upgrade plan',
  },
  [PRODUCT_ACCESS_STATE.FREE_TRIAL_ACTIVE]: {
    href: PURCHASE_PRODUCT_URLS[PRODUCT_ACCESS_STATE.FREE_TRIAL_ACTIVE],
    label: 'Upgrade plan',
  },
  [PRODUCT_ACCESS_STATE.FREE_TRIAL_EXPIRED]: {
    href: PURCHASE_PRODUCT_URLS[PRODUCT_ACCESS_STATE.FREE_TRIAL_EXPIRED],
    label: 'Upgrade plan',
  },
};

/**
 * Renders a card displaying feature usage information and overall product state.
 * @param {Object} props - Component props
 * @param {ProductEmploymentType} props.productEmploymentType - Type of product
 * @param {FeatureAccessName} props.featureName - Name of the feature to display usage for
 * @param {CardStates} props.cardStates - Configuration object defining the display states of the card
 * @returns {JSX.Element | null} A usage card component or null if required data is missing
 */
export function FeatureUsageCard({
  productEmploymentType,
  featureName,
  cardStates,
}: ProductUsageCardProps) {
  const { data, getProductAccessState, isLoading } = useProductUsageAndAccessContext();

  if (isLoading) {
    return <SkeletonUsageCard />;
  }

  const productAccessState = getProductAccessState({
    productEmploymentType,
    featureName,
  });

  const product: ProductUsage | undefined = findProductUsageByEmploymentType(
    productEmploymentType,
    data?.products || []
  );

  if (
    !product ||
    !productAccessState ||
    productAccessState === PRODUCT_ACCESS_STATE.NEVER_SUBSCRIBED ||
    !cardStates[productAccessState]
  ) {
    if (isDev()) {
      // eslint-disable-next-line no-console
      console.warn('FeatureUsageCard: no product or feature access state found.');
    }
    return null;
  }

  const cardState = cardStates[productAccessState];
  const { description, title } = cardState;

  function getIcon() {
    if (
      productAccessState === PRODUCT_ACCESS_STATE.PLAN_EXPIRED ||
      productAccessState === PRODUCT_ACCESS_STATE.FREE_TRIAL_EXPIRED ||
      productAccessState === PRODUCT_ACCESS_STATE.FEATURE_USAGE_EXCEEDED
    ) {
      return { Icon: IconAlertTriangle, tone: 'yellow' as BoxedIconTone };
    }
    const icon = 'icon' in cardState ? cardState.icon : undefined;

    return icon;
  }

  const cta = CTA_CONFIG[productAccessState];

  const icon = getIcon();

  const feature = (data?.access || {})[featureName] as FeatureAccessWithUsage;

  const { activeOrLastActivePricingPlan } = product;

  const pricingPlanName =
    activeOrLastActivePricingPlan.name === PRICING_PLAN_NAMES.FREE_TRIAL
      ? pricingPlansDisplayNames[PRICING_PLAN_NAMES.FREE_TRIAL]
      : `${pricingPlansDisplayNames[activeOrLastActivePricingPlan.name]} plan`;

  return (
    <UsageCard
      icon={icon}
      title={title({
        feature,
        activeOrLastActivePricingPlan,
      })}
      badge={pricingPlanName}
      description={description({
        feature,
        activeOrLastActivePricingPlan,
      })}
      cta={{ label: cta.label, href: cta.href(product.identifier) }}
    />
  );
}
