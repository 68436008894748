import type { BoxProps } from '@remote-com/norma';
import { Box } from '@remote-com/norma';
import styled, { keyframes } from 'styled-components';

const logoAnimation = keyframes`
  0%, 25% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 650px;
  }
`;

const logoAnimationReducedAnimation = keyframes`
  0%, 50% {
    opacity: 1;
  }
  100% {
    opacity: .3;
  }
`;

type RemoteLoaderSpace = 'md' | 'lg' | 'xl';

const marginVertical: Record<RemoteLoaderSpace, string> = {
  md: '50px',
  lg: '80px',
  xl: '150px',
};

const RemoteLoader = styled(Box)<{ $space: RemoteLoaderSpace }>`
  width: 36px;
  margin: ${({ $space }) => marginVertical[$space]} auto;

  svg .logo {
    animation: ${logoAnimationReducedAnimation} 1s infinite alternate;
    stroke: ${({ theme }) => theme.colors.irisBlue};

    @media (prefers-reduced-motion: no-preference) {
      stroke-dashoffset: 0;
      stroke-dasharray: 650px;
      animation: ${logoAnimation} 1.2s infinite alternate;
      animation-timing-function: ease-in;
    }
  }
`;

type RemoteLogoLoaderProps = BoxProps & {
  $space?: RemoteLoaderSpace;
  'aria-label'?: string;
};

const RemoteLogoLoader = ({ $space = 'md', ...props }: RemoteLogoLoaderProps) => (
  <RemoteLoader data-testid="loader" $space={$space} {...props}>
    <svg
      viewBox="0 0 231 310"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={props['aria-label'] || 'Loading'}
    >
      <path
        d="M38 268.5V192V107C44 45.5 115.159 20.2199 159 51.5C211 88.6012 192.5 148 169.5 169.5C161.598 176.887 145.5 186.5 131.5 190C131.5 247 166 272 230.5 272"
        strokeWidth="76"
        className="logo"
      />
    </svg>
  </RemoteLoader>
);

export default RemoteLogoLoader;
