import ChartAxisLabel from '@/src/domains/reports/components/ReportWidgets/ChartAxisLabel';

import {
  ChartContainer as ChartContainerStyled,
  ChartRow,
  ChartWrapper,
} from './ChartContainer.styled';

type ChartContainerProps = {
  children: React.ReactNode;
  yLabel: string;
  xLabel: string;
};

export default function ChartContainer({ children, yLabel, xLabel }: ChartContainerProps) {
  return (
    <ChartContainerStyled>
      <ChartRow>
        <ChartAxisLabel orientation="vertical">{yLabel}</ChartAxisLabel>
        <ChartWrapper>{children}</ChartWrapper>
      </ChartRow>
      <ChartAxisLabel orientation="horizontal">{xLabel}</ChartAxisLabel>
    </ChartContainerStyled>
  );
}
