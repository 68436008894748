import { useCallback, useEffect, useRef } from 'react';

export function usePreventBackdropDismiss() {
  const timeoutIdRef = useRef<number | NodeJS.Timeout | undefined>();
  const preventDismissRef = useRef(false);
  const canDismissViaBackdrop = useCallback(() => preventDismissRef.current === false, []);

  const preventBackdropDismissFor = (timeout: number) => {
    preventDismissRef.current = true;
    timeoutIdRef.current = setTimeout(() => {
      preventDismissRef.current = false;
    }, timeout);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutIdRef.current);
  }, []);

  return {
    canDismissViaBackdrop,
    preventBackdropDismissFor,
  };
}
