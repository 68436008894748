import type { ReactNode } from 'react';
import { useContext } from 'react';

import { HTMLRendered } from '../../core/html-rendered';
import { Text } from '../../core/text/Text';
import { FieldError } from '../../core/text/Text.styled';
import { Box } from '../../foundations/layout/Box';
import { Stack } from '../../foundations/layout/Stack';

import { FIELDSET_VARIANTS, FIELDSET_MODES } from './constants';
import {
  StyledFieldsetDescription,
  StyledFieldsetLegendBox,
  StyledFieldsetLegendText,
  StyledFieldsetStack,
  StyledFieldsetWrapper,
  FieldsetStyled,
} from './Fieldset.styled';
import { FieldsetContext } from './FieldsetContext';

interface ExtraProps {
  value: ReactNode;
}

const Extra = ({ value }: ExtraProps) => (
  <Box mt={6} px={5}>
    <HTMLRendered Tag={Text} variant="sm" color="grey.500">
      {value}
    </HTMLRendered>
  </Box>
);

export interface FieldsetProps {
  children: ReactNode | ReactNode[];
  label: ReactNode;
  description?: ReactNode;
  extra?: ReactNode;
  variant?: string;
  name?: string;
  /**
   * Whether the Fieldset visual should indicate an error.
   * If defined, it should be the error message to display.
   *
   * It's up to the parent to decide what is an error here.
   * In most cases, it means one of the child fields has an error.
   * It's usually provided by a wrapper component from a specific app,
   * which is aware of the form manager (e.g., formik) errors.
   */
  errorMessage?: string;
}

export function Fieldset({
  children,
  label,
  description = null,
  extra = null,
  variant = FIELDSET_VARIANTS.DEFAULT,
  errorMessage,
  ...props
}: FieldsetProps) {
  const context = useContext(FieldsetContext);

  if (context?.mode === FIELDSET_MODES.FOCUS_ENABLED && variant === FIELDSET_VARIANTS.FOCUSED) {
    return (
      <FieldsetStyled id={props.name} {...props} data-fieldset-focused>
        <Stack pl={5} pr={5} mb={6} gap={2} direction="column">
          <Text variant="lgMedium" color="grey.900" as="legend" fontFamily="brand">
            {label}
          </Text>
          {description && (
            <HTMLRendered Tag={Text} forwardedAs="span" variant="sm" color="grey.600">
              {description}
            </HTMLRendered>
          )}
        </Stack>
        <Stack>{children}</Stack>
        {extra && <Extra value={extra} />}
      </FieldsetStyled>
    );
  }

  // Do not render a fieldset for focused variants if the mode is not defined
  if (variant === FIELDSET_VARIANTS.FOCUSED) {
    return <>{children}</>;
  }

  const hasError = typeof errorMessage === 'string';

  // Return the inner fieldset
  return (
    <StyledFieldsetWrapper id={props.name} {...props} hasError={hasError}>
      <StyledFieldsetLegendBox>
        <StyledFieldsetLegendText color="grey.900" variant="lgMedium" fontFamily="brand">
          {label}
        </StyledFieldsetLegendText>
      </StyledFieldsetLegendBox>
      {hasError && <FieldError>{errorMessage}</FieldError>}
      {description && <HTMLRendered Tag={StyledFieldsetDescription}>{description}</HTMLRendered>}
      <StyledFieldsetStack>{children}</StyledFieldsetStack>
      {extra && <Extra value={extra} />}
    </StyledFieldsetWrapper>
  );
}
