import {
  Drawer,
  DrawerHeader,
  DrawerLayout,
  DrawerPane,
  DrawerTitle,
  Text,
} from '@remote-com/norma';
import styled from 'styled-components';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';
import { ConfigurationList } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/ConfigurationList';
import SearchField from '@/src/components/Table/Components/SearchField';
import { DeleteTableViewModal } from '@/src/components/Table/Components/TableViewsDrawer/DeleteTableViewModal';
import { SetTableViewNameModal } from '@/src/components/Table/Components/TableViewsDrawer/SetTableViewNameModal';
import { TableViewsListItem } from '@/src/components/Table/Components/TableViewsDrawer/TableViewsListItem';
import { useModalContext } from '@/src/hooks/useModalContext';
import { useSearch } from '@/src/hooks/useSearch';

type ManageTableViewsDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  views: TableViewsApi.TableView[];
  onDelete?: () => void;
  onRename?: () => void;
  applyView: (view: TableViewsApi.TableView) => void;
  setListenToClickOutside?: (listen: boolean) => void;
};

const StyledDrawerPane = styled(DrawerPane)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[6]}px;
`;

export const ManageTableViewsDrawer = ({
  isVisible,
  onClose,
  onDelete,
  onRename,
  views,
  applyView,
  setListenToClickOutside = () => {},
}: ManageTableViewsDrawerProps) => {
  const { showModal } = useModalContext();

  const searchProps = useSearch({
    items: [...views],
    getItemSearchValue: ({ name }) => name,
  });

  const { searchValue, onSearch, searchResults } = searchProps;
  const activeViews = searchValue ? searchResults : views;

  if (!isVisible) return null;

  const handleDeleteView = (view: TableViewsApi.TableView) => {
    setListenToClickOutside(false);
    const { name, slug } = view;

    showModal({
      component: DeleteTableViewModal,
      modalProps: {
        messages: {
          title: 'Delete saved table view?',
          description: (descriptionName: string) =>
            `${descriptionName} will be deleted from your custom table views. This can’t be undone.`,
          deleteButton: 'Delete table view',
        },
        name,
        setListenToClickOutside,
        onDelete,
        slug,
      },
    });
  };

  const handleRenameView = (view: TableViewsApi.TableView) => {
    setListenToClickOutside(false);
    onRename?.();

    const { slug, persistenceKey, name } = view;
    const {
      columns: { visibility = {}, order = [] } = {},
      filters = [],
      sortBy = [],
    } = view.config || {};

    showModal({
      component: SetTableViewNameModal,
      modalProps: {
        messages: {
          title: 'Rename table view',
        },
        persistenceKey,
        applyView,
        filters,
        order,
        visibility,
        sortBy,
        slug,
        name,
        setListenToClickOutside,
      },
    });
  };

  return (
    <Drawer
      aria-label="Manage table views drawer"
      data-testid="manageTableViews"
      onClose={onClose}
      $layout="fullWidth"
      isVisible={isVisible}
      Trigger="TODO_MIGRATE_TO_COMPONENT_DONT_COPY"
    >
      <DrawerHeader>
        <DrawerTitle>Manage table views</DrawerTitle>
      </DrawerHeader>
      <DrawerLayout>
        <StyledDrawerPane>
          <SearchField
            value={searchValue}
            setSearchQuery={onSearch}
            placeholder="Search table views"
            width="100%"
            id="table-edit-table-drawer-search-input"
          />
          <ConfigurationList>
            {activeViews?.map((view) => (
              <TableViewsListItem
                key={view.slug}
                name={view.name}
                onDeleteView={() => handleDeleteView(view)}
                onRenameView={() => handleRenameView(view)}
              />
            ))}

            {searchValue && searchResults.length === 0 && (
              <Text variant="xs">No results match your search.</Text>
            )}
          </ConfigurationList>
        </StyledDrawerPane>
      </DrawerLayout>
    </Drawer>
  );
};
