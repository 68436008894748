import { Radio as RadioV2 } from '@remote-com/norma';
import PropTypes from 'prop-types';

import { RadioField } from '@/src/components/Ui/Form/formikIntegration/RadioField/RadioField';

const disabledProps = {
  'aria-disabled': 'true',
  onClick: (e) => {
    // aria-disabled does not prevent interaction
    // as the disabled attr
    e.preventDefault();
  },
};

export function Radio({ label, description, disabled, readOnly, ...props }) {
  const { unsafeForceCheckedAttr, ...forwardedProps } = props;

  /**
   * DO NOT EVER USE THE checked PROP WITH FORMIK!
   *
   * All our Formik forms are controlled by the form state, this means that passing a checked={true}
   * to a Radio button won't have any effect on the form values, but there's one specific case where instead
   * of implementing a Formik form, we are managing the state of the form using useState, so this hack was necessary.
   */
  return unsafeForceCheckedAttr ? (
    <RadioV2
      label={label}
      description={description}
      disabled={disabled || readOnly}
      {...forwardedProps}
    />
  ) : (
    <RadioField
      label={label}
      description={description}
      disabled={disabled || readOnly}
      {...((disabled || readOnly) && disabledProps)}
      {...forwardedProps}
    />
  );
}

Radio.defaultProps = {
  description: '',
};

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Radio;
