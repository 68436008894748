import type { ReactNode } from 'react';
import React, { createContext, useContext } from 'react';

export const TableBodyInputSizeContext = createContext<boolean | undefined>(undefined);

type TableBodyInputSizeProviderProps = {
  children: ReactNode;
};

/**
 * Aux hook that allows to determine whether the caller of this hook is rendered inside a table body or not.
 * @returns {boolean}
 */
function useIsInsideTableBody(): boolean {
  const isInsideTableBody = useContext(TableBodyInputSizeContext);
  return isInsideTableBody === undefined ? false : isInsideTableBody;
}

/**
 * Sets the table body rendering context.
 * This is needed because the inputs rendered inside a table body should be "condensed" i.e., with a small size
 * and with the label placed inside the container.
 */
const TableBodyInputSizeProvider = ({ children }: TableBodyInputSizeProviderProps) => {
  const areInputsInsideATableBody = true;

  return (
    <TableBodyInputSizeContext.Provider value={areInputsInsideATableBody}>
      {children}
    </TableBodyInputSizeContext.Provider>
  );
};

export { TableBodyInputSizeProvider, useIsInsideTableBody };
