import { isFlagActive } from '@/src/components/FeatureFlag';
import { error } from '@/src/helpers/general';

export const openZendeskWidget = () => {
  if (isFlagActive('ZENDESK_WEB_WIDGET_ENABLED')) {
    try {
      window.zE('messenger', 'open');
    } catch (e) {
      error(`Zendesk widget error: ${e.message}`);
    }
  }
};

export const closeZendeskWidget = () => {
  if (isFlagActive('ZENDESK_WEB_WIDGET_ENABLED')) {
    try {
      window.zE('messenger', 'close');
    } catch (e) {
      error(`Zendesk widget error: ${e.message}`);
    }
  }
};
