import type { ControlProps } from 'react-select';
import styled from 'styled-components';

import { Stack } from '../../../layout';
import type { $TSFixMe } from '../../../types';

const StyledControl = styled(Stack).attrs({ direction: 'row' })`
  align-items: center;
`;

export function Control({
  children,
  innerProps,
  innerRef,
}: ControlProps & { innerProps: $TSFixMe }) {
  return (
    <StyledControl {...innerProps} ref={innerRef}>
      {children}
    </StyledControl>
  );
}
