import { cleanString } from '@/src/domains/bulkUpload/admin/helpers/strings';

function processValue(rawEntry, sourceFields) {
  const values = (sourceFields || []).flatMap((fieldName) => {
    if (fieldName instanceof RegExp) {
      // find all rawEntry keys that match
      // return those values as array
      return Object.keys(rawEntry)
        .filter((s) => fieldName.test(s))
        .map((matchingFieldName) => rawEntry[matchingFieldName]);
    }
    return rawEntry[fieldName];
  });

  const value = [...new Set(values.filter((exists) => exists != null))][0];

  return value?.toString() ?? null;
}

/*
 * Numbers in CSV passed can be in various formats:
 * 13,37
 * 13.37
 * 1,337,000.00
 * Since JS only accepts 13.37 (dot format) it's better not to try to "guess"
 * What user actually meant if different format applied
 * But simply mark this value explicitly as incorrect
 *
 * The format we expect is:
 * - No thousands separator: 20000 instead of 20,000
 * - Dot as decimal separator: 1.34 instead of 1,34
 */
export const parseNumberInput = (strValue) => {
  const isDecimal = strValue?.includes('.');
  const parsedValue = parseFloat(strValue);

  if (isDecimal) {
    const decimalPlacesCount = strValue.split('.')[1].length;
    const parsedValueWithDecimalPlaces = parsedValue.toFixed(decimalPlacesCount);
    return parsedValueWithDecimalPlaces === strValue ? parsedValue : null;
  }

  return parsedValue.toString() === strValue ? parsedValue : null;
};

const findCurrencySlug = (code, currencies) => {
  return currencies.find((currency) => currency.code === code)?.slug || null;
};

function processEntry(rawEntry, mappingRules, transformationRules, currencies) {
  const interimResult = {};

  Object.entries(mappingRules).forEach(([destinationField, sourceFields]) => {
    interimResult[destinationField] = processValue(rawEntry, sourceFields);
  });

  // as some destinationFields can be dependent on previous destination fields, we are doing a second pass here
  Object.entries(mappingRules).forEach(([destinationField, sourceFields]) => {
    interimResult[destinationField] = processValue({ ...rawEntry, ...interimResult }, sourceFields);
  });

  const processed = Object.keys(interimResult)
    .map((key) => {
      if (transformationRules[key].transform) {
        return { [key]: transformationRules[key].transform(interimResult) };
      }

      if (transformationRules[key].input?.type === 'number') {
        return { [key]: parseNumberInput(interimResult[key]) };
      }

      if (transformationRules[key].input?.type === 'currency') {
        return { [key]: findCurrencySlug(interimResult[key], currencies) };
      }

      return { [key]: interimResult[key] };
    })
    .reduce(
      (acc, curr) => ({
        ...acc,
        ...curr,
      }),
      {}
    );

  return processed;
}

export function processData({ currentProcessor, data, currencies }) {
  if (!data) {
    return {};
  }

  const mappingFields = Object.keys(currentProcessor.rules).reduce((acc, curr) => {
    const mappingFieldsLowerCased = (currentProcessor.rules[curr].mappingFields || []).map(
      cleanString
    );
    return {
      ...acc,
      [curr]: mappingFieldsLowerCased,
    };
  }, {});

  const mappedData = data.map((rawEntryProp) => {
    const entry = Object.keys(rawEntryProp).reduce(
      (acc, curr) => ({
        ...acc,
        [cleanString(curr)]: rawEntryProp[curr],
      }),
      {}
    );

    const processedEntry =
      currentProcessor?.preProcessingTransform?.(entry, currentProcessor) || entry;

    return processEntry(processedEntry, mappingFields, currentProcessor.rules, currencies);
  });

  return mappedData;
}
