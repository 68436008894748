import DOMPurify from 'dompurify';
import type { ElementType } from 'react';

import type { ComponentProps } from '../../types';

export type HTMLRenderedProps<T extends ElementType> = {
  Tag: T;
  forwardedAs?: ElementType;
} & ComponentProps<T>;

export function HTMLRendered<T extends ElementType>({
  children,
  Tag,
  ...props
}: HTMLRenderedProps<T>) {
  return typeof children === 'string' ? (
    <Tag dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children) }} {...props} />
  ) : (
    <Tag {...props}>{children}</Tag>
  );
}
