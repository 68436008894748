import { handleNavigationTracking } from '@remote-com/analytics';
import type {
  ButtonIconProps,
  ButtonInlineProps,
  ButtonProps,
  ContextMenuItemProps,
  DropdownMenuItemProps,
  ExtendableProps,
  ButtonCalloutProps,
  PolymorphicComponent,
  PolymorphicComponentPropsWithRef,
  PropsOf,
  StandaloneLinkProps,
} from '@remote-com/norma';
import type { LinkProps } from 'next/link';
import Link from 'next/link';
import type { AnchorHTMLAttributes, ElementType, MouseEvent, ReactElement } from 'react';
import { forwardRef } from 'react';

import { getDDSessionReplayAttribute } from '@/src/helpers/datadog';

/*
 * Note: this code is replicated both in Employ and Jobs. A ticket was created to assess wether it makes
 * sense to put the HOC in a shareable package (https://linear.app/remote/issue/DEVXP-1068/centralize-withnextlink-hoc-in-a-shareable-place)
 */

type AsForStyledProp<C extends ElementType> = {
  asTag?: C;
};

type PolymorphicComponentProps<C extends ElementType, Props = {}> = ExtendableProps<
  PropsOf<C>,
  Props &
    AsForStyledProp<C> & { ref?: PolymorphicComponentPropsWithRef<C> } & {
      trackNavigation?: Array<string | Record<string, unknown>>;
    }
>;

interface PolymorphicLinkComponent<DefaultElement extends ElementType, Props = {}> {
  <C extends ElementType = DefaultElement>(
    // eslint-disable-next-line no-unused-vars
    props: ElementType<C> extends ElementType<'a'>
      ? PolymorphicComponentProps<C, Props> | LinkProps
      : PolymorphicComponentProps<C, Props>
  ): ReactElement | null;
}

type DragonButtonProps<NormaProps> = NormaProps & {
  trackNavigation?: Array<string | Record<string, unknown>>;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};
type NextJSButtonProps<NormaProps> = DragonButtonProps<NormaProps> & Partial<LinkProps>;

// Lodash partition but for Objects.
// https://github.com/lodash/lodash/issues/3172#issuecomment-352773355
function partitionObject<NormaProps>(
  obj: NextJSButtonProps<NormaProps>,
  filterFn: (value: any, name: keyof NextJSButtonProps<NormaProps>) => boolean
): [LinkProps, DragonButtonProps<NormaProps>] {
  return Object.keys(obj).reduce(
    (result, key) => {
      // @ts-expect-error
      result[filterFn(obj[key], key) ? 0 : 1][key] = obj[key];
      return result;
    },
    [{}, {}] as [LinkProps, DragonButtonProps<NormaProps>]
  );
}

// NextLink props: https://nextjs.org/docs/api-reference/next/link
const nextLinkPropsSet = new Set([
  'as',
  'href',
  'passHref',
  'prefetch',
  'replace',
  'scroll',
  'shallow',
  'getStaticProps',
  'getServerSideProps',
  'getInitialProps',
  'locale',
]);

function extractNextLinkProps<NormaProps>(props: NextJSButtonProps<NormaProps>): {
  nextLinkProps: LinkProps;
  buttonProps: DragonButtonProps<NormaProps>;
} {
  const [nextLinkProps, buttonProps] = partitionObject(props, (propValue: any, propName) =>
    nextLinkPropsSet.has(propName as string)
  );
  return { nextLinkProps, buttonProps };
}

function getDisplayName(WrappedComponent: any) {
  return WrappedComponent?.displayName || WrappedComponent?.name || 'Component';
}

// When providing a blob URL as href for downloading a file next/link will
// remove the second slash in the url during the normalization process:
// -> Given href blob:http://employ.remote.com/f0eb5499-5d79-4ab8-ba1c-95902dba657b
// -> Replaced href blob:http:/employ.remote.com/f0eb5499-5d79-4ab8-ba1c-95902dba657b
// This happens because blob: will be the protocol in this case and next does not allow
// double forwards slashes in the href, and will try to fix this by removing one.
function isDownloadLink(props: any) {
  return typeof props.href === 'string' && props.href.startsWith('blob:') && props.download;
}

/**
 * This type extends the original component props with the NextJS Link props (such as
 *`href`, `prefetch`, etc) and with the native HTML anchor attributes (`target`, `download`, etc).
 * We're omitting some props from these extensions:
 * - `onClick` because we want to persist the type that comes from the original component;
 * - `as` because we want to keep the `as` prop that's inherited from styled components
 * - `href` (from the anchor) because we want to keep the type that comes from LinkProps
 */
type AugmentedProps<T> = Omit<T, 'href'> &
  Partial<Omit<LinkProps, 'as' | 'onClick'>> &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick' | 'href'>;

/**
 * This HOC wraps a component with the NextJS's <Link>, allowing "pure" components from Norma to be
 * usable in a NextJS app, keeping the original component untouched but using keeping the navigation features
 * from the framework in a transparent way.
 */
export function withNextLink<El extends ElementType, Props extends ButtonProps>(
  MainComponent: PolymorphicComponent<El, Props>
): PolymorphicLinkComponent<El, AugmentedProps<Props>>;

export function withNextLink<El extends ElementType, Props extends DropdownMenuItemProps>(
  MainComponent: PolymorphicComponent<El, Props>
): PolymorphicLinkComponent<El, AugmentedProps<Props>>;

export function withNextLink<El extends ElementType, Props extends ContextMenuItemProps>(
  MainComponent: PolymorphicComponent<El, Props>
): PolymorphicLinkComponent<El, AugmentedProps<Props>>;

export function withNextLink<El extends ElementType, Props extends ButtonIconProps>(
  MainComponent: PolymorphicComponent<El, Props>
): PolymorphicLinkComponent<El, AugmentedProps<Props>>;

export function withNextLink<El extends ElementType, Props extends ButtonInlineProps>(
  MainComponent: PolymorphicComponent<El, Props>
): PolymorphicLinkComponent<El, AugmentedProps<Props>>;

export function withNextLink<El extends ElementType, Props extends StandaloneLinkProps>(
  MainComponent: PolymorphicComponent<El, Props>
): PolymorphicLinkComponent<El, AugmentedProps<Props>>;

export function withNextLink<El extends ElementType, Props extends ButtonCalloutProps>(
  MainComponent: PolymorphicComponent<El, Props>
): PolymorphicLinkComponent<El, AugmentedProps<Props>>;

export function withNextLink<El extends ElementType>(
  MainComponent: PolymorphicComponent<El, any>
): PolymorphicLinkComponent<El, any> {
  const ElementWithNextJSLink = forwardRef(
    (props: ButtonProps & LinkProps, ref: PolymorphicComponentPropsWithRef<El>) => {
      const ddActionAttribute = getDDSessionReplayAttribute(props, { showChildContents: true });
      const { href } = props;

      if (!href || isDownloadLink(props)) {
        return <MainComponent ref={ref} {...ddActionAttribute} {...props} />;
      }

      const { nextLinkProps, buttonProps } = extractNextLinkProps(props);

      return (
        <Link {...nextLinkProps} passHref legacyBehavior>
          <MainComponent
            ref={ref}
            {...ddActionAttribute}
            {...buttonProps}
            onClick={(ev: MouseEvent<HTMLAnchorElement>) => {
              const { trackNavigation, onClick } = buttonProps;
              if (typeof onClick === 'function') onClick(ev);
              handleNavigationTracking(trackNavigation, href);
            }}
          />
        </Link>
      );
    }
  );

  ElementWithNextJSLink.displayName = `withNextLink(${getDisplayName(MainComponent)})`;

  return forwardRef(
    <C extends ElementType = 'button'>(
      props: PolymorphicComponentProps<C, ButtonProps & LinkProps>,
      ref: PolymorphicComponentPropsWithRef<'button'>
    ) => <ElementWithNextJSLink {...props} ref={ref} />
  );
}
