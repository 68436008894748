import { getFileExtension } from '../../../foundations';
import type { SelectOption } from '../../input-select';
import { InputSelect } from '../../input-select';
import { InputText } from '../../input-text';
import type {
  InputFileObject,
  InputFileModifiable,
  InputFileOption,
  InputFileUploadConfig,
} from '../helpers';
import { InputFileStyled } from '../styled';

const { StyledExtensionBadge } = InputFileStyled;

function getAllowedExtensionsForOptions(params: {
  options?: InputFileOption[];
  uploadConfig?: InputFileUploadConfig;
}): Record<string, string[]> {
  const { options, uploadConfig } = params;

  if (!options || !uploadConfig) return {};

  const documentTypeKeys = options.map((o) => o.value);

  return Object.entries(uploadConfig).reduce((acc, [key, value]) => {
    if (!documentTypeKeys.includes(key)) return acc;
    return {
      ...acc,
      [key]: value?.extensions,
    };
  }, {});
}

function getDefaultOption(options: InputFileOption[]): undefined | InputFileOption {
  // Heads up: In a previous implementation, this returns "{}".
  // However, when migrate this component to TypeScript,
  // we realised it can only be either undefined or an Option.
  // It's too risky to populate a random Option here,
  // so we changed it to return undefined.
  // If this breaks anything, reverse and force the type.
  if (!options?.length) return undefined;

  const other = options.find((o) => o.value === 'other');
  if (other) return other;

  return options.find((o) => o.value === 'document' || o.value === 'personal');
}

export function InputFileListEntryFields(props: {
  name: string;
  uploadConfig: InputFileUploadConfig;
  documentType?: string;
  onRename: (fileObject: InputFileObject, name: string) => void;
  onChangeDocumentType: (fileObject: InputFileObject, type: string) => void;
  fileObject: InputFileObject;
  modifiable: InputFileModifiable;
}) {
  const {
    name,
    uploadConfig,
    documentType,
    onRename,
    onChangeDocumentType,
    fileObject,
    modifiable,
  } = props;

  const extension = getFileExtension({ name: fileObject.name });
  const extensionsPerType = getAllowedExtensionsForOptions({
    options: modifiable?.type,
    uploadConfig,
  });

  const allowedOptions = modifiable?.type?.map((option) => {
    return {
      ...option,
      isDisabled: !(extensionsPerType[option.value] || []).includes(`.${extension}`),
    };
  });

  return (
    <div className="modifiable">
      {modifiable.name && (
        <InputText
          // Why "any": InputText is built upon FormGroup,
          // which indeed expects a "name" and "label".
          // What we should use here instead is TextControl,
          // which does not use FormGroup.
          // However, that's a bigger refactor that we did not do during the migration.
          // See: https://remote-com.slack.com/archives/C020B57P9QU/p1726126643784599
          name={undefined as any}
          label={undefined as any}
          //
          size="sm"
          value={name}
          onChange={(event) => {
            // "target" is _practically_ HTMLInputElement,
            // but InputText loses its typing due to FormGroup.
            // @todo: Fix FormGroup so that it does not lose typing.
            const target = event.target as HTMLInputElement;
            onRename(fileObject, target.value);
          }}
          aside={
            extension
              ? (asideProps) => (
                  <StyledExtensionBadge {...asideProps}>.{extension}</StyledExtensionBadge>
                )
              : undefined
          }
        />
      )}
      {modifiable.type?.length > 0 && (
        <InputSelect<SelectOption>
          // Why any: See the same props at InputText above.
          name={undefined as any}
          label={undefined as any}
          value={
            documentType
              ? modifiable.type.find((option) => option.value === documentType)
              : getDefaultOption(modifiable.type)
          }
          size="sm"
          options={allowedOptions}
          onChange={(selected) => {
            onChangeDocumentType(fileObject, selected.value);
          }}
        />
      )}
    </div>
  );
}
