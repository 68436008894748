import { differenceInCalendarDays } from 'date-fns';
import getConfig from 'next/config';

import type { TodoUpdate } from '@/src/api/config/employ/shared.types';
import type { TodoItemProps } from '@/src/domains/todos/types';
import { captureException } from '@/src/helpers/captureException';
import type { $TSFixMe } from '@/types';

/**
 * Reports a todo with an invalid type to DataDog (if in PROD) or to the console (otherwise)
 * * */
function reportInvalidTodoType(todoType: string) {
  const message = `Todo of type "${todoType}" is not supported in dragon. This might be due to a legacy notification type in our database, or a configuration conflict between tiger and dragon.`;
  const {
    publicRuntimeConfig: { ENVIRONMENT },
  } = getConfig();
  const isDev = ENVIRONMENT !== 'production';
  // if DEV
  if (isDev) {
    // eslint-disable-next-line no-console
    console.error(message);
  } else {
    // PROD -> datadog
    captureException(new Error(message));
  }
}

/**
 * Gets all necessary props to populate `TodoCard` props
 */
export function getTodoPropsFromConfig(config: $TSFixMe, todo: TodoUpdate): TodoItemProps | null {
  if (!config) {
    reportInvalidTodoType(todo.type);
    return null;
  }

  const startDate = todo?.updatedAt || todo?.insertedAt;

  return {
    title: config?.title,
    description: config?.description,
    category: config?.category,
    dueDate: todo?.dueDate ? new Date(todo.dueDate) : undefined,
    href: config?.link?.href,
    Icon: config?.Icon,
    onClick: config?.onClick,
    startDate: startDate ? new Date(startDate) : undefined,
    type: todo?.type,
  };
}

/**
 * Formats the difference in days to display a string indicating the time left until the due date.
 */
export function formatDaysDifference(daysDifference: number): string {
  switch (true) {
    case daysDifference < 0:
      return 'Overdue';
    case daysDifference === 0:
      return 'Due today';
    case daysDifference === 1:
      return '1 day left';
    default:
      return `${daysDifference} days left`;
  }
}

/**
 * Returns the due date of a todo item in a human-readable format.
 */
export const getTodoItemDueDate = (dueDate?: Date) => {
  if (!dueDate) {
    return undefined;
  }

  const today = new Date();
  const daysDifference = differenceInCalendarDays(dueDate, today);
  return formatDaysDifference(daysDifference);
};
