import { DropdownMenu, Stack, Text, toast } from '@remote-com/norma';
import { IconV2OutlineArrowDown } from '@remote-com/norma/icons/IconV2OutlineArrowDown';
import { IconV2OutlineArrowUp } from '@remote-com/norma/icons/IconV2OutlineArrowUp';
import { IconV2OutlineCheck } from '@remote-com/norma/icons/IconV2OutlineCheck';
import { IconV2OutlineEyeOff } from '@remote-com/norma/icons/IconV2OutlineEyeOff';
import omit from 'lodash/omit';
import { forwardRef, useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { Button } from '@/src/components/Button';
import {
  ResizeHandle,
  SortIcon,
  StyledTh,
} from '@/src/components/Table/Components/TableComponents.styled';
import { DateRelatedFilters } from '@/src/components/Table/Filters';
import { ACTIONS_COLUMN_ID } from '@/src/components/Table/helpers/constants';
import { CHECKBOX_COLUMN_ID } from '@/src/components/Table/helpers/injectCheckbox';
import { trackHideIndividualColumnEvent } from '@/src/components/Table/helpers/tableTrackingEvents';
import { useResizableColumns } from '@/src/components/Table/hooks/useResizableColumns';

import { CellsAlignedToTheRight, CellsWithDateTimeSorting, CurrencyCells } from '../Cells';

// Note: 'basic' is what React Table v7 considers as a sort type for numeric values. Since this is not easy to remember, we're
// considering both 'basic' and 'numeric' as indicators of a numeric sorting. You'll see this logic reflected below.

export const HIDE_COLUMN_FEATURE_USED_KEY = 'hide-table-column-used';

export const messages = {
  columnMenuToggle: 'Open column options menu',
  columnMenuOptionSortAscending: (sortType) => {
    switch (sortType) {
      case 'datetime':
        return 'Sort oldest-newest';
      case 'numeric':
      case 'basic':
        return 'Sort 0-9';
      default:
        return 'Sort A-Z';
    }
  },
  columnMenuOptionSortDescending: (sortType) => {
    switch (sortType) {
      case 'datetime':
        return 'Sort newest-oldest';
      case 'basic':
      case 'numeric':
        return 'Sort 9-0';
      default:
        return 'Sort Z-A';
    }
  },
  columnMenuOptionHideColumn: 'Hide column',
};

const StyledSortButton = styled(Button).attrs({ variant: 'raw' })`
  height: 100%;
  position: absolute;
  width: 100%;
  right: 0;
`;

const StyledTableHeaderContent = styled.span`
  button,
  [tabindex] {
    position: relative;
    z-index: 10;
  }
`;

const isDateColumn = (column) => {
  if (
    column.sortType === 'datetime' ||
    CellsWithDateTimeSorting.has(column.Cell) ||
    DateRelatedFilters.has(column.Filter)
  ) {
    return true;
  }
  return false;
};

const isNumericColumn = (column) => {
  if (
    column.sortType === 'numeric' ||
    column.sortType === 'basic' ||
    // Since our number/currency columns are right aligned, we're considering that
    // if we have this right alignment, the column should have a numeric sorting
    column.align === 'right' ||
    CurrencyCells.has(column.Cell)
  ) {
    return true;
  }
  return false;
};

const getColumnSortType = (column) => {
  if (isDateColumn(column)) {
    return 'datetime';
  }
  if (isNumericColumn(column)) {
    return 'numeric';
  }
  return 'alphanumeric';
};

const SortDirection = ({ sortType, direction, isActive, onSelect }) => {
  const { colors } = useTheme();
  const Icon = direction === 'ascending' ? IconV2OutlineArrowUp : IconV2OutlineArrowDown;
  const messageGroup =
    direction === 'ascending'
      ? messages.columnMenuOptionSortAscending
      : messages.columnMenuOptionSortDescending;
  return (
    <DropdownMenu.Item
      onSelect={() => onSelect(direction)}
      Icon={Icon}
      data-testid={`sort-column-${direction}`}
    >
      <Stack display="flex" direction="row" justifyContent="space-between" flex="1">
        {messageGroup(sortType)}
        {isActive ? (
          <IconV2OutlineCheck
            width="20px"
            fill={colors.primary}
            data-testid={`sort-${direction}-active`}
          />
        ) : null}
      </Stack>
    </DropdownMenu.Item>
  );
};

const TableHeader = forwardRef(
  (
    {
      children,
      canSort,
      isSorted,
      isSortedDesc,
      ResizeEl,
      onClick,
      onSort,
      toggleColumnVisibility,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const handleToggleOpen = () => setOpen(!open);
    const columnSortType = getColumnSortType(props.column);

    const align =
      CellsAlignedToTheRight.has(props.column.Cell) || props.column.align === 'right'
        ? 'right'
        : 'left';

    const hasColumnMenu =
      ![CHECKBOX_COLUMN_ID, ACTIONS_COLUMN_ID].includes(props.column.id) &&
      (canSort || !!toggleColumnVisibility);

    return (
      // width should always only be used in the table to make sure the grid works properly
      <StyledTh
        {...omit(props, 'style.width')}
        ref={ref}
        resizing={props.resizing}
        $align={align}
        $isMenuOpen={open}
        data-testid={`table-header-col-${props.column.id}`}
        $hasColumnMenu={hasColumnMenu}
      >
        <StyledTableHeaderContent>{children}</StyledTableHeaderContent>
        {isSorted && (
          <SortIcon
            as={isSortedDesc ? IconV2OutlineArrowDown : IconV2OutlineArrowUp}
            aria-hidden="true"
          />
        )}

        {hasColumnMenu && (
          <>
            <StyledSortButton
              aria-label={messages.columnMenuToggle}
              onClick={handleToggleOpen}
              aria-expanded={open}
            />
            <DropdownMenu
              align="start"
              dropdownOpen={open}
              setDropdownOpen={handleToggleOpen}
              showTrigger={false}
              dataTestid="table-header-menu"
              menuButtonProps={{
                // TODO: remove this in favor of DropdownMenu.Trigger
                'aria-label': 'Ignore button',
                inert: 'true', // Make SR ignore it, aria-hidden does not work in buttons.
              }}
            >
              {canSort && (
                <>
                  <SortDirection
                    direction="ascending"
                    sortType={columnSortType}
                    isActive={isSorted && !isSortedDesc}
                    onSelect={onSort}
                  />
                  <SortDirection
                    direction="descending"
                    sortType={columnSortType}
                    isActive={isSorted && isSortedDesc}
                    onSelect={onSort}
                  />
                </>
              )}
              {toggleColumnVisibility && (
                <DropdownMenu.Item
                  onSelect={() => {
                    toggleColumnVisibility(props.column);
                  }}
                  Icon={IconV2OutlineEyeOff}
                >
                  {messages.columnMenuOptionHideColumn}
                </DropdownMenu.Item>
              )}
            </DropdownMenu>
          </>
        )}
        <ResizeEl />
      </StyledTh>
    );
  }
);

export const TableHeaders = ({
  columns: baseColumns,
  tableRef,
  tableName,
  toggleColumnVisibility,
  columnResize,
  columnConfig,
}) => {
  const { activeColumn, setActiveColumn, columns } = useResizableColumns({
    tableRef,
    baseColumns,
  });
  const [hideColumnFeatureWasUsed, setHideColumnFeatureWasUsed] = useState(
    !!window.localStorage.getItem(HIDE_COLUMN_FEATURE_USED_KEY),
    []
  );
  const handleToggleColumnVisibility = useCallback(
    (column) => {
      const columnName = typeof column.Header === 'string' ? column.Header : null;

      toggleColumnVisibility(column.id);
      trackHideIndividualColumnEvent({ tableName });

      if (!hideColumnFeatureWasUsed) {
        toast.success(() => (
          <Stack gap="1">
            <Text variant="smMedium" color="grey.900">
              {columnName ? `${columnName} column hidden` : 'Column hidden'}
            </Text>

            <Text variant="sm">
              If you want to make it visible again, go to{' '}
              <Text as="strong" color="grey.900">
                Edit table
              </Text>
              .
            </Text>
          </Stack>
        ));

        localStorage.setItem(HIDE_COLUMN_FEATURE_USED_KEY, true);
        setHideColumnFeatureWasUsed(true);
      }
    },

    [toggleColumnVisibility, hideColumnFeatureWasUsed, tableName]
  );

  return columns.map(({ column, ref }, idx) => {
    const shouldResize =
      column.id !== CHECKBOX_COLUMN_ID &&
      column.sticky !== 'right' &&
      columnResize &&
      !column.disableResize;

    const handleOnSort = (order) => {
      const isSortedAscending = column.isSorted && !column.isSortedDesc;
      const isSortedDescending = column.isSorted && column.isSortedDesc;

      if (
        (isSortedAscending && order === 'ascending') ||
        (isSortedDescending && order === 'descending')
      ) {
        column.clearSortBy();
      } else {
        switch (order) {
          case 'ascending':
            column.toggleSortBy(false);
            break;
          case 'descending':
            column.toggleSortBy(true);
            break;
        }
      }
    };

    return (
      <TableHeader
        ref={ref}
        key={column.id}
        canSort={column.canSort}
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc}
        {...column.getHeaderProps()}
        style={column.style}
        column={column}
        onSort={handleOnSort}
        resizing={idx === activeColumn}
        ResizeEl={() =>
          shouldResize && (
            <ResizeHandle
              data-testid={`resize-${column.id}`}
              onMouseDown={() => setActiveColumn(idx)}
            />
          )
        }
        toggleColumnVisibility={!column.sticky && !!columnConfig && handleToggleColumnVisibility}
      >
        {column.render('Header')}
      </TableHeader>
    );
  });
};
