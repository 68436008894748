// https://www.notion.so/remotecom/Dominican-Republic-1a6b18b7ba3545b3b95801a3171b4704?pvs=4#c2c2b6b4d9cb4f22833f27985ccf9254
export const DOM_BANK_CODES = [
  { value: '003', label: 'Banco Popular' },
  { value: '004', label: 'Banco BHD' },
  { value: '005', label: 'Banco del Progreso' },
  { value: '007', label: 'Banco de Reservas' },
  { value: '008', label: 'Scotiabank' },
  { value: '009', label: 'Banco Santa Cruz' },
  { value: '010', label: 'Banco Leon' },
  { value: '011', label: 'Asoc. Popular de Ahorros y Prestamos' },
  { value: '012', label: 'Citibank, N.A. Sucs. Rep. Dom.' },
  { value: '013', label: 'Banco BDI' },
  { value: '014', label: 'Banco Lopez de Haro' },
  { value: '015', label: 'Banco de Ahorro y Crédito Promérica' },
  { value: '016', label: 'Asociación Dominicana' },
  { value: '017', label: 'Banco Vimenca' },
  { value: '018', label: 'Banco Caribe' },
];
