import { friendlyLabel } from '@/src/helpers/general';

export const companyStatus = /** @type {const} */ ({
  ACTIVE: 'active',
  INITIATED: 'initiated',
  PENDING: 'pending',
  REVIEW: 'review',
  ARCHIVED: 'archived',
});

export const companyStatusLabels = {
  [companyStatus.ACTIVE]: 'Active',
  [companyStatus.INITIATED]: 'Initiated',
  [companyStatus.PENDING]: 'Pending',
  [companyStatus.REVIEW]: 'In review',
  [companyStatus.ARCHIVED]: 'Archived',
};

// Don't use Object.keys(labels) as we want to explicitly control the order of
// options
export const companyStatusOptions = [
  companyStatus.INITIATED,
  companyStatus.REVIEW,
  companyStatus.PENDING,
  companyStatus.ACTIVE,
  companyStatus.ARCHIVED,
].map((value) => ({
  value,
  label: companyStatusLabels[value],
}));

export const companyStatusBadge = {
  [companyStatus.INITIATED]: 'scheduled',
  [companyStatus.PENDING]: 'info',
  [companyStatus.REVIEW]: 'info',
  [companyStatus.ACTIVE]: 'active',
  [companyStatus.ARCHIVED]: 'error',
};

/**
 * This list is manually managed by the compliance team.
 * See: https://www.notion.so/remotecom/Improvements-to-KYB-dashboard-Archival-reasons-Reason-of-request-72c0271c89904509ad36959409e366cc
 */
export const archivedReasons = {
  COLLECTION_ISSUE: 'collection_issue',
  COMPETITOR: 'competitor',
  DUPLICATE: 'duplicate',
  END_OF_SERVICES: 'end_of_services',
  HIGH_RISK: 'high_risk',
  ML_RISK: 'ml_risk',
  PROHIBITED_RELATIONSHIP: 'prohibited_relationship',
  SUSPECTED_FRAUDULENT_ADMIN: 'suspected_fraudulent_admin',
  SUSPECTED_FRAUDULENT_COMPANY: 'suspected_fraudulent_company',
  SUSPECTED_FRAUDULENT_EMPLOYEE: 'suspected_fraudulent_employee',
  TEST_ACCOUNT: 'test_account',
  UNRESPONSIVE_CUSTOMER: 'unresponsive_customer',
  ARCHIVED_BY_CUSTOMER: 'archived_by_customer',
  //
  OTHER: 'other',
};

/**
 * Some labels may have different wording than its key.
 * This is intentional following our compliance's team requests.
 * To learn more, see https://remote-com.slack.com/archives/C01RQ0QSW2J/p1673392944077569?thread_ts=1673355695.150929&cid=C01RQ0QSW2J
 */
export const archivedReasonsLabel = {
  [archivedReasons.COLLECTION_ISSUE]: 'Collection issue',
  [archivedReasons.COMPETITOR]: 'Competitor',
  [archivedReasons.DUPLICATE]: 'Duplicate',
  [archivedReasons.END_OF_SERVICES]: 'End of services',
  [archivedReasons.HIGH_RISK]: 'High risk',
  [archivedReasons.ML_RISK]: 'ML risk',
  [archivedReasons.PROHIBITED_RELATIONSHIP]: 'Prohibited relationship',
  [archivedReasons.SUSPECTED_FRAUDULENT_ADMIN]: 'Suspected fraudulent admin',
  [archivedReasons.SUSPECTED_FRAUDULENT_COMPANY]: 'Suspected fraudulent company',
  [archivedReasons.SUSPECTED_FRAUDULENT_EMPLOYEE]: 'Suspected fraudulent employee',
  [archivedReasons.TEST_ACCOUNT]: 'Test account',
  [archivedReasons.UNRESPONSIVE_CUSTOMER]: 'Unresponsive customer',
  //
  [archivedReasons.OTHER]: 'Other',
};
export const archivedReasonsLabelsCustomer = {
  [archivedReasons.ARCHIVED_BY_CUSTOMER]: 'Archived by customer',
};

export const companyAdminAssignments = {
  BILLING_CONTACT: 'billingContact',
  LEGAL_REPRESENTATIVE: 'legalRepresentative',
};

export const companyAdminAssignmentsLabels = {
  [companyAdminAssignments.BILLING_CONTACT]: 'Billing Contact',
  [companyAdminAssignments.LEGAL_REPRESENTATIVE]: 'Authorized Representative',
};

export function getAssignmentsLabel(isLegalRepresentative, isBillingContact) {
  const assignments = [];

  if (isLegalRepresentative) {
    assignments.push(companyAdminAssignmentsLabels.legalRepresentative);
  }
  if (isBillingContact) {
    assignments.push(companyAdminAssignmentsLabels.billingContact);
  }
  return assignments.length ? assignments.join(', ') : friendlyLabel(null);
}

export const disableInviteStatuses = [429, 403, 500];

/**
 * Company signup source is a free-text string in the backend,
 * we still want to be able to reference specific know values.
 */
export const companySignupSource = {
  REMOTE_TALENT: 'remote_talent',
};

export const primaryBillingContactValues = {
  PRIMARY: 'primary',
  ADDITIONAL: 'additional',
  NONE: 'none',
};
