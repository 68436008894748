import {
  Box,
  Stack,
  toast,
  Drawer,
  DrawerLayout,
  DrawerPane,
  DrawerHeader,
  DrawerSubtitle,
  DrawerTitle,
} from '@remote-com/norma';
import { IconEdit } from '@remote-com/norma/icons/IconEdit';
import { IconFilters } from '@remote-com/norma/icons/IconFilters';
import { IconTrash } from '@remote-com/norma/icons/IconTrash';

import { CardCollapsible } from '@/src/components/CardCollapsible';
import ConfirmDeleteModal from '@/src/components/Table/Components/Toolbar/SaveFilters/ConfirmDeleteModal';
import FiltersList from '@/src/components/Table/Components/Toolbar/SaveFilters/FilterList';
import { IconBox } from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilter.styled';
import { useSaveFilterContext } from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilterContext';
import SaveFilterModal from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilterModal';
import { useModalContext } from '@/src/hooks/useModalContext';

const FilterManagerDrawer = ({ isVisible, onClose }) => {
  const { savedFilters, removeFilter, updateFilter } = useSaveFilterContext();
  const { showModal, hideModal } = useModalContext();

  const deleteFilter = (id, label) => {
    try {
      removeFilter(id, label);
      toast.success({
        title: 'Delete Filter',
        description: `${label} filter was deleted successfully.`,
      });
    } catch (e) {
      toast.error({ title: 'Delete filter', description: `Failed to delete ${label} filter` });
    }
  };

  const editFilter = (id, oldName, newName) => {
    try {
      updateFilter(id, oldName, newName);
      toast.success({
        title: 'Edit Filter',
        description: `Successfully changed filter name from ${oldName} to ${newName}`,
      });
    } catch (e) {
      toast.error({ title: 'Edit filter name', description: e.message });
    }
  };

  const handleOnDelete = (id, label) => {
    showModal({
      component: ConfirmDeleteModal,
      modalProps: {
        filterName: label,
        onDelete: () => {
          deleteFilter(id, label);
          hideModal();
        },
        onCancel: hideModal,
      },
    });
  };

  const handleOnEdit = (id, label, filters) => {
    showModal({
      component: SaveFilterModal,
      modalProps: {
        filterName: label,
        filters,
        onSave: (name) => {
          editFilter(id, label, name);
          hideModal();
        },
        onClose: hideModal,
      },
    });
  };

  return (
    <Drawer
      aria-label="Filter management"
      isVisible={isVisible}
      onClose={onClose}
      Trigger="TODO_MIGRATE_TO_COMPONENT_DONT_COPY"
      $layout="fullWidth"
    >
      <DrawerLayout>
        <DrawerHeader>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={16}>
            <IconBox>
              <IconFilters />
            </IconBox>
            <Box>
              <DrawerTitle>Manage your saved filters</DrawerTitle>
              <DrawerSubtitle>Edit or delete your saved files here.</DrawerSubtitle>
            </Box>
          </Stack>
        </DrawerHeader>
        <DrawerPane>
          {savedFilters.map(({ id, label, filters }, idx) => (
            <CardCollapsible
              key={`filter_${id}_${label}_${idx}`}
              summary={label}
              actions={[
                {
                  Icon: IconEdit,
                  label: 'Edit name',
                  onClick: () => handleOnEdit(id, label, filters),
                },
                { Icon: IconTrash, label: 'Delete', onClick: () => handleOnDelete(id, label) },
              ]}
            >
              <FiltersList filters={filters} />
            </CardCollapsible>
          ))}
        </DrawerPane>
      </DrawerLayout>
    </Drawer>
  );
};

export default FilterManagerDrawer;
