import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Extension } from '@tiptap/react';

export const OnBlurSelection = Extension.create({
  name: 'onBlurSelection',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('onBlurHighlight'),
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(transaction, decoState) {
            const { selection, meta } = transaction;

            if (!selection.empty && meta.blur) {
              const decos = [
                Decoration.inline(selection.from, selection.to, {
                  style: 'background-color: #dcdcdc;',
                }),
              ];
              return DecorationSet.create(transaction.doc, decos);
            }

            if (meta.focus) {
              return DecorationSet.empty;
            }

            return decoState;
          },
        },
        props: {
          decorations(state) {
            if (state && this.getState(state)) {
              return this.getState(state);
            }

            return null;
          },
        },
      }),
    ];
  },
});
