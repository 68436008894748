// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineCalendar = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M8 .25c.41 0 .75.34.75.75v.27c.8-.02 1.73-.02 2.82-.02h.86c1.1 0 2.02 0 2.82.02V1a.75.75 0 0 1 1.5 0v.37c.89.1 1.64.27 2.31.62a6.75 6.75 0 0 1 2.95 2.95c.4.76.57 1.61.66 2.66.08 1.03.08 2.31.08 3.97v.86c0 1.66 0 2.94-.08 3.97a6.96 6.96 0 0 1-.66 2.66 6.75 6.75 0 0 1-2.95 2.95c-.76.4-1.61.57-2.66.66-1.03.08-2.31.08-3.97.08h-.86c-1.66 0-2.94 0-3.97-.08A6.96 6.96 0 0 1 4.94 22a6.75 6.75 0 0 1-2.95-2.95 6.95 6.95 0 0 1-.66-2.66c-.08-1.03-.08-2.31-.08-3.97v-.86c0-1.66 0-2.94.08-3.97.09-1.05.27-1.9.66-2.66a6.75 6.75 0 0 1 2.95-2.95 6.5 6.5 0 0 1 2.31-.62V1c0-.41.34-.75.75-.75Zm-.75 2.63a4.8 4.8 0 0 0-3.93 2.74 5.5 5.5 0 0 0-.5 2.1 52.6 52.6 0 0 0-.07 3.88v.8c0 1.7 0 2.92.08 3.88.08.95.23 1.59.5 2.1.5 1 1.3 1.8 2.29 2.3a5.5 5.5 0 0 0 2.1.5c.96.07 2.19.07 3.88.07h.8c1.7 0 2.92 0 3.88-.08a5.5 5.5 0 0 0 2.1-.5c1-.5 1.8-1.3 2.3-2.29a5.5 5.5 0 0 0 .5-2.1c.07-.96.07-2.19.07-3.88v-.8c0-1.7 0-2.92-.08-3.88a5.5 5.5 0 0 0-.5-2.1 4.8 4.8 0 0 0-3.93-2.74V4a.75.75 0 0 1-1.49 0V2.77c-.78-.02-1.7-.02-2.85-.02h-.8c-1.14 0-2.07 0-2.85.02V4a.75.75 0 0 1-1.5 0V2.88ZM6.25 8c0-.41.34-.75.75-.75h10a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 8Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineCalendar);
export { ForwardRef as IconV2OutlineCalendar };