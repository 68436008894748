import { useMemo, useState } from 'react';

/** Normalizes the search string by removing spaces and converting to lowercase. */
const normalizeSearchComparisonValue = (value: string) => {
  return value.replaceAll(' ', '').toLocaleLowerCase();
};

type UseSearch<T> = {
  /** Function to call when the search input searchValue changes. */
  onSearch: (searchValue: string) => void;
  /** The filtered list of items based on the search searchValue. */
  searchResults: T[];
  /** The current searchValue of the search input. */
  searchValue: string;
};

export type UseSearchProps<T> = {
  /** Function to extract a string value for comparison from an item. */
  getItemSearchValue: (item: T) => string;
  /** The initial list of items to be searched. */
  items: T[];
};

/**
 * Provides search functionality over a list of items by filtering based on a provided search term.
 * It normalizes search inputs and item values for comparison, ensuring a case-insensitive and space-agnostic search.
 */
export const useSearch = <T>({ items, getItemSearchValue }: UseSearchProps<T>): UseSearch<T> => {
  const [searchValue, setSearchValue] = useState<string>('');

  const normalizedSearchQueryValue = useMemo(
    () => normalizeSearchComparisonValue(searchValue),
    [searchValue]
  );

  const searchResults = useMemo(
    () =>
      items.filter((result) => {
        const resultComparisonValue = getItemSearchValue(result);

        return normalizeSearchComparisonValue(resultComparisonValue).includes(
          normalizedSearchQueryValue
        );
      }),
    [getItemSearchValue, items, normalizedSearchQueryValue]
  );

  const onSearch = (updatedSearchValue: string) => {
    setSearchValue(updatedSearchValue);
  };

  return {
    onSearch,
    searchValue,
    searchResults,
  };
};
