// review. should just be countries with eur
export const euroCountries = [
  'AND',
  'AUT',
  'BEL',
  'CYP',
  'DEU',
  'ESP',
  'EST',
  'FIN',
  'FRA',
  'GRC',
  'HRV',
  'IRL',
  'ISL',
  'ITA',
  'LIE',
  'LTU',
  'LUX',
  'LVA',
  'MCO',
  'MLT',
  'NLD',
  'PRT',
  'SMR',
  'SVK',
  'SVN',
];

// Countries such as Montenegro and Kosovo use Euro as their local currency, but can only be paid in Euro via SWIFT due to banking restrictions
// https://wise.com/help/articles/2978075/which-countriesregions-can-i-send-eur-to-via-swift
// TODO: check Andorra and San Marino
export const euroCountriesNeedingSwift = ['MNE', 'XKX'];
