import styled, { css } from 'styled-components';

import { Stack, Box } from '../../layout';
import { sharedTransition } from '../animations';
import { focusVisible } from '../common.styled';
import { Text } from '../text';

import type { TabProps } from './Tab';

interface StyledTabProps extends TabProps {
  multiLine?: boolean;
}

export const StyledTab = styled(Text).attrs<StyledTabProps>(({ asAnchor }) => ({
  forwardedAs: asAnchor ? 'a' : 'button',
  variant: 'raw',
  p: 0,
  pb: 4,
}))<StyledTabProps>`
  ${sharedTransition('color, border-color')}
  background: none;
  border: 0;
  border-bottom: 3px solid transparent;
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  display: inline-block;
  margin-bottom: -2px;
  outline: 0;
  position: relative;
  text-decoration: none;
  ${({ theme }) => theme.typography.smMedium};
  ${({ multiLine }) =>
    !multiLine &&
    `min-width: max-content;
     white-space: nowrap;
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.grey[900]};
  }

  ${focusVisible(css`
    color: ${({ theme }) => theme.colors.grey[900]};
    &::after {
      content: '';
      position: absolute;
      left: -0.4375em;
      width: calc(100% + 0.857em);
      height: 1.857em;
      top: 50%;
      transform: translateY(-73%);
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.brand[500]};
      border-radius: ${({ theme }) => theme.borderRadius['2xs']};
      outline: 2px solid transparent;
    }
  `)}

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.grey[900]};
      border-bottom-color: ${({ theme }) => theme.colors.brand[600]} !important;
    `}
`;

export const StyledTabCount = styled(Text).attrs({
  variant: 'xs',
  color: 'grey.600',
  ml: 2,
})`
  display: inline-block;
`;

export const TabStyle = styled(Stack).attrs({
  forwardedAs: 'ul',
  m: 0,
  p: 0,
  gap: 8,
})<{ $fullWidth: boolean }>`
  flex-direction: row;
  align-items: flex-end;
  align-self: flex-end;
  justify-content: flex-start;
  flex-grow: 1;

  @media screen and (max-width: ${({ theme }) => theme.breakpointValues.medium - 1}px) {
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    gap: var(--tabs-gradientSize);
    padding-right: var(--tabs-gradientSize);

    ${({ $fullWidth }) =>
      $fullWidth &&
      css`
        overflow-x: auto;
        /** Removed during implementation of tab focus style. May need to re-add if issues arise.
         * overflow-y: hidden;
         */
      `}
  }

  ${({ $fullWidth }) =>
    $fullWidth
      ? css`
          justify-content: space-around;
        `
      : css`
          width: 100%;
          /** Removed during implementation of tab focus style. May need to re-add if issues arise.
           * overflow: hidden;
           */
        `}

  ${StyledTab} {
    ${({ $fullWidth }) => {
      return (
        $fullWidth &&
        css`
          justify-content: space-around;
          flex-grow: 1;
        `
      );
    }}
  }
`;

/* This component renders the hidden tabs so that layout calculations are correct,
regarding the currently visible items */
export const HiddenContainer = styled(Box).attrs({ display: 'flex' })`
  overflow: hidden;
  width: 0;
  visibility: hidden;
  > * {
    visibility: hidden;
  }
`;
