import PropTypes from 'prop-types';

import { supportedTypes } from '@/src/components/Form/DynamicForm/constants';
import {
  isFieldVisible,
  maybeGetHelpCenterWithDescription,
} from '@/src/components/Form/DynamicForm/helpers';
import { FieldsetField } from '@/src/components/Ui/Form/formikIntegration/FieldsetField/FieldsetField';
import { InputSpacerStyled } from '@/src/components/Ui/Form/InputSpacer';
import { isDev, isTest, pickKey } from '@/src/helpers/general';

import { DynamicFormField } from './DynamicFormField';
import { renderField } from './renderField';

function sanityCheckFieldValueInWrongCase(fields, values) {
  const isDevOrTestMocked = isDev() || isTest() || isTest() === undefined;

  if (!isDevOrTestMocked) {
    // this catches tests where the util is mocked, forcing the component
    // (and the test) to break in case the values are incorrectly mocked
    return;
  }

  fields.forEach((field) => {
    if (
      pickKey(values, field.name) !== undefined && // ...there's a value
      values[field.name] === undefined // ...but it's in a different casing type
    ) {
      console.error(
        `The form field "${field.name}" has a value with the wrong casing type (camelCase?). Read codebase for troubleshooting.`
      );
      /*
      - Hi, there! Troubleshooting tips:
      Why this error?
        This means that your field name does not match the value name,
        which will cause the prefill to fail and can cause bugs on the submit too.
        For example:
        The field name is "fav_color" (snake_case) but the value passed is camelCase { favColor: 'blue' }
        Most probably this happens because you are using JSON Schemas in some
        kind of hardcoded form field.

      - If you are NOT using JSON Schemas, something serious is wrong,
        report this on #engineering-frontend.

      - If your are calling RenderFields, make sure the "formValues"
        matches the right casing for each respective field (), eg.
          const values = {
            age: 10,
            favColor: 'purple', -> must be fav_color, use snakecaseKeys()
            food: 'pizza',
          })
          const finalValues = isApiActive ? snakecaseKeys(values) : values
        The "isApiActive" comes from { isApiActive } = useCreateHeadlessForm().

        Then, onSubmit, remember to convert the field case back to camelCase!

      - If you are using DynamicForm, something serious is wrong,
        because this case conversion should be happen automatically.
        report this on #engineering-frontend.

      More info (and example) at MR!21399
      */
    }

    if (field.type === supportedTypes.FIELDSET) {
      sanityCheckFieldValueInWrongCase(field.fields, pickKey(values, field.name));
    }
  });
}

function filterNestedRadioFields(fields) {
  const nestedRadioFields = [];

  const includeNestedField = (field) => {
    if (!field.options || typeof field.options === 'function') return field;

    const newOptions = field.options.map((option) => {
      if (option.nested_field) {
        nestedRadioFields.push(option.nested_field);
        return {
          ...option,
          nestedField: fields.find((nestedField) => nestedField.name === option.nested_field),
        };
      }
      return option;
    });

    return { ...field, options: newOptions };
  };

  const formattedFields = fields.map((field) =>
    field.type === supportedTypes.RADIO ? includeNestedField(field) : field
  );

  return formattedFields.filter((field) => !nestedRadioFields.includes(field.name));
}

const RenderFields = (props) => {
  const { fields, biggerMargin, setFieldValue, formValues, showFieldsDeprecated, ...restProps } =
    props;

  sanityCheckFieldValueInWrongCase(fields, formValues);
  const filteredFields = filterNestedRadioFields(fields);

  return filteredFields.map((field, index) => {
    if (field.type === supportedTypes.FIELDSET_FLAT) {
      return isFieldVisible(field, formValues) ? (
        <InputSpacerStyled key={index}>
          <FieldsetField
            label={field.title}
            {...field}
            description={maybeGetHelpCenterWithDescription(field)}
          >
            <RenderFields {...props} fields={field.fields} />
          </FieldsetField>
        </InputSpacerStyled>
      ) : null;
    }

    return (
      <DynamicFormField
        key={field.key || field.name || field.label || index}
        {...{
          input: field,
          index,
          values: formValues,
          showFieldsDeprecated,
          biggerMargin,
          renderField,
          setValue: setFieldValue,
          ...restProps,
        }}
      />
    );
  });
};

RenderFields.defaultProps = {
  defaultValues: {},
  biggerMargin: false,
};

RenderFields.propTypes = {
  fields: PropTypes.array.isRequired, // the fields config
  biggerMargin: PropTypes.bool,
  defaultValues: PropTypes.object, // the values from the API
  onFilesRemoved: PropTypes.func, // required if file upload
  setFieldValue: PropTypes.func.isRequired, // from Formik
  formValues: PropTypes.object.isRequired, // the current formValues from Formik
  /** When true, always renders deprecated fields, even if empty */
  showFieldsDeprecated: PropTypes.bool,
  /** Whether to enable the AI Form Assistant for the field */
  enableAIAssistant: PropTypes.bool,
  /** Configuration for the AI Form Assistant */
  aiAssistantConfig: PropTypes.shape({
    /** The title of the form */
    formTitle: PropTypes.string,
    /**  A description of the form, used to provide context to the AI */
    formContext: PropTypes.string,
    /** When set to true, the AI assistant will generate content as soon as it is opened, without asking for user input. */
    generateOnOpen: PropTypes.bool,
  }),
};

export default RenderFields;
