export function getStickyValue(column) {
  if (column.sticky === 'left' || column.sticky === 'right') {
    return column.sticky;
  }

  if (column.parent) {
    return getStickyValue(column.parent);
  }

  return null;
}

function columnIsLeftSticky(columnId, columns) {
  const index = columns.findIndex(({ id }) => id === columnId);
  const column = columns[index];

  return getStickyValue(column) === 'left';
}

function columnIsRightSticky(columnId, columns) {
  const index = columns.findIndex(({ id }) => id === columnId);
  const column = columns[index];
  return getStickyValue(column) === 'right';
}

function findHeadersSameLevel(header, headers) {
  return headers.filter((flatHeaderItem) => {
    return flatHeaderItem.depth === header.depth;
  });
}

function getStickyProps(header, instance) {
  const dataAttrs = {};

  const sticky = getStickyValue(header);

  if (sticky) {
    dataAttrs['data-sticky-td'] = true;
    const headers = findHeadersSameLevel(header, instance.flatHeaders);

    const isLeftSticky = columnIsLeftSticky(header.id, headers);

    if (isLeftSticky) {
      dataAttrs['data-sticky-left-td'] = true;
    }

    const isRightSticky = columnIsRightSticky(header.id, headers);

    if (isRightSticky) {
      dataAttrs['data-sticky-right-td'] = true;
    }
  }

  return dataAttrs;
}

export const tableStickyColumns = (hooks, hasCheckbox) => {
  hooks.getHeaderProps.push((props, { instance, column }) => {
    const nextProps = getStickyProps(column, instance, hasCheckbox);
    return [props, nextProps];
  });

  hooks.getCellProps.push((props, { instance, cell }) => {
    const nextProps = getStickyProps(cell.column, instance);
    return [props, nextProps];
  });
};
