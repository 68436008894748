import { useDelete } from '@remote-com/data-layer';
import { Avatar, Stack, Text } from '@remote-com/norma';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { Button } from '@/src/components/Button';
import { ModalActionable } from '@/src/components/Modal/ModalActionable';
import { useUserContext } from '@/src/components/UserProvider';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { useDebouncedValue } from '@/src/hooks/useDebouncedValue';
import { useModalContext } from '@/src/hooks/useModalContext';
import type { $TSFixMe } from '@/types';

function ConfirmModal({ hideModal }: { hideModal: () => void }) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { reload } = useRouter();
  const { mutate, isLoading: isLoadingMutation } = useDelete('/api/v1/impersonate/stop', {
    onSuccess: () => {
      reload();
      hideModal();
    },
    onError: (err: $TSFixMe) => {
      setErrorMessage(err.response?.data?.error || 'Failed to stop impersonation');
    },
  });

  const isLoading = useDebouncedValue(isLoadingMutation, 200);

  return (
    <ModalActionable
      open
      title="End impersonation?"
      description="Your impersonation access will end. You will need to request impersonation access again to view this account"
      confirmLabel="Stop"
      isLoading={isLoading}
      onConfirm={() => mutate({})}
      onCancel={hideModal}
      errorMessage={errorMessage}
    />
  );
}

export function ImpersonatedBanner() {
  const { user } = useUserContext();
  const { showModal } = useModalContext();
  const isFlagEnabled = useIsFeatureFlagEnabled('login_as_customer');

  if (!isFlagEnabled || !user?.realUser) return null;

  return (
    <Stack alignItems="center">
      <Stack
        position="fixed"
        top={3}
        zIndex={100}
        direction="row"
        justifyContent="center"
        alignItems="center"
        backgroundColor="blank"
        p={2}
        gap={5}
        borderRadius="89px"
        border="0.5px solid"
        borderColor="grey.300"
        boxShadow="0px 0px 24px 0px #0F141929"
      >
        <Avatar src={user?.profilePicture} size="xxs" fallback={user.email} />
        <Text color="grey.600" variant="sm" data-testid="impersonated-banner-text">
          Viewing as <strong>{user?.name}</strong>
        </Text>
        <Button
          ml={3}
          onClick={() => {
            showModal({ component: ConfirmModal });
          }}
          size="xs"
          tone="destructive"
        >
          Stop
        </Button>
      </Stack>
    </Stack>
  );
}
