/* eslint-disable react/no-this-in-sfc */
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Mark, getMarkType } from '@tiptap/react';

import { CONTRACT_MARK_TYPES } from '@/src/domains/contracts/ContractsEditor/constants';
import {
  forEachDocumentComment,
  getCommentSlugFromMark,
  getDocumentCommentPositions,
  isCommentValidForThisEditor,
} from '@/src/domains/contracts/ContractsEditor/helpers';

const COMMENT_REGULAR_COLOR = '#FFF9EB'; // Yellow 100
const COMMENT_HIGHLIGHTED_COLOR = '#FEEEC7'; // Yellow 200

const COMMENT_REGULAR_STYLE = `padding: 3px; border-radius: 4px; border-bottom: 1px solid; background-color: ${COMMENT_REGULAR_COLOR}; border-bottom-color: #FFD012;`;
// Highlighted decoration is placed inside of the comment mark, so we need to
// offset the padding using negative margin.
const HIGHLIGHTED_DECORATION_STYLE = `margin: -3px; padding: 3px; border-radius: 4px; background-color: ${COMMENT_HIGHLIGHTED_COLOR};`;

const MARK_HIGHLIGHTED_DECORATION = 'commentMarkHighlighted';

function isPositionValid({ from, to } = {}, documentNode) {
  const documentSize = documentNode.content.size;
  return Number.isInteger(from) && Number.isInteger(to) && from < documentSize && to < documentSize;
}

export const CommentText = Mark.create({
  name: CONTRACT_MARK_TYPES.COMMENT_TEXT,
  priority: 5000,

  addOptions() {
    return {
      ...this.parent?.(),
      hasStyling: false,
    };
  },

  addAttributes() {
    return {
      class: {
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }

          return {
            class: attributes.class,
            style: this.options.hasStyling ? COMMENT_REGULAR_STYLE : 'all: unset',
          };
        },
        parseHTML: (element) => element.getAttribute('class'),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['mark', HTMLAttributes, 0];
  },

  addCommands() {
    return {
      setInitialCommentMarks:
        (commentState) =>
        ({ tr, view }) => {
          const { comments } = commentState;
          const commentMark = getMarkType(this.name, view.state.schema);

          // When opening a document with existing comment marks, avoid overwriting
          // them with BE data -- in certain cases `metadata.from` and `metadata.to`
          // become stale after the document is edited:
          //
          // - if the user inserts text before comment mark, we currently do not
          //   adjust metadata
          const existingCommentMarkPositions = getDocumentCommentPositions(tr.doc);
          comments.forEach((comment) => {
            const markPosition = existingCommentMarkPositions[comment.slug] ?? comment.metadata;

            if (!isCommentValidForThisEditor(this.editor, comment)) {
              return;
            }

            if (isPositionValid(markPosition, tr.doc)) {
              tr.addMark(
                markPosition.from,
                markPosition.to,
                commentMark.create({
                  class: `comment-id-${comment.slug}`,
                })
              );
            }
          });
        },
      setCommentText:
        (attributes) =>
        ({ commands }) => {
          if (!attributes.class) {
            return null;
          }

          return commands.setMark(this.name, attributes);
        },
      removeCommentText:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
      scrollToComment:
        (comment) =>
        ({ tr, view }) => {
          if (Number.isInteger(comment.metadata?.from)) {
            const resolvedPosition = tr.doc.resolve(comment.metadata.from);
            const { node } = view.domAtPos(resolvedPosition.start());

            node.scrollIntoView({
              // Scroll the editor so that the highlighted comment mark appears
              // in the center of the viewport -- using the default `start` does
              // not work as well, because the floating toolbar sometimes covers
              // the node.
              block: 'center',
              behavior: 'smooth',
            });
          }
        },
      setHighlightedComments:
        (highlightedComments) =>
        ({ tr }) => {
          const highlightedCommentSlugs = new Set(
            highlightedComments.map((comment) => comment.slug)
          );

          const markDecorationMeta = [];
          forEachDocumentComment(tr.doc, (mark, node, pos) => {
            const commentSlug = getCommentSlugFromMark(mark);
            if (highlightedCommentSlugs.has(commentSlug)) {
              markDecorationMeta.push({
                from: pos,
                to: pos + node.nodeSize,
              });
            }
          });

          tr.setMeta(MARK_HIGHLIGHTED_DECORATION, markDecorationMeta);
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      // Plugin to imperatively set decorations through a command, adapted from
      // https://discuss.prosemirror.net/t/apply-decorations-directly-through-an-editor-method/2377/7
      new Plugin({
        key: new PluginKey('commentMarkDecoration'),
        state: {
          init() {},
          apply(tr, value) {
            const decorationMeta = tr.getMeta(MARK_HIGHLIGHTED_DECORATION);

            if (Array.isArray(decorationMeta)) {
              return DecorationSet.create(
                tr.doc,
                decorationMeta.map(({ from, to }) =>
                  Decoration.inline(from, to, { style: HIGHLIGHTED_DECORATION_STYLE })
                )
              );
            }

            return value;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
