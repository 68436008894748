// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotonePayrollOutputs = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M11.5 2A.75.75 0 0 0 10 2v1.25H8.24a4.24 4.24 0 0 0-1.34 8.26l3.1 1.03v6.71H8.75A3.25 3.25 0 0 1 5.5 16 .75.75 0 0 0 4 16a4.75 4.75 0 0 0 4.75 4.75H10V22a.75.75 0 0 0 1.5 0v-1.25h1.76a4.24 4.24 0 0 0 1.34-8.26l-3.1-1.03V4.75h1.25a.75.75 0 0 0 0-1.5H11.5V2ZM8.24 4.75H10v6.2l-2.63-.87a2.74 2.74 0 0 1 .87-5.33Zm3.26 14.5v-6.2l2.63.87a2.74 2.74 0 0 1-.87 5.33H11.5Zm6.53-15.78a.75.75 0 1 0-1.06 1.06l.72.72H14.5a.75.75 0 0 0 0 1.5h3.19l-.72.72a.75.75 0 0 0 1.06 1.06l2-2c.3-.3.3-.77 0-1.06l-2-2Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotonePayrollOutputs);
export { ForwardRef as IconV2DuotonePayrollOutputs };