import { Field, useFormikContext } from 'formik';

import { DatePicker } from '@/src/components/Ui/Form/DatePicker/DatePicker';
import {
  formatYearMonthDay,
  convertLocalTimeStringToDateObj,
  isValidDate,
} from '@/src/helpers/date';

/**
 * DatePickerField
 *
 * Renders a datepicker field for single date selection
 *
 * @export
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').DateField, 'type'> & {id?: string}} props
 * @return {JSX.Element}
 */
export const DatePickerField = ({
  'data-testid': dataTestid,
  autoComplete = 'off', // Prevents it from appearing on top of the custom datepicker
  autoFocus,
  description,
  displayLabel,
  displayErrorMessage,
  label,
  name,
  onChange,
  placeholder,
  preventJump,
  size,
  type,
  extra,
  labelPlacement,
  isClearable,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field name={name}>
      {({
        field: {
          value,
          onChange: fieldOnChange,
          onBlur, // Do NOT spread onBlur to avoid race conditions with validation. Demo at MR !22321
          ...field
        },
        meta: { error, touched },
        form: { setFieldTouched },
      }) => {
        const handleChange = (date) => {
          setFieldTouched(name, true);
          setFieldValue(field.name, date);
          if (fieldOnChange) fieldOnChange(date);
          if (onChange) onChange(date);
        };

        const handleChangeNative = (event) => {
          const newValue = event.target.value;

          if (newValue === value) {
            // Do nothing, as it's already catched by handleChange.
            // This flow happens when the user picks a date through the calendar view.
            return;
          }

          // The remaining flows happen when the user types in the input and then presses Enter.
          const valueAsDate = new Date(newValue);

          if (!isValidDate(valueAsDate)) {
            // BUG/WORKAROUND when the value is not a date (eg "foo"), react-datepicker ignores it
            // which causes Accessibility issues https://github.com/Hacker0x01/react-datepicker/issues/3789
            // We don't know yet how to force showing it. :/ So, the workaround is to catch it
            // using the native onChange event, which at least shows the error message on blur.
            handleChange(newValue);
            return;
          }

          // When the value is valid but in wrong format,
          // parse it to the right format (Eg 2020/01/01 -> 2020-01-01)
          const dateFormatted = formatYearMonthDay(valueAsDate);

          // react-datepicker also ignores dates outside minDate/maxDate ranges,
          // So, we also need to force show it because of https://github.com/Hacker0x01/react-datepicker/issues/3789
          // More details: MR !22321
          handleChange(dateFormatted);
        };

        return (
          <DatePicker
            {...field}
            description={description}
            errorText={error && touched ? error : ''}
            displayErrorMessage={displayErrorMessage}
            extra={extra}
            label={label || placeholder}
            placeholder={placeholder}
            name={name}
            touched={touched}
            autoComplete={autoComplete}
            field={field}
            props={props}
            autoFocus={autoFocus}
            data-testid={dataTestid}
            onChange={handleChange}
            onChangeNative={handleChangeNative}
            selected={(value && convertLocalTimeStringToDateObj(value)) || null}
            size={size}
            isClearable={isClearable}
            value={value}
            labelPlacement={labelPlacement}
          />
        );
      }}
    </Field>
  );
};

DatePickerField.defaultProps = {
  isLoading: false,
};
