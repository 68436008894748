import { Stack } from '@remote-com/norma';
import { useState } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';

import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import { useUserCan } from '@/src/components/UserCan';
import EmployeeContractOption from '@/src/domains/payroll/components/EmployeeContractOption';
import EmployeeContractRemove from '@/src/domains/payroll/components/EmployeeContractRemove';
import SelectorFilterBox from '@/src/domains/payroll/components/SelectorFilterBox';
import { getEmployeeContractLabel } from '@/src/domains/payroll/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { getDataWithRenamedProperty } from '@/src/helpers/api';

const getInitialFilters = (filters) =>
  Object.entries(filters).reduce((acc, [filterKey, filterValue]) => {
    return {
      ...acc,
      [filterKey]: {
        filterValue,
        isEnabled: true,
      },
    };
  }, {});

const getFiltersForQuery = (toggleFilters) =>
  Object.entries(toggleFilters).reduce((acc, [filterKey, { filterValue, isEnabled }]) => {
    const currentFilter = isEnabled ? { [filterKey]: filterValue } : {};
    return {
      ...acc,
      ...currentFilter,
    };
  }, {});

export default function EmployeeContractSelector({ filters, showFilterBox = false, ...props }) {
  const { userCan: userCanFn } = useUserCan();
  const theme = useTheme();
  const [toggleFilters, setToggleFilters] = useState(getInitialFilters(filters));

  return (
    <ThemeProvider theme={{ withSearchIcon: theme.variant === 'outline' }}>
      <Stack gap="6">
        <PaginatedSearchSelectField
          query={{
            path: '/api/v1/rivendell/contracts',
            params: {
              queryParams: {
                ...getFiltersForQuery(toggleFilters),
              },
            },
            options: {
              select: (res) => getDataWithRenamedProperty(res, 'contracts'),
              enabled: userCanFn('read', Resources.contracts),
            },
          }}
          searchQueryAlias="employeeQuery"
          name="contracts"
          label="Employee"
          placeholder="Select an employee"
          components={{
            Option: EmployeeContractOption,
            MultiValueRemove: EmployeeContractRemove,
          }}
          getOptionLabel={getEmployeeContractLabel}
          getOptionValue={({ slug }) => slug}
          data-testid="contract-select-field"
          {...props}
        />
        {showFilterBox ? (
          <SelectorFilterBox filters={toggleFilters} setFilters={setToggleFilters} />
        ) : null}
      </Stack>
    </ThemeProvider>
  );
}
