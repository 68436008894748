import { Text, toast } from '@remote-com/norma';

import { ButtonInline } from '@/src/components/Button';
import { error, isDev, isTest } from '@/src/helpers/general';

import { evaluateFeatureFlagAudiences } from './audience';
import type { FeatureFlagConfig, FeatureFlagName, FeatureFlagUserAttributes } from './config';

/**
 * @private
 *
 * This will be exported to provide type for "useIsFeatureFlagEnabled".
 * It's likely you'll not need to use this directly.
 */
interface EnabledOptions {
  userId?: string;
}

export type { EnabledOptions as FeatureFlagUserEnabledOptions };

interface Init {
  /**
   * User ID will eventually be "string" only.
   *
   * We are not asking for the ID of an actual user that is signed in.
   * This is just a string that represents the concept of a user,
   * including a guest user that is not signed in yet.
   *
   * In the near future, we expect the clients to provide a stable ID for guest users.
   * For now, however, none of our clients (or new flags) cover guest users yet.
   * Therefore, we intentionally support "null" here for those cases.
   * This discourages the clients from generating unstable IDs on the fly,
   * like `crypto.randomUUID()`, which could lead to critical bugs,
   * such as losing access or data after a refresh due to a flag changes.
   *
   * See: https://linear.app/remote/issue/DEVXP-1774
   */
  userId: string | null;
  attributes?: FeatureFlagUserAttributes;
  config: FeatureFlagConfig;
}

// Track the last path where an error was reported
let lastErrorReportedPath: string | null = null;

/**
 * Report missing user attributes when evaluating feature flag audiences.
 * This is done once per path so not report this error multiple times on the same path.
 */
function reportMissingUserAttributes(name: FeatureFlagName) {
  const currentPath = typeof window !== 'undefined' ? window.location.pathname : 'server';

  if (lastErrorReportedPath !== currentPath) {
    error(`Feature flag '${name}' has audiences but no attributes were provided`);
    lastErrorReportedPath = currentPath;
  }
}

/**
 * @private
 *
 * This class answers whether a specific feature flag is enabled for a specific user.
 * It should be initialized once and used across the app.
 * It takes a user-agnostic config and calculate user-specific decisions, thus the name.
 *
 * It's likely most developers will not need to use this directly.
 * Instead, use the "useIsFeatureFlagEnabled" hook that uses this class internally.
 * See "./context" to learn more.
 */
export class FeatureFlagUser implements Init {
  userId: string | null;

  attributes?: FeatureFlagUserAttributes;

  config: FeatureFlagConfig;

  constructor(init: Init) {
    this.userId = init.userId;
    this.attributes = init.attributes;
    this.config = init.config;
  }

  isFlagEnabled(name: FeatureFlagName, options?: EnabledOptions): boolean {
    // Custom user id is available as a future-proofing feature,
    // especially for the type and schema stability.
    // In practice, however, it should not be used yet.
    // See: https://www.notion.so/Feature-Flags-Behavior-ac83b475f10a415f8ba4ae65ad24398e?d=5fcace6cb5da41be9ba289afb7b13509&pvs=4#671f4da9e32b47ee96bc7dbc743362ed
    if (options?.userId !== undefined) {
      const msg = [
        'Custom user ID is not supported yet.',
        'Providing it now will lead to unexpected results in the future.',
      ].join(' ');
      error(msg);
    }

    const feature = this.config[name];

    // Typescript check should prevent unknown flags to be used, but we still have JS code in the project,
    // so we should trigger a dev warning if this happens, for engineers to fix it if needed.
    if (feature === undefined) {
      // On dev mode, we fetch ALL feature flags from the server, however for prod environments, we only fetch
      // the enabled ones.
      if (isDev() || isTest()) {
        const docsLink =
          'https://www.notion.so/remotecom/Full-stack-Feature-Flags-4594be20558a4abf8e38f2a59c3b4c27?pvs=4#2b39ee4e92bf461e8107a91e3041e011';

        toast.error(
          {
            title: `Unknown feature flag '${name}'`,
            description: (
              <Text>
                Please visit{' '}
                <ButtonInline asTag="a" target="_blank" href={docsLink}>
                  our docs
                </ButtonInline>{' '}
                for troubleshooting this error.
              </Text>
            ),
          },
          { id: `unknown-feature-flag-${name}` }
        );
        console.error(
          `Unknown feature flag: '${name}'.
Please visit ${docsLink} for troubleshooting this error.`
        );
      }
      return false;
    }

    if (!feature.enabled) {
      return false;
    }
    // If the flag has audiences but no attributes are present, capture the error once per path
    const hasAudiences = feature.audiences && feature.audiences.length > 0;
    if (hasAudiences && !this.attributes) {
      reportMissingUserAttributes(name);
      return false;
    }

    // Evaluating audiences for flag:
    const enabledByAudiences = evaluateFeatureFlagAudiences({
      featureFlagName: feature.name,
      audiences: feature.audiences,
      attributes: this.attributes,
    });

    // If the flag is not enabled by audiences, we should return false
    if (!enabledByAudiences) {
      return false;
    }

    // If the flag is enabled by audiences, we should return true
    return true;
  }
}
