// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneFileScan = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M13.25 1.25v2.7c0 1.68 0 2.52.33 3.16a3 3 0 0 0 1.3 1.31c.65.33 1.49.33 3.17.33h2.7V12H3.25V9.25c0-2.8 0-4.2.54-5.27a5 5 0 0 1 2.19-2.19c1.07-.54 2.47-.54 5.27-.54h2Z" /><path fillRule="evenodd" clipRule="evenodd" d="M14.75 4.05v-2.8c.36 0 .7.14.96.4l4.64 4.64c.26.26.4.6.4.96h-2.8c-1.12 0-1.68 0-2.1-.22a2 2 0 0 1-.88-.87c-.22-.43-.22-.99-.22-2.11ZM2 11.25a.75.75 0 0 0 0 1.5h20a.75.75 0 0 0 0-1.5H2ZM4.75 16a.75.75 0 0 0-1.5 0v.54c0 .43 0 .69.02.91a5.75 5.75 0 0 0 5.28 5.28c.22.02.48.02.91.02h5.08c.43 0 .69 0 .91-.02a5.75 5.75 0 0 0 5.28-5.28c.02-.22.02-.48.02-.9V16a.75.75 0 0 0-1.5 0v.5l-.01.83a4.25 4.25 0 0 1-3.9 3.9c-.17.02-.36.02-.84.02h-5l-.83-.01a4.25 4.25 0 0 1-3.9-3.9c-.02-.17-.02-.36-.02-.84V16Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneFileScan);
export { ForwardRef as IconV2DuotoneFileScan };