import { Spinner, useFormGroupContext } from '@remote-com/norma';
import { IconV2SolidArrowUp } from '@remote-com/norma/icons/IconV2SolidArrowUp';
import { useEffect, useRef, useState } from 'react';
import type { ChangeEvent } from 'react';

import { ButtonIcon } from '@/src/components/Button';
import {
  StyledInputWrapper,
  StyledInput,
} from '@/src/components/Ui/Form/AIFormAssistant/AIFormAssistant.styled';

type Props = {
  isVisible: boolean;
  isLoading: boolean;
  onSubmit: (userInput: string) => void;
};

const PrimaryColorSpinner = (props: Record<string, unknown>): JSX.Element => {
  return <Spinner {...props} color="primary" />;
};

const AIFormAssistantInput = ({ isVisible, isLoading, onSubmit }: Props): JSX.Element | null => {
  const { value: originalValue } = useFormGroupContext();

  const [value, setValue] = useState(originalValue || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setValue(originalValue || '');
  }, [originalValue]);

  useEffect(() => {
    // allow the textarea to grow with the content
    if (textareaRef.current) {
      // Reset textarea height to 1 line to shrink it back
      textareaRef.current.style.height = '1lh';
      // Set the height based on the scroll height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value, isVisible]);

  const handleChange = (event: ChangeEvent) => {
    setValue((event as ChangeEvent<HTMLTextAreaElement>).target.value);
  };

  const handleSubmit = () => onSubmit(value);

  if (!isVisible) {
    return null;
  }

  return (
    <StyledInputWrapper $isLoading={isLoading}>
      <StyledInput
        placeholder="Ask Remote AI anything..."
        name="prompt"
        value={value}
        onChange={handleChange}
        rows={1}
        ref={textareaRef}
        disabled={isLoading}
        data-testid="ai-form-assistant-input"
      />
      <ButtonIcon
        Icon={isLoading ? PrimaryColorSpinner : IconV2SolidArrowUp}
        label="Submit"
        size="xs"
        variant="solid"
        disabled={isLoading}
        onClick={handleSubmit}
      />
    </StyledInputWrapper>
  );
};

export default AIFormAssistantInput;
