import { useState, useCallback, useEffect } from 'react';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';
import { useListTableViews } from '@/src/components/Table/hooks/useListTableViews';
import type { SortBy } from '@/src/components/Table/types';
import { useUserContext } from '@/src/components/UserProvider';
import useLocalStorage from '@/src/hooks/useLocalStorage';

import type { useColumnsState } from './useColumnsState';
import { useTableViewStateDiff } from './useTableViewStateDiff';

export const LAST_ACTIVE_VIEWS_KEY = 'last-active-table-views';

export type ListTableViewsParams = {
  persistenceKey: string;
  columnsStateProps: ReturnType<typeof useColumnsState>;
  applyView: (view: TableViewsApi.TableView) => void;
  setAllFilters: (filters: TableViewsApi.TransformedFilter[]) => void;
  setSortBy: (sortBy: SortBy[]) => void;
  tableStateFilters: TableViewsApi.TransformedFilter[];
  tableStateSortBy: SortBy[];
  viewApplyInProgress: React.MutableRefObject<TableViewsApi.TableView | null>;
  isTableViewsEnabledExperimental: boolean;
};

type LastActiveViews = {
  [persistenceKey: string]: TableViewsApi.TableView;
};

// This is just hardcoded for now but could be moved to the backend as well
// To allow configurable default views for tables
export const DEFAULT_VIEW = {
  name: 'Default view',
  slug: 'default',
  persistenceKey: 'generic-table', // This is not used for anything
  config: {
    columns: {
      visibility: {},
      order: [],
    },
    filters: [],
    sortBy: [],
  },
};

export function useTableViews({
  persistenceKey,
  columnsStateProps,
  setAllFilters,
  setSortBy,
  tableStateFilters,
  tableStateSortBy = [],
  isTableViewsEnabledExperimental,
}: ListTableViewsParams) {
  const { user } = useUserContext();
  const { data: { data: views = [] } = {} } = useListTableViews({
    queryParams: { persistenceKey },
    enabled: !!isTableViewsEnabledExperimental,
  });

  // We include the user slug to avoid conflicts between users
  const [lastActiveViews, setLastActiveViews] = useLocalStorage<LastActiveViews>(
    `${LAST_ACTIVE_VIEWS_KEY}${`-${user?.slug}` || ''}`,
    {}
  );

  const setLastActiveView = useCallback(
    (updatedView: TableViewsApi.TableView) => {
      // We dont want to store the default view in local storage
      // Unless the user has added custom views to the table
      if (updatedView.slug === 'default' && views.length === 0) {
        return;
      }

      const updatedViews = {
        ...lastActiveViews,
        [persistenceKey]: updatedView,
      };

      setLastActiveViews(updatedViews);
    },
    [setLastActiveViews, persistenceKey, lastActiveViews, views]
  );

  const [isInitialViewApplied, setIsInitialViewApplied] = useState(false);
  const [viewBeingApplied, setViewBeingApplied] = useState<TableViewsApi.TableView | null>(null);

  const [activeView, setActiveViewInternal] = useState<TableViewsApi.TableView>(() => {
    const lastActiveView = lastActiveViews?.[persistenceKey];
    if (lastActiveView) return lastActiveView;
    return DEFAULT_VIEW;
  });

  const {
    order: tableStateOrder,
    visibility: tableStateVisibility,
    viewColumns,
    applyConfiguration,
    resetColumnConfiguration,
  } = columnsStateProps;

  // Used to keep the last active view in sync with the active view
  const setActiveView = useCallback(
    (view: TableViewsApi.TableView) => {
      setActiveViewInternal(view);
      setLastActiveView(view);
    },
    [setLastActiveView]
  );

  const applyView = useCallback(
    (view: TableViewsApi.TableView) => {
      const { columns, sortBy } = view.config || {};
      if (viewBeingApplied) return;

      // If the view is the default view, we need to reset to default view state
      if (view.slug === DEFAULT_VIEW.slug) {
        resetColumnConfiguration();
        setAllFilters(DEFAULT_VIEW.config.filters);
        setSortBy(DEFAULT_VIEW.config.sortBy);
        setActiveView(view);
        return;
      }

      // This will trigger the useEffect that will apply the filters.
      setViewBeingApplied(view);

      // Apply the column configuration
      if (columns) {
        applyConfiguration({ name: view.name, customizations: { ...columns } });
      }

      if (sortBy) {
        setSortBy(sortBy);
      }
    },
    [
      applyConfiguration,
      resetColumnConfiguration,
      setAllFilters,
      setSortBy,
      setActiveView,
      viewBeingApplied,
      setViewBeingApplied,
    ]
  );

  // This is used to apply the last active view
  useEffect(() => {
    if (!isTableViewsEnabledExperimental || isInitialViewApplied) {
      return;
    }

    setIsInitialViewApplied(true);

    // No need to apply the default view
    if (activeView.slug === DEFAULT_VIEW.slug) return;

    applyView(activeView);
  }, [views, applyView, isInitialViewApplied, isTableViewsEnabledExperimental, activeView]);

  // We're applying the filters here because we need to ensure that the
  // columns are applied before we can apply filters on them.
  useEffect(() => {
    // If we're not applying a view we dont need to do anything.
    if (!viewBeingApplied) return;

    const { filters: filtersToApply = [] } = viewBeingApplied.config || {};

    // If the view doesn't have any filters, we make sure to reset the filter state
    if (filtersToApply.length === 0) {
      setAllFilters([]);
    }

    const filtersWithViewColumns = filtersToApply.filter((filter) =>
      viewColumns.some((column) => column.id === filter.id)
    );

    if (filtersWithViewColumns.length > 0) {
      setAllFilters(filtersWithViewColumns);
    }

    if (filtersWithViewColumns.length === 0 && filtersToApply.length > 0) {
      setAllFilters([]);
    }

    // View apply is complete and we can set the active view and finish the view apply process.
    setActiveView(viewBeingApplied);
    setViewBeingApplied(null);
  }, [activeView, setActiveView, viewColumns, setAllFilters, viewBeingApplied]);

  const { numberOfTableStateChanges } = useTableViewStateDiff({
    view: activeView,
    tableStateVisibility,
    tableStateFilters,
    tableStateOrder,
    tableStateSortBy,
    isTableViewsEnabledExperimental,
  });

  const isLoading = isTableViewsEnabledExperimental && !isInitialViewApplied;

  const shouldShowFloatingActions =
    isTableViewsEnabledExperimental &&
    isInitialViewApplied &&
    !viewBeingApplied &&
    numberOfTableStateChanges > 0;

  return {
    views,
    activeView,
    setActiveView,
    applyView,
    tableStateOrder,
    tableStateVisibility,
    tableStateFilters,
    tableStateSortBy,
    isTableViewsEnabledExperimental,
    persistenceKey,
    isLoading,
    shouldShowFloatingActions,
    numberOfTableStateChanges,
  };
}
