import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

import { CommentText } from '@/src/domains/contracts/ContractsEditor/nodes/CommentText';
import { ConditionalContent } from '@/src/domains/contracts/ContractsEditor/nodes/ConditionalContent/ConditionalContent';
import {
  BlockConditionals,
  InlineConditionals,
  ListItemConditionals,
} from '@/src/domains/contracts/ContractsEditor/nodes/Conditionals/Conditional';
import { CustomBulletList } from '@/src/domains/contracts/ContractsEditor/nodes/CustomBulletList';
import { CustomOrderedList } from '@/src/domains/contracts/ContractsEditor/nodes/CustomOrderedList';
import { ManuallyEnteredField } from '@/src/domains/contracts/ContractsEditor/nodes/ManuallyEnteredField/ManuallyEnteredField';
import { OnBlurSelection } from '@/src/domains/contracts/ContractsEditor/nodes/OnBlurSelection';
import { ResizableImage } from '@/src/domains/contracts/ContractsEditor/nodes/ResizableImage/ResizableImageNode';
import { SignatureBlockField } from '@/src/domains/contracts/ContractsEditor/nodes/SignatureBlockField/SignatureBlockField';
import { SignaturesWrapper } from '@/src/domains/contracts/ContractsEditor/nodes/SignatureBlockField/SignaturesWrapper';
import { SignatureField } from '@/src/domains/contracts/ContractsEditor/nodes/SignatureField/SignatureField';
import { SmartField } from '@/src/domains/contracts/ContractsEditor/nodes/SmartField/SmartFieldNode';
import { CONTRACT_EDITOR_TYPE, EDITOR_FEATURES } from '@/src/domains/contracts/shared/constants';

// TODO: Remove hardcoded image size once we get a better way of resizing

export const baseEditorExtensions = [
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3, 4, 5, 6],
    },
    orderedList: false,
    bulletList: false,
    codeBlock: false,
    blockquote: false,
  }),
  CustomOrderedList,
  CustomBulletList,
  Underline,
  Placeholder.configure({
    placeholder: 'Start typing here or paste content...',
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  OnBlurSelection,
];

export const getGlobalEditorExtensions = ({
  editorType,
  enabledFeatures,
  signatories,
  handleOnClickCompanySignature,
  showUnavailableSmartfieldsAsPending,
  company,
}) => {
  const isTemplate = editorType === CONTRACT_EDITOR_TYPE.TEMPLATE;

  return [
    ...baseEditorExtensions,
    // v1 Conditionals
    ConditionalContent,
    // v2 Conditionals
    InlineConditionals.configure({
      editableConditionals: isTemplate,
    }),
    BlockConditionals.configure({
      editableConditionals: isTemplate,
    }),
    ListItemConditionals.configure({
      editableConditionals: isTemplate,
    }),
    ManuallyEnteredField.configure({
      hasEditOnClick: true,
      hasPasteRule: isTemplate,
      hasValueRendered: !isTemplate,
    }),
    SmartField.configure({
      editableSmartFields: isTemplate,
      unavailableAsPlaceholder: isTemplate,
      unavailableAsPending: showUnavailableSmartfieldsAsPending,
      isTemplate,
    }),
    SignatureField.configure({
      signatories,
      handleOnClickCompanySignature,
      company,
    }),
    SignaturesWrapper.configure({
      company,
    }),
    SignatureBlockField,
    CommentText.configure({
      hasStyling: enabledFeatures[EDITOR_FEATURES.COMMENTS],
    }),
    ResizableImage.configure({
      editable: true,
      allowBase64: true,
      inline: true,
      HTMLAttributes: {
        class: 'editor-inserted-image',
        width: '200',
      },
    }),
  ];
};

export const getViewerEditorExtensions = ({
  enabledFeatures,
  signatories,
  handleOnClickRecipientSignature,
  company,
  recipientSignatureValue,
}) => [
  ...baseEditorExtensions,
  // v2 Conditionals
  BlockConditionals.configure({
    isViewOnly: true,
  }),
  InlineConditionals.configure({ isViewOnly: true }),
  ListItemConditionals.configure({ isViewOnly: true }),
  // v1 Conditionals
  ConditionalContent.configure({
    hasStyling: false,
  }),
  ManuallyEnteredField.configure({
    hasStyling: false,
    hasValueRendered: true,
  }),
  SmartField.configure({
    hasStyling: false,
    hideUnavailableSmartFields: true,
  }),
  SignatureField.configure({
    signatories,
    handleOnClickRecipientSignature,
    company,
    recipientSignatureValue,
  }),
  SignaturesWrapper.configure({
    company,
  }),
  SignatureBlockField,
  CommentText.configure({
    hasStyling: enabledFeatures[EDITOR_FEATURES.COMMENTS],
  }),
  ResizableImage.configure({
    editable: false,
    allowBase64: true,
    inline: true,
    HTMLAttributes: {
      class: 'editor-inserted-image',
      width: '200',
    },
  }),
];
