import { IconV2DuotoneTimes } from '../../icons/build/IconV2DuotoneTimes';
import type { ButtonProps } from '../button';

import { ChipContainer, ChipContent, CloseButton } from './Chip.styled';

type ChipSize = Extract<ButtonProps['size'], 'xs' | 'sm'>;

type CloseButtonProps = {
  /* Event handlers invoked when the close button is clicked. */
  onClick?: (e: Event) => void;
  onMouseDown?: (e: Event) => void;
  onTouchEnd?: (e: Event) => void;
};

export type ChipProps = {
  /* The text content of the chip. */
  children: React.ReactNode;
  /* Changes the size of the chip. */
  size?: ChipSize;
  removeProps?: CloseButtonProps;
};

export const Chip = ({ children, removeProps: closeButtonProps, size = 'xs' }: ChipProps) => {
  return (
    <ChipContainer size={size}>
      <ChipContent>{children}</ChipContent>
      <CloseButton aria-label="Close" $size={size} {...closeButtonProps}>
        <IconV2DuotoneTimes />
      </CloseButton>
    </ChipContainer>
  );
};
