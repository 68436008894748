import { ConfigurationList } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/ConfigurationList';
import { SavedConfigurationsListItem } from '@/src/components/Table/Components/ColumnsConfigurationDrawer/SavedConfigurationsListItem';
import {
  Section,
  SectionHeader,
  SectionTitle,
} from '@/src/components/Table/Components/ColumnsConfigurationDrawer/Section';
import type { AnyColumnsConfiguration } from '@/src/components/Table/hooks/useColumnsState/types';

const messages = {
  sectionTitle: 'Saved tables',
};

type SavedTablesViewProps = {
  activeConfigurationName: string | null;
  onDeleteConfiguration: (configurationName: string) => void;
  onToggleConfiguration: (configuration: AnyColumnsConfiguration) => void;
  savedConfigurations: AnyColumnsConfiguration[];
};

export const SavedTablesView = ({
  activeConfigurationName,
  onDeleteConfiguration,
  onToggleConfiguration,
  savedConfigurations,
}: SavedTablesViewProps) => {
  return (
    <Section>
      <SectionHeader>
        <SectionTitle>{messages.sectionTitle}</SectionTitle>
      </SectionHeader>
      <ConfigurationList>
        {savedConfigurations?.map((savedConfiguration) => (
          <SavedConfigurationsListItem
            key={savedConfiguration.name}
            activeConfiguration={activeConfigurationName}
            configuration={savedConfiguration}
            onDeleteConfiguration={onDeleteConfiguration}
            onToggleConfiguration={onToggleConfiguration}
          />
        ))}
      </ConfigurationList>
    </Section>
  );
};
