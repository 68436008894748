// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2SolidArrowUpRight = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M17.66 17a.75.75 0 0 1-.75-.76V8.15L6.87 18.2a.75.75 0 1 1-1.06-1.06L15.85 7.09h-8.1a.75.75 0 0 1 0-1.5h9.9a.75.75 0 0 1 .76.75v9.9c0 .42-.34.75-.75.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2SolidArrowUpRight);
export { ForwardRef as IconV2SolidArrowUpRight };