export const IDN_BANK_NUMBERS = [
  {
    value: '890016',
    label: 'BCA SYARIAH',
  },
  {
    value: '1130212',
    label: 'BPD Jawa Tengah',
  },
  {
    value: '1239922',
    label: 'BPD Kalbar Kp Syariah Pontianak',
  },
  {
    value: '1220012',
    label: 'BPD Kalsel BPD Kalsel Cabang Utama',
  },
  {
    value: '1209923',
    label: 'BPD Sumselbabel Kc Syariah Palemban',
  },
  {
    value: '4220051',
    label: 'BRI Syariah',
  },
  {
    value: '2009928',
    label: 'BTN Kc Syariah Jakarta',
  },
  {
    value: '610306',
    label: 'Bank ANZ Indonesia',
  },
  {
    value: '1169924',
    label: 'Bank Aceh',
  },
  {
    value: '9450305',
    label: 'Bank Agris',
  },
  {
    value: '4940014',
    label: 'Bank Agroniaga',
  },
  {
    value: '4660019',
    label: 'Bank Andara',
  },
  {
    value: '880013',
    label: 'Bank Antar Daerah',
  },
  {
    value: '370028',
    label: 'Bank Artha Graha International',
  },
  {
    value: '570307',
    label: 'Bank BNP Paribas',
  },
  {
    value: '1290013',
    label: 'Bank BPD Bali',
  },
  {
    value: '5470017',
    label: 'Bank BTPN Syariah',
  },
  {
    value: '1330012',
    label: 'Bank Bengkulu',
  },
  {
    value: '4590011',
    label: 'Bank Bisnis Internasional',
  },
  {
    value: '4410010',
    label: 'Bank Bukopin',
  },
  {
    value: '760010',
    label: 'Bank Bumi Arta',
  },
  {
    value: '220026',
    label: 'Bank CIMB Niaga',
  },
  {
    value: '9490307',
    label: 'Bank CTBC Indonesia',
  },
  {
    value: '540308',
    label: 'Bank Capital',
  },
  {
    value: '140397',
    label: 'Bank Central Asia (BCA)',
  },
  {
    value: '5590010',
    label: 'Bank Centratama Nasional (CNB)',
  },
  {
    value: '9500307',
    label: 'Bank Commonwealth',
  },
  {
    value: '460307',
    label: 'Bank DBS Indonesia',
  },
  {
    value: '1110012',
    label: 'Bank DKI',
  },
  {
    value: '111274',
    label: 'Bank Danamon Indonesia',
  },
  {
    value: '1119916',
    label: 'Bank Dki Kc Syariah Wahid Hasyim',
  },
  {
    value: '5620029',
    label: 'Bank Fama International',
  },
  {
    value: '1610017',
    label: 'Bank Ganesha',
  },
  {
    value: '5670011',
    label: 'Bank Harda Internasional',
  },
  {
    value: '1640058',
    label: 'Bank ICBC Indonesia',
  },
  {
    value: '5130014',
    label: 'Bank Ina Perdana',
  },
  {
    value: '5550018',
    label: 'Bank Index Selindo',
  },
  {
    value: '950011',
    label: 'Bank J Trust Indonesia (Bank Mutiara)',
  },
  {
    value: '1100019',
    label: 'Bank Jabar (BJB)',
  },
  {
    value: '4250018',
    label: 'Bank Jabar Banten Syariah',
  },
  {
    value: '1150014',
    label: 'Bank Jambi',
  },
  {
    value: '4720014',
    label: 'Bank Jasa Jakarta',
  },
  {
    value: '1130348',
    label: 'Bank Jateng',
  },
  {
    value: '9480303',
    label: 'Bank Jatim',
  },
  {
    value: '4840017',
    label: 'Bank KEB Hana Indonesia',
  },
  {
    value: '1230015',
    label: 'Bank Kalimantan Barat (Kalbar)',
  },
  {
    value: '1229929',
    label: 'Bank Kalimantan Selatan',
  },
  {
    value: '1250011',
    label: 'Bank Kalimantan Tengah (Kalteng)',
  },
  {
    value: '1240018',
    label: 'Bank Kalimantan Timur (Kaltim)',
  },
  {
    value: '5350014',
    label: 'Bank Kesejahteraan Ekonomi',
  },
  {
    value: '1210051',
    label: 'Bank Lampung',
  },
  {
    value: '4850010',
    label: 'Bank MNC Internasional',
  },
  {
    value: '1310016',
    label: 'Bank Maluku',
  },
  {
    value: '80017',
    label: 'Bank Mandiri',
  },
  {
    value: '5640012',
    label: 'Bank Mandiri Taspen',
  },
  {
    value: '1570018',
    label: 'Bank Maspion',
  },
  {
    value: '970017',
    label: 'Bank Mayapada International',
  },
  {
    value: '160131',
    label: 'Bank Maybank Indonesia',
  },
  {
    value: '9470302',
    label: 'Bank Maybank Syariah Indonesia',
  },
  {
    value: '5530012',
    label: 'Bank Mayora',
  },
  {
    value: '4260448',
    label: 'Bank Mega',
  },
  {
    value: '1510010',
    label: 'Bank Mestika Dharma',
  },
  {
    value: '1470011',
    label: 'Bank Muamalat',
  },
  {
    value: '5480010',
    label: 'Bank Multiarta Sentosa',
  },
  {
    value: '1180013',
    label: 'Bank Nagari',
  },
  {
    value: '5030017',
    label: 'Bank Nationalnobu',
  },
  {
    value: '90010',
    label: 'Bank Negara Indonesia (BNI)',
  },
  {
    value: '1450015',
    label: 'Bank Nusantara Parahyangan (BNP)',
  },
  {
    value: '280024',
    label: 'Bank OCBC NISP',
  },
  {
    value: '190017',
    label: 'Bank Panin',
  },
  {
    value: '5170016',
    label: 'Bank Panin Syariah',
  },
  {
    value: '1320019',
    label: 'Bank Papua',
  },
  {
    value: '1190016',
    label: 'Bank Pembangunan Daerah Riau (BPD)',
  },
  {
    value: '130307',
    label: 'Bank Permata',
  },
  {
    value: '5580017',
    label: 'Bank Pundi Indonesia',
  },
  {
    value: '1670015',
    label: 'Bank QNB Indonesia',
  },
  {
    value: '20307',
    label: 'Bank Rakyat Indonesia (BRI)',
  },
  {
    value: '470300',
    label: 'Bank Resona Perdania',
  },
  {
    value: '5010011',
    label: 'Bank Royal',
  },
  {
    value: '4980016',
    label: 'Bank SBI Indonesia',
  },
  {
    value: '5230011',
    label: 'Bank Sahabat Sampoerna',
  },
  {
    value: '1520013',
    label: 'Bank Shinhan Indonesia',
  },
  {
    value: '1530016',
    label: 'Bank Sinarmas',
  },
  {
    value: '500306',
    label: 'Bank Standard Chartered Indonesia',
  },
  {
    value: '1260027',
    label: 'Bank Sulselbar',
  },
  {
    value: '1340015',
    label: 'Bank Sulteng',
  },
  {
    value: '1350018',
    label: 'Bank Sultra',
  },
  {
    value: '1270017',
    label: 'Bank SulutGo',
  },
  {
    value: '450304',
    label: 'Bank Sumitomo Mitsui Indonesia',
  },
  {
    value: '1200016',
    label: 'Bank Sumsel Babel',
  },
  {
    value: '1170010',
    label: 'Bank Sumut',
  },
  {
    value: '1179927',
    label: 'Bank Sumut Syariah',
  },
  {
    value: '5210031',
    label: 'Bank Syariah Bukopin',
  },
  {
    value: '4510017',
    label: 'Bank Syariah Mandiri',
  },
  {
    value: '5060016',
    label: 'Bank Syariah Mega',
  },
  {
    value: '2000150',
    label: 'Bank Tabungan Negara (BTN)',
  },
  {
    value: '2130101',
    label: 'Bank Tabungan Pensiunan Nasional (BTPN)',
  },
  {
    value: '230090',
    label: 'Bank UOB Indonesia',
  },
  {
    value: '5660018',
    label: 'Bank Victoria Internasional',
  },
  {
    value: '4050072',
    label: 'Bank Victoria Syariah',
  },
  {
    value: '360300',
    label: 'Bank Windu Kentjana (China Construction Bank)',
  },
  {
    value: '680307',
    label: 'Bank Woori Indonesia',
  },
  {
    value: '4900012',
    label: 'Bank Yudha Bhakti',
  },
  {
    value: '330301',
    label: 'Bank of America Merill-Lynch (BOA)',
  },
  {
    value: '690300',
    label: 'Bank of China (BOC)',
  },
  {
    value: '1460047',
    label: 'Bank of India Indonesia',
  },
  {
    value: '420305',
    label: 'Bank of Tokyo Mitsubishi UFJ (MUFG)',
  },
  {
    value: '169925',
    label: 'Bii kc syariah thamrin',
  },
  {
    value: '310305',
    label: 'Citibank',
  },
  {
    value: '1120015',
    label: 'Daerah Istimewa Yogyakarta (DIY)',
  },
  {
    value: '670304',
    label: 'Deutsche Bank Jakarta',
  },
  {
    value: '870010',
    label: 'Hongkong and Shanghai Bank Corporation (HSBC)',
  },
  {
    value: '320308',
    label: 'JP Morgan Chase Bank',
  },
  {
    value: '480303',
    label: 'Mizuho Bank',
  },
  {
    value: '1280010',
    label: 'Nusa Tenggara Barat (NTB)',
  },
  {
    value: '1300013',
    label: 'Nusa Tenggara Timur (NTT)',
  },
  {
    value: '5420012',
    label: 'PT Bank Jago Tbk',
  },
  {
    value: '5200012',
    label: 'Prima Master Bank',
  },
  {
    value: '520302',
    label: 'Royal Bank of Scotland (Jakarta)',
  },
  {
    value: '4270043',
    label: 'Syariah Bank Negara Indonesia (BNI Syariah)',
  },
];

export const IDN_BIC_NAME_COMBO = [
  { value: 'ABALIDBS', label: 'PT. BPD BALI' },
  { value: 'AGTBIDJA', label: 'PT. BANK RAKYAT INDONESIA AGRONIAGA' },
  { value: 'ANZBIDJX', label: 'PT. BANK ANZ INDONESIA' },
  { value: 'ARFAIDJ1', label: 'PT. BANK PANIN DUBAI SYARIAH' },
  { value: 'ARTGIDJA', label: 'PT. BANK ARTHA GRAHA INTERNASIONAL' },
  { value: 'ATJSIDJ2', label: 'PT. ARTAJASA PEMBAYARAN ELEKTRONIK' },
  { value: 'ATOSIDJ1', label: 'PT. BANK JAGO TBK' },
  { value: 'AWANIDJA', label: 'PT. BANK QNB INDONESIA' },
  { value: 'BBAIIDJA', label: 'PT. BANK BUMI ARTA' },
  { value: 'BBBAIDJA', label: 'PT. BANK PERMATA' },
  { value: 'BBIJIDJA', label: 'PT. BANK UOB INDONESIA' },
  { value: 'BBUKIDJA', label: 'PT. BANK KB BUKOPIN' },
  { value: 'BCIAIDJA', label: 'PT. BANK CAPITAL INDONESIA' },
  { value: 'BDINIDJA', label: 'PT. BANK DANAMON INDONESIA' },
  { value: 'BDIPIDJ1', label: 'PT. BANK SAHABAT SAMPOERNA' },
  { value: 'BDKIIDJ1', label: 'PT. BPD DKI' },
  { value: 'BICNIDJA', label: 'PT. BANK COMMONWEALTH' },
  { value: 'BIDXIDJA', label: 'PT. BANK INDEX SELINDO' },
  { value: 'BKCHIDJA', label: 'BANK OF CHINA (HONG KONG) LIMITED CABANG JAKARTA' },
  { value: 'BKKBIDJA', label: 'BANGKOK BANK PUBLIC, CO. LTD.' },
  { value: 'BMRIIDJA', label: 'PT. BANK MANDIRI (PERSERO)' },
  { value: 'BMSEIDJA', label: 'PT. BANK MULTI ARTA SENTOSA' },
  { value: 'BNIAIDJA', label: 'PT. BANK CIMB NIAGA' },
  { value: 'BNINIDJA', label: 'PT. BANK NEGARA INDONESIA (PERSERO)' },
  { value: 'BNPAIDJA', label: 'PT. BANK BNP PARIBAS INDONESIA' },
  { value: 'BOFAID2X', label: 'BANK OF AMERICA, N.A.' },
  { value: 'BOTKIDJX', label: 'MUFG BANK, LTD.' },
  { value: 'BPIAIDJA', label: 'PT. BANK RESONA PERDANIA' },
  { value: 'BRINIDJA', label: 'PT. BANK RAKYAT INDONESIA (PERSERO)' },
  { value: 'BSDRIDJA', label: 'PT. BANK WOORI SAUDARA INDONESIA 1906' },
  { value: 'BSMDIDJA', label: 'PT BANK SYARIAH INDONESIA TBK' },
  { value: 'BSSPIDSP', label: 'PT. BPD SUMATERA SELATAN DAN BANGKA BELITUNG' },
  { value: 'BTANIDJA', label: 'PT. BANK TABUNGAN NEGARA (PERSERO)' },
  { value: 'BUMIIDJA', label: 'PT. BANK MNC INTERNASIONAL' },
  { value: 'BUSTIDJ1', label: 'PT. BANK BISNIS INTERNASIONAL' },
  { value: 'BUTGIDJ1', label: 'PT. BANK MEGA SYARIAH' },
  { value: 'CENAIDJA', label: 'PT. BANK CENTRAL ASIA' },
  { value: 'CHASIDJX', label: 'JPMORGAN CHASE BANK, N.A.' },
  { value: 'CICTIDJA', label: 'PT. BANK JTRUST INDONESIA' },
  { value: 'CITIIDJX', label: 'CITIBANK, N.A.' },
  { value: 'CTCBIDJA', label: 'PT. BANK CTBC INDONESIA' },
  { value: 'DBSBIDJA', label: 'PT. BANK DBS INDONESIA' },
  { value: 'DEUTIDJA', label: 'DEUTSCHE BANK, A.G.' },
  { value: 'FAMAIDJ1', label: 'PT. BANK FAMA INTERNASIONAL' },
  { value: 'GNESIDJA', label: 'PT. BANK GANESHA' },
  { value: 'HNBNIDJA', label: 'PT. BANK KEB HANA INDONESIA' },
  { value: 'HRDAIDJ1', label: 'PT ALLO BANK INDONESIA TBK' },
  { value: 'HSBCIDJA', label: 'PT. BANK HSBC INDONESIA' },
  { value: 'IBBKIDJA', label: 'PT. BANK MAYBANK INDONESIA' },
  { value: 'ICBKIDJA', label: 'PT. BANK ICBC INDONESIA' },
  { value: 'IDMOIDJ1', label: 'PT. BANK SBI INDONESIA' },
  { value: 'INDOIDJA', label: 'REKENING ANTAR KANTOR SISTEM BI-RTGS Central Bank' },
  { value: 'JSABIDJ1', label: 'PT. BANK JASA JAKARTA' },
  { value: 'KSEBIDJ1', label: 'PT. BANK SEABANK INDONESIA' },
  { value: 'KSEIIDJ1', label: 'PT. KUSTODIAN SENTRAL EFEK INDONESIA' },
  { value: 'LFIBIDJ1', label: 'PT. BANK NATIONALNOBU' },
  { value: 'LMANIDJ1', label: 'PT. BANK OKE INDONESIA TBK' },
  { value: 'LOMAIDJ1', label: 'PT. BANK AMAR INDONESIA' },
  { value: 'LPEIIDJ1', label: 'INDONESIA EXIMBANK' },
  { value: 'MASDIDJ1', label: 'PT. BANK MASPION INDONESIA' },
  { value: 'MAYAIDJA', label: 'PT. BANK MAYAPADA INTERNASIONAL' },
  { value: 'MAYOIDJA', label: 'PT. BANK MAYORA' },
  { value: 'MCORIDJA', label: 'PT. BANK CHINA CONSTRUCTION BANK INDONESIA.' },
  { value: 'MEDHIDS1', label: 'PT. BANK MESTIKA DHARMA' },
  { value: 'MEEKIDJ1', label: 'PT. BANK SHINHAN INDONESIA' },
  { value: 'MEGAIDJA', label: 'PT. BANK MEGA' },
  { value: 'MHCCIDJA', label: 'PT. BANK MIZUHO INDONESIA' },
  { value: 'MUABIDJA', label: 'PT. BANK MUAMALAT INDONESIA' },
  { value: 'NISPIDJA', label: 'PT. BANK OCBC NISP' },
  { value: 'PDBBIDJ1', label: 'PT. BANK PEMBANGUNAN DAERAH BANTEN' },
  { value: 'PDBKIDJ1', label: 'PT. BPD BENGKULU' },
  { value: 'PDIJIDJ1', label: 'PT. BPD PAPUA' },
  { value: 'PDJBIDJA', label: 'PT. BANK JABAR BANTEN' },
  { value: 'PDJGIDJ1', label: 'PT. BPD JAWA TENGAH' },
  { value: 'PDJMIDJ1', label: 'PT. BPD JAMBI' },
  { value: 'PDJTIDJ1', label: 'PT. BPD JAWA TIMUR' },
  { value: 'PDKBIDJ1', label: 'PT. BPD KALIMANTAN BARAT' },
  { value: 'PDKGIDJ1', label: 'PT. BPD KALIMANTAN TENGAH' },
  { value: 'PDKSIDJ1', label: 'PT. BPD KALIMANTAN SELATAN' },
  { value: 'PDKTIDJ1', label: 'PT. BPD KALIMANTAN TIMUR DAN KALIMANTAN UTARA' },
  { value: 'PDLPIDJ1', label: 'PT. BPD LAMPUNG' },
  { value: 'PDMLIDJ1', label: 'PT. BPD MALUKU DAN MALUKU UTARA' },
  { value: 'PDNBIDJ1', label: 'PT. BPD NUSA TENGGARA BARAT SYARIAH' },
  { value: 'PDNTIDJ1', label: 'PT. BPD NUSA TENGGARA TIMUR' },
  { value: 'PDRIIDJA', label: 'PT. BPD RIAU KEPRI' },
  { value: 'PDSBIDJ1', label: 'PT. BPD SUMATERA BARAT' },
  { value: 'PDSUIDJ1', label: 'PT. BPD SUMATERA UTARA' },
  { value: 'PDWGIDJ1', label: 'PT. BPD SULAWESI TENGAH' },
  { value: 'PDWRIDJ1', label: 'PT. BPD SULAWESI TENGGARA' },
  { value: 'PDWUIDJ1', label: 'PT. BPD SULAWESI UTARA' },
  { value: 'PDYKIDJ1', label: 'PT. BPD YOGYAKARTA' },
  { value: 'PINBIDJA', label: 'PT. PAN INDONESIA BANK' },
  { value: 'PMASIDJ1', label: 'PT. BANK PRIMA MASTER' },
  { value: 'PUBAIDJ1', label: 'PT BANK BTPN SYARIAH TBK' },
  { value: 'RABOIDJA', label: 'PT. BANK INTERIM INDONESIA' },
  { value: 'ROYBIDJ1', label: 'PT. BANK DIGITAL BCA' },
  { value: 'SBJKIDJA', label: 'PT. BANK SINARMAS' },
  { value: 'SCBLIDJX', label: 'STANDARD CHARTERED BANK' },
  { value: 'SDOBIDJ1', label: 'PT. BANK KB BUKOPIN SYARIAH' },
  { value: 'SIHBIDJ1', label: 'PT. BANK MANDIRI TASPEN' },
  { value: 'SUNIIDJA', label: 'PT. BANK TABUNGAN PENSIUNAN NASIONAL' },
  { value: 'SWAGIDJ1', label: 'PT. BANK VICTORIA SYARIAH' },
  { value: 'SWBAIDJ1', label: 'PT. BANK OF INDIA INDONESIA' },
  { value: 'SYACIDJ1', label: 'PT. BANK ACEH SYARIAH' },
  { value: 'SYBBIDJ1', label: 'PT. BANK PERMATA UNIT USAHA SYARIAH' },
  { value: 'SYBDIDJ1', label: 'PT. BANK DANAMON INDONESIA UNIT USAHA SYARIAH' },
  { value: 'SYBKIDJ1', label: 'PT. BANK MAYBANK INDONESIA UNIT USAHA SYARIAH' },
  { value: 'SYBTIDJ1', label: 'PT. BANK TABUNGAN NEGARA (PERSERO) UNIT USAHA SYARIAH' },
  { value: 'SYCAIDJ1', label: 'PT. BANK BCA SYARIAH' },
  { value: 'SYDKIDJ1', label: 'PT. BPD DKI UNIT USAHA SYARIAH' },
  { value: 'SYJBIDJ1', label: 'PT. BANK JABAR BANTEN SYARIAH' },
  { value: 'SYJGIDJ1', label: 'PT. BPD JAWA TENGAH UNIT USAHA SYARIAH' },
  { value: 'SYJMIDJ1', label: 'PT. BPD JAMBI UNIT USAHA SYARIAH' },
  { value: 'SYJTIDJ1', label: 'PT. BPD JAWA TIMUR UNIT USAHA SYARIAH' },
  { value: 'SYKBIDJ1', label: 'PT. BPD KALIMANTAN BARAT UNIT USAHA SYARIAH' },
  { value: 'SYKSIDJ1', label: 'PT. BPD KALIMANTAN SELATAN UNIT USAHA SYARIAH' },
  { value: 'SYKTIDJ1', label: 'PT. BPD KALIMANTAN TIMUR DAN KALIMANTAN UTARA UNIT USAHA SYARIAH' },
  { value: 'SYNAIDJ1', label: 'PT. BANK CIMB NIAGA UNIT USAHA SYARIAH' },
  { value: 'SYONIDJ1', label: 'PT. BANK OCBC NISP UNIT USAHA SYARIAH' },
  { value: 'SYRIIDJ1', label: 'PT. BPD RIAU UNIT USAHA SYARIAH' },
  { value: 'SYSBIDJ1', label: 'PT. BPD SUMATERA BARAT UNIT USAHA SYARIAH' },
  { value: 'SYSSIDJ1', label: 'PT. BPD SUMATERA SELATAN DAN BANGKA BELITUNG UNIT USAHA SYARIAH' },
  { value: 'SYSUIDJ1', label: 'PT. BPD SUMATERA UTARA UNIT USAHA SYARIAH' },
  { value: 'SYTBIDJ1', label: 'PT. BANK SINARMAS UNIT USAHA SYARIAH' },
  { value: 'SYWSIDJ1', label: 'PT. BPD SULAWESI SELATAN DAN SULAWESI BARAT UNIT USAHA SYARIAH' },
  { value: 'SYYKIDJ1', label: 'PT. BPD YOGYAKARTA UNIT USAHA SYARIAH' },
  { value: 'VICTIDJ1', label: 'PT. BANK VICTORIA INTERNASIONAL' },
  { value: 'YUDBIDJ1', label: 'PT. BANK NEO COMMERCE' },
];
