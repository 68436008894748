import {
  HeadingIcon,
  ListDisc,
  Text,
  Box,
  Drawer,
  DrawerLayout,
  DrawerPane,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
} from '@remote-com/norma';
import { IconHelpCircle } from '@remote-com/norma/icons/IconHelpCircle';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { ButtonInline } from '@/src/components/Button';
import { REMOTE_TERMS_OF_SERVICE } from '@/src/constants/routes';

const faqContent = [
  {
    title: 'What are the benefits of the Terms of Service?',
    description: (
      <>
        No more signing SLAs for every country and SOs for every employee. Updated Intellectual
        Property (IP) language that’s been re-vetted by outside counsel. The{' '}
        <ButtonInline
          href={REMOTE_TERMS_OF_SERVICE}
          target="_blank"
          rel="noopener noreferrer"
          trackNavigation={['registration', 'terms-of-service']}
        >
          Terms of Service
        </ButtonInline>{' '}
        are also a more accurate reflection of Remote’s subscription-based model that reduces risk.
        In short, our Terms of Service make using Remote even faster, easier, and more compliant.
      </>
    ),
  },
  {
    title: 'What do I need to do?',
    description:
      'The Company Owner or another Company Admin will need to read and agree to the new Terms of Service from within the Remote platform.',
  },
  {
    title: 'How does this impact my existing employees under previously-signed SLA(s) or SO(s)?',
    description:
      'Upon agreeing to the new Terms of Service, prior MSA(s), SLA(s), and SO(s) will be superseded; however, this will not affect existing employees or the terms of their Employment Agreements (EAs).',
  },
  {
    title:
      'Do I need to move to the Terms of Service right away? Can I stay on our current MSA and continue to sign SLA and SOs?',
    description:
      'No immediate action is required. You may continue to sign SLA and SOs each time you hire. Note that MSAs, SLAs, and SOs will be phased out in 2022.',
  },
];

export function TermsOfServiceDrawer({ children }) {
  const [isVisible, setVisibility] = useState(false);

  return (
    <>
      <Drawer
        aria-label="Terms of service"
        isVisible={isVisible}
        onClose={() => setVisibility(false)}
        Trigger="TODO_MIGRATE_TO_COMPONENT_DONT_COPY"
        $layout="fullWidth"
      >
        <DrawerLayout>
          <DrawerHeader>
            <DrawerTitle>Terms of Service</DrawerTitle>
          </DrawerHeader>
          <DrawerPane>
            <Box borderBottom="1px solid" borderColor="grey.200" pb={6} mb={6}>
              <HeadingIcon
                size="sm"
                Icon={IconHelpCircle}
                text="What is Remote's Terms of Service?"
              />
              <Text mt={6} variant="sm" color="lynch">
                Remote's Terms of Service (ToS) is a legally binding contract where we outline our
                terms, rules, and guidelines in order for You to access our service. The acceptance
                of the ToS replaces the need for the Master Service Agreement (MSA), Service Level
                Agreement (SLA), and Service (SO) signed separately outside the platform. Once You
                agree to our Terms of Service, there will be no need to sign an MSA, SLA or SO
                amendment in the future.{' '}
                <ButtonInline
                  href="https://support.remote.com/hc/en-us/sections/5927565837837-Terms-of-Service"
                  target="_blank"
                  rel="noopener"
                >
                  Learn more
                </ButtonInline>
              </Text>
            </Box>
            <Text variant="smMedium" textTransform="uppercase" color="lynch" mb={6}>
              FAQ's
            </Text>
            <ListDisc gap="lg">
              {faqContent.map(({ title, description }, idx) => (
                <li key={`faq-${idx}`}>
                  <Text color="bayoux">
                    <Text variant="smSemiBold">{title}</Text>
                    <Text mt={2}>{description}</Text>
                  </Text>
                </li>
              ))}
            </ListDisc>
          </DrawerPane>
          <DrawerFooter />
        </DrawerLayout>
      </Drawer>
      {children({ onOpen: () => setVisibility(true) })}
    </>
  );
}

TermsOfServiceDrawer.propTypes = {
  children: PropTypes.func.isRequired,
};
