import { TODO_TYPES_V2 } from '@/src/domains/todos/constants';
import { useGetTodos } from '@/src/domains/todos/hooks';

const isAcceptNotification = (notification) =>
  notification.type === TODO_TYPES_V2.ACCEPT_TERMS_OF_SERVICE && notification.status === 'active';

export function useCompanyHasTermsNotification({ options }) {
  return useGetTodos({
    options: {
      ...options,
      select: (data) => data.data.items.some(isAcceptNotification),
    },
  });
}
