import * as Select from '@radix-ui/react-select';
import { Box, Text, Tooltip } from '@remote-com/norma';
import { IconV2OutlineChevronDown } from '@remote-com/norma/icons/IconV2OutlineChevronDown';
import { IconV2OutlineChevronUp } from '@remote-com/norma/icons/IconV2OutlineChevronUp';
import { IconV2SolidCheck } from '@remote-com/norma/icons/IconV2SolidCheck';
import type { ComponentProps, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { TableFooterButtonRoot } from '@/src/components/Table/TableFooter/TableFooterButton';

const SelectTrigger = styled(TableFooterButtonRoot).attrs({ forwardedAs: Select.Trigger })`
  padding-left: ${({ theme }) => theme.space[3]}px;
`;

const SelectLabel = styled(Text).attrs({ color: 'grey.500', variant: 'xs' })``;

const SelectIcon = styled(IconV2OutlineChevronDown)`
  margin-top: 6px;
  height: 16px;
  width: 16px;
`;

const SelectContent = styled(Select.Content)`
  background: ${({ theme }) => theme.colors.blank};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.grey[200]};
  box-shadow: 0px 4px 16px 0px rgba(15, 20, 25, 0.08);
  padding: ${({ theme }) => theme.space[2]}px;
`;

const SelectScrollButtonIcon = styled.svg`
  color: ${({ theme }) => theme.colors.grey[500]};
  height: 20px;
  margin: 0 auto;
  display: block;
`;

type TableFooterSelectProps = {
  hideLabel?: boolean;
  label: string;
  disabled?: boolean;
  'data-testid'?: string;
} & Pick<ComponentProps<typeof Select.Root>, 'value' | 'onValueChange'>;

function TableFooterSelect({
  children,
  disabled,
  value,
  hideLabel,
  label,
  onValueChange,
  'data-testid': dataTestId,
}: PropsWithChildren<TableFooterSelectProps>) {
  return (
    /**
     * We want to have a consistent UX for disabled inputs and buttons where users can
     * focus an element and receive context despite not being able to interact with it.
     *
     * Using the `disabled` prop on `Select.Root` will prevent users from interacting with
     * the select input. Instead we are forcing it to be closed when disabled.
     */
    <Select.Root value={value} onValueChange={onValueChange} open={disabled ? false : undefined}>
      <Tooltip label={!disabled && hideLabel && label} type="caption">
        {/*
         * We can safely pass the `disabled` prop to `SelectTrigger`
         * because it's based on our `Button` component from Norma.
         */}
        <SelectTrigger aria-label={label} disabled={disabled} data-testid={dataTestId}>
          {!hideLabel && <SelectLabel aria-hidden>{label}</SelectLabel>}
          <Select.Value data-testid="table-footer-select-value" />
          <Select.Icon>
            <SelectIcon />
          </Select.Icon>
        </SelectTrigger>
      </Tooltip>
      <Select.Portal
        // @ts-expect-error
        style={{
          /**
           * I'm overwriting zIndex here so that these selects also work in drawers/modals.
           * However, this is really nasty and we shouldn't have to do it. 😢
           */
          // eslint-disable-next-line no-inline-styles/no-inline-styles
          zIndex: 999999,
        }}
      >
        <SelectContent>
          <Select.ScrollUpButton>
            <SelectScrollButtonIcon as={IconV2OutlineChevronUp} />
          </Select.ScrollUpButton>
          <Select.Viewport>{children}</Select.Viewport>
          <Select.ScrollDownButton>
            <SelectScrollButtonIcon as={IconV2OutlineChevronDown} />
          </Select.ScrollDownButton>
        </SelectContent>
      </Select.Portal>
    </Select.Root>
  );
}

const SelectItem = styled(Text).attrs({
  color: 'grey.900',
  p: 2,
  variant: 'smMedium',
})`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: grid;
  gap: ${({ theme }) => theme.space[2]}px;
  grid-template-columns: [indicator] 16px [label] 1fr;

  &[data-disabled] {
    cursor: not-allowed;
  }

  &[data-highlighted] {
    background-color: ${({ theme }) => theme.colors.grey[100]};
    outline: none;
  }
`;

const SelectItemIndicatorWrapper = styled(Box)`
  display: grid;
  place-items: center;
`;

const SelectItemIndicator = styled(IconV2SolidCheck)`
  color: ${({ theme }) => theme.colors.brand[600]};
  height: 16px;
  width: 16px;
`;

type TableFooterSelectItemProps = {
  label: string;
} & Pick<ComponentProps<typeof Select.Item>, 'disabled' | 'value'>;

function TableFooterSelectItem({ disabled, label, value }: TableFooterSelectItemProps) {
  return (
    <Select.Item disabled={disabled} value={value} asChild>
      <SelectItem>
        <SelectItemIndicatorWrapper>
          <Select.ItemIndicator asChild>
            <SelectItemIndicator />
          </Select.ItemIndicator>
        </SelectItemIndicatorWrapper>
        <Select.ItemText>{label}</Select.ItemText>
      </SelectItem>
    </Select.Item>
  );
}

TableFooterSelect.Item = TableFooterSelectItem;

export { TableFooterSelect };
