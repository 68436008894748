import type { SetupIntentResult } from '@stripe/stripe-js';

import type {
  PaymentTimelineEvent,
  PaymentTimelineEventStatus,
} from '@/src/api/config/employ/contractorInvoice.types';
import type { Currency } from '@/src/api/config/employ/shared.types';
import { euroCountriesNeedingSwift } from '@/src/domains/bankAccounts/constants';
import type {
  ContractorInvoicePaymentTimelineContent,
  ContractorInvoicePaymentTimelineContentResolverMap,
} from '@/src/domains/contractorInvoices/types';
import type { InvoiceStatus } from '@/src/domains/invoices/constants';
import { invoiceStatus } from '@/src/domains/invoices/constants';
import type { WireChargeType } from '@/src/domains/paymentMethods/shared/constants';
import { wireChargeTypes } from '@/src/domains/paymentMethods/shared/constants';
import {
  paymentTypes,
  DECLINE_REASON_EXTERNALLY_PAID,
  REASONS_WITH_FREE_TEXT_OPTION,
} from '@/src/domains/payments/constants';
import type { WithdrawalMethod } from '@/src/domains/withdrawalMethods/types';
import { formatDateRange, formatMonthDay, formatMonthDayYear } from '@/src/helpers/date';
import type { $TSFixMe } from '@/types';

import type { ContractorOutstandingPayment, OutstandingPayment } from '../types';

export function hasServiceFee(feeAmount: number | null | undefined) {
  return feeAmount != null;
}

export function getNetAmount(amount: number, feeAmount: number | null | undefined) {
  if (hasServiceFee(feeAmount)) {
    return amount - feeAmount;
  }
  return amount;
}
export function getRefundsTotal(refunds: { amount: number }[] | null | undefined) {
  if (!refunds || !refunds.length) {
    return null;
  }
  return refunds.reduce((accumulator, refund) => accumulator + refund.amount, 0);
}

export function isPayrollRunPaymentType(type: OutstandingPayment['type']) {
  return type === paymentTypes.PAYROLL_RUN;
}

export function isContractorReceiptPaymentType(type: OutstandingPayment['type']) {
  return type === paymentTypes.CONTRACTOR;
}

export function isContractorOutstandingPayment(
  outstandingPayment?: OutstandingPayment
): outstandingPayment is ContractorOutstandingPayment {
  if (!outstandingPayment) {
    return false;
  }
  return isContractorReceiptPaymentType(outstandingPayment.type);
}

function validateEvent(
  eventsLookup: ContractorInvoicePaymentTimelineContentResolverMap,
  paymentTimelineEvent: PaymentTimelineEvent,
  excludedStatuses: PaymentTimelineEventStatus[] = []
) {
  if (excludedStatuses.includes(paymentTimelineEvent.status)) {
    return false;
  }
  return eventsLookup[paymentTimelineEvent.type]?.[paymentTimelineEvent.status] != null;
}

export function formatCurrencyName(currency: Currency) {
  return `${currency.name} (${currency.code})`;
}

export function sortCurrenciesByName(currencies: Currency[]) {
  return currencies.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
}

export type PaymentTimelineLabelParams = {
  payerEntityName?: string;
  contractorName?: string;
  formattedInvoiceAmount: string;
  formattedPayoutAmount?: string;
  isGuaranteed: boolean;
  isCmProtectInvoice: boolean;
  expectedPayOutDate: string | null;
  payOutScheduledAt: string | null;
  invoiceStatus: InvoiceStatus | undefined;
  contractorWithdrawalMethodType: WithdrawalMethod['type'] | undefined;
  contractorWithdrawalMethodLabel?: string;
  declinedReason: string;
};

type FormatPaymentTimelineEventsProps = {
  paymentTimelineContentResolverMap: ContractorInvoicePaymentTimelineContentResolverMap;
  paymentTimelineEvents?: PaymentTimelineEvent[];
  labelParams: PaymentTimelineLabelParams;
  excludedStatuses?: PaymentTimelineEventStatus[];
};

/** *
 * Formats events for use in contractor invoice and outstanding payment timelines
 *
 * @param {object} paymentTimelineContentMap - map of event types and display values (contractor_invoice_ or outstanding_payment_)
 * @param {array} paymentTimelineEvents - payment timeline events for contractor invoice or outstanding payment views
 * @param {object} labelParams - data needed for event titles and descriptions
 * @param {array} excludedStatuses - event statuses to exclude
 * @returns formatted events for payment timeline
 */
export function formatPaymentTimelineEvents({
  paymentTimelineContentResolverMap,
  paymentTimelineEvents = [],
  labelParams,
  excludedStatuses = [],
}: FormatPaymentTimelineEventsProps): ContractorInvoicePaymentTimelineContent[] {
  return paymentTimelineEvents
    .filter((event) => validateEvent(paymentTimelineContentResolverMap, event, excludedStatuses))
    .map<ContractorInvoicePaymentTimelineContent>((event) => {
      const contentResolver = paymentTimelineContentResolverMap[event.type]?.[event.status];
      return {
        status: event.status,
        dateIndicator:
          contentResolver?.customDate?.(labelParams, event) ?? formatMonthDay(event.updatedAt),
        title: contentResolver?.title?.(labelParams, event) ?? '',
        description: contentResolver?.description?.(labelParams, event) ?? '',
        CustomEventDescription: contentResolver?.customEventDescription?.(labelParams, event),
        CustomIndicator: contentResolver?.CustomIndicator,
        isLastExpected: !!contentResolver?.isLastExpected,
      };
    });
}

/**
 *
 * @param {{start: string | null, end: string| null} | string | null} date
 * @returns string | null - 'Jan 16, 2023', 'Dec 30, 2022 - Jan 1, 2023'
 */
export function formatDateForPaymentTimeline(date: { start: string; end: string } | string | null) {
  if (!date) {
    return null;
  }

  if (typeof date === 'string') {
    return formatMonthDayYear(date);
  }

  if (date.start && date.end) {
    return formatDateRange(date.start, date.end, '-');
  }

  if (date.end) {
    return formatMonthDayYear(date.end);
  }

  return null;
}

export const isStripeTransfer = (wireChargeType: WireChargeType) => {
  return (
    wireChargeType === wireChargeTypes.STRIPE_ACH_CREDIT_TRANSFER ||
    wireChargeType === wireChargeTypes.STRIPE_BANK_TRANSFER
  );
};

export const getRemoteEntityAddress = (entity: $TSFixMe) => {
  const { addresses } = entity;
  let { address } = entity;

  if (Array.isArray(addresses)) {
    [address] = addresses;
  }

  return address;
};

export const buildRejectInvoiceBodyParams = (values: $TSFixMe) => {
  const { reason, reasonDetails } = values;
  let employerComment = reason;
  let status: InvoiceStatus = invoiceStatus.REJECTED;

  if (REASONS_WITH_FREE_TEXT_OPTION.includes(reason)) {
    employerComment = reasonDetails;
  }

  if (reason === DECLINE_REASON_EXTERNALLY_PAID) {
    status = invoiceStatus.EXTERNALLY_PAID;
  }

  return { employerComment, status };
};

export const getSwiftReasonMessage = (
  desiredCurrency: Currency,
  country?: { code: string; name: string }
) => {
  if (!country) {
    return `our provider only supports SWIFT payouts of ${desiredCurrency.code} to this country`;
  }

  if (desiredCurrency.code === 'EUR' && euroCountriesNeedingSwift.includes(country.code)) {
    return `our provider only supports SWIFT payouts of EUR to ${country.name}`;
  }

  if (desiredCurrency.code === 'USD' && country.code === 'ECU') {
    return `our provider only supports SWIFT payouts of USD to ${country.name}`;
  }

  return `${desiredCurrency.code} is not the local currency of ${country.name}`;
};

export const getPaymentMethodIdFromSetupIntentResult = (setupIntentResult: SetupIntentResult) => {
  return typeof setupIntentResult?.setupIntent?.payment_method === 'string'
    ? setupIntentResult?.setupIntent?.payment_method
    : setupIntentResult?.setupIntent?.payment_method?.id;
};
