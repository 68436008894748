import { themeV2 } from '@remote-com/norma';
import get from 'lodash/get';
import type { ValueOf } from 'type-fest';

import type { EmployeeEmployment } from '@/src/api/config/employ/employeeEmployment.types';
import { StatusCell } from '@/src/components/Table';
import { ACTIONS_COLUMN_ID } from '@/src/components/Table/helpers/constants';
import { specialAdditionalColumnsCellType } from '@/src/components/Table/hooks/useColumnsState/constants';
import type { contractStatus } from '@/src/domains/employment/constants';
import {
  customerContractStatusBadges,
  getCustomerContractStatusLabel,
} from '@/src/domains/employment/constants';
import { columnContractAccessorLabels } from '@/src/domains/team/constants';
import { TEAM_PAGE_TABS } from '@/src/domains/team/employer/constants';

export const TEAM_PAGE_TAB_VALUES = Object.values(TEAM_PAGE_TABS);
export const NAME_COLUMN_ID = 'employee_name';
export const MANAGER_COLUMN_ID = 'manager';
export const COUNTRY_COLUMN_ID = 'country';
export const CONTRACT_END_DATE_COLUMN_ID = 'contractEndDate';
export const SALARY_COLUMN_ID = 'salary';
export const CONTRACT_COLUMN_ID = 'contract_type';
export const HIRED_BY_COLUMN_ID = 'hired_by';
export const CONTRACT_TYPE_COLUMN_ID = 'contractDurationType';
export const PROBATION_END_DATE_COLUMN_ID = 'probationPeriodEndDate';
export const DEPARTMENT_COLUMN_ID = 'department';
export const BENEFITS_COLUMN_ID = 'benefits';
export const ROLE_COLUMN_ID = 'role';
export const PRICING_PLAN_COLUMN_ID = 'productIdentifier';
export const SERVICE_END_DATE = 'serviceEndDate';
export const DIRECT_REPORTS_ONLY = 'direct_reports_only';
export const CONTRACTORS_TABLE_COLUMNS = new Set([
  'employmentShortSlug',
  COUNTRY_COLUMN_ID,
  ROLE_COLUMN_ID,
  NAME_COLUMN_ID,
  HIRED_BY_COLUMN_ID,
  ACTIONS_COLUMN_ID,
  DEPARTMENT_COLUMN_ID,
  MANAGER_COLUMN_ID,
  PRICING_PLAN_COLUMN_ID,
  SERVICE_END_DATE,
]);
export const ONBOARDING_RESERVE_ID = 'onboarding_reserve';

export const colors = { white: themeV2.colors.white, grey: themeV2.colors.grey[300] };

export const colorRange = [
  themeV2.colors.purple[300],
  themeV2.colors.purple[400],
  themeV2.colors.purple[600],
  themeV2.colors.purple[800],
  themeV2.colors.purple[900],
];

const personalDetailsPrefix = 'Personal details:';
const personalDetailsColumnAccessorLabels = {
  personalDetails: {
    sex: `${personalDetailsPrefix} Sex`,
    gender: `${personalDetailsPrefix} Gender`,
    genderDescription: `${personalDetailsPrefix} Gender description`,
    birthdate: `${personalDetailsPrefix} Birthdate`,
    mobileNumber: `${personalDetailsPrefix} Mobile number`,
    nationality: `${personalDetailsPrefix} Nationality`,
    givenName: `${personalDetailsPrefix} Given name`,
    surname: `${personalDetailsPrefix} Surname`,
    preferredName: `${personalDetailsPrefix} Preferred name`,
    preferredPronouns: `${personalDetailsPrefix} Pronouns`,
    title: `${personalDetailsPrefix} Title`,
  },
};

const homeAddressPrefix = 'Home address:';
const homeAddressLocalDetailsColumnAcessorLabels = {
  localDetails: {
    addressBuildingName: `${homeAddressPrefix} Building name`,
    addressDistrict: `${homeAddressPrefix} District`,
    addressMoo: `${homeAddressPrefix} Moo`,
    addressParish: `${homeAddressPrefix} Parish`,
    addressSubcountyCounty: `${homeAddressPrefix} Subcounty county`,
    addressSubdistrict: `${homeAddressPrefix} Subdistrict`,
    addressType: `${homeAddressPrefix} Type`,
    addressVillage: `${homeAddressPrefix} Village`,
    apartmentNumber: `${homeAddressPrefix} Apartment number`,
    barangay: `${homeAddressPrefix} Barangay`,
    buildingNumber: `${homeAddressPrefix} Building number`,
    department: `${homeAddressPrefix} Department`,
    district: `${homeAddressPrefix} District`,
    governorate: `${homeAddressPrefix} Governorate`,
    katakanaAddress: `${homeAddressPrefix} Katakana address`,
    localGovernmentArea: `${homeAddressPrefix} Local government area`,
    localityName: `${homeAddressPrefix} Locality name`,
    municipality: `${homeAddressPrefix} Municipality`,
    neighborhood: `${homeAddressPrefix} Neighborhood`,
    postalCounty: `${homeAddressPrefix} Postal county`,
    province: `${homeAddressPrefix} Province`,
    residential: `${homeAddressPrefix} Residential`,
    state: `${homeAddressPrefix} State`,
    suburb: `${homeAddressPrefix} Suburb`,
  },
};

const homeAddressCountryColumnAccessorLabels = {
  country: {
    name: `${homeAddressPrefix} Country`,
  },
};

const homeAddressColumnAccessorLabels = {
  homeAddress: {
    ...homeAddressLocalDetailsColumnAcessorLabels,
    ...homeAddressCountryColumnAccessorLabels,
    address: `${homeAddressPrefix} Address`,
    addressLine2: `${homeAddressPrefix} Address line 2`,
    city: `${homeAddressPrefix} City`,
    postalCode: `${homeAddressPrefix} Postal code`,
    state: `${homeAddressPrefix} State`,
  },
};

const contractStatusSpecialAdditionalColumnConfig = {
  'contract.status': {
    cellType: StatusCell,
    accessor: (row: Row) => ({
      type: customerContractStatusBadges[get(row, 'contract.status')],
      label: getCustomerContractStatusLabel(get(row, 'contract.status'), get(row, 'contract.type')),
    }),
    exportData: (row: Row) =>
      getCustomerContractStatusLabel(get(row, 'contract.status'), get(row, 'contract.type')),
  },
};

const personalDetailsNationalitySpecialAdditionalColumnConfig = {
  'personalDetails.nationality': {
    cellType: specialAdditionalColumnsCellType.ARRAY,
  },
};

// Ideally `columnAcessorLabels`, `specialAdditionalColumnsConfig`, and
// `columnAccessorExclusionSet` would come from domains/team/constants.jsx and
// be shared with other tables listing employments. However, the BE responses among
// different routes returning the same data type (employments) are not consistent
// which means the access keys are not the same (e.g. what is just `personalDetails` in
// `columnAcessorLabels` here, is `employment.personalDetails` somewhere else).
export const columnAcessorLabels = {
  ...columnContractAccessorLabels,
  ...personalDetailsColumnAccessorLabels,
  ...homeAddressColumnAccessorLabels,
};

export const specialAdditionalColumnsConfig = {
  ...contractStatusSpecialAdditionalColumnConfig,
  ...personalDetailsNationalitySpecialAdditionalColumnConfig,
};

export const columnAccessorExclusionSet = new Set([
  'personalDetails',
  'homeAddress',
  'homeAddress.country.slug',
  'homeAddress.country.code',
  'homeAddress.country.alpha2Code',
  'grossSalary',
  'grossSalary.amount',
]);

export type Row = {
  slug: string;
  original: EmployeeEmployment;
  contract: {
    status: Exclude<ValueOf<typeof contractStatus>, 'deleted'>;
  };
};
