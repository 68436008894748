import type { ParsedUrlQuery } from 'querystring';

import { object, string } from 'yup';

export const TIME_TRACKING_TABS_EMPLOYER = {
  OVERTIME: {
    label: 'Needs approval',
    value: 'submitted',
  },
  ALL: {
    label: 'All timesheets',
    value: 'summary',
  },
};

const employerRouteQuerySchema = object({
  tab: string()
    .oneOf(Object.values(TIME_TRACKING_TABS_EMPLOYER).map(({ value }) => value))
    .optional(),
}).required();

export const TIME_TRACKING_TABS_EMPLOYEE = {
  TRACKING: {
    label: 'Tracking',
    value: 'tracking',
  },
  ALL: {
    label: 'All timesheets',
    value: 'summary',
  },
};

const employeeRouteQuerySchema = object({
  tab: string()
    .oneOf(Object.values(TIME_TRACKING_TABS_EMPLOYEE).map(({ value }) => value))
    .optional(),
}).required();

/**
 * It returns the selected tab for an employee/employer based on the query
 * parameters, with a default tab if no valid tab, or not tab at all, is found.
 *
 * We're using yup to validate the query and narrow the type to a type we define.
 */
const getSelectedTab = (
  query: ParsedUrlQuery,
  schema: typeof employerRouteQuerySchema | typeof employeeRouteQuerySchema,
  defaultTab: string
) => {
  const isValidTab = schema.isValidSync(query);

  if (!isValidTab) return defaultTab;

  return schema.validateSync(query).tab ?? defaultTab;
};

export const getEmployeeSelectedTab = (query: ParsedUrlQuery) =>
  getSelectedTab(query, employeeRouteQuerySchema, TIME_TRACKING_TABS_EMPLOYEE.TRACKING.value);

export const getEmployerSelectedTab = (query: ParsedUrlQuery) =>
  getSelectedTab(query, employerRouteQuerySchema, TIME_TRACKING_TABS_EMPLOYER.OVERTIME.value);
