import { useMachine } from '@xstate/react';
import type { ReactElement } from 'react';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { FileRejection } from 'react-dropzone';
import type { ActorRefWithDeprecatedState } from 'xstate';

import { FILE_DOCUMENT_SUPPORTED_FORMATS, FILE_MAX_SIZE_BYTES } from '../../foundations';
import { Box } from '../../layout';

import { InputFileDropArea } from './drop/area';
import type { InputFileCallbacksRef, InputFileModifiable, InputFileObject } from './helpers';
import { InputFileList } from './list/list';
import { createInputFileStateMachine } from './state';
import { InputFileStyled } from './styled';

const { DropzoneRoot, Container, ErrorMessage } = InputFileStyled;

// This was previously exported but we disclosed it after Norma migration,
// as there was no usage at the time.
// If this is needed, we can export it again, but please move it to "utils",
// or a separate file.
const areFilesTooMany = (fileRejections: FileRejection[], isSingleFileMode: boolean) => {
  // There's an issue with the Drag-n-Drop lib: it only reports 1 error at a time. So if we're trying
  // to add too many files BUT they're also the wrong file type, only that error is being reported.
  // So we need to do this extra check.
  return (
    (isSingleFileMode && fileRejections.length > 1) ||
    fileRejections.some((f) => f?.errors.some(({ code }) => code === 'too-many-files'))
  );
};

const fileToFileObject = (file: File): InputFileObject => ({
  file,
  isDeleting: false,
  isUploading: true,
  name: file.name,
  size: file.size,
});

const fileRejectionToFileObject = (fileRejection: FileRejection): InputFileObject => ({
  errors: fileRejection.errors,
  file: fileRejection.file,
  isDeleting: false,
  isUploading: false,
  name: fileRejection.file.name,
});

type FileActor = ActorRefWithDeprecatedState<{ fileObject: InputFileObject }, any, any>;

// This was exported before Norma migration.
// See comment at "areFilesTooMany" above.
const fileActorToFileObject = (fileActor: FileActor) => {
  const { fileObject } = fileActor.state.context;
  return {
    ...fileObject,
    isDeleting: fileActor.state.matches('deleting'),
    isUploading: fileActor.state.matches('uploading'),
  };
};

const SINGLE_FILE_ERROR_MESSAGE =
  'Multiple files upload is not supported. Please upload a single file.';

type Props = InputFileCallbacksRef & {
  /** Optional: Semantic label for the dropzone  */
  label?: string;
  /** Optional Semantic description for the dropzone */
  description?: string | React.ReactNode;
  /** Optional: hide the label but keep it for accessibility */
  hideLabel?: boolean;
  /** Optional: hide the description but keep it for accessibility */
  hideDescription?: boolean;
  /** Field error message */
  errorMessage?: string;
  /** Supported files types */
  supportedFileTypes?: string[];
  /** Maximum file size (in bytes) */
  maxFileSize?: number;
  /** Displays the desired version of the component. Note: big size is deprecated, we should use "large" instead */
  size?: 'small' | 'big' | 'large';
  /** Maximum number of files */
  singleFile?: boolean;
  /**
   * The "single file" prop does more than just limiting the number of files.
   * Most importantly, it actually changes the value format:
   * - Single: File | null
   * - Multiple: File[]
   * This makes it very hard to integrate in generic solutions like form builders.
   * Therefore, during the migration to Norma,
   * we introduced this "legacy" prop as a simpler alternative,
   * where we only limit the number of files, without changing the value format.
   *
   * This is intentionally marked as deprecated to discourage its direct usage
   * outside of form builders. To learn more, follow its usages.
   *
   * @deprecated
   */
  singleFileLegacy?: boolean;
  /** Either an array of standard file object or a single file object */
  value?: File | File[];
  /** Callback when file added is clicked. By default, opens file preview URL. */
  onFileClick?: () => void;
  modifiable?: InputFileModifiable;
  'data-testid'?: string;
};

export function InputFileUploader(rawProps: Props): ReactElement {
  const {
    description,
    label,
    value,
    size = 'small',
    deleteFile,
    uploadFile,
    onChange,
    supportedFileTypes = FILE_DOCUMENT_SUPPORTED_FORMATS,
    maxFileSize = FILE_MAX_SIZE_BYTES,
    modifiable,
    singleFile = false,
    singleFileLegacy = false,
    errorMessage,
    hideLabel = false,
    hideDescription = false,
    'data-testid': dataTestId,
    ...props
  } = rawProps;

  const [localErrorMessage, setLocalErrorMessage] = useState<string | undefined>(undefined);
  const callbackRefs = useRef({ onChange, uploadFile, deleteFile });
  const stateMachine = useMemo(
    () => createInputFileStateMachine({ callbackRefs, singleFile }),
    [singleFile]
  );

  const [state, send] = useMachine(stateMachine, {
    // Why any: There's no way to type this reliably here.
    // Not to mention that "process" is not available in the browser.
    // Instead, use a global utility (e.g., isDev).
    devTools: (process as any).NODE_ENV !== 'production',
  });

  const allFiles: InputFileObject[] = Object.values(state.context.actors)
    // Our state is poorly typed, so we need to cast here.
    .map((actor) => fileActorToFileObject(actor as FileActor))
    .filter((file) => !file.errors?.some(({ code }) => code === 'too-many-files'));

  useLayoutEffect(() => {
    callbackRefs.current = { onChange, uploadFile, deleteFile };
  });

  useEffect(() => {
    if (singleFile) {
      if (value) {
        send('SHOW_FILE', { fileObject: value });
      }
      // Why any: We should throw or use a better type guard here
    } else if ((value as File[])?.filter(Boolean)?.length) {
      (value as File[]).map(fileToFileObject).forEach((fileObject) => {
        send('SHOW_FILE', { fileObject });
      });
    }
  }, [value, send, singleFile]);

  const filesHaveErrors = useMemo(() => {
    return allFiles.some((fileObject) => fileObject.errors?.length);
  }, [allFiles]);

  const isUploading = useMemo(() => {
    return allFiles.some((fileObject) => fileObject.isUploading);
  }, [allFiles]);

  return (
    <Container data-testid={dataTestId}>
      <DropzoneRoot>
        <InputFileDropArea
          // See also: "disable input on single file uploaded"
          singleFile={singleFile || singleFileLegacy}
          // The "single file" prop here does not prevent the user from
          // _selecting_ more files,
          // because it assumes we will handle that here.
          //
          // However, this does not work for "single file legacy",
          // mainly because the logic to handle single file (which we want)
          // is coupled with the logic to change value format (which we don't want).
          //
          // To learn more, follow the implementation of this prop.
          disableInputOnSingleFileUploaded={singleFileLegacy}
          maxSize={maxFileSize}
          supportedFileTypes={supportedFileTypes}
          description={description}
          fileObjects={allFiles}
          hasErrors={!!(errorMessage || filesHaveErrors)}
          isUploading={isUploading}
          label={label}
          hideLabel={hideLabel}
          hideDescription={hideDescription}
          onAddFiles={(acceptedFiles: File[], fileRejections: FileRejection[]) => {
            const tooManyFiles = areFilesTooMany(fileRejections, singleFile);

            if (tooManyFiles) {
              setLocalErrorMessage(SINGLE_FILE_ERROR_MESSAGE);
              return;
            }

            setLocalErrorMessage(undefined);

            // If we're in single file mode, a file has been added already and there are
            // files coming from the drop area, remove the previous file before adding the new one
            if (
              singleFile &&
              !!allFiles.length &&
              (!!acceptedFiles.length || !!fileRejections.length)
            ) {
              Object.values(state.context.actors)
                // Our state is poorly typed, so we need to cast here.
                .map((actor) => fileActorToFileObject(actor as FileActor))
                .map((fileObject) =>
                  send('DELETE_FILE', {
                    fileObject,
                  })
                );
            }

            (singleFile ? acceptedFiles.slice(0, 1) : acceptedFiles)
              .map(fileToFileObject)
              .forEach((fileObject: InputFileObject) => {
                send('ADD_FILE', { fileObject });
              });

            fileRejections.map(fileRejectionToFileObject).forEach((fileObject: InputFileObject) => {
              send('ADD_FILE', { fileObject });
            });
          }}
          // "big" size is deprecated, use "large" instead
          size={size === 'big' ? 'large' : size}
          {...props}
        />
      </DropzoneRoot>
      <InputFileList
        singleFile={singleFile}
        modifiable={modifiable}
        // InputFileList does not require supportedFileTypes,
        // but we want to avoid logic change during Norma migration.
        // @ts-expect-error
        supportedFileTypes={supportedFileTypes}
        onRename={(fileObject, newName) => {
          send('RENAME_FILE', {
            fileObject: {
              ...fileObject,
              newName,
            },
          });
        }}
        onChangeDocumentType={(fileObject, type) => {
          send('CHANGE_DOCUMENT_TYPE', {
            fileObject: {
              ...fileObject,
              documentType: type,
            },
          });
        }}
        onDeleteClick={(fileObject) => {
          setLocalErrorMessage(undefined);
          send('DELETE_FILE', {
            fileObject,
          });
        }}
        fileObjects={allFiles}
        {...props}
      />
      {errorMessage && (
        // Why any: The code is wrong, as Box requires a number.
        // However, during the migration to Norma, we avoid changing the logic.
        <Box maxWidth="500px" mx={'5' as any} mb={'2' as any}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
      {localErrorMessage && (
        // See comment at the Box in "errorMessage" branch.
        <Box maxWidth="500px" mx="auto" mb={'2' as any}>
          <ErrorMessage>{localErrorMessage}</ErrorMessage>
        </Box>
      )}
    </Container>
  );
}
