import { weekdayOptions } from '@/src/constants/days';

export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;

export const NUMBER_OF_DIGITS_PER_TIME_SEGMENT = 2;

export const INITIAL_DAY_STATE = {
  begin: {
    value: '',
    parsed: null,
  },
  end: {
    value: '',
    parsed: null,
  },
  recess: {
    option: null,
    parsed: null,
  },
};

export const DEFAULT_RECESS_OPTIONS = [
  { label: '15 min', value: 15 },
  { label: '30 min', value: 30 },
  { label: '45 min', value: 45 },
  { label: '60 min', value: 60 },
  { label: '75 min', value: 75 },
  { label: '90 min', value: 90 },
];

export const DEFAULT_DAY_OPTIONS = weekdayOptions;
