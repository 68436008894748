import { createQueryKey, useInvalidateQuery, usePatch } from '@remote-com/data-layer';
import { useMemo, useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import UserContext from '@/src/components/UserProvider/context';
import { USER_TYPE } from '@/src/domains/registration/auth/constants';
import { useMutationWithCustomState } from '@/src/hooks/useMutationWithCustomState';

// Allows for optimistic updates to the contract: https://tanstack.com/query/v4/docs/react/guides/optimistic-updates
// When editorStateBuffer is undefined, e.g., for the old editor, the optimistic update still works correctly, but it doesn't solve the issue of editor flashing/cursor jumping
// Note: You need to always invalidate queries on an onSettled lifecycle method - these are handled separately in useContractActions.
const getOptimisticUpdateCallbacks = ({ queryClient }) => ({
  onMutate: async ({ bodyParams, pathParams }) => {
    // Query keys need to be created explicitly, according to https://gitlab.com/remote-com/employ-starbase/dragon/-/blob/d09e1d54ebf7326f3700b98e2a6122439aef4d82/data-layer/HowToUse.stories.mdx#L88
    const queryKey = createQueryKey('/api/v1/rivendell/contract-documents/[slug]', {
      pathParams,
    });

    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(queryKey);

    // Snapshot the previous value
    const previousData = queryClient.getQueryData(queryKey);

    // Optimistically update to the new value
    queryClient.setQueryData(queryKey, (oldData) => ({ ...oldData, ...bodyParams }));

    // Return a context object with the snapshotted value
    return {
      previousData,
      queryKey,
    };
  },
  onError: (error, variables, context) => {
    // If the mutation fails, use the context returned from onMutate to roll back
    queryClient.setQueryData(context.queryKey, context.previousData);
  },
});

// This hook is meant to be the SSoT for any contract-related API interactions. The
// methods here do not require nesting inside the editor context, and methods here allow
// for passage of react-query lifecycle methods (only onSuccess, onError, and onSettled) in the
// second argument
export const useContractActions = () => {
  const { user } = useContext(UserContext);
  const { invalidateQuery } = useInvalidateQuery();

  const queryClient = useQueryClient();

  const documentUpdateOptions = {
    ...getOptimisticUpdateCallbacks({ queryClient }),
    onSettled: async () => {
      switch (user.role) {
        case USER_TYPE.EMPLOYER:
          await invalidateQuery('/api/v1/employer/contract-documents');
          return invalidateQuery('/api/v1/employer/contract-documents/[slug]');
        case USER_TYPE.ADMIN:
        default:
          await invalidateQuery('/api/v1/rivendell/contract-documents');
          return invalidateQuery('/api/v1/rivendell/contract-documents/[slug]');
      }
    },
  };

  const updateAsAdmin = usePatch(
    '/api/v1/rivendell/contract-documents/[slug]',
    documentUpdateOptions
  );
  const updateAsEmployer = usePatch(
    '/api/v1/employer/contract-documents/[slug]',
    documentUpdateOptions
  );

  const createRoleSpecificUpdateMutation = () => {
    switch (user.role) {
      case USER_TYPE.EMPLOYER:
        return updateAsEmployer;

      case USER_TYPE.ADMIN:
        return updateAsAdmin;

      case USER_TYPE.EMPLOYEE:
        // return a no-op function since employees cannot update the doc
        return () => null;

      default:
        throw new Error('Attempted to save document draft using unsupported role');
    }
  };

  const {
    mutateAsync: updateDocument,
    isLoading: updateDocumentLoading,
    cancel: cancelUpdateDocument,
  } = useMutationWithCustomState(createRoleSpecificUpdateMutation());

  const documentActions = useMemo(
    () => ({
      update: (type, params, mutationOptions) => updateDocument(params, mutationOptions),
    }),
    [updateDocument]
  );

  // Conditionally cancel in-progress functions on unmount
  useEffect(() => {
    return () => {
      cancelUpdateDocument?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: updateDocumentLoading,
    contractDocument: documentActions,
  };
};
