import { Text, Stack, Pill, Badge } from '@remote-com/norma';
import React from 'react';
import { components } from 'react-select';

import { contractStatusBadges, contractStatusLabels } from '@/src/domains/employment/constants';
import { formatMonthDayYear } from '@/src/helpers/date';

export function contractToEmployee(contract) {
  if (!contract) return '-';

  return `${contract.employment?.user?.name} - ${contract.employment?.shortSlug} (${contract.jobTitle} - ${contract.employment?.company?.name})`;
}

export function EmployeeContractOptionSmall(props) {
  const { data: contract } = props;

  // Ignore array option originated in multi select mode
  if (Array.isArray(contract)) return null;

  return (
    <components.Option {...props}>
      <Stack gap="4">
        <Stack gap="1">
          <Text variant="smMedium" color="grey.900">
            {contract.employment?.user?.name}
          </Text>

          <Text variant="xs" color="bayoux">
            {contract.jobTitle} - {contract.employment?.company?.name}
          </Text>
        </Stack>

        <Stack gap="6px">
          <Stack direction="row" alignItems="center">
            <Badge size="sm" type={contractStatusBadges[contract.status]} />

            <Text variant="xs" color="bayoux">
              {contractStatusLabels[contract.status]} - {contract.employment?.shortSlug}
            </Text>
          </Stack>

          {contract.effectiveDate && (
            <Text variant="xs" color="bayoux">
              Effective: {formatMonthDayYear(contract.effectiveDate)}
            </Text>
          )}

          {contract.terminationDate && (
            <Text variant="xs" color="bayoux">
              Terminated: {formatMonthDayYear(contract.terminationDate)}
            </Text>
          )}
        </Stack>
      </Stack>
    </components.Option>
  );
}

export default function EmployeeContractOption(props) {
  const { data: contract } = props;

  // Ignore array option originated in multi select mode
  if (Array.isArray(contract)) return null;

  return (
    <components.Option
      {...props}
      innerProps={{ ...props.innerProps, 'data-dd-action-name': 'Redacted' }}
    >
      <Stack
        gap="4"
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
      >
        <Stack gap="2">
          <Text variant="smMedium" color="grey.900">
            {contract.employment?.user?.name}
          </Text>

          <Text variant="xs" color="bayoux">
            {contract.jobTitle}
          </Text>

          <Text variant="xs" color="bayoux">
            {contract.employment?.company?.name}
          </Text>
        </Stack>

        <Stack gap="2" alignItems="flex-end" minWidth="160px">
          <Stack direction="row" alignItems="center">
            <Badge size="sm" type={contractStatusBadges[contract.status]} />

            <Text variant="sm" color="bayoux">
              {contractStatusLabels[contract.status]}
            </Text>
          </Stack>

          <Stack gap="2" direction="row" alignItems="center">
            {contract.active ? <Pill tone="purple">Active</Pill> : null}

            <Text variant="xs" color="bayoux">
              {contract.employment?.shortSlug}
            </Text>
          </Stack>

          {contract.effectiveDate && (
            <Text variant="xs" color="bayoux">
              Effective: {formatMonthDayYear(contract.effectiveDate)}
            </Text>
          )}

          {contract.terminationDate && (
            <Text variant="xs" color="bayoux">
              Terminated: {formatMonthDayYear(contract.terminationDate)}
            </Text>
          )}
        </Stack>
      </Stack>
    </components.Option>
  );
}
