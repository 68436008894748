import { AsideBadge } from '@remote-com/norma';
import type { ComponentPropsWithoutRef } from 'react';
import type { Merge, UnionToIntersection, ValueOf } from 'type-fest';

import type { NumberField } from '@/src/components/Form/DynamicForm/types';
import { InputField, InputCurrencyField } from '@/src/components/Ui/Form';
import { PAY_ELEMENT_UNIT_TYPES } from '@/src/domains/payElements/constants';

import { getFormattedPayElementUnit } from '../helpers';

import { DurationInputField } from './DurationInputField';

type UnderlyingProps = UnionToIntersection<
  | ComponentPropsWithoutRef<typeof InputCurrencyField>
  | ComponentPropsWithoutRef<typeof DurationInputField>
  | NumberField
>;

export type EmployeePayElementFieldProps = Merge<
  Omit<UnderlyingProps, 'type' | 'onChange' | 'value' | 'onValueChange'>,
  {
    payElementType: ValueOf<typeof PAY_ELEMENT_UNIT_TYPES>;
    label: string;
    currency?: string;
  }
>;

export const EmployeePayElementField = ({
  payElementType,
  currency,
  ...props
}: EmployeePayElementFieldProps) => {
  if (payElementType === PAY_ELEMENT_UNIT_TYPES.AMOUNT && currency) {
    return <InputCurrencyField {...props} currency={currency} />;
  }

  if (payElementType === PAY_ELEMENT_UNIT_TYPES.DURATION) {
    return <DurationInputField {...props} />;
  }

  const formattedUnit = getFormattedPayElementUnit({ type: payElementType });
  return (
    <InputField
      type="number"
      // @ts-expect-error `aside` is not typed yet
      aside={(asideProps) => <AsideBadge {...asideProps}>{formattedUnit}</AsideBadge>}
      {...props}
    />
  );
};
