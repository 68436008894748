import {
  useGet,
  usePost,
  usePut,
  usePatch,
  useDelete,
  useInvalidateQuery,
} from '@remote-com/data-layer';
import type {
  UseGetOptions,
  UsePostOptions,
  GetResponse,
  UsePutOptions,
  UseDeleteOptions,
  UsePatchOptions,
  GetRequest,
} from '@remote-com/data-layer';
import { toast } from '@remote-com/norma';
import type { AxiosError } from 'axios';
import omit from 'lodash/omit';
import { useQueryClient } from 'react-query';

import type { BillingDocument } from '@/src/api/config/employ/billingDocuments.types';
import type {
  CreateCorrectionPayload,
  EditInvoiceReportPayload,
  EditInvoiceReportRowPayload,
  InvoiceReport,
  PartialItem,
  UpsertAdjustmentPayload,
} from '@/src/api/config/employ/invoiceReport.types';
import { useUser } from '@/src/components/UserProvider/context';
import type { EmployeeInvoiceStatus } from '@/src/domains/invoices/constants';
import { invoiceStaleTime, invoiceReportCorrectionTypes } from '@/src/domains/invoices/constants';
import { getExceptionInfo } from '@/src/helpers/general';
import { useBackgroundJobStatus, BACKGROUND_JOB_STATES } from '@/src/hooks/useBackgroundJobStatus';

type CreateCorrectionQuery = {
  invoiceReportSlug: string;
  options: UsePostOptions<
    '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/credit',
    AxiosError<{ message: string }>
  >;
};

export const useCreateCorrection = ({ invoiceReportSlug, options }: CreateCorrectionQuery) => {
  const { invalidateQuery } = useInvalidateQuery();

  const mutation = usePost('/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/credit', {
    ...options,
    onSuccess: (res, vars, ctx) => {
      invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
        params: {
          pathParams: {
            slug: invoiceReportSlug,
          },
        },
      });
      options?.onSuccess?.(res, vars, ctx);
    },
  });

  const mutate = (
    { primaryReason, secondaryReason, explanation, supportingLink, type }: CreateCorrectionPayload,
    mutateOptions?: UsePostOptions<'/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/credit'>
  ) => {
    return mutation.mutate(
      {
        pathParams: {
          invoiceReportSlug,
        },
        bodyParams: {
          primaryReason,
          secondaryReason,
          description: explanation,
          supportingLink,
          partialCorrection: type === invoiceReportCorrectionTypes.PARTIAL,
        },
      },
      mutateOptions
    );
  };

  return {
    ...mutation,
    mutate,
  };
};

type GetInvoiceReportEndpoint =
  | '/api/v1/rivendell/invoice-reports/[slug]'
  | '/api/v1/employer/invoice-reports/[slug]';

type GetInvoiceReportDetails = {
  slug: string;
  options?: UseGetOptions<
    GetInvoiceReportEndpoint,
    unknown,
    GetResponse<GetInvoiceReportEndpoint>['data']
  >;
};

export const useGetInvoiceReportDetails = ({ slug, options }: GetInvoiceReportDetails) => {
  const { userIsAdmin } = useUser();

  const params = {
    pathParams: {
      slug,
    },
  };

  const defaultOptions = {
    staleTime: invoiceStaleTime,
    select: ({ data }: { data: InvoiceReport }) => data,
  };

  const enabled = options?.enabled ?? true;

  const adminInvoiceReportDetails = useGet('/api/v1/rivendell/invoice-reports/[slug]', {
    params,
    options: {
      ...defaultOptions,
      ...options,
      enabled: enabled && userIsAdmin,
    },
  });

  const employerInvoiceReportDetails = useGet('/api/v1/employer/invoice-reports/[slug]', {
    params: {
      pathParams: {
        slug,
      },
    },
    options: {
      ...defaultOptions,
      ...options,
      enabled: enabled && !userIsAdmin,
    },
  });

  return userIsAdmin ? adminInvoiceReportDetails : employerInvoiceReportDetails;
};

type GetBillingDocumentEndpoint = '/api/v1/billing-platform/employer/billing-documents/[slug]';

type GetBillingDocumentDetails = {
  slug: string;
  options?: UseGetOptions<
    GetBillingDocumentEndpoint,
    unknown,
    GetResponse<GetBillingDocumentEndpoint>['data']
  >;
};

export const useGetBillingDocumentDetails = ({ slug, options }: GetBillingDocumentDetails) => {
  const { userIsAdmin } = useUser();

  const defaultOptions = {
    staleTime: invoiceStaleTime,
    select: ({ data }: { data: BillingDocument }) => data,
    enabled: true,
  };

  const customerBillingDocument = useGet(
    '/api/v1/billing-platform/employer/billing-documents/[slug]',
    {
      params: {
        pathParams: {
          slug,
        },
      },
      options: {
        ...defaultOptions,
        ...options,
        enabled: (options?.enabled ?? defaultOptions.enabled) && !userIsAdmin,
      },
    }
  );

  const adminBillingDocument = useGet(
    '/api/v1/billing-platform/rivendell/billing-documents/[slug]',
    {
      params: {
        pathParams: {
          slug,
        },
      },
      options: {
        ...defaultOptions,
        ...options,
        enabled: (options?.enabled ?? defaultOptions.enabled) && userIsAdmin,
      },
    }
  );

  return userIsAdmin ? adminBillingDocument : customerBillingDocument;
};

export const useGetRebillOriginalInvoiceReportDetails = ({
  slug,
  options,
}: GetInvoiceReportDetails) => {
  const { data: creditNoteReportDetails } = useGetInvoiceReportDetails({
    slug,
    options,
  });

  return useGetInvoiceReportDetails({
    slug: creditNoteReportDetails?.correctedInvoiceReport?.slug ?? '',
    options: {
      ...options,
      enabled: options?.enabled && Boolean(creditNoteReportDetails?.correctedInvoiceReport?.slug),
    },
  });
};

type FinalizeInvoiceReport = {
  invoiceReportSlug: string;
  options: UsePatchOptions<'/api/v1/rivendell/invoice-reports/[slug]/finalize'>;
};

export const useFinalizeInvoiceReportMutation = ({
  invoiceReportSlug,
  options,
}: FinalizeInvoiceReport) => {
  const { invalidateQuery } = useInvalidateQuery();

  const mutation = usePatch('/api/v1/rivendell/invoice-reports/[slug]/finalize', {
    ...options,
    onSuccess: (res, vars, ctx) => {
      invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
        params: {
          pathParams: {
            slug: invoiceReportSlug,
          },
        },
      });
      invalidateQuery('/api/v1/rivendell/consolidated-service-invoices/[invoiceReportSlug]');
      options?.onSuccess?.(res, vars, ctx);
    },
  });

  const mutate = ({ dueDate }: { dueDate: string }) => {
    return mutation.mutate({
      pathParams: {
        slug: invoiceReportSlug,
      },
      bodyParams: {
        dueDate,
      },
    });
  };

  return {
    ...mutation,
    mutate,
  };
};

export const useAddPartialItemMutation = (invoiceReportSlug: string) => {
  const { invalidateQuery } = useInvalidateQuery();

  const mutation = usePost('/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items', {
    onSuccess: () => {
      invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
        params: {
          pathParams: {
            slug: invoiceReportSlug,
          },
        },
      });
    },
  });

  const mutate = (
    bodyParams: PartialItem,
    mutateOptions: UsePostOptions<'/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items'>
  ) => {
    return mutation.mutate(
      {
        pathParams: {
          invoiceReportSlug,
        },
        bodyParams,
      },
      mutateOptions
    );
  };

  return {
    ...mutation,
    mutate,
  };
};

export const useUpdatePartialItemMutation = (
  invoiceReportSlug: string,
  partialItemSlug: string
) => {
  const { invalidateQuery } = useInvalidateQuery();

  const mutation = usePut(
    '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items/[partialItemSlug]',
    {
      onSuccess: () => {
        invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
          params: {
            pathParams: {
              slug: invoiceReportSlug,
            },
          },
        });
      },
    }
  );

  const mutate = (
    bodyParams: PartialItem,
    mutateOptions: UsePutOptions<'/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items/[partialItemSlug]'>
  ) => {
    return mutation.mutate(
      {
        pathParams: {
          invoiceReportSlug,
          partialItemSlug,
        },
        bodyParams,
      },
      mutateOptions
    );
  };

  return {
    ...mutation,
    mutate,
  };
};

export const useUpsertPartialItemMutation = (
  invoiceReportSlug: string,
  partialItemSlug: string
) => {
  const addPartialItemMutation = useAddPartialItemMutation(invoiceReportSlug);

  const updatePartialItemMutation = useUpdatePartialItemMutation(
    invoiceReportSlug,
    partialItemSlug
  );

  return partialItemSlug ? updatePartialItemMutation : addPartialItemMutation;
};

export const useDeletePartialItem = () => {
  const { invalidateQuery } = useInvalidateQuery();

  const mutation = useDelete(
    '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items/[partialItemSlug]'
  );

  const mutate = ({
    invoiceReportSlug,
    partialItemSlug,
    options,
  }: {
    invoiceReportSlug: string;
    partialItemSlug: string;
    options?: UseDeleteOptions<'/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items/[partialItemSlug]'>;
  }) => {
    return mutation.mutate(
      {
        pathParams: {
          invoiceReportSlug,
          partialItemSlug,
        },
      },
      {
        ...options,
        onSuccess: (res, vars, ctx) => {
          invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
            params: {
              pathParams: {
                slug: invoiceReportSlug,
              },
            },
          });

          options?.onSuccess?.(res, vars, ctx);
        },
      }
    );
  };

  return {
    ...mutation,
    mutate,
  };
};

type GetBreakdownCSVEndpoint =
  | '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/breakdown-csv'
  | '/api/v1/employer/invoice-reports/[invoiceReportSlug]/breakdown-csv'
  | '/api/v1/billing-platform/rivendell/billing-documents/[billingDocumentSlug]/itemized-report-csv/download'
  | '/api/v1/billing-platform/employer/billing-documents/[billingDocumentSlug]/itemized-report-csv/download';

type GetBreakdownCSV = {
  invoiceReportSlug: string;
  isZuoraInvoice?: boolean;
  options?: UseGetOptions<GetBreakdownCSVEndpoint, unknown, GetResponse<GetBreakdownCSVEndpoint>>;
};

export const useGetInvoiceReportBreakdownCSV = ({
  invoiceReportSlug,
  isZuoraInvoice,
  options,
}: GetBreakdownCSV) => {
  const { userIsAdmin } = useUser();

  const adminBreakdownCSVLegacyQuery = useGet(
    '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/breakdown-csv',
    {
      params: {
        pathParams: {
          invoiceReportSlug,
        },
      },
      options,
    }
  );

  const employerBreakdownCSVLegacyQuery = useGet(
    '/api/v1/employer/invoice-reports/[invoiceReportSlug]/breakdown-csv',
    {
      params: {
        pathParams: {
          invoiceReportSlug,
        },
      },
      options,
    }
  );

  const adminBreakdownCSVBillingDocumentQuery = useGet(
    '/api/v1/billing-platform/rivendell/billing-documents/[billingDocumentSlug]/itemized-report-csv/download',
    {
      params: {
        pathParams: {
          billingDocumentSlug: invoiceReportSlug,
        },
      },
      options,
    }
  );

  const employerBreakdownCSVBillingDocumentQuery = useGet(
    '/api/v1/billing-platform/employer/billing-documents/[billingDocumentSlug]/itemized-report-csv/download',
    {
      params: {
        pathParams: {
          billingDocumentSlug: invoiceReportSlug,
        },
      },
      options,
    }
  );

  const adminBreakdownCSVQuery = isZuoraInvoice
    ? adminBreakdownCSVBillingDocumentQuery
    : adminBreakdownCSVLegacyQuery;

  const employerBreakdownCSVQuery = isZuoraInvoice
    ? employerBreakdownCSVBillingDocumentQuery
    : employerBreakdownCSVLegacyQuery;

  return userIsAdmin ? adminBreakdownCSVQuery : employerBreakdownCSVQuery;
};

type BulkEditInvoiceReports = {
  slugs: string[];
  status?: EmployeeInvoiceStatus;
  updateAdminNote?: boolean;
  adminNote?: string;
};

export const useBulkEditInvoiceReports = () => {
  const { invalidateQuery } = useInvalidateQuery();
  const queryClient = useQueryClient();

  const mutation = usePut('/api/v1/rivendell/invoice-reports');

  function mutate(
    variables: BulkEditInvoiceReports,
    options: UsePutOptions<'/api/v1/rivendell/invoice-reports'>
  ) {
    const bodyParams: BulkEditInvoiceReports = {
      slugs: variables.slugs,
      status: variables.status,
      updateAdminNote: variables.updateAdminNote,
      adminNote: variables.adminNote,
    };

    return mutation.mutate(
      { bodyParams },
      {
        ...options,
        onSuccess: (res, vars, ctx) => {
          invalidateQuery('/api/v1/rivendell/invoice-reports');

          const updatedInvoiceReports = res.data?.slugs;
          const updatedInvoiceReportsSlugs = new Set(updatedInvoiceReports);

          // to ensure every updated invoice report will be re-fetched
          queryClient.removeQueries({
            predicate: (query) => {
              return updatedInvoiceReportsSlugs.has(
                (query?.queryKey?.[1] as GetRequest<'/api/v1/rivendell/invoice-reports/[slug]'>)
                  ?.pathParams?.slug
              );
            },
          });

          options?.onSuccess?.(res, vars, ctx);
        },
      }
    );
  }

  return {
    ...mutation,
    mutate,
  };
};

type RefreshInvoiceReport = {
  invoiceReportSlug: string;
  options?: UsePostOptions<'/api/v1/rivendell/invoice-reports', AxiosError<{ message: string }>>;
};

export const useRefreshInvoiceReport = ({ invoiceReportSlug, options }: RefreshInvoiceReport) => {
  const { invalidateQuery } = useInvalidateQuery();
  const mutationKey = `/api/v1/rivendell/invoice-reports/${invoiceReportSlug}`;

  const mutation = usePost('/api/v1/rivendell/invoice-reports', {
    mutationKey,
    ...options,
  });

  function mutate({ shouldOverwriteManualEdits }: { shouldOverwriteManualEdits: boolean }) {
    return mutation.mutate(
      {
        bodyParams: { slug: invoiceReportSlug, shouldOverwriteManualEdits },
      },
      {
        onSuccess(res, vars, ctx) {
          options?.onSuccess?.(res, vars, ctx);
          invalidateQuery('/api/v1/rivendell/consolidated-service-invoices/[invoiceReportSlug]');
        },
      }
    );
  }

  return {
    ...mutation,
    mutationKey,
    mutate,
  };
};

export const useEditInvoiceReport = (invoiceReportSlug: string) => {
  const { invalidateQuery } = useInvalidateQuery();

  const mutation = usePut('/api/v1/rivendell/invoice-reports/[slug]', {
    onSuccess: () => {
      invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
        params: {
          pathParams: {
            slug: invoiceReportSlug,
          },
        },
      });
    },
  });

  function mutate(
    variables: EditInvoiceReportPayload,
    options: UsePutOptions<'/api/v1/rivendell/invoice-reports/[slug]'>
  ) {
    return mutation.mutate(
      { bodyParams: variables, pathParams: { slug: invoiceReportSlug } },
      options
    );
  }

  return {
    ...mutation,
    mutate,
  };
};

export function useFinalizeScheduledInvoiceReports(
  options: UsePatchOptions<'/api/v1/rivendell/invoice-reports/finalize'>
) {
  return usePatch('/api/v1/rivendell/invoice-reports/finalize', options);
}

type CreateRebill = {
  invoiceReportSlug: string;
  options: UsePostOptions<'/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/rebill'>;
};

export function useCreateRebill({ invoiceReportSlug, options }: CreateRebill) {
  const { invalidateQuery } = useInvalidateQuery();

  const mutation = usePost('/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/rebill');

  function mutate() {
    return mutation.mutate(
      {
        pathParams: {
          invoiceReportSlug,
        },
      },
      {
        ...options,
        onSuccess: (res, vars, ctx) => {
          invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
            params: {
              pathParams: {
                slug: invoiceReportSlug,
              },
            },
          });

          options?.onSuccess?.(res, vars, ctx);
        },
      }
    );
  }

  return {
    ...mutation,
    mutate,
  };
}

type ReprintPdf = {
  invoiceReportSlug: string;
  isZuoraInvoice?: boolean;
};

export function useReprintPDF({ invoiceReportSlug }: ReprintPdf) {
  const { invalidateQuery } = useInvalidateQuery();

  const reprintBillingDocument = usePost(
    '/api/v1/billing-platform/rivendell/billing-documents/[slug]/reprint-pdf',
    {
      onSuccess(response) {
        toast.success(response.data.message);
      },
      onError(error) {
        const { message } = getExceptionInfo(error);

        toast.error(message);
      },
    }
  );

  const backgroundJobTag = reprintBillingDocument?.data?.data?.job?.tags?.[0] ?? '';

  const { jobState } = useBackgroundJobStatus(backgroundJobTag, {
    onBackgroundJobCompleted: () => {
      if (jobState === BACKGROUND_JOB_STATES.COMPLETED) {
        toast.success('PDF document successfully updated!');

        invalidateQuery('/api/v1/billing-platform/rivendell/billing-documents/[slug]', {
          params: {
            pathParams: {
              slug: invoiceReportSlug,
            },
          },
        });
      }
    },
    reactQueryOptions: {
      refetchOnWindowFocus: false,
      enabled: Boolean(backgroundJobTag),
      onError(ex) {
        const { message } = getExceptionInfo(ex, {
          defaultMessage: 'Something went wrong, please try again later...',
        });

        toast.error(message);
      },
    },
  });

  const reprintInvoiceMutation = usePatch(
    '/api/v1/rivendell/remote-invoices/[invoiceSlug]/reprint',
    {
      onSuccess() {
        invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
          params: {
            pathParams: {
              slug: invoiceReportSlug,
            },
          },
        });
      },
    }
  );

  const reprintCreditNoteMutation = usePatch(
    '/api/v1/rivendell/credit-note-pdf/[creditNoteSlug]/reprint',
    {
      onSuccess() {
        invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
          params: {
            pathParams: {
              slug: invoiceReportSlug,
            },
          },
        });
      },
    }
  );

  return {
    isReprintingInvoicePdf: reprintInvoiceMutation.isLoading,
    reprintInvoicePdf: ({ invoiceSlug }: { invoiceSlug: string }) =>
      reprintInvoiceMutation.mutate({ pathParams: { invoiceSlug } }),
    isReprintingCreditNotePdf: reprintCreditNoteMutation.isLoading,
    reprintCreditNotePdf: ({ creditNoteSlug }: { creditNoteSlug: string }) =>
      reprintCreditNoteMutation.mutate({ pathParams: { creditNoteSlug } }),
    isReprintingBillingDocumentPdf: reprintBillingDocument.isLoading,
    reprintBillingDocumentPdf: () =>
      reprintBillingDocument.mutate({ pathParams: { slug: invoiceReportSlug } }),
  };
}

export function useCreateInvoiceReportAdjustment(invoiceReportSlug: string) {
  const { invalidateQuery } = useInvalidateQuery();
  const mutation = usePost('/api/v1/rivendell/invoice-report-adjustments');

  function mutate(
    variables: UpsertAdjustmentPayload,
    options?: UsePutOptions<'/api/v1/rivendell/invoice-report-adjustments/[adjustmentSlug]'>
  ) {
    mutation.mutate(
      { bodyParams: variables },
      {
        ...options,
        onSuccess(data, vars, ctx) {
          invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
            params: {
              pathParams: {
                slug: invoiceReportSlug,
              },
            },
          });

          options?.onSuccess?.(data, vars, ctx);
        },
      }
    );
  }

  return {
    ...omit(mutation, 'mutateAsync'),
    mutate,
  };
}

export function useUpdateInvoiceReportAdjustment(invoiceReportSlug: string) {
  const { invalidateQuery } = useInvalidateQuery();
  const mutation = usePut('/api/v1/rivendell/invoice-report-adjustments/[adjustmentSlug]');

  function mutate(
    { adjustmentSlug, ...variables }: UpsertAdjustmentPayload & { adjustmentSlug: string },
    options?: UsePutOptions<'/api/v1/rivendell/invoice-report-adjustments/[adjustmentSlug]'>
  ) {
    mutation.mutate(
      { pathParams: { adjustmentSlug }, bodyParams: variables },
      {
        ...options,
        onSuccess(data, vars, ctx) {
          invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
            params: {
              pathParams: {
                slug: invoiceReportSlug,
              },
            },
          });

          options?.onSuccess?.(data, vars, ctx);
        },
      }
    );
  }

  return {
    ...omit(mutation, 'mutateAsync'),
    mutate,
  };
}

export function useEditInvoiceReportRow(invoiceReportSlug: string) {
  const { invalidateQuery } = useInvalidateQuery();
  const mutation = usePut('/api/v1/rivendell/invoice-report-data/[rowSlug]');

  function mutate(
    { rowSlug, ...variables }: EditInvoiceReportRowPayload & { rowSlug: string },
    options?: UsePutOptions<'/api/v1/rivendell/invoice-report-data/[rowSlug]'>
  ) {
    mutation.mutate(
      { pathParams: { rowSlug }, bodyParams: variables },
      {
        ...options,
        onSuccess(data, vars, ctx) {
          invalidateQuery('/api/v1/rivendell/invoice-reports/[slug]', {
            params: {
              pathParams: {
                slug: invoiceReportSlug,
              },
            },
          });

          options?.onSuccess?.(data, vars, ctx);
        },
      }
    );
  }

  return {
    ...omit(mutation, 'mutateAsync'),
    mutate,
  };
}

type GetBillingDocumentByResourceIdEndpoint =
  | '/api/v1/billing-platform/rivendell/billing-documents/from-resource-identifier/[slug]'
  | '/api/v1/billing-platform/employer/billing-documents/from-resource-identifier/[slug]';

export function useGetBillingDocumentByResourceId(
  slug?: string,
  options?: UseGetOptions<
    GetBillingDocumentByResourceIdEndpoint,
    unknown,
    GetResponse<GetBillingDocumentByResourceIdEndpoint>['data']
  >
) {
  const { userIsAdmin } = useUser();

  return useGet(
    userIsAdmin
      ? '/api/v1/billing-platform/rivendell/billing-documents/from-resource-identifier/[slug]'
      : '/api/v1/billing-platform/employer/billing-documents/from-resource-identifier/[slug]',
    {
      params: {
        pathParams: {
          slug: slug!,
        },
      },
      options: {
        ...options,
        select: ({ data }) => data,
      },
    }
  );
}

type RegisterInvoiceReportInFonoaEndpoint =
  '/api/v1/billing-platform/rivendell/billing-documents/[slug]/register-legacy-invoice';

export function useRegisterInvoiceReportInFonoa() {
  const mutation = usePost(
    '/api/v1/billing-platform/rivendell/billing-documents/[slug]/register-legacy-invoice'
  );

  function mutate(slug: string, options?: UsePostOptions<RegisterInvoiceReportInFonoaEndpoint>) {
    return mutation.mutate({ pathParams: { slug } }, options);
  }

  return {
    ...omit(mutation, 'mutateAsync'),
    mutate,
  };
}
