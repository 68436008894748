// NOTE: This list of supported credit card brands has been composed
// by trying out different dummy numbers. The complete list is not
// documented in the `stripe-js` docs.
// https://stripe.com/docs/js/element/events/on_change?type=cardElement

export const CARD_BRAND = {
  AMEX: 'amex',
  DINERS: 'diners',
  DISCOVER: 'discover',
  JCB: 'jcb',
  MASTERCARD: 'mastercard',
  UNIONPAY: 'unionpay',
  VISA: 'visa',
};

export const CARD_BRAND_VALUES = Object.values(CARD_BRAND);
