import type { PropsWithChildren, Ref } from 'react';
import { forwardRef } from 'react';

import type { MenuItemProps } from '../menu-components/MenuItem';
import { MenuItem as BaseMenuItem } from '../menu-components/MenuItem';

import { StyledMenuItem } from './DropdownMenuItem.styled';

export type DropdownMenuItemProps = PropsWithChildren<MenuItemProps>;

export const DropdownMenuItem = forwardRef<typeof BaseMenuItem, DropdownMenuItemProps>(
  (props, ref?: Ref<any>) => (
    <BaseMenuItem {...props} MenuItemComponent={StyledMenuItem} ref={ref} />
  )
);
