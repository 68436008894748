import type { ComponentPropsWithoutRef } from 'react';
import { Children, cloneElement, isValidElement } from 'react';
import styled, { css } from 'styled-components';

import type { StackProps } from '../..';
import { Button, Stack } from '../..';

const StyledButton = styled(Button).attrs({ variant: 'raw' })`
  box-sizing: border-box;
  height: 32px;
  padding: 0 ${({ theme }) => theme.space[6]}px;

  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.none};
  border-right-width: 0;

  background-color: white;

  ${({ theme }) => css`
    ${theme.typography.sm}
    border-color: ${theme.colors.grey[300]};
    color: ${theme.colors.grey[600]};
  `}

  &:first-of-type {
    border-radius: ${({ theme }) => theme.borderRadius.sm} 0 0
      ${({ theme }) => theme.borderRadius.sm};
  }

  &:last-of-type {
    border-radius: 0 ${({ theme }) => theme.borderRadius.sm} ${({ theme }) => theme.borderRadius.sm}
      0;
    border-right-width: 1px;
  }

  &:hover {
    cursor: pointer;

    ${({ theme }) =>
      css`
        background-color: ${theme.colors.background.base};
        color: ${theme.colors.grey[900]};
      `}
  }

  ${({ theme }) => css`
    &[data-selected='true'] {
      ${theme.typography.smMedium}
      background-color: ${theme.colors.brand[50]};
      border-color: ${theme.colors.brand[300]};
      color: ${theme.colors.primary};
    }

    &[data-selected='true'] + & {
      border-left-color: ${theme.colors.brand[300]};
    }
  `}

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }

  &:focus + & {
    border-left-color: ${({ theme }) => theme.colors.primary};
  }
`;

export interface ContentSwitcherButtonProps
  extends Omit<ComponentPropsWithoutRef<'button'>, 'onClick'> {
  selected?: boolean;
  value?: string;
  onClick?: (value: string) => void;
}

function ContentSwitcherButton({
  children,
  onClick,
  selected,
  value,
  ...props
}: ContentSwitcherButtonProps) {
  return (
    <StyledButton
      data-selected={selected}
      onClick={() => {
        // Cast this as ContentSwitcher injects these props
        if (onClick) onClick(value as string);
      }}
      {...props}
    >
      {children}
    </StyledButton>
  );
}

export interface ContentSwitcherProps extends Omit<StackProps, 'onChange' | 'ref' | 'children'> {
  value?: string;
  onChange?: (value: string) => void;
  children:
    | React.ReactElement<ContentSwitcherButtonProps>[]
    | React.ReactElement<ContentSwitcherButtonProps>;
}

function ContentSwitcher({ children, onChange, value, ...props }: ContentSwitcherProps) {
  return (
    <Stack direction="row" gap={0} {...props}>
      {Children.map(children, (child, childIndex) => {
        if (!isValidElement(child)) {
          return null;
        }

        const childValue =
          child.props.value === undefined ? childIndex.toString() : child.props.value;

        return cloneElement(child, {
          ...child.props,
          value: childValue,
          selected: childValue === value,
          onClick: (val: string) => {
            onChange?.(val);
          },
        });
      })}
    </Stack>
  );
}

ContentSwitcher.Button = ContentSwitcherButton;

export default ContentSwitcher;
