import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import styled from 'styled-components';

const StyledNodeViewWrapper = styled(NodeViewWrapper)`
  width: 100%;
`;

const StyledNodeViewContent = styled(NodeViewContent)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
  }

  line-break: anywhere;
`;

export const SignatureBlockComponent = () => {
  return (
    <StyledNodeViewWrapper className="react-component">
      <StyledNodeViewContent />
    </StyledNodeViewWrapper>
  );
};
