import type { ClearIndicatorProps } from 'react-select';
import styled from 'styled-components';

import type { InputSelectProps } from '..';
import { SROnly } from '../../../core/text';
import { Box } from '../../../layout';
import type { $TSFixMe } from '../../../types';

const Button = styled(Box).attrs({ as: 'button', size: '18px' })<{
  $size: InputSelectProps['size'];
}>`
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border-radius: 50%;
  border: unset;
  cursor: pointer;
  display: grid;
  padding: unset;
  place-items: center;
  position: relative;
  z-index: 1;
  margin-top: ${({ theme, $size = 'md' }) => ($size === 'md' ? `-${theme.space[5]}px` : null)};
`;

const Icon = styled(Box).attrs({ as: 'svg', size: '8px' })`
  stroke: ${({ theme }) => theme.colors.grey[600]};
`;

export function ClearIndicator({
  innerProps,
  selectProps,
}: ClearIndicatorProps & { innerProps: $TSFixMe; selectProps: InputSelectProps }) {
  return (
    <Button {...innerProps} $size={selectProps.size}>
      <SROnly>Clear</SROnly>
      <Icon
        // @ts-expect-error
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <path
          d="M0.649994 0.649994L5.35 5.35M0.649994 5.35L5.35 0.65"
          strokeWidth="1.25"
          strokeLinecap="round"
        />
      </Icon>
      {/* Dummy SVG — react-select-event clear methods assume there are 2 hidden svg's and
      they function by pressing the first one.
       This is a quick way to keep tests passing without rewriting the lib  */}
      <svg aria-hidden="true" display="none" />
    </Button>
  );
}
