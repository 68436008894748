// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineAscending = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M3 5.25a.75.75 0 1 0 0 1.5h16a.75.75 0 0 0 0-1.5H3Z" /><path d="M3 11.25a.75.75 0 1 0 0 1.5h9a.75.75 0 0 0 0-1.5H3Z" /><path d="M3 17.25a.75.75 0 1 0 0 1.5h5a.75.75 0 0 0 0-1.5H3Z" /><path d="M18.25 13.81V20a.75.75 0 0 0 1.5 0v-6.19l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 1.06 1.06l1.72-1.72Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineAscending);
export { ForwardRef as IconV2OutlineAscending };