import { useGet } from '@remote-com/data-layer';
import { createContext, useContext, useCallback, useMemo } from 'react';
import type { UseQueryResult } from 'react-query';

import type { ProductEmploymentType } from '@/src/api/config/api.types';
import type { ProductUsageAndAccess } from '@/src/api/config/employ/productUsageAndAccess.types';
import { useUserContext } from '@/src/components/UserProvider';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { getProductAndFeatureAccessState } from '@/src/domains/productUsageAndAccess/helpers';
import type {
  FeatureAccessName,
  ProductAccessState,
} from '@/src/domains/productUsageAndAccess/types';
import { isEmployer } from '@/src/domains/registration/auth/helpers';

type ProductUsageAndAccessContextType = UseQueryResult<ProductUsageAndAccess, unknown> & {
  getProductAccessState: ({
    productEmploymentType,
    featureName,
  }: {
    productEmploymentType: ProductEmploymentType;
    featureName?: FeatureAccessName;
  }) => ProductAccessState | undefined;
};

const ProductUsageAndAccessContext = createContext<ProductUsageAndAccessContextType | undefined>(
  undefined
);

export const ProductUsageAndAccessContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isRecruitPackageEnabled = useIsFeatureFlagEnabled('recruit_package');

  const { user } = useUserContext();

  const query = useGet('/api/v1/product-features', {
    options: {
      select: (response) => response?.data,
      // Temporarily restricting to employers only, as employee use-cases don't exist yet.
      enabled: isRecruitPackageEnabled && isEmployer(user),
    },
  });

  const getProductAccessState = useCallback(
    ({
      productEmploymentType,
      featureName,
    }: {
      productEmploymentType: ProductEmploymentType;
      featureName?: FeatureAccessName;
    }) => {
      if (query.isLoading || query.error) {
        return undefined;
      }

      return getProductAndFeatureAccessState({
        productUsageAndAccess: query.data,
        productEmploymentType,
        featureName,
      });
    },
    [query.data, query.isLoading, query.error]
  );

  const contextValue = useMemo(
    () => ({
      ...query,
      getProductAccessState,
    }),
    [query, getProductAccessState]
  );

  return (
    <ProductUsageAndAccessContext.Provider value={contextValue}>
      {children}
    </ProductUsageAndAccessContext.Provider>
  );
};

export const useProductUsageAndAccessContext = () => {
  const context = useContext(ProductUsageAndAccessContext);

  if (context === undefined) {
    throw new Error(
      'useProductUsageAndAccessContext must be used within ProductUsageAndAccessContextProvider'
    );
  }

  return context;
};
