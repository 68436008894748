// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconFilters = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 24 24" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M5.84 2.06a4.47 4.47 0 0 0-2.73 1.49 4.5 4.5 0 0 0 6.57 6.13 4.52 4.52 0 0 0 1.08-1.76l.15-.42 5.25-.02c4.8-.02 5.27-.03 5.38-.09a.94.94 0 0 0 .38-.43c.08-.2.07-.78-.01-.96a.88.88 0 0 0-.5-.44c-.1-.03-1.84-.04-5.33-.04H10.9l-.1-.31a4.52 4.52 0 0 0-3.68-3.15 3.98 3.98 0 0 0-1.28 0m1.48 2.08c.71.24 1.28.82 1.55 1.54.1.27.1.35.1.82s0 .55-.1.82a2.6 2.6 0 0 1-1.6 1.56c-.24.08-.37.1-.77.1s-.53-.02-.77-.1a2.58 2.58 0 0 1-1.6-1.56 2.75 2.75 0 0 1-.08-1.35c.2-.94 1-1.73 1.95-1.93.33-.07.99-.02 1.32.1m9.52 8.92a5.12 5.12 0 0 0-1.34.41 4.59 4.59 0 0 0-2.3 2.74l-.1.31H7.92c-3.5 0-5.23.01-5.33.04a.86.86 0 0 0-.5.44c-.16.38-.09.97.14 1.21.25.27-.2.25 5.6.27l5.26.02.15.42a4.49 4.49 0 1 0 4.88-5.86 3.98 3.98 0 0 0-1.28 0m1.48 2.08c.71.24 1.28.82 1.55 1.54.1.27.1.35.1.82s0 .55-.1.82a2.6 2.6 0 0 1-1.6 1.56c-.24.08-.37.1-.77.1s-.53-.02-.77-.1a2.58 2.58 0 0 1-1.6-1.56 2.75 2.75 0 0 1-.08-1.35c.2-.94 1-1.73 1.95-1.93.33-.07.99-.02 1.32.1" fillRule="evenodd" /></svg>;
const ForwardRef = forwardRef(IconFilters);
export { ForwardRef as IconFilters };