import { Box, Text } from '@remote-com/norma';
import styled from 'styled-components';

export const NoHoverContent = styled(Box)`
  visibility: visible;
  padding: 0px 4px;
`;

export const HoverContent = styled(Box)`
  white-space: nowrap;
  border-radius: 4px;

  /* <HoverableContent /> (<HoverContent />'s parent) could be inline,
   * which is the case for manually-entered fields. The following
   * combination of rules works for both block- and inline- cases
   * when centering the EDIT label inside of its parent. */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  min-width: 100%;
`;

export const HoverableContent = styled(Box)`
  position: relative;
  cursor: ${({ $enableHover }) => ($enableHover ? 'pointer' : 'auto')};

  ${HoverContent} {
    visibility: hidden;
  }

  &:hover {
    ${NoHoverContent} {
      visibility: ${({ $enableHover }) => ($enableHover ? 'hidden' : 'visible')};
    }

    ${HoverContent} {
      visibility: visible;
    }
  }
`;

export const HoverText = styled(Text).attrs({ variant: 'xsMedium', textTransform: 'uppercase' })`
  font-weight: 600;
`;
