import { InputPhoneNumber, PHONE_NUMBER_SECTION_LABEL } from '@remote-com/norma';
import { useField } from 'formik';
import { useState } from 'react';

import {
  getCountryDataByCountryCode,
  getCountryFromPhoneNumber,
  getStructuredNumberFromInternationalNumber,
  removeSpaces,
} from './helpers';

/**
 * PhoneNumberField
 *
 * Phone number input separated into a country dial code selection and an input for the national phone number.
 *
 * @export
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').TelephoneField, 'type'> & {id?: string}} props
 * @return {JSX.Element}
 */
export function PhoneNumberField({
  name,
  label,
  description,
  options,
  displayLabel,
  'data-testid': dataTestid,
  ...props
}) {
  const [
    { value: internationalPhoneNumber, onChange, ...field },
    { error, touched },
    { setValue, setTouched },
  ] = useField(name);

  const countriesByCountryCode = getCountryDataByCountryCode(options);

  const [country, setCountry] = useState(
    getCountryFromPhoneNumber(countriesByCountryCode, internationalPhoneNumber)
  );

  // TODO: Memoize splitting the number and finding the initial country.
  const { prefix, phoneNumber: nationalPhoneNumber } = getStructuredNumberFromInternationalNumber(
    internationalPhoneNumber,
    country
  );

  function handleBlur(inputName) {
    if (!touched && inputName === PHONE_NUMBER_SECTION_LABEL.PHONE_NUMBER) {
      setTouched();
    }
  }

  function handleCountryCodeChange(newCountry) {
    // Use + as a prefix, as shown in the UI, in case the country code is changed through the UI.
    setValue(`+${newCountry.dialCode}${nationalPhoneNumber}`);
    setCountry(newCountry);
  }

  function handlePhoneNumberChange(event) {
    const valueWithoutSpaces = removeSpaces(event.target.value);

    if (country) {
      // Preserve the current prefix in case only the phone number is being changed.
      return setValue(`${prefix}${country.dialCode}${valueWithoutSpaces}`);
    }

    // Accept the inputs value in case of an unknown country code to not prevent the user from entering a number.
    return setValue(`${prefix}${valueWithoutSpaces}`);
  }

  const fieldsWithErrors = [];
  if (touched && error) {
    if (!country) {
      fieldsWithErrors.push('COUNTRY_CODE');
    }
    if (error) {
      fieldsWithErrors.push('PHONE_NUMBER');
    }
  }

  return (
    <InputPhoneNumber
      name={name}
      value={internationalPhoneNumber}
      description={description}
      field={field}
      errorText={error}
      fieldsWithErrors={fieldsWithErrors}
      currentCountry={country}
      currentNationalPhoneNumber={nationalPhoneNumber}
      onBlur={handleBlur}
      onCountryCodeChange={handleCountryCodeChange}
      onPhoneNumberChange={handlePhoneNumberChange}
      testId={dataTestid}
      label={label}
      options={options}
      {...props}
    />
  );
}
