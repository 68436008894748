import type { PropsWithChildren } from 'react';

import type { StackProps } from '../../layout';

import { CardWrapper } from './Card.styled';

export type CardSize = 'sm' | 'md';

export type CardTone = 'neutral' | 'primary';

export interface CardProps extends StackProps {
  /**
   * Changes the padding inside the card.
   * */
  size?: CardSize;
  /**
   * Changes the appearance of a card.
   * */
  tone?: CardTone;
}

export const Card = ({
  children,
  size = 'sm',
  tone = 'neutral',
  ...props
}: PropsWithChildren<CardProps>) => (
  <CardWrapper $size={size} $tone={tone} {...props}>
    {children}
  </CardWrapper>
);
