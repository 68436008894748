import { IconLock } from '@remote-com/norma/icons/IconLock';
import type { ReactNode } from 'react';

import { useUserContext } from '@/src/components/UserProvider';
import { COMPANY_COMPLETE_ONBOARDING } from '@/src/constants/routes';
import { companyStatus } from '@/src/domains/companies/constants';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { productEmploymentTypes } from '@/src/domains/pricing/constants';
import {
  useProductUsageAndAccessContext,
  PRODUCT_ACCESS_STATE,
} from '@/src/domains/productUsageAndAccess';

import { PaywallPage } from './PaywallPage';
import type { PageHeaderProps, PaywallPageProps } from './PaywallPage';
import { PaywallPageSkeleton } from './PaywallPageSkeleton';

const SIGN_UP_PART_TWO_CONTENT: Omit<
  PaywallPageProps,
  'title' | 'description' | 'img' | 'pageHeader'
> = {
  cta: {
    text: 'Finish setting up account',
    href: COMPANY_COMPLETE_ONBOARDING,
  },
  pill: {
    icon: IconLock,
    tone: 'warning' as const,
    text: 'Finish account setup to access',
  },
};

type Props = {
  /** React elements to render when access is granted */
  children: ReactNode;

  /**
   * Configuration for the scenario where the user has not completed the second part of the signup process.
   */
  signUpPartTwo: Omit<PaywallPageProps, 'cta' | 'pill'>;

  /**
   * Configuration for the scenario where the user has not yet started a free trial.
   * */
  freeTrial: Omit<PaywallPageProps, 'pill'> & {
    pill: {
      /** Text to display in the pill component */
      text: string;
    };
  };

  /**
   * Optional configuration for a custom paywall page with its own visibility condition.
   */
  customPaywall?: {
    visibilityCondition: () => boolean;
  } & PaywallPageProps;

  /** Page header and site title. */
  pageHeader: PageHeaderProps;

  /** Loading state for the page */
  isLoading?: boolean;
};

/**
 * A component that implements access control for Recruit plan features based on various conditions.
 * Renders paywall pages for:
 * - Incomplete sign up (when company status is pending)
 * - Users who have never subscribed to the Recruit plan
 * - Custom paywall conditions
 *
 * @example
 * ```tsx
 * <PaywallPageRecruit
 *   signUpPartTwo={{
 *     title: "Complete Your Setup",
 *     description: "...",
 *     img: "..."
 *   }}
 *   freeTrial={{
 *     title: "Start Your Free Trial",
 *     description: "...",
 *     cta: { text: "Start Trial", href: "/trial" },
 *     pill: { text: "Free Trial Available" },
 *     img: "..."
 *   }}
 *   pageHeader={{ title: "Recruit Features" }}
 * >
 *   <ProtectedFeature />
 * </PaywallPageRecruit>
 * ```
 */
export function PaywallPageRecruit({
  children,
  signUpPartTwo,
  freeTrial,
  customPaywall,
  isLoading,
  pageHeader,
}: Props) {
  const isRecruitPackageEnabled = useIsFeatureFlagEnabled('recruit_package');
  const { user } = useUserContext();

  const {
    isLoading: isLoadingProductAccessState,
    error,
    getProductAccessState,
  } = useProductUsageAndAccessContext();

  const productAccessState = getProductAccessState({
    productEmploymentType: productEmploymentTypes.RECRUIT,
  });

  const isLoadingData = !!isLoading || isLoadingProductAccessState;

  if (isLoadingData || error) {
    return <PaywallPageSkeleton pageHeader={pageHeader} isLoading={isLoadingData} error={error} />;
  }

  if (!isRecruitPackageEnabled) {
    return <>{children}</>;
  }

  if (user?.companyStatus === companyStatus.PENDING) {
    return (
      <PaywallPage
        title={signUpPartTwo.title}
        description={signUpPartTwo.description}
        cta={SIGN_UP_PART_TWO_CONTENT.cta}
        pill={SIGN_UP_PART_TWO_CONTENT.pill}
        img={signUpPartTwo.img}
        Footer={signUpPartTwo.Footer}
        pageHeader={pageHeader}
      />
    );
  }

  if (productAccessState === PRODUCT_ACCESS_STATE.NEVER_SUBSCRIBED) {
    return (
      <PaywallPage
        title={freeTrial.title}
        description={freeTrial.description}
        cta={freeTrial.cta}
        pill={{ icon: IconLock, tone: 'warning', text: freeTrial.pill.text }}
        img={freeTrial.img}
        Footer={freeTrial.Footer}
        pageHeader={pageHeader}
      />
    );
  }

  if (customPaywall && customPaywall?.visibilityCondition() === true) {
    return (
      <PaywallPage
        title={customPaywall.title}
        description={customPaywall.description}
        cta={customPaywall.cta}
        pill={customPaywall.pill}
        img={customPaywall.img}
        Footer={customPaywall.Footer}
        pageHeader={pageHeader}
      />
    );
  }

  return <>{children}</>;
}
