import { css } from 'styled-components';

/**
 * Do not use type="number", it has A11Y and UX issues.
  Prefer using inputmode attribute
  https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
  https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/
  */
export const inputModeAttrs = {
  type: 'text',
  inputMode: 'decimal',
  pattern: '^[0-9.]*$', // accepts numbers and dots (eg 10, 15.50)
} as const;

type PolymorphicSelectValue = undefined | null | string | number | Record<any, any> | Array<any>;

const isFilled = (value: PolymorphicSelectValue) => {
  if (typeof value === 'string') {
    return value !== '';
  }

  if (typeof value === 'undefined' || value === null) {
    return false;
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return true;
};

export const isValueFilled = (
  value: PolymorphicSelectValue,
  defaultValue?: PolymorphicSelectValue,
  hasUserInput?: boolean
) => {
  if (hasUserInput) {
    return true;
  }

  return isFilled(value) || isFilled(defaultValue);
};

export function defaultTransformCreatedOption(value: string) {
  return value.replace(/\s/g, '_').toLowerCase();
}

const scrollbarThumbVisible = css`
  /* Webkit Browsers */
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbarThumbColor);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scrollbarTrackColor);
  }
`;

/**
 * CSS rules for a custom scrollbar.
 * The behavior is slightly different depending on the browser:
 * - Firefox does not allow for adjusting the width of the elements, only accepts a 'thin' modifier. FF also does
 * not allow hiding/showing scrollbar based on hover state — it's always visible
 * - Webkit-based browsers (Chrome, Safari, etc.) allow for hiding/showing scrollbar based on the hover state
 */
export const customScrollbar = css`
  --scrollbarThumbColor: ${({ theme }) => theme.colors.grey[300]};
  --scrollbarTrackColor: transparent;

  /* Firefox */
  scrollbar-color: var(--scrollbarThumbColor) transparent;
  scrollbar-width: thin;

  overflow: overlay;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: transparent;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  &:hover,
  &:focus-within {
    ${scrollbarThumbVisible}
  }
`;
