import type { ChangeEvent, InputHTMLAttributes, MouseEvent, Ref } from 'react';
import { forwardRef, memo } from 'react';

import { HTMLRendered } from '../../core/html-rendered';

import {
  Body,
  Checkbox,
  CheckboxState,
  Description,
  Input,
  Label,
  Wrapper,
} from './InputCheckbox.styled';

export type InputCheckboxSize = 'sm' | 'md';

const disabledProps = {
  readOnly: true,
  'aria-disabled': 'true',
  onChange: (e: ChangeEvent) => {
    // aria-disabled does not prevent interaction
    // as the disabled attr
    e.preventDefault();
  },
};

export type InputCheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  /** Label for the input */
  label: string | React.ReactNode;
  /** Label for the input */
  'aria-label'?: string | object;
  /** Checkbox Size */
  size?: InputCheckboxSize;
  /** Whether the input has an error */
  hasError?: boolean;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Whether the input is read only */
  readOnly?: boolean;
  /** Whether the input is checked (for CheckboxState styling purposes) */
  checked?: boolean;
  /** Description for the input */
  description?: string | React.ReactNode;
  /**
   * DEPRECATED: Whether the label should be hidden
   * @deprecated Works only in v1
   * */
  hideLabel?: boolean;
  /** Allows to provide a custom Input component to be rendered instead of the native input. This is mainly for Formik integration */
  Input?: Function;
  /** Change event callback */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Allows an action to be taken when the wrapping label of the checkbox is clicked */
  handleCheckboxClick?: (evt: MouseEvent<HTMLElement>) => void;
};

const InputCheckboxBase = forwardRef(
  (
    {
      label,
      'aria-label': ariaLabel,
      hideLabel,
      description = '',
      size = 'md',
      disabled,
      readOnly,
      Input: InputComponent = (props: InputHTMLAttributes<HTMLInputElement>) => (
        <input {...props} type="checkbox" />
      ),
      hasError,
      handleCheckboxClick,
      ...props
    }: InputCheckboxProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const hasDescription = !!description;
    const isDisabled = !!disabled || !!readOnly;

    return (
      <Checkbox ref={ref} isDisabled={!!isDisabled} onClick={handleCheckboxClick}>
        <Wrapper>
          <Input>
            <InputComponent
              type="checkbox"
              aria-label={ariaLabel || (label && (typeof label === 'string' ? label : null))}
              disabled={isDisabled}
              {...props}
              {...(isDisabled && disabledProps)}
            />
            <CheckboxState $hasError={hasError} $checked={props.checked} $size={size} />
          </Input>
          <Body>
            {typeof label === 'string' ? (
              <Label isDisabled={!!isDisabled} hidden={hideLabel} size={size}>
                {label}
              </Label>
            ) : (
              label
            )}
            {hasDescription && (
              <HTMLRendered Tag={Description} mt={1} isDisabled={!!isDisabled}>
                {description}
              </HTMLRendered>
            )}
          </Body>
        </Wrapper>
      </Checkbox>
    );
  }
);

export const InputCheckbox = memo(InputCheckboxBase);
