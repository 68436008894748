import { css } from 'styled-components';

export function focusVisible(rules) {
  return css`
    .js-focus-visible &:focus {
      outline: none;
    }

    &:focus-visible {
      ${rules}
    }

    &:focus.focus-visible {
      ${rules}
    }
  `;
}
