import React from 'react';
import styled from 'styled-components';

import { Box } from '../../../layout';

const StyledNoOptionsMessage = styled(Box).attrs({ px: 5 })`
  color: ${({ theme }) => theme.colors.grey[900]};
  display: grid;
  font-size: 14px;
  height: var(--field-select-menu-item-height);
  place-items: center;
`;

export function NoOptionsMessage({ children }: { children: React.ReactNode }) {
  return <StyledNoOptionsMessage>{children}</StyledNoOptionsMessage>;
}
