import type { ButtonIconProps } from '@remote-com/norma';
import { Box, Stack, Text, sharedTransition } from '@remote-com/norma';
import { IconV2OutlineEye } from '@remote-com/norma/icons/IconV2OutlineEye';
import { IconV2OutlineEyeOff } from '@remote-com/norma/icons/IconV2OutlineEyeOff';
import { forwardRef } from 'react';
import type { ElementRef, ElementType, MouseEventHandler, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { ButtonIcon } from '@/src/components/Button';

/* -------------------------------------------------------------------------------------------------
 * ConfigurationList
 * -----------------------------------------------------------------------------------------------*/

type ConfigurationListProps = PropsWithChildren<{}>;

const StyledConfigurationList = styled(Stack)`
  --column-list-space: ${({ theme }) => theme.space[2]}px;

  gap: var(--column-list-space);
`;

export const ConfigurationList = forwardRef<ElementRef<'div'>, ConfigurationListProps>(
  (props, ref) => {
    return <StyledConfigurationList {...props} ref={ref} />;
  }
);

/* -------------------------------------------------------------------------------------------------
 * ConfigurationListItem
 * -----------------------------------------------------------------------------------------------*/

type ConfigurationListItemProps = PropsWithChildren<{
  isActive?: boolean;
}>;

const StyledConfigurationListItem = styled(Stack)<{ $isActive: boolean }>`
  --column-list-item-height: 36px;
  --column-list-item-interaction-color: ${({ theme }) => theme.colors.grey[50]};
  --column-list-item-space: ${({ theme }) => theme.space[2]}px;

  ${sharedTransition('background-color, color')}

  align-items: center;
  border-radius: calc(var(--column-list-item-height) / 2);
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.grey[900] : theme.colors.grey[500])};
  flex-direction: row;
  gap: var(--column-list-item-space);
  height: var(--column-list-item-height);
  padding: var(--column-list-item-space);

  &:hover {
    background-color: var(--column-list-item-interaction-color);
  }
`;

export const ConfigurationListItem = forwardRef<ElementRef<'div'>, ConfigurationListItemProps>(
  ({ isActive = true, ...props }, ref) => {
    return <StyledConfigurationListItem {...props} $isActive={isActive} ref={ref} />;
  }
);

/* -------------------------------------------------------------------------------------------------
 * ConfigurationListItemIcon
 * -----------------------------------------------------------------------------------------------*/

type ConfigurationListItemIconProps = {
  icon: ElementType;
};

const StyledConfigurationListItemIconWrapper = styled(Box)`
  --column-list-item-icon-wrapper-size: 26px;

  display: grid;
  fill: currentColor;
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--column-list-item-icon-wrapper-size);
  place-items: center;
  width: var(--column-list-item-icon-wrapper-size);
`;

const StyledConfigurationListItemIcon = styled.svg`
  --column-list-item-icon-size: 16px;

  height: var(--column-list-item-icon-size);
  width: var(--column-list-item-icon-size);
`;

export const ConfigurationListItemIcon = ({ icon }: ConfigurationListItemIconProps) => {
  return (
    <StyledConfigurationListItemIconWrapper>
      <StyledConfigurationListItemIcon as={icon} />
    </StyledConfigurationListItemIconWrapper>
  );
};

/* -------------------------------------------------------------------------------------------------
 * ConfigurationListItemName
 * -----------------------------------------------------------------------------------------------*/

type ConfigurationListItemNameProps = PropsWithChildren<{}>;

const StyledConfigurationListItemName = styled(Text)`
  ${({ theme }) => theme.typography.smMedium}

  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 0;

  &:first-child {
    padding-left: ${({ theme }) => theme.space[3]}px;
  }
`;

export const ConfigurationListItemName = (props: ConfigurationListItemNameProps) => {
  return <StyledConfigurationListItemName {...props} />;
};

/* -------------------------------------------------------------------------------------------------
 * ConfigurationListItemActions
 * -----------------------------------------------------------------------------------------------*/

type ConfigurationListItemActionsProps = PropsWithChildren<{}>;

const StyledConfigurationListItemActions = styled(Stack)`
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  gap: var(--column-list-item-space, ${({ theme }) => theme.space[2]}px);
`;

export const ConfigurationListItemActions = (props: ConfigurationListItemActionsProps) => {
  return <StyledConfigurationListItemActions {...props} />;
};

/* -------------------------------------------------------------------------------------------------
 * ConfigurationListItemButton
 * -----------------------------------------------------------------------------------------------*/

type ConfigurationListItemButtonProps = PropsWithChildren<{
  icon: ElementType;
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  tone?: ButtonIconProps['tone'];
}>;

export const ConfigurationListItemButton = ({
  icon,
  label,
  onClick,
  tone = 'secondary',
  ...props
}: ConfigurationListItemButtonProps) => {
  return (
    <ButtonIcon
      {...props}
      Icon={icon}
      label={label}
      onClick={onClick}
      size="xs"
      tone={tone}
      variant="ghost"
    />
  );
};

/* -------------------------------------------------------------------------------------------------
 * ConfigurationListItemVisibilityToggle
 * -----------------------------------------------------------------------------------------------*/

const configurationListItemVisibilityToggleMessages = {
  show: 'Show',
  hide: 'Hide',
};

type ConfigurationListItemVisibilityToggleProps = {
  isActive?: boolean;
} & Omit<ConfigurationListItemButtonProps, 'icon' | 'label' | 'tone' | 'aria-checked'>;

export const ConfigurationListItemVisibilityToggle = ({
  isActive = false,
  ...props
}: ConfigurationListItemVisibilityToggleProps) => {
  const icon = isActive ? IconV2OutlineEye : IconV2OutlineEyeOff;

  const label = isActive
    ? configurationListItemVisibilityToggleMessages.hide
    : configurationListItemVisibilityToggleMessages.show;

  return (
    <ConfigurationListItemButton
      {...props}
      aria-checked={isActive || undefined}
      icon={icon}
      label={label}
    />
  );
};
