/**
 * The following implementation required some complex tweaks like debounce and deep equality
 * to make sure we keep in sync table interactions and network interactions.
 *
 * When attempting to implement a new event, make sure the event only gets triggered once and all the data is correct.
 */
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useCallback } from 'react';

import { useTableUserSettingsContext } from '@/src/components/Table/Components/TableUserSettingsProvider';
import { isTest } from '@/src/helpers/general';
import { useDebounce } from '@/src/hooks/useDebounce';

import {
  trackViewEvent,
  trackExportDataEvent,
  trackSaveTableConfigEvent,
  trackSearchEvent,
  trackSortEvent,
  trackFilterEvent,
  trackEditColumnsEvent,
  getColumnNames,
  trackSaveFilter,
  trackChangeColumnsOrderEvent,
} from '../helpers/tableTrackingEvents';

const EVENT_TRACKING_DEBOUNCE_TIMEOUT = isTest() ? 0 : 300;

/**
 *
 * @param {func} effect A callback that should be executed after a dependency change
 * @param {*} dependencies The hook dependencies that will trigger the callback when the values changes after a deep comparison
 * @param {boolean} config.disabled Prevents the hook from firing the callback
 */
const useDebouncedEffectWithDeepValidation = (effect, dependencies, config) => {
  const prevDependencies = useRef(dependencies);
  const debouncedEffect = useDebounce(effect, EVENT_TRACKING_DEBOUNCE_TIMEOUT);
  const isSameData = isEqual(prevDependencies.current, dependencies);

  useEffect(() => {
    if (config?.disabled || isSameData) {
      return;
    }

    debouncedEffect?.({ prevDependencies: prevDependencies.current });

    prevDependencies.current = dependencies;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.disabled, isSameData]);
};

export const useTableTracker = ({
  tableName,
  tableInstance,
  totalCount,
  isLoading,
  loadedSavedFilter,
}) => {
  const { isCompactTable } = useTableUserSettingsContext();
  const effectCalled = useRef(false);
  const loadingRef = useRef(isLoading);
  const changeResolved = loadingRef.current && !isLoading;
  loadingRef.current = isLoading;
  const countResults = totalCount;
  const exportDataCallbackEvent = useCallback(
    (exportProps) => {
      if (!tableName) {
        return;
      }

      trackExportDataEvent({ tableName, countResults, ...exportProps });
    },
    [tableName, countResults]
  );

  const saveTableConfigCallbackEvent = useCallback(
    (config) => {
      if (!tableName) {
        return;
      }

      trackSaveTableConfigEvent({ tableName, config });
    },
    [tableName]
  );

  const saveFilterCallbackEvent = useCallback(
    (config) => {
      if (!tableName) {
        return;
      }

      trackSaveFilter({ tableName, config });
    },
    [tableName]
  );

  const changeColumnsOrderCallbackEvent = useCallback(
    (props) => {
      if (!tableName) {
        return;
      }

      trackChangeColumnsOrderEvent({ tableName, ...props });
    },
    [tableName]
  );

  useDebouncedEffectWithDeepValidation(
    () => trackSearchEvent({ tableName, countResults }),
    tableInstance.state.globalFilter,
    {
      disabled: !tableName || !changeResolved,
    }
  );

  useDebouncedEffectWithDeepValidation(
    () =>
      trackSortEvent({
        tableName,
        sortBy: tableInstance.state.sortBy,
        countResults,
      }),
    tableInstance.state.sortBy[0],
    {
      disabled: !tableName || !changeResolved,
    }
  );

  useDebouncedEffectWithDeepValidation(
    () =>
      trackFilterEvent({
        tableName,
        filters: tableInstance.state.filters,
        loadedSavedFilter,
        countResults,
      }),

    tableInstance.state.filters,
    {
      disabled: !tableName || !changeResolved,
    }
  );

  const visibleColumns = getColumnNames(tableInstance.visibleColumns);

  useDebouncedEffectWithDeepValidation(
    ({ prevDependencies: prevVisibleColumns }) =>
      trackEditColumnsEvent({
        tableName,
        visibleColumns,
        prevVisibleColumns,
      }),
    visibleColumns,
    {
      disabled: !tableName,
    }
  );

  useEffect(() => {
    if (tableName && !effectCalled.current && changeResolved) {
      trackViewEvent({
        tableName,
        columns: getColumnNames(tableInstance.columns),
        countResults,
        isCompactTable,
      });

      effectCalled.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeResolved]);

  return {
    exportDataEvent: exportDataCallbackEvent,
    saveTableConfigEvent: saveTableConfigCallbackEvent,
    saveFilterEvent: saveFilterCallbackEvent,
    changeColumnsOrderEvent: changeColumnsOrderCallbackEvent,
  };
};
