import getConfig from 'next/config';

const {
  publicRuntimeConfig: {
    API_BASE_URL_CLIENT,
    COMPENSATION_API_BASE_URL,
    TALENT_API_BASE_URL,
    REMOTE_AI_API_BASE_URL,
  },
  serverRuntimeConfig: { API_BASE_URL_SERVER },
} = getConfig();

export const V1ApiEndpoint = '/api/v1';
export const V2ApiEndpoint = '/api/v2';
// eslint-disable-next-line remote/disallow-data-layer-endpoint-assignment
export const adminV1ApiEndpoint = '/api/v1/rivendell';

export const VERSIONS = {
  API_V1: V1ApiEndpoint,
  API_V2: V2ApiEndpoint,
  ADMIN_V1: adminV1ApiEndpoint,
};

export const SERVICES_KEYS = {
  TIGER: 'TIGER',
  GROND: 'GROND',
  BOBA: 'BOBA',
  'REMOTE-AI': 'REMOTE-AI',
};

// Warning: If you're going to add an API endpoint in the /pages folder in next, keep in mind that the staging environment will not work.
// See https://remote-com.slack.com/archives/CFEH4JTSM/p1649771729636769

export const SERVICE_CONFIG = {
  [SERVICES_KEYS.TIGER]: {
    baseURL: process.browser ? API_BASE_URL_CLIENT : API_BASE_URL_SERVER,
    headers: {
      'Dragon-Version': process.env.RELEASE,
      'Referrer-Policy': 'strict-origin-when-cross-origin',
    },
    defaultVersion: VERSIONS.API_V1,
    availableVersions: [VERSIONS.API_V1, VERSIONS.API_V2, VERSIONS.ADMIN_V1],
  },
  [SERVICES_KEYS.GROND]: {
    headers: null, // overrides default ApiClient headers, currently SERVICE_CONFIG.TIGER.headers
    baseURL: COMPENSATION_API_BASE_URL,
    defaultVersion: VERSIONS.API_V1,
    availableVersions: [VERSIONS.API_V1],
  },
  [SERVICES_KEYS.BOBA]: {
    headers: {
      'Dragon-Version': process.env.RELEASE,
      'Referrer-Policy': 'strict-origin-when-cross-origin',
    },
    baseURL: TALENT_API_BASE_URL,
    defaultVersion: VERSIONS.API_V1,
    availableVersions: [VERSIONS.API_V1],
  },
  [SERVICES_KEYS['REMOTE-AI']]: {
    headers: {
      'Dragon-Version': process.env.RELEASE,
      'Referrer-Policy': 'strict-origin-when-cross-origin',
    },
    baseURL: REMOTE_AI_API_BASE_URL,
    defaultVersion: VERSIONS.API_V1,
    availableVersions: [VERSIONS.API_V1],
  },
};

export const getServiceConfiguration = (serviceKey) => {
  return SERVICE_CONFIG[serviceKey] || SERVICE_CONFIG.TIGER;
};
