import Head from 'next/head';
import PropTypes from 'prop-types';

import { SvgUse } from '@/src/components/Svg/SvgUse';
import { getSvgFlagName } from '@/src/domains/countries/helpers';

// prefix with BASE_URL to work with Storybook
const svgPath = '/images/flags/sprite/flags.svg';

// Preload SVG sprite as it's quite big, ~1MB (not compressed).
export const PreloadFlagsSprite = () => (
  <Head>
    <link rel="preload" as="image" href={svgPath} type="image/svg+xml" key={svgPath} />
  </Head>
);

export const FlagIcon = ({ name, width = 20, height = 20, ...props }) => (
  <SvgUse
    href={`${svgPath}#${getSvgFlagName(name)}`}
    width={width}
    height={height}
    aria-hidden="true"
    {...props}
  />
);

FlagIcon.propTypes = {
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
