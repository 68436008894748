import { isValid } from 'date-fns';

/**
 * Date constructor parses yyyy-mm and yyyy-m dates differently,
 * so we normalize it here.
 * yyyy-m → yyyy-mm conversion
 */
export function normalizeMonth(dateString: string) {
  if (/^\d{4}-\d{1}$/.test(dateString)) {
    const [year, month] = dateString.split('-');
    dateString = `${year}-0${month}`;
  }

  return dateString;
}

export function addTimeIfMissing(dateString: string) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, time] = dateString.split('T');

  if (!time) {
    return `${dateString}T00:00:00.000`;
  }

  return dateString;
}

export function hasTimezone(dateString: string) {
  // datetime strings with UTC timezone end with a 'Z' character
  if (dateString[dateString.length - 1] === 'Z') {
    return true;
  }

  // Matches the timezone offset from UTC, e.g. +01:00 or -03:00
  if (dateString.match(/(\+|-)\d\d:\d\d$/)) {
    return true;
  }

  return false;
}

// When the time zone offset is absent, date-only strings are interpreted as a UTC time and date-time strings are interpreted as local time.
// When parsing date-time strings from the BE, we know we store them in UTC time, so we add the time zone offset to make sure they're interpreted as a UTC time.
// From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format
export function ensureTimezoned(dateString: string) {
  const [date, time] = dateString.split('T');

  const notValidIsoString = !date || !time;
  if (notValidIsoString) {
    return dateString;
  }

  if (hasTimezone(dateString)) {
    return dateString;
  }

  return `${dateString}Z`;
}

function normalizeDateString(dateString: string, options: { appendTimeZone?: boolean } = {}) {
  const normalizedDateString = addTimeIfMissing(normalizeMonth(dateString));

  if (options.appendTimeZone) {
    return ensureTimezoned(normalizedDateString);
  }

  return normalizedDateString;
}

function isValidDateString(dateString: unknown): dateString is string {
  if (!dateString || dateString === 'no' || /^\d{4}$/.test(dateString as string)) {
    return false;
  }

  return true;
}

/**
 * This should really be just "string", but before we had TypeScript,
 * we passed everything...
 */
type LooseDateString = number | Date | string | null;

export function convertDateStringToDateObj(
  dateString: LooseDateString,
  options: { inLocalTime?: boolean } = {}
): Date | null {
  try {
    if (!isValidDateString(dateString)) {
      return null;
    }

    if (typeof dateString !== 'string') {
      return dateString;
    }

    // The `Date` parsing method interprets date-time strings as local time if they don't specify
    // a timezone. We make sure a timezone (usually UTC) is specified if we want to parse the date-time
    // string as UTC. If we want to parse the string as local time, we don't specify a timezone.
    const normalizedDateString = normalizeDateString(dateString, {
      appendTimeZone: !options.inLocalTime,
    });
    const date = new Date(normalizedDateString);

    // Starting from v2 `date-fns` throw `RangeError` when attempting to format an invalid
    // date: such errors could appear when mounting `DatePickerField` with a string value that
    // does not look like a date. To prevent that, short-circuit when `date` is an "Invalid Date".
    //
    // See:
    // - https://github.com/date-fns/date-fns/blob/master/CHANGELOG.md#200---2019-08-20
    return isValid(date) ? date : null;
  } catch (e) {
    return null;
  }
}

/**
 * Interprets a date string as local time and converts it to a `Date` object
 *
 * This is what you want to use when parsing dates from the user's input (e.g. via a date-picker), which are in local time
 */
export const convertLocalTimeStringToDateObj = (
  /**
   * Date or date-time string representing local time. Example 2021-06-10 or 2021-06-10T12:15:35
   */
  localTimeDateString: LooseDateString
): Date | null => convertDateStringToDateObj(localTimeDateString, { inLocalTime: true });

/**
 * Interprets a date string as UTC time and converts it to a `Date` object
 *
 * This is what you want to use when parsing dates from the BE, which are stored in UTC
 *
 * @param {string} utcDateString Date or date-time string representing UTC time. Example 2021-06-10 or 2021-06-10T12:15:35
 * @returns {Date}
 */
export const convertUtcStringToDateObj = (utcDateString: LooseDateString): Date | null =>
  convertDateStringToDateObj(utcDateString, { inLocalTime: false });

export function getUserTimezone(fallback = 'UTC'): string {
  // For old browsers that don't support Intl
  if (!Intl.DateTimeFormat) return fallback;

  // TypeScript says "timeZone" is string because our tsconfig points to a new
  // ES version. However, there are rare cases where timeZone could still be
  // `undefined`. See:
  // - Case happened in Remote: https://remote-com.slack.com/archives/C020F07FD7F/p1693808060307719
  // - On old specs: https://stackoverflow.com/a/34602679
  // - Reported in Mac Sonoma: https://support.google.com/chrome/thread/231926653?hl=en&msgid=232117132
  // - Reported in Windows: https://bugs.chromium.org/p/chromium/issues/detail?id=913298
  //
  // Disable eslint here for the explicit type correction
  // eslint-disable-next-line prefer-destructuring
  const timeZone: string | undefined = new Intl.DateTimeFormat().resolvedOptions()?.timeZone;
  if (!timeZone) return fallback;
  return timeZone;
}
