import { SROnly, Box } from '@remote-com/norma';

import { FormFieldError } from '@/src/domains/registration/onboarding/shared/styles/Form';

import { CheckboxCard } from './CheckboxCard';
import { Fieldset, CheckboxCardGrid, Legend } from './CheckboxCard.styled';
import type { CheckboxCardGroupProps } from './types';

export function CheckboxCardGroup({
  name,
  label,
  options,
  error,
  touched,
  value,
  onChange,
  disabled,
  readOnly,
  hideLabel,
  ...props
}: CheckboxCardGroupProps) {
  const labelId = `${name}-label`;
  const hasError = !!error && touched;
  const isDisabled = disabled || readOnly;

  return (
    <Fieldset role="group" {...props}>
      {hideLabel ? (
        <SROnly as="legend" id={labelId}>
          {label}
        </SROnly>
      ) : (
        <Legend id={labelId} color="grey.900" variant="2xlMedium" mb={6}>
          {label}
        </Legend>
      )}
      <CheckboxCardGrid aria-labelledby={labelId}>
        {options.map((option) => (
          <CheckboxCard
            key={option.value}
            id={option.value}
            name={name}
            checked={value.includes(option.value)}
            onChange={() => onChange(option.value)}
            disabled={isDisabled}
            readOnly={isDisabled}
            {...option}
          />
        ))}
      </CheckboxCardGrid>

      {hasError && (
        <Box mt={6}>
          <FormFieldError name={name} />
        </Box>
      )}
    </Fieldset>
  );
}
