// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineUnlock = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M11.77 1.25H15A2.75 2.75 0 0 1 17.75 4v.5a.75.75 0 0 1-1.5 0V4c0-.69-.56-1.25-1.25-1.25h-3.2c-.85 0-1.45 0-1.9.04-.46.04-.72.1-.92.2-.42.22-.77.57-.98.99-.1.2-.17.46-.21.91-.04.46-.04 1.06-.04 1.91v.46l1.62-.01h5.26c1.1 0 1.96 0 2.66.06.71.06 1.32.18 1.87.46.89.45 1.62 1.18 2.07 2.07.28.55.4 1.16.46 1.87.06.7.06 1.56.06 2.66v1.26c0 1.1 0 1.96-.06 2.66a4.88 4.88 0 0 1-.46 1.87 4.75 4.75 0 0 1-2.07 2.07c-.55.28-1.16.4-1.87.46-.7.06-1.56.06-2.66.06H9.37c-1.1 0-1.96 0-2.66-.06a4.88 4.88 0 0 1-1.87-.46 4.75 4.75 0 0 1-2.07-2.07 4.88 4.88 0 0 1-.46-1.87c-.06-.7-.06-1.56-.06-2.66v-1.26c0-1.1 0-1.96.06-2.66.06-.71.18-1.32.46-1.87a4.75 4.75 0 0 1 2.07-2.07c.43-.22.9-.34 1.41-.41v-.6c0-.8 0-1.46.04-2 .05-.54.14-1.02.37-1.46.36-.7.93-1.28 1.64-1.64.44-.23.92-.32 1.47-.37.53-.04 1.19-.04 2-.04ZM6.83 8.8c-.62.05-1 .15-1.3.3-.62.32-1.11.81-1.43 1.42-.15.3-.25.7-.3 1.31-.05.63-.05 1.44-.05 2.57v1.2c0 1.13 0 1.94.05 2.57.05.62.15 1 .3 1.3.32.62.81 1.11 1.42 1.43.3.15.7.25 1.31.3.63.05 1.44.05 2.57.05h5.2c1.13 0 1.94 0 2.57-.05.62-.05 1-.15 1.3-.3.62-.32 1.11-.81 1.43-1.42.15-.3.25-.7.3-1.31.05-.63.05-1.44.05-2.57v-1.2c0-1.13 0-1.94-.05-2.57-.05-.62-.15-1-.3-1.3-.32-.62-.81-1.11-1.42-1.43-.3-.15-.7-.25-1.31-.3-.63-.05-1.44-.05-2.57-.05H9.4c-1.13 0-1.94 0-2.57.05ZM12 13.25c.41 0 .75.34.75.75v2a.75.75 0 0 1-1.5 0v-2c0-.41.34-.75.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineUnlock);
export { ForwardRef as IconV2OutlineUnlock };