// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconTimesheet = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M.88 1.88A3 3 0 0 1 3 1h8a1 1 0 0 1 .7.3l1.5 1.5a1 1 0 0 1-1.4 1.4L10.58 3H3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 .7-.3 1 1 0 0 1 1.42 1.42A3 3 0 0 1 15 23H3a3 3 0 0 1-3-3V4a3 3 0 0 1 .88-2.12ZM17.5 7a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM11 11.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm6.5-4.3a1 1 0 0 1 1 1v2.68l1.65.83a1 1 0 1 1-.9 1.78l-2.2-1.1a1 1 0 0 1-.55-.89V8.2a1 1 0 0 1 1-1ZM4 9a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H5a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z" /></svg>;
const ForwardRef = forwardRef(IconTimesheet);
export { ForwardRef as IconTimesheet };