import type { StackProps } from '@remote-com/norma';
import { Avatar, Box, BoxedIcon, Stack, Text, Tooltip } from '@remote-com/norma';
import { IconV2OutlineInfo } from '@remote-com/norma/icons/IconV2OutlineInfo';
import styled from 'styled-components';

import CustomTooltip from '@/src/domains/reports/components/ReportWidgets/CustomTooltip';
import type { WidgetCardBlockProps } from '@/src/domains/reports/components/ReportWidgets/types';

const Wrapper = styled(Stack).attrs(() => ({
  p: 5,
  gap: 5,
  direction: 'row',
  backgroundColor: 'grey.50',
  alignItems: 'flex-start',
}))<StackProps>`
  border-radius: ${({ theme }) => theme.space[5]}px;
  height: fit-content;
`;

export default function WidgetCardBlock({
  count,
  label,
  tooltips,
  icon,
  tone,
  user,
}: WidgetCardBlockProps) {
  return (
    <Wrapper>
      <BoxedIcon appearance="subtle" tone={tone} Icon={icon} size="md" />
      <Box>
        <Text variant="baseSemiBold" color="grey.900">
          {count}
        </Text>
        <Stack direction="row" gap={2} alignItems="center">
          <Text variant="xsMedium" color="grey.600" as="span">
            {label}
          </Text>
          {tooltips?.length ? (
            <Tooltip label={<CustomTooltip tooltips={tooltips} />}>
              <IconV2OutlineInfo width="12" height="12" />
            </Tooltip>
          ) : null}
        </Stack>
        {user ? (
          <Stack direction="row" gap={3} alignItems="center" mt={2}>
            <Avatar fallback={user?.name} src={user?.profilePicture} size="xxs" />
            <Box display="flex" flexDirection="column" justifyContent="flex-start">
              <Text variant="xsMedium" color="grey.900">
                {user?.name}
              </Text>
              <Text variant="2xs" color="grey.600">
                {user?.jobTitle}
              </Text>
            </Box>
          </Stack>
        ) : null}
      </Box>
    </Wrapper>
  );
}
