import { getMonth, getYear } from 'date-fns';
import type { MouseEvent, ChangeEvent, KeyboardEvent } from 'react';
import { useEffect, useState } from 'react';

import { Text } from '../../../core/text';
import { MONTH_OPTIONS } from '../../../foundations/date';
import { Stack } from '../../../foundations/layout/Stack';
import { IconV2OutlineChevronLeft } from '../../../icons/build/IconV2OutlineChevronLeft';
import { IconV2OutlineChevronRight } from '../../../icons/build/IconV2OutlineChevronRight';

import { DatePickerRangeHints } from './DatePickerRangeHints';
import {
  DatePickerHeaderNavigationWrapper,
  DatePickerHeaderDateNavigation,
  DatePickerHeaderMonthLabel,
  DatePickerHeaderMonthSelect,
  DatePickerHeaderYearInput,
  DatePickerHeaderArrowNavigation,
  DatePickerHeaderMonthNavigationButton,
  DatePickerHeaderLinkButton,
  DatePickerHeaderIconPanel,
  DatePickerHeaderLoadingCalendarWeek,
} from './Header.styled';

const stopPropagation = (ev: KeyboardEvent<HTMLInputElement>) => {
  // prevent form submit on "Enter" key press.
  // Why: Users were pressing enter while interacting with the datepicker,
  // and in that stage is expected to not submit the form, just interact with the calendar itself.
  if (ev.keyCode === 13) ev.preventDefault();
};

export type DatePickerHeaderLinkProps = {
  onHeaderLinkClick: () => void;
  headerLinkText: string;
  isHeaderLinkOpen: boolean;
};

type HeaderProps = {
  changeMonth: (month: number) => void;
  changeYear: (year: string) => void;
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  nextMonthButtonDisabled: boolean;
  prevMonthButtonDisabled: boolean;
  minDate?: Date;
  maxDate?: Date;
  reverseOrder?: boolean;
  selectsRange?: boolean;
  headerLinkProps?: DatePickerHeaderLinkProps;
  isLoadingWorkCalendars?: boolean;
  onChangeMonth?: (date: Date) => void;
};

export function DatePickerHeader({
  changeMonth,
  changeYear,
  date,
  decreaseMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
  minDate,
  maxDate,
  reverseOrder,
  selectsRange,
  headerLinkProps,
  isLoadingWorkCalendars,
  onChangeMonth,
}: HeaderProps) {
  const months = MONTH_OPTIONS.map((month) => month.label);

  const [prevDate, setPrevDate] = useState<Date | undefined>();

  const handleChangeMonth = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    changeMonth(months.indexOf(value));
  };

  useEffect(() => {
    if (
      onChangeMonth &&
      date &&
      (date.getFullYear() !== prevDate?.getFullYear() || date.getMonth() !== prevDate?.getMonth())
    ) {
      setPrevDate(date);
      onChangeMonth(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleChangeYear = (ev: ChangeEvent<HTMLInputElement>) => {
    let updatedYear = ev.target.value;

    if (updatedYear.length > 1) {
      // NOTE: Number inputs accept leading zeroes. To make for a
      // better user experience, we're trimming all leading zeroes
      // from the value string.
      updatedYear = updatedYear.replace(/^0+/, '');

      // NOTE: We are not expecting years with more than 4 digits.
      // Since number inputs don't accept the `maxLength` attribute,
      // we're limiting the length here.
      updatedYear = updatedYear.slice(0, 4);

      ev.target.value = updatedYear;
    }

    changeYear(updatedYear);
  };

  const currentYear = date.getFullYear();

  const minYear = minDate?.getFullYear();
  const maxYear = maxDate?.getFullYear();
  const minMonth = minDate?.getMonth();
  const maxMonth = maxDate?.getMonth();

  const ArrowNavigationSection = (
    <DatePickerHeaderArrowNavigation key="arrow-navigation">
      <DatePickerHeaderMonthNavigationButton
        aria-label="Previous month"
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
        type="button"
        data-testid="datepicker-button-month-prev"
      >
        <IconV2OutlineChevronLeft height={18} width={18} />
      </DatePickerHeaderMonthNavigationButton>
      <DatePickerHeaderMonthNavigationButton
        aria-label="Next month"
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
        type="button"
        data-testid="datepicker-button-month-next"
      >
        <IconV2OutlineChevronRight height={18} width={18} />
      </DatePickerHeaderMonthNavigationButton>
    </DatePickerHeaderArrowNavigation>
  );

  const DateNavigationSection = (
    <DatePickerHeaderDateNavigation key="date-navigation">
      <DatePickerHeaderMonthLabel htmlFor="month-select">
        {months[getMonth(date)]}
        <DatePickerHeaderMonthSelect
          id="month-select"
          aria-label="Month"
          onChange={handleChangeMonth}
          value={months[getMonth(date)]}
          data-testid="datepicker-select-month"
          $chars={months[getMonth(date)].length}
        >
          {months.map((option) => {
            let isDisabled = false;
            if (minDate) {
              isDisabled =
                currentYear < minYear! ||
                (currentYear === minYear && months.indexOf(option) < minMonth!);
            }
            if (maxDate) {
              isDisabled =
                isDisabled ||
                currentYear > maxYear! ||
                (currentYear === maxYear && months.indexOf(option) > maxMonth!);
            }

            return (
              <option key={option} value={option} disabled={isDisabled}>
                {option}
              </option>
            );
          })}
        </DatePickerHeaderMonthSelect>
      </DatePickerHeaderMonthLabel>
      <DatePickerHeaderYearInput
        aria-label="Year"
        onChange={handleChangeYear}
        onKeyDown={stopPropagation}
        type="number"
        value={getYear(date)}
        data-testid="datepicker-input-year"
        min={minYear}
        max={maxYear}
      />
      {isLoadingWorkCalendars && <DatePickerHeaderLoadingCalendarWeek />}
    </DatePickerHeaderDateNavigation>
  );

  const navigationSections = [DateNavigationSection, ArrowNavigationSection];
  if (reverseOrder) {
    navigationSections.reverse();
  }

  if (headerLinkProps) {
    const { onHeaderLinkClick, headerLinkText, isHeaderLinkOpen } = headerLinkProps;
    const handleHeaderLinkClick = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onHeaderLinkClick();
    };
    return (
      <DatePickerHeaderNavigationWrapper justifyContent="space-between">
        {selectsRange && <DatePickerRangeHints />}
        <Stack direction="row" gap="3">
          {navigationSections}
        </Stack>
        <DatePickerHeaderLinkButton variant="inline" tone="primary" onClick={handleHeaderLinkClick}>
          <Text variant="smMedium">{headerLinkText}</Text>
          <DatePickerHeaderIconPanel $active={isHeaderLinkOpen} />
        </DatePickerHeaderLinkButton>
      </DatePickerHeaderNavigationWrapper>
    );
  }

  return (
    <DatePickerHeaderNavigationWrapper>{navigationSections}</DatePickerHeaderNavigationWrapper>
  );
}
