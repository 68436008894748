import getConfig from 'next/config';

const {
  publicRuntimeConfig: { GOOGLE_API_KEY, GOOGLE_CLIENT_ID },
} = getConfig();

const googleLibrariesState = {
  isGapiInitialized: false,
  isGisInitialized: false,
  isInitializing: true,

  discoveryDocs: '',
  scope: '',
};

/**
 * Zustand store slice with the logic to load the GAPI and GIS libraries from Google.
 * These libraries allow us to authenticate the user and use Google APIs.
 */
export const useGoogleLibrariesStore = (set, get) => ({
  ...googleLibrariesState,
  maybeEnableImporter: () => {
    const { isGapiInitialized, isGisInitialized } = get();
    if (isGapiInitialized && isGisInitialized) {
      set({ isInitializing: false });
    }
  },
  loadGapi: ({ discoveryDocs }) => {
    const { maybeEnableImporter } = get();
    window.gapi.load('client', async () => {
      await window.gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs,
      });
      set({ isGapiInitialized: true });
      maybeEnableImporter();
    });
  },
  loadGis: async ({ scopes }) => {
    const { maybeEnableImporter } = get();
    const tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_CLIENT_ID,
      scope: scopes,
      callback: '', // defined later
    });
    set({ tokenClient, isGisInitialized: true });
    maybeEnableImporter();
  },
  reloadLibraries: ({ discoveryDocs, scopes }) => {
    const { loadGapi, loadGis } = get();
    loadGapi({ discoveryDocs });
    loadGis({ scopes });
  },
});
