import { TextEllipsis } from '@remote-com/norma';

import { getFlagSrc } from '@/src/domains/countries/helpers';
import { CountryInfo, CountryFlag } from '@/src/domains/countries/styles';

type CountryLabelProps = {
  name: string;
  flagSpacing?: string;
  dataTestId?: string;
};

const CountryLabel = ({ name, ...props }: CountryLabelProps) => {
  if (!name) {
    return <>—</>;
  }

  return (
    <CountryInfo data-testid={props.dataTestId || undefined}>
      <CountryFlag src={getFlagSrc(name) ?? undefined} alt="" />
      <TextEllipsis>{name}</TextEllipsis>
    </CountryInfo>
  );
};

export default CountryLabel;
