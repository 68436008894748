// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconEye = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M2.14 12a18.68 18.68 0 0 0 2.46 3.32C6.4 17.22 8.9 19 12 19c3.1 0 5.6-1.77 7.4-3.68A18.68 18.68 0 0 0 21.86 12a18.68 18.68 0 0 0-2.46-3.32C17.6 6.78 15.1 5 12 5 8.9 5 6.4 6.77 4.6 8.68A18.68 18.68 0 0 0 2.14 12ZM23 12l.9-.45h-.01l-.01-.03a10.61 10.61 0 0 0-.2-.35 20.67 20.67 0 0 0-2.83-3.85C18.9 5.22 15.9 3 12 3 8.1 3 5.1 5.23 3.15 7.32a20.67 20.67 0 0 0-3.03 4.2v.02H.1v.01L1 12l-.9-.45a1 1 0 0 0 0 .9L1 12l-.9.45h.01l.01.03a8.27 8.27 0 0 0 .2.35 20.67 20.67 0 0 0 2.83 3.85C5.1 18.78 8.1 21 12 21c3.9 0 6.9-2.23 8.85-4.32a20.67 20.67 0 0 0 3.03-4.2v-.02h.01v-.01L23 12Zm0 0 .9.45a1 1 0 0 0 0-.9L23 12Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" /></svg>;
const ForwardRef = forwardRef(IconEye);
export { ForwardRef as IconEye };