import { Box } from '@remote-com/norma';
import styled, { css } from 'styled-components';

const ContentContainer = styled(Box).attrs((props) => ({
  px: props.noPadding ? 0 : [5, 5, 8],
  py: 6,
  ...props,
}))`
  ${({ narrow }) =>
    narrow &&
    css`
      /* 
        NOTE: We use content-box here so we can set max-width without
        having to use magic numbers.
      */
      box-sizing: content-box;
      max-width: 768px;
    `}
`;

export default ContentContainer;
