import { IconV2DuotoneShieldCheck } from '@remote-com/norma/icons/IconV2DuotoneShieldCheck';
import { IconV2DuotoneStar } from '@remote-com/norma/icons/IconV2DuotoneStar';
import { IconV2DuotoneSupport } from '@remote-com/norma/icons/IconV2DuotoneSupport';
import { IconV2DuotoneUser } from '@remote-com/norma/icons/IconV2DuotoneUser';
import { IconV2DuotoneUsers } from '@remote-com/norma/icons/IconV2DuotoneUsers';

import { PURCHASE_TYPES } from '@/src/domains/employment/newEmployment/constants';
import { staticAssetUrl } from '@/src/helpers/general';

export const PURCHASE_OPTION_CONTENT = {
  PAYG: {
    variant: PURCHASE_TYPES.PAYG,
    features: [
      'Hire international employees and let us handle local payroll, benefits, taxes, and compliance',
      'Competitive benefits packages tailored to each country',
      'Get support from local HR and legal experts, no third-party delays',
      'Ensure your IP and invention rights are fully protected',
    ],
    title: 'Standard',
    globeBackground: staticAssetUrl('/images/payg-st-bg.png'),
    globeBackground2x: staticAssetUrl('/images/payg-st-bg@2x.png'),
    OptionIcon: IconV2DuotoneUser,
    tone: 'cyan',
    textTone: 'cyan.700',
  },
  CONTRACTOR_STANDARD: {
    variant: PURCHASE_TYPES.CONTRACTOR_STANDARD,
    features: [
      'International payments in 70+ countries around the world',
      'Legally reviewed, compliant contracts localized for dozens of countries',
      'Put payments on autopilot with recurring invoicing and automatic approvals',
    ],
    title: 'Contractor Management',
    globeBackground: staticAssetUrl('/images/contractors/contractor-st-bg.png'),
    globeBackground2x: staticAssetUrl('/images/contractors/contractor-st-bg@2x.png'),
    narrowBackground: staticAssetUrl('/images/contractors/contractor-st-bg-narrow.png'),
    narrowBackground2x: staticAssetUrl('/images/contractors/contractor-st-bg-narrow@2x.png'),
    OptionIcon: IconV2DuotoneUser,
    tone: 'purple',
    textTone: 'purple.700',
  },
  CONTRACTOR_PLUS: {
    variant: PURCHASE_TYPES.CONTRACTOR_PLUS,
    features: [
      'Access to all Contractor Management features',
      'Misclassification protection: up to $100k indemnity coverage per contractor',
    ],
    title: 'Contractor Management Plus',
    globeBackground: staticAssetUrl('/images/contractors/contractor-pl-bg.png'),
    globeBackground2x: staticAssetUrl('/images/contractors/contractor-pl-bg@2x.png'),
    narrowBackground: staticAssetUrl('/images/contractors/contractor-pl-bg-narrow.png'),
    narrowBackground2x: staticAssetUrl('/images/contractors/contractor-pl-bg-narrow@2x.png'),
    OptionIcon: IconV2DuotoneShieldCheck,
    tone: 'brand',
    textTone: 'brand.700',
  },
  CONTRACTOR_PROTECT: {
    variant: PURCHASE_TYPES.CONTRACTOR_PROTECT,
    features: [],
    title: 'Contractor Management Protect',
    globeBackground: staticAssetUrl('/images/premium-plan-bg.png'),
    globeBackground2x: staticAssetUrl('/images/premium-plan-bg@2x.png'),
    narrowBackground: staticAssetUrl('/images/premium-plan-bg-narrow.png'),
    narrowBackground2x: staticAssetUrl('/images/premium-plan-bg-narrow@2x.png'),
    OptionIcon: IconV2DuotoneSupport,
    tone: 'yellow',
    textTone: 'yellow.600',
  },
  TEAM_PLAN: {
    variant: PURCHASE_TYPES.TEAM_PLAN,
    features: [
      'Special pricing for your global hires',
      'Volume discounts starting at 5 seats',
      'Add employees faster and easier',
      'Best for those with a defined international expansion strategy',
    ],
    title: 'TEAM plan',
    globeBackground: staticAssetUrl('/images/team-plan-bg.png'),
    globeBackground2x: staticAssetUrl('/images/team-plan-bg@2x.png'),
    OptionIcon: IconV2DuotoneUsers,
    tone: 'green',
    textTone: 'green.700',
  },
  PREMIUM_PLAN: {
    variant: PURCHASE_TYPES.PREMIUM_PLAN,
    features: [
      'Dedicated customer success manager to support your goals',
      'Prioritized onboarding and direct contact with senior onboarding specialists for all hires',
      'A shared Slack channel where you can chat with your Remote team about urgent needs or questions',
      'Quarterly consultation with our employment experts',
    ],
    title: 'Premium',
    globeBackground: staticAssetUrl('/images/premium-plan-bg.png'),
    globeBackground2x: staticAssetUrl('/images/premium-plan-bg@2x.png'),
    OptionIcon: IconV2DuotoneStar,
    tone: 'yellow',
    textTone: 'yellow.700',
  },
} as const;
