import { InputFileUploader } from '@remote-com/norma';
import { useField } from 'formik';
import PropTypes from 'prop-types';

const asyncConfirm = async () => ({ slug: Date.now() });

export function FileUploaderField({
  defaultValue,
  name,
  onDeleteFile,
  modifiable,
  value: val,
  ...props
}) {
  const [{ value }, { error, touched }, { setValue }] = useField(name);
  function onChange(updatedValue) {
    // Remove the files with errors to the final payload
    // Store only the file itself, excluding extra FileUploader meta
    // on Edit, if not singleFile, keep the rest of the files intact
    let newValue = updatedValue || null;
    if (props.singleFile) {
      if (newValue && 'errors' in newValue) {
        newValue = null;
      }
    } else {
      newValue = updatedValue
        .filter((fileObj) => !('errors' in fileObj))
        .map((fileObj) => (modifiable || !(fileObj.file instanceof File) ? fileObj : fileObj.file));
    }

    setValue(newValue);
  }

  async function handleDeleteFile(info) {
    await asyncConfirm();
    if (onDeleteFile) {
      onDeleteFile(info);
    }
  }
  const fallbackValue = defaultValue || (props.singleFile ? null : []);
  const files = modifiable ? value?.map((v) => v.file) : value;
  return (
    <InputFileUploader
      // passing val here in case someone needs to override the formik value for some reason
      // in fact we should only rely on the formik value but first we need to deprecate value prop
      // to avoid unwanted outcomes in the multiple file upload scenario
      value={val || files || fallbackValue}
      uploadFile={asyncConfirm}
      deleteFile={handleDeleteFile}
      onChange={onChange}
      errorMessage={touched && error ? error : ''}
      modifiable={modifiable}
      name={name}
      {...props}
    />
  );
}

FileUploaderField.defaultProps = {
  name: 'files',
};

FileUploaderField.propTypes = {
  /** Semantic label input field */
  label: PropTypes.string.isRequired,
  /** Form Field name */
  name: PropTypes.string,
  /** Formik passed field value */
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  /** Function to run when a file is deleted */
  onDeleteFile: PropTypes.func,
  /** show fields to modify name and file type */
  modifiable: PropTypes.bool,
};
