import { Box } from '@remote-com/norma';
import { IconChevronDown } from '@remote-com/norma/icons/IconChevronDown';
import styled, { css } from 'styled-components';

import TableCell from '@/src/components/Table/Components/TableCell';

// The Table component sets the first-child of a table row to have less right padding than other cells,
// which doesn't work well in the case of right-aligned table cells inside nested table rows
export const TableCellWithExtraPaddingRight = styled(TableCell)`
  /* && used to increase specificity */
  && {
    padding-right: 16px;
  }
`;

export const StyledIconChevron = styled(IconChevronDown)<{ $expanded: boolean }>`
  transition: transform 0.2s ease;

  ${({ $expanded }) =>
    $expanded &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ReadOnlyCell = styled(Box)<{ $alignRight?: boolean }>`
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-bottom: ${({ theme }) => theme.space[3]}px;

  ${({ $alignRight }) =>
    $alignRight &&
    css`
      justify-content: flex-end;
    `}
`;

export const EditableCell = styled(Box)`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;
