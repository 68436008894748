import { useField } from 'formik';

import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import { useUserCan } from '@/src/components/UserCan';
import { getLegalEntityLabel } from '@/src/domains/billing/helpers';
import { useLegalEntityData } from '@/src/domains/legalEntities/hooks/useEmployerLegalEntitiesData';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import usePrevious from '@/src/hooks/usePrevious';

function getLegalEntitySlug(legalEntity) {
  return legalEntity?.slug;
}

export function LegalEntitySelectField({
  filters = {},
  countrySlug = undefined,
  ...selectFieldProps
}) {
  const [{ value: initialSlug }] = useField(selectFieldProps.name);

  const { data: selectedLegalEntity, isLoading: isLoadingSelectedLegalEntity } = useLegalEntityData(
    {
      legalEntitySlug: initialSlug,
      options: {
        enabled: !!initialSlug,
      },
    }
  );

  const previousCountrySlug = usePrevious(countrySlug);
  const hasCountryChanged = previousCountrySlug !== countrySlug;

  const defaultOptions = selectedLegalEntity ? [selectedLegalEntity] : [];

  const { userCan } = useUserCan();
  const hasPermissions =
    userCan('read', Resources.legal_entities) && userCan('read', Resources.employer.legal_entities);

  return (
    <PaginatedSearchSelectField
      data-testid="legal-entity-select-field"
      query={{
        path: '/api/v1/employer/legal-entities',
        params: {
          queryParams: filters,
        },
        options: {
          select: (res) => getDataWithRenamedProperty(res, 'legalEntities'),
          enabled: hasPermissions,
        },
      }}
      loadingMessage={() => 'Loading entities…'}
      getOptionLabel={getLegalEntityLabel}
      getOptionValue={getLegalEntitySlug}
      transformValue={getLegalEntitySlug}
      // defaultOptions is set so the user sees the selected legal entity on the field after a reload of the page.
      // Without this, it would only be shown as selected if it was on the first page of results.
      defaultOptions={defaultOptions}
      isLoadingDefaultOptions={isLoadingSelectedLegalEntity}
      // In some cases, we get a new list of legal entities when country filter changes
      // This allows us to to NOT have the default behavior of adding the previously selected option to the new options list
      clearPinnedOptions={hasCountryChanged}
      isControlled
      isDisabled={!hasPermissions}
      description={!hasPermissions ? 'You do not have permissions to view entities' : undefined}
      {...selectFieldProps}
    />
  );
}
