// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineDownloadBase = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M17.53 10.41c.3.3.3.77 0 1.06l-5 5.06a.75.75 0 0 1-1.06 0l-5-5.06a.75.75 0 1 1 1.06-1.05l3.72 3.76V3a.75.75 0 0 1 1.5 0v11.18l3.72-3.76c.29-.3.76-.3 1.06 0Z" /><path fillRule="evenodd" clipRule="evenodd" d="M20.75 21c0 .41-.34.75-.75.75H4a.75.75 0 0 1 0-1.5h16c.41 0 .75.34.75.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineDownloadBase);
export { ForwardRef as IconV2OutlineDownloadBase };