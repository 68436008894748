import type { Item as ContextMenuItem } from '@radix-ui/react-context-menu';
import type { Item as DropdownItem } from '@radix-ui/react-dropdown-menu';
import type { MouseEvent, Ref } from 'react';
import { forwardRef, useCallback } from 'react';
import type { StyledComponent } from 'styled-components';

import type { Theme } from '../../foundations';

import { areChildrenText } from './helpers';
import type { MenuItemComponentProps } from './MenuItem.styled';
import { Divider, MenuItemButton } from './MenuItem.styled';
import type { MenuItemInternalProps } from './MenuItemInternal';
import { MenuItemInternal } from './MenuItemInternal';

type InteractionProps =
  | {
      onSelect: () => void;
      onClick?: (e: MouseEvent) => void;
    }
  | {
      onSelect?: () => void;
      onClick?: (e: MouseEvent) => void;
    };

export type MenuItemTone = 'primary';

export type MenuItemProps = MenuItemInternalProps &
  InteractionProps & {
    disabled?: boolean;
    hasDivider?: boolean;
    tone?: MenuItemTone;
    // Writing these types manually to keep nextJS dependency out of Norma
    href?: string | Object;
    target?: string;
  };

export const MenuItem = forwardRef(
  (
    props: React.PropsWithChildren<
      MenuItemProps & {
        MenuItemComponent:
          | StyledComponent<typeof DropdownItem, Theme, MenuItemComponentProps>
          | StyledComponent<typeof ContextMenuItem, Theme, MenuItemComponentProps>;
      }
    >,
    ref?: Ref<HTMLDivElement>
  ) => {
    const {
      children,
      isLoading,
      Icon,
      Pill,
      Toggle,
      disabled,
      hasDivider,
      tone,
      MenuItemComponent,
      onClick,
      ...rest
    } = props;
    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(e);
      },
      [onClick]
    );

    const content = (
      <MenuItemInternal isLoading={isLoading} Icon={Icon} Pill={Pill} Toggle={Toggle}>
        {children}
      </MenuItemInternal>
    );

    // If an href is present, we'll render a Button wrapping the content and pass the props to it. Otherwise, we
    // render the content directly and we pass the props to the MenuItemComponent
    const isLink = !!rest?.href;

    // Note: Employ already calculates data-action-name correctly but Jobs doesn't so we need to keep this logic
    // here for now. The order of the spreads in the itemProps definition is important, what comes from props should be respected
    const dataActionName = areChildrenText(children) ? { 'data-action-name': children } : {};

    const itemProps = {
      ...dataActionName,
      ...rest,
      /**
       * Previously, '@reach-ui' library injected this role value.
       * To maintain backward compatibility, we need to set it as the default value, as some tests rely on it as a selector.
       * TODO: Remove this attribute once tests are updated and no longer dependent on it.
       */
      role: 'menuitem',
      onClick: handleClick,
    };

    return (
      <>
        {hasDivider && <Divider />}

        <MenuItemComponent
          disabled={disabled || isLoading}
          tone={tone}
          asChild={isLink}
          {...(!isLink && !disabled ? itemProps : {})}
          {...(disabled ? { onClick: (e: MouseEvent) => e.stopPropagation() } : {})}
          $isToggle={!!Toggle}
          ref={ref}
        >
          {isLink ? (
            <MenuItemButton disabled={disabled} {...itemProps}>
              {content}
            </MenuItemButton>
          ) : (
            <>{content}</>
          )}
        </MenuItemComponent>
      </>
    );
  }
);
