import { type ComponentProps } from 'react';

import type { LegalEntityApi } from '@/src/api/config/employ/legalEntity.types';
import { useUserCan } from '@/src/components/UserCan';
import { getLegalEntityLabel } from '@/src/domains/legalEntities/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import usePrevious from '@/src/hooks/usePrevious';

import { PaginatedSearchSelectField } from '../PaginatedSearchSelectField';

type Props = ComponentProps<typeof PaginatedSearchSelectField> & {
  filters: Record<string, any>;
  countrySlug?: string;
};

export function LegalEntityMultiselect({ filters = {}, countrySlug, ...selectFieldProps }: Props) {
  const previousCountrySlug = usePrevious(countrySlug);
  const hasCountryChanged = previousCountrySlug !== countrySlug;
  const { userCan } = useUserCan();
  const hasPermissions =
    userCan('read', Resources.legal_entities) && userCan('read', Resources.remote_entities);

  return (
    <PaginatedSearchSelectField
      data-testid="legal-entity-select-field"
      query={{
        path: '/api/v1/rivendell/legal-entities',
        params: {
          queryParams: filters,
        },
        options: {
          select: (res: LegalEntityApi.LegalEntitiesResponse) => {
            return {
              ...res,
              data: getDataWithRenamedProperty(res, 'remoteEntities').data.map(
                (option: LegalEntityApi.LegalEntity) => ({
                  label: getLegalEntityLabel(option),
                  value: option.slug,
                })
              ),
            };
          },
          enabled: hasPermissions,
        },
      }}
      loadingMessage={() => 'Loading legal entities…'}
      // In some cases, we get a new list of legal entities when country filter changes
      // This allows us to to NOT have the default behavior of adding the previously selected option to the new options list
      clearPinnedOptions={hasCountryChanged}
      isControlled
      isDisabled={!hasPermissions}
      description={
        !hasPermissions ? 'You do not have permissions to view legal entities' : undefined
      }
      multiple
      {...selectFieldProps}
    />
  );
}
