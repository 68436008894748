import { endOfMonth, isWithinInterval, startOfMonth } from 'date-fns';

import { formatMonthDay, formatMonthDayYear } from '@/src/helpers/date';
import { useToday } from '@/src/hooks/useToday';

export const isDateWithinThisMonth = ({ date, today }: { date: Date | string; today: Date }) =>
  isWithinInterval(new Date(date), {
    start: startOfMonth(today),
    end: endOfMonth(today),
  });

export const isDateInPayrollRunPeriod = ({
  date,
  payrollRun,
}: {
  date: Date | string;
  payrollRun: { periodStart: string; periodEnd: string };
}) =>
  isWithinInterval(new Date(date), {
    start: new Date(payrollRun.periodStart),
    end: new Date(payrollRun.periodEnd),
  });

type Props = {
  data: {
    terminationDate?: string;
    provisionalStartDate?: string;
    contract?: { terminationDate?: string; provisionalStartDate?: string };
  };
  payrollRun?: { periodStart: string; periodEnd: string };
  isPayrollRunEvents?: boolean;
};

const leavingOnPill = ({ today, terminationDate }: { today: Date; terminationDate: Date }) => {
  const formattedTerminationDate =
    today.getFullYear() === terminationDate.getFullYear()
      ? formatMonthDay(terminationDate)
      : formatMonthDayYear(terminationDate);
  const verb = today.getTime() < terminationDate.getTime() ? 'Leaving' : 'Left';

  return {
    tone: 'pink' as const,
    label: `${verb} on ${formattedTerminationDate}`,
  };
};

const joinedThisMonthPill = {
  tone: 'blue' as const,
  label: 'Joined this month',
};

const getTerminationDate = (data: Props['data']) => {
  const unparsedTerminationDate = data?.terminationDate || data?.contract?.terminationDate;
  const terminationDate = unparsedTerminationDate ? new Date(unparsedTerminationDate) : undefined;
  return terminationDate;
};

const getProvisionalStartDate = (data: Props['data']) =>
  data.provisionalStartDate || data?.contract?.provisionalStartDate;

// Returns an object with 'tone' and 'label' to be used on the Pill component to show user events.
// The events are "calculated" based on the received user data.
// If payrollRun is received and isPayrollRunEvents is true, return events based on payroll run period.
// If isPayrollRunEvents is not true, return events based on today's date.
export const usePersonEventsCellPill = ({ data, payrollRun, isPayrollRunEvents }: Props) => {
  const today = useToday();

  const terminationDate = getTerminationDate(data);
  const provisionalStartDate = getProvisionalStartDate(data);

  if (payrollRun && isPayrollRunEvents) {
    const arePayrollPeriodsAvailable = payrollRun?.periodStart && payrollRun?.periodEnd;

    if (!arePayrollPeriodsAvailable) return null;

    // 'Leaving on...' pill - shown if employee has a terminationDate within the payroll run period
    if (terminationDate && isDateInPayrollRunPeriod({ date: terminationDate, payrollRun })) {
      return leavingOnPill({ today, terminationDate });
    }
    // 'Joined this month' pill - shown if employee's provisionalStartDate is within the payroll run period
    if (
      provisionalStartDate &&
      isDateInPayrollRunPeriod({ date: provisionalStartDate, payrollRun })
    ) {
      return joinedThisMonthPill;
    }
    return null;
  }

  // 'Leaving on...' pill - shown if employee has a terminationDate
  if (terminationDate) {
    return leavingOnPill({ today, terminationDate });
  }

  // 'Joined this month' pill - shown if employee's provisionalStartDate is within this month
  if (provisionalStartDate && isDateWithinThisMonth({ date: provisionalStartDate, today })) {
    return joinedThisMonthPill;
  }

  return null;
};
