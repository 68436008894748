import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledTabBarBadge = styled.span`
  display: inline-block;
  color: var(--colors-blank);
  background-color: var(--colors-salmon);
  width: ${({ greaterThan99 }) => (greaterThan99 ? '24px' : '15px')};
  height: 15px;
  line-height: 15px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  border-radius: ${({ greaterThan99 }) => (greaterThan99 ? '50rem' : '50%')};
  z-index: 1;
`;

const TabBarBadge = ({ count, ...props }) => {
  const greaterThan99 = count > 99;
  return (
    <StyledTabBarBadge {...props} greaterThan99={greaterThan99}>
      {greaterThan99 ? '+99' : count}
    </StyledTabBarBadge>
  );
};

TabBarBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

export default TabBarBadge;
