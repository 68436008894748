import { InputDatePicker } from '@remote-com/norma';
import { IconV2OutlineCalendarDates } from '@remote-com/norma/icons/IconV2OutlineCalendarDates';
import { Field, useFormikContext } from 'formik';

/**
 * DateRangePickerField
 *
 * Renders a datepicker field for range of dates selection
 *
 * @export
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').DateRangeField, 'type'> & {id?: string}} props
 * @return {JSX.Element}
 */
export const DateRangePickerField = ({
  'data-testid': dataTestid,
  autoComplete = 'off',
  autoFocus,
  description,
  displayLabel,
  label,
  name,
  onChange,
  placeholder,
  preventJump,
  size,
  labelPlacement,
  isClearable,
  isLoading,
  transformValue,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field name={name}>
      {({ field: { value, ...field }, meta: { error, touched } }) => {
        const handleChange = (dates) => {
          if (onChange) onChange(dates);

          const hasSelectedDate = dates.start || dates.end;
          const dateValue = transformValue ? transformValue(dates) : [dates.start, dates.end];

          setFieldValue(field.name, hasSelectedDate ? dateValue : null);
        };
        return (
          <InputDatePicker
            description={description}
            errorText={error && touched ? error : ''}
            label={label || placeholder}
            placeholder={placeholder}
            name={name}
            touched={touched}
            value={value}
            autoComplete={autoComplete}
            field={field}
            props={props}
            autoFocus={autoFocus}
            data-testid={dataTestid}
            isClearable={isClearable}
            onChange={handleChange}
            selected={value || [null, null]}
            selectsRange
            size={size}
            labelPlacement={labelPlacement}
            asideIcon={IconV2OutlineCalendarDates}
            isLoading={isLoading}
          />
        );
      }}
    </Field>
  );
};
