import styled, { css } from 'styled-components';

import { DatePickerCalendarBasicContainer } from './BasicContainer.styled';

const selectedBackgroundStyles = css`
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${({ theme }) => theme.colors.brand[50]};
`;

export const DatePickerRangeContainer = styled(DatePickerCalendarBasicContainer)`
  ${({ theme }) => css`
    .react-datepicker__month {
      overflow: hidden;
    }

    .react-datepicker__week {
      /* Fix for ::after selector(gray background) being rendered behind main content (circle)
       Source: https://stackoverflow.com/a/10156261 */
      z-index: 0;
    }

    .react-datepicker__day {
      /* Circle colors for selected start/end dates in range. */
      &--selecting-range-start,
      &--range-start,
      &--range-end,
      &--selected {
        &:not(.react-datepicker__day--disabled) {
          background-color: ${theme.colors.brand[600]};
          color: white;

          --date-picker-marker-color: ${theme.colors.white};

          :focus,
          :focus-within,
          :hover {
            background-color: ${theme.colors.brand[500]};
          }
        }
      }

      /* 
        This adds a background to the left of the last date in the range. It utilizes the ::after selector since we 
        want the last date to be displayed as a circle. 
      */
      &--in-range,
      &--in-selecting-range {
        + .react-datepicker__day--in-selecting-range:hover,
        + .react-datepicker__day--in-selecting-range:focus,
        + .react-datepicker__day--range-end {
          &:not(.react-datepicker__day--disabled)::after {
            ${selectedBackgroundStyles}
            right: 50%;
          }
        }
      }

      /* 
        This adds a background to the right of the first date in the range. It utilizes the ::before selector since we 
        want the first date to be displayed as a circle. 
      */
      &--selecting-range-start,
      &--range-start {
        + .react-datepicker__day--in-range,
        + .react-datepicker__day--in-selecting-range {
          &::before {
            ${selectedBackgroundStyles}
            right: 50%;
          }
        }
      }

      /* 
        This adds a background to the selected range dates, excluding start and end date.
      */
      &--in-range,
      &--in-selecting-range:not(:hover):not(:focus),
      &--in-selecting-range.react-datepicker__day--disabled:hover,
      &--in-selecting-range.react-datepicker__day--disabled:focus {
        &:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end):not(
            .react-datepicker__day--selecting-range-start
          ) {
          background-color: ${theme.colors.brand[50]};
          border-radius: 0;
          width: 100%;

          /* Adding radius if start of a row */
          &:first-child {
            border-radius: 8px 0 0 8px;
          }

          /* Adding radius if end of a row */
          &:last-child {
            border-radius: 0 8px 8px 0;
          }

          /* Adding a circle for feedback if hover/focus */
          &:not(.react-datepicker__day--disabled):focus,
          &:not(.react-datepicker__day--disabled):hover {
            ::after {
              content: '';
              position: absolute;
              background-color: ${theme.colors.brand[100]};
              border-radius: ${theme.borderRadius.full};
              height: var(--date-picker-circle-size);
              width: var(--date-picker-circle-size);
              z-index: var(--date-picker-z-index-disabled);
            }
          }
        }
      }

      /*
        When hovering/focusing over the first date in a row (not start of range), we present the date as a circle. 
        This style adds a background to the left of the date, enhancing its visual connection to the range.
      */
      &--in-selecting-range:hover:first-child:not(
          .react-datepicker__day--selecting-range-start
        )::before,
      &--in-selecting-range:focus:first-child:not(
          .react-datepicker__day--selecting-range-start
        )::before,
      &--range-end:not(.react-datepicker__day--range-start):first-child::before {
        content: '';
        position: absolute;
        background-color: ${theme.colors.brand[50]};
        border-radius: 8px;
        height: 100%;
        width: 75%;
        left: -5px;
        z-index: -1;
      }

      /* Background for "today", keep default selected styling */
      &.react-datepicker__day--today:not(.react-datepicker__day--selected):not(
          .react-datepicker__day--range-end
        ):not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--in-range):not(
          :hover
        ) {
        color: ${theme.colors.brand[600]};
        border: 1px solid ${theme.colors.brand[600]};
      }

      /* When disabled days are in the selection, we want to display a yellow circle */
      &--in-range.react-datepicker__day--disabled {
        color: ${theme.colors.grey[900]};

        ::after {
          content: '';
          position: absolute;
          background-color: ${theme.colors.yellow[400]};
          border-radius: ${theme.borderRadius.full};
          height: var(--date-picker-circle-size);
          width: var(--date-picker-circle-size);
          z-index: var(--date-picker-z-index-disabled);
        }

        &:focus,
        &:hover {
          ::after {
            background-color: ${theme.colors.yellow[300]};
          }
        }
      }
    }
  `}
`;
