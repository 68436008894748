import { Pill } from '@remote-com/norma';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { HTMLRendered } from '../../core/html-rendered';
import { Text } from '../../core/text';
import type { TextProps } from '../../core/text/Text';
import type { BoxProps } from '../../foundations/layout/Box';
import { Box } from '../../foundations/layout/Box';
import type { ComponentProps } from '../../types';

import { Input } from './Input';
import { Label } from './Label';

export interface RadioProps extends Omit<ComponentProps<typeof Input>, 'size'> {
  /**
   * The label for the radio button.
   */
  label?: string;
  /**
   * An optional description which will be placed below the label to provide more context about the corresponding radio button option.
   */
  description?: string;
  /**
   * Additional content to be rendered next to the radio button.
   */
  extra?: ReactNode;
  /**
   * Custom styles applied to the Radio option wrapper.
   * NOTE: Please use this prop sparingly, and only if there is no other way to style the component to fit a specific use case.
   */
  inputWrapperStyles?: BoxProps;
  pill?: string;
}

const Description = (props: TextProps & { disabled?: boolean }) => (
  <Text
    {...props}
    color={props.disabled ? 'grey.500' : 'grey.600'}
    fontWeight="400"
    fontSize="12px"
    lineHeight="18px"
  />
);

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      disabled,
      label,
      checked,
      value,
      onChange,
      onBlur,
      name,
      description,
      extra,
      inputWrapperStyles,
      pill,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <Label
          checked={Boolean(checked)}
          disabled={Boolean(disabled)}
          aria-disabled={Boolean(disabled)}
        >
          <Box flex="none" height="20px" width="20px" mt={1} mr={4} {...inputWrapperStyles}>
            <Input
              {...props}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              checked={Boolean(checked)}
              disabled={Boolean(disabled)}
              aria-disabled={Boolean(disabled)}
              ref={ref}
            />
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Text as="span" variant="base" color={disabled ? 'grey.500' : 'grey.900'}>
                {label}
              </Text>
              {pill && (
                <Pill marginLeft={3} tone="success">
                  {pill}
                </Pill>
              )}
            </Box>
            {description && (
              <HTMLRendered Tag={Description} mt={1} disabled={disabled}>
                {description}
              </HTMLRendered>
            )}
          </Box>
        </Label>
        {extra}
      </>
    );
  }
);
