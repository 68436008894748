import { Box } from '@remote-com/norma';
import styled from 'styled-components';

export const SignatureContainer = styled(Box)`
  overflow: hidden;
  margin-top: ${({ theme }) => theme.space[5]}px;
  border-radius: ${({ theme }) => theme.radii.md};
`;
export const SignaturePreview = styled(Box)<{ $isPlaceholder: boolean }>`
  ${({ theme }) => theme.typography['4xl']};
  color: ${({ theme, $isPlaceholder }) =>
    $isPlaceholder ? theme.colors.grey[300] : theme.colors.grey[900]};
  font-family: 'Dancing Script', cursive;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  padding: ${({ theme }) => theme.space[7]}px;
  min-height: 104px;
`;
export const SignatureDisclaimer = styled(Box)`
  padding: ${({ theme }) => theme.space[3]}px;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  border-radius: ${({ theme }) => theme.radii['2xs']};
  text-align: center;
`;
