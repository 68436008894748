// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneScan = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M7.5 2.75A4.75 4.75 0 0 0 2.75 7.5V8a.75.75 0 0 1-1.5 0v-.5c0-3.45 2.8-6.25 6.25-6.25H8a.75.75 0 0 1 0 1.5h-.5ZM15.25 2c0-.41.34-.75.75-.75h.5c3.45 0 6.25 2.8 6.25 6.25V8a.75.75 0 0 1-1.5 0v-.5a4.75 4.75 0 0 0-4.75-4.75H16a.75.75 0 0 1-.75-.75Zm-14 10c0-.41.34-.75.75-.75h20a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75ZM2 15.25c.41 0 .75.34.75.75v.5a4.75 4.75 0 0 0 4.75 4.75H8a.75.75 0 0 1 0 1.5h-.5a6.25 6.25 0 0 1-6.25-6.25V16c0-.41.34-.75.75-.75Zm20 0c.41 0 .75.34.75.75v.5c0 3.45-2.8 6.25-6.25 6.25H16a.75.75 0 0 1 0-1.5h.5a4.75 4.75 0 0 0 4.75-4.75V16c0-.41.34-.75.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneScan);
export { ForwardRef as IconV2DuotoneScan };