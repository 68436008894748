import { Box } from '@remote-com/norma';
import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';

const StyledCardDrawerHeaderWrapper = styled(Box)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  display: flex;
  flex-shrink: 0;
  gap: ${({ theme }) => theme.space[4]}px;
  justify-content: space-between;
  padding: var(--card-drawer-space-y) var(--card-drawer-space-x);
`;

type StyledCardDrawerHeaderProps = PropsWithChildren<{}>;

export const CardDrawerHeader = forwardRef<HTMLDivElement, StyledCardDrawerHeaderProps>(
  (props, ref) => {
    return <StyledCardDrawerHeaderWrapper ref={ref} {...props} />;
  }
);
