import { css } from 'styled-components';

const BRAND_500 = '#2496FF';
const BRAND_600 = '#0061ff';

const GREY_300 = '#cdd6df';
const GREY_400 = '#9aa6b2';
const GREY_500 = '#697786';
const GREY_600 = '#4b5865';

export const WHITE = '#ffffff';

const BOX_SHADOW = (shade: string) => `0 0 0 1px ${WHITE}, 0 0 0 3px ${shade}`;

const OUTLINE = `1px solid transparent`;

export const INPUT_STATES = {
  default: {
    resting: css`
      color: transparent;
      border-color: ${GREY_600};
    `,
    checked: css`
      color: ${BRAND_600};
      border-color: ${BRAND_600};
    `,
  },
  focus: {
    resting: css`
      color: ${WHITE};
      border-color: ${BRAND_600};
      box-shadow: ${BOX_SHADOW(BRAND_500)};
      outline: ${OUTLINE};
    `,
    checked: css`
      color: ${BRAND_600};
      border-color: ${BRAND_600};
      box-shadow: ${BOX_SHADOW(BRAND_500)};
      outline: ${OUTLINE};
    `,
  },
  active: {
    resting: css`
      color: ${WHITE};
      border-color: ${GREY_500};
      box-shadow: ${BOX_SHADOW(GREY_500)};
      outline: ${OUTLINE};
    `,
    checked: css`
      color: ${BRAND_600};
      border-color: ${BRAND_600};
      box-shadow: ${BOX_SHADOW(BRAND_500)};
      outline: ${OUTLINE};
    `,
  },
  disabled: {
    resting: css`
      color: ${WHITE};
      border-color: ${GREY_400};
    `,
    checked: css`
      color: ${GREY_300};
      border-color: ${GREY_400};
    `,
  },
  hover: {
    resting: css`
      color: transparent;
      border-color: ${BRAND_600};
    `,
    checked: css`
      color: ${BRAND_600};
      border-color: ${BRAND_600};
      background-color: rgba(0, 97, 255, 0.05);
    `,
  },
};
