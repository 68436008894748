// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneStar = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M15.56 4.45c-1.13-2.27-1.7-3.4-2.43-3.77a2.5 2.5 0 0 0-2.26 0c-.74.37-1.3 1.5-2.43 3.77-.3.61-.46.92-.67 1.16-.27.3-.61.55-1 .7-.3.1-.63.14-1.3.22-2.33.26-3.5.38-4.07.86a2.5 2.5 0 0 0-.84 2.53c.18.72 1.06 1.53 2.8 3.15.55.5.82.76 1 1.05.27.44.4.96.36 1.48-.02.34-.14.69-.38 1.38-.79 2.24-1.18 3.36-1.04 4.05a2.5 2.5 0 0 0 2.23 1.96c.7.06 1.77-.47 3.9-1.53l.78-.4c.66-.32.99-.49 1.33-.55.3-.06.62-.06.92 0 .35.06.67.23 1.33.56l.79.39c2.12 1.06 3.18 1.6 3.89 1.53 1.09-.1 2-.89 2.23-1.96.14-.7-.25-1.81-1.04-4.05a5.65 5.65 0 0 1-.38-1.38 2.5 2.5 0 0 1 .37-1.48c.17-.29.45-.54.99-1.05 1.74-1.62 2.62-2.43 2.8-3.15a2.5 2.5 0 0 0-.84-2.53c-.57-.48-1.74-.6-4.07-.86-.67-.08-1-.12-1.3-.23a2.5 2.5 0 0 1-1-.7c-.2-.23-.36-.54-.67-1.15Z" /><path d="m9.42 21.46.8-.4c.65-.32.97-.49 1.32-.55.3-.06.62-.06.92 0 .34.06.67.23 1.33.56l.79.39c2.12 1.06 3.18 1.6 3.89 1.53 1.09-.1 2-.89 2.23-1.96.14-.69-.25-1.81-1.04-4.05-.24-.7-.36-1.04-.38-1.38a2.5 2.5 0 0 1 .37-1.48H4.35c.28.44.4.96.37 1.48-.02.34-.14.69-.38 1.38-.79 2.24-1.18 3.36-1.04 4.05a2.5 2.5 0 0 0 2.23 1.96c.7.06 1.77-.47 3.9-1.53Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneStar);
export { ForwardRef as IconV2DuotoneStar };