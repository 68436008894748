import csvtojson, { csv } from 'csvtojson';
import isFinite from 'lodash/isFinite';

import { createObjectUrlFromBlob, generateAndDownloadDocument } from '@/src/domains/files/helpers';

export const readCSV = async (
  file,
  { convertNumbers = true, delimiter = 'auto', flatKeys = false } = {
    convertNumbers: true,
    delimiter: 'auto',
    flatKeys: false,
  }
) => {
  if (!file) return null;
  try {
    const CSVtext = await file.text();
    const csvData = await csvtojson({ delimiter, flatKeys }).fromString(CSVtext);

    if (!csvData) return null;
    return (
      csvData
        // delete all empty rows, usually just artifacts from Numbers or Excel
        .filter((row) => !Object.values(row).every((column) => !column))
        .map((row) => {
          const copy = { ...row };

          Object.entries(copy).forEach(([key, value]) => {
            // if it's an empty string, just mark it as undefined
            if (value === '' || value === '-') {
              copy[key] = undefined;
            }

            if (!convertNumbers) return;

            // if it's a number masked as a string, convert it to a number
            if (/^\d+$/.test(value) && isFinite(parseFloat(value))) {
              copy[key] = parseFloat(value);
            }
          });

          return copy;
        })
    );
  } catch {
    return file;
  }
};

export const readCSVRaw = async (
  file,
  { delimiter = 'auto' } = {
    delimiter: 'auto',
  }
) => {
  if (!file) return null;
  try {
    const contents = await file.text();
    const csvData = await csv({ delimiter, output: 'csv', noheader: true }).fromString(contents);

    if (!csvData) return null;
    return csvData;
  } catch {
    return file;
  }
};

export const arrayToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  const str = `${Object.keys(array[0])
    .map((value) => (value != null ? `"${value}"` : value))
    .join(',')}\r\n`;

  return array.reduce((curr, next) => {
    curr += `${Object.values(next)
      .map((value) => (value != null ? `"${value}"` : value))
      .join(',')}\r\n`;
    return curr;
  }, str);
};

export const downloadCSV = (data, filename) => {
  const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
  generateAndDownloadDocument({
    content: createObjectUrlFromBlob(blob),
    name: `${filename}`,
  });

  return blob;
};

/**
 * Take an array of objects of similar structure and convert it to a string.
 * @param      {Array}  data            Array of data
 * @return     {String}
 */
export const arrayToCsvCell = (data = []) => {
  if (!data?.length) {
    return '—';
  }

  return data
    .map((row) => {
      if ([undefined, null].includes(row)) {
        return '—';
      }

      if (['boolean', 'number', 'string'].includes(typeof row)) {
        return row;
      }

      if (Array.isArray(row)) {
        return `(${arrayToCsvCell(row)})`;
      }

      return `(${Object.entries(row)
        .map(([key, val]) => `${key}: ${JSON.stringify(val)}`)
        .join(', ')})`.replaceAll('"', ''); // we need to remove all " chars, because CSV terminates column otherwise
    })
    .join(', ');
};
