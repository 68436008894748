// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconCheckCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M15.66 3.77A9 9 0 1 0 21 12v-.92a1 1 0 1 1 2 0V12a11 11 0 1 1-6.52-10.05 1 1 0 1 1-.82 1.82Z" /><path fillRule="evenodd" clipRule="evenodd" d="M22.7 3.3a1 1 0 0 1 0 1.4l-10 10.02a1 1 0 0 1-1.4 0l-3-3a1 1 0 1 1 1.4-1.42l2.3 2.3 9.3-9.3a1 1 0 0 1 1.4 0Z" /></svg>;
const ForwardRef = forwardRef(IconCheckCircle);
export { ForwardRef as IconCheckCircle };