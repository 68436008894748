import { Text, Media } from '@remote-com/norma';
import styled from 'styled-components';

export const Checkbox = styled(Media)`
  --mediaObjectSpacing: 12px;
  display: flex;
  align-items: flex-start;
  position: relative;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  input[aria-disabled='true'] ~ div {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

export const Label = styled(Text).attrs(({ hidden, theme }) => {
  return {
    color: theme.colors.grey[900],
    display: hidden ? 'none' : 'flex',
    variant: 'baseMedium',
  };
})``;
