import { Spinner, Stack, Text } from '@remote-com/norma';
import styled from 'styled-components';

const messages = {
  label: 'Loading profiles…',
} as const;

const StyledWrapper = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[3]}px;
  justify-content: center;
  padding: ${({ theme }) => theme.space[4]}px;
`;

const StyledSpinner = styled(Spinner)`
  --profiles-menu-fetching-spinner-size: 16px;

  height: var(--profiles-menu-fetching-spinner-size);
  width: var(--profiles-menu-fetching-spinner-size);
`;

const StyledMessage = styled(Text)`
  ${({ theme }) => theme.typography.sm};

  color: ${({ theme }) => theme.colors.grey[600]};
`;

type ProfilesMenuFetchingProps = {
  label?: string;
};

export const ProfilesMenuFetching = ({ label = messages.label }: ProfilesMenuFetchingProps) => {
  return (
    <StyledWrapper>
      <StyledSpinner />
      <StyledMessage>{label}</StyledMessage>
    </StyledWrapper>
  );
};
