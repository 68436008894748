/**
 * Determines whether an error is a QuotaExceededError.
 *
 * Browsers love throwing slightly different variations of QuotaExceededError
 * (this is especially true for old browsers/versions), so we need to check
 * different fields and values to ensure we cover every edge-case.
 *
 * @param {unknown} err - The error to check
 * @return {boolean} - Is the error a QuotaExceededError?
 */
const isQuotaExceededError = (err: unknown) =>
  err instanceof DOMException &&
  // everything except Firefox
  (err.code === 22 ||
    // Firefox
    err.code === 1014 ||
    // test name field too, because code might not be present
    // everything except Firefox
    err.name === 'QuotaExceededError' ||
    // Firefox
    err.name === 'NS_ERROR_DOM_QUOTA_REACHED');

export const removeSessionStorageKey = (key: string) => {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    // eslint-disable-next-line no-console
    console.log(`sessionStorage key ${key} removed`);
    window.sessionStorage.removeItem(key);
  } catch (removeError) {
    console.error(`Failed to remove sessionStorage key ${key}`, removeError);
  }
};

export const handleIsQuotaExceededError = (key: string, err: unknown) => {
  if (isQuotaExceededError(err)) {
    // eslint-disable-next-line no-console
    console.warn(`Setting the value of '${key}' exceeded storage the quota.`);
    removeSessionStorageKey(key);
  } else {
    console.error(err);
  }
};

export const getLocalStorageItem = (key: string, initialValue: unknown): any => {
  if (typeof window === 'undefined') {
    return initialValue;
  }
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // If error also return initialValue
    console.error(error);
    return initialValue;
  }
};

export const setLocalStorageItem = (key: string, value: unknown) => {
  try {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    handleIsQuotaExceededError(key, error);
  }
};
