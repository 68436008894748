import { useGet } from '@remote-com/data-layer';

export default function useCompanyCurrenciesData(options = {}) {
  return useGet('/api/v1/company-currencies', {
    options: {
      select: (res) => res.data,
      ...options,
    },
  });
}
