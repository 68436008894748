// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconActivity = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M9 2a1 1 0 0 1 .95.68L15 17.84l2.05-6.16A1 1 0 0 1 18 11h4a1 1 0 1 1 0 2h-3.28l-2.77 8.32a1 1 0 0 1-1.9 0L9 6.16l-2.05 6.16A1 1 0 0 1 6 13H2a1 1 0 1 1 0-2h3.28l2.77-8.32A1 1 0 0 1 9 2Z" /></svg>;
const ForwardRef = forwardRef(IconActivity);
export { ForwardRef as IconActivity };