import type { InvoiceReportTypeFilterValue } from '@/src/domains/invoices/constants';
import {
  invoiceReportTypeFilterValues,
  invoiceReportFilterTypeLabels,
  invoiceReportTypeByGroupForEmployers,
  invoiceReportFilterTypeLabelsForEmployers,
} from '@/src/domains/invoices/constants';

export const getInvoiceReportTypeFilterLabel = (
  type: InvoiceReportTypeFilterValue,
  userIsAdmin?: boolean
) => {
  if (!userIsAdmin) {
    return invoiceReportFilterTypeLabelsForEmployers[type];
  }

  return invoiceReportFilterTypeLabels[type];
};

export function getInvoiceReportTypeFilterOptions(
  userIsAdmin: boolean,
  isContractorProtectEnabled: boolean,
  isRecruitPackageEnabled: boolean
) {
  const types: Partial<typeof invoiceReportTypeFilterValues> = {
    ...invoiceReportTypeFilterValues,
  };

  // Do not expose CM Protect invoice option while `agent_of_record` feature is
  // off; afterwards, we can remove this filtering logic.
  const isInvoiceReportFilterSupported = (type: InvoiceReportTypeFilterValue) => {
    if (type === invoiceReportTypeFilterValues.CONTRACTOR_PAYMENTS_INVOICE) {
      return isContractorProtectEnabled;
    }

    if (type === invoiceReportTypeFilterValues.RECRUIT_SUBSCRIPTION) {
      return isRecruitPackageEnabled;
    }

    return true;
  };

  if (!userIsAdmin) {
    return invoiceReportTypeByGroupForEmployers.map((group) => ({
      ...group,
      options: group.options.filter(isInvoiceReportFilterSupported).map((option) => ({
        label: getInvoiceReportTypeFilterLabel(option, false),
        value: option,
      })),
    }));
  }

  if (!userIsAdmin) {
    delete types.CORRECTION_INVOICE;
    delete types.CORRECTION_CREDIT_NOTE;
  }

  return Object.values(types)
    .filter(isInvoiceReportFilterSupported)
    .map((type: InvoiceReportTypeFilterValue) => {
      return {
        label: getInvoiceReportTypeFilterLabel(type, userIsAdmin),
        value: type,
      };
    });
}
