import type { CollapsibleProps as RadixCollapsibleProps } from '@radix-ui/react-collapsible';
import { Root, Trigger } from '@radix-ui/react-collapsible';
import { forwardRef } from 'react';
import type { ReactNode } from 'react';

import { StyledContent, StyledSeparator } from './Collapsible.styled';

type CollapsibleProps = {
  /**
   * The button or element that will trigger the collapsible.
   */
  trigger: ReactNode;
  /**
   * Whether the collapsible is open or closed. Must be used with `onOpenChange`.
   */
  open?: boolean;
  /**
   * Event handler called when the open state of the collapsible changes.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Whether to render a separator between the trigger and content.
   */
  renderSeparator?: boolean;
} & RadixCollapsibleProps;

/**
 * A collapsible component that can be used to hide and show content
 * based on user interaction with the trigger component.
 *
 * It includes an animation to slide the content up and down.
 */
export const Collapsible = forwardRef<HTMLDivElement, CollapsibleProps>(
  (
    { trigger, renderSeparator = false, open, onOpenChange, children, ...rest }: CollapsibleProps,
    ref
  ) => {
    return (
      <Root open={open} onOpenChange={onOpenChange} {...rest} ref={ref}>
        <Trigger asChild>{trigger}</Trigger>
        {renderSeparator && <StyledSeparator />}
        <StyledContent>
          <>{children}</>
        </StyledContent>
      </Root>
    );
  }
);
