import { Box, Text } from '@remote-com/norma';
import { IconV2OutlineAlertCircle } from '@remote-com/norma/icons/IconV2OutlineAlertCircle';
import type { Node as ProseMirrorNode } from '@tiptap/pm/model';
import type { DecorationWithType, Node, NodeViewProps } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import type { MouseEvent } from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { padNodeLabel } from '@/src/domains/contracts/ContractsEditor/nodes/ManuallyEnteredField/helpers';
import { MANUALLY_ENTERED_FIELD_CLICK_EVENT_NAME } from '@/src/domains/contracts/ContractsEditor/nodes/ManuallyEnteredField/ManuallyEnteredField';
import { EditableContentTag } from '@/src/domains/contracts/ContractsEditor/nodes/shared/ContentTag';
import {
  HoverContent,
  NoHoverContent,
} from '@/src/domains/contracts/ContractsEditor/nodes/shared/ContentTag.styles';
import type { ManuallyEnteredFieldAttrs } from '@/src/domains/contracts/contractTemplate/ManuallyEnteredFields/types';

type BadgeBoxProps = {
  $isFocused: boolean;
  $isClickable: boolean;
};

const ManuallyEnteredFieldBadge = styled(Text).attrs(({ $isFocused }: BadgeBoxProps) => ({
  variant: 'xsSemiBold',
  bg: $isFocused ? 'green.400' : 'green.200',
  color: $isFocused ? 'grey.800' : 'green.900',
}))<BadgeBoxProps>`
  display: inline;
  border-radius: 4px;

  /* Vertically aligning with smart-field component */
  padding: 1px 0 2px;

  /* Maintain whitespace, so that trailing space is not collapsed, but allow wrapping long strings. */
  white-space: pre-wrap;

  ${({ theme, $isClickable }) =>
    $isClickable &&
    css`
      &:hover {
        box-shadow: 0 0 0 1px ${theme.colors.green[700]};
      }
    `}

  ${NoHoverContent} {
    display: inline;
  }

  ${HoverContent} {
    /* To compensate for the 'padding-top' offset above, deduct 1px from
     * the EDIT button offset. */
    top: -1px;
    /* 'top' and 'transform: translateY' work together in HoverContent:
     * by changing top to -1px, we need to unset the transform.
     * better fix tracked at https://linear.app/remote/issue/COD-1353/contracts-editor-manually-entered-fields-wrapping-causes-incorrect 
     */
    transform: translateY(0);
    /* Provide the same background as the parent, to always have some
     * background behind the letters,
     * see https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/19642#note_1569290361 */
    background-color: inherit;
    clip-path: inset(1px);
  }
`;

const InlineEditableContentTag = styled.span`
  background-color: inherit;
  border-radius: inherit;
`;

type NodeDecoration = DecorationWithType & {
  type: {
    attrs: Partial<{
      isFocused: boolean;
    }>;
  };
};

type ManuallyEnteredFieldNode = Omit<ProseMirrorNode, 'attrs'> & {
  attrs: Partial<ManuallyEnteredFieldAttrs>;
};

type Props = Omit<NodeViewProps, 'decorations' | 'node'> & {
  node: ManuallyEnteredFieldNode;
  extension: Node<{
    hasEditOnClick: boolean;
    hasStyling: boolean;
    hasValueRendered: boolean;
  }>;
  decorations: readonly NodeDecoration[];
};

function toValidManuallyEnteredFieldNode(node: ManuallyEnteredFieldNode) {
  return {
    ...node,
    attrs: {
      ...node.attrs,
      id: node.attrs.id ?? '',
      label: node.attrs.label ?? '',
      value: node.attrs.value ?? '',
      description: node.attrs.description ?? '',
    },
  };
}

export const ManuallyEnteredFieldComponent = forwardRef((props: Props, ref) => {
  const { node: nullishNode, decorations, editor, extension } = props;

  const node = toValidManuallyEnteredFieldNode(nullishNode);

  const fieldLabel = node.attrs.label;
  const fieldValue = node.attrs.value;
  const { hasEditOnClick, hasStyling, hasValueRendered } = extension.options;

  const valueOrLabel = fieldValue || fieldLabel;
  const nodeLabel = hasValueRendered ? valueOrLabel : fieldLabel;

  const isFocused = decorations.some((decoration) => decoration.type.attrs.isFocused);
  const isFieldClickable = hasEditOnClick && editor.isEditable;
  const isValueMissing = hasValueRendered && !fieldValue;

  function onFieldClick(event: MouseEvent<HTMLElement>) {
    if (isFieldClickable) {
      // NOTE: Bracket notation for `.emit` is used to override the TypeScript's
      // warning that the method is protected. Arguably, it makes sense to prevent
      // `editor.emit` from emitting internal editor events, but here we are working
      // with a custom event.
      // eslint-disable-next-line dot-notation, @typescript-eslint/dot-notation
      editor['emit'](MANUALLY_ENTERED_FIELD_CLICK_EVENT_NAME, { editor, event, node });
    }
  }

  return (
    <NodeViewWrapper as="span" ref={ref} data-type={extension.name}>
      {/**
       * Shielding the text content of this node from
       * actually being edited. This change is linked to
       * the Node being marked as editable via the `content` property
       * More context: https://linear.app/remote/issue/COD-1291/contracts-editor-bug-with-cmdarrow-to-move-to-beginning-of-text
       */}
      <span contentEditable={false}>
        {hasStyling ? (
          <ManuallyEnteredFieldBadge $isFocused={isFocused} $isClickable={isFieldClickable}>
            <EditableContentTag
              as={InlineEditableContentTag}
              onClick={onFieldClick}
              enableHover={isFieldClickable}
            >
              {
                // Pad the label with spaces, so that EDIT action that appears on hover
                // does not overlap the surrounding text. Note that `min-width` cannot
                // be used because all manually-entered field elements are made inline to
                // flow as a regular text.
                isFieldClickable ? padNodeLabel(nodeLabel, isValueMissing ? 4 : 14) : nodeLabel
              }
              {isValueMissing && (
                <Box as="span" display="inline-flex" flexDirection="row">
                  {' (missing) '}
                  <IconV2OutlineAlertCircle width={14} />
                </Box>
              )}
            </EditableContentTag>
          </ManuallyEnteredFieldBadge>
        ) : (
          nodeLabel
        )}
      </span>
    </NodeViewWrapper>
  );
});
