import { ModalActionable } from '@/src/components/Modal/ModalActionable';

type DeleteConfigModalProps = {
  configurationName: string;
  hideModal: () => void;
  onConfirm: (configurationName: string) => void;
  open: boolean;
  setListenToClickOutside: (listen: boolean) => void;
  messages: {
    title: string;
    description: (descriptionName: string) => string;
    deleteButton: string;
  };
};

export const DeleteConfigModal = ({
  open,
  configurationName,
  onConfirm,
  hideModal,
  setListenToClickOutside,
  messages,
}: DeleteConfigModalProps) => {
  function closeModal() {
    setListenToClickOutside(true);
    hideModal();
  }

  function handleConfirm() {
    onConfirm(configurationName);
    closeModal();
  }

  return (
    <ModalActionable
      title={messages.title}
      description={messages.description(configurationName)}
      confirmLabel={messages.deleteButton}
      onConfirm={handleConfirm}
      onCancel={closeModal}
      tone="destructive"
      open={open}
    />
  );
};
