import type { ComponentPropsWithoutRef } from 'react';

import { MonthPickerHeader } from './Header';
import { DatePickerHeader as Header } from './Header/Header';

/**
 * Props that both `Header` and `MonthPickerHeader` share.
 */
type CommonProps = ComponentPropsWithoutRef<typeof Header> &
  ComponentPropsWithoutRef<typeof MonthPickerHeader>;

/**
 * Common props that are forwarded to either header component as-is.
 */
type ForwardedProps = Pick<
  CommonProps,
  | 'minDate'
  | 'maxDate'
  | 'onChangeMonth'
  | 'isLoadingWorkCalendars'
  | 'selectsRange'
  | 'headerLinkProps'
>;

/**
 * Custom props for this header switcher.
 */
type CustomProps = {
  customHeaderProps: any;
  showMonthYearPicker?: boolean;
  disableYearSelection?: boolean;
};

type Props = CustomProps & ForwardedProps;

export const datePickerCustomHeader = ({
  customHeaderProps,
  maxDate,
  minDate,
  onChangeMonth,
  isLoadingWorkCalendars,
  headerLinkProps,
  showMonthYearPicker,
  disableYearSelection,
  selectsRange,
}: Props) => {
  const extendedCustomHeaderProps = {
    ...customHeaderProps,
    minDate,
    maxDate,
    onChangeMonth,
    isLoadingWorkCalendars,
    selectsRange,
    ...(!!headerLinkProps && {
      headerLinkProps: {
        onHeaderLinkClick: headerLinkProps.onHeaderLinkClick,
        headerLinkText: headerLinkProps.headerLinkText,
        isHeaderLinkOpen: headerLinkProps.isHeaderLinkOpen,
      },
      reverseOrder: true,
    }),
  };

  if (showMonthYearPicker && disableYearSelection) {
    return null;
  }

  if (showMonthYearPicker) {
    return <MonthPickerHeader {...extendedCustomHeaderProps} />;
  }
  return <Header {...extendedCustomHeaderProps} />;
};
