import { todoItemEvents, trackEvent, pathnameToLocationOfAction } from '@remote-com/analytics';
import type { Event } from '@remote-com/analytics';
import { BoxedIcon, Stack, Text } from '@remote-com/norma';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { KeyboardEvent, MouseEvent, PropsWithChildren } from 'react';
import { useEffect } from 'react';

import type { TodoUpdate } from '@/src/api/config/employ/shared.types';
import { todosByType } from '@/src/domains/todos/config';
import { todoCategoryTone } from '@/src/domains/todos/constants';
import { DueDatePill } from '@/src/domains/todos/DueDatePill';
import { getTodoPropsFromConfig, getTodoItemDueDate } from '@/src/domains/todos/helpers';
import { useTodosOverlayContext } from '@/src/domains/todos/TodosOverlayProvider';
import type { TodoItemProps } from '@/src/domains/todos/types';
import { formatWeekDayDayOfMonthMonthShort, getRelativeFormattedDate } from '@/src/helpers/date';

import { TodoOverlayListItemWrapper } from './TodosOverlayList.styled';

type TodoItemWrapperComponentProps = PropsWithChildren<{
  href?: TodoItemProps['href'];
  onClick: TodoItemProps['onClick'];
}>;

/**
 * Wraps the todo item with a link or a button.
 */
const TodoItemWrapperComponent = ({ children, href, onClick }: TodoItemWrapperComponentProps) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      // @ts-expect-error: event is not a MouseEvent
      handleOnClick(event);
    }
  };

  return href ? (
    <Link href={href} passHref legacyBehavior>
      <TodoOverlayListItemWrapper forwardedAs="a" onClick={onClick}>
        {children}
      </TodoOverlayListItemWrapper>
    </Link>
  ) : (
    <TodoOverlayListItemWrapper
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      {children}
    </TodoOverlayListItemWrapper>
  );
};

type TodoProps = Omit<TodoItemProps, 'locationOfAction'>;

/**
 * Renders the details of the todo item.
 */
const TodoItem = ({
  category,
  description,
  dueDate,
  href,
  Icon,
  onClick,
  startDate,
  title,
  type,
}: TodoProps) => {
  const router = useRouter();
  const { closeOverlay } = useTodosOverlayContext();

  const trackTodoItemEvent = (event?: Event) => {
    trackEvent(event || todoItemEvents.TODO_ITEM_VIEWED, {
      messageDetails: type,
      locationOfAction: pathnameToLocationOfAction(router.pathname),
      status: getTodoItemDueDate(dueDate),
    });
  };

  useEffect(() => {
    trackTodoItemEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = (event: MouseEvent<HTMLElement>) => {
    trackTodoItemEvent(todoItemEvents.TODO_ITEM_CLICKED);
    closeOverlay();

    onClick?.(event);
  };

  return (
    <TodoItemWrapperComponent href={href} onClick={handleOnClick}>
      <BoxedIcon tone={todoCategoryTone?.[category] || 'grey'} Icon={Icon} size="md" />
      <Stack direction="column">
        <Text variant="smMedium" color="grey.900">
          {title}
        </Text>
        <Text variant="xs" color="grey.600">
          {description}
        </Text>
        <Stack direction="row" mt={3} gap={3} alignItems="center" flexWrap="wrap">
          {dueDate && <DueDatePill date={new Date(dueDate)} />}
          {startDate && (
            <Text as="time" dateTime={startDate.toISOString()} color="grey.500" variant="xs">
              {getRelativeFormattedDate(startDate.toISOString(), {
                formatPast: formatWeekDayDayOfMonthMonthShort,
                formatFuture: formatWeekDayDayOfMonthMonthShort,
              })}
            </Text>
          )}
        </Stack>
      </Stack>
    </TodoItemWrapperComponent>
  );
};

type TodosOverlayListItemProps = {
  todo: TodoUpdate;
};

/**
 * Renders a todo item with the config provided by `todosByType`
 */
export const TodosOverlayListItem = ({ todo }: TodosOverlayListItemProps) => {
  const todoTypeConfig: TodoItemProps | unknown = todosByType[todo.type]?.({
    data: todo,
  });
  const todoItemProps = getTodoPropsFromConfig(todoTypeConfig, todo);

  /**
   * ⚠️ ATTENTION: DO NOT REMOVE OR ADD ANY TODOS FROM THE API RESPONSE!!
   *
   * If you want items to appear only for specific users or under certain conditions,
   * create them only for relevant users. Altering which items are displayed can lead
   * to side effects that may result in customer support requests.
   *
   * If you have any questions or doubts, reach out to #dev-team-web-experience
   */
  if (!todoItemProps) {
    return null;
  }

  return <TodoItem {...todoItemProps} />;
};
