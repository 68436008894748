import { addDays, differenceInMonths, getYear, isFuture, isToday, parseISO } from 'date-fns';
import flow from 'lodash/flow';
import kebabCase from 'lodash/kebabCase';

import { REMOTE_COUNTRY_EXPLORER_ROUTE } from '@/src/constants/routes';
import { usCountryCode } from '@/src/domains/countries/constants';
import {
  IR35_STATUSES,
  allowedStatusesForSalaryAndStartDateEdits,
  contractType,
  customerDataScopeTypes,
  employmentProfileHeaderLabels,
  employmentStatus,
  employmentType,
  onboardingStatuses,
} from '@/src/domains/employment/constants';
import { contractDurationTypeOptions } from '@/src/domains/employment/employer/contractDetails/constants';
import { offboardingType, offboardingTypeReasonsMap } from '@/src/domains/offboarding/constants';
import { productTypes, productTypeLabels } from '@/src/domains/payroll/constants';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import {
  dateStringToLocalTime,
  formatDayMonthYear,
  formatMonthDayYear,
  formatYearMonthDay,
} from '@/src/helpers/date';
import { pickKey } from '@/src/helpers/general';

export function getLastEmployment(employments) {
  if (!employments || !employments.length) {
    return null;
  }
  return employments.reduce((a, b) => (new Date(a.insertedAt) > new Date(b.insertedAt) ? a : b));
}

export function getActiveEmployment(employments) {
  if (!employments || !employments.length) {
    return null;
  }
  const activeEmployments = employments.filter(
    (employment) => employment.status === employmentStatus.ACTIVE
  );

  if (activeEmployments.length === 1) return activeEmployments[0];

  return getLastEmployment(activeEmployments);
}

/** Returns the active employment or the last employment as fallback */
export function getActiveEmploymentWithFallback(employments) {
  if (!employments || !employments.length) {
    return null;
  }
  const activeEmployment = getActiveEmployment(employments);

  return activeEmployment || getLastEmployment(employments);
}

/**
 * Filter employments to get only those with onboarding statuses
 * @param {array} employments
 * @returns {array}
 */
export function getPendingEmployments(employments = []) {
  return employments?.filter((employment) => onboardingStatuses.includes(employment.status));
}

// activeEmployment can have non-active statuses e.g. archived
export function isActiveEmploymentStatusActive(user) {
  return user?.activeEmployment?.status === employmentStatus.ACTIVE;
}

function getLastContract(contracts) {
  return contracts.reduce((a, b) => {
    // Handles amendments
    if (a.amendedContractSlug === b.slug) {
      return a;
    }

    // Handles multiple initial contracts
    return new Date(a.provisionalStartDate) >= new Date(b.provisionalStartDate) ? a : b;
  });
}

/** Returns the active contract, last active contract or the last contract of the employment as fallback */
export function getActiveContractWithFallback(employment) {
  const { contracts } = employment || {};

  if (!contracts || contracts.length === 0) return null;

  const activeContract =
    contracts.find((contract) => contract.active) ||
    contracts.find((contract) => contract.activeOrLastActive);

  if (activeContract) return activeContract;

  return getLastContract(contracts);
}

export function verifyIfEmploymentIsBeingOnboarded(status) {
  return [
    employmentStatus.CREATED,
    employmentStatus.INVITED,
    employmentStatus.INITIATED,
    employmentStatus.PENDING,
    employmentStatus.REVIEW,
  ].includes(status);
}

/**
 * Check if a given employment object is of a full-time employee.
 *
 * To check if the current user is a full-time employee, use `isEmployee`
 * from `src/domains/registration/auth/helpers` - it will maintain
 * compatibility with other permissions available to full-time employees.
 *
 * @param {object} employment
 * @returns {boolean}
 */
export function isFullTime(employment) {
  return employment?.type === employmentType.FULL_TIME;
}

/**
 * Check if a given employment object is of a contractor type.
 *
 * To check if the current user is a contractor, use `isContractor`
 * from `src/domains/registration/auth/helpers` - it will maintain
 * compatibility with other permissions available to contractors.
 *
 * @param {object} employment
 * @returns {boolean}
 */
export function isContractor(employment = undefined) {
  return employment?.type === employmentType.CONTRACTOR;
}

/**
 * Check if a given employment object is of a direct type.
 *
 * To check if the current user is a direct employee, use `isDirectEmployee`
 * from `src/domains/registration/auth/helpers` - it will maintain
 * compatibility with other permissions available to direct employee.
 *
 * @param {object} employment
 * @returns {boolean}
 */
export function isDirect(employment) {
  return employment?.type === employmentType.DIRECT;
}

export function isGlobalPayroll(employment) {
  return employment?.product?.employmentType === productTypes.GLOBAL_PAYROLL;
}

export function isGlobalPayrollGustoEmployment(employment, countryCode) {
  return isGlobalPayroll(employment) && countryCode === usCountryCode;
}

/**
 * @typedef EmploymentPartialWithStatus
 * @prop {string} status
 */

/**
 * Returns whether the given Employment is in any onboarding of the onboarding states
 * @param {EmploymentPartialWithStatus=} employment
 * @returns {boolean}
 */
export function isOnboarding(employment) {
  return employment?.status && onboardingStatuses.includes(employment.status);
}

export const getGlobalPayrollProduct = ({ data }) => {
  const [result] = data.products.filter(
    ({ employmentType: productEmploymentType }) =>
      productEmploymentType === productTypes.GLOBAL_PAYROLL
  );
  return { data: { products: result } };
};

export function isUSCompany(company = null) {
  return company.country?.code === 'USA';
}

/**
 * Get the friendly contract name based on the user contract type value from the API
 * @param {string} type
 */
export function getContractTypeLabel(type) {
  let friendlyName = '';

  switch (type) {
    case contractType.FULL_TIME:
      friendlyName = 'Employee';
      break;
    case contractType.CONTRACTOR:
      friendlyName = 'Contractor';
      break;
    default:
      friendlyName = '-';
      break;
  }

  return friendlyName;
}

export function getEligibleManagersOptions(eligibleManagers, currentManager) {
  // a currentManager set in the past could no longer be in the list of eligible managers.
  // if so, include them in the dropdown disabled so they can be viewed but overwritten.
  const shouldConcatDisabledCurrentManager =
    currentManager && !eligibleManagers.some(({ slug }) => slug === currentManager.slug);

  const options = shouldConcatDisabledCurrentManager
    ? [
        ...eligibleManagers,
        {
          ...currentManager,
          name: `${currentManager.name} (cannot manage in Remote)`,
          isDisabled: true,
        },
      ]
    : eligibleManagers;

  return options.map(({ name, slug, isDisabled }) => ({
    name,
    slug,
    label: name,
    value: slug,
    isDisabled,
  }));
}

export function getManagerOptions(admins = [], teamMembers = []) {
  const adminsOptions = admins?.map((admin) => {
    const slug = admin?.user?.slug;
    const { name } = admin?.user;
    const roleSlug = admin?.user?.assignedRoles?.[0]?.name.toLowerCase();

    return {
      label: name,
      value: slug,
      role: roleSlug,
    };
  });

  const teamMembersOptions = teamMembers?.map((employee) => {
    const employeeUser = employee?.user;
    const contract = employee?.employment?.contract;

    const { name, slug, email, status } = employeeUser;
    const { jobTitle } = contract;

    return {
      label: name,
      value: slug,
      email,
      status,
      jobTitle,
    };
  });

  const managerOptions = [
    {
      label: 'Company admins',
      options: adminsOptions,
    },
    {
      label: 'Team members',
      options: teamMembersOptions,
    },
  ];

  return managerOptions;
}

export function getTeamMembersOptions(teamMembers = []) {
  const teamMembersData = teamMembers?.map((teamMember) => {
    const { user, employment } = teamMember;
    const { linkedCompanyAdmin } = user;

    const option = {
      label: user?.name,
      value: user?.slug,
      role: linkedCompanyAdmin?.assignedRoles?.[0]?.name,
      linkedCompanyAdmin,
      employment,
    };

    return option;
  });

  const managerTeamMembersOptions = [
    {
      label: 'Team members',
      options: teamMembersData,
    },
  ];

  return managerTeamMembersOptions;
}

export function getCompanyDepartmentsOptions(departments = []) {
  return departments.map(({ name, slug }) => ({
    name,
    slug,
    value: slug,
    label: name,
  }));
}

/**
 * Compose an email link
 * @param {object} params
 * @param {string} params.email
 * @param {object} params.data
 * @param {string} params.data.bcc
 * @param {string} params.data.body
 * @param {string} params.data.cc
 * @param {string} params.data.subject
 */
export function composeMailToHref({ email, data }) {
  let queryStrings = '';

  Object.keys(data)?.forEach((queryStringProperty) => {
    const value = data[queryStringProperty];

    if (value !== undefined) {
      const delimiter = queryStrings.length === 0 ? '?' : '&';
      const urlEncodedQueryStringValue = encodeURI(value);
      queryStrings += `${delimiter + queryStringProperty}=${urlEncodedQueryStringValue}`;
    }
  });

  const href = `mailto:${email + queryStrings}`;

  return href;
}

/**
 * Legal requirement:
 * SDS File attachement is only required for inside and outside ir35 status.
 * @param {string} ir35
 * @returns
 */
export function isSDSAttachementRequired(ir35) {
  return [IR35_STATUSES.INSIDE.value, IR35_STATUSES.OUTSIDE.value].includes(ir35);
}

/**
 * @param {import('@/src/api/config/employ/compensation.types').Compensation[] | undefined} compensations
 * @returns
 */
export function getActiveCompensation(compensations) {
  return compensations?.find((compensation) => compensation.status === 'active');
}

/**
 * Get employment from user details
 * @param {object} user object returned by /users/${slug}/employments API
 * @param {string} employmentSlug
 * @returns {object | null }
 */
export const getEmploymentFromUserDetails = (user, employmentSlug) => {
  if (!user) {
    return null;
  }

  if (employmentSlug) {
    return user?.employments?.find(({ slug }) => slug === employmentSlug);
  }

  return user?.employments?.find(({ status }) => status === 'active');
};

/**
 * @param {object} employment
 * @returns {object | null}
 */
export const getCompanyFromEmployment = (employment) => employment?.company || null;

/**
 * @param {object} employment
 * @returns {object | null}
 */
export const getContractFromEmployment = (employment) =>
  employment?.contracts?.find(({ active }) => active) || null;

/**
 * Returns a URL to the country explorer page for a given country
 * Country should be formatted as the lower case, hyphenated name
 * without abbreviations (e.g., "United Kingdom (U.K.)" should be "united-kingdom")
 * @param {string} countryName string of country name (e.g., United States)
 * @param {string} employmentSlug
 * @returns {string}
 */
export const getCountryExplorerUrl = (countryName = '') =>
  `${REMOTE_COUNTRY_EXPLORER_ROUTE}/${kebabCase(
    countryName.substring(
      0,
      countryName.indexOf('(') > 0 ? countryName.indexOf('(') : countryName.length
    )
  )}`;

/**
 *
 * @param {object} employmentEmployeeInfo
 * @returns {object | null}
 */
export async function getActiveEmploymentCountry(employmentEmployeeInfo) {
  return employmentEmployeeInfo?.employment?.contract?.country;
}

export const getContractDetailsWithDurationFallback = (contractDetails) => {
  const hasContractDuration = contractDetails?.contractDuration;
  const hasContractDurationType = contractDetails?.contractDurationType;

  if (hasContractDuration) {
    const { contractDurationType, contractEndDate, ...detailsWithLegacyContractDuration } =
      contractDetails;
    return hasContractDurationType ? contractDetails : detailsWithLegacyContractDuration;
  }

  if (hasContractDurationType) {
    // In this branch, we assume that if a contract hasContractDurationType, then we assume the migration from the old field type has been done, so we also include the contractEndDate. This doesn't mean contractEndDate will always have data, since it's possible we couldn't extract the end date from the old contract_duration field manually during the data migration.
    const { contractDuration, ...details } = contractDetails;
    return details;
  }

  return contractDetails;
};

const getContractsWithDurationFallback = (contracts = []) => {
  return contracts.map((contract) => {
    const contractDetails = contract?.contractDetails?.details;

    const contractDetailsWithDurationFallback =
      getContractDetailsWithDurationFallback(contractDetails);

    const doDetailsIncludeDurationFallback =
      contractDetailsWithDurationFallback !== contractDetails;

    if (!doDetailsIncludeDurationFallback) {
      return contract;
    }

    return {
      ...contract,
      contractDetails: {
        ...contract.contractDetails,
        details: contractDetailsWithDurationFallback,
      },
    };
  });
};

const getContractsWithActiveCompensation = (contracts = []) => {
  return contracts.map((contract) => ({
    ...contract,
    activeCompensation: getActiveCompensation(contract.compensations),
  }));
};

/**
 * Returns an array of doubly linked lists of initial contracts and their amendments
 * If a contract amendment doesn't have a link, it won't get an amendment reference
 ** [contract -> amendment, contract -> amendment -> amendment]
 ** [amendedContract <- amendment, amendedContract <- amendedContract <- amendment]
 *
 * @param {Array} [contracts=[]]
 * @return {Array} [contracts] – mutated contracts with links
 */
const getInitialContractsWithAmendmentsLinked = (contracts = []) => {
  const amendedContractsSlugs = contracts
    .filter((c) => c.amendedContractSlug)
    .map((c) => c.amendedContractSlug);

  return contracts.map((contract) => {
    if (amendedContractsSlugs.includes(contract.slug)) {
      contract.amendment = contracts.find((c) => c.amendedContractSlug === contract.slug);
      contract.amendment.amendedContract = contract;
    }

    return contract;
  });
};

/**
 * From a mutated initial contract with a linked list, returns an array of contracts by
 * following the links up the chain.
 *
 * @param {Object} contract
 * @param {Object} contract.amendment
 * @return {Array} [contracts]
 */
const getAmendmentsFromInitialContract = (contract) => {
  if (contract.amendment) {
    return [...getAmendmentsFromInitialContract(contract.amendment), contract];
  }
  return [contract];
};

/**
 * Returns initial contracts and amendments sorted by most recent amendments on top.
 * Used to display a list of amendments (creation order) and their respective initial contracts (effective date order from the BE).
 * E.g. [amends_amends_initial_slug_1, amends_initial_slug_1, initial_slug_1, amends_initial_slug_2, initial_slug_2]
 *
 * @param {Array} [contracts=[]]
 * @return {Array} [contracts] – flattened list of initial contract and their amendments
 */
export const getSortedInitialContractsWithAmendments = (contracts = []) => {
  const transformationPipeline = flow(
    getContractsWithActiveCompensation,
    getContractsWithDurationFallback,
    getInitialContractsWithAmendmentsLinked
  );

  const initialContracts = transformationPipeline(contracts).filter((c) => !c.amendedContractSlug);

  return initialContracts.flatMap((contract) => getAmendmentsFromInitialContract(contract));
};

/**
 *  From a list of contracts, get only the amendments of the most recent initial contract
 *
 * @param {Array} [contracts=[]]
 * @return {Array} contract amendments
 */
export function getContractAmendmentsOnly(contracts = []) {
  const contractsWithAmendments = getSortedInitialContractsWithAmendments(contracts);

  const recentInitialContractIdx = contractsWithAmendments.findIndex(
    (contract) => !contract.amendedContractSlug
  );

  return contractsWithAmendments.slice(0, recentInitialContractIdx);
}

/**
 * Returns the min and max dates available for provisionalStartDate field.
 * For new contracts, min provisionalStartDate must be one day after the latest contract effective date.
 * When editing initial contracts, min provisionalStartDate must be the provisionalStartDate of the previous contract (if it exists),
 * max provisionalStartDate must be the provisionalStartDate of the next contract (if it exists).
 * There's no restrictions for amendments
 *
 * @param {Array[contract]} contracts Array of contract objects sorted by effectiveDate
 * @param {Object} contract Contract to edit or amend, null if isNewContract
 * @param {Boolean} isNewContract Retrieving the allowed dates for a new contract?
 * @param {Boolean} isAmending Amending an initial contract?
 * @returns {Object} interval
 * @returns {String} interval.minStartDate
 * @returns {String} interval.maxStartDate
 */
export const getContractAllowedDates = ({
  contracts = [],
  contract,
  isAmending,
  isNewContract,
}) => {
  if (isNewContract) {
    const lastContract = contracts[0];

    return {
      minStartDate: lastContract
        ? addDays(dateStringToLocalTime(lastContract.effectiveDate), 1)
        : null,
    };
  }

  const isAmendingOrAmendment = isAmending || contract.amendedContractSlug;

  if (isAmendingOrAmendment) {
    return {};
  }

  const contractIndex = contracts.findIndex((c) => c.slug === contract.slug);
  const previousContract = contracts[contractIndex + 1];
  const nextContract = contracts[contractIndex - 1];

  return {
    minStartDate: previousContract
      ? dateStringToLocalTime(previousContract.provisionalStartDate)
      : null,
    maxStartDate: nextContract ? dateStringToLocalTime(nextContract.provisionalStartDate) : null,
  };
};

export const getDisplayExpirationDate = ({ contract }) => {
  const hasContractEndDate = Boolean(contract?.contractDetails?.details?.contractEndDate);
  const hasExpirationDate = Boolean(contract?.expirationDate);

  return hasExpirationDate || !hasContractEndDate;
};

export const hasFixedTermContract = (contractDurationType) =>
  contractDurationType === contractDurationTypeOptions.FIXED_TERM.value;

export function getMultiDateFormatArray(date) {
  if (!date) return [];

  return [formatMonthDayYear(date), formatYearMonthDay(date), formatDayMonthYear(date)];
}

/**
 *
 * @param {*} currentEmploymentStatus
 * @returns Boolean
 */
export function isSalaryAndStartDateEditStatus(currentEmploymentStatus) {
  return allowedStatusesForSalaryAndStartDateEdits.includes(currentEmploymentStatus);
}

export const getOffboardingTypeFromReason = (terminationReason = '') => {
  if (!terminationReason) return null;

  return Object.values(offboardingType).find((type) =>
    Object.values(offboardingTypeReasonsMap[type]).includes(terminationReason)
  );
};

export function createDepartmentOptions(departments) {
  return departments?.map(({ name, slug }) => ({
    value: slug,
    label: name,
  }));
}

/**
 *
 * @param {Array} managerOptions - array of available managers
 * @returns {Boolean}
 */
export function checkIsManagerFieldDisabled(currentUser, managerOptions) {
  // FIXME: right now we have a really weird bug in the backend
  // where the policy is randomly being applied meaning we might not get the
  // results we expect.
  // If the current user has a direct reports scope, they shouldn't see other
  // managers and the managerOptions variable should have length === 1.
  // However this is not happening and while we debug it, we're doing this ugly
  // and insecure patch where we filter the results from the backend if the
  // current user has a direct_reports scope.
  // To be clear: this is insecure because the data should've never been sent.
  // But while we're fixing it, we're quickfixing by changing the UI and making
  // sure benevolent users don't get confused.
  // Also: note that the comment doesn't match the arguments of the function.
  // This is because for this patch, we needed to pass currentUser to this function
  if (!managerOptions || managerOptions.length === 0) return true;

  const hasDirectReportsScope = currentUser.assignedRoles.find(
    ({ dataScope }) =>
      dataScope === customerDataScopeTypes.DIRECT_REPORTS ||
      dataScope === customerDataScopeTypes.DIRECT_AND_INDIRECT_REPORTS
  );

  const filteredManagerOptions = hasDirectReportsScope
    ? managerOptions.filter(({ value }) => value === currentUser.slug)
    : managerOptions;

  return (
    filteredManagerOptions?.length === 1 &&
    (pickKey(filteredManagerOptions[0].assignedRoles?.[0], 'dataScope') ===
      customerDataScopeTypes.DIRECT_REPORTS ||
      pickKey(filteredManagerOptions[0].assignedRoles?.[0], 'dataScope') ===
        customerDataScopeTypes.DIRECT_AND_INDIRECT_REPORTS)
  );
}

/**
 *
 * @param {Array} managerOptions  - Array of managers from API
 * @returns {Array} formatted options to be used in a Select input
 */
export function createOnboardingManagerOptions(managerOptions) {
  return (
    managerOptions?.map(({ slug, name, teamMember, assignedRoles }) => ({
      label: name,
      value: slug,
      role: teamMember?.role?.replace('_', ' ') ?? null,
      assignedRoles,
    })) || []
  );
}

export function getAdminUserProfileEmploymentLabel(employment) {
  if (!employment) return null;
  const key = isGlobalPayroll(employment) ? 'global_payroll' : employment.type;
  return employmentProfileHeaderLabels[key];
}

/**
 * @param {Object} values - Key / value pairs of the selected value
 * @returns {Boolean} Returns true / false based on these factors:
 ** if they have a Remote account
 ** if they are an employee
 ** if they are a company admin
 ** if the user is removing the assigned manager
 */
export function checkIsNewManager(values) {
  const manager = values?.manager;
  const isAnEmployee = manager?.employment;
  const isACompanyAdmin = manager?.role;

  // `values?.role === 'employer' OR 'employee'` indicates that it is a manager -- coming from the API within the manager object
  // `values?.role === [slug]` indicates that it is an employee being assigned as a manager
  // `values?.role === ''` indicates that it is a company admin being assigned as a manager
  const isManager =
    manager && !isAnEmployee && (values?.role === 'employer' || values?.role === 'employee');

  const isManagerFieldEmpty = !manager;

  // the manager field is empty or there's an existing manager
  if (isManagerFieldEmpty || isManager) {
    return false;
  }

  if (!isACompanyAdmin) {
    return true;
  }

  return false;
}

export function userCanDirectEmploymentEligibility({
  hasActiveEmployment,
  isDirectEmployment,
  userCan,
  action = 'read',
}) {
  if (!isDirectEmployment) return false;
  if (hasActiveEmployment) {
    return (
      userCan(action, Resources.employer.people_sensitive_details) &&
      userCan(action, Resources.employer.people_onboarded)
    );
  }
  return userCan(action, Resources.employer.hiring);
}

/**
 * This function is used to determine if the user is in the middle of a
 * conversion process for their employment.
 *
 * @param {Object} employmentConversion
 * @returns {Boolean}
 */
export function isEmploymentUnderGoingConversion(employmentConversion) {
  return employmentConversion?.status === 'pending';
}

/**
 * This function is used to determine if the user has been through a
 * conversion process for their employment.
 *
 * @param {Object} employmentConversion
 * @returns {Boolean}
 */
export function hasEmploymentGoneThroughConversion(employmentConversion) {
  return employmentConversion?.status === 'completed';
}

/**
 * This function is used to determine if the user has gone through a conversion
 * for the employment and is now in a new employment.
 * @param {Object} employmentConversion
 * @param {String} currentEmploymentSlug
 * @returns {Boolean}
 */
export const isNewProfilePostConversion = ({ employmentConversion, currentEmploymentSlug }) => {
  return (
    hasEmploymentGoneThroughConversion(employmentConversion) &&
    employmentConversion?.targetEmploymentSlug === currentEmploymentSlug
  );
};

/**
 * This function is used to determine if the user has an old profile
 * that has not been converted.
 *
 * If both the source and target employment slugs are the same, then the
 * user could have gone through a conversion process but a new profile
 * was not created. For example this would happen for direct -> GP conversion.
 *
 * @param {Object} employmentConversion
 * @param {String} currentEmploymentSlug
 * @returns {Boolean}
 * */

export const isOldProfilePostConversion = ({ employmentConversion, currentEmploymentSlug }) => {
  return (
    hasEmploymentGoneThroughConversion(employmentConversion) &&
    employmentConversion?.sourceEmploymentSlug === currentEmploymentSlug &&
    employmentConversion?.targetEmploymentSlug !== currentEmploymentSlug
  );
};

/**
 * This function is used to get the label for the employment conversion
 * @param {Object} employmentConversion
 * */

export const getAdminUserProfileEmploymentConversionLabel = (employment) => {
  if (!employment?.employmentConversion) {
    return null;
  }

  const {
    employmentConversion: { previousType: source, targetType: target },
  } = employment;

  return `Transitioning ${productTypeLabels[source]} -> ${productTypeLabels[target]}`;
};

/**
 * Checks if the employment conversion was completed within the current year
 * @param {Object} employmentConversion
 * @returns true if was completed within the same year, false otherwise
 */
export const hasEmploymentConversionHappenedThisYear = (employmentConversion) => {
  if (!employmentConversion) return false;

  const conversionEndYear = getYear(new Date(employmentConversion.completedAt));
  const currentYear = new Date().getFullYear();

  return conversionEndYear === currentYear;
};

/**
 * Checks if the employment conversion happened less than `months` ago
 *
 * @param {Object} employmentConversion
 * @param {number} months number of months to check against
 * @returns true if happened less than `months` ago, false otherwise
 */
export const hasEmploymentConversionHappenedLessThanMonths = (employmentConversion, months) => {
  if (!employmentConversion) return false;

  const currentDate = new Date();
  const conversionCompletedDate = new Date(employmentConversion.completedAt);

  const diffInMonths = differenceInMonths(currentDate, conversionCompletedDate);

  return diffInMonths >= 0 && diffInMonths < months;
};

/**
 * Checks if a date is in probation period or not
 * @param {string|Date|null|undefined} probationEndDate - Probation end date
 * @returns {boolean} True if still in probation period, false otherwise
 */
export const isInProbationPeriod = (probationEndDate) => {
  if (!probationEndDate) return false;

  const parsedProbationEndDate =
    typeof probationEndDate === 'string' ? parseISO(probationEndDate) : probationEndDate;

  return isFuture(parsedProbationEndDate) || isToday(parsedProbationEndDate);
};
