import { useUser } from '@/src/components/UserProvider/context';
import { isTalentCompany } from '@/src/domains/registration/auth/helpers';

/**
 * Checks if the current user is associated with a talent company
 *
 * @return {boolean} Returns true if the user is associated with a talent company, otherwise returns false.
 */
export const useIsTalentCompany = (): boolean => {
  const { user } = useUser();

  return isTalentCompany(user);
};
