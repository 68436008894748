import { Stack } from '@remote-com/norma';
import { useState } from 'react';
import styled from 'styled-components';

import type { UserProfile } from '@/src/api/config/employ/userProfiles.types';
import { useUserContext } from '@/src/components/UserProvider';
import { useUserProfiles } from '@/src/domains/account/hooks/useUserProfiles';
import { RemoteJobsButton } from '@/src/domains/employee/jobs/RemoteJobsButton/RemoteJobsButton';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { NotificationsButton } from '@/src/domains/inAppNotifications/NotificationsButton';
import { AccountMenu } from '@/src/domains/navigation/account-menu';
import { isUserOnboarded } from '@/src/domains/registration/auth/helpers';
import { ReCAPTCHA } from '@/src/domains/registration/auth/ReCAPTCHA';
import { TodosOverlayButton } from '@/src/domains/todos/TodosOverlayButton';
import { USER_PROFILE_TYPES } from '@/src/domains/userProfile/constants';

import { HelpMenu } from '../HelpMenu';

const StyledWrapper = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[3]}px;
`;

export const TopNavigationMenu = () => {
  const { user } = useUserContext();
  const { selectedProfile } = useUserProfiles();
  const isOnboarded = isUserOnboarded(user);
  const enableChatbot = useIsFeatureFlagEnabled('knowledge_ai_chatbot');
  const [switchingProfileVisible, setSwitchingProfileVisible] = useState(false);

  const isTalentAndJobsActive = (profileType?: UserProfile['profileType']) => {
    if (!profileType || !isOnboarded) {
      return false;
    }

    return [
      USER_PROFILE_TYPES.CANDIDATE as string,
      USER_PROFILE_TYPES.EMPLOYEE as string,
      USER_PROFILE_TYPES.FREELANCER as string,
      USER_PROFILE_TYPES.CONTRACTOR as string,
    ].includes(profileType);
  };

  return (
    <StyledWrapper>
      {isTalentAndJobsActive(selectedProfile?.profileType) ? <RemoteJobsButton /> : null}
      <TodosOverlayButton />
      <NotificationsButton />
      {enableChatbot && <HelpMenu />}
      <AccountMenu setSwitchingProfileVisible={setSwitchingProfileVisible} />
      {/* helper to ensure captcha badge is visible only when we have account menu visible OR we have account switcher modal visible */}
      {switchingProfileVisible && <ReCAPTCHA />}
    </StyledWrapper>
  );
};
