import React, { useRef } from 'react';
import type { ChangeEvent } from 'react';
import type { ActionMeta, SingleValue } from 'react-select';

import { Box } from '../../layout';
import type { $TSFixMe } from '../../types';
import {
  Container,
  Description,
  ErrorMessage,
  Meta,
  MetaSeparator,
  getFormGroupIds,
} from '../form-group';

import { PHONE_NUMBER_SECTION_LABEL } from './constants';
import { CountryCodeSelect } from './CountryCodeSelect';
import type { CountryOption } from './CountryCodeSelect';
import { AuxiliaryInput, Fieldset } from './InputPhoneNumber.styled';
import { NumberInput } from './NumberInput';

export type InputPhoneNumberProps = {
  name: string;
  label?: string;
  id?: string;
  currentCountry?: SingleValue<CountryOption>;
  description?: string;
  errorText?: string;
  fieldsWithErrors?: ('COUNTRY_CODE' | 'PHONE_NUMBER')[];
  value?: string;
  currentNationalPhoneNumber?: string;
  onBlur?: (section: string) => void;
  onCountryCodeChange?: (
    newValue: SingleValue<CountryOption>,
    actionMeta: ActionMeta<CountryOption>
  ) => void;
  onPhoneNumberChange?: (event: ChangeEvent<Element>, ...args: unknown[]) => void | undefined;
  testId?: string;
  readOnly?: boolean;
};

/**
 * Phone number input separated into a country dial code selection and an input for the national phone number.
 */
export function InputPhoneNumber({
  name,
  label,
  description,
  errorText,
  fieldsWithErrors,
  value,
  currentCountry,
  currentNationalPhoneNumber,
  onCountryCodeChange,
  onPhoneNumberChange,
  testId: dataTestid,
  onBlur,
  ...props
}: InputPhoneNumberProps) {
  const hasError = fieldsWithErrors && fieldsWithErrors.length > 0;
  const hasMeta = !!description || hasError;
  const { idDescription, idErrorMessage } = getFormGroupIds(props.id || name);
  const errorIsOnCountryCode = fieldsWithErrors && fieldsWithErrors.includes('COUNTRY_CODE');
  const errorIsOnPhoneNumber = fieldsWithErrors && fieldsWithErrors.includes('PHONE_NUMBER');
  const countryCodeRef = useRef<$TSFixMe>(null);
  const nationalNumberRef = useRef<HTMLInputElement>(null);

  /**
   * NOTE: Since our ErrorSummary only handles regular field and this field is broken down into 2 separate inputs,
   * we're using this auxiliary input that gets focused when the Error Summary link is clicked and then redirects
   * the focus to the correct input.
   */
  const onAuxiliaryInputFocus = () => {
    if (errorIsOnCountryCode) {
      // Focus Country code select
      countryCodeRef.current?.inputRef.focus();
    } else {
      // Focus Phone number input
      nationalNumberRef.current?.focus();
    }
  };

  return (
    <>
      <Container>
        <AuxiliaryInput
          id={props.id}
          onFocus={onAuxiliaryInputFocus}
          aria-label="Telephone number"
        />
        <Fieldset aria-label={label} aria-describedby={idDescription}>
          <Box width="178px">
            <CountryCodeSelect
              {...props}
              ref={countryCodeRef}
              name={`${name}-country-code`}
              isDisabled={props.readOnly} // readOnly is preferable for A11Y (support keyboard navigation), but react-select only supports isDisabled.
              label={PHONE_NUMBER_SECTION_LABEL.COUNTRY_CODE}
              errorText={errorIsOnCountryCode ? 'error' : ''}
              displayErrorMessage={false}
              value={currentCountry}
              onChange={onCountryCodeChange}
              onBlur={() => onBlur?.(PHONE_NUMBER_SECTION_LABEL.COUNTRY_CODE)}
            />
          </Box>
          <NumberInput
            {...props}
            ref={nationalNumberRef}
            id={`${props.id}-national-number`}
            name={`${name}-national-number`}
            displayErrorMessage={false}
            data-testid={dataTestid}
            label={label || PHONE_NUMBER_SECTION_LABEL.PHONE_NUMBER}
            placeholder={label || PHONE_NUMBER_SECTION_LABEL.PHONE_NUMBER}
            errorText={errorIsOnPhoneNumber ? 'error' : ''}
            value={currentNationalPhoneNumber}
            onChange={onPhoneNumberChange}
            onBlur={() => onBlur?.(PHONE_NUMBER_SECTION_LABEL.PHONE_NUMBER)}
          />
        </Fieldset>
        {hasMeta && (
          <Meta>
            {hasError && (
              <ErrorMessage aria-live="polite" id={idErrorMessage} data-testid="error-message">
                {errorText}
              </ErrorMessage>
            )}
            {description && (
              <>
                {hasError && <MetaSeparator>-</MetaSeparator>}
                <Description id={idDescription}>{description}</Description>
              </>
            )}
          </Meta>
        )}
      </Container>
      <input
        type="hidden"
        name={name}
        value={value}
        data-testid={dataTestid ? `${dataTestid}-value` : undefined}
      />
    </>
  );
}
