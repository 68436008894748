import styled, { css } from 'styled-components';

import { Box } from '../../layout';
import { Button } from '../button';
import { focusVisible } from '../common.styled';

import type { MenuItemTone } from './MenuItem';

const buttonCss = css`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  appearance: none;
`;

export type MenuItemComponentProps = { $isToggle?: boolean; tone?: MenuItemTone };

const menuItemInteractionStyles = css`
  background-color: ${({ theme }) => theme.colors.grey[100]};
`;

export const menuItemStyles = css`
  ${buttonCss}

  --norma-menu-item-radius: ${({ theme }) => theme.borderRadius.sm};

  align-items: center;
  border-radius: var(--norma-menu-item-radius);
  color: ${({ theme }) => theme.colors.grey[900]};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  width: 100%;

  &[aria-disabled='true'],
  &[data-disabled]:not([data-disabled='false']) {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover,
  &[data-selected]:not([data-selected='false']),
  &[data-highlighted]:not([data-highlighted='false']) {
    ${menuItemInteractionStyles}
  }

  ${focusVisible(menuItemInteractionStyles)};
`;

export const normaMenuItemStyles = css<MenuItemComponentProps>`
  ${menuItemStyles}

  > * {
    flex: 1;
    padding: ${({ theme }) => `calc(${theme.space[4]}px - 2px) ${theme.space[4]}px`};
    padding-top: ${({ $isToggle, theme }) => $isToggle && `calc(${theme.space[5]}px - 2px) `};
  }
`;

export const StyledIcon = styled.svg`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  fill: var(--menuItemTextColor);
`;

export const StyledMenuLinkWrapper = styled.a`
  text-decoration: none;
  color: var(--menuItemTextColor);
  width: 100%;
`;

export const Divider = styled(Box)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey[200]}`};
  margin: 0px -3px;
  padding-top: calc(${({ theme }) => `${theme.space[2]}px`} - 1px);
  margin-top: calc(${({ theme }) => `${theme.space[2]}px`} - 1px);
`;

export const MenuItemButton = styled(Button).attrs({ variant: 'raw' })`
  ${focusVisible(css`
    outline: none;
    box-shadow: none;
  `)};
`;
