import { Stack, Text } from '@remote-com/norma';

type ActivitySectionProps = {
  title: string;
  count: number;
  label?: string | null;
  titleColor?: any;
};

export const ActivitySection = ({ title, count, label, titleColor }: ActivitySectionProps) => {
  return (
    <Stack alignItems="flex-start">
      <Text variant="xsSemiBold" textTransform="uppercase" color={titleColor}>
        {title}
      </Text>
      <Stack direction="row" gap={2} alignItems="center">
        <Text fontFamily="RemoteSans" variant="xlMedium">
          {count}
        </Text>
        {label ? (
          <Text variant="smMedium" color="grey.600">
            {label}
          </Text>
        ) : null}
      </Stack>
    </Stack>
  );
};
