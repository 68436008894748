import {
  offPayslipCostTypes,
  offPayslipCostTypeLabels,
  offPayslipCostsStatusLabels,
  offPayslipCostsStatuses,
} from '@/src/domains/offpayslipCosts/constants';
import {
  SERVICE_COST_AREAS,
  SERVICE_COST_AREAS_DISPLAY_TEXT,
  SERVICE_COST_SERVICE_TYPES,
  SERVICE_COST_SERVICE_TYPES_DISPLAY_TEXT,
  SERVICE_STATUSES,
  SERVICE_STATUSES_DISPLAY_TEXT,
} from '@/src/domains/serviceCosts/constants';
import { generateSelectOptionsFromValues } from '@/src/helpers/forms';

export const COST_APPROVAL_REQUEST_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
};

export const COST_APPROVAL_REQUEST_STATUS_LABELS = {
  [COST_APPROVAL_REQUEST_STATUSES.PENDING]: 'Pending',
  [COST_APPROVAL_REQUEST_STATUSES.APPROVED]: 'Approved',
  [COST_APPROVAL_REQUEST_STATUSES.DECLINED]: 'Declined',
  [COST_APPROVAL_REQUEST_STATUSES.CANCELLED]: 'Cancelled',
};

export const COST_APPROVAL_REQUEST_STATUS_BADGES = {
  [COST_APPROVAL_REQUEST_STATUSES.PENDING]: 'pending',
  [COST_APPROVAL_REQUEST_STATUSES.APPROVED]: 'active',
  [COST_APPROVAL_REQUEST_STATUSES.DECLINED]: 'error',
  [COST_APPROVAL_REQUEST_STATUSES.CANCELLED]: 'closed',
};

export const COST_APPROVAL_REQUEST_STATUS_PILL_TONE = {
  [COST_APPROVAL_REQUEST_STATUSES.PENDING]: 'neutralDark',
  [COST_APPROVAL_REQUEST_STATUSES.APPROVED]: 'success',
  [COST_APPROVAL_REQUEST_STATUSES.DECLINED]: 'error',
  [COST_APPROVAL_REQUEST_STATUSES.CANCELLED]: 'warning',
};

export const costApprovalRequestStatusOptions = generateSelectOptionsFromValues(
  Object.values(COST_APPROVAL_REQUEST_STATUSES),
  COST_APPROVAL_REQUEST_STATUS_LABELS
);

// 1. Remove mobility which is a virtual value for filtering purposes and not a value allowed in the backend.
// 2. Rename key of visa and immigration to avoid collision. The values are different so we want to preserve both of those.
const { MOBILITY: _, VISA_AND_IMMIGRATION, ...TEMP_OFF_PAYSLIP_COST_TYPES } = offPayslipCostTypes;
const OFF_PAYSLIP_COST_TYPES = {
  ...TEMP_OFF_PAYSLIP_COST_TYPES,
  VISA_AND_IMMIGRATION_OFF_PAYSLIP: VISA_AND_IMMIGRATION,
};
export const COST_APPROVAL_REQUEST_AREAS = {
  ...SERVICE_COST_AREAS,
  ...OFF_PAYSLIP_COST_TYPES,
};

export const COST_APPROVAL_REQUEST_AREA_LABELS = {
  ...SERVICE_COST_AREAS_DISPLAY_TEXT,
  ...offPayslipCostTypeLabels,
};

export const costApprovalRequestAreaOptions = generateSelectOptionsFromValues(
  Object.values(COST_APPROVAL_REQUEST_AREAS),
  COST_APPROVAL_REQUEST_AREA_LABELS
);

export const COST_APPROVAL_REQUEST_SERVICES = {
  ...SERVICE_COST_SERVICE_TYPES,
  // Should this be renamed to match Other from off-payslip cost types?
  CUSTOM: 'custom',
};

export const COST_APPROVAL_REQUEST_SERVICE_LABELS = {
  ...SERVICE_COST_SERVICE_TYPES_DISPLAY_TEXT,
  [COST_APPROVAL_REQUEST_SERVICES.CUSTOM]: 'Custom',
};

export const costApprovalRequestServiceOptions = generateSelectOptionsFromValues(
  Object.values(COST_APPROVAL_REQUEST_SERVICES),
  COST_APPROVAL_REQUEST_SERVICE_LABELS
);

export const COST_APPROVAL_REQUEST_COST_SOURCE_TYPES = {
  MOBILITY_ASSISTANCE_STATUS: 'mobility_assistance_status',
  VISA_SPONSORSHIP_COST: 'visa_sponsorship_cost',
  PRE_HIRE_ASSESSMENT: 'pre_hire_assessment',
};

export const COST_APPROVAL_REQUEST_COST_SOURCE_TYPE_LABELS = {
  [COST_APPROVAL_REQUEST_COST_SOURCE_TYPES.MOBILITY_ASSISTANCE_STATUS]: 'Mobility assistance',
  [COST_APPROVAL_REQUEST_COST_SOURCE_TYPES.VISA_SPONSORSHIP_COST]: 'Visa sponsorship cost',
  [COST_APPROVAL_REQUEST_COST_SOURCE_TYPES.PRE_HIRE_ASSESSMENT]: 'Pre-hire assessment',
};

export const costApprovalRequestCostSourceTypeOptions = generateSelectOptionsFromValues(
  Object.values(COST_APPROVAL_REQUEST_COST_SOURCE_TYPES),
  COST_APPROVAL_REQUEST_COST_SOURCE_TYPE_LABELS
);

export const COST_APPROVAL_REQUEST_COST_STATUSES = {
  ...SERVICE_STATUSES,
  ...offPayslipCostsStatuses,
  NO_COST: 'no_cost',
};

export const COST_APPROVAL_REQUEST_COST_STATUS_LABELS = {
  ...SERVICE_STATUSES_DISPLAY_TEXT,
  ...offPayslipCostsStatusLabels,
  [COST_APPROVAL_REQUEST_COST_STATUSES.NO_COST]: 'No cost',
};

export const costApprovalRequestCostStatusOptions = generateSelectOptionsFromValues(
  Object.values(COST_APPROVAL_REQUEST_COST_STATUSES),
  COST_APPROVAL_REQUEST_COST_STATUS_LABELS
);

export const COST_APPROVAL_REQUEST_COST_TYPES = {
  REMOTE_PAID_SERVICE: 'remote_paid_services',
  OFF_PAYSLIP_COST: 'off_payslip_costs',
};

export const COST_APPROVAL_REQUEST_COST_TYPE_LABELS = {
  [COST_APPROVAL_REQUEST_COST_TYPES.REMOTE_PAID_SERVICE]: 'Service cost',
  [COST_APPROVAL_REQUEST_COST_TYPES.OFF_PAYSLIP_COST]: 'Off-payslip cost',
};

export const COST_APPROVAL_REQUEST_PROVIDERS = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const COST_APPROVAL_REQUEST_PROVIDER_LABELS = {
  [COST_APPROVAL_REQUEST_PROVIDERS.INTERNAL]: 'Internal',
  [COST_APPROVAL_REQUEST_PROVIDERS.EXTERNAL]: 'External',
};

export const costApprovalRequestProviderOptions = generateSelectOptionsFromValues(
  Object.values(COST_APPROVAL_REQUEST_PROVIDERS),
  COST_APPROVAL_REQUEST_PROVIDER_LABELS
);

export const COST_APPROVAL_REQUEST_SERVICE_DESCRIPTIONS = {
  // TODO: Add description for services shown to the employer
  [COST_APPROVAL_REQUEST_SERVICES.IMMIGRATION_GUIDANCE]:
    "We’ll help an employee who wants to relocate to another country get information on their options. With our in-house team of experts, complex immigration laws are a breeze. We'll ensure compliant relocation for your employee's work and residence.",
  [COST_APPROVAL_REQUEST_SERVICES.PRE_EMPLOYMENT_ELIGIBILITY_CHECK]:
    'We’ll confirm that your potential hire holds a valid right to work in a country where they are a temporary resident or foreign national. We can help you determine your potential hire’s right to work before you send an offer letter. With our pre-employment eligibility check, we’ll confirm that your potential hire holds a valid right to work in a country where they are a temporary resident or foreign national.',
};

const mobilityServiceLegalDisclaimer =
  'Please note that depending on the policies of our local immigration service providers, there may be costs associated with cancelling an immigration service or work permit application after it has been initiated.';

export const COST_APPROVAL_REQUEST_AREA_DISCLAIMERS = {
  [COST_APPROVAL_REQUEST_AREAS.MOBILITY_SERVICES]: mobilityServiceLegalDisclaimer,
  [COST_APPROVAL_REQUEST_AREAS.VISA_AND_IMMIGRATION_OFF_PAYSLIP]: mobilityServiceLegalDisclaimer,
  [COST_APPROVAL_REQUEST_AREAS.MOBILITY]: mobilityServiceLegalDisclaimer,
  [COST_APPROVAL_REQUEST_AREAS.VISA_AND_IMMIGRATION]: mobilityServiceLegalDisclaimer,
};
