import { Text } from '@remote-com/norma';
import type { CSSObject } from 'styled-components';
import styled from 'styled-components';

interface TruncatedTextProps {
  width?: string;
}

export const TruncatedText = styled(Text)<TruncatedTextProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ width }: TruncatedTextProps): CSSObject => (width ? { width } : {})};
`;
