import {
  Container,
  Body,
  LabelInputWrapper,
  Label,
  LabelText,
  Aside,
  Meta,
  ErrorMessage,
  Box,
  focusVisible,
} from '@remote-com/norma';
import { transparentize } from 'polished';
import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';

// Add this helper function at the top of the file, after the imports
const getCellBackgroundColor = ({
  theme,
  $hasError,
  $isDisabled,
}: {
  theme: DefaultTheme;
  $hasError: boolean;
  $isDisabled: boolean;
}) => {
  if ($hasError) return theme.colors.red[100];
  if ($isDisabled) return theme.colors.grey[50];
  return theme.colors.blank;
};

// We're using a raw div due to performance reasons. The Stack/Box components are too heavy for the table.
// eslint-disable-next-line remote/norma-prefer-layout
export const CellContentWrapper = styled.div<{ $hasError: boolean; $isDisabled: boolean }>`
  position: absolute;
  inset: 0;
  overflow: hidden;

  background-color: ${getCellBackgroundColor};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.red[100]};
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 10px solid ${({ theme }) => theme.colors.red[700]};
        border-left: 10px solid transparent;
        pointer-events: none;
      }
    `}

  /* NOTE: The selectors below should be used for future changes as they directly modify the FormGroup component that wraps the inputs */
  & ${Container} {
  }

  & ${Body} {
    position: absolute;
    inset: 1px;
    border-radius: 0;
  }

  & ${LabelInputWrapper} {
    padding: ${({ theme }) => `${theme.space[3]}px ${theme.space[4] - 2}px`};

    & * {
      margin: 0;
    }

    & input {
      min-height: 100%;
    }
  }

  & ${Label} {
  }

  & ${LabelText} {
  }

  & ${Aside} {
  }

  & ${Meta} {
  }

  & ${ErrorMessage} {
  }
`;

// NOTE: This is the button that displays the value of the cell and allows editing when clicked.
// We're using a raw button due to performance reasons. The Button component is too heavy for the table.
// eslint-disable-next-line remote/norma-prefer-button
export const DisplayValue = styled.button`
  position: absolute;
  inset: 0;
  background: none;
  border: none;
  max-width: 100%;
  text-align: left;
  padding: ${({ theme }) => `${theme.space[3]}px ${theme.space[4]}px`};
  outline-offset: -1px;

  ${focusVisible(css`
    outline: 1px solid ${({ theme }) => theme.colors.primary};
  `)}
`;

// NOTE: This is required as the Text is wrapped in a Button which removes the ellipsis that we would have on the table cells
// Using a span instead of a Text component for performance reasons
export const EllipsisText = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/*
 * NOTE: this is largely copied from the InputSelect.Menu component
 * https://gitlab.com/remote-com/employ-starbase/dragon/-/blob/master/apps/jobs/src/components/form/InputSelect/components/Menu.tsx
 *
 * We need this as we need to use a custom component for the select's dropdown as we are using a custom menu portal target
 */
export const StyledMenu = styled(Box).attrs({
  mt: 2,
  mx: -5,
})<any>`
  --field-select-menu-border-radius: 11px;
  --field-select-menu-item-height: 44px;

  background: ${({ theme }) => theme.colors.blank};
  border-radius: var(--field-select-menu-border-radius);
  border: 1px solid ${({ theme }) => theme.colors.grey[400]};
  box-shadow: 0 5px 15px 0 ${({ theme }) => transparentize(0.9, theme.colors.grey[900])};
  left: 0;
  overflow: hidden;
  background: white;
  position: absolute;
  top: 100%;
  min-width: 240px;
  z-index: 10;
`;

// NOTE: The content that is rendered inside the portal.
export const PortalContent = styled(Box).attrs({
  position: 'fixed',
  p: 4,
  bg: 'blank',
  borderRadius: 'sm',
})`
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  box-shadow: ${({ theme }) => theme.shadows.large};
  animation: fade-in 0.1s ease-in-out;

  & ${Body} {
    border-radius: 4px;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
