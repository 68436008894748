import { useGetForTable } from '@remote-com/data-layer';

import type { PayrollRun } from '@/src/api/config/employ/payrollRun.types';

type UntransformedParams = {
  queryParams: {
    createdAt?: string;
  };
};

const parseQueryParams = ({
  createdAt,
  ...queryParams
}: UntransformedParams['queryParams']): PayrollRun.EmployerPayrollReportsRequest['queryParams'] => {
  const parsedQueryParams: PayrollRun.EmployerPayrollReportsRequest['queryParams'] = queryParams;

  if (createdAt) {
    [parsedQueryParams.createdAtStart, parsedQueryParams.createdAtEnd] = createdAt;
  }

  return parsedQueryParams;
};

export const usePayrollReports = () => {
  return useGetForTable('/api/v1/employer/payroll-reports', {
    tableOptions: {
      dataProperty: 'payrollReports',
      transformParams: (untransformedParams: UntransformedParams) => ({
        ...untransformedParams,
        queryParams: parseQueryParams({ ...untransformedParams.queryParams }),
      }),
    },
  });
};
