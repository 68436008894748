import type { MouseEventHandler } from 'react';
import { useTheme } from 'styled-components';

import {
  OptionBody,
  OptionPrefix,
  OptionSuffix,
  OptionValue,
  OptionWrapper,
  SelectedIcon,
} from './Option.styled';

interface OptionProps {
  children: React.ReactNode;
  innerProps: {
    id?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onMouseMove?: MouseEventHandler<HTMLDivElement>;
    onMouseOver?: MouseEventHandler<HTMLDivElement>;
    tabIndex?: number;
  };
  innerRef?: React.Ref<HTMLDivElement>;
  isFocused?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  data: Record<string, unknown>;
}

export function Option({
  isFocused,
  isDisabled,
  isSelected,
  children,
  innerProps,
  innerRef,
  data: { prefix, suffix },
}: OptionProps) {
  const { colors } = useTheme();
  return (
    <OptionWrapper
      $isDisabled={isDisabled}
      $isFocused={isFocused}
      $isSelected={isSelected}
      aria-disabled={isDisabled}
      ref={innerRef}
      {...innerProps}
    >
      {prefix && <OptionPrefix>{prefix}</OptionPrefix>}
      <OptionBody>
        <OptionValue>{children}</OptionValue>
        {suffix && <OptionSuffix>{suffix}</OptionSuffix>}
      </OptionBody>
      {isSelected && <SelectedIcon fill={colors.primary} />}
    </OptionWrapper>
  );
}
