import { InputText } from '@remote-com/norma';
import { Field } from 'formik';

/**
 * SearchField
 *
 * Formik input field abstraction, containing label, description and field error
 *
 * @param {Object} props - Component props
 * @param {String} props.name - Form field name
 * @param {String} props.label - Field label
 * @param {String} props.description - Field description
 * @param {Boolean} props.preventJump - Add an empty error message to avoid content shift
 * @param {String} props.placeholder - Field placeholder
 * @param {String} props.variant - Style variant
 */

export const SearchField = ({ name, label, placeholder }) => (
  <Field name={name}>
    {({ field: { value, ...field }, meta: { error, touched } }) => {
      return (
        <InputText
          {...field}
          errorText={touched && error ? error : ''}
          type="search"
          label={label}
          name={name}
          data-testid="search-field-input"
          placeholder={placeholder}
          value={value}
        />
      );
    }}
  </Field>
);
