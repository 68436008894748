import { productEmploymentTypes } from '@/src/domains/pricing/constants';

export const content = {
  [productEmploymentTypes.EOR]: {
    card: {
      title: 'Premium Employment Services plan',
    },
    create: {
      title: 'Create an Employment Services plan',
      description:
        'These are plans that a customer negotiated with the Sales team. For Premium plans, make sure the customer qualifies before creating the plan.',
    },
    delete: {
      description: 'Proceed only if you are sure you want to delete this Premium plan.',
      confirmLabel: 'Delete premium plan',
    },
    archive: {
      description: 'Proceed only if you are sure you want to archive this Premium plan.',
      confirmLabel: 'Archive premium plan',
    },
    cancel: {
      confirmLabel: 'Cancel Premium plan',
      drawer: {
        text: 'Cancel Premium plan',
        toast: 'Premium plan was successfully canceled',
      },
    },
    modal: {
      edit: {
        headerTitle: 'Edit Employment Services plan',
        buttonText: 'Edit plan',
      },
      create: {
        headerTitle: 'Create Employment Services plan',
        buttonText: 'Create plan',
      },
    },
    employer: {
      confirm: {
        title: 'Confirm your Premium plan',
        description: `If you're ready, confirm your Premium plan and proceed to employee onboarding.`,
      },
    },
    notification: {
      titleV1: 'Your Premium plan is ready!',
      title: 'Review and confirm your Premium plan',
    },
  },
  [productEmploymentTypes.GLOBAL_PAYROLL]: {
    card: {
      title: 'Global Payroll plan',
    },
    create: {
      title: 'Create a Global Payroll plan',
      description:
        'These are plans that a customer negotiated with the Sales team. Make sure the customer qualifies before creating the plan.',
    },
    delete: {
      description: 'Proceed only if you are sure you want to delete this plan.',
      confirmLabel: 'Delete plan',
    },
    archive: {
      description: 'Proceed only if you are sure you want to archive this plan.',
      confirmLabel: 'Archive plan',
    },
    cancel: {
      confirmLabel: 'Cancel plan',
      drawer: {
        text: 'Cancel plan',
        toast: 'Global Payroll plan was successfully canceled',
      },
    },
    modal: {
      edit: {
        headerTitle: 'Edit Global Payroll plan',
        buttonText: 'Edit plan',
      },
      create: {
        headerTitle: 'Create Global Payroll plan',
        buttonText: 'Create plan',
      },
    },
    employer: {
      confirm: {
        title: 'Confirm your plan',
        description: `If you're ready, confirm your plan and proceed to employee onboarding.`,
      },
    },
    notification: {
      titleV1: 'Your plan is ready!',
      title: 'Review and confirm your Global Payroll plan',
    },
  },
};
