import { motion } from 'framer-motion';
import type { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../../layout';
import { ButtonIcon, ButtonInline, ButtonInlineStyles } from '../button';
import { Text } from '../text';

export type DrawerContentProps = {
  $layout?: 'fullWidth';
  $maxWidth?: CSSProperties['maxWidth'];
  $hasCloseButton?: boolean;
  $zIndex?: number;
};

export const DrawerOverlay = styled(Box)<DrawerContentProps>`
  all: unset;
  display: flex;
  inset: 0;
  justify-content: flex-end;
  position: fixed;
  z-index: ${({ $zIndex }) => $zIndex};
`;

export const DrawerBackground = styled(motion.div)`
  backdrop-filter: blur(1.5px);
  background: rgba(15, 20, 25, 0.25);
  inset: 0;
  position: absolute;
`;

export const DrawerCloseButton = styled(motion(ButtonIcon))`
  position: absolute;

  ${({ theme }) => css`
    right: ${theme.space[5]}px;
    top: ${theme.space[5]}px;
  `}
`;

export const DrawerLayout = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  height: 100%;
`;

export const DrawerPane = styled(Box)`
  /* Defined within DrawerContent */
  padding: var(--spacingY) var(--spacingX);
`;

export const DrawerHeader = styled(DrawerPane)`
  background-color: ${({ theme }) => theme.colors.background.base};
`;

export const DrawerContent = styled(motion(Box))<DrawerContentProps>`
  ${({ theme }) => css`
    --spacingY: ${theme.space[7]}px;
    --spacingX: ${theme.space[8]}px;
  `}

  all: unset;
  background: white;
  box-sizing: border-box;
  max-width: ${({ $maxWidth }) => $maxWidth};
  overflow-y: auto;
  padding: ${({ $layout }) => {
    switch ($layout) {
      case 'fullWidth':
        return 0;
      default:
        return 'var(--spacingY) var(--spacingX)';
    }
  }};
  position: relative;
  width: 100%;

  ${DrawerHeader} {
    ${({ theme, $hasCloseButton }) =>
      $hasCloseButton &&
      css`
        padding-right: ${theme.space[9]}px;
      `}
  }
`;

export const DrawerFooter = styled(Box)`
  background-color: ${({ theme }) => theme.colors.background.base};
  padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[7]}px`};
`;

export const DrawerTitle = styled.h3`
  ${({ theme }) => theme.typography.lgMedium};
  color: 'grey.900';
`;

export const DrawerSubtitle = styled(Text).attrs({
  variant: 'sm',
  color: 'grey.500',
})``;

export const DrawerCenterContent = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: ${({ theme }) => theme.space[7]}px;
  text-align: center;
`;

export const DrawerCopy = styled.section`
  color: ${({ theme }) => theme.colors.grey[500]};
  --button-colorText: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.typography.sm};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  p {
    line-height: 1.6;
  }

  strong {
    font-weight: 500;
  }

  a {
    ${ButtonInlineStyles}
  }

  img {
    max-width: 100%;
  }

  table {
    border: 0;
    margin: 0 -4px;
    width: 100% !important;

    td {
      border: 0;
      padding: 4px;
    }
  }
`;

export const DrawerStyledButtonInline = styled(ButtonInline)`
  display: inline-flex;
  gap: 2px;
`;
