import getConfig from 'next/config';

import type { UserAccountResponse } from '@/src/components/UserProvider';

const {
  publicRuntimeConfig: { ENVIRONMENT },
} = getConfig();

const CALENDLY_SCHEDULING_PRODUCTION_URL = 'https://calendly.com/d/3r6-7ph-b9x';

const CALENDLY_SCHEDULING_TESTING_URL = 'https://calendly.com/d/45f-2bt-wpj';

export function getSchedulingUrl() {
  const isProduction = ENVIRONMENT === 'production';

  if (isProduction) {
    return CALENDLY_SCHEDULING_PRODUCTION_URL;
  }

  return CALENDLY_SCHEDULING_TESTING_URL;
}

export function constructCalendlyUrl(user?: UserAccountResponse): string {
  const calendlySchedulingUrl = getSchedulingUrl();

  const calendlyUrl = new URL(calendlySchedulingUrl);

  calendlyUrl.searchParams.append('hide_gdpr_banner', '1');

  if (user) {
    calendlyUrl.searchParams.append('email', user.email);
    calendlyUrl.searchParams.append('name', user.name);
    calendlyUrl.searchParams.append('utm_source', user.slug);
  }
  calendlyUrl.searchParams.append('embed_type', 'PopupWidget');
  calendlyUrl.searchParams.append('embed_domain', '1');

  return calendlyUrl.href;
}
