import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';

import useClickOutside from '@/src/hooks/useClickOutside';
import useKeyPressDetection from '@/src/hooks/useKeyPressDetection';

import PopoverContext from './PopoverContext';

const StyledBox = styled(motion.div)`
  position: absolute;
  z-index: 4;
  box-sizing: border-box;
  --popoverBox-padding: ${({ theme }) => theme.space[4]}px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    --popoverBox-padding: ${({ theme }) => theme.space[7]}px;
  }

  ${({ $align, $offset }) => {
    const topValue = `calc(100% + ${$offset})`;

    switch ($align) {
      case 'bottom-start':
        return css`
          top: ${topValue};
          left: 0;
        `;
      case 'bottom-center':
        return css`
          top: ${topValue};
          left: 50%;
          transform: translateX(-50%);
        `;
      case 'bottom-end':
        return css`
          top: ${topValue};
          right: 0;
        `;
      // For now that is all. A different $align case? Add it here.
      default:
        return null;
    }
  }}

  background: var(--colors-blank);
  border-radius: ${({ $borderRadius }) => $borderRadius};
  padding: ${({ $noPadding }) => ($noPadding ? '0' : 'var(--popoverBox-padding)')};
  box-shadow: 0 0 8px ${({ theme }) => theme.colors.grey[200]};
  min-width: 150px;
  width: ${({ $width }) => $width};
  max-width: calc(100vw - 40px); /* 40px is the offset + page total lateral spacing */
`;

const PopoverBox = ({ children, ...props }) => {
  const { isOpen, isListeningClickOutside, togglePopover, targetRef } = useContext(PopoverContext);

  function haveClickedOnTrigger(target) {
    if (target.classList.contains('react-datepicker__day')) {
      return true;
    }
    // two nodes can be either equal or contain each other within in cases when user clicks on svg icon within the button.
    if (
      targetRef?.current &&
      (targetRef?.current.contains(target) || target.isEqualNode(targetRef?.current))
    ) {
      return true;
    }
    return false;
  }

  const refBox = useRef();
  useClickOutside({
    refEl: refBox,
    listen: isOpen,
    onClick: isListeningClickOutside ? togglePopover : () => true,
    whitelistEl: (target) => haveClickedOnTrigger(target),
  });
  useKeyPressDetection({
    listen: isOpen,
    key: 'escape',
    onKeyPress: togglePopover,
  });

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledBox
          ref={refBox}
          {...props}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, y: 20 }}
        >
          {children}
        </StyledBox>
      )}
    </AnimatePresence>
  );
};

PopoverBox.defaultProps = {
  $width: '340px',
  $align: 'bottom-end',
  $noPadding: false,
  $borderRadius: '8px',
  $offset: '8px',
};

PopoverBox.propTypes = {
  children: PropTypes.node.isRequired,
  /** SC prop - set the box width size */
  $width: PropTypes.string,
  /** SC prop - set the box position relative to parent */
  $align: PropTypes.oneOf(['bottom-start', 'bottom-end']),
  $noPadding: PropTypes.bool,
  $borderRadius: PropTypes.string,
  $offset: PropTypes.string,
};

export default PopoverBox;
