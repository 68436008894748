import { Stack, Text } from '@remote-com/norma';
import styled from 'styled-components';

const Wrapper = styled(Stack)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[300]};
  padding-bottom: ${({ theme }) => theme.space[4]}px;
  padding-top: ${({ theme }) => theme.space[4]}px;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

type CustomTooltipProps = {
  tooltips: { title?: string; body: string }[];
};

export default function CustomTooltip({ tooltips }: CustomTooltipProps) {
  return (
    <Stack padding={2}>
      {tooltips.map(({ title, body }) => (
        <Wrapper key={title ?? body}>
          {title ? (
            <Text
              textAlign="left"
              textTransform="uppercase"
              variant="2xsSemiBold"
              color="grey.900"
              as="span"
              pb={2}
            >
              {title}
            </Text>
          ) : null}
          <Text textAlign="left" variant="xs" color="grey.900" as="span">
            {body}
          </Text>
        </Wrapper>
      ))}
    </Stack>
  );
}
