import { Box, sharedTransition } from '@remote-com/norma';
import { IconArrowUp } from '@remote-com/norma/icons/IconArrowUp';
import { rem, transparentize } from 'polished';
import styled, { css } from 'styled-components';

// eslint-disable-next-line deprecate/import
import RemoveFileIcon from '@/public/images/icons/remove-file-icon.svg';
import { focusVisible } from '@/src/style/selectors';

const FileButtonSelector = styled(Box)``;

export const FileContainer = styled.label`
  position: relative;

  &:hover ${FileButtonSelector} {
    box-shadow: 5px 12px 26px ${({ theme }) => theme.colors.brand[200]};
  }

  &:hover ${FileButtonSelector}::before {
    opacity: 1;
  }
`;

export const FileButton = styled(FileButtonSelector)`
  display: flex;
  align-items: center;
  position: relative;
  mix-blend-mode: normal;
  padding: 14px;
  font-size: ${rem('16px')};
  color: ${({ theme }) => theme.colors.blank};
  box-shadow: 5px 12px 26px ${({ theme }) => theme.colors.brand[50]};
  background-color: ${({ theme }) => theme.colors.primary};
  box-sizing: border-box;
  border-radius: 10px;
  text-decoration: none;
  transition: box-shadow 0.25ms ease-in-out;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      58.31deg,
      rgba(134, 114, 252, 0.72) -6.33%,
      rgba(132, 112, 248, 0.5) 107.96%
    );
    z-index: -1;
    transition: opacity 0.25s linear;
    opacity: 0;
    pointer-events: none;
    border-radius: 10px;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1) inset;
  }

  ${({ theme }) =>
    focusVisible(css`
      box-shadow: 0 0 0 3px ${theme.colors.blank}, 0 0 0 5px ${theme.colors.brand[600]};
    `)};
`;

const FileIconContainer = styled.span`
  border-radius: 50%;
  border: 2px solid ${({ theme }) => transparentize(0.6, theme.colors.brand[100])};
  background: ${({ theme }) => transparentize(0.4, theme.colors.brand[900])};
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
`;

export const FileIcon = () => (
  <FileIconContainer>
    <IconArrowUp width="14px" />
  </FileIconContainer>
);

export const FileLabel = styled.span`
  margin-left: 12px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
`;

export const FileLabelTitle = styled.span`
  font-weight: 500;
  font-size: ${rem('16px')};
  line-height: ${rem('22px')};
  margin: 0;
  color: var(--colors-blank);
`;

export const FileLabelDescription = styled.span`
  font-size: ${rem('13px')};
  line-height: ${rem('18px')};
  color: rgba(255, 255, 255, 0.6);
  margin-top: 4px;
  margin-bottom: 0;
`;

const FileRemoveSelector = styled.input``;

export const FileRemove = styled(RemoveFileIcon)`
  ${sharedTransition('fill')}
  fill: transparent;
  cursor: pointer;

  path {
    ${sharedTransition('stroke')}
    stroke: ${({ theme }) => theme.colors.primary};
  }

  ${FileRemoveSelector}:focus ~ &,
  ${FileRemoveSelector}:hover ~ & {
    fill: ${({ theme }) => theme.colors.primary};

    path {
      stroke: ${({ theme }) => theme.colors.blank};
    }
  }
`;

export const FileInput = styled(FileRemoveSelector)`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  cursor: pointer;

  &::file-selector-button {
    cursor: pointer;
  }

  &:focus ~ ${FileButtonSelector} {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.blank},
      0 0 0 5px ${({ theme }) => theme.colors.primary};
  }
`;
