import { Text, SROnly, Box, Media, MediaBody, MediaObject, HTMLRendered } from '@remote-com/norma';
import { Field, useFormikContext } from 'formik';
import get from 'lodash/get';
import { useState, useEffect } from 'react';

import { CheckboxField as InputCheckbox } from '@/src/components/Ui/Form';
import { REMOTE_COMPRESS_LARGE_FILE_ROUTE } from '@/src/constants/routes';
import { useUploadConfig } from '@/src/domains/files/UploadConfig';
import { FormFieldError } from '@/src/domains/registration/onboarding/shared/styles/Form';

import FileNameDisplay from './FileNameDisplay';
import FileUpload from './FileUpload';
import {
  ButtonInlineStyled,
  FieldTitle,
  FileDescription,
  FileLinkWrapper,
  FileName,
  FileSelectedCheck,
  PaperClipIcon,
  DownloadBaseIcon,
  StyledFileUploadField,
  FileLink,
} from './FileUploadField.styled';

export const SKIPPABLE_LABEL = 'This document does not apply to my profile';
export const SKIPPABLE_ERROR_MESSAGE =
  'Please upload a document or acknowledge that it does not apply to your profile.';

export const UPLOAD_LATER_ERROR_MESSAGE =
  'Please upload a document or acknowledge that you will upload it later.';

export const MAX_FILE_SIZE_LABEL = 'Maximum file size of 20 MB.';

function FileStatus({ file, templateFileName, templateFileUrl }) {
  if (file) {
    return (
      <FileLinkWrapper>
        <PaperClipIcon width="14" /> <FileNameDisplay filename={file.name} />
      </FileLinkWrapper>
    );
  }

  if (templateFileUrl) {
    return (
      <FileLink
        href={templateFileUrl}
        target="_blank"
        rel="noopener noreferrer"
        download={templateFileName || ''}
      >
        <FileLinkWrapper>
          <DownloadBaseIcon width="14" /> <FileName>Download the template</FileName>
        </FileLinkWrapper>
      </FileLink>
    );
  }

  return (
    <FileLinkWrapper>
      <PaperClipIcon width="14" />
      <FileName>No file selected</FileName>
    </FileLinkWrapper>
  );
}

const isFieldEmpty = (fieldValue) => !(Array.isArray(fieldValue) && fieldValue.length > 0);

/**
 * FileUploadField
 *
 * Renders a radio field for const value selection
 *
 * @export
 * @param {import('@/src/components/Form/DynamicForm/types').RenderFileField} props
 * @return {JSX.Element}
 */
const FileUploadField = ({
  name,
  label,
  description,
  multiple = false,
  skippable = false,
  skippableLabel = SKIPPABLE_LABEL,
  skippableErrorMessage,
  blockerLabel,
  onFilesRemoved = () => {},
  allowLaterUpload,
  'data-testid': dataTestid,
  fileName: templateFileName,
  url: templateFileUrl,
  documentType,
  accept,
  disableSkippableCheck,
  showLearnMoreLink = false,
}) => {
  const config = useUploadConfig();
  const { values, setFieldTouched, setFieldValue } = useFormikContext();

  const fieldValue = get(values, name);

  const [initialFiles, setInitialFiles] = useState(fieldValue);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // TODO: This value needs to be dynamic
  const [isCheckboxTouched, setIsCheckboxTouched] = useState(false);

  const skippableWithAckCheck = skippable && !disableSkippableCheck;
  const isFileInputVisible = !skippable || (skippable && !isCheckboxChecked);
  const isRequired = !skippable;

  const isFieldInvalid = (value) =>
    skippableWithAckCheck && !isCheckboxChecked && isFieldEmpty(value);

  const validationForBlocker = (value) => {
    // Run the validation based on the value received by the validation function
    // instead of using `fieldValue` declared above: the former will receive the
    // latest updated form value, i.e. when calling `setFieldValue`.
    if (isFieldInvalid(value)) {
      return allowLaterUpload
        ? UPLOAD_LATER_ERROR_MESSAGE
        : skippableErrorMessage || SKIPPABLE_ERROR_MESSAGE;
    }
    return undefined;
  };

  const acceptedFiles = config[documentType];

  const handleFilesSelected = (selectedFiles) => {
    setFieldValue(name, selectedFiles);
  };

  const handleFilesRemoved = async (selectedFiles) => {
    await onFilesRemoved(selectedFiles);
    setFieldValue(name, []);
    setFieldTouched(name);
  };

  const handleClickCheckbox = async () => {
    const willCheckboxBeChecked = !isCheckboxChecked;

    setIsCheckboxChecked(willCheckboxBeChecked);
    setIsCheckboxTouched(true);

    if (willCheckboxBeChecked) {
      if (initialFiles) {
        await onFilesRemoved(initialFiles);
        setInitialFiles([]);
      }

      setFieldValue(name, []);
    }
  };

  useEffect(() => {
    // NOTE: Ensure the error message is toggled immediately when the checkbox or a file is changed.
    if (isCheckboxTouched) {
      setFieldTouched(name);
    }
  }, [name, isCheckboxChecked, isCheckboxTouched, setFieldTouched, fieldValue]);

  return (
    <Field name={name} validate={validationForBlocker}>
      {({ meta: { error, touched, value: selectedFilesList = [] }, form }) => {
        const hasSelectedFile = selectedFilesList?.length > 0;
        const hasSelectedMultipleFiles = multiple && selectedFilesList?.length > 1;

        return (
          <StyledFileUploadField as={skippableWithAckCheck && 'fieldset'}>
            <Media>
              <MediaBody>
                <FieldTitle id={`label-${name}`}>
                  {label} {hasSelectedFile && <FileSelectedCheck />}
                </FieldTitle>
                {description && <HTMLRendered Tag={FileDescription}>{description}</HTMLRendered>}
                {isFileInputVisible && (
                  <>
                    <FileDescription>
                      {hasSelectedFile ? (
                        selectedFilesList.map((file, fileIndex) => (
                          <FileStatus key={fileIndex} file={file} />
                        ))
                      ) : (
                        <FileStatus
                          templateFileName={templateFileName}
                          templateFileUrl={templateFileUrl}
                        />
                      )}
                    </FileDescription>
                    {multiple && !hasSelectedMultipleFiles && (
                      <FileDescription>You may select multiple files at once.</FileDescription>
                    )}
                    {showLearnMoreLink && (
                      <Box display="flex" gap={2} mt={2}>
                        <Text variant="xs" color="inputDescription">
                          {MAX_FILE_SIZE_LABEL}
                          <ButtonInlineStyled
                            href={REMOTE_COMPRESS_LARGE_FILE_ROUTE}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Learn more
                          </ButtonInlineStyled>
                        </Text>
                      </Box>
                    )}
                  </>
                )}
              </MediaBody>
              {isFileInputVisible && (
                <MediaObject $preserveSize>
                  <FileUpload
                    label={`Upload file${multiple ? 's' : ''}`}
                    aria-label={label}
                    files={initialFiles}
                    multiple={multiple}
                    id={name}
                    name={name}
                    onFilesRemoved={(removedFiles) =>
                      handleFilesRemoved(removedFiles, form.setFieldValue)
                    }
                    onFilesSelected={(selectedFiles) =>
                      handleFilesSelected(selectedFiles, form.setFieldValue)
                    }
                    data-testid={dataTestid}
                    aria-describedby={`checkboxHint-${name} ${name}-errorMessage`}
                    aria-invalid={isFieldInvalid(fieldValue)}
                    aria-required={isRequired}
                    {...(acceptedFiles && { accept: acceptedFiles.extensions.join() })}
                    {...(accept && { accept })}
                  />
                </MediaObject>
              )}
            </Media>
            {skippableWithAckCheck && (
              <SROnly id={`checkboxHint-${name}`}>
                This field is optional. To skip it, please tick the following checkbox.
              </SROnly>
            )}

            {/*
              TODO: remove the blockerLabel when the change is made on the BE
              https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/10947
            */}
            {skippableWithAckCheck && (
              <Box mt="5">
                <InputCheckbox
                  id={`${name}-blocker`}
                  name={`${name}-blocker`}
                  label={blockerLabel || skippableLabel}
                  onClick={handleClickCheckbox}
                  checked={isCheckboxChecked}
                  aria-label={`${label}: ${blockerLabel || skippableLabel}`}
                />
              </Box>
            )}
            {error && touched && (
              <Box mt={5}>
                <FormFieldError name={name} id={`${name}-errorMessage`} />
              </Box>
            )}
          </StyledFileUploadField>
        );
      }}
    </Field>
  );
};

export default FileUploadField;
