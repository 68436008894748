import { FeedbackMessage, FEEDBACK_MESSAGE_WARNING, Text, toast, Box } from '@remote-com/norma';
import { IconChevronDown } from '@remote-com/norma/icons/IconChevronDown';
import { IconChevronUp } from '@remote-com/norma/icons/IconChevronUp';
import cloneDeep from 'lodash/cloneDeep';
import { useState, useMemo } from 'react';
import { object } from 'yup';

import { ButtonIcon } from '@/src/components/Button';
import FormModal from '@/src/components/Modal/FormModal';
import {
  onlyDataColumns,
  onlyExportableColumns,
  onlyNonDataColumns,
  COLUMN_STATES,
} from '@/src/components/Table/hooks/useColumnsState';
import { CheckboxField as InputCheckbox } from '@/src/components/Ui/Form';

const CollapseButton = ({ collapsed, setCollapsed }) => {
  const label = collapsed ? 'View Columns' : 'Collapse Columns';
  return (
    <ButtonIcon
      label={label}
      variant="ghost"
      size="xs"
      tone="secondary"
      onClick={() => setCollapsed((c) => !c)}
      Icon={collapsed ? IconChevronDown : IconChevronUp}
      aria-expanded={!collapsed}
    />
  );
};

const CheckboxGroup = ({
  label,
  isChecked,
  handleToggleAll,
  collapsed,
  setCollapsed,
  columns,
  handleColumnVisibilityChange,
}) => {
  return (
    <>
      <Box display="flex" alignItems="flex-end" gap={2}>
        <InputCheckbox
          size="sm"
          name={`check-all-${label.toLowerCase().replace(/\s+/g, '-')}`}
          label={<Text variant="baseSemiBold">{label}</Text>}
          checked={isChecked}
          onChange={handleToggleAll}
        />
        <CollapseButton collapsed={collapsed} setCollapsed={setCollapsed} />
      </Box>
      {!collapsed && (
        <Box pl={6}>
          {columns.map(([id, columnState, name]) => (
            <InputCheckbox
              key={id}
              size="sm"
              name={id}
              label={name}
              checked={columnState !== COLUMN_STATES.invisible}
              onChange={() => handleColumnVisibilityChange(id)}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export const LARGE_CSV_MIN_ROWS = 1000;

const validationSchema = object().shape({});

const getAreAllColumnsChecked = (columns) =>
  columns.every(
    ([, columnState]) =>
      columnState === COLUMN_STATES.visible || columnState === COLUMN_STATES.export
  );

const getColumnsChecked = (columns) =>
  columns.map(([id, , name]) => [id, COLUMN_STATES.visible, name]);

const getColumnsUnchecked = (columns) =>
  columns.map(([id, , name]) => [id, COLUMN_STATES.invisible, name]);

/**
 * Toggles the state of a specific column in a list of columns.
 *
 * @param {Array} columns - The list of columns. Each column is represented as an array [id, state, name].
 * @param {string|number} id - The ID of the column to toggle.
 * @param {string} fromState - The current state of the column to be toggled from.
 * @param {string} toState - The new state to toggle to.
 * @returns {Array} A new array of columns with the specified column's state toggled.
 */
const toggleColumnState = (columns, id, fromState, toState) => {
  return columns.map(([colId, colState, name]) => {
    if (colId === id) {
      return [colId, colState === fromState ? toState : fromState, name];
    }
    return [colId, colState, name];
  });
};

/**
 * Toggles the visibility state of all columns.
 *
 * @param {Array.<Array>} columns - An array of columns, each represented as an array with elements [id, state, name].
 * @param {boolean} isChecked - Indicates whether the columns should be set to invisible (true) or visible (false).
 * @returns {Array.<Array>} A new array of columns with updated visibility states.
 */
const toggleAllColumnsVisibility = (columns, isChecked) => {
  return columns.map(([id, , name]) => [
    id,
    isChecked ? COLUMN_STATES.invisible : COLUMN_STATES.visible,
    name,
  ]);
};

export const ExportDataPopover = ({
  open,
  columnsState,
  totalCount,
  hideModal,
  handleExportColumns,
  createCSV,
  allowConfigureAllColumns,
}) => {
  const [collapsedOriginalColumns, setCollapsedOriginalColumns] = useState(false);
  const [collapsedAdditionalColumns, setCollapsedAdditionalColumns] = useState(true);
  const [collapsedExtraColumns, setCollapsedExtraColumns] = useState(true);

  const clonedColumnsState = cloneDeep(columnsState);

  // We need to use cloneDeep as columnsState is a nested array and otherwise we will be mutating it with all state changes
  const initialColumnsState = clonedColumnsState.filter(
    ([, columnState]) => columnState !== COLUMN_STATES.export
  );
  const initialExtraColumnsState = clonedColumnsState.filter(
    ([, columnState]) => columnState === COLUMN_STATES.export
  );
  const [columnsStateToExport, setColumnsStateToExport] = useState(initialColumnsState);
  // extraColumnsStateToExport contains columns that are not visible in the table but are useful for export use cases.
  const [extraColumnsStateToExport, setExtraColumnsStateToExport] =
    useState(initialExtraColumnsState);

  const isLargeExport = totalCount > LARGE_CSV_MIN_ROWS;

  /* Gets only the columns whose configuration is NOT set to hidden. Columns that are configured as hidden are not meant to be displayed on the table, and as such, should not be configurable. */
  const configurableColumns = columnsStateToExport.filter(onlyExportableColumns);

  // Gets only the columns that are defined originally in each specific table.
  const originalColumns = configurableColumns.filter(onlyNonDataColumns);

  const additionalColumns = configurableColumns.filter(onlyDataColumns);

  const additionalColumnsSorted = [...additionalColumns].sort(([, , nameA], [, , nameB]) =>
    nameA > nameB ? 1 : -1
  );

  const areAllOriginalColumnsChecked = useMemo(
    () => getAreAllColumnsChecked(originalColumns),
    [originalColumns]
  );

  const areAllAdditionalColumnsChecked = useMemo(
    () => getAreAllColumnsChecked(additionalColumns),
    [additionalColumns]
  );

  const areAllExtraColumnsChecked = useMemo(
    () => getAreAllColumnsChecked(extraColumnsStateToExport),
    [extraColumnsStateToExport]
  );

  const areAllColumnsChecked =
    areAllOriginalColumnsChecked && areAllAdditionalColumnsChecked && areAllExtraColumnsChecked;

  async function handleSave() {
    // Merge all types of columns: visible, invisible, and export-only
    const allColumns = [...columnsStateToExport, ...extraColumnsStateToExport];

    // Filter columns that are either visible or marked for export
    const columnsToExport = allColumns.filter(
      ([, columnState]) =>
        columnState === COLUMN_STATES.visible || columnState === COLUMN_STATES.export
    );

    // Create a map from the original columnsState for ordering
    const originalOrderMap = new Map();
    columnsState.forEach(([id], index) => {
      originalOrderMap.set(id, index);
    });

    // Sort columnsToExport based on the original order
    columnsToExport.sort((a, b) => originalOrderMap.get(a[0]) - originalOrderMap.get(b[0]));

    await handleExportColumns(columnsToExport, createCSV);
    toast.success('Your file is being downloaded.');
    hideModal();
  }

  const handleColumnVisibilityChange = (id) => {
    setColumnsStateToExport((prevColumns) => {
      const index = prevColumns.findIndex(([columnId]) => columnId === id);
      return index !== -1
        ? toggleColumnState(prevColumns, id, COLUMN_STATES.visible, COLUMN_STATES.invisible)
        : prevColumns;
    });

    setExtraColumnsStateToExport((prevExtraColumns) => {
      const index = prevExtraColumns.findIndex(([columnId]) => columnId === id);
      return index !== -1
        ? toggleColumnState(prevExtraColumns, id, COLUMN_STATES.export, COLUMN_STATES.invisible)
        : prevExtraColumns;
    });
  };

  const handleToggleAllOriginalColumns = () => {
    const toggledColumns = areAllOriginalColumnsChecked
      ? getColumnsUnchecked(originalColumns)
      : getColumnsChecked(originalColumns);

    setColumnsStateToExport([...toggledColumns, ...additionalColumns]);
  };

  const handleToggleAllAdditionalColumns = () => {
    const toggledColumns = areAllAdditionalColumnsChecked
      ? getColumnsUnchecked(additionalColumns)
      : getColumnsChecked(additionalColumns);

    setColumnsStateToExport([...originalColumns, ...toggledColumns]);
  };

  const handleToggleAllColumns = () => {
    let toggledOriginalAndAdditionalColumns;
    let toggledExtraColumns;

    if (areAllColumnsChecked) {
      // Uncheck all columns
      toggledOriginalAndAdditionalColumns = toggleAllColumnsVisibility(
        [...originalColumns, ...additionalColumns],
        true
      );
      toggledExtraColumns = toggleAllColumnsVisibility(extraColumnsStateToExport, true);
    } else {
      // Check all columns
      toggledOriginalAndAdditionalColumns = toggleAllColumnsVisibility(
        [...originalColumns, ...additionalColumns],
        false
      );
      toggledExtraColumns = toggleAllColumnsVisibility(extraColumnsStateToExport, false);
    }

    setColumnsStateToExport(toggledOriginalAndAdditionalColumns);
    setExtraColumnsStateToExport(toggledExtraColumns);
  };

  const handleToggleAllExtraColumns = () => {
    const toggledColumns = areAllExtraColumnsChecked
      ? extraColumnsStateToExport.map(([id, , name]) => [id, COLUMN_STATES.invisible, name])
      : extraColumnsStateToExport.map(([id, , name]) => [id, COLUMN_STATES.export, name]);

    setExtraColumnsStateToExport(toggledColumns);
  };

  return (
    <FormModal
      modalProps={{
        visible: open,
        headerTitle: 'Export dataset',
        onSave: handleSave,
        saveButtonText: 'Download',
        formName: 'export-csv-data',
        onCancel: hideModal,
        errorMessage: 'There was an error creating your file',
      }}
      validationSchema={validationSchema}
      initialValues={{}}
    >
      <Box maxHeight="65vh" overflow="auto">
        <Text variant="base">Please select columns to export:</Text>
        <Box mb={7}>
          <InputCheckbox
            size="sm"
            name="check-all-columns"
            label={<Text variant="baseSemiBold">All columns</Text>}
            checked={areAllColumnsChecked}
            onChange={() => handleToggleAllColumns()}
          />
          <Box pl={6}>
            <CheckboxGroup
              label="Original columns"
              isChecked={areAllOriginalColumnsChecked}
              handleToggleAll={handleToggleAllOriginalColumns}
              collapsed={collapsedOriginalColumns}
              setCollapsed={setCollapsedOriginalColumns}
              columns={originalColumns}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
            />
            {extraColumnsStateToExport.length > 0 && (
              <CheckboxGroup
                label="Export-only columns"
                isChecked={areAllExtraColumnsChecked}
                handleToggleAll={handleToggleAllExtraColumns}
                collapsed={collapsedExtraColumns}
                setCollapsed={setCollapsedExtraColumns}
                columns={extraColumnsStateToExport}
                handleColumnVisibilityChange={handleColumnVisibilityChange}
              />
            )}
            {allowConfigureAllColumns && additionalColumnsSorted.length > 0 && (
              <CheckboxGroup
                label="Additional columns"
                isChecked={areAllAdditionalColumnsChecked}
                handleToggleAll={handleToggleAllAdditionalColumns}
                collapsed={collapsedAdditionalColumns}
                setCollapsed={setCollapsedAdditionalColumns}
                columns={additionalColumnsSorted}
                handleColumnVisibilityChange={handleColumnVisibilityChange}
              />
            )}
          </Box>
        </Box>
        {isLargeExport && (
          <FeedbackMessage variant={FEEDBACK_MESSAGE_WARNING}>
            This is a large dataset with {totalCount} rows. The download might take a few seconds.
          </FeedbackMessage>
        )}
      </Box>
    </FormModal>
  );
};
