// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2SolidStars = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M18.89 3.39a.39.39 0 1 0-.78 0c0 1.5-1.22 2.72-2.72 2.72a.39.39 0 1 0 0 .78c1.5 0 2.72 1.22 2.72 2.72a.39.39 0 1 0 .78 0c0-1.5 1.22-2.72 2.72-2.72a.39.39 0 1 0 0-.78A2.72 2.72 0 0 1 18.9 3.4Z" /><path d="M8.75 6c.41 0 .75.34.75.75 0 2.9 2.35 5.25 5.25 5.25a.75.75 0 0 1 0 1.5 5.25 5.25 0 0 0-5.25 5.25.75.75 0 0 1-1.5 0c0-2.9-2.35-5.25-5.25-5.25a.75.75 0 0 1 0-1.5C5.65 12 8 9.65 8 6.75c0-.41.34-.75.75-.75Z" /><path d="M18.5 17c.15 0 .28.12.28.28 0 1.07.87 1.94 1.94 1.94a.28.28 0 0 1 0 .56c-1.07 0-1.94.87-1.94 1.94a.28.28 0 0 1-.56 0c0-1.07-.87-1.94-1.94-1.94a.28.28 0 0 1 0-.56c1.07 0 1.94-.87 1.94-1.94 0-.16.13-.28.28-.28Z" /></svg>;
const ForwardRef = forwardRef(IconV2SolidStars);
export { ForwardRef as IconV2SolidStars };