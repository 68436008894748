import { Text, Box, Stack, ButtonInline } from '@remote-com/norma';
import type { ComponentProps } from 'react';
import styled, { css } from 'styled-components';

export const SignaturesWrapper = styled(Box)<{ $isBilingual: boolean }>`
  display: grid;
  ${({ $isBilingual }) =>
    $isBilingual
      ? css`
          grid-template-rows: 1fr 1fr;
          grid-row-gap: 48px;
        `
      : css`
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 72px;
        `}
`;

export const Wrapper = styled(Stack)`
  position: relative;
  padding: ${({ theme }) => theme.space[6]}px ${({ theme }) => theme.space[5]}px;
  /**
   * Additional padding on top is necessary to give room
   * to the absolute positioned PillWrapper.
   */
  padding-top: ${({ theme }) => theme.space[8]}px;
`;

export const PillWrapper = styled(Box)`
  position: absolute;
  top: ${({ theme }) => theme.space[3]}px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

export const SignatureContent = styled(Text).attrs<{ isSigned: boolean }>((props) => ({
  variant: props.isSigned ? '2xlMedium' : 'sm',
}))<{ isSigned: boolean }>`
  padding: ${({ isSigned }) => (isSigned ? '8px 48px' : '12px 48px')};
  user-select: none;

  font-family: ${({ isSigned, theme }) =>
    isSigned ? "'Dancing Script', cursive" : theme.fonts.body};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
`;

export const WithSignatureUnderline = styled(Box)`
  display: flex;
  align-items: end;
  justify-content: center;
  /**
   * Minimum height ensures consistent alignment for both
   * signed and unsigned signature fields, which have different
   * font sizes.
   */
  min-height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
`;

export const StyledButtonInline = styled(ButtonInline)`
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

export const SignatureDetails = styled(Box)`
  margin-top: ${({ theme }) => theme.space[6]}px;
`;

export const PlaceholderSmartField = styled(Text).attrs<ComponentProps<typeof Text>>((props) => ({
  variant: props.variant === 'raw' ? 'sm' : 'smSemiBold',
  color: props.variant === 'raw' ? 'grey.900' : 'purple.700',
  bg: props.variant === 'raw' ? 'transparent' : 'purple.100',
  mt: props.variant === 'raw' ? '0' : '16px',
  with: props.variant === 'raw' ? 'auto' : 'fit-content',
  padding: props.variant === 'raw' ? '0' : '0px 4px',
}))`
  border-radius: 4px;
`;
