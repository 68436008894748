import { HTMLRendered, Stack, Text, RadioCard } from '@remote-com/norma';
import styled from 'styled-components';

import { ButtonInline } from '@/src/components/Button';
import { RadioCardField } from '@/src/components/Ui/Form';

/**
 * @deprecated - please use elements from Octopus instead
 */
export const TierCard = styled(RadioCardField)`
  padding: 8px;
  padding-right: 24px;

  > span {
    background-color: white !important;
  }

  label {
    div {
      flex-wrap: wrap;
      div {
        margin-left: 0;
      }
    }
  }

  label,
  legend {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ${RadioCard.IllustrationContainer} {
    align-self: normal;
  }

  ${RadioCard.ContentWrapper} {
    gap: ${({ theme }) => theme.space[4]}px;
  }
`;

/**
 * @deprecated - please use elements from Octopus instead
 */
export const TierCardDescription = ({
  detailsUrl,
  description,
}: {
  detailsUrl?: string | null;
  description?: string;
}) => (
  <Stack mb={3}>
    <HTMLRendered Tag={Text}>
      {description}{' '}
      {detailsUrl && (
        <ButtonInline href={detailsUrl} target="_blank" rel="nofollow noreferrer noopener">
          View more details
        </ButtonInline>
      )}
    </HTMLRendered>
  </Stack>
);
