import { CARD_BRAND } from './constants';
import AmericanExpressLogo from './images/american-express.svg';
import DinersClubLogo from './images/diners-club.svg';
import DiscoverLogo from './images/discover.svg';
import JCBLogo from './images/jcb.svg';
import MastercardLogo from './images/mastercard.svg';
import VisaLogo from './images/visa.svg';

/**
 * Returns the SVG component for a credit card vendor logo
 * @param {string} vendorName
 */
export function getCardBrandLogo(vendorName) {
  switch (vendorName) {
    case CARD_BRAND.AMEX:
      return AmericanExpressLogo;
    case CARD_BRAND.DINERS:
      return DinersClubLogo;
    case CARD_BRAND.DISCOVER:
      return DiscoverLogo;
    case CARD_BRAND.JCB:
      return JCBLogo;
    case CARD_BRAND.MASTERCARD:
      return MastercardLogo;
    // case CARD_BRAND.UNIONPAY:
    //   return UnionpayLogo;
    case CARD_BRAND.VISA:
      return VisaLogo;
    default:
      return null;
  }
}
