import { Stack, Text } from '@remote-com/norma';
import type { ReactNode } from 'react';

import SearchField from '@/src/components/Table/Components/SearchField';

const messages = {
  noResults: 'No results match your search.',
};

type ColumnsConfigurationSearchProps<T> = {
  /** The label for the search input.  */
  label: string;
  /** Function to call when the search input value changes. */
  onSearch: (value: string) => void;
  /** Function to render each search result. */
  renderResult: (result: T) => ReactNode;
  /** List of search result items. */
  searchResults: T[];
  /** The current value of the search input. */
  searchValue: string;
};

export const ColumnsConfigurationSearch = <T,>({
  label,
  onSearch,
  renderResult,
  searchResults,
  searchValue,
}: ColumnsConfigurationSearchProps<T>) => {
  return (
    <Stack gap={6}>
      <SearchField
        value={searchValue}
        setSearchQuery={onSearch}
        placeholder={label}
        width="100%"
        id="table-edit-table-drawer-search-input"
      />
      {searchValue &&
        (searchResults.length ? (
          <Stack gap={3}>{searchResults.map((searchResult) => renderResult(searchResult))}</Stack>
        ) : (
          <Text variant="xs">{messages.noResults}</Text>
        ))}
    </Stack>
  );
};
