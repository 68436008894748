import type { DefaultTheme, ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components';

import { sharedTransition } from '../../core/animations';
import { Text } from '../../core/text';
import { Box, Stack } from '../../layout';

import type { InputCheckboxSize } from '.';

export const Input = styled(Box)`
  flex-shrink: 0;
`;

export const Body = styled(Box)``;

export const Wrapper = styled(Stack).attrs<{
  direction: string;
  alignItems: undefined;
}>({
  direction: 'row',
  gap: 4,
  alignItems: undefined,
})``;

export const Checkbox = styled(Stack).attrs({ as: 'label' })<{ isDisabled: boolean }>`
  display: flex;
  align-items: flex-start;
  position: relative;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
    opacity: 0;
  }
`;

export const CHECKBOX_STATE_SIZE = 20;
export const CHECKBOX_STATE_SIZE_SMALL = 16;

type CheckboxStateProps = {
  $checked?: boolean;
  $hasError?: boolean;
  $size?: InputCheckboxSize;
};

export const CheckboxState = styled(Box)<CheckboxStateProps>`
  --checkboxStateSize: ${({ $size }) =>
    $size === 'sm' ? CHECKBOX_STATE_SIZE_SMALL : CHECKBOX_STATE_SIZE}px;
  ${sharedTransition('border-color, background-color')}
  transition-duration: 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--checkboxStateSize);
  height: var(--checkboxStateSize);
  border-radius: 5px;
  border: ${({ theme, $size }) => `${$size === 'sm' ? 1 : 2}px solid ${theme.colors.grey[500]}`};
  background-color: ${({ theme }) => theme.colors.blank};

  &::after {
    ${sharedTransition('opacity, transform')}
    transition-duration: 0.1s;
    opacity: 0;
    transform: scale(0);
    width: 100%;
    height: 100%;
    background-image: url('/images/icons/check-light.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${({ $size }) => ($size === 'sm' ? '9px 7px' : '12px 10px')};
    /* Provide zero-width space as 'content' so that the checkbox has a text flow
       and can be aligned using baseline alignment. */
    content: '\\00200b';
    line-height: calc(var(--checkboxStateSize) - 1px);
  }

  /* Indeterminate state icon. */
  &::before {
    ${sharedTransition('opacity')}
    content: '';
    opacity: 0;
    position: absolute;
    width: ${({ theme }) => theme.space[4]}px;
    border-radius: 4px;
    height: 2px;
    background: ${({ theme }) => theme.colors.blank};
  }

  /* Readonly */
  input[readonly] ~ & {
    background: ${({ theme }) => theme.colors.grey[100]};
    border-color: ${({ theme }) => theme.colors.grey[400]};
  }

  /* Hover */
  input:hover:not([readonly]) ~ & {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  /* Active */
  input:active:not([readonly]) ~ & {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.brand[200]},
      0 0 0 4px ${({ theme }) => theme.colors.brand[200]} !important;
  }

  /* Focus */
  input:focus {
    outline: none;
  }
  input:focus:not([readonly]) ~ & {
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.brand[100]},
      0 0 0 4px ${({ theme }) => theme.colors.brand[100]};
  }

  /* Checked */
  input:checked ~ & {
    border-color: ${({ theme }) => theme.colors.brand[600]};
    background-color: ${({ theme }) => theme.colors.brand[600]};

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* Checked Hover */
  input:checked:hover:not([readonly]) ~ & {
    border-color: ${({ theme }) => theme.colors.brand[700]};
    background-color: ${({ theme }) => theme.colors.brand[700]};
  }

  /* Checked Focus */
  input:checked:focus:not([readonly]) ~ & {
    border-color: ${({ theme }) => theme.colors.brand[700]};
    background-color: ${({ theme }) => theme.colors.brand[700]};
  }

  input:indeterminate ~ & {
    border-color: ${({ theme }) => theme.colors.brand[600]};
    background-color: ${({ theme }) => theme.colors.brand[600]};

    &::before {
      opacity: 1;
    }
  }

  input:checked[readonly] ~ &,
  input:indeterminate[readonly] ~ & {
    background: ${({ theme }) => theme.colors.grey[400]};
    border-color: ${({ theme }) => theme.colors.grey[400]};
  }

  /* Error State */
  ${({ $hasError }) =>
    $hasError &&
    css`
      /* The usage of !important and the checked prop in these 2 rules is on purpose:
      - we cannot use the input:[some-state] ~ & selector because this affects ALL instances of this components,
      which makes other components in the page that are NOT in error state to behave like they have an error.
      - because of that, we cannot use the input:checked states and instead need to rely on a checked prop
      TODO: Indeterminate state is not handled currently, check https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/15437 for more details
       */
      background-color: ${({
        theme,
        $checked,
      }: ThemeProps<DefaultTheme> & { $checked?: boolean }) =>
        $checked ? theme.colors.negative.foreground : theme.colors.negative.background} !important;
      border-color: ${({ theme }) => theme.colors.negative.foreground} !important;
    `}
`;

interface LabelProps {
  isDisabled: boolean;
  hidden?: boolean;
  size: InputCheckboxSize;
}

export const Label = styled(Text).attrs(({ hidden, isDisabled, size }: LabelProps) => ({
  color: isDisabled ? 'grey.500' : 'grey.900',
  display: hidden ? 'none' : 'flex',
  variant: size === 'sm' ? 'sm' : 'base',
}))<LabelProps>`
  user-select: none;
`;

interface DescriptionProps {
  isDisabled: boolean;
}

export const Description = styled(Text).attrs(({ isDisabled }: DescriptionProps) => ({
  forwardedAs: 'div',
  variant: 'xs',
  color: isDisabled ? 'grey.500' : 'grey.600',
}))<DescriptionProps>`
  white-space: pre-wrap;
`;
