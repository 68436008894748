// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneBookOpen = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M12 3.26a12.34 12.34 0 0 0-4.45-1.73c-1.55-.27-3.13-.3-4.7-.29-.56 0-.84 0-1.06.12a1 1 0 0 0-.43.43c-.11.22-.11.5-.11 1.06v8.96c0 1.59 0 2.38.29 3 .27.57.63.96 1.18 1.28.58.35 1.5.44 3.33.6.41.05.83.1 1.24.17A12.8 12.8 0 0 1 12 18.9V3.26Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 3.26a12.34 12.34 0 0 1 4.45-1.73c1.55-.27 3.13-.3 4.7-.29.56 0 .84 0 1.06.12a1 1 0 0 1 .43.43c.11.22.11.5.11 1.06v8.96c0 1.59 0 2.38-.29 3-.27.57-.63.96-1.18 1.28-.58.35-1.5.44-3.33.61-.41.04-.83.1-1.24.16A12.8 12.8 0 0 0 12 18.9V3.26ZM3 18.75a.75.75 0 0 0 0 1.5c1.97 0 3.4.12 4.68.47 1.27.35 2.45.93 3.9 1.9.26.17.58.17.84 0a13.1 13.1 0 0 1 3.9-1.9c1.28-.35 2.7-.47 4.68-.47a.75.75 0 0 0 0-1.5c-2.03 0-3.6.13-5.07.53-1.34.36-2.56.95-3.93 1.82a13.93 13.93 0 0 0-3.93-1.82c-1.47-.4-3.04-.53-5.07-.53Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneBookOpen);
export { ForwardRef as IconV2DuotoneBookOpen };