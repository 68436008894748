import { Portal } from '@radix-ui/react-portal';
import { Tooltip, Box, Stack } from '@remote-com/norma';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import FocusLock from 'react-focus-lock';
import styled, { css } from 'styled-components';

import { ButtonClose } from '@/src/components/Button/ButtonClose';
import useKeyPressDetection from '@/src/hooks/useKeyPressDetection';
import { focusVisible } from '@/src/style/selectors';
// The HighlightBox z-index should have higher value to be visible
export const Overlay = styled(Box)`
  z-index: 9998;
  position: fixed;
  inset: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(0, 35, 75, 0.3);
  backdrop-filter: blur(1.5px);
`;

const HighlightBox = styled(Box).attrs({
  tabIndex: '0', // Tooltip direct child node must be tab focusable, so tooltip content becomes accessible by keyboard
})`
  width: 100%;
  z-index: 9999;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.subtle};
  box-sizing: border-box;
  ${({ $styles }) => $styles || ''}

  ${focusVisible(css`
    outline: 1px solid ${({ theme }) => theme.colors.grey[100]};
  `)}
`;

const CloseIcon = styled(ButtonClose)`
  color: ${({ theme }) => theme.colors.lynch};
`;

const LabelBox = ({ label, onClick }) => {
  return label ? (
    <Stack direction="row" overflow="hidden">
      <Box>{label}</Box>
      <Box>
        <CloseIcon size="xs" variant="ghost" onClick={onClick} aria-label="Close tooltip drawer" />
      </Box>
    </Stack>
  ) : null;
};

export const TooltipModal = ({
  visible,
  styles,
  label,
  position,
  children,
  onClose,
  useOverlayPortal = false,
  useHighlightBox = true,
  useFocusLock = true,
}) => {
  useKeyPressDetection({
    listen: visible,
    key: 'escape',
    onKeyPress: onClose,
  });

  const highlightBoxRef = useRef();

  const OverlayComponent = useOverlayPortal ? (
    <Portal>
      <Overlay data-testid="tooltip-modal-overlay" />
    </Portal>
  ) : (
    <Overlay data-testid="tooltip-modal-overlay" />
  );

  return (
    <>
      <Tooltip
        visible={visible}
        styles={styles?.tooltip}
        label={
          useFocusLock ? (
            <FocusLock enabled={visible} shards={[highlightBoxRef]}>
              <LabelBox label={label} onClick={onClose} />
            </FocusLock>
          ) : (
            <LabelBox label={label} onClick={onClose} />
          )
        }
        position={position}
        onClickOutside={onClose}
      >
        {visible && useHighlightBox ? (
          <HighlightBox $styles={styles?.highlight} ref={highlightBoxRef}>
            {children}
          </HighlightBox>
        ) : (
          children
        )}
      </Tooltip>
      {visible ? OverlayComponent : null}
    </>
  );
};

TooltipModal.prototypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    tooltip: PropTypes.string,
    highlight: PropTypes.string,
  }),
};
