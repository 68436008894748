/**
 * Dial and areaCodes for countries from https://github.com/jackocnr/intl-tel-input/blob/master/src/js/data.js
 * Potentially being moved to the countries API https://remote-com.slack.com/archives/CFQDWAW30/p1642521056110600
 * Used to determine a phone numbers country and offering countries to select the dialing code from.
 */
/** @type {import('@/src/components/Form/DynamicForm/types').PhoneOption[]} */
export const countryDialCodes = [
  { name: 'Afghanistan', dialCode: '93' },
  { name: 'Albania', dialCode: '355' },
  { name: 'Algeria', dialCode: '213' },
  { name: 'American Samoa', dialCode: '1', areaCodes: ['684'] },
  { name: 'Andorra', dialCode: '376' },
  { name: 'Angola', dialCode: '244' },
  { name: 'Anguilla', dialCode: '1', areaCodes: ['264'] },
  { name: 'Antigua and Barbuda', dialCode: '1', areaCodes: ['268'] },
  { name: 'Argentina', dialCode: '54' },
  { name: 'Armenia', dialCode: '374' },
  { name: 'Aruba', dialCode: '297' },
  { name: 'Ascension Island', dialCode: '247' },
  { name: 'Australia', dialCode: '61' },
  { name: 'Austria', dialCode: '43' },
  { name: 'Azerbaijan', dialCode: '994' },
  { name: 'Bahamas', dialCode: '1', areaCodes: ['242'] },
  { name: 'Bahrain', dialCode: '973' },
  { name: 'Bangladesh', dialCode: '880' },
  { name: 'Barbados', dialCode: '1', areaCodes: ['246'] },
  { name: 'Belarus', dialCode: '375' },
  { name: 'Belgium', dialCode: '32' },
  { name: 'Belize', dialCode: '501' },
  { name: 'Benin', dialCode: '229' },
  { name: 'Bermuda', dialCode: '1', areaCodes: ['441'] },
  { name: 'Bhutan', dialCode: '975' },
  { name: 'Bolivia', dialCode: '591' },
  { name: 'Bosnia and Herzegovina', dialCode: '387' },
  { name: 'Botswana', dialCode: '267' },
  { name: 'Brazil', dialCode: '55' },
  { name: 'British Indian Ocean Territory', dialCode: '246' },
  { name: 'British Virgin Islands', dialCode: '1', areaCodes: ['284'] },
  { name: 'Brunei', dialCode: '673' },
  { name: 'Bulgaria', dialCode: '359' },
  { name: 'Burkina Faso', dialCode: '226' },
  { name: 'Burundi', dialCode: '257' },
  { name: 'Cambodia', dialCode: '855' },
  { name: 'Cameroon', dialCode: '237' },
  {
    name: 'Canada',
    dialCode: '1',
    areaCodes: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  },
  { name: 'Cape Verde', dialCode: '238', flagName: 'cabo-verde' },
  { name: 'Caribbean Netherlands', dialCode: '599', areaCodes: ['3', '4', '7'] },
  { name: 'Cayman Islands', dialCode: '1', areaCodes: ['345'] },
  { name: 'Central African Republic', dialCode: '236' },
  { name: 'Chad', dialCode: '235' },
  { name: 'Chile', dialCode: '56' },
  { name: 'China', dialCode: '86' },
  { name: 'Christmas Island', dialCode: '61', areaCodes: ['89164'] },
  { name: 'Cocos (Keeling) Island', dialCode: '61', areaCodes: ['89162'] },
  { name: 'Colombia', dialCode: '57' },
  { name: 'Comoros', dialCode: '269' },
  { name: 'Congo (DRC)', dialCode: '243', flagName: 'democratic-republic-of-congo' },
  { name: 'Congo (Republic)', dialCode: '242', flagName: 'republic-of-the-congo' },
  { name: 'Cook Islands', dialCode: '682' },
  { name: 'Costa Rica', dialCode: '506' },
  { name: 'Côte d’Ivoire', dialCode: '225', flagName: "cote-d'ivoire" },
  { name: 'Croatia', dialCode: '385' },
  { name: 'Cuba', dialCode: '53' },
  { name: 'Curaçao', dialCode: '599', flagName: 'curacao' },
  { name: 'Cyprus', dialCode: '357' },
  { name: 'Czech Republic', dialCode: '420' },
  { name: 'Denmark', dialCode: '45' },
  { name: 'Djibouti', dialCode: '253' },
  { name: 'Dominica', dialCode: '1', areaCodes: ['767'] },
  {
    name: 'Dominican Republic',
    dialCode: '1',
    areaCodes: ['809', '829', '849'],
  },
  { name: 'Ecuador', dialCode: '593' },
  { name: 'Egypt', dialCode: '20' },
  { name: 'El Salvador', dialCode: '503' },
  { name: 'Equatorial Guinea', dialCode: '240' },
  { name: 'Eritrea', dialCode: '291' },
  { name: 'Estonia', dialCode: '372' },
  { name: 'Eswatini', dialCode: '268', flagName: 'swaziland' },
  { name: 'Ethiopia', dialCode: '251' },
  { name: 'Falkland Islands', dialCode: '500' },
  { name: 'Faroe Islands', dialCode: '298' },
  { name: 'Fiji', dialCode: '679' },
  { name: 'Finland', dialCode: '358' },
  { name: 'France', dialCode: '33' },
  { name: 'French Guiana', dialCode: '594', flagName: 'france' },
  { name: 'French Polynesia', dialCode: '689', flagName: 'france' },
  { name: 'Gabon', dialCode: '241' },
  { name: 'Gambia', dialCode: '220' },
  { name: 'Georgia', dialCode: '995' },
  { name: 'Germany', dialCode: '49' },
  { name: 'Ghana', dialCode: '233' },
  { name: 'Gibraltar', dialCode: '350' },
  { name: 'Greece', dialCode: '30' },
  { name: 'Greenland', dialCode: '299' },
  { name: 'Grenada', dialCode: '1', areaCodes: ['473'] },
  { name: 'Guadeloupe', dialCode: '590', flagName: 'france' },
  { name: 'Guam', dialCode: '1', areaCodes: ['671'] },
  { name: 'Guatemala', dialCode: '502' },
  { name: 'Guernsey', dialCode: '44', areaCodes: ['1481', '7781', '7839', '7911'] },
  { name: 'Guinea', dialCode: '224' },
  { name: 'Guinea-Bissau', dialCode: '245' },
  { name: 'Guyana', dialCode: '592' },
  { name: 'Haiti', dialCode: '509' },
  { name: 'Honduras', dialCode: '504' },
  { name: 'Hong Kong', dialCode: '852' },
  { name: 'Hungary', dialCode: '36' },
  { name: 'Iceland', dialCode: '354' },
  { name: 'India', dialCode: '91' },
  { name: 'Indonesia', dialCode: '62' },
  { name: 'Iran', dialCode: '98' },
  { name: 'Iraq', dialCode: '964' },
  { name: 'Ireland', dialCode: '353' },
  { name: 'Isle of Man', dialCode: '44', areaCodes: ['1624', '74576', '7524', '7924', '7624'] },
  { name: 'Israel', dialCode: '972' },
  { name: 'Italy', dialCode: '39' },
  { name: 'Jamaica', dialCode: '1', areaCodes: ['876', '658'] },
  { name: 'Japan', dialCode: '81' },
  { name: 'Jersey', dialCode: '44', areaCodes: ['1534', '7509', '7700', '7797', '7829', '7937'] },
  { name: 'Jordan', dialCode: '962' },
  { name: 'Kazakhstan', dialCode: '7', areaCodes: ['33', '7'] },
  { name: 'Kenya', dialCode: '254' },
  { name: 'Kiribati', dialCode: '686' },
  { name: 'Kosovo', dialCode: '383' },
  { name: 'Kuwait', dialCode: '965' },
  { name: 'Kyrgyzstan', dialCode: '996' },
  { name: 'Laos', dialCode: '856' },
  { name: 'Latvia', dialCode: '371' },
  { name: 'Lebanon', dialCode: '961' },
  { name: 'Lesotho', dialCode: '266' },
  { name: 'Liberia', dialCode: '231' },
  { name: 'Libya', dialCode: '218' },
  { name: 'Liechtenstein', dialCode: '423' },
  { name: 'Lithuania', dialCode: '370' },
  { name: 'Luxembourg', dialCode: '352' },
  { name: 'Macau', dialCode: '853', flagName: 'macao' },
  { name: 'North Macedonia', dialCode: '389' },
  { name: 'Madagascar', dialCode: '261' },
  { name: 'Malawi', dialCode: '265' },
  { name: 'Malaysia', dialCode: '60' },
  { name: 'Maldives', dialCode: '960' },
  { name: 'Mali', dialCode: '223' },
  { name: 'Malta', dialCode: '356' },
  { name: 'Marshall Islands', dialCode: '692' },
  { name: 'Martinique', dialCode: '596' },
  { name: 'Mauritania', dialCode: '222' },
  { name: 'Mauritius', dialCode: '230' },
  { name: 'Mayotte', dialCode: '262', areaCodes: ['269', '639'], flagName: 'france' },
  { name: 'Mexico', dialCode: '52' },
  { name: 'Micronesia', dialCode: '691' },
  { name: 'Moldova', dialCode: '373' },
  { name: 'Monaco', dialCode: '377' },
  { name: 'Mongolia', dialCode: '976' },
  { name: 'Montenegro', dialCode: '382' },
  { name: 'Montserrat', dialCode: '1', areaCodes: ['664'] },
  { name: 'Morocco', dialCode: '212' },
  { name: 'Mozambique', dialCode: '258' },
  { name: 'Myanmar', dialCode: '95' },
  { name: 'Namibia', dialCode: '264' },
  { name: 'Nauru', dialCode: '674' },
  { name: 'Nepal', dialCode: '977' },
  { name: 'Netherlands', dialCode: '31' },
  { name: 'New Caledonia', dialCode: '687', flagName: 'france' },
  { name: 'New Zealand', dialCode: '64' },
  { name: 'Nicaragua', dialCode: '505' },
  { name: 'Niger', dialCode: '227' },
  { name: 'Nigeria', dialCode: '234' },
  { name: 'Niue', dialCode: '683' },
  { name: 'Norfolk Island', dialCode: '672' },
  { name: 'North Korea', dialCode: '850' },
  {
    name: 'Northern Mariana Islands',
    dialCode: '1',
    areaCodes: ['670'],
    flagName: 'northern-mariana-islands',
  },
  { name: 'Norway', dialCode: '47' },
  { name: 'Oman', dialCode: '968' },
  { name: 'Pakistan', dialCode: '92' },
  { name: 'Palau', dialCode: '680' },
  { name: 'Palestine', dialCode: '970' },
  { name: 'Panama', dialCode: '507' },
  { name: 'Papua New Guinea', dialCode: '675' },
  { name: 'Paraguay', dialCode: '595' },
  { name: 'Peru', dialCode: '51' },
  { name: 'Philippines', dialCode: '63' },
  { name: 'Poland', dialCode: '48' },
  { name: 'Portugal', dialCode: '351' },
  { name: 'Puerto Rico', dialCode: '1', areaCodes: ['787', '939'] },
  { name: 'Qatar', dialCode: '974' },
  { name: 'Réunion', dialCode: '262', flagName: 'france' },
  { name: 'Romania', dialCode: '40' },
  { name: 'Russia', dialCode: '7' },
  { name: 'Rwanda', dialCode: '250' },
  { name: 'Saint Barthélemy', dialCode: '590', flagName: 'st-barts' },
  { name: 'Saint Helena', dialCode: '290' },
  { name: 'Saint Kitts and Nevis', dialCode: '1', areaCodes: ['869'] },
  { name: 'Saint Lucia', dialCode: '1', areaCodes: ['758'] },
  { name: 'Saint Martin', dialCode: '590', flagName: 'france' },
  { name: 'Saint Pierre and Miquelon', dialCode: '508', flagName: 'france' },
  { name: 'Saint Vincent and the Grenadines', dialCode: '1', areaCodes: ['784'] },
  { name: 'Samoa', dialCode: '685' },
  { name: 'San Marino', dialCode: '378' },
  { name: 'São Tomé and Príncipe', dialCode: '239', flagName: 'sao-tome-and-principe' },
  { name: 'Saudi Arabia', dialCode: '966' },
  { name: 'Senegal', dialCode: '221' },
  { name: 'Serbia', dialCode: '381' },
  { name: 'Seychelles', dialCode: '248' },
  { name: 'Sierra Leone', dialCode: '232' },
  { name: 'Singapore', dialCode: '65' },
  { name: 'Sint Maarten', dialCode: '1', areaCodes: ['721'] },
  { name: 'Slovakia', dialCode: '421' },
  { name: 'Slovenia', dialCode: '386' },
  { name: 'Solomon Islands', dialCode: '677' },
  { name: 'Somalia', dialCode: '252' },
  { name: 'South Africa', dialCode: '27' },
  { name: 'South Korea', dialCode: '82' },
  { name: 'South Sudan', dialCode: '211' },
  { name: 'Spain', dialCode: '34' },
  { name: 'Sri Lanka', dialCode: '94' },
  { name: 'Sudan', dialCode: '249' },
  { name: 'Suriname', dialCode: '597' },
  { name: 'Svalbard and Jan Mayen', dialCode: '47', areaCodes: ['79'], flagName: 'norway' },
  { name: 'Sweden', dialCode: '46' },
  { name: 'Switzerland', dialCode: '41' },
  { name: 'Syria', dialCode: '963' },
  { name: 'Taiwan', dialCode: '886' },
  { name: 'Tajikistan', dialCode: '992' },
  { name: 'Tanzania', dialCode: '255' },
  { name: 'Thailand', dialCode: '66' },
  { name: 'Timor-Leste', dialCode: '670' },
  { name: 'Togo', dialCode: '228' },
  { name: 'Tokelau', dialCode: '690' },
  { name: 'Tonga', dialCode: '676' },
  { name: 'Trinidad and Tobago', dialCode: '1', areaCodes: ['868'] },
  { name: 'Tunisia', dialCode: '216' },
  { name: 'Turkey', dialCode: '90' },
  { name: 'Turkmenistan', dialCode: '993' },
  {
    name: 'Turks and Caicos Islands',
    dialCode: '1',
    areaCodes: ['649'],
  },
  { name: 'Tuvalu', dialCode: '688' },
  { name: 'U.S. Virgin Islands', dialCode: '1', areaCodes: ['340'] },
  { name: 'Uganda', dialCode: '256' },
  { name: 'Ukraine', dialCode: '380' },
  { name: 'United Arab Emirates', dialCode: '971' },
  { name: 'United Kingdom', dialCode: '44' },
  { name: 'United States', dialCode: '1' },
  { name: 'Uruguay', dialCode: '598' },
  { name: 'Uzbekistan', dialCode: '998' },
  { name: 'Vanuatu', dialCode: '678' },
  { name: 'Vatican City', dialCode: '39', areaCodes: ['06698'] },
  { name: 'Venezuela', dialCode: '58' },
  { name: 'Vietnam', dialCode: '84' },
  { name: 'Wallis and Futuna', dialCode: '681', flagName: 'france' },
  {
    name: 'Western Sahara',
    dialCode: '212',
    areaCodes: ['5288', '5289'],
    flagName: 'sahrawi-arab-democratic-republic',
  },
  { name: 'Yemen', dialCode: '967' },
  { name: 'Zambia', dialCode: '260' },
  { name: 'Zimbabwe', dialCode: '263' },
  { name: 'Åland Islands', dialCode: '358', areaCodes: ['18'], flagName: 'aland-islands' },
];

// Ensure there are no duplicate dial codes in the list, as some dial codes may have different area codes.
export const countryDialCodeList = [...new Set(countryDialCodes.map(({ dialCode }) => dialCode))];
