import { Box, Text } from '@remote-com/norma';
import styled from 'styled-components';

export const Description = styled(Box)`
  ${({ theme }) => theme.typography.sm};
  color: ${({ theme }) => theme.colors.lynch};
  padding-left: ${({ theme }) => theme.space[5]}px;
  margin-bottom: ${({ theme }) => theme.space[4]}px;
  white-space: pre-wrap;

  ul,
  ol {
    white-space: normal;
  }
`;

export const FinePrint = styled(Text).attrs({
  forwardedAs: 'p',
  color: 'lynch',
  variant: 'xs',
  px: 5,
})`
  white-space: pre-wrap;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const DetailsCard = styled(Box)`
  margin-top: ${({ theme }) => theme.space[4]}px;
  margin-bottom: ${({ theme }) => theme.space[4]}px;
  padding: ${({ theme }) => theme.space[7]}px;
  background-color: ${({ theme }) => theme.colors.blank};
  border: none;
  border-radius: 10px;
`;
