import type { ComponentProps } from 'react';

import { Button } from '@/src/components/Button';

/**
 * Add table record button
 *
 * A pre-configured wrapper `Button` component for use use in the `addRecordAction` prop in `Table`.
 * You can overwrite the initial setup if needed but it is recommended to always use this component for
 * add record actions in tables for consistency.
 *
 * - `size`: `sm`
 * - `data-testid`: `table-add-record-button`
 */
export function AddTableRecordButton({
  size = 'sm',
  'data-testid': dataTestId = 'table-add-record-button',
  ...props
}: ComponentProps<typeof Button>) {
  return <Button size={size} data-testid={dataTestId} {...props} />;
}
