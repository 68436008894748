export const VNM_WISE_SUPPORTED_BANK_SWIFT_CODES = [
  {
    value: 'ABBKVNVX',
    label: 'An Binh Commercial Joint Stock Bank (AB Bank)',
  },
  {
    value: 'ASCBVNVX',
    label: 'Asia Commercial Bank (ACB)',
  },
  {
    value: 'BVBVVNVX',
    label: 'Baoviet Joint Stock Commercial Bank (BVB)',
  },
  {
    value: 'PCBCVNVX',
    label: 'CHINA CONSTRUCTION BANK CORPORATION',
  },
  {
    value: 'EACBVNVX',
    label: 'Donga Bank',
  },
  {
    value: 'HDBCVNVX',
    label: 'Hochiminh City Development Joint Stock Commercial Bank',
  },
  {
    value: 'HLBBVNVX',
    label: 'Hong Leong Bank',
  },
  {
    value: 'IABBVNVX',
    label: 'Indovina Bank',
  },
  {
    value: 'IBKOVNVX',
    label: 'Industrial Bank Of Korea',
  },
  {
    value: 'BFTVVNVX',
    label: 'Joint Stock Commercial Bank For Foreign Trade Of Vietnam',
  },
  {
    value: 'BIDVVNVX',
    label: 'Joint Stock Commercial Bank for Investment and Development',
  },
  {
    value: 'KLBKVNVX',
    label: 'Kien Long Bank',
  },
  {
    value: 'LVBKVNVX',
    label: 'Lien Viet Post Bank',
  },
  {
    value: 'MSCBVNVX',
    label: 'Military Commercial Joint Stock Bank (MBBank)',
  },
  {
    value: 'NAMAVNVX',
    label: 'Nam A Bank',
  },
  {
    value: 'NVBAVNVX',
    label: 'National Citizen Commercial Joint Stock Bank',
  },
  {
    value: 'ORCOVNVX',
    label: 'ORIENT COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'OJBAVNVX',
    label: 'Ocean Commercial One Member Limited Liability Bank',
  },
  {
    value: 'PGBLVNVX',
    label: 'PETROLIMEX GROUP COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'SHBAVNVX',
    label: 'SAI GON-HA NOI COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'SBITVNVX',
    label: 'SAIGON BANK FOR INDUSTRY AND TRADE, THE',
  },
  {
    value: 'SACLVNVX',
    label: 'SAIGON COMMERCIAL BANK',
  },
  {
    value: 'SHBKVNVX',
    label: 'SHINHAN BANK VIETNAM',
  },
  {
    value: 'SEAVVNVX',
    label: 'SOUTHEAST ASIA COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'SGTTVNVX',
    label: 'Sacombank',
  },
  {
    value: 'TPBVVNVX',
    label: 'TIENPHONG COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'VIDPVNV5',
    label: 'VID PUBLIC BANK',
  },
  {
    value: 'VCBCVNVX',
    label: 'VIET CAPITAL COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'VNTTVNVX',
    label: 'VIET NAM THUONG TIN COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'VNACVNVX',
    label: 'VIETNAM ASIA COMMERCIAL JOINT STOCK BANK (VAB)',
  },
  {
    value: 'VBAAVNVXXXX',
    label: 'VIETNAM BANK FOR AGRIC. AND RURAL DEV.',
  },
  {
    value: 'VNIBVNVX',
    label: 'VIETNAM INTERNATIONAL COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'VPBKVNVX',
    label: 'VIETNAM PROSPERITY JOINT STOCK COMMERCIAL BANK',
  },
  {
    value: 'WBVNVNVX',
    label: 'VIETNAM PUBLIC JOINT STOCK COMMERCIAL BANK (PVCOMBANK)',
  },
  {
    value: 'VRBAVNVX',
    label: 'VIETNAM RUSSIA JOINT VENTURE BANK',
  },
  {
    value: 'VTCBVNVX',
    label: 'VIETNAM TECHNOLOGICAL AND COMMERCIAL JOINT STOCK BANK',
  },
  {
    value: 'GTBAVNVX',
    label: 'Vietnam Construction Joint Stock Commercial Bank',
  },
  {
    value: 'EBVIVNVX',
    label: 'Vietnam Export Import Commercial Joint-Stock Bank',
  },
  {
    value: 'ICBVVNVX',
    label: 'Vietnam Joint Stock Commercial Bank for Industry and Trade',
  },
  {
    value: 'MCOBVNVX',
    label: 'Vietnam Maritime Commercial Stock Bank',
  },
  {
    value: 'HVBKVNVX',
    label: 'Woori Bank Vietnam',
  },
  {
    value: 'ABBKVNVX',
    label: 'ABBank',
  },
  {
    value: 'ASCBVNVX',
    label: 'ACB',
  },
  {
    value: 'VBAAVNVXXXX',
    label: 'Agribank',
  },
  {
    value: 'BIDVVNVX',
    label: 'BIDV Bank',
  },
  {
    value: 'BVBVVNVX',
    label: 'BVB',
  },
  {
    value: 'EACBVNVX',
    label: 'Dong A Bank',
  },
  {
    value: 'EBVIVNVX',
    label: 'Eximbank',
  },
  {
    value: 'HDBCVNVX',
    label: 'HDBank',
  },
  {
    value: 'HLBBVNVX',
    label: 'HLBVN',
  },
  {
    value: 'KLBKVNVX',
    label: 'KienLongBank',
  },
  {
    value: 'LVBKVNVX',
    label: 'LienVietPostBank',
  },
  {
    value: 'MSCBVNVX',
    label: 'MBBank',
  },
  {
    value: 'MCOBVNVX',
    label: 'Maritime Bank',
  },
  {
    value: 'NVBAVNVX',
    label: 'NCB',
  },
  {
    value: 'VRBAVNVX',
    label: 'NH Lien Doanh Viet Nga',
  },
  {
    value: 'VBAAVNVXXXX',
    label: 'NH Nong Nghiep va Phat Trien Nong Thon Vietnam',
  },
  {
    value: 'VNTTVNVX',
    label: 'NH Vietnam Thuong Tin',
  },
  {
    value: 'GTBAVNVX',
    label: 'NH Xay Dung VN',
  },
  {
    value: 'ASCBVNVX',
    label: 'NHTMCP A Chau',
  },
  {
    value: 'ABBKVNVX',
    label: 'NHTMCP An Binh',
  },
  {
    value: 'VCBCVNVX',
    label: 'NHTMCP Ban Viet',
  },
  {
    value: 'BVBVVNVX',
    label: 'NHTMCP Bao Viet',
  },
  {
    value: 'LVBKVNVX',
    label: 'NHTMCP Buu Dien Lien Viet',
  },
  {
    value: 'ICBVVNVX',
    label: 'NHTMCP Cong Thuong Vietnam',
  },
  {
    value: 'WBVNVNVX',
    label: 'NHTMCP Dai Chung Vietnam',
  },
  {
    value: 'OJBAVNVX',
    label: 'NHTMCP Dai Duong',
  },
  {
    value: 'BIDVVNVX',
    label: 'NHTMCP Dau Tu va Phat Trien Vietnam',
  },
  {
    value: 'EACBVNVX',
    label: 'NHTMCP Dong A',
  },
  {
    value: 'SEAVVNVX',
    label: 'NHTMCP Dong Nam A',
  },
  {
    value: 'KLBKVNVX',
    label: 'NHTMCP Kien Long',
  },
  {
    value: 'VTCBVNVX',
    label: 'NHTMCP Ky Thuong Vietnam',
  },
  {
    value: 'BFTVVNVX',
    label: 'NHTMCP Ngoai Thuong Vietnam',
  },
  {
    value: 'HDBCVNVX',
    label: 'NHTMCP Phat Trien Thanh Pho HCM',
  },
  {
    value: 'ORCOVNVX',
    label: 'NHTMCP Phuong Dong',
  },
  {
    value: 'MSCBVNVX',
    label: 'NHTMCP Quan Doi',
  },
  {
    value: 'NVBAVNVX',
    label: 'NHTMCP Quoc Dan',
  },
  {
    value: 'VNIBVNVX',
    label: 'NHTMCP Quoc Te Vietnam',
  },
  {
    value: 'SHBAVNVX',
    label: 'NHTMCP Sai Gon Ha Noi',
  },
  {
    value: 'SACLVNVX',
    label: 'NHTMCP Saigon',
  },
  {
    value: 'SBITVNVX',
    label: 'NHTMCP Saigon Cong Thuong',
  },
  {
    value: 'SGTTVNVX',
    label: 'NHTMCP Saigon Thuong Tin',
  },
  {
    value: 'TPBVVNVX',
    label: 'NHTMCP Tien Phong',
  },
  {
    value: 'VPBKVNVX',
    label: 'NHTMCP Vietnam Thinh Vuong',
  },
  {
    value: 'PGBLVNVX',
    label: 'NHTMCP Xang Dau Petrolimex',
  },
  {
    value: 'EBVIVNVX',
    label: 'NHTMCP Xuat Nhap Khau Vietnam',
  },
  {
    value: 'HLBBVNVX',
    label: 'NHTNHH MTV Hongleong Vietnam',
  },
  {
    value: 'VIDPVNV5',
    label: 'NHTNHH MTV Public Vietnam',
  },
  {
    value: 'SHBKVNVX',
    label: 'NHTNHH MTV Shinhan Vietnam',
  },
  {
    value: 'VNACVNVX',
    label: 'Ngân hàng Thương mại Cổ phần Việt Á',
  },
  {
    value: 'ORCOVNVX',
    label: 'OCB',
  },
  {
    value: 'OJBAVNVX',
    label: 'OceanBank',
  },
  {
    value: 'PGBLVNVX',
    label: 'PG Bank',
  },
  {
    value: 'WBVNVNVX',
    label: 'PVcomBank',
  },
  {
    value: 'VIDPVNV5',
    label: 'Public Bank VN',
  },
  {
    value: 'SACLVNVX',
    label: 'SCB',
  },
  {
    value: 'SHBAVNVX',
    label: 'SHB',
  },
  {
    value: 'SGTTVNVX',
    label: 'Sacombank',
  },
  {
    value: 'SGTTVNVX',
    label: 'Saigon Thuong Tin Commercial Joint Stock Bank',
  },
  {
    value: 'SBITVNVX',
    label: 'Saigonbank',
  },
  {
    value: 'SEAVVNVX',
    label: 'SeABank',
  },
  {
    value: 'SHBKVNVX',
    label: 'ShinhanVN',
  },
  {
    value: 'SCBLVNVX',
    label: 'Standard Chartered Bank (Vietnam) Limited',
  },
  {
    value: 'TPBVVNVX',
    label: 'TPBank',
  },
  {
    value: 'VTCBVNVX',
    label: 'Techcombank',
  },
  {
    value: 'BFTVVNVX',
    label: 'VCB',
  },
  {
    value: 'VNIBVNVX',
    label: 'VIB',
  },
  {
    value: 'BFTVVNVX',
    label: 'VIETCOMBANK',
  },
  {
    value: 'VBAAVNVXXXX',
    label: 'VIETNAM BANK FOR AGRICULTURAL AND RURAL DEVELOPMENT',
  },
  {
    value: 'ICBVVNVX',
    label: 'VIETNAM JOINT STOCK COMMERCIAL BANK FOR INDUSTRY AND TRADE',
  },
  {
    value: 'VPBKVNVX',
    label: 'VPBank',
  },
  {
    value: 'VRBAVNVX',
    label: 'VRBank',
  },
  {
    value: 'VNACVNVX',
    label: 'VietABank',
  },
  {
    value: 'VNTTVNVX',
    label: 'VietBank',
  },
  {
    value: 'VCBCVNVX',
    label: 'Vietcapital Bank',
  },
  {
    value: 'ICBVVNVX',
    label: 'Vietin Bank',
  },
];
