// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneCreditCard = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} fillRule="evenodd" clipRule="evenodd" d="M1.25 11.35c0-.56 0-.84.1-1.05a1 1 0 0 1 .45-.44c.21-.11.49-.11 1.05-.11h18.3c.56 0 .84 0 1.05.1a1 1 0 0 1 .44.45c.11.21.11.49.11 1.05v4c0 2.24 0 3.36-.44 4.22a4 4 0 0 1-1.74 1.74c-.86.44-1.98.44-4.22.44h-8.7c-2.24 0-3.36 0-4.22-.44a4 4 0 0 1-1.74-1.74c-.44-.86-.44-1.98-.44-4.22v-4Z" /><path d="M6.05 2.25c-.74 0-1.11 0-1.43.05A4 4 0 0 0 1.3 5.62c-.05.32-.05.69-.05 1.43l.01.36a1 1 0 0 0 .83.83l.36.01h19.1l.36-.01a1 1 0 0 0 .83-.83l.01-.36c0-.74 0-1.11-.05-1.43a4 4 0 0 0-3.32-3.32c-.32-.05-.69-.05-1.43-.05H6.05Z" /><path d="M6 12.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5H6Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneCreditCard);
export { ForwardRef as IconV2DuotoneCreditCard };