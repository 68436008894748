import type { SyntheticEvent } from 'react';
import { Children, Fragment, cloneElement, forwardRef, useContext } from 'react';

import { isDev } from '@/src/helpers/general';

import PopoverContext from './PopoverContext';

type Props = {
  children: React.ReactElement;
  eventStopPropagation?: boolean;
};

type RefType = HTMLElement;

const PopoverTrigger = forwardRef<RefType, Props>(
  ({ children, eventStopPropagation = false }, ref) => {
    const { isOpen, togglePopover, targetRef } = useContext(PopoverContext);

    if (isDev() && children.type === Fragment) {
      console.error(
        '`PopoverTrigger` does not support React fragments as children. It is recommended to use a single `Button` component.'
      );
    }

    return Children.only(
      cloneElement(children, {
        'aria-pressed': isOpen,
        style: { display: 'flex' },
        onClick: (event: SyntheticEvent) => {
          if (eventStopPropagation) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
          }
          togglePopover();
          if (children.props.onClick) {
            children.props.onClick();
          }
        },
        ref: (node: RefType | null) => {
          if (ref) {
            if (typeof ref === 'function') ref(node);
            else ref.current = node;
          }
          targetRef.current = node;
        },
      })
    );
  }
);

export default PopoverTrigger;
