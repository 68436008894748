import { Box, Stack, Text } from '@remote-com/norma';
import type { ReactNode } from 'react';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import type { Href } from './PageHeader';

const calculateMarginSubtitle = (
  theme: DefaultTheme,
  $href?: Href | string,
  $logo?: ReactNode
): number => {
  if ($href) {
    return theme.space[8];
  }

  if ($logo) {
    return theme.space[9];
  }

  return 0;
};

export const PageTitle = styled(Text).attrs(({ theme }) => ({
  forwardedAs: 'h1',
  color: theme.colors.grey[900],
  fontFamily: 'brand',
  variant: ['xlMedium', '2xlMedium'],
}))``;

export const PageSubtitle = styled(Text).attrs(({ theme }) => ({
  color: theme.colors.grey[600],
  variant: ['xs', 'sm'],
}))``;

export const WrapperSubtitleBreadcrumbs = styled(Box)<{
  $logo?: ReactNode;
  $href?: Href | string;
}>`
  margin-left: ${({ theme, $logo, $href }) => `${calculateMarginSubtitle(theme, $href, $logo)}px`};
`;

export const Header = styled(Stack).attrs({ as: 'header' })`
  margin-bottom: ${({ theme }) => `${theme.space[6]}px`};
  background: transparent;
`;

export const HeaderPageTitleWrapper = styled(Stack).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxHeight: '32px',
})``;
