// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2SolidLock = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M11.8 1.25h-.03c-.81 0-1.47 0-2 .04-.55.05-1.03.14-1.47.37-.7.36-1.28.93-1.64 1.64-.23.44-.32.92-.37 1.47-.04.53-.04 1.19-.04 2v.52a4.5 4.5 0 0 0-1.82.4A4 4 0 0 0 2.7 9.43c-.44.86-.44 1.98-.44 4.22v2.7c0 2.24 0 3.36.44 4.22a4 4 0 0 0 1.74 1.74c.86.44 1.98.44 4.22.44h6.7c2.24 0 3.36 0 4.22-.44a4 4 0 0 0 1.74-1.74c.44-.86.44-1.98.44-4.22v-2.7c0-2.24 0-3.36-.44-4.22a4 4 0 0 0-1.74-1.74 4.5 4.5 0 0 0-1.82-.4v-.52c0-.81 0-1.47-.04-2a3.84 3.84 0 0 0-.37-1.47 3.75 3.75 0 0 0-1.64-1.64 3.85 3.85 0 0 0-1.47-.37c-.53-.04-1.19-.04-2-.04H11.8Zm4.45 6V6.8c0-.85 0-1.45-.04-1.9a2.4 2.4 0 0 0-.2-.92 2.25 2.25 0 0 0-.99-.98 2.4 2.4 0 0 0-.91-.21c-.46-.04-1.06-.04-1.91-.04h-.4c-.85 0-1.45 0-1.9.04-.46.04-.72.1-.92.2-.42.22-.77.57-.98.99-.1.2-.17.46-.21.91-.04.46-.04 1.06-.04 1.91v.45h8.5Zm-4.25 6c.41 0 .75.34.75.75v2a.75.75 0 0 1-1.5 0v-2c0-.41.34-.75.75-.75Z" /></svg>;
const ForwardRef = forwardRef(IconV2SolidLock);
export { ForwardRef as IconV2SolidLock };