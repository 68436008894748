import { IconV2OutlineBell } from '@remote-com/norma/icons/IconV2OutlineBell';
import { IconV2OutlineCheckSquare } from '@remote-com/norma/icons/IconV2OutlineCheckSquare';

export const UPDATE_TYPES = {
  NOTIFICATIONS: 'notifications',
  TODOS: 'todos',
} as const;

export const OVERLAY_BUTTON_ICONS = {
  [UPDATE_TYPES.NOTIFICATIONS]: IconV2OutlineBell,
  [UPDATE_TYPES.TODOS]: IconV2OutlineCheckSquare,
} as const;
