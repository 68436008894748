/**
 * It's common to refer to some countries with acronyms or synonyms
 * This list aims to bypass the BE limitation and handles the missing ones for a better UX
 */
export const countryNameWithAlternatives = {
  US: ['US', 'USA', 'United States', 'United States (US)', 'States'],
  UK: [
    'United Kingdom',
    'United Kingdom (UK)',
    'Kingdom', // If users start their search by typing Kingdom
    'UK',
    'England',
    'Great Britain',
    'Britain', // If users start their search by typing Britain
    'GB',
    'Scotland',
    'Wales',
    'Northern Ireland',
  ],
  UAE: [
    'UAE',
    'United Arab Emirates',
    'United Arab Emirates (UAE)',
    'Arab Emirates',
    'Emirates', // If users start their search by typing Emirates
  ],
  NZ: ['New Zealand', 'Zealand', 'NZ'],
  ASM: ['American Samoa', 'Samoa'],
  ATG: ['Antigua and Barbuda', 'Barbuda'],
  BIH: ['Bosnia and Herzegovina', 'Herzegovina'],
  VGB: ['British Virgin Islands', 'Virgin Islands'],
  COD: ['Democratic Republic of the Congo', 'Republic of the Congo', 'Congo'],
  SLV: ['El Salvador', 'Salvador'],
  GNQ: ['Equatorial Guinea', 'Guinea'],
  FLK: ['Falkland Islands (Malvinas)', 'Malvinas'],
  GUF: ['French Guiana', 'Guiana'],
  PYF: ['French Polynesia', 'Polynesia'],
  NCL: ['New Caledonia', 'Caledonia'],
  MKD: ['North Macedonia', 'Macedonia'],
  COG: ['Republic of the Congo', 'Congo'],
  SHN: ['Saint Helena', 'Helena'],
  KNA: ['Saint Kitts and Nevis', 'Kitts and Nevis', 'Nevis'],
  LCA: ['Saint Lucia', 'Lucia'],
  SPM: ['Saint Pierre and Miquelon', 'Pierre and Miquelon', 'Miquelon'],
  VCT: ['Saint Vincent and the Grenadines', 'Vincent and the Grenadines', 'Grenadines'],
  SMR: ['San Marino', 'Marino'],
  STP: ['Sao Tome and Principe', 'Principe'],
  SAU: ['Saudi Arabia', 'Arabia'],
  ZAF: ['South Africa', 'Africa'],
  KOR: ['South Korea', 'Korea'],
  SSD: ['South Sudan', 'Sudan'],
  TTO: ['Trinidad and Tobago', 'Tobago'],
  TCA: ['Turks and Caicos Islands', 'Caicos Islands'],
  VIR: ['U.S. Virgin Islands', 'Virgin Islands'],
  WLF: ['Wallis and Futuna Islands', 'Futuna Islands'],
};

export const territoryToCountry: Record<string, string> = {
  'French Guiana': 'France',
  'French Polynesia': 'France',
  Guadeloupe: 'France',
  Mayotte: 'France',
  'New Caledonia': 'France',
  Réunion: 'France',
  'Saint Pierre and Miquelon': 'France',
  'Wallis and Futuna Islands': 'France',
};

export const PHONE_NUMBER_SECTION_LABEL = {
  COUNTRY_CODE: 'Country Code',
  PHONE_NUMBER: 'Phone number',
};
