// Notification types from spreadsheet
// https://docs.google.com/spreadsheets/d/1W2X9D6Yiptx2rRI4U2uTnr6f1S9_F4aOgIQ1iFHO3dY/edit#gid=0
export const IN_APP_NOTIFICATION_TYPES = {
  TWO_FACTOR_SETUP: 'two_factor_setup',
  BENEFITS_EMPLOYEE_ALLIANZ: 'benefits_employee_allianz',
  EXPLORE_DOCUMENT_MANAGEMENT: 'explore_document_management',
  CUSTOM_NOTIFICATION: 'custom_notification',
  SET_BILLING_CONTACTS: 'set_billing_contacts',
  SSO_CONFIG_ENABLED: 'sso_config_enabled',
  SSO_DOMAINS_VERIFIED: 'sso_domains_verified',
  TERMS_OF_SERVICE_UPDATED: 'terms_of_service_updated',
  CONTRACTOR_INVOICE_APPROVED_CONTRACTOR: 'contractor_invoice_approved_contractor',
  CAREER_DEVELOPMENT_FEEDBACK_RECEIVED: 'career_development_feedback_received',
  COMPANY_FEEDBACK_EMPLOYER_NEW_FEEDBACK: 'company_feedback_employer_new_feedback',
  COMPANY_FEEDBACK_EMPLOYER_NEW_REPLY: 'company_feedback_employer_new_reply',
  COMPANY_FEEDBACK_EMPLOYEE_NEW_REPLY: 'company_feedback_employee_new_reply',
  CONTRACT_DOCUMENT_AWAITING_VIEW: 'contract_document_awaiting_view',
  CONTRACT_DOCUMENT_SIGNED: 'contract_document_signed',
  CONTRACTOR_INVOICE_EXTERNALLY_PAID: 'contractor_invoice_externally_paid',
  CONTRACTOR_INVOICE_REJECTED: 'contractor_invoice_rejected',
  CONTRACTOR_BLOCKED_BY_NIUM_ONBOARDING: 'contractor_blocked_by_nium_onboarding',
  CONTRACTOR_CAN_NOW_ADD_NIUM_WITHDRAWAL_METHODS: 'contractor_can_now_add_nium_withdrawal_methods',
  CONTRACTOR_SUBSCRIPTION_COSTS: 'contractor_subscription_costs',
  EMPLOYER_EMPLOYEE_WITH_SKIPPED_FIELDS: 'employer_employee_with_skipped_fields',
  EARLY_PAYROLL_CUTOFF: 'early_payroll_cutoff',
  EMPLOYEE_EXTERNAL_BENEFITS_AVAILABLE: 'employee_external_benefits_available',
  EMPLOYEE_HEALTH_INSURANCE_POLICY_CANCELLED: 'employee_health_insurance_policy_cancelled',
  EMPLOYEE_HEALTH_INSURANCE_POLICY_EXPIRED: 'employee_health_insurance_policy_expired',
  EMPLOYEE_PROBATION_ENDING_REMINDER: 'employee_probation_ending_reminder',
  EMPLOYMENT_VERIFICATION_LETTER_DOCUMENT: 'employment_verification_letter_document',
  INACTIVE_CONTRACTORS_DEACTIVATED: 'inactive_contractors_deactivated',
  MARKETING_CAMPAIGN: 'marketing_campaign',
  COST_CALCULATOR_AVAILABLE: 'cost_calculator_available',
  DIRECT_EMPLOYEE_COMPLETED_ONBOARDING: 'direct_employee_completed_onboarding',
  FIRST_PAYMENT_INFO: 'first_payment_info',
  GLOBAL_PAYROLL_EMPLOYER_ACCOUNT_PENDING: 'global_payroll_employer_account_pending',
  PAYROLL_LEGAL_ENTITY_REFUND_ISSUED: 'payroll_legal_entity_refund_issued',
  CONTRACTOR_INVOICE_PAID: 'contractor_invoice_paid',
  CONTRACTOR_INVOICE_PAYOUT_REPROCESSED: 'contractor_invoice_payout_reprocessed',
  EMPLOYER_PROBATION_ENDING_REMINDER: 'employer_probation_ending_reminder',
  GENERIC_EMPLOYMENT_DOCUMENT_AWAITING_VIEW: 'generic_employment_document_awaiting_view',
  EMPLOYER_MANAGED_DOCUMENT_AWAITING_VIEW: 'employer_managed_document_awaiting_view',
  DELETED_AUTO_PAYMENT: 'deleted_auto_payment',
  DECLINED_EXPENSE: 'declined_expense',
  NEW_TAX_DOCUMENT_1042_S: 'new_tax_document_1042_S',
  NEW_TAX_DOCUMENT_1099_NEC_EMPLOYER: 'new_tax_document_1099_nec_employer',
  NEW_TAX_DOCUMENT_1099_NEC_NON_EMPLOYEE: 'new_tax_document_1099_nec_non_employee',
  ONBOARDING_RESERVE_INVOICE_PENDING: 'onboarding_reserve_invoice_pending',
  ONBOARDING_RESERVE_PAYMENT_PROCESSING: 'onboarding_reserve_payment_processing',
  ONBOARDING_RESERVE_PAID: 'onboarding_reserve_paid',
  PAYSLIP: 'payslip',
  PAYROLL_RUN_CREATED: 'payroll_run_created',
  EMPLOYER_PAYROLL_RUN_CREATED: 'employer_payroll_run_created',
  PAYROLL_RUN_INPUTS_SUBMISSION_CUTOFF_DATE: 'payroll_run_inputs_submission_cutoff_date',
  ONBOARDING_RESERVE_REQUIRED: 'onboarding_reserve_required',
  REMOTE_MARKETPLACE_ANNOUNCEMENT: 'remote_marketplace_announcement',
  REMOTE_WORK_REQUEST_APPROVED: 'remote_work_request_approved',
  REMOTE_WORK_REQUEST_DECLINED_BY_MANGER: 'remote_work_request_declined_by_manager',
  REMOTE_WORK_REQUEST_DECLINED_BY_REMOTE: 'remote_work_request_declined_by_remote',
  TIMEOFF_UPDATED: 'timeoff_updated',
  AUTOMATIC_TIMEOFF_HOLIDAY_CREATED: 'automatic_timeoff_holiday_created',
  TRAVEL_LETTER_REQUEST_DECLINED_BY_REMOTE: 'travel_letter_request_declined_by_remote',
  TRAVEL_LETTER_REQUEST_DECLINED_BY_MANAGER: 'travel_letter_request_declined_by_manager',
  TRAVEL_LETTER_REQUEST_APPROVED: 'travel_letter_request_approved',
  US_SECTION_125_NOTICE: 'us_section_125_notice',
  PRE_HIRE_DETAILS_REQUEST_DECLINED: 'pre_hire_details_request_declined',
  TIMESHEET_WITH_OVERTIME_APPROVED: 'timesheet_with_overtime_approved',
  TIMESHEET_SENT_BACK_UPDATE: 'timesheet_sent_back_update',
  EXPIRING_DOCUMENT_ANNOUNCEMENT: 'expiring_document_announcement',
  EXPIRING_DOCUMENT_UNRESPONSIVE: 'expiring_document_unresponsive',
  AT_RISK: 'at_risk', // Admin notification
  MOBILITY_ASSISTANCE_STATUS_SOCIAL_SECURITY_NUMBER_APPLICATION_SUBMITTED:
    'mobility_assistance_status_social_security_number_application_submitted',
  MOBILITY_ASSISTANCE_STATUS_SOCIAL_SECURITY_NUMBER_APPLICATION_ISSUE_RESOLUTION:
    'mobility_assistance_status_social_security_number_application_issue_resolution',
  MOBILITY_ASSISTANCE_STATUS_PROCESS_COMPLETED: 'mobility_assistance_status_process_completed',
  MOBILITY_ASSISTANCE_STATUS_PROCESS_CANCELED: 'mobility_assistance_status_process_canceled',
  COST_APPROVAL_REQUEST_APPROVED: 'cost_approval_request_approved', // Admin notification
  COST_APPROVAL_REQUEST_DECLINED: 'cost_approval_request_declined', // Admin notification
  CONTRACTOR_FREE_TRIAL_AVAILABLE: 'contractor_free_trial_available',
  COMPLIANCE_PROFILE_REFERRED: 'compliance_profile_referred',
  INVITE_CONTRACTOR_TO_COMPANY: 'invite_contractor_to_company',
  LEGAL_ENTITY_NEW_STATE_TAX_FORM: 'legal_entity_new_state_tax_form',
  REJECTED_LEGAL_ENTITY_DOCUMENT: 'rejected_legal_entity_document',
  REMOTE_PAYMENTS_SETUP_APPROVED: 'remote_payments_setup_approved',
  REMOTE_PAYMENTS_SETUP_PENDING: 'remote_payments_setup_pending',
  EOR_ANNUAL_PLAN_START: 'eor_annual_plan_start',
  MISSING_TIMESHEET: 'missing_timesheet',
  PAYROLL_CUSTOMER_GROSS_TO_NET_REPORT_READY: 'payroll_customer_gross_to_net_report_ready',
  CONTRACT_DOCUMENT_NEW_COMMENT: 'contract_document_new_comment',
  CONTRACT_DOCUMENT_NEW_REPLY_TO_COMMENT: 'contract_document_new_reply_to_comment',
  PROJECT_MANAGEMENT_ROLE_ASSIGNED: 'project_management_role_assigned',
  PROJECT_LEAD_ASSIGNED: 'project_lead_assigned',
  PROJECT_MEMBER_ASSIGNED: 'project_member_assigned',
  // -- START of notifications that are deprecated but still exist in Tiger/DB so we have minimal support for them
  ADD_NEW_EMPLOYEE: 'add_new_employee',
  AUTOMATED_BILLING_UPDATE: 'automated_billing_update',
  CONTRACTOR_MANAGEMENT_LAUNCH: 'contractor_management_launch',
  DEPOSIT_NOT_REQUIRED: 'deposit_not_required',
  EMPLOYEE_INVOICES_PAGE_RENAME: 'employee_invoices_page_rename',
  INCENTIVES_FEATURE: 'incentives_feature',
  INTEGRATIONS_GREENHOUSE_NEW: 'integrations_greenhouse_new',
  NEW_BILLING_PROCESS_MARCH_2022: 'new_billing_process_march_2022',
  REMIND_COMPLETE_COMPANY_SIGNUP: 'remind_complete_company_signup',
  NEW_TAX_DOCUMENT_1099_NEC_CONTRACTOR: 'new_tax_document_1099_nec_contractor',

  REMOTE_RELOCATION_UPDATE: 'remote_relocation_update',
  REMOTE_UNICORN_UPDATE: 'remote_unicorn_update',
  SEPA_DEBITOR_CHANGES: 'sepa_debitor_changes',
  // -- END of deprecated notifications
} as const;
