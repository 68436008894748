import { Box, Badge } from '@remote-com/norma';
import { useField } from 'formik';
import { useMemo, useEffect } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';

import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import {
  employeeInvoiceStatusLabels,
  employeeInvoiceStatusBadges,
} from '@/src/domains/invoices/constants';
import { useGetInvoiceReportDetails } from '@/src/domains/invoices/queries';
import { getDataWithRenamedProperty } from '@/src/helpers/api';

function getInvoiceLabel(props) {
  const { formattedNumber, slug, status } = props;

  const shortSlug = slug.substring(0, 6).toUpperCase();
  return (
    <Box display="flex" alignItems="center">
      <Box mr="2">
        <Badge
          type={employeeInvoiceStatusBadges[status]}
          label={employeeInvoiceStatusLabels[status]}
        />
      </Box>
      {` - ${shortSlug}`}
      {formattedNumber && ` - ${formattedNumber}`}
    </Box>
  );
}

export function InvoiceSelector({ filters = {}, description, initialOptions, ...props }) {
  const theme = useTheme();
  const [{ value }] = useField(props.name);
  // This useMemo makes it so that this value is only the initialValue
  // due to the way this component is used, it doesn't make use of the
  // initialValue on the field, so we use this to get the first selected
  // value from edit forms
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValue = useMemo(() => value, []);
  const enabledInitialInvoiceFetch = !!initialValue && initialValue !== 'none';
  const { isLoading, data: selectedInvoice } = useGetInvoiceReportDetails({
    slug: initialValue,
    options: {
      enabled: enabledInitialInvoiceFetch,
    },
  });

  // When we pass non-existent slug we might end up in never ending fetch loop
  // Let's call onChange with null when nothing's find in API
  useEffect(() => {
    if (!isLoading && !selectedInvoice && enabledInitialInvoiceFetch && props.onChange) {
      props.onChange(null);
    }
  }, [enabledInitialInvoiceFetch, isLoading, props, selectedInvoice]);

  const defaultOptions = selectedInvoice && enabledInitialInvoiceFetch ? [selectedInvoice] : [];

  return (
    <ThemeProvider theme={{ withSearchIcon: theme.variant === 'outline' }}>
      <PaginatedSearchSelectField
        query={{
          path: '/api/v1/rivendell/invoice-reports',
          params: {
            queryParams: filters,
          },
          options: {
            select: (res) => getDataWithRenamedProperty(res, 'invoiceReports'),
          },
        }}
        searchQueryAlias="number"
        label="Invoice"
        placeholder="Select an invoice "
        loadingMessage={() => 'Loading invoices…'}
        getOptionLabel={getInvoiceLabel}
        getOptionValue={({ slug }) => slug}
        description={description}
        initialOptions={initialOptions}
        {...props}
        defaultOptions={defaultOptions}
        disableReactSelectSearch
        data-testid="invoice-select-field"
      />
    </ThemeProvider>
  );
}
