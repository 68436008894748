// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneShieldCheck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} fillRule="evenodd" clipRule="evenodd" d="M2.25 8.67c0-1.5 0-2.26.24-2.91a4 4 0 0 1 1-1.54c.5-.48 1.18-.79 2.56-1.4L9.4 1.33a8.6 8.6 0 0 1 1.93-.72 4 4 0 0 1 1.34 0c.5.09.97.3 1.93.72l3.35 1.5c1.38.6 2.06.91 2.57 1.4a4 4 0 0 1 1 1.53c.23.65.23 1.4.23 2.91v5.3c0 1.26 0 1.88-.17 2.45a4 4 0 0 1-.74 1.38c-.38.46-.9.8-1.94 1.5l-2.46 1.64c-1.6 1.07-2.4 1.6-3.27 1.81a5 5 0 0 1-2.34 0c-.86-.2-1.66-.74-3.27-1.8L5.1 19.3c-1.04-.7-1.56-1.04-1.94-1.5a4 4 0 0 1-.74-1.38c-.17-.57-.17-1.2-.17-2.44v-5.3Z" /><path fillRule="evenodd" clipRule="evenodd" d="M16.03 8.97c.3.3.3.77 0 1.06l-4.5 4.5c-.3.3-.77.3-1.06 0l-2-2a.75.75 0 0 1 1.06-1.06L11 12.94l3.97-3.97c.3-.3.77-.3 1.06 0Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneShieldCheck);
export { ForwardRef as IconV2DuotoneShieldCheck };