import type { FieldsetProps } from '@remote-com/norma';
import { Fieldset } from '@remote-com/norma';
import type { ReactElement } from 'react';
import type { Except } from 'type-fest';

import { useFieldsetFieldErrors } from './helpers';

// TypeScript's Omit is intentionally loosely typed for backwards compatibility.
// Except is a more strict version of Omit that uses keyof T.
// See: https://github.com/microsoft/TypeScript/issues/30825
type Props = Except<FieldsetProps, 'errorMessage'>;

/**
 * FieldsetField is aware of formik errors.
 * The field is identified by "props.name".
 *
 * For the underlying Fieldset component that is not aware of formik,
 * see norma/Fieldset.
 */
export function FieldsetField(props: Props): ReactElement {
  const errorMessage = useFieldsetFieldErrors(props.name || '');

  return <Fieldset {...props} errorMessage={errorMessage} />;
}
