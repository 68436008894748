import type { GetResponse } from '@remote-com/data-layer';
import { Box, Stack } from '@remote-com/norma';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
} from 'chart.js';
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';

import Tab from '@/src/components/Tabs/Tab';
import Tabs from '@/src/components/Tabs/Tabs';
import RemoteLogoLoader from '@/src/components/Ui/Loader/RemoteLoader';
import { WidgetCard } from '@/src/components/WidgetCard';
import ChartContainer from '@/src/domains/reports/components/ReportWidgets/ChartContainer';
import ChartLegend from '@/src/domains/reports/components/ReportWidgets/ChartLegend/ChartLegend';
import { WorkforceBreakdownSummary } from '@/src/domains/reports/components/ReportWidgets/WorkforceBreakdownWidget/WorkforceBreakdownSummary';

import { useChartData } from './useChartData';

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartTitle, Tooltip);

export const BREAKDOWN_CATEGORIES = ['active', 'onboarding', 'inactive'] as const;

type WorkforceBreakdownWidgetProps = {
  data: GetResponse<'/api/v1/reports/people/[slug]'>['data'];
  isLoading: boolean;
};

const WorkforceBreakdownWidget = ({ data, isLoading }: WorkforceBreakdownWidgetProps) => {
  const { chartDataMap, chartOptions, colors } = useChartData({ data });
  const [selectedTab, setSelectedTab] = useState('');

  if (isLoading) {
    return (
      <WidgetCard title="">
        <RemoteLogoLoader $space="xl" />
      </WidgetCard>
    );
  }

  if (!data || !data.breakdowns || !data.summary) {
    return null;
  }

  const { breakdowns, summary } = data;
  const validBreakdowns = breakdowns
    ? Object.entries(breakdowns).filter(([, breakdown]) => breakdown.data.length > 0)
    : [];

  const currentTab = (selectedTab || validBreakdowns[0]?.[0]) ?? '';

  const legendItems = summary
    ? BREAKDOWN_CATEGORIES.map((key) => ({
        color: colors[key],
        label: summary[key].label,
      }))
    : [];

  const chartXLabel = (breakdownType: string) => {
    return breakdowns?.[breakdownType]?.label === 'Tenure'
      ? 'Years'
      : breakdowns?.[breakdownType]?.label;
  };

  return (
    <WidgetCard
      title={data.title}
      tabs={
        <Tabs value={currentTab} onClick={(_, newTab) => setSelectedTab(newTab)} fullWidth>
          {validBreakdowns
            .sort((a, b) => a[1].label.localeCompare(b[1].label))
            .map(([key, { label }]) => (
              <Tab key={key} value={key}>
                {label}
              </Tab>
            ))}
        </Tabs>
      }
    >
      <Stack direction="row" gap={6}>
        <Box flex={1}>
          <WorkforceBreakdownSummary summary={data.summary} />
        </Box>
        <Stack flex={3}>
          {/* @ts-expect-error */}
          <ChartLegend items={legendItems} />
          {Object.entries(chartDataMap).map(
            ([breakdownType, chartData]) =>
              chartData && (
                <Box
                  key={breakdownType}
                  style={{ display: currentTab === breakdownType ? 'block' : 'none' }}
                >
                  <ChartContainer yLabel="Headcount" xLabel={chartXLabel(breakdownType)}>
                    <Bar data={chartData} options={chartOptions} />
                  </ChartContainer>
                </Box>
              )
          )}
        </Stack>
      </Stack>
    </WidgetCard>
  );
};

export default WorkforceBreakdownWidget;
