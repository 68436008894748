// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineArrowUp = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M19.53 11.53c-.3.3-.77.3-1.06 0l-5.72-5.72V20a.75.75 0 1 1-1.5 0V5.81l-5.72 5.72a.75.75 0 1 1-1.06-1.06l7-7a.75.75 0 0 1 1.06 0l7 7c.3.3.3.77 0 1.06Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineArrowUp);
export { ForwardRef as IconV2OutlineArrowUp };