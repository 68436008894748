import styled from 'styled-components';

import { Text } from '../../../core/text';
import { Stack } from '../../../layout';

export const Wrapper = styled(Stack).attrs({
  forwardedAs: 'span',
  direction: 'row',
  gap: 3,
})``;

export const Count = styled(Text).attrs({ as: 'span' })`
  font-variant-numeric: tabular-nums;
  margin-left: auto;
`;
