import styled from 'styled-components';

import { INPUT_STATES, WHITE } from './styles';

export const Input = styled.input.attrs({ type: 'radio' })<{
  checked?: boolean;
}>`
  appearance: none;
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 2px solid;

  cursor: pointer;
  background-color: ${WHITE};

  /**
   * focus is the only state that needs to be implemented directly on the input
   * all the other states are implement in the parent (Label)
   */
  &:focus {
    ${({ checked }) => (checked ? INPUT_STATES.focus.checked : INPUT_STATES.focus.resting)}
  }

  &::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: ${({ theme }) => theme.borderRadius.full};
    background-color: currentColor;
  }
`;
