import { format } from 'date-fns';
import type { ComponentProps } from 'react';
import { useState, useRef, useEffect } from 'react';
import type { Except } from 'type-fest';

import { convertLocalTimeStringToDateObj } from '../../foundations/date';

import { DatePickerRangeContainer } from './calendarContainers';
import ReactDatePickerWrapper from './ReactDatePickerWrapper';

type InternalProps = ComponentProps<typeof ReactDatePickerWrapper>;

type LooseDate = number | string | Date | null;

type Props = Except<
  InternalProps,
  'selected' | 'cancelCallback' | 'saveCallback' | 'onChange' | 'dateFormat'
> & {
  selected: [LooseDate, LooseDate];
  onChange?: (date: { start: string | null; end: string | null }) => void;
  dateFormat: string;
};

type DateState = [Date | null, Date | null];

export function DatePickerRange({ onChange, onCalendarClose, selected, ...props }: Props) {
  const initialValues = useRef<DateState>([
    convertLocalTimeStringToDateObj(selected[0]),
    convertLocalTimeStringToDateObj(selected[1]),
  ]);
  const [selectedValue, setSelectedValue] = useState<DateState>(initialValues.current);

  const [startDate, endDate] = selectedValue;

  useEffect(() => {
    setSelectedValue([
      convertLocalTimeStringToDateObj(selected[0]),
      convertLocalTimeStringToDateObj(selected[1]),
    ]);
  }, [selected]);

  function calendarOpenCallback() {
    initialValues.current = [
      convertLocalTimeStringToDateObj(selected[0]),
      convertLocalTimeStringToDateObj(selected[1]),
    ];
  }

  function handleChange(newDates: DateState) {
    const [start, end] = newDates;

    setSelectedValue(newDates);
    if (onChange) {
      onChange({
        start: start && format(start, props.dateFormat),
        end: end && format(end, props.dateFormat),
      });
    }
  }

  function cancelCallback() {
    handleChange(initialValues.current);
  }

  function saveCallback() {
    // Handle single date picking
    if (selectedValue[0] && !selectedValue[1]) {
      handleChange([selectedValue[0], selectedValue[0]]);
    } else if (!selectedValue[0]) {
      handleChange(initialValues.current);
    }
  }

  function calendarCloseCallback() {
    onCalendarClose?.();
    saveCallback();
  }

  function formatInputValue(value: string) {
    const dates = value.split(' - ');

    if (dates.length > 1 && dates[0] !== dates[1] && dates[1]) {
      return value.replace(' - ', ' to ');
    }
    if (dates[0] === dates[1]) return dates[0];

    return value.replace(/ - $/, '');
  }

  return (
    <ReactDatePickerWrapper
      {...({
        selectsRange: true,
        onChange: handleChange,
        // See the note at ReactDatePickerWrapper's Props
      } as any)}
      calendarContainer={DatePickerRangeContainer}
      cancelCallback={cancelCallback}
      saveCallback={saveCallback}
      calendarOpenCallback={calendarOpenCallback}
      onCalendarClose={calendarCloseCallback}
      inputValueFormatter={formatInputValue}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      {...props}
    />
  );
}
