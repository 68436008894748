import { HOURS_IN_DAY, MINUTES_IN_HOUR, NUMBER_OF_DIGITS_PER_TIME_SEGMENT } from './constants';

/**
 * Strips all characters that are not numerical digits
 * @param {string} str
 * @returns {string}
 */
function stripNonDigitCharacters(str) {
  return str.replace(/\D/g, '');
}

/**
 * Parse 24 hour day time string to hours and minutes
 * @param {string} timeStr The time in string form e.g., `"13:30"`
 * @returns {{hours: number, minutes: number}}
 */
export function parseDayTime(timeStr) {
  let [hoursStr = '', minutesStr = ''] = timeStr.split(':');

  hoursStr = stripNonDigitCharacters(hoursStr);
  minutesStr = stripNonDigitCharacters(minutesStr);

  if (hoursStr.length < NUMBER_OF_DIGITS_PER_TIME_SEGMENT) {
    hoursStr = hoursStr.padStart(NUMBER_OF_DIGITS_PER_TIME_SEGMENT, '0');
  }

  if (minutesStr.length < NUMBER_OF_DIGITS_PER_TIME_SEGMENT) {
    minutesStr = minutesStr.padEnd(NUMBER_OF_DIGITS_PER_TIME_SEGMENT, '0');
  }

  const hours = Math.min(parseInt(hoursStr, 10), HOURS_IN_DAY - 1);
  const minutes = Math.min(parseInt(minutesStr, 10), MINUTES_IN_HOUR - 1);

  return {
    hours,
    minutes,
  };
}
