import PayElementName from './PayElementName';

export type Props = {
  value: string;
  row: { original: { archivedAt?: string; legalEntityPayElement: { description?: string } } };
  withArchivedPill?: boolean;
};

export default function EmployeePayElementNameCell({ value, row, withArchivedPill = true }: Props) {
  const {
    archivedAt,
    legalEntityPayElement: { description },
  } = row.original;

  return (
    <PayElementName
      name={value}
      archivedAt={archivedAt}
      description={description}
      withArchivedPill={withArchivedPill}
    />
  );
}
