import { useRouter } from 'next/router';

import { useUser } from '@/src/components/UserProvider/context';
import {
  ADMIN_BILLING_ROUTE,
  ADMIN_INVOICES_ROUTE,
  ADMIN_COMPANIES_PROFILE_ROUTE,
  BILLING_ROUTE,
} from '@/src/constants/routes';
import { billingTabs } from '@/src/domains/billing/constants';
import {
  useBillingTableStorageKey,
  useBillingDocumentsTableStorageKey,
} from '@/src/domains/billing/shared/hooks/useBillingTableStorageKey';
import { getFormattedURL } from '@/src/helpers/general';
import { getLocalStorageItem } from '@/src/helpers/localStorage';

import { useInvoiceTableStorageKey } from './useInvoiceTableStorageKey';

const adminRoute = {
  billing: ADMIN_BILLING_ROUTE,
  companyScopedBilling: ADMIN_COMPANIES_PROFILE_ROUTE,
  invoices: ADMIN_INVOICES_ROUTE,
};

export const useInvoiceDetailsPreviousPage = () => {
  const router = useRouter();

  const { userIsAdmin } = useUser();

  const isCompanyScoped = userIsAdmin && router.query.company === 'true';

  const invoiceTableStorageKey = useInvoiceTableStorageKey();
  const billingTableStorageKey = useBillingTableStorageKey({ isCompanyScoped });
  const billingDocumentsTableStorageKey = useBillingDocumentsTableStorageKey({ isCompanyScoped });

  const invoicesTableState = getLocalStorageItem(invoiceTableStorageKey, {});
  const billingTableState = getLocalStorageItem(billingTableStorageKey, {});
  const billingDocumentsTableState = getLocalStorageItem(billingDocumentsTableStorageKey, {});

  if (isCompanyScoped) {
    const { company, ...companyScopedBillingTableState } = billingDocumentsTableState;

    const formattedURL = getFormattedURL(
      adminRoute.companyScopedBilling,
      {
        '[slug]': company,
      },
      {
        ...companyScopedBillingTableState,
        selectedTab: 'billing',
      }
    ) as string;

    return {
      hrefTitle: 'Back to billing',
      href: formattedURL,
    };
  }

  if (userIsAdmin) {
    /**
     * User reached invoice breakdown through (legacy) Billing -> View invoice breakdown table action
     */
    const hasBillingQueryParam = router.query.billing === 'true';

    if (hasBillingQueryParam) {
      return {
        hrefTitle: 'Back to billing',
        href: getFormattedURL(
          adminRoute.billing,
          {},
          {
            ...billingTableState,
            selectedTab: billingTabs.BILLING_LEGACY,
          }
        ) as string,
      };
    }

    /**
     * User reached details page through `Customers invoice (old)` tab
     */
    const hasCustomerInvoicesQueryParam = router.query.customerInvoices === 'true';

    return hasCustomerInvoicesQueryParam
      ? {
          hrefTitle: 'Back to invoices',
          href: getFormattedURL(
            adminRoute.billing,
            {},
            { ...invoicesTableState, selectedTab: billingTabs.CUSTOMER_INVOICES }
          ) as string,
        }
      : {
          hrefTitle: 'Back to billing',
          href: getFormattedURL(
            adminRoute.billing,
            {},
            { ...billingDocumentsTableState, selectedTab: billingTabs.BILLING }
          ) as string,
        };
  }

  return {
    hrefTitle: 'Back to billing',
    href: getFormattedURL(BILLING_ROUTE, {}, invoicesTableState) as string,
  };
};
