import getConfig from 'next/config';
import { useState } from 'react';

import { captureException } from '@/src/helpers/captureException';

const {
  publicRuntimeConfig: { CAPTCHA_KEY, CAPTCHA_ENABLED },
} = getConfig();

type Params = {
  action: string;
  twofactor: boolean | undefined;
};

export const useReCAPTCHA = () => {
  const [isLoading, setIsLoading] = useState(false);

  function executeRecaptcha({ action, twofactor }: Params) {
    if (CAPTCHA_ENABLED && (window as any).grecaptcha) {
      setIsLoading(true);
      return new Promise((resolve) => {
        (window as any).grecaptcha.ready(async () => {
          const token = await (window as any).grecaptcha.execute(CAPTCHA_KEY, {
            action,
            twofactor,
          });
          resolve(token);
        });
      })
        .catch((error) => {
          const tokenError = new Error('Failed to generate ReCAPTCHA token.', { cause: error });
          captureException(tokenError);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function removeCaptchaBadge() {
    const badge = window.document.querySelector('.grecaptcha-badge');
    if (badge) {
      badge.remove();
    }
  }

  return {
    isLoading,
    executeRecaptcha,
    removeCaptchaBadge,
  };
};
