import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { ErrorSummary } from '@/src/components/Form/ErrorSummary';
import { Modal, modalPropTypes } from '@/src/components/Modal';
import RemoteLogoLoader from '@/src/components/Ui/Loader/RemoteLoader';
import { useFormEvents, formActions } from '@/src/hooks/useFormEvents';

// Formik wraps the modal so we have access to formik state inside the modal.
// Use this for modals with forms.
/**
 * @deprecated - use src/components/Modal/DynamicFormModal.jsx that supports Dynamic Forms
 */
const FormModal = ({
  modalProps: mProps,
  initialValues,
  validationSchema,
  children,
  tone,
  theme = {},
  enableReinitialize,
  formikProps,
}) => {
  const { showError = true, ...modalProps } = mProps;
  const { formState, dispatch } = useFormEvents();

  async function handleOnSubmit(values, { setFieldError }) {
    dispatch({ type: formActions.LOADING });

    try {
      await modalProps.onSave({ values }, { setFieldError });
      if (modalProps.onSubmit) {
        await modalProps.onSubmit();
      }

      dispatch({ type: formActions.RESET });

      if (modalProps.hideModal) {
        await modalProps.hideModal();
      }
    } catch (exception) {
      const alwaysProps = { exception, setFieldError };
      const payload = modalProps.errorMessage
        ? { ...alwaysProps, errorMessage: modalProps.errorMessage }
        : alwaysProps;
      dispatch({
        type: formActions.ERROR,
        payload,
      });
    }
  }

  const commonProps = {
    ...modalProps,
    dangerousAction: tone === 'destructive',
  };

  if (!modalProps.visible) {
    return null;
  }

  if (modalProps.isLoading) {
    return (
      <Modal visible {...commonProps}>
        <RemoteLogoLoader />
      </Modal>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize}
      {...formikProps}
    >
      {(formikBag) => (
        <Modal
          {...commonProps}
          confirmLoading={formState.isLoading}
          disableSubmitBtn={formState.isLoading}
        >
          <ThemeProvider theme={{ ...theme }}>
            <form
              data-testid={modalProps.formName}
              id={modalProps.formName}
              onSubmit={formikBag.handleSubmit}
            >
              {typeof children === 'function' ? children(formikBag) : children}

              {showError ? <ErrorSummary errorMessage={formState.errorMessage} mb={0} /> : null}
            </form>
          </ThemeProvider>
        </Modal>
      )}
    </Formik>
  );
};

FormModal.propTypes = {
  modalProps: PropTypes.shape({
    ...modalPropTypes,
    onSave: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    // formName is required due to the logic of the Modal component
    formName: PropTypes.string.isRequired,
    hideModal: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    showError: PropTypes.bool,
  }),
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]).isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  tone: PropTypes.oneOf(['primary', 'destructive']),
  enableReinitialize: PropTypes.bool,
  theme: PropTypes.object,
  formikProps: PropTypes.object,
};

export default FormModal;
