import { createContext, useContext, useState, type PropsWithChildren } from 'react';

import { TodosContextProvider } from '@/src/domains/todos/TodosProvider';

import { useInvalidateTodosQuery } from './hooks';

type TodosOverlayContext = {
  /**
   * Whether the overlay is open
   */
  isOverlayOpen: boolean;
  /**
   * Function to open the overlay
   */
  openOverlay: () => void;
  /**
   * Function to close the overlay
   */
  closeOverlay: () => void;
};

const TodosOverlayContext = createContext({} as TodosOverlayContext);

/**
 * Context Provider that wraps the `TodosContextProvider` and provides state and functions to control the Todos overlay
 */
const TodosOverlayContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const { invalidateTodosQuery } = useInvalidateTodosQuery();

  const openOverlay = () => {
    invalidateTodosQuery();
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  return (
    <TodosOverlayContext.Provider value={{ isOverlayOpen, openOverlay, closeOverlay }}>
      <TodosContextProvider
        queryKey="todos-overlay"
        getDataOptions={{ useErrorBoundary: false }}
        getCountsOptions={{ useErrorBoundary: false }}
      >
        {children}
      </TodosContextProvider>
    </TodosOverlayContext.Provider>
  );
};

const useTodosOverlayContext = () => {
  return useContext(TodosOverlayContext);
};

export { TodosOverlayContextProvider, useTodosOverlayContext };
