import { Item as DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';

import { ProfilesMenuTooltip } from './ProfilesMenuTooltip';
import type { ProfilesMenuTriggerProps } from './ProfilesMenuTrigger';
import { ProfilesMenuTrigger } from './ProfilesMenuTrigger';

type ProfilesMenuCollapsibleTriggerProps = {
  isStandaloneButton: boolean;
} & ProfilesMenuTriggerProps;

export const ProfilesMenuCollapsibleTrigger = forwardRef<
  HTMLButtonElement,
  ProfilesMenuCollapsibleTriggerProps
>(({ isStandaloneButton, ...props }, ref) => {
  const handleTriggerSelect = (event: Event) => {
    event.preventDefault();
  };

  if (isStandaloneButton) {
    return <ProfilesMenuTrigger {...props} ref={ref} />;
  }

  return (
    <ProfilesMenuTooltip isAccountsMenu={props.isAccountsMenu}>
      <DropdownMenuItem asChild onSelect={handleTriggerSelect}>
        <ProfilesMenuTrigger {...props} ref={ref} />
      </DropdownMenuItem>
    </ProfilesMenuTooltip>
  );
});
