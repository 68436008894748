import type { BillingDocument } from '@/src/api/config/employ/billingDocuments.types';

import { getInvoiceReportFields } from './getInvoiceReportFields';

/*
 * We need to show a different title copy to invoices emitted from Remote Entity (US)
 * to match what is printed on PDFs
 * https://linear.app/remote/issue/BILXP-761/ensure-invoice-description-consistency-between-platform-and-pdf
 */
export const getEORHighLevelBreakdownTitle = (billingDocument: BillingDocument) => {
  const { remoteEntity } = getInvoiceReportFields(billingDocument);

  return remoteEntity?.address?.country?.code === 'USA'
    ? 'Consultancy services'
    : 'Employment services';
};
