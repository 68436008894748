import React from 'react';
import styled from 'styled-components';

import { Stack } from '../../../layout';

const StyledValueContainer = styled(Stack).attrs({ direction: 'row', gap: 2 })<{
  allowCreate: boolean;
}>`
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  height: 100%;
  position: relative;
  // We set the width to a hard 0px value when the Select input allows users to create options
  // so that the input's control does not overflow when the values fill the container.
  width: ${({ allowCreate }) => (allowCreate ? '0px' : '100%')};
`;

export function ValueContainer({
  children,
  allowCreate,
}: {
  children: React.ReactNode;
  allowCreate: boolean;
}) {
  return <StyledValueContainer allowCreate={allowCreate}>{children}</StyledValueContainer>;
}
