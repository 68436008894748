import { css } from 'styled-components';

const InputBaseStyle = css`
  /* Input default styles */
  --inputFontWeight: 500;
  --inputLineHeight: 24px;
  --placeholderColor: var(--colors-lynch);
  --inputColor: var(--colors-blank);
  --inputBorderSize: 2px;
  --inputBorderColor: rgba(255, 255, 255, 0.3);
  --inputBackground: var(--colors-primary);
  --topLabelColor: rgba(255, 255, 255, 0.5);
  --labelTextColor: var(--colors-lynch);

  background-color: transparent;
  opacity: 1;
  border-radius: 0;
  border-width: 0;
  border-bottom: var(--inputBorderSize) solid var(--inputBorderColor);
  padding-left: 0;

  ${({ theme }) =>
    css`
      /* Input default styles */
      --inputFontSize: ${theme.typography.forms.inputFontSize};
      --inputFontWeight: ${theme.typography.forms.inputFontWeight};
      --inputLineHeight: ${
        // "theme.size" is likely a mistake.
        // It's likely safe to just remove this.
        // In the past, it was kept for migration sake. See:
        // https://remote-com.slack.com/archives/C020B57P9QU/p1722495210718209
        (theme as any).size === 'extra-small'
          ? theme.typography.forms.inputLineHeightSmall
          : theme.typography.forms.inputLineHeight
      };
      --inputColor: ${theme.colors.inputValue}
      --inputBorderSize: 2px;
      --inputBorderColor: ${theme.colors.inputBorder};
      --topLabelColor: ${theme.colors.inputLabel};
      --labelTextColor: ${theme.colors.inputDescription};
      --placeholderColor: ${theme.colors.inputPlaceholder};
      --inputColor: ${theme.colors.grey[900]};
      --inputBorderColor: ${theme.colors.grey[300]};
      --inputBackground: ${theme.colors.blank}
      --inputBackgroundActive: ${theme.colors.brand[50]};

      &:hover {
        --inputBorderColor: ${theme.colors.inputBorderHover};
      }
      &:focus {
        --inputBorderColor: ${theme.colors.inputBorderFocus};
      }
    `}
`;

/**
 * @deprecated Avoid using this style in new components.
 * Instead, consider pre-built Norma components,
 * or build your own with Norma's foundational components.
 *
 * This component is extracted from Employ to here for backward compatibility.
 * Original description from Employ:
 *
 * Since we're using this input style with our own Input component and with third party
 * components, we need to set some css variables and change accordingly.
 *
 * This style will implement some default values but other components might override these variables
 * if they need to implement a theme around it.
 * The default will be the styles for the onboarding theme, either colors, fontSize or backgrounds.
 */
export const LegacyInputStyle = css<{
  $dateRangeEditable?: boolean;
}>`
  ${InputBaseStyle}

  font-size: var(--inputFontSize);
  font-weight: var(--inputFontWeight);
  line-height: var(--inputLineHeight);
  color: var(--inputColor);
  border-color: var(--inputBorderColor);

  display: block;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.space[2]}px;
  transition: 0.2s border-color;

  &.error {
    --inputBorderColor: var(--colors-redPink);
  }

  &:hover,
  &:focus {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: var(--placeholderColor);
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeholderColor);
  }

  &::placeholder {
    color: var(--placeholderColor);
    opacity: 1;
  }

  &:read-only {
    border-bottom: 2px dashed ${({ theme }) => theme.colors.grey[300]};
    cursor: not-allowed;
  }

  &:disabled {
    color: var(--colors-lynch);
    cursor: not-allowed;

    &,
    &:hover {
      --inputBorderColor: ${({ theme }) => theme.colors.grey[300]};
    }
  }

  &:read-only {
    ${({ $dateRangeEditable }) =>
      $dateRangeEditable &&
      css`
        border-style: solid;
        color: var(--inputColor);
        cursor: text;
      `}
  }
`;
