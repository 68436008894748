import type { UserProfile } from '@/src/api/config/employ/userProfiles.types';
import { isFreelancerProfile } from '@/src/domains/freelancers/freelancer/helpers';
import type { UserProfileEmploymentType } from '@/src/domains/userProfile/constants';

export const getProfileEmploymentType = (employmentType?: UserProfileEmploymentType) => {
  switch (employmentType) {
    case 'eor':
      return 'Hired through Remote';
    case 'contractor':
      return 'Contractor';
    default:
      return '';
  }
};

export const getProfileItemCopy = (profile: UserProfile) => {
  const { profileType, companyName, jobTitle } = profile;

  if (isFreelancerProfile(profile)) {
    return {
      title: 'Freelancer Hub',
      subtitle: 'Manage your clients',
    };
  }

  const title = profile?.assignedRoles?.[0]?.name || jobTitle || profileType;

  return {
    title,
    subtitle: companyName,
  };
};
