import { Box } from '@remote-com/norma';
import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';

const StyledCardDrawerBodyWrapper = styled(Box)`
  padding: var(--card-drawer-space-y) var(--card-drawer-space-x);
  overflow: auto;
`;

type StyledCardDrawerBodyProps = PropsWithChildren<{}>;

export const CardDrawerBody = forwardRef<HTMLDivElement, StyledCardDrawerBodyProps>(
  (props, ref) => {
    return <StyledCardDrawerBodyWrapper ref={ref} {...props} />;
  }
);
