import { Node, mergeAttributes, PasteRule, ReactNodeViewRenderer } from '@tiptap/react';

import { isSmartFieldAvailable } from '@/src/domains/contracts/ContractsEditor/helpers';

import { SmartfieldComponent } from './SmartFieldComponent';

const COND_V1_START = '«COND_';
const COND_V1_END = '«END_COND»';
const COND_V2_START = '«CON_';
const COND_V2_END = '«END_CON»';
const SIGNATURE_FIELD = '«SIGNATURE»';

const pasteRegex = /«.+?»/g;

export const SmartField = Node.create({
  name: 'smartField',
  group: 'inline',
  inline: true,
  /**
   * Marking as editable via the `content` property, so that it
   * inherits default text behaviour, like CMD+Arrow to move the cursor.
   * This change is linked to the contentEditable=false set in the Component
   * More context: https://linear.app/remote/issue/COD-1291/contracts-editor-bug-with-cmdarrow-to-move-to-beginning-of-text
   */
  content: 'text*',
  selectable: false,
  atom: true,

  addOptions() {
    return {
      ...this.parent?.(),
      // Disables custom styling for smart fields in, e.g., employee view, and displays plain text only.
      hasStyling: true,
      hideUnavailableSmartFields: false,
      editableSmartFields: false,
      unavailableAsPlaceholder: false,
      unavailableAsPending: false,
    };
  },

  addAttributes() {
    return {
      label: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            'data-smart-field-label': attributes.label,
          };
        },
        parseHTML: (element) => element.getAttribute('data-smart-field-label'),
      },
      field: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.field) {
            return {};
          }

          return {
            'data-smart-field': attributes.field,
          };
        },
        parseHTML: (element) => element.getAttribute('data-smart-field'),
      },
      isUnavailable: {
        default: false,
      },
    };
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: pasteRegex,
        handler: ({ state, range, match }) => {
          const { tr } = state;

          const isNotConditional =
            !match[0].startsWith(COND_V1_START) && !match[0].startsWith(COND_V2_START);
          const isNotEndConditional = match[0] !== COND_V1_END && match[0] !== COND_V2_END;
          const isNotSignatureField = match[0] !== SIGNATURE_FIELD;

          const isSmartField = isNotConditional && isNotEndConditional && isNotSignatureField;

          if (isSmartField) {
            const pasteSmartField = match[0].replace(/«|»/g, '').trim();
            const isValidSmartField = isSmartFieldAvailable(
              pasteSmartField,
              this.editor.storage.smartFieldDefinitions
            );

            tr.replaceWith(
              range.from,
              range.to,
              this.type.create({
                label: pasteSmartField,
                field: pasteSmartField,
                isUnavailable: !isValidSmartField,
              })
            );
          }
        },
      }),
    ];
  },

  addCommands() {
    return {
      addSmartField:
        ({ field, isUnavailable = false }) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { label: field, field, isUnavailable },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(SmartfieldComponent);
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        {
          'data-type': this.name,
          style: `display: ${
            node.attrs.isUnavailable && this.options.hideUnavailableSmartFields ? 'none' : 'inline'
          }`,
          'data-has-styling': this.options.hasStyling ? 'true' : 'false',
          'data-is-unavailable': node.attrs.isUnavailable ? 'true' : 'false',
          'data-is-placeholder':
            node.attrs.isUnavailable &&
            (this.options.unavailableAsPlaceholder || this.options.unavailableAsPending)
              ? 'true'
              : 'false',
        },
        HTMLAttributes
      ),
      node.attrs.label,
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element) => element.getAttribute('data-type') === this.name,
      },
    ];
  },

  renderText({ node }) {
    return `«${node.attrs.field}»`;
  },
});
