import type { ElementType, ReactNode } from 'react';

import {
  HeadingContainer,
  HeadingIconContainer,
  HeadingText,
  IconStyled,
} from './HeadingIcon.styled';

export const headingIconColors = [
  'bayoux',
  'fuchsia',
  'cerise',
  'cerulean',
  'darkTurquoise',
  'deYork',
  'irisBlue',
  'lynch',
  'yellow',
  'radiance',
  'redPink',
  'salmon',
  'purple',
  'green',
] as const;

export type HeadingIconColor = (typeof headingIconColors)[number];

export type HeadingIconFontSize = 'sm' | 'md' | 'lg';

export type HeadingIconShape = 'circle' | 'square';

export type HeadingIconSize = 'sm' | 'md' | 'lg';

export type HeadingIconProps = {
  /**
   * Changes the appearance of the icon and its container.
   */
  color?: HeadingIconColor;
  /**
   * Changes the font size of the label text.
   */
  fontSize?: HeadingIconFontSize;
  /**
   * Displays an icon next to the label text.
   */
  Icon: ElementType;
  /**
   * Changes the appearance of the icon container.
   */
  shape?: HeadingIconShape;
  /**
   * Changes the size of the icon and its container
   */
  size?: HeadingIconSize;
  /**
   * The label text.
   */
  text: ReactNode;
};

export const DEFAULT_COLOR: HeadingIconColor = 'irisBlue';

export function HeadingIcon({
  color = DEFAULT_COLOR,
  fontSize = 'md',
  Icon,
  shape = 'square',
  size = 'md',
  text,
  ...props
}: HeadingIconProps) {
  return (
    <HeadingContainer {...props}>
      {Icon && (
        <>
          <HeadingIconContainer
            $color={color}
            $size={size}
            $shape={shape}
            data-testid="heading-icon-container"
          >
            <IconStyled as={Icon} $color={color} $size={size} />
          </HeadingIconContainer>{' '}
        </>
      )}
      <HeadingText $size={fontSize}>{text}</HeadingText>
    </HeadingContainer>
  );
}
