import { useMemo } from 'react';

import { getCurrentDateAsUTC } from '../helpers/date';

/**
 * Returns a memoized value of `new Date()` so it remains
 * unchanged between renders.
 */
export function useToday() {
  return useMemo(() => new Date(), []);
}

/**
 * Returns a memoized value of `new Date()` so it remains
 * unchanged between renders.
 */
export function useTodayAsUTC() {
  return useMemo(() => getCurrentDateAsUTC(), []);
}
