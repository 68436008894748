import styled, { css } from 'styled-components';

import { Box } from '../../layout';
import { inputStyles, getInputColors } from '../form-group';
import { customScrollbar } from '../helpers';

export const TextareaWrapper = styled(Box).attrs({ mr: -3 })``;

export const Textarea = styled.textarea`
  ${inputStyles}

  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      padding-top: ${theme.space[2]}px;
    `}

  line-height: 1.25;
  overflow-wrap: break-word;
  resize: vertical;
  white-space: pre-wrap;
  ::-webkit-resizer {
    background: url('/images/icons/resize-handle.svg');
    background-color: ${({ theme, readOnly }) =>
      readOnly && getInputColors(theme).backgroundReadOnly};
    background-repeat: no-repeat;
    background-position: center;
  }

  ${customScrollbar}
`;
