import { getCountryNameWithAlternatives } from '@/src/components/Ui/Select/CountrySelect';
/**
 * @typedef {Object} Country
 * @property {String} name
 * @property {String} dialCode
 * @property {(undefined|String[])} areaCodes
 */

/**
 * Transforms countries to a map of dialCodes, including possible areaCodes, to country object.
 * @param {Country[]} countries
 * @typedef {Object} CountriesByDialCode
 * @property {Object.<string, Country>} dialCodes
 * @property {Number} dialCodeMaxLength
 * @returns {CountriesByDialCode}
 */
export function getCountryDataByCountryCode(countries) {
  const dialCodes = {};
  let dialCodeMaxLength = 0;

  countries.forEach((country) => {
    if (country.areaCodes) {
      country.areaCodes.forEach((areaCode) => {
        const code = country.dialCode + areaCode;
        dialCodes[code] = country;
        if (code.length > dialCodeMaxLength) {
          dialCodeMaxLength = code.length;
        }
      });
    } else {
      dialCodes[country.dialCode] = country;
      if (country.dialCode.length > dialCodeMaxLength) {
        dialCodeMaxLength = country.dialCode.length;
      }
    }
  });

  return { dialCodes, dialCodeMaxLength };
}

/**
 * Extracts the different parts of an international phone number.
 * Should no country be provided it will return the internationalPhoneNumber as phoneNumber.
 * @param {String} internationalPhoneNumber
 * @param {(undefined | Country)} country
 * @typedef {Object} StructuredPhoneNumber
 * @property {String} prefix
 * @property {String} dialCode
 * @property {String} phoneNumber
 * @returns {StructuredPhoneNumber}
 */
export function getStructuredNumberFromInternationalNumber(internationalPhoneNumber, country) {
  // Accept any character after the country code to return the structure even in case of an invalid number.
  // The validation will be done at the form/field level.
  const baseRegex = new RegExp(`^(\\+|00)(\\d{${country?.dialCode?.length ?? 0}})(.*)$`);
  const baseMatch = internationalPhoneNumber?.match(baseRegex);

  if (!baseMatch) {
    return {
      prefix: '',
      dialCode: '',
      phoneNumber: internationalPhoneNumber,
    };
  }

  const [, plusOrCallPrefix, dialCode, phoneNumber] = baseMatch;

  return {
    prefix: plusOrCallPrefix,
    dialCode,
    phoneNumber,
  };
}

/**
 * Finds the country of the international phone number by dial and area codes.
 * @param {CountriesByDialCode} countries
 * @param {String} internationalPhoneNumber
 * @returns {(undefined|Country)}
 */
export function getCountryFromPhoneNumber(
  { dialCodes, dialCodeMaxLength },
  internationalPhoneNumber
) {
  const { prefix } = getStructuredNumberFromInternationalNumber(internationalPhoneNumber);

  let country;
  if (!prefix) {
    return country;
  }

  for (let i = dialCodeMaxLength + prefix.length; i > prefix.length; i--) {
    const dialCode = internationalPhoneNumber.slice(prefix.length, i);

    if (dialCodes[dialCode]) {
      country = dialCodes[dialCode];
      break;
    }
  }

  return country;
}

/**
 * Finds all possible area codes based on a validation pattern
 * Assumes the pattern will be eg: ^(\\+1)(206|224)[0-9]{1,}$, where (206|224) are the area codes
 * @param {string} pattern
 */
export function getAreaCodesFromPattern(pattern = '') {
  const codeGroupPattern = new RegExp(/\(([^)]+)\)/g);

  const groups = pattern.match(codeGroupPattern);

  if (groups?.length === 2) {
    return groups[1].replaceAll(/\(|\)/g, '').split('|');
  }

  return undefined;
}

/**
 * Create the country dial list from a json schema options list.
 * @param {Object[]} schemaOptions
 */
export function getCountryDialListFromOptions(schemaOptions) {
  return schemaOptions.map((option) => ({
    name: option.label,
    dialCode: option.meta.countryCode,
    pattern: option.pattern,
    areaCodes: getAreaCodesFromPattern(option.pattern),
  }));
}

// Use countryCode as label to show it as selected value
export function getCountryOptionLabel(country) {
  return `+${country.dialCode}`;
}

export function filterOption(option, input) {
  const { data } = option;
  const allNamesWithDialCode = [
    ...getCountryNameWithAlternatives(data.label),
    // to simplify the search, we put dial codes with names as they are strings as well
    data.dialCode,
    getCountryOptionLabel(data),
  ];

  return allNamesWithDialCode.some((name) => name.startsWith(input.toLowerCase()));
}

/**
 * Remove all spaces from a string.
 * @param {string} value
 */
export function removeSpaces(value) {
  return value.replace(/\s/g, '');
}
