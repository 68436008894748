export const experienceOptions = {
  LEVEL_2: {
    label: 'Level 2 - Entry level',
    description:
      'Employees who perform operational tasks with an average level of complexity. They perform their functions with limited autonomy',
    value:
      'Level 2 - Entry Level - Employees who perform operational tasks with an average level of complexity. They perform their functions with limited autonomy',
  },
  LEVEL_3: {
    label: 'Level 3 - Associate',
    description:
      'Employees who perform independently tasks and/or with coordination and control functions',
    value:
      'Level 3 - Associate - Employees who perform independently tasks and/or with coordination and control functions',
  },
  LEVEL_4: {
    label: 'Level 4 - Mid-Senior level',
    description:
      'Employees with high professional functions, executive management responsibilities, who supervise the production with an initiative and operational autonomy within the responsibilities delegated to them',
    value:
      'Level 4 - Mid-Senior level - Employees with high professional functions, executive management responsibilities, who supervise the production with an initiative and operational autonomy within the responsibilities delegated to them',
  },
  LEVEL_5: {
    label: 'Level 5 - Director',
    description: `Directors perform functions of an ongoing nature that are of significant importance for the development and implementation of the company's objectives`,
    value: `Level 5 - Director - Directors perform functions of an ongoing nature that are of significant importance for the development and implementation of the company's objectives`,
  },
  LEVEL_6: {
    label: 'Level 6 - Executive',
    description:
      'An Executive is responsible for running an organization. They create plans to help their organizations grow',
    value:
      'Level 6 - Executive - An Executive is responsible for running an organization. They create plans to help their organizations grow',
  },
};

export const experienceOptionsDefault = [
  experienceOptions.LEVEL_2,
  experienceOptions.LEVEL_3,
  experienceOptions.LEVEL_4,
  experienceOptions.LEVEL_5,
  experienceOptions.LEVEL_6,
];

export const experienceOptionsNonManagerial = [
  experienceOptions.LEVEL_2,
  experienceOptions.LEVEL_3,
];

export const workScheduleTypes = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
};

export const workScheduleOptionsDefault = [
  {
    label: 'Full-time',
    value: workScheduleTypes.FULL_TIME,
  },
  {
    label: 'Part-time',
    value: workScheduleTypes.PART_TIME,
  },
];

export const availablePTOType = /** @type {const} */ ({
  UNLIMITED: 'unlimited',
  FIXED: 'fixed',
});

export const unlimitedPTOOption = [
  {
    value: availablePTOType.UNLIMITED,
    label: 'Unlimited paid time off',
    description: (
      <>
        Gives the employee an uncapped number of paid time off days per year. The number below is
        the mandatory <strong>minimum</strong> number of days they must take.
      </>
    ),
  },
];

export const fixedPTOOption = [
  {
    value: availablePTOType.FIXED,
    label: 'Fixed paid time off',
    description:
      'The employee gets a set number of paid time off days per year that you establish.',
  },
];

export const availablePTOTypeOptions = [...unlimitedPTOOption, ...fixedPTOOption];

export const benefitsDependentsNote =
  'Note: Dependent coverage is available but is paid for by the employee and selected during enrollment.';

export const contractDurationTypes = {
  FIXED_TERM: 'fixed_term',
  INDEFINITE: 'indefinite',
};

export const contractDurationTypeLabels = {
  [contractDurationTypes.FIXED_TERM]: 'Fixed-term',
  [contractDurationTypes.INDEFINITE]: 'Indefinite',
};

export const contractDurationTypeOptions = {
  FIXED_TERM: {
    value: contractDurationTypes.FIXED_TERM,
    label: contractDurationTypeLabels[contractDurationTypes.FIXED_TERM],
  },
  INDEFINITE: {
    value: contractDurationTypes.INDEFINITE,
    label: contractDurationTypeLabels[contractDurationTypes.INDEFINITE],
  },
};

export const salaryRateTypes = {
  HOURLY: 'hourly',
  YEARLY: 'yearly',
};
