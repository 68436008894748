import { Box, Text, HTMLRendered, FieldDescription } from '@remote-com/norma';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { DynamicFormField } from '@/src/components/Form/DynamicForm/DynamicFormField';
import { FieldGroupDescription, FieldGroupLabel } from '@/src/components/Ui/Form/Input';
import { FormFieldError } from '@/src/domains/registration/onboarding/shared/styles/Form';

import { Radio } from './Radio';
import { Fieldset } from './Radio.styled';
import { RadioGroup } from './RadioGroup';

const BoxWithMargin = ({ children }) => <Box mt={3}>{children}</Box>;

/**
 * RadioField
 *
 * Renders a radio field for const value selection
 *
 * @export
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').RadioField, 'type'> & {id?: string}} props
 * @return {JSX.Element}
 */
export default function RadioField({
  name,
  label,
  description,
  hideLabel,
  options,
  direction,
  onChange,
  extra,
  readOnly,
  jsonType,
  size,
  dynamicFormProps,
  ...props
}) {
  const { values, setValues, setFieldTouched, setFieldValue } = useFormikContext();

  function handleOnChange(e, nativeOnChange, fieldName) {
    if (onChange) onChange(e, values, setValues, setFieldTouched);

    nativeOnChange(e);

    if (['true', 'false', true, false].includes(e.target.value)) {
      setFieldValue(fieldName, [true, 'true'].includes(e.target.value));
    }

    if (jsonType === 'number') {
      setFieldValue(fieldName, parseInt(e.target.value, 10));
    }
  }

  function renderError(error) {
    return <>{error && <FormFieldError name={name} Wrapper={BoxWithMargin} />}</>;
  }

  function renderExtra(extraMessage, error, touched) {
    const extraComponent = <HTMLRendered Tag={FieldDescription}>{extraMessage}</HTMLRendered>;
    return (
      <BoxWithMargin>
        {error && touched ? (
          <FormFieldError name={name} description={extraMessage} />
        ) : (
          <Text variant="sm">{extraComponent}</Text>
        )}
      </BoxWithMargin>
    );
  }

  return (
    <Field name={name}>
      {({ field: { value, ...field }, meta: { error, touched } }) => (
        <Box px={5}>
          <Fieldset {...props}>
            <FieldGroupLabel hasError={error && touched} $hidden={hideLabel}>
              {label}
            </FieldGroupLabel>

            {description && <HTMLRendered Tag={FieldGroupDescription}>{description}</HTMLRendered>}

            <RadioGroup $direction={direction}>
              {options.map((option) => (
                <Fragment key={option.value}>
                  <Radio
                    {...field}
                    name={name}
                    label={option.label}
                    value={option.value}
                    description={option.description}
                    onChange={(e) => handleOnChange(e, field.onChange, field.name)}
                    disabled={option.disabled || readOnly}
                    extra={option.extra}
                    inputWrapperStyles={option.inputWrapperStyles}
                    size={size}
                    pill={option.pill}
                    {...(option.attrs || {})}
                  />
                  {option.nestedField && (
                    <DynamicFormField
                      isNestedField
                      key={option.nestedField.name}
                      input={{
                        ...option.nestedField,
                      }}
                      values={dynamicFormProps.formValues}
                      renderField={dynamicFormProps.renderField}
                      setValue={dynamicFormProps.setValue}
                      {...dynamicFormProps.fieldProps}
                    />
                  )}
                </Fragment>
              ))}
            </RadioGroup>
            {extra ? renderExtra(extra, error) : renderError(error)}
          </Fieldset>
        </Box>
      )}
    </Field>
  );
}

RadioField.defaultProps = {
  direction: 'column',
  hideLabel: false,
};

RadioField.propTypes = {
  /** Form field name */
  name: PropTypes.string.isRequired,
  /** Field label */
  label: PropTypes.node.isRequired,
  /** Hide label */
  hideLabel: PropTypes.bool,
  /** Field description */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Field options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      description: PropTypes.node,
      disabled: PropTypes.bool,
      /** Custom attributes to spread to Radio option */
      attrs: PropTypes.object,
      /** Rendered below the Radio option, outside of the wrapper */
      extra: PropTypes.node,
      /** Custom styles applied to the Radio option wrapper */
      inputWrapperStyles: PropTypes.object,
      /** Pill component inline with description */
      pill: PropTypes.string,
    })
  ),
  /* The visual alignment of the inputs */
  direction: PropTypes.oneOf(['row', 'column']),
  /* Callback passed before the native onChange */
  onChange: PropTypes.func,
  /* Read only property to prevent interaction with RadioField */
  readOnly: PropTypes.bool,
};
